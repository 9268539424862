import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class IndexRateMaintenanceDataService {
constructor(private restApi: RestApiService) {}

    async saveIndexRate(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(`${Path.saveTariffIndexRateData}?IndexRate=${reqbody.indexRate}&CreateUser=${reqbody.createdUser}&EffectiveDate=${reqbody.effectiveDate}&IssueDate=${reqbody.issueDate}`);
        return data;
    }

    async getIndexRate(): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.getTariffIndexRateData);
        return data;
    }

}

export const indexRateMaintenanceDataService = new IndexRateMaintenanceDataService(restApiService);
