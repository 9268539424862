import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _ from 'lodash';
import { shipperLossGainColDef } from '../components/shipper-loss-gain-col-def';
import moment from 'moment';
import { ShipperLossGainMaintenanceDataService } from '../services/shipper-loss-gain-maintenance-data-service';
import { ShipperLossGainMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { shipperLossGainMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import history from '../../../../shared/components/header-component/component/history';

export class ShipperLossGainMaintenanceDataStore {
    @observable shipperLossGainMaintenanceData: any[] = [];
    @observable modifiedShipperLossGainMaintenanceData: any[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupShipperLossGainMaintenanceData: any[] = [];
    @observable isRefreshed = false;
    @observable showModal = false;
    @observable operationTypeDropdownOption: any = [];
    @observable projectNamesData: any = [];
    defaultLoggedInUserLocation = '';
    @observable isSearchClicked = false;
    
    @observable selectedShipperLGId = 0;
    @observable selectedShipperLGIds: any[] = [];
    @observable addOrEditShipperLGGridData: any[] = [];
    @observable backupAddOrEditShipperLGGridData: any[] = [];
    @observable onClickShipperLGNameOnGrid = false;
    @observable onClickAddNewShipperLGButton = false;
    @observable isActivateButtonDisabled = true;
    @observable baseSystemDropdownOptions: any = [];
    @observable baseSystemDropdownOptionsUI: any = [];
    @observable systemGradeDropdownOptions: any = [];
    @observable rulesRegTariffDropdownOptions: any = [];
    @observable lgCalcGroupDropdownOptions: any = [];

    @observable selectedBaseSystem = { value: 'All', label: 'All' };
    @observable selectedSystemGrade = { value: 'All', label: 'All' };
    @observable selectedRulesRegsTariff = { value: 'All', label: 'All' };
    @observable selectedLGCalcGroup = { value: 'CG', label: 'Current Groups' };
    @observable enteredSeachKeyword = '';
    @observable locationData: any = [];
    @observable baseSystemData: any = [];
    @observable systemGradeData: any = [];
    @observable allGradeData: any = [];
    @observable rulesRegTariffData: any = [];

    constructor(
        public shipperLossGainMaintenanceDataService: ShipperLossGainMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditShipperLG: AgGridService
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        this.defaultLoggedInUserLocation = this.accountStore.defaultUserLocation;
        dataStore.setShowUnsavedWarningAlert(false);
        this.getGradeData();
        this.getBaseSystemDropdown();
        this.getSystemGradeDropdown();
        this.getSettingsData();

        this.selectedBaseSystem = { value: 'All', label: 'All' };
        this.selectedSystemGrade = { value: 'All', label: 'All' };
        this.selectedRulesRegsTariff = { value: 'All', label: 'All' };
        this.enteredSeachKeyword = '';
        this.selectedLGCalcGroup = { value: 'CG', label: 'Current Groups' };
        this.systemGradeData = [];
        this.systemGradeDropdownOptions = [];
        this.rulesRegTariffData = [];
        this.rulesRegTariffDropdownOptions = [];
        this.userName = this.accountStore.displayName;
        this.selectedShipperLGId = 0;
    }

    // @Catch(() => errorHandler(ProfitCenterMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel() {
        const pageSize = this.agGridService.paginationGetPageSize();
        if(dataStore.isPageSizeChanged) {
            this.agGridService.resetCache(Number(pageSize));
        }
        const dataSource = {
            getRows: (params) => {
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
                const pageSize = this.agGridService.paginationGetPageSize();
                // console.log('loadViewtriggered');
                const page =  params.endRow / (pageSize ? pageSize : 100);
                const pageNo = this.isRefreshed || dataStore.isPageSizeChanged || page <= 1 ? 1 : parseInt(page.toFixed(0));
                const data = this.getapiRequest(pageNo, pageSize, params.sortModel);
                const filterRequestBody: any = [];
                for (const [key, value] of Object.entries(params.filterModel)) {
                    console.log(key, value);
                    const item = {
                        propertyName: key,
                        value: value && value['filterType'] === 'date'? value['dateFrom'] : value ? value['filter']:'',
                        operator: value ? value['type'] : '',
                        joinPrev: 'none'
                    
                      }
                      filterRequestBody.push(item);
                  }
                  console.log('params' +JSON.stringify(filterRequestBody));
                this.agGridService.showLoadingOverlay();
                this.shipperLossGainMaintenanceDataService.getTariffShipperLGGrid(data, filterRequestBody).then((response) => {
                const totalCount = response && response.length > 0 ? response[0].totalCount : response.length
                    params.successCallback(response ? response : [], totalCount);
                    dataStore.setCacheBlockSize(response? response.length : 0)
                    this.agGridService.hideOverlay();
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false); 
                }).catch(() => {
                    params.successCallback([], 0);
                    dataStore.setCacheBlockSize(0);
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false);
                    this.agGridService.hideOverlay(); 
                    errorHandler(ShipperLossGainMaintenanceMessages.FETCH_ERROR_MESSAGE)
                });
                dataStore.setCacheBlockSize(Number(pageSize));
            }
        }
        this.agGridService.setDatasource(dataSource);
    }

    getapiRequest = (currentPage, pageSize, sortModel?) => {
        if(this.isSearchClicked) {
            const baseDetails = this.baseSystemDropdownOptions.length > 0 && this.selectedBaseSystem.label !== 'All' ? this.baseSystemDropdownOptions.filter((item) => item.plName === this.selectedBaseSystem.label) : 0
            
            const tariffOptionid = this.selectedRulesRegsTariff.label === 'All' ? 0 : this.rulesRegTariffData.filter((item) => item.rulesregTariff === this.selectedRulesRegsTariff.label)[0].tariffOptionid;
            const grade = this.selectedSystemGrade.label === 'All' ? '' : this.selectedSystemGrade.label;
            const seachKeyword = this.enteredSeachKeyword;
            const calcGroup = this.selectedLGCalcGroup.value;
            const data = {
                pipelineID: baseDetails && baseDetails.length > 0 ?  baseDetails[0].pipelineID : 0,
                tariffOptionid: tariffOptionid, 
                grade: grade,
                seachKeyword: seachKeyword,
                calcGroup: calcGroup,
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort: sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        } else {
            const data = {
                pipelineID: 0,
                tariffOptionid: 0, 
                grade: '',
                seachKeyword: '',
                calcGroup: 'CG',
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort: sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        }
    }
    
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSystemGradeDropdown(): Promise<void> {
        const baseSystemId = this.selectedBaseSystem.label === 'All' ? 0 : this.baseSystemData.filter((system) => system.plName === this.selectedBaseSystem.label)[0].pipelineID;
        const grade = 'All';
        const gradeName = 'All';
        const systemGradeDropdownOptions = await this.shipperLossGainMaintenanceDataService.getGradesDataWithFilter(grade,gradeName,baseSystemId);
        const option: any = [];
        systemGradeDropdownOptions.length > 0 && systemGradeDropdownOptions.forEach((item) => {
            option.push({ 'value': item.gradeName, 'label': item.gradeName })
        });
        runInAction(() => {
            this.showLoader = false;
            this.systemGradeData = systemGradeDropdownOptions;
            this.systemGradeDropdownOptions = option;
        });
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getRulesRegTariffDropdown(): Promise<void> {
        const baseSystemId = this.selectedBaseSystem.label === 'All' ? 0 : this.baseSystemData.filter((system) => system.plName === this.selectedBaseSystem.label)[0].pipelineID;
        const rulesRegTariffDropdownOptions = await this.shipperLossGainMaintenanceDataService.getRulesRegTariff(baseSystemId);
        const option: any = [];
        rulesRegTariffDropdownOptions.length > 0 && rulesRegTariffDropdownOptions.forEach((item) => {
            option.push({ 'value': item.rulesregTariff, 'label': item.rulesregTariff })
        });
        runInAction(() => {
            this.showLoader = false;
            this.rulesRegTariffData = rulesRegTariffDropdownOptions;
            this.rulesRegTariffDropdownOptions = option;
        });
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getGradeData(): Promise<void> {
        const response = await this.shipperLossGainMaintenanceDataService.getGradesData();
        const filteredGrades = response.map(x => {return {'gradeName': x.gradeName, 'gradeCode': x.gradeCode}})
        this.allGradeData = filteredGrades;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSettingsData(): Promise<void> {
        const response = await this.shipperLossGainMaintenanceDataService.getMiscellaneousSettingsData(this.defaultLoggedInUserLocation);
        const filteredResponse = response.filter((item) => item.settingName === 'LGCalcGroup');
        const a = filteredResponse.map(x => { return { 'value': x.valueCode, 'label': x.valueText } });
        this.lgCalcGroupDropdownOptions = a;
    }

    @Loader
    @Catch(() => errorHandler(ShipperLossGainMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        this.isSearchClicked = false;
            this.isRefreshed = true;
           
            dataStore.onGridReady(); 
            
            this.selectedBaseSystem = { value: 'All', label: 'All' };
            this.selectedSystemGrade = { value: 'All', label: 'All' };
            this.selectedRulesRegsTariff = { value: 'All', label: 'All' };
            this.enteredSeachKeyword = '';
            this.selectedLGCalcGroup = { value: 'CG', label: 'Current Groups' };
            this.systemGradeData = [];
            this.systemGradeDropdownOptions = [];
            this.rulesRegTariffData = [];
            this.rulesRegTariffDropdownOptions = [];
            
    }

    @Loader
    @Catch(() => errorHandler(ShipperLossGainMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
           this.isRefreshed = true;
           this.isSearchClicked = true;
           dataStore.onGridReady();
    }

    getColDef() {
        // this.updatePushtoVmacsButtonColDef();
        return shipperLossGainColDef;
    }

    @action
    reset(): void {
        this.setShipperLGMaintenanceData(this.backupShipperLossGainMaintenanceData);
        this.modifiedShipperLossGainMaintenanceData = this.backupShipperLossGainMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setShipperLGMaintenanceData(shipperLossGainMaintenanceData: any[]): void {
        this.shipperLossGainMaintenanceData = shipperLossGainMaintenanceData
    }

    setbackupShipperLGMaintenanceData(backUpList: any[]) {
        this.backupShipperLossGainMaintenanceData = backUpList;
    }

    @computed
    get ShipperLossGainMaintenanceData() {
        return toJS(this.shipperLossGainMaintenanceData);
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.ShipperLGMaintenance);
    };

    isDeleteDisabled = ()  => {
      const requestBody: any = this.addOrEditShipperLGGridData.filter(a => a.isSelected === true);
      console.log(requestBody);
        return !this.selectedShipperLGId || requestBody.length > 0;
    };

   @action
   async onChangeSearchKeyword(value) {
        this.enteredSeachKeyword = value;
   }
   
   @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async activateShipperLG(): Promise<void> {
        const requestBody = this.selectedShipperLGIds;
        const userName = this.userName;
        const response: any = await this.shipperLossGainMaintenanceDataService.activateLineSegment(requestBody, userName);
       if (response && response.value) {
            this.uiService.toastService.error('There is an error while activating Shipper LG details.')
       } else {
           this.uiService.toastService.success('Shipper LG activated successfully.')
           this.onSearchClick();
        }
    }

isActivateDeactivateDisabled = ()  => {
    return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
};

    @action
    onCloseShipperLGDetails() {
        console.log(dataStore.showUnsavedWarningAlert);
        history.push('/ShipperLossGainMaintenance', { from: '/AddEditShipperLossGainMaintenance' });   
    }

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.shipperLossGainMaintenanceDataService.getBaseSystems();
        this.baseSystemData = baseSystemDropdownOptions;
        this.baseSystemDropdownOptions = baseSystemDropdownOptions;
        this.baseSystemDropdownOptionsUI = baseSystemDropdownOptions.map(item => {return {'value': item.plName, 'label': item.plName}});
    }
}
