import { action, observable, makeAutoObservable, toJS, computed } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { PipelineMaintenanceDataService } from '../services/pipeline-maintenance-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import moment from 'moment';
import _ from 'lodash';
import { PipelineMaintenanceDBTableName } from 'Path';

export class AddPipelineMaintenanceDataStore {
    @observable showLoader = false;
    @observable ownershipData: any [] = [];
    @observable entitiesData: any [] = [];
    @observable relatedAreasData: any [] = []; 
    @observable dropdownPhysicalPipeline : any [] = [];
    @observable qbInvoiceFrequencyDropdownOptions: any [] = [] ;   
    @observable qbRateBasisDropdownOptions: any [] = [];
    @observable lossGainDropdownOptions: any [] = [];
    @observable plaBasisDropdownOptions: any [] = [];
    @observable allocationBasisDropdownOptions: any [] = [];
    @observable ownersDropdownOptions: any [] = [];
    @observable distributeLGToDropdownOptions: any [] = [];
    @observable JIBDropdownOptions: any [] = [];
    @observable legalEntityDropdownOptions: any [] = [];
    @observable lineOfBusinessDropdownOptions: any [] = [];

    @observable formData = {
        plname: '',
        effectiveDate: '' as unknown as Date,
        endDate: '' as unknown as Date,
        operatedInd: true,
        qbInvoiceFreq: '',
        qbRateBasis: '',
        gravityBankInd: false,
        sulphurBankInd: false,
        lossGain: '',
        plaBasis: '',
        allocationBasis: ''
    }
    @observable pipelineCreated=false;
    @observable ownersCreated=false;
    @observable legalEntitiesCreated=false;
    @observable createdUser;
    @observable pipelineId;
    @observable syncedToTest = false;
    @observable syncedToProd = false;
    
    constructor(
        public pipelineMaintenanceDataService: PipelineMaintenanceDataService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridService: AgGridService,
        public agGridServiceOwners: AgGridService,
        public agGridServiceEntities: AgGridService,
        
    ) {
        makeAutoObservable(this);
    }

    @action
    @Loader
    init(): void {
       this.getPhysicalPipelinesDropDownData(); 
       this.getQBInvoiceFrequencyDropdown();
       this.getQBRateBasisDropdown();
       this.getLossGainDropdown();
       this.getPLABasisDropdown();
       this.getAllocationBasisDropdown();
       this.getOwnersDropdown();
       this.getDistributeLGToDropdown();
       this.createdUser = this.accountStore.displayName;
       this.getPipelineEntities();
       this.formData = {
            plname: '',
            effectiveDate: '' as unknown as Date,
            endDate: '' as unknown as Date,
            operatedInd: true,
            qbInvoiceFreq: '',
            qbRateBasis: '',
            gravityBankInd: false,
            sulphurBankInd: false,
            lossGain: '',
            plaBasis: '',
            allocationBasis: ''
        }
        this.setEntitiesGridData([]);
        this.setOwnershipGridData([]);
        this.sizeColumnstoFit();
        this.relatedAreasData=[];
        this.pipelineCreated=false
        this.ownersCreated=false;
        this.legalEntitiesCreated=false;
        this.syncedToTest = false;
        this.syncedToProd = false;
    }

    @action
    async sizeColumnstoFit(): Promise<void>{
        await this.agGridService.sizeColumnsToFit();
        await this.agGridServiceOwners.sizeColumnsToFit();
        await this.agGridServiceEntities.sizeColumnsToFit();
    }

    @action
    reset(){
        this.formData = {
            plname: '',
            effectiveDate: '' as unknown as Date,
            endDate: '' as unknown as Date,
            operatedInd: true,
            qbInvoiceFreq: '',
            qbRateBasis: '',
            gravityBankInd: false,
            sulphurBankInd: false,
            lossGain: '',
            plaBasis: '',
            allocationBasis: ''
        }
        this.setEntitiesGridData([]);
        this.setOwnershipGridData([]);
        this.relatedAreasData=[];
        this.pipelineCreated=false
        this.ownersCreated=false;
        this.legalEntitiesCreated=false;
    }


    // @Loader
    // @action
    // @Catch(() => errorHandler('Fetch Error'))
    // async getPipelineGridData(): Promise<void> {
    //     console.log(this.pipelineTypes, this.selectedSystemAreaType);
    //     console.log(this.pipelineTypes.find(x=>x.valueText===this.selectedSystemAreaType)?.settingID);
    //     const response = await this.pipelineMaintenanceDataService.getPipelineMaintenanceGridData(this.selectedSystemAreaType==='All'?'All':this.pipelineTypes.find(x=>x.valueText===this.selectedSystemAreaType)?.settingID, this.selectedSystemAreaName===''?'All':this.selectedSystemAreaName, this.selectedPhysicalPipeline);
    //     this.pipelineMaintenanceData = response;    
    // }

    
    @action
    changeFormData(value, key) {
        this.formData= {...this.formData, [key]: value}
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPhysicalPipelinesDropDownData(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPhysicalPipelinesDropDownData();
        this.dropdownPhysicalPipeline = response;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getQBInvoiceFrequencyDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getQBInvoiceFrequencyDropdown();
        this.qbInvoiceFrequencyDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
        this.changeFormData(this.qbInvoiceFrequencyDropdownOptions[0].valueText,'qbInvoiceFreq')
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getQBRateBasisDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getQBRateBasisDropdown();
        this.qbRateBasisDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
        this.changeFormData(this.qbRateBasisDropdownOptions[0].valueText,'qbRateBasis')
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLossGainDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getLossGainDropdown();
        this.lossGainDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
        this.changeFormData(this.lossGainDropdownOptions[0].valueText,'lossGain')
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPLABasisDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPLABasisDropdown();
        this.plaBasisDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
        this.changeFormData(this.plaBasisDropdownOptions[0].valueText,'plaBasis')
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAllocationBasisDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getAllocationBasisDropdown();
        this.allocationBasisDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
        this.changeFormData(this.allocationBasisDropdownOptions[0].valueText,'allocationBasis')
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getOwnersDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getOwnersDropDown();
        this.ownersDropdownOptions = response.map(x=>{return {'customerID':x.customerID, 'customerName':x.customerName}});    
        // this.addValuesInCellDropdownsOwners();
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error In Getting EBS Data. Legal Entities cannot be populated!'))
    async getPipelineEntities(): Promise<void> {
        const requestBody={
            'http_action':'Fetch',
            'http_flex_value':''
        }
        const response = await this.pipelineMaintenanceDataService.getPipelineEntities(requestBody);
        this.JIBDropdownOptions = JSON.parse(response.cts_response[0].ebsdata)[0].ResultSets.Table1;
        this.legalEntityDropdownOptions = JSON.parse(response.cts_response[0].ebsdata)[1].ResultSets.Table1;
        this.lineOfBusinessDropdownOptions = JSON.parse(response.cts_response[0].ebsdata)[2].ResultSets.Table1;
        this.addValuesInCellDropdownsLOB()
        this.addValuesInCellDropdownsLE()
        this.addValuesInCellDropdownsJIB()
    }



    
    @action
    // isEditableSettingName(params) {
    //      this.addValuesInCellDropdownsOwners(params);
    //      return true;
    // }

    addValuesInCellDropdownsOwners(params) {
        if(params.data){
            // const ownersNotEndDated=this.OwnershipData.filter(x=>((((x.endDate==''||x.endDate==null||x.endDate==undefined)&&params.data?.effectiveDate>x.effectiveDate)||x.effectiveDate===params.data?.effectiveDate))&&x.owner!==params.data?.owner).map(y=>y.owner)
            // this.agGridServiceOwners.updateOptionCellEditorValues(
            // this.ownersDropdownOptions.filter(x=>!ownersNotEndDated.includes(x.customerName)),
            // 'owner', 
            // 'customerName',
            // );
            const ownersNotEndDated= this.OwnershipData.filter(x=>((((x.endDate==''||x.endDate==null||x.endDate==undefined)&&new Date(x.effectiveDate)<new Date(params.data?.effectiveDate))||x.effectiveDate===params.data?.effectiveDate))).map(y=>y.owner);
            return this.ownersDropdownOptions.filter(x=>!ownersNotEndDated.includes(x.customerName))
        }
    }

    @action
    isEditableSettingNameDistribute(params) {
         this.addValuesInCellDropdownsDistribute();
         return true;
    }


    addValuesInCellDropdownsDistribute(): void {
        this.agGridServiceOwners.updateOptionCellEditorValues(
           this.distributeLGToDropdownOptions,
           'distributeLG', 
           'valueText',
        );
    }

    @action
    isEditableSettingNameJIB(params) {
         this.addValuesInCellDropdownsJIB();
         return true;
    }


    addValuesInCellDropdownsJIB(): void {
        this.agGridServiceEntities.updateOptionCellEditorValues(
           this.JIBDropdownOptions,
           'jointInterestBilling', 
           'JIB_NM',
        );
    }

    @action
    isEditableSettingNameLE(params) {
         this.addValuesInCellDropdownsLE();
         return true;
    }


    addValuesInCellDropdownsLE(): void {
        this.agGridServiceEntities.updateOptionCellEditorValues(
           this.legalEntityDropdownOptions,
           'legalEntity', 
           'LE_NM',
        );
    }

    @action
    isEditableSettingNameLOB(params) {
         this.addValuesInCellDropdownsLOB();
         return true;
    }


    addValuesInCellDropdownsLOB(): void {
        this.agGridServiceEntities.updateOptionCellEditorValues(
           this.lineOfBusinessDropdownOptions,
           'lineofBusiness', 
           'LOB_NM',
        );
    }

    


    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getDistributeLGToDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getDistributeLGToDropdown();
        this.distributeLGToDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})   
    async savePipeline(): Promise<void> {
        let errorFlag = false;
        if(!this.formData.plname){
            this.uiService.toastService.error('Base System is Required');
            errorFlag=true;
        }
        if(!this.formData.effectiveDate){
            this.uiService.toastService.error('Effective Date is Required');
            errorFlag=true;
        }
        if(errorFlag){
            return;
        }
        const requestBody: any =  
            {
                ...this.formData, 
                operatedInd: this.formData.operatedInd === true? 'Y': 'N',
                qbInvoiceFreq: this.qbInvoiceFrequencyDropdownOptions.find(x=>x.valueText===this.formData.qbInvoiceFreq)?.valueCode,
                qbRateBasis: this.qbRateBasisDropdownOptions.find(x=>x.valueText===this.formData.qbRateBasis)?.valueCode,
                gravityBankInd: this.formData.gravityBankInd === true? 'Y': 'N',
                sulphurBankInd: this.formData.sulphurBankInd === true? 'Y': 'N',
                lossGain: this.lossGainDropdownOptions.find(x=>x.valueText===this.formData.lossGain)?.valueCode,
                plaBasis: this.plaBasisDropdownOptions.find(x=>x.valueText===this.formData.plaBasis)?.valueCode,
                allocationBasis: this.allocationBasisDropdownOptions.find(x=>x.valueText===this.formData.allocationBasis)?.valueCode,
                effectiveDate: moment.utc(this.formData.effectiveDate).format('YYYY-MM-DDTHH:mm:ss'), 
                endDate: (this.formData.endDate && moment.utc(this.formData.endDate).format('YYYY-MM-DDTHH:mm:ss')) || null, 
                createUser: this.createdUser
            };
       if(requestBody.length !== 0) {
           const response: any[] = await this.pipelineMaintenanceDataService.savePipeline(requestBody);
           let error = '';
           if(response){
               for (const key in response) {                       
                   if (key==='pipelineID') {
                       this.uiService.toastService.success('Pipeline Created Successfully!')
                       this.pipelineCreated = true;
                       this.pipelineId = response[key];
                       this.syncedToTest = false;
                       this.syncedToProd = false;
                   } else if (key==='related') {
                        this.relatedAreasData = response[key];
                    } else {
                        if (key==='value') {
                            error = response[key]; 
                        }
                       if (key==='statusCode' && response[key]===400) {
                           this.uiService.toastService.error(error);
                       }
                   }
               }
           } 
       }        
    }
    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async saveOwnership(): Promise<void> {
        let errorFlag = false;
        const notEndDatedPriorRecord = this.OwnershipData.filter(x=>(x.endDate==''||x.endDate==null||x.endDate==undefined)&&this.OwnershipData.filter(y=>x.owner===y.owner&&new Date(x.effectiveDate)<new Date(y.effectiveDate)).length>0).length>0;
        if(notEndDatedPriorRecord){            
            this.uiService.toastService.error('Owners must be end dated if occuring again!');
            errorFlag = true;
        }
        const group = _.groupBy(this.OwnershipData, 'effectiveDate') as any;
        Object.keys(group).forEach(key => {
         let sum = 0; const ownersList : any [] = [];
         group[key].forEach((owners, index)=>{
             sum+=Number(owners.ownership)
             ownersList[index] = owners?.owner
         })
         if(this.OwnershipData.some(x=>x.owner===''||x.owner===undefined)){
            this.uiService.toastService.error('Owner cannot be blank!');
            errorFlag = true;
         }
         if(this.OwnershipData.some(x=>x.effectiveDate===''||x.effectiveDate===undefined)){
            this.uiService.toastService.error('Effective Date cannot be blank!');
            errorFlag = true;
         }
         if(sum!==1){
             this.uiService.toastService.error('Ownership should be 100% !');
             errorFlag = true;
         }
         if(this.hasDuplicates(ownersList)){
             this.uiService.toastService.error('Owners should be unique for a grouping!');
             errorFlag = true;
         }
         });
         if(errorFlag){
             return;
         }
         const requestBody = this.OwnershipData.map(owner=>
            {return {
                'pipelineID': this.pipelineId, 
                'createDate': moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': this.createdUser, 
                'ownerID': this.ownersDropdownOptions.find(x=>x.customerName===owner.owner)?.customerID, 
                'ownership': Number(owner.ownership), 
                'ownerEffectiveDate': moment.utc(owner.effectiveDate).format('YYYY-MM-DDTHH:mm:ss'), 
                'ownerEndDate': owner.endDate && moment.utc(owner.endDate).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'transfertoWarehouseIndicator': owner.transfer, 
                'distributeLGTo': this.distributeLGToDropdownOptions.find(x=>x.valueText===owner.distributeLG)?.valueCode 
            }});
       if(requestBody.length !== 0) {
           const response: any[] = await this.pipelineMaintenanceDataService.savePipelineOwners(requestBody);
           if(response){
                    let error ='';
                    for (const key in response) {                       
                        if (key==='pipelineID') {
                            this.uiService.toastService.success('Pipeline Owners Created Successfully!')
                            this.ownersCreated = true;
                            this.syncedToTest = false;
                            this.syncedToProd = false;
                        }else {
                            if (key==='value') {
                                error = response[key]; 
                            }
                            if (key==='statusCode' && response[key]===400) {
                                this.uiService.toastService.error(error);
                            }
                        }
                }
            } 
       }        
    }

    hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async savePipelineEntities(): Promise<void> {
        let errorFlag = false;
        if(this.EntitiesData.some(x=>x.startCycle===''||x.startCycle===undefined)){
            this.uiService.toastService.error('Start cycle cannot be blank!');
            errorFlag = true;
         }if(errorFlag){
            return;
        }
        const requestBody = this.EntitiesData.map(entity=>
            {return {
                'pipelineID': this.pipelineId, 
                'createDate': moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'startCycle': entity.startCycle && moment.utc(entity.startCycle)?.format('YYYY-MM-DDTHH:mm:ss') || null, 
                'endCycle': entity.endCycle && moment.utc(entity.endCycle)?.format('YYYY-MM-DDTHH:mm:ss') || null, 
                'stateCode': entity.stateCode, 
                'glLocation': entity.glLocation, 
                'legalEntity': this.legalEntityDropdownOptions.find(x=>x.LE_NM === entity.legalEntity)?.LE_CD, 
                'lineOfBusiness': this.lineOfBusinessDropdownOptions.find(x=>x.LOB_NM === entity.lineofBusiness)?.LOB_CD, 
                'jointIntrestBilling': this.JIBDropdownOptions.find(x=>x.JIB_NM === entity.jointInterestBilling)?.JIB_CD, 
                'createUser': this.createdUser,
                'legalEntityName': entity.legalEntity,
                'lineOfBusinessName': entity.lineOfBusiness,
                'jointIntrestBillingName': entity.jointInterestBilling  
            }});
       if(requestBody.length !== 0) {
            for(const item of requestBody){
                const requestBodyStateGLCode={
                    'http_action':'Validate',
                    'http_flex_value':(item.stateCode?String(item.stateCode):'0')+(item.glLocation?String(item.glLocation):'0')
                }
                const responseValidateStateGLCode: any = await this.pipelineMaintenanceDataService.validateStateGLCode(requestBodyStateGLCode);
                if(String(responseValidateStateGLCode.cts_response[0]?.ebsdata)==='0'){
                    this.uiService.toastService.error('Invalid State/GL Location Combination!');
                    errorFlag = true;
                }
            }
            if(errorFlag){
                return;
            }
           const response: any[] = await this.pipelineMaintenanceDataService.savePipelineEntities(requestBody);
           if(response){
                let error ='';
                for (const key in response) {                       
                    if (key==='pipelineID') {
                        this.legalEntitiesCreated=true;
                        this.syncedToTest = false;
                        this.syncedToProd = false;
                        this.uiService.toastService.success('Legal Entities Created Successfully!')
                    }else {
                        if (key==='value') {
                            error = response[key]; 
                        }
                        if (key==='statusCode' && response[key]===400) {
                            this.uiService.toastService.error(error);
                        }
                    }
               }
           } 
       }        
    }

    @action
    setOwnershipData(value, rowId, key){
        this.ownershipData=this.OwnershipData;
        this.ownershipData[rowId][key] = value;
        this.agGridServiceOwners.sortGrid('effectiveDate');
    }
    
    @action
    setEntitiesData(value, rowId, key){
        this.entitiesData=this.EntitiesData;
        this.entitiesData[rowId][key] = value;
    }
    
    @action
    setOwnershipGridData(value){
        this.ownershipData=value
    }

    @action
    setEntitiesGridData(value){
        this.entitiesData=value
    }

    @computed
    get OwnershipData() {
        return toJS(this.ownershipData);
    }

    @computed
    get EntitiesData() {
        return toJS(this.entitiesData);
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async pushToVmacs(env): Promise<void> {
        // const action = data.action === 'U' ? 'Update' : 'Insert';
        const requestBody: any =  { 
            'http_action' : '',
            'http_record_id': this.pipelineId,
            'http_cts_tablename': PipelineMaintenanceDBTableName.TableName,
            'http_cts_mappingtablename' : PipelineMaintenanceDBTableName.MappingTableName,
            'http_userid': this.createdUser,
            'http_environment': env.toUpperCase()  
        };
        if(requestBody.length !== 0) {
            const response = await this.pipelineMaintenanceDataService.pushToVmacs(requestBody);
            if(response) {
                this.uiService.toastService.success('Synced Successfully!');
                if(env==='Test'){
                    this.syncedToTest = true;
                } else {
                    this.syncedToProd = true;
                }
            }
        }
    }

}
