import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './create-tariff-log.scss';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef
} from 'ag-grid-community';
import { logEntryDataStore } from '../stores';
import { LogEntryDataStore } from '../stores/create-log-entry-data-store';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import { BaseTariffResponse } from '../model/model';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import DatePicker from 'react-datepicker';
import { timingSafeEqual } from 'crypto';
import { AnyARecord } from 'dns';
import axios from 'axios';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { RoleBasedActionName } from 'shared/types/enum';
import { Prompt } from 'react-router-dom';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Attachments } from 'common/components/attachments/attachments';
import { agGridService } from 'common/services/ag-grid-service';
import { logDOM } from '@testing-library/react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import { errorHandler } from 'shared/handlers/error-handler';
import { Portal } from '@mui/material';
import { action } from 'mobx';
import _, { isEqual } from 'lodash';
import { LogEntryAndScreenMapping, TariffLogEntry, TariffLogStatus } from '../constant/enums';
import momenttimezone from 'moment-timezone';
import { WorkflowDetails } from './workflow-details';
import history from '../../../../shared/components/header-component/component/history';
import { ReactSelect } from '../../../../common/components/select/select';
import '../../../../shared/components/confirm/_confirm.scss';
import { Option } from 'shared/types/shared';

/* eslint-disable */
interface CreateTariffLogEntryContainerProps {
  logEntryDataStore: LogEntryDataStore;
}

interface CreateTariffLogEntryContainerState {
  rowId: number;
  predictiveSelectText: string;
  selectedShownArray: any[];
  totalArray: any[];
  hiddenSelect: boolean;
  hiddenCancelSelect: boolean;
  editableFormData: any;
  baseTariffState: any;
  selectedShownCancelArray: any[];
  totalCancelArray: any[];
  predictiveSelectCancelText: string;
  selectedLogEntryState: string;
  initialDefaultFormData: any;
}

interface CalendarContainerProps {
  children : any
}

const CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };

const gridConfig: ColDef[] = [
  {
        headerName: 'Steps',
        field: 'workflowstepName',
        tooltipField: 'workflowstepName',
        filter: true,
        editable: false,
        sortable: true,
        minWidth: 150,
        singleClickEdit: true,
        flex: 2
    },
    {
        headerName: 'Status',
        field: 'workflowstepStatus',
        tooltipField: 'workflowstepStatus',
        filter: true,
        sortable: true,
        minWidth: 110,
        editable: false,
        singleClickEdit: true,
        suppressSizeToFit: true,
        flex: 1
    },
    {
        headerName: 'Comment',
        field: 'workflowStepComments',
        tooltipField: 'workflowStepComments',
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        filter: true,
        sortable: true,
        editable: false,
        minWidth: 135,
        flex: 2
    },
    {
        headerName: 'Workflow Step Role',
        field: 'workflowstepRole',
        tooltipField: 'workflowstepRole',
        singleClickEdit: true,
        editable: false,
        minWidth: 120,
        suppressSizeToFit: false,
    },
    {
        headerName: 'Last Modified User *',
        field: 'lastModifiedUser',
        tooltipField: 'lastModifiedUser',
        singleClickEdit: true,
        editable: false,
        minWidth: 120,
        suppressSizeToFit: false,
    },
    {
        headerName: 'Last Modified Date',
        field: 'lastModifiedDate',
        tooltipField: 'lastModifiedDate',
        filter: true,
        sortable: true,
        editable: false,
        minWidth: 120,
        valueGetter: params => {
            if (params.data['lastModifiedDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['lastModifiedDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        suppressSizeToFit: false,
    }
];

@inject('logEntryDataStore')
@observer
export class CreateTariffLogEntryContainer extends Component<CreateTariffLogEntryContainerProps, CreateTariffLogEntryContainerState> {

  constructor(props) {
    super(props);
    this.state = {
      rowId: 0,
      predictiveSelectText: "",
      selectedShownArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      totalArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      hiddenSelect: true,
      hiddenCancelSelect: true,
      editableFormData: {},
      baseTariffState: {},
      selectedShownCancelArray:[],
      totalCancelArray: [],
      predictiveSelectCancelText: '',
      selectedLogEntryState:'',
      initialDefaultFormData: {}
    };
    dataStore.setModuleName('Tariff Log');
  }

  componentDidMount() {
    const { logEntryDataStore } = this.props;
    logEntryDataStore.init();
    (Object.keys(LogEntryAndScreenMapping) as (keyof typeof LogEntryAndScreenMapping)[]).map(
      (key, index) => {
        console.log(key);
        if(LogEntryAndScreenMapping[key] === history.location.pathname) {
          logEntryDataStore.selectedLogEntryType = TariffLogEntry[key];
        }
      },
    );
    
    window.scroll(0, 0);
  }

  componentDidUpdate(newProps, newState): void {
    const { logEntryDataStore } = this.props;
    const filters = localStorageService.get('Log Entry Joint Carrier_filter');
    this.props.logEntryDataStore.agGridService.setAllFilters(filters);
    const columnVisible = localStorageService.get('Log Entry Joint Carrier_' + accountStore.userName);
    this.props.logEntryDataStore.agGridService.setColumnVisibility(columnVisible);
    // const logEntrySelectionfilters = localStorageService.get('Log Entry Selection_filter');
    // this.props.logEntryDataStore.agGridServiceForVersionSelection.setAllFilters(logEntrySelectionfilters);
    // const logEntrySelectioncolumnVisible = localStorageService.get('Log Entry Selection_' + accountStore.userName);
    // this.props.logEntryDataStore.agGridServiceForVersionSelection.setColumnVisibility(logEntrySelectioncolumnVisible);
    
    console.log(newProps.logEntryDataStore.selectedBaseTariffDetails, logEntryDataStore.selectedBaseTariffDetails);
    if (logEntryDataStore.selectedBaseTariffDetails.length > 0 && (this.state.baseTariffState !== logEntryDataStore.selectedBaseTariffDetails || this.state.selectedLogEntryState !== logEntryDataStore.selectedLogEntryType)) {
      if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFilingIndex) {
        this.loadIndexLogInitialData();
      } else if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling) {
        this.loadFilingLogInitialData();
      } else if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request) {
        this.loadRequestLogInitialData();
      } else if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance) {
        this.loadMaintananceLogInitialData();
      }
    } else if(logEntryDataStore.selectedBaseTariffDetails.length === 0 && logEntryDataStore.resetLogEntryForm){
      const editableFormData = {};
      this.setState({ editableFormData: editableFormData, baseTariffState: logEntryDataStore.selectedBaseTariffDetails, initialDefaultFormData: editableFormData });
      dataStore.showUnsavedWarningOnTabCloseOrReload();
      logEntryDataStore.resetLogEntryForm = false;
    }
  }

  componentWillUnmount() {
    if(!this.props.logEntryDataStore.isLogEntrySaved && this.props.logEntryDataStore.workflowStepId && !this.props.logEntryDataStore.tariffLogId) {
      this.props.logEntryDataStore.cancelWorkflow(); 
   }
  }

  loadIndexLogInitialData() {
    const { logEntryDataStore } = this.props;
    const jointTariffBaseDetailId = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID;
    logEntryDataStore.fileUploadedSuccessfully = jointTariffBaseDetailId && jointTariffBaseDetailId > 0;
    const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs;
    const filteredLogArray = logEntryDataStore.tariffLogId ? logsArray && logsArray.length > 0 ? logsArray.filter((item) => item.tariffLogID === logEntryDataStore.tariffLogId) : [] : logsArray;
    const tariffLog = filteredLogArray  && filteredLogArray.length > 0 ? filteredLogArray.reduce((a, b) => a.tariffEffectiveDate > b.tariffEffectiveDate ? a : b) : null;
    const tariffNumber = tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null;
    const defaultTariffNumber = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff.split(' ');
    const indexCurrentTariffNo =  logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.currentTariffNo;
    const currentTariffNumber = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null : indexCurrentTariffNo && indexCurrentTariffNo !== ''? indexCurrentTariffNo.split(' ') : null ;
    const indexCurrentCancelNo =  logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.currentCancelNo;
    const currentCancelNo = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.cancels ? tariffLog.cancels.split(' ') :  indexCurrentCancelNo && indexCurrentCancelNo !== ''? indexCurrentCancelNo.split(' ') : currentTariffNumber : currentTariffNumber ;
    let objectForBaseCarrier = {};
    const tariffLogVersion = logEntryDataStore.tariffLogId ? currentTariffNumber ? jointTariffBaseDetailId ? currentTariffNumber[4] : currentTariffNumber[3]  : '0' : currentTariffNumber ? jointTariffBaseDetailId ? parseInt(currentTariffNumber[4]) + 1 : parseInt(currentTariffNumber[3]) + 1 : '0';
    objectForBaseCarrier = {
      [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff]: {
      tariff: currentTariffNumber ? jointTariffBaseDetailId ?  currentTariffNumber[3] : currentTariffNumber[2] : defaultTariffNumber ? jointTariffBaseDetailId ? defaultTariffNumber[3] : defaultTariffNumber[2] : '',
      baseTariffName: currentTariffNumber ? jointTariffBaseDetailId ? `${currentTariffNumber[0]} ${currentTariffNumber[1]} ${currentTariffNumber[2]}` : `${currentTariffNumber[0]} ${currentTariffNumber[1]}` : defaultTariffNumber ? jointTariffBaseDetailId ? `${defaultTariffNumber[0]} ${defaultTariffNumber[1]} ${defaultTariffNumber[2]}` : `${defaultTariffNumber[0]} ${defaultTariffNumber[1]}` : '',
      legalVersion: '',
      cancels: currentCancelNo ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].currentCancelNo : 'New Tariff 0 0 0',
      cancelBaseTariffName: currentCancelNo ? jointTariffBaseDetailId ? `${currentCancelNo[0]} ${currentCancelNo[1]} ${currentCancelNo[2]}` : `${currentCancelNo[0]} ${currentCancelNo[1]}` : 'New Tariff',
      cancelTariff: currentCancelNo ? jointTariffBaseDetailId ?  currentCancelNo[3] : currentCancelNo[2] : '0',
      cancelTariffLogVersion: currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[4] : currentCancelNo[3] : '0',
      cancelTariffLogSubVersion: currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[5] : currentCancelNo[4] : '0',
      tariffLogVersion: tariffLogVersion, 
      tariffLogSubVersion: currentTariffNumber && logEntryDataStore.tariffLogId ? jointTariffBaseDetailId ? currentTariffNumber[5] : currentTariffNumber[4] : '0',
      description: tariffLog && tariffLog.description ? logEntryDataStore.tariffLogId ? tariffLog.description : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].description : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].description,
      index: tariffLog ? tariffLog.index ? true: false : true,
      vmacsTariffUpdate: true,
      vmacsImpact: true,
      documentation: false,
      tariffIssueDate: tariffLog && tariffLog.tariffIssueDate ? logEntryDataStore.tariffLogId ?  new Date(tariffLog.tariffIssueDate): new Date(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].indexIssueDate) : new Date(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].indexIssueDate),
      tariffEffectiveDate: tariffLog && tariffLog.tariffEffectiveDate ?  logEntryDataStore.tariffLogId ?  new Date(tariffLog.tariffEffectiveDate): new Date(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].indexEffectiveDate) : new Date(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].indexEffectiveDate),
      ctsEffectiveDate: tariffLog && tariffLog.ctsEffectiveDate ? logEntryDataStore.tariffLogId ? new Date(tariffLog.ctsEffectiveDate) : new Date(moment(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].indexEffectiveDate).startOf('month').toString()): new Date(moment(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].indexEffectiveDate).startOf('month').toString()),
      projectName: tariffLog && tariffLog.projectName ? tariffLog.projectName : null,
      tested: tariffLog ? logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Tariff Filing Index'&& tariffLog.testInd ? true : false : false,
      projectType: 'Index',
      isProject: false,
      tariffBaseID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseID,
      tariffBaseDetailID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID,
      tariffLogID: tariffLog && tariffLog.tariffLogID ? tariffLog.tariffLogID : false,
      logStatus: logEntryDataStore.tariffLogId && tariffLog && tariffLog.logStatus ? tariffLog.logStatus : TariffLogStatus.PendingProd,
      maintenanceSelectionVersionData: logsArray  && logsArray.length > 0 ? tariffLog && tariffLog.ctsEffectiveDate ?  [...logsArray.filter((item) => item.ctsEffectiveDate > tariffLog.ctsEffectiveDate), ...[logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).length > 0 ? logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] : [] : [],
      newTariffFilling: tariffLog ? tariffLog.newTariffFilling ? true: false : true,
      newVersion: 'V1'
    }
    }
    let objectForJointCarriers = {};
    console.log(objectForJointCarriers);
    const editableFormData = { ...objectForBaseCarrier, ...objectForJointCarriers };
    this.setState({ ...this.state, editableFormData: editableFormData, baseTariffState: logEntryDataStore.selectedBaseTariffDetails, initialDefaultFormData:editableFormData });
    this.setState({
      selectedLogEntryState : logEntryDataStore.selectedLogEntryType
    })
    dataStore.showUnsavedWarningOnTabCloseOrReload();
  }

  loadFilingLogInitialData() {
    const { logEntryDataStore } = this.props;
    const jointTariffBaseDetailId = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID;
    logEntryDataStore.fileUploadedSuccessfully = jointTariffBaseDetailId && jointTariffBaseDetailId > 0;
    const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs;
    const filteredLogArray = logEntryDataStore.tariffLogId ? logsArray && logsArray.length > 0 ? logsArray.filter((item) => item.tariffLogID === logEntryDataStore.tariffLogId) : [] : logsArray;
    const tariffLog = filteredLogArray  && filteredLogArray.length > 0 ? filteredLogArray.reduce((a, b) => a.tariffEffectiveDate > b.tariffEffectiveDate ? a : b) : null;
    const tariffNumber = tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null;
    const defaultTariffNumber = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff.split(' ');
   
    const indexCurrentTariffNo =  logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.currentTariffNo;
    const currentTariffNumber = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null : indexCurrentTariffNo && indexCurrentTariffNo !== ''? indexCurrentTariffNo.split(' ') : null ;
    const currentCancelNo = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.cancels ? tariffLog.cancels.split(' ') :  null : currentTariffNumber;
    logEntryDataStore.isProjectNameSaved = logEntryDataStore.tariffLogId && tariffLog && tariffLog.projectName && tariffLog.projectName !== ''&& tariffLog.testInd !== null ? true : false;
    const tariffLogVersion = logEntryDataStore.tariffLogId ? tariffNumber ? jointTariffBaseDetailId ? tariffNumber[4] : tariffNumber[3] : '0' : currentTariffNumber ? jointTariffBaseDetailId ? parseInt(currentTariffNumber[4]) + 1 : parseInt(currentTariffNumber[3]) + 1: '0';
    let objectForBaseCarrier = {};
    objectForBaseCarrier = {
        [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff]: {
      tariff: currentTariffNumber ? jointTariffBaseDetailId ?  currentTariffNumber[3] : currentTariffNumber[2] : defaultTariffNumber ? jointTariffBaseDetailId ? defaultTariffNumber[3] : defaultTariffNumber[2] : '',
      baseTariffName: currentTariffNumber ? jointTariffBaseDetailId ? `${currentTariffNumber[0]} ${currentTariffNumber[1]} ${currentTariffNumber[2]}` : `${currentTariffNumber[0]} ${currentTariffNumber[1]}` : defaultTariffNumber ? jointTariffBaseDetailId ? `${defaultTariffNumber[0]} ${defaultTariffNumber[1]} ${defaultTariffNumber[2]}` : `${defaultTariffNumber[0]} ${defaultTariffNumber[1]}` : '',
      legalVersion: '',
      cancels: currentCancelNo ? '' : 'New Tariff 0 0 0',
      cancelBaseTariffName: currentCancelNo ? jointTariffBaseDetailId ? `${currentCancelNo[0]} ${currentCancelNo[1]} ${currentCancelNo[2]}` : `${currentCancelNo[0]} ${currentCancelNo[1]}`  : 'New Tariff',
      cancelTariff: currentCancelNo ? jointTariffBaseDetailId ?  currentCancelNo[3] : currentCancelNo[2] : '0',
      cancelTariffLogVersion: currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[4] : currentCancelNo[3] : '0',
      cancelTariffLogSubVersion: currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[5] : currentCancelNo[4] : '0',
      tariffLogVersion: tariffLogVersion,
      tariffLogSubVersion: currentTariffNumber && logEntryDataStore.tariffLogId ? jointTariffBaseDetailId ? currentTariffNumber[5] : currentTariffNumber[4] : '0',
      description: logEntryDataStore.tariffLogId ? tariffLog && tariffLog.description ? tariffLog.description : '' : '',
      index: tariffLog && logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Tariff Filing'.toLowerCase()? tariffLog.index ? true: false : logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Tariff Filing'.toLowerCase() ? false : true,
      vmacsTariffUpdate: true,
      vmacsImpact: true,
      documentation: false,
      tariffIssueDate: tariffLog && tariffLog.tariffIssueDate && logEntryDataStore.tariffLogId ? new Date(tariffLog.tariffIssueDate): new Date(),
      tariffEffectiveDate: tariffLog && tariffLog.tariffEffectiveDate && logEntryDataStore.tariffLogId ? new Date(tariffLog.tariffEffectiveDate): new Date(),
      ctsEffectiveDate: tariffLog && tariffLog.ctsEffectiveDate && logEntryDataStore.tariffLogId ? new Date(tariffLog.ctsEffectiveDate) : new Date(moment().startOf('month').toString()),
      projectName: logEntryDataStore.tariffLogId && tariffLog && tariffLog.projectName ? tariffLog.projectName : null,
      tested: tariffLog && logEntryDataStore.tariffLogId ? tariffLog.testInd : null,
      projectType: 'Index',
      isProject: false,
      tariffBaseID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseID,
      tariffBaseDetailID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID,
      tariffLogID: tariffLog && tariffLog.tariffLogID ? tariffLog.tariffLogID : false,
      logStatus:  tariffLog && tariffLog.logStatus && logEntryDataStore.tariffLogId ?  tariffLog.logStatus : TariffLogStatus.PendingTestDetermination,
      maintenanceSelectionVersionData: logsArray  && logsArray.length > 0 ? tariffLog && tariffLog.ctsEffectiveDate ?  [...logsArray.filter((item) => item.ctsEffectiveDate > tariffLog.ctsEffectiveDate), ...[logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).length > 0 ? logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] : [] : [],
      newVersion: 'V1'
    }
    }
    let objectForJointCarriers = {};
    console.log(objectForJointCarriers);
    const editableFormData = { ...objectForBaseCarrier, ...objectForJointCarriers };
    this.setState({ ...this.state, editableFormData: editableFormData, baseTariffState: logEntryDataStore.selectedBaseTariffDetails, initialDefaultFormData:editableFormData });
    this.setState({
      selectedLogEntryState : logEntryDataStore.selectedLogEntryType
    })
    dataStore.showUnsavedWarningOnTabCloseOrReload();
   
  }

  loadRequestLogInitialData() {
    const jointTariffBaseDetailId = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID;
      logEntryDataStore.fileUploadedSuccessfully = jointTariffBaseDetailId && jointTariffBaseDetailId > 0;
      const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs;
      const filteredLogArray = logEntryDataStore.tariffLogId ? logsArray && logsArray.length > 0 ? logsArray.filter((item) => item.tariffLogID === logEntryDataStore.tariffLogId) : [] : logsArray;
      const tariffLog = filteredLogArray  && filteredLogArray.length > 0 ? filteredLogArray.reduce((a, b) => a.tariffEffectiveDate > b.tariffEffectiveDate ? a : b) : null;
      const tariffNumber = tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null;
      const defaultTariffNumber = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff.split(' ');
      // const tariffLogVersion = logEntryDataStore.tariffLogId ? tariffNumber ? jointTariffBaseDetailId ? tariffNumber[4] : tariffNumber[3] : '0' : tariffNumber ? jointTariffBaseDetailId ? parseInt(tariffNumber[4]) + 1 : parseInt(tariffNumber[3]) + 1: '0';
      const indexCurrentTariffNo =  logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.currentTariffNo;
      const currentTariffNumber = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null : indexCurrentTariffNo && indexCurrentTariffNo !== ''? indexCurrentTariffNo.split(' ') : null ;
      const currentCancelNo = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.cancels ? tariffLog.cancels.split(' ') :  null : currentTariffNumber;
      const tariffLogVersion = logEntryDataStore.tariffLogId ? tariffNumber ? jointTariffBaseDetailId ? tariffNumber[4] : tariffNumber[3] : '0' : currentTariffNumber ? jointTariffBaseDetailId ? currentTariffNumber[4] : currentTariffNumber[3] : '0';
      const filterTariffNumberLog = logsArray.filter((item) => item.tariffNumber === indexCurrentTariffNo);
      const array = filterTariffNumberLog && filterTariffNumberLog.length > 0 ? filterTariffNumberLog.reduce((a, b) => a.tariffLogVersion > b.tariffLogVersion ? a : b) : null;
      const currentVersion = array ? array.tariffLogVersion : 'V1';
      const newVersion = currentVersion? `V${parseInt(currentVersion.replace('V', '')) +  1}` :  currentVersion;
      let objectForBaseCarrier = {};
      objectForBaseCarrier = {
        [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff]: {
          tariff: currentTariffNumber ? jointTariffBaseDetailId ?  currentTariffNumber[3] : currentTariffNumber[2] : defaultTariffNumber ? jointTariffBaseDetailId ? defaultTariffNumber[3] : defaultTariffNumber[2] : '',
          baseTariffName: currentTariffNumber ? jointTariffBaseDetailId ? `${currentTariffNumber[0]} ${currentTariffNumber[1]} ${currentTariffNumber[2]}` : `${currentTariffNumber[0]} ${currentTariffNumber[1]}` : defaultTariffNumber ? jointTariffBaseDetailId ? `${defaultTariffNumber[0]} ${defaultTariffNumber[1]} ${defaultTariffNumber[2]}` : `${defaultTariffNumber[0]} ${defaultTariffNumber[1]}` : '',
          legalVersion: '',
          cancels: currentCancelNo ? '' : 'New Tariff 0 0 0',
          cancelBaseTariffName: currentCancelNo ? jointTariffBaseDetailId ? `${currentCancelNo[0]} ${currentCancelNo[1]} ${currentCancelNo[2]}` : `${currentCancelNo[0]} ${currentCancelNo[1]}`  : 'New Tariff',
          cancelTariff: currentCancelNo ? jointTariffBaseDetailId ?  currentCancelNo[3] : currentCancelNo[2] : '0',
          cancelTariffLogVersion: currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[4] : currentCancelNo[3] : '0',
          cancelTariffLogSubVersion: currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[5] : currentCancelNo[4] : '0',
          tariffLogVersion: tariffLogVersion,
          tariffLogSubVersion: currentTariffNumber ? jointTariffBaseDetailId ? currentTariffNumber[5] : currentTariffNumber[4] : '0',
          description: logEntryDataStore.tariffLogId ? tariffLog && tariffLog.description ? tariffLog.description : '' : '',
          index: tariffLog && logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Tariff Filing'.toLowerCase()? tariffLog.index ? true: false : logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Tariff Filing'.toLowerCase() ? false : true,
          vmacsTariffUpdate: true,
          vmacsImpact: true,
          documentation: false,
          tariffIssueDate: tariffLog && tariffLog.tariffIssueDate ? new Date(tariffLog.tariffIssueDate): array && array.tariffIssueDate ? new Date(array.tariffIssueDate) :  null,
          tariffEffectiveDate: tariffLog && tariffLog.tariffEffectiveDate ? new Date(tariffLog.tariffEffectiveDate):  array && array.tariffEffectiveDate ? new Date(array.tariffEffectiveDate) : null,
          ctsEffectiveDate: tariffLog && tariffLog.ctsEffectiveDate && logEntryDataStore.tariffLogId? new Date(tariffLog.ctsEffectiveDate) : null,
          projectName: logEntryDataStore.tariffLogId && tariffLog && tariffLog.projectName ? tariffLog.projectName : null,
          tested: tariffLog && logEntryDataStore.tariffLogId ? tariffLog.testInd : null,
          projectType: 'Index',
          isProject: false,
          tariffBaseID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseID,
          tariffBaseDetailID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID,
          tariffLogID: tariffLog && tariffLog.tariffLogID ? tariffLog.tariffLogID : false,
          logStatus:  tariffLog && tariffLog.logStatus && logEntryDataStore.tariffLogId ?  tariffLog.logStatus : TariffLogStatus.PendingTest,
          maintenanceSelectionVersionData: logsArray  && logsArray.length > 0 ? tariffLog && tariffLog.ctsEffectiveDate ?  [...logsArray.filter((item) => item.ctsEffectiveDate > tariffLog.ctsEffectiveDate), ...[logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).length > 0 ? logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] : [] : [],
          newVersion: newVersion,
          currentVersion: currentVersion,
          newTariffFilling: tariffLog && logEntryDataStore.tariffLogId ? tariffLog.newTariffFilling ? true: false : !currentTariffNumber ? true : false
      }
    }
      let objectForJointCarriers = {};
      console.log(objectForJointCarriers);
      const editableFormData = { ...objectForBaseCarrier, ...objectForJointCarriers };
      this.setState({ ...this.state, editableFormData: editableFormData, baseTariffState: logEntryDataStore.selectedBaseTariffDetails, initialDefaultFormData:editableFormData });
      this.setState({
        selectedLogEntryState : logEntryDataStore.selectedLogEntryType
      })
      dataStore.showUnsavedWarningOnTabCloseOrReload();
  }

  
  loadMaintananceLogInitialData() {
    const jointTariffBaseDetailId = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID;
      logEntryDataStore.fileUploadedSuccessfully = jointTariffBaseDetailId && jointTariffBaseDetailId > 0;
      const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs;
      const filteredLogArray = logEntryDataStore.tariffLogId ? logsArray && logsArray.length > 0 ? logsArray.filter((item) => item.tariffLogID === logEntryDataStore.tariffLogId) : [] : logsArray;
      const tariffLog = filteredLogArray  && filteredLogArray.length > 0 ? filteredLogArray.reduce((a, b) => a.tariffEffectiveDate > b.tariffEffectiveDate ? a : b) : null;
      const tariffNumber = tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null;
      const defaultTariffNumber = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff.split(' ');
      // const tariffLogVersion = logEntryDataStore.tariffLogId ? tariffNumber ? jointTariffBaseDetailId ? tariffNumber[4] : tariffNumber[3] : '0' : tariffNumber ? jointTariffBaseDetailId ? parseInt(tariffNumber[4]) + 1 : parseInt(tariffNumber[3]) + 1: '0';
      const indexCurrentTariffNo =  logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.currentTariffNo;
      const currentTariffNumber = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null : indexCurrentTariffNo && indexCurrentTariffNo !== ''? indexCurrentTariffNo.split(' ') : null ;
      const currentCancelNo = logEntryDataStore.tariffLogId ? tariffLog && tariffLog.cancels ? tariffLog.cancels.split(' ') :  null : currentTariffNumber;
      const tariffLogVersion = logEntryDataStore.tariffLogId ? tariffNumber ? jointTariffBaseDetailId ? tariffNumber[4] : tariffNumber[3] : '0' : currentTariffNumber ? jointTariffBaseDetailId ? currentTariffNumber[4] : currentTariffNumber[3] : '0';
      const filterTariffNumberLog = logsArray.filter((item) => item.tariffNumber === indexCurrentTariffNo);
      const array = filterTariffNumberLog && filterTariffNumberLog.length > 0 ? filterTariffNumberLog.reduce((a, b) => a.tariffLogVersion > b.tariffLogVersion ? a : b) : null;
      const currentVersion = logEntryDataStore.tariffLogId && array ? array.tariffLogVersion : '';
      const newVersion =  !logEntryDataStore.tariffLogId && currentVersion? `V${parseInt(currentVersion.replace('V', '')) +  1}` :  currentVersion;
      let objectForBaseCarrier = {};
      objectForBaseCarrier = {
        [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff]: {
          tariffNumber: logEntryDataStore.tariffLogId && tariffLog ? tariffLog.tariffNumber : '',
          tariff: logEntryDataStore.tariffLogId && currentTariffNumber ? jointTariffBaseDetailId ?  currentTariffNumber[3] : currentTariffNumber[2] : defaultTariffNumber ? jointTariffBaseDetailId ? defaultTariffNumber[3] : defaultTariffNumber[2] : '',
          baseTariffName: logEntryDataStore.tariffLogId && currentTariffNumber ? jointTariffBaseDetailId ? `${currentTariffNumber[0]} ${currentTariffNumber[1]} ${currentTariffNumber[2]}` : `${currentTariffNumber[0]} ${currentTariffNumber[1]}` : defaultTariffNumber ? jointTariffBaseDetailId ? `${defaultTariffNumber[0]} ${defaultTariffNumber[1]} ${defaultTariffNumber[2]}` : `${defaultTariffNumber[0]} ${defaultTariffNumber[1]}` : '',
          legalVersion: logEntryDataStore.tariffLogId && '',
          cancels: logEntryDataStore.tariffLogId && currentCancelNo ? '' : '',
          cancelBaseTariffName: logEntryDataStore.tariffLogId && currentCancelNo ? jointTariffBaseDetailId ? `${currentCancelNo[0]} ${currentCancelNo[1]} ${currentCancelNo[2]}` || 'New Tariff' : `${currentCancelNo[0]} ${currentCancelNo[1]}` || 'New Tariff' : logEntryDataStore.tariffLogId ?   'New Tariff' : '',
          cancelTariff: logEntryDataStore.tariffLogId && currentCancelNo ? jointTariffBaseDetailId ?  currentCancelNo[3] || '0': currentCancelNo[2] || '0': logEntryDataStore.tariffLogId ?   '0' : '',
          cancelTariffLogVersion: logEntryDataStore.tariffLogId && currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[4] || '0': currentCancelNo[3] || '0': logEntryDataStore.tariffLogId ?   '0' : '',
          cancelTariffLogSubVersion: logEntryDataStore.tariffLogId && currentCancelNo ? jointTariffBaseDetailId ? currentCancelNo[5] || '0': currentCancelNo[4] || '0': logEntryDataStore.tariffLogId ?   '0' : '',
          tariffLogVersion: logEntryDataStore.tariffLogId ? tariffLogVersion : '',
          tariffLogSubVersion: logEntryDataStore.tariffLogId && currentTariffNumber ? jointTariffBaseDetailId ? currentTariffNumber[5] : currentTariffNumber[4] : '',
          description: logEntryDataStore.tariffLogId ? tariffLog && tariffLog.description ? tariffLog.description : '' : '',
          vmacsTariffNumber:  logEntryDataStore.tariffLogId ? tariffLog && tariffLog.vmacsTariffNumber ? tariffLog.vmacsTariffNumber : '' : '',
          tariffIssueDate: logEntryDataStore.tariffLogId && tariffLog && tariffLog.tariffIssueDate ? new Date(tariffLog.tariffIssueDate): null,
          tariffEffectiveDate:  logEntryDataStore.tariffLogId && tariffLog && tariffLog.tariffEffectiveDate ? new Date(tariffLog.tariffEffectiveDate): null,
          ctsEffectiveDate: logEntryDataStore.tariffLogId && tariffLog && tariffLog.ctsEffectiveDate ? new Date(tariffLog.ctsEffectiveDate) : null,
          projectName: logEntryDataStore.tariffLogId && tariffLog && tariffLog.projectName ? tariffLog.projectName : null,
          tested: tariffLog && logEntryDataStore.tariffLogId ? tariffLog.testInd : null,
          tariffBaseID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseID,
          tariffBaseDetailID: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID,
          tariffLogID: tariffLog && tariffLog.tariffLogID ? tariffLog.tariffLogID : false,
          logStatus:  tariffLog && tariffLog.logStatus && logEntryDataStore.tariffLogId ?  tariffLog.logStatus : '',
          newVersion: newVersion,
          currentVersion: currentVersion,
        }
      }
      let objectForJointCarriers = {};
      console.log(objectForJointCarriers);
      const editableFormData = { ...objectForBaseCarrier, ...objectForJointCarriers };
      this.setState({ ...this.state, editableFormData: editableFormData, baseTariffState: logEntryDataStore.selectedBaseTariffDetails, initialDefaultFormData:editableFormData });
      this.setState({
        selectedLogEntryState : logEntryDataStore.selectedLogEntryType
      })
      dataStore.showUnsavedWarningOnTabCloseOrReload();
  }

  clickOutsideEvent = (e) => {
    const element = document.getElementById('predictiveSelectContainer');
    const elementCancel = document.getElementById('predictiveSelectContainerCancel');
    if (!element?.contains(e.target)) {
      this.setState({ ...this.state, hiddenSelect: true });
    }
    if(!elementCancel?.contains(e.target)) {
      this.setState({ ...this.state, hiddenCancelSelect: true });
    }
    this.removeClickListener();
  };

  removeClickListener = () => {
    document.removeEventListener('click', this.clickOutsideEvent);
  };


  tariffLogGridColDef: ColDef[] = [
    {
      headerName: 'File Name',
      field: 'fileName',
      filter: true,
      sortable: true,
      editable: () => { return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLog); },
      cellEditor: 'agSelectCellEditor',
      width: 150,
      //cellClass: (params) => {return this.props.logEntryDataStore.isEditableSettingName(params) ?  'cell-editable' : ''}, 
      // cellStyle: (params) => {
      //       return {border: '1px solid #0091ea'};
      // }
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: true,
      sortable: true,
      editable: false,
      width: 150
    },
    {
      headerName: 'Created By',
      field: 'createUser',
      width: 150,
      filter: true,
      sortable: true,
      editable: false
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      filter: true,
      sortable: true,
      editable: false,
      comparator: (a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if(dateA===dateB) return 0;
        else if(dateA>dateB) return 1;
        return -1           
      },
    }
  ];


  tariffLogJointCarrierGridColDef: ColDef[] = [
    {
      headerName: 'Joint Carrier',
      field: 'jointCarrier',
      filter: true,
      sortable: true,
      editable: () => { return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLog); },
      cellEditor: 'agSelectCellEditor',
    },
    {
      headerName: 'Base Tariff',
      field: 'baseTariff',
      filter: true,
      sortable: true,
      editable: false,
    },
    {
      headerName: 'Pipeline Area',
      field: 'pipeline',
      minWidth: 665,
      filter: true,
      sortable: true,
      editable: false
    }
  ];


 logEntrySelectionGridColDef: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly:true,
      filter: true,
      sortable: true,
      cellStyle: () => {return {padding: '10px'}},
      editable: () => { return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLog); },
      // cellEditor: 'agSelectCellEditor',
    },
    {
      headerName: 'Tariff#',
      field: 'tariffNumber',
      filter: true,
      width: 100,
      sortable: true,
      editable: false,
      flex: 1 
    },
    {
      headerName: 'Version',
      field: 'tariffLogVersion',
      filter: true,
      sortable: true,
      editable: false,
      width: 80,
      flex: 1
    },
    {
      headerName: 'CTS Effective Date',
      field: 'ctsEffectiveDate',
      filter: true,
      sortable: true,
      editable: false,
      width: 100,
      flex: 1 
    },
    {
      headerName: 'New Version',
      field: 'tariffLogNewVersion',
      filter: true,
      sortable: true,
      editable: () => {return !this.props.logEntryDataStore.isEditDisabled()},
      cellClass: (params) => {return !this.props.logEntryDataStore.isEditDisabled() ?  'cell-editable' : ''},
      width: 80, 
      flex: 1 
    },
    {
      headerName: 'New CTS Effective Date',
      field: 'newCtsEffectiveDate',
      filter: true,
      sortable: true,
      editable: () => {return !this.props.logEntryDataStore.isEditDisabled()},
      cellClass: (params) => {return !this.props.logEntryDataStore.isEditDisabled() ?  'cell-editable' : ''},
      cellRenderer: (params) => { 
        const newCtsEffectiveDate = params.data['newCtsEffectiveDate'] ?  moment(moment(params.data['newCtsEffectiveDate']).format('MM/DD/yyyy')).toDate() : null
        return (params.data && 
            (<DatePicker 
                popperClassName='ag-custom-component-popup' 
                selected={newCtsEffectiveDate}
                dateFormat="MM/dd/yyyy" 
                onChange={(value)=>{this.onChangeDate(params, value, 'newCtsEffectiveDate')}} 
                popperContainer={CalendarContainer} 
                popperPlacement='top-start' 
                className={this.props.logEntryDataStore.isEditDisabled() ? 'serviceDateInputStyle' : 'serviceDateInputStyle-editable'}
                disabled={this.props.logEntryDataStore.isEditDisabled()}/>
            )
        ) || params.value 
    },
    valueGetter: params => {
        if (params.data['newCtsEffectiveDate'] === null) {
            return '';
        } else {
            return moment(params.data['newCtsEffectiveDate']).format(AppConstant.DATE_FORMAT);
        }
    }, 
     
      width: 100,
      flex: 1 
    }
  ];


  onChangeDate = (params, value, key) => {  
    const {logEntryDataStore} = this.props;
    const newCtsEffectiveDate = moment(value).format('MM/DD/yyyy');
    console.log(moment(value).format('MM/DD/yyyy'));
    logEntryDataStore.setValueInChangeMap(params.data.tariffLogID, key, params.data.newCtsEffectiveDate, newCtsEffectiveDate);
    params.data.newCtsEffectiveDate =  moment(value).format('MM/DD/yyyy');
    this.updateRow(params.data);
  } 

  getColDef() {
    return this.tariffLogGridColDef;
  }

  getColDefJointCarrier() {
    return this.tariffLogJointCarrierGridColDef;
  }

  downloadAttachment(fileID) {
    this.props.logEntryDataStore.downloadAttachment(fileID);
  };

  deleteAttachment = async (files) => {
      // const requestBody: any = await pmcTariffDataStore.agGridService.getSelectedRows();
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
          () => {
            this.props.logEntryDataStore.deleteAttachment(files);
          },
          'Delete',
          'Are you sure you want to delete the selected attachment?'
      );
  };

  updateAttachment = async () => {
    this.props.logEntryDataStore.updateAttachment();
  }


  getGridConfig(gridId) {
    const {
      logEntryDataStore: { fileUploadGridData, agGridService }
    } = this.props;
    const tariffLogGridParams: any = {
      rowData: fileUploadGridData,
      columnDefs: this.getColDef(),
    };
    return agGridService.getGridConfig(tariffLogGridParams, gridId);
  }

  getGridConfigJointCarrier(gridId) {
    const {
      logEntryDataStore: { selectedBaseTariffDetails, agGridService }
    } = this.props;
    const rowData = selectedBaseTariffDetails.length > 0 ? selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails?.map(x => { return { 'jointCarrier': x.jointCarrier, 'baseTariff': x.baseTariff, 'pipelineArea': x.pipeline }; }) || [] : []
    const tariffLogJointCarrierGridParams: any = {
      rowData: rowData,
      columnDefs: this.getColDefJointCarrier(),
    };
    return agGridService.getGridConfig(tariffLogJointCarrierGridParams, gridId);
  }

  predictiveSelectOnChange = (e, tabIndex) => {
    document.addEventListener('click', this.clickOutsideEvent);
    this.onChangeEditableFormData('projectName', e.target.value, tabIndex);
  };

  predictiveSelectOnChangeCancel = (e, tabIndex) => {
    document.addEventListener('click', this.clickOutsideEvent);
    this.onChangeEditableFormData('cancelBaseTariffName', e.target.value, tabIndex);
  };

  predictiveSelectOnClick = (key?) => {
    document.addEventListener('click', this.clickOutsideEvent);
    if(key && key === 'cancels') {
      this.setState({ ...this.state, hiddenCancelSelect: logEntryDataStore.shownCancelVersion.length > 0 ? false : true });
    }
    this.setState({ ...this.state, hiddenSelect: logEntryDataStore.shownProjectNames.length > 0 ? false : true });
  };

  onChangeEditableFormData = (key, value, tabKey, fromPredictiveContainer = false) => {
    const {logEntryDataStore} = this.props;
    let tempState = {};
    let tempCancelState = {};
    const logsArray =  logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs;
    // if (key === 'projectName') {
    //   tempState = ({ hiddenSelect: logEntryDataStore.shownProjectNames.filter((x, index, arr) => x.includes(value)).length > 0 ? false : true, predictiveSelectText: value, selectedShownArray: this.state.totalArray.filter((x, index, arr) => x.includes(value)) });
    //   logEntryDataStore.setShownProjectNames(logEntryDataStore.projectNames.filter((x, index, arr) => x.includes(value)));
    //   if (fromPredictiveContainer) {
    //     tempState = { ...tempState, predictiveSelectText: value, hiddenSelect: true };
    //   }
    // }
    if (key === 'cancelBaseTariffName') {
      tempCancelState = ({ hiddenCancelSelect: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs.filter((x, index, arr) => x.tariffNumber.includes(value)).length > 0 ? false : true, predictiveSelectCancelText: value, selectedShownCancelArray: this.state.totalCancelArray.filter((x, index, arr) => x.includes(value)) });
      const filteredArray = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs.filter((x, index, arr) => x.tariffNumber.includes(value));
      const cancelArray = filteredArray.map(x=> { return x.tariffNumber}).filter((item, index, arr) => arr.indexOf(item) === index);
      logEntryDataStore.setShownCancelVersion(cancelArray);
      if (fromPredictiveContainer) {
        tempCancelState = { ...tempCancelState, predictiveSelectCancelText: value, hiddenCancelSelect: true };
      }
    }

    if (tabKey === logEntryDataStore.baseTariffData[this.state.rowId].baseTariff) {
      let modifiedEditableFormDataState = {};
      
      Object.keys(this.state.editableFormData).forEach(stateKey => {
        modifiedEditableFormDataState = {
          ...modifiedEditableFormDataState,
          [stateKey]: {
            ...this.state.editableFormData[stateKey],
            [key]: value,
            'documentation': key === 'documentation' ? value : (key === "index" && value === true) ? false : this.state.editableFormData[stateKey]['documentation'],
            'isProject':
              ((key === "index" || key === "documentation") && value === true) ? false :
                (key === "index" && value === false && this.state.editableFormData[stateKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[stateKey]['index'] === false) ? true :
                  (key === "index" && value === false) ? false :
                    (this.state.editableFormData[stateKey]['index'] === true || this.state.editableFormData[stateKey]['documentation'] === true) ? false :
                      key === 'isProject' ? value : this.state.editableFormData[stateKey]['isProject'],
            'tested':
              // ((key === "index") && value === true) ? false :
              //   (key === "index" && value === false) || (this.state.editableFormData[stateKey]['index'] === false) ? true :
              //     (key === "index" && value === false) ? false :
              //       (this.state.editableFormData[stateKey]['index'] === true) ? false :
                      key === 'tested' ? value : this.state.editableFormData[stateKey]['tested'],
            'index': key === 'index' ? value : (key === "documentation" && value === true) ? false : this.state.editableFormData[stateKey]['index'],
            'projectType': key === 'projectType' ? value : (key === "index") && value === true ? 'Index' : (key === "documentation") && value === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === true) || (key === "vmacsImpact" && value === true && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) ? 'Project' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[stateKey]['documentation'] === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[stateKey]['index'] === true ? 'Index' : this.state.editableFormData[stateKey]['projectType'],
            'logStatus' : key === 'newTariffFilling' ? value ?  'Pending Tariff Filing' : 'Pending Test' : key === 'tested' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling ? value ?  'Pending Test' : 'Pending Prod' : this.state.editableFormData[stateKey]['logStatus'],
            'maintenanceSelectionVersionData': key === 'ctsEffectiveDate' && logsArray  && logsArray.length > 0  ?  [...logsArray.filter((item) => item.ctsEffectiveDate > moment(value).format(AppConstant.API_DATE_FORMAT)), ...[logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).length > 0 ?  logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] : this.state.editableFormData[stateKey]['maintenanceSelectionVersionData'],
            'tariffIssueDate': key === 'newTariffFilling' && value ? null : key === 'tariffIssueDate' ? value : this.state.editableFormData[stateKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'newTariffFilling' && value ? null :  key === 'tariffEffectiveDate' ? value : this.state.editableFormData[stateKey]['tariffEffectiveDate'],
            'ctsEffectiveDate': key === 'tariffEffectiveDate' ? new Date(moment(value).startOf('month').toString()):  key === 'ctsEffectiveDate' ? value : this.state.editableFormData[stateKey]['ctsEffectiveDate'],
            'projectName': key === 'projectName' ? value && value !== 'Select' ? value : null :  this.state.editableFormData[stateKey]['projectName']
          }
        };
      });
      this.setState({ ...this.state, editableFormData: modifiedEditableFormDataState, ...tempState, ...tempCancelState });
    } else {
      this.setState({
        ...this.state, editableFormData: {
          ...this.state.editableFormData,
          [tabKey]: {
            ...this.state.editableFormData[tabKey],
            [key]: value,
            'documentation': key === 'documentation' ? value : (key === "index" && value === true) ? false : this.state.editableFormData[tabKey]['documentation'],
            'isProject':
              ((key === "index" || key === "documentation") && value === true) ? false :
                (key === "index" && value === false && this.state.editableFormData[tabKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[tabKey]['index'] === false) ? true :
                  (key === "index" && value === false) ? false :
                    (this.state.editableFormData[tabKey]['index'] === true || this.state.editableFormData[tabKey]['documentation'] === true) ? false :
                      key === 'isProject' ? value : this.state.editableFormData[tabKey]['isProject'],
            'tested':
              // ((key === "index" || key === "documentation") && value === true) ? false :
              //   (key === "index" && value === false && this.state.editableFormData[tabKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[tabKey]['index'] === false) ? true :
              //     (key === "index" && value === false) ? false :
              //       (this.state.editableFormData[tabKey]['index'] === true || this.state.editableFormData[tabKey]['documentation'] === true) ? false :
                      key === 'tested' ? value : this.state.editableFormData[tabKey]['tested'],
            'index': key === 'index' ? value : (key === "documentation" && value === true) ? false : this.state.editableFormData[tabKey]['index'],
            'projectType': key === 'projectType' ? value : (key === "index") && value === true ? 'Index' : (key === "documentation") && value === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === true) || (key === "vmacsImpact" && value === true && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) ? 'Project' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[tabKey]['documentation'] === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[tabKey]['index'] === true ? 'Index' : this.state.editableFormData[tabKey]['projectType'],
            'logStatus' :  key === 'newTariffFilling' ? value ?  'Pending Tariff Filing' : 'Pending Test' :  key === 'tested' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling ? value ?  'Pending Test' : 'Pending Prod'  : this.state.editableFormData[tabKey]['logStatus'] ,
            'maintenanceSelectionVersionData': key === 'ctsEffectiveDate' && logsArray  && logsArray.length > 0  ?  [...logsArray.filter((item) => item.ctsEffectiveDate > moment(value).format(AppConstant.API_DATE_FORMAT)), ...[logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).length > 0 ? logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] :  this.state.editableFormData[tabKey]['maintenanceSelectionVersionData'],
            'tariffIssueDate': key === 'newTariffFilling' && value ? null : key === 'tariffIssueDate' ? value : this.state.editableFormData[tabKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'newTariffFilling' && value ? null :  key === 'tariffEffectiveDate' ? value : this.state.editableFormData[tabKey]['tariffEffectiveDate'],
            'ctsEffectiveDate': key === 'tariffEffectiveDate' ? new Date(moment(value).startOf('month').toString()): key === 'ctsEffectiveDate' ? value : this.state.editableFormData[tabKey]['ctsEffectiveDate'],
            'projectName': key === 'projectName' ? value && value !== 'Select' ? value : null :  this.state.editableFormData[tabKey]['projectName']
          }
        }, ...tempState, ...tempCancelState
      });
    }
    dataStore.setShowUnsavedWarningAlert(true);
  };

  onChangeEditableFormDataRequestAndMaintenance = (key, value, tabKey, fromPredictiveContainer = false) => {
    const {logEntryDataStore} = this.props;
    let tempState = {};
    let tempCancelState = {};
    let newVersion= this.state.editableFormData[tabKey]['newVersion'];
    let currentVersion = this.state.editableFormData[tabKey]['currentVersion'];
    let logStatus = null;
    const logsArray =  logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs;
    // if (key === 'projectName') {
    //   tempState = ({ hiddenSelect: logEntryDataStore.shownProjectNames.filter((x, index, arr) => x.includes(value)).length > 0 ? false : true, predictiveSelectText: value, selectedShownArray: this.state.totalArray.filter((x, index, arr) => x.includes(value)) });
    //   logEntryDataStore.setShownProjectNames(logEntryDataStore.projectNames.filter((x, index, arr) => x.includes(value)));
    //   if (fromPredictiveContainer) {
    //     tempState = { ...tempState, predictiveSelectText: value, hiddenSelect: true };
    //   }
    // }
    if (key === 'cancelBaseTariffName') {
      tempCancelState = ({ hiddenCancelSelect: logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs.filter((x, index, arr) => x.tariffNumber.includes(value)).length > 0 ? false : true, predictiveSelectCancelText: value, selectedShownCancelArray: this.state.totalCancelArray.filter((x, index, arr) => x.includes(value)) });
      const filteredArray = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs.filter((x, index, arr) => x.tariffNumber.includes(value));
      const cancelArray = filteredArray.map(x=> { return x.tariffNumber}).filter((item, index, arr) => arr.indexOf(item) === index);
      logEntryDataStore.setShownCancelVersion(cancelArray);
      if (fromPredictiveContainer) {
        tempCancelState = { ...tempCancelState, predictiveSelectCancelText: value, hiddenCancelSelect: true };
      }
    }

    let goLiveData = null;
    if(key === 'projectName' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request) {
      const projectData =  value ? logEntryDataStore.projectNames.find((item) => item.name === value) : null;
      goLiveData = projectData ? projectData.goLiveDate : null;
    }

    if(key === 'tariffLogVersion'  && (logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request || logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance)) {
      const tariffNumber =  `${this.state.editableFormData[tabKey]['baseTariffName']} ${this.state.editableFormData[tabKey]['tariff']} ${value} ${this.state.editableFormData[tabKey]['tariffLogSubVersion']}`
      const jointTariffBaseDetailId = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID;
      const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs;
      const filterArray = logsArray.filter((item) => 
      {const tariff = item.tariffNumber.split(' ');
      return tariff[tariff.length - 2] === `${value}` });
      const array = filterArray && filterArray.length > 0 ? filterArray.reduce((a, b) => a.tariffLogVersion > b.tariffLogVersion ? a : b) : null;
      currentVersion = array ? array.tariffLogVersion : 'V0';
      newVersion = currentVersion? `V${parseInt(currentVersion.replace('V', '')) +  1}` :  currentVersion;
      logStatus = array ? array.logStatus : '';
    }

    if(key === 'newTariffFilling' && !value  && (logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request || logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance)) {
      const tariffNumber =  `${this.state.editableFormData[tabKey]['baseTariffName']} ${this.state.editableFormData[tabKey]['tariff']} ${this.state.editableFormData[tabKey]['tariffLogVersion']} ${this.state.editableFormData[tabKey]['tariffLogSubVersion']}`
      const jointTariffBaseDetailId = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID;
      const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs : logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs;
      const filterArray = logsArray.filter((item) => item.tariffNumber === tariffNumber);
      const array = filterArray && filterArray.length > 0 ? filterArray.reduce((a, b) => a.tariffLogVersion > b.tariffLogVersion ? a : b) : null;
      currentVersion = array ? array.tariffLogVersion : 'V0';
      newVersion = currentVersion? `V${parseInt(currentVersion.replace('V', '')) +  1}` :  currentVersion;
      logStatus = array ? array.logStatus : '';
    }

    const currentActiveLog = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.currentTariffNo;
    const activeLog = currentActiveLog ?  currentActiveLog.split(' ') : [];

    if (tabKey === logEntryDataStore.baseTariffData[this.state.rowId].baseTariff) {
      let modifiedEditableFormDataState = {};
      
      Object.keys(this.state.editableFormData).forEach(stateKey => {
        modifiedEditableFormDataState = {
          ...modifiedEditableFormDataState,
          [stateKey]: {
            ...this.state.editableFormData[stateKey],
            [key]: value,
            'documentation': key === 'documentation' ? value : (key === "index" && value === true) ? false : this.state.editableFormData[stateKey]['documentation'],
            'isProject':
              ((key === "index" || key === "documentation") && value === true) ? false :
                (key === "index" && value === false && this.state.editableFormData[stateKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[stateKey]['index'] === false) ? true :
                  (key === "index" && value === false) ? false :
                    (this.state.editableFormData[stateKey]['index'] === true || this.state.editableFormData[stateKey]['documentation'] === true) ? false :
                      key === 'isProject' ? value : this.state.editableFormData[stateKey]['isProject'],
            'tested':
              // ((key === "index") && value === true) ? false :
              //   (key === "index" && value === false) || (this.state.editableFormData[stateKey]['index'] === false) ? true :
              //     (key === "index" && value === false) ? false :
              //       (this.state.editableFormData[stateKey]['index'] === true) ? false :
                      key === 'tested' ? value : this.state.editableFormData[stateKey]['tested'],
            'index': key === 'index' ? value : (key === "documentation" && value === true) ? false : this.state.editableFormData[stateKey]['index'],
            'projectType': key === 'projectType' ? value : (key === "index") && value === true ? 'Index' : (key === "documentation") && value === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === true) || (key === "vmacsImpact" && value === true && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) ? 'Project' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[stateKey]['documentation'] === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[stateKey]['index'] === true ? 'Index' : this.state.editableFormData[stateKey]['projectType'],
            'logStatus' : logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ?  key === 'tested' ? value ?  'Pending Test' : 'Pending Prod' : logStatus ? logStatus : 'Pending Test' :  key === 'newTariffFilling' ? value ?  'Pending Tariff Filing' : 'Pending Test' : key === 'tested' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling ? value ?  'Pending Test' : 'Pending Prod' : this.state.editableFormData[stateKey]['logStatus'],
            'maintenanceSelectionVersionData': key === 'ctsEffectiveDate' && logsArray  && logsArray.length > 0  ?  [...logsArray.filter((item) => item.ctsEffectiveDate > moment(value).format(AppConstant.API_DATE_FORMAT)), ...[logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).length > 0 ?  logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] : this.state.editableFormData[stateKey]['maintenanceSelectionVersionData'],
            'tariffIssueDate': key === 'newTariffFilling' && value ? this.state.editableFormData[stateKey]['tariffIssueDate'] : key === 'tariffIssueDate' ? value : this.state.editableFormData[stateKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'newTariffFilling' && value ? this.state.editableFormData[stateKey]['tariffEffectiveDate'] :  key === 'tariffEffectiveDate' ? value : this.state.editableFormData[stateKey]['tariffEffectiveDate'],
            'ctsEffectiveDate': key === 'projectName' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request ?  goLiveData ? new Date(goLiveData) : null : key === 'tariffEffectiveDate' ? new Date(moment(value).startOf('month').toString()):  key === 'ctsEffectiveDate' ? value : this.state.editableFormData[stateKey]['ctsEffectiveDate'],
            'projectName': key === 'projectName' ? value && value !== 'Select' ? value : null :  this.state.editableFormData[stateKey]['projectName'],
            'cancelTariffLogVersion': logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request ?  key === 'newTariffFilling' && value ? this.state.editableFormData[stateKey]['cancelTariffLogVersion'] :  key === 'tariffLogVersion' ? value && value !== '0' && value > this.state.editableFormData[stateKey]['cancelTariffLogVersion'] &&(activeLog && parseInt(activeLog) - parseInt(value) !> 1)? parseInt(value)-1  : this.state.editableFormData[tabKey]['cancelTariffLogVersion'] :  key === 'cancelTariffLogVersion' ? value : this.state.editableFormData[stateKey]['cancelTariffLogVersion'] : key === 'cancelTariffLogVersion' ? value : this.state.editableFormData[stateKey]['cancelTariffLogVersion'],
            'cancelTariffLogSubVersion': logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request?  key === 'newTariffFilling' && value ? this.state.editableFormData[stateKey]['cancelTariffLogSubVersion'] : key === 'cancelTariffLogSubVersion' ? value : this.state.editableFormData[stateKey]['cancelTariffLogSubVersion'] : key === 'cancelTariffLogSubVersion' ? value : this.state.editableFormData[stateKey]['cancelTariffLogSubVersion'],
            'tariffLogVersion': logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request?  key === 'newTariffFilling' && value ? parseInt(this.state.editableFormData[stateKey]['cancelTariffLogVersion']) + 1 : key === 'tariffLogVersion' ? value : this.state.editableFormData[stateKey]['tariffLogVersion'] : key === 'tariffLogVersion' ? value : this.state.editableFormData[stateKey]['tariffLogVersion'],
            'tariffLogSubVersion':logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request ? key === 'newTariffFilling' && value ? this.state.editableFormData[stateKey]['tariffLogSubVersion'] : key === 'tariffLogVersion' ? `${parseInt(currentVersion.replace('V', ''))}` : this.state.editableFormData[stateKey]['tariffLogSubVersion'] : key === 'tariffLogVersion' ? `${parseInt(currentVersion.replace('V', ''))}` : key === 'tariffLogSubVersion' ? value : this.state.editableFormData[stateKey]['tariffLogSubVersion'],
            'newVersion': key === 'newTariffFilling' && value ? 'V1' : newVersion,
            'currentVersion' : currentVersion
          }
        };
      });
      this.setState({ ...this.state, editableFormData: modifiedEditableFormDataState, ...tempState, ...tempCancelState });
    } else {
      this.setState({
        ...this.state, editableFormData: {
          ...this.state.editableFormData,
          [tabKey]: {
            ...this.state.editableFormData[tabKey],
            [key]: value,
            'documentation': key === 'documentation' ? value : (key === "index" && value === true) ? false : this.state.editableFormData[tabKey]['documentation'],
            'isProject':
              ((key === "index" || key === "documentation") && value === true) ? false :
                (key === "index" && value === false && this.state.editableFormData[tabKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[tabKey]['index'] === false) ? true :
                  (key === "index" && value === false) ? false :
                    (this.state.editableFormData[tabKey]['index'] === true || this.state.editableFormData[tabKey]['documentation'] === true) ? false :
                      key === 'isProject' ? value : this.state.editableFormData[tabKey]['isProject'],
            'tested':
              // ((key === "index" || key === "documentation") && value === true) ? false :
              //   (key === "index" && value === false && this.state.editableFormData[tabKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[tabKey]['index'] === false) ? true :
              //     (key === "index" && value === false) ? false :
              //       (this.state.editableFormData[tabKey]['index'] === true || this.state.editableFormData[tabKey]['documentation'] === true) ? false :
                      key === 'tested' ? value : this.state.editableFormData[tabKey]['tested'],
            'index': key === 'index' ? value : (key === "documentation" && value === true) ? false : this.state.editableFormData[tabKey]['index'],
            'projectType': key === 'projectType' ? value : (key === "index") && value === true ? 'Index' : (key === "documentation") && value === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === true) || (key === "vmacsImpact" && value === true && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) ? 'Project' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[tabKey]['documentation'] === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[tabKey]['index'] === true ? 'Index' : this.state.editableFormData[tabKey]['projectType'],
            'logStatus' :  logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ?  key === 'tested' ? value ?  'Pending Test' : 'Pending Prod' : logStatus ? logStatus : 'Pending Test' :  key === 'newTariffFilling' ? value ?  'Pending Tariff Filing' : 'Pending Test' : key === 'tested' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling ? value ?  'Pending Test' : 'Pending Prod' : this.state.editableFormData[tabKey]['logStatus'],
            'maintenanceSelectionVersionData': key === 'ctsEffectiveDate' && logsArray  && logsArray.length > 0  ?  [...logsArray.filter((item) => item.ctsEffectiveDate > moment(value).format(AppConstant.API_DATE_FORMAT)), ...[logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).length > 0 ? logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] :  this.state.editableFormData[tabKey]['maintenanceSelectionVersionData'],
            'tariffIssueDate': key === 'newTariffFilling' && value ? this.state.editableFormData[tabKey]['tariffIssueDate'] : key === 'tariffIssueDate' ? value : this.state.editableFormData[tabKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'newTariffFilling' && value ? this.state.editableFormData[tabKey]['tariffEffectiveDate'] :  key === 'tariffEffectiveDate' ? value : this.state.editableFormData[tabKey]['tariffEffectiveDate'],
            'ctsEffectiveDate': key === 'projectName' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request ?  goLiveData ? new Date(goLiveData) : null : key === 'tariffEffectiveDate' ? new Date(moment(value).startOf('month').toString()): key === 'ctsEffectiveDate' ? value : this.state.editableFormData[tabKey]['ctsEffectiveDate'],
            'projectName': key === 'projectName' ? value && value !== 'Select' ? value : null :  this.state.editableFormData[tabKey]['projectName'],
            'cancelTariffLogVersion': logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request ?  key === 'newTariffFilling' && value ? this.state.editableFormData[tabKey]['cancelTariffLogVersion'] :  key === 'tariffLogVersion' ? value && value !== '0' && value > this.state.editableFormData[tabKey]['cancelTariffLogVersion'] &&(activeLog && parseInt(activeLog) - parseInt(value) !> 1)? parseInt(value)-1  : this.state.editableFormData[tabKey]['cancelTariffLogVersion'] :  key === 'cancelTariffLogVersion' ? value : this.state.editableFormData[tabKey]['cancelTariffLogVersion'] : key === 'cancelTariffLogVersion' ? value : this.state.editableFormData[tabKey]['cancelTariffLogVersion'],
            'cancelTariffLogSubVersion': logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request?  key === 'newTariffFilling' && value ? this.state.editableFormData[tabKey]['cancelTariffLogSubVersion'] : key === 'cancelTariffLogSubVersion' ? value : this.state.editableFormData[tabKey]['cancelTariffLogSubVersion'] : key === 'cancelTariffLogSubVersion' ? value : this.state.editableFormData[tabKey]['cancelTariffLogSubVersion'],
            'tariffLogVersion': logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request?  key === 'newTariffFilling' && value ? parseInt(this.state.editableFormData[tabKey]['cancelTariffLogVersion']) + 1 : key === 'tariffLogVersion' ? value : this.state.editableFormData[tabKey]['tariffLogVersion'] : key === 'tariffLogVersion' ? value : this.state.editableFormData[tabKey]['tariffLogVersion'],
            'tariffLogSubVersion':logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request ? key === 'newTariffFilling' && value ? this.state.editableFormData[tabKey]['tariffLogSubVersion'] : key === 'tariffLogVersion' ? `${parseInt(currentVersion.replace('V', ''))}` : this.state.editableFormData[tabKey]['tariffLogSubVersion'] :  key === 'tariffLogVersion' ? `${parseInt(currentVersion.replace('V', ''))}` : key === 'tariffLogSubVersion' ? value : this.state.editableFormData[tabKey]['tariffLogSubVersion'],
            'newVersion': key === 'newTariffFilling' && value ? 'V1' : newVersion,
            'currentVersion' :  currentVersion
          }
        }, ...tempState, ...tempCancelState
      });
    }
    dataStore.setShowUnsavedWarningAlert(true);
  };

  onChangeEditableFormDataMaintenance = (key, value, tabKey, fromPredictiveContainer = false, version?) => {
    const {logEntryDataStore} = this.props;
    const tariffNumber = key === 'tariffNumber' ? value : this.state.editableFormData[tabKey]?.tariffNumber;
    const logVersion = key === 'tariffNumber' ? version : this.state.editableFormData[tabKey]?.currentVersion;
    const tariffLog = this.findTariffLogDetail(tariffNumber, logVersion);
    const lastCreatedVersion = logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.filter(x=>(x.tariffNumber===tariffNumber)).reduce((a, b) => a.tariffLogVersion > b.tariffLogVersion ? a : b)?.tariffLogVersion || logVersion;

    if (tabKey === logEntryDataStore.baseTariffData[this.state.rowId].baseTariff) {
      let modifiedEditableFormDataState = {};
      
      Object.keys(this.state.editableFormData).forEach(stateKey => {
        modifiedEditableFormDataState = {
          ...modifiedEditableFormDataState,
          [stateKey]: {
            ...this.state.editableFormData[stateKey],
            [key]: value,
            'tariff': key === 'tariffNumber' && value ? value.slice(value.indexOf(value.match(/\d/))).split(' ')[0] : this.state.editableFormData[stateKey]['tariff'],
            'baseTariffName':  key === 'tariffNumber' && value ? value.slice(0, value.indexOf(value.match(/\d/))) :  this.state.editableFormData[stateKey]['baseTariffName'],
            'cancels': key === 'tariffNumber'  && tariffLog ? tariffLog.cancels : this.state.editableFormData[stateKey]['cancels'],
            'cancelBaseTariffName':  key === 'tariffNumber' && value && tariffLog ? tariffLog.cancels?.slice(0, tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/))) || 'New Tariff' :this.state.editableFormData[stateKey]['cancelBaseTariffName'] || 'New Tariff',
            'cancelTariff': key === 'tariffNumber' && value && tariffLog ?  tariffLog.cancels?.slice(tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/))).split(' ')[0] || '0' : this.state.editableFormData[stateKey]['cancelTariff'] || '0',
            'cancelTariffLogVersion': key === 'tariffNumber' && value && tariffLog ?  tariffLog.cancels?.slice(tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/))).split(' ')[1] || '0' : this.state.editableFormData[stateKey]['cancelTariffLogVersion'] || '0',
            'cancelTariffLogSubVersion':  key === 'tariffNumber' && value && tariffLog ? tariffLog.cancels?.slice(tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/))).split(' ')[2] || '0': this.state.editableFormData[stateKey]['cancelTariffLogSubVersion'] || '0',
            'tariffLogVersion': key === 'tariffNumber' && value ? value.slice(value.indexOf(value.match(/\d/))).split(' ')[1] :  this.state.editableFormData[stateKey]['tariffLogVersion'],
            'tariffLogSubVersion':   key === 'tariffNumber' && value ? value.slice(value.indexOf(value.match(/\d/))).split(' ')[2] :  this.state.editableFormData[stateKey]['tariffLogSubVersion'],
            'description': key === 'description' && value ? value : this.state.editableFormData[stateKey]['description'],
            'tariffBaseID':key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffBaseID : this.state.editableFormData[stateKey]['tariffBaseID'],
            'tariffBaseDetailID': key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffBaseDetailID : this.state.editableFormData[stateKey]['tariffBaseDetailID'],
            'tariffLogID':key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffLogID : this.state.editableFormData[stateKey]['tariffLogID'],
            'tested': key === 'tested' ? value : this.state.editableFormData[stateKey]['tested'],
            'logStatus' : key === 'tariffNumber' ? value && tariffLog ? tariffLog.logStatus : 'Pending Prod' :  key === 'tested' ? value ?  'Pending Test' : 'Pending Prod' : this.state.editableFormData[stateKey]['logStatus'],
            'tariffIssueDate': key === 'tariffNumber' ? value && tariffLog ? tariffLog.tariffIssueDate : this.state.editableFormData[stateKey]['tariffIssueDate'] : this.state.editableFormData[stateKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'tariffNumber' ? value && tariffLog ? tariffLog.tariffEffectiveDate : this.state.editableFormData[stateKey]['tariffEffectiveDate'] : this.state.editableFormData[stateKey]['tariffEffectiveDate'],
            'ctsEffectiveDate':key === 'tariffNumber' ? value && tariffLog ? tariffLog.ctsEffectiveDate : this.state.editableFormData[stateKey]['ctsEffectiveDate'] : key === 'ctsEffectiveDate' ? value : this.state.editableFormData[stateKey]['ctsEffectiveDate'],
            'newVersion':  key === 'tariffNumber' && value && tariffLog ? `V${parseInt( lastCreatedVersion.replace('V', '')) +  1}` : this.state.editableFormData[stateKey]['newVersion'],
            'currentVersion' : key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffLogVersion : this.state.editableFormData[stateKey]['currentVersion'],
            'tariffNumber': key === 'tariffNumber' ? value : this.state.editableFormData[stateKey]['tariffNumber'],
            'projectName' : key==='projectName' ? value: this.state.editableFormData[stateKey]['projectName'],
            'vmacsTariffNumber': key ==='tariffNumber' ? value && tariffLog ? tariffLog.vmacsTariffNumber : this.state.editableFormData[stateKey]['vmacsTariffNumber'] : this.state.editableFormData[stateKey]['vmacsTariffNumber']
          }
        };
      });
      this.setState({ ...this.state, editableFormData: modifiedEditableFormDataState });
    } else {
      this.setState({
        ...this.state, editableFormData: {
          ...this.state.editableFormData,
          [tabKey]: {
            ...this.state.editableFormData[tabKey],
            [key]: value,
            'tariff': key === 'tariffNumber' && value ? value.slice(value.indexOf(value.match(/\d/))).split(' ')[0] : this.state.editableFormData[tabKey]['tariff'],
            'baseTariffName':  key === 'tariffNumber' && value ? value.slice(0, value.indexOf(value.match(/\d/))) :  this.state.editableFormData[tabKey]['baseTariffName'],
            'cancels': key === 'tariffNumber'  && tariffLog ? tariffLog.cancels : this.state.editableFormData[tabKey]['cancels'],
            'cancelBaseTariffName':  key === 'tariffNumber' && value && tariffLog ? tariffLog.cancels?.slice(0, tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/)))  || 'New Tariff':this.state.editableFormData[tabKey]['cancelBaseTariffName']  || 'New Tariff',
            'cancelTariff': key === 'tariffNumber' && value && tariffLog ?  tariffLog.cancels?.slice(tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/))).split(' ')[0] || '0': this.state.editableFormData[tabKey]['cancelTariff'] || '0',
            'cancelTariffLogVersion': key === 'tariffNumber' && value && tariffLog ?  tariffLog.cancels?.slice(tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/))).split(' ')[1] || '0': this.state.editableFormData[tabKey]['cancelTariffLogVersion'] || '0',
            'cancelTariffLogSubVersion':  key === 'tariffNumber' && value && tariffLog ? tariffLog.cancels?.slice(tariffLog.cancels?.indexOf(tariffLog.cancels?.match(/\d/))).split(' ')[2] || '0': this.state.editableFormData[tabKey]['cancelTariffLogSubVersion'] || '0',
            'tariffLogVersion': key === 'tariffNumber' && value ? value.slice(value.indexOf(value.match(/\d/))).split(' ')[1] :  this.state.editableFormData[tabKey]['tariffLogVersion'],
            'tariffLogSubVersion':   key === 'tariffNumber' && value ? value.slice(value.indexOf(value.match(/\d/))).split(' ')[2] :  this.state.editableFormData[tabKey]['tariffLogSubVersion'],
            'description': key === 'description' && value ? value : this.state.editableFormData[tabKey]['description'],
            'tariffBaseID':key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffBaseID : this.state.editableFormData[tabKey]['tariffBaseID'],
            'tariffBaseDetailID': key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffBaseDetailID : this.state.editableFormData[tabKey]['tariffBaseDetailID'],
            'tariffLogID':key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffLogID : this.state.editableFormData[tabKey]['tariffLogID'],
            'tested': key === 'tested' ? value : this.state.editableFormData[tabKey]['tested'],
            'logStatus' : key === 'tariffNumber' ? value && tariffLog ? tariffLog.logStatus : 'Pending Prod' :  key === 'tested' ? value ?  'Pending Test' : 'Pending Prod' : this.state.editableFormData[tabKey]['logStatus'],
            'tariffIssueDate': key === 'tariffNumber' ? value && tariffLog ? tariffLog.tariffIssueDate : this.state.editableFormData[tabKey]['tariffIssueDate'] : this.state.editableFormData[tabKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'tariffNumber' ? value && tariffLog ? tariffLog.tariffEffectiveDate : this.state.editableFormData[tabKey]['tariffEffectiveDate'] : this.state.editableFormData[tabKey]['tariffEffectiveDate'],
            'ctsEffectiveDate':key === 'tariffNumber' ? value && tariffLog ? tariffLog.ctsEffectiveDate : this.state.editableFormData[tabKey]['ctsEffectiveDate'] : key === 'ctsEffectiveDate' ? value : this.state.editableFormData[tabKey]['ctsEffectiveDate'],
            'newVersion':  key === 'tariffNumber' && value && tariffLog ? `V${parseInt( lastCreatedVersion.replace('V', '')) +  1}` : this.state.editableFormData[tabKey]['newVersion'],
            'currentVersion' : key === 'tariffNumber' && value && tariffLog ? tariffLog.tariffLogVersion : this.state.editableFormData[tabKey]['currentVersion'],
            'tariffNumber': key === 'tariffNumber' ? value : this.state.editableFormData[tabKey]['tariffNumber'],
            'projectName' : key==='projectName' ? value: this.state.editableFormData[tabKey]['projectName'],
            'vmacsTariffNumber': key ==='tariffNumber' ? value && tariffLog ? tariffLog.vmacsTariffNumber : this.state.editableFormData[tabKey]['vmacsTariffNumber'] : this.state.editableFormData[tabKey]['vmacsTariffNumber']
          }
        }
      });
    }
    dataStore.setShowUnsavedWarningAlert(true);
  };

  uploadFile = (e) => {
    logEntryDataStore.uploadFile(e);
  };


  validateRequest = (data) => {
      const localValidatingErrorBody: any = [];
      let isValidRequest = true;
      if((data.tariffBaseID === 0 || !data.tariffBaseID) && (!data.tariffBaseDetailID)){
          const message = 'Please select valid Tariff Base.';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
      }

      if(data.baseTariffName === '' || !data.baseTariffName || !data.tariff || data.tariff === '' || !data.tariffLogVersion || data.tariffLogVersion === '' || !data.tariffLogSubVersion || data.tariffLogSubVersion === ''){
          const message = 'Please enter valid Tariff version.';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
      }
      if(data.cancels !== '' && (!data.cancelBaseTariffName || data.cancelBaseTariffName === '' || !data.cancelTariff || data.cancelTariff === '' || !data.cancelTariffLogVersion || data.cancelTariffLogVersion === '' || !data.cancelTariffLogSubVersion || data.cancelTariffLogSubVersion === '')) {
          const message = 'Please select valid Tariff Version for Cancellation.';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
      }
      if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling || logEntryDataStore.selectedLogEntryType  === TariffLogEntry.TariffFilingIndex) {
        if(data.cancelBaseTariffName !== 'New Tariff' && (parseInt(data.cancelTariffLogVersion) >= parseInt(data.tariffLogVersion) || parseInt(data.tariffLogVersion)- parseInt(data.cancelTariffLogVersion) > 1 )) {
          const message = `The Tariff # as per sequence should be ${data.baseTariffName} ${data.tariff} ${parseInt(data.cancelTariffLogVersion) + 1} 0. Please enter valid tariff version for creating log entry.`;
          localValidatingErrorBody.push(message);
          isValidRequest = false;
        } 
        if(data.cancelBaseTariffName === 'New Tariff' && (parseInt(data.cancelTariffLogVersion) !== parseInt(data.tariffLogVersion)  || parseInt(data.tariffLogVersion)- parseInt(data.cancelTariffLogVersion) > 1)) {
          const message = `The Tariff # as per sequence should be ${data.baseTariffName} ${data.tariff} ${data.cancelTariffLogVersion} 0. Please enter valid tariff version for creating log entry.`;
          localValidatingErrorBody.push(message);
          isValidRequest = false;
        }
      }
      
      // if(logEntryDataStore.comment === '' || !logEntryDataStore.comment) {
      //   const message = 'Comment is required!';
      //   localValidatingErrorBody.push(message);
      //   isValidRequest = false;
      // }

      if(data.currentVersion === 'V0' && logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance) {
        const message = 'Maintenance log can only be created on top of existing log';
        localValidatingErrorBody.push(message);
        isValidRequest = false;
      }
      
      if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling && logEntryDataStore.workflowstepName === 'Complete Test Determination') {
        if(data.tested === null ) {
          const message = 'Test Determination is required';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
        }
        // if (!data.projectName || !logEntryDataStore.isProjectNameDisabled()){
        //   const message = 'Project Name is required';
        //   localValidatingErrorBody.push(message);
        //   isValidRequest = false;
        // }
        
      }

      if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance) {
        if(data.tested === null ) {
          const message = 'Test Determination is required';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
        }
        if (!data.projectName && data.tested === true){
          const message = 'Project Name is required';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
        }
        
      }
      return {
          isValidRequest: isValidRequest, 
          localValidatingErrorBody: localValidatingErrorBody
      };

  }

  cancelEditing = () => {
    const initialFormData = this.state.initialDefaultFormData;
    const updatedRows = this.props.logEntryDataStore.getMaintenanceGridUpdatedRowIDs();
    if(initialFormData !== this.state.editableFormData) {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () =>{this.props.logEntryDataStore.cancelEditing()},
          '',
          'All the unsaved changes will be lost. Are you sure you want to cancel ?'
        );
    } else {
      this.props.logEntryDataStore.cancelEditing()
    }
  }

  createNewIndexLogEntry = () => {
    const initialFormData = this.state.initialDefaultFormData;
    const updatedRows = this.props.logEntryDataStore.getMaintenanceGridUpdatedRowIDs();
    if(initialFormData !== this.state.editableFormData) {
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
        () =>{ this.props.logEntryDataStore.createNewIndexLogEntry()},
        '',
        'All the unsaved changes will be lost. Are you sure you want to create a new index log entry ?'
      );
    } else {
      this.props.logEntryDataStore.createNewIndexLogEntry();
    }
        
  }

  saveLogEntry = (buttonTitle) => {
    let data = { ...this.state.editableFormData };
    const { logEntryDataStore } = this.props;
    const logEntryID = logEntryDataStore.logEntryTypes.filter((item) => item.valueText === logEntryDataStore.selectedLogEntryType)[0].settingId;
      // if(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) {
        const { isValidRequest, localValidatingErrorBody} = this.validateRequest(data[logEntryDataStore.selectedBaseTariff]);
        if(isValidRequest) {
          if(logEntryDataStore.selectedLogEntryType !== TariffLogEntry.Maintenance) {
            const request =  {
              tariffLogID: (logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling) && logEntryDataStore.tariffLogId ? logEntryDataStore.tariffLogId : 0,
              tariffBaseID: data[logEntryDataStore.selectedBaseTariff].tariffBaseID,
              tariffNumber: `${data[logEntryDataStore.selectedBaseTariff].baseTariffName} ${data[logEntryDataStore.selectedBaseTariff].tariff} ${data[logEntryDataStore.selectedBaseTariff].tariffLogVersion} ${data[logEntryDataStore.selectedBaseTariff].tariffLogSubVersion}`,
              tariffIssueDate: data[logEntryDataStore.selectedBaseTariff].tariffIssueDate ? moment(data[logEntryDataStore.selectedBaseTariff].tariffIssueDate).format(AppConstant.API_DATE_FORMAT) :  moment(new Date('9999-12-31')).format(AppConstant.API_DATE_FORMAT),
              tariffEffectiveDate: data[logEntryDataStore.selectedBaseTariff].tariffEffectiveDate ? moment(data[logEntryDataStore.selectedBaseTariff].tariffEffectiveDate).format(AppConstant.API_DATE_FORMAT): moment(new Date('9999-12-31')).format(AppConstant.API_DATE_FORMAT),
              ctsEffectiveDate: moment(data[logEntryDataStore.selectedBaseTariff].ctsEffectiveDate).format(AppConstant.API_DATE_FORMAT),
              cancels: `${data[logEntryDataStore.selectedBaseTariff].cancelBaseTariffName} ${data[logEntryDataStore.selectedBaseTariff].cancelTariff} ${data[logEntryDataStore.selectedBaseTariff].cancelTariffLogVersion} ${data[logEntryDataStore.selectedBaseTariff].cancelTariffLogSubVersion}`,
              index: logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase() ?  data[logEntryDataStore.selectedBaseTariff].index : undefined,
              newTariffFilling: logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase() ? data[logEntryDataStore.selectedBaseTariff].newTariffFilling : undefined,
              createUser: logEntryDataStore.userName,
              createDate: moment().format(AppConstant.API_DATE_FORMAT),
              vmacsTariffNumber:`${data[logEntryDataStore.selectedBaseTariff].tariff}.${data[logEntryDataStore.selectedBaseTariff].tariffLogVersion}`,
              logEntryID: logEntryID,
              logStatus: data[logEntryDataStore.selectedBaseTariff].logStatus,
              testInd:logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase() ? data[logEntryDataStore.selectedBaseTariff].tested : undefined,
              projectName:logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase() ? data[logEntryDataStore.selectedBaseTariff].projectName : undefined,
              description: data[logEntryDataStore.selectedBaseTariff].description,
              tariffBaseDetailID: data[logEntryDataStore.selectedBaseTariff].tariffBaseDetailID,
              tariffLogVersion: data[logEntryDataStore.selectedBaseTariff].newVersion,
              workflowId: logEntryDataStore.workflowId
              }
              logEntryDataStore.saveLogEntry(request, null, buttonTitle);
              if(logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request)
              this.saveProjectName(buttonTitle);
          } else {
            const projectNameData = logEntryDataStore.projectNames.find((item) => item.name ===  data[logEntryDataStore.selectedBaseTariff].projectName);
            const request =  {
              tariffLogID: data[logEntryDataStore.selectedBaseTariff].tariffLogID  ?  data[logEntryDataStore.selectedBaseTariff].tariffLogID : 0,
              ctsEffectiveDate:  data[logEntryDataStore.selectedBaseTariff].ctsEffectiveDate ?   data[logEntryDataStore.selectedBaseTariff].ctsEffectiveDate : '',
              workflowID: logEntryDataStore.workflowId,
              testInd:  data[logEntryDataStore.selectedBaseTariff].tested,
              prjWorkflowID: projectNameData? projectNameData.workflowID : 0,
              logStatus: data[logEntryDataStore.selectedBaseTariff].logStatus,
              createUser: logEntryDataStore.userName,
              tariffLogVersion:  data[logEntryDataStore.selectedBaseTariff].newVersion,
              tariffNumber: data[logEntryDataStore.selectedBaseTariff].tariffNumber,
              description: data[logEntryDataStore.selectedBaseTariff].description ?  data[logEntryDataStore.selectedBaseTariff].description : '',
              }
              logEntryDataStore.saveLogEntry(request, TariffLogEntry.Maintenance, buttonTitle);
          }
          
        } else {
          const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
          errorHandler(messages);
        }
      // } else {
      //   const selectedRows = logEntryDataStore.agGridServiceForVersionSelection.getSelectedRows();
      //   let request: any = [];
      //   const logEntryID = logEntryDataStore.logEntryTypes.filter((item) => item.valueText === logEntryDataStore.selectedLogEntryType)[0].settingId;
      //   if(selectedRows && selectedRows.length > 0 ) {
      //     Object.keys(data).map(key => {
      //     selectedRows.map((item) => {
      //       // const filteredData = Object.keys(data).filter(key => item.tariffNumber.includes(key));
      //       // console.log(filteredData);
        
      //         if(item.tariffNumber.includes(key)) {
      //           const { isValidRequest, localValidatingErrorBody} = this.validateRequest(data[key]);
      //           if(isValidRequest) {
      //             const requestElement =  {
      //                   tariffLogID: item.tariffLogID,
      //                   tariffNumber: `${data[key].baseTariffName} ${data[key].tariff} ${data[key].tariffLogVersion} ${data[key].tariffLogSubVersion}`,
      //                   tariffLogVersion: item.tariffLogNewVersion &&  item.tariffLogNewVersion !== ''?  item.tariffLogNewVersion : item.tariffLogVersion,
      //                   tariffIssueDate: moment(data[key].tariffIssueDate).format(AppConstant.API_DATE_FORMAT),
      //                   tariffEffectiveDate: moment(data[key].tariffEffectiveDate).format(AppConstant.API_DATE_FORMAT),
      //                   ctsEffectiveDate: moment(item.newCtsEffectiveDate).format(AppConstant.API_DATE_FORMAT),
      //                   cancels: item.cancels,
      //                   index: item.index,
      //                   description: data[key].description,
      //                   createDate: moment(new Date()).format(AppConstant.API_DATE_FORMAT),
      //                   createUser: item.createUser,
      //                   updateUser: logEntryDataStore.createdUser,
      //                   vmacsTariffNumber: `${data[key].tariff} ${data[key].tariffLogVersion}`,
      //                   logStatus: data[key].logStatus,
      //                   testInd: data[key].tested,
      //                   projectName: item.projectName,
      //                   logEntryID: logEntryID,
      //                   tariffBaseID: data[key].tariffBaseID,
      //                   tariffBaseDetailID: data[key].tariffBaseDetailID,
      //                   workflowId: logEntryDataStore.workflowId
      //                 }
      //                 request.push(requestElement);
      //           } else {
      //             const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
      //             errorHandler(messages);
      //           }  
      //         }           
      //       });            
      //     })
      //   } else {
      //     errorHandler('Please select atleast one log version for updation.');
      //   }
        
        
      //   // let tempData = {};
      //   // data.forEach((id) => {
      //   //   tempData = { ...tempData, ...id };
      //   // });
      //   logEntryDataStore.saveLogEntry(request, logEntryDataStore.selectedLogEntryType, buttonTitle);
      // }
    
  };

  saveProjectName = (saveButtonTitle) => {
    let data = { ...this.state.editableFormData };
    const { logEntryDataStore } = this.props;
    if ((!data[logEntryDataStore.selectedBaseTariff].projectName ||  data[logEntryDataStore.selectedBaseTariff].projectName ==='') && !logEntryDataStore.isProjectNameDisabled() && saveButtonTitle !== 'Unlink/Link New Project'){
      errorHandler('Project Name is required.');
      return ;
    }
    if((logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling)  && data[logEntryDataStore.selectedBaseTariff].tested !== null && ((data[logEntryDataStore.selectedBaseTariff].projectName && data[logEntryDataStore.selectedBaseTariff].projectName !=='') || saveButtonTitle === 'Unlink/Link New Project')) {
      const tariffNumber = `${data[logEntryDataStore.selectedBaseTariff].baseTariffName} ${data[logEntryDataStore.selectedBaseTariff].tariff} ${data[logEntryDataStore.selectedBaseTariff].tariffLogVersion} ${data[logEntryDataStore.selectedBaseTariff].tariffLogSubVersion}`;
      logEntryDataStore.saveTestDetermination(data[logEntryDataStore.selectedBaseTariff].projectName, tariffNumber, saveButtonTitle )
    }
    
  }


  saveVMACSSetup = () => {
    let data = { ...this.state.editableFormData };
    data = Object.keys(data).map(key => {
      return {
        [key]: {
          "projectName": data[key].projectName,
          "tested": data[key].tested,
          "projectType": data[key].projectType,
          "isProject": data[key].isProject,
          "tariffBaseID": data[key].tariffBaseID,
          "createUser": logEntryDataStore.createdUser,
          "tariffBaseDetailID": data[key].tariffBaseDetailID
        }
      };
    });
    let tempData = {};
    data.forEach((id) => {
      tempData = { ...tempData, ...id };
    });
    logEntryDataStore.saveVMACSSetup(tempData);
  };


  changeBaseTariff(e) {
    if(e.value && e.value !== 'Select') {
      logEntryDataStore.selectedBaseTariff = e.value;
      logEntryDataStore.getSelectedBaseTariffDetails();
    }
  }
  
  findTariffLogDetail(value, version){
    const { logEntryDataStore } = this.props;
    return logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.find(x=>(x.tariffNumber===value && version && version.includes(x.tariffLogVersion)));
  } 

  render(): React.ReactNode {
    const { logEntryDataStore } = this.props;
    return (
      
        <Container fluid>
          <div className='createTariffLogBox'>
            <div className='screenHeader'>
                <h2 className='screen_title'>Create Tariff Log</h2>
            </div>
            {this.props.logEntryDataStore.showLoader == true &&
                        <div className = 'alert-overlay'> 
                          <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                        </div> 
                      }
            {/* <Prompt when={dataStore.showUnsavedWarningAlert === true} message={'You have unsaved changes, Are you sure you want to leave?'} /> */}
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Tariff Log</Accordion.Header>
                <Accordion.Body>
                  <div className='flex'>
                    <label className="standard_label_style">Log Entry Type</label>
                      <select className="select" 
                          disabled={true}
                          aria-disabled={true}
                          value={logEntryDataStore.selectedLogEntryType} 
                          onChange={(e)=>{logEntryDataStore.selectedLogEntryType = e.target.value}}>
                                {logEntryDataStore.logEntryTypes.map(option => (
                                  <option key={option.valueText} value={option.valueText}>
                                      {option.valueText}
                                  </option>
                              ))}
                      </select>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Base Tariff</Accordion.Header>
                <Accordion.Body>
                  <div className='flex'>
                    {/* <div className="rotatedText">
                      Base Tariff
                    </div> */}
                    <div className='baseTariff'>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Base Tariff</label>
                          <ReactSelect 
                            className='select'
                            values={{label: logEntryDataStore.selectedBaseTariff, value: logEntryDataStore.selectedBaseTariff}}
                            placeholder="Select"
                            options={[{value:'Select', label:'Select'},...logEntryDataStore.baseTariffDropdownOption]}
                            onChange={this.changeBaseTariff}
                            isMulti={false}
                            isSearchable={true}
                            disabled={logEntryDataStore.readonlyView}
                          />
                          {/* <input
                            type="text"
                            className="search_text_field input"
                            value={logEntryDataStore.baseTariffData[this.state.rowId]?.baseTariff}
                            readOnly={true}
                          /> */}
                        </Col>
                        <Col>
                          <label className="standard_label_style">Tariff Type</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={logEntryDataStore.selectedBaseTariffDetails.length > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffType : ''}
                            readOnly={true}
                          />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Jurisdiction</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            readOnly={true}
                            value={logEntryDataStore.selectedBaseTariffDetails.length > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.jurisdiction: ''}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Rules & Regulations</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={logEntryDataStore.selectedBaseTariffDetails.length > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.rulesReg : ''}
                            readOnly={true}
                          />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Tariff Agency</label>
                          <input
                            type="text"
                            className='search_text_field agencyInput'
                            readOnly={true}
                            value={logEntryDataStore.selectedBaseTariffDetails.length > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.agencyName : ''}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Filing Carrier</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={logEntryDataStore.selectedBaseTariffDetails.length > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.filingCarrier : ''}
                            readOnly={true}
                          />
                        </Col>
                        <Col className='colPipelineArea'>
                          <label className="standard_label_style">Pipeline Area</label>
                          <textarea
                            value={logEntryDataStore.selectedBaseTariffDetails.length > 0 ? logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.pipeline : ''}
                            className="search_text_field textAreaPipelineArea"
                            readOnly={true}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row style={{ 'marginTop': '20px' }}>
                        <AgGridComponent gridConfig={this.getGridConfigJointCarrier('Log Entry Joint Carrier')} />
                      </Row>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {this.renderLogEntryAndVMACSSetupView(logEntryDataStore)}
              <Accordion.Item eventKey="2">
                <Accordion.Header>Attachments</Accordion.Header>
                <Accordion.Body>
                  <div className='flex tariffTeam'>
                    <div style={{ 'width': '100%' }}>
                      <Row>
                        <Attachments saveRoleAccess={!logEntryDataStore.isEditDisabled()} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.tariffLogDocID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.tariffLogDocID)} agGridService={logEntryDataStore.agGridServiceForAddAttachment} fileUploadGridData={logEntryDataStore.fileUploadGridData}  cellValueChangeMap={logEntryDataStore.cellValueChangeMapAttachment} updateRow={(rowData) => logEntryDataStore.updateRowAttachment(rowData)}></Attachments>
                         <Row>
                              {/* <div className="rightFlexBottom col">
                              {logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Tariff Filing'.toLowerCase() ? <CustomButton type={CustomButtonType.Submit}
                                  disabled={logEntryDataStore.isEditDisabled()}
                                  onClick={() => {this.saveLogEntry();}}>
                                  Save and Submit for Testing Determination
                                </CustomButton> :
                                <CustomButton type={CustomButtonType.Submit} onClick={() => { this.saveLogEntry(); }} disabled={logEntryDataStore.isEditDisabled()}>
                                  Save
                                </CustomButton>}
                                <CustomButton type={CustomButtonType.Submit} onClick={() => { this.cancelEditing() }}>
                                  Cancel
                                </CustomButton>
                              </div> */}
                            </Row>
                      </Row>
                      <Row>
                      </Row>
                    </div>
                  </div>
                 
                </Accordion.Body>
              </Accordion.Item>
              
            </Accordion>
            <WorkflowDetails datastore={logEntryDataStore} stepName={logEntryDataStore.workflowstepName} saveLogEntry={this.saveLogEntry} cancelEditing={this.cancelEditing} createNewLogEntry = {this.createNewIndexLogEntry} saveProjectName={this.saveProjectName} isSaveDisabled={this.isSaveDisabled} isCommentDisabled={this.isCommentDisabled}/>
            {logEntryDataStore.comment && <></>}
            {logEntryDataStore.workFlowDetailsDataGrid && <></>}
            {logEntryDataStore.statusOrHistory && <></>}
            {logEntryDataStore.selectedLogEntryType && <></>}
            {logEntryDataStore.stepDescription &&  <></>}   
            {logEntryDataStore.tariffLogId &&  <></>}   
            {logEntryDataStore.readonlyView && <></>}  
            {logEntryDataStore.workflowstepName && <></>}      
          </div>
        </Container>
    );
  }

  private renderLogEntryAndVMACSSetupView(logEntryDataStore: LogEntryDataStore) {
    //For now hiding tabs for joint carrier 
    //const tabsArrayForBaseTariff = logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Maintenance'.toLowerCase() ? [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff].concat(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails?.map(x => x.baseTariff)) : [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff];
    const tabsArrayForBaseTariff = [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff];
    return <Accordion.Item eventKey="1">
      <Accordion.Header>Log Entry</Accordion.Header>
      <Accordion.Body>
        <Tabs
          defaultActiveKey="0"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {tabsArrayForBaseTariff.map((x, index) => (
            <Tab eventKey={index} title={x}>
              {logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFilingIndex && this.renderLogEntryForIndexEntryType(x, logEntryDataStore)}
              {logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling && this.renderLogEntryForTariffFilingEntry(x, logEntryDataStore)}
              {logEntryDataStore.selectedLogEntryType === TariffLogEntry.Request && this.renderLogEntryForRequestlogEntryType(x, logEntryDataStore)}
              {logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance && this.renderLogEntryForMaintenanceEntryType(x, logEntryDataStore)}
              
              {(logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling || logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.TariffLogVMACSSetup) && <><hr></hr>
                <div className='flex tariffTeam'>
                  {/* <div className="rotatedText">
                    VMACS Setup
                  </div> */}
                  <div style={{ 'width': '100%' }}>
                    <Row>
                      <Col>
                        <div className="status-block">
                          <label className="standard_l]abel_style">Will it be tested</label>
                          <div className="status-block" aria-disabled={logEntryDataStore.isEditVMACSSetupDisabled()}>
                            <input
                              type="radio"
                              checked={this.state.editableFormData[x]?.tested === true}
                              onChange={() => logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ? this.onChangeEditableFormDataMaintenance('tested', true, x) : this.onChangeEditableFormData('tested', true, x)}
                              readOnly={logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ? logEntryDataStore.isEditMaintenanceLogDisabled() || logEntryDataStore.tariffLogId : logEntryDataStore.isEditVMACSSetupDisabled()  || ((logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling)  && (!logEntryDataStore.isLogEntrySaved || logEntryDataStore.workflowstepName !== 'Complete Test Determination')) || this.state.editableFormData[x]?.logStatus === TariffLogStatus.Complete}
                              disabled={logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ? logEntryDataStore.isEditMaintenanceLogDisabled() || logEntryDataStore.tariffLogId : logEntryDataStore.isEditVMACSSetupDisabled()  || ((logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling)  && (!logEntryDataStore.isLogEntrySaved || logEntryDataStore.workflowstepName !== 'Complete Test Determination')) || this.state.editableFormData[x]?.logStatus === TariffLogStatus.Complete} />Yes
                            <input
                              type="radio"
                              value={"No"}
                              checked={this.state.editableFormData[x]?.tested === false}
                              onChange={() =>  logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ? this.onChangeEditableFormDataMaintenance('tested', false, x) : this.onChangeEditableFormData('tested', false, x)}
                              readOnly={logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ? logEntryDataStore.isEditMaintenanceLogDisabled() || logEntryDataStore.tariffLogId : logEntryDataStore.isEditVMACSSetupDisabled() || ((logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling)  && (!logEntryDataStore.isLogEntrySaved || logEntryDataStore.workflowstepName !== 'Complete Test Determination')) || this.state.editableFormData[x]?.logStatus === TariffLogStatus.Complete}
                              disabled={logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ? logEntryDataStore.isEditMaintenanceLogDisabled() || logEntryDataStore.tariffLogId : logEntryDataStore.isEditVMACSSetupDisabled()  || ((logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling)  && (!logEntryDataStore.isLogEntrySaved || logEntryDataStore.workflowstepName !== 'Complete Test Determination')) || this.state.editableFormData[x]?.logStatus === TariffLogStatus.Complete} />No
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <label className="standard_label_style">Project Name</label>
                        <div style={{ 'marginLeft': '10px' }} aria-disabled={logEntryDataStore.isProjectNameDisabled() || (( this.state.editableFormData[x]?.logStatus !== TariffLogStatus.PendingProd && this.state.editableFormData[x]?.logStatus !== TariffLogStatus.PendingTest) ||  this.state.editableFormData[x]?.logStatus === TariffLogStatus.Complete || (logEntryDataStore.workflowstepName !== 'Link Project' && logEntryDataStore.lastCompletedtWorkflowstepName !== 'Link Project'))}>
                        <ReactSelect 
                            className='select'
                            values={{label: this.state.editableFormData[x]?.projectName, value: this.state.editableFormData[x]?.projectName}}
                            placeholder="Select"
                            options={[{value:'Select', label:'Select'}, ...logEntryDataStore.shownProjectNames]}
                            onChange={(e: any) => { e.value ?   logEntryDataStore.selectedLogEntryType === TariffLogEntry.Maintenance ? this.onChangeEditableFormDataMaintenance('projectName', e.value, x, true) : this.onChangeEditableFormData('projectName', e.value, x, true) : null}}
                            isMulti={false}
                            isSearchable={true}
                            disabled={logEntryDataStore.isProjectNameDisabled() || (( this.state.editableFormData[x]?.logStatus !== TariffLogStatus.PendingProd && this.state.editableFormData[x]?.logStatus !== TariffLogStatus.PendingTest) ||  this.state.editableFormData[x]?.logStatus === TariffLogStatus.Complete || (logEntryDataStore.selectedLogEntryType === TariffLogEntry.TariffFiling && logEntryDataStore.workflowstepName !== 'Link Project' && logEntryDataStore.lastCompletedtWorkflowstepName !== 'Link Project') ||  this.state.editableFormData[x]?.tested !== true)}
                          />
                          {/* <input type="text"
                            className= 'logStatus'
                            value={this.state.editableFormData[x]?.projectName}
                            //disabled={this.state.editableFormData[x]?.index === true || this.state.editableFormData[x]?.documentation === true}
                            style={{ 'width': '180px' }}
                            onClick={() => this.predictiveSelectOnClick()}
                            onChange={(e) => this.predictiveSelectOnChange(e, x)}
                            readOnly={logEntryDataStore.isProjectNameDisabled() || (( this.state.editableFormData[x]?.logStatus !== TariffLogStatus.PendingProd && this.state.editableFormData[x]?.logStatus !== TariffLogStatus.PendingTest) ||  this.state.editableFormData[x]?.logStatus === TariffLogStatus.Complete || logEntryDataStore.workflowstepName || logEntryDataStore.isProjectNameSaved)}
                          />
                          <div hidden={this.state.hiddenSelect} className="predictiveSelectContainer" id="predictiveSelectContainer">
                            {logEntryDataStore.shownProjectNames.map((item) => (
                              <div className="predictiveSelect" onClick={(e) => {
                                this.onChangeEditableFormData('projectName', item, x, true);
                              }} aria-disabled={logEntryDataStore.isEditDisabled()}>
                                {item}
                          </div>
                          ))}
                        </div> */}
                      </div>
                      </Col>
                    </Row>
                  </div>
                </div></>}
            </Tab>)
          )}
        </Tabs>
      </Accordion.Body>
    </Accordion.Item>;
  }

  private renderLogEntryForIndexEntryType(x: any, logEntryDataStore: LogEntryDataStore) {
    return <div className='flex tariffTeam'>
      {/* <div className="rotatedText">
        Log Entry
      </div> */}
      <div style={{ 'width': '100%' }}>
        <Row>
          <Col>
            <label className="standard_label_style">Tariff #</label>
            <input
              type="text"
              className="mainVersionInput"
              onChange={(e) => this.onChangeEditableFormData('baseTariffName', e.target.value, x)}
              value={this.state.editableFormData[x]?.baseTariffName ? this.state.editableFormData[x]?.baseTariffName : ''}
              readOnly={true} />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('tariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff ? this.state.editableFormData[x]?.tariff : ''}
              readOnly={true} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
              if(e.target.value.match(numbers) || e.target.value === '')
              this.onChangeEditableFormData('tariffLogVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogVersion ?  this.state.editableFormData[x]?.tariffLogVersion : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
                if(e.target.value.match(numbers) || e.target.value === '')
                this.onChangeEditableFormData('tariffLogSubVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogSubVersion ?  this.state.editableFormData[x]?.tariffLogSubVersion : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} 
            />
          </Col>
          {(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase()) && (<Col>
            <label className="standard_label_style">Log Status</label>
            <input
              type="text"
              className="logStatus"
              onChange={(e) => this.onChangeEditableFormData('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.logStatus ?  this.state.editableFormData[x]?.logStatus : ''}
              readOnly={true} />
          </Col>)}
          {(logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase()) && (<Col><div className="status-block">
            <label className="standard_label_style">New Tariff Filing</label>
            <div className="status-block">
              <input
                type="radio"
                value="true"
                onChange={() => this.onChangeEditableFormData('newTariffFilling', true, x)}
                checked={this.state.editableFormData[x]?.newTariffFilling === true}
                readOnly={logEntryDataStore.isEditDisabled()} 
                disabled={logEntryDataStore.isEditDisabled()}  />Yes
              <input
                type="radio"
                value="false"
                onChange={() => this.onChangeEditableFormData('newTariffFilling', false, x)}
                checked={this.state.editableFormData[x]?.newTariffFilling === false}
                readOnly={logEntryDataStore.isEditDisabled()} 
                disabled={logEntryDataStore.isEditDisabled()} />No
            </div>
          </div></Col>)}
         {(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">Tariff Issue Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('tariffIssueDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffIssueDate} disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>}
        </Row>
        <Row>
          {(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">Vmacs Tariff #</label>
            <input
              type="text"
              className="VmacsTariffInput"
              onChange={(e) => this.onChangeEditableFormData('vmacsTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff && this.state.editableFormData[x]?.tariffLogVersion ? `${this.state.editableFormData[x]?.tariff}.${this.state.editableFormData[x]?.tariffLogVersion}`: ''}
              readOnly={true} 
            />
          </Col>}
          {(logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">Description</label>
            <textarea
              className="search_text_field input descriptionInputBox description"
              onChange={(e) => this.onChangeEditableFormData('description', e.target.value, x)}
              value={this.state.editableFormData[x]?.description ?  this.state.editableFormData[x]?.description : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>}
          <Col>
            <Col>
                <div className="status-block">
                  <label className="standard_label_style">Will it be tested</label>
                  <div className="status-block" aria-disabled={logEntryDataStore.isEditVMACSSetupDisabled()}>
                    <input
                      type="radio"
                      value="true"
                      onChange={() => this.onChangeEditableFormData('tested', true, x)}
                      checked={this.state.editableFormData[x]?.tested && this.state.editableFormData[x]?.tested === true}
                      readOnly={logEntryDataStore.isEditVMACSSetupDisabled()}
                      disabled={logEntryDataStore.isEditVMACSSetupDisabled() || (logEntryDataStore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFilingIndex.toLowerCase())} />Yes
                    <input
                      type="radio"
                      value='false'
                      checked={this.state.editableFormData[x]?.tested === false}
                      onChange={() => this.onChangeEditableFormData('tested', false, x)}
                      readOnly={logEntryDataStore.isEditVMACSSetupDisabled()}
                      disabled={logEntryDataStore.isEditVMACSSetupDisabled() || (logEntryDataStore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFilingIndex.toLowerCase())} />No
                  </div>
                </div>
            </Col>
            <Col>
            </Col>
                
          </Col>
          
          {(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) &&  <Col>
            <label className="standard_label_style">Tariff Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('tariffEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffEffectiveDate} disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>}
        </Row>
        <Row>
        {(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) ? <Col>
            <label className="standard_label_style">Cancels</label>
            <div>
            <input
              type="text"
              className="mainVersionInput"
              onClick={() => this.predictiveSelectOnClick('cancels')}
              onChange={(e) => this.predictiveSelectOnChangeCancel(e, x)}
              // onChange={(e) => this.onChangeEditableFormData('cancelBaseTariffName', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelBaseTariffName ?  this.state.editableFormData[x]?.cancelBaseTariffName : ''}
              readOnly={true}
             //  readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
             // disabled={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
             {/* <div hidden={this.state.hiddenCancelSelect} className="predictiveSelectContainer" id="predictiveSelectContainerCancel">
                          {logEntryDataStore.shownCancelVersion.map((item) => (
                            <div className="predictiveSelect" onClick={(e) => {
                              this.onChangeEditableFormData('cancels', item, x, true);
                              const cancelTariff = item.split(' ');
                              if(cancelTariff && cancelTariff.length > 0) {
                                this.state.editableFormData[x].cancelBaseTariffName = `${cancelTariff[0]} ${cancelTariff[1]}`;
                                this.state.editableFormData[x].cancelTariff = cancelTariff[2];
                                this.state.editableFormData[x].cancelTariffLogVersion = cancelTariff[3];
                                this.state.editableFormData[x].cancelTariffLogSubVersion = cancelTariff[4];
                                this.setState({
                                  editableFormData: this.state.editableFormData
                                });
                              }
                            }} aria-disabled={logEntryDataStore.isEditDisabled()}>
                              {item}
                        </div>
                      ))}
                    </div> */}
             </div>
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariff ?  this.state.editableFormData[x]?.cancelTariff : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariffLogVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogVersion ? this.state.editableFormData[x]?.cancelTariffLogVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariffLogSubVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogSubVersion ?  this.state.editableFormData[x]?.cancelTariffLogSubVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)} 
            />
          </Col>: <Col><div className="status-block"></div></Col>}
          {(logEntryDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase()) ? (<Col>
            <label className="standard_label_style">Log Status</label>
            <input
              type="text"
              className="logStatus"
              onChange={(e) => this.onChangeEditableFormData('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.logStatus}
              readOnly={true} />
            {/* <label className="standard_label_style">Legal Version</label>
      <input
        type="text"
        className="search_text_field input"
        onChange={(e) => this.onChangeEditableFormData('legalVersion', e.target.value, x)}
        value={this.state.editableFormData[x]?.legalVersion}
        readOnly={logEntryDataStore.isEditDisabled()}
      /> */}
          </Col>) : <Col> 
            <label className="standard_label_style">New Version</label>
            <input
              type="text"
              className="search_text_field input"
              onChange={(e) => this.onChangeEditableFormData('version', e.target.value, x)}
              value={this.state.editableFormData[x]?.newVersion ? this.state.editableFormData[x]?.newVersion : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFilingIndex.toLowerCase()}
            />
          </Col>}
          {(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">CTS Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('ctsEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.ctsEffectiveDate} disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>}
        </Row>
       {(logEntryDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) ? <Row>
          <Col>
            <label className="standard_label_style">Description</label>
            <textarea
              className="search_text_field input descriptionInputBox"
              onChange={(e) => this.onChangeEditableFormData('description', e.target.value, x)}
              value={this.state.editableFormData[x]?.description ? this.state.editableFormData[x]?.description : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>
          <Col>
            {/* <div className="status-block">
    <label className="standard_label_style">Vmacs Impact</label>
    <div className="status-block">
      <input
        type="radio"
        onChange={() => this.onChangeEditableFormData('vmacsImpact', true, x)}
        checked={this.state.editableFormData[x]?.vmacsImpact === true}//onChange = {(e) => this.onSelectStatus(e)}
        readOnly={logEntryDataStore.isEditDisabled()}
      />Yes
      <input
        type="radio"
        onChange={() => this.onChangeEditableFormData('vmacsImpact', false, x)}
        checked={this.state.editableFormData[x]?.vmacsImpact === false}
        readOnly={logEntryDataStore.isEditDisabled()}
      />No
    </div>
  </div> */}
          </Col>
          <Col></Col>
        </Row> :
        <div style ={{marginTop : '20px'}}>
          <Row>
            <Col>
              <div>
                <Col><label className="standard_label_style">CTS Effective Date</label>
                <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('ctsEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.ctsEffectiveDate} disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
                </Col>
              </div>
            </Col>
          </Row>
        <Row>
          <Col>
          <label className="standard_label_style">Selection</label>
          <div style={{width: '70%'}}>
            <AgGridComponent gridConfig={this.getGridConfigForLogEntrySelection(this.state.editableFormData[x]?.maintenanceSelectionVersionData, 'Log Entry Selection')}/>
          </div>
          </Col>
          </Row>
          </div>
          }
        <Row>
          <Col>
          </Col>
          <Col>
            {/* <div className="status-block">
    <label className="standard_label_style">Documentation</label>
    <div className="status-block">
      <input
        type="radio"
        onChange={() => this.onChangeEditableFormData('documentation', true, x)}
        checked={this.state.editableFormData[x]?.documentation === true}
        readOnly={logEntryDataStore.isEditDisabled()}
      />Yes
      <input
        type="radio"
        checked={this.state.editableFormData[x]?.documentation === false}
        onChange={() => this.onChangeEditableFormData('documentation', false, x)}
        readOnly={logEntryDataStore.isEditDisabled()}
      />No
    </div>
  </div> */}
          </Col>
          <Col></Col>
        </Row>
        <Row>
          {/* <div className="rightFlexBottom col">
            <CustomButton type={CustomButtonType.Submit} onClick={() => { this.saveLogEntry(); }} disabled={logEntryDataStore.isEditDisabled()}>
    Save
  </CustomButton>
  <CustomButton type={CustomButtonType.Submit}
    disabled={(this.state.editableFormData[x]?.index === true || this.state.editableFormData[x]?.documentation === true) || logEntryDataStore.isEditDisabled()}
    onClick={() => { console.log('1'); }}>
    Submit for Testing Determination
  </CustomButton>
          </div> */}

        </Row>
      </div>
    </div>;
  }

  private renderLogEntryForTariffFilingEntry(x: any, logEntryDataStore: LogEntryDataStore) {
    return <div className='flex tariffTeam'>
      {/* <div className="rotatedText">
        Log Entry
      </div> */}
      <div style={{ 'width': '100%' }}>
        <Row>
          <Col>
            <label className="standard_label_style">Tariff #</label>
            <input
              type="text"
              className="mainVersionInput"
              onChange={(e) => this.onChangeEditableFormData('baseTariffName', e.target.value, x)}
              value={this.state.editableFormData[x]?.baseTariffName ?  this.state.editableFormData[x]?.baseTariffName: ''}
              readOnly={true} />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('tariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff ?  this.state.editableFormData[x]?.tariff : ''}
              readOnly={true} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
              if(e.target.value.match(numbers) || e.target.value === '')
              this.onChangeEditableFormData('tariffLogVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogVersion ?  this.state.editableFormData[x]?.tariffLogVersion : ''}
              readOnly={logEntryDataStore.isEditDisabled()|| logEntryDataStore.readonlyView} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
                if(e.target.value.match(numbers) || e.target.value === '')
                this.onChangeEditableFormData('tariffLogSubVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogSubVersion ? this.state.editableFormData[x]?.tariffLogSubVersion : ''}
              readOnly={true} 
            />
          </Col>
          <Col>
            <label className="standard_label_style">Log Status</label>
            <input
              type="text"
              className="logStatus"
              onChange={(e) => this.onChangeEditableFormData('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.logStatus ?  this.state.editableFormData[x]?.logStatus : ''}
              readOnly={true} />
          </Col>
         <Col>
            <label className="standard_label_style">Tariff Issue Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('tariffIssueDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffIssueDate} disabled={logEntryDataStore.isEditDisabled()|| logEntryDataStore.readonlyView} />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="standard_label_style">Vmacs Tariff #</label>
            <input
              type="text"
              className="VmacsTariffInput"
              onChange={(e) => this.onChangeEditableFormData('vmacsTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff && this.state.editableFormData[x]?.tariffLogVersion ? `${this.state.editableFormData[x]?.tariff}.${this.state.editableFormData[x]?.tariffLogVersion}` : ''}
              readOnly={true} 
            />
          </Col>
          <Col></Col>          
         <Col>
            <label className="standard_label_style">Tariff Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('tariffEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffEffectiveDate} disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="standard_label_style">Cancels</label>
            <div>
            <input
              type="text"
              className="mainVersionInput"
              onClick={() => this.predictiveSelectOnClick('cancels')}
              onChange={(e) => this.predictiveSelectOnChangeCancel(e, x)}
              value={this.state.editableFormData[x]?.cancelBaseTariffName ? this.state.editableFormData[x]?.cancelBaseTariffName : ''}
              readOnly={true}
            />
            </div>
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariff ? this.state.editableFormData[x]?.cancelTariff : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariffLogVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogVersion ? this.state.editableFormData[x]?.cancelTariffLogVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariffLogSubVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogSubVersion ? this.state.editableFormData[x]?.cancelTariffLogSubVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)} 
            />
          </Col>
          <Col> 
            <label className="standard_label_style">Version</label>
            <input
              type="text"
              className="search_text_field input"
              onChange={(e) => this.onChangeEditableFormData('version', e.target.value, x)}
              value={this.state.editableFormData[x]?.newVersion ? this.state.editableFormData[x]?.newVersion : ''}
              readOnly={true}
            />
          </Col>
          <Col>
            <label className="standard_label_style">CTS Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('ctsEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.ctsEffectiveDate} disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>
        </Row>
       <Row>
          <Col>
            <label className="standard_label_style">Description</label>
            <textarea
              className="search_text_field input descriptionInputBox"
              onChange={(e) => this.onChangeEditableFormData('description', e.target.value, x)}
              value={this.state.editableFormData[x]?.description ? this.state.editableFormData[x]?.description : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>
          <Col>
           
          </Col>
          <Col></Col>
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </div>
    </div>;
  }

  private renderLogEntryForRequestlogEntryType(x: any, logEntryDataStore: LogEntryDataStore) {
    return <div className='flex tariffTeam'>
      <div style={{ 'width': '100%' }}>
        <Row>
          <Col>
            <label className="standard_label_style">Tariff #</label>
            <input
              type="text"
              className="mainVersionInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('baseTariffName', e.target.value, x)}
              value={this.state.editableFormData[x]?.baseTariffName ? this.state.editableFormData[x]?.baseTariffName : ''}
              readOnly={true} />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('tariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff ? this.state.editableFormData[x]?.tariff : ''}
              readOnly={true} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
              if(e.target.value.match(numbers) || e.target.value === '')
              this.onChangeEditableFormDataRequestAndMaintenance('tariffLogVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogVersion ? this.state.editableFormData[x]?.tariffLogVersion : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
                if(e.target.value.match(numbers) || e.target.value === '')
                this.onChangeEditableFormDataRequestAndMaintenance('tariffLogSubVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogSubVersion ? this.state.editableFormData[x]?.tariffLogSubVersion : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} 
            />
          </Col>
          <Col>
            <label className="standard_label_style">Log Status</label>
            <input
              type="text"
              className="logStatus"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.logStatus ? this.state.editableFormData[x]?.logStatus : ''}
              readOnly={true} />
          </Col>
          
          <Col>
            <label className="standard_label_style">Tariff Issue Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormDataRequestAndMaintenance('tariffIssueDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffIssueDate} disabled={true} />
          </Col>
        </Row>
        <Row>
          <Col>
            <label className="standard_label_style">Vmacs Tariff #</label>
            <input
              type="text"
              className="VmacsTariffInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('vmacsTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff && this.state.editableFormData[x]?.tariffLogVersion ? `${this.state.editableFormData[x]?.tariff}.${this.state.editableFormData[x]?.tariffLogVersion}` : ''}
              readOnly={true} 
            />
          </Col>
          <Col><div className="status-block">
            <label className="standard_label_style">New Tariff Filing</label>
            <div className="status-block">
              <input
                type="radio"
                value="true"
                onChange={() => this.onChangeEditableFormDataRequestAndMaintenance('newTariffFilling', true, x)}
                checked={this.state.editableFormData[x]?.newTariffFilling === true}
                readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView}
                disabled= {logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />Yes
              <input
                type="radio"
                value="false"
                onChange={() => this.onChangeEditableFormDataRequestAndMaintenance('newTariffFilling', false, x)}
                checked={this.state.editableFormData[x]?.newTariffFilling === false}
                readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView}
                disabled= {logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />No
            </div>
          </div></Col>
          <Col>
            <label className="standard_label_style">Tariff Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormDataRequestAndMaintenance('tariffEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffEffectiveDate} disabled={true} />
          </Col>
        </Row>
        <Row>
        <Col>
            <label className="standard_label_style">Cancels</label>
            <div>
            <input
              type="text"
              className="mainVersionInput"
              onClick={() => this.predictiveSelectOnClick('cancels')}
              onChange={(e) => this.predictiveSelectOnChangeCancel(e, x)}
              value={this.state.editableFormData[x]?.cancelBaseTariffName ? this.state.editableFormData[x]?.cancelBaseTariffName : ''}
              readOnly={true}
            />
             </div>
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('cancelTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariff ? this.state.editableFormData[x]?.cancelTariff : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('cancelTariffLogVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogVersion ? this.state.editableFormData[x]?.cancelTariffLogVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('cancelTariffLogSubVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogSubVersion ? this.state.editableFormData[x]?.cancelTariffLogSubVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)} 
            />
          </Col>
          <Col> 
            <label className="standard_label_style">New Version</label>
            <input
              type="text"
              className="search_text_field input"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('version', e.target.value, x)}
              value={this.state.editableFormData[x]?.newVersion}
              readOnly={true}
            />
          </Col>
          <Col>
            <label className="standard_label_style">CTS Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormDataRequestAndMaintenance('ctsEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.ctsEffectiveDate} disabled={true} />
          </Col>
        </Row>
       <Row>
          <Col>
            <label className="standard_label_style">Description</label>
            <textarea
              className="search_text_field input descriptionInputBox"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('description', e.target.value, x)}
              value={this.state.editableFormData[x]?.description ? this.state.editableFormData[x]?.description : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
        <Row>
          {dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.TariffLogVMACSSetup) && <Col>
        <Col>
       
                {/* <div className="rotatedText">
                  VMACS Setup
                </div> */}
                <div style={{ 'width': '100%', 'marginTop' : '40px' }}>
                  <Row>
                   <Col>
                      <label className="standard_label_style">Project Name</label>
                      <div style={{ 'marginLeft': '10px' }} aria-disabled={logEntryDataStore.isEditVMACSSetupDisabled()}>
                      <ReactSelect 
                            className='select'
                            values={{label: this.state.editableFormData[x]?.projectName, value: this.state.editableFormData[x]?.projectName}}
                            placeholder="Select"
                            options={[{value:'Select', label:'Select'}, ...logEntryDataStore.shownProjectNames]}
                            onChange={(e: any) => { e.value ?  this.onChangeEditableFormDataRequestAndMaintenance('projectName', e.value, x, true) : null}}
                            isMulti={false}
                            isSearchable={true}
                            disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView}
                          />
                        {/* <input type="text"
                          className= 'logStatus'
                          value={this.state.editableFormData[x]?.projectName}
                          //disabled={this.state.editableFormData[x]?.index === true || this.state.editableFormData[x]?.documentation === true}
                          style={{ 'width': '180px' }}
                          onClick={() => this.predictiveSelectOnClick()}
                          onChange={(e) => this.predictiveSelectOnChange(e, x)}
                          readOnly={logEntryDataStore.isEditVMACSSetupDisabled()}
                        />
                        <div hidden={this.state.hiddenSelect} className="predictiveSelectContainer" id="predictiveSelectContainer">
                          {logEntryDataStore.shownProjectNames.map((item) => (
                            <div className="predictiveSelect" onClick={(e) => {
                              this.onChangeEditableFormData('projectName', item, x, true);
                            }} aria-disabled={logEntryDataStore.isEditDisabled()}>
                              {item}
                        </div>
                      ))}
                    </div> */}
                  </div>
                </Col> 
                </Row>
                </div>
        </Col></Col>}
        </Row>
      </div>
    </div>;
  }

  private renderLogEntryForMaintenanceEntryType(x: any, logEntryDataStore: LogEntryDataStore) {
    return <div className='flex tariffTeam'>
      {/* <div className="rotatedText">
        Log Entry
      </div> */}
      <div style={{ 'width': '100%' }}>
        <Row>
          <Col>
           <label className="standard_label_style">Tariff #</label> 
           <ReactSelect
                className='VmacsTariffInput'
                values={this.state.editableFormData[x]?.tariffNumber!=='' && {label:this.state.editableFormData[x]?.tariffNumber, value: this.state.editableFormData[x]?.tariffNumber} || ''}
                placeholder={'Select Tariff Log'}
                options={Array.isArray(logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs) && logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.length && logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.map(option=> {return{value: option.tariffNumber, label: option.tariffNumber + '   ' + option.tariffLogVersion }}).reverse() ||  []}
                onChange={(e)=>this.onChangeEditableFormDataMaintenance('tariffNumber', (e as Option).value, x, false, (e as Option).label)}
                isMulti={false}
                isSearchable={true}
                disabled={logEntryDataStore.selectedBaseTariff==='' || logEntryDataStore.readonlyView || logEntryDataStore.isEditMaintenanceLogDisabled() }
            />
              {/* <input
              type="text"
              className="mainVersionInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('baseTariffName', e.target.value, x)}
              value={this.state.editableFormData[x]?.baseTariffName ? this.state.editableFormData[x]?.baseTariffName : ''}
              readOnly={true} />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataRequestAndMaintenance('tariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff ? this.state.editableFormData[x]?.tariff : ''}
              readOnly={true} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
              if(e.target.value.match(numbers) || e.target.value === '')
              this.onChangeEditableFormDataRequestAndMaintenance('tariffLogVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogVersion ? this.state.editableFormData[x]?.tariffLogVersion : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
                if(e.target.value.match(numbers) || e.target.value === '')
                this.onChangeEditableFormDataRequestAndMaintenance('tariffLogSubVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogSubVersion ? this.state.editableFormData[x]?.tariffLogSubVersion : ''}
              readOnly={true} 
            /> */}
            
          </Col>
         <Col>
            <label className="standard_label_style">Log Status</label>
            <input
              type="text"
              className="logStatus"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.logStatus ? this.state.editableFormData[x]?.logStatus : ''}
              readOnly={true} />
          
          </Col>
          <Col>
            <label className="standard_label_style">Tariff Issue Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormDataMaintenance('tariffIssueDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffIssueDate && new Date(this.state.editableFormData[x]?.tariffIssueDate)||null} disabled={true} />
          </Col>
        </Row>
        <Row>
         <Col>
            <label className="standard_label_style">Vmacs Tariff #</label>
            <input
              type="text"
              className="VmacsTariffInput"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('vmacsTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.vmacsTariffNumber}
              readOnly={true} 
            />
          </Col>
          <Col>
            <label className="standard_label_style">Current Version</label>
            <input
              type="text"
              className="search_text_field input"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.currentVersion ? this.state.editableFormData[x]?.currentVersion : ''}
              readOnly={true} />
          </Col>
          <Col>
            <label className="standard_label_style">Tariff Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormDataMaintenance('tariffEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffEffectiveDate && new Date(this.state.editableFormData[x]?.tariffEffectiveDate) || null} disabled={true} />
          </Col>
        </Row>
        <Row>
        <Col>
            <label className="standard_label_style">Cancels</label>
            <div>
            <input
              type="text"
              className="mainVersionInput"
              onClick={() => this.predictiveSelectOnClick('cancels')}
              onChange={(e) => this.predictiveSelectOnChangeCancel(e, x)}
              value={this.state.editableFormData[x]?.cancelBaseTariffName ?  this.state.editableFormData[x]?.cancelBaseTariffName : ''}
              readOnly={true}
            />
             </div>
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('cancelTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariff ?  this.state.editableFormData[x]?.cancelTariff : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('cancelTariffLogVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogVersion ?  this.state.editableFormData[x]?.cancelTariffLogVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('cancelTariffLogSubVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogSubVersion ?  this.state.editableFormData[x]?.cancelTariffLogSubVersion : ''}
              readOnly={true}
              // readOnly={this.props.logEntryDataStore.isCancelsFieldDiscabled(this.state.editableFormData[x]?.newTariffFilling)} 
            />
          </Col>
        <Col> 
            <label className="standard_label_style">New Version</label>
            <input
              type="text"
              className="search_text_field input"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('version', e.target.value, x)}
              value={this.state.editableFormData[x]?.newVersion ? this.state.editableFormData[x]?.newVersion : ''}
              readOnly={true}
            />
          </Col>
          <Col>
            <label className="standard_label_style">CTS Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormDataMaintenance('ctsEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.ctsEffectiveDate && new Date(this.state.editableFormData[x]?.ctsEffectiveDate)||null} disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>
        </Row>
       <Row>
          <Col>
            <label className="standard_label_style">Description</label>
            <textarea
              className="search_text_field input descriptionInputBox"
              onChange={(e) => this.onChangeEditableFormDataMaintenance('description', e.target.value, x)}
              value={this.state.editableFormData[x]?.description ? this.state.editableFormData[x]?.description : ''}
              readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />
          </Col>
          {/* <Col>
          <label className="standard_label_style">Filed</label>
          <input
            type="radio"
            checked={this.state.editableFormData[x]?.fiied === true}
            onChange={() => this.onChangeEditableFormData('fiied', true, x)}
            readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView}
            disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />Yes
          <input
            type="radio"
            value={"No"}
            checked={this.state.editableFormData[x]?.fiied === false}
            onChange={() => this.onChangeEditableFormData('fiied', false, x)}
            readOnly={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView}
            disabled={logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView} />No
                          
          </Col> */}
          <Col></Col>
          <Col>
            {/* <label className="standard_label_style">CTS Cancel Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormDataRequestAndMaintenance('ctsEffectiveDate', e, x); } } selectedDate={this.findTariffLogDetail(x)?.ctsEffectiveDate && new Date(this.findTariffLogDetail(x)?.ctsEffectiveDate)||null} disabled={true} /> */}
          </Col>
        </Row>
        <Row>
          <Col>
          </Col>
          <Col>

       
          </Col>
          <Col></Col>
        </Row>
        <Row>

        </Row>
      </div>
    </div>;
  }

  getWorkFlowDetailsGridConfig(gridId) {
    const {
        logEntryDataStore: { agGridServiceForWorkflowDetails }
    } = this.props;
    const { logEntryDataStore } = this.props;
    const rowData = logEntryDataStore.workFlowDetailsDataGrid;
    const gridParams: any = {
      rowData,
      columnDefs: gridConfig,
      };
    return agGridServiceForWorkflowDetails.getGridConfig(gridParams, gridId);
  }


  getGridConfigForLogEntrySelection(rowData, gridId) {
    const {
      logEntryDataStore: { fileUploadGridData, agGridServiceForVersionSelection }
    } = this.props;
    const onCellEditingStarted = this.onCellEditingStarted;
    const onCellEditingStopped = this.onCellEditingStopped;
    const tariffLogGridParams: any = {
      rowData: rowData,
      columnDefs: this.logEntrySelectionGridColDef,
      onCellEditingStarted,
      onCellEditingStopped,
      context : agGridServiceForVersionSelection
    };
    return agGridServiceForVersionSelection.getGridConfig(tariffLogGridParams, gridId);
  }

  onCellEditingStarted = (event: CellEditingStartedEvent): void => {
    const row = event.data.tariffLogID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
        logEntryDataStore: { cellValueChangeMap }
    } = this.props;
    const isNotEmpty =
        row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
    if (!isNotEmpty) {
        this.props.logEntryDataStore.setValueInChangeMap(row, col, value, value);
    }
  };

  onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
    const row = event.data.tariffLogID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
      logEntryDataStore: { cellValueChangeMap}
    } = this.props;
    cellValueChangeMap[row][col]['currentValue'] = value;
    this.updateRow(event.data);
    this.props.logEntryDataStore.agGridService.getNodes()?.refreshCells();
  };

  @action
  updateRow = (selectedRowData: any): void => {
    const tabBaseTariff = [logEntryDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff]
      const updatedShopIndex = this.state.editableFormData[tabBaseTariff[0]]?.maintenanceSelectionVersionData.findIndex(a => a.tariffLogID == selectedRowData.tariffLogID);
      if (updatedShopIndex && !_.isEqual(this.state.editableFormData[tabBaseTariff[0]]?.maintenanceSelectionVersionData[updatedShopIndex], selectedRowData)) {        
          this.mapEditableColumns(this.state.editableFormData[tabBaseTariff[0]]?.maintenanceSelectionVersionData[updatedShopIndex], selectedRowData);
          dataStore.setShowUnsavedWarningAlert(true);
      }
      this.setState({
        editableFormData: this.state.editableFormData
      })
         
  };

  mapEditableColumns(currentItem: any, updatedItem: any) {
      currentItem.newVersion = updatedItem.newVersion;
      currentItem.newCtsEffectiveDate = updatedItem.newCtsEffectiveDate;      
  }

  isSaveDisabled = () => {
    const {logEntryDataStore} = this.props;
    let data = { ...this.state.editableFormData };
    if(logEntryDataStore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFiling.toLowerCase()) {
      const disabled  = logEntryDataStore.workflowstepName && logEntryDataStore.workflowstepName === 'Complete Test Determination' ? logEntryDataStore.isEditVMACSSetupDisabled() : ((logEntryDataStore.workflowstepName && logEntryDataStore.workflowstepName === 'Link Project') || (logEntryDataStore.lastCompletedtWorkflowstepName && logEntryDataStore.lastCompletedtWorkflowstepName === 'Link Project')) ? logEntryDataStore.isEditDisabled() || logEntryDataStore.isProjectNameDisabled() : logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView ;
      return disabled;
    } else {
      if(logEntryDataStore.selectedLogEntryType.toLowerCase() === TariffLogEntry.Maintenance.toLowerCase()) {
        return logEntryDataStore.isEditMaintenanceLogDisabled() || logEntryDataStore.readonlyView;
      }
     
    }
    return logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView;
    
  }

  isCommentDisabled = () => {
    const {logEntryDataStore} = this.props;
    let data = { ...this.state.editableFormData };
    if(logEntryDataStore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFiling.toLowerCase()) {
      const disabled  = logEntryDataStore.workflowstepName && logEntryDataStore.workflowstepName === 'Complete Test Determination' ? logEntryDataStore.isEditVMACSSetupDisabled() : ((logEntryDataStore.workflowstepName && logEntryDataStore.workflowstepName === 'Link Project') || (logEntryDataStore.lastCompletedtWorkflowstepName && logEntryDataStore.lastCompletedtWorkflowstepName === 'Link Project')) ? logEntryDataStore.isEditDisabled() || logEntryDataStore.isProjectNameDisabled() : logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView ;
      return disabled;
    } else {
      if(logEntryDataStore.selectedLogEntryType.toLowerCase() === TariffLogEntry.Maintenance.toLowerCase()) {
        return logEntryDataStore.isEditMaintenanceLogDisabled() || logEntryDataStore.readonlyView;
      }
    }
    return logEntryDataStore.isEditDisabled() || logEntryDataStore.readonlyView;
  }
}
