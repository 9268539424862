import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import {
    ColDef
} from 'ag-grid-community';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import { AgGridComponent } from 'common/components/ag-grid-component';
import history from 'shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';


interface workflowDetailsInterface {
    datastore: any;
    stepName: string;
}

export const WorkflowDetails = (props: workflowDetailsInterface) => {

    const gridConfig: ColDef[] = [
    {
          headerName: 'Steps',
          field: 'workflowstepName',
          tooltipField: 'workflowstepName',
          filter: true,
          editable: false,
          sortable: true,
          minWidth: 150,
          singleClickEdit: true,
          flex: 2
      },
      {
          headerName: 'Status',
          field: 'workflowstepStatus',
          tooltipField: 'workflowstepStatus',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          singleClickEdit: true,
          suppressSizeToFit: true,
          flex: 1
      },
      {
          headerName: 'Comment',
          field: 'workflowStepComments',
          tooltipField: 'workflowStepComments',
          cellEditor: 'agSelectCellEditor',
          singleClickEdit: true,
          filter: true,
          sortable: true,
          editable: false,
          minWidth: 135,
          flex: 2
      },
      {
          headerName: 'Workflow Step Role',
          field: 'workflowstepRole',
          tooltipField: 'workflowstepRole',
          singleClickEdit: true,
          editable: false,
          minWidth: 120,
          suppressSizeToFit: false,
      },
      {
          headerName: 'Last Modified User *',
          field: 'lastModifiedUser',
          tooltipField: 'lastModifiedUser',
          singleClickEdit: true,
          editable: false,
          minWidth: 120,
          suppressSizeToFit: false,
      },
      {
          headerName: 'Last Modified Date',
          field: 'lastModifiedDate',
          tooltipField: 'lastModifiedDate',
          filter: true,
          sortable: true,
          editable: false,
          minWidth: 120,
          valueGetter: params => {
              if (params.data['lastModifiedDate'] === null) {
                  return '';
              } else {
                  return momenttimezone
                  .tz(moment.utc(params.data['lastModifiedDate']), AppConstant.CST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
              }
          },
          suppressSizeToFit: false,
      }
  ];

  function getGridConfig(gridId) {
    const {
        datastore: { agGridService }
    } = props;
    const { datastore } = props;
    const rowData = datastore.workflowStatusGridData;
    const gridParams: any = {
      rowData,
      columnDefs: gridConfig,
      context : agGridService
      };
    return agGridService.getGridConfig(gridParams, gridId);
  }

  return (
        <div className='workflowDetails'>
            <div className='screenHeader'>
                <h2 className='screen_title'> Workflow Details</h2>
            </div>
            <div className='selectContainer'>
                <Row> 
                    <div style={{width: '50.2%', display: 'inline-flex'}}>  
                        <div className="leftFlex">
                            <label className="standard_label_style">{props.datastore.statusOrHistory?'Workflow Status':'Workflow History'}</label>
                        </div>
                        <div className="rightFlex">
                            <div className="rightFlexInner">
                                <CustomButton type={CustomButtonType.Submit} disabled={props.datastore.statusOrHistory} onClick={()=>{props.datastore.getWorkflowStatusGridData()}}>
                                Workflow Status
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} disabled={!props.datastore.statusOrHistory} onClick={()=>{props.datastore.getWorkflowHistoryStatusGridData()}}>
                                Workflow History
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                </Row>
                <div className="flex">
                <div className="innerflexwidths">
                    <Row>
                        <AgGridComponent gridConfig={getGridConfig('Workflow Details')}/>
                    </Row>
                </div>
                <div className="innerflexwidths">
                    <div className="flexColumn">
                        <div style={{'width':'100%'}}>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '10px'}}>
                                    <label className="standard_label_style">Step Description:</label>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '5px'}}>
                                <div style={{'fontSize': '14px', 'marginLeft': '20px'}}>{props.datastore.stepDescription&&props.datastore.stepDescription||''}</div>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '10px'}} >
                                    <label className="standard_label_style">Comment</label>
                                    <textarea
                                    style={{marginLeft: '20px', borderRadius: '5px', border: '1px solid #a3d0e4'}} 
                                    disabled={props.datastore?.workflowAction==='View'}
                                    value={props.datastore.formData.comment}
                                    onChange={(e)=>props.datastore.saveComment(e.target.value)}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="rightFlexButtons">
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{history.push('/TariffDashboard')}}>
                            &#9664; Cancel
                            </CustomButton>
                            {props.datastore?.stepName==='Process & Submit for Approval'&&props.datastore?.workflowAction==='Process' && (
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{props.datastore?.saveTariffOption()}}>
                            Save
                            </CustomButton>)}
                            {props.datastore?.workflowAction==='Process' && (props.datastore?.stepName==='Complete Analyst Review /Submit for Approval' ||props.datastore?.stepName==='Complete Tariff Approval') && (
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{props.datastore.saveWorkflowComments('reject')}}>
                            Reject
                            </CustomButton>)}
                            {props.datastore?.stepName==='Process & Submit for Approval'&&props.datastore?.workflowAction==='Process' && (
                            <CustomButton type={CustomButtonType.Submit} disabled={!props.datastore?.saved || props.datastore.isWorkflowStepSaveDisabled(props.datastore?.stepName)} onClick={()=>{props.datastore?.saveWorkflowComments()}}>
                            Process and Submit for Approval
                            </CustomButton>)}
                            {props.datastore?.stepName==='Complete Analyst Review /Submit for Approval'&&props.datastore?.workflowAction==='Process' && (
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{props.datastore?.saveWorkflowComments()}} disabled={props.datastore.isWorkflowStepSaveDisabled(props.datastore?.stepName)}>
                            Complete Analyst Review /Submit for Approval
                            </CustomButton>)}
                            {props.datastore?.stepName==='Complete Tariff Approval'&&props.datastore?.workflowAction==='Process' && (
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{props.datastore?.saveWorkflowComments()}} disabled={props.datastore.isWorkflowStepSaveDisabled(props.datastore?.stepName)}>
                            Complete Tariff Approval
                            </CustomButton>)}
                            {/* {props.datastore?.stepName==='Tariff Approval'&&props.datastore?.workflowAction==='Process' && (
                            <CustomButton type={CustomButtonType.Submit}  onClick={()=>{props.datastore?.sendEmailWorkflow('approve')}}>
                            Tariff Approval
                            </CustomButton>)} */}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}