import { action, observable, makeAutoObservable, toJS, computed } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { ProjectRequestDataService } from '../services/project-request-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import _ from 'lodash';

export class ProjectRequestDataStore {
  
    @observable projectRequestTypeDropdownOptions: any [] = [];
    @observable tariffRateTypesDropdownOptions: any [] = ['Public', 'Private'];
    @observable selectedProjectRequestType = '';
    @observable selectedTariffRateType = '';
    @observable username;
    @observable location;
    @observable workflowId;
    @observable workflowCreatorJurisdiction;
    @observable isFromWorkflowDashboard = false;
    @observable showLoader = false;
    agGridService = new AgGridService();
    
    constructor(
        public projectRequestDataService: ProjectRequestDataService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        console.log(this.accountStore.rolesJson[0]?.defaultLocation);
        this.getProjectTypesDropDownData(this.accountStore.rolesJson[0]?.defaultLocation);
        if(this.accountStore.rolesJson[0]?.defaultLocation==='CAN'){
            this.getPMCTariffRateTypes();
        }
        this.location = this.accountStore.rolesJson[0]?.defaultLocation;
        this.username = this.accountStore.displayName;
    }

    
    @action
    setProjectRequestType(value){
        this.selectedProjectRequestType=value
    }

    showOnlyUS = () => {
        return this.accountStore.rolesJson[0]?.defaultLocation==='US'
    }
    
    @action
    setTariffRateType(value){
        this.selectedTariffRateType=value
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getProjectTypesDropDownData(defaultLocation): Promise<void> {
        const response = await this.projectRequestDataService.getProjectTypesDropDownData(defaultLocation);
        
        if(this.accountStore.rolesJson[0]?.defaultLocation==='CAN'){
            const sortedResponse =  _.orderBy(response, [x=>x.name], ['asc']);
            this.projectRequestTypeDropdownOptions = sortedResponse;  
            this.selectedProjectRequestType=sortedResponse[0].name; 
        }
        else
        {
            this.projectRequestTypeDropdownOptions = response;  
            this.selectedProjectRequestType=response[0].name; 
    
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPMCTariffRateTypes(): Promise<void> {
        const response = await this.projectRequestDataService.getPMCTariffRateTypes();
        const sortedResponse =  _.orderBy(response, [x=>x.valueText], ['desc']);
        this.tariffRateTypesDropdownOptions = sortedResponse;   
        this.selectedTariffRateType=sortedResponse[0].valueText;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async step0PostWorkflowDetails(): Promise<void> {
        const requestBody = {
            'workflowName': this.selectedProjectRequestType,
            'criteria': this.selectedTariffRateType,
            'createUser': this.username
        }          
        if(requestBody) {
            const response: any = await this.projectRequestDataService.step0PostWorkflowDetails(requestBody);
            console.log('step0PostWorkflowDetails', response);
            if(response){
                let error ='';
                for (const key in response) {                       
                    if (key==='value') {
                        error = response[key];
                    } else if (key==='statusCode' && response[key]===400) {
                        this.uiService.toastService.error(error);
                    } 
                }
                if(error===''){
                        this.workflowId=response.workflowId;
                        history.push('/project-request-step-1', {workflowId: response.workflowId, workflowStepId: response.workflowStepId, saved: false, selectedProjectRequestType: this.selectedProjectRequestType})
                }
            }       
        }
    }

    @action
    getDescription() {
        return this.projectRequestTypeDropdownOptions.find(x=>x.name===this.selectedProjectRequestType)?.description
    }
    
}