import { FilingEntityMaintenanceDataStore } from './filing-entity-maintenance-data-store';
import { filingEntityMaintenanceDataService } from '../services/filing-entity-maintenance-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { accountStore } from '../../../account/stores';
import { AddEditFilingEntityMaintenanceStore } from './add-edit-filing-entity-maintenance-data-store';

export const agGridServiceForAddOrEditLocation = new AgGridService();
export const agGridServiceForAddOrEditLinker = new AgGridService();

export const filingEntityMaintenanceDataStore = new FilingEntityMaintenanceDataStore(filingEntityMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLocation);
export const addEditFilingEntityMaintenanceDataStore = new AddEditFilingEntityMaintenanceStore(filingEntityMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLinker);