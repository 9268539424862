import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { connectionPointsDataColDef } from '../components/connection-point-col-def';
import moment from 'moment';
// import Path, { ConnectionPointsDBTableName } from '../../../../Path';
import axios from 'axios';
import history from '../../../../shared/components/header-component/component/history';
import { CPDataService, cpDataService } from '../services/cp-data-service';
import { ConnectionPointsResponse } from '../model/model';
import { ConnectionPointsMessage } from '../constants/enums';
import { connectionPointMaintenanceDataStore } from '.';
import { AccountStore } from '../../../account/stores/account-store';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';

export class ConnectionPointMaintenanceDataStore {
    @observable connectionPointGridData: ConnectionPointsResponse[] = [];
    @observable modifiedConnectionPointGridData: ConnectionPointsResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupConnectionPointsData: ConnectionPointsResponse[] = [];
    @observable isRefreshed = false;
    @observable showModal = false;
    @observable selectedBaseSystem = { value: 'All', label: 'All' };
    @observable selectedLocation = { value: 'All', label: 'All' };
    @observable selectedCPName = '';
    @observable selectedCPCode = '';
    @observable selectedCPType = { value: 'All', label: 'All' };
    @observable selectedCPStatus = { value: 'All', label: 'All' };
    @observable selectedActivityType = { value: 'All', label: 'All' };
    @observable selectedState = { value: 'All', label: 'All' };
    endDate: any = moment().toISOString().slice(0, 10);
    settingTypesData: any = [];
    @observable isActivateButtonDisabled = true;
    @observable baseSystemData: any = [];
    @observable baseSystemDropdownOption: any = [];
    @observable locationData: any = [];
    @observable locationDropdownOption: any = [];
    @observable cpTypeData : any = [];
    @observable cpTypeDropdownOption: any = [];
    @observable activityTypeData : any = [];
    @observable activityTypeDropdownOption: any = [];
    @observable tariffStatesData: any = [];
    @observable tariffStatesDropdownOption: any = [];
    @observable ctsStatusData: any = [];
    @observable ctsStatusDropdownOption : any = [];
    @observable isSearchClicked = false;


    constructor(
        public cpDataService: CPDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        dataStore.setShowUnsavedWarningAlert(false);
        this.selectedBaseSystem = { value: 'All', label: 'All' };
        this.selectedLocation = { value: 'All', label: 'All' };
        this.selectedCPName = '';
        this.selectedCPCode = '';
        this.selectedCPType = { value: 'All', label: 'All' };
        this.selectedCPStatus = { value: 'All', label: 'All' };
        this.selectedActivityType = { value: 'All', label: 'All' };
        this.selectedState = { value: 'All', label: 'All' };
        this.isSearchClicked= false;
        // this.settingNameOptions = [];
        this.getBaseSystemDropdown();
        this.getLocationDropdown();
        this.getCPTypeDropdown();
        this.getActivityTypeDropdown();
        this.getTariffStatesDropdown();
        this.getCTSStatus();
        // this.loadViewModel();
        // this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    async loadViewModel() {
        const pageSize = connectionPointMaintenanceDataStore.agGridService.paginationGetPageSize();
        if(dataStore.isPageSizeChanged) {
            connectionPointMaintenanceDataStore.agGridService.resetCache(Number(pageSize));
        }
        const dataSource = {
            getRows: (params) => {
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
                const pageSize = this.agGridService.paginationGetPageSize();
                const page =  params.endRow / (pageSize ? pageSize : 100);
                const pageNo = this.isRefreshed || dataStore.isPageSizeChanged || page <= 1 ? 1 : parseInt(page.toFixed(0));
                const data = this.getapiRequest(connectionPointMaintenanceDataStore.isSearchClicked, pageNo, pageSize, params.sortModel);
                
                const filterRequestBody: any = [];
                for (const [key, value] of Object.entries(params.filterModel)) {
                    console.log(key, value);
                    const item = {
                        propertyName: key,
                        value: value && value['filterType'] === 'date'? value['dateFrom'] : value ? value['filter']:'',
                        operator: value ? value['type'] : '',
                        joinPrev: 'none'
                    
                      }
                      filterRequestBody.push(item);
                  }
                  console.log('params' +JSON.stringify(filterRequestBody));
                
                this.agGridService.showLoadingOverlay();
                cpDataService.getCPGridData(data, filterRequestBody).then((response) => {
                    const totalCount = response.data && response.data.length > 0 ? response.data[0].totalCount : response.data.length
                    params.successCallback(response.data ? response.data : [], totalCount);
                    this.agGridService.hideOverlay();
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false);
                    dataStore.setCacheBlockSize(response.data? response.data.length : 0)
                }).catch(() => {
                    params.successCallback([], 0);
                    dataStore.setCacheBlockSize(0);
                    this.isRefreshed = false;
                    this.agGridService.hideOverlay();
                    dataStore.setPageSizeChanged(false);
                    errorHandler(ConnectionPointsMessage.FETCH_ERROR_MESSAGE)
                });
            }
        }
        this.agGridService.setDatasource(dataSource);
    }


    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.cpDataService.getBaseSystems();
        this.baseSystemData = baseSystemDropdownOptions;
        const a = (baseSystemDropdownOptions.map(x => x.plName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.baseSystemDropdownOption = option;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLocationDropdown(): Promise<void> {
        const locationDropdownOptions = await this.cpDataService.getLocationsData();
        this.locationData = locationDropdownOptions;
        const a = (locationDropdownOptions.map(x => x.locnName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.locationDropdownOption = option;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCPTypeDropdown(): Promise<void> {
        const cpTypeDropdownOption = await this.cpDataService.getCPTypesData();
        this.cpTypeData = cpTypeDropdownOption;
        const a = (cpTypeDropdownOption.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.cpTypeDropdownOption = option;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getActivityTypeDropdown(): Promise<void> {
        const activityTypeDropdownOption = await this.cpDataService.getActivityTypesData();
        this.activityTypeData = activityTypeDropdownOption;
        const a = (activityTypeDropdownOption.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.activityTypeDropdownOption = option;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffStatesDropdown(): Promise<void> {
        const tariffStatesDropdownOption = await this.cpDataService.getTariffStates();
        this.tariffStatesData = tariffStatesDropdownOption;
        const a = (tariffStatesDropdownOption.map(x => x.stateName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.tariffStatesDropdownOption = option;
        });
    }

    @Catch(() => errorHandler('Fetch Error'))
    async getCTSStatus(): Promise<void> {
        const ctsStatusOptions = await this.cpDataService.getCPStatus();
        this.ctsStatusData = ctsStatusOptions;
        const a = (ctsStatusOptions.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.ctsStatusDropdownOption = option;
        });
    }

    @Loader
    @Catch(() => errorHandler(ConnectionPointsMessage.FETCH_ERROR_MESSAGE))
    async onClearSelectSettingName(): Promise<void> {
        this.isSearchClicked = false;
        this.isRefreshed = true
        this.isActivateButtonDisabled = true;
        dataStore.onGridReady();  
        this.selectedBaseSystem = { value: 'All', label: 'All' };
        this.selectedLocation = { value: 'All', label: 'All' };
        this.selectedCPName = '';
        this.selectedCPCode = '';
        this.selectedCPType = { value: 'All', label: 'All' };
        this.selectedCPStatus = { value: 'All', label: 'All' };
        this.selectedActivityType = { value: 'All', label: 'All' };
        this.selectedState = { value: 'All', label: 'All' };
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @Catch(() => errorHandler(ConnectionPointsMessage.FETCH_ERROR_MESSAGE))
    async onSearchClick(currentPage, pageSize): Promise<void> {
        this.isSearchClicked = true;
        this.isRefreshed = true;
        this.isActivateButtonDisabled = true;
        dataStore.onGridReady();
        dataStore.setShowUnsavedWarningAlert(false); 
    }

    getColDef() {
        return connectionPointsDataColDef;
    }

    getapiRequest(searchClick, currentPage, pageSize, sortModel?) {
        if(searchClick) {
            const pipelineID = this.selectedBaseSystem.label === 'All' ? 0 : this.baseSystemData.filter((item) => item.plName === this.selectedBaseSystem.label)[0].pipelineID;
            const locationID = this.selectedLocation.label === 'All' ? 0 : this.locationData.filter((item) => item.locnName === this.selectedLocation.label)[0].locationID;
            const activityTypeID = this.selectedActivityType.label === 'All' ? 0 : this.activityTypeData.filter((item) => item.valueText === this.selectedActivityType.label)[0].settingID; 
            const stateID = this.selectedState.label === 'All' ? 0 : this.tariffStatesData.filter((item) => item.stateName === this.selectedState.label)[0].stateID;
            const cpStatusID = this.selectedCPStatus.label === 'All' ? 0 : this.ctsStatusData.filter((item) => item.valueText === this.selectedCPStatus.label)[0].settingID;
            const cpTypeID = this.selectedCPType.label === 'All' ? 0 :  this.cpTypeData.filter((item) => item.valueText === this.selectedCPType.label)[0].settingID;
            const data = {
                pipelineID: pipelineID, 
                locationID: locationID,
                cpName: this.selectedCPName,
                cpCode: this.selectedCPCode,
                cpType: cpTypeID,
                cpStatus: cpStatusID,
                activityTypeID: activityTypeID,
                stateID: stateID,
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        } else {
            const data = {
                pipelineID: 0, 
                locationID: 0,
                cpName: '',
                cpCode: '',
                cpType: 0,
                cpStatus: 0,
                activityTypeID: 0,
                stateID: 0,
                page: this.isSearchClicked ?  1 : currentPage,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        }
        
    }

    @action
    reset(): void {
        this.setConnectionPointsData(this.backupConnectionPointsData);
        this.modifiedConnectionPointGridData = this.backupConnectionPointsData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setShowLoader(showLoader): void {
        this.showLoader = showLoader;
    }

    @action
    setConnectionPointsData(connectionPointGridData: ConnectionPointsResponse[]): void {
        this.connectionPointGridData = connectionPointGridData
    }

    setbackupConnectionPointsData(backUpList: ConnectionPointsResponse[]) {
        this.backupConnectionPointsData = backUpList;
    }

    @computed
    get ConnectionPointsData() {
        return toJS(this.connectionPointGridData);
    }

    private addValuesInCellDropdowns(): void {
        console.log('');
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    isSaveDisabled = ()  => {
      
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    @action
    updateRow = (selectedRowData: any): void => {
        const updatedShopIndex = this.connectionPointGridData.findIndex(a => a.connectionPointID == selectedRowData.connectionPointID);
        if (!_.isEqual(this.backupConnectionPointsData[updatedShopIndex], selectedRowData)) {
            selectedRowData.syncStatus = selectedRowData.source === 'VMACS' ? SyncStatus[0] : selectedRowData.syncStatus;
            this.mapEditableColumns(this.connectionPointGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.additionalValue = updatedItem.additionalValue;
        currentItem.remarks = updatedItem.remarks;
        currentItem.settingName = updatedItem.settingName;
        currentItem.valueCode = updatedItem.valueCode;
        currentItem.valueText = updatedItem.valueText;
        currentItem.connectionPointID = updatedItem.connectionPointID;
        currentItem.syncStatus = updatedItem.syncStatus;
        currentItem.testSyncDate = updatedItem.testSyncDate;
        currentItem.prodSyncDate = updatedItem.prodSyncDate;
        currentItem.action = updatedItem.action;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
        currentItem.source = updatedItem.source;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }


    getUpdatedRows = () => {
        const updatedRowIDs = this.connectionPointGridData.filter(a => this.getUpdatedRowIDs().includes(a.connectionPointID.toString()));
        return updatedRowIDs;
    }

    @action 
    scrollToFocusTheNewCell() {
        this.agGridService.scrollToSelectedRow();
    }

    @action
    updateValueCodeColumn(data: any, value: string, colName: string): void { 
        const row = data.connectionPointID;
        const initValue = data[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        data.valueCode = value;
        this.updateRow(data);
    }

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }

    @Loader
    @action
    @Catch(() => errorHandler(ConnectionPointsMessage.FAILED_SUBMIT))
    async updateStatusFromDelToActive(): Promise<void> {
        const selectedRowIDs: any = await this.agGridService.getSelectedRows();
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        selectedRowIDs.forEach((item) => {
           requestBody.push(item.connectionPointID)
        });
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
            const response: ConnectionPointsResponse[] = await this.cpDataService.updateStatusFromDelToActive(requestBody, this.userName);
            // let errorInSave = false;
            // let errorSummary: ToastMessage[] = [];
            if (response) {
                this.isRefreshed = true;
                this.loadViewModel();  
            }
            this.cellValueChangeMap = {};
            this.uiService.toastService.success(ConnectionPointsMessage.SAVE);
            runInAction(() => {
                this.showLoader = false;
                this.isActivateButtonDisabled= true;
                dataStore.setShowUnsavedWarningAlert(false);
            })  
        }
    }

    @action
    openConnectonPointDetails(data) {
        history.push('/ConnectionPointDetails', {from: '/ConnectionPointMaintenance', data : data})
    }

 
}
