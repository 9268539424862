import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { projectDashboardDataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { WorkflowIdCellRenderer } from './cell-renderers/workFlowId-cell-renderer';

export const openRequestColDef: ColDef[] = [
    { 
        headerName: 'Workflow ID', 
        field: 'workflowID',
        colId: 'workflowID',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        width: 110, 
        valueGetter: params => {
            if (params.data['workflowID'] === null) {
                return '';
            } else {
                return params.data['workflowID'];
            }
        },
        cellRenderer: WorkflowIdCellRenderer
    },{ 
        headerName: 'Project Name', 
        field: 'projectName',
        colId: 'projectName',
        filter: true, 
        sortable: true, 
        minWidth: 120,
        singleClickEdit: true,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['projectName'] === null) {
                return '';
            } else {
                return params.data['projectName'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Project Type', 
        field: 'projectType', 
        colId: 'projectType',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['projectType'] === null) {
                return '';
            } else {
                return params.data['projectType'];
            }
        },
        minWidth: 300,
        flex: 1
    },
    { 
        headerName: 'Description', 
        field: 'description', 
        colId: 'description',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['description'] === null) {
                return '';
            } else {
                return params.data['description'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Workflow Step', 
        field: 'workflowStatus', 
        colId: 'workflowStatus',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 240,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['workflowStatus'] === null) {
                return '';
            } else {
                return params.data['workflowStatus'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Last Modified User', 
        field: 'updateUser', 
        colId: 'updateUser',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 130,
        valueGetter: params => {
            if (params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Last Modified Date',
        field: 'updateDate', 
        colId: 'updateDate',
        filter: true, 
        sortable: true,
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 120,
        valueGetter: params => {
            if (params.data['updateDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        } ,
        flex: 1
    }
];

