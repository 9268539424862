import { action, observable, makeAutoObservable } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { PipelineMaintenanceDataService } from '../services/pipeline-maintenance-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';


type LocationState = { state : { from: string; plname : string } };

export class PipelineMaintenanceDataStore {
  
    @observable pipelineMaintenanceData : any [] = [];
    @observable selectedSystemAreaType = { value: 'All', label: 'All' };
    @observable selectedSystemAreaName = '';
    @observable selectedPhysicalPipeline = { value: 'All', label: 'All' };
    @observable dropdownPhysicalPipeline : any [] = [];
    @observable pipelineTypes : any [] = [];
    @observable showLoader = false;
    @observable pipelineIdForEditing;
    @observable vmacsStatus;
    
    constructor(
        public pipelineMaintenanceDataService: PipelineMaintenanceDataService,
        public uiService: UiService,
        public agGridService: AgGridService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }
    
    

    @action
    init(): void {
        this.selectedSystemAreaType={ value: 'All', label: 'All' };
        const previousLocation = history?.location?.state as { from?: string, plname?: string }
        if(previousLocation?.from && previousLocation.from==='AddPipeline'){
            this.selectedSystemAreaName=previousLocation.plname || '';    
        } else if(previousLocation?.from && previousLocation.from==='EditPipeline'){
            // do nothing
        } else {
            this.selectedSystemAreaName=''
        }
        this.selectedPhysicalPipeline={ value: 'All', label: 'All' };
        this.vmacsStatus='';
        this.getPipelineGridData();
        this.getPipelineTypesDropDownData();
        this.getPhysicalPipelinesDropDownData();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineGridData(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPipelineMaintenanceGridData(this.selectedSystemAreaType.label==='All'?'All':this.pipelineTypes.find(x=>x.label===this.selectedSystemAreaType.label)?.value, this.selectedSystemAreaName===''?'All':this.selectedSystemAreaName, this.selectedPhysicalPipeline.label);
        this.pipelineMaintenanceData = response;    
        // this.agGridService.sizeColumnsToFit();
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineTypesDropDownData(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPipelineTypesDropDownData();
        this.pipelineTypes = response.map(x=>{return {'value':x.settingID, 'label':x.valueText}});    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPhysicalPipelinesDropDownData(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPhysicalPipelinesDropDownData();
        this.dropdownPhysicalPipeline = response.map(x=>{return {'value':x, 'label':x}});   
    }

    @action
    clearScreen(){
        this.selectedSystemAreaType={ value: 'All', label: 'All' };
        this.selectedSystemAreaName='';
        this.selectedPhysicalPipeline={ value: 'All', label: 'All' };
        this.getPipelineGridData();
    }

    openEditPipeline(pipelineID, syncStatus): void {
        this.setPipelineIdForEditing(pipelineID);
        this.setSyncStatusForEditing(syncStatus);
        history.push('/EditPipeline')
    }

    @action
    setSystemAreaType(value){
        this.selectedSystemAreaType=value
    }

    @action
    setPhysicalPipeline(value){
        this.selectedPhysicalPipeline=value
    }

    @action
    setSystemAreaName(value){
        this.selectedSystemAreaName=value
    }

    @action
    setPipelineIdForEditing(value){
        this.pipelineIdForEditing=value
    }
    
    @action
    setSyncStatusForEditing(value){
        this.vmacsStatus=value
    }

}
