import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class MiscellaneousSettingDataService {
    constructor(private restApi: RestApiService) {}

    async getMiscellaneousSettingsData(defaultUserLocation): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.url_id1 + defaultUserLocation);
        return data;
    }


    async updateMiscellaneousSettingsData(reqbody: any, defaultUserLocation): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.url_id2 + defaultUserLocation, reqbody, {});
        return data;
    }

    async getSettingNameOptions(defaultUserLocation): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id3 + defaultUserLocation);
        return data;
    }

    async deleteSettings(reqbody: any, defaultUserLocation): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.url_id2 + defaultUserLocation, reqbody, {});
        return data;
    }

    async addNewSettingType(reqbody: any, defaultUserLocation): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.url_id3 + defaultUserLocation, reqbody, {});
        return data;
    }

    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsSettingsEndpoint, reqbody, {});
        return data;
    }

    async updateStatusFromDelToActive(reqbody: any, defaultUserLocation): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.url_id2 + defaultUserLocation, reqbody, {});
        return data;
    }

    async getJurisdictionDropdownData(defaultUserLocation): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id8 + `?location=${defaultUserLocation}`);
        return data;
    } 
    async getAssetGroupOptions(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getAssetGroupData +'?jurisdiction=CAN');
        return data;
    }
}

export const miscellaneousSettingDataService = new MiscellaneousSettingDataService(restApiService);
