import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { AddEditGradeMaintenanceStore } from '../stores/add-edit-grade-maintenance-data-store';
import {
    GridOptions,
    ColDef,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/grade.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
// import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { addEditGradeMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import moment from 'moment';
import { ReactSelect } from 'common/components/select/select';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';
import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays';

interface CalendarContainerProps {
    children : any
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface AddEditGradeMaintenanceProps {
    addEditGradeMaintenanceStore: AddEditGradeMaintenanceStore;
}

interface AddEditGradeMaintenanceState {
    search: string;
    lastAddedRemarksID: number;
    lastAddedRelationshipID: number;
}

@inject('addEditGradeMaintenanceStore')
@observer
export class AddEditGradeMaintenance extends Component<AddEditGradeMaintenanceProps, AddEditGradeMaintenanceState> {
    constructor(props: AddEditGradeMaintenanceProps | Readonly<AddEditGradeMaintenanceProps>) {
        super(props);
        this.state = {
            search: '',
            lastAddedRemarksID: 0,
            lastAddedRelationshipID: 0
        };
        dataStore.setModuleName('Grade Maintenance')
    }
    
    async componentDidMount() {
        const { addEditGradeMaintenanceStore } = this.props;
        const previousLineSegment = history?.location?.state as { from?: string }
        await addEditGradeMaintenanceStore.init();
        addEditGradeMaintenanceStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {addEditGradeMaintenanceStore} = this.props;
      const filters = localStorageService.get('Grade Maintenance_filter');
      this.props.addEditGradeMaintenanceStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Grade Maintenance_' + accountStore.userName);
      this.props.addEditGradeMaintenanceStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }
    
    componentWillUnmount() {
        const { addEditGradeMaintenanceStore } = this.props;
        addEditGradeMaintenanceStore.resetGradeDetails();
    }

    onChangeGradeName = (e) => {
      this.props.addEditGradeMaintenanceStore.onChangeGradeName(e.target.value)
    }

    onChangeGrade = (e) => {
      this.props.addEditGradeMaintenanceStore.onChangeGrade(e.target.value)
    }

    CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };

    disableEditing = () => {
        const { addEditGradeMaintenanceStore } = this.props;
        if (addEditGradeMaintenanceStore && addEditGradeMaintenanceStore.addOrEditLinkerGridData) {
            const initialData = addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter(a => (!addEditGradeMaintenanceStore.getUpdatedRowIDs().includes(a.pipelineID.toString())));
            return initialData.filter((item) => item.isSelected === true).length > 1
        }
    }

    AddLinkerDetailsColDef() {
        const { addEditGradeMaintenanceStore } = this.props;
        const AddLinkerDetailsColDef: ColDef[] = [
            {
                headerName: 'Select',
                field: 'select',
                colId: 'Select',
                headerComponent: (params) => {
                    return (
                        <input
                            type="checkbox"
                            onChange={(e) => addEditGradeMaintenanceStore.headerCheckBoxSelection(e)}
                            checked={!addEditGradeMaintenanceStore.checkIfHeaderCheckboxShouldbeChecked(params)}
                            // disabled={this.disableEditing()}
                        />
                    );
                },
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            onChange={(e) => addEditGradeMaintenanceStore.linkedPipelineCheckboxHandler(params, e)}
                            checked={params.data.isSelected}
                            // disabled={this.disableEditing()}
                        />
                    );
                },
                cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
                maxWidth: 40,
                flex: 1,
                suppressSizeToFit: true,
                cellStyle: () => { return { padding: '10px' } }
            },
            {
                headerName: 'Base System',
                field: 'plName',
                colId: 'plName',
                filter: true,
                sortable: true,
                editable: false,
                cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
                valueGetter: params => {
                    if (params.data['plName'] === null) {
                        return '';
                    } else {
                        return params.data['plName'];
                    }
                },
                suppressSizeToFit: true,
                flex: 1
            },
            {
                headerName: 'System Grade',
                field: 'systemGrade',
                colId: 'systemGrade',
                filter: true,
                sortable: true,
                // singleClickEdit: true,
                editable: true,
                // editable: () => { return !this.disableEditing() },
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: (params) => {
                  return {
                      values: this.props.addEditGradeMaintenanceStore.systemGradeDropdownOptions.map(x=>x.label)
                  };
                },
                cellClass: (params) => { return this.disableEditing() ? '' : 'cell-editable' },
                valueGetter: params => {
                    if (params.data['systemGrade'] === null) {
                        return '';
                    } else {
                      const gradeName = params.data && params.data['systemGrade'] ? this.props.addEditGradeMaintenanceStore.systemGradeData.find(x => x.grade == params.data['systemGrade'])?.gradeName : '';
                        return  gradeName;
                    }
                },
                suppressSizeToFit: true,
                flex: 1
            },
            {
                headerName: 'Status',
                field: 'gradeStatus',
                colId: 'gradeStatus',
                filter: true,
                sortable: true,
                editable: true,
                // editable: () => { return !this.disableEditing() },
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: ['Active', 'Inactive']
                },
                cellClass: (params) => { return this.disableEditing() ? '' : 'cell-editable' },
                valueGetter: params => {
                    if (params.data['gradeStatus'] === null) {
                        return '';
                    } else {
                        return params.data['gradeStatus'] === 'A' || params.data['gradeStatus'] === 'Active' ? 'Active' : 'Inactive';
                    }
                },
                suppressSizeToFit: true,
                flex: 1
            },
            {
                headerName: 'Effective Date',
                field: 'effectiveDate',
                colId: 'effectiveDate',
                filter: true,
                sortable: true,
                editable: true,
                // editable: () => { return !this.disableEditing() },
                suppressSizeToFit: true,
                cellRenderer: (params) => {
                    const effectiveDate = params.data['effectiveDate'] ? moment(moment(params.data['effectiveDate'])
                        .format('MM/DD/yyyy')).toDate() : null
                    return (params.data &&
                        (<DatePicker
                            popperClassName='ag-custom-component-popup'
                            selected={effectiveDate}
                            dateFormat="MM/dd/yyyy"
                            onChange={(value) => { this.onChangeDate(params, value, 'effectiveDate') }}
                            popperContainer={this.CalendarContainer}
                            popperPlacement='top-start'
                            // className={this.disableEditing() ? 'serviceDateInputStyle' : 'serviceDateInputStyle-editable'}
                            // disabled={this.disableEditing()} 
                            />
                        )
                    ) || params.value
                },
                valueGetter: params => {
                    if (params.data['effectiveDate'] === null) {
                        return '';
                    } else {
                        return moment(params.data['effectiveDate']).format(AppConstant.DATE_FORMAT);
                    }
                },
                cellClass: (params) => { return this.disableEditing() ? '' : 'cell-editable' },
                flex: 1
            }
        ];
        return AddLinkerDetailsColDef;
    }

    private getAddLinkerDetailsGridConfig(gridId): GridOptions {
      const {
          addEditGradeMaintenanceStore: { AddOrEditLinkerGridData, agGridServiceForAddOrEditLinker }
      } = this.props;
      const { addEditGradeMaintenanceStore } = this.props;
      const columnDefs = this.AddLinkerDetailsColDef();
      const rowData = AddOrEditLinkerGridData;
      const onCellEditingStarted = this.onCellEditingStarted;
      const onCellEditingStopped = this.onCellEditingStopped;
      const gradeMaintenanceGridParams: any = {
        rowData,
        columnDefs,
        onCellEditingStarted,
        onCellEditingStopped
      };
      return agGridServiceForAddOrEditLinker.getGridConfig(gradeMaintenanceGridParams, gridId);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          addEditGradeMaintenanceStore: { cellValueChangeMap }
      } = this.props;
      const isNotEmpty =
          row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
      if (!isNotEmpty) {
          this.props.addEditGradeMaintenanceStore.setValueInChangeMap(row, col, value, value);
      }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
        addEditGradeMaintenanceStore: { cellValueChangeMap, updateRowforAddOrUpdateGrid, getColDef}
      } = this.props;
      cellValueChangeMap[row][col]['currentValue'] = value;
      if(col ===  "systemGrade"){
        event.data.systemGrade = this.props.addEditGradeMaintenanceStore.systemGradeDropdownOptions.find(x=>x.label === event.data.systemGrade).value;
      }
      updateRowforAddOrUpdateGrid(event.data);
      this.props.addEditGradeMaintenanceStore.agGridServiceForAddOrEditLinker.getNodes()?.refreshCells();
    };

    onChangeDate = (params, value, key) => {
        const { addEditGradeMaintenanceStore } = this.props;
        const effectiveDate = moment(value).format('MM/DD/yyyy');
        console.log(moment(value).format('MM/DD/yyyy'));
        addEditGradeMaintenanceStore.setValueInChangeMap(params.data.pipelineID, key, params.data.effectiveDate, effectiveDate);
        params.data.effectiveDate = moment(value).format('MM/DD/yyyy');
        addEditGradeMaintenanceStore.updateRowforAddOrUpdateGrid(params.data);
        if (addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length > 1) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () => addEditGradeMaintenanceStore.globalEffectiveDateSelection(params.data.effectiveDate),
                'EffectiveDate',
                `Do you want to apply selected service date i.e. ${params.data.effectiveDate} to all the linked pipelines?`
            );
        }
    }

    onClickSaveGrade = () => {
      const { addEditGradeMaintenanceStore } = this.props;
      addEditGradeMaintenanceStore.saveGrade();
    }
    
  onClickDeactivateLineSegment = () => {
    const { addEditGradeMaintenanceStore } = this.props;
    const updatedRowIDs: any = addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter(a => addEditGradeMaintenanceStore.getUpdatedRowIDs().includes(a.pipelineID.toString()));
    if (updatedRowIDs.length > 0) {
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
        () => addEditGradeMaintenanceStore.deactivateGrade(),
        'Inactive',
        'All the unsaved changes will be lost. Are you sure you want to deactivate the selected grade?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
        () => addEditGradeMaintenanceStore.deactivateGrade(),
        'Inactive',
        'Are you sure you want to deactivate the selected grade?'
      );
    }
  }
    
    async pushToVmacs(env) {
      const { addEditGradeMaintenanceStore } = this.props;
      console.log(addEditGradeMaintenanceStore.addOrEditLinkerGridData)
      console.log(addEditGradeMaintenanceStore.getUpdatedRowIDs())
      const requestBody: any = addEditGradeMaintenanceStore.addOrEditLinkerGridData.length > 0 && addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter(a => addEditGradeMaintenanceStore.getUpdatedRowIDs().includes(a.tariffGradeID));   // .toString()));
      const needToSync = addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0]) || addEditGradeMaintenanceStore.addOrEditLinkerGridData.some((item) => item.syncStatus && item.syncStatus === SyncStatus[1] && item.syncStatus !== SyncStatus[2] );
      // const updatedRowIDs = addEditGradeMaintenanceStore.remarksGridData.filter(a => addEditGradeMaintenanceStore.getUpdatedRowIDsForRemark().includes(a.lsCommentID.toString()));
      if((addEditGradeMaintenanceStore.selectedGrade !== '' && addEditGradeMaintenanceStore.selectedGradeName !== '' &&(needToSync || (requestBody.length <= 0 && addEditGradeMaintenanceStore.editableGradeData.syncStatus && addEditGradeMaintenanceStore.editableGradeData.syncStatus !== SyncStatus[2])))) {
        if(((addEditGradeMaintenanceStore.editableGradeData.syncStatus && addEditGradeMaintenanceStore.editableGradeData.syncStatus=== SyncStatus[0]) || addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0])) && env === 'Push TariffGrade to Vmacs Prod' && addEditGradeMaintenanceStore.selectedGrade !== '' && addEditGradeMaintenanceStore.selectedGradeName !== '' ) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmWithCustomComponent(
              () => {},
              ConfirmWithOK,
              {secondaryText: 'Grade has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
              'confirmPopup'
            );
          } else {
              const confirmService = new ConfirmService();
              const envName = env == 'Push TariffGrade to Vmacs Test' ? 'Test' : 'Prod'
              confirmService.showConfirmDialog(
                () => addEditGradeMaintenanceStore.pushSelectedRowsToVmacs(env),
                'Active',
                `Are you sure you want to push the selected data to the ${envName} Environment?`
            );
          }
      } else {
        const secondaryText = 'Please select only the active and unsynced grade to perform the operation';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
  }
    
    onClickClose = () => {
        const { addEditGradeMaintenanceStore } = this.props;
        addEditGradeMaintenanceStore.onCloseGradeDetails();
    }

    pushButtonDisabled(env) {
      const { addEditGradeMaintenanceStore } = this.props;
      
      if(env === 'Push TariffGrade to Vmacs Test') {
        const needToSync = addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0]);
        return  !addEditGradeMaintenanceStore.editableGradeData || (!addEditGradeMaintenanceStore.editableGradeData.tariffGradeID || addEditGradeMaintenanceStore.editableGradeData.tariffGradeID === 0) || (addEditGradeMaintenanceStore.editableGradeData.syncStatus && (addEditGradeMaintenanceStore.editableGradeData.syncStatus === SyncStatus[1] || addEditGradeMaintenanceStore.editableGradeData.syncStatus === SyncStatus[2]) && !needToSync) ; 
      }
      const needToSync = addEditGradeMaintenanceStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[1] && item.syncStatus !== SyncStatus[2] );
        return  !addEditGradeMaintenanceStore.editableGradeData || (!addEditGradeMaintenanceStore.editableGradeData.tariffGradeID || addEditGradeMaintenanceStore.editableGradeData.tariffGradeID === 0) || ( addEditGradeMaintenanceStore.editableGradeData.syncStatus && addEditGradeMaintenanceStore.editableGradeData.syncStatus === SyncStatus[2] && !needToSync);
    }

    render(): React.ReactNode {
      const { addEditGradeMaintenanceStore } = this.props;
      return (
        <Container fluid>
          {this.renderGradeMaintenanceScreen()}
        </Container>
      );
    }

    renderGradeMaintenanceScreen() : React.ReactNode {
      const { addEditGradeMaintenanceStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='gradeMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Grade Details</h2>
                </div>
                
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                    <label className="standard_label_style">Grade</label>
                      <input 
                        type="text"
                        value={addEditGradeMaintenanceStore.selectedGrade}
                        className="search_text_field"
                        // disabled={true}
                        style={{width: '20%', marginRight: '10px'}}
                        onChange = {(e) => this.onChangeGrade(e)}
                      />
                        <label className="standard_label_style">Grade Name</label>
                      <input 
                        type="text"
                        value={addEditGradeMaintenanceStore.selectedGradeName}
                        className="search_text_field"
                        // disabled={true}
                        style={{width: '20%', marginRight: '10px'}}
                        onChange = {(e) => this.onChangeGradeName(e)}
                      />
                </div>
              </div>
            </div>
            
            
              
              <div style={{'marginTop':'20px'}}>
                    <div className='screenHeader'>
                        <h2 className='screen_title'>Related Grade</h2>
                    </div>
              
            <div className='gradeMaintenanceBox'>
                <div className='pipelineLinkerBox'>
                    <div className="rotatedText">
                      Linker
                    </div>
                    <Row style={{margin:'20px', width: '60%', height: '40%'}}>
                      <AgGridComponent gridConfig={this.getAddLinkerDetailsGridConfig('Grade Details')}/>
                    </Row>
                </div>
              </div>


                <div className='selectContainer'>
                  <div className='searchButtonDiv'>
                        <div className='otherHeaderButtonsDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickSaveGrade} disabled={addEditGradeMaintenanceStore.isSaveDisabled()}>
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickDeactivateLineSegment} disabled= {addEditGradeMaintenanceStore.isDeleteDisabled()}>
                                Deactivate
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push TariffGrade to Vmacs Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Push TariffGrade to Vmacs Test')}>
                                Push to Test
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push TariffGrade to Vmacs Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Push TariffGrade to Vmacs Prod')}>
                                Push to Prod
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickClose} disabled= {false}>
                                Close
                            </CustomButton>
                          </div>
                      </div>
              </div>
            </div>
            </Col>
        </Row>
      );
    }

}