import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { CustomButtonType, CustomButton } from '../../../../shared/components/custom-button/custom-button';
import { miscellaneousSettingsDataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';

export interface ConfirmNewSettingsProps {
    settingsTypeData: any;
    onClose: () => void;
    onSuccessClick: (data) => void;
    defaultUserLocation?: string;
    jurisdictionOptions?: any;
    assetGroupDropdownOptions?: any;
    editableData?: any;
    isEditingSettingData: boolean;
}
export class AddNewSettingsPopup extends React.Component<ConfirmNewSettingsProps> {
    state = {
        valueCode: this.props.isEditingSettingData ? this.props.editableData[0].valueCode : '',
        valueText: this.props.isEditingSettingData ? this.props.editableData[0].valueText : '',
        inValidValueCode: '',
        inValidValueText: '',
        jurisdiction: this.props.isEditingSettingData ? { 'value': this.props.editableData[0].defaultLocation, 'label': this.props.editableData[0].defaultLocation }
            : this.props.jurisdictionOptions.length > 0 ? { 'value': this.props.jurisdictionOptions[0].value, 'label': this.props.jurisdictionOptions[0].label }
            : {'value': '', 'label': ''},
        settingName: this.props.isEditingSettingData ? {'value': this.props.editableData[0].settingName, 'label': this.props.editableData[0].settingName}
            : {'value': this.props.settingsTypeData[0].value, 'label': this.props.settingsTypeData[0].label},
        selectedAssetGroup: this.props.isEditingSettingData ? {'value': this.props.editableData[0].assetGrp, 'label': this.props.editableData[0].assetGrp}
            : {'value': this.props.assetGroupDropdownOptions[0].value, 'label': this.props.assetGroupDropdownOptions[0].label},
        remarks: this.props.isEditingSettingData ? this.props.editableData[0].remarks ? this.props.editableData[0].remarks : '' : ''
    };
    public render(): React.ReactNode {
        const { onClose, jurisdictionOptions, defaultUserLocation, settingsTypeData, assetGroupDropdownOptions } = this.props;
        return (
            <div className="confirm">
                <Row className="mb-2">
                    <Col>
                        <div className="input">
                                <div className="confirm__action mt-10">Select Jurisdiction:</div>
                            
                                <ReactSelect
                                    className='select w-200'
                                    values={this.state.jurisdiction}
                                    placeholder="Select"
                                    options={jurisdictionOptions}
                                    onChange={(e: any) => {this.setState({ jurisdiction : e})}}
                                    isMulti={false}
                                    isSearchable={true}
                                    disabled={this.props.isEditingSettingData}
                                />
                        </div>
                    </Col>
                    <Col>
                        <div className="input">
                                <div className="confirm__action mt-10">Setting Name:</div>
                            
                                <ReactSelect
                                    className='select w-200'
                                    values={this.state.settingName}
                                    placeholder="Select"
                                    options={settingsTypeData}
                                    onChange={(e: any) => {this.setState({ settingName : e})}}
                                    isMulti={false}
                                    isSearchable={true}
                                    disabled={this.props.isEditingSettingData}
                                />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <div className="confirm__action mt-10">Value Code:</div>
                            <input
                                className={`search_text_field ${this.state.inValidValueCode}`}
                                placeholder="Enter Value Code"
                                onChange={this.handleChangeValueCode}
                                value={this.state.valueCode}
                                disabled={this.props.isEditingSettingData}
                            />
                            {this.state.inValidValueCode && <div className="email-error">{'Enter a valid value code'}</div>}
                    </Col>
                    <Col>
                        <div className="confirm__action mt-10">Value Text:</div>
                            <input
                                className={`search_text_field ${this.state.inValidValueText}`}
                                placeholder="Enter Value Text"
                                onChange={this.handleChangeValueText}
                                value={this.state.valueText}
                            />
                            {this.state.inValidValueText && <div className="email-error">{'Enter a valid value text'}</div>}
                    </Col>
                </Row>
                <Row>
                    <Col hidden={defaultUserLocation == 'US' || this.state.jurisdiction.value == 'US' || this.state.jurisdiction.value == 'USA'}>
                        <div className="input">
                                <div className="confirm__action mt-10">Asset Group:</div>
                            
                                <ReactSelect
                                    className='select w-200'
                                    values={this.state.selectedAssetGroup}
                                    placeholder="Select"
                                    options={assetGroupDropdownOptions}
                                    onChange={(e: any) => {this.setState({ selectedAssetGroup : e})}}
                                    isMulti={false}
                                    isSearchable={true}
                                    disabled={this.props.isEditingSettingData}
                                />
                        </div>
                    </Col>
                    <Col>
                        <div className="confirm__action mt-10">Remarks:</div>
                            <input
                                className={'search_text_field'} // ${this.state.inValidSettingName}`}
                                placeholder="Enter Remarks"
                                onChange={this.handleChangeRemarks}
                                value={this.state.remarks}
                            />
                    </Col>
                </Row>
                <div className="confirm__action mt-10">
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick} disabled={!this.isValueTextValid() || !this.isValueCodeValid()}>
                        Save
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Cancel
                    </CustomButton>
                </div>
            </div>
        );
    }

    isValueTextValid = () => {
        const checkValidValue = this.state.valueText.replaceAll(' ', '').length > 0;
        if(this.state.valueText.length > 0 && checkValidValue) {
            return true;
        }
        return false;
    }

    isValueCodeValid = () => {
        const checkValidValue = this.state.valueCode.replaceAll(' ', '').length > 0;
        if(this.state.valueCode.length > 0 && checkValidValue) {
            return true;
        }
        return false;
    }

    handleChangeValueCode = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ valueCode: value });
        const checkValidValue = value.replaceAll(' ', '').length > 0;
        if(value.length > 0 && checkValidValue) {
            this.setState({ inValidValueCode: '' });
        } else {
            this.setState({ inValidValueCode: 'invalid-value' });
        }
    };

    handleChangeValueText = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ valueText: value });
        const checkValidValue = value.replaceAll(' ', '').length > 0;
        if(value.length > 0 && checkValidValue) {
            this.setState({ inValidValueText: '' });
        } else {
            this.setState({ inValidValueText: 'invalid-value' });
        }
    };

    handleChangeRemarks = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ remarks: value });
    };

    handleClick = () => {
        const data = {
            settingName: this.state.settingName.label,
            valueCode: this.state.valueCode,
            selectedAssetGroup: this.state.selectedAssetGroup.label,
            valueText: this.state.valueText,
            defaultLocation: this.state.jurisdiction.label,
            remarks: this.state.remarks
        }
        miscellaneousSettingsDataStore.addEditSettingData = data;
        this.props.onSuccessClick(data);
        this.props.onClose();
    };
}
