import { action, computed, observable, toJS } from 'mobx';
import { isArray, isNull } from 'lodash';
import { Option } from 'shared/types/shared';
import { DataTypeDefaultOption } from '../default-page_constant';
import { accountStore } from '../../features/account/stores';
import { agGridService } from 'common/services/ag-grid-service';
import { connectionPointMaintenanceDataStore } from 'features/master-data-management/connection-point-maintenance/stores';
import { cpDataService } from 'features/master-data-management/connection-point-maintenance/services/cp-data-service';
import { connectable } from 'rxjs';
import { errorHandler } from 'shared/handlers/error-handler';
import { ConnectionPointsMessage } from 'features/master-data-management/connection-point-maintenance/constants/enums';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { profitCenterMaintenanceDataStore } from 'features/master-data-management/profit-center-maintenance/stores';
import { lineSegmentMaintenanceDataService } from 'features/master-data-management/line-segment-maintenance/services/line-segment-maintenance-data-service';
import { lineSegmentMaintenanceDataStore } from 'features/master-data-management/line-segment-maintenance/stores';
import { customerMaintenanceDataStore } from 'features/master-data-management/customer-maintenance/stores';
import { shipperLossGainMaintenanceDataStore } from 'features/master-data-management/shipper-loss-gain-maintenance/stores';
import { gradeMaintenanceDataService } from 'features/master-data-management/grade-maintenance/services/grade-maintenance-data-service';
import { gradeMaintenanceDataStore } from 'features/master-data-management/grade-maintenance/stores';
import { filingEntityMaintenanceDataStore } from 'features/master-data-management/filing-entity-maintenance/stores';
import { dataStore } from '.';

export class DataStore {
    @observable dataTypes: any;
    @observable selectedDataType: any = DataTypeDefaultOption;
    @observable currentModuleName = '';
    @observable showUnsavedWarningAlert = false;
    @observable isPageSizeChanged = false;
    @observable cacheBlockSize = 100;
    @observable agGridId = '';


    init(dataTypeOptions): void {
        this.loadViewModel(dataTypeOptions);
    }

    async loadViewModel(dataTypeOptions): Promise<void> {
        this.setDataTypes(dataTypeOptions);
    }

    @action
    setDataTypes(dataTypes: any): void {
        this.dataTypes = dataTypes;
    }


    @action
    setShowUnsavedWarningAlert(value: boolean): void {
        this.showUnsavedWarningAlert = value;
    }

    @action
    setPageSizeChanged(value: boolean): void {
        this.isPageSizeChanged = value;
    }

    @action
    setCacheBlockSize(value: number): void {
        this.cacheBlockSize = value;
    }

    @computed
    get CacheBlockSize() {
        return toJS(this.cacheBlockSize);
    }

    setSelectedDataType(dataType: any): void {
        this.selectedDataType = !isNull(dataType) && !isArray(dataType) ? dataType : '';
    }

    @action
    reset(): void {
        this.setSelectedDataType(DataTypeDefaultOption);
    }

    @action
    async setModuleName(moduleName): Promise<void> {
        this.currentModuleName = moduleName,
        this.showUnsavedWarningAlert = false;
    }

    getCurrentModuleName(): string {
        return this.currentModuleName;
    }

    async setAgGridId(gridId): Promise<void> {
      this.agGridId = gridId;
    }

    getAgGridId(): string {
        return this.agGridId;
    }

    configureDataTypeOptionsByRole(dataTypeOptions) {
        const accessDataTypes: any = [];
        dataTypeOptions.forEach(element => {
            if (element === 'DefaultPageComponent' || element === 'PMCDashboard') {
                accessDataTypes.push(element);
            } else {
                const checkAccess = this.checkOperationAccessWithModule('view', element);
                if (checkAccess) {
                    accessDataTypes.push(element);
                }
            }
        });
        return accessDataTypes;
    }

    checkOperationAccess(operationName): boolean {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            if (!access) {
                const roleMapping = accountStore.rolesJson.find(
                    mapping => mapping.roleName.toLowerCase() === userRole.toLowerCase()
                );
                const moduleInfo = roleMapping?.modules.find(x => x.name === this.currentModuleName);
                if (moduleInfo && !access) {
                    access = moduleInfo[operationName];
                }
            }
        });
        return access;
    }

    checkOperationAccessWithModule(operationName, currentModuleName): boolean {
        let access = false;
        const currentUserRoles = accountStore.userRoles;
        currentUserRoles.forEach(userRole => {
            const role = userRole;
            if (!access) {
                const roleMapping = accountStore.rolesJson.find(mapping => mapping.roleName.toLowerCase() === role.toLowerCase());
                const moduleInfo = roleMapping?.modules.find(x => x.name === currentModuleName);
                if (moduleInfo && !access) {
                    access = moduleInfo[operationName];
                }
            }
        });
        return access;
    }

    checkOperationAccessWithModuleAndPipelineId(operationName, currentModuleName, pipelineIDs): boolean {
      let access = false;
      const currentUserRoles = accountStore.userRoles;
      currentUserRoles.forEach(userRole => {
          const role = userRole;
          if (!access) {
              const roleMapping = accountStore.rolesJson.find(mapping => mapping.roleName.toLowerCase() === role.toLowerCase());
              const moduleInfo = roleMapping?.modules.find(x => x.name === currentModuleName);
              if (moduleInfo && !access) {
                  access = moduleInfo[operationName] && ((!roleMapping.pipelinePrimaryApprovers || (roleMapping.pipelinePrimaryApprovers.some((x) =>  !x.pipelineID))) || !pipelineIDs || pipelineIDs.length <= 0 || pipelineIDs.filter((item) => roleMapping.pipelinePrimaryApprovers.filter((x) =>  x.pipelineID.toString() === item.toString()).length >  0).length > 0);
              }
          }
      });
      return access;
  }

    showUnsavedWarningOnTabCloseOrReload() {
        if (this.showUnsavedWarningAlert) {
            window.onbeforeunload = () => 'You have unsaved changes, Are you sure you want to leave?'
          } else {
            window.onbeforeunload = null
          }
    }

    sortAndFilter(allOfTheData, sortModel, filterModel) {
        return this.sortData(sortModel, this.filterData(filterModel, allOfTheData));
      }
      
    sortData(sortModel, data) {
        const sortPresent = sortModel && sortModel.length > 0;
        if (!sortPresent) {
          return data;
        }
        // do an in memory sort of the data, across all the fields
        const resultOfSort = data.slice();
        resultOfSort.sort(function (a, b) {
          for (let k = 0; k < sortModel.length; k++) {
            const sortColModel = sortModel[k];
            const valueA = a[sortColModel.colId];
            const valueB = b[sortColModel.colId];
            // this filter didn't find a difference, move onto the next one
            if (valueA == valueB) {
              continue;
            }
            const sortDirection = sortColModel.sort === 'asc' ? 1 : -1;
            if (valueA > valueB) {
              return sortDirection;
            } else {
              return sortDirection * -1;
            }
          }
          // no filters found a difference
          return 0;
        });
        return resultOfSort;
      }
      
    filterData(filterModel, data) {
        const filterPresent = filterModel && Object.keys(filterModel).length > 0;
        if (!filterPresent) {
          return data;
        }
      
        const resultOfFilter: any = [];
        for (let i = 0; i < data.length; i++) {
          const item = data[i];
      
        //   if (filterModel.plName) {
        //     const plName = item.plName;
        //     const allowPlName = parseInt(filterModel.plName.filter);
            // EQUALS = 1;
            // LESS_THAN = 2;
            // GREATER_THAN = 3;
            // if (filterModel.plName.type == 'equals') {
            //   if (plName !== allowPlName) {
            //     continue;
            //   }
            // } else if (filterModel.plName.type == 'lessThan') {
            //   if (age >= allowedAge) {
            //     continue;
            //   }
            // } else {
            //   if (age <= allowedAge) {
            //     continue;
            //   }
            // }
        //   }
      
        //   if (filterModel.year) {
        //     if (filterModel.year.values.indexOf(item.year.toString()) < 0) {
        //       // year didn't match, so skip this record
        //       continue;
        //     }
        //   }
      
          if (filterModel.plName) {
            if (filterModel.plName.values.indexOf(item.plName) < 0) {
              continue;
            }
          }
      
          resultOfFilter.push(item);
        }
      
        return resultOfFilter;
      }

    onGridReady() {
      if(this.getCurrentModuleName() === MasterDataComponentModulesName.ProfitCenterMaintenance ) {
        profitCenterMaintenanceDataStore.loadViewModel();        
      }
      if(this.getCurrentModuleName() === MasterDataComponentModulesName.LineSegmentMaintenance ) {
        lineSegmentMaintenanceDataStore.loadViewModel();
      }
      if(this.getCurrentModuleName() === MasterDataComponentModulesName.ConnectionPointMaintenance ) {
        connectionPointMaintenanceDataStore.loadViewModel();
      }
      if(this.getCurrentModuleName() === MasterDataComponentModulesName.GradeMaintenance ) {
        gradeMaintenanceDataStore.loadViewModel();
      }
      if(this.getCurrentModuleName() === MasterDataComponentModulesName.CustomerMaintenance && dataStore.isPageSizeChanged) {
        customerMaintenanceDataStore.loadViewModel();
      }
      if(this.getCurrentModuleName() === MasterDataComponentModulesName.ShipperLGMaintenance ) {
        shipperLossGainMaintenanceDataStore.loadViewModel();
      }
      if(this.getCurrentModuleName() === MasterDataComponentModulesName.FilingEntityMaintenance ) {
        filingEntityMaintenanceDataStore.loadViewModel();
      }
  }
}
