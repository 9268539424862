import React, { Component } from 'react';
import { AppConstant } from 'app_constants';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './customer-maintenance.scss';
import {
    ColDef, RowSelectedEvent, CellEditingStoppedEvent
} from 'ag-grid-community';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import { CustomerMaintenanceDataStore } from '../stores/customer-maintenance-data-store';
import moment from 'moment';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import momenttimezone from 'moment-timezone';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { customerMaintenanceDataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import { localStorageService } from 'shared/services/local-storage-service';
import { AddCustomerMaintenanceContainer } from './add-customer-maintenance'
/* eslint-disable */
interface CustomerMaintenanceContainerProps {
    customerMaintenanceDataStore: CustomerMaintenanceDataStore;
}

interface CustomerMaintenanceContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowSelected: boolean
}

@inject('customerMaintenanceDataStore')
@observer
export class CustomerMaintenanceContainer extends Component<CustomerMaintenanceContainerProps, CustomerMaintenanceContainerState> {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowSelected: false
        };
        dataStore.setModuleName('Customer Maintenance')
    }

    componentDidMount() {
      const {customerMaintenanceDataStore} = this.props;
      customerMaintenanceDataStore.addCM = false;
      customerMaintenanceDataStore.init();
    }

    componentDidUpdate() {
        const filters = localStorageService.get('Customer Maintenance_filter');
        this.props.customerMaintenanceDataStore.agGridService.setAllFilters(filters);
        const columnVisible = localStorageService.get('Customer Maintenance_' + this.props.customerMaintenanceDataStore.username);
        this.props.customerMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
        // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }
  
    filterParams = {
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
        textFormatter: (r) => {
            if (r == null) return null;
            return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, 'a')
            .replace(/æ/g, 'ae')
            .replace(/ç/g, 'c')
            .replace(/[èéêë]/g, 'e')
            .replace(/[ìíîï]/g, 'i')
            .replace(/ñ/g, 'n')
            .replace(/[òóôõö]/g, 'o')
            .replace(/œ/g, 'oe')
            .replace(/[ùúûü]/g, 'u')
            .replace(/[ýÿ]/g, 'y');
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      };
    
    CustomerMaintenanceGridColDef: ColDef[] = [
        //   {
        //     headerName: 'Select',
        //     field: 'select',
        //     checkboxSelection: (params) => {return params.data ? true: false}, 
        //     colId:'Select',
        //     // cellRenderer: 'checkboxRenderer',
        //     width: 40,
        //     headerCheckboxSelection: false,
        //     suppressSizeToFit: true,
        //     cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        //     cellStyle: () => {return {padding: '10px'}}
        //   },
          { 
            headerName: 'Type', 
            field: 'custTypeCode',
            tooltipField: 'custTypeCode', 
            filterParams: this.filterParams,
            filter: true, 
            sortable: true,
            editable: false,
            minWidth: 160,
            flex: 1,
            singleClickEdit: true,
            cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        },
        { 
            headerName: 'Customer Name', 
            field: 'custName',
            tooltipField: 'custName', 
            minWidth: 160,
            filterParams: this.filterParams,
            editable: false,
            cellRenderer: params => {
                return (<span style={{color: 'blue', 'textDecoration': 'underline'}}
                    onClick={()=>{this.props.customerMaintenanceDataStore.setAddCM();
                        this.props.customerMaintenanceDataStore.getCustomerDetails(params.data?.customerID)}}>
                    {params.data?.custName}</span>)
            },
            filter: true, 
            sortable: true,
            flex: 1,
            cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        },
        { 
            headerName: 'Customer Code', 
            field: 'custCode',
            tooltipField: 'custCode',
            filterParams: this.filterParams,
            minWidth: 140, 
            filter: true, 
            editable: false,
            sortable: true,
            cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : ''},
            flex: 1,
        },
        { 
            headerName: 'Customer Enom Code', 
            field: 'custEnomCode',
            tooltipField: 'custEnomCode',
            filterParams: this.filterParams,
            minWidth: 140, 
            filter: true, 
            editable: false,
            sortable: true,
            cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : ''},
            flex: 1,
        },
        { 
            headerName: 'Assigned System', 
            field: 'plName',
            tooltipField: 'plName',
            minWidth: 125, 
            filterParams: this.filterParams,
            filter: true, 
            sortable: true,
            editable: false,
            flex: 1,
            singleClickEdit: true,
            cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        },
        { 
            headerName: 'Status', 
            field: 'custStatus',
            tooltipField: 'custStatus',
            minWidth: 125,
            flex: 1, 
            filterParams: this.filterParams,
            filter: true, 
            sortable: true,
            editable: false,
            singleClickEdit: true,
            cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        }    
  ];
  

    getColDef() {
      return this.CustomerMaintenanceGridColDef
    }


    getGridConfig(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridService }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          columnDefs:this.getColDef(),
          onRowSelected: this.onRowSelected,
          onCellEditingStopped: this.onCellEditingStopped,
          rowModelType,
          cacheBlockSize
          };
        return agGridService.getGridConfig(cmGridParams, gridId);
      }

      onRowSelected = (event: RowSelectedEvent): void => {
        this.setState({...this.state, rowSelected: (event.api.getSelectedNodes().length>=1)});
      }

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const { customerMaintenanceDataStore } = this.props;
        // const rowId = event.data.rowId;
        // const rowData = event.data;
        // const col = event.colDef.field || '';
        // const value = event.value;
        // customerMaintenanceDataStore.setUpdatedRowIds([...customerMaintenanceDataStore.updatedRows, event.data.rowId])
    };
  
    onSearchClick = () => {
        const { customerMaintenanceDataStore } = this.props;
        // const updatedRowIDs = customerMaintenanceDataStore.connectionPointGridData.filter(a => customerMaintenanceDataStore.getUpdatedRowIDs().includes(a.connectionPointID.toString()));
        const currentPage = customerMaintenanceDataStore.agGridService.paginationGetCurrentPage();
        const pageSize = customerMaintenanceDataStore.agGridService.paginationGetPageSize();
        this.props.customerMaintenanceDataStore.onSearchClick(currentPage, pageSize);
    }

    render(): React.ReactNode {
        const { customerMaintenanceDataStore } = this.props;
        return customerMaintenanceDataStore.addCM?(<>{history.push('/AddCustomerMaintenance')}</>):(
            <Container fluid>
                <Row>
                    <Col>
                        <div className='CustomerMaintenanceBox'>
                            <div className='screenHeader'>
                            <h2 className='screen_title'> Customer Maintenance</h2>
                            </div>
                            <div className='selectContainer'>
                                <Row>
                                    <Col>
                                    <label className="standard_label_style">Customer Type</label>
                                    <ReactSelect
                                        className='select'
                                        values={customerMaintenanceDataStore.formData['customerType']?.value!=='' && customerMaintenanceDataStore.formData['customerType'] || null}
                                        placeholder={'Select Type'}
                                        options={Array.isArray(customerMaintenanceDataStore.customerTypesDropdown) && customerMaintenanceDataStore.customerTypesDropdown.length && customerMaintenanceDataStore.customerTypesDropdown?.map(option=> {return{value: option.settingID, label: option.valueText }}) ||  []}
                                        onChange={(e)=>{customerMaintenanceDataStore.changeFormData(e, 'customerType')}}
                                        isMulti={false}
                                        disabled={false}
                                        isSearchable={true}
                                    />
                                    </Col>
                                    <Col>
                                    <label className="standard_label_style">Customer Name</label>
                                    <input
                                        type="text"
                                        className="search_text_field input"
                                        onChange={(e)=>{customerMaintenanceDataStore.changeFormData(e.target.value, 'customerName')}}
                                        value={customerMaintenanceDataStore.formData.customerName}
                                    />
                                    </Col>
                                    <Col>
                                    <label className="standard_label_style">Assigned System</label>
                                    <ReactSelect
                                        className='select'
                                        values={customerMaintenanceDataStore.formData['assignedSystem']?.value!=='' && customerMaintenanceDataStore.formData['assignedSystem'] || ''}
                                        placeholder={'Select System'}
                                        options={Array.isArray(customerMaintenanceDataStore.assignedSystemsDropdown) && customerMaintenanceDataStore.assignedSystemsDropdown.length && [...[{value: 0 ,label: 'All'}], ...customerMaintenanceDataStore.assignedSystemsDropdown?.map(option=> {return{value: option.pipelineID, label: option.plName }})] ||  []}
                                        onChange={(e)=>{customerMaintenanceDataStore.changeFormData(e, 'assignedSystem')}}
                                        isMulti={false}
                                        disabled={false}
                                        isSearchable={true}
                                    />
                                    </Col><Col></Col>
                                </Row>
                                <Row>
                                    <Col>
                                    <label className="standard_label_style">Customer Status</label>
                                    <ReactSelect
                                        className='select'
                                        values={customerMaintenanceDataStore.formData['customerStatus']?.value!=='' && customerMaintenanceDataStore.formData['customerStatus'] || ''}
                                        placeholder={'Select Status'}
                                        options={Array.isArray(customerMaintenanceDataStore.customerStatusDropdown) && customerMaintenanceDataStore.customerStatusDropdown.length && customerMaintenanceDataStore.customerStatusDropdown?.map(option=> {return{value: option.settingID, label: option.valueText }}) ||  []}
                                        onChange={(e)=>{customerMaintenanceDataStore.changeFormData(e, 'customerStatus')}}
                                        isMulti={false}
                                        disabled={false}
                                        isSearchable={true}
                                    />
                                    </Col>
                                    <Col>
                                    <label className="standard_label_style">Customer Code</label>
                                    <input
                                        type="text"
                                        className="search_text_field input"
                                        onChange={(e)=>{customerMaintenanceDataStore.changeFormData(e.target.value, 'customerCode')}}
                                        value={customerMaintenanceDataStore.formData.customerCode}
                                    />
                                    </Col>
                                    <Col>
                                    <label className="standard_label_style">Enom Code</label>
                                    <input
                                        type="text"
                                        className="search_text_field input"
                                        onChange={(e)=>{customerMaintenanceDataStore.changeFormData(e.target.value, 'enomCode')}}
                                        value={customerMaintenanceDataStore.formData.enomCode}
                                    />
                                    </Col><Col></Col>
                                </Row>
                                <div className='searchButtonDiv'>
                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.onSearchClick()}}>
                                        Search
                                    </CustomButton>
                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.clearScreen()}}>
                                        Clear
                                    </CustomButton>
                                    <div className="otherHeaderButtonsDiv"> 
                                        <CustomButton type={CustomButtonType.Submit} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.CustomerMaintenance)} onClick={()=>{customerMaintenanceDataStore.setAddCM()}}>
                                            Add New Customer
                                        </CustomButton>
                                        {/* <CustomButton type={CustomButtonType.Submit} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)} onClick={()=>{customerMaintenanceDataStore.activate()}}>
                                            Activate
                                        </CustomButton> */}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfig('Customer Maintenance')}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
    );
}}
