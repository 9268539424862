import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { agencyMaintenanceDataStore } from '../stores';
import { TextCellRenderer } from './cell-renderer/text-cell-renderer';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { AgencyCodeCellRenderer } from './cell-renderer/agency-code-cell-renderer';

export const agencyColDef: ColDef[] = [
    {   
        headerName: 'Select',
        field: 'select', 
        colId:'Select',
        checkboxSelection: (params) => {return agencyMaintenanceDataStore.isEditableJurisdiction(params) ? false: true}, 
        // cellRenderer: 'checkboxRenderer', 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 40, 
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true, 
    },
    { 
        headerName: 'Jurisdiction', 
        field: 'jurisdiction',
        colId: 'jurisdiction',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        // cellEditor: 'agSelectCellEditor', 
        minWidth: 110, 
        // cellClass: (params) => {return agencyMaintenanceDataStore.isEditableJurisdiction(params) ?  'cell-editable' : ''}, 
        // cellStyle: (params) => {
        //     if (params.data.settingID <= 0 && !params.data.settingName) {
        //         return {border: '1px solid #0091ea'};
        //     }
        //     return null;
        // },
        valueGetter: params => {
            if (params.data['jurisdiction'] === null) {
                return '';
            } else {
                return params.data['jurisdiction'];
            }
        },
        flex: 1
    },
    
    { 
        headerName: 'Agency Code', 
        field: 'agencycode',
        colId: 'agencycode',
        filter: true, 
        sortable: true, 
        minWidth: 120,
        // cellRenderer: TextCellRenderer,
        cellRenderer: AgencyCodeCellRenderer,
        singleClickEdit: true,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['agencycode'] === null) {
                return '';
            } else {
                return params.data['agencycode'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Agency Name', 
        field: 'agencyname', 
        colId: 'agencyname',
        filter: true, 
        sortable: true, 
        singleClickEdit: true,
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' :  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.AgencyMaintenance) ? 'cell-editable': ''},
        valueGetter: params => {
            if (params.data['agencyname'] === null) {
                return '';
            } else {
                return params.data['agencyname'];
            }
        },
        minWidth: 200,
        flex: 2
    },
    // { headerName: 'City', field: 'city', filter: true, sortable: true, editable: true },
    // { headerName: 'State', field: 'state', filter: true, sortable: true, editable: true },
    // { headerName: 'Zip', field: 'zip', filter: true, sortable: true, editable: true },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 130,
        valueGetter: params => {
            if (params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        },
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Created Date',
        field: 'createDate', 
        colId: 'createDate',
        filter: true, 
        sortable: true,
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 120,
        valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        } ,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Updated User', 
        field: 'updateuser', 
        colId: 'updateuser',
        filter: true, 
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updateuser'] === null) {
                return '';
            } else {
                return params.data['updateuser'];
            }
        },
        hide: true
    },
    { 
        headerName: 'Updated Date', 
        field: 'updatedate', 
        colId: 'updatedate',
        filter: true, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updatedate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updatedate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        hide: true
    },
    { 
        headerName: 'Push to VMACS', 
        field: 'PushToVMACS', 
        colId:'PushToVMACS',
        editable: dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd),
        cellRenderer: PushVmacsButtonComponent,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        suppressSizeToFit: true
    },
    { 
        headerName: 'Sync Status', 
        field: 'syncStatus', 
        colId: 'syncStatus',
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['syncStatus'] === null) {
                return '';
            } else {
                return params.data['syncStatus'];
            }
        }
    },
    { 
        headerName: 'VMACS Test Sync Date', 
        field: 'testSyncDate', 
        colId: 'testSyncDate',
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['testSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['testSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        hide: true
    },
    { 
        headerName: 'VMACS Prod Sync Date', 
        field: 'prodSyncDate', 
        colId: 'prodSyncDate',
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['prodSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['prodSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        hide: true  
    }
];

