import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { CustomButtonType, CustomButton } from '../../../../shared/components/custom-button/custom-button';
import { ReactSelect } from 'common/components/select/select';

export interface ConfirmProps {
    settingsTypeData: any;
    onClose: () => void;
    onSuccessClick: (data) => void;
    defaultUserLocation?: string;
    jurisdictionOptions?: any;
}
export class AddNewSettingsTypePopup extends React.Component<ConfirmProps> {
    state = {
        value: '',
        inValidSettingName: '',
        jurisdiction: this.props.jurisdictionOptions.length > 0 ? { 'value': this.props.jurisdictionOptions[0].value, 'label': this.props.jurisdictionOptions[0].label }
            : {'value': '', 'label': ''},
    };
    public render(): React.ReactNode {
        const { onClose, jurisdictionOptions, defaultUserLocation } = this.props;
        return (
            <div className="confirm">
                <Row className="mb-2">
                    <Col>
                        <div className="input">
                                <div className="confirm__action mt-10">Select Jurisdiction:</div>
                            
                                <ReactSelect
                                    className='select w-200'
                                    values={this.state.jurisdiction}
                                    placeholder="Select"
                                    options={jurisdictionOptions}
                                    onChange={(e: any) => {this.setState({ jurisdiction : e})}}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        {/* <div className="input"> */}
                        <div className="confirm__action mt-10">Enter new setting type :</div>
                            <input
                                className={`search_text_field ${this.state.inValidSettingName}`}
                                placeholder="Enter Setting Type"
                                onChange={this.handleChange}
                                value={this.state.value}
                            />
                            {this.state.inValidSettingName && <div className="email-error">{'Enter a valid and unique value'}</div>}
                        {/* </div> */}
                    </Col>
                </Row>
                <div className="confirm__action mt-10">
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick} disabled={!this.isSettingNameValid()}>
                        Save
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Cancel
                    </CustomButton>
                </div>
            </div>
        );
    }

    isSettingNameValid = () => {
        const checkValidValue = this.state.value.replaceAll(' ', '').length > 0;
        if(this.state.value.length > 0 && checkValidValue && !(this.props.settingsTypeData.filter((item) => item.settingTypeDesc.toLowerCase().trim() === this.state.value.toLowerCase().trim() && item.defaultLocation === this.state.jurisdiction).length > 0)) {
            return true;
        }
        return false;
    }

    handleChange = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ value: value });
        const checkValidValue = value.replaceAll(' ', '').length > 0;
        if(value.length > 0 && checkValidValue && !(this.props.settingsTypeData.filter((item) => item.settingTypeDesc.toLowerCase().trim() === value.toLowerCase().trim() && item.defaultLocation === this.state.jurisdiction).length > 0)) {
            this.setState({ inValidSettingName: '' });
        } else {
            this.setState({ inValidSettingName: 'invalid-value' });
        }
    };

    handleClick = () => {
        const data = {value: this.state.value, jurisdiction : this.state.jurisdiction.label}
        this.props.onSuccessClick(data);
        this.props.onClose();
    };
}
