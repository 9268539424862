import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { AddEditLineSegmentMaintenanceStore } from '../stores/add-edit-line-segment-maintenance-store';
import {
    GridOptions,
    ColDef,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { LineSegmentMaintenanceResponse } from '../model/model';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/line-segment.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { addEditLineSegmentMaintenanceStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import moment from 'moment';
import { ReactSelect } from 'common/components/select/select';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';
import { errorHandler } from 'shared/handlers/error-handler';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface AddEditLineSegmentMaintenanceProps {
    addEditLineSegmentMaintenanceStore: AddEditLineSegmentMaintenanceStore;
}

interface AddEditLineSegmentMaintenanceState {
    search: string;
    lastAddedRemarksID: number;
    lastAddedRelationshipID: number;
}

@inject('addEditLineSegmentMaintenanceStore')
@observer
export class AddEditLineSegmentMaintenance extends Component<AddEditLineSegmentMaintenanceProps, AddEditLineSegmentMaintenanceState> {
    constructor(props: AddEditLineSegmentMaintenanceProps | Readonly<AddEditLineSegmentMaintenanceProps>) {
        super(props);
        this.state = {
            search: '',
            lastAddedRemarksID: 0,
            lastAddedRelationshipID: 0
        };
        dataStore.setModuleName('Line Segment Maintenance')
    }
    
    async componentDidMount() {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      const previousLineSegment = history?.location?.state as { from?: string }
      await addEditLineSegmentMaintenanceStore.init();
      addEditLineSegmentMaintenanceStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {addEditLineSegmentMaintenanceStore} = this.props;
      const filters = localStorageService.get('Line Segment Maintenance_filter');
      this.props.addEditLineSegmentMaintenanceStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Line Segment Maintenance_' + accountStore.userName);
      this.props.addEditLineSegmentMaintenanceStore.agGridService.setColumnVisibility(columnVisible);
    }
    
    componentWillUnmount() {
      const {addEditLineSegmentMaintenanceStore} = this.props;
      addEditLineSegmentMaintenanceStore.resetLineSegmentDetails();
    }
    
  remarkColDef() {
    const remarkColDef: ColDef[] = [
      {
        headerName: 'Remarks',
        field: 'remark',
        colId: 'remark',
        filter: true,
        sortable: true,
        editable: !addEditLineSegmentMaintenanceStore.isSaveDisabled(),
        width: 300,
        cellClass: (params) => { return 'cell-editable' },
        valueGetter: params => {
          if (params.data['remark'] === null) {
            return '';
          } else {
            return params.data['remark'];
          }
        },
        flex: 1
      },
      {
        headerName: 'User Name',
        field: 'lastUpdatedBy',
        colId: 'lastUpdatedBy',
        filter: true,
        sortable: true,
        editable: false,
        width: 200,
        cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
        valueGetter: params => {
          if (params.data['lastUpdatedBy'] === null) {
            return '';
          } else {
            return params.data['lastUpdatedBy'];
          }
        }
      },
      {
        headerName: 'Date',
        field: 'lastUpdatedDate',
        colId: 'lastUpdatedDate',
        filter: true,
        sortable: true,
        editable: false,
        width: 200,
        cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
        valueGetter: params => {
          if (params.data['lastUpdatedDate'] === null) {
            return '';
          } else {
            return momenttimezone
              .tz(moment.utc(params.data['lastUpdatedDate']), AppConstant.CST_TIME_FORMATTER)
              .format(AppConstant.DATE_TIME_FORMAT);
          }
        }
      },
    ];
    return remarkColDef;
  }

  AddLinkerDetailsColDef() {
    const AddLinkerDetailsColDef: ColDef[] = [
      {
        headerName: 'Select',
        field: 'select',
        colId: 'select',
        headerComponent: (params) => {
          return (
            <input
              type="checkbox"
              onChange={(e) => addEditLineSegmentMaintenanceStore.headerCheckBoxSelection(e)}
              checked={!addEditLineSegmentMaintenanceStore.checkIfHeaderCheckboxShouldbeChecked(params)}
            />
          );
        },
        cellRenderer: (params) => {
          return (
            <input
              type="checkbox"
              onChange={(e) => addEditLineSegmentMaintenanceStore.linkedPipelineCheckboxHandler(params, e)}
              checked={params.data.isSelected}
            />
          );
        },
        cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
        maxWidth: 40,
        flex: 1,
        suppressSizeToFit: true,
        cellStyle: () => { return { padding: '10px' } }
      },
      {
        headerName: 'System/Area',
        field: 'linkerSystem',
        colId: 'linkerSystem',
        filter: true,
        sortable: true,
        editable: !addEditLineSegmentMaintenanceStore.isSaveDisabled(),
        cellEditor: PredictiveSelectCellEditor,
        flex: 1,
        singleClickEdit: true,
        minWidth: 100,
        cellEditorParams: (params) => {
          return {
            dropDownValues: addEditLineSegmentMaintenanceStore.pipelineSystemDropdownOption.map(x => x.label)
          };
        },
        valueGetter: params => {
          if (params.data['linkerSystem'] === null) {
            return '';
          } else {
            return params.data['linkerSystem'];
          }
        },
      },
      {
        headerName: 'Line Segment',
        field: 'linkerLineSegment',
        colId: 'linkerLineSegment',
        filter: true,
        sortable: true,
        minWidth: 200,
        editable: !addEditLineSegmentMaintenanceStore.isSaveDisabled(),
        flex: 2,
        cellEditor: PredictiveSelectCellEditor,
        singleClickEdit: true,
        cellEditorParams: (params) => {
          return {
            dropDownValues: addEditLineSegmentMaintenanceStore.lineSegmentDescData.filter(x => x.relatedBaseSystem ? x.relatedBaseSystem == params.data['linkerSystem'] : x.startBaseSystem == params.data['linkerSystem']).map(x => x.lineSegDesc)
          };
        },
        valueGetter: params => {
          if (params.data['linkerLineSegment'] === null) {
            return '';
          } else {
            return params.data['linkerLineSegment'];
          }
        },
      },
      {
        headerName: 'Relationship Type',
        field: 'linkerRelationshipType',
        colId: 'linkerRelationshipType',
        filter: true,
        sortable: true,
        editable: !addEditLineSegmentMaintenanceStore.isSaveDisabled(),
        headerClass: 'text-center',
        flex: 2,
        minWidth: 150,
        cellEditor: PredictiveSelectCellEditor,
        singleClickEdit: true,
        cellEditorParams: (params) => {
          return {
            dropDownValues: addEditLineSegmentMaintenanceStore.lineSegmentRelationshipData.map(x => x.label)
          };
        },
        valueGetter: params => {
          if (params.data['linkerRelationshipType'] === null) {
            return '';
          } else {
            return params.data['linkerRelationshipType'];
          }
        },
      }
    ];
    return AddLinkerDetailsColDef;
  }
    
    onClickAddNewRelationship = () => {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      const tariffRelLineSegID = this.state.lastAddedRelationshipID - 1;
      const rowData = [{
          tariffRelLineSegID: tariffRelLineSegID,
          linkerSystem: '',
          linkerLineSegment: '',
          linkerRelationshipType: '',
        }, ...addEditLineSegmentMaintenanceStore.AddOrEditLinkerGridData];
        addEditLineSegmentMaintenanceStore.setLinkerGridData(rowData);
        this.setState({
          lastAddedRelationshipID: tariffRelLineSegID
        });
        addEditLineSegmentMaintenanceStore.addValuesInCellDropdowns();
        addEditLineSegmentMaintenanceStore.agGridServiceForAddOrEditLinker.getNodes()?.refreshCells();   
        addEditLineSegmentMaintenanceStore.scrollToFocusTheNewCellRelationship();
        addEditLineSegmentMaintenanceStore.checkIfHeaderCheckboxShouldbeChecked();
    };
    
    onClickAddRemark = () => {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      const rowData = [{
          lsCommentID: this.state.lastAddedRemarksID - 1,
          lastUpdatedBy: addEditLineSegmentMaintenanceStore.userName,
          lastUpdatedDate: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
          remark: '',
      } as unknown, ...addEditLineSegmentMaintenanceStore.RemarksGridData];
      addEditLineSegmentMaintenanceStore.setRemarksGridData(rowData);
    }
  
    onClickSaveLineSegment = () => {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.saveLineSegment();
    }
    
    onClickDeactivateLineSegment = () => {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.deactivateLineSegment();
    }
    
    async pushToVmacs(env) {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      const requestBody: any = addEditLineSegmentMaintenanceStore.addOrEditLinkerGridData.filter(a => addEditLineSegmentMaintenanceStore.getUpdatedRowIDsForLinker().includes(a.tariffRelLineSegID.toString()));
      const updatedRowIDs = addEditLineSegmentMaintenanceStore.remarksGridData.filter(a => addEditLineSegmentMaintenanceStore.getUpdatedRowIDsForRemark().includes(a.lsCommentID.toString()));
      if((addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit !== '' &&( (requestBody.length > 0 && !requestBody.some((item) => item.syncStatus === SyncStatus[2])) || (requestBody.length <= 0 && addEditLineSegmentMaintenanceStore.editableLSData.syncStatus && addEditLineSegmentMaintenanceStore.editableLSData.syncStatus !== SyncStatus[2])))) {
        if(((addEditLineSegmentMaintenanceStore.editableLSData.syncStatus && addEditLineSegmentMaintenanceStore.editableLSData.syncStatus=== SyncStatus[0])) && env === 'Push LineSegment to Vmacs Prod' && addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit !== '' ) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmWithCustomComponent(
              () => {},
              ConfirmWithOK,
              {secondaryText: 'line segment has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
              'confirmPopup'
            );
          } else {
              const confirmService = new ConfirmService();
              confirmService.showConfirmDialog(
                () => addEditLineSegmentMaintenanceStore.pushSelectedRowsToVmacs(env),
                'Active',
                `Are you sure you want to push the selected data to the ${env} Environment?`
            );
          }
      } else {
        const secondaryText = 'Please select only the active and unsynced line segment to perform the operation';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
  }
    
  onClickClose = () => {
    const { addEditLineSegmentMaintenanceStore } = this.props;
    addEditLineSegmentMaintenanceStore.onCloseLineSegmentDetails();
  }
    
    onChangeProjectName(e){
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.selectedTariffProjectForAddEdit = e.label;
      addEditLineSegmentMaintenanceStore.selectedWorkflowId = e.value == 'Select' ? 0 : e.value;
      addEditLineSegmentMaintenanceStore.getPipelineSystemDropdown();
    }
  
    onChangeSystemArea(e){
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit = e.label;
      addEditLineSegmentMaintenanceStore.relSelectedPipelineSystemForAddEdit = e.label;

      const basePipelineId = addEditLineSegmentMaintenanceStore.pipelineSystemData.filter(x => x.plName == e.label)[0].basePipelineID;

      addEditLineSegmentMaintenanceStore.getBaseSystemDropdown(basePipelineId);
      addEditLineSegmentMaintenanceStore.getLineSegmentDescForLS(e.label);
      addEditLineSegmentMaintenanceStore.selectedBeginningBaseSystemForAddEdit = '';
      addEditLineSegmentMaintenanceStore.selectedEndingBaseSystemForAddEdit = '';
      addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit = '';
      addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit = '';
      addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit = '';
      addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit = '';
    }
    
    onChangeSegmentType(e){
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit = e.label;
      if (e.label != 'Tariff Segment' && addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit) {
        const lastChars = addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit.substr(addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit.length - 2);
        if (lastChars == '-T') {
          addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit = addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit.slice(0, -2);
        }
      } else if (e.label == 'Tariff Segment' && addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit) {
        addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit = addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit + '-T';
      }
    }
  
    onChangeBeginningBaseSystem(e) {
        const { addEditLineSegmentMaintenanceStore } = this.props;
       if(e.value && e.value !== 'Select') {
        addEditLineSegmentMaintenanceStore.selectedBeginningBaseSystemForAddEdit = e.value;
        const baseDetails = addEditLineSegmentMaintenanceStore.baseSystemData.find((item) => item.plName === e.value)
        const pipelineID = baseDetails.pipelineID ? baseDetails.pipelineID : null;
        const a = pipelineID ? addEditLineSegmentMaintenanceStore.locationData.filter((x) => x.pipelineID === pipelineID) : addEditLineSegmentMaintenanceStore.locationData;
        const b = a.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        addEditLineSegmentMaintenanceStore.beginningLocationDropdownOption = b;
        addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit = '';
        addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit = '';
       } else {
        const b = addEditLineSegmentMaintenanceStore.locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        addEditLineSegmentMaintenanceStore.beginningLocationDropdownOption = b;
        addEditLineSegmentMaintenanceStore.selectedBeginningBaseSystemForAddEdit = null;
       }
       addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit = undefined;
    }
    
    onChangeEndingBaseSystem(e) {
        const { addEditLineSegmentMaintenanceStore } = this.props;
       if(e.value && e.value !== 'Select') {
        addEditLineSegmentMaintenanceStore.selectedEndingBaseSystemForAddEdit = e.value;
        const baseDetails = addEditLineSegmentMaintenanceStore.baseSystemData.find((item) => item.plName === e.value)
        const pipelineID = baseDetails.pipelineID ? baseDetails.pipelineID : null;
        const a = pipelineID ? addEditLineSegmentMaintenanceStore.locationData.filter((x) => x.pipelineID === pipelineID) : addEditLineSegmentMaintenanceStore.locationData;
        const b = a.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        addEditLineSegmentMaintenanceStore.endingLocationDropdownOption = b;
        addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit = '';
        addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit = '';
       } else {
        const b = addEditLineSegmentMaintenanceStore.locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        addEditLineSegmentMaintenanceStore.endingLocationDropdownOption = b;
        addEditLineSegmentMaintenanceStore.selectedEndingBaseSystemForAddEdit = null;
       }
       addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit = undefined;
    }
    
    onChangeBeginningLocation(e) {
        const { addEditLineSegmentMaintenanceStore } = this.props;
       if (e.value && e.value !== 'Select' ) {
         if (addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit && addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit !== 'Select') {
           const tariffSegmentText = addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit == 'Tariff Segment' ? '-T' : '';
           addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit = e.value + '-' + addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit + tariffSegmentText;
         }
          addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit = e.value ;
          addEditLineSegmentMaintenanceStore.getBeginningCPTariff(e.value)
          addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit = '';
       } else {
            addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit = null;
            addEditLineSegmentMaintenanceStore.beginningCPTypeDropdownOption = [];
       }
    }
    
    onChangeEndingLocation(e) {
        const { addEditLineSegmentMaintenanceStore } = this.props;
       if (e.value && e.value !== 'Select' ) {
         if (addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit && addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit !== 'Select') {
           const tariffSegmentText = addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit == 'Tariff Segment' ? '-T' : '';
           addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit = addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit + '-' + e.value + tariffSegmentText;
         }
        addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit = e.value ;
        addEditLineSegmentMaintenanceStore.getEndingCPTariff(e.value)
        addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit = '';
       } else {
            addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit = null;
            addEditLineSegmentMaintenanceStore.endingCPTypeDropdownOption = [];
       }
    }
    
    onChangeBeginningCP(e) {
        const { addEditLineSegmentMaintenanceStore } = this.props;
        if (e.value && e.value !== 'Select' ) {
            addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit = e.label
        } else {
            addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit = null;
        }
    }
    
    onChangeEndingCP(e) {
        const { addEditLineSegmentMaintenanceStore } = this.props;
        if (e.value && e.value !== 'Select' ) {
            addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit = e.label
        } else {
            addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit = null;
        }
    }
    
    onChangeOpType(e){
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.selectedOperationType = e;
      addEditLineSegmentMaintenanceStore.selectedOperationTypeCode = e.value;
    }
  
    onClearRelationshipRow = () => {
      const filterValidRows = this.props.addEditLineSegmentMaintenanceStore.addOrEditLinkerGridData.filter((item) => !item.isSelected);
      this.props.addEditLineSegmentMaintenanceStore.setLinkerGridData(filterValidRows);
      this.props.addEditLineSegmentMaintenanceStore.addValuesInCellDropdowns();
      this.props.addEditLineSegmentMaintenanceStore.agGridServiceForAddOrEditLinker.getNodes()?.refreshCells();   
      this.props.addEditLineSegmentMaintenanceStore.checkIfHeaderCheckboxShouldbeChecked()
    }


    onChangeLineSegment = (e) => {
      this.props.addEditLineSegmentMaintenanceStore.onChangeLineSegmentDescription(e.target.value)
    }
    
    handleClose = () => {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.isShowModal = false;
      addEditLineSegmentMaintenanceStore.enteredRemarkForMile = '';
      // addEditLineSegmentMaintenanceStore.selectedMiles = addEditLineSegmentMaintenanceStore.prevSelectedMiles;
    }
  
    onChangeRemarkForMile(e){
      addEditLineSegmentMaintenanceStore.enteredRemarkForMile = e.target.value;
    }
  
    onChangeMiles = (e) => {
      // addEditLineSegmentMaintenanceStore.onChangeMiles(e.target.value);
      const { addEditLineSegmentMaintenanceStore } = this.props;
      if (e.target.value.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/) && e.target.value.slice(-1) !== '.') {
        addEditLineSegmentMaintenanceStore.prevSelectedMiles = addEditLineSegmentMaintenanceStore.selectedMiles;
        addEditLineSegmentMaintenanceStore.selectedMiles = e.target.value;
      } else {
        this.props.addEditLineSegmentMaintenanceStore.incorrectMiles();
      }
    }
    
    onEditMiles = (e) => {
      if (e.target.value.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/) && e.target.value.slice(-1) !== '.') {
        if (addEditLineSegmentMaintenanceStore.isEditingLineSegment && addEditLineSegmentMaintenanceStore.prevSelectedMiles != e.target.value) {
          addEditLineSegmentMaintenanceStore.isShowModal = true;
          addEditLineSegmentMaintenanceStore.selectedMiles = e.target.value;
        } else {
          addEditLineSegmentMaintenanceStore.selectedMiles = e.target.value;
          addEditLineSegmentMaintenanceStore.prevSelectedMiles = e.target.value;
        }
      } else {
        this.props.addEditLineSegmentMaintenanceStore.incorrectMiles();
      }
    }
    
    onUpdateMiles(){
      if (addEditLineSegmentMaintenanceStore.enteredRemarkForMile && addEditLineSegmentMaintenanceStore.enteredRemarkForMile.length > 0) {
        addEditLineSegmentMaintenanceStore.prevSelectedMiles = addEditLineSegmentMaintenanceStore.selectedMiles;
        const milesRemark = {
          'lsCommentID': 0,
          'remark': addEditLineSegmentMaintenanceStore.enteredRemarkForMile,
          'lastUpdatedBy': addEditLineSegmentMaintenanceStore.userName,
          'lastUpdatedDate': moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')
        };
        addEditLineSegmentMaintenanceStore.remarksGridData = [milesRemark, ...addEditLineSegmentMaintenanceStore.remarksGridData]
        addEditLineSegmentMaintenanceStore.isShowModal = false;
        addEditLineSegmentMaintenanceStore.enteredRemarkForMile = '';
      } else {
        errorHandler('Please enter a valid Remark value.');
      }      
    }
    
    onBiDirectionalCheckClicked = (e) => {
      const checked = e.target.checked;
      addEditLineSegmentMaintenanceStore.isBiDirectionalChecked = checked;
    }
    
    onChangeCapacity = (e) => {
      if (e.target.value.match(/^[0-9]*$/) && e.target.value.slice(-1) !== '.') {
        this.props.addEditLineSegmentMaintenanceStore.onChangeCapacity(e.target.value)
      } else {
        this.props.addEditLineSegmentMaintenanceStore.incorrectCapacity();
      }
    }
    
    onSelectServiceDate = (e) => {
        this.props.addEditLineSegmentMaintenanceStore.onSelectServiceDate(e);
    };
    
    onChangeSegmentStatus(e){
      const { addEditLineSegmentMaintenanceStore } = this.props;
      addEditLineSegmentMaintenanceStore.selectedStatusForAddEdit = e.value;
    }
    
    render(): React.ReactNode {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      return (
        <Container fluid>
          {this.renderLineSegmentMaintenanceScreen()}
        </Container>
      );
    }

    renderLineSegmentMaintenanceScreen() : React.ReactNode {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='lineSegmentMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Line Segment Details</h2>
                </div>
                
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                  <label className="standard_label_style">Related Tariff Project</label>
                  <ReactSelect
                      className='select'
                      values={addEditLineSegmentMaintenanceStore.selectedTariffProjectForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedTariffProjectForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedTariffProjectForAddEdit} : {label: 'Select', value:  'Select'}}
                      placeholder={'Select'}
                      options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.shownProjectNames]}
                      onChange={(e: any) => { this.onChangeProjectName(e)}}
                      isMulti={false}
                      isSearchable={true}
                      disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled() || (addEditLineSegmentMaintenanceStore.isFromProjectRequest && addEditLineSegmentMaintenanceStore.selectedTariffProjectForAddEdit)}
                  />
                  <label className="standard_label_style">System/Area</label>
                  <ReactSelect
                      className='select  expandselect'
                      values={addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit} : {label: 'Select', value:  'Select'}}
                      placeholder={'Select'}
                      options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.pipelineSystemDropdownOption]}
                      onChange={(e: any) => { this.onChangeSystemArea(e)}}
                      isMulti={false}
                      isSearchable={true}
                      disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                  />
                  <label className="standard_label_style">Segment Type</label>
                  <ReactSelect
                      className='select'
                      values={addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit} : {label: 'Select', value:  'Select'}}
                      placeholder={'Select'}
                      options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.lineSegTypeDropdownOption]}
                      onChange={(e: any) => { this.onChangeSegmentType(e)}}
                      isMulti={false}
                      isSearchable={true}
                      disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                  />
                </div>
              </div>
            </div>
            <div className='lineSegmentMaintenanceBox'>
              <div className='selectContainer'>
                  <Row>
                    <Col><label className="standard_label_style">Beginning</label></Col>
                    <Col></Col>
                    <Col><label className="standard_label_style">Ending</label></Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="standard_label_style">Base System</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedBeginningBaseSystemForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedBeginningBaseSystemForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedBeginningBaseSystemForAddEdit} : {label: 'Select', value:  'Select'}}
                          placeholder={'Select'}
                          options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.baseSystemDropdownOption]}
                          onChange={(e: any) => { this.onChangeBeginningBaseSystem(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                    <Col></Col>
                    <Col>
                      <label className="standard_label_style">Base System</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedEndingBaseSystemForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedEndingBaseSystemForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedEndingBaseSystemForAddEdit} : {label: 'Select', value:  'Select'}}
                          placeholder={'Select'}
                          options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.baseSystemDropdownOption]}
                          onChange={(e: any) => { this.onChangeEndingBaseSystem(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="standard_label_style">Location</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit} : {label: 'Select', value:  'Select'}}
                          placeholder={'Select'}
                          options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.beginningLocationDropdownOption]}
                          onChange={(e: any) => { this.onChangeBeginningLocation(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                    <Col>
                      <label className="standard_label_style">Segment Description</label>
                      <input 
                          type="text"
                          value={addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit}
                          className="expanded_search_text_field"
                          onChange = {(e) => this.onChangeLineSegment(e)}
                          readOnly={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                        />
                    </Col>
                    <Col>
                      <label className="standard_label_style">Location</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit} : {label: 'Select', value:  'Select'}}
                          placeholder={'Select'}
                          options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.endingLocationDropdownOption]}
                          onChange={(e: any) => { this.onChangeEndingLocation(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="standard_label_style">CP</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit} : {label: 'Select', value:  'Select'}}
                          placeholder={'Select'}
                          options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.beginningCPTypeDropdownOption]}
                          onChange={(e: any) => { this.onChangeBeginningCP(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                    <Col></Col>
                    <Col>
                      <label className="standard_label_style">CP</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit} : {label: 'Select', value:  'Select'}}
                          placeholder={'Select'}
                          options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.endingCPTypeDropdownOption]}
                          onChange={(e: any) => { this.onChangeEndingCP(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                  </Row>
                </div>
            </div>
            <div className='lineSegmentMaintenanceBox'>
              <div className='selectContainer'>
                  <Row>
                    <Col>
                      <label className="standard_label_style">Segment Operation Type</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedOperationType}
                          placeholder={'Select'}
                          options={addEditLineSegmentMaintenanceStore.operationTypeDropdownOption}
                          onChange={(e: any) => { this.onChangeOpType(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                    <Col>
                      <label className="standard_label_style">Miles</label>
                      <input 
                          type="number"
                          min="0"
                          value={addEditLineSegmentMaintenanceStore.selectedMiles}
                          className="search_text_field"
                          onChange={(e) => this.onChangeMiles(e)}
                          onBlur={(e) => this.onEditMiles(e)}
                          readOnly={addEditLineSegmentMaintenanceStore.isSaveDisabled() || addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit == 'Tariff Segment'}
                        />
                    </Col>
                    <Col>
                      <label className="standard_label_style">Bi-Directional</label>
                      <input
                        type="checkbox"
                        onChange={(e) => this.onBiDirectionalCheckClicked(e)}
                        checked={addEditLineSegmentMaintenanceStore.isBiDirectionalChecked}
                        disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label className="standard_label_style">Capacity</label>
                      <input 
                          type="number"
                          min="0"
                          step='1'
                          value={addEditLineSegmentMaintenanceStore.enteredCapacity}
                          className="search_text_field"
                          onChange = {(e) => this.onChangeCapacity(e)}
                          readOnly={addEditLineSegmentMaintenanceStore.isSaveDisabled() || addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit == 'Tariff Segment'}
                        />
                    </Col>
                    <Col className='flex'>
                      <label className="standard_label_style">Service Date</label>
                      <ReactDatePicker
                          selectedDate={addEditLineSegmentMaintenanceStore.selectedServiceDate}
                          onDateChange={(e) => {
                              this.onSelectServiceDate(e);
                          }}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                    <Col>
                      <label className="standard_label_style">Segment Status</label>
                      <ReactSelect
                          className='select'
                          values={addEditLineSegmentMaintenanceStore.selectedStatusForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedStatusForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedStatusForAddEdit} : {label: 'Select', value:  'Select'}}
                          placeholder={'Select'}
                          options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.lineSegStatusDropdownOption]}
                          onChange={(e: any) => { this.onChangeSegmentStatus(e)}}
                          isMulti={false}
                          isSearchable={true}
                          disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}
                      />
                    </Col>
                  </Row>
                  <Row className='searchButtonDiv' hidden={!addEditLineSegmentMaintenanceStore.isEditingLineSegment}>
                    <Col>
                      <label className="standard_label_style">Last Updated By:</label>
                      <label className="standard_label_style">{ addEditLineSegmentMaintenanceStore.editableLSData.updateUser }</label>
                    </Col>
                    <Col>
                      <label className="standard_label_style">Last Updated:</label>
                      <label className="standard_label_style">{ addEditLineSegmentMaintenanceStore.editableLSData.updateDate }</label>
                    </Col>
                    <Col></Col>
                    <Col></Col>
                  </Row>
              </div>
            </div>
            <div className='screenHeader'>
              <h2 className='screen_title'>Remarks</h2>
            </div>
            <div className='locationMaintenanceBox'>
                <div className='pipelineLinkerBox' style={{marginTop: '0px'}}>
                    <Row style={{margin:'20px', width: '60%', height: '40%'}}>
                        <AgGridComponent gridConfig={this.getGridConfig('Line Segment Remark')} />
                    </Row>
                    <div style={{marginTop: '12px'}}>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onClickAddRemark} disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}>
                          Add Remark
                      </CustomButton>
                    </div>
                </div>
            </div>
              
              <div style={{'marginTop':'20px'}}>
                    <div className='screenHeader'>
                        <h2 className='screen_title'>Related Line Segment</h2>
                    </div>
              <div className='lineSegmentMaintenanceBox'>
                <div className='selectContainer'>
                  <Row>
                        <Col>
                            <label className="standard_label_style" style={{paddingBottom: '6px'}}>System/Area</label>
                            <div style={{paddingTop: '10px'}}>
                              <ReactSelect
                                  className='select expandselect'
                                  values={addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit ? {label: addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit, value: addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit} : {label: 'Select', value:  'Select'}}
                                  placeholder={'Select'}
                                  options={[{value:'Select', label:'Select'},...addEditLineSegmentMaintenanceStore.pipelineSystemDropdownOption]}
                                  onChange={(e: any) => { this.onChangeSystemArea(e)}}
                                  isMulti={false}
                                  isSearchable={true}
                                  disabled={true}
                                />
                              </div>
                        </Col>
                        <Col>
                          <label className="standard_label_style" style={{paddingBottom: '6px'}}>Segment Description</label>
                          <input 
                              type="text"
                              style={{paddingBottom: '10px'}}
                              value={addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit}
                              className="expanded_search_text_field"
                              readOnly={true}
                            />
                        </Col>
                        <Col>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickAddNewRelationship} disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}>
                                Add New Relationship
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.onClearRelationshipRow()} disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}>
                              Clear
                            </CustomButton>
                    </Col>
                    <Col></Col>
                    </Row>
                    
                  </div>
              </div>

              
            <div className='locationMaintenanceBox'>
                <div className='pipelineLinkerBox'>
                    <div className="rotatedText">
                      Linker
                    </div>
                    <Row style={{margin:'20px', width: '60%', height: '40%'}}>
                      <AgGridComponent gridConfig={this.getAddLinkerDetailsGridConfig('Line Segment Details')}/>
                    </Row>
                </div>
              </div>


                <div className='selectContainer'>
                  <div className='searchButtonDiv'>
                        <div className='otherHeaderButtonsDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickSaveLineSegment} disabled={addEditLineSegmentMaintenanceStore.isSaveDisabled()}>
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickDeactivateLineSegment} disabled= {addEditLineSegmentMaintenanceStore.isDeleteDisabled()}>
                                Deactivate
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push LineSegment to Vmacs Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Push LineSegment to Vmacs Test')}>
                                Push to Test
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push LineSegment to Vmacs Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Push LineSegment to Vmacs Prod')}>
                                Push to Prod
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickClose} disabled= {false}>
                                Close
                            </CustomButton>
                          </div>
                      </div>
              </div>
            </div>
            <Modal className='modal-lg' show={addEditLineSegmentMaintenanceStore.isShowModal} onHide={this.handleClose}>
              <Modal.Header className='screenHeader' closeButton>
                <Modal.Title className='screen_title'>Update Miles Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <div>
                <label className="standard_label_style">Please provide remark to update Mile value.</label>
              </div>
              <div>
                  <label className="standard_label_style">Remark*</label>
                  <input 
                    type="text"
                    style={{width:'400px', marginLeft:'10px'}}
                    value={addEditLineSegmentMaintenanceStore.enteredRemarkForMile}
                    className="expanded_search_text_field"
                    onChange = {(e) => this.onChangeRemarkForMile(e)}
                  />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.handleClose}>
                      Close
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onUpdateMiles}>
                    Update
                  </CustomButton>
                </Modal.Footer>
            </Modal>
            </Col>
        </Row>
      );
    }


    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        addEditLineSegmentMaintenanceStore.setIsActivateButtonDisabled(addEditLineSegmentMaintenanceStore.isSaveDisabled())
      } else {
        addEditLineSegmentMaintenanceStore.setIsActivateButtonDisabled(true)
      }
    }
    
    onCellEditingStartedForRemark = (event: CellEditingStartedEvent): void => {
      const row = event.data.lsCommentID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          addEditLineSegmentMaintenanceStore: { cellValueChangeMapForRemark }
      } = this.props;
      const isNotEmpty =
          row in cellValueChangeMapForRemark && col in cellValueChangeMapForRemark[row] && cellValueChangeMapForRemark[row][col].initValue;
      if (!isNotEmpty) {
          this.props.addEditLineSegmentMaintenanceStore.setValueInChangeMapForRemark(row, col, value, value);
      }
    };

    onCellEditingStoppedForRemark = (event: CellEditingStoppedEvent): void => {
      const row = event.data.lsCommentID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
        addEditLineSegmentMaintenanceStore: { cellValueChangeMapForRemark, updateRowforAddOrUpdateGridForRemark, getColDef}
      } = this.props;
      cellValueChangeMapForRemark[row][col]['currentValue'] = value;
      updateRowforAddOrUpdateGridForRemark(event.data);
      this.props.addEditLineSegmentMaintenanceStore.agGridServiceForAddOrEditRemark.getNodes()?.refreshCells();
    };
  
    onCellEditingStartedForLinker = (event: CellEditingStartedEvent): void => {
      const row = event.data.tariffRelLineSegID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          addEditLineSegmentMaintenanceStore: { cellValueChangeMap }
      } = this.props;
      const isNotEmpty =
          row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
      if (!isNotEmpty) {
          this.props.addEditLineSegmentMaintenanceStore.setValueInChangeMap(row, col, value, value);
      }
    };

    onCellEditingStoppedForLinker = (event: CellEditingStoppedEvent): void => {
      const row = event.data.tariffRelLineSegID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
        addEditLineSegmentMaintenanceStore: { cellValueChangeMap, updateRowforAddOrUpdateGrid, getColDef}
      } = this.props;
      cellValueChangeMap[row][col]['currentValue'] = value;
      if (col == 'linkerSystem' && event.data.linkerSystem) {
        addEditLineSegmentMaintenanceStore.getLineSegmentDesc(event.data.linkerSystem);
      }
      updateRowforAddOrUpdateGrid(event.data);
      this.props.addEditLineSegmentMaintenanceStore.agGridServiceForAddOrEditLinker.getNodes()?.refreshCells();
    };
    
    private getAddLinkerDetailsGridConfig(gridId): GridOptions {
      const {
          addEditLineSegmentMaintenanceStore: { AddOrEditLinkerGridData, agGridServiceForAddOrEditLinker }
      } = this.props;
      const { addEditLineSegmentMaintenanceStore } = this.props;
      const columnDefs = this.AddLinkerDetailsColDef();
      const rowData = AddOrEditLinkerGridData;
      const onCellEditingStarted = this.onCellEditingStartedForLinker;
      const onCellEditingStopped = this.onCellEditingStoppedForLinker;
      const linkerGridParams: any = {
        rowData,
        columnDefs,
        onCellEditingStarted,
        onCellEditingStopped
      };
      return agGridServiceForAddOrEditLinker.getGridConfig(linkerGridParams, gridId);
    }

    private getGridConfig(gridId): GridOptions {
      const {
          addEditLineSegmentMaintenanceStore: { RemarksGridData, agGridServiceForAddOrEditRemark }
      } = this.props;
      const { addEditLineSegmentMaintenanceStore } = this.props;
      const columnDefs = this.remarkColDef();
      const rowData = RemarksGridData;
      const onCellEditingStarted = this.onCellEditingStartedForRemark;
      const onCellEditingStopped = this.onCellEditingStoppedForRemark;
      const remarkGridParams: any = {
        rowData,
        columnDefs,
        onCellEditingStarted,
        onCellEditingStopped
      };
      return agGridServiceForAddOrEditRemark.getGridConfig(remarkGridParams, gridId);
    }
    
    pushButtonDisabled(env) {
      const { addEditLineSegmentMaintenanceStore } = this.props;
      if(env === 'Push LineSegment to Vmacs Test') {
        return  !addEditLineSegmentMaintenanceStore.editableLSData || (!addEditLineSegmentMaintenanceStore.editableLSData.lineSegID || addEditLineSegmentMaintenanceStore.editableLSData.lineSegID === 0) || (addEditLineSegmentMaintenanceStore.editableLSData.syncStatus && (addEditLineSegmentMaintenanceStore.editableLSData.syncStatus === SyncStatus[1] || addEditLineSegmentMaintenanceStore.editableLSData.syncStatus === SyncStatus[2])); 
      }
        return  !addEditLineSegmentMaintenanceStore.editableLSData || (!addEditLineSegmentMaintenanceStore.editableLSData.lineSegID || addEditLineSegmentMaintenanceStore.editableLSData.lineSegID === 0) || ( addEditLineSegmentMaintenanceStore.editableLSData.syncStatus && addEditLineSegmentMaintenanceStore.editableLSData.syncStatus === SyncStatus[2]);
    }
}
