import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { lineSegmentColDef } from '../components/line-segment-col-def';
import moment from 'moment';
import Path from '../../../../Path';
// import Path, { LineSegmentSettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { LineSegmentMaintenanceDataService } from '../services/line-segment-maintenance-data-service';
import { LineSegmentMaintenanceResponse } from '../model/model';
import { LineSegmentMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { addEditLineSegmentMaintenanceStore, lineSegmentMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
// import { AddLineSegmentDetailsColDef } from '../components/add-lineSegment-details-container';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class LineSegmentMaintenanceDataStore {
    @observable lineSegmentMaintenanceData: LineSegmentMaintenanceResponse[] = [];
    @observable modifiedLineSegmentMaintenanceData: LineSegmentMaintenanceResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupLineSegmentMaintenanceData: LineSegmentMaintenanceResponse[] = [];
    @observable isRefreshed = false;
    @observable showModal = false;
    @observable selectedType = { value: 'All', label: 'All' };
    @observable selectedDescription = '';
    @observable selectedStatus = { value: 'All', label: 'All' };
    @observable selectedPipelineSystem = { value: 'All', label: 'All' };
    endDate: any = moment().toISOString().slice(0, 10);
    @observable statusDropdownOptions: any[] = ServiceStatus;
    lineSegmentTypeData: any[] = [];
    pipelineSystemData: any[] = [];
    lineSegmentStatusData: any[] = [];
    @observable operationTypeDropdownOption: any = [];
    @observable projectNamesData: any = [];
    defaultLoggedInUserLocation = '';
    @observable isSearchClicked = false;
    @observable selectedPipelineSystemForAddEdit = '';
    @observable selectedTariffProjectForAddEdit = '';
    @observable selectedBaseSystemForAddEdit = '';
    @observable selectedLocationForAddEdit = '';
    @observable selectedConnectionPointForAddEdit = '';
    
    @observable pipelineSystemDropdownOption: any[] = [];
    @observable lineSegTypeDropdownOption: any[] = [];
    @observable lineSegStatusDropdownOption: any[] = [];
    @observable selectedLineSegmentDetails: any = {};
    @observable addOrEditedLineSegName = '';
    @observable selectedLineSegmentId = 0;
    @observable selectedSegmentIds: any[] = [];
    @observable addOrEditLineSegmentGridData: any[] = [];
    @observable backupAddOrEditLineSegmentGridData: any[] = [];
    @observable onClickLineSegmentNameOnGrid = false;
    @observable onClickAddNewLineSegmentButton = false;
    @observable isActivateButtonDisabled = true;
    @observable updatedFieldonLineSegmentDetailsScreen = false;
    @observable selectedlineSegment = ''
    @observable locationData: any = [];
    @observable baseSystemData: any = [];

    constructor(
        public lineSegmentMaintenanceDataService: LineSegmentMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditLineSegment: AgGridService
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        this.defaultLoggedInUserLocation = this.accountStore.defaultUserLocation;
        dataStore.setShowUnsavedWarningAlert(false);
        this.getPipelineSystemDropdown();
        this.getLineSegmentStatusData();
        this.getLineSegmentTypeData();
        this.getSettingsData();
        // this.getProjectNames();
        this.selectedType = { value: 'All', label: 'All' };
        this.selectedDescription = '';
        this.selectedStatus = { value: 'All', label: 'All' };
        this.selectedPipelineSystem = { value: 'All', label: 'All' };
        this.userName = this.accountStore.displayName;
        this.selectedLineSegmentId = 0;
        this.selectedLineSegmentDetails = {};
    }

    // @Catch(() => errorHandler(ProfitCenterMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel() {
        const pageSize = this.agGridService.paginationGetPageSize();
        if(dataStore.isPageSizeChanged) {
            this.agGridService.resetCache(Number(pageSize));
        }
        const dataSource = {
            getRows: (params) => {
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
                const pageSize = this.agGridService.paginationGetPageSize();
                // console.log('loadViewtriggered');
                const page =  params.endRow / (pageSize ? pageSize : 100);
                const pageNo = this.isRefreshed || dataStore.isPageSizeChanged || page <= 1 ? 1 : parseInt(page.toFixed(0));
                const data = this.getapiRequest(pageNo, pageSize, params.sortModel);
                const filterRequestBody: any = [];
                for (const [key, value] of Object.entries(params.filterModel)) {
                    console.log(key, value);
                    const item = {
                        propertyName: key,
                        value: value && value['filterType'] === 'date'? value['dateFrom'] : value ? value['filter']:'',
                        operator: value ? value['type'] : '',
                        joinPrev: 'none'
                    
                      }
                      filterRequestBody.push(item);
                  }
                  console.log('params' +JSON.stringify(filterRequestBody));
                this.agGridService.showLoadingOverlay();
                this.lineSegmentMaintenanceDataService.getLineSegmentsData(data, filterRequestBody).then((response) => {
                const totalCount = response && response.length > 0 ? response[0].totalCount : response.length
                    params.successCallback(response ? response : [], totalCount);
                    dataStore.setCacheBlockSize(response? response.length : 0)
                    this.agGridService.hideOverlay();
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false); 
                }).catch(() => {
                    params.successCallback([], 0);
                    dataStore.setCacheBlockSize(0);
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false);
                    this.agGridService.hideOverlay();
                    errorHandler(LineSegmentMaintenanceMessages.FETCH_ERROR_MESSAGE)
                });
                dataStore.setCacheBlockSize(Number(pageSize));
            }
        }
        this.agGridService.setDatasource(dataSource);
    }

    getapiRequest = (currentPage, pageSize, sortModel?) => {
        if(this.isSearchClicked) {
            const area = this.selectedPipelineSystem.label === 'All' ? 0 : this.pipelineSystemData.filter((item) => item.plName === this.selectedPipelineSystem.label)[0].pipelineID;
            const lineSegType = this.selectedType.label === 'All' ? 0 : this.lineSegmentTypeData.filter((item) => item.valueText === this.selectedType.label)[0].settingID;
            const lineSegDesc = this.selectedDescription;
            const lineSegStatus = this.selectedStatus.label === 'All' ? 0 : this.lineSegmentStatusData.filter((item) => item.valueText === this.selectedStatus.label)[0].settingID;
            const data = {
                area: area, 
                lineSegType: lineSegType,
                lineSegDesc: lineSegDesc,
                lineSegStatus: lineSegStatus,
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        } else {
            const data = {
                area: 0, 
                lineSegType: 0,
                lineSegDesc: '',
                lineSegStatus: 0,
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        }
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineSystemDropdown(): Promise<void> {
        const pipelineSystemDropdownOptions = await this.lineSegmentMaintenanceDataService.getPipelineSystems();
        this.pipelineSystemData = pipelineSystemDropdownOptions;
        const a = (pipelineSystemDropdownOptions.map(x => x.plName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.pipelineSystemDropdownOption = option;
        });
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSettingsData(): Promise<void> {
        const response = await this.lineSegmentMaintenanceDataService.getMiscellaneousSettingsData(this.defaultLoggedInUserLocation);
        const filteredResponse = response.filter((item) => item.settingName === 'TariffLineSegmentOpTypes');
        const a = filteredResponse.map(x => { return { 'value': x.valueCode, 'label': x.valueText } });
        this.operationTypeDropdownOption = a;
    }

    // @Loader
    // @action
    // async getProjectNames(): Promise<void> {
    //     const projectNamesData = await this.lineSegmentMaintenanceDataService.getProjectNames();
    //     this.projectNamesData = projectNamesData;
    // }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLineSegmentTypeData(): Promise<void> {
        const lineSegTypeDropdownOption = await this.lineSegmentMaintenanceDataService.getLineSegmentTypeData();
        this.lineSegmentTypeData = lineSegTypeDropdownOption;
        const a = (lineSegTypeDropdownOption.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.lineSegTypeDropdownOption = option;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLineSegmentStatusData(): Promise<void> {
        const lineSegStatusDropdownOption = await this.lineSegmentMaintenanceDataService.getLineSegmentStatusData();
        this.lineSegmentStatusData = lineSegStatusDropdownOption;
        const a = (lineSegStatusDropdownOption.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.lineSegStatusDropdownOption = option;
        });
    }


    @action
    createDataSourceGroupByLineSegment(response) {
        const array = response
        const result = array.reduce((r, { LineSegName: LineSegName, ...object }) => {
        let temp = r.find(o => o.LineSegName === LineSegName);
        if (!temp) r.push(temp = { LineSegName, children: [] });
        temp.children.push(object);
        return r;
    }, []);
    
    console.log(result);
    }

    @Loader
    @Catch(() => errorHandler(LineSegmentMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        this.isSearchClicked = false;
            this.isRefreshed = true;
           
            dataStore.onGridReady(); 
            
            this.selectedType = { value: 'All', label: 'All' };
            this.selectedDescription = '';
            this.selectedPipelineSystem = { value: 'All', label: 'All' };
            this.selectedStatus = { value: 'All', label: 'All' };
    }

    @Loader
    @Catch(() => errorHandler(LineSegmentMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
           this.isRefreshed = true;
           this.isSearchClicked = true;
           dataStore.onGridReady();
    }

    getColDef() {
        // this.updatePushtoVmacsButtonColDef();
        return lineSegmentColDef;
    }

    // getAddLineSegmentDetailsColDef() {
    //     this.updatePushtoVmacsButtonColDef();
    //     return AddLineSegmentDetailsColDef;
    // }

    @action
    reset(): void {
        this.setLineSegmentMaintenanceData(this.backupLineSegmentMaintenanceData);
        this.modifiedLineSegmentMaintenanceData = this.backupLineSegmentMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setLineSegmentMaintenanceData(lineSegmentMaintenanceData: LineSegmentMaintenanceResponse[]): void {
        this.lineSegmentMaintenanceData = lineSegmentMaintenanceData
    }

    setbackupLineSegmentMaintenanceData(backUpList: LineSegmentMaintenanceResponse[]) {
        this.backupLineSegmentMaintenanceData = backUpList;
    }

    @computed
    get LineSegmentMaintenanceData() {
        return toJS(this.lineSegmentMaintenanceData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    // @Loader
    // @action
    // @Catch(() =>  errorHandler(LineSegmentMaintenanceMessages.FAILED_SUBMIT))
    // async saveLineSegment(saveAndAddNew): Promise<void> {
    //     const updatedRowIDs = this.addOrEditLineSegmentGridData.filter(a => a.isSelected === true);
    //     const lineSegmentDetailDTOs: any = [];
    //     let requestBody: any = {};
    //     const localValidatingErrorBody: any = [];
    //     if(this.addOrEditedLineSegName && this.addOrEditedLineSegName !== '') {
    //         const filteredLineSegmentData = this.lineSegmentMaintenanceData.filter((lineSegment) => lineSegment.lineSegmentType === this.addOrEditedLineSegName);
    //         const existingLineSegment  = filteredLineSegmentData.some((lineSegment) => lineSegment.lineSegmentID !==  this.selectedLineSegmentId)
    //         const isUniqueLineSegment = this.onClickLineSegmentNameOnGrid ? !existingLineSegment : filteredLineSegmentData.length <=0;
    //         if(isUniqueLineSegment) {
    //             updatedRowIDs.forEach((item) => {
    //                 const serviceDate = item.serviceDate ? moment.utc(new Date(item.serviceDate)).format('YYYY-MM-DDTHH:mm:ss')  : null;
    //                 item.lineSegmentRelPLID = item.lineSegmentRelPLID && item.lineSegmentRelPLID > 0 ? item.lineSegmentRelPLID: 0,
    //                 item.pipelineID = item.pipelineID && item.pipelineID > 0 ? item.pipelineID : 0,
    //                 item.serviceDate = serviceDate,
    //                 item.serviceStatus = item.serviceStatus === 'Active' || item.serviceStatus === 'A' ?  'A' : 'I'
    //                 if(item.serviceDate) {
    //                     lineSegmentDetailDTOs.push(item);
    //                 } else {
    //                     const message = 'Please select valid service date for the linked pipelines';
    //                     if(!localValidatingErrorBody.includes(message)) {
    //                         localValidatingErrorBody.push(message);
    //                     }
    //                 }
    //             });
    //         } else {
    //             const message = 'Tried to create duplicate lineSegment. Please create the unique record.';
    //             if(!localValidatingErrorBody.includes(message)) {
    //                 localValidatingErrorBody.push(message);
    //             }
    //         } 
    //     } else {
    //         const message = 'Please insert valid LineSegment Name as mandatory fields.';
    //         if(!localValidatingErrorBody.includes(message)) {
    //             localValidatingErrorBody.push(message);
    //         }
    //     }
    //     if(localValidatingErrorBody.length > 0) {
    //         const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
    //         errorHandler(messages);
    //     } else if(updatedRowIDs.length === lineSegmentDetailDTOs.length) {
    //         requestBody = { lineSegmentID: this.selectedLineSegmentId, 
    //             LineSegName: this.addOrEditedLineSegName, 
    //             createUser: this.userName,
    //             lineSegmentDetailDTOs: lineSegmentDetailDTOs,
    //         } 
    //         const response: LineSegmentMaintenanceResponse[] = await this.lineSegmentMaintenanceDataService.updateLineSegmentMaintenanceData(requestBody, this.onClickLineSegmentNameOnGrid);
    //         if (response) {
    //             dataStore.setShowUnsavedWarningAlert(false);
    //             this.onClickLineSegmentNameOnGrid = false; 
    //             this.cellValueChangeMap = {};
    //             this.selectedLineSegmentId = 0;
    //             this.selectedLineSegmentDetails = {};
    //             this.updatedFieldonLineSegmentDetailsScreen = false;
    //             this.uiService.toastService.success(LineSegmentMaintenanceMessages.SAVE);
    //             if(!saveAndAddNew) {
    //                 this.showLoader = false;
    //                 this.isRefreshed = false;
    //                 this.agGridServiceForAddOrEditLineSegment.destroyGrid();
    //                 this.onClickAddNewLineSegmentButton = false;
    //                 this.onCloseLineSegmentDetails();
    //             } else {
    //                 this.resetAddLineSegmentMaintenanceGridData();
    //             }

    //         }
    //     }
    // }


    @action
    async resetAddLineSegmentMaintenanceGridData(): Promise<void> {
            this.addOrEditedLineSegName = '';
            this.addOrEditLineSegmentGridData = [];
            this.backupAddOrEditLineSegmentGridData = []
            this.pipelineSystemData.map((item) =>
             {
                item.serviceStatus =  'Active',
                item.serviceDate =  null,
                item.allowTrades = 'N'
                item.isSelected = false
                this.addOrEditLineSegmentGridData.push(item)
            });
            this.backupAddOrEditLineSegmentGridData = this.addOrEditLineSegmentGridData;
            this.onClickLineSegmentNameOnGrid = false;
            this.isRefreshed = false
        this.agGridServiceForAddOrEditLineSegment.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(LineSegmentMaintenanceMessages.FAILED_SUBMIT))
    async deleteLineSegment(): Promise<void> {
        const requestBody = { 
            lineSegmentID: this.selectedLineSegmentId, 
            LineSegName:this.addOrEditedLineSegName,
            createUser: this.userName, 
            lineSegmentDetailDTOs: [] 
        }
        await this.lineSegmentMaintenanceDataService.deleteLineSegmentMaintenanceData(requestBody).then((response) => {
            if(response && response.value) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                  () => {console.log('')},
                  ConfirmWithOK,
                  {secondaryText: response.value !== '' ? response.value : 'Record cannot be deleted because it is linked to a base system. Unlink lineSegment from base system.'} as ConfirmWithOKProps,
                  'confirmPopup'
                );
                const lineSegmentData = {LineSegName: lineSegmentMaintenanceDataStore.addOrEditedLineSegName}
             //   lineSegmentMaintenanceDataStore.openLineSegmentDetails(lineSegmentData);
                return ;
            }
            dataStore.setShowUnsavedWarningAlert(false);
            this.onClickLineSegmentNameOnGrid = false;
            this.onClickAddNewLineSegmentButton = false;
            this.agGridServiceForAddOrEditLineSegment.destroyGrid();
            this.isActivateButtonDisabled= true;
            this.updatedFieldonLineSegmentDetailsScreen = false;
            this.onCloseLineSegmentDetails();
        });
    }



 addValuesInCellDropdowns(): void {
        // const baseSystemDropdownOptions = this.selectedLineSegName !== 'All' ? this.baseSystemDropdownOptions.filter((item) => item.valueText  === this.selectedLineSegName) : this.baseSystemDropdownOptions;
        // this.agGridService.updateOptionCellEditorValues(
        //     baseSystemDropdownOptions,
        //     'pipeline',
        //     'plName'
        // );

        this.agGridServiceForAddOrEditLineSegment.updateOptionCellEditorValues(
            ServiceStatus,
            'serviceStatus',
            'status'
        );
    }
     

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
      //  get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
       // this.updateRow(item);
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.LineSegmentMaintenance);
    };

    isDeleteDisabled = ()  => {
      const requestBody: any = this.addOrEditLineSegmentGridData.filter(a => a.isSelected === true);
      console.log(requestBody);
        return !this.selectedLineSegmentId || requestBody.length > 0;
    };

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    // updatePushtoVmacsButtonColDef = () => {
    //     const pushToVmacsButtonColDef = lineSegmentColDef.find(x => x.colId === 'PushToVMACS');
    //     if (pushToVmacsButtonColDef) {
    //         pushToVmacsButtonColDef.hide = !dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSync) ? true : false,
    //         pushToVmacsButtonColDef.suppressColumnsToolPanel = !dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSync) ? true : false,
    //         pushToVmacsButtonColDef.cellRendererParams = {
    //             dataStore : lineSegmentMaintenanceDataStore,
    //             handleClickPushVmacs : (env, data) => this.pushToVmacs(env, data),
    //             updatedData : this.getUpdatedRows() 
    //         };
    //     }
    // }

    // getUpdatedRows = () => {
    //     const updatedRowIDs = this.lineSegmentMaintenanceData.filter(a => this.getUpdatedRowIDs().includes(a.lineSegmentID.toString()));
    //     return updatedRowIDs;
    // }

    // @Loader
    // @action
    // @Catch((err) => {
    //     if(err && err.response.data.cts_response) {
    //         let msg = '';
    //         err.response.data.cts_response.forEach((item) => {
    //             msg = msg + ' ' + (item.detail ? item.detail : '');
    //         })
    //         errorHandler(msg);
    //     }
    // })
    // async pushToVmacs(env, data): Promise<void> {
    //     // const action = data.action === 'U' ? 'Update' : 'Insert';
    //     const lineSegmentRelPLID =  data.lineSegmentRelPLID === 0 ? '' :  data.lineSegmentRelPLID.toString();
    //     const requestBody: any =  { 
    //         'http_action' : null,
    //         'http_record_id': data.lineSegmentID.toString(),
    //         'http_childrecord_id': lineSegmentRelPLID,
    //        // 'http_cts_tablename': LineSegmentSettingsDBTableName.TableName,
    //        // 'http_cts_mappingtablename' : LineSegmentSettingsDBTableName.MappingTableName,
    //         'http_userid': this.userName,
    //         'http_environment': env.toUpperCase() 
    //     };
    //     console.log('pushtoVMacs')
    //     if(requestBody.length !== 0) {
    //         const response = await this.lineSegmentMaintenanceDataService.pushToVmacs(requestBody);
    //         // if(response) {
    //             await this.onSearchClick();
    //             this.uiService.toastService.success(LineSegmentMaintenanceMessages.SYNC);
    //         // }
    //     }

    // }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const selectedRowData = this.addOrEditLineSegmentGridData;
        if(selectedRowData && selectedRowData.length > 0) {
            const lineSegments = this.selectedLineSegmentId.toString();
            const linkedpipelines= (selectedRowData.filter((item) => item.lineSegmentRelPLID && item.lineSegmentRelPLID !== 0).map(x => x.lineSegmentRelPLID )).join(',');
            const requestBody: any =  { 
                'http_action' : null,
                'http_record_id': lineSegments,
                'http_childrecord_id': linkedpipelines,
              //  'http_cts_tablename': LineSegmentSettingsDBTableName.TableName,
              //  'http_cts_mappingtablename' : LineSegmentSettingsDBTableName.MappingTableName,
                'http_userid': this.userName,
                'http_environment': env.toUpperCase()   
            };
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.lineSegmentMaintenanceDataService.pushToVmacs(requestBody);
                // if(response) {
                    // await this.onSearchClick();
                    // this.openLineSegmentDetails({LineSegName: this.addOrEditedLineSegName});
                    this.updatedFieldonLineSegmentDetailsScreen = false;
                    this.uiService.toastService.success(LineSegmentMaintenanceMessages.SYNC);
                // }
            }
    
        }
       
    }

    @action
   isEditableJurisdiction(params) {
        if (params.data.lineSegmentID <= 0) {
        return true
        }
        return false;
   }

   @action
   async onChangeLineSegment(value) {
        this.selectedDescription = value;
   }

   @action
   async onChangeLineSegmentName(value) {
        this.selectedlineSegment = value;
   }
   
   @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async activateLineSegment(): Promise<void> {
        const requestBody = this.selectedSegmentIds;
        const userName = this.userName;
        const response: any = await this.lineSegmentMaintenanceDataService.activateLineSegment(requestBody, userName);
       if (response && response.value) {
            this.uiService.toastService.error('There is an error while activating Line Segment details.')
       } else {
           this.uiService.toastService.success('Line Segment activated successfully.')
           this.onSearchClick();
        }
    }
   
   mapEditableColumnsLineSegmentsMainGrid(currentItem: any, updatedItem: any) {
    currentItem.lineSegmentRelPLID = updatedItem.lineSegmentRelPLID;
    currentItem.lineSegmentID = updatedItem.lineSegmentID;
    currentItem.LineSegName = updatedItem.LineSegName;
    currentItem.LineSegCode = updatedItem.LineSegCode;
    currentItem.pipelineID = updatedItem.pipelineID;
    currentItem.plName = updatedItem.plName;
    currentItem.syncStatus = updatedItem.syncStatus;
    currentItem.testSyncDate = updatedItem.testSyncDate;
    currentItem.prodSyncDate = updatedItem.prodSyncDate;
    currentItem.serviceStatus = updatedItem.serviceStatus;
    currentItem.allowTrades = updatedItem.allowTrades;
    currentItem.serviceDate = updatedItem.serviceDate;
    currentItem.action = updatedItem.action;
}

isActivateDeactivateDisabled = ()  => {
    return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
};


//    @Loader
//     @action
//     @Catch(() => errorHandler(LineSegmentMaintenanceMessages.FAILED_SUBMIT))
//     async updateStatusFromDelToActive(): Promise<void> {
//         const selectedRowIDs: any = await this.agGridService.getSelectedRows();
//         const requestBody: any = [];
//         const localValidatingErrorBody: any = [];
//         selectedRowIDs.forEach((item) => {
//             if(item.LineSegName && item.LineSegName !== '') {
//                 const isUniqueLineSegment = this.lineSegmentMaintenanceData.filter((lineSegment) => item.lineSegmentType === lineSegment.lineSegmentType).length <= 1;
//                 if(isUniqueLineSegment) {
//                     item.createUser =  this.userName,
//                     item.lineSegmentID = item.lineSegmentID ? item.lineSegmentID : 0,
//                     item.action = item.action && item.action === 'D' ? 'U' : item.action,
//                     item.lineSegmentDetailDTOs = []
//                     requestBody.push(item);
//                 } else {
//                     const message = `Tried to create duplicate lineSegment with LineSegment Code as ${item.LineSegCode} and LineSegment Name as ${item.LineSegName}. Please create the unique record.`;
//                     if(!localValidatingErrorBody.includes(message)) {
//                         localValidatingErrorBody.push(message);
//                     }
//                 }
//             } else {
//                 const message = 'Please insert valid LineSegment Name as mandatory fields.';
//                 if(!localValidatingErrorBody.includes(message)) {
//                     localValidatingErrorBody.push(message);
//                 }
//             }
//         });
//         if(localValidatingErrorBody.length > 0) {
//             const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
//             errorHandler(messages);
//         } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
//             const response: LineSegmentMaintenanceResponse[] = await this.lineSegmentMaintenanceDataService.updateStatusFromDelToActive(requestBody);
//             let errorInSave = false;
//             let errorSummary: ToastMessage[] = [];
//             if (response) {
//                 for (const key in response) {
//                     if (response[key].lineSegmentID) {
//                         const objIndex = this.lineSegmentMaintenanceData.findIndex(a => ((a.lineSegmentID === response[key].lineSegmentID)|| (a.lineSegmentType === response[key].lineSegmentType && a.lineSegmentDesc === response[key].lineSegmentDesc)));
//                         if(objIndex >= 0) {
//                             const backupItem = this.lineSegmentMaintenanceData[objIndex];
//                             this.mapEditableColumnsLineSegmentsMainGrid(backupItem, response[key]);
//                         }
//                     }
//                     else {
//                         errorInSave = true;
//                         errorSummary = [...errorSummary, { id: key, description: response[key].lineSegmentID.toString() }];
//                     }
//                 }
//             }
//             this.cellValueChangeMap = {};
//             if (errorInSave) {
//                 this.uiService.toastService.error('', {}, errorSummary);
//                 errorSummary.forEach(errorMessage => {
//                     for (let i = 0; i < this.lineSegmentMaintenanceData.length; i++) {
//                         const updatedData = this.lineSegmentMaintenanceData[i];
//                         if (updatedData.lineSegmentID.toString() === errorMessage.id) {
//                             const backupRowIndex = this.lineSegmentMaintenanceData.findIndex(a => a.lineSegmentID.toString() == errorMessage.id);
//                             this.mapEditableColumnsLineSegmentsMainGrid(updatedData, this.lineSegmentMaintenanceData[backupRowIndex]);
//                         }
//                     }
//                 });
//             }
//             else {
//                 this.uiService.toastService.success(LineSegmentMaintenanceMessages.SAVE);
//             }
//             runInAction(() => {
//                 this.showLoader = false;
//                 this.isRefreshed = false;
//                 this.isActivateButtonDisabled= true;
//                 dataStore.setShowUnsavedWarningAlert(false);
//             })  
//         }
//     }

//     @Loader
//     @action
//     async openLineSegmentDetails(data) {
//         this.agGridService.destroyGrid();
       
//             const selectedLineSegName = data.LineSegName;
//             const response: LineSegmentMaintenanceResponse[] = await this.lineSegmentMaintenanceDataService.getLineSegmentsDataWithFilter(selectedLineSegName, 0, 'All' );
//             if(response) {
//                 runInAction(() => {
//                     this.showLoader = false;
//                     this.addOrEditLineSegmentGridData = [];
//                     this.backupAddOrEditLineSegmentGridData = [];
//                     this.cellValueChangeMap = {};
//                     const lineSegmentData = response.filter((item) => item.LineSegName === data.LineSegName);
//                     this.selectedLineSegmentDetails = lineSegmentData.length > 0 ? lineSegmentData[0] : {};
//                     this.addOrEditedLineSegName = lineSegmentData[0].LineSegName;
//                     this.selectedLineSegmentId = lineSegmentData[0].lineSegmentID;
//                     this.baseSystemData.map((item) =>
//                      {
//                         const linkedPipeline = lineSegmentData.filter((lineSegment) => lineSegment.pipelineID === item.pipelineID)
//                         const serviceStatus = linkedPipeline.length > 0  && linkedPipeline[0].serviceStatus ? linkedPipeline[0].serviceStatus !== 'A' ? 'Inactive' : 'Active' :'Active'
//                         item.serviceStatus =  serviceStatus
//                         item.serviceDate = linkedPipeline.length > 0 ? linkedPipeline[0].serviceDate : null,
//                         item.allowTrades = linkedPipeline.length > 0 ? linkedPipeline[0].allowTrades : 'N',
//                         item.lineSegmentRelPLID = linkedPipeline.length > 0 ? linkedPipeline[0].lineSegmentRelPLID : 0,
//                         item.isSelected = linkedPipeline.length > 0 ? true : false,
//                         item.syncStatus = linkedPipeline.length > 0 ? linkedPipeline[0].syncStatus : SyncStatus[0]
//                         this.addOrEditLineSegmentGridData.push(item);
//                     });
//                     this.backupAddOrEditLineSegmentGridData = this.addOrEditLineSegmentGridData;
//                     this.isRefreshed = false;
//                     this.onClickLineSegmentNameOnGrid = true;
//                     dataStore.setShowUnsavedWarningAlert(false);
//                     history.push('/LineSegmentDetails');
//                 })
//             } else {
//                 this.getSelectedLineSegmentData();
//             }
           
//         // }
        
// ;    }

    @action
    onCloseLineSegmentDetails() {
        console.log(dataStore.showUnsavedWarningAlert);
        history.push('/LineSegmentMaintenance', { from: '/AddEditLineSegmentMaintenance' });   
    }


    @Loader
    @Catch(() => errorHandler(LineSegmentMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async getSelectedLineSegmentData(): Promise<void> {
            runInAction(() => {
                this.addOrEditedLineSegName = '';
                this.addOrEditLineSegmentGridData = [];
                this.backupAddOrEditLineSegmentGridData = [];
                this.cellValueChangeMap = {};
                this.pipelineSystemData.map((item) =>
                 {
                    item.lineSegmentRelPLID = 0,
                    item.serviceStatus =  'Active',
                    item.serviceDate =  null,
                    item.allowTrades = 'N',
                    item.isSelected = false,
                    item.syncStatus = SyncStatus[0]
                    this.addOrEditLineSegmentGridData.push(item)
                });
                this.backupAddOrEditLineSegmentGridData = this.addOrEditLineSegmentGridData;
                this.isRefreshed = false;
            })
            this.agGridService.destroyGrid();
        this.agGridServiceForAddOrEditLineSegment.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
   async onEnterLineSegmentforAddOREdit(value) {
        if(this.addOrEditedLineSegName !== value) {
            this.updatedFieldonLineSegmentDetailsScreen = value
        }
        this.addOrEditedLineSegName = value   
   }

   @computed
    get AddOrEditLineSegmentGridData() {
        return toJS(this.addOrEditLineSegmentGridData);
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditLineSegmentGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditLineSegmentGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsLineSegmentDetailsGrid(this.addOrEditLineSegmentGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsLineSegmentDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.serviceStatus = updatedItem.serviceStatus;
        currentItem.allowTrades = updatedItem.allowTrades;
        currentItem.isSelected = updatedItem.isSelected
        currentItem.serviceDate = updatedItem.serviceDate;
    }

    handleAllowTradeCheckBoxChange(params, value) {
        const row = params.data.pipelineID;
        const initValue = params.data['allowTrades'];
        this.setValueInChangeMap(row, 'allowTrades', initValue, value);
        if(initValue === 'Y') {
            params.data['allowTrades'] = 'N'
        } else {
            params.data['allowTrades'] = 'Y'
        }
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data)
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected
        this.setValueInChangeMap(row, 'isSelected', initValue, checked);
        params.data.isSelected = checked;
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data);
    };

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.lineSegmentMaintenanceDataService.getBaseSystems();
        if(baseSystemDropdownOptions) {
            this.baseSystemData = baseSystemDropdownOptions;
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLocationDropdown(): Promise<void> {
        const locationDropdownOptions = await this.lineSegmentMaintenanceDataService.getLocationsData();
        if (locationDropdownOptions) {
            this.locationData = locationDropdownOptions;
        }
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async openLineSegmentDetails(data) {
        await this.getBaseSystemDropdown();
        await this.getLocationDropdown();
        const lsId = data.lineSegID;
        const response: any = await this.lineSegmentMaintenanceDataService.getLineSegmentDetails(lsId);
        const opType = this.operationTypeDropdownOption.filter(x => x.value == response.segmentOperationType)[0];
        // const projectName = response.workflowId != 0 ? this.projectNamesData.filter(x => x.workflowID == response.workflowId)[0]?.name : '';
        response.tariffRelLineSegs.map(res => {
            res.linkerSystem = res.baseLineSegPipelineName;
            res.linkerLineSegment = res.baseLineSegDescription;
            res.linkerRelationshipType = res.relSegTypeText;
        })

        runInAction(() => {
            addEditLineSegmentMaintenanceStore.isEditingLineSegment = true;
            addEditLineSegmentMaintenanceStore.editableLSData = response;
            addEditLineSegmentMaintenanceStore.selectedLineSegmentId = data.lineSegID;
            addEditLineSegmentMaintenanceStore.selectedTariffProjectForAddEdit = response.projectName;
            addEditLineSegmentMaintenanceStore.selectedWorkflowId = response.workflowId;
            addEditLineSegmentMaintenanceStore.selectedPipelineSystemForAddEdit = response.systemAreaText;
            addEditLineSegmentMaintenanceStore.selectedSegmentTypeForAddEdit = response.segmentTypeText;
            addEditLineSegmentMaintenanceStore.selectedDescriptionForAddEdit = response.segmentDescription;
            addEditLineSegmentMaintenanceStore.selectedBeginningBaseSystemForAddEdit = response.beginingBaseSystemText;
            addEditLineSegmentMaintenanceStore.selectedEndingBaseSystemForAddEdit = response.endingBaseSystemText;

            addEditLineSegmentMaintenanceStore.locationData = this.locationData;
            const baseDetails = this.baseSystemData.find((item) => item.plName === response.beginingBaseSystemText)
            const pipelineID = baseDetails.pipelineID ? baseDetails.pipelineID : null;
            const a = pipelineID ? this.locationData.filter((x) => x.pipelineID === pipelineID) : this.locationData;
            const b = a.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
            addEditLineSegmentMaintenanceStore.beginningLocationDropdownOption = b;

            const baseDetailsEnding = this.baseSystemData.find((item) => item.plName === response.beginingBaseSystemText)
            const pipelineIDEnding = baseDetailsEnding.pipelineID ? baseDetailsEnding.pipelineID : null;
            const c = pipelineIDEnding ? this.locationData.filter((x) => x.pipelineID === pipelineIDEnding) : this.locationData;
            const d = c.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
            addEditLineSegmentMaintenanceStore.endingLocationDropdownOption = d;

            addEditLineSegmentMaintenanceStore.selectedBeginningLocationForAddEdit = response.beginingLocationText;
            addEditLineSegmentMaintenanceStore.selectedEndingLocationForAddEdit = response.endingLocationText;
            addEditLineSegmentMaintenanceStore.selectedBeginningCPForAddEdit = response.beginingCPText;
            addEditLineSegmentMaintenanceStore.selectedEndingCPForAddEdit = response.endingCPText;
            addEditLineSegmentMaintenanceStore.selectedOperationType = { value: opType.value, label: opType.label };
            addEditLineSegmentMaintenanceStore.selectedOperationTypeCode = response.segmentOperationType;
            addEditLineSegmentMaintenanceStore.selectedMiles = response.miles;
            addEditLineSegmentMaintenanceStore.prevSelectedMiles = response.miles;
            addEditLineSegmentMaintenanceStore.enteredCapacity = response.capacity;
            addEditLineSegmentMaintenanceStore.isBiDirectionalChecked = response.biDirectional == 'Y' ? true : false;
            addEditLineSegmentMaintenanceStore.selectedServiceDate = new Date(response.serviceDate);
            addEditLineSegmentMaintenanceStore.selectedStatusForAddEdit = response.segmentStatusText;
            addEditLineSegmentMaintenanceStore.remarksGridData = response.tariffLSComments;
            addEditLineSegmentMaintenanceStore.addOrEditLinkerGridData = response.tariffRelLineSegs;
            history.push('/AddEditLineSegmentMaintenance', {from: '/LineSegmentMaintenance', data : data})
        })
    }
}
