import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { RightAngleXRefDataStore } from '../stores/right-angle-xref-data-store';
import './right-angle-xref.scss';
import { dataStore } from '../../../../common/stores';
import { rightAngleXRefDataStore } from '../stores';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReactSelect } from 'common/components/select/select';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';


  /* eslint-disable */
interface AddEditRAXrefProps {
    rightAngleXRefDataStore: RightAngleXRefDataStore;
}


@inject('rightAngleXRefDataStore')
@observer
export class AddEditRAXref extends Component<AddEditRAXrefProps, any> {
    constructor(props: AddEditRAXrefProps | Readonly<AddEditRAXrefProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Add Edit RA Xref')
    }

    componentDidUpdate() {
      const {rightAngleXRefDataStore} = this.props;
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    componentWillUnmount() {
      console.log("unmount" + dataStore.showUnsavedWarningAlert);
    }

    handleClose = () => {
      rightAngleXRefDataStore.onCloseRAXrefModal();
    }
    
    handleRemoveRAXref = () => {
      rightAngleXRefDataStore.removePMCRightAngleXref();
    }  
    
    handleShow = () => rightAngleXRefDataStore.isShowModal = true;

    handleSaveRAXref = async() => 
    {
      rightAngleXRefDataStore.handleSaveRAXref();
    }

    onSelectTariffNumber = (e) => {
        rightAngleXRefDataStore.setTariffNumberForAddEdit(e);  
    };

    onChangeOperator = (e) => {
        rightAngleXRefDataStore.setOperatorForAddEdit(e);
    };
    
    onChangeRAName = (e) => {
        rightAngleXRefDataStore.setRANameForAddEdit(e.target.value);
    };

    onSelectSystemGroup = (e) => {
        rightAngleXRefDataStore.setSystemGroupForAddEdit(e);
    };

    onSelectDistributionType = (e) => {
        rightAngleXRefDataStore.setDistributionTypeForAddEdit(e);
    };

    onSelectTariffRequestType = (e) => {
        rightAngleXRefDataStore.setTariffRequestTypeForAddEdit(e);
    };

    onSelectTariffRateType = (e) => {
        rightAngleXRefDataStore.setTariffRateTypeForAddEdit(e);
    };
    
    onRemoveRAXrefConfirmationClicked = () => {
      rightAngleXRefDataStore.onRemoveRAXrefConfirmationClicked();
    };
    
    handleCloseRAXrefConfirmationModal = () => {
      rightAngleXRefDataStore.handleCloseRAXrefConfirmationModal();
    } 

    render(): React.ReactNode {
      const { rightAngleXRefDataStore } = this.props;
      
      return (
        <Container fluid>
            {this.renderAddRAXrefScreen()}
        </Container>
      );
    }

    renderAddRAXrefScreen() : React.ReactNode {
      const { rightAngleXRefDataStore } = this.props;
      const headerTitle = rightAngleXRefDataStore.isEditingRAXref ? 'Edit Right Angle Xref' : 'Add Right Angle Xref';
      return (
    <>
      <Modal className='modal-xl rightAngleXRefBox' show={rightAngleXRefDataStore.isShowModal} onHide={this.handleClose}>
        <Modal.Header className='screenHeader' closeButton>
          <Modal.Title className='screen_title'>{headerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
                <label className='standard_label_style'>Tariff Number*</label>
                <ReactSelect
                    className='select'
                    values={rightAngleXRefDataStore.selectedTariffNumberForAddEdit}
                    placeholder="Select"
                    options={rightAngleXRefDataStore.tariffNumberDropdownOptions}
                    onChange={this.onSelectTariffNumber}
                    isMulti={false}
                    isSearchable={true}
                    menuPlacement='bottom'
                />
            </Col>
            <Col>
                <label className="standard_label_style">Tariff Rate Type*</label>
                <ReactSelect
                    className='select'
                    values={rightAngleXRefDataStore.selectedTariffRateTypeForAddEdit}
                    placeholder="Select"
                    options={rightAngleXRefDataStore.tariffRateTypeDropdownOptions}
                    onChange={this.onSelectTariffRateType}
                    isMulti={false}
                    isSearchable={true}
                    menuPlacement='bottom'
                />
            </Col>
            <Col>
                <label className='standard_label_style'>RA Name*</label>
                <input
                    type='text'
                    className='expanded_text_field'
                    value={rightAngleXRefDataStore.selectedRANameForAddEdit}
                    onChange={this.onChangeRAName}
                />
            </Col>
          </Row>
          <Row>
                <Col>
                    <label className='standard_label_style'>Asset Group</label>
                    <ReactSelect
                        className='select'
                        values={rightAngleXRefDataStore.selectedTariffRequestTypeForAddEdit}
                        placeholder=""
                        options={rightAngleXRefDataStore.tariffRequestTypeDropdownOptions}
                        onChange={this.onSelectTariffRequestType}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}    
                    />
                </Col>
                <Col>
                    <label className='standard_label_style'>Distribution</label>
                    <ReactSelect
                        className='select'
                        values={rightAngleXRefDataStore.selectedDistributionTypeForAddEdit}
                        placeholder=""
                        options={rightAngleXRefDataStore.distributionTypeDropdownOptions}
                        onChange={this.onSelectDistributionType}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}
                    />
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col>
                    <label className="standard_label_style">Pipeline System</label>
                    <ReactSelect
                        className='select'
                        values={rightAngleXRefDataStore.selectedSystemGroupForAddEdit}
                        placeholder=""
                        options={rightAngleXRefDataStore.systemGroupDropdownOptions}
                        onChange={this.onSelectSystemGroup}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}
                    />
                </Col>
                <Col>
                    <label className='standard_label_style'>Operator</label>
                    <ReactSelect
                        className='select'
                        values={rightAngleXRefDataStore.selectedOperatorForAddEdit}
                        placeholder=""
                        options={rightAngleXRefDataStore.operatorDropdownOptions}
                        onChange={this.onChangeOperator}
                        isMulti={false}
                        isSearchable={true}
                        menuPlacement='bottom'
                        disabled={true}
                    />
                </Col>
                <Col></Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {rightAngleXRefDataStore.isEditingRAXref && <CustomButton type={CustomButtonType.Submit} onClick={this.handleRemoveRAXref}>
                Delete
          </CustomButton>}
          {!rightAngleXRefDataStore.isEditingRAXref && <CustomButton type={CustomButtonType.Submit} onClick={this.handleClose}>
              Close
          </CustomButton>}
          <CustomButton type={CustomButtonType.Submit} onClick={this.handleSaveRAXref}>
            Save
          </CustomButton>
        </Modal.Footer>
        </Modal>
        <Modal className='modal-lg' show={rightAngleXRefDataStore.isShowRemoveRAXrefConfirmationModal} onHide={this.handleCloseRAXrefConfirmationModal}>
          <Modal.Header className='screenHeader' closeButton>
            <Modal.Title className='screen_title'>Delete RA Xref Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>Are you sure you want to delete the selected RA Xref?</div>
          </Modal.Body>
          <Modal.Footer>
            <CustomButton type={CustomButtonType.Submit} onClick={this.handleCloseRAXrefConfirmationModal}>
              No
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} onClick={this.onRemoveRAXrefConfirmationClicked}>
              Yes
            </CustomButton>
          </Modal.Footer>
        </Modal>
    </>
  );
}
}