import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { profitCenterMaintenanceDataStore } from '../../stores';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';


export const ProfitCenterNameCellRenderer = (props: ICellRendererParams) => {    
    return (

        !dataStore.checkOperationAccess(RoleBasedActionName.View) || (props.data && props.data.action && props.data.action === 'D') ? <span>{props.data && props.data.profitCenter ? props.data.profitCenter : ''}</span> : 
            <span style={{color: 'blue'}}
            onClick={()=>profitCenterMaintenanceDataStore.openProfitCenterDetails(props.data)}
            >
                {props.data ? props.data.profitCenterDesc ==undefined?'0000000':props.data.profitCenterDesc : '' }</span>
    );
};