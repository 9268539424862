import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { connectionPointMaintenanceDataStore } from '../../stores';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';


export const ConnectionPointNameCellRenderer = (props: ICellRendererParams) => {    
    return (

        !dataStore.checkOperationAccess(RoleBasedActionName.View) || (props.data && props.data.action && props.data.action === 'D') ? <span>{props.data && props.data.cpName ? props.data.cpName : ''}</span> : 
            <span style={{color: 'blue'}}
            onClick={()=>connectionPointMaintenanceDataStore.openConnectonPointDetails(props.data)}
            >
                {props.data ? props.data.cpName ==undefined?'0000000':props.data.cpName : '' }</span>
    );
};