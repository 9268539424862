import { useState, useRef, useImperativeHandle, useEffect, forwardRef } from 'react';
import Tippy from '@tippyjs/react';
import './predictive-search.scss';
// eslint-disable-next-line
import React from 'react'; 
/** Use if using PredictiveSelectCellEditorPopper*/ 
// import { usePopper } from 'react-popper';
// import ReactDOM from 'react-dom';
// import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
// import { renderToStaticMarkup } from 'react-dom/server';

interface predictiveSearchPropTypes {
/** dropDownValues is Array of values
 *  elementName is to show element name on empty array (Don't use if using valueSetter)
 *  ONLY WORKS WITH singleClickEdit=true
 * */ 
    dropDownValues? : any
    value? : any
    elementName? : string
}
/** Sample use in conjunction with valueFormatter: 
 * 
 *  {
      headerName: 'Location',
      field: 'location',
      colId:'location',
      editable: true,
      cellEditor: PredictiveSelectCellEditor,
      singleClickEdit: true,
      cellEditorParams: (params) => {
        return {
            dropDownValues: LocationDropdown.map(x=>x.locnName),
            elementName: 'Location'
        };
      },
      valueFormatter:  (params) => {
        return params.value? params.value: LocationDropdown?.length>0  ? 'Select Location': 'No Location Found'
      },
 */


// const PredictiveSelectCellEditorPopper = forwardRef (({elementName = 'Option',...props}: predictiveSearchPropTypes, ref) => {
//     const [visible, setVisible] = useState(true);
//     const [currentValue, setCurrentValue] = useState(props.value);
//     const show = () => setVisible(true);
//     const hide = () => setVisible(false);
//     const [done, setDone] = useState(false);

//     useImperativeHandle(ref, () => {
//         return {
//             getValue() {
//                 return currentValue
//             },
//         };
//     });

//     const [referenceElement, setReferenceElement] = useState<HTMLInputElement | null>(null)
//     const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
//     const [dropdownDIVs, setDropdownDIVs] = useState<HTMLDivElement | null>(null)
//     const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
    
//     const { styles, attributes } = usePopper(referenceElement, popperElement);


//     const onClickHandler = (e, element) => {
//         setCurrentValue(element);
//         console.log(e, element, 'currentValue')
//         hide();
//     };
    
//     const DropDownContent = () => {return(
//     <>
//         <div className='menu-container'>
//         {props.dropDownValues && props.dropDownValues?.map((element,index)=> (
//             <div key={index} ref={(ref) => {
//               if(dropdownDIVs) {
//                 dropdownDIVs[index].current = ref;
//               }}} onMouseDown={(e)=>{onClickHandler(e, element)}} className='menu-item'>
//                 {element}
//             </div>
//         )) || (<div className='menu-item'>
//                 No {elementName} Found
//             </div>)}
//         </div>
//     </>
//   )}
 
//   return (
//     <>
//       <input type='text' ref={setReferenceElement} value={currentValue} style={{width: '100%', height: '100%'}} onClick={()=>{ show }} />
//       {ReactDOM.createPortal(
//         <div
//           ref={setPopperElement}
//           style={styles.popper}
//           {...attributes.popper}
//         >
//           <DropDownContent/>
//         </div>,
//         document.body as Element
//       )}
//     </>
//   );
// })
// PredictiveSelectCellEditorPopper.displayName = 'PredictiveSelectCellEditorPopper';


const PredictiveSelectCellEditor = forwardRef (({elementName = 'Option',...props}: predictiveSearchPropTypes, ref) => {
  const [visible, setVisible] = useState(true);
  const [currentValue, setCurrentValue] = useState(props.value);
  const [searchValue, setSearchValue] = useState(props.value);
  const [filteredValues, setFilteredValues] = useState(props.dropDownValues);
  const [refKeysScroll, setRefKeys] = useState<HTMLDivElement|null>(null);
  const refInput: any = useRef(null);
  
  
  const show = () => setVisible(true);
  const hide = () => setVisible(false);
  const [done, setDone] = useState(false);

  useImperativeHandle(ref, () => {
   
    if(!done) {
      return {
        scrollIntoView() {
         (refKeysScroll?.firstElementChild as HTMLDivElement).scrollIntoView();
        },
      };
    } else {
      return {
        getValue() {
            return currentValue
        },
      };
    }
     
    
  });

  const onClickHandler = (element) => {
    setDone(true);
    if(element===`Unselect ${elementName}`)
      setCurrentValue('');
    else
      setCurrentValue(element);
      refInput?.current?.focus();
    hide();
  };

  const filterSearch = (e) => {
    console.log(e);
    setSearchValue(e.target.value);
    const filteredValues = props?.dropDownValues?.filter(x=>x?.toUpperCase()?.includes(e.target?.value?.toUpperCase()))
    setFilteredValues(filteredValues);
    if(filteredValues?.length>0){
      show();
    } else {
      hide();
    }
  };

  // const keyDown = (e, index) => {
  //   if(['Space','ArrowUp','ArrowDown','ArrowLeft','ArrowRight'].indexOf(e.code) > -1) {
  //     e.preventDefault();
  //   }
  //   if (e.code == 'ArrowDown') {
  //     show();
  //     // refKeysScroll?.focus();
  //     (refKeysScroll?.firstElementChild as HTMLDivElement).focus({preventScroll: true})
  //     // document.getElementById(elementName+index)?.focus({preventScroll: true});
  //     show();
  //   }else if (e.code == 'ArrowUp') {
  //     show();
  //     document.getElementById(elementName+(index-1))?.focus({preventScroll: true});
  //     show();
  //     if(index==0){
  //       refInput?.current?.focus();
  //       show();
  //     }
  //   }
  // }

  useEffect(()=>{
    refInput?.current?.focus();
  },[])

  const onClickOutside = () => {
    setDone(true);
    hide();
  }
  const DropDownContent = (
    <>
        <div ref={setRefKeys} tabIndex={0} className='menu-container'>
          <div style ={{maxHeight: '300px', overflowY: 'scroll'}}>
        {filteredValues && filteredValues.length>0 && (elementName!=='Option' ? ([`Unselect ${elementName}`, ...filteredValues]) : [...filteredValues])?.map((element,index)=> (
            <div key={index} tabIndex={index+1} id={elementName+index+1} 
              // onKeyUp={(e)=>{keyDown(e, index+1)}} 
                onMouseDown={(e)=>{onClickHandler(element)}}  
                className='menu-item'>
                {element}
            </div>
        )) || (<div className='menu-item-disabled'>
                No {elementName} Found
            </div>)}
        </div>
        </div>
    </>
  );
    
  return (
    <Tippy
      content={DropDownContent}
      visible={visible}
      onClickOutside={onClickOutside}
      allowHTML={false}
      arrow={true}
      hideOnClick={false}
      appendTo={document.body}
      interactive={true}
      interactiveDebounce={100}
      placement='bottom-start'
      sticky= 'popper'
    >
      <input type='text' ref={refInput} value={searchValue} style={{width: '100%', height: '100%'}} 
      // onKeyUp={(e)=>{keyDown(e, -1)}} 
      onChange={(e)=>{filterSearch(e)}} onClick={()=>{ show }}/>
    </Tippy>
  );
});

PredictiveSelectCellEditor.displayName = 'PredictiveSelectCellEditorTippy';

export default PredictiveSelectCellEditor;
