import { action, observable, makeAutoObservable, toJS, computed, runInAction } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { ProjectRequestDataService } from '../services/project-request-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import moment from 'moment';
import {EMAIL_RECIPIENTS, CAN_EMAIL_RECIPIENTS} from '../../../../Path';
import { projectRequestDataStore, projectRequestStep1DataStore } from '.';
import _ from 'lodash';
import { WFAttachmentResponse, WFNotesResponse } from '../model/model';
import { dataStore } from 'common/stores';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import {tariffOptionsDataStoreProject} from '../tariff-options-project/stores/index'
import { RoleBasedActionName } from 'shared/types/enum';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { AppConstant } from 'app_constants';

export class ProjectRequestStep2DataStore {
  
    @observable pipelinesDropdown: any [] = [];
    @observable baseTariffDropdown: any [] = [];
    @observable tariffLogDropdown: any[] = [];
    @observable cpDropdown: any[] = [];
    @observable inactiveCpDropdown: any[] = [];
    @observable locationDropdown: any[] = [];
    @observable inactiveLocationDropdown: any[] = [];
    @observable profitCenterDropdown: any[] = [];
    @observable gradeMaintenanceDropdown: any[] = [];
    @observable customerMaintenanceDropdown: any[] = [];
    @observable agencyDropdown: any[] = [];
    @observable lineSegmentDropdown: any[] = [];
    @observable inactiveLineSegmentDropdown: any[] = [];
    
    @observable loader = false;
    @observable forwardButtonText = 'Go forward';
    @observable activeTab = 'tariffSetup';

    @observable infraSummary: any;

    @observable tariffOptions: any[] = [];

    @observable href='';

    @observable selectedTariffs: any = [];
    @observable selectedTariffIDs: any = [];
    @observable selectedProjectRequestType;
    @observable workflowId;
    @observable workflowStepId;
    @observable workflowStepFlowId;
    @observable username;
    @observable location;
    @observable showLoader = false;
    @observable workflowDetails;
    @observable workflowStatusGridData: any [] = [];
    @observable linkedWorkflowData: any [] = [];
    @observable stepDescription = '';
    @observable workflowstepName = '';
    @observable workflowstepOrder = '';
    @observable statusOrHistory = true;
    @observable baseLinked = false;
    @observable tariffLogLinked = false;
    @observable pipelineLinked = false;
    @observable connectionPointLinked = false;
    @observable locationLinked = false;
    @observable profitCenterLinked = false;
    @observable gradeMaintenanceLinked=false;
    @observable tariffOptionLinked=false;
    @observable gmLinked = false;
    @observable agencyLinked = false;
    @observable lineSegmentLinked = false;
    @observable customerMaintenanceLinked=false;
    @observable showTariffReviw = false;
    @observable backupDescription = '';
    @observable selectedTariffRateType = '';
    @observable workflowStepStatus = '';
    @observable workflowstepRole = '';
    @observable selectedSystemGroup;
    @observable systemGroupDropdownOptions: any[] = [];
    @observable selectedPipelineGroup;
    @observable pipelineGroupDropdownOptions: any[] = [];
    @observable pmcGridViewData: any[] = [];
    systemGroupData: any[]=[];
    pipelineGroupData: any[] = [];
    @observable isTariffNoteSaved = false;
    @observable isTariffNoteAdded = false;
    @observable wfRequestRejected = false;
    @observable wfRequestApproved = false;
    @observable cellValueChangeMap: any = {};
    @observable cellValueChangeMapAttachment: any = {};
    @observable reviewTariffDetails: any[] = [];
    @observable dontProcessOptions = false;
    @observable autoCallWorkflowSteps = false;
    

    @observable fileUploadGridData: WFAttachmentResponse[] = [];
    backupAttachmentGridData: WFAttachmentResponse[] = [];
    @observable notesGridData: WFNotesResponse[] = [];
    backupNotesGridData: WFNotesResponse[] = [];
    assetGroupData: any[]=[];
    shrinkageData: any[]=[];
    lossAllowanceData: any[] = [];
    raXrefTariffNumbers: any[] = [];
    
    @observable url = '';

    @observable formData = {
        pipelineSystems: [],
        description: '',
        goLive: '' as unknown as Date,
        comment: '',
        projectName: '',
        tracking: false,
        dor: false,
        selectedBaseTariff: ''
    }

    @observable formData2a = {
        baseTariff: '',
        tariffLog: [] as any [],
        logIds: [] as any [],
        baseTariffarray: [] as any [] || null
    }

    @observable formDataInfrastructure = {
        pipeline: [] as any [],
        location: [] as any [],
        cp: [] as any [],
        profitCenter: [] as any [],
        agency: [] as any [],
        lineSegment: [] as any [],
        gradeMaintenance: [] as any [],
        customer: [] as any [],
    }

    constructor(
        public projectRequestDataService: ProjectRequestDataService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridService: AgGridService
    ) {
        makeAutoObservable(this);
    }

    showOnlyUS = () => {
        return this.accountStore.rolesJson[0]?.defaultLocation==='US'
    }
    
    @action
    reset() {
        this.formData2a = {
            baseTariff: '',
            tariffLog: [],
            logIds: [],
            baseTariffarray: []
        }
        this.formDataInfrastructure = {
            pipeline: [] as any [],
            location: [] as any [],
            cp: [] as any [],
            profitCenter: [] as any [],
            agency: [] as any [],
            lineSegment: [] as any [],
            gradeMaintenance: [] as any [],
            customer: [] as any [],
        }
        this.linkedWorkflowData=[];
        this.baseLinked=false;
        this.tariffLogLinked=false;
        this.tariffOptionLinked=false;
        this.lineSegmentLinked=false;
        this.profitCenterLinked=false;
        this.connectionPointLinked=false;
        this.agencyLinked=false;
        this.gmLinked=false;
        this.pipelineLinked=false;
        this.locationLinked=false;
        this.gradeMaintenanceLinked=false;
        this.customerMaintenanceLinked=false;
        this.tariffOptions=[];
        this.dontProcessOptions = false;
        this.loader=false;
        this.url='';
        this.notesGridData = [];
        this.fileUploadGridData = [];
        this.backupAttachmentGridData = [];
        this.backupAttachmentGridData = [];
        this.workflowstepRole = '';
    }
    @action
    async init(callbackReviewFunction?): Promise<void> {
        try{this.loader=true;
        this.formData2a = {
            baseTariff: '',
            tariffLog: [] as any [],
            logIds: [] as any [],
            baseTariffarray: []
        }
        this.dontProcessOptions = false;
        this.formDataInfrastructure = {
            pipeline: [] as any [],
            location: [] as any [],
            cp: [] as any [],
            profitCenter: [] as any [],
            agency: [] as any [],
            lineSegment: [] as any [],
            gradeMaintenance: [] as any [],
            customer: [] as any [],
        }
        this.notesGridData = [];
        this.fileUploadGridData = [];
        this.backupAttachmentGridData = [];
        this.backupAttachmentGridData = [];
        this.workflowstepName = '';
        this.workflowstepOrder = '';
        this.workflowstepRole = '';
        const state=history?.location?.state as {from?: string, workflowId?: string, selectedProjectRequestType?: string};
        this.workflowId= state?.workflowId || this.workflowId;
        this.url=history?.location?.pathname||'';
        if(history?.location?.pathname==='/project-request-step-2'){
            this.activeTab='tariffSetup';
        }else if(history?.location?.pathname==='/project-request-Infrastructure'||history?.location?.pathname==='/project-request-infrastructure'||history?.location?.pathname==='project-request-clone-tariff-options'){
            this.activeTab='infrastructureSetup';
        }else{
            this.activeTab='infrastructureSetup';
        }
        this.href=history?.location?.pathname;
        this.username = this.accountStore.displayName;
        this.selectedProjectRequestType = state?.selectedProjectRequestType || this.selectedProjectRequestType
        if (this.accountStore.rolesJson[0]?.defaultLocation == 'CAN') {
            await this.getAssetGroupDropdownData();
            await this.getRAXrefTariffList();
            await this.getPMCWorkflowSelectedTariffDetails();
            await this.getWorkflowNotesByWFID();
            await this.getWorkflowDocumentByWFID();
        } else {
            this.getWorkflowNotesByWFIDUS();
            this.getWorkflowDocumentByWFIDUS();
        }
        await this.getBaseTariffDropDownData();
        this.loader = true;
        await this.getPipelinesDropDownData();
        this.loader = true;
        this.getWorkflowDetails();
        this.loader = true;
        this.getWorkflowStatusGridData();
        this.loader = true;
        await this.getLinkedBaseAndLog(true);
        this.loader = true;
        this.activeTab!=='project-request-step-2' && await this.getAgencies();
        this.loader = true;
        this.activeTab!=='project-request-step-2' && this.getLinkedWorkflowData();
        this.loader = true;
        if(this.activeTab==='review'||this.activeTab==='compare'){
            if(this.tariffOptionLinked){
                tariffOptionsDataStoreProject.init(this.formData2a, this.workflowId, true, this.activeTab);
            } else{
                tariffOptionsDataStoreProject.init(this.formData2a, this.workflowId, false, this.activeTab);
            }
        }
        this.loader=false;}catch(e){this.loader=false;console.log(e);errorHandler('Failed to Initialize Step 2. Please retry')}
    }

    
    @action 
    setActiveTab(value){
        this.activeTab=value;
    }
     
    @action
    changeFormData(value, key) {
        this.formData= {...this.formData, [key]: value}
    }

    @action
    async changeFormDataInfrastructure(value, key, link=true) : Promise<boolean> {
        if(key==='pipeline'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && await this.step2aLinkWorkflow('PL', [value.value]);
            // this.gradeMaintenanceDropdown=[];
            // this.locationDropdown=[];
            // this.lineSegmentDropdown=[];
            // this.profitCenterDropdown=[];
            // this.cpDropdown=[];
            // this.customerMaintenanceDropdown=[];
            this.getGM(value.value)
            this.getLineSegments(value.value)
            this.getProfitCenter(value.value)
            this.getCustomerMaintenance(value.value)
            await this.getLocations(value.value)
        }else if(key==='pipelineDelete'){
            const returnedBool = link && await this.step2UnlinkWorkflow('PL', [value.value]);
            if(returnedBool){ 
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'pipeline': [...this.formDataInfrastructure.pipeline.filter(x=>x!==value)]}
                const locationData = [...this.locationDropdown, ...this.inactiveLocationDropdown]
                locationData.filter((y)=>y.pipelineId === value.value)?.forEach((z)=> {
                    this.formDataInfrastructure['location']?.forEach(async (k)=> {
                        if(k.value===z.locationID){ 
                            await this.changeFormDataInfrastructure(k, 'locationDelete', false)
                        }
                    })
                });
                this.locationDropdown=[...this.locationDropdown.filter(x=>x.pipelineId!==value.value)]
                this.gradeMaintenanceDropdown.filter((y)=>y.pipelineId === value.value)?.forEach((z)=> {
                    this.formDataInfrastructure['gradeMaintenance']?.forEach(async (k)=> {
                        if(k.value===z.gradeID){ 
                            await this.changeFormDataInfrastructure(k, 'gradeMaintenanceDelete', false)
                        }
                    })
                });
                this.gradeMaintenanceDropdown=[...this.gradeMaintenanceDropdown.filter(x=>x.pipelineId!==value.value)]
                const lineSegmentData  = [...this.lineSegmentDropdown, ...this.inactiveLineSegmentDropdown];
                lineSegmentData.filter((y)=>y.pipelineId === value.value)?.forEach((z)=> {
                    this.formDataInfrastructure['gradeMaintenanceDropdown']?.forEach(async (k)=> {
                        if(k.value===z.lineSegID){ 
                            await this.changeFormDataInfrastructure(k, 'lineSegmentDelete', false)
                        }
                    })
                });
                this.profitCenterDropdown=[...this.profitCenterDropdown.filter(x=>x.pipelineId!==value.value)]
                this.profitCenterDropdown.filter((y)=>y.pipelineId === value.value)?.forEach((z)=> {
                    this.formDataInfrastructure['profitCenter']?.forEach(async (k)=> {
                        if(k.value===z.profitCenterID){ 
                            await this.changeFormDataInfrastructure(k, 'profitCenterDelete', false)
                        }
                    })
                });
                this.profitCenterDropdown=[...this.profitCenterDropdown.filter(x=>x.pipelineId!==value.value)]
                this.customerMaintenanceDropdown.filter((y)=>y.pipelineId === value.value)?.forEach((z)=> {
                    this.formDataInfrastructure['customer']?.forEach(async (k)=> {
                        if(k.value===z.customerID){ 
                            await this.changeFormDataInfrastructure(k, 'customerDelete', false)
                        }
                    })
                });
                this.customerMaintenanceDropdown=[...this.customerMaintenanceDropdown.filter(x=>x.pipelineId!==value.value)]
                if(this.formDataInfrastructure.pipeline.length===0){
                    this.pipelineLinked=false;
                }
                if(this.formDataInfrastructure.gradeMaintenance.length===0){
                    this.gmLinked=false;
                }
                if(this.formDataInfrastructure.profitCenter.length===0){
                    this.profitCenterLinked=false;
                }
                if(this.formDataInfrastructure.location.length===0){
                    this.locationLinked=false;
                }
                if(this.formDataInfrastructure.lineSegment.length===0){
                    this.lineSegmentLinked=false;
                }
                if(this.formDataInfrastructure.cp.length===0){
                    this.connectionPointLinked=false;
                }
                if(this.formDataInfrastructure.customer.length===0){
                    this.customerMaintenanceLinked=false;
                }
                if(this.formDataInfrastructure.gradeMaintenance.length===0){
                    this.gradeMaintenanceLinked=false;
                }
            }
        } else if(key==='location'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && this.step2aLinkWorkflow('LOC', [value.value]);
            if(value.value){
                await this.getCP(value.value)
            } else{
                const locationData = [...this.locationDropdown, ...this.inactiveLocationDropdown]
                const id  = locationData.find(y=>y.locnName===value.label)?.locationID;
                await this.getCP(id)
            }
        }else if(key==='locationDelete'){
            const locationData = [...this.locationDropdown, ...this.inactiveLocationDropdown]
            const id  = value.value ? value.value : locationData.find(y=>y.locnName===value.label)?.locationID;
            const returnedBool = link && await this.step2UnlinkWorkflow('LOC', [id]);
            if(returnedBool){
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'location': [...this.formDataInfrastructure.location.filter(x=>x!==value)]}
                const cpData = [...this.cpDropdown, ...this.inactiveCpDropdown]
                // const locationId  = value.value ? value.value : id;
                cpData.filter((y)=>y.locationId === id)?.forEach((z)=> {
                this.formDataInfrastructure['cp']?.forEach(async (h)=> {
                        if(h.value===z.connectionPointID){ 
                            await this.changeFormDataInfrastructure(h, 'cpDelete', false)
                        }
                    })
                });
                this.cpDropdown=[...this.cpDropdown.filter(x=>x.locationId!==id)]
                if(this.formDataInfrastructure.location.length===0){
                    this.locationLinked=false;
                }
                if(this.formDataInfrastructure.cp.length===0){
                    this.connectionPointLinked=false;
                }
            }
        } else if(key==='cp'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && this.step2aLinkWorkflow('CP', [value.value]);
        } else if(key==='cpDelete'){
            const cpData = [...this.cpDropdown, ...this.inactiveCpDropdown]
            const id  = value.value ? value.value : cpData.find(y=>y.cpName===value.label)?.connectionPointID;
            const returnedBool = link && await this.step2UnlinkWorkflow('CP', [id]);
            if(returnedBool){
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'cp': [...this.formDataInfrastructure.cp.filter(x=>x!==value)]}
                if(this.formDataInfrastructure.cp.length===0){
                    this.connectionPointLinked=false;
                }
            }
        } else if(key==='profitCenter'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && await this.step2aLinkWorkflow('PRFT', [value.value]);
        } else if(key==='profitCenterDelete'){
            const returnedBool = link && await this.step2UnlinkWorkflow('PRFT', [value.value]);
            if(returnedBool){
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'profitCenter': [...this.formDataInfrastructure.profitCenter.filter(x=>x!==value)]}
                if(this.formDataInfrastructure.profitCenter.length===0){
                    this.profitCenterLinked=false;
                } 
            }
        } else if(key==='customer'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && await this.step2aLinkWorkflow('CUST', [value.value]);
        } else if(key==='customerDelete'){
            const returnedBool = link && await this.step2UnlinkWorkflow('CUST', [value.value]);
            if(returnedBool){
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'customer': [...this.formDataInfrastructure.customer.filter(x=>x!==value)]}
                if(this.formDataInfrastructure.customer.length===0){
                    this.customerMaintenanceLinked=false;
                }
            }
        } else if(key==='gradeMaintenance'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && this.step2aLinkWorkflow('GRD', [value.value]);
        } else if(key==='gradeMaintenanceDelete'){
            const returnedBool = link && await this.step2UnlinkWorkflow('GRD', [value.value]);
            if(returnedBool){
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'gradeMaintenance': [...this.formDataInfrastructure.gradeMaintenance.filter(x=>x!==value)]}
                if(this.formDataInfrastructure.gradeMaintenance.length===0){
                    this.gradeMaintenanceLinked=false;
                }
            }
        } else if(key==='agency'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && await this.step2aLinkWorkflow('AGCY', [value.value]);
        } else if(key==='agencyDelete'){
            const returnedBool = link && await this.step2UnlinkWorkflow('AGCY', [value.value]);
            if(returnedBool){
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'agency': [...this.formDataInfrastructure.agency.filter(x=>x!==value)]}
                if(this.formDataInfrastructure.agency.length===0){
                    this.agencyLinked=false;
                }
            }
        } else if(key==='lineSegment'){
            this.formDataInfrastructure= {...this.formDataInfrastructure, [key]: [...new Map([...this.formDataInfrastructure[key], value].map(item =>
                [item['label'], item])).values()] }
            link && await this.step2aLinkWorkflow('LS', [value.value]);
        } else if(key==='lineSegmentDelete'){
            const lineSegData = [...this.lineSegmentDropdown, ...this.inactiveLineSegmentDropdown]
            const id  = value.value ? value.value : lineSegData.find(y=>y.lineSegDesc===value.label)?.lineSegID;
            const returnedBool = link && await this.step2UnlinkWorkflow('LS', [id]);
            if(returnedBool) {
                this.formDataInfrastructure= {...this.formDataInfrastructure, 'lineSegment': [...this.formDataInfrastructure.lineSegment.filter(x=>x!==value)]}
                if(this.formDataInfrastructure.lineSegment.length===0){
                    this.lineSegmentLinked=false;
                }
            }
        }
        return true;
    }

    @action
    async changeFormData2a(value, key): Promise<void> {
        if(key==='tariffLog'){
            await this.step2aLinkWorkflow('LOG', [this.tariffLogDropdown.find(y=>y.tariffNumber===value)?.tariffLogID], value);
        }else if(key==='tariffLogDelete'){
            await this.step2UnlinkWorkflow('LOG', [this.tariffLogDropdown.find(y=>y.tariffNumber===value)?.tariffLogID], value);
        }else if(key==='baseTariff'){
            if(value===''){
                await this.step2UnlinkWorkflow('BASE', [], value);
            } else if(value!==''){
                await this.step2UnlinkWorkflow('BASE', [], value);
                await this.step2aLinkWorkflow('BASE', [this.baseTariffDropdown.find(x=>x.baseTariff===value)?.tariffBaseID], value)
            }
        }
    }

    @action
    async getLinkedBaseAndLog(firstTime=false): Promise<void> {
        try{
        this.loader=true    
        const response = await this.projectRequestDataService.getLinkedBaseAndLog(this.workflowId);
        if(response){
            this.formData2a={...this.formData2a, baseTariffarray: [...new Set(response?.flatMap(x=>{if(x.baseName!==''&&x.baseName!==null){return x.baseName}else{return []}}))], baseTariff:response[0]?.baseName || '', logIds: response?.flatMap(x=>{if(x.logID!==''&&x.logID!==null&&x.logID!==0){return x.logID}else{return []}}), tariffLog: response?.flatMap(x=>{if(x.logName!==''&&x.logName!==null){return x.logName}else{return []}})}
            let baseTariffData = [] as any [];
            for(let i =0; i < this.formData2a.logIds.length; i++ ) {
                baseTariffData = [...baseTariffData, ...await this.getSelectedBaseTariffDetails(this.formData2a.logIds[i])];
            }
            if(baseTariffData.map(x=>x.tariffLogs.some(y=>y.testInd===true).length>0)){
                this.autoCallWorkflowSteps= false;
            } else {
                this.autoCallWorkflowSteps= true;
            }
            // if(this.baseTariffData.some(x=>x.)
            if(this.formData2a.baseTariff){
                this.baseLinked=true;
            }
            if(this.formData2a.tariffLog.length){
                this.tariffLogLinked=true;
            }
            if(firstTime && this.formData2a.baseTariff){
                this.getTariffLogDownData(this.formData2a.baseTariff);
            }
        }
        this.loader=false;
        }catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && errorHandler(error?.message + Object.values(error?.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }
    }

    @action
    async processTariffOptions(){
        try{this.loader=true;
        let tariffOptionsResponse = [] as any [];
        const logIds = await this.projectRequestDataService.getOptionInfo(this.workflowId)
        const logIdsFiltered = logIds.filter(x=>x.process===false)?.map(x=>x.logID);
        for(let i=0;i<this.formData2a.tariffLog.length;i++){
            if(logIds.length==0 || logIdsFiltered.includes(this.tariffLogDropdown.find(y=>y.tariffNumber===this.formData2a.tariffLog[i])?.tariffLogID)){
            const response: any = await this.projectRequestDataService.processTariffOptions(this.tariffLogDropdown.find(y=>y.tariffNumber===this.formData2a.tariffLog[i])?.tariffLogID, this.username, this.workflowId )
            tariffOptionsResponse=[...tariffOptionsResponse, ...response.map(x=>{return{...x, baseTariff: this.formData2a.baseTariff, baseId: this.baseTariffDropdown.find(y=>y.baseTariff===this.formData2a.baseTariff)?.tariffBaseID,logName:x.logName,tariffName:this.formData2a.tariffLog[i], logId: this.tariffLogDropdown.find(y=>y.tariffNumber===this.formData2a.tariffLog[i])?.tariffLogID}})];
            }
        };
        this.tariffOptions = [...tariffOptionsResponse];
        if(tariffOptionsResponse.length){
            this.tariffOptionLinked=true;
            if(tariffOptionsResponse.every(x=>x.process)){
                this.dontProcessOptions= true;
            }
        }else{
            this.tariffOptionLinked=false;
        }
        await this.getLinkedWorkflowData(false, true);
        await this.getLinkedBaseAndLog(false)
        this.loader=false;
        }catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && errorHandler(error?.message + Object.values(error?.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }
    }

    
    @action
    async getOptionInfo(){
        try{this.loader=true;
        const response: any = await this.projectRequestDataService.getOptionInfo(this.workflowId)
        const tariffOptionsUnFiltered = [...response];
        this.tariffOptions = [...response.filter(x=>x.process===true).map(x=>{return{...x, 
            baseTariff: x.baseName, baseId: this.baseTariffDropdown.find(y=>y.baseTariff===this.formData2a.baseTariff)?.tariffBaseID,
            logName:x.logName,
            tariffName:x.logName, 
            logId: x.logID, 
            tariffNo: x.optionName||'No Tariff # Defined'}})];
        if(tariffOptionsUnFiltered.length>0){
            if(response.every(x=>x.process===true)){
                this.tariffOptionLinked=true;
                this.dontProcessOptions=true;
            } else {
                this.tariffOptionLinked=false;
                this.dontProcessOptions=false;
            }
        } else {
            this.tariffOptionLinked=false;
            this.dontProcessOptions=false;
        }
        this.loader=false;
        }catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && errorHandler(error?.message + Object.values(error?.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }
    }

    @action
    async getLinkedWorkflowData(tariffSetup=false, dontRetrieveAgain=false): Promise<void> {
        try{
            this.loader=true;
            const response = await this.projectRequestDataService.getLinkedWorkflowData(this.workflowId);
            if (response){
                if(dontRetrieveAgain===true){
                    const maxValue = Math.max(
                        response[0]?.pipeline?.split(';')?.length || 0, 
                        response[0]?.customer?.split(';')?.length || 0,
                        response[0]?.location?.split(';')?.length || 0,
                        response[0]?.cp?.split(';')?.length || 0, 
                        response[0]?.grade?.split(';')?.length || 0, 
                        response[0]?.profitCenter?.split(';')?.length || 0, 
                        response[0]?.agency?.split(';')?.length || 0, 
                        response[0]?.option?.split(';')?.length || 0, 
                        response[0]?.lineSegment?.split(';')?.length || 0
                    ) 
                    let finalArray= [] as any [];
                    for(let x=0;x<maxValue-1;x++){
                        finalArray = [...finalArray, {
                            pipeline: response[0]?.pipeline?.split(';')[x] && response[0]?.pipeline?.split(';')[x] || '',
                            customer: response[0]?.customer?.split(';')[x] && response[0]?.customer?.split(';')[x] || '',
                            location: response[0]?.location?.split(';')[x] && response[0]?.location?.split(';')[x] || '',
                            cp: response[0]?.cp?.split(';')[x] && response[0]?.cp?.split(';')[x] || '',
                            grade: response[0]?.grade?.split(';')[x] && response[0]?.grade?.split(';')[x] || '',
                            profitCenter: response[0]?.profitCenter?.split(';')[x] && response[0]?.profitCenter?.split(';')[x] || '',
                            agency: response[0]?.agency?.split(';')[x] && response[0]?.agency?.split(';')[x] || '',
                            option: response[0]?.option?.split(';')[x] && response[0]?.option?.split(';')[x] || '',
                            lineSegment: response[0]?.lineSegment?.split(';')[x] && response[0]?.lineSegment?.split(';')[x] || '',
                        }]
                    }
                    this.linkedWorkflowData = finalArray;
                    const options = this.linkedWorkflowData.map(x=>x.option);
                    if(options.length>0){
                        this.getOptionInfo();
                    }
                } else {
                    this.loader=true;
                    const maxValue = Math.max(
                        response[0]?.pipeline?.split(';')?.length || 0, 
                        response[0]?.customer?.split(';')?.length || 0,
                        response[0]?.location?.split(';')?.length || 0,
                        response[0]?.cp?.split(';')?.length || 0, 
                        response[0]?.grade?.split(';')?.length || 0, 
                        response[0]?.profitCenter?.split(';')?.length || 0, 
                        response[0]?.agency?.split(';')?.length || 0, 
                        response[0]?.option?.split(';')?.length || 0, 
                        response[0]?.lineSegment?.split(';')?.length || 0
                    ) 
                    let finalArray= [] as any [];
                    const promisesPipeline = [] as any [];
                    for(let x=0;x<maxValue-1;x++){
                        finalArray = [...finalArray, {
                            pipeline: response[0]?.pipeline?.split(';')[x] && response[0]?.pipeline?.split(';')[x] || '',
                            customer: response[0]?.customer?.split(';')[x] && response[0]?.customer?.split(';')[x] || '',
                            location: response[0]?.location?.split(';')[x] && response[0]?.location?.split(';')[x] || '',
                            cp: response[0]?.cp?.split(';')[x] && response[0]?.cp?.split(';')[x] || '',
                            grade: response[0]?.grade?.split(';')[x] && response[0]?.grade?.split(';')[x] || '',
                            profitCenter: response[0]?.profitCenter?.split(';')[x] && response[0]?.profitCenter?.split(';')[x] || '',
                            agency: response[0]?.agency?.split(';')[x] && response[0]?.agency?.split(';')[x] || '',
                            option: response[0]?.option?.split(';')[x] && response[0]?.option?.split(';')[x] || '',
                            lineSegment: response[0]?.lineSegment?.split(';')[x] && response[0]?.lineSegment?.split(';')[x] || '',
                        }]
                        if(response[0]?.pipeline?.split(';')[x]) {
                            promisesPipeline.push(this.changeFormDataInfrastructure({label: response[0]?.pipeline?.split(';')[x], value: this.pipelinesDropdown.find(y=>y.plName===response[0]?.pipeline?.split(';')[x])?.pipelineID }, 'pipeline', false));
                            this.pipelineLinked=true;
                        }
                    }
                    this.linkedWorkflowData = finalArray;
                    const options = this.linkedWorkflowData.map(x=>x.option);
                    if(options.length>0){
                        this.getOptionInfo();
                    }
                    Promise.all(promisesPipeline).then(async z=>{
                        const promisesLocation = [] as any [];
                        for(let x=0;x<maxValue-1;x++){
                            if(response[0]?.customer?.split(';')[x]){
                                this.changeFormDataInfrastructure({label: response[0]?.customer?.split(';')[x], value: this.customerMaintenanceDropdown.find(y=>y.customerName===response[0]?.customer?.split(';')[x])?.customerID }, 'customer', false);
                                this.customerMaintenanceLinked=true; 
                            }
                            if(response[0]?.grade?.split(';')[x]){
                                this.changeFormDataInfrastructure({label: response[0]?.grade?.split(';')[x], value: this.gradeMaintenanceDropdown.find(y=>y.gradeName===response[0]?.grade?.split(';')[x])?.gradeID }, 'gradeMaintenance', false);
                                this.gradeMaintenanceLinked=true;
                            }
                            if(response[0]?.profitCenter?.split(';')[x]) {
                                this.changeFormDataInfrastructure({label: response[0]?.profitCenter?.split(';')[x], value: this.profitCenterDropdown.find(y=>y.profitCenter===response[0]?.grade?.split(';')[x])?.profitCenterID }, 'profitCenter', false);
                                this.profitCenterLinked=true;
                            }
                            if(response[0]?.agency?.split(';')[x]){
                                this.changeFormDataInfrastructure({label: response[0]?.agency?.split(';')[x], value: this.agencyDropdown.find(y=>y.agencyname===response[0]?.agency?.split(';')[x])?.agencyid }, 'agency', false);
                                this.agencyLinked=true;
                            }
                            if(response[0]?.lineSegment?.split(';')[x]) {
                                this.changeFormDataInfrastructure({label: response[0]?.lineSegment?.split(';')[x], value: this.lineSegmentDropdown.find(y=>y.lineSegDesc===response[0]?.lineSegment?.split(';')[x])?.lineSegID }, 'lineSegment', false);
                                this.lineSegmentLinked=true;
                            }
                            if(response[0]?.location?.split(';')[x]){ 
                                promisesLocation.push(this.changeFormDataInfrastructure({label: response[0]?.location?.split(';')[x], value: this.locationDropdown.find(y=>y.locnName===response[0]?.location?.split(';')[x])?.locationID }, 'location', false));
                                this.locationLinked=true;
                            }
                        }
                        Promise.all(promisesLocation).then(z2=>{
                            for(let x=0;x<maxValue-1;x++){
                                if(response[0]?.cp?.split(';')[x]) {
                                    this.changeFormDataInfrastructure({label: response[0]?.cp?.split(';')[x], value: this.cpDropdown.find(y=>y.cpName===response[0]?.cp?.split(';')[x])?.connectionPointID }, 'cp', false);
                                    this.connectionPointLinked=true;
                                }
                            }
                        })
                    this.loader=false;
                });
            }}
            this.loader=false;
        } catch (e: any) {
            this.loader=false;
            console.log(e)
            e?.message && errorHandler(e.message + Object.values(e.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }
    }

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getGM(pipelineId): Promise<void> {
        const response = await this.projectRequestDataService.getGM(pipelineId);
        this.gradeMaintenanceDropdown = response && [...new Map([...this.gradeMaintenanceDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['gradeID'], item])).values()] || []; 
    }

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getCP(locationId):  Promise<void> {
        const response = await this.projectRequestDataService.getCP(locationId, this.selectedProjectRequestType);
        this.cpDropdown = response && Array.isArray(response) && [...new Map([...this.cpDropdown, ...response.map(x=>{return{...x, locationId: locationId }})].map(item =>
            [item['connectionPointID'], item])).values()] || [];  
            await this.getInactiveCP(locationId)
    }

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getInactiveCP(locationId):  Promise<void> {
        const response = await this.projectRequestDataService.getInactiveCP(locationId);
        this.inactiveCpDropdown = response && Array.isArray(response) && [...new Map([...this.inactiveCpDropdown, ...response.map(x=>{return{...x, locationId: locationId }})].map(item =>
            [item['connectionPointID'], item])).values()] || [];  
    }

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getLocations(pipelineId): Promise<void> {
        const response = await this.projectRequestDataService.getLocations(pipelineId);
        this.locationDropdown = response && Array.isArray(response) && [...new Map([...this.locationDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['locationID'], item])).values()] || []; 
        
        await this.getInactiveLocations(pipelineId);
    }

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getInactiveLocations(pipelineId): Promise<void> {
        const response = await this.projectRequestDataService.getInactiveLocations(pipelineId);
        this.inactiveLocationDropdown = response && Array.isArray(response) && [...new Map([...this.inactiveLocationDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['locationID'], item])).values()] || [];  
    }

    

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getAgencies():  Promise<void> {
        const response = await this.projectRequestDataService.getAgencies();
        this.agencyDropdown = response  && Array.isArray(response) && [...new Map([...this.agencyDropdown, ...response].map(item =>
            [item['agencyid'], item])).values()] || [];  
    }

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getLineSegments(pipelineId):  Promise<void> {
        const response = await this.projectRequestDataService.getLineSegments(pipelineId);
        this.lineSegmentDropdown = response  && Array.isArray(response) && [...new Map([...this.lineSegmentDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['lineSegID'], item])).values()] || []; 
        await this.getTariffLineSegments(pipelineId);
        await this.getInactiveLineSegments(pipelineId); 
    }

    
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getTariffLineSegments(pipelineId):  Promise<void> {
        const response = await this.projectRequestDataService.getTariffLineSegments(pipelineId);
        this.lineSegmentDropdown = response  && Array.isArray(response) && [...new Map([...this.lineSegmentDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['lineSegID'], item])).values()] || [];
    }

    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getInactiveLineSegments(pipelineId):  Promise<void> {
        const response = await this.projectRequestDataService.getInactiveLineSegments(pipelineId);
        this.inactiveLineSegmentDropdown = response  && Array.isArray(response) && [...new Map([...this.inactiveLineSegmentDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['lineSegID'], item])).values()] || [];  
    }
    
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getProfitCenter(pipelineId):  Promise<void> {
        const response = await this.projectRequestDataService.getProfitCenter(pipelineId);
        this.profitCenterDropdown = response  && Array.isArray(response) && [...new Map([...this.profitCenterDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['profitCenterID'], item])).values()] || [];  
    }
    
    
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getCustomerMaintenance(pipelineId):  Promise<void> {
        const response = await this.projectRequestDataService.getCustomerMaintenance(pipelineId);
        this.customerMaintenanceDropdown = response  && Array.isArray(response) && [...new Map([...this.customerMaintenanceDropdown, ...response.map(x=>{return{...x, pipelineId: pipelineId }})].map(item =>
            [item['customerID'], item])).values()] || [];  
    }
    
    @action
    setPipeline(pipelines: any[]): void {
        this.changeFormData(pipelines,'pipelineSystems')
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelinesDropDownData(): Promise<void> {
        const response = await this.projectRequestDataService.getPipelines();
        this.pipelinesDropdown = response;  
    }
    
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseTariffDropDownData(): Promise<void> {
        const response = await this.projectRequestDataService.getBaseTariffs();
        this.baseTariffDropdown = response && Array.isArray(response) && [...new Map(response.map(item =>
            [item['baseTariff'], item])).values()] || [];  
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffLogDownData(value?): Promise<void> {
        const response = await this.projectRequestDataService.step2aGetTariffLog(this.baseTariffDropdown?.find(x=>x.baseTariff===value)?.tariffBaseID);
        this.tariffLogDropdown = response && Array.isArray(response) && [...new Map(response.map(item =>
            [item['tariffNumber'], item])).values()] || [];  
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getWorkflowDetails(): Promise<void> {
        try{this.loader=true;
        const response = await this.projectRequestDataService.getWorkflowDetails(this.workflowId);
        this.formData = response && {
            pipelineSystems: response.pipelineId===''||response.pipelineId===null?null:response.pipelineId.split(',').map(x=>this.pipelinesDropdown?.find(y=>String(y.pipelineID)===String(x))?.plName)||null,
            description: response.description,
            goLive: new Date(response.goLiveDate) as unknown as Date,
            comment: '',
            tracking: response.tracking, 
            dor: response.dor,
            projectName: response.name,
            selectedBaseTariff: this.baseTariffDropdown?.find(x=>response.baseTariffID===x.tariffBaseID)?.baseTariff || 'New'
        }||this.formData;
        this.backupDescription=this.formData.description;
        this.workflowDetails=response;
        this.loader=false;
        }  catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }

    }

    
    
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getWorkflowStatusGridData(): Promise<void> {
        try{
            this.loader=true;
            const response = await this.projectRequestDataService.getWorkflowStatusGridData(this.workflowId);
            this.workflowStatusGridData=response;
            this.statusOrHistory=true;
            
            const filteredInprogressParallelSteps = response.filter(x => x.workflowstepStatus === 'In Progress');
            const pipelineIDs = this.pipelinesDropdown.filter((x: any) =>  this.formData.pipelineSystems && this.formData.pipelineSystems.filter((i)=> i === x.plName).length > 0).map((y) => y.pipelineID);
            const currentWorkflowStep = filteredInprogressParallelSteps.length > 1 ? filteredInprogressParallelSteps.filter((x) =>  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, x.workflowstepName, pipelineIDs)) : [];
            this.workflowstepOrder = currentWorkflowStep.length > 0 ? currentWorkflowStep[0].workflowstepOrder.charAt(0): response.find(x=>x.workflowstepStatus==='In Progress')?.workflowstepOrder.charAt(0);
            this.stepDescription=currentWorkflowStep.length > 0 ? currentWorkflowStep[0].workflowstepDescription : response.find(x=>x.workflowstepStatus==='In Progress')?.workflowstepDescription||this.stepDescription;
            this.workflowstepName = currentWorkflowStep.length > 0 ? currentWorkflowStep[0].workflowstepName : response.find(x => x.workflowstepStatus === 'In Progress')?.workflowstepName || this.workflowstepName;
            this.workflowstepRole = currentWorkflowStep.length > 0 ? currentWorkflowStep[0].workflowstepRole : response.find(x => x.workflowstepStatus === 'In Progress')?.workflowstepRole || this.workflowstepRole;
            this.forwardButtonText= currentWorkflowStep.length > 0 ? currentWorkflowStep[0].workflowstepName : (response.find(x => x.workflowstepStatus === 'In Progress')?.workflowstepName || this.workflowstepName);
            this.workflowStepFlowId = this.workflowStepStatus == 'Completed' || this.workflowStepStatus == null ? this.workflowStepFlowId : currentWorkflowStep.length > 0 ? currentWorkflowStep[0].workflowStepFlowId : response.find(x => x.workflowstepStatus === 'In Progress')?.workflowStepFlowId;
            this.workflowStepId = this.workflowStepStatus == 'Completed' || this.workflowStepStatus == null ? this.workflowStepId : currentWorkflowStep.length > 0 ? currentWorkflowStep[0].workflowstepId : response.find(x=>x.workflowstepStatus==='In Progress')?.workflowstepId;
            this.loader=false
        }  catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }
    }

    @action
    async getWorkflowHistoryStatusGridData(): Promise<void> {
        try{this.loader=true;
        const response = await this.projectRequestDataService.getWorkflowHistoryStatusGridData(this.workflowId);
        this.workflowStatusGridData=response;
        this.statusOrHistory=false;
        this.loader=false;}
        catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAssetGroupDropdownData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const assetGroups = await this.projectRequestDataService.getAssetGroups(userJurisdiction);
        this.assetGroupData = assetGroups;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getRAXrefTariffList(): Promise<void> {
        const raXrefDetails = await this.projectRequestDataService.getRAXrefDetailsWithoutFilter();
        const raXrefTariffNumbers: any[] = [];
        raXrefDetails.map((item) => {
            raXrefTariffNumbers.push(item.tariffNumber);
        })
        const unique = [...new Set(raXrefTariffNumbers)]
        console.log(unique)
        const raXrefTariffNumberList = unique.map(i => Number(i));
        this.raXrefTariffNumbers = raXrefTariffNumberList;
    }

    isUSJurisdiction = () => {
        return this.accountStore.rolesJson[0]?.defaultLocation==='US'
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getPMCWorkflowSelectedTariffDetails(): Promise<void> {
        const response: any = await this.projectRequestDataService.getPMCWorkflowSelectedTariffDetails(this.workflowId);
        runInAction(async () => {
            this.selectedTariffs = response.pmcTariffDetailID;
            this.selectedTariffIDs = response.pmcTariffDetailID;
            await this.tariffReviewTabClicked();
        })
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async tariffReviewTabClicked(): Promise<void> {
        this.showTariffReviw = true;
        const response = await this.projectRequestDataService.getWFReviewTariffDetail(this.selectedTariffIDs);
        runInAction(() => {
            this.reviewTariffDetails = response;
        })
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getWorkflowNotesByWFID(): Promise<void> {
        const response = await this.projectRequestDataService.getWorkflowNotesByWFID(this.workflowId);
            runInAction(() => {
                this.setPMCTariffNoteGridData(response);
                if (response.length > 0) {
                    this.isTariffNoteAdded = true;
                }
            })
    }

    @action
    async getWorkflowNotesByWFIDUS(): Promise<void> {
        try{this.loader=true;
        const response = await this.projectRequestDataService.getWorkflowNotesByWFID(this.workflowId);
        this.setPMCTariffNoteGridData(response);
        if (response.length > 0) {
            this.isTariffNoteAdded = true;
        }
        this.loader=false;} catch(e){
            console.log(e);
            this.loader=false;
        }
    }
    
    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getWorkflowDocumentByWFID(): Promise<void> {
        const response = await this.projectRequestDataService.getWorkflowDocumentByWFID(this.workflowId);
            runInAction(() => {
                this.setPMCTariffAttachmentGridData(response);
            })
    }

    
    @action
    async getWorkflowDocumentByWFIDUS(): Promise<void> {
        try{this.loader=true;
        const response = await this.projectRequestDataService.getWorkflowDocumentByWFID(this.workflowId);
        this.setPMCTariffAttachmentGridData(response);
        this.loader=false}catch(e){
            console.log(e);
            this.loader=false;
        }
    }

    @action
    setPMCTariffNoteGridData(notesGridData: any[]): void {
        this.notesGridData = notesGridData
    }

    @computed
    get NotesGridData() {
        return toJS(this.notesGridData);
    }

    @action
    setPMCTariffAttachmentGridData(attachmentGridData: any[]): void {
        this.fileUploadGridData = attachmentGridData;
    }

    @computed
    get AttachmentsGridData() {
        return toJS(this.fileUploadGridData);
    }

    @action
    updateRow = (selectedRowData: any): void => {
        const updatedShopIndex = this.notesGridData.findIndex(a => a.workflowNoteID == selectedRowData.workflowNoteID);
        if (!_.isEqual(this.backupNotesGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumns(this.notesGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    @action
    updateRowAttachment = (selectedRowData: any): void => {
        const updatedShopIndex = this.fileUploadGridData.findIndex(a => a.workflowDocumentID == selectedRowData.workflowDocumentID);
        if (!_.isEqual(this.backupAttachmentGridData[updatedShopIndex], selectedRowData)) {
            this.mapAttachmentEditableColumns(this.fileUploadGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.workflowNoteID = updatedItem.workflowNoteID;
        currentItem.notes = updatedItem.notes;
        currentItem.createUser = updatedItem.createUser;
        currentItem.createDate = updatedItem.createDate;
    }

    mapAttachmentEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.workflowDocumentID = updatedItem.workflowDocumentID;
        currentItem.description = updatedItem.description;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMapAttachment) {
            for (const col in this.cellValueChangeMapAttachment[row]) {
                const obj = this.cellValueChangeMapAttachment[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async saveTariffNotes(): Promise<void> {
    const gridData = this.notesGridData.filter(note => note.notes !=='')
    let requestBodyForNote: any = {};
        const diff = _.differenceWith(gridData, this.backupNotesGridData, _.isEqual);
        requestBodyForNote = {
        'workflowId': this.workflowId, 
        'user': this.username,
        'notes': diff.map(note => {
            return {
                'id': note.workflowNoteID ? note.workflowNoteID : 0,
                'notes': note.notes,
            }            
        })
        }

    if(requestBodyForNote.notes.length !== 0) {
        let errorSummary: ToastMessage[] = [];
        let errorInSave = false;
        const response: any[] = await this.projectRequestDataService.saveWFTariffNotes(requestBodyForNote);
        if(response){
            for (const key in response) {   
                if(response.length > 0){
                    this.setPMCTariffNoteGridData(response);
                    this.backupNotesGridData = response;
                    this.isTariffNoteSaved = true;
                    dataStore.setShowUnsavedWarningAlert(false);
                }
                else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: 'There is an error saving note' }];
                }
            }
        }
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.notesGridData.length; i++) {
                    const updatedData = this.notesGridData[i];
                    if (updatedData.workflowNoteID.toString() === errorMessage.id) {
                        const backupRowIndex = this.notesGridData.findIndex(a => a.workflowNoteID.toString() == errorMessage.id);
                        this.mapEditableColumns(updatedData, this.notesGridData[backupRowIndex]);
                    }
                }
            });
        }
        else {
            this.uiService.toastService.success('Note saved successfully');
        }
    }        
    }

     @Loader
     @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async deleteTariffNote(noteID): Promise<void> {
        if(noteID) {
            const response: any = await this.projectRequestDataService.deleteWFTariffNote(noteID);
            if(response){ 
                const nodesGridData = this.NotesGridData.filter((item) => item.workflowNoteID !== noteID);
                this.setPMCTariffNoteGridData(nodesGridData);
                this.uiService.toastService.success('Note has deleted successfully')
            }else {
                this.uiService.toastService.error('There is an error while deleting selected note');
            }
            dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in uploading attachment '))
    async uploadFile(e): Promise<void> {
        const formData = new FormData();
        formData.append('Attachment', e.target.files[0]);
        formData.append('FileName', e.target.files[0].name);
        formData.append('Description', 'File Description');
        formData.append('user', this.username );
        formData.append('workflowId', this.workflowId);
        const uploadedFileData: any = await this.projectRequestDataService.uploadFile(formData);
        if(uploadedFileData.length > 0){
            this.uiService.toastService.success('Attachment uploaded successfully');
            const documents = JSON.parse(JSON.stringify(uploadedFileData));
            const attachmentList = this.backupAttachmentGridData.concat(documents)
            this.setPMCTariffAttachmentGridData(attachmentList);
            this.backupAttachmentGridData = attachmentList;
            e.target.value = '';
        }else {
            this.uiService.toastService.error('There is an error while uploading attachment');
        }        
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in downloading attachment '))
    async downloadAttachment(fileID): Promise<void> {
        const response: any = await this.projectRequestDataService.downloadAttachment(fileID);
        window.open(response, '_blank', 'noreferrer');
        this.uiService.toastService.success('Attachment downloaded successfully');
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in deleting attachment '))
    async deleteAttachment(docID): Promise<void> {
        const response: any = await this.projectRequestDataService.deleteAttachment(docID);
        if(response){
            const filteredData = this.AttachmentsGridData.filter((item) =>  item.workflowDocumentID !== docID);
            this.uiService.toastService.success('Attachment deleted successfully');
            this.setPMCTariffAttachmentGridData(filteredData);
            this.backupAttachmentGridData = this.fileUploadGridData;
        }else {
            this.uiService.toastService.error('There is an error while deleting attachment');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in editing attachment '))
    async updateAttachment(): Promise<void> {
        const filesToUpdate: any = [];
        const updatedRowsIDS = this.getUpdatedRowIDs();
        const documents = JSON.parse(JSON.stringify(this.fileUploadGridData));
        if(updatedRowsIDS.length>0){
            updatedRowsIDS.map(docId=>{
                const updatedRow: any = documents.find(item=>item.workflowDocumentID == docId)
                filesToUpdate.push({
                    'id': updatedRow.workflowDocumentID,
                    'workflowId': updatedRow.workflowId,
                    'user': updatedRow.createUser,
                    'description': updatedRow.description
                })
            })
            const response: any = await this.projectRequestDataService.updateAttachment(filesToUpdate);
            if(response.length > 0){
                documents.map(item=>{
                    response.map(resItem=>{
                        if(resItem.workflowDocumentID == item.workflowDocumentID){
                            item.description = resItem.description,
                            item.updateUser = resItem.updateUser,
                            item.updateDate = resItem.updateDate
                        }
                    })
                })
                this.setPMCTariffAttachmentGridData(documents);
                this.cellValueChangeMapAttachment = {};
                this.agGridService.getNodes()?.refreshCells();
                this.backupAttachmentGridData = documents;
                this.uiService.toastService.success('Attachment updated successfully')
            }else {
                this.uiService.toastService.error('There is an error while updating attachment');
            }
        }    
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async step1SavePostWorkflowDetails(): Promise<void> {
        // if(this.formData.description===this.backupDescription){
        //     this.uiService.toastService.error('Please change description to save!');
        //     return;
        // }
        const requestBody = {
                'projectRequestID': this.workflowDetails && this.workflowDetails.projectRequestID || 0,
                'workflowID': this.workflowDetails?.workflowID || this.workflowId,
                'jurisdiction': this.workflowDetails?.jurisdiction || this.accountStore.rolesJson[0]?.defaultLocation,
                'pipelineId': this.formData.pipelineSystems===null?null:this.formData.pipelineSystems.length?this.formData.pipelineSystems.map(x=>this.pipelinesDropdown.find(y=>y.plName===x)?.pipelineID).join(','):null,
                'description': this.formData.description || '',
                'goLiveDate': this.formData.goLive && moment(this.formData.goLive).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'createUser': this.workflowDetails?.createUser || this.username,
                'createDate': this.workflowDetails?.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                'name': this.formData.projectName, 
                'tracking': this.formData.tracking, 
                'dor': this.formData.dor, 
                'baseTariffID': this.formData.selectedBaseTariff==='New'?null:this.baseTariffDropdown.find(x=>this.formData.selectedBaseTariff===x.baseTariff)?.tariffBaseID
              }
        if(requestBody) {
            const response: any = await this.projectRequestDataService.step1SavePostWorkflowDetails(requestBody);
            if(response){
                let error ='';
                for (const key in response) {                       
                    if (key==='value') {
                        error = response[key]; 
                    } else if (key==='statusCode' && response[key]===400) {
                        this.uiService.toastService.error(error);
                    } 
                }
                if(error===''){
                        this.getWorkflowDetails();
                         this.sendEmail();
                        this.uiService.toastService.success('Project Description Updated Successfully!')
                }
            }       
        }
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async goToPreviousPage(): Promise<void> {
        const response: any = await this.projectRequestDataService.goToPreviousPage(this.workflowId, this.username, this.workflowStepFlowId);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                // this.getWorkflowDetails();
                if (!this.wfRequestRejected) {
                    history.push('/project-request-step-1', {workflowId: this.workflowId, selectedProjectRequestType: this.selectedProjectRequestType, workflowStepId: '', saved: true})
                }
            }
        }       
    }
    
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async step2aLinkWorkflow(type, reqBody, value?): Promise<void> {
        try{this.loader=true;
        const response: any = await this.projectRequestDataService.step2aLinkWorkflow(this.workflowId, this.username, reqBody, type);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                if(type==='BASE'){
                    this.baseLinked=true;
                    await this.step2UnlinkWorkflow('LOG', [])
                    this.loader=true
                    this.formData2a= {...this.formData2a, 'baseTariff': value, 'tariffLog': []}
                    this.getTariffLogDownData(value);
                    this.loader=true
                    this.tariffLogLinked=false;
                    this.getLinkedBaseAndLog();
                    this.loader=true
                } else if(type==='LOC'){
                    this.locationLinked=true;
                } else if(type==='PL'){
                    this.pipelineLinked=true;
                } else if(type==='CP'){
                    this.connectionPointLinked=true;
                } else if(type==='PRFT'){
                    this.profitCenterLinked=true;
                } else if(type==='AGCY'){
                    this.agencyLinked=true;
                } else if(type==='LS'){
                    this.lineSegmentLinked=true;
                } else if(type==='GRD'){
                    this.gradeMaintenanceLinked=true;
                } else if(type==='CUST'){
                    this.customerMaintenanceLinked=true;
                } else  if(type==='LOG'){
                    this.formData2a = {...this.formData2a, 'tariffLog': [...new Set([...this.formData2a['tariffLog'], value])]}
                    this.tariffLogLinked=true;
                    this.getLinkedBaseAndLog();
                    this.loader=true
                }
                await this.getLinkedWorkflowData(false, true);
            }
        } 
        this.loader=false;}  catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && (error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }      
    }

    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async step2UnlinkWorkflow(type, reqBody, value?): Promise<void|boolean> {
        try{this.loader=true;
        const response: any = await this.projectRequestDataService.step2UnlinkWorkflow(this.workflowId, this.username, reqBody, type);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                await this.getLinkedWorkflowData(false, true);
                this.loader=true
                if(type==='BASE'){
                    this.baseLinked=false;
                    await this.step2UnlinkWorkflow('LOG', [])
                    this.loader=true
                    this.formData2a= {...this.formData2a, 'baseTariff': '', tariffLog: []}
                    this.tariffLogLinked=false;
                    this.getLinkedBaseAndLog();
                    this.loader=true
                } else if(type==='LOC'){
                    return true;
                } else if(type==='PL'){
                    return true;
                } else if(type==='CP'){
                    return true;
                } else if(type==='PRFT'){
                    return true;
                } else if(type==='AGCY'){
                    return true;
                } else if(type==='LS'){
                    return true;
                } else if(type==='GRD'){
                    return true;
                } else if(type==='CUST'){
                    return true;
                } else if(type==='LOG') {
                    this.formData2a= {...this.formData2a, 'tariffLog': [...this.formData2a.tariffLog.filter(x=>x!==value)]}
                    if(this.formData2a.tariffLog.length===0){
                        this.tariffLogLinked=false;
                    }
                    this.getLinkedBaseAndLog();
                    this.loader=true
                }
            }
        }  
        this.loader=false;}  catch(error: any){ 
            this.loader=false;
            console.log(error); 
            error?.message && errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x))
        }
    }

    @action
    async getSelectedBaseTariffDetails(logId): Promise<any> {
        const selectedBaseTariff = this.baseTariffDropdown.find(y=>{return this.formData2a.baseTariff===y.baseTariff});
        const baseTariffDetails = await this.projectRequestDataService.getSelectedBaseTariffDetails(selectedBaseTariff.tariffBaseID, false, logId);
        return baseTariffDetails;
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getParentStatus(): Promise<any> {
        const response = await this.projectRequestDataService.getParentStatus(this.workflowId);
       return response; 
    }
    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async saveComments(direction='approve'): Promise<void> {
        if(direction ==='approve' && this.workflowstepName === 'Complete Tariff Approval' && (this.selectedProjectRequestType === 'Maintenance 2B – Nomination Receipt / Delivery Connection Points' || this.selectedProjectRequestType === 'Maintenance 2C – Cancelled Connection Point')) {
            const parentStatus = await this.getParentStatus();
            if(parentStatus && parentStatus !== '') {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                    () => {return;},
                    ConfirmWithOK,
                    {secondaryText: `Parent Log (${parentStatus}) workflow is not completed yet.`} as ConfirmWithOKProps,
                    'confirmPopup'
                );
                return;
            }
        }
        if(direction==='reject' && (!this.formData.comment || this.formData.comment === '')){
            this.uiService.toastService.error('Comment is required!');
            return;
        }
        if(this.formData.comment && this.formData.comment !=='') {
            const response: any = await this.projectRequestDataService.step1SaveComments(this.workflowId, this.formData.comment, this.username, this.workflowStepFlowId);
            if(response){
                this.showLoader = true;
                let error ='';
                for (const key in response) {                       
                    if (key==='value') {
                        error = response[key]; 
                    } else if (key==='statusCode' && response[key]===400) {
                        this.uiService.toastService.error(error);
                    } 
                }
                if(error===''){
                        // this.getWorkflowDetails();
                      //  await this.sendEmailWorkflow(direction);
                      if(direction==='approve'){
                        const stepDetails = this.workflowStatusGridData.filter((item) => item.workflowstepOrder.charAt(0) === this.workflowstepOrder);
                            if(stepDetails.length > 1) {
                                const allParallelStepsCompleted = stepDetails.filter((item) => item.workflowstepStatus === 'In Progress');
                                if(allParallelStepsCompleted.length <= 1) {
                                    await this.sendEmailWorkflowForParallelStepsCompletion(direction);
                                }
                                else {
                                    await this.sendEmailWorkflow(direction);
                                }
                            } else {
                                await this.sendEmailWorkflow(direction);
                            }
                        } else {
                            await this.sendEmailWorkflow(direction);
                        }
                        if(direction==='approve'){
                            // const stepDetails = this.workflowStatusGridData.filter((item) => item.workflowstepOrder.charAt(0) === this.workflowstepOrder);
                            // if(stepDetails.length > 1) {
                            //     const allParallelStepsCompleted = stepDetails.filter((item) => item.workflowstepStatus === 'In Progress');
                            //     if(allParallelStepsCompleted.length <= 1) {
                            //         await this.sendEmailWorkflowForParallelStepsCompletion(direction);
                            //     }
                            // }
                            if(this.href==='/project-request-step-2'){
                                history.push('project-request-clone-tariff-options', {from: 'step-2a', workflowId: this.workflowId})
                            }
                            else if(this.href==='/project-request-Infrastructure'||this.href==='/project-request-infrastructure'){
                                history.push('project-request-clone-tariff-options', { workflowId: this.workflowId})
                            }
                            else if(this.href==='/project-request-clone-tariff-options'){
                                history.push('project-request-vmacs-test', { workflowId: this.workflowId})
                            }
                            else if(this.href==='/project-request-vmacs-test'){
                                history.push('project-request-confirm-test', { workflowId: this.workflowId})
                            }
                            else if(this.href==='/project-request-confirm-test'){
                                history.push('/project-request-tariff-review', { workflowId: this.workflowId})
                            }
                            else{
                                history.push('/ProjectDashboard')
                            }
                        }else {
                            history.push('project-request-clone-tariff-options', { workflowId: this.workflowId})
                        }
                        this.formData={...this.formData, comment:''}
                        this.uiService.toastService.success('Comments Saved Successfully!')
                }
            } 
        } else {
            // await this.sendEmailWorkflow(direction);
            if(direction==='approve'){
                const stepDetails = this.workflowStatusGridData.filter((item) => item.workflowstepOrder.charAt(0) === this.workflowstepOrder);
                    if(stepDetails.length > 1) {
                        const allParallelStepsCompleted = stepDetails.filter((item) => item.workflowstepStatus === 'In Progress');
                        if(allParallelStepsCompleted.length <= 1) {
                            await this.sendEmailWorkflowForParallelStepsCompletion(direction);
                        }
                        else {
                            await this.sendEmailWorkflow(direction);
                        }
                    } else {
                        await this.sendEmailWorkflow(direction);
                    }
                } else {
                    await this.sendEmailWorkflow(direction);
                }
            if(direction==='approve'){
                // const stepDetails = this.workflowStatusGridData.filter((item) => item.workflowstepOrder.charAt(0) === this.workflowstepOrder);
                // if(stepDetails.length > 1) {
                //     const allParallelStepsCompleted = stepDetails.filter((item) => item.workflowstepStatus === 'In Progress');
                //     if(allParallelStepsCompleted.length <= 1) {
                //         await this.sendEmailWorkflowForParallelStepsCompletion(direction);
                //     }
                // }
                if(this.href==='/project-request-step-2'){
                    history.push('project-request-clone-tariff-options', {from: 'step-2a', workflowId: this.workflowId})
                }
                else if(this.href==='/project-request-Infrastructure'||this.href==='/project-request-infrastructure'){
                    history.push('project-request-clone-tariff-options', { workflowId: this.workflowId})
                }
                else if(this.href==='/project-request-clone-tariff-options'){
                    history.push('project-request-vmacs-test', { workflowId: this.workflowId})
                }
                else if(this.href==='/project-request-vmacs-test'){
                    history.push('project-request-confirm-test', { workflowId: this.workflowId})
                }
                else if(this.href==='/project-request-confirm-test'){
                    history.push('/project-request-tariff-review', { workflowId: this.workflowId})
                }
                else{
                    history.push('/ProjectDashboard')
                }
            }else {
                history.push('project-request-clone-tariff-options', { workflowId: this.workflowId})
            }
            this.formData={...this.formData, comment:''}

        } 
        this.showLoader=false;    
    }     

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async rejectTariffRequest(): Promise<void> {
        if(!this.formData.comment){
            this.uiService.toastService.error('Comment is required!');
            return;
        }
        const response: any = await this.projectRequestDataService.step1SaveComments(this.workflowId, this.formData.comment, this.username, this.workflowStepFlowId);
        this.wfRequestRejected = true;
        if (response) {
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    this.goToPreviousPage();
                    this.sendEmailWorkflow('reject');
                    // this.getWorkflowStatusGridData();
                    this.formData={...this.formData, comment:''}
                    this.uiService.toastService.success('Comments Saved Successfully!');
                    history.push('/ProjectDashboard');
            }
        }
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async approveTariffRequest(): Promise<void> {
        if (!this.formData.comment) {
            this.uiService.toastService.error('Comment is required!');
            return;
        }

        const filteredTariffDetailsExistsInRAXref: any = [];
        this.raXrefTariffNumbers.map(number => {
            const tariffDetail = this.reviewTariffDetails.filter(tariff=>tariff.tariffNumber === number)
            if(tariffDetail.length > 0)
            filteredTariffDetailsExistsInRAXref.push(tariffDetail[0]);
        });

        const selectedRATariffDetailIDs: any[] = [];
        if (filteredTariffDetailsExistsInRAXref.length > 0) {
            filteredTariffDetailsExistsInRAXref.map((item) => {
                const externalSystemArr = item.externalSystem && item.externalSystem != '' ? item.externalSystem.split(',') : [];
                if (externalSystemArr.length > 0 && externalSystemArr.includes('Right Angle')) {
                    selectedRATariffDetailIDs.push(item.pmcTariffDetailID);
                }
            })
        }
        
        if (selectedRATariffDetailIDs.length > 0) {
            const response: any = await this.projectRequestDataService.savePMCRightAngleTariffStaging(this.workflowId, selectedRATariffDetailIDs);     
            if (response) {
                this.uiService.toastService.success('Pushing Tariff Request to External System is in progress.')
            }else {
            this.uiService.toastService.error('There is an error in pushing Tariff Request to External System.')
            }
        }

        // we have done this change as per this INC0273345 incident // 

        const selectedExtSystemsData = this.reviewTariffDetails.map(x => x.externalSystem);
        const selectedExtSystems = [...new Set(selectedExtSystemsData)]
        let extSysTariffType = 'Public';
        if (selectedExtSystems.length > 1 && !selectedExtSystems.includes('')) {
            extSysTariffType = 'Public Both';
        } else if (selectedExtSystems.length  && selectedExtSystems.includes('Right Angle')) {
            if (selectedExtSystems.includes('Right Angle')){
                extSysTariffType = 'Public RA';
            } else {
                extSysTariffType = 'Public Polaris';
            }
        }
        const response: any = await this.projectRequestDataService.step1SaveComments(this.workflowId, this.formData.comment, this.username, this.workflowStepFlowId);
        this.wfRequestApproved = true;
        if (response) {
            this.sendEmailWorkflowToApprove('approve', extSysTariffType);
            // this.getWorkflowStatusGridData();
            this.formData = { ...this.formData, comment: '' }
            this.uiService.toastService.success('Comments Saved Successfully!');
            history.push('/ProjectDashboard');
        }else {
            this.uiService.toastService.error('There is an error in saving comments.')
        }
    }
       
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmail(): Promise<void> {
        // const requestBody= {
        //     'workflowStepId': this.workflowStepId,
        //     'direction': 'approve',
        //     'criteria': '',
        //     'useremail': 'hima.mantri@plains.com'
        // }
        const requestBody = {
            'to': this.isUSJurisdiction() ? EMAIL_RECIPIENTS : CAN_EMAIL_RECIPIENTS,
            'from': 'CTSsupport@plains.com',
            'subject': `Dev CTS - Project Overview Update - ${this.formData.projectName}`,
            'body': `Hello Team, <br> <br> An update was made to the project overview section: <br><br> <b>Workflow ID:</b> ${this.workflowId || ''}<br>
            <b>Project Name:</b>  ${this.formData.projectName || ''}<br>
            <b> Pipeline System:</b> ${this.formData.pipelineSystems ? this.formData.pipelineSystems.join(', ') : ''}<br>
            <b>Tariff:</b>  ${this.formData.selectedBaseTariff || ''}<br>
            <b>Description:</b> ${this.formData.description||''}<br>
            <b>Go Live:</b> ${this.formData.goLive ? moment(this.formData.goLive).format(AppConstant.DATE_FORMAT) :''}<br><br>
            You can see the details of this project by visiting https://cts-dev.plains.com/ProjectDashboard <br><br>
            Thank You, <br>
            CTS Support Team <br>
            ctssupport@plains.com `
        }
        const response: any = await this.projectRequestDataService.step1SendEmail(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            }
            }       
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmailWorkflow(direction): Promise<void> {
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': direction,
            'criteria': this.selectedTariffRateType && this.selectedTariffRateType != '' ? this.selectedTariffRateType : projectRequestDataStore.selectedTariffRateType,
            'useremail': this.username,
            'additionalInfo': this.formData.comment
        }
        const response: any = await this.projectRequestDataService.step1SendEmailWorkflow(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value' || (response[key].error) ) {
                    error =  key==='value' ? response[key] : response[key].error ; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error==='' || !error){
                    if(this.autoCallWorkflowSteps===true){
                        console.log(response);
                    }
                    if (!this.wfRequestApproved) {
                        this.getWorkflowStatusGridData();
                    }
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            } else {
                this.uiService.toastService.error(error);
            }
        }       
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmailWorkflowToApprove(direction, extSysTariffType): Promise<void> {
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': direction,
            'criteria': this.selectedTariffRateType && this.selectedTariffRateType != '' ? this.selectedTariffRateType == 'Public' ? extSysTariffType : this.selectedTariffRateType : projectRequestDataStore.selectedTariffRateType == 'Public' ? extSysTariffType : projectRequestDataStore.selectedTariffRateType,
            'useremail': this.username,
            'additionalInfo': this.formData.comment
        }
        const response: any = await this.projectRequestDataService.step1SendEmailWorkflow(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value' || (response[key].error) ) {
                    error =  key==='value' ? response[key] : response[key].error ; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error==='' || !error){
                    if(this.autoCallWorkflowSteps===true){
                        console.log(response);
                    }
                    if (!this.wfRequestApproved) {
                        this.getWorkflowStatusGridData();
                    }
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            } else {
                this.uiService.toastService.error(error);
            }
        }       
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmailWorkflowForParallelStepsCompletion(direction): Promise<void> {
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': direction,
            'criteria': 'complete',
            'useremail': this.username,
            'additionalInfo': this.formData.comment
        }
        const response: any = await this.projectRequestDataService.step1SendEmailWorkflow(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value' || (response[key].error) ) {
                    error =  key==='value' ? response[key] : response[key].error ; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error==='' || !error){
                    if(this.autoCallWorkflowSteps===true){
                        console.log(response);
                    }
                    if (!this.wfRequestApproved) {
                        this.getWorkflowStatusGridData();
                    }
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            } else {
                this.uiService.toastService.error(error);
            }
        }       
    }

    checkIsCTSCommentsEnabled = () => {
        return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, this.workflowstepName) && (this.workflowstepRole == 'Tariff Contributor' || this.workflowstepRole == 'Tariff Admin' || this.workflowstepRole == 'Tariff Reviewer');
    }
}
