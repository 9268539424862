import { tariffOptionsDataService } from '../services/tariff-options-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { TariffOptionsDataStore } from './tariff-options-data-store';
import { accountStore } from '../../../account/stores';
import { agGridService, AgGridService } from 'common/services/ag-grid-service';


export const agGridServiceMovement = new AgGridService();
export const agGridServiceConnectionPoint = new AgGridService();
export const agGridServiceDeduction = new AgGridService();
export const agGridServiceLossAllowance = new AgGridService();
export const agGridServiceRelatedTariff = new AgGridService();
export const agGridServicePipeline = new AgGridService();
export const agGridServiceOwner = new AgGridService();
export const agGridServiceComments = new AgGridService();
export const agGridServiceCTSComments = new AgGridService();

export const tariffOptionsDataStore = new TariffOptionsDataStore(tariffOptionsDataService, agGridServiceMovement, agGridServiceConnectionPoint, agGridServiceDeduction, agGridServiceLossAllowance, agGridServiceRelatedTariff, agGridServicePipeline, agGridServiceOwner, agGridServiceComments, agGridServiceCTSComments, agGridService,  uiService, accountStore);
