import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class PMCTariffDataService {
constructor(private restApi: RestApiService) {}

    async getPipelineGroup(): Promise<any[]> {
            const { data } = await this.restApi.get<any>(Path.url_id24);
            return data;
    }
    
    async getSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getPMCPipelineSystems(assetGroupID): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCPipelineSystems + `?assetGroupID=${assetGroupID}`);
        return data;
    }

    async getTariffRequestTypes(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffRequestTypes + `?jurisdiction=${jurisdiction}`);
        return data;
    }

    async getMiscellaneousSettingsData(defaultUserLocation): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.url_id1 + defaultUserLocation);
        return data;
    }

    async getFacilities(systemGroupId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id26+`?systemGroupId=${systemGroupId}`);
        return data;
    }

    async getFees(systemGroupId, startDate): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCSystemFees+`?systemGroupId=${systemGroupId}&Period=${startDate}`);
        return data;
    }

    async getProducts(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id27);
        return data;
    }

    async getDistributionTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id28);
        return data;
    }
    
    async getExternalSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getExternalSystems);
        return data;
    }

    async getProductTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCProductTypes);
        return data;
    }

    async getTariffApplications(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffApplications);
        return data;
    }

    async getUnits(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id29);
        return data;
    }

    async getCurrency(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id30);
        return data;
    }

    async getRegulator(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getRegulator);
        return data;
    }

    async getFacilityCodes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getFacilityCodes);
        return data;
    }

    async getDestination(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getDestination);
        return data;
    }

    async getLSD(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLSD);
        return data;
    }
    
    async getPMCShrinkage(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCShrinkage);
        return data;
    }

    async getPMCLossAllwance(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCLossAllwance);
        return data;
    }

    async savePMCTariff(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCTariff, reqbody, {});
        return data;
    }

    async savePMCTariffDetailFeeDetail(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCTariffDetailFeeDetail, reqbody, {});
        return data;
    }


    async deletePMCTariff(tariffID): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.deletePMCTariff+`?PMCTariffID=${tariffID}`);
        return data;
    }

    async duplicatePMCTariff(PMCTariffID, userName): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.duplicatePMCTariff+`?PMCTariffID=${PMCTariffID}&user=${userName}`);
        return data;
    }

    async getPMCDashboardDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.getPMCDashboardDetails, reqbody, {});
        return data;
    }

    async savePMCFeeDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCFeeData, reqbody, {});
        return data;
    }

    async savePMCTariffNotes(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCTariffNotes, reqbody, {});
        return data;
    }

    async deletePMCTariffNote(notesID: number): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.deletePMCTariffNote+`?notesID=${notesID}`);
        return data;
    }

    async updatePMCTariffNote(notesID: number): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.savePMCTariffNotes+`?notesID=${notesID}`);
        return data;
    }

    async uploadFile(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCTariffDocument, formData);
        return data;
    }

    async updateAttachment(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.updateAttachment, reqbody, {});
        return data;
    }

    async downloadAttachment(fileID): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.downloadAttachment+`?docId=${fileID}`);
        return data;
    }

    async deleteAttachment(docID): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.deleteAttachment+`?docId=${docID}`);
        return data;
    }
}

export const pmcTariffDataService = new PMCTariffDataService(restApiService);
