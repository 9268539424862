import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class BaseTariffDataService {
    constructor(private restApi: RestApiService) {}

    async getTariffTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id10);
        return data;
    }  
    async getJurisdictionDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id8);
        return data;
    }

    async getProductTypesDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id11);
        return data;
    }

    async getTariffAgency(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id12+`jurisdiction=${jurisdiction}&stateAbbr=All&agency=All&agencyCode=All`);
        return data;
    }

    async getFillingCarrierDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getFilingCarrier);
        return data;
    }
    
    async getPipelineAreaDropdownData(id : number): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id14+id);
        return data;
    }

    async saveBaseTariff(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.url_id15, reqbody, {});
        return data;
    }
    
}

export const baseTariffDataService = new BaseTariffDataService(restApiService);
