import { observable, action, makeAutoObservable } from 'mobx';
import { DataService } from '../services/data-service';
import {
    AuthToken,
    StorageService,
    LoggedInAuthToken,
} from 'shared/types/shared';
import { AppConstant } from '../../../app_constants';
import { UiService, uiService } from 'shared/services/ui-service';
import Catch from 'shared/decorators/catch-decorator';
import { Subject } from 'rxjs';
import { UserService } from 'shared/services/user-service';
import { errorHandler } from 'shared/handlers/error-handler';
import Loader from 'shared/decorators/loader-decorator';

import {
    USER_LOADING_ERROR,
} from '../account_constant';
import { dataStore } from 'common/stores';

export class AccountStore {
    @observable isLoggedIn = false;
    @observable userName = '';
    @observable displayName = '';
    @observable userRoles: string[] = [];
    @observable rolesJson: any = [];
    @observable defaultUserLocation = '';
    @observable userPipelineId: any=[];
    @observable userApproverAccess= false;

    constructor(
        private accountDataService: DataService,
        private localStorageService: StorageService,
        private uiService: UiService,
        private accountSubscripitonService: Subject<LoggedInAuthToken>,
        private userService: UserService
    ) {
        makeAutoObservable(this);
        this.accountSubscripitonService.subscribe(() => {
            console.log('accountStore logout', this.accountSubscripitonService);
            this.logOut();
        });
    }

    @action
    setLoggedInStatus(isLoggedIn: boolean): void {
        this.isLoggedIn = isLoggedIn;
    }

    @action
    setUserName(userName: string): void {
        this.userName = userName;
    }

    @action
    setDisplayName(displayName: string): void {
        this.displayName = displayName;
    }

    @action
    setUserRole(roles: string[]): void {
        this.userRoles = roles;
    }

    @action
    setRoleAccessData(rolesJson: any): void {
        this.rolesJson = rolesJson;
    }

    @action
    setDefaultUserLocation(defaultUserLocation: any): void {
        this.defaultUserLocation = defaultUserLocation;
    }

    @action
    setUserPipelineId(pipelineID: any): void {
        this.userPipelineId = pipelineID;
    }

    @action
    setUserApproverAccess(access: any): void {
        this.userApproverAccess = access;
    }

    setUserID(userId: number): void {
        this.localStorageService.set(AppConstant.USERID, userId);
    }

    getUserInfo(): any {
        return this.localStorageService.get(AppConstant.USER_INFO);
    }

    getUserID(): any {
        return this.localStorageService.get(AppConstant.USERID);
    }

    getRoleData(): any {
        return this.localStorageService.get(AppConstant.ROLE_ACCESS_DATA);
    }

    @Catch(err => console.log(err))
    async login(): Promise<void> {
        const token = await this.accountDataService.login();
        console.log('Microsoft login token', JSON.stringify(token));
        if (token && token.roles.length > 0) {
            await this.getRolesAccessData(token.userName);
            this.setAuthInfo(token, true);
        }
       
    }

    getRoleForAView(roles: string[]): string | undefined {
        return this.userRoles.find(role => roles.includes(role));
    }

    isLoggedInUserHasRoleToAccessAView(roles: string[]): boolean {
        return this.userRoles.some(role => roles.includes(role));
    }

    setAuthInfo(token: AuthToken, isLoggedIn: boolean): void {
        this.setLoggedInStatus(isLoggedIn);
        this.setUserName(token.userName);
        this.setDisplayName(token.displayName);

    }

    logOut = (): void => {
        this.localStorageService.remove(AppConstant.AUTHTOKEN);
        this.localStorageService.remove(AppConstant.LOGINTIME);
        this.localStorageService.remove(AppConstant.USERID);
        this.localStorageService.remove(AppConstant.USER_INFO);
        this.setAuthInfo(AppConstant.DEFAULT_USER_DETAILS, false);
        this.setUserID(0);
        this.accountDataService.logout();
    };

    @Catch(err => errorHandler(USER_LOADING_ERROR))
    async getRolesAccessData(userName): Promise<void> {
        const response: any = await this.userService.getRolesAccessData(userName);
        console.log('getRolesAccessData' + JSON.stringify(response))
        if(response && response.data) {
            const userRoleNames = (response.data.map(x => x.roleName)).filter((x , index, arr) => arr.indexOf(x) === index);
            this.setUserRole(userRoleNames);
            this.setRoleAccessData(response.data);
            if(response.data.length > 0) {
                const defaultUserLocation  = response.data[0].defaultLocation;
                this.setDefaultUserLocation(defaultUserLocation);
                this.localStorageService.set(AppConstant.DEFAULT_USER_LOCATION, defaultUserLocation);
                const pipelineIDArray: any[] = response.data.map(x => x.pipelinePrimaryApprovers);
                this.setUserPipelineId(pipelineIDArray);
                // this.localStorageService.set(AppConstant.DEFAULT_USER_PIPELINEID, response.data[0].pipelineID);
                // this.setUserApproverAccess(response.data[0].primaryApprover);
                // this.localStorageService.set(AppConstant.PRIMARY_APPROVER, response.data[0].primaryApprover);
            }
            
            this.localStorageService.set<any>(AppConstant.ROLE_ACCESS_DATA, response.data);
        }
        console.log('login successful');
    }

    // @Loader
    // @Catch(err => errorHandler(INVALID_USERID))
    // async getLoggedInUserDetailsIfUserIdZero(email: string): Promise<void> {
    //     const response: any = await this.userService.getLoginUserInfo(email);
    //     console.log("getLoginUserApiResponse " + JSON.stringify(response))
    //     const userId =
    //         response && response.data && response.data.Data && response.data.Data.ID ? response.data.Data.ID : 0;
    //     if (userId > 0) {
    //         this.localStorageService.set<any>(AppConstant.USER_INFO, userInfo);
    //     }
    //     this.setUserID(userId);
    // }
}
