import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { GradeMaintenanceDataStore } from '../stores/grade-maintenance-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { GradeMaintenanceResponse } from '../model/model';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/grade.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { gradeMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface GradeMaintenanceContainerProps {
    gradeMaintenanceDataStore: GradeMaintenanceDataStore;
}

interface GradeMaintenanceContainerState {
    search: string;
}

@inject('gradeMaintenanceDataStore')
@observer
export class GradeMaintenanceContainer extends Component<GradeMaintenanceContainerProps, GradeMaintenanceContainerState> {
    constructor(props: GradeMaintenanceContainerProps | Readonly<GradeMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Grade Maintenance')
    }
    
    componentDidMount() {
      const {gradeMaintenanceDataStore} = this.props;
      const previousGrade = history?.location?.state as { from?: string }
      history.push('/GradeMaintenance');
       if(previousGrade?.from && previousGrade.from==='/GradeDetails'){
           gradeMaintenanceDataStore.onSearchClick();   
     } else {
            gradeMaintenanceDataStore.init();   
        }
       gradeMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {gradeMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Grade Maintenance_filter');
      this.props.gradeMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Grade Maintenance_' + accountStore.userName);
      this.props.gradeMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    onBtClearSelection = () => {
      const { gradeMaintenanceDataStore } = this.props;
      gradeMaintenanceDataStore.onClearSearch();
    }

    onSearchClick = () => {
      const { gradeMaintenanceDataStore } = this.props;
      gradeMaintenanceDataStore.onSearchClick();
    }

    onAddNewClick = async() => {
      const { gradeMaintenanceDataStore } = this.props;
        
      gradeMaintenanceDataStore.onClickAddNewGradeButton = true;
      gradeMaintenanceDataStore.onClickGradeNameOnGrid = false;
      gradeMaintenanceDataStore.agGridService.destroyGrid();
      gradeMaintenanceDataStore.getPipelineSystemDropdown();
      gradeMaintenanceDataStore.getSelectedGradeData();
      history.push('/GradeDetails');
    }

    onChangeGradeName = (e) => {
      this.props.gradeMaintenanceDataStore.onChangeGradeName(e.target.value)
    }


    onChangeGrade = (e) => {
      this.props.gradeMaintenanceDataStore.onChangeGrade(e.target.value)
    }
    handleStateChangeFromDelToActive = async() => {
      const { gradeMaintenanceDataStore } = this.props;
      const requestBody: any = await gradeMaintenanceDataStore.agGridService.getSelectedRows();
      const updatedRowIDs = gradeMaintenanceDataStore.gradeMaintenanceData.filter(a => gradeMaintenanceDataStore.getUpdatedRowIDs().includes(a.gradeID.toString()));
      if(requestBody.length > 0 && !requestBody.some((item) => item.action !== 'D')) {
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.gradeMaintenanceDataStore.updateStatusFromDelToActive(),
            'Active',
            'All the unsaved changes will be lost. Are you sure you want to activate the selected grades?'
        );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.gradeMaintenanceDataStore.updateStatusFromDelToActive(),
            'Active',
            'Are you sure you want to activate the selected grades?'
        );
        }
      } else {
        const secondaryText = requestBody.length > 0 ? 'Please select only the inactive grades to perform the operation' : 'No records are selected. Please select atleast one inactive grade to perform the operation.';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
      console.log(' succesfully');
    }
    
    onClickActivate = () => {
      const { gradeMaintenanceDataStore } = this.props;
      gradeMaintenanceDataStore.activateGrade();
    }

    render(): React.ReactNode {
      const { gradeMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderGradeMaintenanceScreen()}
        </Container>
      );
    }

    renderGradeMaintenanceScreen() : React.ReactNode {
      const { gradeMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='gradeMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Grade Maintenance</h2>
                </div>
                
                {/* <h4 className='search_criteria_title'> 
                  <label>Search Criteria</label> 
                </h4> */}
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                    <label className="standard_label_style">Grade</label>
                      <input 
                        type="text"
                        value={gradeMaintenanceDataStore.selectedgrade}
                        className="search_text_field"
                        // disabled={true}
                        style={{width: '20%', marginRight: '10px'}}
                        onChange = {(e) => this.onChangeGrade(e)}
                      />
                        <label className="standard_label_style">Grade Name</label>
                      <input 
                        type="text"
                        value={gradeMaintenanceDataStore.selectedgradeName}
                        className="search_text_field"
                        // disabled={true}
                        style={{width: '20%', marginRight: '10px'}}
                        onChange = {(e) => this.onChangeGradeName(e)}
                      />
                    <label className="standard_label_style">Pipeline System</label>
                      <ReactSelect
                        className='select'
                        values={gradeMaintenanceDataStore.selectedPipelineSystem}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...gradeMaintenanceDataStore.pipelineSystemDropdownOption]}
                        onChange={(e: any) => {gradeMaintenanceDataStore.selectedPipelineSystem = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                  </div>
                  <div className='searchButtonDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                        Search
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                        Clear
                    </CustomButton>
                    <div className='otherHeaderButtonsDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled={gradeMaintenanceDataStore.isSaveDisabled()}>
                          Add Grade
                      </CustomButton>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onClickActivate} disabled= {gradeMaintenanceDataStore.isActivateButtonDisabled || gradeMaintenanceDataStore.isActivateDeactivateDisabled()}>
                        Activate
                      </CustomButton>
                      </div>
                  </div>
                </div>
              </div>
              {this.props.gradeMaintenanceDataStore.showLoader == true &&
                <div className = 'alert-overlay'> 
                  <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                </div> 
              }
              <AgGridComponent gridConfig={this.getGridConfig('Grade Maintenance')} />
              {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
            </Col>
          </Row>
      );
    }


  onSelectionChanged = (event: SelectionChangedEvent) => {
      const { gradeMaintenanceDataStore } = this.props;
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        gradeMaintenanceDataStore.selectedGradeIds = selectedRows.map(x => { return x.gradeID });
        gradeMaintenanceDataStore.setIsActivateButtonDisabled(gradeMaintenanceDataStore.isSaveDisabled())
      } else {
        gradeMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
    }

    private getGridConfig(gridId): GridOptions {
      const {
          gradeMaintenanceDataStore: { GradeMaintenanceData, agGridService }
      } = this.props;
      const { gradeMaintenanceDataStore } = this.props;
      const columnDefs = gradeMaintenanceDataStore.getColDef();
      const rowData = GradeMaintenanceData;
      const onSelectionChanged = this.onSelectionChanged;
      const gradeMaintenanceGridParams: any = {
        rowData,
        columnDefs,
        onSelectionChanged
      };
      return agGridService.getGridConfig(gradeMaintenanceGridParams, gridId);
    }
}
