import React from 'react';
// import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { pipelineMaintenanceDataStore } from '../../stores';


export const PipelineDetailCellRenderer = (props: ICellRendererParams) => {
    return (
         props.data.type==='Base System' ?
            <span style={{color: 'blue', 'textDecoration': 'underline'}}
            onClick={()=>pipelineMaintenanceDataStore.openEditPipeline(props.data.pipelineID, props.data.syncStatus)}>
                {props.data.name}</span>
        :   <span> 
            {props.data.name}</span>
    );
};