import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { profitCenterColDef } from '../components/profit-center-col-def';
import moment from 'moment';
import Path from '../../../../Path';
// import Path, { ProfitCenterSettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { ProfitCenterMaintenanceDataService } from '../services/profit-center-maintenance-data-service';
import { ProfitCenterMaintenanceResponse } from '../model/model';
import { ProfitCenterMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { profitCenterMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
// import { AddProfitCenterDetailsColDef } from '../components/add-profitCenter-details-container';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class ProfitCenterMaintenanceDataStore {
    @observable profitCenterMaintenanceData: ProfitCenterMaintenanceResponse[] = [];
    @observable modifiedProfitCenterMaintenanceData: ProfitCenterMaintenanceResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupProfitCenterMaintenanceData: ProfitCenterMaintenanceResponse[] = [];
    @observable isRefreshed = false;
    @observable showModal = false;
    @observable selectedprofitCenter = '';
    @observable selectedDescription = '';
    @observable selectedSuccprofitCenter = '';
    @observable selectedprofitCenterGrp = '';
    @observable selectedToDate = '';
    @observable selectedFromDate = '';
    endDate: any = moment().toISOString().slice(0, 10);
    @observable pipelineSystemDropdownOptions: any[] = [];
    @observable statusDropdownOptions: any[] = ServiceStatus;
    tariffStatesData: any[] = [];
    pipelineSystemData: any[] = [];
    
    @observable pipelineSystemDropdownOption: any[] = [];
    @observable locnNameDropdownOption: any[] = [];
    @observable selectedProfitCenterDetails: any = {};
    @observable addOrEditedLocnName = '';
    @observable selectedProfitCenterId = 0;
    @observable addOrEditProfitCenterGridData: any[] = [];
    @observable backupAddOrEditProfitCenterGridData: any[] = [];
    @observable onClickProfitCenterNameOnGrid = false;
    @observable onClickAddNewProfitCenterButton = false;
    @observable isActivateButtonDisabled = true;
    @observable updatedFieldonProfitCenterDetailsScreen = false;
    @observable isSearchClicked = false;
    @observable baseSystemDropdownOptions: any = [];
    @observable baseSystemDropdownOptionsUI: any = [];
    @observable selectedBaseSystem = { value: 'All', label: 'All' };

    constructor(
        public profitCenterMaintenanceDataService: ProfitCenterMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditProfitCenter: AgGridService
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        this.pipelineSystemDropdownOptions = [];
        dataStore.setShowUnsavedWarningAlert(false);
        // this.getLocationDropdownData();
        this.getBaseSystemDropdown();
        this.selectedprofitCenter = '';
        this.selectedDescription = '';
        this.selectedSuccprofitCenter = '';
        this.selectedprofitCenterGrp = '';
        this.selectedToDate = '';
        this.selectedFromDate = '';
        this.selectedBaseSystem = { value: 'All', label: 'All' };
        this.userName = this.accountStore.displayName;
        this.selectedProfitCenterId = 0;
        this.selectedProfitCenterDetails = {};
    }

    // @Loader
    @action
    // @Catch(() => errorHandler(ProfitCenterMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const pageSize = this.agGridService.paginationGetPageSize();
        if(dataStore.isPageSizeChanged) {
            this.agGridService.resetCache(Number(pageSize));
        }
        const dataSource = {
            getRows: (params) => {
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
                const pageSize = this.agGridService.paginationGetPageSize();
                const page =  params.endRow / (pageSize ? pageSize : 100);
                const pageNo = this.isRefreshed || dataStore.isPageSizeChanged || page <= 1 ? 1 : parseInt(page.toFixed(0));
                const data = this.getapiRequest(pageNo, pageSize, params.sortModel);
                const filterRequestBody: any = [];
                for (const [key, value] of Object.entries(params.filterModel)) {
                    console.log(key, value);
                    const item = {
                        propertyName: key,
                        value: value && value['filterType'] === 'date'? value['dateFrom'] : value ? value['filter']:'',
                        operator: value ? value['type'] : '',
                        joinPrev: 'none'
                    
                      }
                      filterRequestBody.push(item);
                  }
                  console.log('params' +JSON.stringify(filterRequestBody));
                this.agGridService.showLoadingOverlay();
                this.profitCenterMaintenanceDataService.getProfitCentersData(data, filterRequestBody).then((response) => {
                const totalCount = response && response.length > 0 ? response[0].totalCount : response.length
                    params.successCallback(response ? response : [], totalCount);
                    dataStore.setCacheBlockSize(response? response.length : 0)
                    this.agGridService.hideOverlay();
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false); 
                }).catch(() => {
                    params.successCallback([], 0);
                    dataStore.setCacheBlockSize(0);
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false);
                    this.agGridService.hideOverlay();
                    errorHandler(ProfitCenterMaintenanceMessages.FETCH_ERROR_MESSAGE)
                });
                dataStore.setCacheBlockSize(Number(pageSize));
            }
        }
        this.agGridService.setDatasource(dataSource);
    }

    getapiRequest = (currentPage, pageSize, sortModel?) => {
        if(this.isSearchClicked) {
            const baseDetails = this.baseSystemDropdownOptions.length > 0 && this.selectedBaseSystem.label !== 'All' ? this.baseSystemDropdownOptions.filter((item) => item.plName === this.selectedBaseSystem.label) : 0
            const data = {
                profitCenter: this.selectedprofitCenter,
                description: this.selectedDescription,
                succprofitCenter: this.selectedSuccprofitCenter,
                profitCenterGrp: this.selectedprofitCenterGrp,
                toDate: this.selectedToDate === '' ? '9999-12-31' : this.selectedToDate,
                fromDate:  this.selectedFromDate === '' ? '9999-12-31' : this.selectedFromDate,
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
                baseID: baseDetails && baseDetails.length > 0 ?  baseDetails[0].pipelineID : 0
            }
            return data;
        } else {
            const data = {
                profitCenter: '',
                description: '',
                succprofitCenter: '',
                profitCenterGrp: '',
                toDate: '9999-12-31',
                fromDate: '9999-12-31',
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 100,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
                baseID: 0
            }
            return data;
        }
    }

    @Loader
    @Catch(() => errorHandler(ProfitCenterMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        this.isSearchClicked = false;
        this.isRefreshed = true;
       
        dataStore.onGridReady(); 
        
        this.selectedprofitCenter = '';
        this.selectedDescription = '';
        this.selectedprofitCenterGrp = '';
        this.selectedSuccprofitCenter = '';
        this.selectedToDate = '';
        this.selectedFromDate = '';
        this.selectedBaseSystem = { value: 'All', label: 'All' };
    }

    @Loader
    @Catch(() => errorHandler(ProfitCenterMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        this.isRefreshed = true;
        this.isSearchClicked = true;
        dataStore.onGridReady();
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.profitCenterMaintenanceDataService.getBaseSystems();
        this.baseSystemDropdownOptions = baseSystemDropdownOptions;
        this.baseSystemDropdownOptionsUI = baseSystemDropdownOptions.map(item => {return {'value': item.plName, 'label': item.plName}});
    }

    getColDef() {
        // this.updatePushtoVmacsButtonColDef();
        return profitCenterColDef;
    }

    // getAddProfitCenterDetailsColDef() {
    //     this.updatePushtoVmacsButtonColDef();
    //     return AddProfitCenterDetailsColDef;
    // }

    @action
    reset(): void {
        this.setProfitCenterMaintenanceData(this.backupProfitCenterMaintenanceData);
        this.modifiedProfitCenterMaintenanceData = this.backupProfitCenterMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setProfitCenterMaintenanceData(profitCenterMaintenanceData: ProfitCenterMaintenanceResponse[]): void {
        this.profitCenterMaintenanceData = profitCenterMaintenanceData
    }

    setbackupProfitCenterMaintenanceData(backUpList: ProfitCenterMaintenanceResponse[]) {
        this.backupProfitCenterMaintenanceData = backUpList;
    }

    @computed
    get ProfitCenterMaintenanceData() {
        return toJS(this.profitCenterMaintenanceData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    // @Loader
    // @action
    // @Catch(() =>  errorHandler(ProfitCenterMaintenanceMessages.FAILED_SUBMIT))
    // async saveProfitCenter(saveAndAddNew): Promise<void> {
    //     const updatedRowIDs = this.addOrEditProfitCenterGridData.filter(a => a.isSelected === true);
    //     const profitCenterDetailDTOs: any = [];
    //     let requestBody: any = {};
    //     const localValidatingErrorBody: any = [];
    //     if(this.addOrEditedLocnName && this.addOrEditedLocnName !== '') {
    //         const filteredProfitCenterData = this.profitCenterMaintenanceData.filter((profitCenter) => profitCenter.profitCenterType === this.addOrEditedLocnName);
    //         const existingProfitCenter  = filteredProfitCenterData.some((profitCenter) => profitCenter.profitCenterID !==  this.selectedProfitCenterId)
    //         const isUniqueProfitCenter = this.onClickProfitCenterNameOnGrid ? !existingProfitCenter : filteredProfitCenterData.length <=0;
    //         if(isUniqueProfitCenter) {
    //             updatedRowIDs.forEach((item) => {
    //                 const serviceDate = item.serviceDate ? moment.utc(new Date(item.serviceDate)).format('YYYY-MM-DDTHH:mm:ss')  : null;
    //                 item.profitCenterRelPLID = item.profitCenterRelPLID && item.profitCenterRelPLID > 0 ? item.profitCenterRelPLID: 0,
    //                 item.pipelineID = item.pipelineID && item.pipelineID > 0 ? item.pipelineID : 0,
    //                 item.serviceDate = serviceDate,
    //                 item.serviceStatus = item.serviceStatus === 'Active' || item.serviceStatus === 'A' ?  'A' : 'I'
    //                 if(item.serviceDate) {
    //                     profitCenterDetailDTOs.push(item);
    //                 } else {
    //                     const message = 'Please select valid service date for the linked pipelines';
    //                     if(!localValidatingErrorBody.includes(message)) {
    //                         localValidatingErrorBody.push(message);
    //                     }
    //                 }
    //             });
    //         } else {
    //             const message = 'Tried to create duplicate profitCenter. Please create the unique record.';
    //             if(!localValidatingErrorBody.includes(message)) {
    //                 localValidatingErrorBody.push(message);
    //             }
    //         } 
    //     } else {
    //         const message = 'Please insert valid ProfitCenter Name as mandatory fields.';
    //         if(!localValidatingErrorBody.includes(message)) {
    //             localValidatingErrorBody.push(message);
    //         }
    //     }
    //     if(localValidatingErrorBody.length > 0) {
    //         const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
    //         errorHandler(messages);
    //     } else if(updatedRowIDs.length === profitCenterDetailDTOs.length) {
    //         requestBody = { profitCenterID: this.selectedProfitCenterId, 
    //             locnName: this.addOrEditedLocnName, 
    //             createUser: this.userName,
    //             profitCenterDetailDTOs: profitCenterDetailDTOs,
    //         } 
    //         const response: ProfitCenterMaintenanceResponse[] = await this.profitCenterMaintenanceDataService.updateProfitCenterMaintenanceData(requestBody, this.onClickProfitCenterNameOnGrid);
    //         if (response) {
    //             dataStore.setShowUnsavedWarningAlert(false);
    //             this.onClickProfitCenterNameOnGrid = false; 
    //             this.cellValueChangeMap = {};
    //             this.selectedProfitCenterId = 0;
    //             this.selectedProfitCenterDetails = {};
    //             this.updatedFieldonProfitCenterDetailsScreen = false;
    //             this.uiService.toastService.success(ProfitCenterMaintenanceMessages.SAVE);
    //             if(!saveAndAddNew) {
    //                 this.showLoader = false;
    //                 this.isRefreshed = false;
    //                 this.agGridServiceForAddOrEditProfitCenter.destroyGrid();
    //                 this.onClickAddNewProfitCenterButton = false;
    //                 this.onCloseProfitCenterDetails();
    //             } else {
    //                 this.resetAddProfitCenterMaintenanceGridData();
    //             }

    //         }
    //     }
    // }


    @action
    async resetAddProfitCenterMaintenanceGridData(): Promise<void> {
            this.addOrEditedLocnName = '';
            this.addOrEditProfitCenterGridData = [];
            this.backupAddOrEditProfitCenterGridData = []
            this.pipelineSystemData.map((item) =>
             {
                item.serviceStatus =  'Active',
                item.serviceDate =  null,
                item.allowTrades = 'N'
                item.isSelected = false
                this.addOrEditProfitCenterGridData.push(item)
            });
            this.backupAddOrEditProfitCenterGridData = this.addOrEditProfitCenterGridData;
            this.onClickProfitCenterNameOnGrid = false;
            this.isRefreshed = false
        this.agGridServiceForAddOrEditProfitCenter.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(ProfitCenterMaintenanceMessages.FAILED_SUBMIT))
    async deleteProfitCenter(): Promise<void> {
        const requestBody = { 
            profitCenterID: this.selectedProfitCenterId, 
            locnName:this.addOrEditedLocnName,
            createUser: this.userName, 
            profitCenterDetailDTOs: [] 
        }
        await this.profitCenterMaintenanceDataService.deleteProfitCenterMaintenanceData(requestBody).then((response) => {
            if(response && response.value) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                  () => {console.log('')},
                  ConfirmWithOK,
                  {secondaryText: response.value !== '' ? response.value : 'Record cannot be deleted because it is linked to a base system. Unlink profitCenter from base system.'} as ConfirmWithOKProps,
                  'confirmPopup'
                );
                const profitCenterData = {locnName: profitCenterMaintenanceDataStore.addOrEditedLocnName}
             //   profitCenterMaintenanceDataStore.openProfitCenterDetails(profitCenterData);
                return ;
            }
            dataStore.setShowUnsavedWarningAlert(false);
            this.onClickProfitCenterNameOnGrid = false;
            this.onClickAddNewProfitCenterButton = false;
            this.agGridServiceForAddOrEditProfitCenter.destroyGrid();
            this.isActivateButtonDisabled= true;
            this.updatedFieldonProfitCenterDetailsScreen = false;
            this.onCloseProfitCenterDetails();
        });
    }



 addValuesInCellDropdowns(): void {
        // const baseSystemDropdownOptions = this.selectedlocnName !== 'All' ? this.baseSystemDropdownOptions.filter((item) => item.valueText  === this.selectedlocnName) : this.baseSystemDropdownOptions;
        // this.agGridService.updateOptionCellEditorValues(
        //     baseSystemDropdownOptions,
        //     'pipeline',
        //     'plName'
        // );

        this.agGridServiceForAddOrEditProfitCenter.updateOptionCellEditorValues(
            ServiceStatus,
            'serviceStatus',
            'status'
        );
    }
     

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
      //  get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
       // this.updateRow(item);
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isDeleteDisabled = ()  => {
      const requestBody: any = this.addOrEditProfitCenterGridData.filter(a => a.isSelected === true);
      console.log(requestBody);
        return !this.selectedProfitCenterId || requestBody.length > 0;
    };

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    // updatePushtoVmacsButtonColDef = () => {
    //     const pushToVmacsButtonColDef = profitCenterColDef.find(x => x.colId === 'PushToVMACS');
    //     if (pushToVmacsButtonColDef) {
    //         pushToVmacsButtonColDef.hide = !dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSync) ? true : false,
    //         pushToVmacsButtonColDef.suppressColumnsToolPanel = !dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSync) ? true : false,
    //         pushToVmacsButtonColDef.cellRendererParams = {
    //             dataStore : profitCenterMaintenanceDataStore,
    //             handleClickPushVmacs : (env, data) => this.pushToVmacs(env, data),
    //             updatedData : this.getUpdatedRows() 
    //         };
    //     }
    // }

    // getUpdatedRows = () => {
    //     const updatedRowIDs = this.profitCenterMaintenanceData.filter(a => this.getUpdatedRowIDs().includes(a.profitCenterID.toString()));
    //     return updatedRowIDs;
    // }

    // @Loader
    // @action
    // @Catch((err) => {
    //     if(err && err.response.data.cts_response) {
    //         let msg = '';
    //         err.response.data.cts_response.forEach((item) => {
    //             msg = msg + ' ' + (item.detail ? item.detail : '');
    //         })
    //         errorHandler(msg);
    //     }
    // })
    // async pushToVmacs(env, data): Promise<void> {
    //     // const action = data.action === 'U' ? 'Update' : 'Insert';
    //     const profitCenterRelPLID =  data.profitCenterRelPLID === 0 ? '' :  data.profitCenterRelPLID.toString();
    //     const requestBody: any =  { 
    //         'http_action' : null,
    //         'http_record_id': data.profitCenterID.toString(),
    //         'http_childrecord_id': profitCenterRelPLID,
    //        // 'http_cts_tablename': ProfitCenterSettingsDBTableName.TableName,
    //        // 'http_cts_mappingtablename' : ProfitCenterSettingsDBTableName.MappingTableName,
    //         'http_userid': this.userName,
    //         'http_environment': env.toUpperCase() 
    //     };
    //     console.log('pushtoVMacs')
    //     if(requestBody.length !== 0) {
    //         const response = await this.profitCenterMaintenanceDataService.pushToVmacs(requestBody);
    //         // if(response) {
    //             await this.onSearchClick();
    //             this.uiService.toastService.success(ProfitCenterMaintenanceMessages.SYNC);
    //         // }
    //     }

    // }

    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const selectedRowData = this.addOrEditProfitCenterGridData;
        if(selectedRowData && selectedRowData.length > 0) {
            const profitCenters = this.selectedProfitCenterId.toString();
            const linkedpipelines= (selectedRowData.filter((item) => item.profitCenterRelPLID && item.profitCenterRelPLID !== 0).map(x => x.profitCenterRelPLID )).join(',');
            const requestBody: any =  { 
                'http_action' : null,
                'http_record_id': profitCenters,
                'http_childrecord_id': linkedpipelines,
              //  'http_cts_tablename': ProfitCenterSettingsDBTableName.TableName,
              //  'http_cts_mappingtablename' : ProfitCenterSettingsDBTableName.MappingTableName,
                'http_userid': this.userName,
                'http_environment': env.toUpperCase()   
            };
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.profitCenterMaintenanceDataService.pushToVmacs(requestBody);
                // if(response) {
                    // await this.onSearchClick();
                    // this.openProfitCenterDetails({locnName: this.addOrEditedLocnName});
                    this.updatedFieldonProfitCenterDetailsScreen = false;
                    this.uiService.toastService.success(ProfitCenterMaintenanceMessages.SYNC);
                // }
            }
    
        }
       
    }

    @action
   isEditableJurisdiction(params) {
        if (params.data.profitCenterID <= 0) {
        return true
        }
        return false;
   }

   @action
   async onChangeProfitCenter(value) {
        this.selectedprofitCenter = value;
   }

   @action
   async onChangeDescription(value) {
        this.selectedDescription = value;
   }

   @action
   async onChangeSuccProfitCenter(value) {
        this.selectedSuccprofitCenter = value;
   }

   @action
   async onChangeProfitCenterGrp(value) {
        this.selectedprofitCenterGrp = value;
   }

   @action
   async onChangeToDate(value) {
        this.selectedToDate = value;
   }

   @action
   async onChangeFromDate(value) {
        this.selectedFromDate = value;
   }

   
   mapEditableColumnsProfitCentersMainGrid(currentItem: any, updatedItem: any) {
    currentItem.profitCenterRelPLID = updatedItem.profitCenterRelPLID;
    currentItem.profitCenterID = updatedItem.profitCenterID;
    currentItem.locnName = updatedItem.locnName;
    currentItem.locnCode = updatedItem.locnCode;
    currentItem.pipelineID = updatedItem.pipelineID;
    currentItem.plName = updatedItem.plName;
    currentItem.syncStatus = updatedItem.syncStatus;
    currentItem.testSyncDate = updatedItem.testSyncDate;
    currentItem.prodSyncDate = updatedItem.prodSyncDate;
    currentItem.serviceStatus = updatedItem.serviceStatus;
    currentItem.allowTrades = updatedItem.allowTrades;
    currentItem.serviceDate = updatedItem.serviceDate;
    currentItem.action = updatedItem.action;
}


//    @Loader
//     @action
//     @Catch(() => errorHandler(ProfitCenterMaintenanceMessages.FAILED_SUBMIT))
//     async updateStatusFromDelToActive(): Promise<void> {
//         const selectedRowIDs: any = await this.agGridService.getSelectedRows();
//         const requestBody: any = [];
//         const localValidatingErrorBody: any = [];
//         selectedRowIDs.forEach((item) => {
//             if(item.locnName && item.locnName !== '') {
//                 const isUniqueProfitCenter = this.profitCenterMaintenanceData.filter((profitCenter) => item.profitCenterType === profitCenter.profitCenterType).length <= 1;
//                 if(isUniqueProfitCenter) {
//                     item.createUser =  this.userName,
//                     item.profitCenterID = item.profitCenterID ? item.profitCenterID : 0,
//                     item.action = item.action && item.action === 'D' ? 'U' : item.action,
//                     item.profitCenterDetailDTOs = []
//                     requestBody.push(item);
//                 } else {
//                     const message = `Tried to create duplicate profitCenter with ProfitCenter Code as ${item.locnCode} and ProfitCenter Name as ${item.locnName}. Please create the unique record.`;
//                     if(!localValidatingErrorBody.includes(message)) {
//                         localValidatingErrorBody.push(message);
//                     }
//                 }
//             } else {
//                 const message = 'Please insert valid ProfitCenter Name as mandatory fields.';
//                 if(!localValidatingErrorBody.includes(message)) {
//                     localValidatingErrorBody.push(message);
//                 }
//             }
//         });
//         if(localValidatingErrorBody.length > 0) {
//             const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
//             errorHandler(messages);
//         } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
//             const response: ProfitCenterMaintenanceResponse[] = await this.profitCenterMaintenanceDataService.updateStatusFromDelToActive(requestBody);
//             let errorInSave = false;
//             let errorSummary: ToastMessage[] = [];
//             if (response) {
//                 for (const key in response) {
//                     if (response[key].profitCenterID) {
//                         const objIndex = this.profitCenterMaintenanceData.findIndex(a => ((a.profitCenterID === response[key].profitCenterID)|| (a.profitCenterType === response[key].profitCenterType && a.profitCenterDesc === response[key].profitCenterDesc)));
//                         if(objIndex >= 0) {
//                             const backupItem = this.profitCenterMaintenanceData[objIndex];
//                             this.mapEditableColumnsProfitCentersMainGrid(backupItem, response[key]);
//                         }
//                     }
//                     else {
//                         errorInSave = true;
//                         errorSummary = [...errorSummary, { id: key, description: response[key].profitCenterID.toString() }];
//                     }
//                 }
//             }
//             this.cellValueChangeMap = {};
//             if (errorInSave) {
//                 this.uiService.toastService.error('', {}, errorSummary);
//                 errorSummary.forEach(errorMessage => {
//                     for (let i = 0; i < this.profitCenterMaintenanceData.length; i++) {
//                         const updatedData = this.profitCenterMaintenanceData[i];
//                         if (updatedData.profitCenterID.toString() === errorMessage.id) {
//                             const backupRowIndex = this.profitCenterMaintenanceData.findIndex(a => a.profitCenterID.toString() == errorMessage.id);
//                             this.mapEditableColumnsProfitCentersMainGrid(updatedData, this.profitCenterMaintenanceData[backupRowIndex]);
//                         }
//                     }
//                 });
//             }
//             else {
//                 this.uiService.toastService.success(ProfitCenterMaintenanceMessages.SAVE);
//             }
//             runInAction(() => {
//                 this.showLoader = false;
//                 this.isRefreshed = false;
//                 this.isActivateButtonDisabled= true;
//                 dataStore.setShowUnsavedWarningAlert(false);
//             })  
//         }
//     }

//     @Loader
//     @action
//     async openProfitCenterDetails(data) {
//         this.agGridService.destroyGrid();
       
//             const selectedlocnName = data.locnName;
//             const response: ProfitCenterMaintenanceResponse[] = await this.profitCenterMaintenanceDataService.getProfitCentersDataWithFilter(selectedlocnName, 0, 'All' );
//             if(response) {
//                 runInAction(() => {
//                     this.showLoader = false;
//                     this.addOrEditProfitCenterGridData = [];
//                     this.backupAddOrEditProfitCenterGridData = [];
//                     this.cellValueChangeMap = {};
//                     const profitCenterData = response.filter((item) => item.locnName === data.locnName);
//                     this.selectedProfitCenterDetails = profitCenterData.length > 0 ? profitCenterData[0] : {};
//                     this.addOrEditedLocnName = profitCenterData[0].locnName;
//                     this.selectedProfitCenterId = profitCenterData[0].profitCenterID;
//                     this.baseSystemData.map((item) =>
//                      {
//                         const linkedPipeline = profitCenterData.filter((profitCenter) => profitCenter.pipelineID === item.pipelineID)
//                         const serviceStatus = linkedPipeline.length > 0  && linkedPipeline[0].serviceStatus ? linkedPipeline[0].serviceStatus !== 'A' ? 'Inactive' : 'Active' :'Active'
//                         item.serviceStatus =  serviceStatus
//                         item.serviceDate = linkedPipeline.length > 0 ? linkedPipeline[0].serviceDate : null,
//                         item.allowTrades = linkedPipeline.length > 0 ? linkedPipeline[0].allowTrades : 'N',
//                         item.profitCenterRelPLID = linkedPipeline.length > 0 ? linkedPipeline[0].profitCenterRelPLID : 0,
//                         item.isSelected = linkedPipeline.length > 0 ? true : false,
//                         item.syncStatus = linkedPipeline.length > 0 ? linkedPipeline[0].syncStatus : SyncStatus[0]
//                         this.addOrEditProfitCenterGridData.push(item);
//                     });
//                     this.backupAddOrEditProfitCenterGridData = this.addOrEditProfitCenterGridData;
//                     this.isRefreshed = false;
//                     this.onClickProfitCenterNameOnGrid = true;
//                     dataStore.setShowUnsavedWarningAlert(false);
//                     history.push('/ProfitCenterDetails');
//                 })
//             } else {
//                 this.getSelectedProfitCenterData();
//             }
           
//         // }
        
// ;    }

    @action
    onCloseProfitCenterDetails() {
        console.log(dataStore.showUnsavedWarningAlert);
        history.push('/ProfitCenterMaintenance', { from: '/ProfitCenterDetails' });   
    }


    @Loader
    @Catch(() => errorHandler(ProfitCenterMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async getSelectedProfitCenterData(): Promise<void> {
            runInAction(() => {
                this.addOrEditedLocnName = '';
                this.addOrEditProfitCenterGridData = [];
                this.backupAddOrEditProfitCenterGridData = [];
                this.cellValueChangeMap = {};
                this.pipelineSystemData.map((item) =>
                 {
                    item.profitCenterRelPLID = 0,
                    item.serviceStatus =  'Active',
                    item.serviceDate =  null,
                    item.allowTrades = 'N',
                    item.isSelected = false,
                    item.syncStatus = SyncStatus[0]
                    this.addOrEditProfitCenterGridData.push(item)
                });
                this.backupAddOrEditProfitCenterGridData = this.addOrEditProfitCenterGridData;
                this.isRefreshed = false;
            })
            this.agGridService.destroyGrid();
        this.agGridServiceForAddOrEditProfitCenter.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
   async onEnterProfitCenterforAddOREdit(value) {
        if(this.addOrEditedLocnName !== value) {
            this.updatedFieldonProfitCenterDetailsScreen = value
        }
        this.addOrEditedLocnName = value   
   }

   @computed
    get AddOrEditProfitCenterGridData() {
        return toJS(this.addOrEditProfitCenterGridData);
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditProfitCenterGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditProfitCenterGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsProfitCenterDetailsGrid(this.addOrEditProfitCenterGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsProfitCenterDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.serviceStatus = updatedItem.serviceStatus;
        currentItem.allowTrades = updatedItem.allowTrades;
        currentItem.isSelected = updatedItem.isSelected
        currentItem.serviceDate = updatedItem.serviceDate;
    }

    handleAllowTradeCheckBoxChange(params, value) {
        const row = params.data.pipelineID;
        const initValue = params.data['allowTrades'];
        this.setValueInChangeMap(row, 'allowTrades', initValue, value);
        if(initValue === 'Y') {
            params.data['allowTrades'] = 'N'
        } else {
            params.data['allowTrades'] = 'Y'
        }
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data)
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected
        this.setValueInChangeMap(row, 'isSelected', initValue, checked);
        params.data.isSelected = checked;
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data);
    };

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }

    @action
    openProfitCenterDetails(data) {
        history.push('/ProfitCenterDetails', {from: '/ProfitCenterMaintenance', data : data, profitCenterId: data.profitCenterID})
    }
}
