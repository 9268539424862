import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from 'common/services/ag-grid-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from 'features/account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import moment from 'moment';
import _ from 'lodash';
import { TariffDashboardDataService } from '../services/tariff-dashboard-data-service';
import { StatusData, TariffDashboardMessages } from '../constants/enum';
import { AppConstant } from 'app_constants';
import { TariffLogEntry } from 'features/tariff-management/tariff-log/constant/enums';
import { logEntryDataStore } from 'features/tariff-management/tariff-log/stores';
import { projectRequestDataStore } from 'features/tariff-management/project-request/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { processOptionStep2DataStore } from 'features/tariff-management/process-tariff-options/stores';


export class TariffDashboardDataStore {
    @observable createdUser;
    @observable tariffDashboardData: any[] = [];
    @observable showLoader = false;
    @observable inProgressLogData: any[] = [];
    @observable selectedCTSEffectiveDate;
    @observable selectedTariffEffectiveDate;
    @observable selectedAgency = 'All';
    @observable selectedPipelineSystem = 'All';
    @observable selectedTariffNumber = '';
    @observable selectedOwnerOrCarrier = 'All';
    @observable selectedTariffClass = 'All';
    @observable selectedStatus = StatusData[0].status;
    @observable selectedTariffStatus = 'All';
    @observable selectedBaseTariff = 'All';
    @observable agencyDropdownOptions: any[] = [];
    @observable pipelineSystemsDropdownOptions: any[] = [];
    @observable statusDropdownOptions: any[] = StatusData;
    @observable tariffStatusDropdownOptions: any[] = [];
    @observable tariffClassDropdownOptions: any[] = [];
    @observable ownerCarrierDropdownOptions: any[] = [];
    @observable baseTariffDropdownOptions: any[] = [];
    @observable endDateError = '';
    @observable defaultUserLocation = '';
    agencySelectOptionData: any[] = [];
    pipelineSelectOptionData: any[] = [];
    statusSelectOptionData: any[] = [];
    tariffStatusSelectOptionData: any[] = [];
    tariffClasSelectOptionData: any[] = [];
    ownerCarrierSelectOptionData: any[] = [];
    baseTariffSelectOptionData: any[] = [];
    @observable selectedLogID = '';
    @observable enteredByUser = '';

    constructor(
        public tariffDashboardDataService: TariffDashboardDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForOpenRequest: AgGridService
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.createdUser = this.accountStore.displayName;
        this.defaultUserLocation = this.accountStore.defaultUserLocation    
        this.selectedCTSEffectiveDate = undefined;
        this.selectedTariffEffectiveDate = undefined;
        this.selectedAgency = 'All';
        this.selectedPipelineSystem = 'All';
        this.selectedTariffNumber = '';
        this.selectedOwnerOrCarrier = 'All';
        this.selectedTariffClass = 'All';
        this.selectedStatus = StatusData[0].status;
        this.selectedTariffStatus = 'All';
        this.selectedBaseTariff = 'All';
        this.selectedLogID = '';
        this.enteredByUser = '';
        const a = StatusData.map(x => {return { 'value': x.status, 'label': x.status}});
        this.statusSelectOptionData = a;
        this.getInprogressTariffLogs();
        this.getAgencyDropdown();
        this.getPipelineSystems();
        this.getOwnerCarrierData();
        // this.getStatusData();
        this.getTariffClassData();
        this.getTariffStatusData();
        this.getBaseTariffData();
        const state=history?.location?.state as {from?: string; tariffLogId?: string; baseTariff?: string; tariffNumber?: string; tariffClass?: string; workflowId?: string; process?:boolean; } || '';
        if(state.tariffNumber) {
            this.selectedTariffNumber = state.tariffNumber;
            this.onSearchClick();
        } else {
            this.loadViewModel();
        } 
        history.push('/TariffDashboard');       
    }


    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const response = await this.tariffDashboardDataService.getTariffDashboardData(0, 0, 0, '', '', '', 0, this.selectedStatus, '', this.defaultUserLocation, 0, 0, '', '');
        if(response) {
            this.tariffDashboardData = response;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const agencyId = this.agencyDropdownOptions.length > 0 && this.selectedAgency !=='All' ? this.agencyDropdownOptions.filter((item) => item.agencycode === this.selectedAgency)[0].agencyid : 0;
        const pipelineId = this.pipelineSystemsDropdownOptions.length > 0 && this.selectedPipelineSystem !=='All'? this.pipelineSystemsDropdownOptions.filter((item) => item.plName === this.selectedPipelineSystem)[0].pipelineID : 0;
        const ownerCarrier = this.selectedOwnerOrCarrier !=='All' && this.ownerCarrierDropdownOptions.length > 0 ? this.ownerCarrierDropdownOptions.filter((item) => item.customerName  === this.selectedOwnerOrCarrier)[0].customerID: 0;
        const tariffClassId  = this.tariffClassDropdownOptions.length > 0  && this.selectedTariffClass!=='All' ? this.tariffClassDropdownOptions.filter((item) => item.valueText === this.selectedTariffClass)[0].settingID : 0;
        const status = this.selectedStatus !== 'All' ?  this.selectedStatus : '';
        const baseTariffId =  this.baseTariffDropdownOptions.length > 0 && this.selectedBaseTariff !=='All'? this.baseTariffDropdownOptions.filter((item) => item.baseTariff === this.selectedBaseTariff)[0].tariffBaseID : 0;
        const jointCarrierId =  this.baseTariffDropdownOptions.length > 0 && this.selectedBaseTariff !=='All'? this.baseTariffDropdownOptions.filter((item) => item.baseTariff === this.selectedBaseTariff)[0].jtid : 0;
        const tariffStatus = this.selectedTariffStatus !== 'All' ? this.selectedTariffStatus : '';
        const ctsEffectiveDate = this.selectedCTSEffectiveDate && this.selectedCTSEffectiveDate !== '' ? moment(this.selectedCTSEffectiveDate).format(AppConstant.DATE_FORMAT) : '';
        const tariffEffectiveDate = this.selectedTariffEffectiveDate && this.selectedTariffEffectiveDate !== '' ? moment(this.selectedTariffEffectiveDate).format(AppConstant.DATE_FORMAT) : '';
        const response = await this.tariffDashboardDataService.getTariffDashboardData(agencyId , ownerCarrier, pipelineId, tariffEffectiveDate, ctsEffectiveDate, this.selectedTariffNumber, tariffClassId, status, tariffStatus, this.defaultUserLocation, baseTariffId, jointCarrierId, this.selectedLogID, this.enteredByUser);
        if(response) {
            this.tariffDashboardData = response;
            this.endDateError= '';
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async clearSearch(): Promise<void> {
        const response = await this.tariffDashboardDataService.getTariffDashboardData(0, 0, 0, '', '', '', 0, '', '', this.defaultUserLocation, 0, 0, '', '');
        if(response) {
            this.tariffDashboardData = response;
            this.selectedCTSEffectiveDate = '';
            this.selectedAgency = 'All';
            this.selectedPipelineSystem = 'All';
            this.selectedTariffNumber = '';
            this.selectedOwnerOrCarrier = 'All';
            this.selectedTariffClass = 'All';
            this.selectedTariffEffectiveDate = '';
            this.selectedStatus = 'All';
            this.selectedTariffStatus= 'All';
            this.selectedBaseTariff = 'All';
            this.selectedLogID = '';
            this.enteredByUser = '';
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async getInprogressTariffLogs(): Promise<void> {
        const response = await this.tariffDashboardDataService.getInprogressTariffLogs(this.defaultUserLocation);
        if(response) {
            this.inProgressLogData = response;
        } 
    }


    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async getAgencyDropdown(): Promise<void> {
        const defaultUserLocation = this.accountStore.defaultUserLocation === 'US' ? 'USA': this.accountStore.defaultUserLocation;
        const response = await this.tariffDashboardDataService.getAgencyDropdown(defaultUserLocation);
        if(response) {
            this.agencyDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.agencycode, 'label': x.agencycode}});
            this.agencySelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async getPipelineSystems(): Promise<void> {
        const response = await this.tariffDashboardDataService.getPipelineSystem();
        if(response) {
            this.pipelineSystemsDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.plName, 'label': x.plName}});
            this.pipelineSelectOptionData = a;
        } 
    }

    // @Loader
    // @action
    // @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    // async getStatusData(): Promise<void> {
    //     const response = await this.tariffDashboardDataService.getStatusData();
    //     if(response) {
    //         this.statusDropdownOptions = response;
    //     } 
    // }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async getTariffStatusData(): Promise<void> {
        const response = await this.tariffDashboardDataService.getTariffStatusData();
        if(response) {
            this.tariffStatusDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
            this.tariffStatusSelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async getTariffClassData(): Promise<void> {
        const response = await this.tariffDashboardDataService.getTariffClassData();
        if(response) {
            this.showLoader = false;
            this.tariffClassDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
            this.tariffClasSelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async getOwnerCarrierData(): Promise<void> {
        const response = await this.tariffDashboardDataService.getOwnerCarrierData();
        if(response) {
            this.showLoader = false;
            this.ownerCarrierDropdownOptions = response;
            const data = response.map((x) => x.customerName).filter((x , index, arr) => arr.indexOf(x) === index);;

            const a = data.map(x => {return { 'value': x, 'label': x}});
            this.ownerCarrierSelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(TariffDashboardMessages.FETCH_ERROR_MESSAGE))
    async getBaseTariffData(): Promise<void> {
        const response = await this.tariffDashboardDataService.getBaseTariffData();
        if(response) {
            this.baseTariffDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.baseTariff, 'label': x.baseTariff}});
            this.baseTariffSelectOptionData = a;
        } 
    }


    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, 'Tariff Dashboard');
    };

    isViewOptionButtonDisbaled = (buttonAction) => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, buttonAction)
    }

    @action
    reset(): void {
        this.tariffDashboardData = [];
    }

    @action
    async onChangeTariffEffectiveDate(value) {
        this.selectedTariffEffectiveDate = value;
    }

    @action
    async onChangeCTSEffectiveDate(value) { 
        this.selectedCTSEffectiveDate = value;
    }

    @action
    async onChangeTariffNumber(value) {
         this.selectedTariffNumber = value;
    }

    @computed
    get TariffDashboardData() {
        return toJS(this.tariffDashboardData);
    }

    async onClickLogId(params) {
        // if(params.data.process) {
        //     history.push('/TariffLog', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'] });
        // } else {
            if(params.data.tariffClass === TariffLogEntry.TariffFiling) {
                history.push('/CreateFilingLogEntry', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'], process : params.data['process'] });
            } else if(params.data.tariffClass === TariffLogEntry.TariffFilingIndex) {
                history.push('/CreateIndexLogEntry', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'], process : params.data['process'] });
            } else if(params.data.tariffClass === TariffLogEntry.Request) {
                history.push('/CreateRequestLogEntry', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'], process : params.data['process'] });
            } else if(params.data.tariffClass === TariffLogEntry.Maintenance) {
                history.push('/CreateMaintenanceLogEntry', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'] , process : params.data['process'] });
            }
        // }
        
    }

    async onClickViewOptions(params, action) { 
        if(action === 'Process' && (!params.data['wfStep'] && params.data['wF1DUrl'] && params.data['wF1DID'] && params.data['wF1DStep']) && (params.data['tariffClass'] === TariffLogEntry.TariffFiling || params.data['tariffClass'] === TariffLogEntry.Maintenance)) {
            projectRequestDataStore.workflowId = params.data['wF1DID'];
            projectRequestDataStore.workflowCreatorJurisdiction = this.defaultUserLocation;
            projectRequestDataStore.isFromWorkflowDashboard = true;
            history.push(`/${params.data['wF1DUrl']}`, {workflowId: params.data['wF1DID'], saved: true, selectedProjectRequestType: params.data['wF1DName']});
        } else if(action === 'Process' && (params.data['wfStep'] === 'Complete Test Determination' || params.data['wfStep'] === 'Link Project') && (params.data['tariffClass'] === TariffLogEntry.TariffFiling || params.data['tariffClass'] === TariffLogEntry.Maintenance)) {
            const url  = params.data['tariffClass'] === TariffLogEntry.Maintenance ? '/CreateMaintenanceLogEntry' : '/CreateFilingLogEntry'
            history.push(url, { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'], process : params.data['process'] });
        } else if((!params.data.process && action === 'Process') && params.data['tariffClass'] === TariffLogEntry.TariffFilingIndex ) {
            if(params.data.hasAttachments && params.data.hasAttachments === true) {
                this.tariffDashboardProcessLog(params, action);
            } else {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                    () => {console.log('OK')},
                    ConfirmWithOK,
                    {secondaryText: 'Reference documents is not attached to the log. Please attached the reference documents before processing the log. '} as ConfirmWithOKProps,
                    'confirmPopup'
                );
            } 
        } else {
            if(action === 'View' && params.data['tariffClass'] === TariffLogEntry.TariffFilingIndex)
            history.push('/TariffOptions-Index', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'] , process : params.data['process'], action: action,  workflowStep: params.data['wfStep'] });

            if(action === 'View' && (params.data['tariffClass'] === TariffLogEntry.TariffFiling || params.data['tariffClass'] === TariffLogEntry.Maintenance))
            history.push('/TariffOptions', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffName: params.data['tariffNumber'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'] , process : params.data['process'], action: action,  workflowStep: params.data['wfStep'], disabled: true });

            if(action === 'Process' && (params.data['tariffClass'] === TariffLogEntry.TariffFiling || params.data['tariffClass'] === TariffLogEntry.Maintenance)) {
                processOptionStep2DataStore.workflowId = params.data['workflowID'];
                const url = params.data['wfStep'] && params.data['wfStep'] === 'Complete Analyst Review /Submit for Approval' ? '/process-tariff-review' : params.data['wfStep']  && params.data['wfStep'] !== 'Process & Submit for Approval' ? '/process-tariff-approval' :  '/process-tariff-infrastructure';
                const pipeline = params.data['pipeline'] && this.pipelineSystemsDropdownOptions.length > 0 ? this.pipelineSystemsDropdownOptions.filter((item) => params.data['pipeline'].split(',').includes(item.plName)).map((x) => x.pipelineID) : [];
                history.push(url,{tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'], process : params.data['process'], action: action, workflowStep: params.data['wfStep'], saved: true, selectedProjectRequestType: '', pipeline: pipeline.join(',')});
            }

            if(action === 'Process' && params.data['tariffClass'] === TariffLogEntry.TariffFilingIndex)
            history.push('/TariffOptions-Index', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'] , process : params.data['process'], action: action,  workflowStep: params.data['wfStep'] });
        }
       
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in processing log'))
    async tariffDashboardProcessLog(params, action): Promise<void> {
        const response = await this.tariffDashboardDataService.tariffDashboardProcessLog(params.data['tariffLogId'], this.createdUser);
        if(response) {
            const process = response === true ? response : params.data['process'];
            if(params.data['tariffClass'] === TariffLogEntry.TariffFilingIndex) {
                history.push('/TariffOptions-Index', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'], process : process, action: action, workflowStep: params.data['wfStep'] });
            } else {
                processOptionStep2DataStore.workflowId = params.data['workflowID'];
                const url = params.data['wfStep'] && params.data['wfStep'] === 'Complete Analyst Review /Submit for Approval' ? '/process-tariff-review' : params.data['wfStep']  && params.data['wfStep'] !== 'Process & Submit for Approval' ? '/process-tariff-approval' :  '/process-tariff-infrastructure';
                const pipeline = params.data['pipeline'] && this.pipelineSystemsDropdownOptions.length > 0 ? this.pipelineSystemsDropdownOptions.filter((item) => item.plName === params.data['pipeline'])[0].pipelineID : [];
                history.push(url, {tariffLogId: params.data['tariffLogId'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'], process : process, action: action, workflowStep: params.data['wfStep'], saved: true, selectedProjectRequestType: '', pipeline: pipeline.join(',')});
                // history.push('/TariffOptions', { from: '/TariffDashboard', tariffLogId: params.data['tariffLogId'], tariffName: params.data['tariffNumber'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['tariffClass'], workflowId : params.data['workflowID'] , process : params.data['process'], action: action,  workflowStep: params.data['wfStep'] });
            }
            
        } 
    }


    @action
    isWorkflowStepEnabled= (wfStep, wF1DStep) => {
        if((wfStep && wfStep === 'Complete Tariff Approval') || (wF1DStep && wF1DStep === 'Complete Tariff Approval')) 
        return this.isViewOptionButtonDisbaled(MasterDataComponentModulesName.TariffDashboardApproveButton) &&  !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, wfStep)
        else 
        return this.isViewOptionButtonDisbaled(MasterDataComponentModulesName.TariffDashboardProcessButton) &&  !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, wfStep)
    }

}