import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { gradeColDef } from '../components/grade-col-def';
import moment from 'moment';
import Path from '../../../../Path';
// import Path, { GradeSettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { GradeMaintenanceDataService } from '../services/grade-maintenance-data-service';
import { GradeMaintenanceResponse } from '../model/model';
import { GradeMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { addEditGradeMaintenanceDataStore, gradeMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
// import { AddGradeDetailsColDef } from '../components/add-grade-details-container';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class AddEditGradeMaintenanceStore { 
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userName = '';
    @observable selectedGrade;
    @observable selectedGradeName;
    @observable addOrEditLinkerGridData: any = [];
    @observable backupAddOrEditLinkerGridData: any = [];
    @observable editableGradeData: any = {};
    @observable backupEditableGradeData: any = {};
    @observable isEditingGrade = false;
    @observable selectedGradeId;
    @observable selectedWorkflowId = 0;
    @observable baseSystemDropdownOptions: any[] = [];
    baseSystemData: any[] = [];
    
    @observable baseSystemDropdownOption: any[] = [];
    @observable systemGradeDropdownOptions: any[] = [];
    systemGradeData: any[] = [];
    navigateToScreen: any = null;
    
    constructor(
        public gradeMaintenanceDataService: GradeMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditLinker: AgGridService,
    ) {
        makeAutoObservable(this);
    }

    async init(): Promise<void> {
        this.baseSystemDropdownOptions = [];
        this.userName = this.accountStore.displayName;
        await this.getSystemGrades();
        await this.getBaseSystemDropdown();
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSystemGrades(): Promise<void> {
        const systemGradesDropdownOptions = await this.gradeMaintenanceDataService.getSystemGrades();
        this.systemGradeData = systemGradesDropdownOptions;
        const a = systemGradesDropdownOptions.map(x => { return { 'value': x.grade, 'label': x.gradeName } });
        const sortedSystemGrade =  _.orderBy(a, [x=>x.label], ['asc']);
        runInAction(() => {
            this.systemGradeDropdownOptions = sortedSystemGrade;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.gradeMaintenanceDataService.getBaseSystems();
        this.baseSystemData = baseSystemDropdownOptions;
        const a = (baseSystemDropdownOptions.map(x => x.plName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.baseSystemDropdownOption = option;
            if(!this.isEditingGrade)
            this.resetAddGradeMaintenanceGridData();
        });
    }

    @action
    async resetAddGradeMaintenanceGridData(): Promise<void> {
        this.addOrEditLinkerGridData = [];
        this.backupAddOrEditLinkerGridData = [];
        const addOrEditLinkerGridData: any = [];

        this.baseSystemData.map((item) => {
            item.gradeStatus = 'A';
            item.effectiveDate = null;
            item.systemGrade = null;
            item.isSelected = false;
            item.createUser = this.userName;
            item.createDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss');
            addOrEditLinkerGridData.push(item);
        });
        this.setAddOrEditLinkerGridData(addOrEditLinkerGridData);
        this.setBackUpAddOrEditLinkerGridData(addOrEditLinkerGridData);
    }

    @action
    headerCheckBoxSelection = (event) => {
        const checked = event.target.checked;
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.plName && filters.plName.values.includes(item.plName)) || (filters.gradeStatus && filters.gradeStatus.values.includes(item.gradeStatus)) || (filters.effectiveDate && filters.effectiveDate.values.include(item.effectiveDate)) || (filters.gradeStatus && filters.gradeStatus.values.includes(item.gradeStatus)))) : this.addOrEditLinkerGridData;
        // const data = filteredData.map((item) => { item.isSelected = checked; return (item)})
       
            if(checked) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () =>  {  filteredData.forEach((item) => {this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                    })},
                    'Multiple Pipeline',
                    'Do you want to link grade to multiple pipelines?'
                );
                
            } else {
                filteredData.forEach((item) => {
                    this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                });
            }
    };

    @action
    checkIfHeaderCheckboxShouldbeChecked = (params) => {
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.plName && filters.plName.values.includes(item.plName)) || (filters.gradeStatus && filters.gradeStatus.values.includes(item.gradeStatus)) || (filters.effectiveDate && filters.effectiveDate.values.include(item.effectiveDate)) || (filters.gradeStatus && filters.gradeStatus.values.includes(item.gradeStatus)))) : this.addOrEditLinkerGridData;
        return filteredData.some((item) => item.isSelected === false || !item.isSelected)
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected;
        if(this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length > 0 && checked) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () =>  { this.setValueInChangeMap(row, 'isSelected', initValue, checked);
                params.data.isSelected = checked;
                params.data['gradeStatus'] = (params.data.gradeStatus === 'Active' ||  params.data.gradeStatus === 'A') ? 'Active' : 'Inactive'
                this.updateRowforAddOrUpdateGrid(params.data);
                },
                'Multiple Pipeline',
                'Do you want to link grade to multiple pipelines?'
            );
        } else {
            this.setValueInChangeMap(row, 'isSelected', initValue, checked);
            params.data.isSelected = checked;
            params.data['gradeStatus'] = (params.data.gradeStatus === 'Active' ||  params.data.gradeStatus === 'A') ? 'Active' : 'Inactive'
            this.updateRowforAddOrUpdateGrid(params.data);
        }
    };

    @action
    globalEffectiveDateSelection = (effectiveDate) => {
            const data = this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).map((item) => { item.effectiveDate = effectiveDate; return (item)})
            data.map((item) => {
                this.updateRowforAddOrUpdateGrid(item);
            }) 
    };

    @action
   setAddOrEditLinkerGridData(addOrEditLinkerGridData: any): void {
       this.addOrEditLinkerGridData = addOrEditLinkerGridData
   }

   setBackUpAddOrEditLinkerGridData(addOrEditLinkerGridData: any): void {
       this.backupAddOrEditLinkerGridData = addOrEditLinkerGridData
   }

    @computed
    get AddOrEditLinkerGridData() {
        return toJS(this.addOrEditLinkerGridData);
    }

    getColDef() {
        return gradeColDef;
    }

    @action
    async onChangeGrade(value) {
        if (value.length <= 3) {
            this.selectedGrade = value;
        } else {
            this.selectedGrade = value.substring(0,3)
            this.uiService.toastService.error('Grade field length should not be more than 3 characters.')
        }
    }

   @action
   async onChangeGradeName(value) {
        this.selectedGradeName = value;
   }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditLinkerGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditLinkerGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsGradeDetailsGrid(this.addOrEditLinkerGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsGradeDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.gradeStatus = updatedItem.gradeStatus;
        currentItem.systemGrade = updatedItem.systemGrade;
        currentItem.isSelected = updatedItem.isSelected
        currentItem.effectiveDate = updatedItem.effectiveDate;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async saveGrade(): Promise<void> {
        const updatedRowIDs = this.addOrEditLinkerGridData.filter(a => a.isSelected === true);
        const tariffGradeRelatedPipelines: any = [];
        let requestBody: any = {};
        const localValidatingErrorBody: any = [];
        if (this.selectedGrade && this.selectedGrade !== '' && this.selectedGrade.length <= 3 && this.selectedGradeName && this.selectedGradeName !== '') {
            const filteredGradeData = this.systemGradeData.filter((x) => x.grade.toLowerCase().trim() === this.selectedGrade.toLowerCase().trim());
            const existingGrade = filteredGradeData.some((x) => x.gradeName.toLowerCase().trim() !== this.selectedGradeName.toLowerCase().trim());
            const isUniqueGrade = this.isEditingGrade ? !existingGrade : filteredGradeData.length <= 0;
            if (isUniqueGrade) {
                updatedRowIDs.forEach((item) => {
                    if (!item.plName || item.plName == '') {
                        const message = 'Please select valid Base System as mandatory fields.';
                        if (!localValidatingErrorBody.includes(message)) {
                            localValidatingErrorBody.push(message);
                        }
                    }
                    const effectiveDate = item.effectiveDate ? moment(new Date(item.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss') : null;
                    item.gradeRelPipelineID = item.gradeRelPipelineID > 0 ? item.gradeRelPipelineID : 0,
                    item.pipelineID = item.pipelineID && item.pipelineID > 0 ? item.pipelineID : 0,
                    item.plSysID = item.plSysID && item.plSysID > 0 ? item.plSysID : 0,
                    item.grade = this.selectedGrade,
                    item.systemGrade = this.systemGradeData.filter((x) => x.grade == item.systemGrade)[0]?.grade,
                    item.createDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.createUser = item.createUser || this.userName,
                    item.updateDate = item.gradeRelPipelineID  ? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss') : null,
                    item.updateUser = item.gradeRelPipelineID  ? this.userName : null,
                    item.ctsGradeRelPLID = item.ctsGradeRelPLID ? item.ctsGradeRelPLID : null,
                    item.action = this.isEditingGrade ? this.editableGradeData.action : 'A',
                    item.effectiveDate = effectiveDate,
                    item.gradeStatus = item.gradeStatus === 'Active' || item.gradeStatus === 'A' ? 'A' : 'I';
                    if (item.effectiveDate) {
                        tariffGradeRelatedPipelines.push(item);
                        const initialData = addEditGradeMaintenanceDataStore.addOrEditLinkerGridData.filter(a => (!addEditGradeMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString())));
                    } else {
                        const message = 'Please select valid effective date for the linked grade';
                        if (!localValidatingErrorBody.includes(message)) {
                            localValidatingErrorBody.push(message);
                        }
                    }
                });
            } else {
                const message = 'Tried to create duplicate grade. Please create the unique record.';
                if (!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        } else {
            if (!this.selectedGrade && this.selectedGrade == '') {
                const message = 'Please insert valid Grade as mandatory fields.';
                if (!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            } else if (this.selectedGrade.length > 3) {
                const msg = 'Grade field length should not be more than 3 characters.';
                if (!localValidatingErrorBody.includes(msg)) {
                    localValidatingErrorBody.push(msg);
                }
            }
            else {
                const message = 'Please insert valid Grade Name as mandatory fields.';
                if (!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        }
        if (localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if (updatedRowIDs.length === tariffGradeRelatedPipelines.length) {
            const tariffGradeRelatedPipelinesUpdated = tariffGradeRelatedPipelines.map(({isSelected, plSysCode, ...keepAttrs}) => keepAttrs)
            requestBody = {
                'tariffGradeID': this.isEditingGrade ? this.editableGradeData.tariffGradeID : 0,
                'grade': this.selectedGrade,
                'gradeName': this.selectedGradeName,
                'createDate': this.isEditingGrade ? this.editableGradeData.createDate : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                'createUser': this.isEditingGrade ? this.editableGradeData.createUser : this.userName,
                'updateDate': this.isEditingGrade ? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'): null,
                'updateUser': this.isEditingGrade ? this.userName : null,
                'syncStatus': this.isEditingGrade ? this.editableGradeData.syncStatus : SyncStatus[0],
                'testSyncDate': this.isEditingGrade ? this.editableGradeData.testSyncDate : null,
                'prodSyncDate': this.isEditingGrade ? this.editableGradeData.prodSyncDate : null,
                'action': this.isEditingGrade ? this.editableGradeData.action : 'A',
                'ctsGradeID': this.isEditingGrade ? this.editableGradeData.ctsGradeID : null,
                'workflowId': 0,
                'projectName': null,
                'tariffGradeRelatedPipelines': tariffGradeRelatedPipelinesUpdated
            }
            const response: any = await this.gradeMaintenanceDataService.saveTariffGrade(requestBody);
            if (response && response.tariffGradeID) {
                this.uiService.toastService.success(GradeMaintenanceMessages.SAVE);
                if (this.editableGradeData && !_.isEqual(this.editableGradeData, this.backupEditableGradeData)) {
                    response.syncStatus = SyncStatus[0];
                }
                const a: any = [];
                // this.addOrEditLinkerGridData.forEach((x) => {
                //     x.systemGrade = this.systemGradeData.filter(p => p.grade == x.systemGrade)[0]?.gradeName;
                //     a.push(x);
                // })
                this.addOrEditLinkerGridData.forEach((x) => {
                    const linkedGrade = response.tariffGradeRelatedPipelines.filter((item) => item.pipelineID === x.pipelineID);
                    const gradeStatus = linkedGrade.length > 0  && linkedGrade[0].gradeStatus ? linkedGrade[0].gradeStatus !== 'A' ? 'Inactive' : 'Active' :'Active'
                    x.gradeStatus =  gradeStatus ? gradeStatus : x.gradeStatus,
                    x.effectiveDate = linkedGrade.length > 0 ? linkedGrade[0].effectiveDate :  x.effectiveDate,
                    x.systemGrade = linkedGrade.length > 0 ? linkedGrade[0].systemGrade : x.systemGrade,
                    x.isSelected = linkedGrade.length > 0 ? true : x.isSelected,
                    x.syncStatus = linkedGrade.length > 0 ? linkedGrade[0].syncStatus || SyncStatus[0] : x.syncStatus,
                    x.gradeRelPipelineID = linkedGrade.length > 0 ? linkedGrade[0].gradeRelPipelineID : x.gradeRelPipelineID,
                    x.updateUser = linkedGrade.length > 0 ? linkedGrade[0].updateUser : this.userName,
                    x.createUser = linkedGrade.length > 0 ? linkedGrade[0].createUser : this.userName,
                    x.syncStatus = linkedGrade.length > 0 ? linkedGrade[0].syncStatus : x.syncStatus ? x.syncStatus : SyncStatus[0]
                    a.push(x);
                });
                this.setAddOrEditLinkerGridData(a);
                this.editableGradeData = response;
                this.backupEditableGradeData = response;
                this.selectedGradeId = response.tariffGradeID;
            } else {
                this.uiService.toastService.error(GradeMaintenanceMessages.FAILED_SUBMIT)
            }
        }
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.error) {
            errorHandler(GradeMaintenanceMessages.SYNC_FAILED_SUBMIT);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const gradeId = this.editableGradeData ?  this.editableGradeData.tariffGradeID.toString() : null;
        const requestBody: any =  {
            workflowid: this.selectedWorkflowId && this.selectedWorkflowId != 0 ? this.selectedWorkflowId : 0,
            env: env,
            id: gradeId
            }
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response : any = await this.gradeMaintenanceDataService.pushToVmacs(requestBody);
            if(response == true) {
                this.uiService.toastService.success(GradeMaintenanceMessages.SYNC);
                this.editableGradeData.syncStatus = env === 'Push TariffGrade to Vmacs Test' ? SyncStatus[1] : SyncStatus[2]
                this.addOrEditLinkerGridData.forEach((x) => {
                    if(x.isSelected === true) {
                        x.syncStatus = env === 'Push TariffGrade to Vmacs Test' ? SyncStatus[1] : SyncStatus[2]
                    }
                })
            } else {
                this.uiService.toastService.error(GradeMaintenanceMessages.SYNC_FAILED_SUBMIT);
            }
        }
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(GradeMaintenanceMessages.FAILED_SUBMIT))
    async deactivateGrade() {
        const gradeId = this.selectedGradeId;
        const user = this.userName;
        const response: any = await this.gradeMaintenanceDataService.deactivateGrade(gradeId, user);
        if (response && response.value) {
            this.uiService.toastService.error('Record cannot be deleted because it is linked to other base system(s). Unlink Grade from base system.');
       } else {
           this.uiService.toastService.success('Grade deactivated successfully.');
        }
    }
    

    @action
    onCloseGradeDetails() {
        // if(this.navigateToScreen) {
        //     history.push(this.navigateToScreen, {workflowId : this.selectedWorkflowId, tariffName: this.navigateState?.tariffLog, tariffNumber: this.navigateState?.tariffLog, baseTariff: this.navigateState?.baseTariff, tariffLogId : this.navigateState?.tariffLogId});
        //     dataStore.setShowUnsavedWarningAlert(false);
        // } else {
            history.push('/GradeMaintenance');  
        // }
    }

    @action
    resetGradeDetails() {
        this.isEditingGrade = false;
        this.selectedGradeId = '';
        this.selectedGrade = '';
        this.selectedGradeName = '';
        this.editableGradeData = {};
        this.backupEditableGradeData = {};
        this.addOrEditLinkerGridData = [];
        this.backupAddOrEditLinkerGridData = [];
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.GradeMaintenance);
    };

    isDeleteDisabled = ()  => {
          return !this.editableGradeData  ||  (this.editableGradeData && !this.editableGradeData.tariffGradeID) || this.isActivateDeactivateDisabled()
    }
    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };
}