import React from 'react';
// import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { locationMaintenanceDataStore } from '../../stores';


export const LocationDetailCellRenderer = (props: ICellRendererParams) => {    
    return (

        locationMaintenanceDataStore.isSaveDisabled() || props.data.action === 'D' ? <span>{props.data.locnName}</span> : 
            <span style={{color: 'blue'}}
            onClick={()=>locationMaintenanceDataStore.openLocationDetails(props.data)}>
                {props.data.locnName ==undefined?'0000000':props.data.locnName }</span>
    );
};