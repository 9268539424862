import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { pmcGridViewColDef } from '../components/pmc-grid-view-col-def';
import { PMCGridViewDataService } from '../services/pmc-grid-view-data-service';
import history from '../../../../shared/components/header-component/component/history';
import { PMCGridViewResponse } from '../model/model';
import { PMCGridViewMessages } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { pmcGridViewDataStore } from '.';
import { dataStore } from '../../../../common/stores';
import { RoleBasedActionName } from '../../../../shared/types/enum';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { pmcTariffDataStore } from 'features/tariff-management/pmc-tariff/stores';
import moment from 'moment';

export class PMCGridViewDataStore {
    @observable pmcGridViewData: PMCGridViewResponse[] = [];
    @observable showLoader = false;
    @observable createdUser;
    isRefreshed = false;
    @observable selectedAssetGroup;
    @observable assetGroupDropdownOptions: any[] = [];
    @observable selectedSystemGroup;
    @observable systemGroupDropdownOptions: any[] = [];
    @observable selectedDistributionType;
    @observable selectedColValue;
    @observable selectedUnitValue;
    @observable amountToUpdate;
    @observable selectedEffectiveStartDate;
    @observable selectedColValueForDuplicate;
    @observable selectedUnitValueForDuplicate;
    @observable amountToUpdateForDuplicate;
    @observable isShowModal = false;
    @observable isButtonDisabled = false;
    @observable isShowBulkDuplicateConfirmationModal = false;
    @observable distributionTypeDropdownOptions: any[] = [];
    @observable columnDropdownOptions: any[] = [];
    @observable selectedEffStartDate = new Date(moment(new Date).startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
    @observable selectedEffectiveEndDate= new Date(moment(new Date).endOf('month').format('YYYY-MM-DDTHH:mm:ss'));

    assetGroupData: any[]=[];
    systemGroupData: any[]=[];
    pipelineGroupData: any[]=[];
    distributionTypeData: any[]=[];
    columnData: any[]=[];
    unitData: any[]=[];
    currencyData: any[]=[];
    regulatorData: any[]=[];
    shrinkageData: any[]=[];
    lossAllowanceData: any[]=[];
    
    @observable systemGroupDropdownOption: any[] = [];
    @observable selectedPipelineGroup;
    @observable pipelineGroupDropdownOptions: any[] = [];
    
    constructor(
        public pmcGridViewDataService: PMCGridViewDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
    ) {
        makeAutoObservable(this);
    }

    unitValueToUpdate: any[] = [{label:'%', value:'%'}, {label:'$', value:'$'}]
    monthNames = [{ 'label': 'Jan', 'value': 'January' }, { 'label': 'Feb', 'value': 'February' }, { 'label': 'Mar', 'value': 'March' }, { 'label': 'Apr', 'value': 'April' }, { 'label': 'May', 'value': 'May' }, { 'label': 'Jun', 'value': 'June' }, { 'label': 'Jul', 'value': 'July' }, { 'label': 'Aug', 'value': 'August' }, { 'label': 'Sep', 'value': 'September' }, { 'label': 'Oct', 'value': 'October' }, { 'label': 'Nov', 'value': 'November' }, { 'label': 'Dec', 'value': 'December' }];

    async init(): Promise<void> {
        this.createdUser = this.accountStore.displayName;
        this.selectedAssetGroup = { value: 'All', label: 'All' };
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedPipelineGroup = { value: 'All', label: 'All' };
        this.selectedDistributionType = { value: 'All', label: 'All' };
        this.selectedColValue = '';
        this.amountToUpdate = '';
        this.selectedUnitValue = '';
        this.systemGroupDropdownOptions = [];
        this.pmcGridViewData = [];
        dataStore.setShowUnsavedWarningAlert(false);
        this.getAssetGroupDropdownData();
        this.getPipelineGroupDropdownData();
        this.getSystemGroupDropdownData();
        this.getDistributionTypeDropdownData();
        this.getColumnDropdownData();
        this.getUnitDropdownData();
        this.getCurrencyDropdownData();
        this.getRegulatorDropdownData();
        this.getShrinkageDropdownData();
        this.getLossAllowanceDropdownData();
        this.onSearchClick();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAssetGroupDropdownData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const assetGroups = await this.pmcGridViewDataService.getAssetGroups(userJurisdiction);
        this.assetGroupData = assetGroups;
        this.showLoader = false;
        const a = assetGroups.map(x => {return { 'value': x.assetGroupID, 'label': x.name}});
        const sortedAssetGroups =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setAssetGroupDropdownOptions(sortedAssetGroups);
    }

    @action
    setAssetGroupDropdownOptions(options){
        this.assetGroupDropdownOptions = options
    }

    @action
    setAssetGroup(assetGroup: any): void {
        this.selectedAssetGroup = assetGroup;
        this.getSystemGroupDropdownData();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline System'))
    async getSystemGroupDropdownData(): Promise<void> {
        let systemGroups: any[] = [];
        if(this.selectedAssetGroup.label === 'All' ){
            systemGroups = await this.pmcGridViewDataService.getSystemGroup();
        }else{
            const assetGroupID = this.assetGroupData.filter(x=>x.name===this.selectedAssetGroup.label)[0]?.assetGroupID;
            systemGroups = await this.pmcGridViewDataService.getPMCPipelineSystems(assetGroupID);
        }
        this.systemGroupData = systemGroups;
        this.showLoader = false;
        const a = (systemGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedSystemGroups =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setSystemGroupDropdownOptions(sortedSystemGroups);
    }

    @action
    setSystemGroupDropdownOptions(options){
        this.systemGroupDropdownOptions = options
    }

    getSelectedSystemGroupID = () =>{
        const systemGroupID = !this.selectedSystemGroup.label || this.selectedSystemGroup.label == '' || this.selectedSystemGroup.label == 'All' || this.selectedSystemGroup.label == ' ' ? 0 : this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
        return systemGroupID;
    }

    getSelectedPipelineID = () =>{
        const pipelineID = !this.selectedPipelineGroup.label || this.selectedPipelineGroup.label === '' || this.selectedPipelineGroup.label === 'All' || this.selectedPipelineGroup.label == ' ' ? 0 : this.pipelineGroupData.find(x=>x.valueText===this.selectedPipelineGroup.label)?.settingID;
        return pipelineID;
    }

    getSelectedDistributionID = () =>{
        const distributionID = !this.selectedDistributionType.label || this.selectedDistributionType.label === '' || this.selectedDistributionType.label === 'All' || this.selectedDistributionType.label == ' ' ? 0 : this.distributionTypeData.find(x=>x.valueText===this.selectedDistributionType.label)?.settingID;
        return distributionID;
    }

    getSelectedAssetGroupID = () =>{
        const assetGroupID = !this.selectedAssetGroup.label || this.selectedAssetGroup.label == '' || this.selectedAssetGroup.label == 'All' || this.selectedAssetGroup.label == ' ' ? 0 : this.assetGroupData.find(x=>x.name===this.selectedAssetGroup.label)?.assetGroupID;
        return assetGroupID;
    }

    @action
    setSystemGroup(systemGroup: any): void {
        this.selectedSystemGroup = systemGroup;
    }

    @action
    setEffectiveStartDate(effectiveStartDate: Date): void {
        this.selectedEffectiveStartDate = effectiveStartDate;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline Region'))
    async getPipelineGroupDropdownData(): Promise<void> {
        const pipelineGroups = await this.pmcGridViewDataService.getPipelineGroup();
        this.pipelineGroupData = pipelineGroups;
        this.showLoader = false;
        const a = (pipelineGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedPipelines =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setPipelineGroupDropdownOptions(sortedPipelines);
    }

    @action
    setPipelineGroupDropdownOptions(options){
        this.pipelineGroupDropdownOptions = options
    }

    @action
    setPipelineGroup(pipelineGroup: any): void {
        this.selectedPipelineGroup = pipelineGroup;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Distribution Types'))
    async getDistributionTypeDropdownData(): Promise<void> {
        const distributionTypes = await this.pmcGridViewDataService.getDistributionTypes();
        this.distributionTypeData = distributionTypes;
        this.showLoader = false;
        const a = (distributionTypes.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedDistributionType =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setDistributionTypeDropdownOptions(sortedDistributionType);
    }

    @action
    setDistributionTypeDropdownOptions(options){
        this.distributionTypeDropdownOptions = options
    }

    @action
    setDistributionType(distributionType: any): void {
        this.selectedDistributionType = distributionType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Column Values'))
    async getColumnDropdownData(): Promise<void> {
        const columns = await this.pmcGridViewDataService.getPMCFeeTypes();
        this.columnData = columns;
        this.showLoader = false;
        const a = (columns.map(x => {return { 'value': x.valueCode, 'label': x.systemName}}));
        const sortedColumns =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setColumnDropdownOptions(sortedColumns);
    }

    @action
    setColumnDropdownOptions(options){
        this.columnDropdownOptions = options
    }

    @action
    setColumn(column: any): void {
        this.selectedColValue = column;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching UOM values'))
    async getUnitDropdownData(): Promise<void> {
        const units = await this.pmcGridViewDataService.getUnits();
        this.unitData = units;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Currency values'))
    async getCurrencyDropdownData(): Promise<void> {
        const currencies = await this.pmcGridViewDataService.getCurrency();
        this.currencyData = currencies;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Regulator values'))
    async getRegulatorDropdownData(): Promise<void> {
        const regulatores = await this.pmcGridViewDataService.getRegulator();
        this.regulatorData = regulatores;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Shrinkage values'))
    async getShrinkageDropdownData(): Promise<void> {
        const shrinkageValues = await this.pmcGridViewDataService.getPMCShrinkage();
        this.shrinkageData = shrinkageValues;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Loss Allowance values'))
    async getLossAllowanceDropdownData(): Promise<void> {
        const lossAllowanceValues = await this.pmcGridViewDataService.getPMCLossAllwance();
        this.lossAllowanceData = lossAllowanceValues;
    }

    @Loader
    @Catch(() => errorHandler(PMCGridViewMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const assetGroupID = this.getSelectedAssetGroupID();
        const systemGroupId = this.getSelectedSystemGroupID();
        const pipelineId = this.getSelectedPipelineID();
        const distributionId = this.getSelectedDistributionID();
        const inputeffStartDate = this.selectedEffStartDate? moment(this.selectedEffStartDate).format('YYYY-MM-DD'):null;
        const inputeffEndDate =this.selectedEffectiveEndDate?moment(this.selectedEffectiveEndDate).format('YYYY-MM-DD'):null;
        const localValidatingErrorBody: any = [];
        let isValidSearch = true;
        if(inputeffStartDate && inputeffEndDate ? moment(inputeffEndDate).isBefore(inputeffStartDate):false){
            const message = 'End Date can not be before Start Date. Please select correct End Date.';
            localValidatingErrorBody.push(message);
            isValidSearch = false;
        }
        if (isValidSearch) {
        const response = await this.pmcGridViewDataService.getPMCTariffDetail(assetGroupID, systemGroupId, pipelineId, distributionId,inputeffStartDate,inputeffEndDate);
        const sortedArr = _.orderBy(response,[item=>item.tariffNumber],['asc']);
        runInAction(() => {
            this.showLoader = false;
            this.pmcGridViewData = sortedArr;
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }
    else {
        const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
        errorHandler(messages);
    }
    }

    @Loader
    @Catch(() => errorHandler(PMCGridViewMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        const assetGroupID = 0;
        const systemGroupId = 0;
        const pipelineId = 0;
        const distributionId = 0;
            this.selectedEffStartDate = new Date(moment(new Date).startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
            this.selectedEffectiveEndDate = new Date(moment(new Date).endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
            const inputeffStartDate = this.selectedEffStartDate? moment(this.selectedEffStartDate).format('YYYY-MM-DD'):null;
            const inputeffEndDate =this.selectedEffectiveEndDate?moment(this.selectedEffectiveEndDate).format('YYYY-MM-DD'):null;
        const response = await this.pmcGridViewDataService.getPMCTariffDetail(assetGroupID, systemGroupId, pipelineId, distributionId, inputeffStartDate,inputeffEndDate);
        runInAction(() => {
            this.showLoader = false;
            this.selectedAssetGroup = { value: 'All', label: 'All' };
            this.selectedSystemGroup = { value: 'All', label: 'All' };
            this.selectedPipelineGroup = { value: 'All', label: 'All' };
            this.selectedDistributionType = { value: 'All', label: 'All' };
            this.selectedEffStartDate = new Date(moment(new Date).startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
            this.selectedEffectiveEndDate = new Date(moment(new Date).endOf('month').format('YYYY-MM-DDTHH:mm:ss'));
            this.pmcGridViewData = response;
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    validateTariff = (selectedTariffs) => {
        const localValidatingErrorBody: any = [];
        let isValidTariffUpdateRequest = true;
        if(selectedTariffs.length === 0){
            const message = 'Please select at least one Tariff.';
            localValidatingErrorBody.push(message);
            isValidTariffUpdateRequest = false;
        }

        if(this.selectedColValue === '' || !this.selectedColValue){
            const message = 'Please select Fee type to be updated.';
            localValidatingErrorBody.push(message);
            isValidTariffUpdateRequest = false;
        }

        if(this.amountToUpdate === '' || !this.amountToUpdate){
            const message = 'Please enter Amount value.';
            localValidatingErrorBody.push(message);
            isValidTariffUpdateRequest = false;
        }

        if(this.selectedUnitValue === '' || !this.selectedUnitValue){
            const message = 'Please select unit value.';
            localValidatingErrorBody.push(message);
            isValidTariffUpdateRequest = false;
        }
        return {
            isValidTariffUpdateRequest: isValidTariffUpdateRequest, 
            localValidatingErrorBody: localValidatingErrorBody
        };
    }

    @action
    async onUpdateClick(){
        const selectedTariffs : any = await this.agGridService.getSelectedRows();  
        const {isValidTariffUpdateRequest, localValidatingErrorBody} = this.validateTariff(selectedTariffs);
        if(isValidTariffUpdateRequest){
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(() => {
                this.updateBulkTariff(selectedTariffs);
            },
                'Tariff Update Confirmation',
                'Are you sure you want to update all the selected tariff?'
            );
        }else{
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }    
    }

    @Loader
    @Catch(() => errorHandler(PMCGridViewMessages.FETCH_ERROR_MESSAGE))
    async updateBulkTariff(selectedTariffs) {
        const user = this.createdUser;
        const feeType = this.selectedColValue.value;
        const amount = this.amountToUpdate;
        const unit = this.selectedUnitValue.value;
        const reqBody = selectedTariffs.map(row=> row.pmcTariffDetailID)
        const response = await this.pmcGridViewDataService.updateBulkTariff(user, feeType, amount, unit, reqBody);
        this.uiService.toastService.success('Selected Tariff updated successfully');
        runInAction(() => {
            this.showLoader = false;
            this.isRefreshed = false;
        })
        this.agGridService.deselectAllRows();
        this.selectedColValue = '';
        this.amountToUpdate = '';
        this.selectedUnitValue = '';
        this.onSearchClick();
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);    
    }

    @action
    async onDuplicateBtnClicked(){
        const selectedTariffs : any = await this.agGridService.getSelectedRows();  
        if(selectedTariffs.length === 0){
            const message = 'Please select at least one Tariff.';
            errorHandler(message);
            return;
        } else {
            this.isShowModal = true;
        }
    }

    validateDuplicateTariffDetails = () => {
        const localValidatingErrorBody: any = [];
        let isValidDuplicateTariffRequest = true;
        if(this.selectedEffectiveStartDate === '' || !this.selectedEffectiveStartDate){
            const message = 'Please select Start Date.';
            localValidatingErrorBody.push(message);
            isValidDuplicateTariffRequest = false;
        }

        if(this.selectedColValueForDuplicate === '' || !this.selectedColValueForDuplicate){
            const message = 'Please select Fee type to be updated.';
            localValidatingErrorBody.push(message);
            isValidDuplicateTariffRequest = false;
        }

        if(this.amountToUpdateForDuplicate === '' || !this.amountToUpdateForDuplicate){
            const message = 'Please enter Amount value.';
            localValidatingErrorBody.push(message);
            isValidDuplicateTariffRequest = false;
        }

        if(this.selectedUnitValueForDuplicate === '' || !this.selectedUnitValueForDuplicate){
            const message = 'Please select unit value.';
            localValidatingErrorBody.push(message);
            isValidDuplicateTariffRequest = false;
        }
        return {
            isValidDuplicateTariffRequest: isValidDuplicateTariffRequest, 
            localValidatingErrorBodyForTariffDuplicate: localValidatingErrorBody
        };
    }

    @action
    async onBulkDuplicateClick() {
        this.isShowBulkDuplicateConfirmationModal = true;
    }

    @action
    onCloseBulkDuplicateModal() {
        this.isShowModal = false;
        this.resetDuplicateModalValues();
    }

    @action
    handleCloseBulkDuplicateConfirmationModal() {
        this.isShowBulkDuplicateConfirmationModal = false;
    }

    @action
    async onBulkDuplicateConfirmationClicked(): Promise<void> {
        const selectedTariffs: any = await this.agGridService.getSelectedRows();
        const {isValidDuplicateTariffRequest, localValidatingErrorBodyForTariffDuplicate} = this.validateDuplicateTariffDetails();
        if (isValidDuplicateTariffRequest) {
            const startDate = moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD');
            const reqBody = selectedTariffs.map(row=> row.pmcTariffDetailID)
            const response = await this.pmcGridViewDataService.bulkDuplicateTariffCheck(startDate, reqBody);
            const invalidTariffs = response.filter((item) => item.isValid === false);
            if (response.length == 0 || invalidTariffs.length == 0) {
                const user = this.createdUser;
                const feeType = this.selectedColValueForDuplicate.value;
                const amount = this.amountToUpdateForDuplicate;
                const unit = this.selectedUnitValueForDuplicate.value;
                const response = await this.pmcGridViewDataService.bulkDuplicateTariff(user, feeType, amount, unit, startDate, reqBody);
                this.isShowBulkDuplicateConfirmationModal = false;
                this.uiService.toastService.success('Selected Tariff/s duplicated successfully');
                runInAction(() => {
                    this.isButtonDisabled=false;
                    this.isShowModal = false;
                    this.isRefreshed = false;
                    this.resetDuplicateModalValues();
                })
            } else {
                const localValidatingErrorBody: any = [];
                invalidTariffs.map((tariff) => {
                    localValidatingErrorBody.push(`Tariff Number ${tariff.tariffNumber} having ${tariff.msg}`);
                })
                const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
                this.isShowBulkDuplicateConfirmationModal = false;
                errorHandler(messages);
            }
        } else {
            const messages = '\u2022  ' + localValidatingErrorBodyForTariffDuplicate.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    @action
    resetDuplicateModalValues() {
        this.selectedEffectiveStartDate = '';
        this.amountToUpdateForDuplicate = '';
        this.selectedColValueForDuplicate = '';
        this.selectedUnitValueForDuplicate = '';
    }
    
    @action
    setEffStartDate(effStartDate: Date): void {
        this.selectedEffStartDate = effStartDate;
      
    }

    @action
    setEffectiveEndDate(effectiveEndDate: Date): void {// this.selectedEffectiveEndDate =effectiveEndDate? new Date(moment(effectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss')) :null;
         this.selectedEffectiveEndDate = effectiveEndDate;
         if( this.selectedEffectiveEndDate != null)
         {
            this.selectedEffectiveEndDate =new Date(moment(this.selectedEffectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss'))
         }
    }


    @action
    @Loader
    @Catch(() => errorHandler(PMCGridViewMessages.FETCH_ERROR_MESSAGE))
    async onClickEditTariff(tariffData): Promise<void> {
        const assetGroupID = this.assetGroupData.find(x => x.name === tariffData.assetGroup)?.assetGroupID;
        const reqBody = [{ 'propertyName': 'TARIFFNO', 'value': tariffData.tariffNumber.toString() }, { 'propertyName': 'TARIFFID', 'value': tariffData.pmcTariffDetailID.toString() }];
        const response = await this.pmcGridViewDataService.getPMCDashboardDetails(reqBody);
        runInAction(() => {
            pmcTariffDataStore.editableTariffData = response;
            pmcTariffDataStore.assetGroupID = assetGroupID;
            pmcTariffDataStore.isTariffSaved = true;
            pmcTariffDataStore.isTariffNumberClicked = true;
            pmcTariffDataStore.setTariffRequestType({ 'value': tariffData.assetGroup, 'label': tariffData.assetGroup});
            
            pmcTariffDataStore.pmcTariffNumber = response[0].tariffNumber;
            pmcTariffDataStore.tariffStatus = response[0].pmcTariffDetailStatus;
            pmcTariffDataStore.pmcTariffDetailID = response[0].pmcTariffDetailID;
            pmcTariffDataStore.pmcTariffFeeDetailID = response[0].pmcTariffFeeDetailID;
            pmcTariffDataStore.selectedSystemGroupID = this.systemGroupData.find(x => x.valueText === response[0].pmcSystemGroup)?.settingID;
            pmcTariffDataStore.setSystemGroup({'value': response[0].pmcSystemGroup, 'label': response[0].pmcSystemGroup});
            pmcTariffDataStore.setAgreement(response[0].agreement);
            pmcTariffDataStore.selectedAgreementEndDate = response[0].agreementEndDate ? new Date(moment(response[0].agreementEndDate).format('YYYY-MM-DD')) : '';
            pmcTariffDataStore.setBSW(parseFloat(response[0].bS_WPenalty).toFixed(2));
            const currencyValue = this.currencyData.find(x=>x.settingID===response[0].currency)?.valueText;
            pmcTariffDataStore.setDescription(response[0].additionalDesc);
            const regulatorValue = this.regulatorData.find(x=>x.settingID===response[0].regulator)?.valueText;
            pmcTariffDataStore.setRegulator({ 'value': response[0].regulator, 'label': regulatorValue});
            pmcTariffDataStore.setCurrency({ 'value': response[0].currency, 'label': currencyValue});
            pmcTariffDataStore.setDeliveryTerminallingFee(parseFloat(response[0].deliveryTerminalling).toFixed(2));
            pmcTariffDataStore.setDestination({ 'value': response[0].destination, 'label': response[0].destination});
            pmcTariffDataStore.setDistributionType({ 'value': response[0].distribution, 'label': response[0].distribution});
            pmcTariffDataStore.selectedEffectiveDate = response[0].effectiveDate ? new Date(response[0].effectiveDate) : '';
            pmcTariffDataStore.setElectronicReportingFee(parseFloat(response[0].electronicReportingFee).toFixed(2));
            pmcTariffDataStore.setEscalationAmount(response[0].escalationAmount);
            pmcTariffDataStore.setEscalationMonth({ 'value': response[0].escalationMonth, 'label': this.monthNames.filter(x=>x.value == response[0].escalationMonth)[0]?.label });
            const externalSystems =  response[0].externalSystem.length > 0 ? response[0].externalSystem.split(',') : [];
            pmcTariffDataStore.setExternalSystems(externalSystems);
            pmcTariffDataStore.setFacilityCode({'value': response[0].pmcFacility, 'label': response[0].pmcFacility});
            pmcTariffDataStore.setIntraSystemTransferFee(parseFloat(response[0].intraSystemTransferFee).toFixed(2));
            pmcTariffDataStore.setLSD({'value': response[0].lsd, 'label': response[0].lsd});
            const lossAllowanceValue = response[0].lossAllowance ? this.lossAllowanceData.find(x=>x.valueText===response[0].lossAllowance)?.settingID : null;
            pmcTariffDataStore.setLossAllowance({'value': lossAllowanceValue, 'label': response[0].lossAllowance});
            pmcTariffDataStore.setLumpSumFees(parseFloat(response[0].lumpSumFees).toFixed(2));
            pmcTariffDataStore.setOperator({'value': response[0].operator, 'label': response[0].operator});
            pmcTariffDataStore.setPipelineGroup({'value': response[0].pmcPipelineGroup, 'label': response[0].pmcPipelineGroup});
            pmcTariffDataStore.setPrimaryRate(parseFloat(response[0].primaryPipelineTariff).toFixed(2));
            const products =  response[0].product.length > 0 ? response[0].product.split(',') : [];
            pmcTariffDataStore.setProducts(products);
            pmcTariffDataStore.setProductType({'value': response[0].productTypeID, 'label': response[0].productType});
            pmcTariffDataStore.setQualityMgmtFee(parseFloat(response[0].qualityManagementFee).toFixed(2));
            pmcTariffDataStore.setSecondaryRate(parseFloat(response[0].secondaryPipelineTariff).toFixed(2));
            pmcTariffDataStore.setReceiptTerminalling(parseFloat(response[0].receiptTerminalling).toFixed(2));
            pmcTariffDataStore.setLmciAbandonment(parseFloat(response[0].lmciAbandonmentS_C).toFixed(2));
            pmcTariffDataStore.setInjectionFee(parseFloat(response[0].offload_InjectionFee).toFixed(2));
            pmcTariffDataStore.setSurcharge(parseFloat(response[0].surcharge).toFixed(2));
            const shrinkageValue = response[0].shrinkage ? this.shrinkageData.find(x=>x.valueText===response[0].shrinkage)?.settingID : '';
            pmcTariffDataStore.setShrinkage({'value': shrinkageValue, 'label': response[0].shrinkage});
            pmcTariffDataStore.setTariffApplication({'value': response[0].tariffApplication, 'label': response[0].tariffApplication});
            const unitValue = this.unitData.find(x=>x.settingID===response[0].unit)?.valueText;
            pmcTariffDataStore.setUnit({'value': response[0].unit, 'label': unitValue});
            pmcTariffDataStore.totalFee = Number(response[0].total);
            pmcTariffDataStore.selectedEffectiveStartDate = new Date(response[0].startDate);
            pmcTariffDataStore.selectedEffectiveEndDate = response[0].endDate ? new Date(response[0].endDate) : '';

            pmcTariffDataStore.setPMCTariffAttachmentGridData(response[0].pmcDocs);
            pmcTariffDataStore.backupAttachmentGridData = response[0].pmcDocs;
            pmcTariffDataStore.setPMCTariffNoteGridData(response[0].pmcNotes);
            
        })
        history.push('/PMCTariff', { from: '/PMCGridView' });   
    }

    getColDef() {
        return pmcGridViewColDef;
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };
}