import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { miscellaneousColDef } from '../components/miscellaneous-col-def';
import moment from 'moment';
import Path, { MiscellaneusSettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { MiscellaneousSettingDataService } from '../services/miscellaneous-setting-data-service';
import { MiscellaneousSettingsResponse } from '../model/model';
import { MiscellaneusSettingsMessage } from '../constants/enums';
import { miscellaneousSettingsDataStore } from '.';
import { AccountStore } from '../../../account/stores/account-store';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AddNewSettingsPopup, ConfirmNewSettingsProps } from '../components/addNewSettingsPopup';

export class MiscellaneousSettingsDataStore {
    @observable miscellaneousSettingsData: MiscellaneousSettingsResponse[] = [];
    @observable modifiedMiscellaneousSettingsData: MiscellaneousSettingsResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable addEditSettingData: any = {};
    @observable isEditingSettingData = false;
    @observable editableSettingData: any = [];
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupMiscellaneousSettingsData: MiscellaneousSettingsResponse[] = [];
    isRefreshed = false;
    @observable showModal = false;
    @observable selectedSettingName = { value: 'All', label: 'All' };
    endDate: any = moment().toISOString().slice(0, 10);
    @observable settingNameOptions: any[] = [];
    @observable settingNameOptionsUI: any[] = [];
    settingTypesData: any = [];
    @observable isActivateButtonDisabled = true;
    @observable jurisdictionDropdownOptions: any[] = [];
    jurisdictionData: any[] = [];
    defaultLoggedInUserLocation = '';
    @observable assetGroupDropdownOptions: any[] = [];

    constructor(
        public miscellaneousSettingDataService: MiscellaneousSettingDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        dataStore.setShowUnsavedWarningAlert(false);
        this.settingNameOptions = [];
        this.settingNameOptionsUI = [];
        this.userName = this.accountStore.displayName;
        this.defaultLoggedInUserLocation = this.accountStore.defaultUserLocation;
        this.getJurisdictionDropdownData();
        this.getSettingDropdownData();
        this.getAssetDropdownData();
        this.loadViewModel();
        // this.userID = this.accountStore.getUserID();
        
        this.selectedSettingName = { value: 'All', label: 'All' };
    }

    @Loader
    @action
    @Catch(() => errorHandler(MiscellaneusSettingsMessage.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const response: MiscellaneousSettingsResponse[] = await this.miscellaneousSettingDataService.getMiscellaneousSettingsData(this.defaultLoggedInUserLocation);
        this.setShowLoader(false);
        this.modifiedMiscellaneousSettingsData = response;
        // this.miscellaneousSettingsData = response;
        this.setMiscellaneousSettingsData(response);
        this.setbackupMiscellaneousSettingsData(response);
        this.isRefreshed = false;
         // this.addValuesInCellDropdowns();
        this.agGridService.getNodes()?.refreshCells();   
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSettingDropdownData(): Promise<void> {
        const settingNameOptions = await this.miscellaneousSettingDataService.getSettingNameOptions(this.defaultLoggedInUserLocation);
        this.settingTypesData = settingNameOptions;
        // const a = (settingNameOptions.map(x => {return {settingTypeDesc : x.settingTypeDesc, defaultLocation: a.defaultLocation} }));
        const b =  this.settingTypesData.filter((x , index, arr) => arr.indexOf(x) === index)
        const options: any = [];
        const optionsData: any = [];
        b.forEach((item) => {
            options.push({ settingTypeDesc: item.settingTypeDesc, defaultLocation: item.defaultLocation })
            optionsData.push({ 'value': item.settingTypeDesc, 'label': item.settingTypeDesc });
        })
        runInAction(() => {
            this.settingNameOptions = options;
            this.settingNameOptionsUI = optionsData;
        })
    };


    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAssetDropdownData(): Promise<void> {
        const assetGroupOptions = await this.miscellaneousSettingDataService.getAssetGroupOptions();
        const a = (assetGroupOptions.map(x => {return {name : x.name, assetGroupID: x.assetGroupID} }));
        const b =  assetGroupOptions.filter((x , index, arr) => arr.indexOf(x) === index)
        const options: any = [{name: 'Select', assetGroupID: 'Select'}];
        b.forEach((item) => {
            options.push({name : item.name, assetGroupID : item.assetGroupID})
        })
        runInAction(() => {
            this.assetGroupDropdownOptions = options
        })
    };

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getJurisdictionDropdownData(): Promise<void> {
        const jurisdictionDropdownOptions = await this.miscellaneousSettingDataService.getJurisdictionDropdownData(this.defaultLoggedInUserLocation);
        this.jurisdictionData = jurisdictionDropdownOptions;
        const b =  this.jurisdictionData.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({valueText : item.valueText, valueCode: item.valueCode });
        });
        runInAction(() => {
            this.showLoader = false;
            this.jurisdictionDropdownOptions = option;
        });
    }

    @Loader
    @Catch(() => errorHandler(MiscellaneusSettingsMessage.FETCH_ERROR_MESSAGE))
    async onClearSelectSettingName(): Promise<void> {
        const response: MiscellaneousSettingsResponse[] = await this.miscellaneousSettingDataService.getMiscellaneousSettingsData(this.defaultLoggedInUserLocation);
        this.setShowLoader(false);
        this.modifiedMiscellaneousSettingsData = response;
        this.setMiscellaneousSettingsData(response);
        this.setbackupMiscellaneousSettingsData(response);
        this.isRefreshed = false;
         // this.addValuesInCellDropdowns();
        this.selectedSettingName = { value: 'All', label: 'All' };
        this.agGridService.deselectAllRows();
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @Catch((err) => {
            console.log(JSON.stringify(err.response.data)) ; 
            if(err.response.data && err.response.data.length > 0) {
                const rows = miscellaneousSettingsDataStore.miscellaneousSettingsData.filter((item) => err.response.data.includes(item.settingID));
                const error = `Error in deleting the following rows having dependency \n ${JSON.stringify(rows)} `;
                errorHandler(error);
            } else {
                errorHandler(MiscellaneusSettingsMessage.FAILED_SUBMIT);
            }
        }
    )
    async removeSelectedSettings(): Promise<void> {
        const requestBody: any = await this.agGridService.getSelectedRows();
        if(requestBody.length > 0) {
        requestBody.forEach((item) => {
            item.updateUser = this.userName,
            item.updateDate = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')
        })
        const response: MiscellaneousSettingsResponse[] = await this.miscellaneousSettingDataService.deleteSettings(requestBody, this.defaultLoggedInUserLocation);
        const filteredResponse = this.selectedSettingName.label !== 'All' ? response.filter((item) => item.settingName === this.selectedSettingName.label): response;
        this.setShowLoader(false);
        this.modifiedMiscellaneousSettingsData = filteredResponse;
        this.setMiscellaneousSettingsData(filteredResponse);
        this.setbackupMiscellaneousSettingsData(filteredResponse);
        this.isRefreshed = false;
        this.isActivateButtonDisabled= true;
         // this.addValuesInCellDropdowns();
        this.agGridService.deselectAllRows();
        this.agGridService.getNodes()?.refreshCells();
        dataStore.setShowUnsavedWarningAlert(false); 
        } 
    }

    @Loader
    @Catch(() => errorHandler(MiscellaneusSettingsMessage.FETCH_ERROR_MESSAGE))
    async onSearchSetting(): Promise<void> {
        const response: MiscellaneousSettingsResponse[] = await this.miscellaneousSettingDataService.getMiscellaneousSettingsData(this.defaultLoggedInUserLocation);
        const filteredResponse = this.selectedSettingName.label !== 'All' ? response.filter((item) => item.settingName === this.selectedSettingName.label) : response;
        this.setShowLoader(false);
        this.modifiedMiscellaneousSettingsData = filteredResponse;
        // this.miscellaneousSettingsData = filteredResponse;
        this.setMiscellaneousSettingsData(filteredResponse);
        // this.setbackupMiscellaneousSettingsData(filteredResponse);
        this.isRefreshed = false;
         // this.addValuesInCellDropdowns();
        this.agGridService.deselectAllRows();
        this.agGridService.getNodes()?.refreshCells(); 
        dataStore.setShowUnsavedWarningAlert(false); 
    }

    getColDef() {
        this.updatePushtoVmacsButtonColDef();
        this.updateSelectCellColDef();
        return miscellaneousColDef;
    }

    @action
    reset(): void {
        this.setMiscellaneousSettingsData(this.backupMiscellaneousSettingsData);
        this.modifiedMiscellaneousSettingsData = this.backupMiscellaneousSettingsData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setShowLoader(showLoader): void {
        this.showLoader = showLoader;
    }

    @action
    setMiscellaneousSettingsData(miscellaneousSettingsData: MiscellaneousSettingsResponse[]): void {
        this.miscellaneousSettingsData = miscellaneousSettingsData
    }

    setbackupMiscellaneousSettingsData(backUpList: MiscellaneousSettingsResponse[]) {
        this.backupMiscellaneousSettingsData = backUpList;
    }

    @computed
    get MiscellaneousSettingsData() {
        return toJS(this.miscellaneousSettingsData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    @action
   isEditableSettingName(params) {
        if (params.data.settingID <= 0) {
         // this.addValuesInCellDropdowns();
        return true
        }
        return false;
   }

    @Loader
    @action
    @Catch(() => errorHandler(MiscellaneusSettingsMessage.FAILED_SUBMIT))
    async saveSettingsData(): Promise<void> {
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        const item = this.addEditSettingData;
        if((item.settingName !== '' && item.settingName) && (item.valueCode !== '' && item.valueCode) && (item.valueText !== '' && item.valueText)) {
            const isUniqueSetting = this.miscellaneousSettingsData.filter((setting) => item.settingName === setting.settingName && item.defaultLocation === setting.defaultLocation && (item.valueCode === setting.valueCode || item.valueText === setting.valueText)).length <= 1;
            if(isUniqueSetting) {
                const settingTypeData = this.settingTypesData.filter((settingType) => settingType.settingTypeDesc === item.settingName);
                if(settingTypeData.length > 0 && item.valueCode.length > settingTypeData[0].valueCodeLimit) {
                    const message = `Please limit the Value Code character for ${item.settingName} setting max upto ${settingTypeData[0].valueCodeLimit} characters.`;
                    if(!localValidatingErrorBody.includes(message)) {
                        localValidatingErrorBody.push(message);
                    }
                } else {
                    const tariffMappingId = this.settingTypesData.length > 0 && item.settingName !== '' ? this.settingTypesData.filter((i) => i.settingTypeDesc === item.settingName)[0].tariffMappingID: 0;
                    const assetGroupID = this.defaultLoggedInUserLocation !== 'US' && this.assetGroupDropdownOptions.length > 0 && item.selectedAssetGroup && item.selectedAssetGroup !== '' && item.selectedAssetGroup !== 'Select' ? this.assetGroupDropdownOptions.find(x => x.name === item.selectedAssetGroup)?.assetGroupID : null;
                    item.updateUser = this.isEditingSettingData ? this.userName : null,
                    item.updateDate = this.isEditingSettingData ? moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss') : null,
                    item.createUser = this.isEditingSettingData ? this.editableSettingData[0].createUser : this.userName,
                    item.createDate = this.isEditingSettingData ? this.editableSettingData[0].createDate : moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.tariffMappingId = this.isEditingSettingData ? this.editableSettingData[0].tariffMappingId : tariffMappingId;
                    item.settingID = this.isEditingSettingData ? this.editableSettingData[0].settingID : 0,
                    item.assetGroupID = assetGroupID;
                    item.action = this.isEditingSettingData ? this.editableSettingData[0].action : 'I';
                    requestBody.push(item);
                }
            } else {
                const message = `Tried to create duplicate setting for ${item.settingName} with Value Code as ${item.valueCode} and Value Text as ${item.valueText}. Please create the unique record.`;
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        } else {
            const message = 'Please insert valid Setting Name, Value Code and Value Text as mandatory fields.';
            if(!localValidatingErrorBody.includes(message)) {
                localValidatingErrorBody.push(message);
            }
        }
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0) {
            const response: MiscellaneousSettingsResponse[] = await this.miscellaneousSettingDataService.updateMiscellaneousSettingsData(requestBody, this.defaultLoggedInUserLocation);
            let errorInSave = false;
            let errorSummary: ToastMessage[] = [];
            if (response) {
                for (const key in response) {
                    if (response[key].settingID) {
                        const objIndex = this.miscellaneousSettingsData.findIndex(a => ((a.settingID === response[key].settingID)|| (a.settingName === response[key].settingName && a.valueCode === response[key].valueCode && a.valueText === response[key].valueText)));
                        if(objIndex >= 0) {
                            const backupItem = this.miscellaneousSettingsData[objIndex];
                            this.mapEditableColumns(backupItem, response[key]);
                        }
                    }
                    else {
                        errorInSave = true;
                        errorSummary = [...errorSummary, { id: key, description: response[key].settingID.toString() }];
                    }
                }
            }
            this.cellValueChangeMap = {};
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                    for (let i = 0; i < this.miscellaneousSettingsData.length; i++) {
                        const updatedData = this.miscellaneousSettingsData[i];
                        if (updatedData.settingID.toString() === errorMessage.id) {
                            const backupRowIndex = this.miscellaneousSettingsData.findIndex(a => a.settingID.toString() == errorMessage.id);
                            this.mapEditableColumns(updatedData, this.miscellaneousSettingsData[backupRowIndex]);
                        }
                    }
                });
            }
            else {
                this.uiService.toastService.success(MiscellaneusSettingsMessage.SAVE);
            }
            this.setShowLoader(false);
            this.isRefreshed = true;
            this.isEditingSettingData = false;
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }
     
    @action
    @Catch(() => errorHandler('Fetch Error'))
    openSettingDetails(data) {
        const editableSettingData: any = [];
        editableSettingData.push(data);
        this.isEditingSettingData = true;
        this.editableSettingData = editableSettingData;
        const defaultUserLocation = this.defaultLoggedInUserLocation;
        const jurisdictionDropdown = this.jurisdictionDropdownOptions; // defaultUserLocation !== 'All' ? this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions.filter((item) => item.valueCode === defaultUserLocation) : this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions;
        const jurisdictionDropdownData = jurisdictionDropdown.map(x => {return { 'value': x.valueText, 'label': x.valueCode}});
        const settingsTypeData = this.settingNameOptions.map(x => {return { 'value': x.settingTypeDesc, 'label': x.settingTypeDesc}});
        const assetGroupDropdown = this.assetGroupDropdownOptions.map(x => {return { 'value': x.assetGroupID, 'label': x.name}});
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          (data) => {this.saveSettingsData()},
          AddNewSettingsPopup,
          {settingsTypeData: settingsTypeData,
          jurisdictionOptions: jurisdictionDropdownData,
          assetGroupDropdownOptions: assetGroupDropdown,
          editableData: editableSettingData,
          isEditingSettingData: true,
          defaultUserLocation: defaultUserLocation} as ConfirmNewSettingsProps,
          'confirmPopup'
        );
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    isSaveDisabled = ()  => {
        // for (const row in this.cellValueChangeMap) {
        //     for (const col in this.cellValueChangeMap[row]) {
        //         const obj = this.cellValueChangeMap[row][col];
        //         if (obj.initValue !== obj.currentValue) {
        //             return false;
        //         }
        //     }
        // }

        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    isAddNewSettingTypeDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.AddSettingType);
    };

    isDeleteDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Delete);
    };

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }

    @action
    updateRow = (selectedRowData: any): void => {
        const updatedShopIndex = this.miscellaneousSettingsData.findIndex(a => a.settingID == selectedRowData.settingID);
        if (!_.isEqual(this.backupMiscellaneousSettingsData[updatedShopIndex], selectedRowData)) {
            selectedRowData.syncStatus = selectedRowData.source === 'VMACS' ? SyncStatus[0] : selectedRowData.syncStatus;
            this.mapEditableColumns(this.miscellaneousSettingsData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.additionalValue = updatedItem.additionalValue;
        currentItem.remarks = updatedItem.remarks;
        currentItem.settingName = updatedItem.settingName;
        currentItem.valueCode = updatedItem.valueCode;
        currentItem.valueText = updatedItem.valueText;
        currentItem.settingID = updatedItem.settingID;
        currentItem.syncStatus = updatedItem.syncStatus;
        currentItem.testSyncDate = updatedItem.testSyncDate;
        currentItem.prodSyncDate = updatedItem.prodSyncDate;
        currentItem.action = updatedItem.action;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
        currentItem.source = updatedItem.source;
        currentItem.defaultLocation = updatedItem.defaultLocation;
        currentItem.assetGrp = updatedItem.assetGrp;
        currentItem.assetGroupID = updatedItem.assetGroupID;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @Loader
    @action
    @Catch(() => errorHandler(MiscellaneusSettingsMessage.FAILED_SUBMIT))
    async saveNewSettingType(settingTypeDesc, jurisdiction): Promise<void> {
        
        const requestBody: any =  { settingTypeID: 0,
            settingTypeDesc: settingTypeDesc,
            defaultLocation: jurisdiction,
            createUser: this.userName,
            createDate: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            updateUser: null,
            updateDate: null
        };
        if (requestBody.length !== 0) {
            const response: any[] = await this.miscellaneousSettingDataService.addNewSettingType(requestBody, this.defaultLoggedInUserLocation);
            if (response.length > 0 && response[0].settingTypeID) {
                this.settingNameOptions = [];
                await this.getSettingDropdownData();
                this.setShowLoader(false);
                this.isRefreshed = false;
                this.uiService.toastService.success(MiscellaneusSettingsMessage.SAVE);
            } else {
                this.uiService.toastService.error(MiscellaneusSettingsMessage.FAILED_SUBMIT);
            }
        }
    }

    updatePushtoVmacsButtonColDef = () => {
        const pushToVmacsButtonColDef = miscellaneousColDef.find(x => x.colId === 'PushToVMACS');
        if (pushToVmacsButtonColDef) {
            pushToVmacsButtonColDef.hide = !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncProd)) || miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false,
            pushToVmacsButtonColDef.suppressColumnsToolPanel = !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) || dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncProd)) ||  miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false,
            pushToVmacsButtonColDef.cellRendererParams = {
                dataStore : miscellaneousSettingsDataStore,
                handleClickPushVmacs : (env, data) => this.pushToVmacs(env, data),
                updatedData : this.getUpdatedRows()
            };
        }
        const syncStatusColDef = miscellaneousColDef.find(x => x.colId === 'syncStatus');
        if (syncStatusColDef) {
            syncStatusColDef.hide =  miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false,
            syncStatusColDef.suppressColumnsToolPanel = miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false
        }
        const prodSyncDateTimeColDef = miscellaneousColDef.find(x => x.colId === 'prodSyncDate');
        if (prodSyncDateTimeColDef) {
            prodSyncDateTimeColDef.hide = miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false,
            prodSyncDateTimeColDef.suppressColumnsToolPanel = miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false
        }
        const testSyncDateTimeColDef = miscellaneousColDef.find(x => x.colId === 'testSyncDate');
        if (testSyncDateTimeColDef) {
            testSyncDateTimeColDef.hide =  miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false,
            testSyncDateTimeColDef.suppressColumnsToolPanel = miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'CAN' ? true : false
        }
    }

    updateSelectCellColDef = () => {
        const settingNameColDef = miscellaneousColDef.find(x => x.colId === 'settingName');
        if (settingNameColDef) {
            const settingNameOptions = this.selectedSettingName.label !== 'All' ? this.settingNameOptions.filter((item) => item.settingTypeDesc  === this.selectedSettingName.label) : this.settingNameOptions;
            settingNameColDef.cellEditorParams = (params) => {
                const filteredDataBasedonLocationSelected = params.data.defaultLocation ? settingNameOptions.filter((item) => item.defaultLocation === params.data.defaultLocation).map(item => item.settingTypeDesc) : settingNameOptions.map(item => item.settingTypeDesc);
                const validSettingSelected = filteredDataBasedonLocationSelected.filter((item) => item.settingTypeDesc === params.data.settingName).length > 0;
                params.data.settingName = validSettingSelected ? params.data.settingName : filteredDataBasedonLocationSelected.length > 0 ? filteredDataBasedonLocationSelected[0].settingTypeDesc : null;
                return { values: filteredDataBasedonLocationSelected };
            };

        }
        const defaultLocationColDef = miscellaneousColDef.find(x => x.colId === 'defaultLocation');
        if (defaultLocationColDef) {
            const jurisdictionDropdown =  this.defaultLoggedInUserLocation !== 'All' ? this.jurisdictionDropdownOptions.filter((item) => item.valueCode === this.defaultLoggedInUserLocation) : this.jurisdictionDropdownOptions;
            defaultLocationColDef.cellEditorParams = (params) => {
                const settingNameOptions = this.selectedSettingName.label !== 'All' ? this.settingNameOptions.filter((item) => item.settingTypeDesc  === this.selectedSettingName.label) : this.settingNameOptions;
                const filteredDataBasedonLocationSelected = params.data.defaultLocation ? settingNameOptions.filter((item) => item.defaultLocation === params.data.defaultLocation).map(item => item.settingTypeDesc) : settingNameOptions.map(item => item.settingTypeDesc);
                const validSettingSelected = filteredDataBasedonLocationSelected.filter((item) => item.settingTypeDesc === params.data.settingName).length > 0;
                params.data.settingName = validSettingSelected ? params.data.settingName : filteredDataBasedonLocationSelected.length > 0 ? filteredDataBasedonLocationSelected[0].settingTypeDesc : null;;
                    return { values: jurisdictionDropdown.map(item => item.valueCode) };
            };
        }

        const assetGroupColDef = miscellaneousColDef.find(x => x.colId === 'assetGrp');
        if (assetGroupColDef) {
            assetGroupColDef.hide =  miscellaneousSettingsDataStore.defaultLoggedInUserLocation === 'US' ? true : false,
            assetGroupColDef.cellEditorParams = (params) => {
                return { values: this.assetGroupDropdownOptions.map(item => item.name) };
            };
        }
    }

    getUpdatedRows = () => {
        const updatedRowIDs = this.miscellaneousSettingsData.filter(a => this.getUpdatedRowIDs().includes(a.settingID.toString()));
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushToVmacs(env, data): Promise<void> {
        const action = data.action === 'U' ? 'Update' : 'Insert';

        const requestBody: any =  { 
            'http_action' : action,
            'http_record_id': data.settingID,
            'http_cts_tablename': MiscellaneusSettingsDBTableName.TableName,
            'http_cts_mappingtablename' : MiscellaneusSettingsDBTableName.MappingTableName,
            'http_userid': this.userName,
            'http_environment': env.toUpperCase()  
        };
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response = await this.miscellaneousSettingDataService.pushToVmacs(requestBody);
            // if(response) {
                await this.onSearchSetting();
                this.uiService.toastService.success(MiscellaneusSettingsMessage.SYNC);
            // }
        }

    }

    @Loader
    @action
    @Catch(() => errorHandler(MiscellaneusSettingsMessage.FAILED_SUBMIT))
    async updateStatusFromDelToActive(): Promise<void> {
        const selectedRowIDs: any = await this.agGridService.getSelectedRows();
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        selectedRowIDs.forEach((item) => {
            if((item.settingName !== '' && item.settingName) && (item.valueCode !== '' && item.valueCode) && (item.valueText !== '' && item.valueText)) {
                const isUniqueSetting = this.miscellaneousSettingsData.filter((setting) => item.settingName === setting.settingName && item.defaultLocation === setting.defaultLocation && (item.valueCode === setting.valueCode || item.valueText === setting.valueText)).length <= 1;
                if(isUniqueSetting) {
                    const tariffMappingId = this.settingTypesData.length > 0 && item.settingName !== '' ? this.settingTypesData.filter((i) => i.settingTypeDesc === item.settingName)[0].tariffMappingID: 0
                    item.updateUser =  this.userName,
                    item.updateDate =  moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.createUser = item.createUser ? item.createUser : this.userName,
                    item.createDate = item.createDate ? item.createDate : moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.tariffMappingId = item.tariffMappingId ? item.tariffMappingId : tariffMappingId,
                    item.settingID = item.settingID ? item.settingID : 0,
                    item.action = item.action && item.action === 'D' ? 'U' : item.action
                    requestBody.push(item);
                } else {
                    const message = `Tried to create duplicate setting for ${item.settingName} with Value Code as ${item.valueCode} and Value Text as ${item.valueText}. Please create the unique record.`;
                    if(!localValidatingErrorBody.includes(message)) {
                        localValidatingErrorBody.push(message);
                    }
                }
            } else {
                const message = 'Please insert valid Setting Name, Value Code and Value Text as mandatory fields.';
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        });
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
            const response: MiscellaneousSettingsResponse[] = await this.miscellaneousSettingDataService.updateMiscellaneousSettingsData(requestBody, this.defaultLoggedInUserLocation);
            let errorInSave = false;
            let errorSummary: ToastMessage[] = [];
            if (response) {
                for (const key in response) {
                    if (response[key].settingID) {
                        const objIndex = this.miscellaneousSettingsData.findIndex(a => ((a.settingID === response[key].settingID)|| (a.settingName === response[key].settingName && a.valueCode === response[key].valueCode && a.valueText === response[key].valueText)));
                        if(objIndex >= 0) {
                            const backupItem = this.miscellaneousSettingsData[objIndex];
                            this.mapEditableColumns(backupItem, response[key]);
                        }
                    }
                    else {
                        errorInSave = true;
                        errorSummary = [...errorSummary, { id: key, description: response[key].settingID.toString() }];
                    }
                }
            }
            this.cellValueChangeMap = {};
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                    for (let i = 0; i < this.miscellaneousSettingsData.length; i++) {
                        const updatedData = this.miscellaneousSettingsData[i];
                        if (updatedData.settingID.toString() === errorMessage.id) {
                            const backupRowIndex = this.miscellaneousSettingsData.findIndex(a => a.settingID.toString() == errorMessage.id);
                            this.mapEditableColumns(updatedData, this.miscellaneousSettingsData[backupRowIndex]);
                        }
                    }
                });
            }
            else {
                this.uiService.toastService.success(MiscellaneusSettingsMessage.SAVE);
            }
            this.setShowLoader(false);
            this.isRefreshed = false;
             // this.addValuesInCellDropdowns();  
            this.isActivateButtonDisabled= true;  
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    @action 
    scrollToFocusTheNewCell() {
        this.agGridService.scrollToSelectedRow();
    }

    @action
    updateValueCodeColumn(data: any, value: string, colName: string): void { 
        const row = data.settingID;
        const initValue = data[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        data.valueCode = value;
        this.updateRow(data);
    }
 
}
