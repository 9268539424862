import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, template, update } from 'lodash';
import { connectionPointsDataColDef } from '../components/connection-point-col-def';
import moment from 'moment';
// import Path, { ConnectionPointsDBTableName } from '../../../../Path';
import axios from 'axios';
import { CPDataService, cpDataService } from '../services/cp-data-service';
import { ConnectionPointsResponse } from '../model/model';
import { ConnectionPointsMessage } from '../constants/enums';
import { connectionPointMaintenanceDataStore } from '.';
import { AccountStore } from '../../../account/stores/account-store';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ThreeSixty } from '@mui/icons-material';
import { AppConstant } from 'app_constants';
import history from '../../../../shared/components/header-component/component/history';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';

export class ConnectionPointDetailsDataStore {
    @observable connectionPointGridData: any[] = [];
    @observable modifiedConnectionPointGridData: any[] = [];
    @observable cellValueChangeMap: any = {};
    @observable cellValueChangeMapForRelationshipGrid: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupConnectionPointsData: ConnectionPointsResponse[] = [];
    @observable isRefreshed = false;
    @observable showModal = false;
    @observable selectedBaseSystem;
    @observable selectedLocation;
    @observable selectedCPName;
    @observable selectedCPCode;
    @observable selectedCPType;
    @observable selectedCCType;
    @observable selectedCPStatus;
    @observable selectedActivityType;
    @observable selectedState;
    @observable selectedfercType;
    @observable selectedConnCarrierOperator;
    @observable selectedServiceDate;
    @observable selectedRelatedBaseSystem;
    @observable selectedRelatedLocation;
    endDate: any = moment().toISOString().slice(0, 10);
    settingTypesData: any = [];
    @observable isActivateButtonDisabled = true;
    @observable baseSystemData: any = [];
    @observable baseSystemDropdownOption: any = [];
    @observable relatedBaseSystemDropdownOption: any = [];
    @observable locationData: any = [];
    @observable locationDropdownOption: any = [];
    @observable relatedLocationDropdownOption: any = [];
    @observable cpTypeData : any = [];
    @observable cpTypeDropdownOption: any = [];
    @observable activityTypeData : any = [];
    @observable activityTypeDropdownOption: any = [];
    @observable ccTypeData : any = [];
    @observable ccTypeDropdownOption: any = [];
    @observable fercTypeData : any = [];
    @observable fercTypeDropdownOption: any = [];
    @observable connCarrierOperatorData : any = [];
    @observable connCarrierOperatorDropdownOption: any = [];
    @observable tariffStatesData: any = [];
    @observable tariffStatesDropdownOption: any = [];
    @observable ctsStatusData: any = [];
    @observable ctsStatusDropdownOption : any = [];
    @observable isSearchClicked = false;
    @observable remarksGridData: any[] = [];
    backupRemarksGridData: any[] = [];
    @observable relationshipGridData: any[] = [];
    backupRelationshipGridData: any[] = [];
    @observable cpRelTypesDropdownOption: any[] = [];
    navigateToScreen: any = null;
    workflowId: any = null;
    @observable cpDetailsData;
    @observable isFormUpdated;
    navigateState: any;


    constructor(
        public cpDataService: CPDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForRelationShip: AgGridService
    ) { 
        makeAutoObservable(this);
    }

    init(): void {
        // dataStore.setShowUnsavedWarningAlert(false);
        // this.settingNameOptions = [];
        this.showLoader = true;
        this.workflowId = null;
        this.navigateToScreen = null;
        this.navigateState = null;
        this.loadInitialData().then(() => {
            const state=history?.location?.state as {from?: string; url?: string; workflowId?: string; pipeline? : any; location? : any } || '';
            if(state.url) {
                this.navigateToScreen  = state.url;
                this.workflowId = state.workflowId ? state.workflowId : 0;
                this.navigateState = state;
                const a = state.pipeline ? state.pipeline.map(x => {return { 'value': x.label, 'label': x.label}}) : this.baseSystemDropdownOption;
                this.baseSystemDropdownOption=  a;
                const b = state.location ? state.location.map(x => {return { 'value': x.label, 'label': x.label}}) : this.locationDropdownOption;
                this.locationDropdownOption= b;
                const c = a.map((x) => {
                    return x.value
                })
                const d = b.map((x) => {
                    return x.value
                })
                const baseSystemData = this.baseSystemData.filter((x) => c.includes(x.plName));
                const filteredlocationData = this.locationData.filter((x) => d.includes(x.locnName));
                this.selectedBaseSystem = this.baseSystemDropdownOption.length  === 1 ?  this.baseSystemDropdownOption[0].value : null;
                const baseDetails = baseSystemData.find((item) => item.plName === this.selectedBaseSystem)
                const pipelineID = baseDetails && baseDetails.pipelineID ? baseDetails.pipelineID : null;
                const locationData = pipelineID ? filteredlocationData.filter((x) => x.pipelineID === pipelineID) : this.locationData;
                const locationOptData = locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
                this.locationDropdownOption = locationOptData;
                this.selectedLocation = this.locationDropdownOption.length  === 1 ?  this.locationDropdownOption[0].value : null;
                this.selectedRelatedBaseSystem = this.baseSystemDropdownOption.length  === 1 ?  this.baseSystemDropdownOption[0].value : null;
                this.selectedRelatedLocation = this.locationDropdownOption.length  === 1 ?  this.locationDropdownOption[0].value : null;
                if(this.selectedRelatedLocation) {
                    this.getCPTariff(this.selectedRelatedLocation);
                }
            }
    
            const connectionPointMaintenanceState=history?.location?.state as {from?: string; data?: string; workflowId?: string; } || '';
            if(connectionPointMaintenanceState.from === '/ConnectionPointMaintenance' && connectionPointMaintenanceState.data) {
                this.cpDetailsData = connectionPointMaintenanceState.data;
                this.selectedActivityType = this.cpDetailsData?.activityType;
                this.selectedBaseSystem = this.cpDetailsData?.plName;
                this.selectedRelatedBaseSystem = this.cpDetailsData?.plName;
                this.selectedCPCode = this.cpDetailsData.cpCode;
                this.selectedCPName = this.cpDetailsData.cpName;
                this.selectedCPStatus = this.cpDetailsData.status;
                this.selectedCPType =this.cpDetailsData.cpType;
                
                this.selectedLocation = this.cpDetailsData.locnName;
                this.selectedRelatedLocation = this.cpDetailsData.locnName;
                this.selectedServiceDate = new Date(this.cpDetailsData.serviceDate);
                this.selectedState = this.cpDetailsData.state;
                this.workflowId = this.cpDetailsData.workflowId;
            
                this.remarksGridData = this.cpDetailsData.comments;
                this.getCPTariff(this.selectedLocation).then((response) => {
                    this.relationshipGridData = this.cpDetailsData.relations.map((x) => {
                        const cpName = this.connectionPointGridData.find((y) => y.connectionPointID === x.conPointIDTO);
                        const relatedCPType = this.cpRelTypesDropdownOption.find((y) => y.settingID === x.relatedCPType);
                    
                        return (
                            {
                                ...x,
                                cpName: cpName ? cpName.cpName : x.cpName ? x.cpName : null,
                                relatedCPType: relatedCPType ? relatedCPType.valueText : null,
                                carrySplit: x.carrySplit,
                                isSelected: false
                            }
                        )
                    });
                    
                });
               
    
                const ccType = this.ccTypeData.find((x) => x.settingID === this.cpDetailsData.ccTypeId);
                const fercType = this.fercTypeData.find((x) => x.settingID === this.cpDetailsData.fercTypeId);
                const operatorCustomer = this.connCarrierOperatorData.find((x) => x.customerID === this.cpDetailsData.operatorCustomerID);
                this.selectedCCType = ccType ? ccType.valueText: undefined;
                this.selectedfercType = fercType ? fercType.valueText: undefined;
                this.selectedConnCarrierOperator =operatorCustomer ? operatorCustomer.customerName : undefined;
               
    
    
            }
    
            this.agGridService.refreshGrid();
            history.push('/ConnectionPointDetails');
             
            this.showLoader = false;
        })
       

        // this.loadViewModel();
        // this.userID = this.accountStore.getUserID();
        this.userName = this.accountStore.displayName;
    }

    // async loadViewModel() {
    //     const pageSize = connectionPointMaintenanceDataStore.agGridService.paginationGetPageSize();
    //     if(dataStore.isPageSizeChanged) {
    //         connectionPointMaintenanceDataStore.agGridService.resetCache(Number(pageSize));
    //     }
    //     const dataSource = {
    //         getRows: (params) => {
    //         // Use startRow and endRow for sending pagination to Backend
    //         // params.startRow : Start Page
    //         // params.endRow : End Page
    //             const pageSize = this.agGridService.paginationGetPageSize();
    //             const page =  params.endRow / (pageSize ? pageSize : 20);
    //             const pageNo = this.isRefreshed || dataStore.isPageSizeChanged || page <= 1 ? 1 : parseInt(page.toFixed(0));
    //             const data = this.getapiRequest(connectionPointMaintenanceDataStore.isSearchClicked, pageNo, pageSize, params.sortModel);
                
    //             const filterRequestBody: any = [];
    //             for (const [key, value] of Object.entries(params.filterModel)) {
    //                 console.log(key, value);
    //                 const item = {
    //                     propertyName: key,
    //                     value: value && value['filterType'] === 'date'? value['dateFrom'] : value ? value['filter']:'',
    //                     operator: value ? value['type'] : '',
    //                     joinPrev: 'none'
                    
    //                   }
    //                   filterRequestBody.push(item);
    //               }
    //               console.log('params' +JSON.stringify(filterRequestBody));
                
    //             this.agGridService.showLoadingOverlay();
    //             cpDataService.getCPGridData(data, filterRequestBody).then((response) => {
    //                 const totalCount = response.data && response.data.length > 0 ? response.data[0].totalCount : response.data.length
    //                 params.successCallback(response.data ? response.data : [], totalCount);
    //                 this.agGridService.hideOverlay();
    //                 this.isRefreshed = false;
    //                 dataStore.setPageSizeChanged(false);
    //                 dataStore.setCacheBlockSize(response.data? response.data.length : 0)
    //             }).catch(() => {
    //                 params.successCallback([], 0);
    //                 dataStore.setCacheBlockSize(0);
    //                 this.isRefreshed = false;
    //                 dataStore.setPageSizeChanged(false);
    //                 errorHandler(ConnectionPointsMessage.FETCH_ERROR_MESSAGE)
    //             });
    //         }
    //     }
    //     this.agGridService.setDatasource(dataSource);
    // }

    async loadInitialData() {
        await this.getBaseSystemDropdown();
        await this.getLocationDropdown();
        await this.getCPTypeDropdown();
        await this.getActivityTypeDropdown();
        await this.getTariffStatesDropdown();
        await this.getCTSStatus();
        await this.getCCTypeDropdown();
        await this.getFercReportType();
        await this.getConnCarrierOperator();
        await this.getCPTariff();
        await this.getCPRelTypes();
        this.addValuesInCellDropdowns();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.cpDataService.getBaseSystems();
        if(baseSystemDropdownOptions) {
            this.baseSystemData = baseSystemDropdownOptions;
            const a = baseSystemDropdownOptions.map(x => {return { 'value': x.plName, 'label': x.plName}});
            this.baseSystemDropdownOption= a;
            this.relatedBaseSystemDropdownOption = a;
        }

       
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLocationDropdown(): Promise<void> {
        const locationDropdownOptions = await this.cpDataService.getLocationsData();
        if(locationDropdownOptions) {
            this.locationData = locationDropdownOptions;
            const a = locationDropdownOptions.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
            this.locationDropdownOption= a;
            this.relatedLocationDropdownOption = a;
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCPTariff(locationName?): Promise<void> {
        const location = locationName ? this.locationData.find((x) => x.locnName === locationName) : this.locationData.find((x) => x.locnName === this.selectedLocation);
        const connectionPointGridData = await this.cpDataService.getCPTariff(location && location.locationID ?  location.locationID : 0);
        if(connectionPointGridData) {
            const a = this.connectionPointGridData.map((x) => x.cpName)
            connectionPointGridData.map((item) => {
                if(!a.includes(item.cpName))
                this.connectionPointGridData.push(item)
            })
        }
        this.addValuesInCellDropdowns();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCPRelTypes(): Promise<void> {
        const cpRelTypesDropdownOption = await this.cpDataService.getCPRelTypes();
        if(cpRelTypesDropdownOption) {
            this.cpRelTypesDropdownOption = cpRelTypesDropdownOption;
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCPTypeDropdown(): Promise<void> {
        const cpTypeDropdownOption = await this.cpDataService.getCPTypesData();
        this.cpTypeData = cpTypeDropdownOption;
        const a = (cpTypeDropdownOption.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({valueText : item})
        })
        this.cpTypeDropdownOption= option.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCCTypeDropdown(): Promise<void> {
        const ccTypeDropdownOption = await this.cpDataService.getCCTypesData();
        this.ccTypeData = ccTypeDropdownOption;
        const a = (ccTypeDropdownOption.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({valueText : item})
        })
        this.ccTypeDropdownOption= option.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getActivityTypeDropdown(): Promise<void> {
        const activityTypeDropdownOption = await this.cpDataService.getActivityTypesData();
        this.activityTypeData = activityTypeDropdownOption;
        const a = (activityTypeDropdownOption.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({valueText : item})
        })
        this.activityTypeDropdownOption= option.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffStatesDropdown(): Promise<void> {
        const tariffStatesDropdownOption = await this.cpDataService.getTariffStates();
        this.tariffStatesData = tariffStatesDropdownOption;
        const a = (tariffStatesDropdownOption.map(x => x.stateName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({stateName : item})
        })
        this.tariffStatesDropdownOption= option.map(x => {return { 'value': x.stateName, 'label': x.stateName}});
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCTSStatus(): Promise<void> {
        const ctsStatusOptions = await this.cpDataService.getCPStatus();
        this.ctsStatusData = ctsStatusOptions;
        const a = (ctsStatusOptions.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({valueText : item})
        })
        this.ctsStatusDropdownOption= option.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
    }


    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getFercReportType(): Promise<void> {
        const fercTypeData = await this.cpDataService.getFercType();
        this.fercTypeData = fercTypeData;
        const a = (fercTypeData.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({valueText : item})
        })
        this.fercTypeDropdownOption= option.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getConnCarrierOperator(): Promise<void> {
        const connCarrierOperatorData = await this.cpDataService.getConnCarrerOperator(0);
        this.connCarrierOperatorData = connCarrierOperatorData;
        const a = (connCarrierOperatorData.map(x => x.customerName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({customerName : item})
        })
        this.connCarrierOperatorDropdownOption= option.map(x => {return { 'value': x.customerName, 'label': x.customerName}});
    }

    @Loader
    @Catch(() => errorHandler(ConnectionPointsMessage.FETCH_ERROR_MESSAGE))
    async onClearSelectSettingName(): Promise<void> {
        this.isSearchClicked = false;
        this.isRefreshed = true
        this.isActivateButtonDisabled = true;
        dataStore.onGridReady();  
        this.selectedBaseSystem = 'All';
        this.selectedLocation = 'All';
        this.selectedCPName = '';
        this.selectedCPCode = '';
        this.selectedCPType = 'All';
        this.selectedCPStatus = 'All';
        this.selectedActivityType = 'All';
        this.selectedState = 'All';
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @Catch(() => errorHandler(ConnectionPointsMessage.FETCH_ERROR_MESSAGE))
    async onSearchClick(currentPage, pageSize): Promise<void> {
        this.isSearchClicked = true;
        this.isRefreshed = true;
        this.isActivateButtonDisabled = true;
        dataStore.onGridReady();
        dataStore.setShowUnsavedWarningAlert(false); 
    }

    getColDef() {
        return connectionPointsDataColDef;
    }

    getapiRequest(searchClick, currentPage, pageSize, sortModel?) {
        if(searchClick) {
            const pipelineID = this.selectedBaseSystem === 'All' ? 0 : this.baseSystemData.filter((item) => item.plName === this.selectedBaseSystem)[0].pipelineID;
            const locationID = this.selectedLocation === 'All' ? 0 : this.locationData.filter((item) => item.locnName === this.selectedLocation)[0].locationID;
            const activityTypeID = this.selectedActivityType === 'All' ? 0 : this.activityTypeData.filter((item) => item.valueText === this.selectedActivityType)[0].settingID; 
            const stateID = this.selectedState === 'All' ? 0 : this.tariffStatesData.filter((item) => item.stateName === this.selectedState)[0].stateID;
            const cpStatusID = this.selectedCPStatus === 'All' ? 0 : this.ctsStatusData.filter((item) => item.valueText === this.selectedCPStatus)[0].settingID;
            const cpTypeID = this.selectedCPType === 'All' ? 0 :  this.cpTypeData.filter((item) => item.valueText === this.selectedCPType)[0].settingID;
            const data = {
                pipelineID: pipelineID, 
                locationID: locationID,
                cpName: this.selectedCPName,
                cpCode: this.selectedCPCode,
                cpType: cpTypeID,
                cpStatus: cpStatusID,
                activityTypeID: activityTypeID,
                stateID: stateID,
                page: currentPage ? currentPage : 1,
                pageSize : pageSize ? pageSize : 20,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        } else {
            const data = {
                pipelineID: 0, 
                locationID: 0,
                cpName: '',
                cpCode: '',
                cpType: 0,
                cpStatus: 0,
                activityTypeID: 0,
                stateID: 0,
                page: this.isSearchClicked ?  1 : currentPage,
                pageSize : pageSize ? pageSize : 20,
                sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        }
        
    }

    @action
    reset(): void {
        this.selectedActivityType = undefined;
        this.selectedBaseSystem = undefined;
        this.selectedCCType = undefined;
        this.selectedCPCode = undefined;
        this.selectedCPName = undefined;
        this.selectedCPStatus = undefined;
        this.selectedCPType = undefined;
        this.selectedConnCarrierOperator = undefined;
        this.selectedLocation = undefined;
        this.selectedServiceDate = undefined;
        this.selectedState = undefined;
        this.selectedfercType = undefined;
        this.selectedRelatedBaseSystem = undefined;
        this.selectedRelatedLocation = undefined;
        this.setRelationshipGridData([]);
        this.setRemarksGridData([]);
        this.cpDetailsData = undefined;
        this.workflowId = null;   
        this.navigateToScreen = null;  
        this.navigateState  = null;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setShowLoader(showLoader): void {
        this.showLoader = showLoader;
    }

    @action
    setConnectionPointsData(connectionPointGridData: ConnectionPointsResponse[]): void {
        this.connectionPointGridData = connectionPointGridData
    }

    setbackupConnectionPointsData(backUpList: ConnectionPointsResponse[]) {
        this.backupConnectionPointsData = backUpList;
    }

    @computed
    get ConnectionPointsData() {
        return toJS(this.connectionPointGridData);
    }

    
    @computed
    get RemarksGridData() {
        return toJS(this.remarksGridData);
    }

    @action
    setRemarksGridData(remarksGridData: any[]): void {
        this.remarksGridData = remarksGridData
    }

    @action
    setRelationshipGridData(relationshipGridData: any[]): void {
        this.relationshipGridData = relationshipGridData
    }


    @computed
    get RelationshipGridData() {
        return toJS(this.relationshipGridData);
    }

    addValuesInCellDropdowns(): void {
        this.agGridServiceForRelationShip.updateOptionCellEditorValues(
            this.connectionPointGridData,
            'cpName',
            'cpName'
        );
        this.agGridServiceForRelationShip.updateOptionCellEditorValues(
            this.cpRelTypesDropdownOption,
            'relatedCPType',
            'valueText'
        );
    }

    

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    getUpdatedRowIDsRelationshipGrid(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMapForRelationshipGrid) {
            for (const col in this.cellValueChangeMapForRelationshipGrid[row]) {
                const obj = this.cellValueChangeMapForRelationshipGrid[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRowRemarks(item);
    }

    isSaveDisabled = ()  => {
      
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.ConnectionPointMaintenance);
    };

    @action
    updateRowRemarks = (selectedRowData: any): void => {
        const updatedShopIndex = this.remarksGridData.findIndex(a => a.cpCommentID == selectedRowData.cpCommentID);
        if (!_.isEqual(this.backupRemarksGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumns(this.remarksGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    @action
    updateRowRelationship = (selectedRowData: any): void => {
        const updatedShopIndex = this.relationshipGridData.findIndex(a => a.relConnectionPointID == selectedRowData.relConnectionPointID);
        if (!_.isEqual(this.backupRelationshipGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsForRelationshipGrid(this.relationshipGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.remarks = updatedItem.remarks;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
        currentItem.cpCommentID = updatedItem.cpCommentID;

    }

    mapEditableColumnsForRelationshipGrid(currentItem: any, updatedItem: any) {
        currentItem.cpName = updatedItem.cpName;
        currentItem.effTransCycle = updatedItem.effTransCycle;
        currentItem.relatedCPType = updatedItem.relatedCPType;
        currentItem.carrySplit = updatedItem.carrySplit;
        currentItem.isSelected = updatedItem.isSelected;
        currentItem.plName =updatedItem.plName;
        currentItem.locnName =updatedItem.locnName;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    setValueInChangeMapRelationshipGrid(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMapForRelationshipGrid)) {
            this.cellValueChangeMapForRelationshipGrid[row] = {};
        }
        if (!(col in this.cellValueChangeMapForRelationshipGrid[row] && this.cellValueChangeMapForRelationshipGrid[row][col].initValue))
            this.cellValueChangeMapForRelationshipGrid[row][col] = { initValue: initValue };
        else this.cellValueChangeMapForRelationshipGrid[row][col]['currentValue'] = newValue;
    }

    getUpdatedRows = () => {
        const updatedRowIDs = this.remarksGridData.filter(a => this.getUpdatedRowIDs().includes(a.cpCommentID.toString()));
        return updatedRowIDs;
    }

    @action 
    scrollToFocusTheNewCell() {
        this.agGridService.scrollToSelectedRow();
    }

    @action 
    scrollToFocusTheNewCellRelationship() {
        this.agGridServiceForRelationShip.scrollToSelectedRow();
    }

    @action
    updateValueCodeColumn(data: any, value: string, colName: string): void { 
        const row = data.connectionPointID;
        const initValue = data[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        data.valueCode = value;
        // this.updateRow(data);
    }

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }

    @Loader
    @action
    @Catch(() => errorHandler(ConnectionPointsMessage.FAILED_SUBMIT))
    async updateStatusFromDelToActive(): Promise<void> {
        const selectedRowIDs: any = await this.agGridService.getSelectedRows();
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        selectedRowIDs.forEach((item) => {
           requestBody.push(item.connectionPointID)
        });
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
            const response: ConnectionPointsResponse[] = await this.cpDataService.updateStatusFromDelToActive(requestBody, this.userName);
            // let errorInSave = false;
            // let errorSummary: ToastMessage[] = [];
            if (response) {
                this.isRefreshed = true;
                // this.loadViewModel();  
            }
            this.cellValueChangeMap = {};
            this.uiService.toastService.success(ConnectionPointsMessage.SAVE);
            runInAction(() => {
                this.showLoader = false;
                this.isActivateButtonDisabled= true;
                dataStore.setShowUnsavedWarningAlert(false);
            })  
        }
    }


    validateRequest = () => {
        const localValidatingErrorBody: any = [];
        let isValidRequest = true;
        if(!this.selectedLocation || this.selectedLocation === '') {
            const message = 'Please select a valid Location Name'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(!this.selectedBaseSystem || this.selectedBaseSystem === '') {
            const message = 'Please select a valid Base System'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(!this.selectedActivityType || this.selectedActivityType === '') {
            const message = 'Please select a valid Activity Type'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        if(!this.selectedCPType || this.selectedCPType === '') {
            const message = 'Please select a valid CP Type'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(!this.selectedCPCode || this.selectedCPCode === '') {
            const message = 'Please select a valid CP Code'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        if(!this.selectedCPName || this.selectedCPName === '') {
            const message = 'Please select a valid CP Name'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        if(!this.selectedCPStatus || this.selectedCPStatus === '') {
            const message = 'Please select a valid CP Status'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        // if(!this.selectedState || this.selectedState === '') {
        //     const message = 'Please select a valid State'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // }

        if(!this.selectedServiceDate || this.selectedServiceDate === '') {
            const message = 'Please select a valid Service Date'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        }
        return {
            isValidRequest: isValidRequest, 
            localValidatingErrorBody: localValidatingErrorBody
        };
  
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(ConnectionPointsMessage.FAILED_SUBMIT))
    async saveConnectionPointData(): Promise<void> {
        const updatedRowIDs =  this.relationshipGridData;
        const updatedRowIDsRemarks = this.getUpdatedRows();
        const relationshipDetailDTOs: any = [];
        let requestBody: any = {};
        const remarksArray: any = [];
        const relationshipArray: any = [];
        const errorBody: any = [];
        const { isValidRequest, localValidatingErrorBody} = this.validateRequest();
        if(isValidRequest) {
            const filteredCPData = this.connectionPointGridData.filter((cp) => cp.cpName.toLowerCase().trim() === this.selectedCPName.toLowerCase().trim());
            const existingCP = this.cpDetailsData && this.cpDetailsData.connectionPointID ? filteredCPData.some((cp) => cp.connectionPointID !==   this.cpDetailsData.connectionPointID) : filteredCPData.length > 1;
            const isUniqueCP =  this.cpDetailsData && this.cpDetailsData.connectionPointID ? !existingCP : filteredCPData.length <=0;
            if(isUniqueCP) {
                const pipeline = this.baseSystemData.find((x) => x.plName === this.selectedBaseSystem);
                const location = this.locationData.find((x) => x.locnName === this.selectedLocation);
                const ccType = this.ccTypeData.find((x) => x.valueText === this.selectedCCType);
                const cpType = this.cpTypeData.find((x) => x.valueText === this.selectedCPType);
                const fercType = this.fercTypeData.find((x) => x.valueText === this.selectedfercType);
                const activityType = this.activityTypeData.find((x) => x.valueText === this.selectedActivityType);
                const connCarrier = this.connCarrierOperatorData.find((x) => x.customerName === this.selectedConnCarrierOperator);
                const state = this.tariffStatesData.find((x) => x.stateName === this.selectedState);
                const cpStatus = this.ctsStatusData.find((x) => x.valueText === this.selectedCPStatus);
                if(updatedRowIDs) {
                    updatedRowIDs.forEach((item) => {
                        const effTransCycle = item.effTransCycle ? moment.utc(new Date(item.effTransCycle)).format('YYYY-MM-DDTHH:mm:ss')  : null;
                        const conPntTo = this.connectionPointGridData.find((x) =>  x.cpName === item.cpName);
                        const connectionPointTo =conPntTo ?  conPntTo : item.conPointIDTO ? {connectionPointID : item.conPointIDTO} : null
                        const relType = this.cpRelTypesDropdownOption.find((x) => x.valueText === item.relatedCPType)
                        // item.relType = serviceDate,
                        // item.transscycle = serviceDate
                        // item.split = item.serviceStatus === 'Active' || item.serviceStatus === 'A' ?  'A' : 'I';
                        if(!item.plName) {
                            const message = 'Select related Connection Point Pipeline';
                            if(!errorBody.includes(message)) {
                                errorBody.push(message);
                            }
                        }
                        if(!item.locnName) {
                            const message = 'Select related Connection Point Location';
                            if(!errorBody.includes(message)) {
                                errorBody.push(message);
                            }
                        }
                        if(!connectionPointTo) {
                            const message = 'Select a related Connection Point Name';
                            if(!errorBody.includes(message)) {
                                errorBody.push(message);
                            } 
                        }
                        if(!effTransCycle) {
                            const message = 'Select a valid Effective Trans cycle';
                            if(!errorBody.includes(message)) {
                                errorBody.push(message);
                            } 
                        }
                        if(!relType) {
                            const message = 'Select a valid Relation Type';
                            if(!errorBody.includes(message)) {
                                errorBody.push(message);
                            } 
                        } 
                        relationshipArray.push({
                            cpRelatedID : item.relConnectionPointID && item.relConnectionPointID > 0 ? item.relConnectionPointID : 0,
                            conPntTo: connectionPointTo ? connectionPointTo.connectionPointID : 0,
                            relType : relType ? relType.settingID : 0,
                            transscycle: effTransCycle,
                            split: item.carrySplit ? item.carrySplit : 'N' 
                        })
                      
                    });
                }
                if(updatedRowIDsRemarks) {
                    updatedRowIDsRemarks.forEach((item) => {
                        remarksArray.push({
                            comment: item.remarks,
                            commentID: item.cpCommentID &&  item.cpCommentID > 0 ?   item.cpCommentID : 0
                        })
                        
                    });
                }
            if(errorBody.length < 1){
                requestBody = {
                    createUser: this.userName,
                    createDate: moment().format(AppConstant.API_DATE_FORMAT),
                    updateuser: this.cpDetailsData && this.cpDetailsData.connectionPointID ? this.userName : null,
                    updatedate: this.cpDetailsData && this.cpDetailsData.connectionPointID? moment().format(AppConstant.API_DATE_FORMAT) : null,
                    connectionPointID: this.cpDetailsData ? this.cpDetailsData.connectionPointID : 0,
                    pipelineID: pipeline ?  pipeline.pipelineID : 0,
                    locationID: location ? location.locationID : 0,
                    ccType: ccType ? ccType.settingID: 0,
                    cpType: cpType ? cpType.settingID: 0,
                    cpCode: this.selectedCPCode,
                    cpName: this.selectedCPName,
                    cpActivityType: activityType ? activityType.settingID: 0,
                    stateID: state ? state.stateID : null,
                    fercType: fercType ? fercType.settingID : null,
                    serviceStatus: cpStatus ? cpStatus.settingID : 0,
                    serviceDate: moment(this.selectedServiceDate).format(AppConstant.API_DATE_FORMAT),
                    operatorCustomerID: connCarrier ? connCarrier.customerID : 0,
                    remarks: '',
                    relatedCPSystems: relationshipArray,
                    cpComments: remarksArray,
                    workflowId : this.workflowId ? this.workflowId : 0
                
                }
                const response = await this.cpDataService.saveConnectionPoint(requestBody);
                if (response && response.length > 0 && response[0].connectionPointID) {
                    dataStore.setShowUnsavedWarningAlert(false);
                    // this.onClickLocationNameOnGrid = false; 
                    this.cellValueChangeMap = {};
                    this.cellValueChangeMapForRelationshipGrid = {};
                    this.isFormUpdated = false
                    // this.selectedLocationId = 0;
                    // this.selectedLocationDetails = {};
                    // this.updatedFieldonLocationDetailsScreen = false;
                    this.uiService.toastService.success(ConnectionPointsMessage.SAVE);
                    // this.onCancelCPDetails();
                    this.cpDetailsData =  response[0];
                    this.selectedActivityType = this.cpDetailsData?.activityType;
                    this.selectedBaseSystem = this.cpDetailsData?.plName;
                    this.selectedRelatedBaseSystem = this.cpDetailsData?.plName;
                    this.selectedCPCode = this.cpDetailsData.cpCode;
                    this.selectedCPName = this.cpDetailsData.cpName;
                    this.selectedCPStatus = this.cpDetailsData.status;
                    this.selectedCPType =this.cpDetailsData.cpType;
                    
                    this.selectedLocation = this.cpDetailsData.locnName;
                    this.selectedRelatedLocation = this.cpDetailsData.locnName;
                    this.selectedServiceDate = new Date(this.cpDetailsData.serviceDate);
                    this.selectedState = this.cpDetailsData.state;
                    this.workflowId = this.cpDetailsData.workflowId;
                
                    this.remarksGridData = this.cpDetailsData.comments;
                    this.getCPTariff(this.selectedLocation).then((response) => {
                        this.relationshipGridData = this.cpDetailsData.relations.map((x) => {
                            const cpName = this.connectionPointGridData.find((y) => y.connectionPointID === x.conPointIDTO);
                            const relatedCPType = this.cpRelTypesDropdownOption.find((y) => y.settingID === x.relatedCPType);
                        
                            return (
                                {
                                    ...x,
                                    cpName: cpName ? cpName.cpName : x.cpName ? x.cpName :null,
                                    relatedCPType: relatedCPType ? relatedCPType.valueText : null,
                                    carrySplit: x.carrySplit,
                                    isSelected: false
                                }
                            )
                        });
                    });
                
        
                    const ccType = this.ccTypeData.find((x) => x.settingID === this.cpDetailsData.ccTypeId);
                    const fercType = this.fercTypeData.find((x) => x.settingID === this.cpDetailsData.fercTypeId);
                    const operatorCustomer = this.connCarrierOperatorData.find((x) => x.customerID === this.cpDetailsData.operatorCustomerID);
                    this.selectedCCType = ccType ? ccType.valueText: undefined;
                    this.selectedfercType = fercType ? fercType.valueText: undefined;
                    this.selectedConnCarrierOperator =operatorCustomer ? operatorCustomer.customerName : undefined;
                
                    // this.openLocationDetails({locnName: this.addOrEditedLocnName.trim()})
                    // if(!saveAndAddNew) {
                        this.showLoader = false;
                        
                        // this.onCloseLocationDetails();
                    // } else {
                              
                    // }
    
                } else {
                    errorHandler(response && response.value ? response.value : 'Error while Saving Details');
                }
            } else {
                const messages = '\u2022  ' + errorBody.join('\r\n\n\u2022  ');
                errorHandler(messages);
            }
           
            } else {
                errorHandler('Please enter a unique Connection Point Name');
            }
        } else {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } 
    }

    @action
    onCancelCPDetails() {
        if(this.navigateToScreen) {
            history.push(this.navigateToScreen, {workflowId : this.workflowId, tariffName: this.navigateState?.tariffName, tariffNumber: this.navigateState?.tariffNumber, baseTariff: this.navigateState?.baseTariff, tariffLogId : this.navigateState?.tariffLogId});
            dataStore.setShowUnsavedWarningAlert(false);
        } else {
            history.push('/ConnectionPointMaintenance', { from: '/ConnectionPointDetails' });   
        }
    }

    @action
    linkedCPCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.relConnectionPointID;
        const initValue = params.data.isSelected
        if(checked)
        this.setValueInChangeMap(row, 'isSelected', initValue, checked);
        params.data.isSelected = checked;
        this.updateRowRelationship(params.data);
    };

    @action
    headerCheckBoxSelection = (event) => {
        const checked = event.target.checked;
        const filters =  this.agGridServiceForRelationShip.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.relationshipGridData.filter((item) => ((filters.cpName && filters.cpName.values.includes(item.cpName)) || (filters.relatedCPType && filters.relatedCPType.values.includes(item.relatedCPType)) || (filters.effTransCycle && filters.effTransCycle.values.include(item.effTransCycle)) || (filters.carrySplit && filters.carrySplit.values.includes(item.carrySplit)))) : this.relationshipGridData;
        // const data = filteredData.map((item) => { item.isSelected = checked; return (item)})
        filteredData.forEach((item) => {
            if(checked)
            this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
            item['isSelected'] = checked;
            this.updateRowRelationship(item);
        });   
    };

    @action
    checkIfHeaderCheckboxShouldbeChecked = (params?) => {
        const filters =  this.agGridServiceForRelationShip.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.relationshipGridData.filter((item) => ((filters.cpName && filters.cpName.values.includes(item.cpName)) || (filters.relatedCPType && filters.relatedCPType.values.includes(item.relatedCPType)) || (filters.effTransCycle && filters.effTransCycle.values.include(item.effTransCycle)) || (filters.carrySplit && filters.carrySplit.values.includes(item.carrySplit)))) : this.relationshipGridData;
        return filteredData.some((item) => item.isSelected === false || !item.isSelected)
    }
    
    @Loader
    @action
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const selectedRowData = this.relationshipGridData;
        // if(selectedRowData && selectedRowData.length > 0) {
            const cpId = this.cpDetailsData ?  this.cpDetailsData.connectionPointID.toString() : null;
            const linkedCP= (selectedRowData.filter((item) => item.relConnectionPointID && item.relConnectionPointID !== 0).map(x => x.relConnectionPointID )).join(',');
            const requestBody: any =  {
                workflowid: '0',
                env: env,
                id: cpId
              }
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.cpDataService.pushToVmacs(requestBody);
                if(response) {
                    this.uiService.toastService.success(ConnectionPointsMessage.SYNC);
                    this.cpDetailsData.syncStatus = env === 'Test' ? SyncStatus[1] : SyncStatus[2]
                } else {
                    this.uiService.toastService.error(ConnectionPointsMessage.SYNC_FAILED_SUBMIT);
                }
            }
    
        // }
       
    }

    isDeleteDisabled = ()  => {
          return !this.cpDetailsData  ||  (this.cpDetailsData && !this.cpDetailsData.connectionPointID) || this.isActivateDeactivateDisabled()
    }

    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    @Loader
    @action
    @Catch(() =>  errorHandler(ConnectionPointsMessage.FAILED_SUBMIT))
    async deleteCP(): Promise<void> {
        const requestBody = { 
            connectionPointID: this.cpDetailsData ? this.cpDetailsData.connectionPointID : 0, 
            cpName:this.cpDetailsData.cpName,
            createUser: this.userName
        }
        await this.cpDataService.deactivateCP(requestBody).then((response) => {
            if(response && response.value) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                  () => {console.log('')},
                  ConfirmWithOK,
                  {secondaryText: response.value !== '' ? response.value : 'Record cannot be deleted because it is linked to a base system. Unlink Connection Point from base system.'} as ConfirmWithOKProps,
                  'confirmPopup'
                );
            } else {
                if(response) {
                    this.uiService.toastService.success('Deactivated Successfully');
                    this.onCancelCPDetails();
                }
            }
        });
    }
 
}
