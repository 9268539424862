import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import {
    ColDef
} from 'ag-grid-community';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import { AgGridComponent } from 'common/components/ag-grid-component';
import history from 'shared/components/header-component/component/history';
import { projectRequestDataStore } from '../../stores';


interface workflowDetailsInterface {
    datastore: any;
    stepName: string;
}


export const WorkflowDetails = (props: workflowDetailsInterface) => {

    const gridConfig: ColDef[] = [
    {
          headerName: 'Steps',
          field: 'workflowstepName',
          tooltipField: 'workflowstepName',
          filter: true,
          editable: false,
          sortable: true,
          minWidth: 150,
          singleClickEdit: true,
          flex: 2
      },
      {
          headerName: 'Status',
          field: 'workflowstepStatus',
          tooltipField: 'workflowstepStatus',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          singleClickEdit: true,
          suppressSizeToFit: true,
          flex: 1
      },
      {
          headerName: 'Comment',
          field: 'workflowStepComments',
          tooltipField: 'workflowStepComments',
          cellEditor: 'agSelectCellEditor',
          singleClickEdit: true,
          filter: true,
          sortable: true,
          editable: false,
          minWidth: 135,
          flex: 2
      },
      {
          headerName: 'Workflow Step Role',
          field: 'workflowstepRole',
          tooltipField: 'workflowstepRole',
          singleClickEdit: true,
          editable: false,
          minWidth: 120,
          suppressSizeToFit: false,
      },
      {
          headerName: 'Last Modified User *',
          field: 'lastModifiedUser',
          tooltipField: 'lastModifiedUser',
          singleClickEdit: true,
          editable: false,
          minWidth: 120,
          suppressSizeToFit: false,
      },
      {
          headerName: 'Last Modified Date',
          field: 'lastModifiedDate',
          tooltipField: 'lastModifiedDate',
          filter: true,
          sortable: true,
          editable: false,
          minWidth: 120,
          valueGetter: params => {
              if (params.data['lastModifiedDate'] === null) {
                  return '';
              } else {
                  return momenttimezone
                  .tz(moment.utc(params.data['lastModifiedDate']), AppConstant.CST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
              }
          },
          suppressSizeToFit: false,
      }
  ];

    function getGridConfig(gridId) {
        const {
            datastore: { agGridService }
        } = props;
        const { datastore } = props;
        const rowData = datastore.workflowStatusGridData;
        const gridParams: any = {
        rowData,
        columnDefs: gridConfig,
        context : agGridService
        };
        return agGridService.getGridConfig(gridParams, gridId);
    }

    const showOnlyUS = () => {
        return projectRequestDataStore.accountStore.rolesJson[0]?.defaultLocation==='US'
    }

  return (
        <div>
            <div className='screenHeader'>
                <h2 className='screen_title'> Workflow Details</h2>
            </div>
            <div className='selectContainer'>
                <Row>
                    <div className="rightFlex">
                        <div className="leftFlex">
                            <label className="standard_label_style">{props.datastore.statusOrHistory?'Workflow Status':'Workflow History'}</label>
                        </div>
                        <div className="rightFlexInner">
                            <CustomButton type={CustomButtonType.Submit} disabled={props.datastore.statusOrHistory} onClick={()=>{props.datastore.getWorkflowStatusGridData()}}>
                            Workflow Status
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={!props.datastore.statusOrHistory} onClick={()=>{props.datastore.getWorkflowHistoryStatusGridData()}}>
                            Workflow History
                            </CustomButton>
                        </div>
                    </div>
                </Row>
                <div className="flex">
                <div className="innerflexwidths">
                    <Row>
                        <AgGridComponent gridConfig={getGridConfig('Workflow Details')}/>
                    </Row>
                </div>
                <div className="innerflexwidths">
                    <div className="flexColumn">
                        <div style={{'width':'100%'}}>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '10px'}}>
                                    <label className="standard_label_style">Step Description:</label>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '5px'}}>
                                <label style={{'fontSize': '14px'}}>{props.datastore.stepDescription&&props.datastore.stepDescription||''}</label>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '10px'}} >
                                    <label className="standard_label_style">Comment*:</label>
                                    <textarea
                                    style={{marginLeft: '20px', borderRadius: '5px', border: '1px solid #a3d0e4'}} 
                                    value={props.datastore.formData.comment}
                                    onChange={(e)=>props.datastore.changeFormData(e.target.value, 'comment')}
                                    />
                                </Col>
                            </Row>
                        </div>
                        {showOnlyUS() && <div className="rightFlexButtons">
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{props.datastore.projectRequestSaved? history.push('/ProjectDashboard'): history.push('/ProjectRequest')}}>
                            Cancel
                            </CustomButton>
                            {props.stepName==='step1'?
                            (<CustomButton type={CustomButtonType.Submit} disabled={!props.datastore.projectRequestSaved} onClick={()=>{props.datastore.step1SaveComments(false)}}>
                            Initiate Project &#9654;
                            </CustomButton>):
                            <></>}
                        </div>}
                        {!showOnlyUS() && <div className="rightFlexButtons">
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{history.push('/ProjectRequest')}}>
                            Cancel
                            </CustomButton>
                            {props.stepName==='step1'?
                            (<CustomButton type={CustomButtonType.Submit} disabled={!props.datastore.projectRequestSaved} onClick={()=>{props.datastore.step1SaveComments()}}>
                            Initiate and Submit &#9654;
                            </CustomButton>):
                            <></>}
                        </div>}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}