import {
    ColDef
  } from 'ag-grid-community';

export const colDefDeduction: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellRenderer: 'checkboxRenderer',
      width: 40,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'DedID',
      field: 'tariffDedID',
      editable: false,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Begin',
      field: 'begin',
      editable: false,
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.begin=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    },
    {
      headerName: 'End',
      field: 'end',
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.end=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    },
    {
      headerName: 'Deduction',
      field: 'deduction',
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.deduction=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    }
  ];