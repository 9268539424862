import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ProjectDashboardDataStore } from '../stores/project-dashboard-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/project-dashboard.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { projectDashboardDataStore } from '../stores';
import { projectDashboardColDef } from './project-dashboard-col-def';
import { openRequestColDef } from './open-request-col-def';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface ProjectDashboardContainerProps {
    projectDashboardDataStore: ProjectDashboardDataStore;
}

interface ProjectDashboardContainerState {
    search: string;
    addRowClick: boolean;
    lastAddedAgencyID: any;
}

@inject('projectDashboardDataStore')
@observer
export class ProjectDashboardContainer extends Component<ProjectDashboardContainerProps, ProjectDashboardContainerState> {
    constructor(props: ProjectDashboardContainerProps | Readonly<ProjectDashboardContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            lastAddedAgencyID: 0
        };
        dataStore.setModuleName('Project Dashboard')
    }
    
    componentDidMount() {
      history.push('/ProjectDashboard');
      const {projectDashboardDataStore} = this.props;
      projectDashboardDataStore.init();
      projectDashboardDataStore.agGridService.refreshGrid();
      projectDashboardDataStore.agGridServiceForOpenRequest.refreshGrid();
    }

    componentDidUpdate() {
      const filters = localStorageService.get('Project Dashboard_filter');
      this.props.projectDashboardDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Project Dashboard_' + accountStore.userName);
      this.props.projectDashboardDataStore.agGridService.setColumnVisibility(columnVisible);

      const filtersForOpenRequestGrid = localStorageService.get('Project Dashboard Open Request_filter');
      this.props.projectDashboardDataStore.agGridServiceForOpenRequest.setAllFilters(filtersForOpenRequestGrid);
      const columnStateForOpenRequestGrid = localStorageService.get('Project Dashboard Open Request_' + accountStore.userName);
      this.props.projectDashboardDataStore.agGridServiceForOpenRequest.setColumnVisibility(columnStateForOpenRequestGrid);
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    componentWillUnmount() {
        this.props.projectDashboardDataStore.reset();
    }
    
    onBtClearSelection = () => {
      const { projectDashboardDataStore } = this.props;
      projectDashboardDataStore.clearSearch();
    }

    onSearchClick = () => {
      const { projectDashboardDataStore } = this.props;
      if(projectDashboardDataStore.endDateError === '') {
        projectDashboardDataStore.onSearchClick();
      }
    }
    
    showOnlyUS = () => {
        return accountStore.rolesJson[0]?.defaultLocation==='US'
    }

    render(): React.ReactNode {
      const { projectDashboardDataStore } = this.props;
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className='projectDashboardBox'>
             
                <div className='screenHeader'>
                  <h2 className='screen_title'>Project Dashboard</h2>
                </div>
                <div className='selectContainer'>
                <div className='subHeader'>
                  <h2 className='screen_title'>Open Projects</h2>
                </div>
                <div className='selectSecondContainer'>
                  <AgGridComponent gridConfig={this.getGridConfigForOpenRequest('Project Dashboard Open Request')} />
                </div>
                <div className='subHeader'>
                  <h2 className='screen_title'>Project Search</h2>
                </div>
                  <div className='selectSecondContainer'>
                    <div className='settingSelectionDiv'>
                    <Row>
                          <Col>
                          <label className="standard_label_style">Project Type</label>
                            <ReactSelect
                                  className='select'
                                  values={projectDashboardDataStore.selectedProjectRequestType}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...projectDashboardDataStore.projectRequestTypeDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.onSelectRequestType(e)}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                          </Col>
                          <Col>
                          <label className="standard_label_style">Pipeline Systems</label>
                            <ReactSelect
                                  className='select'
                                  values={projectDashboardDataStore.selectedPipelineSystem}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...projectDashboardDataStore.pipelineSystemsDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.selectedPipelineSystem = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                          </Col>
                          <Col>
                              <label className="standard_label_style">Go Live Date</label>
                              <input 
                                type="date"
                                value={projectDashboardDataStore.selectedGoLiveFromDate}
                                className="goLiveInputs"
                                onChange = {(e) => projectDashboardDataStore.onChangeFromDate(e.target.value)}
                              />
                               To
                              <input 
                                type="date"
                                value={projectDashboardDataStore.selectedGoLiveToDate}
                                className="goLiveInputs toDate"
                                onChange = {(e) => projectDashboardDataStore.onChangeToDate(e.target.value)}
                              />
                            <div className='error'>{projectDashboardDataStore.endDateError} </div>
                          </Col>
                  </Row>
                  </div>
                  <div className='settingSelectionDiv'>
                  <Row>
                          <Col>
                          <label className="standard_label_style">Project Name</label>
                          <input 
                            type="text"
                            value={projectDashboardDataStore.selectedProjectName}
                            className="search_text_field"
                            onChange = {(e) => projectDashboardDataStore.onChangeProjectname(e.target.value)}
                          />
                          </Col>
                          {this.showOnlyUS() && <Col>
                          <label className="standard_label_style">Tariff</label>
                            <ReactSelect
                                  className='select'
                                  values={projectDashboardDataStore.selectedTraiff}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, { value: 'New', label: 'New' }, ...projectDashboardDataStore.tariffDataDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.selectedTraiff = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                          </Col>}
                          <Col>
                            <label className="standard_label_style">Workflow Step</label>
                              <ReactSelect
                                  className={projectDashboardDataStore.selectedProjectRequestType.label !== 'All' ? "select workFlowStepSelect" : 'select workFlowStepSelect disabled'}
                                  values={projectDashboardDataStore.selectedWorkflowStatus}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...projectDashboardDataStore.workflowStepDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.selectedWorkflowStatus = e}}
                                  isMulti={false}
                                  isSearchable={true}
                                  disabled={projectDashboardDataStore.selectedProjectRequestType.label === 'All'}
                              />
                          </Col>
                  </Row>
                  
                    </div>
                    <div className='searchButtonDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                        Search
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                        Clear
                    </CustomButton>
                    </div>
                    {this.props.projectDashboardDataStore.showLoader == true &&
                      <div className = 'alert-overlay'> 
                        <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                      </div> 
                    }
                    <AgGridComponent gridConfig={this.getGridConfig('Project Dashboard')} />
                    <div className='otherHeaderButtonsDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={() => {history.push('/ProjectRequest')}} disabled= {projectDashboardDataStore.isSaveDisabled()}>
                        Initiate Project
                      </CustomButton>
                    </div>
                    <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          projectDashboardDataStore: { ProjectDashboardData, agGridService }
      } = this.props;
      const { projectDashboardDataStore } = this.props;
      const columnDefs =projectDashboardColDef;
      const rowData = ProjectDashboardData;
      const projectDashboardGridParams: any = {
        rowData,
        columnDefs,
      };
      return agGridService.getGridConfig(projectDashboardGridParams, gridId);
    }

    private getGridConfigForOpenRequest(gridId): GridOptions {
      const {
          projectDashboardDataStore: { openRequestGridData, agGridServiceForOpenRequest }
      } = this.props;
      const { projectDashboardDataStore } = this.props;
      const columnDefs =openRequestColDef;
      const rowData = openRequestGridData;
      const projectDashboardGridParams: any = {
        rowData,
        columnDefs,
        context: agGridServiceForOpenRequest
      };
      return agGridServiceForOpenRequest.getGridConfig(projectDashboardGridParams, gridId);
    }
}
