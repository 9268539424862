import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _ from 'lodash';
import { rightAngleXRefColDef } from '../components/right-angle-xref-col-def';
import { RightAngleXRefDataService } from '../services/right-angle-xref-data-service';
import history from '../../../../shared/components/header-component/component/history';
import { AccountStore } from '../../../account/stores/account-store';
import { dataStore } from '../../../../common/stores';
import { RoleBasedActionName } from '../../../../shared/types/enum';
import moment from 'moment';
import { rightAngleXRefDataStore } from '.';

export class RightAngleXRefDataStore {
    @observable selectedTariffNumber;
    @observable selectedTariffNumberForAddEdit;
    @observable pmcRightAngleXrefID = 0;
    @observable rightAngleXRefData: any[] = [];
    @observable tariffNumberDropdownOptions: any[] = [];
    @observable selectedTariffRequestType;
    @observable selectedTariffRequestTypeForAddEdit;
    @observable tariffRequestTypeDropdownOptions: any[] = [];
    @observable selectedRAName;
    @observable selectedRANameForAddEdit;
    @observable raNamesDropdownOptions: any[] = [];
    @observable selectedOperator;
    @observable selectedOperatorForAddEdit;
    @observable operatorDropdownOptions: any[] = [];
    @observable selectedSystemGroup;
    @observable selectedSystemGroupForAddEdit;
    @observable systemGroupDropdownOptions: any[] = [];
    @observable selectedDistributionType;
    @observable selectedDistributionTypeForAddEdit;
    @observable distributionTypeDropdownOptions: any[] = [];
    @observable selectedTariffRateType;
    @observable selectedTariffRateTypeForAddEdit;
    @observable tariffRateTypeDropdownOptions: any[] = [];
    @observable showLoader = false;
    @observable isShowRemoveRAXrefConfirmationModal = false;
    @observable createdUser;
    isRefreshed = false;
    @observable isShowModal = false;
    @observable isEditingRAXref = false;
    @observable editableRAXrefData: any = {};
    
    tariffNumbersData: any[] = [];
    raNamesData: any[] = [];
    systemGroupData: any[]=[];
    distributionTypeData: any[]=[];
    tariffRequestTypeData: any[] = [];
    tariffRateTypeData: any[] = [];

    constructor(
        public rightAngleXRefDataService: RightAngleXRefDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
    ) {
        makeAutoObservable(this);
    }

    async init(): Promise<void> {
        this.createdUser = this.accountStore.displayName;
        this.rightAngleXRefData = [];
        this.selectedTariffNumber = { value: 'All', label: 'All' };
        this.selectedOperator = { value: 'All', label: 'All' };
        this.selectedRAName = { value: 'All', label: 'All' };
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedDistributionType = { value: 'All', label: 'All' };
        this.selectedTariffRequestType = { value: 'All', label: 'All' };
        this.selectedTariffRateType = { value: 'All', label: 'All' };
        await this.getTariffNumberDropdownData();
        await this.getOperatorDropdownData();
        await this.getSystemGroupDropdownData();
        await this.getRANameDropdownData();
        await this.getDistributionTypeDropdownData();
        await this.getTariffRequestTypeDropdownData();
        await this.getTariffRateTypeDropdownData();
        await this.onSearchClick();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffNumberDropdownData(): Promise<void> {
        const tariffNumbers = await this.rightAngleXRefDataService.getPMCTariffDetailIDs();
        this.tariffNumbersData = tariffNumbers;
        this.showLoader = false;
        const a = tariffNumbers.map(x => {return { 'value': x, 'label': x}});
        const sortedTariffNumbers =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffNumberDropdownOptions(sortedTariffNumbers);
    }

    @action
    setTariffNumberDropdownOptions(options){
        this.tariffNumberDropdownOptions = options
    }

    @action
    setTariffNumber(tariffNumber: any): void {
        this.selectedTariffNumber = tariffNumber;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Operator values'))
    async setTariffNumberForAddEdit(tariffNumber: any): Promise<void> {
        this.selectedTariffNumberForAddEdit = tariffNumber;
        const response: any = await this.rightAngleXRefDataService.getPMCRightAngleXrefSupplementaryData(tariffNumber.label);
        if (response) {
            runInAction(() => {
                this.selectedTariffRequestTypeForAddEdit = { label: response.assetGroup, value: response.assetGroup };
                this.selectedSystemGroupForAddEdit = { label: response.pipelineSystem, value: response.pipelineSystem };
                this.selectedOperatorForAddEdit = { label: response.operator, value: response.operator };
                this.selectedDistributionTypeForAddEdit = { label: response.distribution, value: response.distribution };
            })   
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Operator values'))
    async getOperatorDropdownData(): Promise<void> {
        const operators = await this.rightAngleXRefDataService.getOperators();
        this.showLoader = false;
        const a = operators.map(x => {return { 'value': x, 'label': x}});
        const sortedOperators =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setOperatorDropdownOptions(sortedOperators);
    }

    @action
    setOperatorDropdownOptions(options){
        this.operatorDropdownOptions = options
    }

    @action
    setOperatorForAddEdit(operator: any): void {
        this.selectedOperatorForAddEdit = operator;
    }

    @action
    setOperator(operator: any): void {
        this.selectedOperator = operator;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffRequestTypeDropdownData(): Promise<void> {
        const userJurisdiction = this.accountStore.defaultUserLocation;
        const tariffRequestTypes = await this.rightAngleXRefDataService.getTariffRequestTypes(userJurisdiction);
        this.tariffRequestTypeData = tariffRequestTypes;
        this.showLoader = false;
        const a = tariffRequestTypes.map(x => {return { 'value': x.assetGroupID, 'label': x.name}});
        const sortedTariffRequestTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffRequestTypeDropdownOptions(sortedTariffRequestTypes);
    }

    @action
    setTariffRequestTypeDropdownOptions(options){
        this.tariffRequestTypeDropdownOptions = options
    }

    @action
    setTariffRequestType(tariffRequestType: any): void {
        this.selectedTariffRequestType = tariffRequestType;
    }

    @action
    setTariffRequestTypeForAddEdit(tariffRequestType: any): void {
        this.selectedTariffRequestTypeForAddEdit = tariffRequestType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline System'))
    async getSystemGroupDropdownData(): Promise<void> {
        const systemGroups = await this.rightAngleXRefDataService.getSystemGroup();
        this.systemGroupData = systemGroups;
        this.showLoader = false;
        const a = systemGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedSystemGroups =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setSystemGroupDropdownOptions(sortedSystemGroups);
    }

    @action
    setSystemGroupDropdownOptions(options){
        this.systemGroupDropdownOptions = options
    }

    getSelectedSystemGroupID = () =>{
        const systemGroupID = !this.selectedSystemGroup || this.selectedSystemGroup.label == '' || this.selectedSystemGroup.label == 'All' ? '' : this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
        return systemGroupID;
    }

    getSelectedDistributionID = () =>{
        const distributionID = !this.selectedDistributionType || this.selectedDistributionType.label === '' || this.selectedDistributionType.label === 'All' ? '' : this.distributionTypeData.find(x=>x.valueText===this.selectedDistributionType.label)?.settingID;
        return distributionID;
    }

    @action
    setSystemGroup(systemGroup: any): void {
        this.selectedSystemGroup = systemGroup;
    }

    // @action
    setSystemGroupForAddEdit(systemGroup: any): void {
        this.selectedSystemGroupForAddEdit = systemGroup;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getRANameDropdownData(): Promise<void> {
        const raNames = await this.rightAngleXRefDataService.getRANames();
        this.raNamesData = raNames;
        this.showLoader = false;
        const a = raNames.map(x => {return { 'value': x, 'label': x}});
        const sortedRANamess =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setRANameDropdownOptions(sortedRANamess);
    }

    @action
    setRANameDropdownOptions(options){
        this.raNamesDropdownOptions = options
    }

    @action
    setRAName(raName: any): void {
        this.selectedRAName = raName;
    }

    @action
    setRANameForAddEdit(raName: any): void {
        this.selectedRANameForAddEdit = raName;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffRateTypeDropdownData(): Promise<void> {
        const tariffRateTypes = await this.rightAngleXRefDataService.getTariffRateTypes();
        tariffRateTypes.push({'systemName': 'Total','valueCode': 'Total'})
        this.tariffRateTypeData = tariffRateTypes;
        this.showLoader = false;
        const a = tariffRateTypes.map(x => {return { 'value': x.valueCode, 'label': x.systemName}});
        const sortedTariffRateTypes =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setTariffRateTypeDropdownOptions(sortedTariffRateTypes);
    }

    @action
    setTariffRateTypeDropdownOptions(options){
        this.tariffRateTypeDropdownOptions = options
    }

    @action
    setTariffRateType(tariffRateType: any): void {
        this.selectedTariffRateType = tariffRateType;
    }

    @action
    setTariffRateTypeForAddEdit(tariffRateType: any): void {
        this.selectedTariffRateTypeForAddEdit = tariffRateType;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Distribution Types'))
    async getDistributionTypeDropdownData(): Promise<void> {
        const distributionTypes = await this.rightAngleXRefDataService.getDistributionTypes();
        this.distributionTypeData = distributionTypes;
        this.showLoader = false;
        const a = distributionTypes.map(x => {return { 'value': x.settingID, 'label': x.valueText}});
        const sortedDistributionType =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setDistributionTypeDropdownOptions(sortedDistributionType);
    }

    @action
    setDistributionTypeDropdownOptions(options){
        this.distributionTypeDropdownOptions = options
    }

    @action
    setDistributionType(distributionType: any): void {
        this.selectedDistributionType = distributionType;
    }

    setDistributionTypeForAddEdit(distributionType: any): void {
        this.selectedDistributionTypeForAddEdit = distributionType;
    }

    @Loader
    @Catch(() => errorHandler('Data could not be loaded'))
    async onSearchClick(): Promise<void> {
        const tariffNumber = this.selectedTariffNumber && this.selectedTariffNumber.label != 'All' && this.selectedTariffNumber.label != '' ? this.selectedTariffNumber.label : '';
        const systemGroupID = this.getSelectedSystemGroupID();
        const operator = this.selectedOperator && this.selectedOperator.label != '' && this.selectedOperator.label != 'All' ? this.selectedOperator.label : '';
        const distributionID = this.getSelectedDistributionID();
        const assetGroupID = !this.selectedTariffRequestType || this.selectedTariffRequestType.label === '' || this.selectedTariffRequestType.label === 'All' ? '' : this.tariffRequestTypeData.find(x=>x.name===this.selectedTariffRequestType.label)?.assetGroupID;
        const tariffRateType = this.selectedTariffRateType && this.selectedTariffRateType.label != '' && this.selectedTariffRateType.label != 'All' ? this.selectedTariffRateType.label : '';
        const raName = this.selectedRAName && this.selectedRAName.label != '' && this.selectedRAName.label != 'All' ? this.selectedRAName.label : '';
        const response = await this.rightAngleXRefDataService.getRAXrefDetails(tariffNumber, assetGroupID, systemGroupID, operator, distributionID, tariffRateType, raName);
        runInAction(() => {
            this.showLoader = false;
            this.rightAngleXRefData = response;
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    resetSearchFilter() {
        this.rightAngleXRefData = [];
        this.selectedTariffNumber = { value: 'All', label: 'All' };
        this.selectedOperator = { value: 'All', label: 'All' };
        this.selectedRAName = { value: 'All', label: 'All' };
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedDistributionType = { value: 'All', label: 'All' };
        this.selectedTariffRequestType = { value: 'All', label: 'All' };
        this.selectedTariffRateType = { value: 'All', label: 'All' };
    }

    @Loader
    @Catch(() => errorHandler('Data could not be loaded'))
    async onClearSearch(): Promise<void> {
        const response = await this.rightAngleXRefDataService.getRAXrefDetailsWithoutFilter();
        runInAction(() => {
            this.showLoader = false;
            this.resetSearchFilter();
            this.rightAngleXRefData = response;
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
    async onAddNewRAXref() {
        this.isShowModal = true;      
    }

    @action
    validateRAXrefDetails() {
        const localValidatingErrorBody: any = [];
        let isValidSystemFee = true;
        if(this.selectedTariffNumberForAddEdit.label === '' || this.selectedTariffNumberForAddEdit.label === 'Select' || !this.selectedTariffNumberForAddEdit.label){
            const message = 'Please select Tariff Number.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(this.selectedOperatorForAddEdit.label === '' || this.selectedOperatorForAddEdit.label === 'Select' || !this.selectedOperatorForAddEdit.label){
            const message = 'Please select Operator value.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(this.selectedRANameForAddEdit === '' || !this.selectedRANameForAddEdit){
            const message = 'Please select RA Name value.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(this.selectedTariffRequestTypeForAddEdit.label === '' || this.selectedTariffRequestTypeForAddEdit.label === 'Select' || !this.selectedTariffRequestTypeForAddEdit.label){
            const message = 'Please select Asset Group.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(this.selectedDistributionTypeForAddEdit.label === '' || this.selectedDistributionTypeForAddEdit.label === 'Select' || !this.selectedDistributionTypeForAddEdit.label){
            const message = 'Please select Distribution Type.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(this.selectedSystemGroupForAddEdit.label === '' || this.selectedSystemGroupForAddEdit.label === 'Select' || !this.selectedSystemGroupForAddEdit.label){
            const message = 'Please select Pipeline System Name.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(this.selectedTariffRateTypeForAddEdit.label === '' || this.selectedTariffRateTypeForAddEdit.label === 'Select' || !this.selectedTariffRateTypeForAddEdit.label){
            const message = 'Please select Pipeline System Name.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        return {
            isValidSystemFee: isValidSystemFee, 
            localValidatingErrorBody: localValidatingErrorBody
        };
    }

    @action
    setRAXrefValue = (data) => {
        if (data) {
            const systemGroupId = this.systemGroupData.find(x => x.valueText === data.pipelineSystem)?.settingID;
            const distributionId = this.distributionTypeData.find(x=>x.valueText===data.distribution)?.settingID;
            const assetGroupId = this.tariffRequestTypeData.find(x => x.valueText === data.assetGroup)?.settingID;
            this.pmcRightAngleXrefID = data.pmcRightAngleXrefID;
            this.selectedSystemGroupForAddEdit = {label:data.pipelineSystem,value:systemGroupId}
            this.selectedRANameForAddEdit = data.raName;
            this.selectedTariffNumberForAddEdit = {label:data.tariffNumber,value:data.tariffNumber};
            this.selectedOperatorForAddEdit = {label:data.operator,value:data.operator};
            this.selectedTariffRateTypeForAddEdit = {label:data.tariffRateType,value:data.tariffRateType};
            this.selectedTariffRequestTypeForAddEdit = { value: assetGroupId, label: data.assetGroup };
            this.selectedDistributionTypeForAddEdit = { value: distributionId, label: data.distribution };
        } else {
            this.pmcRightAngleXrefID = 0;
            this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedRANameForAddEdit = '';
            this.selectedTariffNumberForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedOperatorForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedTariffRateTypeForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedTariffRequestTypeForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedDistributionTypeForAddEdit = { value: 'Select', label: 'Select' };
        }
    }

    @action
    async handleSaveRAXref() : Promise<void> {
        const {isValidSystemFee, localValidatingErrorBody} = this.validateRAXrefDetails();
        if(isValidSystemFee){
            const reqBody = {
                'pmcRightAngleXrefID': this.pmcRightAngleXrefID,
                'raName': this.selectedRANameForAddEdit,
                'tariffNumber': this.selectedTariffNumberForAddEdit.label.toString(),
                'tariffRateType': this.selectedTariffRateTypeForAddEdit.label,
                'createUser': this.isEditingRAXref ? this.editableRAXrefData.createUser : this.createdUser,
                'createDate': this.isEditingRAXref ? this.editableRAXrefData.createDate : new Date(),
                'updateUser': this.isEditingRAXref ? this.createdUser : null,
                'updateDate': this.isEditingRAXref ? moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
            };
            const response: any[] = await this.rightAngleXRefDataService.savePMCRightAngleXref(reqBody);
            if(response){
                this.uiService.toastService.success('Right Angle Xref details saved successfully');
                this.setRAXrefValue(null);
                this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
                rightAngleXRefDataStore.isShowModal = false;
                this.onSearchClick();
            }else {
                this.uiService.toastService.error('There is an error while saving Right Angle Xref details');
            }
        }else{
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    @action
    async removePMCRightAngleXref() {
        this.isShowRemoveRAXrefConfirmationModal = true;
    }

    @action
    handleCloseRAXrefConfirmationModal() {
        this.isShowRemoveRAXrefConfirmationModal = false;
    }

    @action
    async onRemoveRAXrefConfirmationClicked(): Promise<void> {
        const reqBody = [this.pmcRightAngleXrefID];
        const response: any[] = await this.rightAngleXRefDataService.removePMCRightAngleXref(reqBody);
        if(response){
            this.uiService.toastService.success('Right Angle Xref details deleted successfully');
            this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
            this.isShowRemoveRAXrefConfirmationModal = false;
            rightAngleXRefDataStore.isShowModal = false;
            this.onSearchClick();
        }else {
            this.uiService.toastService.error('There is an error while saving Right Angle Xref details');
        }
    }

    @Loader
    @action
    async onClickEditRAMapping(data) {
        this.editableRAXrefData = data;
        this.setRAXrefValue(data);
        this.isEditingRAXref = true;
        this.isShowModal = true;
    }

    @action
    async onCloseRAXrefModal() {
        this.isShowModal = false;
        this.editableRAXrefData = {};
        this.setRAXrefValue(null);
        this.isEditingRAXref = false;
    }

    getColDef() {
        return rightAngleXRefColDef;
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };
}