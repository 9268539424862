import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './create-base-tariff.scss';
import {
    CellEditingStoppedEvent,
    ColDef 
} from 'ag-grid-community';
import { baseTariffDataStore } from '../stores';
import { BaseTariffDataStore } from '../stores/base-tariff-data-store';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import { BaseTariffResponse } from '../model/model';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { Prompt } from 'react-router-dom';
import { accountStore } from 'features/account/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import history from 'shared/components/header-component/component/history';
import { ReactSelect } from 'common/components/select/select';

/* eslint-disable */
interface CreateBaseTariffContainerProps {
     baseTariffDataStore: BaseTariffDataStore;
}

interface CreateBaseTariffContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
}

@inject('baseTariffDataStore')
@observer
export class BaseTariffContainer extends Component<CreateBaseTariffContainerProps, CreateBaseTariffContainerState> {

    constructor(props: CreateBaseTariffContainerProps | Readonly<CreateBaseTariffContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0
        };
        dataStore.setModuleName('Base Tariff')
    }

    componentDidMount() {
      const {baseTariffDataStore} = this.props;
      if(baseTariffDataStore.accountStore.rolesJson[0]?.defaultLocation==='US'){
        baseTariffDataStore.init();
        window.scroll(0, 0);
      }else{
        history.push('/');
      }
    }
    
    // componentDidUpdate(prevProps: Readonly<CreateBaseTariffContainerProps>, prevState: Readonly<CreateBaseTariffContainerState>, snapshot?: any): void {
    //   baseTariffDataStore.setPipelineArea(baseTariffDataStore.selectedFilingCarrier==="Third Party"?'N/A':baseTariffDataStore.pipelineAreaDropdownOptions.reduce((acc,current, index)=> acc + (index!==0?', ':'') + current.plname, ''));
    // }

    componentDidUpdate() {
      const filters = localStorageService.get('Base Tariff_filter');
      this.props.baseTariffDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Base Tariff_' + accountStore.userName);
      this.props.baseTariffDataStore.agGridService.setColumnVisibility(columnVisible);
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }
    
    componentWillUnmount() {
        this.props.baseTariffDataStore.reset();
    }

    baseTariffGridColDef: ColDef[] = [
      {   
        headerName: 'Select',
        field: 'select', 
        checkboxSelection: true, 
        cellRenderer: 'checkboxRenderer', 
        width: 40, 
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true
      },
      { 
          headerName: 'Joint Carrier', 
          field: 'jointCarrier',
          tooltipField: 'jointCarrier', 
          filter: true, 
          sortable: true,
          editable: () => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.BaseTariff)},
          cellEditor: 'agSelectCellEditor', 
          minWidth: 150,
          cellClass: (params) => {return this.props.baseTariffDataStore.isEditableSettingName(params) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.BaseTariff) ?  'cell-editable' : ''}, 
          cellStyle: (params) => {
                return {border: '1px solid #0091ea'};
          },
          flex: 2
      },
      { 
        headerName: 'Base Tariff', 
        field: 'baseTariff', 
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 150,
        flex: 1
      },
      { 
          headerName: 'Pipeline Area', 
          field: 'pipelineArea',
          tooltipField: 'pipelineArea',
          filter: true, 
          sortable: true,
          editable: false,
          minWidth: 422,
          flex: 3
      },
      { 
        headerName: 'Created By', 
        field: 'createdBy', 
        width: 120,
        filter: true, 
        sortable: true,
        editable: false
      },
      { 
        headerName: 'Created Date', 
        field: 'createdDate', 
        width: 120, 
        filter: true, 
        sortable: true,
        editable: false,
        valueGetter: params => {
              return momenttimezone
              .tz(moment.utc(params.data['createdDate']), AppConstant.CST_TIME_FORMATTER)
              .format(AppConstant.DATE_TIME_FORMAT);
        },
        comparator: (a, b) => {
          const dateA = new Date(a);
          const dateB = new Date(b);
          if(dateA===dateB) return 0;
          else if(dateA>dateB) return 1;
          return -1           
        },
      }
  ];
  

    getColDef() {
      return this.baseTariffGridColDef
    }


    getGridConfig(gridId) {
        const {
            baseTariffDataStore: { baseTariffData, agGridService }
        } = this.props;
        const { baseTariffDataStore } = this.props;
        const rowData = baseTariffData;
        const baseTariffGridParams: any = {
          rowData,
          columnDefs:this.getColDef(),        //   onCellEditingStarted,
          onCellEditingStopped: this.onCellEditingStopped,
          };
        return agGridService.getGridConfig(baseTariffGridParams, gridId);
      }
  
      onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const rowId = event.data.rowId;
        const rowData = event.data;
        const col = event.colDef.field || '';
        const value = event.value;
        // baseTariffDataStore.updateShowUnsavedWarningAlert();
        if(col==="jointCarrier"){
          const filingId = baseTariffDataStore.fillingCarrierDropdownOptions.filter(x=>x.name===value)[0]?.ctsfilingid;
          baseTariffDataStore.getPipelineAreaGrid(rowId, rowData, filingId);
          // console.log(baseTariffDataStore.BaseTariffData)
        }
      };

    onSelectJurisdiction = (e) => {
      const { baseTariffDataStore } = this.props;
      baseTariffDataStore.setJurisdictionName(e.target.value);
      baseTariffDataStore.getTariffAgencyDropdownData();
    }

    onSelectTariffType = (e) => {
      const { baseTariffDataStore } = this.props;
      baseTariffDataStore.setTariffType(e);
    }

    // onSelectProductType = (e) => {
    //   const { baseTariffDataStore } = this.props;
    //   baseTariffDataStore.setProductType(e.target.value);
    // }
    
  
    onSelectRulesAndRegulations = (e) => {
      const { baseTariffDataStore } = this.props;
      baseTariffDataStore.setRulesAndRegulations(e.target.value);
    }
    
    onSelectStatus = (e) => {
      const { baseTariffDataStore } = this.props;
      baseTariffDataStore.setStatus(JSON.parse(e.target.value));
    }

    onSelectTariffAgency = (e) => {
      const { baseTariffDataStore } = this.props;
      baseTariffDataStore.selectedTariffAgency = e;
      if(baseTariffDataStore.selectedFilingCarrier.label!=="Third Party"){
        const filingCarrier = baseTariffDataStore.fillingCarrierDropdownOptions.filter(x=>x.name===baseTariffDataStore.selectedFilingCarrier.label)[0]?.filingCode;
        baseTariffDataStore.setBaseTariff(filingCarrier +  ' ' + baseTariffDataStore.selectedTariffAgency.label + ' ' + baseTariffDataStore.selectedTariff)
      }
      baseTariffDataStore.setBaseTariffGridData(baseTariffDataStore.baseTariffData.map(x => {return {...x, 'baseTariff': baseTariffDataStore.fillingCarrierDropdownOptions.filter(y=>y.name===x.jointCarrier)[0]?.filingCode +  ' JT ' + baseTariffDataStore.selectedTariffAgency.label + ' ' + baseTariffDataStore.selectedTariff}}))
    }


    onSelectTariff = (e) => {
      const { baseTariffDataStore } = this.props;
      baseTariffDataStore.selectedTariff = e.target.value.replace(/[^0-9a-zA-Z]/,"");
      if(baseTariffDataStore.selectedFilingCarrier.label!=="Third Party"){
        const filingCarrier = baseTariffDataStore.fillingCarrierDropdownOptions.filter(x=>x.name===baseTariffDataStore.selectedFilingCarrier.label)[0]?.filingCode;
        baseTariffDataStore.setBaseTariff(filingCarrier +  ' ' + baseTariffDataStore.selectedTariffAgency.label + ' ' + baseTariffDataStore.selectedTariff)
      }
      baseTariffDataStore.setBaseTariffGridData(baseTariffDataStore.baseTariffData.map(x => {return {...x, 'baseTariff': baseTariffDataStore.fillingCarrierDropdownOptions.filter(y=>y.name===x.jointCarrier)[0]?.filingCode +  ' JT ' + baseTariffDataStore.selectedTariffAgency.label + ' ' + baseTariffDataStore.selectedTariff}}))
    }

    onSelectFilingCarrier = (e) => {
      const { baseTariffDataStore } = this.props;
      baseTariffDataStore.selectedFilingCarrier = e;
      if(e.label==="Third Party"){
        baseTariffDataStore.setBaseTariff("N/A");
        baseTariffDataStore.setPipelineArea("N/A");  
      }else{ 
        const filingCarrier = baseTariffDataStore.fillingCarrierDropdownOptions.filter(x=>x.name===baseTariffDataStore.selectedFilingCarrier.label)[0].filingCode;
        const filingId = baseTariffDataStore.fillingCarrierDropdownOptions.filter(x=>x.name===baseTariffDataStore.selectedFilingCarrier.label)[0].ctsfilingid;
        baseTariffDataStore.setBaseTariff(filingCarrier +  ' ' + baseTariffDataStore.selectedTariffAgency.label + ' ' + baseTariffDataStore.selectedTariff)
        // baseTariffDataStore.getPipelineAreaDropdownData(filingId);
        baseTariffDataStore.setBaseTariffGridData(baseTariffDataStore.baseTariffData.filter((x)=>x.jointCarrier!==e.label))
      }
    }
      
    onAddRow = () => {
      const rowData = [...this.props.baseTariffDataStore.BaseTariffData,{
        rowId: this.state.rowId,
        jointCarrier: '',
        baseTariff: null,
        pipelineArea: null,
        remarks: null,
        createdBy: this.props.baseTariffDataStore.createdUser,
        createdDate: new Date(),
      } as unknown as BaseTariffResponse];      
      this.props.baseTariffDataStore.setBaseTariffGridData(rowData);
      this.props.baseTariffDataStore.addValuesInCellDropdowns();
      this.setState({...this.state, rowId: this.state.rowId+1})
  }

  deleteItem = async () => {
    const { baseTariffDataStore } = this.props;
    const requestBody: any = await baseTariffDataStore.agGridService.getSelectedRows();
    console.log(requestBody);
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => this.filterRows(requestBody),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  filterRows = (requestBody) => {
    baseTariffDataStore.setBaseTariffGridData(baseTariffDataStore.BaseTariffData.filter(
      x=>requestBody.every(y=>x.rowId!==y.rowId)))
  }  

  saveBaseTariff = () => {
    baseTariffDataStore.saveTariffBase();
  }

    render(): React.ReactNode {
        const { baseTariffDataStore } = this.props;
        return (
          <div className='CreateBaseTariffBox'>
            <Container fluid>
                    <div className='screenHeader'>
                      <h2 className='screen_title'> Create Base Tariff</h2>
                    </div>
                  {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
                    <div className='selectContainer'>
                    <Row>
                    <Col style={{'marginTop':'10px'}}>   
                        {/* <div className='settingSelectionDiv'> */}
                        <label className="standard_label_style">PAA  Jurisdiction</label>
                        <input className="select" value={'USA'} disabled={true}/>
                    </Col>
                    <Col style={{'marginTop':'10px'}}>
                    <label className="standard_label_style">Tariff Type</label>
                    <ReactSelect
                        className='select'
                        values={baseTariffDataStore.selectedTariffType}
                        placeholder="Select"
                        options={baseTariffDataStore.tariffTypesDropdownOptions}
                        onChange={(e: any) => {this.onSelectTariffType(e)}}
                        isMulti={false}
                        isSearchable={true}
                        disabled={baseTariffDataStore.isEditDisabled()}
                    />
                    </Col>
            <Col style={{'marginTop':'10px'}}> 
                <label className="standard_label_style">Status</label>
                <div className="status-block">
                    <input 
                      type="radio"
                      name="status"
                      value={"true"}
                      checked={baseTariffDataStore.selectedStatus===true}
                      onChange = {(e) => this.onSelectStatus(e)}
                      disabled={baseTariffDataStore.isEditDisabled()}
                    />Active
                    <input 
                      type="radio"
                      name="status"
                      value={"false"}
                      checked={!baseTariffDataStore.selectedStatus===true}
                      onChange = {(e) => this.onSelectStatus(e)}
                      disabled={baseTariffDataStore.isEditDisabled()}
                    />Inactive
                </div>
            </Col>   
            <Col></Col>
            </Row> 
            <Row>
            <Col style={{'marginTop':'10px'}}> 
               <label className="standard_label_style">Tariff Agency</label>
                  <ReactSelect
                        className='select'
                        values={baseTariffDataStore.selectedTariffAgency}
                        placeholder="Select"
                        options={baseTariffDataStore.tariffAgencyDropdownOptions}
                        onChange={(e: any) => {this.onSelectTariffAgency(e)}}
                        isMulti={false}
                        isSearchable={true}
                        disabled={baseTariffDataStore.isEditDisabled()}
                    />
            </Col>
            <Col style={{'marginTop':'10px'}}>
            <label className="standard_label_style">Rules & Regulations</label>
                    <input 
                      type="text" 
                      className="search_text_field input"
                      onChange = {(e) => this.onSelectRulesAndRegulations(e)}
                      readOnly={baseTariffDataStore.isEditDisabled()}
                    />
            </Col>
            <Col style={{'marginTop':'10px'}}>
            <label className="standard_label_style">Tariff # *</label>
                    <input 
                      type="text" 
                      value={baseTariffDataStore.selectedTariff}
                      className="search_text_field input"
                      onChange = {(e) => this.onSelectTariff(e)}
                      readOnly={baseTariffDataStore.isEditDisabled()}
                    />
                                
            </Col><Col></Col>
            </Row>
            <Row>
            <Col>
            <label className="standard_label_style">Filing Carrier 1</label>
                    <ReactSelect
                        className='select'
                        values={baseTariffDataStore.selectedFilingCarrier}
                        placeholder="Select"
                        options={[...baseTariffDataStore.fillingCarrierDropdownOptionsUI, {'value': 'Third Party', 'label':'Third Party'}]}
                        onChange={(e: any) => {this.onSelectFilingCarrier(e)}}
                        isMulti={false}
                        isSearchable={true}
                        disabled={baseTariffDataStore.isEditDisabled()}
                    />
            </Col>   
            <Col>
            <label className="standard_label_style">Base Tariff</label>
                    <input 
                      type="text" 
                      value={baseTariffDataStore.selectedBaseTariff}
                      className="search_text_field input"
                      disabled={baseTariffDataStore.selectedFilingCarrier.label==="Third Party" || baseTariffDataStore.isEditDisabled()}
                      readOnly={true}
                      />                            
            </Col>
            <div className='colPipelineArea'> 
            <label className="standard_label_style" style={{'verticalAlign': 'top'}}>Pipeline Area</label>
                    <textarea 
                      value={baseTariffDataStore.selectedPipelineArea}
                      className="search_text_field textAreaPipelineArea"
                      disabled={baseTariffDataStore.selectedFilingCarrier.label==="Third Party" || baseTariffDataStore.isEditDisabled()}
                      readOnly={true}
                    />
            </div><Col></Col>
            </Row>
            </div>
            <div className='searchButtonDiv'>
                <div className='otherHeaderButtonsDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={()=>this.onAddRow()} disabled={baseTariffDataStore.isEditDisabled()}>
                      Add Joint Carrier
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={()=>this.deleteItem()} disabled={baseTariffDataStore.isEditDisabled()}>
                      Delete Joint Carrier
                    </CustomButton>
                    </div>
            </div>
            <Row>
                <AgGridComponent gridConfig={this.getGridConfig('Base Tariff')}/>
            </Row>
            <div className='selectContainer margin20'>
            <Row>
            <div className='colComments'>
            <label className="standard_label_style">Comments</label>
                    <input type="text" 
                      value={baseTariffDataStore.selectedComments}
                      className="search_text_field textComments"
                      onChange={(e)=>baseTariffDataStore.setComments(e.target.value)}
                      readOnly={baseTariffDataStore.isEditDisabled()}
                    />
            </div>
            </Row>
            <Row>
            <Col>
            <label className="standard_label_style">Created By</label>
                    <input type="text" 
                      value={baseTariffDataStore.createdUser}
                      className="search_text_field"
                      readOnly={true}
                    />
            </Col>
            <Col>
            <label className="standard_label_style">Created Date</label>
                    <input type="text" 
                      value={baseTariffDataStore.createdDate}
                      className="search_text_field"
                      readOnly={true}
                    />
            </Col>
            </Row>
            <Row style={{'marginTop':'25px'}}>
                <div className="rightFlexBottom">
                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.saveBaseTariff()}} disabled= {baseTariffDataStore.selectedTariff==="" || baseTariffDataStore.isEditDisabled()} >
                      Save Base Tariff
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{history.push(baseTariffDataStore.state.from==='step2'?'/project-request-step-2':'/', {workflowId:baseTariffDataStore.state?.workflowId})}} disabled= {false}>
                      Cancel
                    </CustomButton>
                </div>
            </Row>
            </div>
        </Container>
        </div>
    );
}}
