import { restApiService } from '../../../shared/services/rest-api-service';
import { RestApi, AuthToken } from '../../../shared/types/shared';
import { LocalStorageService, localStorageService } from '../../../shared/services/local-storage-service';
import { DataService } from './data-service';
import { AppConstant } from '../../../app_constants';
import { AuthenticationService, authenticationService } from 'shared/services/authentication-service';
import { uiService } from 'shared/services/ui-service';
import { InteractionRequiredAuthError } from 'msal';

export class AccountDataService implements DataService {
    constructor(
        private restApi: RestApi,
        private localStorageService: LocalStorageService,
        private authenticationService: AuthenticationService
    ) { }

    async login(): Promise<AuthToken | any> {
        try {
            const token = await this.authenticationService.login();
            console.log('authentication Service ' + JSON.stringify(token))
            if (token && token.roles.length > 0) {
                this.localStorageService.set(AppConstant.AUTHTOKEN, token);
                this.localStorageService.set(AppConstant.LOGINTIME, new Date());
                return token;
            } else {
                uiService.toastService.error(AppConstant.UNAUTHORIZED_USER);
                setTimeout(() => {
                    return this.logout();
                },3000);
                return Promise.reject();
            }

        } catch (e) {
            if (e instanceof InteractionRequiredAuthError) {
                console.log('authService error ' + JSON.stringify(e))
                uiService.toastService.error(AppConstant.UNAUTHORIZED_USER);
            }
            return Promise.reject();
        }
    }

    logout(): void {
        this.authenticationService.logout();
    }
}

export const accountDataService = new AccountDataService(restApiService, localStorageService, authenticationService);
