import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import './_data-grid.scss';
import { dataStore } from '../../stores';
import { PageSizeComponent } from '../page-size-component';
import { agGridService } from 'common/services/ag-grid-service';
interface DataGridProps {
    gridOption: GridOptions;
}

export class DataGrid extends Component<DataGridProps> {
    /* fix height issue */
    render(): React.ReactNode {
        const { gridOption } = this.props;

        const statusBar = {
            statusPanels:  [
                // {
                //   statusPanel: 'agTotalAndFilteredRowCountComponent',
                //   align: 'left',
                // },
                // {
                //   statusPanel: 'agTotalRowCountComponent',
                //   align: 'center',
                // },
                { statusPanel: 'agFilteredRowCountComponent', align: 'center' },
                { statusPanel: 'agSelectedRowCountComponent', align: 'center' },
                { statusPanel: 'agAggregationComponent', align: 'center'},
                { statusPanel: PageSizeComponent, align: 'right', statusPanelParams : {agGridService : gridOption.context ?  gridOption.context : agGridService}},
              ],
        };
        
        const options = { ...gridOption, onGridReady: this.onGridReady,
            rowMultiSelectWithClick: true, statusBar:statusBar };
        const screen = dataStore.getCurrentModuleName();
        return (
            <div className="ag-theme-balham data-grid" id= {screen}>
                <AgGridReact groupDefaultExpanded={-1} stopEditingWhenCellsLoseFocus={true}  rowSelection={'multiple'} {...options} />
            </div>
        );
    }

    onGridReady = (event: GridReadyEvent): void => {
        if (this.props.gridOption.onGridReady) {
            this.props.gridOption.onGridReady(event);
        }
    };
}
