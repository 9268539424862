import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class ProjectDashboardDataService {
constructor(private restApi: RestApiService) {}

    async getProjectDashboardData(projectRequestTypeId = 0, pipelineId = 0, goLiveFromDate = '', goLiveToDate = '', tariffId = 0, workflowStatus = 'All', projectName = '', defaultLocation ): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.getProjectRequestDashboardData}?projectRequestTypeId=${projectRequestTypeId}&pipelineId=${pipelineId}&goLiveDateStart=${goLiveFromDate}&goLiveDateEnd=${goLiveToDate}&projectName=${projectName}&tariffId=${tariffId}&workflowStatus=${workflowStatus}&jurisdiction=${defaultLocation}`);
        return data;
    }

    async getWorkflowOpenProjectsRequests(defaultLocation): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.getWorkflowOpenProjectsRequests}?jurisdiction=${defaultLocation}`);
        return data;
    }

    async getProjectRequestType(defaultLocation): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.getTariffRequestTypes}?Jurisdiction=${defaultLocation}`);
        return data;
    }

    async getPipelineSystem(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_1_getPipelines);
        return data;
    }

    async getPMCTariffRateTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id28);
        return data;
    }

    async getCANSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getTariffData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id15);
        return data;
    }

    async getWorkflowStatus(defaultLocation): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.getWorkflowStatus}?location=${defaultLocation}`);
        return data;
    }

}

export const projectDashboardDataService = new ProjectDashboardDataService(restApiService);