import { action, observable, makeAutoObservable, toJS, computed, runInAction } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { ProjectRequestDataService } from '../services/project-request-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import moment from 'moment';
import { EMAIL_RECIPIENTS, CAN_EMAIL_RECIPIENTS } from '../../../../Path';
import _ from 'lodash';
import { projectRequestDataStore, projectRequestStep2DataStore } from '.';
import { pmcTariffDataStore } from 'features/tariff-management/pmc-tariff/stores';
import { step1TariffColDef } from '../components/step-1-col-def';
import { dataStore } from 'common/stores';
import { WFAttachmentResponse, WFNotesResponse } from '../model/model';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { accountStore } from 'features/account/stores';
import { AppConstant } from 'app_constants';

export class ProjectRequestStep1DataStore {
  
    @observable pipelinesDropdown: any [] = [];
    @observable baseTariffDropdown: any[] = [];
    @observable selectedTariffs: any = [];
    @observable selectedProjectRequestType;
    @observable selectedProjectRequestTypeID;
    @observable workflowId;
    @observable workflowStepId;
    @observable username;
    @observable location;
    @observable showLoader = false;
    @observable workflowDetails;
    @observable workflowStepFlowId;
    @observable workflowStatusGridData: any[] = [];
    @observable selectedTariffRateType = '';
    @observable selectedTariffRateTypeID;
    @observable stepDescription = '';
    @observable backupDescription = '';
    @observable statusOrHistory = true;
    @observable projectRequestSaved = false;
    @observable showTariffReviw = false;
    @observable workflowStepStatus = '';
    @observable selectedSystemGroup;
    @observable systemGroupDropdownOptions: any[] = [];
    @observable selectedPipelineGroup;
    @observable selectedEffectiveStartDate = new Date(moment(new Date).startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
    @observable selectedEffectiveEndDate = new Date(moment(new Date).endOf('month').format('YYYY-MM-DDTHH:mm:ss'));;
    @observable pipelineGroupDropdownOptions: any[] = [];
    @observable pmcGridViewData: any[] = [];
    systemGroupData: any[]=[];
    pipelineGroupData: any[] = [];
    @observable isTariffNoteSaved = false;
    @observable isTariffNoteAdded = false;
    @observable cellValueChangeMap: any = {};
    @observable cellValueChangeMapAttachment: any = {};
    @observable reviewTariffDetails: any[] = [];

    @observable fileUploadGridData: WFAttachmentResponse[] = [];
    backupAttachmentGridData: WFAttachmentResponse[] = [];
    @observable notesGridData: WFNotesResponse[] = [];
    backupNotesGridData: WFNotesResponse[] = [];    
    assetGroupData: any[]=[];
    unitData: any[]=[];
    currencyData: any[]=[];
    shrinkageData: any[]=[];
    lossAllowanceData: any[]=[];
    regulatorData: any[]=[];

    @observable formData = {
        pipelineSystems: [],
        description: '',
        goLive: '' as unknown as Date,
        comment: '',
        projectName: '',
        tracking: false,
        dor: false,
        selectedBaseTariff: 'New'
    }
    constructor(
        public projectRequestDataService: ProjectRequestDataService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridService: AgGridService,
        public agGridServiceForAddAttachment: AgGridService
    ) {
        makeAutoObservable(this);
    }

    monthNames = [{ 'label': 'Jan', 'value': 'January' }, { 'label': 'Feb', 'value': 'February' }, { 'label': 'Mar', 'value': 'March' }, { 'label': 'Apr', 'value': 'April' }, { 'label': 'May', 'value': 'May' }, { 'label': 'Jun', 'value': 'June' }, { 'label': 'Jul', 'value': 'July' }, { 'label': 'Aug', 'value': 'August' }, { 'label': 'Sep', 'value': 'September' }, { 'label': 'Oct', 'value': 'October' }, { 'label': 'Nov', 'value': 'November' }, { 'label': 'Dec', 'value': 'December' }];

    @action
    async init(): Promise<void> {
        const state=history?.location?.state as {workflowId?: string; workflowStepId?: string, saved?: boolean, selectedProjectRequestType?: string } || '';
        this.workflowId= state?.workflowId || this.workflowId;
        // this.workflowStepId=state.workflowStepId;
        this.username = this.accountStore.displayName;
        this.selectedProjectRequestType = state?.selectedProjectRequestType || this.selectedProjectRequestType
        this.pmcGridViewData = [];
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedPipelineGroup = { value: 'All', label: 'All' };
        this.selectedTariffs = [];
        this.showTariffReviw = false;
        if(!state.saved){
            this.formData = {
                pipelineSystems: [],
                description: '',
                goLive: '' as unknown as Date,
                comment: '',
                projectName: '',
                tracking: false,
                dor: false,
                selectedBaseTariff: 'New'
            }
            this.projectRequestSaved=false;
            this.uiService.toastService.success('Workflow Initiated!');
        }
        await this.getPipelinesDropDownData();
        await this.getTariffDropDownData();
        await this.getWorkflowDetails();
        if (this.accountStore.rolesJson[0]?.defaultLocation !== 'US') {
            if (projectRequestDataStore.isFromWorkflowDashboard) {
                this.projectRequestSaved = true;
            }
            await this.getAssetGroupDropdownData();
            await this.getPipelinesDropDownData();
            await this.getTariffDropDownData();
            await this.getWorkflowDetails();
            await this.getPipelineGroupDropdownData();
            await this.getWorkflowNotesByWFID();
            await this.getWorkflowDocumentByWFID();
            await this.getUnitDropdownData();
            await this.getCurrencyDropdownData();
            await this.getRegulatorDropdownData();
            await this.getShrinkageDropdownData();
            await this.getLossAllowanceDropdownData();
            await this.getSystemGroupDropdownData();
            await this.onSearchClick();
            await this.getPMCWorkflowSelectedTariffDetails();
        }
        this.getWorkflowStatusGridData();
    }

    isUSJurisdiction = () => {
        return this.accountStore.rolesJson[0]?.defaultLocation==='US'
    }
     
    @action
    changeFormData(value, key) {
        this.formData= {...this.formData, [key]: value}
        console.log(this.formData);
    }

    @action
    setPipeline(pipelines: any[]): void {
        this.changeFormData(pipelines,'pipelineSystems')
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelinesDropDownData(): Promise<void> {
        const response = await this.projectRequestDataService.getPipelines();
        if(Array.isArray(response)){
            this.pipelinesDropdown = response;
        }  
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffDropDownData(): Promise<void> {
        const response = await this.projectRequestDataService.getBaseTariffs();
        if(Array.isArray(response)){
            this.baseTariffDropdown = response;
        }  
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getPMCWorkflowSelectedTariffDetails(): Promise<void> {
        const response: any = await this.projectRequestDataService.getPMCWorkflowSelectedTariffDetails(this.workflowId);
        runInAction(async () => {
            if (response.pmcTariffDetailID.length > 0 && this.pmcGridViewData.length > 0) {
                const selectedTariffs: any[] = [];
                response.pmcTariffDetailID.map(selectedTariffId => {
                const tariff: any = this.pmcGridViewData?.filter(item => item.pmcTariffDetailID == selectedTariffId)
                tariff[0].isSelected = true;
                selectedTariffs.push(tariff[0]);
                })
                this.selectedTariffs = selectedTariffs;
            }
        })
    }
  
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getWorkflowDetails(): Promise<void> {
        const response = await this.projectRequestDataService.getWorkflowDetails(this.workflowId);
        this.formData = response && {
            pipelineSystems: response.pipelineId===''||response.pipelineId===null?null:response.pipelineId.split(',').map(x=>this.pipelinesDropdown.find(y=>String(y.pipelineID)===String(x))?.plName)||null,
            description: response.description,
            goLive: new Date(response.goLiveDate) as unknown as Date,
            comment: '',
            tracking: response.tracking, 
            dor: response.dor,
            projectName: response.name,
            selectedBaseTariff: this.baseTariffDropdown?.find(x=>response.baseTariffID===x.tariffBaseID)?.baseTariff || 'New'
        }||this.formData;
        this.backupDescription=this.formData.description;
        this.workflowDetails=response;
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getWorkflowStatusGridData(): Promise<void> {
        const response = await this.projectRequestDataService.getWorkflowStatusGridData(this.workflowId);
        this.workflowStatusGridData=response;
        this.workflowStepFlowId=response.find(x=>x.workflowstepStatus==='In Progress').workflowStepFlowId;
        this.workflowStepId = response.find(x=>x.workflowstepStatus==='In Progress').workflowstepId;
        this.statusOrHistory=true;
        this.stepDescription=response.find(x=>x.workflowstepStatus==='In Progress')?.workflowstepDescription||this.stepDescription
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getWorkflowHistoryStatusGridData(): Promise<void> {
        const response = await this.projectRequestDataService.getWorkflowHistoryStatusGridData(this.workflowId);
        this.workflowStatusGridData=response;
        this.statusOrHistory=false;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAssetGroupDropdownData(): Promise<void> {
        const userJurisdiction = projectRequestDataStore.isFromWorkflowDashboard ? projectRequestDataStore.workflowCreatorJurisdiction : this.accountStore.defaultUserLocation;
        const assetGroups = await this.projectRequestDataService.getAssetGroups(userJurisdiction);
        this.assetGroupData = assetGroups;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline System'))
    async getSystemGroupDropdownData(): Promise<void> {
        let systemGroups: any[] = [];
        const assetGroupID = this.selectedProjectRequestTypeID ? this.selectedProjectRequestTypeID : projectRequestDataStore.projectRequestTypeDropdownOptions.filter(x=>x.name===projectRequestDataStore.selectedProjectRequestType)[0]?.assetGroupID;
        systemGroups = await this.projectRequestDataService.getPMCPipelineSystems(assetGroupID);
        this.systemGroupData = systemGroups;
        this.showLoader = false;
        const a = (systemGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedSystemGroups =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setSystemGroupDropdownOptions(sortedSystemGroups);
    }

    @action
    setSystemGroupDropdownOptions(options){
        this.systemGroupDropdownOptions = options
    }

    getSelectedSystemGroupID = () =>{
        const systemGroupID = !this.selectedSystemGroup.label || this.selectedSystemGroup.label == '' || this.selectedSystemGroup.label == 'All' || this.selectedSystemGroup.label == ' ' ? 0 : this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
        return systemGroupID;
    }

    getSelectedPipelineID = () =>{
        const pipelineID = !this.selectedPipelineGroup.label || this.selectedPipelineGroup.label === '' || this.selectedPipelineGroup.label === 'All' || this.selectedPipelineGroup.label == ' ' ? 0 : this.pipelineGroupData.find(x=>x.valueText===this.selectedPipelineGroup.label)?.settingID;
        return pipelineID;
    }

    @action
    setSystemGroup(systemGroup: any): void {
        this.selectedSystemGroup = systemGroup;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline Region'))
    async getPipelineGroupDropdownData(): Promise<void> {
        const pipelineGroups = await this.projectRequestDataService.getPipelineGroup();
        this.pipelineGroupData = pipelineGroups;
        this.showLoader = false;
        const a = (pipelineGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedPipelines =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setPipelineGroupDropdownOptions(sortedPipelines);
    }

    @action
    setPipelineGroupDropdownOptions(options){
        this.pipelineGroupDropdownOptions = options
    }

    @action
    setPipelineGroup(pipelineGroup: any): void {
        this.selectedPipelineGroup = pipelineGroup;
    }

    @action
    setEffectiveStartDate(effectiveStartDate: Date): void {
        this.selectedEffectiveStartDate = effectiveStartDate;
    }

    @action
    setEffectiveEndDate(effectiveEndDate: Date): void {
        const endDate = effectiveEndDate ? new Date(moment(effectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss')) : new Date(moment('9999-12-31').format(AppConstant.DATE_TIME_FORMAT));
        this.selectedEffectiveEndDate = endDate;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching UOM values'))
    async getUnitDropdownData(): Promise<void> {
        const units = await this.projectRequestDataService.getUnits();
        this.unitData = units;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Currency values'))
    async getCurrencyDropdownData(): Promise<void> {
        const currencies = await this.projectRequestDataService.getCurrency();
        this.currencyData = currencies;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Regulator values'))
    async getRegulatorDropdownData(): Promise<void> {
        const regulatores = await this.projectRequestDataService.getRegulator();
        this.regulatorData = regulatores;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Shrinkage values'))
    async getShrinkageDropdownData(): Promise<void> {
        const shrinkageValues = await this.projectRequestDataService.getPMCShrinkage();
        this.shrinkageData = shrinkageValues;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Loss Allowance values'))
    async getLossAllowanceDropdownData(): Promise<void> {
        const lossAllowanceValues = await this.projectRequestDataService.getPMCLossAllwance();
        this.lossAllowanceData = lossAllowanceValues;
    }

    @action
   setPmcGridViewData(pmcGridViewData: any): void {
       this.pmcGridViewData = pmcGridViewData
   }

   @computed
    get PmcGridViewData() {
        return toJS(this.pmcGridViewData);
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.pmcGridViewData.findIndex(a => a.tariffNumber == selectedRowData.tariffNumber);
        this.pmcGridViewData[updatedShopIndex].isSelected = selectedRowData.isSelected;
        const selectedTariffs = this.pmcGridViewData.filter(tariff => tariff.isSelected);
        this.selectedTariffs = selectedTariffs;
        dataStore.setShowUnsavedWarningAlert(true);
    };

    @action
    tariffCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        params.data.isSelected = checked;
        this.updateRowforAddOrUpdateGrid(params.data);
    };

    @action
    headerCheckBoxSelection = (event) => {
        const checked = event.target.checked;
        const filters =  this.agGridService.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.pmcGridViewData.filter((item) => ((filters.tariffNumber && filters.tariffNumber.values.includes(item.tariffNumber)) || (filters.assetGroup && filters.assetGroup.values.includes(item.assetGroup)) || (filters.pmcSystemGroup && filters.pmcSystemGroup.values.include(item.pmcSystemGroup)) || (filters.pmcPipelineGroup && filters.pmcPipelineGroup.values.includes(item.pmcPipelineGroup)) || (filters.operator && filters.operator.values.includes(item.operator))
            || (filters.origin && filters.origin.values.includes(item.origin)) || (filters.unit && filters.unit.values.includes(item.unit)) || (filters.currency && filters.currency.values.includes(item.currency)) || (filters.regulator && filters.regulator.values.includes(item.regulator)) || (filters.destination && filters.destination.values.includes(item.destination)) || (filters.tariffApplication && filters.tariffApplication.values.includes(item.tariffApplication)) || (filters.distribution && filters.distribution.values.includes(item.distribution)) || (filters.product && filters.product.values.includes(item.product))
            || (filters.lossAllowance && filters.lossAllowance.values.includes(item.lossAllowance)) || (filters.shrinkage && filters.shrinkage.values.includes(item.shrinkage)) || (filters.createUser && filters.createUser.values.includes(item.createUser)) || (filters.updateUser && filters.updateUser.values.includes(item.updateUser)))) : this.pmcGridViewData;
        if(filteredData.length > 0)
        filteredData.forEach((item) => {
            item['isSelected'] = checked;
            this.updateRowforAddOrUpdateGrid(item);
        });   
    };

    @action
    checkIfHeaderCheckboxShouldbeChecked = (params) => {
        const filters =  this.agGridService.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.pmcGridViewData.filter((item) => ((filters.tariffNumber && filters.tariffNumber.values.includes(item.tariffNumber)) || (filters.assetGroup && filters.assetGroup.values.includes(item.assetGroup)) || (filters.pmcSystemGroup && filters.pmcSystemGroup.values.include(item.pmcSystemGroup)) || (filters.pmcPipelineGroup && filters.pmcPipelineGroup.values.includes(item.pmcPipelineGroup)) || (filters.operator && filters.operator.values.includes(item.operator))
            || (filters.origin && filters.origin.values.includes(item.origin)) || (filters.unit && filters.unit.values.includes(item.unit)) || (filters.currency && filters.currency.values.includes(item.currency)) || (filters.destination && filters.destination.values.includes(item.destination)) || (filters.tariffApplication && filters.tariffApplication.values.includes(item.tariffApplication)) || (filters.distribution && filters.distribution.values.includes(item.distribution)) || (filters.product && filters.product.values.includes(item.product))
            || (filters.lossAllowance && filters.lossAllowance.values.includes(item.lossAllowance)) || (filters.shrinkage && filters.shrinkage.values.includes(item.shrinkage)) || (filters.createUser && filters.createUser.values.includes(item.createUser)) || (filters.updateUser && filters.updateUser.values.includes(item.updateUser)))) : this.pmcGridViewData;
        return filteredData.length > 0 ? filteredData.some((item) => item.isSelected === false) : true;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async onSearchClick(): Promise<void> {
        if (!this.showOnlyUS()) {
            const localValidatingErrorBody: any = [];
            let isValidSearch = true;
            if(this.selectedEffectiveStartDate && this.selectedEffectiveEndDate ? moment(this.selectedEffectiveEndDate).isBefore(this.selectedEffectiveStartDate):false){
                const message = 'End Date can not be before Start Date. Please select correct End Date.';
                localValidatingErrorBody.push(message);
                isValidSearch = false;
            }
            if (isValidSearch) {
                const assetGroupID = this.selectedProjectRequestTypeID ? this.selectedProjectRequestTypeID : projectRequestDataStore.projectRequestTypeDropdownOptions.filter(x => x.name === projectRequestDataStore.selectedProjectRequestType)[0]?.assetGroupID;
                const systemGroupId = this.getSelectedSystemGroupID();
                const pipelineId = this.getSelectedPipelineID();
                const distributionId = this.selectedTariffRateTypeID ? this.selectedTariffRateTypeID : projectRequestDataStore.tariffRateTypesDropdownOptions.filter(x => x.valueText === projectRequestDataStore.selectedTariffRateType)[0]?.settingID;
                const startDate = moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD');
                const endDate = moment(this.selectedEffectiveEndDate).format('YYYY-MM-DD');
                const isNotApproved = true;
                const response = await this.projectRequestDataService.getPMCTariffDetail(assetGroupID, systemGroupId, pipelineId, distributionId, startDate, endDate, isNotApproved);
                response.map(tariff => tariff.isSelected = false);
                runInAction(() => {
                    this.showLoader = false;
                    this.setPmcGridViewData(response);
                    this.selectedTariffs = [];
                })
                this.agGridService.getNodes()?.refreshCells();
                dataStore.setShowUnsavedWarningAlert(false);
            } else {
                const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
                errorHandler(messages);
            }
        }
    }

    @action
    @Loader
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async onClearSearch(): Promise<void> {
        if (!this.showOnlyUS()) {
            this.selectedEffectiveStartDate = new Date(moment(new Date).startOf('month').format('YYYY-MM-DDTHH:mm:ss'));
            this.selectedEffectiveEndDate = new Date(moment(new Date).endOf('month').format('YYYY-MM-DDTHH:mm:ss'));;
            const assetGroupID = this.selectedProjectRequestTypeID ? this.selectedProjectRequestTypeID : projectRequestDataStore.projectRequestTypeDropdownOptions.filter(x=>x.name===projectRequestDataStore.selectedProjectRequestType)[0]?.assetGroupID;
            const systemGroupId = 0;
            const pipelineId = 0;
            const distributionId = this.selectedTariffRateTypeID ? this.selectedTariffRateTypeID : projectRequestDataStore.tariffRateTypesDropdownOptions.filter(x => x.valueText === projectRequestDataStore.selectedTariffRateType)[0]?.settingID;
            const startDate = moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD');
            const endDate = moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD');
            const isNotApproved = true;
            const response = await this.projectRequestDataService.getPMCTariffDetail(assetGroupID, systemGroupId, pipelineId, distributionId, startDate, endDate, isNotApproved);
            response.map(tariff => tariff.isSelected = false);
            runInAction(() => {
                this.showLoader = false;
                this.selectedSystemGroup = { value: 'All', label: 'All' };
                this.selectedPipelineGroup = { value: 'All', label: 'All' };
                this.setPmcGridViewData(response);
                this.selectedTariffs = [];
            })
            this.agGridService.getNodes()?.refreshCells();  
            dataStore.setShowUnsavedWarningAlert(false);   
        }
    }

    showOnlyUS = () => {
        return projectRequestDataStore.accountStore.rolesJson[0]?.defaultLocation==='US'
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async tariffReviewTabClicked(): Promise<void> {
        this.showTariffReviw = true;
        if (this.selectedTariffs.length > 0) {
            const selectedTariffDetailIDs: any[] = [];
            this.selectedTariffs.map((item) => {
                selectedTariffDetailIDs.push(item.pmcTariffDetailID);
            })
            const response = await this.projectRequestDataService.getWFReviewTariffDetail(selectedTariffDetailIDs);
            runInAction(() => {
                this.reviewTariffDetails = response;
            })
        }
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getWorkflowNotesByWFID(): Promise<void> {
        const response = await this.projectRequestDataService.getWorkflowNotesByWFID(this.workflowId);
            runInAction(() => {
                this.setPMCTariffNoteGridData(response);
                if (response.length > 0) {
                    this.isTariffNoteAdded = true;
                }
            })
    }
    
    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + Object.values(error.response?.data?.errors).map(x => '\n \u2022 ' + x)) })
    async getWorkflowDocumentByWFID(): Promise<void> {
        const response = await this.projectRequestDataService.getWorkflowDocumentByWFID(this.workflowId);
            runInAction(() => {
                this.setPMCTariffAttachmentGridData(response);
            })
    }

    @action
    setPMCTariffNoteGridData(notesGridData: any[]): void {
        this.notesGridData = notesGridData
    }

    @computed
    get NotesGridData() {
        return toJS(this.notesGridData);
    }

    @action
    setPMCTariffAttachmentGridData(attachmentGridData: any[]): void {
        this.fileUploadGridData = attachmentGridData;
    }

    @computed
    get AttachmentsGridData() {
        return toJS(this.fileUploadGridData);
    }

    @action
    updateRow = (selectedRowData: any): void => {
        const updatedShopIndex = this.notesGridData.findIndex(a => a.workflowNoteID == selectedRowData.workflowNoteID);
        if (!_.isEqual(this.backupNotesGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumns(this.notesGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    @action
    updateRowAttachment = (selectedRowData: any): void => {
        const updatedShopIndex = this.fileUploadGridData.findIndex(a => a.workflowDocumentID == selectedRowData.workflowDocumentID);
        if (!_.isEqual(this.backupAttachmentGridData[updatedShopIndex], selectedRowData)) {
            this.mapAttachmentEditableColumns(this.fileUploadGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.workflowNoteID = updatedItem.workflowNoteID;
        currentItem.notes = updatedItem.notes;
        currentItem.createUser = updatedItem.createUser;
        currentItem.createDate = updatedItem.createDate;
    }

    mapAttachmentEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.workflowDocumentID = updatedItem.workflowDocumentID;
        currentItem.description = updatedItem.description;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMapAttachment) {
            for (const col in this.cellValueChangeMapAttachment[row]) {
                const obj = this.cellValueChangeMapAttachment[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async saveTariffNotes(): Promise<void> {
    const gridData = this.notesGridData.filter(note => note.notes !=='')
    let requestBodyForNote: any = {};
        const diff = _.differenceWith(gridData, this.backupNotesGridData, _.isEqual);
        requestBodyForNote = {
        'workflowId': this.workflowId, 
        'user': this.username,
        'notes': diff.map(note => {
            return {
                'id': note.workflowNoteID ? note.workflowNoteID : 0,
                'notes': note.notes,
            }            
        })
        }

    if(requestBodyForNote.notes.length !== 0) {
        let errorSummary: ToastMessage[] = [];
        let errorInSave = false;
        const response: any[] = await this.projectRequestDataService.saveWFTariffNotes(requestBodyForNote);
        if(response){
            for (const key in response) {   
                if(response.length > 0){
                    this.setPMCTariffNoteGridData(response);
                    this.backupNotesGridData = response;
                    this.isTariffNoteSaved = true;
                    dataStore.setShowUnsavedWarningAlert(false);
                }
                else {
                    errorInSave = true;
                    errorSummary = [...errorSummary, { id: key, description: 'There is an error saving note' }];
                }
            }
        }
        if (errorInSave) {
            this.uiService.toastService.error('', {}, errorSummary);
            errorSummary.forEach(errorMessage => {
                for (let i = 0; i < this.notesGridData.length; i++) {
                    const updatedData = this.notesGridData[i];
                    if (updatedData.workflowNoteID.toString() === errorMessage.id) {
                        const backupRowIndex = this.notesGridData.findIndex(a => a.workflowNoteID.toString() == errorMessage.id);
                        this.mapEditableColumns(updatedData, this.notesGridData[backupRowIndex]);
                    }
                }
            });
        }
        else {
            this.uiService.toastService.success('Note saved successfully');
        }
    }        
    }

     @Loader
     @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async deleteTariffNote(noteID): Promise<void> {
        if(noteID) {
            const response: any = await this.projectRequestDataService.deleteWFTariffNote(noteID);
            if(response){ 
                const nodesGridData = this.NotesGridData.filter((item) => item.workflowNoteID !== noteID);
                this.setPMCTariffNoteGridData(nodesGridData);
                this.uiService.toastService.success('Note has deleted successfully')
            }else {
                this.uiService.toastService.error('There is an error while deleting selected note');
            }
            dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in uploading attachment '))
    async uploadFile(e): Promise<void> {
        const formData = new FormData();
        formData.append('Attachment', e.target.files[0]);
        formData.append('FileName', e.target.files[0].name);
        formData.append('Description', 'File Description');
        formData.append('user', this.username );
        formData.append('workflowId', this.workflowId);
        const uploadedFileData: any = await this.projectRequestDataService.uploadFile(formData);
        if(uploadedFileData.length > 0){
            this.uiService.toastService.success('Attachment uploaded successfully');
            const documents = JSON.parse(JSON.stringify(uploadedFileData));
            const attachmentList = this.backupAttachmentGridData.concat(documents)
            this.setPMCTariffAttachmentGridData(attachmentList);
            this.backupAttachmentGridData = attachmentList;
            e.target.value = '';
        }else {
            this.uiService.toastService.error('There is an error while uploading attachment');
        }        
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in downloading attachment '))
    async downloadAttachment(fileID): Promise<void> {
        const response: any = await this.projectRequestDataService.downloadAttachment(fileID);
        window.open(response, '_blank', 'noreferrer');
        this.uiService.toastService.success('Attachment downloaded successfully');
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in deleting attachment '))
    async deleteAttachment(docID): Promise<void> {
        const response: any = await this.projectRequestDataService.deleteAttachment(docID);
        if(response){
            const filteredData = this.AttachmentsGridData.filter((item) =>  item.workflowDocumentID !== docID);
            this.uiService.toastService.success('Attachment deleted successfully');
            this.setPMCTariffAttachmentGridData(filteredData);
            this.backupAttachmentGridData = this.fileUploadGridData;
        }else {
            this.uiService.toastService.error('There is an error while deleting attachment');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in editing attachment '))
    async updateAttachment(): Promise<void> {
        const filesToUpdate: any = [];
        const updatedRowsIDS = this.getUpdatedRowIDs();
        const documents = JSON.parse(JSON.stringify(this.fileUploadGridData));
        if(updatedRowsIDS.length>0){
            updatedRowsIDS.map(docId=>{
                const updatedRow: any = documents.find(item=>item.workflowDocumentID == docId)
                filesToUpdate.push({
                    'id': updatedRow.workflowDocumentID,
                    'workflowId': updatedRow.workflowId,
                    'user': updatedRow.createUser,
                    'description': updatedRow.description
                })
            })
            const response: any = await this.projectRequestDataService.updateAttachment(filesToUpdate);
            if(response.length > 0){
                documents.map(item=>{
                    response.map(resItem=>{
                        if(resItem.workflowDocumentID == item.workflowDocumentID){
                            item.description = resItem.description,
                            item.updateUser = resItem.updateUser,
                            item.updateDate = resItem.updateDate
                        }
                    })
                })
                this.setPMCTariffAttachmentGridData(documents);
                this.cellValueChangeMapAttachment = {};
                this.agGridService.getNodes()?.refreshCells();
                this.backupAttachmentGridData = documents;
                this.uiService.toastService.success('Attachment updated successfully')
            }else {
                this.uiService.toastService.error('There is an error while updating attachment');
            }
        }    
    }    
    
    @Loader
    @action
        @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async step1SavePostWorkflowDetails(): Promise<void> {
        if(!this.formData.goLive){
            this.uiService.toastService.error('Go-live Date is Required!')
            return;
        } if(this.showOnlyUS() && projectRequestDataStore.selectedProjectRequestType !== '1A - Mergers, Acquisitions, \u0026 Rebuild' && (!this.formData.pipelineSystems || this.formData.pipelineSystems.length === 0)) {
            this.uiService.toastService.error('Select valid Pipeline Systems')
            return;
        }
        const requestBody = {
                'projectRequestID': this.workflowDetails && this.workflowDetails.projectRequestID || 0,
                'workflowID': this.workflowDetails?.workflowID || this.workflowId,
                'jurisdiction': this.workflowDetails?.jurisdiction || this.accountStore.rolesJson[0]?.defaultLocation,
                'pipelineId': this.formData.pipelineSystems===null?null:this.formData.pipelineSystems.length?this.formData.pipelineSystems.map(x=>this.pipelinesDropdown.find(y=>y.plName===x)?.pipelineID)?.join(','):null,
                'description': this.formData.description || '',
                'goLiveDate': this.formData.goLive && moment(this.formData.goLive).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'createUser': this.workflowDetails?.createUser || this.username,
                'createDate': this.workflowDetails?.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                'name': this.formData.projectName, 
                'tracking': this.formData.tracking, 
                'dor': this.formData.dor, 
                'baseTariffID': this.formData.selectedBaseTariff==='New'?null:this.baseTariffDropdown.find(x=>this.formData.selectedBaseTariff===x.baseTariff)?.tariffBaseID
              }
        if(requestBody) {
            const response: any = await this.projectRequestDataService.step1SavePostWorkflowDetails(requestBody);
            if(response){
                let error ='';
                for (const key in response) {                       
                    if (key==='value') {
                        error = response[key]; 
                    } else if (key==='statusCode' && response[key]===400) {
                        this.uiService.toastService.error(error);
                    } 
                }
                if(error===''){
                        this.getWorkflowDetails();
                        if (this.isUSJurisdiction()) {
                             this.sendEmail();
                        }  
                        this.projectRequestSaved=true;
                        this.uiService.toastService.success('Project Request Saved Successfully!')
                }
            }       
        }
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async step1SaveComments(isCommentRequired = true): Promise<void> {
        const localValidatingErrorBody: any = [];
        let isValidWFRequest = true;
        if(!this.formData.comment && isCommentRequired){
            const message = 'Comment is required!';
            localValidatingErrorBody.push(message);
            isValidWFRequest = false;
        }
        if (!this.showOnlyUS() && this.selectedTariffs.length == 0) {
            const message = 'Please select at least one tariff!';
            localValidatingErrorBody.push(message);
            isValidWFRequest = false;
        }
        if((!this.formData.comment || this.formData.comment === '') && !isCommentRequired && isValidWFRequest) {
            if (this.accountStore.rolesJson[0]?.defaultLocation == 'CAN') {
                const selectedTariffDetailIDs: any[] = [];
                this.selectedTariffs.map((item) => {
                    selectedTariffDetailIDs.push(item.pmcTariffDetailID);
                })
                const response: any = await this.projectRequestDataService.savePMCWorkflowSelectedTariffDetails(this.workflowId, selectedTariffDetailIDs);
            }
            await this.sendEmailWorkflow('approve');
            this.formData={...this.formData, comment:''}
            if (this.accountStore.rolesJson[0]?.defaultLocation == 'CAN' && accountStore.userRoles.includes('PMCAssetsAnalyst')) {
                history.push('/ProjectDashboard');
            } else {
                history.push('/project-request-step-2', {workflowId: this.workflowId, workflowStepId: '', selectedProjectRequestType: this.selectedProjectRequestType})
            }
            return;
        }
        if (isValidWFRequest) {
            const response: any = await this.projectRequestDataService.step1SaveComments(this.workflowId, this.formData.comment, this.username, this.workflowStepFlowId);
            if(response){
                let error ='';
                for (const key in response) {                       
                    if (key==='value') {
                        error = response[key]; 
                    } else if (key==='statusCode' && response[key]===400) {
                        this.uiService.toastService.error(error);
                    } 
                }
                if(error===''){
                    // this.getWorkflowDetails();
                    // this.getWorkflowStatusGridData();
                    if (this.accountStore.rolesJson[0]?.defaultLocation == 'CAN') {
                        const selectedTariffDetailIDs: any[] = [];
                        this.selectedTariffs.map((item) => {
                            selectedTariffDetailIDs.push(item.pmcTariffDetailID);
                        })
                        const response: any = await this.projectRequestDataService.savePMCWorkflowSelectedTariffDetails(this.workflowId, selectedTariffDetailIDs);
                    }
                    await this.sendEmailWorkflow('approve');
                    this.formData={...this.formData, comment:''}
                    this.uiService.toastService.success('Comments Saved Successfully!')
                    if (this.accountStore.rolesJson[0]?.defaultLocation == 'CAN' && accountStore.userRoles.includes('PMCAssetsAnalyst')) {
                        history.push('/ProjectDashboard');
                    } else {
                        history.push('/project-request-step-2', {workflowId: this.workflowId, workflowStepId: '', selectedProjectRequestType: this.selectedProjectRequestType})
                    }
                }
            }
        }else{
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }       
    }
    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmail(): Promise<void> {
        // const requestBody= {
        //     'workflowStepId': this.workflowStepId,
        //     'direction': 'approve',
        //     'criteria': '',
        //     'useremail': 'hima.mantri@plains.com'
        // }
        const requestBody = {
            'to': this.isUSJurisdiction() ? EMAIL_RECIPIENTS : CAN_EMAIL_RECIPIENTS,
            'from': 'CTSsupport@plains.com',
            'subject': `Dev CTS - Project Overview Update - ${this.formData.projectName}`,
            'body': `Hello Team, <br><br> An update was made to the project overview section: <br><br> <b> Workflow ID:</b> ${this.workflowId || ''}<br><b>Project Name: </b> ${this.formData.projectName || ''}<br><b>Pipeline System:</b> ${this.formData.pipelineSystems ? this.formData.pipelineSystems.join(', ') : ''}<br><b>Tariff: </b> ${this.formData.selectedBaseTariff || ''}<br><b>Description: </b> ${this.formData.description||''}<br><b>
            Go Live:</b> ${this.formData.goLive ? moment(this.formData.goLive).format(AppConstant.DATE_FORMAT) :''} <br><br>
            You can see the details of this project by visiting https://cts-dev.plains.com/ProjectDashboard <br><br>
            Thank You, <br>
            CTS Support Team <br>
            ctssupport@plains.com `       }
        if(this.projectRequestSaved===false||this.backupDescription!==this.formData.description){
            const response: any = await this.projectRequestDataService.step1SendEmail(requestBody);
            if(response){
                let error ='';
                for (const key in response) {                       
                    if (key==='value') {
                        error = response[key]; 
                    } else if (key==='statusCode' && response[key]===400) {
                        this.uiService.toastService.error(error);
                    } 
                }
                if(error===''){
                        // this.getWorkflowDetails();
                        // this.uiService.toastService.success('Email Sent Successfully!')
                }
            }       
        }
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmailWorkflow(direction): Promise<void> {
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': direction,
            'criteria': this.selectedTariffRateType && this.selectedTariffRateType != '' ? this.selectedTariffRateType : projectRequestDataStore.selectedTariffRateType,
            'useremail': this.username,
            'additionalInfo': this.formData.comment
        }
        const response: any = await this.projectRequestDataService.step1SendEmailWorkflow(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            }
            }       
    }

    @action
    @Loader
    @Catch(() => errorHandler('Data could not be loaded'))
    async onClickEditTariff(tariffData): Promise<void> {
        const assetGroupID = this.assetGroupData.find(x => x.name === tariffData.assetGroup)?.assetGroupID;
        const reqBody = [{ 'propertyName': 'TARIFFNO', 'value': tariffData.tariffNumber.toString() }, { 'propertyName': 'TARIFFID', 'value': tariffData.pmcTariffDetailID.toString() }];
        const response = await this.projectRequestDataService.getPMCDashboardDetails(reqBody);
        runInAction(() => {
            pmcTariffDataStore.editableTariffData = response;
            pmcTariffDataStore.assetGroupID = assetGroupID;
            pmcTariffDataStore.isTariffSaved = true;
            pmcTariffDataStore.isTariffNumberClicked = true;
            pmcTariffDataStore.setTariffRequestType({ 'value': tariffData.assetGroup, 'label': tariffData.assetGroup});
            
            pmcTariffDataStore.pmcTariffNumber = response[0].tariffNumber;
            pmcTariffDataStore.tariffStatus = response[0].pmcTariffDetailStatus;
            pmcTariffDataStore.pmcTariffDetailID = response[0].pmcTariffDetailID;
            pmcTariffDataStore.pmcTariffFeeDetailID = response[0].pmcTariffFeeDetailID;
            pmcTariffDataStore.selectedSystemGroupID = this.systemGroupData.find(x => x.valueText === response[0].pmcSystemGroup)?.settingID;
            pmcTariffDataStore.setSystemGroup({'value': response[0].pmcSystemGroup, 'label': response[0].pmcSystemGroup});
            pmcTariffDataStore.setAgreement(response[0].agreement);
            pmcTariffDataStore.selectedAgreementEndDate = response[0].agreementEndDate ? new Date(moment(response[0].agreementEndDate).format('YYYY-MM-DD')) : '';
            pmcTariffDataStore.setBSW(parseFloat(response[0].bS_WPenalty).toFixed(2));
            const currencyValue = this.currencyData.find(x=>x.settingID===response[0].currency)?.valueText;
            pmcTariffDataStore.setCurrency({ 'value': response[0].currency, 'label': currencyValue});
            pmcTariffDataStore.setDeliveryTerminallingFee(parseFloat(response[0].deliveryTerminalling).toFixed(2));
            pmcTariffDataStore.setDescription(response[0].additionalDesc);
            const regulatorValue = this.regulatorData.find(x=>x.settingID===response[0].regulator)?.valueText;
            pmcTariffDataStore.setRegulator({ 'value': response[0].regulator, 'label': regulatorValue});
            pmcTariffDataStore.setDestination({ 'value': response[0].destination, 'label': response[0].destination});
            pmcTariffDataStore.setDistributionType({ 'value': response[0].distribution, 'label': response[0].distribution});
            pmcTariffDataStore.selectedEffectiveDate = response[0].effectiveDate ? new Date(response[0].effectiveDate) : '';
            pmcTariffDataStore.setElectronicReportingFee(parseFloat(response[0].electronicReportingFee).toFixed(2));
            pmcTariffDataStore.setEscalationAmount(response[0].escalationAmount);
            pmcTariffDataStore.setEscalationMonth({ 'value': response[0].escalationMonth, 'label': this.monthNames.filter(x=>x.value == response[0].escalationMonth)[0]?.label });
            const externalSystems =  response[0].externalSystem.length > 0 ? response[0].externalSystem.split(',') : [];
            pmcTariffDataStore.setExternalSystems(externalSystems);
            pmcTariffDataStore.setFacilityCode({'value': response[0].pmcFacility, 'label': response[0].pmcFacility});
            pmcTariffDataStore.setIntraSystemTransferFee(parseFloat(response[0].intraSystemTransferFee).toFixed(2));
            pmcTariffDataStore.setLSD({'value': response[0].lsd, 'label': response[0].lsd});
            const lossAllowanceValue = response[0].lossAllowance ? this.lossAllowanceData.find(x=>x.valueText===response[0].lossAllowance)?.settingID : null;
            pmcTariffDataStore.setLossAllowance({'value': lossAllowanceValue, 'label': response[0].lossAllowance});
            pmcTariffDataStore.setLumpSumFees(parseFloat(response[0].lumpSumFees).toFixed(2));
            pmcTariffDataStore.setOperator({'value': response[0].operator, 'label': response[0].operator});
            pmcTariffDataStore.setPipelineGroup({'value': response[0].pmcPipelineGroup, 'label': response[0].pmcPipelineGroup});
            pmcTariffDataStore.setPrimaryRate(parseFloat(response[0].primaryPipelineTariff).toFixed(2));
            const products =  response[0].product.length > 0 ? response[0].product.split(',') : [];
            pmcTariffDataStore.setProducts(products);
            pmcTariffDataStore.setProductType({'value': response[0].productTypeID, 'label': response[0].productType});
            pmcTariffDataStore.setQualityMgmtFee(parseFloat(response[0].qualityManagementFee).toFixed(2));
            pmcTariffDataStore.setSecondaryRate(parseFloat(response[0].secondaryPipelineTariff).toFixed(2));
            pmcTariffDataStore.setReceiptTerminalling(parseFloat(response[0].receiptTerminalling).toFixed(2));
            pmcTariffDataStore.setLmciAbandonment(parseFloat(response[0].lmciAbandonmentS_C).toFixed(2));
            pmcTariffDataStore.setInjectionFee(parseFloat(response[0].offload_InjectionFee).toFixed(2));
            pmcTariffDataStore.setSurcharge(parseFloat(response[0].surcharge).toFixed(2));
            const shrinkageValue = response[0].shrinkage ? this.shrinkageData.find(x=>x.valueText===response[0].shrinkage)?.settingID : '';
            pmcTariffDataStore.setShrinkage({'value': shrinkageValue, 'label': response[0].shrinkage});
            pmcTariffDataStore.setTariffApplication({'value': response[0].tariffApplication, 'label': response[0].tariffApplication});
            const unitValue = this.unitData.find(x=>x.settingID===response[0].unit)?.valueText;
            pmcTariffDataStore.setUnit({'value': response[0].unit, 'label': unitValue});
            pmcTariffDataStore.totalFee = Number(response[0].total);
            pmcTariffDataStore.selectedEffectiveStartDate = new Date(response[0].startDate);
            pmcTariffDataStore.selectedEffectiveEndDate = response[0].endDate ? new Date(response[0].endDate) : '';

            pmcTariffDataStore.setPMCTariffAttachmentGridData(response[0].pmcDocs);
            pmcTariffDataStore.backupAttachmentGridData = response[0].pmcDocs;
            pmcTariffDataStore.setPMCTariffNoteGridData(response[0].pmcNotes);
            
            
        })
        history.push('/PMCTariff', { from: '/project-request-step-1' });   
    }

    getTariffDetailColDef() {
        return step1TariffColDef;
    }
}
