import { GradeMaintenanceDataStore } from './grade-maintenance-data-store';
import { gradeMaintenanceDataService } from '../services/grade-maintenance-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { accountStore } from '../../../account/stores';
import { AddEditGradeMaintenanceStore } from './add-edit-grade-maintenance-data-store';

export const agGridServiceForAddOrEditLocation = new AgGridService();
export const agGridServiceForAddOrEditLinker = new AgGridService();

export const gradeMaintenanceDataStore = new GradeMaintenanceDataStore(gradeMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLocation);
export const addEditGradeMaintenanceDataStore = new AddEditGradeMaintenanceStore(gradeMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLinker);