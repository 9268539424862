import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LineSegmentMaintenanceDataStore } from '../stores/line-segment-maintenance-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { LineSegmentMaintenanceResponse } from '../model/model';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/line-segment.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { lineSegmentMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface LineSegmentMaintenanceContainerProps {
    lineSegmentMaintenanceDataStore: LineSegmentMaintenanceDataStore;
}

interface LineSegmentMaintenanceContainerState {
    search: string;
}

@inject('lineSegmentMaintenanceDataStore')
@observer
export class LineSegmentMaintenanceContainer extends Component<LineSegmentMaintenanceContainerProps, LineSegmentMaintenanceContainerState> {
    constructor(props: LineSegmentMaintenanceContainerProps | Readonly<LineSegmentMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Line Segment Maintenance')
    }
    
    componentDidMount() {
      const {lineSegmentMaintenanceDataStore} = this.props;
      const previousLineSegment = history?.location?.state as { from?: string }
      history.push('/LineSegmentMaintenance');
       if(previousLineSegment?.from && previousLineSegment.from==='/AddEditLineSegmentMaintenance'){
           lineSegmentMaintenanceDataStore.onSearchClick();   
     } else {
            lineSegmentMaintenanceDataStore.init();   
        }
       lineSegmentMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {lineSegmentMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Line Segment Maintenance_filter');
      this.props.lineSegmentMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Line Segment Maintenance_' + accountStore.userName);
      this.props.lineSegmentMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    onBtClearSelection = () => {
      const { lineSegmentMaintenanceDataStore } = this.props;
      lineSegmentMaintenanceDataStore.onClearSearch();
    }

    onSearchClick = () => {
      const { lineSegmentMaintenanceDataStore } = this.props;
      lineSegmentMaintenanceDataStore.onSearchClick();
    }

    onAddNewClick = async() => {
      const { lineSegmentMaintenanceDataStore } = this.props;
        
      lineSegmentMaintenanceDataStore.onClickAddNewLineSegmentButton = true;
      lineSegmentMaintenanceDataStore.onClickLineSegmentNameOnGrid = false;
      lineSegmentMaintenanceDataStore.agGridService.destroyGrid();
      lineSegmentMaintenanceDataStore.getPipelineSystemDropdown();
      lineSegmentMaintenanceDataStore.getSelectedLineSegmentData();
      history.push('/AddEditLineSegmentMaintenance');
    }
    
    onClickAddNewLineSegment = async() => {
      const { lineSegmentMaintenanceDataStore } = this.props;
        
      lineSegmentMaintenanceDataStore.onClickAddNewLineSegmentButton = true;
      lineSegmentMaintenanceDataStore.onClickLineSegmentNameOnGrid = false;
      lineSegmentMaintenanceDataStore.agGridService.destroyGrid();
      lineSegmentMaintenanceDataStore.getPipelineSystemDropdown();
      lineSegmentMaintenanceDataStore.getSelectedLineSegmentData();
      history.push('/AddEditLineSegmentMaintenance');
    }
    
    onClickActivate = () => {
      const { lineSegmentMaintenanceDataStore } = this.props;
      lineSegmentMaintenanceDataStore.activateLineSegment();
    }

    onChangeLineSegmentName = (e) => {
      this.props.lineSegmentMaintenanceDataStore.onChangeLineSegmentName(e.target.value)
    }


    onChangeLineSegment = (e) => {
      this.props.lineSegmentMaintenanceDataStore.onChangeLineSegment(e.target.value)
    }
    // handleStateChangeFromDelToActive = async() => {
    //   const { lineSegmentMaintenanceDataStore } = this.props;
    //   const requestBody: any = await lineSegmentMaintenanceDataStore.agGridService.getSelectedRows();
    //   const updatedRowIDs = lineSegmentMaintenanceDataStore.lineSegmentMaintenanceData.filter(a => lineSegmentMaintenanceDataStore.getUpdatedRowIDs().includes(a.lineSegmentID.toString()));
    //   if(requestBody.length > 0 && !requestBody.some((item) => item.action !== 'D')) {
    //     if(updatedRowIDs.length > 0) {
    //       const confirmService = new ConfirmService();
    //       confirmService.showConfirmDialog(
    //         () => {},
    //         'Active',
    //         'All the unsaved changes will be lost. Are you sure you want to activate the selected lineSegments?'
    //     );
    //     } else {
    //       const confirmService = new ConfirmService();
    //       confirmService.showConfirmDialog(
    //         () => {},
    //         'Active',
    //         'Are you sure you want to activate the selected lineSegments?'
    //     );
    //     }
    //   } else {
    //     const secondaryText = requestBody.length > 0 ? 'Please select only the inactive lineSegments to perform the operation' : 'No records are selected. Please select atleast one inactive lineSegment to perform the operation.';
    //     const confirmService = new ConfirmService();
    //     confirmService.showConfirmWithCustomComponent(
    //       () => {},
    //       ConfirmWithOK,
    //       {secondaryText: secondaryText} as ConfirmWithOKProps,
    //       'confirmPopup'
    //     );
    //   }
    //   console.log(' succesfully');
    // }

    render(): React.ReactNode {
      const { lineSegmentMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderLineSegmentMaintenanceScreen()}
        </Container>
      );
    }

    renderLineSegmentMaintenanceScreen() : React.ReactNode {
      const { lineSegmentMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='lineSegmentMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Line Segment Maintenance</h2>
                </div>
                
                {/* <h4 className='search_criteria_title'> 
                  <label>Search Criteria</label> 
                </h4> */}
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                  <label className="standard_label_style">System/Area</label>
                  <ReactSelect
                        className='select'
                        values={lineSegmentMaintenanceDataStore.selectedPipelineSystem}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...lineSegmentMaintenanceDataStore.pipelineSystemDropdownOption]}
                        onChange={(e: any) => {lineSegmentMaintenanceDataStore.selectedPipelineSystem = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                  <label className="standard_label_style">Line Segment Type</label>
                  <ReactSelect
                        className='select'
                        values={lineSegmentMaintenanceDataStore.selectedType}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...lineSegmentMaintenanceDataStore.lineSegTypeDropdownOption]}
                        onChange={(e: any) => {lineSegmentMaintenanceDataStore.selectedType = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                </div>
                <div className='settingSelectionDiv'>
                  <label className="standard_label_style">Line Segment Description</label>
                  <input 
                      type="text"
                      value={lineSegmentMaintenanceDataStore.selectedDescription}
                      className="search_text_field"
                      // disabled={true}
                      // style={{width: '20%'}}
                      onChange = {(e) => this.onChangeLineSegment(e)}
                    />                    
                    <label className="standard_label_style">Line Segment Status</label>
                    <ReactSelect
                        className='select'
                        values={lineSegmentMaintenanceDataStore.selectedStatus}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...lineSegmentMaintenanceDataStore.lineSegStatusDropdownOption]}
                        onChange={(e: any) => {lineSegmentMaintenanceDataStore.selectedStatus = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                </div>
                <div className='searchButtonDiv'>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                      Search
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                      Clear
                  </CustomButton>
                  <div className='otherHeaderButtonsDiv'>
                    {/* <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled = {lineSegmentMaintenanceDataStore.isSaveDisabled()}> */}
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onClickAddNewLineSegment} disabled={lineSegmentMaintenanceDataStore.isSaveDisabled()}>
                        Add Line Segment
                    </CustomButton>
                    {/* <CustomButton type={CustomButtonType.Submit} onClick={this.handleStateChangeFromDelToActive} disabled= {lineSegmentMaintenanceDataStore.isActivateButtonDisabled}>
                      Activate
                    </CustomButton> */}
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onClickActivate} disabled= {lineSegmentMaintenanceDataStore.isActivateButtonDisabled || lineSegmentMaintenanceDataStore.isActivateDeactivateDisabled()}>
                      Activate
                    </CustomButton>
                    </div>
                </div>
              </div>
            </div>
            {this.props.lineSegmentMaintenanceDataStore.showLoader == true &&
              <div className = 'alert-overlay'> 
                <div style={{margin: 'auto'}} className = 'dots-1'></div> 
              </div> 
            }
            <AgGridComponent gridConfig={this.getGridConfig('Line Segment Maintenance')} />
            {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
          </Col>
        </Row>
      );
    }


    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        lineSegmentMaintenanceDataStore.selectedSegmentIds = selectedRows.map(x => {return x.lineSegID})
        lineSegmentMaintenanceDataStore.setIsActivateButtonDisabled(lineSegmentMaintenanceDataStore.isSaveDisabled())
      } else {
        lineSegmentMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
    }

    private getGridConfig(gridId): GridOptions {
      const {
          lineSegmentMaintenanceDataStore: { LineSegmentMaintenanceData, agGridService }
      } = this.props;
      const { lineSegmentMaintenanceDataStore } = this.props;
      const columnDefs = lineSegmentMaintenanceDataStore.getColDef();
      const rowData = LineSegmentMaintenanceData;
      const onSelectionChanged = this.onSelectionChanged;
      const rowModelType = 'infinite';
      const cacheBlockSize =  dataStore.CacheBlockSize;
      const lineSegmentMaintenanceGridParams: any = {
        // rowData,
        rowModelType,
        columnDefs,
        onSelectionChanged,
        cacheBlockSize
      };
      return agGridService.getGridConfig(lineSegmentMaintenanceGridParams, gridId);
    }
}
