import { projectRequestDataService } from '../services/project-request-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { ProjectRequestDataStore } from './project-request-data-store';
import { ProjectRequestStep1DataStore } from './project-request-step-1-data-store';
import { accountStore } from '../../../account/stores';
import { ProjectRequestStep2DataStore } from './project-request-step-2-data-store';

export const agGridServiceForAddAttachmentProject = new AgGridService();

export const projectRequestDataStore = new ProjectRequestDataStore(projectRequestDataService, uiService, accountStore);

export const projectRequestStep1DataStore = new ProjectRequestStep1DataStore(projectRequestDataService, uiService, accountStore, agGridService, agGridServiceForAddAttachmentProject);

export const projectRequestStep2DataStore = new ProjectRequestStep2DataStore(projectRequestDataService, uiService, accountStore, agGridService);
