import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { dataStore } from 'common/stores';
import { ConnectionPointNameCellRenderer } from './cell-renderers/cp-name-cell-renderer';
import { connectionPointMaintenanceDataStore } from '../stores';
import { locationMaintenanceDataStore } from 'features/master-data-management/location-maintenance/stores';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import momenttimezone from 'moment-timezone';

const filterParams = {
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
    textFormatter: (r) => {
        if (r == null) return null;
        return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    suppressAndOrCondition: true,
  };

const dateFilterParams = {
    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],

      // provide comparator function
      comparator: function (filterLocalDateAtMidnight, cellValue) {

          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts  = cellValue.split('/');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(day, month, year);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
              return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
          } else {
              return 0;
          }
      },
    debounceMs: 200,
    suppressAndOrCondition: true,
  }

export const connectionPointsDataColDef: ColDef[] = [
    {   
        headerName: 'Select',
        field: 'select', 
        colId:'Select',
        checkboxSelection:  true, 
        // cellRenderer: 'checkboxRenderer', 
        cellClass: (params) => {return  params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 40, 
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true
    },
    { 
        headerName: 'Base System', 
        field: 'plName', 
        colId: 'plName', 
        sortable: true,
        singleClickEdit: true,
        minWidth: 150, 
        filter: 'agTextColumnFilter',
        filterParams: filterParams,
        // filterParams: baseSystemFilterParams(),
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['plName'] === null) {
                return '';
            } else {
                return params.data['plName'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Location', 
        field: 'locnName',
        colId: 'locnName', 
        singleClickEdit: true, 
        filter: 'agTextColumnFilter',
        filterParams: filterParams,
        sortable: true, 
        width: 110, 
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (!params.data || params.data['locnName'] === null) {
                return '';
            } else {
                return params.data['locnName'];
            }
        },
    },
    { 
        headerName: 'Connection Point Name', 
        field: 'cpName', 
        colId: 'cpName',
        singleClickEdit: true, 
        filter: 'agTextColumnFilter',
        sortable: true,
        filterParams: filterParams,
        cellRenderer: ConnectionPointNameCellRenderer,
        cellClass: (params) => {return  params && params.data && params.data.action &&  params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        minWidth: 200, 
        valueGetter: params => {
            if (!params.data || params.data['cpName'] === null) {
                return '';
            } else {
                return params.data['cpName'];
            }
        },
        flex: 2
    },
    { 
        headerName: 'CP Code', 
        field: 'cpCode',
        colId: 'cpCode', 
        singleClickEdit: true,
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true, 
        valueGetter: params => {
            if (!params.data || params.data['cpCode'] === null) {
                return '';
            } else {
                return params.data['cpCode'];
            }
        },
        cellClass: (params) => {return  params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 100
    },
    { 
        headerName: 'CP Type', 
        field: 'cpType', 
        colId: 'cpType', 
        filter: 'agTextColumnFilter',
        filterParams: filterParams,
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return  params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['cpType'] === null) {
                return '';
            } else {
                return params.data['cpType'];
            }
        },
    },
    { 
        headerName: 'CP Status',
        field: 'status',
        colId: 'status',  
        filter: 'agTextColumnFilter',
        filterParams: filterParams,
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['status'] === null) {
                return '';
            } else {
                return params.data['status'];
            }
        },
    },
    { 
        headerName: 'Activity Type', 
        field: 'activityType',
        colId: 'activityType',   
        filter: 'agTextColumnFilter',
        filterParams: filterParams,
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['activityType'] === null) {
                return '';
            } else {
                return params.data['activityType'];
            }
        },
    },
    { 
        headerName: 'State', 
        field: 'state', 
        colId: 'state',   
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['state'] === null) {
                return '';
            } else {
                return params.data['state'] ;
            }
        }, 
    },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser', 
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        },
    },
    { 
        headerName: 'Created Date',
        field: 'createDate',
        colId: 'createDate',  
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
    },
    { 
        headerName: 'Updated User', 
        field: 'updateUser',
        colId: 'updateUser',   
        filter: 'agTextColumnFilter',
        filterParams: filterParams,
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        },
    },
    { 
        headerName: 'Updated Date', 
        field: 'updateDate', 
        colId: 'updateDate',   
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || params.data['updateDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
    },
    { 
        headerName: 'Sync Status', 
        field: 'syncStatus', 
        colId: 'syncStatus',
        width: 130,
        filter: 'agTextColumnFilter',
        filterParams: filterParams,
         cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['syncStatus']) {
                return '';
            } else {
                return params.data['syncStatus'];
            }
        }
    },
    { 
        headerName: 'VMACS Test Sync Date', 
        field: 'testSyncDate', 
        colId: 'testSyncDate',
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        editable: false,
        width: 145,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['testSyncDate']) {
                return '';
            } else {
                  return moment(params.data['testSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        suppressSizeToFit: true  
    },
    { 
        headerName: 'VMACS Prod Sync Date', 
        field: 'prodSyncDate', 
        colId: 'prodSyncDate',
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        editable: false,
        width: 145,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['prodSyncDate']) {
                return '';
            } else {
                return moment(params.data['prodSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        suppressSizeToFit: true  
    }
];
