import React from 'react';
import ErrorPage from './error-page';

interface ErrorBoundaryState {
    hasErrored: boolean;
}

class ErrorBoundary extends React.Component<{ children?: any}, ErrorBoundaryState> {
    constructor(props: Readonly<any>) {
        super(props);

        this.state = {
            hasErrored: false
        };
    }

    static getDerivedStateFromError() {
        // process the error
        return { hasErrored: true };
    }

    componentDidCatch(error: any, info: any) {
        console.log(error);
    }

    render() {
        if (this.state.hasErrored) {
            return <ErrorPage primaryText={'Something went wrong'} secondaryText={'Please contact administrator'} />;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
