import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import moment from 'moment';
import { TariffOptionsDataService } from '../services/tariff-options-data-service';
import history from '../../../../shared/components/header-component/component/history';
import { map } from 'lodash';
import { AccountStore } from '../../../account/stores/account-store';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';

export class TariffOptionsIndexDataStore {    
    @observable baseTariffDropdown: any[] = [];
    @observable selectedBaseTariffDetails: any[] = [];
    @observable cancelledTariffDropdown: any[] = [];
    @observable tariffOptions: any[] = [];
    @observable agencyDropdown: any[] = [];
    @observable statusDropdown: any[] = [];
    @observable tariffTypesDropdown: any[] = [];
    @observable companyDropdown: any[] = [];
    @observable invoiceBasisDropdown: any[] = [];
    @observable pipelineAreasDropdown: any[] = [];
    @observable administratorDropdown: any[] = [];
    @observable ownerDropdownForOwner: any = {};
    
    @observable tariffRelationTypes: any = {};
    
    // @observable shownProjectNames: any[] = [];
    // @observable fileUploadGridData: any[] = [];
    @observable Tabs: any[] = [];
    @observable createdUser;
    @observable selectedPipelineArea = '';

    @observable selectedTab = -1;
    @observable screenHeaderTabSelected:  any[] = [];
    @observable cloneNewTariff= {};
    
    @observable formData: any[] = [];

    @observable formDataSelection = {
        baseTariff: '',
        tariffLog: ''
    }

    @observable showUnsavedWarningAlert=false
    
    @observable mvtLineSegment: any [] = [];
    @observable mvtRateTypeDirection: any [] = [];
    @observable mvtRateBasis: any [] = [];
    @observable mvtRateTypes: any [] = [];
    @observable cpLineLocation: any [] = [];
    @observable cpArea: any = {};
    
    @observable commentsData: any[] = [];
    @observable ctsCommentsData: any[] = [];
    @observable relatedTariffData: any [] = [];
    @observable deduction: any [] = [];
    @observable lossAllowance: any [] = [];
    @observable ownerData: any [] = [];
    @observable pipelineAreaData: any[] = [];
    @observable connectionPointData: any[] = [];
    @observable connectionPointDataBackup: any[] = [];
    @observable movementData: any[] = [];
    @observable movementDataBackup: any[] = [];

    
    @observable tariffIndexRate; 
    
    @observable workflowId;
    @observable workflowStepId;
    @observable workflowStepFlowId;
    @observable workflowAction;
    @observable workflowProcess = false;
    @observable statusOrHistory=true;
    @observable workflowStatusGridData: any[]=[];
    @observable stepDescription;
    @observable stepName;
    @observable workflowComplete=false;
    @observable saved=false;
    @observable comment='';
    @observable tariffLogId;
    @observable purpose: any = {};
    
    @observable loader = false;

    @observable baseTariffShow=false;
    @observable tariffLogShow=false;
    @observable userName: any;

    constructor(
        public tariffOptionsDataService: TariffOptionsDataService,
        public agGridServiceMovement: AgGridService,
        public agGridServiceConnectionPoint: AgGridService,
        public agGridServiceDeduction: AgGridService,
        public agGridServiceLossAllowance: AgGridService,
        public agGridServiceRelatedTariff: AgGridService,
        public agGridServicePipeline: AgGridService,
        public agGridServiceOwner: AgGridService,
        public agGridServiceComments: AgGridService,
        public agGridServiceCommentsCTS: AgGridService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    @action
    async init(): Promise<void> {
        try{this.loader = true;
        this.baseTariffShow=true;
        this.tariffLogShow=true;
        // this.formDataSelection = {
        //     baseTariff: '',
        //     tariffLog: ''
        // }
        const state=history?.location?.state as {workflowId?: string, tariffNumber?: string, baseTariff?: string, tariffLogId?: number, action?: string, process?: boolean} || '';
        this.workflowId= state?.workflowId || null;
        this.workflowAction= state?.action || 'View';
        this.workflowProcess= state?.process || false;
        this.tariffLogId= state?.tariffLogId || null;
        this.tariffIndexRate=0;
        this.showUnsavedWarningAlert=false
        await this.getBaseTariffDropDownData();
        if(state?.baseTariff){
            await this.changeFormDataSelection(state.baseTariff, 'baseTariff');
        }
        if(state?.tariffNumber){
            this.formDataSelection = {
                baseTariff: state?.baseTariff || '',
                tariffLog: state?.tariffNumber
            }
        }
        this.selectedBaseTariffDetails=[];
        await this.getAgencyDropdown();
        this.getStatusDropdown();
        this.getTariffTypesDropdown();
        // this.getCompanyDropdown();
        this.getInvoiceBasisDropdown();
        await this.getPipelineAreasDropdown();
        this.getTariffRelationTypes();
        this.getMVTRateTypeDirection();
        this.getMVTRateBasis();
        this.getMVTRateType();        
        this.getTariffIndexRate();
        this.workflowStatusGridData=[]
        this.workflowStepId='';
        // this.getAdministratorDropdown();
        this.createdUser = this.accountStore.displayName;
        this.screenHeaderTabSelected = [];
        this.deduction= [];
        // if(this.workflowProcess===true){
            await this.getTariffOptions(true, state.tariffLogId || 0);
        // }
        if(this.workflowId){
            this.getWorkflowStatusGridData();
        }
        this.saved=false;
        this.comment='';
        dataStore.setShowUnsavedWarningAlert(false);
        this.loader=false;}catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
        this.userName = this.accountStore.displayName;
    }
    
    @action
    toggleBaseTariffShow() {
        this.baseTariffShow = !this.baseTariffShow
    }

    @action
    toggleTariffLogShow(){
        this.tariffLogShow = !this.tariffLogShow
    }

    @action
    unmount(){
        this.formData=[];
        this.formDataSelection = {
            baseTariff: '',
            tariffLog: ''
        }
        this.selectedBaseTariffDetails=[];
        this.baseTariffShow=false;
        this.tariffLogShow=false;
        this.loader=false;
    }

     
    @action
    @Catch((e)=>console.log(e))
    async getWorkflowStatusGridData(): Promise<void> {
        if(this.workflowId){
            const response = await this.tariffOptionsDataService.getWorkflowStatusGridData(this.workflowId);
            this.workflowStatusGridData=response;
            // this.workflowStepFlowId=response.find(x=>x.workflowstepStatus==='In Progress').workflowStepFlowId;
            this.workflowStepId = response.find(x=>x.workflowstepStatus==='In Progress')?.workflowstepId ||null;
            this.workflowStepFlowId=response.find(x=>x.workflowstepStatus==='In Progress')?.workflowStepFlowId || null;
            this.workflowComplete = response.filter(x=>x.workflowstepStatus==='Complete')?.length>=4? true: false;
            this.stepDescription=response.find(x=>x.workflowstepStatus==='In Progress')?.workflowstepDescription||this.stepDescription
            this.stepName=response.find(x=>x.workflowstepStatus==='In Progress')?.workflowstepName||this.stepName
        }
        this.statusOrHistory=true;
    }

     
    @action
    async getWorkflowHistoryStatusGridData(): Promise<void> {
        if(this.workflowId){
            const response = await this.tariffOptionsDataService.getWorkflowHistoryStatusGridData(this.workflowId);
            this.workflowStatusGridData=response;
        }
        this.statusOrHistory=false;
    }

    @action
    async saveComment(comment): Promise<void> {
        this.comment=comment;
    }

    @action
    changePurpose = (purpose) => {
        // this.purpose = purpose;
        console.log(purpose);
    }
     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async saveWorkflowComments(direction='approve', fromTariffOptions=false): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(direction==='reject' && !this.comment){
            this.uiService.toastService.error('Comment is required!');
            return;
        }
        // if(Object.values(this.formData).some(x=>!x.agency)){
        //     requiredFlag=true;
        //     requiredOptions.push('Agency');
        // }
        // if(Object.values(this.formData).some(x=>!x.tariffNo)){
        //     requiredFlag=true;
        //     requiredOptions.push('Tariff #');
        // }
        // if(Object.values(this.formData).some(x=>!x.type)){
        //     requiredFlag=true;
        //     requiredOptions.push('Type');
        // }
        // if(Object.values(this.formData).some(x=>!x.companyValue)){
        //     requiredFlag=true;
        //     requiredOptions.push('Company');
        // }
        // if(Object.values(this.formData).some(x=>!x.invoiceBasis)){
        //     requiredFlag=true;
        //     requiredOptions.push('Invoice Basis');
        // }
        // if(Object.values(this.formData).some(x=>!x.statusValue)){
        //     requiredFlag=true;
        //     requiredOptions.push('Status');
        // }
        // if(Object.values(this.formData).some(x=>!x.effectiveDate)){
        //     requiredFlag=true;
        //     requiredOptions.push('Effective Date');
        // }
        if(Object.values(this.ConnectionPointData).some(x=>x.some(y=>!y.accept))){
            requiredFlag=true;
            requiredOptions.push('Accept every line for Connection Point/for ALL Options');
        }
        if(Object.values(this.MovementData).some(x=>x.some(y=>!y.accept))){
            requiredFlag=true;
            requiredOptions.push('Accept every line for Movement for ALL Options');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        try{
        this.loader=true;
        if(!fromTariffOptions&&this.stepName==='Process & Submit for Approval'){
            this.saveTariffOption(true, direction);
        } else {
            if(this.comment){
                const response: any = await this.tariffOptionsDataService.saveWorkflowComments(this.workflowId, this.comment, this.createdUser, this.workflowStepFlowId);
                if(response){
                    let error ='';
                    for (const key in response) {                       
                        if (key==='value') {
                            error = response[key]; 
                        } else if (key==='statusCode' && response[key]===400) {
                            this.uiService.toastService.error(error);
                        } 
                    }
                    if(error===''){
                            // this.getWorkflowDetails();
                            this.sendEmailWorkflow(direction, this.comment);
                            this.getWorkflowStatusGridData();
                            this.comment=''
                            this.uiService.toastService.success('Comments Saved Successfully!');
                    }
                }
            } else {
                this.sendEmailWorkflow(direction, this.comment);
                this.getWorkflowStatusGridData();
            }
            this.loader=false;
            
            }
        } catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
    }

    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async saveOnlyComments(): Promise<void> {
        try{this.loader=true;
            if(this.comment){
                const response: any = await this.tariffOptionsDataService.saveWorkflowComments(this.workflowId, this.comment, this.createdUser, this.workflowStepFlowId);
                if(response){
                    let error ='';
                    for (const key in response) {                       
                        if (key==='value') {
                            error = response[key]; 
                        } else if (key==='statusCode' && response[key]===400) {
                            this.uiService.toastService.error(error);
                        } 
                    }
                    if(error===''){
                            // this.getWorkflowDetails();
                            this.getWorkflowStatusGridData();
                            this.comment=''
                            this.uiService.toastService.success('Comments Saved Successfully!');
                    }
                }
            }
            this.loader=false;}catch (e) {
                this.loader = false;
                this.uiService.toastService.error('Some error occurred. Check console for more details.');
                console.log(e);
            }
    }

    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmailWorkflow(direction :string, comment?): Promise<void> {
        try{this.loader=true;
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': direction,
            'criteria': '',
            'useremail': this.userName,
            'additionalInfo': comment ? comment : this.comment
        }
        const response: any = await this.tariffOptionsDataService.sendEmailWorkflow(requestBody);
        if(response){
            this.loader=false;    
            let error ='';
            for (const key in response) {                       
                if (key==='value' || (response[key].error) ) {
                    error =  key==='value' ? response[key] : response[key].error ; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error==='' || !error){
                    if(direction==='reject'){
                        this.uiService.toastService.success('Process Rejected Successfully!');
                    } else {
                        this.uiService.toastService.success('Processed Successfully!');
                    }
                    history.push('/tariffDashboard')
            } else {
                this.uiService.toastService.error(error);
            }
        }   
        this.loader=false;    }
        catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
    }

    @action
    async addNewTariffOption(){
        this.selectedTab=0;
        await this.getOwnerDropDown(this.pipelineAreasDropdown[0]?.pipelineID, 0);
        await this.getMVTLineSegment(this.pipelineAreasDropdown[0]?.pipelineID, 0);
        await this.getCPLocation(this.pipelineAreasDropdown[0]?.pipelineID, 0);
        this.formData=[...this.formData, {
                agency: this.agencyDropdown[0].agencycode,
                statusValue: 'Active',
                name: '',
                tariffNo: '',
                effectiveDate: '' as unknown as Date,
                administrator: this.ownerDropdownForOwner[0][0]?.customerName,
                type: 'Rates, Rules and Regulations',
                companyValue: this.ownerDropdownForOwner[0][0]?.customerName,
                invoiceBasis: 'Delivery',
                tariffIssueDate: '' as unknown as Date,
                cancelDate: '' as unknown as Date,
                cancelTariff: 'None',
                pipelineArea: this.pipelineAreasDropdown[0]?.plName,
                syncStatus: '',
                tariffOptionID: 0,
                new: true,
        }]
    }

    @action
    @Catch(() => errorHandler('Fetch Error. Please retry selecting Base Tariff And Tariff Log.'))
    async getTariffOptions(lastTabNavigation=false, tariffLogId=0): Promise<void> {
        try{
        this.loader = true;
        const response = await this.tariffOptionsDataService.getTariffOptions(this.baseTariffDropdown.find(x=>this.formDataSelection.baseTariff===x.baseTariff)?.tariffBaseID,tariffLogId!==0? tariffLogId : this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x=>x.tariffNumber===this.formDataSelection?.tariffLog)?.tariffLogID);
        if(Array.isArray(response)){
            this.tariffOptions = response;
            if(response.length>0 && lastTabNavigation===true){
                this.selectedTab=0;
                this.baseTariffShow=false;
                this.tariffLogShow=false;
            }
            this.formData = this.tariffOptions.map((x, index)=>{return{
                value1: (x.value1 || x.value1 !== null) ? x.value1 : (x.tariffNo?.split(' ')[0] && x.tariffNo?.split(' ')[0] || ''),
                value2: (x.value1 && x.value2 !== null) ? x.value2 : (x.tariffNo?.split(' ')[1] && x.tariffNo?.split(' ')[1] || ''),
                value3: (x.value1 && x.value3 !== null) ? x.value3 : (x.tariffNo?.split(' ')[2] && x.tariffNo?.split(' ')[2] || ''),
                value4: (x.value1 && x.value4 !== null) ? x.value4 : (x.tariffNo?.slice((x.tariffNo?.split(' ')[0]+x.tariffNo?.split(' ')[1]+x.tariffNo?.split(' ')[2]).length+3) || ''),
                agency: this.agencyDropdown.find(y=>y.agencyname===x.agency).agencycode,
                statusValue: x.statusValue,
                name: x.name,
                tariffNo: x.value1 && x.value1 + ' '+ (x.value2 || '') + ' ' + (x.value3 || '') + ' ' + (x.value4 || '') || x.tariffNo, 
                effectiveDate: x.effectiveDate && new Date(x.effectiveDate) || '',
                administrator: x.administratorValue,
                type: x.type,
                companyValue: x.companyValue,
                invoiceBasis: x.invoiceBasis,
                tariffIssueDate: x.tariffIssueDate && new Date(x.tariffIssueDate)||'',
                cancelDate: x.cancelDate && new Date(x.cancelDate)||'',
                cancelTariff: x.cancelTariff || 'None',
                pipelineArea: this.pipelineAreasDropdown?.find(y=>y.plSysCode===x.plArea)?.plName,
                syncStatus: x.syncStatus,
                tariffOptionID: x.tariffOptionID,
                createDate: x.createDate,
                createUser: x.createUser,
            }});
            this.tariffOptions.forEach((value,index)=>{
                this.getOwnerDropDown(this.pipelineAreasDropdown.find(y=>y.plSysCode=== value.plArea)?.pipelineID|| this.pipelineAreasDropdown[0]?.pipelineID, index, true)
                this.getMVTLineSegment(this.pipelineAreasDropdown.find(y=>y.plSysCode=== value.plArea)?.pipelineID|| this.pipelineAreasDropdown[0]?.pipelineID, index, true)
                this.getCPLocation(this.pipelineAreasDropdown.find(y=>y.plSysCode=== value.plArea)?.pipelineID|| this.pipelineAreasDropdown[0]?.pipelineID, index, true);
            })
            this.deduction = response.map(x=>x.deduction);
            this.deduction.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, existing: true, rowId: yindex }
                })
            });
            this.relatedTariffData = response.map(x=>x.relatedTariff);
            this.relatedTariffData.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, existing: true, rowId: yindex }
                })
            });
            this.lossAllowance=response.map((x)=>x.lossAllowance);
            this.lossAllowance.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, existing: true, rowId: yindex }
                })
            });
            this.ownerData=response.map((x)=>x.ownerOptions);
            this.ownerData.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, existing: true, system: y.plArea, index: index}
                })
            });
            this.pipelineAreaData=response.map(x=>x.pipelineOptions);
            this.pipelineAreaData.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, existing: true, rowId: yindex }
                })
            });
            this.connectionPointData=response.map(x=>x.connectionPoints);
            this.connectionPointDataBackup=[...response.map(x=>x.connectionPoints)||[]];
            // this.connectionPointData=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
            // this.connectionPointDataBackup=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
            this.connectionPointData.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, rowId: yindex, existing: true, accept: y.accept || false }
                })
            });
            // this.movementData=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
            // this.movementDataBackup=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
            this.movementData=response.map(x=>x.movemements);
            this.movementDataBackup=[...response.map(x=>x.movemements)||[]];
            this.movementData.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, rowId: yindex, existing: true, accept: y.accept || false }
                })
            });
            this.commentsData=response.map(x=>x.comments);
            this.commentsData.forEach((x,index, xarray)=>{
                x.forEach((y, yindex)=>{
                    xarray[index][yindex]={...y, rowId: yindex, existing: true }
                })
            });
            this.ctsCommentsData = response.map(x => x.ctsComments);
            this.ctsCommentsData = this.ctsCommentsData?.map((x, index, xarray) => {
                        x.forEach(p=>{this.purpose = {...this.purpose, [index]: p.purpose}})
                        const val = x.filter(item=>item.comments && item.comments != '').map((y, yindex) => {
                            return xarray[index][yindex] = { ...y, rowId: yindex, existing: true, index: index }
                        })
                        return val;
                    });
            this.loader = false;
        }else{
            this.uiService.toastService.error('Fetch error. Failed to fetch Tariff Options.');
            this.loader = false;
        }}catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
    }

    
    @action
    async getCancelledTariffs(): Promise<void> {
        const response = await this.tariffOptionsDataService.getCancelledTariffs(this.baseTariffDropdown.find(x=>this.formDataSelection.baseTariff===x.baseTariff)?.tariffBaseID,this.selectedBaseTariffDetails[0]?.tariffLogs.find(x=>x.tariffNumber===this.formDataSelection?.tariffLog)?.tariffLogID);
        this.cancelledTariffDropdown = response;
    }

    @action
    changeFormDataSelection(value, key, from='noLoop') {
        this.formDataSelection= {...this.formDataSelection, [key]: value}
        if(key==='baseTariff'){
            this.changeFormDataSelection('','tariffLog','loop');
            this.selectedBaseTariffDetails=[];
            this.getSelectedBaseTariffDetails();
            this.tariffOptions=[];
            this.formData=[];
        }
        if(key==='tariffLog'&&from==='noLoop'){
            this.getTariffOptions(true);
            this.getCancelledTariffs();
        }
    }

    @action
    changeFormData(value, key, index) {
        this.formData[index]= {...this.formData[index], [key]: value}
        if(key==='pipelineArea'){
            this.getOwnerDropDown(this.pipelineAreasDropdown.find(x=>x.plSysCode===value)?.pipelineID, index);
            this.getMVTLineSegment(this.pipelineAreasDropdown.find(x=>x.plSysCode===value)?.pipelineID, index);
            this.getCPLocation(this.pipelineAreasDropdown.find(x=>x.plSysCode===value)?.pipelineID, index);
        }
        this.showUnsavedWarningAlert=true;
    }

    @action
    async getBaseTariffDropDownData(): Promise<void> {
        const response = await this.tariffOptionsDataService.getBaseTariffs();
        this.baseTariffDropdown = response && Array.isArray(response) && [...new Map(response.map(item =>
            [item['baseTariff'], item])).values()] || [];  
    }

    
    @action
    async getSelectedBaseTariffDetails(): Promise<void> {
        const selectedBaseTariff = this.baseTariffDropdown.find(y=>{return this.formDataSelection.baseTariff===y.baseTariff});
        const baseTariffDetails = await this.tariffOptionsDataService.getSelectedBaseTariffDetails(selectedBaseTariff.tariffBaseID, this.workflowProcess, this.tariffLogId);
        this.selectedBaseTariffDetails = baseTariffDetails;
    }
 
    @action
    async getAgencyDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getAgencyDropdown();
        this.agencyDropdown = response;
        // agencyname
    }

    @action
    async getStatusDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getStatusDropdown();
        this.statusDropdown = response;
        // valueText
    }

    @action
    async getTariffTypesDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getTariffTypesDropdown();
        this.tariffTypesDropdown = response;
        // valueText
    }

    //  
    // @action
    // async getCompanyDropdown(): Promise<void> {
    //     const response = await this.tariffOptionsDataService.getCompanyDropdown();
    //     this.companyDropdown = response;
    //     // plName
    // }
    
    @action
    async getInvoiceBasisDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getInvoiceBasisDropdown();
        this.invoiceBasisDropdown = response;
        // valueText
    }
    
    @action
    async getPipelineAreasDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getPipelineAreasDropdown();
        this.pipelineAreasDropdown = response;
        // plName
    }

    
    @action
    async getTariffIndexRate(): Promise<void> {
        const response: any = await this.tariffOptionsDataService.getTariffIndexRate();
        this.tariffIndexRate = response.indexRate || '';
        // plName
    }
    
    

    @action
    async getTariffRelationTypes(): Promise<void> {
        const response = await this.tariffOptionsDataService.getTariffRelationTypes();
        this.tariffRelationTypes = response;
        // plName
    }
    
 

    @action
    async getMVTRateTypeDirection(): Promise<void> {
        const response = await this.tariffOptionsDataService.getMVTRateTypeDirection();
        this.mvtRateTypeDirection = response;
        // plName
    }

    @action
    async getMVTRateBasis(): Promise<void> {
        const response = await this.tariffOptionsDataService.getMVTRateBasis();
        this.mvtRateBasis = response;
        // plName
    }
    
     
    @action
    async getMVTRateType(): Promise<void> {
        const response = await this.tariffOptionsDataService.getMVTRateType();
        this.mvtRateTypes = response;
        // plName
    }
    
    
     
    @action
    async getCPArea(index, rowId, location): Promise<void> {
        const selectedBaseTariffDetails = this.selectedBaseTariffDetails[0]?.tariffLogs.find(x => x.tariffNumber === this.formDataSelection?.tariffLog);
        let response = await this.tariffOptionsDataService.getCPTariff(location);
        response = selectedBaseTariffDetails.testInd ? response : response.filter(x => x.syncStatus == 'Synced to PROD');
        if(Array.isArray(response)){
            this.cpArea[index]={...this.cpArea[index], [rowId]:[...response]};
        }
        // plName
    }
    
    

    
    //  
    // @action
    // async getAdministratorDropdown(): Promise<void> {
    //     const response = await this.tariffOptionsDataService.getAdministratorDropdown();
    //     this.administratorDropdown = response;
    //     // customerName
    // }

     
    @action
    async getOwnerDropDown(pipeline, index, check=false): Promise<void> {
        const response = await this.tariffOptionsDataService.getOwnerDropDown(pipeline);
        this.ownerDropdownForOwner = {...this.ownerDropdownForOwner, [index]: response};
        if(check===true){
            this.formData[index] = {...this.formData[index],
            administrator: this.ownerDropdownForOwner[index]?.map(x=>x.customerName)?.includes(this.formData[index]?.administrator)?this.formData[index]?.administrator:this.ownerDropdownForOwner[index]?.map(x=>x.customerName)[0],
            companyValue: this.ownerDropdownForOwner[index]?.map(x=>x.customerName)?.includes(this.formData[index]?.companyValue)?this.formData[index]?.companyValue:this.ownerDropdownForOwner[index]?.map(x=>x.customerName)[0],
            };
        }
        console.log(toJS(this.formData))
    }

     
    @action
    async getMVTLineSegment(pipeline, index, check=false): Promise<void> {
        const selectedBaseTariffDetails = this.selectedBaseTariffDetails[0]?.tariffLogs.find(x => x.tariffNumber === this.formDataSelection?.tariffLog);
        let response = await this.tariffOptionsDataService.getMVTLineSegment(pipeline);
        response = selectedBaseTariffDetails.testInd ? response : response.filter(x => x.syncStatus == 'Synced to PROD')
        this.mvtLineSegment = {...this.mvtLineSegment, [index]: response};
        // plName
    }

    
     
    @action
    async getCPLocation(pipeline, index, check = false): Promise<void> {
        const selectedBaseTariffDetails = this.selectedBaseTariffDetails[0]?.tariffLogs.find(x => x.tariffNumber === this.formDataSelection?.tariffLog);
        let response = await this.tariffOptionsDataService.getCPLocation(pipeline);
        response = selectedBaseTariffDetails.testInd ? response : response.filter(x => x.syncStatus == 'Synced to PROD')
        this.cpLineLocation = {...this.cpLineLocation, [index]: response};
        // plName
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveTariffOption(fromWorkflow=false, direction?): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(Object.values(this.formData).some(x=>!x.tariffNo)){
            requiredFlag=true;
            requiredOptions.push('Tariff # for ALL Options');
        }
        if(Object.values(this.RelatedTariffData).some(x=>x.some(y=>!y.relationship))){
            requiredFlag=true;
            requiredOptions.push('Relationship in Related Tariff Grid for ALL Options');
        }
        if(Object.values(this.RelatedTariffData).some(x=>x.some(y=>!y.agencyName))){
            requiredFlag=true;
            requiredOptions.push('Agency in Related Tariff Grid for ALL Options');
        }
        if(Object.values(this.RelatedTariffData).some(x=>x.some(y=>!y.number))){
            requiredFlag=true;
            requiredOptions.push('Number in Related Tariff Grid for ALL Options');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        try{this.loader=true;
        const requestBody : any [] = this.formData.map((x,index)=> {return {
                    'tariffOptionID': this.formData[index]?.tariffOptionID || 0, 
                    'name': this.formData[index]?.name, 
                    'tariffBaseID': this.baseTariffDropdown.find(x=>this.formDataSelection.baseTariff===x.baseTariff)?.tariffBaseID, 
                    'tariffLogID': this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x=>x.tariffNumber===this.formDataSelection?.tariffLog)?.tariffLogID, 
                    'agencyID': this.agencyDropdown.find(x=>x.agencycode===this.formData[index].agency)?.agencyid,
                    'tariffNo': this.formData[index].value1 && this.formData[index].value1 + ' '+ this.formData[index].value2 + ' ' + this.formData[index].value3 + ' ' + this.formData[index].value4 || this.formData[index]?.tariffNo, 
                    'typeID': this.tariffTypesDropdown.find(x=>x.valueText===this.formData[index].type)?.settingID, 
                    'companyID': this.ownerDropdownForOwner[index]?.find(x=>x.customerName===this.formData[index]?.companyValue)?.customerID, 
                    'invoiceBasisID': this.invoiceBasisDropdown.find(x=>x.valueText===this.formData[index]?.invoiceBasis)?.settingID, 
                    'pipelineArea': this.pipelineAreasDropdown.find(x=>x.plName===this.formData[index]?.pipelineArea)?.pipelineID, 
                    'status': this.statusDropdown.find(x=>x.valueText===this.formData[index]?.statusValue)?.settingID, 
                    'effectiveDate': this.formData[index]?.effectiveDate && moment(new Date(this.formData[index]?.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss') || null,
                    'tariffIssueDate': this.formData[index]?.tariffIssueDate && moment(new Date(this.formData[index]?.tariffIssueDate)).format('YYYY-MM-DDTHH:mm:ss')|| null,
                    'createDate': this.formData[index].createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    'createUser': this.formData[index].createUser || this.createdUser,
                    'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                    'updateUser': this.createdUser,
                    'value1': this.formData[index].value1,
                    'value2': this.formData[index].value2,
                    'value3': this.formData[index].value3,
                    'value4': this.formData[index].value4,
                    'cpOptions': this.ConnectionPointData[index].map((y) => {return {
                          'tariffCPID': y.tariffCPID,
                          'locationID': y.locationID,
                          'cpid': y.cpid,
                          'oldRate': y.oldRate,
                          'newRate': y.newRate,
                          'effectiveDate': y.effectiveDate,
                          'endDate': y.endDate,
                          'noChange': y.noChange,
                          'rateTypeID': y.rateTypeID,
                          'rateBasisID': y.rateBasisID,
                          'accept': y.accept,
                          'override': y.override || false
                        }
                    }),
                    'mvmtOptions': this.MovementData[index].map((y) => {return {
                            'tariffMvmtID': y.tariffMvmtID,
                            'segmentID': y.segmentID,
                            'rateDirectionID': y.rateDirectionID,
                            'oldRate': y.oldRate,
                            'newRate': y.newRate,
                            'effectiveDate': y.effectiveDate,
                            'endDate': y.endDate,
                            'cemaRate': y.cemaRate,
                            'rateTypeID': y.rateTypeID,
                            'rateBasisID': y.rateBasisID,
                            'tariffComments': y.tariffComments,
                            'noChange': y.noChange,
                            'accept': y.accept,
                            'override': y.override || false
                          }
                    }),
                    'relOptions': this.RelatedTariffData[index].map((x)=>{return{
                          'tariffRelID': x.tariffRelID || 0,
                          'relationship': x.relationship,
                          'agencyID': this.agencyDropdown.find(y=>y.agencyname===x.agencyName).agencyid, 
                          'number': x.number, 
                        }
                    })
            
        }});
        let response: any[] = []; 
        response = await this.tariffOptionsDataService.editTariffOptions(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
                this.loader=false;
            }else{
                this.getWorkflowStatusGridData();
                this.saved=true;
                this.uiService.toastService.success('Tariff Options Saved Successfully!')
                this.loader=false;
                if(fromWorkflow===true){
                    this.saveWorkflowComments(direction, true)
                } else {
                    if(this.comment){
                        this.saveOnlyComments()
                    }
                }
            }dataStore.setShowUnsavedWarningAlert(false);
            
        } this.loader=false;}catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
    }

    addComments(index, linkTariff=false){
        if(this.commentsData.length){
            this.commentsData[index]=[{
                'rowId': this.commentsData[index].length
            }, ...this.commentsData[index]]
        } else {
            this.commentsData[0]=[{
                'rowId': 0
            }]
        }
    }

    @computed
    get CommentsData() {
        return toJS(this.commentsData);
    }

    @computed
    get CTSCommentsData() {
        return toJS(this.ctsCommentsData);
    } 

    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteComments (requestBody, index): Promise<void>  {
        this.commentsData[index]=this.CommentsData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.optionCmntID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteComments(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions();
                    this.uiService.toastService.success('Comment/s Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }


     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveComments(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.CommentsData[index].every(x=>x.comments)){
            requiredFlag=true;
            requiredOptions.push('Comments');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.CommentsData[index].map((x)=>{
            return { 
            'optionCmntID': x.optionCmntID || 0, 
            'optionID': tariffOptionID, 
            'comments': x.comments, 
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser 
            }}
        )
        const response: any[] = await this.tariffOptionsDataService.saveComments(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Comments Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    
    addRelatedTariff(index, linkTariff=false){
        if(this.relatedTariffData.length){
            this.relatedTariffData[index]=[{
                'relationship': linkTariff===true?'General Rules/Regulations':'', 
                'agencyID': '', 
                'number': '', 
                'rowId': this.relatedTariffData[index].length
            }, ...this.relatedTariffData[index]]
        } else {
            this.relatedTariffData[0]=[{
                'relationship': '', 
                'agencyID': '', 
                'number': '', 
                'rowId': 0
            }]
        }
    }

    @computed
    get RelatedTariffData() {
        return toJS(this.relatedTariffData);
    }

    
     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteRelatedTariff (requestBody, index): Promise<void>  {
        this.relatedTariffData[index]=this.RelatedTariffData[index].filter(
        x=>requestBody.every(y=>x.rowId!==y.rowId));
        // const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffRelID);
        // if(slicedRequestBody.length > 0) {
        //     const response: any[] = await this.tariffOptionsDataService.deleteRelatedTariff(slicedRequestBody, this.createdUser);
        //     if(response){
        //         let errorFlag=false;let errorValue=''
        //         for(const key in response){
        //             if(key==='statusCode'&&response[key]===400){
        //                 errorFlag=true;
        //             }
        //             if(key==='value'){
        //                 errorValue=response[key];
        //             }
        //         } 
        //         if(errorFlag===true){
        //             this.uiService.toastService.error(errorValue)
        //         }else{
        //             this.getTariffOptions();
        //             this.uiService.toastService.success('Related Tariff Deleted Successfully!')
        //         }dataStore.setShowUnsavedWarningAlert(false);
        //     } 
        // }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveRelatedTariff(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.RelatedTariffData[index].every(x=>x.relationship)){
            requiredFlag=true;
            requiredOptions.push('Relationship');
        }
        if(!this.RelatedTariffData[index].every(x=>x.agencyName)){
            requiredFlag=true;
            requiredOptions.push('Agency');
        }
        if(!this.RelatedTariffData[index].every(x=>x.number)){
            requiredFlag=true;
            requiredOptions.push('Number');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.RelatedTariffData[index].map((x)=>{
            return { 
            'tariffRelID': x.tariffRelID || 0, 
            'tariffOptionID': tariffOptionID, 
            'relationship': x.relationship, 
            'agencyID': this.agencyDropdown.find(y=>y.agencyname===x.agencyName).agencyid, 
            'number': x.number, 
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser
            }}
        )
        const response: any[] = await this.tariffOptionsDataService.saveRelatedTariff(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Related Tariff Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }
   
    addDeduction(index){
        if(this.deduction.length){
            this.deduction[index]=[{
                'tariffDedID': '', 
                'tariffOptionID': '', 
                'begin': '', 
                'end': '', 
                'deduction': '',
                'rowId': this.deduction[index].length
            }, ...this.deduction[index]]
        } else {
            this.deduction[0]=[{
                'tariffDedID': '', 
                'tariffOptionID': '', 
                'begin': '', 
                'end': '', 
                'deduction': '', 
                'rowId': 0
            }]
        }
    }

    @computed
    get DeductionData() {
        return toJS(this.deduction);
    }

    
     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteDeduction (requestBody, index): Promise<void>  {
        this.deduction[index]=this.DeductionData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffDedID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteDeduction(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions();
                    this.uiService.toastService.success('Deduction Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveDeduction(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.DeductionData[index].every(x=>x.begin)){
            requiredFlag=true;
            requiredOptions.push('Begin');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.DeductionData[index].map((x)=>{
            return { 
            'tariffDedID': x.tariffDedID || 0, 
            'tariffOptionID': tariffOptionID, 
            'begin': x.begin, 
            'end': x.end ? (x.end) : null, 
            'deduction': x.deduction ? (x.deduction) : null, 
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveDeduction(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Deduction Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    
    
    addLossAllowance(index): boolean {
        if(index==='blank'){
            return false;
        }
        if(this.lossAllowance.length){
            this.lossAllowance[index]=[{
                'tariffLAID': 0, 
                'tariffOptionID': '', 
                'effectiveDate': '', 
                'endDate': '', 
                'lossAllowance': '',  
                'rowId': this.lossAllowance[index].length
            }, ...this.lossAllowance[index]]
        } else {
            this.lossAllowance[0]=[{
                'tariffLAID': 0, 
                'tariffOptionID': '', 
                'effectiveDate': '', 
                'endDate': '', 
                'lossAllowance': '',  
                'rowId': 0
            }]
        }
        return true;
    }

    @computed
    get LossAllowanceData() {
        return toJS(this.lossAllowance);
    }
    
    @action
    setLossAllowaneDate(value, rowId, key, index){
        this.lossAllowance=this.LossAllowanceData;
        this.lossAllowance[index][rowId][key] = value;
    }

     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteLossAllowance (requestBody, index): Promise<void>  {
        this.lossAllowance[index]=this.LossAllowanceData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffLAID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteLossAllowance(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions();
                    this.uiService.toastService.success('Loss Allowance Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveLossAllowance(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.LossAllowanceData[index].every(x=>x.effectiveDate)){
            requiredFlag=true;
            requiredOptions.push('Effective Date');
        }
        if(!this.LossAllowanceData[index].every(x=>x.lossAllowance)){
            requiredFlag=true;
            requiredOptions.push('Loss Allowance');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.LossAllowanceData[index].map((x)=>{
            return { 
            'tariffLAID': x.tariffLAID || 0, 
            'tariffOptionID': tariffOptionID, 
            'effectiveDate': x.effectiveDate && moment(new Date(x.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss')||null, 
            'endDate': x.endDate && moment(new Date(x.endDate)).format('YYYY-MM-DDTHH:mm:ss')||null, 
            'lossAllowance': x.lossAllowance?x.lossAllowance:null,  
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveLossAllowance(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Loss Allowance Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    
    
    addPipelineArea(index) {
        if(this.pipelineAreaData.length){
            this.pipelineAreaData[index]=[{
                'rowId': this.pipelineAreaData[index].length
            }, ...this.pipelineAreaData[index]]
        } else {
            this.pipelineAreaData[0]=[{
                'rowId': 0
            }]
        }
    }

    @computed
    get PipelineAreaData() {
        return toJS(this.pipelineAreaData);
    }

    
    
     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deletePipelineArea (requestBody, index): Promise<void>  {
        this.pipelineAreaData[index]=this.PipelineAreaData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffPLID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deletePipeline(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions();
                    this.uiService.toastService.success('Pipeline Area Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    
     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async savePipelineArea(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.PipelineAreaData[index].every(x=>x.plName)){
            requiredFlag=true;
            requiredOptions.push('Area');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.PipelineAreaData[index].map((x)=>{
            return { 
                'ctsID':x.tariffPLID || 0, 
                'optionID': tariffOptionID, 
                'id': this.pipelineAreasDropdown.find(y=>y.plSysCode=== x.plName)?.pipelineID || x.plAreaID, 
                'user': this.createdUser   
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.savePipelineArea(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Pipeline Area Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    addConnectionPoint(index) {
        if(this.connectionPointData.length){
            this.connectionPointData[index]=[{
                'rowId': this.connectionPointData[index].length,
                'noChange': true
            }, ...this.connectionPointData[index]]
        } else {
            this.connectionPointData[0]=[{
                'rowId': 0,
                'noChange': true
            }]
        }
    }

    @computed
    get ConnectionPointData() {
        return toJS(this.connectionPointData);
    }
    
        
     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteConnectionPoint (requestBody, index): Promise<void>  {
        this.connectionPointData[index]=this.ConnectionPointData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffCPID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteCP(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions();
                    this.uiService.toastService.success('Connection Point Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @action
    setConnectionPointData(value, rowId, key, index, params?){
        this.connectionPointData=this.ConnectionPointData;
        this.connectionPointData[index][rowId][key] = value;
        if(key==='noChange'){
            if(value===true){
                this.connectionPointData[index][rowId]['newRate'] = this.connectionPointData[index][rowId]['oldRate'];
                this.connectionPointData[index][rowId]['accept'] = false;
            }
            else if(value===false){
                this.connectionPointData[index][rowId]['newRate'] = this.connectionPointDataBackup[index][rowId]['newRate'];
                this.connectionPointData[index][rowId]['accept'] = false;
            }
            this.changeNewRateCPOverride(rowId, index, this.connectionPointDataBackup[index][rowId]['newRate'])
        }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveConnectionPoint(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.ConnectionPointData[index].every(x=>x.location)){
            requiredFlag=true;
            requiredOptions.push('Location');
        }
        if(!this.ConnectionPointData[index].every(x=>x.effectiveDate)){
            requiredFlag=true;
            requiredOptions.push('Effective Date');
        }
        if(!this.ConnectionPointData[index].every(x=>x.rateBasis)){
            requiredFlag=true;
            requiredOptions.push('Rate Basis');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.ConnectionPointData[index].map((x)=>{
            return { 
                'tariffCPID': x.tariffCPID || 0, 
                'tariffOptionID': tariffOptionID, 
                'locationID': this.cpLineLocation[index].find(y=>y.locnName===x.location)?.locationID || x.locationID, 
                'cpid': this.cpArea[index][x.rowId].find(y=>x.cpName===y.cpName)?.connectionPointID || x.cpid, 
                'oldRate': x.oldRate, 
                'newRate': x.newRate, 
                'effectiveDate': x.effectiveDate && moment(new Date(x.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'endDate': x.endDate && moment(new Date(x.endDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'noChange': x.noChange, 
                'rateTypeID': [...this.mvtRateTypes].find(y=>x.rateType===y.tariffRateDesc)?.tariffRateTypeID || x.rateTypeID, 
                'rateBasisID': [...this.mvtRateBasis].find(y=>x.rateBasis===y.valueText)?.settingID || x.rateBasisID,
                'tariffComments': x.tariffComments, 
                'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': x.createUser || this.createdUser, 
                'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'updateUser': this.createdUser 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveConnectionPoint(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Connection Point Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    
    addMovement(index) {
        if(this.movementData.length){
            this.movementData[index]=[{
                'rowId': this.movementData[index].length,
                'noChange': false
            }, ...this.movementData[index]]
        } else {
            this.movementData[0]=[{
                'rowId': 0,
                'noChange': false
            }]
        }
    }

    @computed
    get MovementData() {
        return toJS(this.movementData);
    }
    
      
    @action
    setMovementData(value, rowId, key, index, params?){
        this.movementData=this.MovementData;
        this.movementData[index][rowId][key] = value;
        if(key==='noChange'){
            if(value===true){
                this.movementData[index][rowId]['newRate'] = this.movementData[index][rowId]['oldRate'];
                this.movementData[index][rowId]['accept'] = false;
            }
            else if(value===false){
                this.movementData[index][rowId]['newRate'] = this.movementDataBackup[index][rowId]['newRate'];
                this.movementData[index][rowId]['accept'] = false;
            }
            this.changeNewRateMovementOverride(rowId, index, this.movementDataBackup[index][rowId]['newRate'])
        }
    }

     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteMovement (requestBody, index): Promise<void>  {
        this.movementData[index]=this.MovementData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffMvmtID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteMovement(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions();
                    this.uiService.toastService.success('Movement Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveMovement(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.MovementData[index].every(x=>x.lineSegDesc)){
            requiredFlag=true;
            requiredOptions.push('Segment');
        }
        if(!this.MovementData[index].every(x=>x.rateDirection)){
            requiredFlag=true;
            requiredOptions.push('Rate Direction');
        }
        if(!this.MovementData[index].every(x=>x.effectiveDate)){
            requiredFlag=true;
            requiredOptions.push('Effective Date');
        }
        if(!this.MovementData[index].every(x=>x.rateBasis)){
            requiredFlag=true;
            requiredOptions.push('Rate Basis');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.MovementData[index].map((x)=>{
            return { 
                'tariffMvmtID': x.tariffMvmtID || 0, 
                'tariffOptionID': tariffOptionID, 
                'segmentID': this.mvtLineSegment[index].find(y=>y.lineSegDesc===x.lineSegDesc)?.lineSegID || x.segmentID, 
                'rateDirectionID': this.mvtRateTypeDirection.find(y=>x.rateDirection===y.valueText)?.settingID || x.rateDirectionID, 
                'oldRate': x.oldRate, 
                'newRate': x.newRate, 
                'noChange':x.noChange,
                'effectiveDate': x.effectiveDate && moment(new Date(x.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'endDate': x.endDate && moment(new Date(x.endDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'cemaRate': x.cemaRate, 
                'rateTypeID': [...this.mvtRateTypes].find(y=>x.rateType===y.tariffRateDesc)?.tariffRateTypeID || x.rateTypeID, 
                'rateBasisID': [...this.mvtRateBasis].find(y=>x.rateBasis===y.valueText)?.settingID || x.rateBasisID, 
                'tariffComments': x.tariffComments, 
                'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': x.createUser || this.createdUser, 
                'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'updateUser': this.createdUser 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveMovement(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Movement Data Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }


    addOwner(index) {
        if(this.ownerData.length){
            this.ownerData[index]=[{
                system: this.formData[index].pipelineArea,
                index: index,
                'rowId': this.ownerData[index].length
            }, ...this.ownerData[index]]
        } else {
            this.ownerData[0]=[{
                system: this.formData[index].pipelineArea,
                index: 0,
                'rowId': this.ownerData[index].length
            }]
        }
    }

    @computed
    get OwnerData() {
        return toJS(this.ownerData);
    }

     
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteOwner (requestBody, index): Promise<void>  {
        this.ownerData[index]=this.OwnerData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffOwnID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteOwner(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions();
                    this.uiService.toastService.success('Owner Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveOwner(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.OwnerData[index].every(x=>x.custName)){
            requiredFlag=true;
            requiredOptions.push('Owner');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        } 
        const requestBody=  this.OwnerData[index].map((x)=>{
            return { 
                'ctsID':x.tariffOwnID || 0, 
                'optionID': tariffOptionID, 
                'id': this.ownerDropdownForOwner[index].find(y=>y.customerName===x.custName)?.customerID, 
                'user': this.createdUser   
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveOwner(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Owner Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async deleteTariffOptions(tariffOptionID): Promise<void> {
        const response: any[] = await this.tariffOptionsDataService.deleteTariffOptions(tariffOptionID,this.createdUser);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions(true);
                this.uiService.toastService.success('Tariff Option Deleted Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

     
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async cloneTariffOption(tariffOptionId,cloneAll): Promise<void> {
        const response: any[] = await this.tariffOptionsDataService.cloneTariffOption(tariffOptionId,this.createdUser,cloneAll==='all');
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions(true);
                this.uiService.toastService.success('Tariff Option Cloned Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }
    
    isEditDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isWorkflowStepSaveDisabled = (stepName)  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, stepName);
    };

    newRateIncorrectAlert = () => {
        this.uiService.toastService.error('Rate should never be negative and only upto 2 decimal places!')
    }

    changeNewRateCPOverride = (rowId, index, value) => {
        this.connectionPointData=this.ConnectionPointData;
        this.connectionPointData[index][rowId]['override'] = String(value)!==String(this.connectionPointDataBackup[index][rowId]['newRate']);
    }

    
    changeNewRateMovementOverride = (rowId, index, value) => {
        this.movementData=this.MovementData;
        this.movementData[index][rowId]['override'] = String(value)!==String(this.movementDataBackup[index][rowId]['newRate']);
    }
}
