import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { profitCenterColDef } from '../components/profit-center-col-def';
import moment from 'moment';
import Path from '../../../../Path';
// import Path, { ProfitCenterSettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { ProfitCenterMaintenanceDataService } from '../services/profit-center-maintenance-data-service';
import { ProfitCenterMaintenanceResponse } from '../model/model';
import { ProfitCenterMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { profitCenterMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
// import { AddProfitCenterDetailsColDef } from '../components/add-profitCenter-details-container';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';
import { AppConstant } from 'app_constants';

export class ProfitCenterDetailsDataStore {
    @observable selectedProfitCenterId: any;
    @observable selectedProfitCenterDetails: any;
    @observable formData = {
        pipelineName: null,
        profitCenter: '',
        validToDate: new Date('12-31-2040'),
        tariffArea:null,
        agency: null,
        profitCenterID: 0,
        extProfitCenterID: 0,
        client: '001',
        controlArea: '001',
        validFromDate: moment().format(AppConstant.API_DATE_FORMAT),
        createDate: null,
        createUser: '',
        updateDate: null,
        updateUser: null,
        syncStatus: SyncStatus[0],
        pipelineID: null,
        tariffRelProfitCenters:[],
        workflowId: 0
    };
    @observable showLoader = false;
    @observable baseSystemData: any = [];
    @observable baseSystemDropdownOption: any = [];
    @observable cellValueChangeMapForRelationshipGrid: any = {};
    @observable relationshipGridData: any[] = [];
    backupRelationshipGridData: any[] = [];
    navigateToScreen: any = null;
    workflowId: any = null;
    @observable isFormUpdated;
    navigateState: any;
    @observable tariffAreaSystemData: any = [];
    @observable tariffAreaSystemDropdownOptions: any = [];
    @observable tariffAgenciesData: any = [];
    @observable tariffAgenciesDropdownOptions: any = [];
    userName: any;
    
    constructor(
        public profitCenterMaintenanceDataService: ProfitCenterMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }
    init(): void {
        this.isFormUpdated = false
        this.loadInitialData().then(() => {
            const state=history?.location?.state as {from?: string; url?: string; workflowId?: string; pipeline? : any; profitCenterId? : any } || '';
            if(state.url) {
                this.navigateToScreen  = state.url;
                this.workflowId = state.workflowId ? state.workflowId : 0;
                this.navigateState = state;
            }
            if( state && state.profitCenterId) {
                
                this.getSelectedProfitCenterData(state.profitCenterId);
            }
            
            this.selectedProfitCenterId = 0;
            this.selectedProfitCenterDetails = {};
            this.userName = this.accountStore.displayName;
            history.push('/ProfitCenterDetails');

        });

    }

    async loadInitialData() {
        await this.getBaseSystemDropdown();
        await this.getTariffAreaSystem();
        await this.getTariffAgencies();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.profitCenterMaintenanceDataService.getPipelineSystems();
        if(baseSystemDropdownOptions) {
            this.baseSystemData = baseSystemDropdownOptions;
            const a = baseSystemDropdownOptions.map(x => {return { 'value': x.plName, 'label': x.plName}});
            this.baseSystemDropdownOption= a;
        }

       
    }
    
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffAreaSystem(): Promise<void> {
        const tariffAreaSystemDropdownOptions = await this.profitCenterMaintenanceDataService.getTariffArea();
        this.tariffAreaSystemData = tariffAreaSystemDropdownOptions;
        const systemsData = tariffAreaSystemDropdownOptions.map(x => {return { 'value': x.plName, 'label': x.plName}});
        runInAction(() => {
            this.showLoader = false;
            this.tariffAreaSystemDropdownOptions = systemsData;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffAgencies(): Promise<void> {
        const tariffAgenciesDropdownOptions = await this.profitCenterMaintenanceDataService.getTariffAgencies();
        this.tariffAgenciesData = tariffAgenciesDropdownOptions;
        const agenciesData = tariffAgenciesDropdownOptions.map(x => {return { 'value': x.agencycode, 'label': x.agencycode}});
        runInAction(() => {
            this.showLoader = false;
            this.tariffAgenciesDropdownOptions = agenciesData;
        })
    }

    setValueInChangeMapRelationshipGrid(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMapForRelationshipGrid)) {
            this.cellValueChangeMapForRelationshipGrid[row] = {};
        }
        if (!(col in this.cellValueChangeMapForRelationshipGrid[row] && this.cellValueChangeMapForRelationshipGrid[row][col].initValue))
            this.cellValueChangeMapForRelationshipGrid[row][col] = { initValue: initValue };
        else this.cellValueChangeMapForRelationshipGrid[row][col]['currentValue'] = newValue;
    }

    @action
    updateRowRelationship = (selectedRowData: any): void => {
        const updatedShopIndex = this.formData.tariffRelProfitCenters.findIndex((a: any) => a.profitCenterID == selectedRowData.profitCenterID);
        if (!_.isEqual(this.formData.tariffRelProfitCenters[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumns(this.formData.tariffRelProfitCenters[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.isSelected = updatedItem.isSelected;

    }
    @action
    linkedCPCheckboxHandler = (params, event, gridName?) => {
        const checked = event.target.checked;
            const row = params.data.relConnectionPointID;
            const initValue = params.data.isSelected
            if(checked)
            this.setValueInChangeMapRelationshipGrid(row, 'isSelected', initValue, checked);
            params.data.isSelected = checked;
            this.updateRowRelationship(params.data);
        
    };

    @action
    headerCheckBoxSelection = (event, gridName?) => {
        const checked = event.target.checked
            const filters =  this.agGridService.getFilterValue();
            const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.formData.tariffRelProfitCenters.filter((item : any) => ((filters.agencyCode && filters.agencyCode.values.includes(item.agencyCode)) || (filters.number && filters.number.values.includes(item.number)) || (filters.description && filters.description.values.include(item.description)) || (filters.statusText && filters.statusText.values.include(item.statusText)) || (filters.profitCenterText && filters.profitCenterText.values.include(item.profitCenterText)) || (filters.issueDate && filters.issueDate.values.include(item.issueDate)) || (filters.canceldate && filters.canceldate.values.include(item.canceldate)) || (filters.effectiveDate && filters.effectiveDate.values.include(item.effectiveDate)) || (filters.typeText && filters.typeText.values.includes(item.typeText)))) : this.formData.tariffRelProfitCenters;
            // const data = filteredData.map((item) => { item.isSelected = checked; return (item)})
            filteredData.forEach((item: any) => {
                if(checked)
                this.setValueInChangeMapRelationshipGrid(item.profitCenterID, 'isSelected', item.isSelected, checked);
                item['isSelected'] = checked;
                this.updateRowRelationship(item);
            });
       
    };

    @computed
    get RelationShipData() {
        return toJS(this.formData);
    }


    @action
    checkIfHeaderCheckboxShouldbeChecked = (params?, gridName?) => {
        const filters =  this.agGridService.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.formData.tariffRelProfitCenters.filter((item : any) => ((filters.agencyCode && filters.agencyCode.values.includes(item.agencyCode)) || (filters.number && filters.number.values.includes(item.number)) || (filters.description && filters.description.values.include(item.description)) || (filters.statusText && filters.statusText.values.include(item.statusText)) || (filters.profitCenterText && filters.profitCenterText.values.include(item.profitCenterText)) || (filters.issueDate && filters.issueDate.values.include(item.issueDate)) || (filters.canceldate && filters.canceldate.values.include(item.canceldate)) || (filters.effectiveDate && filters.effectiveDate.values.include(item.effectiveDate)) || (filters.typeText && filters.typeText.values.includes(item.typeText)))) : this.formData.tariffRelProfitCenters;
        return filteredData.length > 0 ? filteredData.some((item: any) => item.isSelected === false || !item.isSelected) : true
    }
    @action
    onCancelprofitCenterDetails() {
        if(this.navigateToScreen) {
            history.push(this.navigateToScreen, {workflowId : this.workflowId, tariffName: this.navigateState?.tariffName, tariffNumber: this.navigateState?.tariffNumber, baseTariff: this.navigateState?.baseTariff, tariffLogId : this.navigateState?.tariffLogId});
            dataStore.setShowUnsavedWarningAlert(false);
        } else {
            history.push('/ProfitCenterMaintenance', { from: '/ProfitCenterDetails' });   
        }
    }
    isSaveDisabled = ()  => {
      
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.ProfitCenterMaintenance);
    };

    validateRequest = () => {
        const localValidatingErrorBody: any = [];
        let isValidRequest = true;
        if(!this.formData.pipelineName || this.formData.pipelineName === '') {
            const message = 'Please select a valid Base System'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(!this.formData.profitCenter || this.formData.profitCenter === '') {
            const message = 'Please enter a valid Profit Center Code'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(this.formData.profitCenter && this.formData.profitCenter.length > 10) {
            const message = 'Profit Center Code max char length exceeds i.e. 10'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(!this.formData.validToDate || this.formData.validToDate.toString() === '') {
            const message = 'Please select a valid To Date'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        return {
            isValidRequest: isValidRequest, 
            localValidatingErrorBody: localValidatingErrorBody
        };
  
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSelectedProfitCenterData(profitCenterID): Promise<void> {
        const formData = await this.profitCenterMaintenanceDataService.getSelectedProfitCenterData(profitCenterID);
        if(formData) {
            if(formData.tariffRelProfitCenters.length > 0) {
                const systemsData = this.tariffAreaSystemData.filter((x) => x.pipelineID ===formData.tariffRelProfitCenters[0].tariffAreaID && x.valueCode === 'TA').map(x => {return { 'value': x.plName, 'label': x.plName}});
                this.tariffAreaSystemDropdownOptions = systemsData;
                const tariffArea = this.tariffAreaSystemData.find((x) => x.pipelineID === formData.tariffRelProfitCenters[0].tariffAreaID && x.valueCode === 'TA');
                const relationshipData = formData.tariffRelProfitCenters.filter(x => x.tariffAreaID === tariffArea.pipelineID);
                this.formData = {...formData, validToDate : new Date(formData.validToDate), tariffArea: tariffArea.plName, tariffRelProfitCenters:relationshipData };
            } else {
                const systemsData = this.tariffAreaSystemData.filter((x) => x.basePipelineID === formData.pipelineID && x.valueCode === 'TA').map(x => {return { 'value': x.plName, 'label': x.plName}});
                this.tariffAreaSystemDropdownOptions = systemsData;
                this.formData = {...formData, validToDate : new Date(formData.validToDate)};
            }
           
        }

       
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getRelatedGridData(profitCenterID, pipelineId, agencyId): Promise<void> {
        const relationshipData = await this.profitCenterMaintenanceDataService.getRelatedGridData(profitCenterID, pipelineId, agencyId);
        if(relationshipData) {
            this.formData = {...this.formData, tariffRelProfitCenters:relationshipData };
        }

       
    }

    resetData()  {
        this.formData ={
            pipelineName: null,
            profitCenter: '',
            validToDate: new Date('12-31-2040'),
            tariffArea:null,
            agency: null,
            profitCenterID: 0,
            extProfitCenterID: 0,
            client: '001',
            controlArea: '001',
            validFromDate: moment().format(AppConstant.API_DATE_FORMAT),
            createDate: null,
            createUser: '',
            updateDate: null,
            updateUser: null,
            syncStatus: SyncStatus[0],
            pipelineID: null,
            tariffRelProfitCenters:[],
            workflowId: 0
        };
        this.navigateState = null;
        this.navigateToScreen = null;
        this.workflowId = null;
        this.isFormUpdated = false;

    }


    @Loader
    @action
    @Catch(() =>  errorHandler(ProfitCenterMaintenanceMessages.FAILED_SUBMIT))
    async saveProfitCenter(): Promise<void> {
        // const updatedRowIDs = this.addOrEditLocationGridData.filter(a => a.isSelected === true);
        // const locationDetailDTOs: any = [];
        const { isValidRequest, localValidatingErrorBody} = this.validateRequest();
        if(isValidRequest) {
            const requestBody = {
                profitCenterID: this.formData.profitCenterID ? this.formData.profitCenterID : 0,
                extProfitCenterID: this.formData.extProfitCenterID ? this.formData.extProfitCenterID : 0,
                profitCenter: this.formData.profitCenter ?  this.formData.profitCenter : '',
                profitCenterDesc: this.formData.pipelineName ?  this.formData.pipelineName : '',
                client: '001',
                controlArea: '001',
                validToDate:  this.formData.validToDate ?  moment(this.formData.validToDate).format(AppConstant.API_DATE_FORMAT) : '',
                validFromDate: this.formData.validFromDate ? moment(this.formData.validFromDate).format(AppConstant.API_DATE_FORMAT) :  moment().format(AppConstant.API_DATE_FORMAT),
                createDate: this.formData.createDate ? moment(this.formData.createDate).format(AppConstant.API_DATE_FORMAT) :  moment().format(AppConstant.API_DATE_FORMAT),
                createUser: this.formData.createUser ?  this.formData.createUser: this.userName,
                updateDate: this.formData.profitCenterID &&  this.formData.profitCenterID !== 0 ? moment().format(AppConstant.API_DATE_FORMAT) : null,
                updateUser: this.formData.profitCenterID &&  this.formData.profitCenterID !== 0 ? this.userName : null,
                syncStatus: SyncStatus[0],
                pipelineID: this.formData.pipelineID ? this.formData.pipelineID : this.baseSystemData.filter((item) => item.plName === this.formData.pipelineName).pipelineID,
                pipelineName: this.formData.pipelineName,
                tariffRelProfitCenters: this.formData.tariffRelProfitCenters ?  this.formData.tariffRelProfitCenters : [],
                workflowId: this.workflowId ? this.workflowId : 0
              }
              const response = await this.profitCenterMaintenanceDataService.saveProfitCenter(requestBody);
              if(response) {
                this.isFormUpdated = false;
                if(response.tariffRelProfitCenters.length > 0) {
                    const systemsData = this.tariffAreaSystemData.filter((x) => x.pipelineID ===response.tariffRelProfitCenters[0].tariffAreaID && x.valueCode === 'TA').map(x => {return { 'value': x.plName, 'label': x.plName}});
                    this.tariffAreaSystemDropdownOptions = systemsData;
                    const tariffArea = this.tariffAreaSystemData.find((x) => x.pipelineID === response.tariffRelProfitCenters[0].tariffAreaID && x.valueCode === 'TA');
                    const relationshipData = response.tariffRelProfitCenters.filter(x => x.tariffAreaID === tariffArea.pipelineID);
                    this.formData = {...response, validToDate : new Date(response.validToDate), tariffArea: tariffArea.plName, tariffRelProfitCenters:relationshipData };
                } else {
                    const systemsData = this.tariffAreaSystemData.filter((x) => x.basePipelineID === response.pipelineID && x.valueCode === 'TA').map(x => {return { 'value': x.plName, 'label': x.plName}});
                    this.tariffAreaSystemDropdownOptions = systemsData;
                    this.formData = {...response, validToDate : new Date(response.validToDate)};
                }
                this.uiService.toastService.success(ProfitCenterMaintenanceMessages.SAVE);
              }
        } else {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } 
           
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(ProfitCenterMaintenanceMessages.SYNC_FAILED_SUBMIT))
    async pushSelectedRowsToVmacs(env): Promise<void> {
            const requestBody: any =  {
                env: env === 'Test' ? 'Push ProfitCenter to Vmacs Test' : 'Push ProfitCenter to Vmacs Prod',
                id: this.formData.profitCenterID,
                workflowid: '0'
              }
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.profitCenterMaintenanceDataService.pushToVmacs(requestBody);
                if(response == true) {
                    this.isFormUpdated = false;
                    this.uiService.toastService.success(ProfitCenterMaintenanceMessages.SYNC);
                    this.formData.syncStatus = env === 'Test' ? SyncStatus[1] : SyncStatus[2]
                } else {
                    this.isFormUpdated = false;
                    this.uiService.toastService.error(ProfitCenterMaintenanceMessages.SYNC_FAILED_SUBMIT);
                }
                    
            }
       
    }
}