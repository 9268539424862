import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import Multiselect from 'multiselect-react-dropdown';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker'
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import '../tariff-log-details.scss';

interface processOptionDetailsInterface {
    datastore: any;
    processOptionDatastore?: any;
    showOnlyUS: () => boolean;
    onChangePipeline: (selectedList: any, selectedItem: any) => void;
    allDisabled: boolean;
    checkRoleAccessSave?: boolean;
}

export const ProcessOptionDetails = (props: processOptionDetailsInterface) => {
    const checkRoleAccessSave = props.checkRoleAccessSave
    return (
        <div className='ProcessOptionDetailsBox'>
        <div className='screenHeader'>
                            <h2 className='screen_title'>Tariff Reference</h2>
                        </div>
                        <div className='selectContainer'>
                                <Row style={{'marginTop': '0px'}}>
                                    <Col style={{'marginTop': '10px', 'marginBottom': '10px'}}>
                                            <label className='standard_label_style'>Workflow ID</label>
                                            <input
                                            type='text'
                                            className='search_text_field'
                                            // onChange = {(e) => {}}
                                            value={props.datastore?.workflowId}
                                            readOnly
                                            />
                                    </Col>
                                    <Col style={{'marginTop': '10px'}}>
                                            <label className='standard_label_style'>Base Tariff</label>
                                            <input
                                            type='text'
                                            className='search_text_field'
                                            // onChange = {(e) => {}}
                                            value={props.datastore?.formData2a?.baseTariff}
                                            readOnly
                                            />
                                    </Col>
                                    <Col style={{'marginTop': '10px'}}>
                                        <label className='standard_label_style'>Tariff Number</label>
                                        <input
                                            type='text'
                                            className='search_text_field'
                                            // onChange = {(e) => {}}
                                            value={props.datastore?.formData2a?.tariffLog[0]}
                                            readOnly
                                            />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col></Col>
                                </Row>
                        </div>
        </div>
    )
}