import React, { Component } from 'react';
import {
    ColDef, CellEditingStoppedEvent, RowDataUpdatedEvent, FirstDataRenderedEvent
  } from 'ag-grid-community';
import { TariffOptionsDataStore } from '../stores/tariff-options-data-store';
import { Portal } from 'react-overlays';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { inject, observer } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from 'shared/components/header-component/component/history';
import { tariffOptionsDataStore } from '../stores';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { dataStore } from 'common/stores';

interface connectionPointInterface {
  tariffOptionsDataStore: TariffOptionsDataStore
  index: number,
  tariffOptionID: number,
  addConnectionPoint: (index: any) => void
}

interface calendarContainerInterface {
  children: any;
}

@inject('tariffOptionsDataStore')
@observer
export class ConnectionPoint extends Component<connectionPointInterface> {
  constructor(props) {
    super(props);
  }

  CalendarContainer = (props: calendarContainerInterface) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{props.children}</Portal>;
  };

   onChangeData = (params, value, key, index) => {
    const {tariffOptionsDataStore} = this.props;
    if(key==='effectiveDate'||key==='endDate'||key==='alwaysCharge'){
      const rowIndex = tariffOptionsDataStore.ConnectionPointData[index].findIndex(x=>x.rowId===params.data.rowId)
      tariffOptionsDataStore.setConnectionPointData(value, rowIndex, key, index);
      tariffOptionsDataStore.modifedRows[this.props.index] = {...this.props.tariffOptionsDataStore.modifedRows[this.props.index], cp: [...(tariffOptionsDataStore.modifedRows[this.props.index]?.cp||[])]}
      tariffOptionsDataStore.modifedRows[this.props.index]['cp'] = [...tariffOptionsDataStore.modifedRows[this.props.index]['cp'], params.data?.rowId] 
    // this.props.tariffOptionsDataStore.modifedRows[index]['cp'] = [...this.props.tariffOptionsDataStore.modifedRows[index]['cp'], params.data.rowId] 
    }
  } 
  
  colDefConnectionPoint: ColDef[] = [
      {
        headerName: 'Select',
        field: 'select',
        checkboxSelection: (params) => { return !params.data.isCloned},
        colId:'Select',
        width: 40,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}}
      },
      {
        headerName: 'Tariff CPID',
        field: 'tariffCPID',
        colId:'tariffCPID',
        tooltipField: 'tariffCPID',
        filter: true,
        sortable: true,
        editable: false,
        width: 110,
        minWidth: 110,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        singleClickEdit: true,
        flex: 1
      },
      {
      headerName: 'Location',
      field: 'location',
      colId:'location',
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      cellEditor: PredictiveSelectCellEditor,
      cellEditorParams: (params) => {
        return {
            dropDownValues: ['Unselect Location', ...this.props.tariffOptionsDataStore.cpLineLocation[this.props.index].map(x=>x.locnName)]
        };
      },
      valueFormatter:  (params) => {
        return params.data.location? params.data.location: this.props.tariffOptionsDataStore.cpLineLocation[this.props.index]?.length>0  ? 'Select Location' : 'No Location Found.'
      },
      valueSetter: (params) => {
        if(params.data.location!==params.newValue){
          params.data.cpName = '';
          params.data.cpTypeValue = '';
        } 
        if(params.newValue!=='Unselect Location'){
          params.data.location=params.newValue;
          return false
        } else {
          params.data.location='';
          params.data.cpName = '';
          params.data.cpTypeValue = '';
          return true;
        }
      },
      singleClickEdit: true,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      },
      { 
        headerName: 'Connection Point',
        field: 'cpName',
        colId:'cpName',
        tooltipField: 'cpName',
        // cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditor: PredictiveSelectCellEditor,
        valueFormatter:  (params) => {
          return params.data?.cpName? params.data.cpName : this.props.tariffOptionsDataStore.cpArea[this.props.index] && this.props.tariffOptionsDataStore.cpArea[this.props.index][params.data.rowId]?.length>0? 'Select CP': 'No CP Found'  
        },
        cellEditorParams:  (params) => {
          return {
            dropDownValues: ['Unselect Connection Point',...(this.props.tariffOptionsDataStore.cpArea[this.props.index] && this.props.tariffOptionsDataStore.cpArea[this.props.index][params.data.rowId]?.map(x=>x.cpName) || []) ]
          };
        },
        editable: (params) => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType() || params.data.cpTypeValue)},
        valueSetter: (params) => {
          if(params.newValue!=='Unselect Connection Point'){
            params.data.cpName=params.newValue;
            return true
          } else {
            params.data.cpName='';
            return true
          }
        },
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        minWidth: 130,
      },
      {
        headerName: 'CP Type',
        field: 'cpTypeValue',
        tooltipField: 'cpTypeValue',
        colId:'cpTypeValue', 
        filter: true,
        editable: (params) => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType() || params.data.cpName)},
        cellEditor: PredictiveSelectCellEditor,
        valueFormatter:  (params) => {
          return params.data.cpTypeValue? params.data.cpTypeValue : this.props.tariffOptionsDataStore.cpTypeDropdownOption?.length>0 ? 'Select CP Type': 'No CP Type Found'  
        },
        valueSetter: (params) => {
          if(params.data.cpTypeValue !== params.newValue){
            // params.data.cpName  = ''
          } 
          if(params.newValue!=='Unselect CP Type'){
            params.data.cpTypeValue=params.newValue;
          } else {
              params.data.cpTypeValue='';
              // params.data.cpName = ''
          }
          return true
        },
        cellEditorParams: (params) => {
          const cpTypeValue
          = this.props.tariffOptionsDataStore.cpArea[this.props.index] 
          && this.props.tariffOptionsDataStore.cpArea[this.props.index][params.data.rowId]?.find(x=>x.cpName===params.data.cpName)?.cpTypeName

          const filteredcpTypeDropdownOption = cpTypeValue && cpTypeValue !== '' ? this.props.tariffOptionsDataStore.cpTypeDropdownOption.filter((x) => x.valueText === cpTypeValue) : this.props.tariffOptionsDataStore.cpTypeDropdownOption;
          return {
            dropDownValues: ['Unselect CP Type',...filteredcpTypeDropdownOption.map(x=>x.valueText)]
          };
        },
        sortable: true,
        minWidth: 130,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        singleClickEdit: true,
      },{
        headerName: 'Rate Type',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        field: 'rateType',
        tooltipField: 'rateType',
        cellEditor: PredictiveSelectCellEditor,
        filter: true,
        editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
        sortable: true,
        singleClickEdit: true,
        cellEditorParams: (params) => {
          return {
            dropDownValues: ['Unselect Rate Type', ...this.props.tariffOptionsDataStore.cpRateTypes.map(x=>x.tariffRateDesc)]
          };
        },
        valueFormatter:  (params) => {
          return params.data.rateType? params.data.rateType : this.props.tariffOptionsDataStore.cpRateTypes?.length>0 ? 'Select Rate Type': 'No Rate Types Found'  
        },
        valueSetter: (params) => {
          if(params.newValue!=='Unselect Rate Type'){
            params.data.rateType=params.newValue;
            return true
          } else {
            params.data.rateType='';
            return true
          }
        },  
        minWidth: 135,
    },
      {
        headerName: 'Old Rate (cents)',
        field: 'oldRate',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        tooltipField: 'oldRate',
        valueSetter: (params) => {
          if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
            params.data.oldRate=params.newValue.toUpperCase();
            return true
          } else {
            this.props.tariffOptionsDataStore.newRateIncorrectAlert();
            return false
          }
        },
        filter: true,
        editable: false,
        sortable: true,
        width: 140,
        minWidth: 140,
        flex: 1
      },
      {
          headerName: 'New Rate (cents)',
          field: 'newRate',
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          tooltipField: 'newRate',
          valueSetter: (params) => {
            if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
              params.data.newRate=params.newValue.toUpperCase();
              return true
            } else {
              this.props.tariffOptionsDataStore.newRateIncorrectAlert();
              return false
            }
          },
          filter: true,
          editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
          sortable: true,
          width: 140,
          minWidth: 140,
          singleClickEdit: true,
          flex: 1
      },
      {
        headerName: 'Effective Date',
        field: 'effectiveDate',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        editable: false,
        // comparator: (a, b) => {
        //     const dateA = new Date(a);
        //     const dateB = new Date(b);
        //     if(dateA===dateB) return 0;
        //     else if(dateA>dateB) return 1;
        //     return -1           
        // },
        // sort: 'asc',
        // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
        valueFormatter: ({ value, data }) => '',
        cellRenderer: (params) => {
            return (params.data ? (<DatePicker
                  popperClassName='ag-custom-component-popup'
                  selected={params.data.effectiveDate && new Date(params.data.effectiveDate) || null}
                  onChange={(value)=>{this.onChangeData(params, value, 'effectiveDate', this.props.index)}}
                  popperContainer={this.CalendarContainer}
                  popperPlacement='top-start'
                  className='datePickerGridInput'
                  disabled ={this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                  // readOnly={this.props.tariffOptionsDataStore.ownersCreated}
                  />) : '')
        },
        singleClickEdit: true,
        width: 120,
        minWidth: 120,
        flex: 1
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      editable: false,
      // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
      cellRenderer: (params) => {
          return (params.data &&  (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.value && new Date(params.value) || null}
                onChange={(value)=>{this.onChangeData(params, value, 'endDate', this.props.index)}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                // readOnly={this.props.tariffOptionsDataStore.ownersCreated}
                className='datePickerGridInput'
                disabled={this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                />)) || params.value
      },
      width: 120,
      minWidth: 120,
      singleClickEdit: true,
      flex: 1
  },
      {
          headerName: 'Rate Basis',
          field: 'rateBasis',
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
          cellEditor: PredictiveSelectCellEditor,
          tooltipField: 'rateBasis',
          singleClickEdit: true,
          cellEditorParams: (params) => {
            return {
              dropDownValues: ['Unselect Rate Basis',...(this.props.tariffOptionsDataStore.mvtRateBasis && this.props.tariffOptionsDataStore.mvtRateBasis?.map(x=>x.valueText)||[])]
            };
          },
          valueFormatter:  (params) => {
            return params.data.rateBasis? params.data.rateBasis : this.props.tariffOptionsDataStore.mvtRateBasis?.length>0 ? 'Select Rate Basis': 'No Rate Basis Found'  
          },
          valueSetter: (params) => {
            if(params.newValue!=='Unselect Rate Basis'){
              params.data.rateBasis=params.newValue;
              return true
            } else {
              params.data.rateBasis='';
              return true
            }
          },  
          minWidth: 130,
      },
      {
        headerName: 'Always Charge',
        field: 'alwaysCharge',
        tooltipField: 'alwaysCharge',
        headerClass: 'text-center',
        editable: false,
        filter: true,
        sortable: true,
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.alwaysCharge===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.alwaysCharge, 'alwaysCharge', this.props.index)}} disabled = {this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}/>);
        }, 
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 130,
        singleClickEdit: true,
      },
      {
          headerName: 'Ref',
          field: 'ref',
          editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          tooltipField: 'ref',
          filter: true,
          sortable: true,
          width: 110,
      },    
    ];
    getGridConfigConnectionPoint = (gridId) => {
      const {
        tariffOptionsDataStore: { agGridServiceConnectionPoint }
      } = this.props;
      const {tariffOptionsDataStore} = this.props;
      const gridParams: any = {
        rowData: tariffOptionsDataStore.ConnectionPointData.length && tariffOptionsDataStore.ConnectionPointData[tariffOptionsDataStore.selectedTab]||[],
        columnDefs:this.colDefConnectionPoint, 
        domLayout: 'autoHeight',
        onRowDataUpdated: this.onRowDataUpdated,
        onCellEditingStopped: this.onCellEditingStopped,
        onFirstDataRendered: this.onFirstDataRendered,
        context : agGridServiceConnectionPoint
        };
      return agGridServiceConnectionPoint.getGridConfig(gridParams, gridId);
    }

    onRowDataUpdated = (event: RowDataUpdatedEvent) => {
      // const allColumnIds : any [] = [];
      // event.columnApi.getAllGridColumns().forEach((column, index) => {
      //   if(index!==0){
      //     allColumnIds.push(column.getId());
      //   }
      // });
      setTimeout(()=>event.columnApi.autoSizeColumns(['location','cpName','cpTypeValue','rateType','rateBasis','ref'], false), 1000);
    }

    onFirstDataRendered = async (event: FirstDataRenderedEvent): Promise<void> => {
      setTimeout(()=>event.columnApi.autoSizeColumns(['location','cpName','cpTypeValue','rateType','rateBasis','ref'], false), 1000);
    }

    onCellEditingStopped = async (event: CellEditingStoppedEvent): Promise<void> => {
      const rowId = event.data.rowId;
      const col = event.colDef.field || '';
      const value = event.value;
      // this.props.tariffOptionsDataStore.modifedRows[this.props.index]['cp'] = [...this.props.tariffOptionsDataStore.modifedRows[this.props.index]['cp'], rowId] 
      this.props.tariffOptionsDataStore.modifedRows[this.props.index] = {...this.props.tariffOptionsDataStore.modifedRows[this.props.index], cp: [...(this.props.tariffOptionsDataStore.modifedRows[this.props.index]?.cp||[])]}
      this.props.tariffOptionsDataStore.modifedRows[this.props.index]['cp'] = [...this.props.tariffOptionsDataStore.modifedRows[this.props.index]['cp'], event.data?.rowId] 
      // baseTariffDataStore.updateShowUnsavedWarningAlert();
      const rowIndex = this.props.tariffOptionsDataStore.ConnectionPointData[this.props.index].findIndex(x=>x.rowId===event.data?.rowId)
      if(col==='location'){
        const locationId = this.props.tariffOptionsDataStore.cpLineLocation[this.props.index]?.find(x=>value===x.locnName).locationID;
        await this.props.tariffOptionsDataStore.getCPArea(this.props.index, rowId, locationId, true);
      } 
      // else if(col==='cpName'){
      //   this.props.tariffOptionsDataStore.connectionPointData = this.props.tariffOptionsDataStore.ConnectionPointData;
      //   this.props.tariffOptionsDataStore.connectionPointData[this.props.index][rowIndex]['cpName'] = event.value;
      //  } 
       this.props.tariffOptionsDataStore.agGridServiceConnectionPoint.redrawRows();
       setTimeout(()=>event.columnApi.autoSizeColumns(['location','cpName','cpTypeValue','rateType','rateBasis','ref'], false), 1000);
    }

    deleteConnectionPoint = async (index) => {
      const { tariffOptionsDataStore } = this.props;
      const requestBody: any = await tariffOptionsDataStore.agGridServiceConnectionPoint.getSelectedRows();
      if(requestBody.length > 0) {
          console.log(requestBody);
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => tariffOptionsDataStore.deleteConnectionPoint(requestBody, index),
            'Delete',
            'Are you sure you want to delete the selected record/s?'
        );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {console.log(1)},
          ConfirmWithOK,
          {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }
  
render(): React.ReactNode {
    return (
    <>
      <Row>
      <Col><div className='LeftFlex'>
        {/* <label className="standard_label_style">Current Index Rate</label>
          <input
            type="text"
            className="search_text_field input"
            readOnly={true}
            value={this.props.tariffOptionsDataStore.tariffIndexRate}
          /> */}
        </div>
        </Col>
        <div className='rightFlex2'>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true ||  tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.props.addConnectionPoint(this.props.index)}}>
            Add Option Connection Point
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.props.tariffOptionsDataStore.saveConnectionPoint(this.props.index, this.props.tariffOptionID)}}>
            Save Connection Point
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.deleteConnectionPoint(this.props.index)}}>
            Remove Connection Point
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || tariffOptionsDataStore.readOnlyView || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.LocationMaintenance) || tariffOptionsDataStore.disabledFieldOnRulesandRegType() || tariffOptionsDataStore.isCreateNewEntityDisabled} onClick={()=>{history.push('/LocationDetails', {from: '/TariffOptions', url: '/TariffOptions',  tariffName: tariffOptionsDataStore.formDataSelection.tariffLog, tariffNumber: tariffOptionsDataStore.formDataSelection.tariffLog, baseTariff: tariffOptionsDataStore.formDataSelection.baseTariff, tariffLogId : tariffOptionsDataStore.tariffLogId, pipeline :  tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea })}}>
            Add Location
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || tariffOptionsDataStore.readOnlyView ||  !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ConnectionPointMaintenance) || tariffOptionsDataStore.disabledFieldOnRulesandRegType() || tariffOptionsDataStore.isCreateNewEntityDisabled} onClick={()=> { history.push('/ConnectionPointDetails', {from: '/TariffOptions', url: '/TariffOptions',  tariffName: tariffOptionsDataStore.formDataSelection.tariffLog, tariffNumber: tariffOptionsDataStore.formDataSelection.tariffLog, baseTariff: tariffOptionsDataStore.formDataSelection.baseTariff, tariffLogId : tariffOptionsDataStore.tariffLogId, pipeline: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea ? [{ 'value': tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea, 'label': tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea}] : null})}}>
            Add Connection Point
            </CustomButton>
        </div>
      </Row>
      <Row>
        <AgGridComponent gridConfig={this.getGridConfigConnectionPoint('Tariff Option Connection Point')}/>
      </Row>
      </>
    )}
  }