import React, { Component } from 'react';
import { AppConstant } from 'app_constants';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './tariff-rate-types.scss';
import {
    ColDef, RowSelectedEvent, CellEditingStoppedEvent
} from 'ag-grid-community';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import { TariffRateTypesDataStore } from '../stores/tariff-rate-types-data-store';
import moment from 'moment';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import momenttimezone from 'moment-timezone';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { tariffRateTypesDataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';

/* eslint-disable */
interface TariffRateTypesContainerProps {
    tariffRateTypesDataStore: TariffRateTypesDataStore;
}

interface TariffRateTypesContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowSelected: boolean
}

@inject('tariffRateTypesDataStore')
@observer
export class TariffRateTypesContainer extends Component<TariffRateTypesContainerProps, TariffRateTypesContainerState> {

    constructor(props: TariffRateTypesContainerProps | Readonly<TariffRateTypesContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowSelected: false
        };
        dataStore.setModuleName('Tariff Rate Types')
    }

    componentDidMount() {
      const {tariffRateTypesDataStore} = this.props;
      tariffRateTypesDataStore.init();
    }
    
    TariffRateTypesGridColDef: ColDef[] = [
        {   
            headerName: 'Select',
            field: 'select', 
            checkboxSelection: (params) => {return params.data ? true: false}, 
            cellRenderer: 'checkboxRenderer', 
            width: 40, 
            headerCheckboxSelection: true, 
            headerCheckboxSelectionFilteredOnly: true,
            cellStyle: () => {return {padding: '10px'}},
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
          },
          { 
            headerName: 'Ferc Type', 
            field: 'fercType',
            tooltipField: 'Ferc Type', 
            filter: true, 
            sortable: true,
            editable: (params) => {return params.data.action === 'D'? false: true},
            minWidth: 160,
            flex: 1,
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : this.props.tariffRateTypesDataStore.isEditableSettingNameFercType(params) && params.data?  'cell-editable' : ''},

        },
        { 
            headerName: 'Tariff Rate Type', 
            field: 'tariffRateType',
            tooltipField: 'Tariff Rate Type', 
            minWidth: 160,
            editable: (params) => {return params.data.action === 'D'? false: true},
            filter: true, 
            sortable: true,
            flex: 1,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
            valueSetter: (params) => {
                if(params.newValue.match(/[a-zA-Z]/)&&params.newValue.length<=3){
                    params.data.tariffRateType=params.newValue.toUpperCase();
                    return true;
                }else{
                    tariffRateTypesDataStore.throwTariffRateTypeLengthError()
                    return false;
                }
            }
        },
        { 
            headerName: 'Tariff Rate Desc', 
            field: 'tariffRateDesc',
            tooltipField: 'Tariff Rate Desc',
            minWidth: 200, 
            filter: true, 
            sortable: true,
            editable: (params) => {return params.data.action === 'D'? false: true},
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
            flex: 2,
        },
        { 
            headerName: 'Service Basis', 
            field: 'serviceBasis',
            tooltipField: 'Service Basis',
            minWidth: 125, 
            filter: true, 
            sortable: true,
            editable: (params) => {return params.data.action === 'D'? false: true},
            flex: 1,
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : this.props.tariffRateTypesDataStore.isEditableSettingNameServiceBasis(params) && params.data?  'cell-editable' : ''},
            valueFormatter: (params) => {
                return (params.data?.serviceBasis==="Receipt" || params.data?.serviceBasis==="R") ? "R" :(params.data?.serviceBasis==="Delivery" || params.data?.serviceBasis==="D")? "D" : ""
            }
        },
        { 
            headerName: 'PR Summary', 
            field: 'prSummaryAction',
            tooltipField: 'PR Summary',
            //cellRenderer: PipelineDetailCellRenderer, 
            minWidth: 125,
            flex: 1, 
            filter: true, 
            sortable: true,
            editable: (params) => {return params.data.action === 'D'? false: true},
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : this.props.tariffRateTypesDataStore.isEditableSettingNamePRSummary(params) && params.data?  'cell-editable' : ''},
        },
        {   
            headerName: 'Mvmt x', 
            field: 'mvmt',
            headerClass: 'text-center',
            tooltipField: 'Mvmt x', 
            editable: (params) => {return params.data.action === 'D'? false: true},
            cellRenderer: params => {
            return (<input type='checkbox' disabled={params.data.action === 'D'||false} checked={params.data.mvmt===true} onClick={()=>{this.props.tariffRateTypesDataStore.mvmtClicked(params)}}/>);
            }, 
            width: 100,
            suppressSizeToFit: true,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
            cellStyle: () => {return {padding: '10px', justifyContent: 'center'}}
        },
        { 
            headerName: 'Created User', 
            field: 'createUser', 
            colId: 'createUser',
            filter: true, 
            sortable: true,
            editable: false,
            width: 120,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['createUser'] === null) {
                    return '';
                } else {
                    return params.data['createUser'];
                }
            }
        },
        { 
            headerName: 'Created Date', 
            field: 'createDate', 
            colId: 'createDate',
            filter: true, 
            sortable: true, 
            editable: false,
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['createDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
             comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            }
        },
        { 
            headerName: 'Updated User', 
            field: 'updateuser', 
            colId: 'updateuser',
            filter: true, 
            sortable: true,
            editable: false,
            width: 120,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['updateuser'] === null) {
                    return '';
                } else {
                    return params.data['updateuser'];
                }
            }
        },
        { 
            headerName: 'Updated Date', 
            field: 'updatedate', 
            colId: 'updatedate',
            filter: true, 
            sortable: true, 
            editable: false,
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['updatedate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['updatedate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
            comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            },
            sort: 'desc'
        },
        { 
            headerName: 'Push to VMACS', 
            field: 'PushToVMACS', 
            colId:'PushToVMACS',
            editable: dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd),
            cellRenderer: PushVmacsButtonComponent,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
            suppressSizeToFit: true,
            hide: !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)) ? true : false,
            cellRendererParams: {
                dataStore : tariffRateTypesDataStore,
                updatedData : ()=>tariffRateTypesDataStore.getUpdatedRows(),
                handleClickPushVmacs : (env, data) => tariffRateTypesDataStore.pushToVmacs(env, data),
            }
        },
        { 
            headerName: 'Sync Status', 
            field: 'syncStatus', 
            colId: 'syncStatus',
            editable: false, 
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['syncStatus'] === null) {
                    return '';
                } else {
                    return params.data['syncStatus'];
                }
            }
        },
        { 
            headerName: 'VMACS Test Sync Date', 
            field: 'testSyncDate', 
            colId: 'testSyncDate',
            editable: false,
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (!params.data['testSyncDate']) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['testSyncDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
            comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            },
        },
        { 
            headerName: 'VMACS Prod Sync Date', 
            field: 'prodSyncDate', 
            colId: 'prodSyncDate',
            editable: false,
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (!params.data['prodSyncDate']) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['prodSyncDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
            comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            },
        }    
  ];
  

    getColDef() {
      return this.TariffRateTypesGridColDef
    }


    getGridConfig(gridId) {
        const {
            tariffRateTypesDataStore: { tariffRateTypesData, agGridService }
        } = this.props;
        const { tariffRateTypesDataStore } = this.props;
        const pMGridParams: any = {
          rowData: tariffRateTypesData,
          columnDefs:this.getColDef(),
          onRowSelected: this.onRowSelected,
          onCellEditingStopped: this.onCellEditingStopped,
          };
        return agGridService.getGridConfig(pMGridParams, gridId);
      }

      onRowSelected = (event: RowSelectedEvent): void => {
        this.setState({...this.state, rowSelected: (event.api.getSelectedNodes().length>=1)});
      }

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const { tariffRateTypesDataStore } = this.props;
        // const rowId = event.data.rowId;
        // const rowData = event.data;
        // const col = event.colDef.field || '';
        // const value = event.value;
        tariffRateTypesDataStore.setUpdatedRowIds([...tariffRateTypesDataStore.updatedRows, event.data.rowId])
    };

      onAddRow() {
        const {
            tariffRateTypesDataStore: { TariffRateTypesData, agGridService }
        } = this.props;
        const { tariffRateTypesDataStore } = this.props;
        const rowData = [{
            rowId: tariffRateTypesDataStore.TariffRateTypesData.sort((a,b)=>b.rowId-a.rowId)[0]?.rowId + 1 || 0,
            // createUser: tariffRateTypesDataStore.username,
            createUser: tariffRateTypesDataStore.username,
            createDate: new Date(),    
            updateuser: null,
            updatedate: null,
            newRecord: true,
            mvmt: false,
            }, ...TariffRateTypesData
           ];
          tariffRateTypesDataStore.setGridData(rowData);
    }
              
    deleteRateTypes = async () => {
        const { tariffRateTypesDataStore } = this.props;
        const requestBody: any = await tariffRateTypesDataStore.agGridService.getSelectedRows();
        if(requestBody.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => tariffRateTypesDataStore.deleteRateTypes(),
              'Delete/Deactivate',
              'Are you sure you want to Delete ALL selected \'Unsaved\' records, and/or Deactivate ALL selected \'Existing\' record/s?'
          );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'No records are selected. Please select atleast one record to perform deactivation.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        }
   }
    

    render(): React.ReactNode {
        const { tariffRateTypesDataStore } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className='TariffRateTypesBox'>
                            <div className='screenHeader'>
                            <h2 className='screen_title'> Tariff Rate Types</h2>
                            </div>
                            <div className='selectContainer'>
                                <div className='settingSelectionDiv'>
                                    <label className="standard_label_style">Ferc Type</label>
                                    <ReactSelect
                                        className='select'
                                        values={tariffRateTypesDataStore.selectedFercType}
                                        placeholder="Select"
                                        options={[{ value: 'All', label: 'All' }, ...tariffRateTypesDataStore.fercTypesDropwdownUI]}
                                        onChange={(e: any) => {tariffRateTypesDataStore.setFercType(e)}}
                                        isMulti={false}
                                        isSearchable={true}
                                    />

                                    <label className="standard_label_style">Rate Types</label>
                                    <ReactSelect
                                        className='select'
                                        values={tariffRateTypesDataStore.selectedRateType}
                                        placeholder="Select"
                                        options={[{ value: 'All', label: 'All' }, ...tariffRateTypesDataStore.rateTypesDropdown]}
                                        onChange={(e: any) => {tariffRateTypesDataStore.setRateType(e)}}
                                        isMulti={false}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className='searchButtonDiv'>
                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{tariffRateTypesDataStore.getRateTypesGridData()}}>
                                        Search
                                    </CustomButton>
                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{tariffRateTypesDataStore.clearScreen()}}>
                                        Clear
                                    </CustomButton>
                                    <div className="otherHeaderButtonsDiv"> 
                                        <CustomButton type={CustomButtonType.Submit} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)} onClick={()=>{tariffRateTypesDataStore.SaveRateTypes()}}>
                                            Save
                                        </CustomButton>
                                        <CustomButton type={CustomButtonType.Submit} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)} onClick={()=>{this.onAddRow()}}>
                                            Add New Rate Type
                                        </CustomButton>
                                        <CustomButton type={CustomButtonType.Submit} disabled={!this.state.rowSelected ||!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate) } onClick={()=>{this.deleteRateTypes()}}>
                                        {/* disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)} */}
                                            Delete / Deactivate
                                        </CustomButton>
                                        <CustomButton type={CustomButtonType.Submit} disabled={!this.state.rowSelected || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate) } onClick={()=>{tariffRateTypesDataStore.activateRateTypes()}}>
                                            Activate
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfig('Tariff Rate Types')}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
    );
}}
