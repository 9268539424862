import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class TariffDashboardDataService {
constructor(private restApi: RestApiService) {}

    async getTariffDashboardData(agencyId , ownerCarrier, pipelineId, tariffEffectiveDate, ctsEffectiveDate, tariffNumber, tariffClassId, status, tariffStatus, defaultLocation, baseTariffId, jointCarrierId, logId, enteredByUser): Promise<any[]> {
        const logID = !logId || logId === '' ? 0 : logId
        const { data } = await this.restApi.get<any>(`${Path.getTariffDashboardData}?tariffAgencyId=${agencyId}&pipelineId=${pipelineId}&ownerCarrier=${ownerCarrier}&tariffEffectiveDate=${tariffEffectiveDate}&ctsEffectiveDate=${ctsEffectiveDate}&tariffNumber=${tariffNumber}&tariffClassId=${tariffClassId}&status=${status}&tariffStatus=${tariffStatus}&jurisdiction=${defaultLocation}&baseTariffId=${baseTariffId}&JTID=${jointCarrierId}&LogID=${logID}&strUser=${enteredByUser}`);
        return data;
    }

    async getInprogressTariffLogs(juridictionName): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.getInprogressTariffLog}?jurisdiction=${juridictionName}`);
        return data;
    }

    async getAgencyDropdown(juridictionName): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.url_id9}?jurisdiction=${juridictionName}&agency=All&agencyCode=All`);
        return data;
    }

    async getPipelineSystem(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_1_getPipelines);
        return data;
    }

    async getTariffClassData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLogEntryTypeData);
        return data;
    }

    async getTariffStatusData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffLogStatusData);
        return data;
    }

    // async getStatusData(): Promise<any[]> {
    //     const { data } = await this.restApi.get<any>(Path.getCTSStatus);
    //     return data;
    // }

    async getOwnerCarrierData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_ownerDropdown}?pipelineId=0`);
        return data;
    }

    async getBaseTariffData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseTariffJointCarriers);
        return data;
    }

    async tariffDashboardProcessLog(logId, username): Promise<any> {
        const { data } = await this.restApi.post<any>(`${Path.tariffDashboardProcessLog}?logId=${logId}&user=${username}`);
        return data;
    }

}

export const tariffDashboardDataService = new TariffDashboardDataService(restApiService);