import React from 'react';
// import './delete-ag-grid-row.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';

interface DeleteRowProps extends ICellRendererParams {
    onDeleteRowCallback: (data) => void;
    disableDeleteBtn: boolean;
}

export const DeleteRowCellRenderer = (props: DeleteRowProps) => {
    console.log(props);
    return (
        <div>
            <CustomButton type={CustomButtonType.Submit} onClick={() => props.onDeleteRowCallback(props.data)} disabled={props.disableDeleteBtn} >
                Delete
            </CustomButton>
        </div >
    );
};