import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { filingEntityMaintenanceDataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { FilingEntityCellRenderer } from './cell-renderers/filing-entity-cell-renderer';

const filterParams = {
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
    textFormatter: (r) => {
        if (r == null) return null;
        return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    suppressAndOrCondition: true,
};
  
export const filingEntityColDef: ColDef[] = [
    { 
        headerName: 'Filing Entity', 
        field: 'filingEntity',
        colId: 'filingEntity',
        filter: true, 
        sortable: true,
        editable: false, 
        filterParams: filterParams, 
        cellRenderer: FilingEntityCellRenderer,
        minWidth: 250, 
        flex: 1,
        valueGetter: params => {
            if (!params.data || !params.data['filingEntity']) {
                return '';
            } else {
                return params.data['filingEntity'];
            }
        }
    },
    { 
        headerName: 'Pipeline System', 
        field: 'pipelineSystem',
        colId: 'pipelineSystem',
        filter: true, 
        sortable: true, 
        minWidth: 250,
        flex: 1,
        valueGetter: params => {
            if (!params.data || !params.data['pipelineSystem']) {
                return '';
            } else {
                return params.data['pipelineSystem'];
            }
        },
        suppressSizeToFit: true
    },
    // { 
    //     headerName: 'Created User', 
    //     field: 'createUser', 
    //     colId: 'createUser', 
    //     filter: true, 
    //     sortable: true, 
    //     editable: false,
    //     width: 130,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['createUser'] === null) {
    //             return '';
    //         } else {
    //             return params.data['createUser'];
    //         }
    //     }
    // },
    // { 
    //     headerName: 'Created Date',
    //     field: 'createDate',
    //     colId: 'createDate',  
    //     filter: true, 
    //     sortable: true,
    //     editable: false,
    //     width: 120,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['createDate'] === null) {
    //             return '';
    //         } else {
    //             return moment(params.data['createDate']).format(AppConstant.DATE_TIME_FORMAT);
    //         }

    //                } 
    // },
    // { 
    //     headerName: 'Updated User', 
    //     field: 'updateuser',
    //     colId: 'updateuser',   
    //     filter: true, 
    //     sortable: true,
    //     editable: false,
    //     width: 120,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['updateuser'] === null) {
    //             return '';
    //         } else {
    //             return params.data['updateuser'];
    //         }
    //     }
    // },
    // { 
    //     headerName: 'Updated Date', 
    //     field: 'updatedate', 
    //     colId: 'updatedate',   
    //     filter: true, 
    //     sortable: true, 
    //     editable: false,
    //     width: 130,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['updatedate'] === null) {
    //             return '';
    //         } else {
    //             // return momenttimezone
    //             // .tz(moment.utc(params.data['updatedate']), AppConstant.CST_TIME_FORMATTER)
    //             // .format(AppConstant.DATE_TIME_FORMAT);

    //             return moment(params.data['updatedate']).format(AppConstant.DATE_TIME_FORMAT);
    //         }
    //     }  
    // },
   
    // { 
    //     headerName: 'Sync Status', 
    //     field: 'syncStatus', 
    //     colId: 'syncStatus',
    //     width: 130,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['syncStatus'] === null) {
    //             return '';
    //         } else {
    //             return params.data['syncStatus'];
    //         }
    //     }
    // },
    // { 
    //     headerName: 'VMACS Test Sync Date', 
    //     field: 'testSyncDate', 
    //     colId: 'testSyncDate',
    //     editable: false,
    //     width: 145,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (!params.data['testSyncDate']) {
    //             return '';
    //         } else {
    //               return moment(params.data['testSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
    //         }
    //     },
    //     suppressSizeToFit: true  
    // },
    // { 
    //     headerName: 'VMACS Prod Sync Date', 
    //     field: 'prodSyncDate', 
    //     colId: 'prodSyncDate',
    //     editable: false,
    //     width: 145,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (!params.data['prodSyncDate']) {
    //             return '';
    //         } else {
    //             return moment(params.data['prodSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
    //         }
    //     },
    //     suppressSizeToFit: true  
    // }
];

