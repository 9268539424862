import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class TariffRateTypesDataService {
    constructor(private restApi: RestApiService) {}

    async getRateTypesGridData(fercTypeId, rateTypeId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.rateTypeGrid+`?fercTypeID=${fercTypeId}&rateTypeID=${rateTypeId}`);
        return data;
    }
    
    async getFercTypesDropDownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getFercTypes);
        return data;
    }

    async getRateTypesDropDownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.rateType);
        return data;
    }

    async getPRSummaryDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.prSummaryAction);
        return data;
    }

    async getActivityType(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.activityType);
        return data;
    }

    async updateRateTypes(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.rateType, reqbody, {});
        return data;
    }    

    async deleteRateTypes(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.rateType, reqbody, {});
        return data;
    }    

    async activateRateTypes(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.rateType, reqbody, {});
        return data;
    }  
    
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsLocationEndpoint, reqbody, {});
        return data;
    }  

}

export const tariffRateTypesDataService = new TariffRateTypesDataService(restApiService);
