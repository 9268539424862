import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { locationMaintenanceDataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { LocationDetailCellRenderer } from './cell-renderers/location-name-cell-renderer';

export const locationColDef: ColDef[] = [
    {   
        headerName: 'Select',
        field: 'select', 
        colId:'Select',
        checkboxSelection:  true, 
        // cellRenderer: 'checkboxRenderer', 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 40, 
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true
    },
    { 
        headerName: 'Pipeline', 
        field: 'plName',
        colId: 'plName',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        // cellEditor: 'agSelectCellEditor', 
        minWidth: 110, 
        // cellClass: (params) => {return locationMaintenanceDataStore.isEditableJurisdiction(params) ?  'cell-editable' : ''}, 
        // cellStyle: (params) => {
        //     if (params.data.settingID <= 0 && !params.data.settingName) {
        //         return {border: '1px solid #0091ea'};
        //     }
        //     return null;
        // },
        valueGetter: params => {
            if (params.data['plName'] === null) {
                return '';
            } else {
                return params.data['plName'];
            }
        }
    },
    { 
        headerName: 'Location Name', 
        field: 'locnName',
        colId: 'locnName',
        filter: true, 
        sortable: true, 
        cellRenderer: LocationDetailCellRenderer,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['locnName'] === null) {
                return '';
            } else {
                return params.data['locnName'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Location Code', 
        field: 'locnCode', 
        colId: 'locnCode',
        filter: true, 
        sortable: true, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' :  ''},
        valueGetter: params => {
            if (params.data['locnCode'] === null) {
                return '';
            } else {
                return params.data['locnCode'];
            }
        },
        suppressSizeToFit: true
    },
    // { headerName: 'City', field: 'city', filter: true, sortable: true, editable: true },
    // { headerName: 'State', field: 'state', filter: true, sortable: true, editable: true },
    // { headerName: 'Zip', field: 'zip', filter: true, sortable: true, editable: true },
    { 
        headerName: 'PL Service Status', 
        field: 'serviceStatus', 
        colId: 'serviceStatus',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 150,
        valueGetter: params => {
            if (params.data['serviceStatus'] === null) {
                return '';
            } else {
                return params.data['serviceStatus'] === 'A' || params.data['serviceStatus'] === 'Active'  ? 'Active' : 'Inactive';
            }
        },
        flex: 1
    },
    { 
        headerName: 'Service Date',
        field: 'serviceDate', 
        colId: 'serviceDate',
        filter: true, 
        sortable: true,
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 120,
        valueGetter: params => {
            if (params.data['serviceDate'] === null) {
                return '';
            } else {
                return moment(params.data['serviceDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'Allow Trade', 
        field: 'allowTrades', 
        colId: 'allowTrades',
        headerClass: 'text-center',
        filter: true, 
        sortable: true,
        tooltipField: 'allowTrades', 
        cellRenderer: (params) => {
            return (
                <input 
                    type='checkbox' 
                    checked={params.value==='Y'}
                    readOnly={true}
                />);
        }, 
        minWidth: 100, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        flex: 1  
    },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser', 
        filter: true, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        }
    },
    { 
        headerName: 'Created Date',
        field: 'createDate',
        colId: 'createDate',  
        filter: true, 
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        } 
    },
    { 
        headerName: 'Updated User', 
        field: 'updateuser',
        colId: 'updateuser',   
        filter: true, 
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updateuser'] === null) {
                return '';
            } else {
                return params.data['updateuser'];
            }
        }
    },
    { 
        headerName: 'Updated Date', 
        field: 'updatedate', 
        colId: 'updatedate',   
        filter: true, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updatedate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updatedate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }  
    },
    // { 
    //     headerName: 'Push to VMACS', 
    //     field: 'PushToVMACS', 
    //     colId:'PushToVMACS',
    //     // hide: !dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSync),
    //     // suppressColumnsToolPanel: !dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSync),
    //     editable: dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSync),
    //     cellRenderer: PushVmacsButtonComponent,
    //     cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
    //     suppressSizeToFit: true  
    // },
    { 
        headerName: 'Sync Status', 
        field: 'syncStatus', 
        colId: 'syncStatus',
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['syncStatus'] === null) {
                return '';
            } else {
                return params.data['syncStatus'];
            }
        }
    },
    { 
        headerName: 'VMACS Test Sync Date', 
        field: 'testSyncDate', 
        colId: 'testSyncDate',
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['testSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['testSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        suppressSizeToFit: true  
    },
    { 
        headerName: 'VMACS Prod Sync Date', 
        field: 'prodSyncDate', 
        colId: 'prodSyncDate',
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['prodSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['prodSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        suppressSizeToFit: true  
    }
];

