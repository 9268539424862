import React from 'react';
// import './attachment-file-name.scss';
import { ICellRendererParams } from 'ag-grid-community';

interface AttachmentProps extends ICellRendererParams {
    onDownloadFileCallback: (data) => void;
}

export const FileNameCellRenderer = (props: AttachmentProps) => {
    return (
        <div>
            <a href={'/'} download onClick={() => props.onDownloadFileCallback(props.data)} target='_blank' rel='noopener noreferrer'>
                {props.data.fileName}
            </a>
        </div >
    );
};