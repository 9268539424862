import React, { Component, PureComponent } from 'react';
import { CustomButtonType, CustomButton } from 'shared/components/custom-button/custom-button';
import { agGridService } from '../../services/ag-grid-service';
import { dataStore } from '../../stores';
import { ConfirmService } from '../../../shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { ICellRendererParams } from 'ag-grid-community';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';

/* eslint-disable */
export interface ButtonProps extends ICellRendererParams {
    disabled: boolean | undefined;
    dataStore: any;
    handleClickPushVmacs: any;
    updatedData? : any;
}
export class PushVmacsButtonComponent extends PureComponent<ButtonProps, {}> {
    render(): React.ReactNode {
        const { disabled = false } = this.props;
        const id = this.props.data.settingID ? this.props.data.settingID : null;
        const agencyid = this.props.data.agencyid ? this.props.data.agencyid : null;
        const rateTypesId = this.props.data.tariffRateTypeID ? this.props.data.tariffRateTypeID : null;
        const action = this.props.data.action;
        const testButtonDisabled = this.props.data.syncStatus && (this.props.data.syncStatus === 'Synced to TEST' || this.props.data.syncStatus === 'Synced to PROD'); 
        const prodButtonDisabled = this.props.data.syncStatus && this.props.data.syncStatus === 'Synced to PROD'; 
        const screen = dataStore.getCurrentModuleName();
        const isNotVMACSDataSource = (screen === 'Setting Maintenance' && this.props.data.source) ? this.props.data.source !== 'VMACS' : false;
        const isNotUSADefalutLocationUser = (screen === 'Setting Maintenance' && this.props.data.defaultLocation) ? this.props.data.defaultLocation === 'CAN' : false;
        if (((id && id <= 0 && screen === 'Setting Maintenance')|| (agencyid && agencyid <= 0 && screen === 'Agency Maintenance')) || (action && action === 'D') || isNotVMACSDataSource || isNotUSADefalutLocationUser || (screen === 'Tariff Rate Types' && [null, "", undefined].includes(rateTypesId)) ) {
            return null;
        } else {
            return (
                <>
                    <CustomButton type={CustomButtonType.Submit} onClick={() => this.handleClickPushVmacs('Test')} disabled={testButtonDisabled || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest)}>
                        TEST
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={() => this.handleClickPushVmacs('Prod')} disabled= {prodButtonDisabled || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)}>
                        PROD
                    </CustomButton>
                </>
            );
        }
    }

    handleClickPushVmacs = (env) => {
      // const rowId = this.props.data.settingID
        const unsavedData = this.props.updatedData;
        if(this.props.data) {
            if(env === 'Prod' && this.props.data.syncStatus && this.props.data.syncStatus === 'Not yet Synced' ) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                    () => {},
                    ConfirmWithOK,
                    {secondaryText: 'This record has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
                    'confirmPopup'
                );
            } else if(Array.isArray(unsavedData) && unsavedData.length > 0 || (unsavedData instanceof Function && unsavedData().length>0)) {
                const displayEnv = env === 'Prod' ? 'Production' : env;
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () => this.props.handleClickPushVmacs(env, this.props.data),
                    'Sync',
                    `All the unsaved changes will be lost. Are you sure you want to push the selected data to the ${displayEnv} Environment?`
                );
            } else {
                const displayEnv = env === 'Prod' ? 'Production' : env;
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () =>  this.props.handleClickPushVmacs(env, this.props.data),
                    'Sync',
                    `Are you sure you want to push the selected data to the ${displayEnv} Environment?`
            );
            }
          }
    }
    
  //   handleClickPushVmacsProd = () => {
  //     // const rowId = this.props.data.settingID
  //     // if(rowId) {
  //     //   const confirmService = new ConfirmService();
  //     //   confirmService.showConfirmDialog(
  //     //     () => this.pushDataToVmaxProd(rowId),
  //     //     'Sync',
  //     //     'Are you sure yout want to push the selected data to VMACS Prod Env?'
  //     //   );
  //     // }
  //     this.props.handleClickPushVmacs('Prod');
  //  }

    // pushDataToVmaxTest = (selectedRowsfromGrid) => {

    // }

    // pushDataToVmaxProd = (selectedRowsfromGrid) => {

    // }
}
