import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
// import './add-pipeline.scss';
import {
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    ColDef,
    GridOptions
} from 'ag-grid-community';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker'
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Portal } from 'react-overlays';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ProfitCenterDetailsDataStore } from '../stores/profit-center-details-data-store';
import { ReactSelect } from 'common/components/select/select';
import { ThreeSixty } from '@mui/icons-material';
// import { profitCenterDetailsDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';

/* eslint-disable */
interface ProfitCenterDetailsContainerProps {
    profitCenterDetailsDataStore: ProfitCenterDetailsDataStore;
}

interface ProfitCenterDetailsContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowIdEntities: number;
    lastAddedRemarksID: number;
    lastAddedRelationshipID: number;
}

interface CalendarContainerProps {
    children : any
}

const onChangeDate = (params, value, key) => {  
    const effTransCycle = moment(value).format('MM/DD/yyyy');
    console.log(moment(value).format('MM/DD/yyyy'));
    // profitCenterDetailsDataStore.setValueInChangeMapRelationshipGrid(params.data.pipelineID, key, params.data.effTransCycle, effTransCycle);
    params.data.effTransCycle =  moment(value).format('MM/DD/yyyy');
    // profitCenterDetailsDataStore.updateRowRelationship(params.data);
 } ; 
const CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };


@inject('profitCenterDetailsDataStore')
@observer
export class AddProfitCenterDetailsContainer extends Component<ProfitCenterDetailsContainerProps, ProfitCenterDetailsContainerState> {

    constructor(props: ProfitCenterDetailsContainerProps | Readonly<ProfitCenterDetailsContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowIdEntities: 0,
            lastAddedRemarksID: 0,
            lastAddedRelationshipID: 0
        };
        dataStore.setModuleName('AddProfitCenterDetails')
    }

    componentDidMount() {
          const {profitCenterDetailsDataStore} = this.props;
          if(!dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.ProfitCenterMaintenance)){
            history.push('/ProfitCenterMaintenance');
            return;
        }

          profitCenterDetailsDataStore.init();
    }

    componentWillUnmount() {
        const {profitCenterDetailsDataStore} = this.props;
        profitCenterDetailsDataStore.resetData();
    }

    relationshipColDef() { 
        const relationshipColDef: ColDef[] = [
            {   
                headerName: 'Select',
                field: 'Select', 
                colId:'Select',
                headerComponent: (params) => {
                return (
                    <input
                        type="checkbox"
                        onChange={(e) => this.props.profitCenterDetailsDataStore.headerCheckBoxSelection(e)}
                        checked={!this.props.profitCenterDetailsDataStore.checkIfHeaderCheckboxShouldbeChecked(params)}
                        disabled={false}
                    />
                );
            },
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            onChange={(e) => this.props.profitCenterDetailsDataStore.linkedCPCheckboxHandler(params, e)}
                            checked={params.data.isSelected}
                            disabled={false}
                        />
                    );
                }, 
                cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                minWidth: 45, 
                flex: 1,
                // suppressSizeToFit: true,
                cellStyle: () => {return {padding: '10px'}}
            },
            { 
                headerName: 'Agency', 
                field: 'agencyCode',
                colId: 'agencyCode',
                filter: true, 
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                // cellEditor: PredictiveSelectCellEditor, 
                minWidth: 80, 
                // cellEditorParams: {
                 //   dropDownValues: this.props.profitCenterDetailsDataStore.baseSystemData.map((x) => x.plName)
                // },
                // cellClass: (params) =>{return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},        
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['agencyCode'] === null) {
                        return '';
                    } else {
                        return params.data['agencyCode'];
                    }
                },
                singleClickEdit: true,
            },
            { 
                headerName: 'Number', 
                field: 'number',
                colId: 'number',
                filter: true, 
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                // cellEditor: PredictiveSelectCellEditor, 
                minWidth: 80, 
                // cellEditorParams: (params) => {return {
                //     dropDownValues:  params.data['plName']  &&  params.data['plName'] !== '' ? this.props.profitCenterDetailsDataStore.locationData.filter((i) => i.plName === params.data['plName']).map((x) => x.locnName) :  this.props.profitCenterDetailsDataStore.locationData.map((x) => x.locnName),
                // }},
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['number'] === null) {
                        return '';
                    } else {
                        return params.data['number'];
                    }
                },
                singleClickEdit: true,
            },
            { 
                headerName: 'Description', 
                field: 'description',
                colId: 'description',
                filter: true, 
                sortable: true,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                editable: false,
                minWidth: 110, 
                // cellEditorParams: (params) => {return {
                //     dropDownValues:  params.data['locnName']  &&  params.data['locnName'] !== '' ? this.props.profitCenterDetailsDataStore.connectionPointGridData.filter((item) => item.locnName === params.data.locnName).map((x) => x.cpName) : [],
                // }},
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['description'] === null) {
                        return '';
                    } else {
                        return params.data['description'];
                    }
                },
                singleClickEdit: true,
            },
            { 
                headerName: 'Type', 
                field: 'typeText',
                colId: 'typeText',
                filter: true, 
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                singleClickEdit: true,
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                // cellEditor: PredictiveSelectCellEditor, 
                // cellEditorParams: {
                //     dropDownValues: this.props.profitCenterDetailsDataStore.cpRelTypesDropdownOption.map((x) => x.valueText)
                // },
                minWidth: 110, 
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},        
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['typeText'] === null) {
                        return '';
                    } else {
                        return params.data['typeText'];
                    }
                }
            },
            { 
                headerName: 'Status', 
                field: 'statusText',
                colId: 'statusText',
                filter: true, 
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                singleClickEdit: true,
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                // cellEditor: PredictiveSelectCellEditor, 
                // cellEditorParams: {
                //     dropDownValues: this.props.profitCenterDetailsDataStore.cpRelTypesDropdownOption.map((x) => x.valueText)
                // },
                minWidth: 80, 
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},        
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['statusText'] === null) {
                        return '';
                    } else {
                        return params.data['statusText'];
                    }
                }
            },

            { 
                headerName: 'ProfitCenter', 
                field: 'profitCenterText',
                colId: 'profitCenterText',
                filter: true,
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                singleClickEdit: true,
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                // cellEditor: PredictiveSelectCellEditor, 
                // cellEditorParams: {
                //     dropDownValues: this.props.profitCenterDetailsDataStore.cpRelTypesDropdownOption.map((x) => x.valueText)
                // },
                minWidth: 110, 
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},        
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['profitCenterText'] === null) {
                        return '';
                    } else {
                        return params.data['profitCenterText'];
                    }
                }
            },
            { 
                headerName: 'Issue Date',
                field: 'issueDate', 
                colId: 'issueDate',
                filter: true, 
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                suppressSizeToFit: true,
                // cellRenderer: (params) => { 
                //     const effTransCycle = params.data['effTransCycle'] ?  moment(moment(params.data['effTransCycle'])
                //     .format('MM/DD/yyyy')).toDate() : null
                //     return (params.data && 
                //         (<DatePicker 
                //             popperClassName='ag-custom-component-popup' 
                //             selected={effTransCycle}
                //             dateFormat="MM/dd/yyyy" 
                //             onChange={(value)=>{onChangeDate(params, value, 'effTransCycle')}} 
                //             popperContainer={CalendarContainer} 
                //             popperPlacement='top-start' 
                //             className={'serviceDateInputStyle-editable'}
                //             // disabled={profitCenterDetailsDataStore.isSaveDisabled()}
                //             />
                //         )
                //     ) || params.value 
                // },
                valueGetter: params => {
                    if (params.data['issueDate'] === null) {
                        return '';
                    } else {
                        return moment(params.data['issueDate']).format(AppConstant.DATE_FORMAT);
                    }
                }, 
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                minWidth: 120,    
            },
            { 
                headerName: 'Effective Date',
                field: 'effectiveDate', 
                colId: 'effectiveDate',
                filter: true, 
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                suppressSizeToFit: true,
                valueGetter: params => {
                    if (params.data['effectiveDate'] === null) {
                        return '';
                    } else {
                        return moment(params.data['effectiveDate']).format(AppConstant.DATE_FORMAT);
                    }
                }, 
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                minWidth: 120,    
            },
            { 
                headerName: 'Cancel Date',
                field: 'canceldate', 
                colId: 'canceldate',
                filter: true, 
                sortable: true,
                editable: false,
                // editable: !profitCenterDetailsDataStore.isSaveDisabled(),
                suppressSizeToFit: true,
                valueGetter: params => {
                    if (params.data['canceldate'] === null) {
                        return '';
                    } else {
                        return moment(params.data['canceldate']).format(AppConstant.DATE_FORMAT);
                    }
                }, 
                // cellClass: (params) => {return !profitCenterDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                minWidth: 120,    
            }
       
       
    ];
    return relationshipColDef
    }


    onChangeBaseSystem(e) {
        const { profitCenterDetailsDataStore } = this.props;
       if(e.value && e.value !== 'Select') {
        profitCenterDetailsDataStore.formData.pipelineName = e.value;
        profitCenterDetailsDataStore.formData.pipelineID = profitCenterDetailsDataStore.baseSystemData.find((x) => x.plName === e.value).pipelineID;
        const tariffAreaData = profitCenterDetailsDataStore.tariffAreaSystemData.filter((x) => x.basePipelineID === profitCenterDetailsDataStore.formData.pipelineID);
        profitCenterDetailsDataStore.tariffAreaSystemDropdownOptions = tariffAreaData.map(x => {return { 'value': x.plName, 'label': x.plName}});
        profitCenterDetailsDataStore.isFormUpdated = true;
       } else {
        profitCenterDetailsDataStore.formData.pipelineName = null;
        profitCenterDetailsDataStore.formData.pipelineID = null;
        profitCenterDetailsDataStore.isFormUpdated = true;
       }
    }

    async onChangeTariffArea(e) {
        const { profitCenterDetailsDataStore } = this.props;
       if(e.value && e.value !== 'Select') {
        profitCenterDetailsDataStore.formData.tariffArea = e.value;
        const tariffArea = profitCenterDetailsDataStore.tariffAreaSystemData.find((item) => item.plName === e.value);
        const agency = profitCenterDetailsDataStore.tariffAgenciesData.find((item) => item.agencycode === profitCenterDetailsDataStore.formData.agency);
        if(tariffArea && tariffArea.pipelineID)
            await profitCenterDetailsDataStore.getRelatedGridData(profitCenterDetailsDataStore.formData.profitCenterID, tariffArea.pipelineID, agency &&  agency.agencyid ? agency.agencyid : 0);
       } else {
        profitCenterDetailsDataStore.formData.tariffArea = null;
       }
    }

    async onChangeAgencies(e) {
        const { profitCenterDetailsDataStore } = this.props;
       if(e.value && e.value !== 'All') {
        profitCenterDetailsDataStore.formData.agency = e.value;
        const tariffArea = profitCenterDetailsDataStore.tariffAreaSystemData.find((item) => item.plName === profitCenterDetailsDataStore.formData.tariffArea);
        const agency = profitCenterDetailsDataStore.tariffAgenciesData.find((item) => item.agencycode === e.value);
        if(tariffArea && tariffArea.pipelineID && agency && agency.agencyid)
            await profitCenterDetailsDataStore.getRelatedGridData(profitCenterDetailsDataStore.formData.profitCenterID, tariffArea.pipelineID, agency.agencyid);

       } else {
        profitCenterDetailsDataStore.formData.agency = null;
        const tariffArea = profitCenterDetailsDataStore.tariffAreaSystemData.find((item) => item.plName === profitCenterDetailsDataStore.formData.tariffArea);
        if(tariffArea && tariffArea.pipelineID)
            await profitCenterDetailsDataStore.getRelatedGridData(profitCenterDetailsDataStore.formData.profitCenterID, tariffArea.pipelineID, 0);

       }
    }

    // onChangeRelatedBaseSystem(e) {
    //     const { profitCenterDetailsDataStore } = this.props;
    //    if(e.value && e.value !== 'Select') {
    //     profitCenterDetailsDataStore.selectedRelatedBaseSystem = e.value;
    //     const baseDetails = profitCenterDetailsDataStore.baseSystemData.find((item) => item.plName === e.value)
    //     const pipelineID = baseDetails.pipelineID ? baseDetails.pipelineID : null;
    //     const a = pipelineID ? profitCenterDetailsDataStore.locationData.filter((x) => x.pipelineID === pipelineID) : profitCenterDetailsDataStore.locationData;
    //     const b = a.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
    //     profitCenterDetailsDataStore.relatedLocationDropdownOption = b;
    //    } else {
    //     const b = profitCenterDetailsDataStore.locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
    //     profitCenterDetailsDataStore.relatedLocationDropdownOption = b;
    //     profitCenterDetailsDataStore.selectedRelatedBaseSystem = null;
    //    }
    //    profitCenterDetailsDataStore.selectedRelatedLocation = undefined;
    //    profitCenterDetailsDataStore.connectionPointGridData = [];
    //    profitCenterDetailsDataStore.isFormUpdated = true;
    // }

    // handleCarrySplitCheckBoxChange(params, value) {
    //     const row = params.data.pipelineID;
    //     const initValue = params.data['carrySplit'];
    //     this.props.profitCenterDetailsDataStore.setValueInChangeMapRelationshipGrid(row, 'carrySplit', initValue, value);
    //     if(initValue === 'Y') {
    //         params.data['carrySplit'] = 'N'
    //     } else {
    //         params.data['carrySplit'] = 'Y'
    //     }
    //     this.props.profitCenterDetailsDataStore.updateRowRelationship(params.data)
    // }

    // onChangeLocationName(e, isRelationSegment) {
    //     const { profitCenterDetailsDataStore } = this.props;
    //    if (e.value && e.value !== 'Select' ) {
    //     if(!isRelationSegment) {
    //         profitCenterDetailsDataStore.selectedLocation = e.value ;
    //         if(profitCenterDetailsDataStore.selectedBaseSystem === profitCenterDetailsDataStore.selectedRelatedBaseSystem)
    //         profitCenterDetailsDataStore.selectedRelatedLocation = e.value ;
    //         profitCenterDetailsDataStore.getCPTariff(e.value)
    //     } else {
    //         profitCenterDetailsDataStore.selectedRelatedLocation = e.value ;
    //         profitCenterDetailsDataStore.getCPTariff(e.value)
    //     }
    //    } else {
    //         if(!isRelationSegment) {
    //             profitCenterDetailsDataStore.selectedLocation = null;
    //             if(profitCenterDetailsDataStore.selectedBaseSystem === profitCenterDetailsDataStore.selectedRelatedBaseSystem)
    //             profitCenterDetailsDataStore.selectedRelatedLocation = null;
    //             profitCenterDetailsDataStore.connectionPointGridData = [];
    //         } else {
    //             profitCenterDetailsDataStore.selectedRelatedLocation = null;
    //             profitCenterDetailsDataStore.connectionPointGridData = [];
    //         }
    //    }
    //    profitCenterDetailsDataStore.isFormUpdated = true;
    // }

    // onChangeCPType(e) {
    //     const { profitCenterDetailsDataStore } = this.props;
    //     if (e.value && e.value !== 'Select' ) {
    //         profitCenterDetailsDataStore.selectedCPType = e.value
    //         if(e.value !== 'Connecting Carrier (rec/del)') {
    //             profitCenterDetailsDataStore.selectedCCType = undefined;
    //             profitCenterDetailsDataStore.selectedConnCarrierOperator = undefined;
    //         }
    //         if(e.value === 'Non-Custody Transfer (rec/del)') {
    //             profitCenterDetailsDataStore.selectedfercType = undefined;
    //         }

    //     } else {
    //         profitCenterDetailsDataStore.selectedCPType = null;
    //     }
    //     profitCenterDetailsDataStore.isFormUpdated = true;
    // }

    saveProfitCenter() {
        this.props.profitCenterDetailsDataStore.saveProfitCenter();
    }

    // onDeleteClick = async () => {
    //     const { profitCenterDetailsDataStore } = this.props;
    //     const updatedRowIDs: any = profitCenterDetailsDataStore.remarksGridData.filter(a => profitCenterDetailsDataStore.getUpdatedRowIDs().includes(a.cpCommentID.toString()));
    //     if(updatedRowIDs.length > 0 || profitCenterDetailsDataStore.isFormUpdated) {
    //       const confirmService = new ConfirmService();
    //       confirmService.showConfirmDialog(
    //         () => { profitCenterDetailsDataStore.deleteCP()},
    //         'Inactive',
    //         'All the unsaved changes will be lost. Are you sure you want to deactivate the selected connection point?'
    //     );
    //     } else {
    //       const confirmService = new ConfirmService();
    //       confirmService.showConfirmDialog(
    //       () => profitCenterDetailsDataStore.deleteCP(),
    //       'Inactive',
    //       'Are you sure you want to deactivate the selected connection point?'
    //     );
    //     }
    //   }


    render(): React.ReactNode {
         const { profitCenterDetailsDataStore } = this.props;
        return (
        <div className='profitCenterMaintenanceBox'>
            <Container fluid>
            <div className='boxes'>
                  <div className='screenHeader'>
                      <h2 className='screen_title'>Profit Center Details</h2>
                    </div>
                  <div className='selectContainer'>
                    <Row className="pipelineName">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Base System/ Description</label>
                            <ReactSelect
                                className='select'
                                values={profitCenterDetailsDataStore.formData.pipelineName ? {label: profitCenterDetailsDataStore.formData.pipelineName, value: profitCenterDetailsDataStore.formData.pipelineName} : {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...profitCenterDetailsDataStore.baseSystemDropdownOption]}
                                onChange={(e: any) => { this.onChangeBaseSystem(e)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled={profitCenterDetailsDataStore.isSaveDisabled() || (profitCenterDetailsDataStore.formData && profitCenterDetailsDataStore.formData.profitCenterID !== 0)}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Profit Center Code</label>
                            <input
                                type="text"
                                className="search_text_field"
                                onChange = {(e) => {profitCenterDetailsDataStore.formData.profitCenter = e.target.value; profitCenterDetailsDataStore.isFormUpdated = true;}}
                                value={profitCenterDetailsDataStore.formData.profitCenter}
                                readOnly={profitCenterDetailsDataStore.isSaveDisabled()}
                            />
                            <label style ={{color : 'red', fontSize: '12px', marginLeft: '5px'}}>* Max char length is 10</label>
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Valid to Date</label>
                                <ReactDatePicker classNames='select' onDateChange={(e) => { profitCenterDetailsDataStore.formData.validToDate = e; profitCenterDetailsDataStore.isFormUpdated = true; } } selectedDate={profitCenterDetailsDataStore.formData.validToDate} disabled={profitCenterDetailsDataStore.isSaveDisabled()}/>
                        </Col>
                        <Col></Col>
                      
                    </Row>
                    <div style={{'marginTop':'20px'}}>
                    <div className='screenHeader'>
                        <h2 className='screen_title'>Related Profit Center </h2>
                    </div>
              <div className='locationMaintenanceBox'>
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                  <Row className="pipelineName">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Profit Center</label>
                           
                            <ReactSelect
                                className='select'
                                values={profitCenterDetailsDataStore.formData.pipelineName ? {label: profitCenterDetailsDataStore.formData.pipelineName, value: profitCenterDetailsDataStore.formData.pipelineName} :  {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...profitCenterDetailsDataStore.baseSystemDropdownOption]}
                                isMulti={false}
                                onChange={() =>  {}}
                                isSearchable={true}
                                disabled={true}
                            />
                           
                        </Col>
                    </Row>
                    <Row>
                    
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Tariff Area</label>
                           
                            <ReactSelect
                                className='select'
                                values={profitCenterDetailsDataStore.formData.tariffArea ? {label: profitCenterDetailsDataStore.formData.tariffArea, value: profitCenterDetailsDataStore.formData.tariffArea} :  {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...profitCenterDetailsDataStore.tariffAreaSystemDropdownOptions]}
                                onChange={(e: any) => { this.onChangeTariffArea(e) }}
                                isMulti={false}
                                isSearchable={true}
                                disabled = {!profitCenterDetailsDataStore.formData.profitCenterID ||  profitCenterDetailsDataStore.formData.profitCenterID === 0}
                                //  
                            />
                            
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Agency</label>
                           
                            <ReactSelect
                                className='select'
                                values={profitCenterDetailsDataStore.formData.agency ? {label: profitCenterDetailsDataStore.formData.agency, value: profitCenterDetailsDataStore.formData.agency} :  {label: 'All', value:  'All'}}
                                placeholder={'All'}
                                options={[{value:'All', label:'All'},...profitCenterDetailsDataStore.tariffAgenciesDropdownOptions]}
                                onChange={(e: any) => { this.onChangeAgencies(e) }}
                                isMulti={false}
                                isSearchable={true}
                                disabled = {!profitCenterDetailsDataStore.formData.profitCenterID ||  profitCenterDetailsDataStore.formData.profitCenterID === 0}
                                // disabled={true}
                            />
                            
                        </Col>
                    </Row>
                  
                  </div>
                  
                </div>
              </div>
              <div className='locationMaintenanceBox'>
                <div className='pipelineLinkerBox'>
                    <div className="rotatedText">
                      Linker
                    </div>
                    <Row style={{margin:'20px', width: '80%', height: '40%'}}>
                      <AgGridComponent gridConfig={this.getAddRelationShipGridConfig('RelationShip Details')}/>
                    </Row>
                    
                </div>
                <Row>
                    <div className='searchButtonDiv'>
                    <div className='otherHeaderButtonsDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => {}} disabled={true}>
                          Cancel
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => {}} disabled={true}>
                          Unlink
                        </CustomButton>
                    </div>
                  </div>
                  </Row>
              </div>
              </div>
              {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
                    <hr></hr>
                    <div className='searchButtonDiv'>

                        <div className='otherHeaderButtonsDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => this.saveProfitCenter()} disabled={profitCenterDetailsDataStore.isSaveDisabled()}> 
                            Save
                        </CustomButton>
                        {/* <CustomButton type={CustomButtonType.Submit} onClick={this.onDeleteClick} disabled= {profitCenterDetailsDataStore.isDeleteDisabled()}>
                            Deactivate
                        </CustomButton> */}
                        <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Test')}>
                            Push to Test
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={() =>  this.pushToVmacs('Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Prod')}>
                            Push to Prod
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => profitCenterDetailsDataStore.onCancelprofitCenterDetails()}> 
                            Close
                        </CustomButton>
                        </div>
                        
                        </div>
                    </div>
          </div>
          {this.props.profitCenterDetailsDataStore.showLoader == true &&
                <div className = 'alert-overlay'> 
                  <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                </div> 
              }
        </Container>
       </div>
    );
}

  private getAddRelationShipGridConfig(gridId): GridOptions {
    const {
        profitCenterDetailsDataStore: { RelationShipData, agGridService }
    } = this.props;
    const { profitCenterDetailsDataStore } = this.props;
    const columnDefs = this.relationshipColDef();
    const rowData = RelationShipData.tariffRelProfitCenters;
    // const onCellEditingStarted = this.onCellEditingStartedRelationShip;
    // const onCellEditingStopped = this.onCellEditingStoppedRelationShip;
    const locationMaintenanceGridParams: any = {
      rowData,
      columnDefs,
    //   onCellEditingStarted,
    //   onCellEditingStopped,
      context: agGridService
    };
    return agGridService.getGridConfig(locationMaintenanceGridParams, gridId);
  }


// onAddRelationshipRow = () => {
//     const cpCommentID = this.state.lastAddedRelationshipID - 1
//     const rowData = [{
//         relProfitCenterID: cpCommentID,
//         conPointIDFrom: null,
//         conPointIDTO: null,
//         relatedCPType: null,
//         carrySplit: null,
//         effTransCycle: null,
//         createDate: moment().format(AppConstant.API_DATE_FORMAT),
//         createUser: this.props.profitCenterDetailsDataStore.userName,
//         updateDate: null,
//         updateUser: null,
//         action: "I",
//         isSelected: false
//       }, ...this.props.profitCenterDetailsDataStore.relationshipGridData];
//     this.props.profitCenterDetailsDataStore.setRelationshipGridData(rowData);
//     this.setState({
//         lastAddedRelationshipID: cpCommentID
//     });
//     this.props.profitCenterDetailsDataStore.addValuesInCellDropdowns();
//     this.props.profitCenterDetailsDataStore.agGridServiceForRelationShip.getNodes()?.refreshCells();   
//     this.props.profitCenterDetailsDataStore.scrollToFocusTheNewCellRelationship();
//     this.props.profitCenterDetailsDataStore.checkIfHeaderCheckboxShouldbeChecked()
// }


private async pushToVmacs(env) {
    const { profitCenterDetailsDataStore } = this.props;
    if((profitCenterDetailsDataStore.formData.pipelineName !== ''  &&( ( profitCenterDetailsDataStore.formData.syncStatus && profitCenterDetailsDataStore.formData.syncStatus !== SyncStatus[2])))) {
      if(profitCenterDetailsDataStore.isFormUpdated) {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => profitCenterDetailsDataStore.pushSelectedRowsToVmacs(env),
          'Active',
          `All the unsaved changes will be lost. Are you sure you want to push the selected data to the ${env} Environment?`
      );
      } else {
        if(((profitCenterDetailsDataStore.formData.syncStatus && profitCenterDetailsDataStore.formData.syncStatus=== SyncStatus[0])) && env === 'Prod' && profitCenterDetailsDataStore.formData.pipelineName !== '' ) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'Profit center has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        } else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => profitCenterDetailsDataStore.pushSelectedRowsToVmacs(env),
              'Active',
              `Are you sure you want to push the selected data to the ${env} Environment?`
          );
        }      
      }
    } else {
      const secondaryText = 'Please select only the active and unsynced profit center to perform the operation';
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: secondaryText} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  private pushButtonDisabled(env) {
    const { profitCenterDetailsDataStore } = this.props;
    if(env === 'Test') {
      return  !profitCenterDetailsDataStore.formData || ((!profitCenterDetailsDataStore.formData.profitCenterID || profitCenterDetailsDataStore.formData.profitCenterID === 0) || (profitCenterDetailsDataStore.formData.syncStatus && (profitCenterDetailsDataStore.formData.syncStatus === SyncStatus[1] || profitCenterDetailsDataStore.formData.syncStatus === SyncStatus[2]))) || false; 
    }
      return  !profitCenterDetailsDataStore.formData || ((!profitCenterDetailsDataStore.formData.profitCenterID || profitCenterDetailsDataStore.formData.profitCenterID === 0) || ( profitCenterDetailsDataStore.formData.syncStatus && (profitCenterDetailsDataStore.formData.syncStatus === SyncStatus[0] || profitCenterDetailsDataStore.formData.syncStatus === SyncStatus[2]))) || false;
  }

}