import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { locationColDef } from '../components/location-col-def';
import moment from 'moment';
import Path, { LocationSettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { LocationMaintenanceDataService } from '../services/location-maintenance-data-service';
import { LocationMaintenanceResponse } from '../model/model';
import { LocationMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { locationMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { AddLocationDetailsColDef } from '../components/add-location-details-container';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class LocationMaintenanceDataStore {
    @observable locationMaintenanceData: LocationMaintenanceResponse[] = [];
    @observable modifiedLocationMaintenanceData: LocationMaintenanceResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupLocationMaintenanceData: LocationMaintenanceResponse[] = [];
    isRefreshed = false;
    @observable showModal = false;
    @observable selectedlocnName = '';
    @observable selectedStatus = { value: 'All', label: 'All' };
    @observable selectedBaseSystem = { value: 'All', label: 'All' };
    endDate: any = moment().toISOString().slice(0, 10);
    @observable baseSystemDropdownOptions: any[] = [];
    @observable statusDropdownOptions: any[] = ServiceStatus.map(x => { return { 'value': x.status, 'label': x.status } });
    tariffStatesData: any[] = [];
    baseSystemData: any[] = [];
    
    @observable baseSystemDropdownOption: any[] = [];
    @observable locnNameDropdownOption: any[] = [];
    @observable selectedLocationDetails: any = {};
    @observable addOrEditedLocnName = '';
    @observable selectedLocationId = 0;
    @observable addOrEditLocationGridData: any[] = [];
    backupAddOrEditLocationGridData: any[] = [];
    @observable onClickLocationNameOnGrid = false;
    @observable onClickAddNewLocationButton = false;
    @observable isActivateButtonDisabled = true;
    @observable updatedFieldonLocationDetailsScreen = false;
    navigateToScreen: any = null;
    workflowId: any = null;
    navigateState: any;

    constructor(
        public locationMaintenanceDataService: LocationMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditLocation: AgGridService
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        this.baseSystemDropdownOptions = [];
        this.navigateToScreen = null;
        this.workflowId = null;
        this.navigateState = null;
        dataStore.setShowUnsavedWarningAlert(false);
        this.getBaseSystemDropdown();
        // this.getLocationDropdownData();
        this.selectedlocnName = '';
        this.selectedStatus = { value: 'All', label: 'All' };
        this.selectedBaseSystem = { value: 'All', label: 'All' };
        this.loadViewModel();
        // this.userID = this.accountStore.getUserID();
        // this.userName = this.accountStore.displayName;
        // this.onSelectJurisdiction(this.selectedlocnName);
        this.userName = this.accountStore.displayName;
        this.selectedLocationId = 0;
        this.selectedLocationDetails = {};
        const state=history?.location?.state as {from?: string; url?: string; workflowId?: string; } || '';
        if(state.url) {
            this.navigateToScreen  = state.url;
            this.workflowId = state.workflowId ? state.workflowId : 0;
            this.navigateState = state;
            this.resetAddLocationMaintenanceGridData();
        }

    }

    @Loader
    @action
    @Catch(() => errorHandler(LocationMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const locationName = this.selectedlocnName === '' ? 'All' : this.selectedlocnName;
        const response: LocationMaintenanceResponse[] = await this.locationMaintenanceDataService.getLocationsData();
        runInAction(() => {
            this.showLoader = false;
            this.modifiedLocationMaintenanceData = response;
            this.locationMaintenanceData = response;
            this.setLocationMaintenanceData(response);
            this.setbackupLocationMaintenanceData(response);
            this.createDataSourceGroupByLocation(response);
            this.isRefreshed = false;
            this.selectedLocationId = 0;
            this.selectedLocationDetails = {};
        });
        this.agGridService.getNodes()?.refreshCells();   
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.locationMaintenanceDataService.getBaseSystems();
        this.baseSystemData = baseSystemDropdownOptions;
        const a = (baseSystemDropdownOptions.map(x => x.plName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.baseSystemDropdownOption = option;
            this.resetAddLocationMaintenanceGridData();
        });
    }

    // @action
    // @Catch(() => errorHandler('Fetch Error'))
    // async getLocationDropdownData(): Promise<void> {
    //     const locnCodeOptions: any = [];
    //     const locnNameOptions: any = [];
    //     const baseSystemId = this.baseSystemData.filter((system) => system.plName === this.selectedBaseSystem)[0].pipelineID;
    //     const locationName = this.selectedlocnName === '' ? 'All' : this.selectedlocnName;
    //     const response: LocationMaintenanceResponse[] = await this.locationMaintenanceDataService.DataWithFilter(locationName, baseSystemId, this.selectedStatus );
    //     const filteredlocnCodeArray = response.map(x => x.locnCode).filter((x , index, arr) => arr.indexOf(x) === index);
    //     filteredlocnCodeArray.forEach((item) => {
    //         locnCodeOptions.push({locnCode : item})
    //     })
    //     const filteredlocnNameArray = response.map(x => x.locnName).filter((x , index, arr) => arr.indexOf(x) === index);
    //     filteredlocnNameArray.forEach((item) => {
    //         locnNameOptions.push({locnName : item})
    //     })
    //     runInAction(() => {
    //         this.showLoader = false;
    //         // this.baseSystemDropdownOption = locnCodeOptions;
    //         this.locnNameDropdownOption = locnNameOptions;
    //     });
    // }

    @action
    createDataSourceGroupByLocation(response) {
        const array = response
        const result = array.reduce((r, { locnName: locnName, ...object }) => {
        let temp = r.find(o => o.locnName === locnName);
        if (!temp) r.push(temp = { locnName, children: [] });
        temp.children.push(object);
        return r;
    }, []);
    
    console.log(result);
    }

    @Loader
    @Catch(() => errorHandler(LocationMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        const response: LocationMaintenanceResponse[] = await this.locationMaintenanceDataService.getLocationsData();
        runInAction(() => {
            this.showLoader = false;
            this.modifiedLocationMaintenanceData = response;
            this.locationMaintenanceData = response;
            this.setLocationMaintenanceData(response);
            this.setbackupLocationMaintenanceData(response);
            this.createDataSourceGroupByLocation(response);
            this.isRefreshed = false;
            this.selectedlocnName = 'All';
            this.selectedBaseSystem = { value: 'All', label: 'All' };
            this.selectedStatus = { value: 'All', label: 'All' };
            this.selectedLocationId = 0;
            this.selectedLocationDetails = {};
        });
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @Catch(() => errorHandler(LocationMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const baseSystemId = this.selectedBaseSystem.label === 'All' ? 0 : this.baseSystemData.filter((system) => system.plName === this.selectedBaseSystem.label)[0].pipelineID;
        const locationName = this.selectedlocnName === '' ? 'All' : this.selectedlocnName;
        const serviceStatus = this.selectedStatus.label === 'All' ? this.selectedStatus.label : this.selectedStatus.label === 'Active' ? 'A' : 'I';
        const response: LocationMaintenanceResponse[] = await this.locationMaintenanceDataService.getLocationsDataWithFilter(locationName, baseSystemId, serviceStatus );
        runInAction(() => {
            this.showLoader = false;
            this.modifiedLocationMaintenanceData = response;
            this.locationMaintenanceData = response;
            this.setLocationMaintenanceData(response);
            this.selectedLocationId = 0;
            this.selectedLocationDetails = {};
            // this.setbackupLocationMaintenanceData(response);
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    getColDef() {
        this.updatePushtoVmacsButtonColDef();
        return locationColDef;
    }

    getAddLocationDetailsColDef() {
        this.updatePushtoVmacsButtonColDef();
        return AddLocationDetailsColDef;
    }

    @action
    reset(): void {
        this.setLocationMaintenanceData(this.backupLocationMaintenanceData);
        this.modifiedLocationMaintenanceData = this.backupLocationMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setLocationMaintenanceData(locationMaintenanceData: LocationMaintenanceResponse[]): void {
        this.locationMaintenanceData = locationMaintenanceData
    }

    setbackupLocationMaintenanceData(backUpList: LocationMaintenanceResponse[]) {
        this.backupLocationMaintenanceData = backUpList;
    }

    @computed
    get LocationMaintenanceData() {
        return toJS(this.locationMaintenanceData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(LocationMaintenanceMessages.FAILED_SUBMIT))
    async saveLocation(saveAndAddNew): Promise<void> {
        const updatedRowIDs = this.addOrEditLocationGridData.filter(a => a.isSelected === true);
        const locationDetailDTOs: any = [];
        let requestBody: any = {};
        const localValidatingErrorBody: any = [];
        if(this.addOrEditedLocnName && this.addOrEditedLocnName !== '') {
            if(!this.selectedLocationId && this.addOrEditedLocnName.toUpperCase().startsWith('Z-')) {
                const message = 'Please insert a valid Location Name for New Location';
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                } 
            } 
            const filteredLocationData = this.locationMaintenanceData.filter((location) => location.locnName.toLowerCase().trim() === this.addOrEditedLocnName.toLowerCase().trim());
            const existingLocation  = filteredLocationData.some((location) => location.locationID !==  this.selectedLocationId)
            const isUniqueLocation = this.onClickLocationNameOnGrid ? !existingLocation : filteredLocationData.length <=0;
            if(isUniqueLocation) {
                updatedRowIDs.forEach((item) => {
                    const serviceDate = item.serviceDate ? moment.utc(new Date(item.serviceDate)).format('YYYY-MM-DDTHH:mm:ss')  : null;
                    item.locationRelPLID = item.locationRelPLID && item.locationRelPLID > 0 ? item.locationRelPLID: 0,
                    item.pipelineID = item.pipelineID && item.pipelineID > 0 ? item.pipelineID : 0,
                    item.serviceDate = serviceDate,
                    item.serviceStatus = item.serviceStatus === 'Active' || item.serviceStatus === 'A' ?  'A' : 'I';
                    if(item.serviceDate) {
                        locationDetailDTOs.push(item);
                        const initialData = locationMaintenanceDataStore.addOrEditLocationGridData.filter(a => (!locationMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString())));
                        if(this.selectedLocationId && initialData.filter((item) => item.isSelected === true).length > 1 && !this.addOrEditedLocnName.toUpperCase().startsWith('Z-') ) {
                            const message = ' You are trying to update an existing Location with multiple Pipelines. You must prefix the Location name with Z- to save this record.';
                            if(!localValidatingErrorBody.includes(message)) {
                                localValidatingErrorBody.push(message);
                            } 
                        } else if(locationDetailDTOs.length > 1 && !(initialData.filter((item) => item.isSelected === true).length > 1) && !this.addOrEditedLocnName.toUpperCase().startsWith('Z-')) {
                            const message = ' Multiple Pipelines cannot be linked to a Location. Please select only one Pipeline.';
                            if(!localValidatingErrorBody.includes(message)) {
                                localValidatingErrorBody.push(message);
                            } 
                        } 
                    } else {
                        const message = 'Please select valid service date for the linked pipeline';
                        if(!localValidatingErrorBody.includes(message)) {
                            localValidatingErrorBody.push(message);
                        }
                    }
                });
            } else {
                const message = 'Tried to create duplicate location. Please create the unique record.';
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            } 
        } else {
            const message = 'Please insert valid Location Name as mandatory fields.';
            if(!localValidatingErrorBody.includes(message)) {
                localValidatingErrorBody.push(message);
            }
        }
        if(this.navigateToScreen && this.workflowId && locationDetailDTOs.length < 1) {
            const message = 'Please linked the location to atleast one pipeline.';
            if(!localValidatingErrorBody.includes(message)) {
                localValidatingErrorBody.push(message);
            }
        }
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(updatedRowIDs.length === locationDetailDTOs.length) {
            // this.addOrEditedLocnName = locationDetailDTOs.length > 1 && !this.addOrEditedLocnName.startsWith('Z-') ? `Z- ${this.addOrEditedLocnName}` : this.addOrEditedLocnName;
            requestBody = { locationID: this.selectedLocationId, 
                locnName: this.addOrEditedLocnName, 
                createUser: this.userName,
                locationDetailDTOs: locationDetailDTOs,
                workflowId : this.workflowId ? this.workflowId : 0
            } 
            const response: LocationMaintenanceResponse[] = await this.locationMaintenanceDataService.updateLocationMaintenanceData(requestBody, this.onClickLocationNameOnGrid);
            if (response) {
                dataStore.setShowUnsavedWarningAlert(false);
                // this.onClickLocationNameOnGrid = false; 
                this.cellValueChangeMap = {};
                this.selectedLocationId = 0;
                this.selectedLocationDetails = {};
                this.updatedFieldonLocationDetailsScreen = false;
                this.uiService.toastService.success(LocationMaintenanceMessages.SAVE);
                this.openLocationDetails({locnName: this.addOrEditedLocnName.trim()})
                // if(!saveAndAddNew) {
                    this.showLoader = false;
                    
                    // this.onCloseLocationDetails();
                // } else {
                          
                // }

            }
        }
    }


    @action
    async resetAddLocationMaintenanceGridData(): Promise<void> {
            this.addOrEditedLocnName = '';
            this.addOrEditLocationGridData = [];
            this.backupAddOrEditLocationGridData = [];
            this.selectedLocationId = 0;
            const addOrEditLocationGridData: any = [];
            if(this.workflowId && this.navigateToScreen) {
                const workflowLinkerData =  await this.locationMaintenanceDataService.getWorkflowLinkerData(this.workflowId);
                if(workflowLinkerData && workflowLinkerData.length  > 0) {
                     const baseTariffData: any = [];
                     const pipeline = workflowLinkerData[0].pipeline.split(';');
                     pipeline.map((item1)=> {
                         this.baseSystemData.map((item) =>
                         {
                             if(item1 === item.plName) {
                                item.serviceStatus =  'Active',
                                item.serviceDate =  null,
                                item.allowTrades = 'N'
                                item.isSelected = false
                                baseTariffData.push(item)
                             }
                         });
 
                     })
                     this.setAddOrEditLocationGridData(baseTariffData);
                     this.setBackUpAddOrEditLocationGridData(baseTariffData);
                }
               
             } else if(this.navigateState && this.navigateState.pipeline) {
                const baseTariffData: any = [];
                this.baseSystemData.map((item) =>
                {
                    if(this.navigateState.pipeline === item.plName) {
                       item.serviceStatus =  'Active',
                       item.serviceDate =  null,
                       item.allowTrades = 'N'
                       item.isSelected = false
                       baseTariffData.push(item)
                    }
                });
                this.setAddOrEditLocationGridData(baseTariffData);
                this.setBackUpAddOrEditLocationGridData(baseTariffData);
             }else {
                this.baseSystemData.map((item) =>
                {
                   item.serviceStatus =  'Active',
                   item.serviceDate =  null,
                   item.allowTrades = 'N'
                   item.isSelected = false
                   addOrEditLocationGridData.push(item)
               });
                this.setAddOrEditLocationGridData(addOrEditLocationGridData);
                this.setBackUpAddOrEditLocationGridData(addOrEditLocationGridData);
             }
            
            this.onClickLocationNameOnGrid = false;
            this.isRefreshed = false;
            this.cellValueChangeMap ={};
        this.agGridServiceForAddOrEditLocation.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(LocationMaintenanceMessages.FAILED_SUBMIT))
    async deleteLocation(): Promise<void> {
        const requestBody = { 
            locationID: this.selectedLocationId, 
            locnName:this.addOrEditedLocnName,
            createUser: this.userName, 
            locationDetailDTOs: [] 
        }
        await this.locationMaintenanceDataService.deleteLocationMaintenanceData(requestBody).then((response) => {
            if(response && response.value) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                  () => {console.log('')},
                  ConfirmWithOK,
                  {secondaryText: response.value !== '' ? response.value : 'Record cannot be deleted because it is linked to a base system. Unlink location from base system.'} as ConfirmWithOKProps,
                  'confirmPopup'
                );
                const locationData = {locnName: locationMaintenanceDataStore.addOrEditedLocnName.trim()}
                locationMaintenanceDataStore.openLocationDetails(locationData);
                return ;
            }
            dataStore.setShowUnsavedWarningAlert(false);
            this.onClickLocationNameOnGrid = false;
            this.onClickAddNewLocationButton = false;
            this.agGridServiceForAddOrEditLocation.destroyGrid();
            this.isActivateButtonDisabled= true;
            this.updatedFieldonLocationDetailsScreen = false;
            this.onCloseLocationDetails();
        });
    }



//  addValuesInCellDropdowns(): void {
//         // const baseSystemDropdownOptions = this.selectedlocnName !== 'All' ? this.baseSystemDropdownOptions.filter((item) => item.valueText  === this.selectedlocnName) : this.baseSystemDropdownOptions;
//         // this.agGridService.updateOptionCellEditorValues(
//         //     baseSystemDropdownOptions,
//         //     'pipeline',
//         //     'plName'
//         // );

//         this.agGridServiceForAddOrEditLocation.updateOptionCellEditorValues(
//             ServiceStatus,
//             'serviceStatus',
//             'status'
//         );
//     }
     

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    // @action
    // updateColumn(item: any, value: string, key: string, popup?: boolean ) {
    //     this.setValueInChangeMap(item.ID, key, item[key], value);
    //     item[key] = value;
    //     this.updateRow(item);
    // }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isDeleteDisabled = ()  => {
      const requestBody: any = this.addOrEditLocationGridData.filter(a => a.isSelected === true);
      console.log(requestBody);
        return !this.selectedLocationId || requestBody.length > 0;
    };
    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    updatePushtoVmacsButtonColDef = () => {
        const pushToVmacsButtonColDef = locationColDef.find(x => x.colId === 'PushToVMACS');
        if (pushToVmacsButtonColDef) {
            pushToVmacsButtonColDef.hide = !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)) ? true : false,
            pushToVmacsButtonColDef.suppressColumnsToolPanel= !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd))? true : false,
            pushToVmacsButtonColDef.cellRendererParams = {
                dataStore : locationMaintenanceDataStore,
                handleClickPushVmacs : (env, data) => this.pushToVmacs(env, data),
                updatedData : this.getUpdatedRows() 
            };
        }
    }

    getUpdatedRows = () => {
        const updatedRowIDs = this.locationMaintenanceData.filter(a => this.getUpdatedRowIDs().includes(a.locationID.toString()));
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushToVmacs(env, data): Promise<void> {
        // const action = data.action === 'U' ? 'Update' : 'Insert';
        const locationRelPLID =  data.locationRelPLID === 0 ? '' :  data.locationRelPLID.toString();
        const requestBody: any =  { 
            'http_action' : null,
            'http_record_id': data.locationID.toString(),
            'http_childrecord_id': locationRelPLID,
            'http_cts_tablename': LocationSettingsDBTableName.TableName,
            'http_cts_mappingtablename' : LocationSettingsDBTableName.MappingTableName,
            'http_userid': this.userName,
            'http_environment': env.toUpperCase() 
        };
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response = await this.locationMaintenanceDataService.pushToVmacs(requestBody);
            // if(response) {
                await this.onSearchClick();
                this.uiService.toastService.success(LocationMaintenanceMessages.SYNC);
            // }
        }

    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const selectedRowData = this.addOrEditLocationGridData;
        if(selectedRowData && selectedRowData.length > 0) {
            const locations = this.selectedLocationId.toString();
            const linkedpipelines= (selectedRowData.filter((item) => item.locationRelPLID && item.locationRelPLID !== 0).map(x => x.locationRelPLID )).join(',');
            const requestBody: any =  { 
                'http_action' : null,
                'http_record_id': locations,
                'http_childrecord_id': linkedpipelines,
                'http_cts_tablename': LocationSettingsDBTableName.TableName,
                'http_cts_mappingtablename' : LocationSettingsDBTableName.MappingTableName,
                'http_userid': this.userName,
                'http_environment': env.toUpperCase()   
            };
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.locationMaintenanceDataService.pushToVmacs(requestBody);
                // if(response) {
                    // await this.onSearchClick();
                    this.updatedFieldonLocationDetailsScreen = false;
                    this.uiService.toastService.success(LocationMaintenanceMessages.SYNC);
                    this.openLocationDetails({locnName: this.addOrEditedLocnName.trim()});
                    
                // }
            }
    
        }
       
    }

    @action
   isEditableJurisdiction(params) {
        if (params.data.locationID <= 0) {
        return true
        }
        return false;
   }

   @action
   async onChangeLocationName(value) {
        this.selectedlocnName = value;
   }
   
   mapEditableColumnsLocationsMainGrid(currentItem: any, updatedItem: any) {
    currentItem.locationRelPLID = updatedItem.locationRelPLID;
    currentItem.locationID = updatedItem.locationID;
    currentItem.locnName = updatedItem.locnName;
    currentItem.locnCode = updatedItem.locnCode;
    currentItem.pipelineID = updatedItem.pipelineID;
    currentItem.plName = updatedItem.plName;
    currentItem.syncStatus = updatedItem.syncStatus;
    currentItem.testSyncDate = updatedItem.testSyncDate;
    currentItem.prodSyncDate = updatedItem.prodSyncDate;
    currentItem.serviceStatus = updatedItem.serviceStatus;
    currentItem.allowTrades = updatedItem.allowTrades;
    currentItem.serviceDate = updatedItem.serviceDate;
    currentItem.action = updatedItem.action;
}


   @Loader
    @action
    @Catch(() => errorHandler(LocationMaintenanceMessages.FAILED_SUBMIT))
    async updateStatusFromDelToActive(): Promise<void> {
        const selectedRowIDs: any = await this.agGridService.getSelectedRows();
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        selectedRowIDs.forEach((item) => {
            if(item.locnName && item.locnName !== '') {
                const isUniqueLocation = this.locationMaintenanceData.filter((location) => item.locnName === location.locnName).length <= 1;
                if(isUniqueLocation) {
                    item.createUser =  this.userName,
                    item.locationID = item.locationID ? item.locationID : 0,
                    item.action = item.action && item.action === 'D' ? 'U' : item.action,
                    item.locationDetailDTOs = []
                    requestBody.push(item);
                } else {
                    const message = `Tried to create duplicate location with Location Code as ${item.locnCode} and Location Name as ${item.locnName}. Please create the unique record.`;
                    if(!localValidatingErrorBody.includes(message)) {
                        localValidatingErrorBody.push(message);
                    }
                }
            } else {
                const message = 'Please insert valid Location Name as mandatory fields.';
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        });
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
            const response: LocationMaintenanceResponse[] = await this.locationMaintenanceDataService.updateStatusFromDelToActive(requestBody);
            let errorInSave = false;
            let errorSummary: ToastMessage[] = [];
            if (response) {
                for (const key in response) {
                    if (response[key].locationID) {
                        const objIndex = this.locationMaintenanceData.findIndex(a => ((a.locationID === response[key].locationID)|| (a.locnCode === response[key].locnCode && a.locnName === response[key].locnName)));
                        if(objIndex >= 0) {
                            const backupItem = this.locationMaintenanceData[objIndex];
                            this.mapEditableColumnsLocationsMainGrid(backupItem, response[key]);
                        }
                    }
                    else {
                        errorInSave = true;
                        errorSummary = [...errorSummary, { id: key, description: response[key].locationID.toString() }];
                    }
                }
            }
            this.cellValueChangeMap = {};
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                    for (let i = 0; i < this.locationMaintenanceData.length; i++) {
                        const updatedData = this.locationMaintenanceData[i];
                        if (updatedData.locationID.toString() === errorMessage.id) {
                            const backupRowIndex = this.locationMaintenanceData.findIndex(a => a.locationID.toString() == errorMessage.id);
                            this.mapEditableColumnsLocationsMainGrid(updatedData, this.locationMaintenanceData[backupRowIndex]);
                        }
                    }
                });
            }
            else {
                this.uiService.toastService.success(LocationMaintenanceMessages.SAVE);
            }
            runInAction(() => {
                this.showLoader = false;
                this.isRefreshed = false;
                this.isActivateButtonDisabled= true;
                dataStore.setShowUnsavedWarningAlert(false);
            })  
        }
    }

    @Loader
    @action
    async openLocationDetails(data) {
        this.agGridService.destroyGrid();
       
            const selectedlocnName = data.locnName;
            const response: LocationMaintenanceResponse[] = await this.locationMaintenanceDataService.getLocationsDataWithFilter(selectedlocnName, 0, 'All' );
            if(response) {
                runInAction(async () => {
                    this.showLoader = false;
                    this.addOrEditLocationGridData = [];
                    this.backupAddOrEditLocationGridData = [];
                    this.cellValueChangeMap = {};
                    const locationData = response.filter((item) => item.locnName === data.locnName);
                    this.selectedLocationDetails = locationData.length > 0 ? locationData[0] : {};
                    this.addOrEditedLocnName = locationData[0].locnName;
                    this.selectedLocationId = locationData[0].locationID;
                    const addOrEditLocationGridData: any = [];
                    if(this.workflowId && this.navigateToScreen) {
                        const workflowLinkerData =  await this.locationMaintenanceDataService.getWorkflowLinkerData(this.workflowId);
                        if(workflowLinkerData && workflowLinkerData.length  > 0) {
                             const baseTariffData: any = [];
                             const pipeline = workflowLinkerData[0].pipeline.split(';');
                             pipeline.map((item1)=> {
                                 this.baseSystemData.map((item) =>
                                 {
                                     if(item1 === item.plName) {
                                        const linkedPipeline = locationData.filter((location) => location.pipelineID === item.pipelineID)
                                        const serviceStatus = linkedPipeline.length > 0  && linkedPipeline[0].serviceStatus ? linkedPipeline[0].serviceStatus !== 'A' ? 'Inactive' : 'Active' :'Active'
                                        item.serviceStatus =  serviceStatus
                                        item.serviceDate = linkedPipeline.length > 0 ? linkedPipeline[0].serviceDate : null,
                                        item.allowTrades = linkedPipeline.length > 0 ? linkedPipeline[0].allowTrades : 'N',
                                        item.locationRelPLID = linkedPipeline.length > 0 ? linkedPipeline[0].locationRelPLID : 0,
                                        item.isSelected = linkedPipeline.length > 0 ? true : false,
                                        item.syncStatus = linkedPipeline.length > 0 ? linkedPipeline[0].syncStatus : SyncStatus[0]
                                        baseTariffData.push(item)
                                     }
                                    
                                 });
         
                             })
                             this.setAddOrEditLocationGridData(baseTariffData);
                             this.setBackUpAddOrEditLocationGridData(baseTariffData);
                        }
                       
                     } else if(this.navigateState && this.navigateState.pipeline) {
                        const baseTariffData: any = [];
                        this.baseSystemData.map((item) =>
                        {
                            if(this.navigateState.pipeline === item.plName) {
                                const linkedPipeline = locationData.filter((location) => location.pipelineID === item.pipelineID)
                                const serviceStatus = linkedPipeline.length > 0  && linkedPipeline[0].serviceStatus ? linkedPipeline[0].serviceStatus !== 'A' ? 'Inactive' : 'Active' :'Active'
                                item.serviceStatus =  serviceStatus
                                item.serviceDate = linkedPipeline.length > 0 ? linkedPipeline[0].serviceDate : null,
                                item.allowTrades = linkedPipeline.length > 0 ? linkedPipeline[0].allowTrades : 'N',
                                item.locationRelPLID = linkedPipeline.length > 0 ? linkedPipeline[0].locationRelPLID : 0,
                                item.isSelected = linkedPipeline.length > 0 ? true : false,
                                item.syncStatus = linkedPipeline.length > 0 ? linkedPipeline[0].syncStatus : SyncStatus[0]
                                baseTariffData.push(item)
                            }
                        });
                        this.setAddOrEditLocationGridData(baseTariffData);
                        this.setBackUpAddOrEditLocationGridData(baseTariffData);
                     }else {
                        this.baseSystemData.map((item) =>
                            {
                                const linkedPipeline = locationData.filter((location) => location.pipelineID === item.pipelineID)
                                const serviceStatus = linkedPipeline.length > 0  && linkedPipeline[0].serviceStatus ? linkedPipeline[0].serviceStatus !== 'A' ? 'Inactive' : 'Active' :'Active'
                                item.serviceStatus =  serviceStatus
                                item.serviceDate = linkedPipeline.length > 0 ? linkedPipeline[0].serviceDate : null,
                                item.allowTrades = linkedPipeline.length > 0 ? linkedPipeline[0].allowTrades : 'N',
                                item.locationRelPLID = linkedPipeline.length > 0 ? linkedPipeline[0].locationRelPLID : 0,
                                item.isSelected = linkedPipeline.length > 0 ? true : false,
                                item.syncStatus = linkedPipeline.length > 0 ? linkedPipeline[0].syncStatus : SyncStatus[0]
                                addOrEditLocationGridData.push(item)
                            });
                            this.setAddOrEditLocationGridData(addOrEditLocationGridData);
                            this.setBackUpAddOrEditLocationGridData(addOrEditLocationGridData);
                     }
                    this.isRefreshed = false;
                    this.onClickLocationNameOnGrid = true;
                    dataStore.setShowUnsavedWarningAlert(false);
                    if(history.location.pathname !== '/LocationDetails')
                    history.push('/LocationDetails');
                })
            } else {
                this.getSelectedLocationData();
            }
           
        // }
        
;    }

    @action
    onCloseLocationDetails() {
        console.log(dataStore.showUnsavedWarningAlert);
        if(this.navigateToScreen) {
            history.push(this.navigateToScreen, {workflowId : this.workflowId, tariffName: this.navigateState?.tariffName, tariffNumber: this.navigateState?.tariffNumber, baseTariff: this.navigateState?.baseTariff, tariffLogId : this.navigateState?.tariffLogId});
            this.addOrEditedLocnName = '';
            this.addOrEditLocationGridData = [];
            this.backupAddOrEditLocationGridData = [];
            this.selectedLocationId = 0;
            this.onClickLocationNameOnGrid = false;
            this.isRefreshed = false;
            this.cellValueChangeMap ={};
            this.agGridServiceForAddOrEditLocation.getNodes()?.refreshCells();  
            dataStore.setShowUnsavedWarningAlert(false);
        } else {
            history.push('/LocationMaintenance', { from: '/LocationDetails' });   
        }
        
    }


    @Loader
    @Catch(() => errorHandler(LocationMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async getSelectedLocationData(): Promise<void> {
            runInAction(() => {
                this.addOrEditedLocnName = '';
                this.addOrEditLocationGridData = [];
                this.backupAddOrEditLocationGridData = [];
                this.cellValueChangeMap = {};
                this.selectedLocationId = 0;
                const addOrEditLocationGridData: any = [];
                this.baseSystemData.map((item) =>
                 {
                    item.locationRelPLID = 0,
                    item.serviceStatus =  'Active',
                    item.serviceDate =  null,
                    item.allowTrades = 'N',
                    item.isSelected = false,
                    item.syncStatus = SyncStatus[0]
                    addOrEditLocationGridData.push(item)
                });
                this.setAddOrEditLocationGridData(addOrEditLocationGridData);
                this.setBackUpAddOrEditLocationGridData(addOrEditLocationGridData);
                this.isRefreshed = false;
            })
            this.agGridService.destroyGrid();
        this.agGridServiceForAddOrEditLocation.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
   async onEnterLocationforAddOREdit(value) {
        if(this.addOrEditedLocnName !== value) {
            this.updatedFieldonLocationDetailsScreen = value
        }
        this.addOrEditedLocnName = value   
   }

   @action
   setAddOrEditLocationGridData(addOrEditLocationGridData: any): void {
       this.addOrEditLocationGridData = addOrEditLocationGridData
   }

   setBackUpAddOrEditLocationGridData(addOrEditLocationGridData: any): void {
       this.backupAddOrEditLocationGridData = addOrEditLocationGridData
   }

   @computed
    get AddOrEditLocationGridData() {
        return toJS(this.addOrEditLocationGridData);
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditLocationGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditLocationGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsLocationDetailsGrid(this.addOrEditLocationGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsLocationDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.serviceStatus = updatedItem.serviceStatus;
        currentItem.allowTrades = updatedItem.allowTrades;
        currentItem.isSelected = updatedItem.isSelected
        currentItem.serviceDate = updatedItem.serviceDate;
    }

    handleAllowTradeCheckBoxChange(params, value) {
        const row = params.data.pipelineID;
        const initValue = params.data['allowTrades'];
        this.setValueInChangeMap(row, 'allowTrades', initValue, value);
        if(initValue === 'Y') {
            params.data['allowTrades'] = 'N'
        } else {
            params.data['allowTrades'] = 'Y'
        }
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data)
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected;
        if(this.addOrEditLocationGridData.filter((item) => item.isSelected === true).length > 0 && checked) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () =>  { this.setValueInChangeMap(row, 'isSelected', initValue, checked);
                params.data.isSelected = checked;
                params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
                this.updateRowforAddOrUpdateGrid(params.data);
                },
                'Multiple Pipeline',
                'Do you want to link location to multiple pipelines?'
            );
        } else {
            this.setValueInChangeMap(row, 'isSelected', initValue, checked);
            params.data.isSelected = checked;
            params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
            this.updateRowforAddOrUpdateGrid(params.data);
        }
    };

    @action
    headerCheckBoxSelection = (event) => {
        const checked = event.target.checked;
        const filters =  this.agGridServiceForAddOrEditLocation.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLocationGridData.filter((item) => ((filters.plName && filters.plName.values.includes(item.plName)) || (filters.serviceStatus && filters.serviceStatus.values.includes(item.serviceStatus)) || (filters.serviceDate && filters.serviceDate.values.include(item.serviceDate)) || (filters.serviceStatus && filters.serviceStatus.values.includes(item.serviceStatus)) || (filters.allowTrades && filters.allowTrades.values.includes(item.allowTrades)))) : this.addOrEditLocationGridData;
        // const data = filteredData.map((item) => { item.isSelected = checked; return (item)})
       
            if(checked) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () =>  {  filteredData.forEach((item) => {this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                    })},
                    'Multiple Pipeline',
                    'Do you want to link location to multiple pipelines?'
                );
                
            } else {
                filteredData.forEach((item) => {
                    this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                });
            }
    };

    @action
    checkIfHeaderCheckboxShouldbeChecked = (params) => {
        const filters =  this.agGridServiceForAddOrEditLocation.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLocationGridData.filter((item) => ((filters.plName && filters.plName.values.includes(item.plName)) || (filters.serviceStatus && filters.serviceStatus.values.includes(item.serviceStatus)) || (filters.serviceDate && filters.serviceDate.values.include(item.serviceDate)) || (filters.serviceStatus && filters.serviceStatus.values.includes(item.serviceStatus)) || (filters.allowTrades && filters.allowTrades.values.includes(item.allowTrades)))) : this.addOrEditLocationGridData;
        return filteredData.some((item) => item.isSelected === false)
    }

    @action
    globalServiceDateSelection = (serviceDate) => {
            const data = this.addOrEditLocationGridData.filter((item) => item.isSelected === true).map((item) => { item.serviceDate = serviceDate; return (item)})
            data.map((item) => {
                this.updateRowforAddOrUpdateGrid(item);
            }) 
    };

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }
}
