import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';
import { TariffLogEntry } from '../constant/enums';

export class TariffLogDataService {
    constructor(private restApi: RestApiService) {}

    
    async getBaseTariff(): Promise<any[]> {
//        const { data } = await this.restApi.get<any>(Path.url_id15); 
        const { data } = await this.restApi.get<any>(Path.getBaseTariffJointCarriers);
        
        return data;
    }

    async getSelectedBaseTariffDetails(id, jtId, logEntryId, logId?: null, isLogProcessed?): Promise<any[]> {
        //        const { data } = await this.restApi.get<any>(Path.url_id15); 
        if(logId) {
            const { data } = await this.restApi.get<any>(`${Path.url_id16}?baseID=${id}&jtID=${jtId}&process=${isLogProcessed}&logEntryID=${logEntryId}&logId=${logId}`);
                
                return data;
        } else {
            const { data } = await this.restApi.get<any>(`${Path.url_id16}?baseID=${id}&jtID=${jtId}&process=false&logEntryID=${logEntryId}`);
                
            return data;
        }
                
    }

    async getPipelineAreaData(id : number): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id14+id);
        return data;
    }

    async getProjectTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id17);
        return data;
    }

    async getProjectNames(logEntryType?): Promise<any[]> {
        if(logEntryType) {
            const logType =  logEntryType === TariffLogEntry.Maintenance ? 'Maintenance' : 'Tariff%20Filing';
            const { data } = await this.restApi.get<any>(`${Path.url_id18}?logType=${logType}`);
            return data;
        }
        const { data } = await this.restApi.get<any>(Path.url_id18);
        return data;
    }

    async uploadFile(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.url_id19, formData);
        return data;
    }

    async updateAttachment(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffUpdateAttachment, reqbody, {});
        return data;
    }

    async downloadAttachment(fileID): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffDownloadAttachment+`?docId=${fileID}`);
        return data;
    }

    async deleteAttachment(docID): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.tariffDeleteAttachment+`?docId=${docID}`);
        return data;
    }

    async saveLogEntry(formData, logEntryType?): Promise<any[]> {
        if(logEntryType && logEntryType.toLowerCase() === 'Maintenance'.toLowerCase()) {
            const { data } = await this.restApi.put<any>(Path.url_id20, formData);
            return data;
        } else {
            const { data } = await this.restApi.post<any>(Path.url_id20, formData);
            return data; 
        }
        
    }

    async saveLogEntryFromLogEntryScreen(formData, logEntryType?): Promise<any> {
        if(logEntryType && logEntryType.toLowerCase() === 'Maintenance'.toLowerCase()) {
            const { data } = await this.restApi.post<any>(Path.saveMaintenanceLogEntry, formData);
            return data;
        } else {
            const { data } = await this.restApi.post<any>(Path.saveLogEntry, formData);
            return data; 
        }
        
    }
    
    async saveVMACSSetup(formData): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.url_id20, formData);
        return data;
    }

    async getLogEntryTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLogEntryTypeData);
        return data;
    }

    async getWorkflowInstanceData(userName, logEntryType): Promise<any> {
        const workFlowName = logEntryType === TariffLogEntry.TariffFiling ?  'Tariff Filing' :
                            logEntryType === TariffLogEntry.TariffFilingIndex ? 'Index' : 
                            logEntryType === TariffLogEntry.Request ? 'Tariff Filing' : 'Tariff Maintenance';
        if(workFlowName) {
            const request = {
                workFlowName: workFlowName,
                criteria: '',
                createUser:userName
            }
            const { data } = await this.restApi.post<any>(Path.step0_postWorkflowDetails, request);
            return data;
        }
    }

    async getWorkflowStatusGridData(workflowId, criteria?): Promise<any> {
        if(criteria) {
            const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowStatusGridDetails+`?workflowId=${workflowId}&criteria=${criteria}`);
            return data;
        }
        const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowStatusGridDetails+`?workflowId=${workflowId}`);
        return data;
    }

    
    async getWorkflowHistoryStatusGridData(workflowId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowHistoryStatusGridDetails+`?workflowId=${workflowId}`);
        return data;
    }

    async logEntrySendEmailWorkflow(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_1_sendEmailWorkflow, reqbody, {});
        return data;
    }

    async saveWorkflowComments(workflowId, comments, username,workflowStepFlowId ): Promise<any[]> {
        const reqBody = { 'workflowID': workflowId, 'comment': comments, 'user': username, 'workflowStepFlowID': workflowStepFlowId }
        const { data } = await this.restApi.post<any>(Path.step_1_saveComments, reqBody, {});
        return data;
    }

    async cancelWorkflow(username,workflowStepId ): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.cancelWorkflow+`?workflowStepID=${workflowStepId}&User=${username}`, {}, {});
        return data;
    }

    async updateWFTariffLogInfo(workflowId, logId, tariffNumber ): Promise<any> {
        const { data } = await this.restApi.post<any>(Path.updateWFTariffLogInfo+`?workflowID=${workflowId}&LogId=${logId}&TariffNo=${tariffNumber}`);
        return data;
    }

    async saveTestDetermination(projectName, logId, userName, workflowID): Promise<any> {
        const { data } = await this.restApi.post<any>(Path.saveTestDetermination+`?logId=${logId}&user=${userName}&workflowID=${workflowID}`);
        return data;
    }

    async getLog1DStatus(logId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.getLog1DStatus+`?logID=${logId}`);
        return data;
    }

    async autoUpdateWorkflowStepstatusComplete(workflowStepId: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.autoUpdateWorkflowStepstatusComplete+`?workflowStepId=${workflowStepId}`);
        return data;
    }

    async autoUpdateWorkflowStepstatusReject(workflowStepId: any, userName: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.autoUpdateWorkflowStepstatusReject+`?workflowStepId=${workflowStepId}&user=${userName}`);
        return data;
    }

    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }

    
}

export const tariffLogDataService = new TariffLogDataService(restApiService);
