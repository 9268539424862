import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LocationMaintenanceDataStore } from '../stores/location-maintenance-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { LocationMaintenanceResponse } from '../model/model';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/location.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { locationMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface LocationMaintenanceContainerProps {
    locationMaintenanceDataStore: LocationMaintenanceDataStore;
}

interface LocationMaintenanceContainerState {
    search: string;
}

@inject('locationMaintenanceDataStore')
@observer
export class LocationMaintenanceContainer extends Component<LocationMaintenanceContainerProps, LocationMaintenanceContainerState> {
    constructor(props: LocationMaintenanceContainerProps | Readonly<LocationMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Location Maintenance')
    }
    
    componentDidMount() {
      const {locationMaintenanceDataStore} = this.props;
      const previousLocation = history?.location?.state as { from?: string }
      history.push('/LocationMaintenance');
        if(previousLocation?.from && previousLocation.from==='/LocationDetails'){
           locationMaintenanceDataStore.onSearchClick();   
        } else {
            locationMaintenanceDataStore.init();   
        }
      // locationMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {locationMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Location Maintenance_filter');
      this.props.locationMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Location Maintenance_' + accountStore.userName);
      this.props.locationMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    // componentWillUnmount() {
    //   this.props.locationMaintenanceDataStore.onClickAddNewLocationButton = false;
    //   this.props.locationMaintenanceDataStore.onClickLocationNameOnGrid = false;
    //   this.props.locationMaintenanceDataStore.reset();
    // }
    
    onBtClearSelection = () => {
      const { locationMaintenanceDataStore } = this.props;
      locationMaintenanceDataStore.onClearSearch();
    }

    onSearchClick = () => {
      const { locationMaintenanceDataStore } = this.props;
      locationMaintenanceDataStore.onSearchClick();
    }

    onAddNewClick = async() => {
      const { locationMaintenanceDataStore } = this.props;
        
      locationMaintenanceDataStore.onClickAddNewLocationButton = true;
      locationMaintenanceDataStore.onClickLocationNameOnGrid = false;
      locationMaintenanceDataStore.agGridService.destroyGrid();
      locationMaintenanceDataStore.getBaseSystemDropdown();
      locationMaintenanceDataStore.getSelectedLocationData();
      history.push('/LocationDetails');
    }

    onChangeLocationName = (e) => {
      this.props.locationMaintenanceDataStore.onChangeLocationName(e.target.value)
    }

    handleStateChangeFromDelToActive = async() => {
      const { locationMaintenanceDataStore } = this.props;
      const requestBody: any = await locationMaintenanceDataStore.agGridService.getSelectedRows();
      const updatedRowIDs = locationMaintenanceDataStore.locationMaintenanceData.filter(a => locationMaintenanceDataStore.getUpdatedRowIDs().includes(a.locationID.toString()));
      if(requestBody.length > 0 && !requestBody.some((item) => item.action !== 'D')) {
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.locationMaintenanceDataStore.updateStatusFromDelToActive(),
            'Active',
            'All the unsaved changes will be lost. Are you sure you want to activate the selected locations?'
        );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.locationMaintenanceDataStore.updateStatusFromDelToActive(),
            'Active',
            'Are you sure you want to activate the selected locations?'
        );
        }
      } else {
        const secondaryText = requestBody.length > 0 ? 'Please select only the inactive locations to perform the operation' : 'No records are selected. Please select atleast one inactive location to perform the operation.';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
      console.log(' succesfully');
    }

    handleBulkSync = async(env) => {
      // const { locationMaintenanceDataStore } = this.props;
      // const requestBody: any = await locationMaintenanceDataStore.agGridService.getSelectedRows();
      // const updatedRowIDs = locationMaintenanceDataStore.locationMaintenanceData.filter(a => locationMaintenanceDataStore.getUpdatedRowIDs().includes(a.locationID.toString()));
      // if(requestBody.length > 0 && requestBody.some((item) => item.action !== 'D') && !requestBody.some((item) => item.syncStatus === SyncStatus[2])) {
      //   if(updatedRowIDs.length > 0) {
      //     const confirmService = new ConfirmService();
      //     confirmService.showConfirmDialog(
      //       () => locationMaintenanceDataStore.pushSelectedRowsToVmacs(env),
      //       'Active',
      //       `All the unsaved changes will be lost. Are you sure you want to push the selected data to the ${env} Environment?`
      //   );
      //   } else {
      //     if(requestBody.some((item) => item.syncStatus === SyncStatus[0]) && env === 'Prod') {
      //       const confirmService = new ConfirmService();
      //       confirmService.showConfirmWithCustomComponent(
      //         () => {},
      //         ConfirmWithOK,
      //         {secondaryText: 'Some of the selected records has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
      //         'confirmPopup'
      //       );
      //     } else if(requestBody.some((item) => item.syncStatus === SyncStatus[1]) && env === 'Test') {
      //       const confirmService = new ConfirmService();
      //       confirmService.showConfirmWithCustomComponent(
      //         () => {},
      //         ConfirmWithOK,
      //         {secondaryText: 'Some of the selected records have already been verified in the Test Environment. Please select only the unsynced locations to sync with Test Environment'} as ConfirmWithOKProps,
      //         'confirmPopup'
      //       );
      //     } else {
      //         const confirmService = new ConfirmService();
      //         confirmService.showConfirmDialog(
      //           () => locationMaintenanceDataStore.pushSelectedRowsToVmacs(env),
      //           'Active',
      //           `Are you sure you want to push the selected data to the ${env} Environment?`
      //       );
      //     }      
      //   }
      // } else {
      //   const secondaryText = requestBody.length > 0 ? 'Please select only the active and unsynced locations to perform the operation' : 'No records are selected. Please select atleast one active and unsynced location to perform the operation.';
      //   const confirmService = new ConfirmService();
      //   confirmService.showConfirmWithCustomComponent(
      //     () => {},
      //     ConfirmWithOK,
      //     {secondaryText: secondaryText} as ConfirmWithOKProps,
      //     'confirmPopup'
      //   );
      // }
      // console.log(' succesfully');
      
    }

    render(): React.ReactNode {
      const { locationMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderLocationMaintenanceScreen()}
        </Container>
      );
    }

    renderLocationMaintenanceScreen() : React.ReactNode {
      const { locationMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='locationMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Location Maintenance</h2>
                </div>
                
                {/* <h4 className='search_criteria_title'> 
                  <label>Search Criteria</label> 
                </h4> */}
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                    <label className="standard_label_style">Base System</label>
                      <ReactSelect
                        className='select'
                        values={locationMaintenanceDataStore.selectedBaseSystem}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...locationMaintenanceDataStore.baseSystemDropdownOption]}
                        onChange={(e: any) => {locationMaintenanceDataStore.selectedBaseSystem = e}}
                        isMulti={false}
                        isSearchable={true}
                      />
                      <label className="standard_label_style">Location Name</label>
                      <input 
                        type="text"
                        value={locationMaintenanceDataStore.selectedlocnName}
                        className="search_text_field"
                        // disabled={true}
                        style={{width: '20%', marginRight: '10px'}}
                        onChange = {(e) => this.onChangeLocationName(e)}
                      />

                      <label className="standard_label_style">Status</label>
                      <ReactSelect
                        className='select'
                        values={locationMaintenanceDataStore.selectedStatus}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...locationMaintenanceDataStore.statusDropdownOptions]}
                        onChange={(e: any) => {locationMaintenanceDataStore.selectedStatus = e}}
                        isMulti={false}
                        isSearchable={true}
                      />
                  </div>
                  <div className='searchButtonDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                        Search
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                        Clear
                    </CustomButton>
                    <div className='otherHeaderButtonsDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled = {locationMaintenanceDataStore.isSaveDisabled()}>
                          Add Location
                      </CustomButton>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.handleStateChangeFromDelToActive} disabled= {locationMaintenanceDataStore.isActivateButtonDisabled || locationMaintenanceDataStore.isActivateDeactivateDisabled()}>
                        Activate
                      </CustomButton>
                      {/* { dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSync) && 
                        <>
                          <CustomButton type={CustomButtonType.Submit} onClick={() => this.handleBulkSync('Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSync)}>
                              Push to VMACS Test
                          </CustomButton>
                          <CustomButton type={CustomButtonType.Submit} onClick={() => this.handleBulkSync('Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSync)}>
                              Push to VMACS Prod
                          </CustomButton>
                        </>} */}
                    </div>
                  </div>
                </div>
              </div>
              {this.props.locationMaintenanceDataStore.showLoader == true &&
                <div className = 'alert-overlay'> 
                  <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                </div> 
              }
              <AgGridComponent gridConfig={this.getGridConfig('Location Maintenance')} />
              {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
            </Col>
          </Row>
      );
    }


    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        locationMaintenanceDataStore.setIsActivateButtonDisabled(locationMaintenanceDataStore.isSaveDisabled())
      } else {
        locationMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
    }

    private getGridConfig(gridId): GridOptions {
      const {
          locationMaintenanceDataStore: { LocationMaintenanceData, agGridService }
      } = this.props;
      const { locationMaintenanceDataStore } = this.props;
      const columnDefs = locationMaintenanceDataStore.getColDef();
      const rowData = LocationMaintenanceData;
      const onSelectionChanged = this.onSelectionChanged;
      const locationMaintenanceGridParams: any = {
        rowData,
        columnDefs,
        onSelectionChanged
      };
      return agGridService.getGridConfig(locationMaintenanceGridParams, gridId);
    }
}
