import React, { Component, useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { LocationMaintenanceDataStore } from '../stores/location-maintenance-data-store';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { LocationMaintenanceResponse } from '../model/model';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/location.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { locationMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ValueFormatterParams, ColDef, ICellEditorParams, GridOptions,CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import Select from 'react-select';
import { dateUtils } from 'shared/services/date-utils';;
import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays';
import '../styles/locationDetails.scss';

interface CalendarContainerProps {
    children : any
}

const onChangeDate = (params, value, key) => {  
    const serviceDate = moment(value).format('MM/DD/yyyy');
    console.log(moment(value).format('MM/DD/yyyy'));
    locationMaintenanceDataStore.setValueInChangeMap(params.data.pipelineID, key, params.data.serviceDate, serviceDate);
    params.data.serviceDate =  moment(value).format('MM/DD/yyyy');
    locationMaintenanceDataStore.updateRowforAddOrUpdateGrid(params.data);
    if(locationMaintenanceDataStore.addOrEditLocationGridData.filter((item) => item.isSelected === true).length > 1) {
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
          () =>  locationMaintenanceDataStore.globalServiceDateSelection(params.data.serviceDate),
          'ServiceDate',
          `Do you want to apply selected service date i.e. ${params.data.serviceDate} to all the linked pipelines?`
      );
    }
    
 } ;
const CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };

const disableEditing = () => {
  if(locationMaintenanceDataStore && locationMaintenanceDataStore.addOrEditLocationGridData) {
    const initialData = locationMaintenanceDataStore.addOrEditLocationGridData.filter(a => (!locationMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString())));
    return initialData.filter((item) => item.isSelected === true).length > 1
  }
}

export const AddLocationDetailsColDef: ColDef[] = [
    {   
        headerName: 'Select',
        field: 'select', 
        colId:'Select',
        headerComponent: (params) => {
          return (
              <input
                  type="checkbox"
                  onChange={(e) => locationMaintenanceDataStore.headerCheckBoxSelection(e)}
                  checked={!locationMaintenanceDataStore.checkIfHeaderCheckboxShouldbeChecked(params)}
                  disabled={disableEditing()}
              />
          );
      },
        cellRenderer: (params) => {
            return (
                <input
                    type="checkbox"
                    onChange={(e) => locationMaintenanceDataStore.linkedPipelineCheckboxHandler(params, e)}
                    checked={params.data.isSelected}
                    disabled={disableEditing()}
                />
            );
        }, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        maxWidth: 40, 
        flex: 1,
        suppressSizeToFit: true,
        cellStyle: () => {return {padding: '10px'}}
    },
    { 
        headerName: 'Base System', 
        field: 'plName',
        colId: 'plName',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['plName'] === null) {
                return '';
            } else {
                return params.data['plName'];
            }
        },
        suppressSizeToFit: true,
        flex: 1
    },
    { 
        headerName: 'Status', 
        field: 'serviceStatus', 
        colId: 'serviceStatus',
        filter: true, 
        sortable: true, 
        editable: () => {return !disableEditing()},
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
            values: ['Active', 'Inactive']
        },
        cellClass: (params) => {return disableEditing() ? '' : 'cell-editable'},         
        valueGetter: params => {
            if (params.data['serviceStatus'] === null) {
                return '';
            } else {
                return params.data['serviceStatus'] === 'A' || params.data['serviceStatus'] === 'Active'  ? 'Active' : 'Inactive';
            }
        },
        suppressSizeToFit: true,
        flex: 1
    },
    { 
        headerName: 'Service Date',
        field: 'serviceDate', 
        colId: 'serviceDate',
        filter: true, 
        sortable: true,
        editable: () => {return !disableEditing()},
        suppressSizeToFit: true,
        cellRenderer: (params) => { 
            const serviceDate = params.data['serviceDate'] ?  moment(moment(params.data['serviceDate'])
            .format('MM/DD/yyyy')).toDate() : null
            return (params.data && 
                (<DatePicker 
                    popperClassName='ag-custom-component-popup' 
                    selected={serviceDate}
                    dateFormat="MM/dd/yyyy" 
                    onChange={(value)=>{onChangeDate(params, value, 'serviceDate')}} 
                    popperContainer={CalendarContainer} 
                    popperPlacement='top-start' 
                    className={disableEditing() ? 'serviceDateInputStyle' : 'serviceDateInputStyle-editable'}
                    disabled={disableEditing()}/>
                )
            ) || params.value 
        },
        valueGetter: params => {
            if (params.data['serviceDate'] === null) {
                return '';
            } else {
                return moment(params.data['serviceDate']).format(AppConstant.DATE_FORMAT);
            }
        }, 
        cellClass: (params) => {return disableEditing() ? '' : 'cell-editable'},     
        flex: 1
    },
    { 
        headerName: 'Allow Trade', 
        field: 'allowTrades', 
        colId: 'allowTrades',
        filter: true, 
        sortable: true,
        editable: () => {return !disableEditing()},
        headerClass: 'text-center',
        flex: 1,
        minWidth: 100,
        tooltipField: 'allowTrades', 
        cellRenderer: (params) => {
            return (
                <input
                    className='' 
                    type='checkbox' 
                    checked={params.data['allowTrades']==='Y'}
                    onChange={(value) => locationMaintenanceDataStore.handleAllowTradeCheckBoxChange(params, value)}
                    disabled={disableEditing()}
                />);
        }, 
        cellClass: (params) => {return disableEditing() ? '' : 'cell-editable'},     
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}}
    }
];

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface LocationDetailsContainerProps {
    locationMaintenanceDataStore: LocationMaintenanceDataStore;
}


@inject('locationMaintenanceDataStore')
@observer
export class LocationDetailsContainer extends Component<LocationDetailsContainerProps, any> {
    constructor(props: LocationDetailsContainerProps | Readonly<LocationDetailsContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Location Details')
    }
    
    componentDidMount() {
      const { locationMaintenanceDataStore } = this.props;
      if(!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.LocationMaintenance)){
        history.push('/LocationMaintenance');
      }else if(!locationMaintenanceDataStore.selectedLocationId && locationMaintenanceDataStore.onClickLocationNameOnGrid){
        const state=history?.location?.state as {from?: string; url?: string; workflowId?: string; } || '';
        if(state?.from && state?.from !== '/LocationMaintenance') {
          locationMaintenanceDataStore.init();
        } else {
          history.push('/LocationMaintenance', { from: '/LocationDetails' });
        }
      } else if(!locationMaintenanceDataStore.onClickAddNewLocationButton && !locationMaintenanceDataStore.onClickLocationNameOnGrid){
        locationMaintenanceDataStore.init();
      }
      history.push('/LocationDetails');
    }

    componentDidUpdate() {
      const {locationMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Location Details_filter');
      this.props.locationMaintenanceDataStore.agGridServiceForAddOrEditLocation.setAllFilters(filters);
      const columnVisible = localStorageService.get('Location Details_' + accountStore.userName);
      this.props.locationMaintenanceDataStore.agGridServiceForAddOrEditLocation.setColumnVisibility(columnVisible);
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    componentWillUnmount() {
      console.log("unmount" + dataStore.showUnsavedWarningAlert);
    }


    onCancelClick = async() => {
      const { locationMaintenanceDataStore } = this.props;
      locationMaintenanceDataStore.onCloseLocationDetails();
    }

    onDeleteClick = async () => {
      const { locationMaintenanceDataStore } = this.props;
      const updatedRowIDs: any = locationMaintenanceDataStore.addOrEditLocationGridData.filter(a => locationMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString()));
      if(updatedRowIDs.length > 0) {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => locationMaintenanceDataStore.deleteLocation(),
          'Inactive',
          'All the unsaved changes will be lost. Are you sure you want to deactivate the selected locations?'
      );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
        () => locationMaintenanceDataStore.deleteLocation(),
        'Inactive',
        'Are you sure you want to deactivate the selected location?'
      );
      }
    }


    onChangeLocationName = (e) => {
      this.props.locationMaintenanceDataStore.onChangeLocationName(e.target.value)
    }
    
    onSaveClick = async (saveAndAddNew) => {
      await this.props.locationMaintenanceDataStore.saveLocation(saveAndAddNew);
    };

    onAddNewClick = () => {
      const { locationMaintenanceDataStore } = this.props;
      const updatedRowIDs: any = locationMaintenanceDataStore.addOrEditLocationGridData.filter(a => locationMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString()));
      let message = ''
      if((locationMaintenanceDataStore.selectedLocationId > 0 && (locationMaintenanceDataStore.selectedLocationDetails.syncStatus === SyncStatus[0] || locationMaintenanceDataStore.selectedLocationDetails.syncStatus === SyncStatus[1]))) {
         message = locationMaintenanceDataStore.selectedLocationDetails.syncStatus === SyncStatus[0] ? 'Record is not yet synced with Test and Production Environment. ' : 'Record is not yet synced with Production Environment. '
      }
      if (updatedRowIDs.length > 0 || locationMaintenanceDataStore.updatedFieldonLocationDetailsScreen) {
          message = message + 'All the unsaved changes will be lost. ';
      }
      if (message !== '') {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () =>  this.props.locationMaintenanceDataStore.resetAddLocationMaintenanceGridData(),
          'Add New Location',
          message + 'Are you sure you want to add new location?'
      );
      } else {
        this.props.locationMaintenanceDataStore.resetAddLocationMaintenanceGridData()
      }
                               
    }

    handleEditLocationNameDetails = (e) => {
      this.props.locationMaintenanceDataStore.onEnterLocationforAddOREdit(e.target.value);
    }

    render(): React.ReactNode {
      const { locationMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
            {this.renderAddLocationScreen()}
        </Container>
      );
    }

    renderAddLocationScreen() : React.ReactNode {
      const { locationMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='locationMaintenanceBox'>
                 <div className='screenHeader'>
                  <h2 className='screen_title'>Location Details</h2>
                </div>
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                    <label className="standard_label_style">Enter New Location Name</label>
                    <input 
                      type="text"
                      value={locationMaintenanceDataStore.addOrEditedLocnName}
                      className="input_text_field"
                      // disabled={true}
                      style={{width: '20%', marginRight: '10px'}}
                      onChange = {(e) => this.handleEditLocationNameDetails(e)}
                    />
                    <div className='searchButtonDiv'>
                    <div className='otherHeaderButtonsDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => this.onSaveClick(false)}>
                          Save
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled= {!locationMaintenanceDataStore.selectedLocationId}>
                          Add New Location
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.onDeleteClick} disabled= {locationMaintenanceDataStore.isDeleteDisabled() || locationMaintenanceDataStore.isActivateDeactivateDisabled()}>
                          Deactivate
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.pushToVmacs('Test')}} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Test')}>
                          Push to Test
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.pushToVmacs('Prod')}} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Prod')}>
                          Push to Prod
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.onCancelClick}>
                          Close
                        </CustomButton>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div className='locationMaintenanceBox'>
                <div className='pipelineLinkerBox'>
                    <div className="rotatedText">
                      Linker
                    </div>
                    <Row style={{margin:'20px', width: '60%', height: '40%'}}>
                      <AgGridComponent gridConfig={this.getAddLocationDetailsGridConfig('Location Details')}/>
                    </Row>
                    
                </div>
              </div>
              {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
            </Col>
          </Row>
      );
    }

    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        locationMaintenanceDataStore.setIsActivateButtonDisabled(locationMaintenanceDataStore.isSaveDisabled())
      } else {
        locationMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
    }


    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          locationMaintenanceDataStore: { cellValueChangeMap }
      } = this.props;
      const isNotEmpty =
          row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
      if (!isNotEmpty) {
          this.props.locationMaintenanceDataStore.setValueInChangeMap(row, col, value, value);
      }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
        locationMaintenanceDataStore: { cellValueChangeMap, updateRowforAddOrUpdateGrid, getColDef}
      } = this.props;
      cellValueChangeMap[row][col]['currentValue'] = value;
      updateRowforAddOrUpdateGrid(event.data);
      this.props.locationMaintenanceDataStore.agGridServiceForAddOrEditLocation.getNodes()?.refreshCells();
    };
   
    private getAddLocationDetailsGridConfig(gridId): GridOptions {
      const {
          locationMaintenanceDataStore: { AddOrEditLocationGridData, agGridServiceForAddOrEditLocation }
      } = this.props;
      const { locationMaintenanceDataStore } = this.props;
      const columnDefs = locationMaintenanceDataStore.getAddLocationDetailsColDef();
      const rowData = AddOrEditLocationGridData;
      const onCellEditingStarted = this.onCellEditingStarted;
      const onCellEditingStopped = this.onCellEditingStopped;
      const locationMaintenanceGridParams: any = {
        rowData,
        columnDefs,
        onCellEditingStarted,
        onCellEditingStopped
      };
      return agGridServiceForAddOrEditLocation.getGridConfig(locationMaintenanceGridParams, gridId);
    }

    private pushButtonDisabled(env) {
      const { locationMaintenanceDataStore } = this.props;
      if(env === 'Test') {
        return  locationMaintenanceDataStore.selectedLocationId === 0 || (locationMaintenanceDataStore.selectedLocationDetails.syncStatus && (locationMaintenanceDataStore.selectedLocationDetails.syncStatus === SyncStatus[1] || locationMaintenanceDataStore.selectedLocationDetails.syncStatus === SyncStatus[2])); 
      }
        return  locationMaintenanceDataStore.selectedLocationId === 0 || ( locationMaintenanceDataStore.selectedLocationDetails.syncStatus && locationMaintenanceDataStore.selectedLocationDetails.syncStatus === SyncStatus[2]);
    }

    private async pushToVmacs(env) {
      const { locationMaintenanceDataStore } = this.props;
      const requestBody: any = await locationMaintenanceDataStore.addOrEditLocationGridData.filter((item) => item.isSelected === true);
      const updatedRowIDs = locationMaintenanceDataStore.addOrEditLocationGridData.filter(a => locationMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString()));
      if((locationMaintenanceDataStore.addOrEditedLocnName !== ''  &&( (requestBody.length > 0 && !requestBody.some((item) => item.syncStatus === SyncStatus[2])) || (requestBody.length <= 0 && locationMaintenanceDataStore.selectedLocationDetails.syncStatus && locationMaintenanceDataStore.selectedLocationDetails.syncStatus !== SyncStatus[2])))) {
        if(updatedRowIDs.length > 0 || locationMaintenanceDataStore.updatedFieldonLocationDetailsScreen) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => locationMaintenanceDataStore.pushSelectedRowsToVmacs(env),
            'Active',
            `All the unsaved changes will be lost. Are you sure you want to push the selected data to the ${env} Environment?`
        );
        } else {
          if(((requestBody.length <= 0 && locationMaintenanceDataStore.selectedLocationDetails.syncStatus && locationMaintenanceDataStore.selectedLocationDetails.syncStatus=== SyncStatus[0]) || requestBody.some((item) => item.syncStatus === SyncStatus[0])) && env === 'Prod' && locationMaintenanceDataStore.addOrEditedLocnName !== '' ) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmWithCustomComponent(
              () => {},
              ConfirmWithOK,
              {secondaryText: 'Some of the selected records/location has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
              'confirmPopup'
            );
          } else if(((requestBody.length <= 0 && locationMaintenanceDataStore.selectedLocationDetails.syncStatus && locationMaintenanceDataStore.selectedLocationDetails.syncStatus=== SyncStatus[1]) || requestBody.some((item) => item.syncStatus === SyncStatus[1])) && env === 'Test' && locationMaintenanceDataStore.addOrEditedLocnName !== '') {
            const confirmService = new ConfirmService();
            confirmService.showConfirmWithCustomComponent(
              () => {},
              ConfirmWithOK,
              {secondaryText: 'Some of the selected records/location have already been verified in the Test Environment. Please select only the unsynced locations to sync with Test Environment'} as ConfirmWithOKProps,
              'confirmPopup'
            );
          } else {
              const confirmService = new ConfirmService();
              confirmService.showConfirmDialog(
                () => locationMaintenanceDataStore.pushSelectedRowsToVmacs(env),
                'Active',
                `Are you sure you want to push the selected data to the ${env} Environment?`
            );
          }      
        }
      } else {
        const secondaryText = 'Please select only the active and unsynced locations to perform the operation';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }

}

