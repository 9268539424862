export enum TariffLogEntry {
    TariffFiling= 'Tariff Filing',
    TariffFilingIndex= 'Tariff Filing Index',
    Maintenance= 'Maintenance',
    Request='Request'
}

export enum TariffLogStatus {
    PendingTestDetermination= 'Pending Test Determination',
    PendingTest= 'Pending Test',
    PendingProd= 'Pending Prod',
    PendingApproval= 'Pending Approval',
    Complete= 'Complete',
    PendingTariffFiling = 'Pending Tariff Filing',
    PendingTestTariffFiling = 'Pending Test/Tariff Filing'
}

export enum LogEntryAndScreenMapping {
    TariffFiling= '/CreateFilingLogEntry',  
    TariffFilingIndex= '/CreateIndexLogEntry',
    Maintenance= '/CreateMaintenanceLogEntry',
    Request='/CreateRequestLogEntry'
}