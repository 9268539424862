import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { TariffDashboardDataStore } from '../stores/tariff-dashboard-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/tariff-dashboard.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { tariffDashboardDataStore } from '../stores';
import { tariffDashboardColDef } from './tariff-dashboard-col-def';
import { inProgressLogsColDef } from './inprogress-logs-col-def';
import DatePicker from 'react-datepicker';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface TariffDashboardContainerProps {
    tariffDashboardDataStore: TariffDashboardDataStore;
}

interface TariffDashboardContainerState {
    search: string;
    addRowClick: boolean;
    lastAddedAgencyID: any;
}

@inject('tariffDashboardDataStore')
@observer
export class TariffDashboardContainer extends Component<TariffDashboardContainerProps, TariffDashboardContainerState> {
    constructor(props: TariffDashboardContainerProps | Readonly<TariffDashboardContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            lastAddedAgencyID: 0
        };
        dataStore.setModuleName('Tariff Dashboard')
    }
    
    componentDidMount() {
      const {tariffDashboardDataStore} = this.props;
      tariffDashboardDataStore.init();
      tariffDashboardDataStore.agGridService.refreshGrid();
      tariffDashboardDataStore.agGridServiceForOpenRequest.refreshGrid();
    }

    componentDidUpdate() {
      const filters = localStorageService.get('Tariff Dashboard_filter');
      this.props.tariffDashboardDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Tariff Dashboard_' + accountStore.userName);
      this.props.tariffDashboardDataStore.agGridService.setColumnVisibility(columnVisible);

      const filtersForOpenRequestGrid = localStorageService.get('Tariff Dashboard Inprogress Logs_filter');
      this.props.tariffDashboardDataStore.agGridServiceForOpenRequest.setAllFilters(filtersForOpenRequestGrid);
      const columnStateForOpenRequestGrid = localStorageService.get('Tariff Dashboard Inprogress Logs_' + accountStore.userName);
      this.props.tariffDashboardDataStore.agGridServiceForOpenRequest.setColumnVisibility(columnStateForOpenRequestGrid);
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    componentWillUnmount() {
        this.props.tariffDashboardDataStore.reset();
    }
    
    onBtClearSelection = () => {
      const { tariffDashboardDataStore } = this.props;
      tariffDashboardDataStore.clearSearch();
    }

    onSearchClick = () => {
      const { tariffDashboardDataStore } = this.props;
      if(tariffDashboardDataStore.endDateError === '') {
        tariffDashboardDataStore.onSearchClick();
      }
    }

    render(): React.ReactNode {
      const { tariffDashboardDataStore } = this.props;
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className='tariffDashboardBox'>
             
                <div className='screenHeader'>
                  <h2 className='screen_title'>Tariff Dashboard</h2>
                </div>
                <div className='selectContainer'>
                <div className='subHeader'>
                  <h2 className='screen_title'>CTS Logs- In Progress</h2>
                </div>
                <div className='selectSecondContainer'>
                  <AgGridComponent gridConfig={this.getGridConfigForOpenRequest('Tariff Dashboard Inprogress Logs')} />
                </div>
                <div className='subHeader'>
                  <h2 className='screen_title'>Tariff Search</h2>
                </div>
                  <div className='selectSecondContainer'>
                   
                    <Row>
                          <Col>
                          <label className="standard_label_style">Agency</label>
                            <ReactSelect 
                              className='select'
                              values={{label: tariffDashboardDataStore.selectedAgency, value: tariffDashboardDataStore.selectedAgency}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...tariffDashboardDataStore.agencySelectOptionData]}
                              onChange={(e: any) => { e.value ?  tariffDashboardDataStore.selectedAgency = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                          </Col>
                          <Col>
                          <label className="standard_label_style">Owner/Carrier</label>
                          <ReactSelect 
                              className='select'
                              values={{label: tariffDashboardDataStore.selectedOwnerOrCarrier, value: tariffDashboardDataStore.selectedOwnerOrCarrier}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...tariffDashboardDataStore.ownerCarrierSelectOptionData]}
                              onChange={(e: any) => { e.value ?  tariffDashboardDataStore.selectedOwnerOrCarrier = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                          </Col>
                          <Col>
                          <label className="standard_label_style">Status</label>
                          <ReactSelect 
                              className='select'
                              values={{label: tariffDashboardDataStore.selectedStatus, value: tariffDashboardDataStore.selectedStatus}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...tariffDashboardDataStore.statusSelectOptionData]}
                              onChange={(e: any) => { e.value ?  tariffDashboardDataStore.selectedStatus = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                      </Col>
                  </Row>
                  <Row>
                          <Col>
                          <label className="standard_label_style">Tariff No.</label>
                          <input 
                            type="text"
                            value={tariffDashboardDataStore.selectedTariffNumber}
                            className="search_text_field"
                            // disabled={true}
                            // style={{width: '20%'}}
                            onChange = {(e) => tariffDashboardDataStore.onChangeTariffNumber(e.target.value)}
                          />
                          </Col>
                          <Col>
                          <label className="standard_label_style">Tariff Log Type</label>
                          <ReactSelect 
                              className='select'
                              values={{label: tariffDashboardDataStore.selectedTariffClass, value: tariffDashboardDataStore.selectedTariffClass}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...tariffDashboardDataStore.tariffClasSelectOptionData]}
                              onChange={(e: any) => { e.value ?  tariffDashboardDataStore.selectedTariffClass = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                          </Col>
                          <Col>
                          
                            <label className="standard_label_style">CTS Effective Date</label>
                            <DatePicker
                                className="search_text_field toDate"
                                selected={tariffDashboardDataStore.selectedCTSEffectiveDate}
                                onChange={(e) => {
                                    tariffDashboardDataStore.onChangeCTSEffectiveDate(e);
                                }}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                          
                          </Col>
                  </Row>
                  
                    
                  
                  <Row>
                          <Col>
                          <label className="standard_label_style">Pipelines</label>
                          <ReactSelect 
                              className='select'
                              values={{label: tariffDashboardDataStore.selectedPipelineSystem, value: tariffDashboardDataStore.selectedPipelineSystem}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...tariffDashboardDataStore.pipelineSelectOptionData]}
                              onChange={(e: any) => { e.value ?  tariffDashboardDataStore.selectedPipelineSystem = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                          </Col>
                          <Col>
                          <label className="standard_label_style">Log Status</label>
                          <ReactSelect 
                              className='select'
                              values={{label: tariffDashboardDataStore.selectedTariffStatus, value: tariffDashboardDataStore.selectedTariffStatus}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...tariffDashboardDataStore.tariffStatusSelectOptionData]}
                              onChange={(e: any) => { e.value ?  tariffDashboardDataStore.selectedTariffStatus = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                          </Col>
                          <Col>
                                <label className="standard_label_style">Tariff Effective Date</label>
                                <DatePicker
                                    className='search_text_field toDate'
                                    selected={tariffDashboardDataStore.selectedTariffEffectiveDate}
                                    onChange={(e) => {
                                        tariffDashboardDataStore.onChangeTariffEffectiveDate(e);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                          </Col>
                  </Row>
                  
                    <Row>
                          <Col>
                          <label className="standard_label_style">Base Tariff</label>
                          <ReactSelect 
                              className='select'
                              values={{label: tariffDashboardDataStore.selectedBaseTariff, value: tariffDashboardDataStore.selectedBaseTariff}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...tariffDashboardDataStore.baseTariffSelectOptionData]}
                              onChange={(e: any) => { e.value ?  tariffDashboardDataStore.selectedBaseTariff = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                          </Col>
                          <Col>
                          <label className="standard_label_style">Log ID</label>
                          <input 
                            type="text"
                            value={tariffDashboardDataStore.selectedLogID}
                            className="search_text_field"
                            // disabled={true}
                            // style={{width: '20%'}}
                            onChange = {(e) => tariffDashboardDataStore.selectedLogID = e.target.value}
                          />
                          </Col>
                          <Col>
                          <label className="standard_label_style">Entered By</label>
                          <input 
                            type="text"
                            value={tariffDashboardDataStore.enteredByUser}
                            className="search_text_field"
                            // disabled={true}
                            // style={{width: '20%'}}
                            onChange = {(e) => tariffDashboardDataStore.enteredByUser = e.target.value}
                          />
                          </Col>
                          </Row>
                    <div className='searchButtonDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                        Search
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                        Clear
                    </CustomButton>
                    </div>
                    {this.props.tariffDashboardDataStore.showLoader == true &&
                      <div className = 'alert-overlay'> 
                        <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                      </div> 
                    }
                    <AgGridComponent gridConfig={this.getGridConfig('Tariff Dashboard')} />
                    {/* <div className='otherHeaderButtonsDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={() => {history.push('/BaseTariff')}} disabled= {tariffDashboardDataStore.isSaveDisabled()}>
                        Create Base Tariff
                      </CustomButton>
                      <CustomButton type={CustomButtonType.Submit} onClick={() => {history.push('/TariffLog')}} disabled= {tariffDashboardDataStore.isSaveDisabled()}>
                        Create Tariff Log
                      </CustomButton>
                      <CustomButton type={CustomButtonType.Submit} onClick={() => {history.push('/TariffOptions')}} disabled= {tariffDashboardDataStore.isSaveDisabled()}>
                        Create Tariff Option
                      </CustomButton>
                    </div> */}
                    <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/>

                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          tariffDashboardDataStore: { TariffDashboardData, agGridService }
      } = this.props;
      const { tariffDashboardDataStore } = this.props;
      const columnDefs =tariffDashboardColDef;
      const rowData = TariffDashboardData;
      const tariffDashboardGridParams: any = {
        rowData,
        columnDefs,
      };
      return agGridService.getGridConfig(tariffDashboardGridParams, gridId);
    }

    private getGridConfigForOpenRequest(gridId): GridOptions {
      const {
          tariffDashboardDataStore: { inProgressLogData, agGridServiceForOpenRequest }
      } = this.props;
      const { tariffDashboardDataStore } = this.props;
      const columnDefs =inProgressLogsColDef;
      const rowData = inProgressLogData;
      const tariffDashboardGridParams: any = {
        rowData,
        columnDefs,
        context : agGridServiceForOpenRequest     
      };
      return agGridServiceForOpenRequest.getGridConfig(tariffDashboardGridParams, gridId);
    }
}
