export enum TariffManagementDataComponentModulesName {
    BaseTariff = 'Base Tariff',
    TariffLog = 'Tariff Log',
    VMACSSyncTest = 'VMACS Sync - Push to Test',
    VMACSSyncProd = 'VMACS Sync - Push to Prod',
    ActivateAndDeactivate = 'Activate/Deactivate', 
    PMCTariff = 'PMC Tariff',
    SystemFeeMaintenance = 'System Fee',
    IndexRateMaintenance = 'Index Rate Maintenance',
    PMCGridView = 'PMC Tariff Update',
    PMCDashboard = 'PMC Dashboard',
    TariffDashboard = 'Tariff Dashboard',
    PMCTariffSelection = 'PMC Tariff Selection',
    PMCReview = 'PMC Review',
    PMCNotesAndAttachments = 'PMC Notes & Attachments'
}

export const TariffManagementDataTypeOptions = [
    'BaseTariff',
    'TariffLog',
    'PMCTariff',
    'SystemFeeMaintenance',
    'IndexRateMaintenance',
    'PMCGridView',
    'PMCDashboard',
    'TariffDashboard'
];