import React, { Fragment } from 'react';
// import PieRechartComponent from './PieRechartComponent';
// import logo from './Plainslogo.png';
import { observer } from 'mobx-react';
import { ReactLoader } from './shared/components/loader/loader';
import { Redirect, Route, Switch } from 'react-router-dom';
import { componentMapper } from './features/master-data-management/components/component-mapper';
import { componentMapper as tariffComponentMapper} from './features/tariff-management/components/component-mapper';
import { ToastContainer } from 'react-toastify';
import HeaderRouterComponent from './shared/components/header-component/component/header-component';
import { LoginContainer } from './features/account/components/login-container';
import { accountStore } from 'features/account/stores';
import ProtectedRoute from 'shared/components/protected-route';
import { MasterDataComponent } from 'features/master-data-management/components/master-data-container';
import { TariffManagementDataComponent } from 'features/tariff-management/components/tariff-management-container';
import { ProjectManagementDataComponent } from 'features/project-management/components/project-management-container';

const App = (): JSX.Element => {
    return (
        <Fragment>
            <ReactLoader visible={false} />
            <ToastContainer
                position='top-center'
                autoClose={10000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                closeButton={false}
                rtl={false}
                draggable
                pauseOnHover
            />
            {accountStore.isLoggedIn ? (
                <HeaderRouterComponent
                    onLogout={accountStore.logOut}
                    userName={accountStore.displayName}
                    roles={accountStore.userRoles}
                />
            ) : null}
            <Switch>
                <Route path="/login" component={LoginContainer} />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/"
                    component={MasterDataComponent}
                />
               
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/build"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/Agency"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/Miscellaneous"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/PMCTariff"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/SystemFeeMaintenance"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/PMCGridView"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/BaseTariff"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/TariffDashboard"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/CreateFilingLogEntry"
                    component={TariffManagementDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/CreateIndexLogEntry"
                    component={TariffManagementDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/CreateMaintenanceLogEntry"
                    component={TariffManagementDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/CreateRequestLogEntry"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/TariffLog"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/ProjectRequest"
                    component={TariffManagementDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/ProjectDashboard"
                    component={ProjectManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/project-request-step-1"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/project-request-step-2"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/project-request-infrastructure"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/project-request-clone-tariff-options"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/project-request-vmacs-test"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/project-request-confirm-test"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/project-request-tariff-review"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/PipelineMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact          
                    path="/AddPipeline"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact          
                    path="/EditPipeline"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/LocationMaintenance"
                    component={MasterDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact          
                    path="/LocationDetails"
                    component={MasterDataComponent}
                />  
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/TariffRateTypes"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/ConnectionPointMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/ConnectionPointDetails"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/FilingEntityMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/FilingEntityDetails"
                    component={MasterDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/FilingCarrierMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/FilingCarrierDetails"
                    component={MasterDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/GradeMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/GradeDetails"
                    component={MasterDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/LineSegmentMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/AddEditLineSegmentMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/ProfitCenterMaintenance"
                    component={MasterDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/ProfitCenterDetails"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/ShipperLossGainMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/CustomerMaintenance"
                    component={MasterDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/AddCustomerMaintenance"
                    component={MasterDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/RightAngleXRef"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/TariffOptions"
                    component={TariffManagementDataComponent}
                />
                 <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/TariffOptions-Index"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/IndexRateMaintenance"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/process-tariff-options"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/process-clone-tariff-options"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/process-tariff-review"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/process-tariff-approval"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/process-tariff-infrastructure"
                    component={TariffManagementDataComponent}
                />
                {accountStore.isLoggedIn ? <><Route path="/Dashboard" component={componentMapper.get('DefaultPageComponent')} />
                <Route path="/PMCDashboard" component={tariffComponentMapper.get('PMCDashboardContainer')} /></> : 
                <> <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/PMCDashboard"
                    component={TariffManagementDataComponent}
                />
                <ProtectedRoute
                    roles={accountStore.userRoles}
                    accountStore={accountStore}
                    exact
                    path="/Dashboard"
                    component={MasterDataComponent}
                /></>
                 }
            </Switch>
        </Fragment>
    );
};

export default observer(App);
