import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './tariff-options.scss';
import {
  CellEditingStoppedEvent,
  ColDef
} from 'ag-grid-community';
import { TariffOptionsIndexDataStore } from '../stores/tariff-options-data-store';
import { tariffOptionsDataStore } from '../stores';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import momenttimezone from 'moment-timezone';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { RoleBasedActionName } from 'shared/types/enum';
import { Prompt } from 'react-router-dom';
import { colDefDeduction } from './deduction-col-def';
import { LossAllowance } from './loss-allowance';
import { ConnectionPoint } from './connection-point';
import { Movement } from './movement';
import { WorkflowDetails } from './workflow-details'
import { ThreeDots } from 'react-loader-spinner';

/* eslint-disable */
interface TariffOptionsContainerProps {
  tariffOptionsDataStore: TariffOptionsIndexDataStore;
}

interface TariffOptionsContainerState {
  rowId: number;
  cloneNewTariff: boolean;
  // predictiveSelectText: string;
  // selectedShownArray: any[];
  // totalArray: any[];
  // hiddenSelect: boolean;
  // editableFormData: any;
  // baseTariffState: any;
}

interface interfaceCustomerName{
    customerName: string;
}

@inject('tariffOptionsDataStore')
@observer
export class TariffOptionsContainer extends Component<TariffOptionsContainerProps, TariffOptionsContainerState> {
  cloneBoxRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {
      rowId: 0,
      cloneNewTariff: false
      // predictiveSelectText: "",
      // selectedShownArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      // totalArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      // hiddenSelect: true,
      // editableFormData: {},
      // baseTariffState: {}
    };
    this.cloneBoxRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    dataStore.setModuleName('Tariff Options');
  }

  componentDidMount() {
    const { tariffOptionsDataStore } = this.props;
    tariffOptionsDataStore.init();
    window.scroll(0, 0);
   // this.getPlusDimensions();
  }

  handleClickOutside(event) {
    if (this.cloneBoxRef && !this?.cloneBoxRef?.current?.contains(event.target)) {
      tariffOptionsDataStore.cloneNewTariff = {};
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    tariffOptionsDataStore.unmount();
  }

  getGridConfigJointCarrier(gridId) {
    const {
      tariffOptionsDataStore: { agGridService, selectedBaseTariffDetails }
    } = this.props;
    const gridParams: any = {
      rowData: selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails?.map(x => { return { 'jointCarrier': x.jointCarrier, 'baseTariff': x.baseTariff, 'pipelineArea': x.pipeline }; }) || [],
      columnDefs: this.colDefBaseTariff,
      domLayout: 'autoHeight',
    };
    return agGridService.getGridConfig(gridParams, gridId);
  }
  colDefBaseTariff: ColDef[] = [
    {
      headerName: 'Joint Carrier',
      field: 'jointCarrier',
      filter: true,
      sortable: true,
      editable: false,
      minWidth: 130,
      cellEditor: 'agSelectCellEditor',
    },
    {
      headerName: 'Base Tariff',
      field: 'baseTariff',
      minWidth: 130,
      filter: true,
      sortable: true,
      editable: false,
    },
    {
      headerName: 'Pipeline Area',
      field: 'pipeline',
      minWidth: 665,
      filter: true,
      sortable: true,
      editable: false,
      flex: 1
    }
  ];

  colDefPipelineArea: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellRenderer: 'checkboxRenderer',
      width: 40,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Area',
      field: 'plName',
      editable: false,
      cellEditor: 'agSelectCellEditor',
      // valueGetter: (params) => {
      //   return this.props.tariffOptionsDataStore.pipelineAreasDropdown.find(x=>x.pipelineID===params.data?.tariffPLID)?.plSysCode
      // },
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStore.pipelineAreasDropdown.map(x=>x.plSysCode)
        };
      },
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Update Date',
      field: 'updateDate',
      editable: false,
      minWidth: 130,
      flex: 1,
      valueGetter: params => {
        if (params.data['updateDate'] === null) {
            return '';
        } else {
            return moment(params.data['updateDate']).format(AppConstant.DATE_FORMAT);
        }
      }},
    {
      headerName: 'Update User',
      field: 'updateUser',
      editable: false,
      minWidth: 130,
      flex: 1
    }
  ];

  getGridConfigPipelineArea(gridId) {
    const {
      tariffOptionsDataStore: { agGridServicePipeline }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.PipelineAreaData.length && tariffOptionsDataStore.PipelineAreaData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefPipelineArea,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context : agGridServicePipeline     
      };
    return agGridServicePipeline.getGridConfig(gridParams, gridId);
  }
  colDefOwner: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellRenderer: 'checkboxRenderer',
      width: 40,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'System',
      field: 'system',
      editable: false,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Owner',
      field: 'custName',
      minWidth: 130,
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStore.ownerDropdownForOwner[params.data.index]?.map(x=>x.customerName)
        };
      },
      flex: 1
    }
  ];
  getGridConfigOwner(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceOwner }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.OwnerData.length && tariffOptionsDataStore.OwnerData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefOwner,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context : agGridServiceOwner     
      };
    return agGridServiceOwner.getGridConfig(gridParams, gridId);
  }
  colDefRelatedTariff: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => {return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'?  true : false},
      cellRenderer: 'checkboxRenderer',
      width: 40,
      cellClass: (params) => {return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'?  'cell-editable' : ''},
      headerCheckboxSelection: (params) => {return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'?  true : false},
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Relationship',
      field: 'relationship',
      cellEditor: 'agSelectCellEditor',
      cellClass: (params) => {return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'?  'cell-editable' : ''},
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStore.tariffRelationTypes.map(x=>x.valueText)
        };
      },
      editable: (params) => { 
        return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'
      }, 
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Agency',
      field: 'agencyName',
      cellClass: (params) => {return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'?  'cell-editable' : ''},
      minWidth: 130,
      flex: 1,
      editable: (params) => { 
        return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'
      }, 
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStore.agencyDropdown.map(x=>x.agencyname)
        };
      },
    },
    {
      headerName: 'Number',
      cellClass: (params) => {return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'?  'cell-editable' : ''},
      field: 'number',
      editable: (params) => { 
        return this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'
      }, 
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.number=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    },
    {
      headerName: 'Effective Date',
      field: 'effectiveDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['effectiveDate'] === null) {
            return '';
        } else {
            return moment(params.data['effectiveDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
      flex: 1
    },
    {
      headerName: 'Update Date',
      field: 'updateDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['updateDate'] === null) {
            return '';
        } else {
            return moment(params.data['updateDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
      flex: 1
    },
    {
      headerName: 'Update User',
      field: 'updateUser',
      editable: false,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Status',
      field: 'status',
      editable: false,
      minWidth: 130,
      flex: 1
    }
  ];

  colDefComments: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellRenderer: 'checkboxRenderer',
      width: 40,
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Comments',
      field: 'comments',
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      minWidth: 130,
      flex: 4
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['createDate'] === null) {
            return '';
        } else {
            return moment(params.data['createDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
    },
    {
      headerName: 'Created User',
      field: 'createUser',
      editable: false,
      minWidth: 130,
    }
  ];

  colDefCTSComments: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellRenderer: 'checkboxRenderer',
      width: 40,
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Comments',
      field: 'comments',
      // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      minWidth: 130,
      flex: 4
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['createDate'] === null) {
            return '';
        } else {
            return moment(params.data['createDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
    },
    {
      headerName: 'Created User',
      field: 'createUser',
      editable: false,
      minWidth: 130,
    }
  ];

  getGridConfigComments(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceComments }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.CommentsData.length && tariffOptionsDataStore.CommentsData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefComments,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context : agGridServiceComments     
      };
    return agGridServiceComments.getGridConfig(gridParams, gridId);
  }

  getGridConfigCTSComments(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceCommentsCTS }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.CTSCommentsData.length && tariffOptionsDataStore.CTSCommentsData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefCTSComments,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context: agGridServiceCommentsCTS
      };
    return agGridServiceCommentsCTS.getGridConfig(gridParams, gridId);
  }

  getGridConfigRelatedTariff(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceRelatedTariff }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.RelatedTariffData.length && tariffOptionsDataStore.RelatedTariffData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefRelatedTariff,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context : agGridServiceRelatedTariff     
      };
    return agGridServiceRelatedTariff.getGridConfig(gridParams, gridId);
  }

  getGridConfigDeduction(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceDeduction }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.DeductionData.length && tariffOptionsDataStore.DeductionData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:colDefDeduction,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context : agGridServiceDeduction     
      };
    return agGridServiceDeduction.getGridConfig(gridParams, gridId);
  }

  selectTab(x,index){
    if(x==='new'){
      if(tariffOptionsDataStore.formData.length===0){
        tariffOptionsDataStore.addNewTariffOption();
      }else{
      tariffOptionsDataStore.cloneNewTariff[index]= true;
      document.addEventListener("mousedown", this.handleClickOutside);
      } 
    }else{
      tariffOptionsDataStore.cloneNewTariff[index]= false;
      tariffOptionsDataStore.selectedTab=x;
      tariffOptionsDataStore.screenHeaderTabSelected.push(x);
    }
  }

  getPlusDimensions(){
    var x= document.getElementById('cloneNew')?.getBoundingClientRect();
    console.log(x);
    return x?.x || 0;
  }

  findTariffLogDetail(){
    return tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.find(x=>x.tariffNumber===tariffOptionsDataStore.formDataSelection?.tariffLog);
  } 
 
  addLossAllowance(index){
    this.props.tariffOptionsDataStore.addLossAllowance(index)
  }
 
  addConnectionPoint(index){
    this.props.tariffOptionsDataStore.addConnectionPoint(index)
  }

  addMovement(index){
    this.props.tariffOptionsDataStore.addMovement(index)
  }

  
  isValidDate(d) {
    return d instanceof Date;
  }
  
  cloneContent(index, type, tariffOptionID) {
    this.props.tariffOptionsDataStore.cloneTariffOption(tariffOptionID,type);
    tariffOptionsDataStore.cloneNewTariff = {};
  }

  removeOption(tariffOptionID){
    const confirmService = new ConfirmService();
    confirmService.showConfirmDialog(
      () => {this.props.tariffOptionsDataStore.deleteTariffOptions(tariffOptionID);
        tariffOptionsDataStore.cloneNewTariff = {}},
      'Delete Tariff Option',
      'Are you sure you want to delete the selected Tariff Option?'
    );
  }

  changePipelineWarning(value, pipelineArea, index){
    if(this.props.tariffOptionsDataStore.formData[index]['new']!==true){
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
        () => this.props.tariffOptionsDataStore.changeFormData(value, pipelineArea, index),
        'Delete All Related Data',
        'Modifying the Pipeline Area Would Erase All Related Data from All the Below Grids on Save?'
      );
    }else{
      this.props.tariffOptionsDataStore.changeFormData(value, pipelineArea, index);
    }
  }

  deleteDeduction = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceDeduction.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteDeduction(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  
  deleteRelatedTariff = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceRelatedTariff.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteRelatedTariff(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  deleteOwner = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceOwner.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteOwner(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  deletePipelineArea = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServicePipeline.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deletePipelineArea(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  
  deleteComments = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceComments.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteComments(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }
  render(): React.ReactNode {
    const { tariffOptionsDataStore } = this.props;
    return (
      <div className='TariffOptionsIndexBox'>
        <>
          {tariffOptionsDataStore.loader ? <div className="loader__overlay" /> : null}
          <div className="loader__icon">
              <ThreeDots height={80} width={80} color="green" ariaLabel="loading" visible = {tariffOptionsDataStore.loader}/>
          </div>
        </>
        {/* <Container fluid> */}
          <div className='boxes'>
            {/* <Prompt when={tariffOptionsDataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'} /> */}
            <div className='screenHeader'>
                <h2 className='screen_title'>Tariff Reference</h2>
            </div>
            <div className='selectContainer'>
              <div className='internalHeader' onClick={()=>tariffOptionsDataStore.toggleBaseTariffShow()}>
                <h6>Base Tariff </h6>
              </div>
              {tariffOptionsDataStore.baseTariffShow && (
                    <div style={{marginLeft: '10px'}}>
                      <Row>
                        <Col xs={4} style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Base Tariff</label>
                          <select className='search_text_field' disabled value={tariffOptionsDataStore.formDataSelection.baseTariff} onChange={(e)=>{tariffOptionsDataStore.changeFormDataSelection(e.target.value, 'baseTariff')}}>
                          <option value='' disabled selected hidden>Select Base Tariff</option>
                          {Array.isArray(tariffOptionsDataStore.baseTariffDropdown) && tariffOptionsDataStore.baseTariffDropdown.length && tariffOptionsDataStore.baseTariffDropdown.map(option=>
                          (<option key={option.baseTariff} value={option.baseTariff}>
                              {option.baseTariff}
                          </option>)
                          )||(
                              <option key='noFound' disabled value={'noFound'}>
                              No Base Tariff Found
                              </option>
                          )}
                      </select>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row> 
                            <Col style={{marginTop:'10px'}}>
                              <label className="standard_label_style">Jurisdiction</label>
                              <input
                                type="text"
                                className="search_text_field input"
                                readOnly={true}
                                value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.jurisdiction}
                              />
                            </Col>
                          </Row>
                          <Row>  
                            <Col style={{marginTop:'10px'}}>
                              <label className="standard_label_style">Tariff Agency</label>
                              <input
                                type="text"
                                className='search_text_field input'
                                readOnly={true}
                                value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.agencyName}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col style={{marginTop:'10px'}}>
                            <label className="standard_label_style">Tariff Type</label>
                            <input
                              type="text"
                              className="search_text_field input"
                              value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffType}
                              readOnly={true}
                            />
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{marginTop:'10px'}}>
                              <label className="standard_label_style">Rules & Regulations</label>
                              <input
                                type="text"
                                className="search_text_field input"
                                value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.rulesReg}
                                readOnly={true}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={5} style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Pipeline Area</label>
                          <textarea
                            value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.pipeline}
                            className="search_text_field pipelineAreas"
                            readOnly={true}
                          />
                        </Col>
                      </Row>
                      <Row style={{marginTop:'0px'}}>
                        <Col style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Filing Carrier</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.filingCarrier}
                            readOnly={true}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row style={{ 'marginTop': '20px' }}>
                        <AgGridComponent gridConfig={this.getGridConfigJointCarrier('Tariff Option Index JC')} />
                      </Row>
                    </div>)}
                  <div className='internalHeader' onClick={()=>tariffOptionsDataStore.toggleTariffLogShow()}>
                    <h6>Tariff Log </h6>
                  </div>
                  {tariffOptionsDataStore.tariffLogShow && (
                  <div style={{ marginLeft: '10px' }}>
                    <Row>
                      <Col style={{marginTop:'10px'}} xs={4}>
                        <label className="standard_label_style">Tariff #</label>
                        <select className='search_text_field' disabled value={tariffOptionsDataStore.formDataSelection.tariffLog} onChange={(e)=>{tariffOptionsDataStore.changeFormDataSelection(e.target.value, 'tariffLog')}}>
                            <option value='' disabled selected hidden>Select Tariff Logs</option>
                            {Array.isArray(tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs) && tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.length && tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.map(option=>
                            (<option key={option.tariffNumber} value={option.tariffNumber}>
                                {option.tariffNumber}
                            </option>
                            ))||(
                                <option key='noFound' disabled value={'noFound'}>
                                No Tariff Logs Found
                                </option>
                            )}
                        </select>
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Log Status</label>
                        <input
                          type="text"
                          className="search_text_field input"
                          value={this.findTariffLogDetail()?.logStatus||''}
                          readOnly={true} />
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Tariff Issue Date</label>
                        <ReactDatePicker onDateChange={()=>{}} selectedDate={this.findTariffLogDetail()?.tariffIssueDate && new Date(this.findTariffLogDetail()?.tariffIssueDate)||null} disabled={true} />
                      </Col>
                    </Row>
                    <Row>
                     <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Vmacs Tariff #</label>
                        <input
                          type="text"
                          className="search_text_field input"
                          value={this.findTariffLogDetail()?.vmacsTariffNumber||''}
                          readOnly={true} 
                        />
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Index</label>
                          <div className="status-block">
                            <input
                              type="radio"
                              value="true"
                              checked={this.findTariffLogDetail()?.index === true}
                              readOnly={true}
                              disabled={true}/>Yes
                            <input
                              type="radio"
                              value="false"
                              checked={this.findTariffLogDetail()?.index === false}
                              readOnly={true} 
                              disabled={true}/>No
                          </div>
                      </Col> 
                      <Col style={{marginTop:'10px'}}>
                      <div>
                        <Col><label className="standard_label_style">Tariff Effective Date</label>
                        <ReactDatePicker onDateChange={()=>{}} selectedDate={this.findTariffLogDetail()?.tariffEffectiveDate && new Date(this.findTariffLogDetail()?.tariffEffectiveDate)||null} disabled={true} />
                        </Col>
                      </div>
                      </Col>
                    </Row>
                    <Row>
                    <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Cancels</label>
                        <div style={{display:'inline-flex'}}>
                        <input
                          type="text"
                          className="mainVersionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(0, this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/)))||''}
                          readOnly={true}
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/))).split(' ')[0]||''}
                          readOnly={true} 
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/))).split(' ')[1]||''}
                          readOnly={true} 
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/))).split(' ')[2]||''}
                          readOnly={true} 
                        />
                      </div>
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Will it be tested</label>
                          <div className="status-block">
                            <input
                              type="radio"
                              value="true"
                              checked={this.findTariffLogDetail()?.testInd === true}
                              readOnly={true}
                              disabled={true}/>Yes
                            <input
                              type="radio"
                              value="false"
                              checked={this.findTariffLogDetail()?.testInd === false}
                              readOnly={true} 
                              disabled={true}/>No
                          </div>
                      </Col> 
                      <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">CTS Effective Date</label>
                        <ReactDatePicker onDateChange={()=>{}} selectedDate={this.findTariffLogDetail()?.ctsEffectiveDate && new Date(this.findTariffLogDetail()?.ctsEffectiveDate)||null} disabled={true} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={7} style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Description</label>
                        <textarea
                          className="search_text_field input descriptionTariffLogArea"
                          value={this.findTariffLogDetail()?.description||''}
                          readOnly={true} />
                      </Col>
                    </Row>
                  </div>)}
            </div>
            <div className='screenHeader'>
                <h2 className='screen_title'>Tariff Option</h2>
            </div>
            <div className='selectContainer'>
            <div className="screenHeaderTabs">
              {tariffOptionsDataStore.formData.length && tariffOptionsDataStore.formData.map((x,index)=>(
                <div className={tariffOptionsDataStore.formData[index]?.action==='Z'?'screenheaderTabRed':tariffOptionsDataStore.formData[index]?.isChanged? 'screenheaderTabYellow':tariffOptionsDataStore.selectedTab===index?'screenheaderTabActive':tariffOptionsDataStore.screenHeaderTabSelected.includes(x)?'screenHeaderTabSelected':'screenHeaderTab'} style = {{fontWeight : tariffOptionsDataStore.selectedTab===index ? 'bold' : 'normal'}}>
                  <div style={{position:'relative'}}>
                    <div style={{display: 'inline-block', 'cursor': 'pointer', padding: '5px', minHeight: '35px', minWidth: '40px'}} onClick={()=>{this.selectTab(index, index)}}>{tariffOptionsDataStore.formData[index].agency + ' ' + (x.value1 && x.value1 + ' '+ x.value2 + ' ' + x.value3 + ' ' + x.value4 || x.tariffNo)}</div>
                     {/* {tariffOptionsDataStore.formData[index]['new']!==true && <button style={{float: 'right', display: 'inline-block', marginTop: '2.5px', marginLeft:'10px', border: '1px solid gray', textAlign: 'center', minWidth: '33px'}} onClick={()=>{this.selectTab('new', index)}}>...</button>}
                     {tariffOptionsDataStore.cloneNewTariff[index]===true && (<div ref={this.cloneBoxRef} style={{'textAlign': 'right', 'padding': '5px', 'width': '145px', 'border': '1px solid black', 'position': 'absolute', 'backgroundColor':'white', top: '37px', right: this.getPlusDimensions() && this.getPlusDimensions()>800?'0px':'0px'}}>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.cloneContent(index, 'some', x.tariffOptionID)}>Clone</div>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.cloneContent(index, 'all', x.tariffOptionID)}>Clone All Content</div>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.removeOption(x.tariffOptionID)}>Remove Option</div>
                    </div>)||<></>} */}
                  </div>
                </div>))|| (<></>)}
              {!tariffOptionsDataStore.formData.length&&<div style={{'position':'relative'}}>
              <button className='screenHeaderTab' disabled={true} onClick={()=>{this.selectTab('new', 0)}} style={{marginLeft:'0px'}} id='cloneNew'>No Tariff Options Found</button>
              </div>}         
            </div>
            {tariffOptionsDataStore.formData.length && tariffOptionsDataStore.formData.map((x,index)=>(
            tariffOptionsDataStore.selectedTab===index && 
            (
            <div>
            <div className='internalHeader'>
              <h6>Ext System (VMACS) </h6>
            </div>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Agency</label>
                      <select className="select" disabled={true} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].agency} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'agency', index)}} style={{maxWidth: '200px', height: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].agency?.length>22?'70px':'35px', whiteSpace: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].agency?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].agency?.length>22?'2':'unset'}}>
                      {Array.isArray(tariffOptionsDataStore.agencyDropdown) && tariffOptionsDataStore.agencyDropdown.length && tariffOptionsDataStore.agencyDropdown.map(option=>
                        (<option key={option.agencycode} value={option.agencycode}>
                            {option.agencycode}
                        </option>)
                      )}
                      </select>
              </Col>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Status</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].statusValue} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'statusValue', index)}}>
                      {Array.isArray(tariffOptionsDataStore.statusDropdown) && tariffOptionsDataStore.statusDropdown.length && tariffOptionsDataStore.statusDropdown.map(option=>
                        (<option key={option.valueText} value={option.valueText}>
                            {option.valueText}
                        </option>)
                      )}
                      </select>
              </Col>
              <Col style={{'marginTop': '10px', 'marginRight': '10px', display: 'inline-flex' }} xs={4}>
                      <label className='standard_label_style'>Name</label>
                      <textarea
                      className='select'
                      style={{width:'100%'}}
                      disabled={true}
                      value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].name}
                      onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'name', index)}}
                      />
                      {/* <ReactDatePicker classNames='search_text_field' onDateChange={(e)=>{}} selectedDate={new Date()}/> */}
              </Col><Col></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}} xs={2}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className="standard_label_style">Tariff #</label>
                        <div style={{display:'inline-flex'}}>
                        <input
                          type="text"
                          className="mainVersionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value1 ||''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value?.toUpperCase(), 'value1', index)}}
                          readOnly={tariffOptionsDataStore.stepName!=='Process & Submit for Approval'}
                        />
                        <input
                          type="text"
                          className="versionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value2 ||''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value?.toUpperCase(), 'value2', index)}}
                          readOnly={tariffOptionsDataStore.stepName!=='Process & Submit for Approval'}
                        />
                        <input
                          type="text"
                          className="versionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value3 ||''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value?.toUpperCase(), 'value3', index)}}
                          readOnly={tariffOptionsDataStore.stepName!=='Process & Submit for Approval'}
                        />
                        <input
                          type="text"
                          className="versionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value4 ||''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value?.toUpperCase(), 'value4', index)}}
                          readOnly={tariffOptionsDataStore.stepName!=='Process & Submit for Approval'}
                        />
                        </div>
                        {/* <input type='text' disabled={true} className='search_text_field' value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].tariffNo} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'tariffNo', index)}}/> */}
              </Col>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Effective Date</label>
                      <ReactDatePicker disabled={true} classNames='search_text_field' onDateChange={(e)=>{tariffOptionsDataStore.changeFormData(e, 'effectiveDate', index)}} selectedDate={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].effectiveDate}/>
              </Col>
              <Col style={{'marginTop': '10px', display: 'inline-flex'}} xs={4}>
                      <label className='standard_label_style'>VMACS Tariff #</label>
                      <input
                          type="text"
                          className="select"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.agency + ' ' +tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].tariffNo||''}
                          readOnly={true}
                        />
              </Col><Col></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col xs={'auto'}>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Type</label>
                      <select className='select'  disabled={true} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].type} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'type', index)}} style={{maxWidth: '223px', height: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].type?.length>28?'70px':'35px', whiteSpace: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].type?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].type?.length>22?'2':'unset'}}>
                      {Array.isArray(tariffOptionsDataStore.tariffTypesDropdown) && tariffOptionsDataStore.tariffTypesDropdown.length && tariffOptionsDataStore.tariffTypesDropdown.map(option=>
                        (<option key={option.valueText} value={option.valueText}>
                            {option.valueText}
                        </option>)
                      )}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Pipeline Areas</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea} onChange={(e)=>this.changePipelineWarning(e.target.value, 'pipelineArea', index)} style={{maxWidth: '220px', padding: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].pipelineArea?.length>22?'9px':'5px', height: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].pipelineArea?.length>22?'70px':'35px', whiteSpace: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].pipelineArea?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].pipelineArea?.length>22?'2':'unset'}}>
                      <option value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea}>
                            {tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea}
                      </option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Invoice Basis</label>
                      <select className='select'disabled={true} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].invoiceBasis} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'invoiceBasis', index)}}>
                      {Array.isArray(tariffOptionsDataStore.invoiceBasisDropdown) && tariffOptionsDataStore.invoiceBasisDropdown.length && tariffOptionsDataStore.invoiceBasisDropdown.map(option=>
                        (<option key={option.valueText} value={option.valueText}>
                            {option.valueText}
                        </option>)
                      )}
                      </select>
                    </Col>
                  </Row>
              </Col>
              <Col xs={'auto'}>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Tariff Issue Date</label>
                      <ReactDatePicker disabled={this.props.tariffOptionsDataStore?.stepName !=='Process & Submit for Approval'} classNames='select' onDateChange={(e)=>{tariffOptionsDataStore.changeFormData(e, 'tariffIssueDate', index)}} selectedDate={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].tariffIssueDate}/>
                    </Col>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancels Tariff</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].cancelTariff} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'cancelTariff', index)}} >
                        <option key={'None'} value={'None'}>None</option>
                        <option key={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].cancelTariff} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].cancelTariff}>
                            {tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].cancelTariff}
                        </option>
                      </select>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancel Date</label>
                      <ReactDatePicker classNames='search_text_field' onDateChange={(e)=>{tariffOptionsDataStore.changeFormData(e, 'cancelDate', index)}} selectedDate={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].cancelDate}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancel Tariffs</label>
                      <select className='select' value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].cancelTariff} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'cancelTariff', index)}} >
                      <option key={'None'} value={'None'}>None</option>
                      {Array.isArray(tariffOptionsDataStore.cancelledTariffDropdown) && tariffOptionsDataStore.cancelledTariffDropdown.length && tariffOptionsDataStore.cancelledTariffDropdown.map(option=>
                        (<option key={option} value={option}>
                            {option}
                        </option>)
                      )}
                      </select>
                    </Col>
                  </Row> */}
              </Col>
              {/* <Col style={{'marginTop': '10px'}} xs={4}>
                      <div>
                        <label className='standard_label_style'>Comments</label>
                        <textarea className='search_text_field commentExtSytem' value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].comment} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'comment')}}/>
                      </div>
              </Col> */}
              <Col></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Company</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].companyValue} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'companyValue', index)}} style={{maxWidth: '223px', padding: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].companyValue?.length>22?'9px':'5px', height: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].companyValue?.length>22?'70px':'35px', whiteSpace: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].companyValue?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].companyValue?.length>22?'2':'unset'}}>
                      {tariffOptionsDataStore.ownerDropdownForOwner[index] && Array.isArray(tariffOptionsDataStore.ownerDropdownForOwner[index]) && tariffOptionsDataStore.ownerDropdownForOwner[index].length && tariffOptionsDataStore.ownerDropdownForOwner[index]?.map( option =>
                         (<option key={option?.customerName} value={option?.customerName}>
                            {option.customerName}
                        </option>)
                      )}
                      </select>
                    </Col>
              {/* <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Synced</label>
                      <input className='select' disabled onChange={(e)=>tariffOptionsDataStore.changeFormData(e.target.value, 'syncStatus', index)} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].syncStatus}/>
              </Col> */}
            </Row>
            {/* <Row>
              <div className="rightFlex" style={{'marginTop':'0px'}}>
                  <CustomButton type={CustomButtonType.Submit} onClick={()=>{tariffOptionsDataStore.saveTariffOption(index, x.tariffOptionID)}}>
                      Save
                  </CustomButton>
              </div>
            </Row> */}
            {/* <div className='internalHeader'>
              <h6>Tariff Additional Info</h6>
            </div>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}} xs={'5'}>
                      <label className='standard_label_style'>Purpose</label>
                      <textarea className='search_text_field commentExtSytem' />
              </Col>
              <Col style={{'marginTop': '10px'}}>
                      <label className='standard_label_style'>Comments</label>
                      <textarea className='search_text_field commentExtSytem' />
              </Col>
              <Col xs={'auto'}></Col>
            </Row> */}
            {/* <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Impact</label>
                      <select className='search_text_field' value={'New Tariff in Vmacs'}>
                          <option value={'New Tariff in Vmacs'}>New Tariff in Vmacs</option>
                      </select>
              </Col>
            </Row> */}
            <div className='internalHeader'>
              <h6>Comments</h6>
            </div>
             {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.addComments(index)}}>
                  Add Comment
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.saveComments(index, x.tariffOptionID)}}>
                  Save Comments 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{this.deleteComments(index)}}>
                  Delete Comment 
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigComments('Tariff Option Index Comments')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Movement</h6>
            </div>
            <Movement addMovement={(index)=>this.addMovement(index)} tariffOptionsDataStore={tariffOptionsDataStore} index={index} tariffOptionID={x.tariffOptionID}/>
            <div className='internalHeader'>
              <h6>Connection Point</h6>
            </div>
            <ConnectionPoint addConnectionPoint={(index)=>this.addConnectionPoint(index)} tariffOptionsDataStore={tariffOptionsDataStore} index={index} tariffOptionID={x.tariffOptionID}/>
            <div className='internalHeader'>
              <h6>Pipeline Area</h6>
            </div>
            {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.addPipelineArea(index)}}>
                  Add Pipeline Area 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.savePipelineArea(index, x.tariffOptionID)}}>
                  Save Pipeline Area 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{this.deletePipelineArea(index)}}>
                  Delete Pipeline Area 
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigPipelineArea('Tariff Option Index Pipeline Area')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Owner</h6>
            </div>
            {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.addOwner(index)}}>
                  Add Owner
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.saveOwner(index, x.tariffOptionID)}}>
                  Save Owner
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{this.deleteOwner(index)}}>
                  Delete Owner
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigOwner('Tariff Option Index Owners')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Related Tariff</h6>
            </div>
            {this.props.tariffOptionsDataStore?.stepName==='Process & Submit for Approval'&&this.props.tariffOptionsDataStore?.workflowAction==='Process' && tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].type==='Rules and Regulations' && (<Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.addRelatedTariff(index)}}>
                  Add Related Tariff
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{this.deleteRelatedTariff(index)}}>
                  Delete Related Tariff
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={()=>{tariffOptionsDataStore.addRelatedTariff(index, true)}}>
                  Link Tariff
                  </CustomButton>
              </div>
            </Row>)}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigRelatedTariff('Tariff Option Index Related Tariffs')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Loss Allowance</h6>
            </div>
            <LossAllowance addLossAllowance={(index)=>this.addLossAllowance(index)} tariffOptionsDataStore={tariffOptionsDataStore} index={index} tariffOptionID={x.tariffOptionID}/>
            {tariffOptionsDataStore.addLossAllowance('blank') && <></>}
            <div className='internalHeader'>
              <h6>Deduction</h6>
            </div>
            {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.addDeduction(index)}}>
                  Add Deduction
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStore.saveDeduction(index, x.tariffOptionID)}}>
                  Save Deduction
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true} onClick={()=>{this.deleteDeduction(index)}}>
                  Delete Deduction
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigDeduction('Tariff Option Index Deductions')}/>
                {tariffOptionsDataStore.statusOrHistory && <></>}
                {tariffOptionsDataStore.workflowComplete && <></>}
                {tariffOptionsDataStore.workflowStatusGridData && <></>}
            </Row>
            <div className='internalHeader'>
              <h6>CTS Purpose & Comments</h6>
            </div>
            <Row style={{margin: '10px'}}>
              <Col style={{display: 'flex', maxWidth: 'unset', alignItems: 'center'}}>
                  <label className="standard_label_style">Purpose:</label>
                  <textarea
                    style={{marginLeft: '20px', borderRadius: '5px', width: '100%', height: '120px', border: '1px solid #a3d0e4'}} 
                    value={tariffOptionsDataStore.purpose[index]}
                    onChange={(e) => tariffOptionsDataStore.changePurpose(e.target.value)}
                    disabled= {true}// {tariffOptionsDataStoreLog.readOnlyView}
                  />
              </Col>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigCTSComments('Tariff Option CTS Comments')}/>
            </Row>
            <WorkflowDetails datastore={tariffOptionsDataStore} stepName={''}/>
            </div>)))||<></>}
            </div>
          </div>
        {/* </Container> */}
      </div>
    )
  }
}
