import React, { PureComponent } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { pmcGridViewDataStore } from '../../stores';

interface TariffDetailIDState {
    tariffDetailId: number;
    data: any;
}

export class TariffDetailIDCellRenderer extends PureComponent<ICellRendererParams, TariffDetailIDState> {
    constructor(props: ICellRendererParams) {
        super(props);
        this.state = {
            tariffDetailId: props.data.tariffNumber,
            data: props.data
        };
    }

    onClickEditTariff = () => {
        const data = JSON.parse(JSON.stringify(this.state.data));
        pmcGridViewDataStore.onClickEditTariff(data);
    }
    
    render(): React.ReactNode {
        return (
            <div>
                <span style={{color: 'blue'}}
                    onClick={() => this.onClickEditTariff()}>
                            {this.state.tariffDetailId}</span>
            </div >
        );
    }
};