import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { RightAngleXRefDataStore } from '../stores/right-angle-xref-data-store';
import {
    GridOptions,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import './right-angle-xref.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { rightAngleXRefDataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';
import { AddEditRAXref } from './AddEditRAXref';

/* eslint-disable */
interface RightAngleXRefContainerProps {
    rightAngleXRefDataStore: RightAngleXRefDataStore;
}

interface RightAngleXRefContainerState {
    search: string;
}

@inject('rightAngleXRefDataStore')
@observer
export class RightAngleXRefContainer extends Component<RightAngleXRefContainerProps, RightAngleXRefContainerState> {
    constructor(props: RightAngleXRefContainerProps | Readonly<RightAngleXRefContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('RA XRef')
    }
    
    componentDidMount() {
        history.push('/RightAngleXRef');
        const { rightAngleXRefDataStore } = this.props;
        rightAngleXRefDataStore.init();
        rightAngleXRefDataStore.agGridService.refreshGrid();
    }

    onSelectTariffNumber = (e) => {
        rightAngleXRefDataStore.setTariffNumber(e);
    };

    onChangeOperator = (e) => {
        rightAngleXRefDataStore.setOperator(e);
    };
    
    onChangeRAName = (e) => {
        rightAngleXRefDataStore.setRAName(e);
    };

    onSelectRAName = (e) => {
        rightAngleXRefDataStore.setRAName(e);
    };

    onSelectSystemGroup = (e) => {
        rightAngleXRefDataStore.setSystemGroup(e);
    };

    onSelectDistributionType = (e) => {
        rightAngleXRefDataStore.setDistributionType(e);
    };

    onSelectTariffRequestType = (e) => {
        rightAngleXRefDataStore.setTariffRequestType(e);
    };

    onSelectTariffRateType = (e) => {
        rightAngleXRefDataStore.setTariffRateType(e);
    };

    onAddNewClick = async() => {
      rightAngleXRefDataStore.onAddNewRAXref();
    }
    
    render(): React.ReactNode {
      const { rightAngleXRefDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderRightAngleXRefScreen()}
        </Container>
      );
    }

    renderRightAngleXRefScreen() : React.ReactNode {
      const { rightAngleXRefDataStore } = this.props;
      return (
        <div className='rightAngleXRefBox'>
            <Container fluid>
                <div>
                    <div className='screenHeader'>
                    <h2 className='screen_title'>RightAngle XRef - Mapping</h2>
                    </div>
                    <div className='selectContainer'>
                    <div className='settingSelectionDiv'>
                    <Row>
                        <Col>
                            <label className='standard_label_style'>Tariff Number</label>
                            <ReactSelect
                                className='select'
                                values={rightAngleXRefDataStore.selectedTariffNumber}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...rightAngleXRefDataStore.tariffNumberDropdownOptions]}
                                onChange={this.onSelectTariffNumber}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Operator</label>
                            <ReactSelect
                                className='select'
                                values={rightAngleXRefDataStore.selectedOperator}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...rightAngleXRefDataStore.operatorDropdownOptions]}
                                onChange={this.onChangeOperator}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>RA Name</label>
                            <ReactSelect
                                className='select'
                                values={rightAngleXRefDataStore.selectedRAName}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...rightAngleXRefDataStore.raNamesDropdownOptions]}
                                onChange={this.onChangeRAName}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className='standard_label_style'>Asset Group</label>
                                <ReactSelect
                                    className='select'
                                    values={rightAngleXRefDataStore.selectedTariffRequestType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...rightAngleXRefDataStore.tariffRequestTypeDropdownOptions]}
                                    onChange={this.onSelectTariffRequestType}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className='standard_label_style'>Distribution</label>
                                <ReactSelect
                                    className='select'
                                    values={rightAngleXRefDataStore.selectedDistributionType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...rightAngleXRefDataStore.distributionTypeDropdownOptions]}
                                    onChange={this.onSelectDistributionType}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="standard_label_style">Pipeline System</label>
                                <ReactSelect
                                    className='select'
                                    values={rightAngleXRefDataStore.selectedSystemGroup}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...rightAngleXRefDataStore.systemGroupDropdownOptions]}
                                    onChange={this.onSelectSystemGroup}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className="standard_label_style">Tariff Rate Type</label>
                                <ReactSelect
                                    className='select'
                                    values={rightAngleXRefDataStore.selectedTariffRateType}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...rightAngleXRefDataStore.tariffRateTypeDropdownOptions]}
                                    onChange={this.onSelectTariffRateType}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col></Col>
                        </Row>
                    </div>
                    <div className='searchButtonDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=> rightAngleXRefDataStore.onSearchClick()}>
                            Search
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=> rightAngleXRefDataStore.onClearSearch()}>
                            Clear
                        </CustomButton>
                        <div className='otherHeaderButtonsDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={()=> this.onAddNewClick()} > 
                                Add New RA Xref
                            </CustomButton>
                        </div>
                    </div>
                    </div>
                {this.props.rightAngleXRefDataStore.showLoader == true &&
                    <div className = 'alert-overlay'> 
                    <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                    </div> 
                }
                <AgGridComponent gridConfig={this.getGridConfig('RA XRef')} />
                </div>
              </Container >
              {rightAngleXRefDataStore.isShowModal && <AddEditRAXref rightAngleXRefDataStore={rightAngleXRefDataStore}  />
          }
        </div>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          rightAngleXRefDataStore: { rightAngleXRefData, agGridService }
      } = this.props;
      const { rightAngleXRefDataStore } = this.props;
      const columnDefs = rightAngleXRefDataStore.getColDef();
      const rowData = rightAngleXRefData;
      const pmcDashboardGridParams: any = {
        rowData,
        columnDefs
      };
      return agGridService.getGridConfig(pmcDashboardGridParams, gridId);
    }
}