import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from 'common/services/ag-grid-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from 'features/account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import moment from 'moment';
import _ from 'lodash';
import { ProjectDashboardDataService } from '../services/project-dashboard-data-service';
import { ProjectDashboardMessages } from '../constants/enum';
import { projectRequestDataStore, projectRequestStep1DataStore, projectRequestStep2DataStore } from 'features/tariff-management/project-request/stores';


export class ProjectDashboardDataStore {
    @observable createdUser;
    @observable projectDashboardData: any[] = [];
    @observable showLoader = false;
    @observable openRequestGridData: any[] = [];
    @observable selectedGoLiveToDate = '';
    @observable selectedGoLiveFromDate = '';
    @observable selectedProjectRequestType = { value: 'All', label: 'All' };
    @observable selectedPipelineSystem = { value: 'All', label: 'All' };
    @observable selectedProjectName = '';
    @observable selectedWorkflowStatus = { value: 'All', label: 'All' };
    @observable selectedTraiff = { value: 'All', label: 'All' };
    @observable tariffRateTypesDropdownOptions: any[] = [];
    @observable projectRequestTypeDropdownOptions: any[] = [];
    @observable projectRequestTypeDropdownOptionsUI: any[] = [];
    @observable pipelineSystemsDropdownOptions: any[] = [];
    @observable pipelineSystemsDropdownOptionsUI: any[] = [];
    @observable workflowStepDropdownOptions: any[] = [];
    @observable workflowStepDropdownOptionsUI: any[] = [];
    @observable tariffDataDropdownOptions: any[] = [];
    @observable tariffDataDropdownOptionsUI: any[] = [];
    @observable endDateError = '';
    @observable defaultUserLocation = ''
    workflowStepData: any[] = [];

    constructor(
        public projectDashboardDataService: ProjectDashboardDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForOpenRequest: AgGridService
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.createdUser = this.accountStore.displayName;
        this.defaultUserLocation = this.accountStore.defaultUserLocation;
        this.selectedGoLiveToDate = '';
        this.selectedGoLiveFromDate = '';
        this.selectedProjectRequestType = { value: 'All', label: 'All' };
        this.selectedPipelineSystem = { value: 'All', label: 'All' };
        this.selectedProjectName = '';
        this.selectedWorkflowStatus = { value: 'All', label: 'All' };
        this.selectedTraiff = { value: 'All', label: 'All' };
        this.getWorkflowOpenProjectsRequests();
        this.getProjectRequestType();
        this.getPipelineSystems();
        this.getWorkflowStatus();
        this.getTariffData();
        this.loadViewModel();
        if (this.accountStore.rolesJson[0]?.defaultLocation === 'CAN') {
            this.getPMCTariffRateTypes();
        }
    }

    showOnlyUS = () => {
        return this.accountStore.rolesJson[0]?.defaultLocation==='US'
    }

    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const response = await this.projectDashboardDataService.getProjectDashboardData(0, 0, '', '', 0, 'All', '', this.defaultUserLocation);
        if (response) {
            if (!this.showOnlyUS()) {
                response.map(req => {
                    if (req.workflowStatus == null) {
                        req.workflowStatus = 'Completed'
                    }
                })
            }
            this.projectDashboardData = response;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const projectRequestTypeId = this.projectRequestTypeDropdownOptions.length > 0 && this.selectedProjectRequestType.label !=='All' ? this.projectRequestTypeDropdownOptions.filter((item) => item.name === this.selectedProjectRequestType.label)[0].assetGroupID : 0;
        const pipelineId = this.pipelineSystemsDropdownOptions.length > 0 && this.selectedPipelineSystem.label !=='All'? this.pipelineSystemsDropdownOptions.filter((item) => item.plName === this.selectedPipelineSystem.label)[0].pipelineID : 0;
        const workflowStatus = this.selectedWorkflowStatus.label;
        const tariffId  = this.tariffDataDropdownOptions.length > 0  && this.selectedTraiff.label !=='All' && this.selectedTraiff.label !=='New' ? this.tariffDataDropdownOptions.filter((item) => item.baseTariff === this.selectedTraiff.label)[0].tariffBaseID : this.selectedTraiff.label !=='All' ? -1 : 0;
        const response = await this.projectDashboardDataService.getProjectDashboardData(projectRequestTypeId , pipelineId, this.selectedGoLiveFromDate, this.selectedGoLiveToDate, tariffId, workflowStatus, this.selectedProjectName, this.defaultUserLocation);
        if (response) {
            if (!this.showOnlyUS()) {
                response.map(req => {
                    if (req.workflowStatus == null) {
                        req.workflowStatus = 'Completed'
                    }
                })
            }
            this.projectDashboardData = response;
            this.endDateError= '';
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async clearSearch(): Promise<void> {
        const response = await this.projectDashboardDataService.getProjectDashboardData(0, 0, '', '', 0, 'All', '', this.defaultUserLocation);
        if (response) {
            if (!this.showOnlyUS()) {
                response.map(req => {
                    if (req.workflowStatus == null) {
                        req.workflowStatus = 'Completed'
                    }
                })
            }
            this.projectDashboardData = response;
            this.selectedGoLiveFromDate = '';
            this.selectedProjectRequestType = { value: 'All', label: 'All' };
            this.selectedPipelineSystem = { value: 'All', label: 'All' };
            this.selectedProjectName = '';
            this.selectedWorkflowStatus = { value: 'All', label: 'All' };
            this.selectedTraiff = { value: 'All', label: 'All' };
            this.selectedGoLiveToDate = '';
            this.endDateError = '';
            const workflowStepDropdownData = [...new Map(this.workflowStepData.map(item => [item['name'], item])).values()];
            this.workflowStepDropdownOptions = workflowStepDropdownData;
            const a = (workflowStepDropdownData.map(x => {return { 'value': x.name, 'label': x.name}}));
            this.workflowStepDropdownOptionsUI = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async getWorkflowOpenProjectsRequests(): Promise<void> {
        const response = await this.projectDashboardDataService.getWorkflowOpenProjectsRequests(this.defaultUserLocation);
        if(response) {
            this.openRequestGridData = response;
        } 
    }


    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async getProjectRequestType(): Promise<void> {
        const response = await this.projectDashboardDataService.getProjectRequestType(this.defaultUserLocation);
        if (response) {
            this.projectRequestTypeDropdownOptions = response;
            const a = (response.map(x => {return { 'value': x.assetGroupID, 'label': x.name}}));
            this.projectRequestTypeDropdownOptionsUI = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPMCTariffRateTypes(): Promise<void> {
        const response = await this.projectDashboardDataService.getPMCTariffRateTypes();
        this.tariffRateTypesDropdownOptions = response;
    }

    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async getPipelineSystems(): Promise<void> {
        let response;
        if (!this.showOnlyUS()) {
            const systemGroups = await this.projectDashboardDataService.getCANSystemGroup();
            const a = (systemGroups.map(x => {return { 'pipelineID': x.settingID, 'plName': x.valueText}}));
            const sortedSystemGroups = _.orderBy(a, [x => x.plName], ['asc']);
            response = sortedSystemGroups;
        } else {
            response = await this.projectDashboardDataService.getPipelineSystem();
        }
        if(response) {
            this.pipelineSystemsDropdownOptions = response;
            const a = (response.map(x => {return { 'value': x.plName, 'label': x.plName}}));
            this.pipelineSystemsDropdownOptionsUI = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async getWorkflowStatus(): Promise<void> {
        const response = await this.projectDashboardDataService.getWorkflowStatus(this.defaultUserLocation);
        if(response) {
            const key  = 'name'
            const arrayUniqueByKey = [...new Map(response.map(item =>
                [item[key], item])).values()];
            this.workflowStepData = response;
            this.workflowStepDropdownOptions = arrayUniqueByKey;
            const a = (arrayUniqueByKey.map(x => {return { 'value': x.name, 'label': x.name}}));
            this.workflowStepDropdownOptionsUI = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(ProjectDashboardMessages.FETCH_ERROR_MESSAGE))
    async getTariffData(): Promise<void> {
        const response = await this.projectDashboardDataService.getTariffData();
        if(response) {
            this.showLoader = false;
            this.tariffDataDropdownOptions = response;
            const a = (response.map(x => {return { 'value': x.tariffBaseID, 'label': x.baseTariff}}));
            this.tariffDataDropdownOptionsUI = a;
        } 
    }


    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, 'Project Dashboard');
    };

    @action
    reset(): void {
        this.projectDashboardData = [];
    }

    @action
    async onChangeToDate(value) {
        if(value !== '' && value < this.selectedGoLiveFromDate) {
            this.endDateError = 'To Date should be greater.'
        } else {
            this.selectedGoLiveToDate = value;
            this.endDateError = ''
        }
    }

    @action
    async onChangeFromDate(value) {
        if(this.selectedGoLiveToDate !== '' && this.selectedGoLiveToDate < value) {
            this.endDateError = 'To Date should be greater.'
        } else {
            this.selectedGoLiveFromDate = value;
            this.endDateError = ''
        }
        
    }

    @action
    async onChangeProjectname(value) {
         this.selectedProjectName = value;
    }

    @action
    onSelectRequestType= (value) => {
        this.selectedProjectRequestType = { value: value.value, label: value.label };
        this.selectedWorkflowStatus = { value: 'All', label: 'All' };
        const selectRequestTypeData = this.projectRequestTypeDropdownOptions.find((item) => item.name === value.label);
        const workflowStepDropdownOptions = selectRequestTypeData && value.label !== 'All'? this.workflowStepData.filter((item) => item.assetGroupID === selectRequestTypeData.assetGroupID) : this.workflowStepData;
        const workflowStepDropdownData = [...new Map(workflowStepDropdownOptions.map(item => [item['name'], item])).values()];
        this.workflowStepDropdownOptions = workflowStepDropdownData;
        const a = (workflowStepDropdownData.map(x => {return { 'value': x.name, 'label': x.name}}));
        this.workflowStepDropdownOptionsUI = a;
      }

    @computed
    get ProjectDashboardData() {
        return toJS(this.projectDashboardData);
    }

    async onClickWorkflowId(params) {
        if (params.data.url || (!this.showOnlyUS() && (params.data['workflowStatus'] == 'Completed' || params.data['workflowStatus'] == null))) {
            params.data.url = params.data['workflowStatus'] == 'Completed' || params.data['workflowStatus'] == null ? 'project-request-step-2' : params.data.url;
            const selectedTariffRateTypeId = this.tariffRateTypesDropdownOptions.find(x => x.valueText === params.data['criteria'])?.settingID;
            projectRequestDataStore.workflowId = params.data['workflowID'];
            projectRequestDataStore.workflowCreatorJurisdiction = params.data['jurisdiction'];
            projectRequestDataStore.isFromWorkflowDashboard = true;
            projectRequestDataStore.selectedProjectRequestType = params.data['projectType'];
            projectRequestStep1DataStore.selectedProjectRequestTypeID = this.projectRequestTypeDropdownOptions.filter((item) => item.name === params.data['projectType'])[0].assetGroupID
            projectRequestStep1DataStore.selectedTariffRateType = params.data['criteria'];
            projectRequestStep1DataStore.selectedTariffRateTypeID = selectedTariffRateTypeId;
            projectRequestStep2DataStore.selectedTariffRateType = params.data['criteria'];
            projectRequestStep2DataStore.workflowStepStatus = params.data['workflowStatus'];
            history.push(`/${params.data.url}`, { workflowId: params.data['workflowID'], saved: true, selectedProjectRequestType: params.data['projectType'] });
        }
    }

}