import React, { Component } from 'react';
import { AppConstant } from 'app_constants';
import momenttimezone from 'moment-timezone';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './customer-maintenance.scss';
import {
    ColDef, RowSelectedEvent, CellEditingStoppedEvent, CellEditingStartedEvent
} from 'ag-grid-community';
import DatePicker from 'react-datepicker';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import { CustomerMaintenanceDataStore } from '../stores/customer-maintenance-data-store';
import moment from 'moment';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { customerMaintenanceDataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import { localStorageService } from 'shared/services/local-storage-service';
import { Portal } from 'react-overlays';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';
import { SyncStatus } from 'common/constants/enum';
import { Prompt } from 'react-router-dom';

/* eslint-disable */
interface AddCustomerMaintenanceContainerProps {
    customerMaintenanceDataStore: CustomerMaintenanceDataStore;
}

interface AddCustomerMaintenanceContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowSelected: boolean
}

interface calendarContainerInterface {
    children: any;
}

@inject('customerMaintenanceDataStore')
@observer
export class AddCustomerMaintenanceContainer extends Component<AddCustomerMaintenanceContainerProps, AddCustomerMaintenanceContainerState> {

    constructor(props) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowSelected: false
        };
        dataStore.setModuleName('CustomerMaintenanceAdd')
    }

    
  CalendarContainer = (props: calendarContainerInterface) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{props.children}</Portal>;
  };

  onChangeData = (params, value, key) => {
    const {customerMaintenanceDataStore} = this.props;
    const rowIndex = customerMaintenanceDataStore.LinkerData.findIndex(x=>x.rowId===params.data.rowId)
    customerMaintenanceDataStore.setLinkerData(value, rowIndex, key);
    customerMaintenanceDataStore.unsavedChanges = true;
   
      // customerMaintenanceDataStore.modifedRows[index]['lossAllowance'] = [...customerMaintenanceDataStore.modifedRows[index]['lossAllowance'], params.data?.rowId] 
  } 

  onChangeDate = (params, value, key) => {
    const {customerMaintenanceDataStore} = this.props;
    const rowIndex = customerMaintenanceDataStore.CreditsData.findIndex(x=>x.rowId===params.data.rowId)
    customerMaintenanceDataStore.setCreditsData(value, rowIndex, key);
    customerMaintenanceDataStore.unsavedChanges = true;
      // customerMaintenanceDataStore.modifedRows[index]['lossAllowance'] = [...customerMaintenanceDataStore.modifedRows[index]['lossAllowance'], params.data?.rowId] 
  } 

  onChangePrimaryInd=(params, value, key) => {
    const {customerMaintenanceDataStore} = this.props;
    const rowIndex = customerMaintenanceDataStore.addressData.findIndex(x=>x.rowId===params.data.rowId)
    const initValue = customerMaintenanceDataStore.addressData[rowIndex][key];
   
        if(initValue === 'Y') {
            customerMaintenanceDataStore.setAddressData('N', rowIndex, key);
        } else {
            customerMaintenanceDataStore.setAddressData('Y', rowIndex, key);
        }
        customerMaintenanceDataStore.addressData[rowIndex].newOrModified = true;
        customerMaintenanceDataStore.unsavedChanges = true;
  }

    componentDidMount() {
      const {customerMaintenanceDataStore} = this.props;
      customerMaintenanceDataStore.addCM = true;
      customerMaintenanceDataStore.initAddEdit();
    }

    componentWillUnmount() {
        const {customerMaintenanceDataStore} = this.props;
        customerMaintenanceDataStore.resetAddEdit();
      }

    componentDidUpdate() {
        const filters = localStorageService.get('Customer Maintenance_filter');
        this.props.customerMaintenanceDataStore.agGridServiceAdd.setAllFilters(filters);
        const columnVisible = localStorageService.get('Customer Maintenance_' + this.props.customerMaintenanceDataStore.username);
        this.props.customerMaintenanceDataStore.agGridServiceAdd.setColumnVisibility(columnVisible);
        // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }
  
    filterParams = {
        filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
        textFormatter: (r) => {
            if (r == null) return null;
            return r
            .toLowerCase()
            .replace(/[àáâãäå]/g, 'a')
            .replace(/æ/g, 'ae')
            .replace(/ç/g, 'c')
            .replace(/[èéêë]/g, 'e')
            .replace(/[ìíîï]/g, 'i')
            .replace(/ñ/g, 'n')
            .replace(/[òóôõö]/g, 'o')
            .replace(/œ/g, 'oe')
            .replace(/[ùúûü]/g, 'u')
            .replace(/[ýÿ]/g, 'y');
        },
        debounceMs: 200,
        suppressAndOrCondition: true,
      };
    
    CustomerMaintenanceGridColDef: ColDef[] = [
         { 
            headerName: 'Existing Customer Name', 
            field: 'custName',
            tooltipField: 'custName', 
            minWidth: 160,
            cellRenderer: params => {
                return (<span style={{color: 'blue', 'textDecoration': 'underline'}}
                    onClick={()=>{
                        if(this.props.customerMaintenanceDataStore.unsavedChanges) {
                            const confirmService = new ConfirmService();
                            confirmService.showConfirmDialog(
                            () => this.props.customerMaintenanceDataStore.getCustomerDetails(params.data?.customerID),
                            'Active',
                            `All the unsaved changes will be lost. Are you sure you want to continue?`
                            );
                        } else {
                            this.props.customerMaintenanceDataStore.getCustomerDetails(params.data?.customerID)
                        }
                       
                        }}>
                    {params.data?.custName}</span>)
            },
            filterParams: this.filterParams,
            editable: false,
            filter: true, 
            sortable: true,
            flex: 1,
        }
  ];

  GridConfigAddress: ColDef[] = [
    {
        headerName: 'Select',
        field: 'select',
        checkboxSelection: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled() && params.data.syncStatus !== SyncStatus[2]},
        colId:'Select',
        width: 40,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled() && params.data.syncStatus !== SyncStatus[2]? 'cell-editable' : ''},
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}}
      },
    { 
        headerName: 'Pipeline', 
        field: 'plName',
        tooltipField: 'plName', 
        minWidth: 160,
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return (params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled() && !params.data.existing)},
        filter: true, 
        sortable: true,
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
            return {
                dropDownValues: this.props.customerMaintenanceDataStore.assignedSystemsDropdown.filter((pl) => this.props.customerMaintenanceDataStore.linkerData.filter((item) => item.plName === pl.plName).length > 0).map(x=>x.plName),
                elementName: 'Pipeline'
            };
        },
        valueFormatter:  (params) => {
            return params.data.plName? params.data.plName : this.props.customerMaintenanceDataStore.assignedSystemsDropdown?.length>0 ? 'Select Pipeline': 'No Pipeline Found'  
        }, 
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled() && !params.data.existing? 'cell-editable' : ''},
        flex: 1,
    },
    { 
        headerName: 'Customer Type', 
        field: 'customerTypeValue',
        tooltipField: 'customerTypeValue', 
        minWidth: 160,
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
            const filteredLinkerData = this.props.customerMaintenanceDataStore.LinkerData.find((i) => i.plName === params.data.plName);
            const customerType  =  Object.keys(filteredLinkerData).filter((key, index) => {
                return filteredLinkerData[key] === true
            }
          )
            const filter =  this.props.customerMaintenanceDataStore.customerTypesDropdown.filter((x) => x.valueText !== 'Shipper' && 
                customerType.filter((i) => x.valueText.toLowerCase().includes(i.toLowerCase())).length > 0);

              
            return {
                dropDownValues: filter.map(x=>x.valueText),
                elementName: 'Customer Type'
            };
        },
        valueFormatter:  (params) => {
            return params.data.customerTypeValue? params.data.customerTypeValue : this.props.customerMaintenanceDataStore.customerTypesDropdown?.length>0 ? 'Select Customer Type': 'No  Customer Type Found'  
        }, 
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        flex: 1,
    },
    { 
        headerName: 'Address or Contact', 
        field: 'custAddressType',
        tooltipField: 'custAddressType', 
        minWidth: 160,
        filterParams: this.filterParams,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        singleClickEdit: true, 
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
          return {
              dropDownValues: this.props.customerMaintenanceDataStore.customerAddressTypeDropdown.map(x=>x.valueText),
              elementName: 'Address or Contact'
          };
        },
        valueFormatter:  (params) => {
            return params.data.custAddressType? params.data.custAddressType : this.props.customerMaintenanceDataStore.customerAddressTypeDropdown?.length>0 ? 'Select Address or Contact Type': 'No Address or Contact Type Found'  
        },
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        flex: 1,
    },
   { 
    headerName: 'Type', 
    field: 'addressTypeValue',
    tooltipField: 'addressTypeValue', 
    minWidth: 160,
    filterParams: this.filterParams,
    cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper'  &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
    singleClickEdit: true, 
    cellEditor: PredictiveSelectCellEditor,
    cellEditorParams: (params) => {
      return {
          dropDownValues: this.props.customerMaintenanceDataStore.addressTypesDropdown.map(x=>x.valueText),
          elementName: 'Address Type'
      };
    },
    valueFormatter:  (params) => {
        return params.data.addressTypeValue? params.data.addressTypeValue : this.props.customerMaintenanceDataStore.addressTypesDropdown?.length>0 ? 'Select Address Type': 'No Address Type Found'  
    },
    editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
    filter: true, 
    sortable: true,
    flex: 1,
    },
    { 
        headerName: 'Primary Ind',
        field: 'primaryInd',
        tooltipField: 'primaryInd',
        headerClass: 'text-center',
        editable: false,
        filter: true,
        sortable: true,
        cellRenderer: (params) => {
            return (
                <input
                    className='' 
                    type='checkbox' 
                    checked={ params.data && params.data['primaryInd']==='Y'}
                    onClick={(value) => this.onChangePrimaryInd(params, value, 'primaryInd')}
                    disabled = {params.data.customerTypeValue === 'Shipper' ||  customerMaintenanceDataStore.isSaveDisabled()}
                />);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 130,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        singleClickEdit: true,
        flex: 1
    },
   { 
    headerName: 'First Name', 
    field: 'firstName',
    tooltipField: 'firstName', 
    singleClickEdit: true, 
    cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
    minWidth: 160,
    filterParams: this.filterParams,
    editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
    filter: true, 
    sortable: true,
    flex: 1,
    },
   { 
    headerName: 'Last Name', 
    field: 'lastName',
    tooltipField: 'lastName', 
    cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
    singleClickEdit: true, 
    minWidth: 160,
    filterParams: this.filterParams,
    editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
    filter: true, 
    sortable: true,
    flex: 1,
    },
    { 
        headerName: 'Initial', 
        field: 'initial',
        tooltipField: 'initial', 
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        singleClickEdit: true, 
        minWidth: 160,
        filterParams: this.filterParams,
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'Title', 
        field: 'title',
        tooltipField: 'title', 
        minWidth: 160,
        filterParams: this.filterParams,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'Address Line 1', 
        field: 'addrLine1',
        tooltipField: 'addrLine1', 
        minWidth: 160,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'Address Line 2', 
        field: 'addrLine2',
        tooltipField: 'addrLine2', 
        minWidth: 160,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'City', 
        field: 'city',
        tooltipField: 'city', 
        minWidth: 160,
        filterParams: this.filterParams,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'State', 
        field: 'stateName',
        tooltipField: 'stateName', 
        minWidth: 160,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        filterParams: this.filterParams,
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
            return {
                dropDownValues: this.props.customerMaintenanceDataStore.tariffStatesDropdownOption.map(x=>x.stateName),
                elementName: 'State'
            };
        },
        valueFormatter:  (params) => {
            return params.data.stateName? params.data.stateName : this.props.customerMaintenanceDataStore.tariffStatesDropdownOption?.length>0 ? 'Select State': 'No State Found'  
        }, 
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'Zip', 
        field: 'zip',
        tooltipField: 'zip', 
        minWidth: 160,
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        sortable: true,
        flex: 1,
        valueSetter: (params) => {
            if(params.newValue.match(/^[0-9.]*$/)){
              params.data.zip=params.newValue.toUpperCase();
              return true
            } else {
                customerMaintenanceDataStore.setError('Invalid Zip!')
                return false
            }
        },
    },
    { 
        headerName: 'Country', 
        field: 'countryValue',
        tooltipField: 'countryValue', 
        minWidth: 160,
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
            return {
                dropDownValues: this.props.customerMaintenanceDataStore.jurisdictionDropdown.filter((y) => y.valueCode === this.props.customerMaintenanceDataStore.accountStore.defaultUserLocation).map(x=>x.valueText),
                elementName: 'Country'
            };
        },
        valueFormatter:  (params) => {
            return params.data.countryValue? params.data.countryValue : this.props.customerMaintenanceDataStore.jurisdictionDropdown?.length>0 ? 'Select Country': 'No Country Found'  
        }, 
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        flex: 1,
    },
    { 
        headerName: 'Phone', 
        field: 'phone',
        tooltipField: 'phone', 
        minWidth: 160,
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' && !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        valueSetter: (params) => {
            if(params.newValue.length!==10){
              customerMaintenanceDataStore.setError('Invalid Phone Number!')
              return false
            } else {
              params.data.phone=params.newValue;
              return true
            }
        },
        valueFormatter: (params)=>{
            if(params.data.phone){
            let a =params.data.phone.slice(0,3);
            let b =params.data.phone.slice(3,6);
            let c =params.data.phone.slice(6);
            return a + '-' + b + '-' + c
            } else return ''
        },
        flex: 1,
    },
    { 
        headerName: 'Email', 
        field: 'email',
        tooltipField: 'email', 
        minWidth: 160,
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        filter: true, 
        sortable: true,
        cellClass: (params) => {return params.data.customerTypeValue !== 'Shipper' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        flex: 1,
        valueSetter: (params) => {
            if(params.newValue.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)){
              params.data.email=params.newValue.toUpperCase();
              return true
            } else {
              customerMaintenanceDataStore.setError('Invalid E-mail Address!')
              return false
            }
        }
    },
    
];

GridConfigAdditionalContact: ColDef[] = [
    {
        headerName: 'Select',
        field: 'select',
        checkboxSelection:(params) => {return (!params.data.contactTypeValue ||  params.data.contactTypeValue === 'Phone')&&  !customerMaintenanceDataStore.isSaveDisabled() && params.data.syncStatus !== SyncStatus[2]},
        colId:'Select',
        width: 40,
        cellClass:(params) => {return (!params.data.contactTypeValue ||  params.data.contactTypeValue === 'Phone') &&  !customerMaintenanceDataStore.isSaveDisabled() && params.data.syncStatus !== SyncStatus[2] ? 'cell-editable' : ''},
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}}
      },
    { 
       headerName: 'Contact Type', 
       field: 'contactTypeValue',
       tooltipField: 'contactTypeValue', 
       minWidth: 160,
       filterParams: this.filterParams,
    //    cellClass:(params) => {return !params.data.contactTypeValue ||  params.data.contactTypeValue === 'Phone'? 'cell-editable' : ''},
    //    editable: (params) => {return !params.data.contactTypeValue ||  params.data.contactTypeValue === 'Phone'},
       editable: false,
       filter: true, 
       sortable: true,
       singleClickEdit: true, 
       cellEditor: PredictiveSelectCellEditor,
       cellEditorParams: (params) => {
         return {
             dropDownValues: this.props.customerMaintenanceDataStore.contactTypesDropdown.filter(x=> x.valueText === 'Phone').map(x=>x.valueText),
             elementName: 'Contact Type'
         };
       },
       valueFormatter:  (params) => {
           return params.data.contactTypeValue? params.data.contactTypeValue : this.props.customerMaintenanceDataStore.contactTypesDropdown?.length>0 ? 'Select Contact Type': 'No Contact Type Found'  
       },
       flex: 1,
   },
   { 
    headerName: 'Contact', 
    field: 'contactInfo',
    tooltipField: 'contactInfo', 
    minWidth: 160,
    filterParams: this.filterParams,
    cellClass:(params) => {return params.data.contactTypeValue === 'Phone' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
    editable: (params) => {return params.data.contactTypeValue === 'Phone' &&  !customerMaintenanceDataStore.isSaveDisabled()},
    filter: true, 
    sortable: true,
    singleClickEdit: true, 
    cellEditor: PredictiveSelectCellEditor,
    cellEditorParams: (params) => {
    return {
        dropDownValues: this.props.customerMaintenanceDataStore.addressData.filter((addr) => addr.custAddressType === 'CONTACT' && addr.customerTypeValue !== 'Shipper').map(x=>{return x.firstName +' ' + x.lastName}).filter((x , index, arr) => arr.indexOf(x) === index),
        elementName: 'Contact'
    };
    },
    valueFormatter:  (params) => {
        return params.data.contactInfo? params.data.contactInfo : this.props.customerMaintenanceDataStore.addressData?.length>0 ? 'Select Contact': 'No Contact Found'  
    },
    flex: 1,
},
   { 
    headerName: 'Phone Type', 
    field: 'phoneTypeValue',
    tooltipField: 'phoneTypeValue', 
    cellClass:(params) => {return params.data.contactTypeValue === 'Phone' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
    minWidth: 160,
    filterParams: this.filterParams,
    editable: (params) => {return params.data.contactTypeValue === 'Phone' &&  !customerMaintenanceDataStore.isSaveDisabled()},
    singleClickEdit: true,
    cellEditor: PredictiveSelectCellEditor,
    cellEditorParams: (params) => {
      return {
          dropDownValues: this.props.customerMaintenanceDataStore.phoneNumberTypesDropdown.map(x=>x.valueText),
          elementName: 'Phone Type'
      };
    },
    valueFormatter:  (params) => {
        return params.data.phoneTypeValue? params.data.phoneTypeValue : this.props.customerMaintenanceDataStore.phoneNumberTypesDropdown?.length>0 ? 'Select Phone Type': 'No Phone Type Found'  
    }, 
    filter: true, 
    sortable: true,
    flex: 1,
    },
    { 
        headerName: 'Phone', 
        field: 'phone',
        tooltipField: 'phone', 
        minWidth: 160,
        cellClass:(params) => {return params.data.contactTypeValue === 'Phone' &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: (params) => {return params.data.contactTypeValue === 'Phone' &&  !customerMaintenanceDataStore.isSaveDisabled()},
        valueSetter: (params) => {
        if(params.newValue.length!==10){
          customerMaintenanceDataStore.setError('Invalid Phone Number!')
          return false
        } else {
          params.data.phone=params.newValue;
          params.data.newOrModified = true;
          return true
            }
        },
        valueFormatter: (params)=>{
            if(params.data.phone){
            let a =params.data.phone.slice(0,3);
            let b =params.data.phone.slice(3,6);
            let c =params.data.phone.slice(6);
            return a + '-' + b + '-' + c
            } else return ''
        },
        filter: true, 
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'Email Address', 
        field: 'emailAddress',
        tooltipField: 'emailAddress', 
        minWidth: 160,
        filterParams: this.filterParams,
        editable: false,
        // cellClass:(params) => {return params.data.contactTypeValue === 'Email'? 'cell-editable' : ''},
        singleClickEdit: true, 
        filter: true, 
        sortable: true,
        flex: 1,
        valueSetter: (params) => {
            if(params.newValue.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)){
              params.data.emailAddress=params.newValue.toUpperCase();
              return true
            } else {
              customerMaintenanceDataStore.setError('Invalid E-mail Address!')
              return false
            }
        }
    },
    { 
        headerName: 'Remarks', 
        field: 'remark',
        tooltipField: 'remark', 
        minWidth: 160,
        filterParams: this.filterParams,
        // editable: true,
        cellClass:(params) => {return (!params.data.contactTypeValue ||  params.data.contactTypeValue === 'Phone') &&  !customerMaintenanceDataStore.isSaveDisabled()? 'cell-editable' : ''},
        editable: (params) => {return (!params.data.contactTypeValue ||  params.data.contactTypeValue === 'Phone') &&  !customerMaintenanceDataStore.isSaveDisabled()},
        singleClickEdit: true, 
        filter: true, 
        sortable: true,
        flex: 1,
    }
];

GridConfigOtherInformation: ColDef[] = [
    {
        headerName: 'Select',
        field: 'select',
        checkboxSelection: true,
        colId:'Select',
        width: 40,
        cellClass: (params) => {return 'cell-editable'},
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}}
      },
    { 
       headerName: 'Affiliated Companies/DBA', 
       field: 'comment',
       tooltipField: 'comment', 
       minWidth: 160,
       filterParams: this.filterParams,
       cellClass: 'cell-editable',
       editable: true,
       filter: true, 
       sortable: true,
       singleClickEdit: true, 
       flex: 1,
   },
]


GridConfigComments: ColDef[] = [
    // {
    //     headerName: 'Select',
    //     field: 'select',
    //     checkboxSelection: false,
    //     colId:'Select',
    //     width: 40,
    //     cellClass: (params) => {return 'cell-editable'},
    //     headerCheckboxSelection: true,
    //     headerCheckboxSelectionFilteredOnly: true,
    //     cellStyle: () => {return {padding: '10px'}}
    //   },
    { 
        headerName: 'Comment Type', 
        field: 'custCommentType',
        tooltipField: 'custCommentType', 
        minWidth: 160,
        filterParams: this.filterParams,
        // cellClass: 'cell-editable',
        editable: false,
        filter: true, 
        sortable: true,
        singleClickEdit: true, 
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
          return {
              dropDownValues: this.props.customerMaintenanceDataStore.customerCommentTypeDropdown.map((x) => x.valueText),
              elementName: 'Comment Type'
          };
        },
        valueFormatter:  (params) => {
            return params.data.custCommentType? params.data.custCommentType : this.props.customerMaintenanceDataStore.customerCommentTypeDropdown?.length>0 ? 'Select Comment Type': 'No Comment Type Found'  
        },
        flex: 1,
    },
    { 
        headerName: 'Contact', 
        field: 'contactInfo',
        tooltipField: 'contactInfo', 
        minWidth: 160,
        filterParams: this.filterParams,
        // cellClass: (params) =>{ return params.data.custCommentType === 'CONTACT' ? 'cell-editable' : ''},
        // editable: (params) =>{ return params.data.custCommentType === 'CONTACT' ? true : false},
        editable: false,
        filter: true, 
        sortable: true,
        singleClickEdit: true, 
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
        return {
            dropDownValues: this.props.customerMaintenanceDataStore.addressData.filter((addr) => addr.custAddressType === 'CONTACT' && addr.customerTypeValue !== 'Shipper').map(x=>{return x.firstName +' ' + x.lastName}).filter((x , index, arr) => arr.indexOf(x) === index),
            elementName: 'Contact'
        };
        },
        valueFormatter:  (params) => {
            return params.data.contactInfo? params.data.contactInfo : this.props.customerMaintenanceDataStore.addressData?.length>0 ? 'Select Contact': 'No Contact Found'  
        },
        flex: 1,
    },
    { 
       headerName: 'Comments', 
       field: 'comment',
       tooltipField: 'comment', 
       minWidth: 160,
       filterParams: this.filterParams,
       // cellClass: 'cell-editable',
       editable: false,
       filter: true, 
       sortable: true,
       singleClickEdit: true, 
       flex: 1,
   },
   { 
        headerName: 'Date', 
        field: 'createDate',
        tooltipField: 'createDate', 
        minWidth: 160,
        filterParams: this.filterParams,
        editable: false,
        filter: true, 
        sortable: true,
        singleClickEdit: true, 
        flex: 1,
        valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return moment(params.data['createDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
    }
]

GridConfigCredits: ColDef[] = [
    { 
       headerName: 'Credit Type', 
       field: 'valueText',
       tooltipField: 'valueText', 
       minWidth: 160,
       filterParams: this.filterParams,
       // cellClass: 'cell-editable',
       editable: false,
       filter: true, 
       sortable: true,
       singleClickEdit: true, 
       cellEditor: PredictiveSelectCellEditor,
       cellEditorParams: (params) => {
         return {
             dropDownValues: this.props.customerMaintenanceDataStore.creditTypesDropdown.map(x=>x.valueText),
             elementName: 'Credit Type'
         };
       },
       valueFormatter:  (params) => {
           return params.data.valueText? params.data.valueText : this.props.customerMaintenanceDataStore.creditTypesDropdown?.length>0 ? 'Select Credit Type': 'No Credit Type Found'  
       },
       flex: 1,
   },
    { 
        headerName: 'Credit Amount', 
        field: 'creditAmount',
        tooltipField: 'creditAmount', 
        minWidth: 160,
        // cellClass: 'cell-editable',
        filterParams: this.filterParams,
        singleClickEdit: true, 
        editable: false,
        valueSetter: (params) => {
            if(params.newValue.match(/^[0-9.]*$/)){
              params.data.creditAmount=params.newValue.toUpperCase();
              return true
            } else {
                customerMaintenanceDataStore.setError('Invalid Credit Amount!')
                return false
            }
        }, 
        filter: true, 
        sortable: true,
        flex: 1,
    },
    {
        headerName: 'Effective Range',
        field: 'effectiveRange',
        editable: false,
        // cellClass: (params) => {return params.data ?  'cell-editable' : ''},
        valueFormatter: ({ value, data }) => '',
        cellRenderer: (params) => {
            return (params.data ? (<DatePicker
                  popperClassName='ag-custom-component-popup'
                  selected={params.data.effectiveRange  ? new Date(params.data.effectiveRange): null}
                  onChange={(value)=>{this.onChangeDate(params, value, 'effectiveRange')}}
                  popperContainer={this.CalendarContainer}
                  popperPlacement='top-start'
                  className='datePickerGridInput'
                //   dateFormat="MMMM yyyy"
                //   showMonthYearPicker
                  readOnly={true}
                  />) : '')
        },
        minWidth: 130,
        flex: 1,
        // valueSetter: (params) => {
        //     if(params.newValue){
        //       params.data.effectiveRange=moment(new Date(params.newValue)).format('MM/YYYY');
        //       return true
        //     } else {
        //         customerMaintenanceDataStore.setError('Invalid Effective Range!')
        //         return false
        //     }
        // }
    },
    { 
        headerName: 'End Date', 
        field: 'endCycle',
        tooltipField: 'endCycle', 
        minWidth: 160,
        editable: false,
        // cellClass: (params) => {return params.data ?  'cell-editable' : ''},
        valueFormatter: ({ value, data }) => '',
        cellRenderer: (params) => {
            return (params.data ? (<DatePicker
                  popperClassName='ag-custom-component-popup'
                  selected={params.data.endCycle  ? new Date(params.data.endCycle): null}
                  onChange={(value)=>{this.onChangeDate(params, value, 'endCycle')}}
                  popperContainer={this.CalendarContainer}
                  popperPlacement='top-start'
                  className='datePickerGridInput'
                //   dateFormat="MMMM yyyy"
                //   showMonthYearPicker
                  readOnly={true}
                  />) : '')
        },
        flex: 1,
    },
    { 
        headerName: 'Modified By', 
        field: 'updateUser', 
        colId: 'updateUser',
        filter: true, 
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        },
        hide: false
    },
    { 
        headerName: 'Modified Date', 
        field: 'updateDate', 
        colId: 'updateDate',
        filter: true, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updateDate'] === null) {
                return '';
            } else {
                return moment(params.data['updateDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
    }
];

  LinkerDataGridColDef: ColDef[] = [
    {
        headerName: 'Select',
        field: 'select',
        checkboxSelection: (params) => {return !customerMaintenanceDataStore.isSaveDisabled() && (!params.data.existing || !params.data.syncToProdList)},
        colId:'Select',
        width: 40,
        cellClass: (params) => {return  !customerMaintenanceDataStore.isSaveDisabled() &&  (!params.data.existing || !params.data.syncToProdList) ? 'cell-editable' : ''},
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}}
      },
    {
        headerName: 'Base System',
        field: 'plName',
        cellEditor: PredictiveSelectCellEditor,
        cellEditorParams: (params) => {
          return {
              dropDownValues: this.props.customerMaintenanceDataStore.assignedSystemsDropdown.filter((i) => this.props.customerMaintenanceDataStore.linkerData.filter((pl) => pl.plName === i.plName).length <= 0).map(x=>x.plName),
              elementName: 'Base System'
          };
        },
        editable: (params) => { 
            return (params.data.plName && params.data.existing) || customerMaintenanceDataStore.isSaveDisabled() ? false: true;
        },
        cellClass: (params) => { 
            return (params.data.plName && params.data.existing) || customerMaintenanceDataStore.isSaveDisabled() ? '': 'cell-editable'
        },
        singleClickEdit: true, 
        valueFormatter:  (params) => {
          return params.data.plName? params.data.plName : this.props.customerMaintenanceDataStore.assignedSystemsDropdown?.length>0 ? 'Select Base System': 'No Base System Found'  
        },
        minWidth: 160,
        flex: 1
      },
    { 
        headerName: 'Shipper',
        field: 'shipper',
        tooltipField: 'shipper',
        headerClass: 'text-center',
        editable: false,
        filter: true,
        sortable: true,
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.shipper===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.shipper, 'shipper')}}
          disabled={true}/>);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 110,
        singleClickEdit: true,
        flex: 1
     },
     { 
        headerName: 'Consignee',
        field: 'consignee',
        tooltipField: 'consignee',
        headerClass: 'text-center',
        editable: false,
        filter: true,
        sortable: true,
        cellClass: (params) => {return  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.consignee===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.consignee, 'consignee')}}
          disabled={(params.data.plName && (params.data.plName.startsWith('Z-') || params.data.plName.startsWith('z-'))) ||  customerMaintenanceDataStore.isSaveDisabled() || params.data.syncToProdList?.includes('consignee')}/>);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 110,
        singleClickEdit: true,
        flex: 1
     },
     { 
        headerName: 'Supplier',
        field: 'supplier',
        tooltipField: 'supplier',
        headerClass: 'text-center',
        cellClass: (params) => {return  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        editable: false,
        filter: true,
        sortable: true,
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.supplier===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.supplier, 'supplier')}}
          disabled={(params.data.plName && (params.data.plName.startsWith('Z-') || params.data.plName.startsWith('z-'))) ||  customerMaintenanceDataStore.isSaveDisabled() || params.data.syncToProdList?.includes('supplier')}/>);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 110,
        singleClickEdit: true,
        flex: 1
     },
     { 
        headerName: 'Operator',
        field: 'operator',
        tooltipField: 'operator',
        headerClass: 'text-center',
        editable: false,
        filter: true,
        sortable: true,
        cellClass: (params) => {return  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.operator===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.operator, 'operator')}}
          disabled={(params.data.plName && (params.data.plName.startsWith('Z-') || params.data.plName.startsWith('z-'))) || customerMaintenanceDataStore.isSaveDisabled()  || params.data.syncToProdList?.includes('operator')}/>);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 110,
        singleClickEdit: true,
        flex: 1
     },
     { 
        headerName: 'Owner',
        field: 'owner',
        tooltipField: 'owner',
        headerClass: 'text-center',
        editable: false,
        filter: true,
        cellClass: (params) => {return  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        sortable: true,
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.owner===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.owner, 'owner')}}
          disabled={(params.data.plName && (params.data.plName.startsWith('Z-') || params.data.plName.startsWith('z-'))) ||  customerMaintenanceDataStore.isSaveDisabled() || params.data.syncToProdList?.includes('owner')}/>);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 110,
        singleClickEdit: true,
        flex: 1
     },
     { 
        headerName: 'Inter-Company',
        field: 'ic',
        tooltipField: 'ic',
        headerClass: 'text-center',
        editable: false,
        cellClass: (params) => {return  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        filter: true,
        sortable: true,
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.ic===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.ic, 'ic')}}
          disabled={(params.data.plName && (params.data.plName.startsWith('Z-') || params.data.plName.startsWith('z-'))) || customerMaintenanceDataStore.isSaveDisabled() || params.data.syncToProdList?.includes('ic')}/>);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 110,
        singleClickEdit: true,
        flex: 1
     },
     { 
        headerName: 'Trade',
        field: 'trader',
        tooltipField: 'trader',
        headerClass: 'text-center',
        editable: false,
        cellClass: (params) => {return  !customerMaintenanceDataStore.isSaveDisabled() ? 'cell-editable' : ''},
        filter: true,
        sortable: true,
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.trader===true}  
          onClick={(e)=>{this.onChangeData(params, !params.data.trader, 'trader')}}
          disabled={(params.data.plName && (params.data.plName.startsWith('Z-') || params.data.plName.startsWith('z-'))) || customerMaintenanceDataStore.isSaveDisabled()  || params.data.syncToProdList?.includes('trade')}/>);
        }, 
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        minWidth: 110,
        singleClickEdit: true,
        flex: 1
     },
     { 
        headerName: 'Nomination Start Date', 
        field: 'nominationStartDate', 
        colId: 'nominationStartDate',
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 160,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['nominationStartDate'] || params.data['nominationStartDate'] === null || params.data['nominationStartDate'] === '' ) {
                return '';
            } else {
                return moment(params.data['nominationStartDate']).format(AppConstant.DATE_FORMAT);
            }
        },
    }
];

  

    getColDef() {
      return this.CustomerMaintenanceGridColDef
    }


    getGridConfig(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridService }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          rowData: customerMaintenanceDataStore.customerNames,
          columnDefs:this.getColDef(),
          onRowSelected: this.onRowSelected,
        //   onCellEditingStopped: this.onCellEditingStopped,
        //   rowModelType,
        //   cacheBlockSize
          };
        return agGridService.getGridConfig(cmGridParams, gridId);
      }

      getGridConfigAddress(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridServiceForAddAddress }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          rowData: customerMaintenanceDataStore.addressData,
          columnDefs:this.GridConfigAddress,
          onRowSelected: this.onRowSelected,
          context: agGridServiceForAddAddress,
          onCellEditingStopped: (event) => {
            customerMaintenanceDataStore.unsavedChanges = true;
            event.data.newOrModified = true;
            this.props.customerMaintenanceDataStore.agGridServiceForAddAddress.redrawRows();
          },
        //   onCellEditingStarted: this.onCellEditingStarted,
        //   rowModelType,
        //   cacheBlockSize
          };
        return agGridServiceForAddAddress.getGridConfig(cmGridParams, gridId);
      }

      getGridConfigAdditionalContact(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridServiceForAddOtherContacts }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          rowData: customerMaintenanceDataStore.addContactData,
          columnDefs:this.GridConfigAdditionalContact,
          onRowSelected: this.onRowSelected,
          onCellEditingStopped: (event) => {
            customerMaintenanceDataStore.unsavedChanges = true;
            event.data.newOrModified = true;
            const col = event.colDef.field || '';
            if(col === 'contactTypeValue' && event.data.contactTypeValue === 'Email') {
                event.data.contactInfo = null;
                event.data.phoneTypeValue = null;
                event.data.phone = null
            } else {
                if(col === 'contactTypeValue' && event.data.contactTypeValue === 'Phone') {
                    event.data.emailAddress = null;
                }
            }
            this.props.customerMaintenanceDataStore.agGridServiceForAddOtherContacts.redrawRows();
          },
          context: agGridServiceForAddOtherContacts
        //   rowModelType,
        //   cacheBlockSize
          };
        return agGridServiceForAddOtherContacts.getGridConfig(cmGridParams, gridId);
      }

      getGridConfigPipelines(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridServiceAdd }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          rowData: customerMaintenanceDataStore.linkerData,
          columnDefs:this.LinkerDataGridColDef,
          onRowSelected: this.onRowSelected,
          onCellEditingStopped: this.onCellEditingStopped,
          context:agGridServiceAdd
        //   rowModelType,
        //   cacheBlockSize
          };
        return agGridServiceAdd.getGridConfig(cmGridParams, gridId);
      }

      
      getGridConfigCredits(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridServiceForAddCredit }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          rowData: customerMaintenanceDataStore.creditsData,
          columnDefs:this.GridConfigCredits,
          onRowSelected: this.onRowSelected,
          onCellEditingStopped: this.onCellEditingStopped,
          context: agGridServiceForAddCredit
        //   rowModelType,
        //   cacheBlockSize
          };
        return agGridServiceForAddCredit.getGridConfig(cmGridParams, gridId);
      }

      getGridConfigOtherData(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridServiceForAddOtherInfo }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          rowData: customerMaintenanceDataStore.OtherData,
          columnDefs:this.GridConfigOtherInformation,
          onRowSelected: this.onRowSelected,
          onCellEditingStopped: this.onCellEditingStopped,
          context: agGridServiceForAddOtherInfo
        //   rowModelType,
        //   cacheBlockSize
          };
        return agGridServiceForAddOtherInfo.getGridConfig(cmGridParams, gridId);
      }

      
      getGridConfigComments(gridId) {
        const {
            customerMaintenanceDataStore: { CustomerMaintenanceData, agGridServiceForAddComments }
        } = this.props;
        const { customerMaintenanceDataStore } = this.props;
        const rowModelType = 'infinite';
        const cacheBlockSize =  dataStore.CacheBlockSize;
        const cmGridParams: any = {
          rowData: customerMaintenanceDataStore.commentsData,
          columnDefs:this.GridConfigComments,
          onRowSelected: this.onRowSelected,
          onCellEditingStopped: this.onCellEditingStopped,
          context: agGridServiceForAddComments
        //   rowModelType,
        //   cacheBlockSize
          };
        return agGridServiceForAddComments.getGridConfig(cmGridParams, gridId);
      }

      

      onRowSelected = (event: RowSelectedEvent): void => {
        this.setState({...this.state, rowSelected: (event.api.getSelectedNodes().length>=1)});
      }

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const { customerMaintenanceDataStore } = this.props;
        customerMaintenanceDataStore.unsavedChanges = true;
        // const rowId = event.data.rowId;
        // const rowData = event.data;
        // const col = event.colDef.field || '';
        // const value = event.value;
        // customerMaintenanceDataStore.setUpdatedRowIds([...customerMaintenanceDataStore.updatedRows, event.data.rowId])
    };
    
    // onCellEditingStarted = (event: CellEditingStartedEvent): void => {
    //     const { customerMaintenanceDataStore } = this.props;
    //     const rowId = event.data.rowId;
    //     const rowData = event.data;
    //     const col = event.colDef.field || '';
    //     const value = event.value;
    //     if(col==='phone'){
    //         if(value.length%3===0){
    //             customerMaintenanceDataStore.addressData=customerMaintenanceDataStore.AddressData;
    //             const rowIndex = customerMaintenanceDataStore.LinkerData.findIndex(x=>x.rowId===rowId);
    //             customerMaintenanceDataStore.addressData[rowIndex][col] = value + ('-');
    //         }
    //     }
    // };

    onClearRow = async(sectionName) => {
         if(sectionName === 'Linker') {
            const selectedRows = await this.props.customerMaintenanceDataStore.agGridServiceAdd.getSelectedRows();
            const selectedRowIds = selectedRows && selectedRows.length > 0 ? selectedRows?.map((x) =>{ return  x.rowId}) : []
            const filterValidRows = selectedRowIds.length > 0 ? this.props.customerMaintenanceDataStore.LinkerData.filter((item) => !selectedRowIds.includes(item.rowId)) : this.props.customerMaintenanceDataStore.backupLinkerData;
            this.props.customerMaintenanceDataStore.linkerData = filterValidRows;
        }
        if(sectionName === 'Address') {
            const selectedRows = await this.props.customerMaintenanceDataStore.agGridServiceForAddAddress.getSelectedRows();
            this.props.customerMaintenanceDataStore.addressData = this.props.customerMaintenanceDataStore.AddressData.filter(
                x=>x.existing===true || (selectedRows && selectedRows.every(y=>x.rowId!==y.rowId)));
            const selectedRowIds = selectedRows && selectedRows.length > 0 ?  [...selectedRows].filter(x=>x.existing===true).map((x) =>{ return  x.tariffCustAddID}) : []
            if(selectedRowIds.length > 0) {
                this.deleteSectionAlert(selectedRowIds, sectionName)
            }
        }
        if(sectionName === 'AddContact') {
            const selectedRows = await this.props.customerMaintenanceDataStore.agGridServiceForAddOtherContacts.getSelectedRows();
            this.props.customerMaintenanceDataStore.addContactData = this.props.customerMaintenanceDataStore.AddContanctData.filter(
                x=>x.existing===true || (selectedRows && selectedRows.every(y=>x.rowId!==y.rowId)));
            const selectedRowIds = selectedRows && selectedRows.length > 0 ? [...selectedRows].filter(x=>x.existing===true)?.map((x) =>{ return  x.tariffCustAddContactID}) : []
            if(selectedRowIds.length > 0) {
                this.deleteSectionAlert(selectedRowIds, sectionName)
            }
        }
        if(sectionName === 'OtherData') {
            const selectedRows = await this.props.customerMaintenanceDataStore.agGridServiceForAddOtherInfo.getSelectedRows();
            this.props.customerMaintenanceDataStore.otherData = this.props.customerMaintenanceDataStore.OtherData.filter(
                x=>x.existing===true || (selectedRows && selectedRows.every(y=>x.rowId!==y.rowId)));
            const selectedRowIds = selectedRows && selectedRows.length > 0 ? [...selectedRows].filter(x=>x.existing===true)?.map((x) =>{ return  x.tariffCustCommentID}) : []
            if(selectedRowIds.length > 0) {
                this.deleteSectionAlert(selectedRowIds, sectionName)
            }
        }
        if(sectionName === 'AddComments') {
            const selectedRows = await this.props.customerMaintenanceDataStore.agGridServiceForAddComments.getSelectedRows();
            this.props.customerMaintenanceDataStore.commentsData = this.props.customerMaintenanceDataStore.CommentsData.filter(
                x=>x.existing===true || (selectedRows && selectedRows.every(y=>x.rowId!==y.rowId)));
            const selectedRowIds = selectedRows && selectedRows.length > 0 ? [...selectedRows].filter(x=>x.existing===true)?.map((x) =>{ return  x.tariffCustCommentID}) : []
            if(selectedRowIds.length > 0) {
                this.deleteSectionAlert(selectedRowIds, sectionName)
            }
        }
    }

    deleteSectionAlert = async(requestBody, section) => {
        if(requestBody.length > 0) {
            console.log(requestBody);
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
            () => this.props.customerMaintenanceDataStore.deleteSelectedRows(requestBody, section),
            'Delete',
            'Are you sure you want to delete the selected record/s?'
        );
        } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
            'confirmPopup'
        );
        }
    }
  
    render(): React.ReactNode {
        const { customerMaintenanceDataStore } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className='AddCustomerMaintenanceBox'>
                            <div className='screenHeader'>
                            <h2 className='screen_title'> Customer Details </h2>
                            </div>
                            <Prompt when={customerMaintenanceDataStore.unsavedChanges} message={'You have unsaved changes, Are you sure you want to leave?'}/>
                            <div className='selectContainer'>
                                <Row style={{marginBottom: '10px'}}>
                                    <Col xs={8}>
                                        <Row>
                                            <Col>
                                            <label className="standard_label_style">Related Tariff Project</label>
                                            {/* <ReactSelect
                                                className='search_text_field'
                                                values={customerMaintenanceDataStore.formDataAdd.selectedTariffProjectForAddEdit ? {label: customerMaintenanceDataStore.formDataAdd.selectedTariffProjectForAddEdit, value: customerMaintenanceDataStore.formDataAdd.selectedTariffProjectForAddEdit} : {label: 'Select', value:  'Select'}}
                                                placeholder={'Select'}
                                                options={[{value:'Select', label:'Select'}]}
                                                onChange={(e: any) => { customerMaintenanceDataStore.changeFormDataAdd(e.value, 'selectedTariffProjectForAddEdit')}}
                                                isMulti={false}
                                                isSearchable={true}
                                                disabled={true}
                                            /> */}
                                            <input
                                                type="text"
                                                className="search_text_field input"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'selectedTariffProjectForAddEdit')}}
                                                value={customerMaintenanceDataStore.formDataAdd.selectedTariffProjectForAddEdit}
                                                readOnly={true}
                                            />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                            <label className="standard_label_style">Customer Search</label>
                                            <input
                                                type="text"
                                                className="search_text_field input"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'customerNameSearch')}}
                                                value={customerMaintenanceDataStore.formDataAdd.customerNameSearch}
                                            />
                                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.checkCustomerName()}}>
                                                Check
                                            </CustomButton>
                                            </Col>
                                        </Row>
                                        {customerMaintenanceDataStore.disableCustomerName && !customerMaintenanceDataStore.formDataAdd.customerId && <Row>
                                        <Col>
                                            <label style ={{color : 'red', fontSize: '12px', marginLeft: '15px', width:'60%'}}>* Please verify if customer already exist in the system. Once search is performed this field will be enabled.</label>
                                        </Col>
                                        </Row>}
                                        <Row>
                                            <Col>
                                            <label className="standard_label_style">Customer Name</label>
                                            <input
                                                type="text"
                                                className="search_text_field input"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'customerName')}}
                                                value={customerMaintenanceDataStore.formDataAdd.customerName}
                                                readOnly={customerMaintenanceDataStore.disableCustomerName}
                                            />
                                            </Col>
                                            <Col>
                                            <label className="standard_label_style">Salesforce ID</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                value={customerMaintenanceDataStore.formDataAdd.salesForceID}
                                                readOnly={true}
                                            />
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:'7.5px'}}>
                                            <Col xs={6}>
                                            <label className="standard_label_style">Customer Code</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'customerCode')}}
                                                value={customerMaintenanceDataStore.formDataAdd.customerCode}
                                                readOnly={true}
                                            />
                                            </Col>
                                            <Col xs={6}>
                                            <label className="standard_label_style">Enom Code</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'enomCode')}}
                                                value={customerMaintenanceDataStore.formDataAdd.enomCode}
                                                readOnly={true}
                                            />
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop:'7.5px'}}>
                                            {/* <Col>
                                            <label className="standard_label_style standard_label_style2">FEIN</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'fein')}}
                                                value={customerMaintenanceDataStore.formDataAdd.fein}
                                                readOnly={customerMaintenanceDataStore.disablecustomerDetails}
                                            />
                                            </Col> */}
                                            <Col>
                                            <label className="standard_label_style standard_label_style2">Prepayment Required</label>
                                            <input
                                                type="checkbox"
                                                style={{width: '35px'}}
                                                // className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(!customerMaintenanceDataStore.formDataAdd.prepaymentRequiredInd, 'prepaymentRequiredInd')}}
                                                checked={customerMaintenanceDataStore.formDataAdd.prepaymentRequiredInd}
                                                disabled={customerMaintenanceDataStore.disablecustomerDetails}
                                            />
                                            </Col>
                                            {/* <Col>
                                            <label className="standard_label_style standard_label_style2">SAP Account</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'sapAccount')}}
                                                value={customerMaintenanceDataStore.formDataAdd.sapAccount}
                                            />
                                            </Col> */}
                                             <Col>
                                            <label className="standard_label_style standard_label_style2">Affiliated with other shippers</label>
                                            <input
                                                type="checkbox"
                                                style={{width: '35px'}}
                                                // className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(!customerMaintenanceDataStore.formDataAdd.shipperAffiliatedInd, 'shipperAffiliatedInd')}}
                                                checked={customerMaintenanceDataStore.formDataAdd.shipperAffiliatedInd}
                                                disabled={customerMaintenanceDataStore.disablecustomerDetails}
                                            />
                                            </Col>
                                        </Row>
                                        {/* <Row style={{marginTop:'7.5px'}}>
                                            <Col>
                                            <label className="standard_label_style standard_label_style2">Foreign entity</label>
                                            <input
                                                type="checkbox"
                                                style={{width: '35px'}}
                                                // className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(!customerMaintenanceDataStore.formDataAdd.foreignEntityInd, 'foreignEntityInd')}}
                                                checked={customerMaintenanceDataStore.formDataAdd.foreignEntityInd}
                                                disabled={customerMaintenanceDataStore.disablecustomerDetails}
                                            />
                                            </Col>
                                           
                                        </Row> */}
                                        <Row style={{marginTop:'7.5px'}}>
                                            <Col>
                                            <label className="standard_label_style standard_label_style2">Related Party</label>
                                            <input
                                                type="checkbox"
                                                style={{width: '35px'}}
                                                // className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(!customerMaintenanceDataStore.formDataAdd.relatedPartyInd, 'relatedPartyInd')}}
                                                checked={customerMaintenanceDataStore.formDataAdd.relatedPartyInd}
                                                disabled={customerMaintenanceDataStore.disablecustomerDetails}
                                            />
                                            </Col>
                                            {/* <Col>
                                            <label className="standard_label_style standard_label_style2">Performance Risk</label>
                                            <input
                                                type="checkbox"
                                                style={{width: '35px'}}
                                                // className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(!customerMaintenanceDataStore.formDataAdd.performanceRiskInd, 'performanceRiskInd')}}
                                                checked={customerMaintenanceDataStore.formDataAdd.performanceRiskInd}
                                                disabled={customerMaintenanceDataStore.disablecustomerDetails}
                                            />
                                            </Col> */}
                                            {/* <Col>
                                            <label className="standard_label_style standard_label_style2">SAP Account</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'sapAccount')}}
                                                value={customerMaintenanceDataStore.formDataAdd.sapAccount}
                                            />
                                            </Col> */}
                                        </Row>
                                        {/* <Row style={{marginTop:'7.5px'}}>
                                            <Col>
                                            <label className="standard_label_style standard_label_style2">637 No</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'no637')}}
                                                value={customerMaintenanceDataStore.formDataAdd.no637}
                                            />
                                            </Col>
                                            <Col>
                                            <label className="standard_label_style standard_label_style2">SAP IC Account</label>
                                            <input
                                                type="text"
                                                className="search_text_field input2"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'sapICAccount')}}
                                                value={customerMaintenanceDataStore.formDataAdd.sapICAccount}
                                            />
                                            </Col>
                                        </Row> */}
                                        <Row style={{marginTop:'7.5px'}}>
                                            <Col>
                                            <label className="standard_label_style">Comments</label>
                                            <input
                                                type="text"
                                                className="search_text_field input"
                                                onChange={(e)=>{customerMaintenanceDataStore.changeFormDataAdd(e.target.value, 'comments')}}
                                                value={customerMaintenanceDataStore.formDataAdd.comments}
                                            />
                                            </Col>
                                        </Row>
                                        <Row style={{marginTop: '15px'}}>
                                            <Col>
                                            <label className="standard_label_style">Updated By</label>
                                            <input type="text" 
                                            value={customerMaintenanceDataStore.formDataAdd.updatedBy || customerMaintenanceDataStore.formDataAdd.createUser || customerMaintenanceDataStore.username}
                                            className="search_text_field"
                                            readOnly={true}
                                            />
                                            </Col>
                                            <Col>
                                            <label className="standard_label_style">Updated Date</label>
                                            <input type="text" 
                                            value={customerMaintenanceDataStore.formDataAdd.updateDate? moment(customerMaintenanceDataStore.formDataAdd.updateDate).format(AppConstant.DATE_TIME_FORMAT) : customerMaintenanceDataStore.formDataAdd.createDate ? 
                                                moment(customerMaintenanceDataStore.formDataAdd.createDate).format(AppConstant.DATE_TIME_FORMAT) :  moment().format(AppConstant.DATE_TIME_FORMAT)}
                                            className="search_text_field"
                                            readOnly={true}
                                            />
                                            </Col><Col></Col>
                                        </Row>
                                    </Col>
                                    <Col xs={4}>
                                        <div style={{border: '1px solid black', marginRight: '5px'}}>  
                                            <AgGridComponent gridConfig={this.getGridConfig('Customer Maintenance')}/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <Row>
                            <div className='rightFlex'>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.addLinkerData()}} disabled = {customerMaintenanceDataStore.isSaveDisabled()}>
                                Add Base System
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.onClearRow('Linker')}} disabled = {customerMaintenanceDataStore.isSaveDisabled()}>
                                Clear
                                </CustomButton>
                            </div>`
                            </Row>
                            <div style={{display:'inline-flex', width: '100%'}}>
                                <div className="rotatedText">
                                Linker
                                </div>
                                <div style={{'width':'100%'}}>
                                <AgGridComponent gridConfig={this.getGridConfigPipelines('Customer Maintenance Add Linker')}/>
                                </div>
                            </div> 
                            <div className='internalHeader'>
                                <h6>Address/Contact</h6>
                            </div>
                            <Row>
                            <div className='rightFlex'>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.addAddressData()}} disabled = {customerMaintenanceDataStore.isSaveDisabled()}>
                                Add Address/Contact
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.onClearRow('Address')}} disabled = {customerMaintenanceDataStore.isSaveDisabled()}>
                                Delete Address
                                </CustomButton>
                            </div>`
                            </Row>
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfigAddress('Address')}/>
                            </div>
                            <div className='internalHeader'>
                                <h6>Additional Email/Phone Number</h6>
                            </div>
                            <Row>
                            <div className='rightFlex'>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.addAddContactData()}} disabled = {customerMaintenanceDataStore.isSaveDisabled()}>
                                Add Phone No
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.onClearRow('AddContact')}} disabled = {customerMaintenanceDataStore.isSaveDisabled()}>
                                Delete Contact
                                </CustomButton>
                            </div>`
                            </Row>
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfigAdditionalContact('Additional Contact')}/>
                            </div>
                           <div className='internalHeader'>
                                <h6>Credits</h6>
                            </div>
                            {/* <Row>
                            <div className='rightFlex'>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.addCreditsData()}} disabled = {true}>
                                Add Credits
                                </CustomButton>
                            </div>`
                            </Row> */}
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfigCredits('Credits')}/>
                            </div>
                            {/* <div className='internalHeader'>
                                <h6>Other Information</h6>
                            </div>
                            <Row>
                            <div className='rightFlex'>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.addOtherData()}}>
                                Add Other Information
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.onClearRow('OtherData')}}>
                                Delete Other Info
                                </CustomButton>
                            </div>`
                            </Row>
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfigOtherData('Other Information')}/>
                            </div> */}
                            <div className='internalHeader'>
                                <h6>Other Information/Comments</h6>
                            </div>
                            {/* <Row>
                            <div className='rightFlex'>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.addCommentsData()}}>
                                Add Other Information/Comments
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.onClearRow('AddComments')}}>
                                Delete Comments
                                </CustomButton>
                            </div>`
                            </Row> */}
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfigComments('Other Comments')}/>
                            </div>
                            <Row>
                            <div className='rightFlex'>
                               
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.saveCustomer()}} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.CustomerMaintenance)}>
                                    Save
                                </CustomButton>
                                {/* <CustomButton type={CustomButtonType.Submit} disabled={!customerMaintenanceDataStore.formDataAdd.customerId || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate)} onClick={()=>{customerMaintenanceDataStore.deactivate(customerMaintenanceDataStore.formDataAdd.customerId)}}>
                                    Deactivate
                                </CustomButton> */}
                                <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Test')}>
                                    Push to Test
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={() =>  this.pushToVmacs('Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Prod')}>
                                    Push to Prod
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={()=>{customerMaintenanceDataStore.setAddCM(); history.push('/CustomerMaintenance')}}>
                                Cancel
                                </CustomButton>
                            </div>
                           </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
    );
}

private async pushToVmacs(env) {
    const { customerMaintenanceDataStore } = this.props;
    // const requestBody: any = customerMaintenanceDataStore.relationshipGridData.filter(a => customerMaintenanceDataStore.getUpdatedRowIDsRelationshipGrid().includes(a.relConnectionPointID.toString()));
    // const updatedRowIDs = customerMaintenanceDataStore.remarksGridData.filter(a => customerMaintenanceDataStore.getUpdatedRowIDs().includes(a.cpCommentID.toString()));
    if(((customerMaintenanceDataStore.formDataAdd.customerName !== '' && customerMaintenanceDataStore.formDataAdd.customerId)  && (customerMaintenanceDataStore.formDataAdd.syncStatus && customerMaintenanceDataStore.formDataAdd.syncStatus !== SyncStatus[2]))) {
    //   if(updatedRowIDs.length > 0 || customerMaintenanceDataStore.isFormUpdated || requestBody.length > 0) {
    //     const confirmService = new ConfirmService();
    //     confirmService.showConfirmDialog(
    //       () => customerMaintenanceDataStore.pushSelectedRowsToVmacs(env),
    //       'Active',
    //       `All the unsaved changes will be lost. Are you sure you want to push the selected data to the ${env} Environment?`
    //   );
    //   } else {
        if(((customerMaintenanceDataStore.formDataAdd.syncStatus && customerMaintenanceDataStore.formDataAdd.syncStatus=== SyncStatus[0])) && env === 'Prod' && customerMaintenanceDataStore.formDataAdd.customerName !== '' ) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'Customer Details has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        } else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => customerMaintenanceDataStore.pushSelectedRowsToVmacs(env),
              'Active',
              `Are you sure you want to push the selected data to the ${env} Environment?`
          );
        }      
    //   }
    } else {
      const secondaryText = 'Please select only the active and unsynced Customer to perform the operation';
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: secondaryText} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  private pushButtonDisabled(env) {
    const { customerMaintenanceDataStore } = this.props;
    if(env === 'Test') {
      return  !customerMaintenanceDataStore.formDataAdd || ((!customerMaintenanceDataStore.formDataAdd.customerId || customerMaintenanceDataStore.formDataAdd.customerId === 0) || (customerMaintenanceDataStore.formDataAdd.syncStatus && (customerMaintenanceDataStore.formDataAdd.syncStatus === SyncStatus[1] || customerMaintenanceDataStore.formDataAdd.syncStatus === SyncStatus[2]))); 
    }
      return  !customerMaintenanceDataStore.formDataAdd || ((!customerMaintenanceDataStore.formDataAdd.customerId || customerMaintenanceDataStore.formDataAdd.customerId === 0) || ( customerMaintenanceDataStore.formDataAdd.syncStatus && customerMaintenanceDataStore.formDataAdd.syncStatus === SyncStatus[2]));
  }
}
