import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { SystemFeeMaintenanceDataStore } from '../stores/system-fee-maintenance-data-store';
import {
    GridOptions,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import './system-fee-maintenance.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { systemFeeMaintenanceDataStore } from '../stores';
import DatePicker from 'react-datepicker';
import { AddEditSystemFeeMaintenance } from './AddEditSystemFeeMaintenance';
import { ReactSelect } from 'common/components/select/select';
import { localStorageService } from 'shared/services/local-storage-service';


/* eslint-disable */
interface SystemFeeMaintenanceContainerProps {
    systemFeeMaintenanceDataStore: SystemFeeMaintenanceDataStore;
}

interface SystemFeeMaintenanceContainerState {
    search: string;
}

@inject('systemFeeMaintenanceDataStore')
@observer
export class SystemFeeMaintenanceContainer extends Component<SystemFeeMaintenanceContainerProps, SystemFeeMaintenanceContainerState> {
    constructor(props: SystemFeeMaintenanceContainerProps | Readonly<SystemFeeMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('PMC Tariff Management')
    }
    
    componentDidMount() {
      const {systemFeeMaintenanceDataStore} = this.props;
      history.push('/SystemFeeMaintenance');
      systemFeeMaintenanceDataStore.init();
      systemFeeMaintenanceDataStore.agGridService.refreshGrid();
    }

    async componentDidUpdate(){
        const filters = await localStorageService.get('System Fee Maintenance_filter');
        await this.props.systemFeeMaintenanceDataStore.agGridService.setAllFilters(filters);
        const columnVisible = localStorageService.get('System Fee Maintenance_' + this.props.systemFeeMaintenanceDataStore.accountStore.userName);
        this.props.systemFeeMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
    }

    onSelectSystemGroup = (e) => {
        systemFeeMaintenanceDataStore.setSystemGroup(e);
    };

    onSelectEffectiveStartDate = (e) => {
        systemFeeMaintenanceDataStore.setEffectiveStartDate(e);
    };

    onSelectEffectiveEndDate = (e) => {
        systemFeeMaintenanceDataStore.setEffectiveEndDate(e);
    };

    onAddNewClick = async() => {
      systemFeeMaintenanceDataStore.onAddNewSystemFee();
    }

    handleCloseEndDateConfirmationModal(){
      systemFeeMaintenanceDataStore.handleCloseEndDateConfirmationModal();
    }

    setEndDateOfPrevSystemFee(){
      systemFeeMaintenanceDataStore.setSystemFeeEndDate();
    }

    render(): React.ReactNode {
      const { systemFeeMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderSystemFeeMaintenanceScreen()}
        </Container>
      );
    }

    renderSystemFeeMaintenanceScreen() : React.ReactNode {
      const { systemFeeMaintenanceDataStore } = this.props;
      return (
        <div>
        <Row>
            <Col>
              <div className='systemFeeMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>System Fee</h2>
                </div>
                <div className='selectContainer flex'>
                  <div className='settingSelectionDiv'>
                    <Row>
                      <Col className='pipelineSys'>
                    <label className="standard_label_style pipelineSysLabel">Pipeline System</label>
                      <ReactSelect
                        className='expanded_select'
                        values={systemFeeMaintenanceDataStore.selectedSystemGroup}
                        placeholder="Select"
                        options={[{value:'All', label:'All'},...systemFeeMaintenanceDataStore.systemGroupDropdownOptions]}
                        onChange={this.onSelectSystemGroup}
                        isMulti={false}
                        isSearchable={true}
                      />
                      </Col>
                      <Col className='dateCol'>
                        <label className="standard_label_style_Date dateLabelGrid effStartDate">Effective Start Date</label>
                        <DatePicker
                            className='datepicker__holder'
                            selected={systemFeeMaintenanceDataStore.selectedEffectiveStartDate}
                            onChange={(e) => {
                                this.onSelectEffectiveStartDate(e);
                            }}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                      </Col>
                      <Col className='dateCol'>
                        <label className="standard_label_style_Date dateLabelGrid " >Effective End Date</label>
                        <DatePicker
                            className='datepicker__holder'
                            selected={systemFeeMaintenanceDataStore.selectedEffectiveEndDate}
                            onChange={(e) => {
                                this.onSelectEffectiveEndDate(e);
                            }}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                        />
                      </Col>
                      </Row>
                  </div>
                  <div className='searchButtonDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={()=> systemFeeMaintenanceDataStore.onSearchClick()} disabled= {false}>
                        Search
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={()=> systemFeeMaintenanceDataStore.onClearSearch()} disabled= {false}>
                        Clear
                    </CustomButton>
                    <div className='otherHeaderButtonsDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={()=> this.onAddNewClick()} > 
                        Add System Fee
                      </CustomButton>
                    </div>
                  </div>
                </div>
              </div>
              {this.props.systemFeeMaintenanceDataStore.showLoader == true &&
                <div className = 'alert-overlay'> 
                  <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                </div> 
              }
              <AgGridComponent gridConfig={this.getGridConfig('System Fee Maintenance')} />
            </Col>
          </Row>
          {systemFeeMaintenanceDataStore.isShowModal && <AddEditSystemFeeMaintenance systemFeeMaintenanceDataStore={systemFeeMaintenanceDataStore}  />
          }

          <Modal className='modal-lg' show={systemFeeMaintenanceDataStore.isShowEndDateConfirmationModal} onHide={this.handleCloseEndDateConfirmationModal}>
            <Modal.Header className='screenHeader' closeButton>
              <Modal.Title className='screen_title'>Set End Date Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>There is already one System Fee exists without effective end date value for same System Pipeline. To continue with this effective start date, you need to set effective end date for existing system fee. Do you want to proceed?</div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseEndDateConfirmationModal}>
                No
              </Button>
              <Button variant="primary" onClick={this.setEndDateOfPrevSystemFee}>
                Yes
              </Button>
          </Modal.Footer>
          </Modal>
          </div>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          systemFeeMaintenanceDataStore: { systemFeeMaintenanceData, agGridService }
      } = this.props;
      const { systemFeeMaintenanceDataStore } = this.props;
      const columnDefs = systemFeeMaintenanceDataStore.getColDef();
      const rowData = systemFeeMaintenanceData;
      const systemFeeMaintenanceGridParams: any = {
        rowData,
        columnDefs
      };
      return agGridService.getGridConfig(systemFeeMaintenanceGridParams, gridId);
    }
}
