import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { filingCarrierColDef } from '../components/filing-carrier-col-def';
import moment from 'moment';
import Path from '../../../../Path';
import axios from 'axios';
import { FilingCarrierMaintenanceDataService } from '../services/filing-carrier-maintenance-data-service';
import { FilingCarrierMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { addEditFilingCarrierMaintenanceDataStore, filingCarrierMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class AddEditFilingCarrierMaintenanceStore { 
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userName = '';
    @observable selectedFilingCarrierName = { value: 'Select', label: 'Select' };
    @observable selectedFilingCarrierCode = '';
    @observable addOrEditLinkerGridData: any = [];
    @observable backupAddOrEditLinkerGridData: any = [];
    @observable editableFilingCarrierData: any = {};
    @observable backupEditableFilingCarrierData: any = {};
    @observable isEditingFilingCarrier = false;
    @observable selectedFilingCarrierId;
    @observable selectedWorkflowId = 0;
    @observable selectedProjectName = '';
    baseSystemData: any[] = [];
    pipelineSystemData: any[] = [];
    
    @observable pipelineSystemDropdownOption: any[] = [];
    
    @observable baseSystemDropdownOption: any[] = [];
    @observable pipelineOwnerDropdownOptions: any[] = [];
    pipelineOwnerData: any[] = [];
    navigateToScreen: any = null;
    @observable unsavedChanges = false;
    
    constructor(
        public filingCarrierMaintenanceDataService: FilingCarrierMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditLinker: AgGridService,
    ) {
        makeAutoObservable(this);
    }

    async init(): Promise<void> {
        this.userName = this.accountStore.displayName;
        this.unsavedChanges = false;
        if (!this.isEditingFilingCarrier) {
            await this.getTariffPipelineOwner();
            // this.getPipelineSystemDropdown();
        }
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffPipelineOwner(): Promise<void> {
        const pipelineOwnerDropdownOptions = await this.filingCarrierMaintenanceDataService.getFilingCarrierEntity();
        this.pipelineOwnerData = pipelineOwnerDropdownOptions;
        if(!this.pipelineOwnerData || this.pipelineOwnerData.length <= 0) {
            runInAction(() => {
                this.showLoader = false;
                this.pipelineOwnerDropdownOptions = [];
            });
            errorHandler('Everything is set up. No Entity found to create new carrier.') 
          return; 
        }
        const a = pipelineOwnerDropdownOptions.map(x => {return { 'value': x.ownerEntityName, 'label': x.ownerEntityName}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        runInAction(() => {
            this.showLoader = false;
            this.pipelineOwnerDropdownOptions = sortedValues;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineSystemDropdown(): Promise<void> {
        const customerID = this.pipelineOwnerData.length > 0  &&  (this.selectedFilingCarrierName.label &&  this.selectedFilingCarrierName.label !== 'Select')? this.pipelineOwnerData.find((i) => i.ownerEntityName === this.selectedFilingCarrierName.label).ownerEntityID || 0 : 0;
        const pipelineSystemDropdownOptions = await this.filingCarrierMaintenanceDataService.getFilingCarrierRelPipelinesByCustomerID(customerID);
        const result = Object.values(
            pipelineSystemDropdownOptions.reduce((acc, obj) => ({ ...acc, [obj.pipelineID]: obj }), {})
        );
        
        const a = result.map((x:any) => {return { 'pipelineName': x.plName, 'pipelineID': x.pipelineID, 'entityID': x.entityID, 'plName' : x.plName}});
        const sortedValues =  _.orderBy(a, [x=>x.pipelineName], ['asc']);
        this.pipelineSystemData = sortedValues;
        runInAction(() => {
            this.showLoader = false;
            // this.pipelineSystemDropdownOption = sortedValues;
            this.setAddOrEditLinkerGridData(sortedValues);
            this.setBackUpAddOrEditLinkerGridData(sortedValues);
        });
    }

    @action
    async resetAddFilingCarrierMaintenanceGridData(): Promise<void> {
        this.addOrEditLinkerGridData = [];
        this.backupAddOrEditLinkerGridData = [];
        const addOrEditLinkerGridData: any = [];

        this.baseSystemData.map((item) => {
            item.isSelected = false;
            addOrEditLinkerGridData.push(item);
        });
        const sortedArr = _.orderBy(addOrEditLinkerGridData, [item=>item.pipelineName], ['asc']);
        this.setAddOrEditLinkerGridData(sortedArr);
        this.setBackUpAddOrEditLinkerGridData(sortedArr);
    }

    @action
    headerCheckBoxSelection = (event) => {
        const checked = event.target.checked;
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.pipelineName && filters.pipelineName.values.includes(item.pipelineName)))) : this.addOrEditLinkerGridData;
        // const data = filteredData.map((item) => { item.isSelected = checked; return (item)})
       
            if(checked) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () =>  {  filteredData.forEach((item) => {this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                    })},
                    'Multiple Pipeline',
                    'Do you want to link Tariff Carrier to multiple pipelines?'
                );
                
            } else {
                filteredData.forEach((item) => {
                    this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                });
            }
    };

    @action
    checkIfHeaderCheckboxShouldbeChecked = (params) => {
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.pipelineName && filters.pipelineName.values.includes(item.pipelineName)))) : this.addOrEditLinkerGridData;
        return filteredData.some((item) => item.isSelected === false || !item.isSelected)
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected;
        if((this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length > 0 && this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length < 2 ) && checked) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () =>  { this.setValueInChangeMap(row, 'isSelected', initValue, checked);
                params.data.isSelected = checked;
                this.updateRowforAddOrUpdateGrid(params.data);
                },
                'Multiple Pipeline',
                'Do you want to link Tariff Carrier to multiple pipelines?'
            );
        } else {
            this.setValueInChangeMap(row, 'isSelected', initValue, checked);
            params.data.isSelected = checked;
            this.updateRowforAddOrUpdateGrid(params.data);
        }
    };

    @action
    globalEffectiveDateSelection = (effectiveDate) => {
            const data = this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).map((item) => { item.effectiveDate = effectiveDate; return (item)})
            data.map((item) => {
                this.updateRowforAddOrUpdateGrid(item);
            }) 
    };

    @action
   setAddOrEditLinkerGridData(addOrEditLinkerGridData: any): void {
       this.addOrEditLinkerGridData = addOrEditLinkerGridData
   }

   setBackUpAddOrEditLinkerGridData(addOrEditLinkerGridData: any): void {
       this.backupAddOrEditLinkerGridData = addOrEditLinkerGridData
   }

    @computed
    get AddOrEditLinkerGridData() {
        return toJS(this.addOrEditLinkerGridData);
    }

    getColDef() {
        return filingCarrierColDef;
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditLinkerGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditLinkerGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsTariffCarrierDetailsGrid(this.addOrEditLinkerGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsTariffCarrierDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.isSelected = updatedItem.isSelected;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getFilingCarriersData(): Promise<any> {
        const filingCarrierDropdownOptions = await this.filingCarrierMaintenanceDataService.getTariffCarrier();
        return filingCarrierDropdownOptions || [];
    }

    async isUniqueFilingCode(value) {
        if(value && value !== '') {
            if(filingCarrierMaintenanceDataStore.filingCarrierDropdownData && filingCarrierMaintenanceDataStore.filingCarrierDropdownData.length > 0) {
                return this.isEditingFilingCarrier  ?  filingCarrierMaintenanceDataStore.filingCarrierDropdownData.filter((i) => i.filingCode === value && this.editableFilingCarrierData.entityID !== i.entityID).length <= 0 : filingCarrierMaintenanceDataStore.filingCarrierDropdownData.filter((i) => i.filingCode === value).length <= 0;
            } else {
                const filingCarrierDropdownData = await this.getFilingCarriersData();
                return this.isEditingFilingCarrier  ?  filingCarrierDropdownData.filter((i) => i.filingCode === value && this.editableFilingCarrierData.entityID !== i.entityID).length <= 0 : filingCarrierDropdownData.filter((i) => i.filingCode === value).length <= 0;
            }
        } else {
            return true
        }
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async saveFilingCarrier(): Promise<void> {
        const updatedRowIDs = this.addOrEditLinkerGridData.filter(a => a.isSelected === true);
        const filingCarrierRelatedPipelines: any = [];
        let requestBody: any = {};
        const localValidatingErrorBody: any = [];
        if (this.selectedFilingCarrierName.label && this.selectedFilingCarrierName.label !== 'Select') {
            updatedRowIDs.length > 0 && updatedRowIDs.forEach((item) => {
                    item.tariffFilingCarrierRelSystemID = item.tariffFilingCarrierRelSystemID > 0 ? item.tariffFilingCarrierRelSystemID : 0;
                    item.tariffFilingCarrierID = item.tariffFilingCarrierID > 0 ? item.tariffFilingCarrierID: 0;
                    item.createDate = item.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.createUser = item.createUser || this.userName,
                    item.updateDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.updateUser = this.userName,
                    item.pipelineID = item.pipelineID && item.pipelineID > 0 ? item.pipelineID : 0;
                    item.pipelineName = item.pipelineName ? item.pipelineName : '';
                    item.action = this.isEditingFilingCarrier ? this.editableFilingCarrierData.action : 'A';
                    filingCarrierRelatedPipelines.push(item);
                });
            } else {
            const message = 'Please select Filing Carrier as mandatory field.';
            if (!localValidatingErrorBody.includes(message)) {
                localValidatingErrorBody.push(message);
            }
        }
       const isUniqueFilingCode = await this.isUniqueFilingCode(this.selectedFilingCarrierCode);
       if(!isUniqueFilingCode) {
        const message = 'Filing Carrier Code should be unique.';
        if (!localValidatingErrorBody.includes(message)) {
            localValidatingErrorBody.push(message);
        }
       }
        if (localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if (updatedRowIDs.length === filingCarrierRelatedPipelines.length) {
            const filingCarrierRelatedPipelinesUpdated = filingCarrierRelatedPipelines.length > 0 ? filingCarrierRelatedPipelines.map(({ isSelected, pipelineId, ...keepAttrs }) => keepAttrs) : [];
            const custId = this.pipelineOwnerData.length > 0  &&  (this.selectedFilingCarrierName.label &&  this.selectedFilingCarrierName.label !== 'Select')? this.pipelineOwnerData.find((i) => i.ownerEntityName === this.selectedFilingCarrierName.label).ownerEntityID || 0 : 0;
            requestBody = {
                'entityID': this.isEditingFilingCarrier ? this.editableFilingCarrierData.entityID : 0,
                // 'extFilingCarrierID': this.isEditingFilingCarrier ? this.editableFilingCarrierData.extFilingCarrierID : 0,
                'name': this.selectedFilingCarrierName.label,
                'statusText': this.isEditingFilingCarrier ? this.editableFilingCarrierData.statusText : 'Active',
                'createDate': this.isEditingFilingCarrier ? this.editableFilingCarrierData.createDate : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                'createUser': this.isEditingFilingCarrier ? this.editableFilingCarrierData.createUser : this.userName,
                'updateDate': this.isEditingFilingCarrier ? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'): null,
                'updateUser': this.isEditingFilingCarrier ? this.userName : null,
                'syncStatus': this.isEditingFilingCarrier ? this.editableFilingCarrierData.syncStatus : SyncStatus[0],
                'action': this.isEditingFilingCarrier ? this.editableFilingCarrierData.action : 'A',
                'filingCode':  this.selectedFilingCarrierCode,
                'customerID': this.isEditingFilingCarrier ? this.editableFilingCarrierData.customerID : custId,
                'workflowId': 0,
                'projectName': null,
                'pipelineIds':  this.isEditingFilingCarrier ? this.editableFilingCarrierData.pipelineIds : this.addOrEditLinkerGridData.map((x) => x.pipelineID)
            }
            const response: any = await this.filingCarrierMaintenanceDataService.saveTariffCarrier(requestBody);
            if (response && response.entityID) {
                this.uiService.toastService.success(FilingCarrierMaintenanceMessages.SAVE);
                if (this.editableFilingCarrierData && !_.isEqual(this.editableFilingCarrierData, this.backupEditableFilingCarrierData)) {
                    response.syncStatus = SyncStatus[0];
                }

                // const a = this.addOrEditLinkerGridData;
                // const filteredArray: any = [];
                // if (response.pipelineIds.length > 0) {
                //     a.map(x => {
                //         response.pipelineIds.map((item) => {
                //             if (x.pipelineID == item) {
                //                 x.pipelineName = x.plName;
                //                 filteredArray.push(x);
                //             }
                //         })
                //     })
                // }
                this.unsavedChanges = false;
                this.editableFilingCarrierData = response;
                this.backupEditableFilingCarrierData = response;
                this.selectedFilingCarrierId = response.entityID;
                // const sortedArr = _.orderBy(filteredArray, [item=>item.pipelineName], ['asc']);
                // this.setAddOrEditLinkerGridData(sortedArr);
            } else {
                this.uiService.toastService.error(FilingCarrierMaintenanceMessages.FAILED_SUBMIT)
            }
        }
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.error) {
            errorHandler(FilingCarrierMaintenanceMessages.SYNC_FAILED_SUBMIT);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const carrierId = this.editableFilingCarrierData ?  this.editableFilingCarrierData.entityID.toString() : null;
        const requestBody: any =  {
            workflowid: this.selectedWorkflowId && this.selectedWorkflowId != 0 ? this.selectedWorkflowId : 0,
            env: env,
            id: carrierId
            }
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response : any = await this.filingCarrierMaintenanceDataService.pushToVmacs(requestBody);
            if(response == true) {
                this.uiService.toastService.success(FilingCarrierMaintenanceMessages.SYNC);
                this.editableFilingCarrierData.syncStatus = env === 'Push TariffCarrier to Vmacs Test' ? SyncStatus[1] : SyncStatus[2]
                this.addOrEditLinkerGridData.forEach((x) => {
                    if(x.isSelected === true) {
                        x.syncStatus = env === 'Push TariffCarrier to Vmacs Test' ? SyncStatus[1] : SyncStatus[2]
                    }
                })
            } else {
                this.uiService.toastService.error(FilingCarrierMaintenanceMessages.SYNC_FAILED_SUBMIT);
            }
        }
    }    

    @action
    onCloseFilingCarrierDetails() {
        // if(this.navigateToScreen) {
        //     history.push(this.navigateToScreen, {workflowId : this.selectedWorkflowId, tariffName: this.navigateState?.tariffLog, tariffNumber: this.navigateState?.tariffLog, baseTariff: this.navigateState?.baseTariff, tariffLogId : this.navigateState?.tariffLogId});
        //     dataStore.setShowUnsavedWarningAlert(false);
        // } else {
            history.push('/FilingCarrierMaintenance');  
        // }
    }

    @action
    resetFilingCarrierDetails() {
        this.isEditingFilingCarrier = false;
        this.selectedFilingCarrierId = '';
        this.selectedFilingCarrierName = { value: 'Select', label: 'Select' };
        this.selectedFilingCarrierCode = '';
        this.editableFilingCarrierData = {};
        this.backupEditableFilingCarrierData = {};
        this.addOrEditLinkerGridData = [];
        this.backupAddOrEditLinkerGridData = [];
        this.unsavedChanges = false;
        this.pipelineOwnerDropdownOptions = [];
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.FilingCarrierMaintenance) || addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData?.action === 'D' || (addEditFilingCarrierMaintenanceDataStore.isEditingFilingCarrier && !addEditFilingCarrierMaintenanceDataStore.accountStore.userRoles.includes('TariffAdmin'));
    };

    isDeleteDisabled = ()  => {
          return !this.editableFilingCarrierData  ||  (this.editableFilingCarrierData && !this.editableFilingCarrierData.entityID) || this.isActivateDeactivateDisabled()
    }
    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivateTariff);
    };

    @Loader
    @action
    @Catch(() =>  errorHandler(FilingCarrierMaintenanceMessages.FAILED_SUBMIT))
    async deactivateFilingCarriereData(): Promise<void> {
        const requestBody = { 
            entityID: this.editableFilingCarrierData.entityID,
            createUser: this.userName, 
        }
        await this.filingCarrierMaintenanceDataService.deactivateFilingCarriereData(requestBody).then((response) => {
            if(response && response.value) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                  () => {console.log('')},
                  ConfirmWithOK,
                  {secondaryText: response.value !== '' ? response.value : 'Record cannot be deleted because it is linked to a base system. Unlink Filing Carrier from base system.'} as ConfirmWithOKProps,
                  'confirmPopup'
                );
                return ;
            }
            this.unsavedChanges = false;
            dataStore.setShowUnsavedWarningAlert(false);
            this.onCloseFilingCarrierDetails();
        });
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(FilingCarrierMaintenanceMessages.FAILED_SUBMIT))
    async activateFilingCarriereData(): Promise<void> {
        const requestBody = { 
            entityID: this.editableFilingCarrierData.entityID,
            createUser: this.userName, 
        }
        await this.filingCarrierMaintenanceDataService.activateFilingCarriereData(requestBody).then((response) => {
            if(response && response.value) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                  () => {console.log('')},
                  ConfirmWithOK,
                  {secondaryText: response.value !== '' ? response.value : 'Record cannot be deleted because it is linked to a base system. Unlink Filing Carrier from base system.'} as ConfirmWithOKProps,
                  'confirmPopup'
                );
                return ;
            }
            this.unsavedChanges = false;
            dataStore.setShowUnsavedWarningAlert(false);
            this.onCloseFilingCarrierDetails();
        });
    }

}