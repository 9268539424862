import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class AgencyMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    async getAgenciesDataWithFilter(juridictionName, agencyCode, agencyName): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.url_id9}?jurisdiction=${juridictionName}&agency=${agencyName}&agencyCode=${agencyCode}`);
        return data;
    }

    async getTariffAgencies(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.url_id4);
        return data;
    }
    async getTariffStates(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id7);
        return data;
    }
    async getSettingNameOptions(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id3);
        return data;
    }  
    async getJurisdictionDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id8);
        return data;
    }
    async updateAgencyMaintenanceData(requestBody, addNewAgency): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.url_id9, requestBody, {});
        return data;
    }  
    async deleteAgencyMaintenanceData(requestBody): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.url_id9, requestBody, {});
        return data;
    }  
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsAgencyEndpoint, reqbody, {});
        return data;
    }  

    async updateStatusFromDelToActive(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.url_id9, reqbody, {});
        return data;
    }
}

export const agencyMaintenanceDataService = new AgencyMaintenanceDataService(restApiService);
