import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './add-pipeline.scss';
import {
    CellEditingStoppedEvent,
    ColDef
} from 'ag-grid-community';
import { AddPipelineMaintenanceDataStore } from '../stores/add-pipeline-maintenance-data-store';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker'
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Portal } from 'react-overlays';
import CustomDateEditor from './cell-renderers/custo-date-editor';
import { addPipelineMaintenanceDataStore } from '../stores';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';

/* eslint-disable */
interface PipelineMaintenanceContainerProps {
     addPipelineMaintenanceDataStore: AddPipelineMaintenanceDataStore;
}

interface PipelineMaintenanceContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowIdEntities: number
}

@inject('addPipelineMaintenanceDataStore')
@observer
export class AddPipelineMaintenanceContainer extends Component<PipelineMaintenanceContainerProps, PipelineMaintenanceContainerState> {

    constructor(props: PipelineMaintenanceContainerProps | Readonly<PipelineMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowIdEntities: 0
        };
        dataStore.setModuleName('AddPipelineMaintenance')
    }

    componentDidMount() {
      const {addPipelineMaintenanceDataStore} = this.props;
      addPipelineMaintenanceDataStore.init();
      if(!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)){
        history.push('/PipelineMaintenance');
      }
    }

    componentWillUnmount() {
        const {addPipelineMaintenanceDataStore} = this.props;
        addPipelineMaintenanceDataStore.reset();
    }

    CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");

        return <Portal container={el}>{children}</Portal>;
    };

    SimpleCellRenderer =  (props) => (
        <span
          style={{
            backgroundColor: props.node.group ? 'coral' : 'lightgreen',
            padding: 2,
          }}
        >
          Hi + {props.value}
        </span>
    );


    onChangeDate = (params, value, key) => {
        const {addPipelineMaintenanceDataStore} = this.props;
        if(key==='effectiveDate'||key==='endDate'){
            var index = addPipelineMaintenanceDataStore.ownershipData.findIndex(x=>x.rowId===params.data.rowId)
            addPipelineMaintenanceDataStore.setOwnershipData(this.dateToEpoch2(value), index, key);
        }else {
            var index = addPipelineMaintenanceDataStore.entitiesData.findIndex(x=>x.rowIdEntities===params.data.rowIdEntities)
            addPipelineMaintenanceDataStore.setEntitiesData(this.dateToEpoch2(value), index, key);
        }
    }

    pipelineMaintenanceGridColDef: ColDef[] = [
        {
            headerName: 'Select',
            field: 'select',
            checkboxSelection: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.ownersCreated ? true: false},
            cellRenderer: 'checkboxRenderer',
            width: 40,
            headerCheckboxSelection: false,
            headerCheckboxSelectionFilteredOnly: true,
            cellStyle: () => {return {padding: '10px'}},
            suppressSizeToFit: true
          },
          {
            headerName: 'Owners*',
            field: 'owner',
            tooltipField: 'owner',
            filter: true,
            sortable: true,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.ownersCreated},
            cellEditor: 'agSelectCellEditor',
            minWidth: 150,
            singleClickEdit: true,
            cellEditorParams: (params) => {
                    const data = this.props.addPipelineMaintenanceDataStore.addValuesInCellDropdownsOwners(params)?.map(x=>x.customerName);
                    return {
                        values: params.data?.owner? [params.data?.owner,...data as []] : data
                    };
            },
            cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.ownersCreated?  'cell-editable' : ''},
            flex: 1,
        },
        {
            headerName: 'Ownership*',
            field: 'ownership',
            tooltipField: 'ownership',
            filter: true,
            singleClickEdit: true,
            sortable: true,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.ownersCreated},
            cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.ownersCreated ?  'cell-editable' : ''},
            aggFunc: params => {
                let total = 0;
                params.values.forEach(value => total += Number(value)||0);
                return 'Total Ownership: ' +  (total * 100).toFixed(2) + '%';
            },
            valueSetter: (params) => {
                if(Number(params.newValue)>100){
                    params.data.ownership=1
                    return true 
                 }else if(Number(params.newValue)>1){
                    params.data.ownership=params.newValue/100
                    return true
                } else {
                    if(isNaN(params.newValue)){
                        return false
                    }
                    params.data.ownership=params.newValue
                    return true
                }
            },
            minWidth: 200,
            flex: 1
        },
        {
            headerName: 'Effective Date*',
            field: 'effectiveDate',
            editable: false,
            rowGroup: true,
            // comparator: (a, b) => {
            //     const dateA = new Date(a);
            //     const dateB = new Date(b);
            //     if(dateA===dateB) return 0;
            //     else if(dateA>dateB) return 1;
            //     return -1           
            // },
            // sort: 'asc',
            showRowGroup: true,
            cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.ownersCreated ?  'cell-editable' : ''},
            valueFormatter: ({ value, data }) => '',
            cellRenderer: (params) => {
                return (params.data ? (<DatePicker
                      popperClassName='ag-custom-component-popup'
                      selected={params.data.effectiveDate}
                      onChange={(value)=>{this.onChangeDate(params, value, 'effectiveDate')}}
                      popperContainer={this.CalendarContainer}
                      popperPlacement='top-start'
                      className='datePickerGridInput'
                      readOnly={this.props.addPipelineMaintenanceDataStore.ownersCreated}
                      />) : '')
            },
            minWidth: 130,
            flex: 1
        },
        {
            headerName: 'End Date',
            field: 'endDate',
            editable: false,
            cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.ownersCreated ?  'cell-editable' : ''},
            cellRenderer: (params) => {
                return (params.data &&  (<DatePicker
                      popperClassName='ag-custom-component-popup'
                      selected={params.value}
                      onChange={(value)=>{this.onChangeDate(params, value, 'endDate')}}
                      popperContainer={this.CalendarContainer}
                      popperPlacement='top-start'
                      readOnly={this.props.addPipelineMaintenanceDataStore.ownersCreated}
                      className='datePickerGridInput'
                      />)) || params.value
            },
            flex: 1,
            minWidth: 130
        },
        {
            headerName: 'Transfer to Data Warehouse*',
            field: 'transfer',
            tooltipField: 'transfer',
            cellEditor: 'agSelectCellEditor',
            cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.ownersCreated ?  'cell-editable' : ''},
            cellEditorParams: {
                values: ['Y', 'N']
            },
            filter: true,
            singleClickEdit: true,
            sortable: true,
            minWidth: 150,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.ownersCreated},
            flex: 1
        },
        {
            headerName: 'Distribute L/G To*',
            field: 'distributeLG',
            tooltipField: 'distributeLG',
            singleClickEdit: true,
            filter: true,
            sortable: true,
            cellEditor: 'agSelectCellEditor',
            minWidth: 150,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.ownersCreated},
            cellClass: (params) => {return this.props.addPipelineMaintenanceDataStore.isEditableSettingNameDistribute(params) && params.data && !this.props.addPipelineMaintenanceDataStore.ownersCreated?  'cell-editable' : ''},
            flex: 1
        },
  ];

  legalEntityGrid: ColDef[] = [
      {
        headerName: 'Select',
        field: 'select',
        checkboxSelection: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ? true: false},
//        checkboxSelection: true,
        cellRenderer: 'checkboxRenderer',
        width: 40,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        suppressSizeToFit: true,
        cellStyle: () => {return {padding: '10px'}}
      },
      {
        headerName: 'Start Cycle*',
        field: 'startCycle',
        editable: false,
        cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ?  'cell-editable' : ''},
        cellRenderer: (params) => {
            return (params.data &&  (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.data.startCycle}
                onChange={(value)=>{this.onChangeDate(params, value, 'startCycle')}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                className='datePickerGridInput'
                readOnly={this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated}
                />)) || params.value
        },
        sort: 'desc',
        minWidth: 130,
        flex: 1
    },
    {
        headerName: 'End Cycle',
        field: 'endCycle',
        editable: false,
        cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ?  'cell-editable' : ''},
        cellRenderer: (params) => {
            return (params.data &&  (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.data.endCycle}
                onChange={(value)=>{this.onChangeDate(params, value, 'endCycle')}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                className='datePickerGridInput'
                readOnly={this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated}
                />)) || params.value
        },
        minWidth: 130,
        flex: 1
    },
    {
            headerName: 'State Code*',
            field: 'stateCode',
            tooltipField: 'stateCode',
            cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ?  'cell-editable' : ''},
            filter: true,
            sortable: true,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated},
            width: 130,
            singleClickEdit: true,
            suppressSizeToFit: true
        },
        {
            headerName: 'GL Location*',
            field: 'glLocation',
            cellClass: (params) => {return params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ?  'cell-editable' : ''},
            tooltipField: 'glLocation',
            filter: true,
            sortable: true,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated},
            width: 110,
            singleClickEdit: true,
            suppressSizeToFit: true
        },
        {
            headerName: 'Legal Entity*',
            field: 'legalEntity',
            tooltipField: 'legalEntity',
            cellClass: (params) => {return this.props.addPipelineMaintenanceDataStore.isEditableSettingNameLE(params) && params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ?  'cell-editable' : ''},
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            filter: true,
            sortable: true,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated},
            minWidth: 135,
            suppressSizeToFit: false,
            flex: 2
        },
        {
            headerName: 'Line of Business*',
            field: 'lineofBusiness',
            cellClass: (params) => {return this.props.addPipelineMaintenanceDataStore.isEditableSettingNameLOB(params) && params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ?  'cell-editable' : ''},
            tooltipField: 'endDate',
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated},
            minWidth: 162,
            suppressSizeToFit: false,
            flex: 2
        },
        {
            headerName: 'Joint Interest Billing*',
            field: 'jointInterestBilling',
            tooltipField: 'jointInterestBilling',
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            cellClass: (params) => {return this.props.addPipelineMaintenanceDataStore.isEditableSettingNameJIB(params) && params.data && !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated ?  'cell-editable' : ''},
            filter: true,
            sortable: true,
            editable: (params) => {return !this.props.addPipelineMaintenanceDataStore.legalEntitiesCreated},
            minWidth: 175,
            suppressSizeToFit: false,
            flex: 2
        },
        {
            headerName: 'Created User',
            field: 'createdUser',
            tooltipField: 'createdUser',
            filter: true,
            sortable: true,
            editable: false,
            width: 120,
        },
        {
            headerName: 'Created Date',
            field: 'createdDate',
            tooltipField: 'createdDate',
            filter: true,
            sortable: true,
            editable: false,
            width: 120,
            valueGetter: params => {
                if (params.data['createdDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['createdDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
        }
    ];

    relatedAreasGrid: ColDef[] = [
        {
                headerName: 'Pipeline Type',
                field: 'pipelineType',
                tooltipField: 'pipelineType',
                filter: true,
                sortable: true,
                editable: false,
                minWidth: 550,
                flex: 1
            },
            {
                headerName: 'Pipeline Name',
                field: 'pipelineName',
                tooltipField: 'pipelineName',
                filter: true,
                sortable: true,
                editable: false,
                minWidth: 550,
                flex: 1
            }
        ];


    getColDef() {
        return this.pipelineMaintenanceGridColDef
    }

    getColDefLegalEntity() {
        return this.legalEntityGrid
    }

    getColDefRelatedAreas() {
        return this.relatedAreasGrid
    }

    getGridConfigOwnerShip(gridId) {
        const {
            addPipelineMaintenanceDataStore: { agGridServiceOwners }
        } = this.props;
        const { addPipelineMaintenanceDataStore } = this.props;
        const pMGridParams: any = {
          rowData: addPipelineMaintenanceDataStore.OwnershipData,
          columnDefs:this.getColDef(), // onCellEditingStopped: this.onCellEditingStopped,
          groupDisplayType: 'groupRows',
          groupIncludeFooter: true,
          context: agGridServiceOwners
        //   autoGroupColumnDef: true
        //   autoGroupColumnDef: {
        //     sort: 'desc'
        //   }
        //   autoGroupColumnDef: {
        //     field: 'effectiveDate',
        //     comparator: (a, b) => {
        //         const dateA = new Date(a);
        //         const dateB = new Date(b);
        //         if(dateA===dateB) return 0;
        //         else if(dateA>dateB) return 1;
        //         return -1           
        //     },
        //     sort: 'asc'
        //     }
          };
        return agGridServiceOwners.getGridConfig(pMGridParams, gridId);
    }

    getGridConfigLegalEntity(gridId) {
        const {
            addPipelineMaintenanceDataStore: { agGridServiceEntities }
        } = this.props;
        const { addPipelineMaintenanceDataStore } = this.props;
        const pMGridParams: any = {
          rowData: addPipelineMaintenanceDataStore.EntitiesData,
          columnDefs:this.getColDefLegalEntity(),
          context: agGridServiceEntities // onCellEditingStopped: this.onCellEditingStopped
          };
        return agGridServiceEntities.getGridConfig(pMGridParams, gridId);
    }

    getGridConfigRelatedAreas (gridId) {
        const {
            addPipelineMaintenanceDataStore: { agGridService }
        } = this.props;
        const { addPipelineMaintenanceDataStore } = this.props;
        const pMGridParams: any = {
          rowData: addPipelineMaintenanceDataStore.relatedAreasData,
          columnDefs:this.getColDefRelatedAreas(), // onCellEditingStopped: this.onCellEditingStopped
          };
        return agGridService.getGridConfig(pMGridParams, gridId);
    }

    // onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
    //     const { addPipelineMaintenanceDataStore } = this.props;
    //     const rowId = event.data.rowId;
    //     const rowData = event.data;
    //     const col = event.colDef.field || '';
    //     const value = event.value;
    //     if(col==="ownership"){
    //         console.log(event.value, "event", rowId)
    //       if(event.value>1){
    //         event.value=event.value/10;
    //       }
    //     }
    //   };

    changeFormData(value, key) {
        addPipelineMaintenanceDataStore.changeFormData(value, key)
    }

    savePipeline() {
        console.log(addPipelineMaintenanceDataStore.formData);
        addPipelineMaintenanceDataStore.savePipeline();
    }

    onAddRowOwnership() {
        const rowData = [{
            createdUser: addPipelineMaintenanceDataStore.createdUser,
            effectiveDate: this.dateToEpoch2(addPipelineMaintenanceDataStore.OwnershipData.length===0?
                new Date():new Date(addPipelineMaintenanceDataStore.OwnershipData.sort((a,b)=>b.effectiveDate-a.effectiveDate)[0]?.effectiveDate)),
            rowId: this.state.rowId
            }, ...addPipelineMaintenanceDataStore.OwnershipData
           ];
          addPipelineMaintenanceDataStore.setOwnershipGridData(rowData);
          this.setState({...this.state, rowId: this.state.rowId+1})
        //   this.props.baseTariffDataStore.addValuesInCellDropdowns();
    }

    dateToEpoch2(thedate) {
        return thedate && thedate.setHours(0,0,0,0) || "";
    }

    onSaveOwnership() {
        addPipelineMaintenanceDataStore.saveOwnership();
    }

    onAddRowEntities() {
        const rowData = [...addPipelineMaintenanceDataStore.EntitiesData,{
            createdUser: addPipelineMaintenanceDataStore.createdUser,
            createdDate: new Date(),
            rowIdEntities: this.state.rowIdEntities
            }
           ];
          addPipelineMaintenanceDataStore.setEntitiesGridData(rowData);
          this.setState({...this.state, rowIdEntities: this.state.rowIdEntities+1})
        //   this.props.baseTariffDataStore.addValuesInCellDropdowns();
    }

    onSaveEntities() {
        addPipelineMaintenanceDataStore.savePipelineEntities();
    }

    deleteItemOwners = async () => {
        const { addPipelineMaintenanceDataStore } = this.props;
        const requestBody: any = await addPipelineMaintenanceDataStore.agGridServiceOwners.getSelectedRows();
        if(requestBody.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => this.filterRowsOwners(requestBody),
              'Delete',
              'Are you sure you want to delete the selected record/s?'
          );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        }
      }

      filterRowsOwners = (requestBody) => {
        addPipelineMaintenanceDataStore.setOwnershipGridData(addPipelineMaintenanceDataStore.OwnershipData.filter(
          x=>requestBody.every(y=>x.rowId!==y.rowId)))
      }


    deleteItemEntities = async () => {
        const { addPipelineMaintenanceDataStore } = this.props;
        const requestBody: any = await addPipelineMaintenanceDataStore.agGridServiceEntities.getSelectedRows();
        if(requestBody.length > 0) {
            console.log(requestBody);
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => this.filterRowsEntities(requestBody),
              'Delete',
              'Are you sure you want to delete the selected record/s?'
          );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        }
      }

      filterRowsEntities = (requestBody) => {
        addPipelineMaintenanceDataStore.setEntitiesGridData(addPipelineMaintenanceDataStore.EntitiesData.filter(
          x=>requestBody.every(y=>x.rowIdEntities!==y.rowIdEntities)))
      }

      pageRedirectFunction = () => {
        if(addPipelineMaintenanceDataStore.pipelineCreated){
            history.push('/PipelineMaintenance', { from: 'AddPipeline', plname: addPipelineMaintenanceDataStore.formData.plname})
        } else {
            history.push('/PipelineMaintenance')
        }
      }

      pushToVmacs = (env) => {
        const displayEnv = env === 'Prod' ? 'Production' : env;
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () =>  addPipelineMaintenanceDataStore.pushToVmacs(env),
            'Sync',
            `Are you sure you want to push the selected data to the ${displayEnv} Environment?`
        );
      }

    render(): React.ReactNode {
        const { addPipelineMaintenanceDataStore } = this.props;
        return (
        <div className='AddPipelineBox'>
            <Container fluid>
            <div className='boxes'>
                  <div className='screenHeader'>
                      <h2 className='screen_title'> Add Pipeline Detail</h2>
                    </div>
                  <div className='selectContainer'>
                  <Row className='physicalPipeline'>
                    <Col style={{'marginTop':'10px'}}>
                        <label className="standard_label_style">Physical Pipeline</label>
                        <select className="select" style={{'width': '200px !important'}} disabled={addPipelineMaintenanceDataStore.pipelineCreated} value={'ADVANTAGE PL'} onChange={(e) => {}}>
                        {addPipelineMaintenanceDataStore.dropdownPhysicalPipeline.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                        </select>
                        <div style={{marginLeft:'20px',"padding":'5px'}}>
                            <label className="standard_label_style">
                            <input
                                type="checkbox"
                                checked={false}
                                disabled={addPipelineMaintenanceDataStore.pipelineCreated}
                                onChange = {()=>{}}
                            /> Map
                            </label>
                        </div>
                    </Col>
                    </Row>
                    <Row className="baseSystem">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Base System*</label>
                            <Row style ={{marginTop : '20px'}}>
                            <input
                            type="text"
                            className="search_text_field input"
                            readOnly={addPipelineMaintenanceDataStore.pipelineCreated}
                            onChange = {(e) => this.changeFormData(e.target.value, 'plname')}
                            value={addPipelineMaintenanceDataStore.formData?.plname}
                            />
                            </Row>
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Nomination Area</label>
                            <Row style ={{marginTop : '20px'}}><input
                            type="text"
                            className="search_text_field input"
                            onChange = {(e) => {}}
                            value={addPipelineMaintenanceDataStore.formData?.plname?addPipelineMaintenanceDataStore.formData?.plname + ' - N':''}
                            readOnly
                            />
                            </Row>
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Tariff Area</label>
                            <Row style ={{marginTop : '20px'}}><input
                            type="text"
                            className="search_text_field input"
                            onChange = {(e) => {}}
                            value={addPipelineMaintenanceDataStore.formData?.plname?addPipelineMaintenanceDataStore.formData?.plname + ' - T':''}
                            readOnly
                            />
                            </Row>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <hr></hr>
                    <div className="row justify-content-center dates">
                            <Col className="colWidth" style={{'marginTop':'10px'}}>
                                <label className="standard_label_style">Effective Date*</label>
                                <ReactDatePicker readOnly={addPipelineMaintenanceDataStore.pipelineCreated} onDateChange={(e)=>this.changeFormData(e, 'effectiveDate')} selectedDate={addPipelineMaintenanceDataStore.formData?.effectiveDate}/>
                            </Col>
                            <Col className="colWidth" style={{'marginTop':'10px'}}>
                                <label className="standard_label_style">End Date</label>
                                <ReactDatePicker readOnly={addPipelineMaintenanceDataStore.pipelineCreated} onDateChange={(e)=>this.changeFormData(e, 'endDate')} selectedDate={addPipelineMaintenanceDataStore.formData?.endDate}/>
                                {/* <div style={{marginLeft:'20px'}}>
                                    <input
                                        type="checkbox"
                                        checked={true}
                                        onChange = {()=>{}}
                                    /> Operated
                                </div> */}
                            </Col>
                            <Col style={{'marginTop':'10x', 'minWidth':'130px'}}>
                            <label style={{"padding":'5px'}} className="standard_label_style">
                                <input type="checkbox" disabled={addPipelineMaintenanceDataStore.pipelineCreated} onChange={(e)=>this.changeFormData(!addPipelineMaintenanceDataStore.formData?.operatedInd, 'operatedInd')} checked={addPipelineMaintenanceDataStore.formData?.operatedInd}/>
                                 &nbsp; Operated
                            </label>
                            </Col>
                            <Col>
                            </Col>
                        </div>
                        <div>
                            <div className="rightFlexSave col">
                                <CustomButton type={CustomButtonType.Submit} disabled={addPipelineMaintenanceDataStore.pipelineCreated} onClick={()=>{this.savePipeline()}} >
                                Save
                                </CustomButton>
                            </div>
                        </div>
                    <div className='flexDetails'>
                        <div className='inner'>
                            <div className="rotatedText">
                            Quality Bank
                            </div>
                            <div style={{'width':'100%'}}>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">QB Invoice Freq</label>
                                        <select className="select" disabled={addPipelineMaintenanceDataStore.pipelineCreated} value={addPipelineMaintenanceDataStore.formData?.qbInvoiceFreq} onChange={(e) => this.changeFormData(e.target.value, 'qbInvoiceFreq')}>
                                        {addPipelineMaintenanceDataStore.qbInvoiceFrequencyDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">QB Rate Basis</label>
                                        <select className="select" disabled={addPipelineMaintenanceDataStore.pipelineCreated} value={addPipelineMaintenanceDataStore.formData?.qbRateBasis} onChange={(e) => this.changeFormData(e.target.value, 'qbRateBasis')}>
                                        {addPipelineMaintenanceDataStore.qbRateBasisDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{justifyContent: 'center'}}>
                                        <label style={{"padding":'5px', 'width': '150px'}} className="standard_label_style">
                                            <input type="checkbox" disabled={addPipelineMaintenanceDataStore.pipelineCreated} onChange={(e)=>this.changeFormData(!addPipelineMaintenanceDataStore.formData?.gravityBankInd, 'gravityBankInd')} checked={addPipelineMaintenanceDataStore.formData?.gravityBankInd}/>
                                            &nbsp; Gravity Bank
                                        </label>
                                        <label style={{"padding":'5px', 'width': '150px'}} className="standard_label_style">
                                            <input type="checkbox" disabled={addPipelineMaintenanceDataStore.pipelineCreated} onChange={(e)=>this.changeFormData(!addPipelineMaintenanceDataStore.formData?.sulphurBankInd, 'sulphurBankInd')} checked={addPipelineMaintenanceDataStore.formData?.sulphurBankInd}/>
                                            &nbsp; Sulphur Bank
                                        </label>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className='inner'>
                            <div className="rotatedText">
                            Loss Gain & PLA
                            </div>
                            <div style={{'width':'100%'}}>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">Loss/Gain</label>
                                        <select className="select" disabled={addPipelineMaintenanceDataStore.pipelineCreated} value={addPipelineMaintenanceDataStore.formData?.lossGain} onChange={(e) => this.changeFormData(e.target.value, 'lossGain')}>
                                        {addPipelineMaintenanceDataStore.lossGainDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">PLA Basis</label>
                                        <select className="select" disabled={addPipelineMaintenanceDataStore.pipelineCreated} value={addPipelineMaintenanceDataStore.formData?.plaBasis} onChange={(e) => this.changeFormData(e.target.value, 'plaBasis')}>
                                        {addPipelineMaintenanceDataStore.plaBasisDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">Allocation Basis</label>
                                        <select className="select" disabled={addPipelineMaintenanceDataStore.pipelineCreated} value={addPipelineMaintenanceDataStore.formData?.allocationBasis} onChange={(e) => this.changeFormData(e.target.value, 'allocationBasis')}>
                                        {addPipelineMaintenanceDataStore.allocationBasisDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className='screenHeader'>
                      <h2 className='screen_title'>Owners/L/G System Owners</h2>
                    </div>
                    <div className='selectContainer'>
                    <Row>
                        <div className="rightFlex">
                            <CustomButton type={CustomButtonType.Submit} disabled={addPipelineMaintenanceDataStore.ownersCreated || !addPipelineMaintenanceDataStore.OwnershipData.length} onClick={()=>this.deleteItemOwners()}>
                            Delete Ownership
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={addPipelineMaintenanceDataStore.ownersCreated || !addPipelineMaintenanceDataStore.pipelineCreated} onClick={()=>this.onAddRowOwnership()}>
                            Add Ownership
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={addPipelineMaintenanceDataStore.ownersCreated || !addPipelineMaintenanceDataStore.OwnershipData.length} onClick={()=>this.onSaveOwnership()}>
                            Save
                            </CustomButton>
                        </div>
                    </Row>
                    <Row>
                        <AgGridComponent gridConfig={this.getGridConfigOwnerShip('Add Pipeline Ownership')}/>
                    </Row>
                    <Row>
                        <div className="rightFlex">
                            <CustomButton type={CustomButtonType.Submit} disabled={addPipelineMaintenanceDataStore.legalEntitiesCreated||!addPipelineMaintenanceDataStore.EntitiesData.length} onClick={()=>this.deleteItemEntities()}>
                            Delete Legal Entity
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={addPipelineMaintenanceDataStore.legalEntitiesCreated||!addPipelineMaintenanceDataStore.pipelineCreated} onClick={()=>this.onAddRowEntities()}>
                            Add Legal Entity
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={addPipelineMaintenanceDataStore.legalEntitiesCreated||!addPipelineMaintenanceDataStore.EntitiesData.length} onClick={()=>this.onSaveEntities()}>
                            Save
                            </CustomButton>
                        </div>
                    </Row>
                    <Row>
                        <AgGridComponent gridConfig={this.getGridConfigLegalEntity('Add Pipeline Legal Entity')}/>
                    </Row>
                    </div>
                    <div className='screenHeader'>
                      <h2 className='screen_title'>Related Area(s)</h2>
                    </div>
                    <div className='selectContainer'>
                    <Row className='relatedAreas' style={{'marginTop':'10px'}}>
                        <AgGridComponent gridConfig={this.getGridConfigRelatedAreas('Add Pipeline Related Areas')}/>
                    </Row>
                    </div>
                    <Row style={{'marginTop':'25px'}}>
                        <div className="rightFlexBottom">
                            {(dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)) && (
                            <><CustomButton type={CustomButtonType.Submit} onClick={()=>{this.pushToVmacs('Test')}} disabled={!addPipelineMaintenanceDataStore.pipelineCreated||addPipelineMaintenanceDataStore.syncedToTest || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest)}>
                            Push to Test
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.pushToVmacs('Prod')}} disabled={!addPipelineMaintenanceDataStore.syncedToTest||addPipelineMaintenanceDataStore.syncedToProd ||  !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)} >
                            Push to Prod
                            </CustomButton></>)}
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.pageRedirectFunction()}} disabled={false}>
                            Cancel
                            </CustomButton>
                            {/* <CustomButton type={CustomButtonType.Submit} onClick={()=>{console.log('1')}} disabled= {false}>
                            System Linker
                            </CustomButton> */}
                        </div>
                    </Row>
                </div>
          </div>
        </Container>
       </div>
    );
}}
