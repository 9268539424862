import { tariffOptionsDataService } from '../services/tariff-options-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { TariffOptionsIndexDataStore } from './tariff-options-data-store';
import { accountStore } from '../../../account/stores';
import { agGridService, AgGridService } from 'common/services/ag-grid-service';


export const agGridServiceMovementIndex = new AgGridService();
export const agGridServiceConnectionPointIndex = new AgGridService();
export const agGridServiceDeductionIndex = new AgGridService();
export const agGridServiceLossAllowanceIndex = new AgGridService();
export const agGridServiceRelatedTariffIndex = new AgGridService();
export const agGridServicePipelineIndex = new AgGridService();
export const agGridServiceOwnerIndex = new AgGridService();
export const agGridServiceCommentsIndex = new AgGridService();
export const agGridServiceCommentsCTS = new AgGridService();

export const tariffOptionsDataStore = new TariffOptionsIndexDataStore(tariffOptionsDataService, agGridServiceMovementIndex, agGridServiceConnectionPointIndex, agGridServiceDeductionIndex, agGridServiceLossAllowanceIndex, agGridServiceRelatedTariffIndex, agGridServicePipelineIndex, agGridServiceOwnerIndex, agGridServiceCommentsIndex, agGridServiceCommentsCTS, agGridService,  uiService, accountStore);
