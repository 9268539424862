import React from 'react';
// import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { tariffDashboardDataStore } from '../../stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { StatusData } from '../../constants/enum';
import { TariffLogEntry } from 'features/tariff-management/tariff-log/constant/enums';



export const LogIdCellRenderer = (props: ICellRendererParams) => {    
    return (

        tariffDashboardDataStore.isSaveDisabled() || props.data.action === 'D' ? 
        <span>{props.data.tariffLogId}</span> : 
            <span style={{color: 'blue', width: '60px', cursor:'pointer'}}
            onClick={()=>tariffDashboardDataStore.onClickLogId(props)}>
                {props.data.tariffLogId ==undefined?'0000000':props.data.tariffLogId }</span>
    );
};

export const TariffNumberCellRenderer = (props: ICellRendererParams) => {    
    return (

        tariffDashboardDataStore.isSaveDisabled() || props.data.action === 'D' ? 
        <span>{props.data.tariffNumber}</span> : 
            <span style={{color: 'blue', width: '60px', cursor:'pointer'}}
            onClick={()=>tariffDashboardDataStore.onClickLogId(props)}>
                {props.data.tariffNumber ==undefined?'0000000':props.data.tariffNumber }</span>
    );
};

export const ViewOptionsCellRenderer = (props: ICellRendererParams) => {    
    return (

        <>
            {(tariffDashboardDataStore.isViewOptionButtonDisbaled(MasterDataComponentModulesName.TariffDashboardViewButton)) ?
                <span>{ 'View' }</span> :  
                <span style={{color: 'blue', width: '30px', cursor:'pointer'}}
                    onClick={()=>tariffDashboardDataStore.onClickViewOptions(props, 'View')}>
                    { 'View' }
                </span>}
            
            {props.data.status === StatusData[0].status && (props.data.wfStep || ((props.data.tariffClass === TariffLogEntry.TariffFiling || props.data.tariffClass === TariffLogEntry.Maintenance) && props.data.wF1DStep)) && <>
                {tariffDashboardDataStore.isWorkflowStepEnabled(props.data.wfStep, props.data.wF1DStep) ?
                    <>
                        <span style={{ minWidth: '30px', textAlign: 'center'}}>{ ' | ' }</span>
                        <span>{props.data.wfStep ?  props.data.wfStep : props.data.wF1DStep ? props.data.wF1DStep : ''}</span>
                    </> :
                    <>
                        <span style={{color: 'blue', minWidth: '30px', textAlign: 'center'}}>{ ' | ' }</span>
                        <span style={{color: 'blue', width: '60px', cursor:'pointer'}}
                        onClick={()=>tariffDashboardDataStore.onClickViewOptions(props, 'Process')}>
                        {props.data.wfStep ?  props.data.wfStep : props.data.wF1DStep ? props.data.wF1DStep : ''}
                        </span>
                    </>
                }
                </>
            } 
            
        </>
    );
};