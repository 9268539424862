import { tariffOptionsDataService } from '../services/tariff-options-data-service';
import { uiService } from 'shared/services/ui-service';
import { TariffOptionsIndexDataStore } from './tariff-options-data-store';
import { accountStore } from 'features/account/stores';
import { agGridService, AgGridService } from 'common/services/ag-grid-service';


export const agGridServiceMovementProject = new AgGridService();
export const agGridServiceConnectionPointProject = new AgGridService();
export const agGridServiceDeductionProject = new AgGridService();
export const agGridServiceLossAllowanceProject = new AgGridService();
export const agGridServiceRelatedTariffProject = new AgGridService();
export const agGridServicePipelineProject = new AgGridService();
export const agGridServiceOwnerProject = new AgGridService();
export const agGridServiceCommentsProject = new AgGridService();
export const agGridServiceCommentsCTS = new AgGridService();

export const tariffOptionsDataStoreLog = new TariffOptionsIndexDataStore(tariffOptionsDataService, agGridServiceMovementProject, agGridServiceConnectionPointProject, agGridServiceDeductionProject, agGridServiceLossAllowanceProject, agGridServiceRelatedTariffProject, agGridServicePipelineProject, agGridServiceOwnerProject, agGridServiceCommentsProject, agGridServiceCommentsCTS, agGridService,  uiService, accountStore);
