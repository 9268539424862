import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class LineSegmentMaintenanceDataService {
    constructor(private restApi: RestApiService) {}


    async getLineSegmentsData(request, requestBody): Promise<any[]> {
        const { data } =  await this.restApi.post<any>(`${Path.getLineSegmentData}?Area=${request.area}&LineSegType=${request.lineSegType}&LineSegDesc=${request.lineSegDesc}&LineSegStatus=${request.lineSegStatus}&ColId=${request.sortColId}&Sort=${request.sort}&isPagination=true&pageno=${request.page}&pagesize=${request.pageSize}`, requestBody, {});
        return data;
    }
    async getLineSegmentDetails(lsId): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getLineSegmentDetails}?lineSegID=${lsId}`);
        return data;
    }
    async getPipelineSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLineSegmentSystemAreaData);
        return data;
    } 
    async getActivePipelines(workflowId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.getActivePipelines}?workflowID=${workflowId}`);
        return data;
    }
    async getLocationsData(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getLocationsWithDefaultFilter);
        return data;
    }
    async getLocationsDataWithFilter(locnName, baseSystemId, status): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getLocations}?baseSystemId=${baseSystemId}&locationName=${locnName}&status=${status}`);
        return data;
    }
    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }
    async getCPTypesData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getCPTypes);
        return data;
    }
    async getCPTariff(locationId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_ConnectionPointGridCP}?locationID=${locationId}`);
        return data;
    }
    async getMiscellaneousSettingsData(defaultUserLocation): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.url_id1 + defaultUserLocation);
        return data;
    }
    async getLineSegmentDesc(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_MovementGridLineSegment}?plAreaId=${pipelineId}`);
        return data;
    }
    async saveLineSegment(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.saveTariffLineSegment, reqbody, {});
        return data;
    } 
    async deactivateLineSegment(lineSegID: number, userName: string): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.deactivateTariffLineSegment+`?LineSegID=${lineSegID}&user=${userName}`);
        return data;
    }
    async activateLineSegment(requestBody: any, userName: string): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateTariffLineSegment+`?user=${userName}`, requestBody, {});
        return data;
    } 
    async deleteLineSegmentRemark(requestBody: any, userName: string): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.deleteLineSegmentRemark+`?user=${userName}`, requestBody, {});
        return data;
    }
    async getProjectNames(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.url_id18}?logType=Tariff%20Filing&isAllProject=true`);
        return data;
    }
    async getLineSegmentStatusData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLineSegmentStatusData);
        return data;
    }  
    async getLineSegmentTypeData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLineSegmentTypeData);
        return data;
    }
    async updateLineSegmentMaintenanceData(requestBody, onEditLineSegment): Promise<any[]> {
        if(onEditLineSegment) {
            const { data } = await this.restApi.put<any>(Path.addLineSegmentDetailsAPI, requestBody, {});
            return data;
        } else {
            const { data } = await this.restApi.post<any>(Path.addLineSegmentDetailsAPI, requestBody, {});
            return data;
        }
    }  
    async deleteLineSegmentMaintenanceData(requestBody): Promise<any> {
        const { data } = await this.restApi.delete<any>(Path.addLineSegmentDetailsAPI, requestBody, {});
        return data;
    }  
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsLineSegmentEndpoint, reqbody, {});
        return data;
    }  

    async updateStatusFromDelToActive(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateLineSegments, reqbody, {});
        return data;
    }
}

export const lineSegmentMaintenanceDataService = new LineSegmentMaintenanceDataService(restApiService);
