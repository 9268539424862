import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class CPDataService {
    constructor(private restApi: RestApiService) {}

    async getCPGridData(requestData, requestBody): Promise<any> {
        const response =  await this.restApi.post<any>(Path.getConnectionPointData + `?isPagination=true&pageno=${requestData.page}&pagesize=${requestData.pageSize}&pipelineID=${requestData.pipelineID}&locationID=${requestData.locationID}&CPName=${requestData.cpName}&CPCode=${requestData.cpCode}&CPType=${requestData.cpType}&CPStatusID=${requestData.cpStatus}&ActivityTypeID=${requestData.activityTypeID}&StateID=${requestData.stateID}&ColId=${requestData.sortColId}&Sort=${requestData.sort}`, requestBody, {});
        return response;
    }


    async getLocationsData(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getLocationsWithDefaultFilter);
        return data;
    }
    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }


    async getCPTypesData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getCPTypes);
        return data;
    }

    async getActivityTypesData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getActivityTypes);
        return data;
    }

    async getCCTypesData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getCCTypes);
        return data;
    }

    async getFercType(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getFercTypes);
        return data;
    }

    async getConnCarrerOperator(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.getConnCarrerOperator}?pipelineId=${pipelineId}`);
        return data;
    }

    async getCPStatus(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getCTSStatus);
        return data;
    }


    async getTariffStates(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id7);
        return data;
    }  
    
    async updateStatusFromDelToActive(reqbody: any, userName): Promise<any[]> {
        const { data } = await this.restApi.post<any>(`${Path.activateConnectionPoints}=${userName}`, reqbody, {});
        return data;
    }

    async getCPTariff(locationId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_ConnectionPointGridCP}?locationID=${locationId}`);
        return data;
    }

    async getCPRelTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getCPRelationTypes);
        return data;
    }  

    async saveConnectionPoint(requestBody): Promise<any> {
            const { data } = await this.restApi.post<any>(Path.saveConnectionPoint, requestBody, {});
            return data;
    }
    
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsCPEndpoint, reqbody, {});
        return data;
    } 

    async deactivateCP(reqbody: any): Promise<any> {
        const { data } = await this.restApi.post<any>(`${Path.deleteCP}?CPID=${reqbody.connectionPointID}&user=${reqbody.createUser}`);
        return data;
    }

}

export const cpDataService = new CPDataService(restApiService);
