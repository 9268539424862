import * as React from 'react';
import {ThreeDots} from 'react-loader-spinner';
import './_loader.scss';
import { loaderSubject } from '.';

export interface ReactLoaderProps {
    visible: boolean;
}

export interface ReactLoaderState {
    visible: boolean;
}

export class ReactLoader extends React.Component<ReactLoaderProps, ReactLoaderState> {
    constructor(props: Readonly<ReactLoaderProps>) {
        super(props);
        this.state = {
            visible: props.visible
        };
        loaderSubject.subscribe((visible: boolean) => {
            this.setState({ visible });
        });
    }

    componentDidUpdate(newProps: { visible: boolean | undefined }) {
        if (this.props.visible !== newProps.visible && newProps.visible) {
            loaderSubject.next(newProps.visible);
        }
    }

    componentWillUnmount() {
        loaderSubject.unsubscribe();
    }

    public render(): React.ReactNode {
        const { visible } = this.state;
        return (
            <React.Fragment>
                {visible ? <div className="loader__overlay" /> : null}
                <div className="loader__icon">
                    <ThreeDots height={80} width={80} color="green" ariaLabel="loading" visible = {visible}/>
                </div>
            </React.Fragment>
        );
    }
}
