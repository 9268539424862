import React, {Component} from 'react'
import { indexRateMaintenanceDataStore } from '../stores';
import { IndexRateMaintenanceDataStore } from '../stores/index-rate-maintenance-data-store';
import { inject, observer } from 'mobx-react';
import { dataStore } from 'common/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import { Col, Row, Container } from 'react-bootstrap';
import momenttimezone from 'moment-timezone';

import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'common/components/ag-grid-component';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import { ColDef, GridOptions } from 'ag-grid-community';


interface IndexRateContainerProps {
    indexRateMaintenanceDataStore: IndexRateMaintenanceDataStore;
}

const historyGridColDef: ColDef[] = [
    { 
        headerName: 'Index %', 
        field: 'index',
        colId: 'index',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        width: 90, 
        valueGetter: params => {
            if (params.data['index'] === null) {
                return '';
            } else {
                return params.data['index'];
            }
        }
    },
    { 
        headerName: 'Effective Date', 
        field: 'effectiveDate', 
        colId: 'effectiveDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['effectiveDate'] === null) {
                return '';
            } else {
                return moment(params.data['effectiveDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
    },
    { 
        headerName: 'Updated By', 
        field: 'updatedUser',
        colId: 'updatedUser',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        minWidth: 120, 
        valueGetter: params => {
            if (params.data['updatedUser'] === null) {
                return '';
            } else {
                return params.data['updatedUser'];
            }
        }
    },
    { 
        headerName: 'Updated Date', 
        field: 'updatedDate', 
        colId: 'updatedDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updatedDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updatedDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        flex: 1
    },
];




@inject('indexRateMaintenanceDataStore')
@observer
export class IndexRateMaintenanceContainer extends Component<IndexRateContainerProps, any> {
    
    constructor(props: IndexRateContainerProps | Readonly<IndexRateContainerProps>) {
        super(props);
        this.state = {
           shouldShowGrid: false
        };
        
        dataStore.setModuleName('Index Rate Maintenance');
    }

    componentDidMount() {
        const { indexRateMaintenanceDataStore } = this.props;
        indexRateMaintenanceDataStore.init();
    }

    componentWillUnmount() {
        this.props.indexRateMaintenanceDataStore.reset();
    }

    onSaveClick = () => {
        const { indexRateMaintenanceDataStore } = this.props;
        indexRateMaintenanceDataStore.saveIndexRate();
    }

    handleDecimalsOnValue(value) {
        return value.match(/[-]?\d{0,3}(\.\d{0,4})?$/)[0];
    }

    
    
    render(): React.ReactNode {
        const inputClassName = indexRateMaintenanceDataStore.isSaveDisabled() ? 'search_text_field-D' : 'search_text_field';
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div>
                            <div className='screenHeader'>
                                <h2 className='screen_title'>Index Rate Maintenance</h2>
                            </div>
            
                            <div className='selectContainer'>
                                <div className='settingSelectionDiv'>
                                    <Row>
                                        <Col>
                                            <label className="standard_label_style">Current Index Rate</label>
                                            <input 
                                            type="text"
                                            value={indexRateMaintenanceDataStore.enteredIndexRate}
                                            className={inputClassName}
                                            disabled={indexRateMaintenanceDataStore.isSaveDisabled()}
                                            style={{width: '20%', marginRight: '10px'}}
                                            onChange = {(e) => indexRateMaintenanceDataStore.setEnteredIndexRate(this.handleDecimalsOnValue(e.target.value))}
                                            />
                                            <label>%</label>
                                        </Col>
                                        <Col>
                                            <label className="standard_label_style">Effective Date</label>
                                            <input 
                                            type="date"
                                            value={indexRateMaintenanceDataStore.effectiveDate}
                                            className={inputClassName}
                                            disabled={indexRateMaintenanceDataStore.isSaveDisabled()}
                                            style={{width: '20%', marginRight: '10px'}}
                                            onChange = {(e) => indexRateMaintenanceDataStore.setEffectiveDate(e.target.value)}
                                            />
                                        </Col>
                                        <Col>
                                            <label className="standard_label_style">Issue Date</label>
                                            <input 
                                            type="date"
                                            value={indexRateMaintenanceDataStore.issueDate}
                                            className={inputClassName}
                                            disabled={indexRateMaintenanceDataStore.isSaveDisabled()}
                                            style={{width: '20%', marginRight: '10px'}}
                                            onChange = {(e) => indexRateMaintenanceDataStore.setIssueDate(e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className='searchButtonDiv'>
                                    
                                <div className='otherHeaderButtonsDiv'>
                                    <CustomButton type={CustomButtonType.Submit} onClick={this.onSaveClick} disabled = {indexRateMaintenanceDataStore.isSaveDisabled()}>
                                        Save
                                    </CustomButton>
                                    <CustomButton type={CustomButtonType.Submit} onClick={() => this.setState({ shouldShowGrid : true})} disabled = {indexRateMaintenanceDataStore.isSaveDisabled()}>
                                         View History
                                     </CustomButton>
                                
                                </div>
                                </div>
                            </div>
                            {this.props.indexRateMaintenanceDataStore.showLoader == true &&
                                <div className = 'alert-overlay'> 
                                    <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                                </div>
                                }
                                {this.state.shouldShowGrid &&<AgGridComponent gridConfig={this.getGridConfig('View Index Rate History')} />}
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }

    private getGridConfig(gridId): GridOptions {
        const {
            indexRateMaintenanceDataStore: { historyGridData, agGridService }
        } = this.props;
        const { indexRateMaintenanceDataStore } = this.props;
        const columnDefs =historyGridColDef;
        const rowData = historyGridData;
        const indexRateMaintenanceGridParams: any = {
          rowData,
          columnDefs,
        };
        return agGridService.getGridConfig(indexRateMaintenanceGridParams, gridId);
      }

}