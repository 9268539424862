import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { FilingCarrierMaintenanceDataStore } from '../stores/filing-carrier-maintenance-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/filingCarrier.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { filingCarrierMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface FilingCarrierMaintenanceContainerProps {
    filingCarrierMaintenanceDataStore: FilingCarrierMaintenanceDataStore;
}

interface FilingCarrierMaintenanceContainerState {
    search: string;
}

@inject('filingCarrierMaintenanceDataStore')
@observer
export class FilingCarrierMaintenanceContainer extends Component<FilingCarrierMaintenanceContainerProps, FilingCarrierMaintenanceContainerState> {
    constructor(props: FilingCarrierMaintenanceContainerProps | Readonly<FilingCarrierMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Filing Carrier Maintenance')
    }
    
    componentDidMount() {
      const {filingCarrierMaintenanceDataStore} = this.props;
      const previousFilingCarrier = history?.location?.state as { from?: string }
      history.push('/FilingCarrierMaintenance');
       if(previousFilingCarrier?.from && previousFilingCarrier.from==='/FilingCarrierDetails'){
           filingCarrierMaintenanceDataStore.onSearchClick();   
     } else {
            filingCarrierMaintenanceDataStore.init();   
        }
       filingCarrierMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {filingCarrierMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Filing Carrier Maintenance_filter');
      this.props.filingCarrierMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Filing Carrier Maintenance_' + accountStore.userName);
      this.props.filingCarrierMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    onBtClearSelection = () => {
      const { filingCarrierMaintenanceDataStore } = this.props;
      filingCarrierMaintenanceDataStore.onClearSearch();
    }

    onSearchClick = () => {
      const { filingCarrierMaintenanceDataStore } = this.props;
      filingCarrierMaintenanceDataStore.onSearchClick();
    }

    onAddNewClick = async() => {
      const { filingCarrierMaintenanceDataStore } = this.props;
        
      filingCarrierMaintenanceDataStore.onClickAddNewFilingCarrierButton = true;
      filingCarrierMaintenanceDataStore.onClickFilingCarrierOnGrid = false;
      filingCarrierMaintenanceDataStore.agGridService.destroyGrid();
      // filingCarrierMaintenanceDataStore.getPipelineSystemDropdown();
      filingCarrierMaintenanceDataStore.getSelectedFilingCarrierData();
      history.push('/FilingCarrierDetails');
    }

    render(): React.ReactNode {
      const { filingCarrierMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderFilingCarrierMaintenanceScreen()}
        </Container>
      );
    }

    renderFilingCarrierMaintenanceScreen() : React.ReactNode {
      const { filingCarrierMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='filingCarrierMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Tariff Filing Carrier Maintenance</h2>
                </div>
                
                {/* <h4 className='search_criteria_title'> 
                  <label>Search Criteria</label> 
                </h4> */}
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                    <label className="standard_label_style">Filing Carrier Code</label>
                      <ReactSelect
                        className='select'
                        values={filingCarrierMaintenanceDataStore.selectedFilingCarrierCode}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...filingCarrierMaintenanceDataStore.filingCarrierDropdownData.filter((i) => i.filingCode).map(x => {return { 'value': x.filingCode, 'label': x.filingCode}})]}
                        onChange={(e: any) => {filingCarrierMaintenanceDataStore.setFilingCarrier(e)}}
                        isMulti={false}
                        isSearchable={true}
                    />
                    <label className="standard_label_style">Filing Carrier Name</label>
                      <ReactSelect
                        className='select'
                        values={filingCarrierMaintenanceDataStore.selectedFilingCarrierName}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' },  ...filingCarrierMaintenanceDataStore.filingCarrierDropdownData.filter(x => filingCarrierMaintenanceDataStore.selectedFilingCarrierCode.label !== 'All' ? x.filingCode === filingCarrierMaintenanceDataStore.selectedFilingCarrierCode.label : true).map(x => {return { 'value': x.name, 'label': x.name}})]}
                        onChange={(e: any) => {filingCarrierMaintenanceDataStore.selectedFilingCarrierName = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                    {/* <label className="standard_label_style">Pipeline System</label>
                      <ReactSelect
                        className='select'
                        values={filingCarrierMaintenanceDataStore.selectedPipelineSystem}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...filingCarrierMaintenanceDataStore.pipelineSystemDropdownOption]}
                        onChange={(e: any) => {filingCarrierMaintenanceDataStore.selectedPipelineSystem = e}}
                        isMulti={false}
                        isSearchable={true}
                    /> */}
                  </div>
                  <div className='searchButtonDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                        Search
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                        Clear
                    </CustomButton>
                    <div className='otherHeaderButtonsDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled={filingCarrierMaintenanceDataStore.isSaveDisabled()}>
                          Add Filing Carrier
                      </CustomButton>
                      </div>
                  </div>
                </div>
              </div>
              {this.props.filingCarrierMaintenanceDataStore.showLoader == true &&
                <div className = 'alert-overlay'> 
                  <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                </div> 
              }
              <AgGridComponent gridConfig={this.getGridConfig('Filing Carrier Maintenance')} />
              {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
            </Col>
          </Row>
      );
    }


  onSelectionChanged = (event: SelectionChangedEvent) => {
      const { filingCarrierMaintenanceDataStore } = this.props;
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        filingCarrierMaintenanceDataStore.selectedFilingCarrierIds = selectedRows.map(x => { return x.gradeID });
        filingCarrierMaintenanceDataStore.setIsActivateButtonDisabled(filingCarrierMaintenanceDataStore.isSaveDisabled())
      } else {
        filingCarrierMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
    }

    private getGridConfig(gridId): GridOptions {
      const {
          filingCarrierMaintenanceDataStore: { FilingCarrierMaintenanceData, agGridService }
      } = this.props;
      const { filingCarrierMaintenanceDataStore } = this.props;
      const columnDefs = filingCarrierMaintenanceDataStore.getColDef();
      const rowData = FilingCarrierMaintenanceData;
      const onSelectionChanged = this.onSelectionChanged;
      const rowModelType = 'infinite';
      const cacheBlockSize =  dataStore.CacheBlockSize;
      const filingCarrierMaintenanceGridParams: any = {
         rowData,
        // rowModelType,
        columnDefs,
        onSelectionChanged,
        cacheBlockSize
      };
      return agGridService.getGridConfig(filingCarrierMaintenanceGridParams, gridId);
    }
}
