import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { systemFeeColDef } from '../components/system-fee-col-def';
import moment from 'moment';
import Path from '../../../../Path';
import axios from 'axios';
import { SystemFeeMaintenanceDataService } from '../services/system-fee-maintenance-data-service';
import { SystemFeeMaintenanceResponse } from '../model/model';
import { SystemFeeMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { systemFeeMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from '../../../../common/stores';
import { RoleBasedActionName } from '../../../../shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';
import { AppConstant } from 'app_constants';

export class SystemFeeMaintenanceDataStore {
    @observable systemFeeMaintenanceData: SystemFeeMaintenanceResponse[] = [];
    @observable modifiedSystemFeeMaintenanceData: SystemFeeMaintenanceResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    @observable createdUser;
    backupSystemFeeMaintenanceData: SystemFeeMaintenanceResponse[] = [];
    isRefreshed = false;
    @observable showModal = false;
    @observable selectedEffectiveStartDate;
    @observable selectedEffectiveEndDate;
    @observable selectedEffectiveEndDateGrid;
    @observable selectedSystemGroup;
    @observable selectedSystemGroupForAddEdit: any = { value: 'Select', label: 'Select' };
    @observable selectedSystemGroupIDForAddEdit = 0;
    @observable selectedEffectiveStartDateForAddEdit;
    @observable selectedEffectiveEndDateForAddEdit;
    @observable selectedEffectiveEndDateForAddEditGrid;
    @observable isEditingSystemFee = false;
    @observable editableSystemFeeData: any = {};
    @observable systemGroupDropdownOptions: any[] = [];
    @observable shrinkageDropdownOptions: any[] = [];
    @observable lossAllowanceDropdownOptions: any[] = [];
    @observable enteredPrimaryRate;
    @observable enteredSecondaryRate;
    @observable enteredLMCIRate;
    @observable enteredInjectionFee;
    @observable enteredRecieptTerminalingRate;
    @observable enteredQualityMgmtFee;
    @observable enteredSurcharge;
    @observable enteredElectronicReportingFee;
    @observable deliveryTerminallingFee;
    @observable lossAllowance = { value: 'Select', label: 'Select' };
    @observable shrinkage = { value: 'Select', label: 'Select' };
    @observable bsw;
    @observable intraSystemTransferFee
    @observable lumpSumFees;
    tariffStatesData: any[] = [];
    systemGroupData: any[]=[];
    shrinkageData: any[]=[];
    lossAllowanceData: any[]=[];
    
    @observable systemGroupDropdownOption: any[] = [];
    @observable addOrEditedSystemGroup = '';
    @observable pmcSystemFeeID = 0;
    @observable selectedFacilityID = 0;
    @observable selectedSystemGroupId = 0;
    @observable selectedFacilityIdForAddEdit = null;
    @observable addOrEditSystemFeeGridData: any[] = [];
    @observable backupAddOrEditSystemFeeGridData: any[] = [];
    @observable onClickSystemGroupOnGrid = false;
    @observable onClickAddNewSystemFeeButton = false;
    @observable isShowModal = false;
    @observable isShowEndDateConfirmationModal = false;
    @observable systemFeeDetailsToSetEndDate: any = {};

    constructor(
        public sytemFeeMaintenanceDataService: SystemFeeMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditSystemFee: AgGridService
    ) {
        makeAutoObservable(this);
    }

    async init(): Promise<void> {
        this.createdUser = this.accountStore.displayName;
        this.selectedSystemGroup = { value: 'All', label: 'All' };
        this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
        this.shrinkage = { value: 'Select', label: 'Select' };
        this.lossAllowance = { value: 'Select', label: 'Select' };
        this.selectedEffectiveStartDate = null;
        this.selectedEffectiveEndDate = null;
        this.selectedEffectiveEndDateGrid = null;
        this.selectedEffectiveStartDateForAddEdit = null;
        this.selectedEffectiveEndDateForAddEdit = null;
        this.selectedEffectiveEndDateForAddEditGrid = null;
        dataStore.setShowUnsavedWarningAlert(false);
        this.getSystemGroupDropdownData();
        this.getLossAllowanceDropdownData();
        this.getShrinkageDropdownData();
        this.onSearchClick();
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Pipeline System'))
    async getSystemGroupDropdownData(): Promise<void> {
        const systemGroups = await this.sytemFeeMaintenanceDataService.getSystemGroup();
        this.systemGroupData = systemGroups;
        this.showLoader = false;
        const a = (systemGroups.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedSystemGroups =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setSystemGroupDropdownOptions(sortedSystemGroups);
    }

    @action
    setSystemGroupDropdownOptions(options){
        this.systemGroupDropdownOptions = options
    }

    @action
    setSystemGroup(systemGroup: any): void {
        this.selectedSystemGroup = systemGroup;
    }

    @action
    setSystemGroupForAddEdit(systemGroup: any): void {
        this.selectedSystemGroupForAddEdit = systemGroup;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Shrinkage values'))
    async getShrinkageDropdownData(): Promise<void> {
        const shrinkageValues = await this.sytemFeeMaintenanceDataService.getPMCShrinkage();
        this.shrinkageData = shrinkageValues;
        this.showLoader = false;
        const a = (shrinkageValues.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedShrinkage =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setShrinkageDropdownOptions(sortedShrinkage);
    }

    @action
    setShrinkageDropdownOptions(options){
        this.shrinkageDropdownOptions = options
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in fetching Loss Allowance values'))
    async getLossAllowanceDropdownData(): Promise<void> {
        const lossAllowanceValues = await this.sytemFeeMaintenanceDataService.getPMCLossAllwance();
        this.lossAllowanceData = lossAllowanceValues;
        this.showLoader = false;
        const a = (lossAllowanceValues.map(x => {return { 'value': x.settingID, 'label': x.valueText}}));
        const sortedLossAllowance =  _.orderBy(a, [x=>x.label], ['asc']);
        this.setLossAllowanceDropdownOptions(sortedLossAllowance);
    }

    @action
    setLossAllowanceDropdownOptions(options){
        this.lossAllowanceDropdownOptions = options
    }

    getColDef() {
        return systemFeeColDef;
    }

    @Loader
    @action
    async openSystemFeeDetails(data) {
        this.editableSystemFeeData = data;
        this.setSystemFeeValue(data);
        this.isEditingSystemFee = true;
        this.isShowModal = true;
    }

    @Loader
    @action
    async onAddNewSystemFee() {
        this.isShowModal = true;      
    }

    @action
    setEffectiveStartDate(effectiveStartDate: Date): void {
        this.selectedEffectiveStartDate = effectiveStartDate;
    }

    @action
    setEffectiveEndDate(effectiveEndDate: Date): void {
        const endDate = effectiveEndDate ? new Date(moment(effectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss')) : null;
        this.selectedEffectiveEndDate = effectiveEndDate;
        this.selectedEffectiveEndDateGrid = endDate;
    }

    @action
    setEffectiveStartDateForAddEdit(effectiveStartDate: Date): void {
        this.selectedEffectiveStartDateForAddEdit = effectiveStartDate;
    }

    @action
    setEffectiveEndDateForAddEdit(effectiveEndDate: Date): void {
        const endDate = effectiveEndDate ? new Date(moment(effectiveEndDate).endOf('month').format('YYYY-MM-DDTHH:mm:ss')) : null;
        this.selectedEffectiveEndDateForAddEdit = effectiveEndDate;
        this.selectedEffectiveEndDateForAddEditGrid = endDate;
    }

    @action
    setLossAllowance(lossAllowance: any): void {
        this.lossAllowance = lossAllowance;
    }

    @action
    setShrinkage(shrinkage: any): void {
        this.shrinkage = shrinkage;
    }

    @action
    async onCloseSystemFeeDetailsModal() {
        this.isShowModal = false;
        this.editableSystemFeeData = {};
        this.setSystemFeeValue(null);
        this.isEditingSystemFee = false;
    }

    @action
    handleCloseEndDateConfirmationModal(){
        this.systemFeeDetailsToSetEndDate = {};
        this.isShowEndDateConfirmationModal = false;
    }

    validateSystemFee(){
        const localValidatingErrorBody: any = [];
        let isValidSystemFee = true;
        if(this.selectedSystemGroupForAddEdit.label === '' || this.selectedSystemGroupForAddEdit.label === 'Select' || !this.selectedSystemGroupForAddEdit.label){
            const message = 'Please select Pipeline System Name.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }

        if(this.selectedEffectiveStartDateForAddEdit === '' || !this.selectedEffectiveStartDateForAddEdit){
            const message = 'Please select Effective Start Date.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if((this.selectedEffectiveEndDateForAddEditGrid !== '' || this.selectedEffectiveEndDateForAddEditGrid) && this.selectedEffectiveEndDateForAddEditGrid ? moment(this.selectedEffectiveEndDateForAddEditGrid).isBefore(this.selectedEffectiveStartDateForAddEdit):false){
            const message = 'Effective End Date can not be before Effective Start Date. Please select correct Effective End Date.';
            localValidatingErrorBody.push(message);
            isValidSystemFee = false;
        }
        if(this.selectedSystemGroupForAddEdit.label && this.selectedSystemGroupForAddEdit.label !== '' && this.selectedSystemGroupForAddEdit.label !== 'Select' && this.selectedEffectiveStartDateForAddEdit !== '' && this.selectedEffectiveStartDateForAddEdit){
            this.systemFeeMaintenanceData.map(fee => {
                if((this.editableSystemFeeData.pmcSystemFeeID !== fee.pmcSystemFeeID && this.selectedSystemGroupForAddEdit.label === fee.systemGroup
                    && moment(this.selectedEffectiveStartDateForAddEdit).isBetween(fee.effectiveStartDate, fee.effectiveEndDate ? fee.effectiveEndDate : '9999-12-31'))
                    || (this.editableSystemFeeData.pmcSystemFeeID !== fee.pmcSystemFeeID && this.selectedSystemGroupForAddEdit.label === fee.systemGroup && moment(this.selectedEffectiveStartDateForAddEdit).isSame(fee.effectiveStartDate))
                    || (this.editableSystemFeeData.pmcSystemFeeID !== fee.pmcSystemFeeID && this.selectedSystemGroupForAddEdit.label === fee.systemGroup && moment(this.selectedEffectiveStartDateForAddEdit).isSame(fee.effectiveEndDate ? fee.effectiveEndDate : '9999-12-31'))
                )
                {
                    if(fee.effectiveEndDate){
                        const message = 'This date range is in between existing system fee. Please change the date range.';
                        localValidatingErrorBody.push(message);
                        isValidSystemFee = false;
                    }else{
                        this.systemFeeDetailsToSetEndDate = fee;
                        isValidSystemFee = false;
                        this.isShowEndDateConfirmationModal = true;
                    }
                }
            })
        }
        return {
            isValidSystemFee: isValidSystemFee, 
            localValidatingErrorBody: localValidatingErrorBody
        };
    }

    @action
    async handleSaveSystemFees() : Promise<void> {
        const {isValidSystemFee, localValidatingErrorBody} = this.validateSystemFee();
        if(isValidSystemFee){
            const reqBody = {
                'pmcSystemFeeID': this.pmcSystemFeeID,
                'systemGroupID': this.getSelectedSystemGroupIDForAddEdit(),
                'facilityID': this.selectedFacilityIdForAddEdit ? this.selectedFacilityIdForAddEdit : null,
                'effectiveStartDate': new Date(moment(this.selectedEffectiveStartDateForAddEdit).format('YYYY-MM-DD')),
                'effectiveEndDate': this.selectedEffectiveEndDateForAddEditGrid ? new Date(moment(this.selectedEffectiveEndDateForAddEditGrid).format('YYYY-MM-DD')) : null,
                'primaryPLTariff': this.enteredPrimaryRate ? this.enteredPrimaryRate : 0,
                'surcharge': this.enteredSurcharge ? this.enteredSurcharge : 0,
                'secondaryPLTariff': this.enteredSecondaryRate ? this.enteredSecondaryRate : 0,
                'lossAllowance': this.lossAllowance.label && this.lossAllowance.label !== 'Select' && this.lossAllowance.label != '' ? parseFloat(this.lossAllowance.value).toFixed(2) : null,
                'injectionFee': this.enteredInjectionFee ? this.enteredInjectionFee : 0,
                'shrinkage': this.shrinkage.label && this.shrinkage.label != '' && this.shrinkage.label !== 'Select' ? (this.shrinkage.value).toString() : '',
                'receiptTermFee': this.enteredRecieptTerminalingRate ? this.enteredRecieptTerminalingRate : 0,
                'bS_W': this.bsw ? this.bsw : 0,
                'deliveryTermFee': this.deliveryTerminallingFee ? this.deliveryTerminallingFee : 0,
                'electronicReportingFee': this.enteredElectronicReportingFee ? this.enteredElectronicReportingFee : 0,
                'LMCIAbandonmentS_C': this.enteredLMCIRate ? this.enteredLMCIRate : 0,
                'lumpSumpFee': this.lumpSumFees ? this.lumpSumFees : 0,
                'qualityMgmtFee': this.enteredQualityMgmtFee ? this.enteredQualityMgmtFee : 0,
                'intraSystemTransferFee': this.intraSystemTransferFee ? this.intraSystemTransferFee : 0,
                'createUser': this.createdUser,
                'createDate': this.isEditingSystemFee ? this.editableSystemFeeData.createDate : new Date(),
                'updateUser': this.isEditingSystemFee ? this.createdUser : null,
                'updateDate': this.isEditingSystemFee ? moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]') : null,
            };
            const response: SystemFeeMaintenanceResponse[] = await this.sytemFeeMaintenanceDataService.saveSystemFeeDetails(reqBody);
            if(response.length > 0){
                this.uiService.toastService.success('System Fee saved successfully');
                this.setSystemFeeValue(null);
                this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
                systemFeeMaintenanceDataStore.isShowModal = false;
                this.onSearchClick();
            }else {
                this.uiService.toastService.error('There is an error while saving System Fee');
            }
        }else{
            if(!this.isShowEndDateConfirmationModal){
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
            }
        }
    }

    @action
    async setSystemFeeEndDate() : Promise<void> {
        const fee = this.systemFeeDetailsToSetEndDate;
        if(moment(this.selectedEffectiveStartDateForAddEdit).subtract(1, 'days').isBefore(fee.effectiveStartDate)){
            const message = 'Effective End Date value can not be set before Effective Start Date of existing system fee. Please select correct Effective Start Date of current system fee to set Effective End date of existing system fee.';
            errorHandler(message);
        }else{
            const reqBody = {
                    'pmcSystemFeeID': fee.pmcSystemFeeID,
                    'systemGroupID': fee.systemGroupID,
                    'facilityID': fee.facilityID,
                    'effectiveStartDate': fee.effectiveStartDate,
                    'effectiveEndDate': moment(this.selectedEffectiveStartDateForAddEdit).subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ss').toString(),
                    'primaryPLTariff': fee.primaryPLTariff,
                    'secondaryPLTariff': fee.secondaryPLTariff,
                    'lossAllowance': fee.lossAllowance,
                    'injectionFee': fee.injectionFee,
                    'shrinkage': fee.shrinkage,
                    'receiptTermFee': fee.receiptTermFee,
                    'bS_W': fee.bS_W,
                    'deliveryTermFee': fee.deliveryTermFee,
                    'electronicReportingFee': fee.electronicReportingFee,
                    'LMCIAbandonmentS_C': fee.lmciAbandonmentS_C,
                    'lumpSumpFee': fee.lumpSumpFee,
                    'qualityMgmtFee': fee.qualityMgmtFee,
                    'intraSystemTransferFee': fee.intraSystemTransferFee,
                    'createUser': fee.createUser,
                    'createDate': fee.createDate,
                    'updateUser': this.createdUser,
                    'updateDate': moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                };
            const response: SystemFeeMaintenanceResponse[] = await this.sytemFeeMaintenanceDataService.saveSystemFeeDetails(reqBody);
            if(response.length > 0){
                this.uiService.toastService.success(`Effective end date of existing System Fee is successfully updated to ${moment(this.selectedEffectiveStartDateForAddEdit).subtract(1, 'days').format('YYYY-MM-DD').toString()}`);
                this.systemFeeDetailsToSetEndDate = {};
                systemFeeMaintenanceDataStore.isShowEndDateConfirmationModal = false;
                this.onSearchClick();
            }else {
                this.uiService.toastService.error('There is an error while updating Effective end date of existing System Fee');
            }
        }
    }

    @action
    setSystemFeeValue = (data) => {
        if(data){
            this.pmcSystemFeeID = data.pmcSystemFeeID;
            this.selectedSystemGroupForAddEdit = {label:data.systemGroup,value:data.systemGroupID}
            this.selectedFacilityIdForAddEdit = data.facilityID;
            this.selectedEffectiveStartDateForAddEdit = data.effectiveStartDate ? new Date(data.effectiveStartDate) : null;
            this.selectedEffectiveEndDateForAddEditGrid = data.effectiveEndDate ? new Date(data.effectiveEndDate) : null;
            this.selectedEffectiveEndDateForAddEdit = data.effectiveEndDate ? new Date(moment(data.effectiveEndDate).startOf('month').format('YYYY-MM-DDTHH:mm:ss')) : null;
            this.enteredPrimaryRate = data.primaryPLTariff;
            this.enteredSurcharge = data.surcharge;
            this.enteredSecondaryRate = data.secondaryPLTariff;
            const lossAllowanceLabelValue = data.lossAllowance ? this.lossAllowanceData.find(x=>x.settingID==data.lossAllowance)?.valueText : null;
            const shrinkageLabelValue = data.shrinkage ? this.shrinkageData.find(x=>x.settingID==data.shrinkage)?.valueText : null;
            this.lossAllowance = {label:lossAllowanceLabelValue, value:data.lossAllowance};
            this.enteredInjectionFee = data.injectionFee;
            this.shrinkage = {label:shrinkageLabelValue, value:data.shrinkage};
            this.enteredRecieptTerminalingRate = data.receiptTermFee;
            this.bsw = data.bS_W;
            this.deliveryTerminallingFee = data.deliveryTermFee;
            this.enteredElectronicReportingFee = data.electronicReportingFee;
            this.enteredLMCIRate = data.lmciAbandonmentS_C;
            this.lumpSumFees = data.lumpSumpFee;
            this.enteredQualityMgmtFee = data.qualityMgmtFee;
            this.intraSystemTransferFee = data.intraSystemTransferFee;
        } else {
            this.pmcSystemFeeID = 0;
            this.selectedSystemGroupForAddEdit = { value: 'Select', label: 'Select' };
            this.selectedSystemGroupIDForAddEdit = 0;
            this.selectedFacilityIdForAddEdit = null;
            this.selectedEffectiveStartDateForAddEdit = null;
            this.selectedEffectiveEndDateForAddEdit = null;
            this.selectedEffectiveEndDateForAddEditGrid = null;
            this.enteredPrimaryRate = null;
            this.enteredSurcharge = null;
            this.enteredSecondaryRate = null;
            this.lossAllowance = { value: 'Select', label: 'Select' };
            this.enteredInjectionFee = null;
            this.shrinkage = { value: 'Select', label: 'Select' };
            this.enteredRecieptTerminalingRate = null;
            this.bsw = null;
            this.deliveryTerminallingFee = null;
            this.enteredElectronicReportingFee = null;
            this.enteredLMCIRate = null;
            this.lumpSumFees = null;
            this.enteredQualityMgmtFee = null;
            this.intraSystemTransferFee = null;
        }
        
    }

    getSelectedSystemGroupID = () =>{
        const systemGroupID = this.selectedSystemGroup.label === 'All' ? 0 : this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroup.label)?.settingID;
        return systemGroupID;
    }

    getSelectedSystemGroupIDForAddEdit = () =>{
        const systemGroupID = this.selectedSystemGroupForAddEdit ? this.systemGroupData.find(x=>x.valueText===this.selectedSystemGroupForAddEdit.label)?.settingID : 0;
        return systemGroupID;
    }

    @action
    setSystemFeeMaintenanceData(systemFeeMaintenanceData: SystemFeeMaintenanceResponse[]): void {
        this.systemFeeMaintenanceData = systemFeeMaintenanceData
    }

    @Loader
    @Catch(() => errorHandler(SystemFeeMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const localValidatingErrorBody: any = [];
        let isValidSearch = true;
        if(this.selectedEffectiveStartDate && this.selectedEffectiveEndDate ? moment(this.selectedEffectiveEndDate).isBefore(this.selectedEffectiveStartDate):false){
            const message = 'Effective End Date can not be before Effective Start Date. Please select correct Effective End Date.';
            localValidatingErrorBody.push(message);
            isValidSearch = false;
        }
        if (isValidSearch) {
            const systemGroupID = this.getSelectedSystemGroupID();
            const facilityID = this.selectedFacilityID;
            const effectiveStartDate = this.selectedEffectiveStartDate ? moment(this.selectedEffectiveStartDate).format('YYYY-MM-DD') : '9999-12-31';
            const effectiveEndDate = this.selectedEffectiveEndDateGrid ? moment(this.selectedEffectiveEndDateGrid).format('YYYY-MM-DD') : '9999-12-31';
            const response = await this.sytemFeeMaintenanceDataService.getSystemFeeDataWithFilter(systemGroupID, facilityID, effectiveStartDate, effectiveEndDate);
            runInAction(() => {
                this.showLoader = false;
                this.modifiedSystemFeeMaintenanceData = response;
                this.systemFeeMaintenanceData = response;
                this.setSystemFeeMaintenanceData(response);
                this.isRefreshed = false;
            })
            this.agGridService.getNodes()?.refreshCells();
            dataStore.setShowUnsavedWarningAlert(false);
        } else {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    @Loader
    @Catch(() => errorHandler(SystemFeeMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        const response = await this.sytemFeeMaintenanceDataService.getSystemFeeDataWithoutFilter();
        runInAction(() => {
            this.showLoader = false;
            this.selectedSystemGroup = { value: 'All', label: 'All' };
            this.selectedEffectiveStartDate = null;
            this.selectedEffectiveEndDate = null;
            this.selectedEffectiveEndDateGrid = null;
            this.modifiedSystemFeeMaintenanceData = response;
            this.systemFeeMaintenanceData = response;
            this.setSystemFeeMaintenanceData(response);
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };
}