import { ColDef } from 'ag-grid-community';
import { TariffDetailIDCellRenderer } from './cell-renderer/tariff-detail-id';

export const rightAngleXRefColDef: ColDef[] = [
    {   
        headerName: 'Tariff Number',
        field: 'tariffNumber', 
        colId:'tariffNumber',
        cellRenderer: TariffDetailIDCellRenderer, 
        minWidth: 130,
        filter: true,
        sortable: true,
        flex: 1,
    },
    {   
        headerName: 'Asset Group',
        field: 'assetGroup', 
        colId:'assetGroup',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 140,
        flex: 1,
    },
    {   
        headerName: 'Pipeline System',
        field: 'pipelineSystem', 
        colId:'pipelineSystem',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 260,
        flex: 1,
    },
    {   
        headerName: 'Operator',
        field: 'operator', 
        colId:'operator',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {   
        headerName: 'Distribution',
        field: 'distribution', 
        colId:'distribution',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        flex: 1,
    },
    {   
        headerName: 'Tariff Rate Type',
        field: 'tariffRateType', 
        colId:'tariffRateType',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 150,
        flex: 1,
    },
    {   
        headerName: 'RA Name(Price Curve)',
        field: 'raName', 
        colId:'raName',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
];

