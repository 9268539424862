import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from 'common/services/ag-grid-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from 'features/account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import moment from 'moment';
import _ from 'lodash';
import { IndexRateMaintenanceDataService } from '../services/index-rate-maintenance-data-service';
import { IndexRateMaintenanceMessages } from '../constants/enum';
import { AppConstant } from 'app_constants';


export class IndexRateMaintenanceDataStore {
    @observable createdUser;

    @observable showLoader = false;
    @observable enteredIndexRate = '';
    @observable effectiveDate: any;
    @observable issueDate: any;
    @observable historyGridData:any[] = [];
    @observable indexRateDetails: any;

    constructor(
        public indexRateMaintenanceDataService: IndexRateMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.createdUser = this.accountStore.displayName;
        this.enteredIndexRate = '';
        this.loadViewModel();
    }


    @action
    setEnteredIndexRate(options){
        this.enteredIndexRate = options
    }

    @action
    setEffectiveDate(value) {
        this.effectiveDate = value;
    }

    @action
    setIssueDate(value) {
        this.issueDate = value;
    }


    @Loader
    @action
    @Catch(() => errorHandler(IndexRateMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const response = await this.indexRateMaintenanceDataService.getIndexRate();
        if(response) {
            this.showLoader = false;
            this.indexRateDetails = response
            this.setEnteredIndexRate(response.indexRate ? response.indexRate : '');
            this.setEffectiveDate(response.effStartDate ? moment(response.effStartDate).format('YYYY-MM-DD') : '');
            this.setIssueDate(response.tariffIssueDate ? moment(response.tariffIssueDate).format('YYYY-MM-DD') : '');
        } 
    }
  

     @Loader
     @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveIndexRate(): Promise<void> {
        const {isValidRate, localValidatingErrorBody} = this.validateRate();

        if(isValidRate){
            const requestBody: any =  {
               indexRate: this.enteredIndexRate,
               createdUser : this.createdUser,
               effectiveDate: this.effectiveDate,
               issueDate : this.issueDate
            };
            if(requestBody.length !== 0) {
                const response: any = await this.indexRateMaintenanceDataService.saveIndexRate(requestBody);
                if(response){         
                    this.uiService.toastService.success('Index Rate Updated Successfully with value: ' + response.indexRate)
                    this.setEnteredIndexRate(response.indexRate ? response.indexRate : '');
                    this.setEffectiveDate(response.effStartDate ? moment(response.effStartDate).format('YYYY-MM-DD') : '');
                    this.setIssueDate(response.tariffIssueDate ? moment(response.tariffIssueDate).format('YYYY-MM-DD') : '');
                    dataStore.setShowUnsavedWarningAlert(false);
                } 
            }  
        }else{
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }       
     }

     validateRate(){
        const localValidatingErrorBody: any = [];
        let isValidRate = true;
        if(!this.enteredIndexRate || this.enteredIndexRate === ''){
            const message = 'Please enter a valid Index Rate.';
            localValidatingErrorBody.push(message);
            isValidRate = false;
        }
        if(!this.effectiveDate || this.effectiveDate === ''){
            const message = 'Please enter a valid Effective Date.';
            localValidatingErrorBody.push(message);
            isValidRate = false;
        }
        if(!this.issueDate || this.issueDate === ''){
            const message = 'Please enter a valid Issue Date.';
            localValidatingErrorBody.push(message);
            isValidRate = false;
        }
        return {
            isValidRate: isValidRate, 
            localValidatingErrorBody: localValidatingErrorBody
        };
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    @action
    reset(): void {
        this.setEnteredIndexRate('');
    }

}