import { ColumnApi, GridApi, GridOptions, GridReadyEvent, ColDef, ColumnMovedEvent, ColumnPinnedEvent, ColumnResizedEvent, ColumnVisibleEvent, ColumnState, ApplyColumnStateParams, RowGroupingDisplayType, RowSelectedEvent, IDatasource } from 'ag-grid-community/dist/lib';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/shared/changeDetectionService';
import { AgGridComponentParams } from '../domain/model';
import { accountStore } from 'features/account/stores';
import { dataStore } from 'common/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import { cpDataService } from 'features/master-data-management/connection-point-maintenance/services/cp-data-service';
import { connectionPointMaintenanceDataStore } from 'features/master-data-management/connection-point-maintenance/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { profitCenterMaintenanceDataService } from 'features/master-data-management/profit-center-maintenance/services/profit-center-maintenance-data-service';
import { profitCenterMaintenanceDataStore } from 'features/master-data-management/profit-center-maintenance/stores';
/* eslint-disable */

export class AgGridService {
    private columnApi: ColumnApi | undefined;
    private gridApi: GridApi | undefined;

    private setColumnApi(columnApi: ColumnApi): void {
        this.columnApi = columnApi;
    }

    public setGridApi(gridApi: GridApi): void {
        this.gridApi = gridApi;
    }

    getNodes() {
        return this.gridApi;
    }

    getRenderedNodes() {
        return this.gridApi?.getRenderedNodes();
    }

    getColumns() {
        return this.columnApi;
    }

    updateEditableProperty(columnKey: string, value: boolean): void {
        if (this.columnApi) {
            const column = this.columnApi.getColumn(columnKey);
            if(column) {
                column.getColDef().editable = value;
            }
        }
    }

    getAllFilters(): any {
        let result;
        if (this.gridApi) {
            result = this.gridApi.getFilterModel();
        }
        return result;
    }

    setAllFilters(filters: any): void {
        if (this.gridApi) {
            this.gridApi.setFilterModel(filters);
            this.gridApi.onFilterChanged();
        }
    }

    setColumnVisibility(columns: any): void {
        if (this.columnApi && columns) {
            // columns.forEach((col) => {
            //     this.columnApi?.setColumnVisible(col.colId, !col.hide);
            //     this.columnApi?.setColumnPinned(col.colId, col.pinned);
            // })
            let columnArrangement = { state: columns,
                applyOrder: true} as ApplyColumnStateParams;
            this.columnApi.applyColumnState(columnArrangement);
        }
    }

   
    

    clearAllFilters() {
        if (this.gridApi) {
            this.gridApi.setFilterModel(null);
            this.gridApi.onFilterChanged();
        }
    }

    setColDef(columnKey: string, def: ColDef): void {
        if (this.columnApi) {
            const column = this.columnApi.getColumn(columnKey);
            if(column) {
                column.setColDef(def, def);
            }
        }
    }

    updateOptionCellEditorValues<T>(cellValues: T[], columnKey: string, modelKey: Extract<keyof T, string>): void {
        if (this.columnApi) {
            const column = this.columnApi.getColumn(columnKey);
            console.log(column);
            console.log(cellValues);
            if(column) {
                column.getColDef().cellEditorParams = {
                    values: cellValues.map(item => item[modelKey])
                };
            }
        }
    }

    addNewRowToGrid<T>(row: T, rowIndex: number) {
        this.gridApi?.updateRowData({ add: [row], addIndex: rowIndex });
    }

    private customizeGridConfig() {
        return {
            defaultColDef: {
                resizable: true,
                filter: true,
                sortable: true,
                accentedSort: true,
                comparator: (a: any, b: any) => typeof a === 'string' ? a.localeCompare(b) : (a > b ? 1 : (a < b ? -1 : 0))
            },
            rowDataChangeDetectionStrategy: ChangeDetectionStrategyType.DeepValueCheck,
            suppressScrollOnNewData: true,
            enableRangeSelection: true,
            rememberGroupStateWhenNewData: true,
            suppressAggFuncInHeader: true,
            pagination: true,
            paginationPageSize: 100,
            cacheOverflowSize: 2,
            infiniteInitialRowCount: 0,
            cacheBlockSize: 100,
            // enableServerSideSorting: true,
            // enableServerSideFilter: true
            // // how many pages to hold in the cache.
            // maxBlocksInCache: 2,
            maxConcurrentDatasourceRequests: 2
        };
    }

    getGridConfig(props: AgGridComponentParams, gridId?: string): GridOptions {
        dataStore.setAgGridId(gridId);
        const {
            rowData,
            columnDefs,
            domLayout,
            onRowClicked,
            onCellClicked,
            onCellEditingStarted,
            onCellEditingStopped,
            onRangeSelectionChanged,
            onFirstDataRendered,
            onRowDataUpdated,
            onRowSelected, 
            onFilterChanged = (event) => this.onFilterChanged(event, gridId),
            groupDisplayType = 'singleColumn',
            groupIncludeFooter = false,
            // animateRows = true, // Optional - set to 'true' to have rows animate when sorted
            rowSelection =  'multiple',// Options - allows click selection of rows 
            // rowDeselection= 'true',
            // suppressRowClickSelection= true,
            // rowMultiSelectWithClick= true,
            onCellValueChanged,
            onSelectionChanged,
            aggFuncs,
            onPaginationChanged,
            rowModelType,
            cacheBlockSize,
            context
        } = props;

        // const statusBar = {
        //     statusPanels:  [
        //         {
        //           statusPanel: 'agTotalAndFilteredRowCountComponent',
        //           align: 'left',
        //         },
        //         {
        //           statusPanel: 'agTotalRowCountComponent',
        //           align: 'center',
        //         },
        //         { statusPanel: 'agFilteredRowCountComponent' },
        //         { statusPanel: 'agSelectedRowCountComponent' },
        //         { statusPanel: 'agAggregationComponent' },
        //         {
        //             statusPanel: PageSizeComponent,
        //             align: 'left',
        //           }
        //       ],
        // };
        
        // const excelStyle = [{
        //     id: 'decimal-format',
        //     numberFormat: {
        //         format: '###,##0.000'
        //     }},{
        //     id: 'comma-format',
        //     numberFormat: {
        //         format: '###,###.###'
        //     }},{
        //     id: 'work-wait',
        //     numberFormat: {
        //         format: '###,###'
        //     }}]

        const gridOptions: GridOptions = {
            columnDefs,
            rowData: rowData,
            domLayout: domLayout,
            // excelStyles: excelStyle,
            onRowClicked,
            onCellClicked,
            onColumnMoved: (event) => this.onColumnMoved(event, gridId),
            onColumnPinned: (event) =>  this.onColumnPinned(event, gridId),
            onColumnResized: (event) =>  this.onColumnResized(event, gridId),
            onColumnVisible: (event) =>  this.onColumnVisible(event, gridId),
            onRowSelected: onRowSelected, 
            onCellEditingStarted,
            onCellEditingStopped,
            onFirstDataRendered,
            onRowDataUpdated,
            onGridReady: (event) => this.handleGridReady(event, gridId),
            groupDisplayType: groupDisplayType as RowGroupingDisplayType, // statusBar: statusBar,
            onRangeSelectionChanged,
            rowSelection: rowSelection,
            rowMultiSelectWithClick: true,
            suppressCopyRowsToClipboard:true,
            aggFuncs : aggFuncs,
            suppressRowClickSelection: true,
            onCellValueChanged,
            onFilterChanged,
            groupIncludeFooter: groupIncludeFooter,
            onSelectionChanged,
            onPaginationChanged,
            maintainColumnOrder: true,
            rowModelType,
            cacheBlockSize,
            overlayLoadingTemplate: '<span className="ag-overlay-loading-center">Please wait while your rows are loading...</span>',
            overlayNoRowsTemplate: '<span className="ag-overlay-loading-center">No rows to display.</span>',
            context
        };
        const config = this.customizeGridConfig();
       
        return { ...gridOptions, ...config };
    }

    onColumnMoved = (event: ColumnMovedEvent, gridId = ''): void => {
        const { api, columnApi } = event;
        const userId= accountStore.userName;
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getAgGridId();
        localStorageService.set(gridId + '_' + userId, columnState);
    }

    onColumnPinned = (event: ColumnPinnedEvent, gridId = ''): void => {
        const { columnApi } = event;
        const userId= accountStore.userName;
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getAgGridId();
        localStorageService.set(gridId + '_' + userId, columnState);
    }

    onColumnResized = (event: ColumnResizedEvent, gridId = ''): void => {
        const { columnApi } = event;
        const userId= accountStore.userName;
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getAgGridId();
        localStorageService.set(gridId + '_' + userId, columnState)
    }
    
    onColumnVisible = (event: ColumnVisibleEvent, gridId = ''): void => {
        const { columnApi } = event;
        const userId= accountStore.userName;
        var columnState = columnApi.getColumnState();
        let moduleName = dataStore.getAgGridId();
        localStorageService.set(gridId + '_' + userId, columnState)
    }

    onFilterChanged = (event: { api: any; }, gridId = ''): void => {
        const { api } = event;
        const filterModel = api.getFilterModel();
        const moduleName = dataStore.getAgGridId();
        localStorageService.set(gridId + '_filter', filterModel);
    }  
    handleGridReady = (event: GridReadyEvent, gridId = ''): void => {
        const { api, columnApi } = event;
        const userId= accountStore.userName;
        let moduleName = dataStore.getAgGridId();
        // api.sizeColumnsToFit();
        // const filter = localStorageService.get(moduleName + '_filter');
        // api.setFilterModel(filter);
        const columnState =  localStorageService.get(gridId + '_' + userId);
        try {
            if (columnState) {
                let columnArrangement = { state: columnState,
                    applyOrder: true} as ApplyColumnStateParams;
                const result = columnApi.applyColumnState(columnArrangement);
                // columnApi.az
                // columnApi.sizeColumnsToFit()
                console.log(result)
            }
        }
        catch (e) {
            // localStorageService.remove(moduleName + '_' + userId);
            console.log('error in getting Column arrangement for module', e);
        }
        this.setColumnApi(columnApi);
        this.setGridApi(api);
        dataStore.onGridReady();        
    };

    handlerRowSelected = (e: { rowIndex: any; type: any; preventDefault: () => void; }):void => {
        console.log("row selected", e.rowIndex, e.type);
        e.preventDefault();
    }

    sortGrid(colId, sortType = 'desc' as 'desc') {
        console.log('sorting', this.columnApi);
        this.columnApi?.applyColumnState({
          state: [{ colId: colId, sort: sortType }]
        });
    }
    
    refreshGrid () {
        this.gridApi?.refreshCells({force: true});
    }

    async sizeColumnsToFit () {
        await this.gridApi?.sizeColumnsToFit();
    }

    redrawRows () {
        this.gridApi?.redrawRows();
    }

    getDisplayedRowCount() {
        return this.gridApi?.getRenderedNodes().length;
    }
    getSelectedRows() {
        return this.gridApi?.getSelectedRows();
    }
    deselectAllRows() {
        return this.gridApi?.deselectAll();
    }
    paginationSetPageSize(value) {
        return this.gridApi?.paginationSetPageSize(value);
    }

    paginationGetCurrentPage() {
        return  this.gridApi?.paginationGetCurrentPage()
    }
    paginationGetPageSize() {
        return this.gridApi?.paginationGetPageSize();
    }
    scrollToSelectedRow(){
        this.gridApi?.paginationGoToFirstPage();
        this.gridApi?.ensureIndexVisible(0);
    }

    setSelectedNodes() {
        this.gridApi?.forEachNode((node) =>
          node.setSelected(node.data.isSelected)
        );
    };

    destroyGrid() {
        this.gridApi?.destroy();
        this.gridApi = undefined;
    }

    setDatasource(datasource: IDatasource) {
        if(this.gridApi) {
            this.gridApi?.setDatasource(datasource)
        } 
    }

    GoToCurrentPage(number) {
        this.gridApi?.paginationGoToPage(number);
    }

    getModel() {
        this.gridApi?.getFilterModel();
    }

    getSortModel() {
        const listOfSortModel = this.columnApi?.getColumnState().filter(s => s.sort !== null)
        return listOfSortModel;
    }
    
    getFilterValue() {
        if (this.gridApi) {
            const result = this.gridApi.getFilterModel();
            return result
        }
       return null;
    }

    showLoadingOverlay() {
        if(this.gridApi)
        this.gridApi.showLoadingOverlay();
    }

    hideOverlay() {
        if(this.gridApi)
        this.gridApi.hideOverlay();
    }

    showNoRowsOverlay() {
        if(this.gridApi)
        this.gridApi.showNoRowsOverlay();
    }

    resetCache(pageSize) {
        if(this.gridApi) {
            const api: any = this.gridApi;
            api.gridOptionsWrapper?.setProperty('cacheBlockSize', pageSize);
        }
    }

   async removeSortModel() {
        const colState = await this.columnApi?.getColumnState();
        if(colState && colState.length > 0) {
            colState.forEach(s => s.sort = null);
            let columnArrangement = { state: colState,
                applyOrder: true} as ApplyColumnStateParams;
            const result = this.columnApi?.applyColumnState(columnArrangement);
        }
    }

    

}

export const agGridService = new AgGridService();
