import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import moment from 'moment';
import { BaseTariffDataService } from '../services/base-tariff-data-service';
import { BaseTariffResponse } from '../model/model';
import history from '../../../../shared/components/header-component/component/history';
import { AccountStore } from '../../../account/stores/account-store';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';


export class BaseTariffDataStore {
    @observable baseTariffData: BaseTariffResponse[] = [];
    @observable showLoader = false;
    @observable selectedJurisdictionName;
    @observable selectedTariffType = { value: 'Rules and Regulations', label: 'Rules and Regulations' };
    @observable selectedProductType;
    @observable selectedTariffAgency = { value: 'FERC', label: 'FERC' };
    @observable selectedRulesAndRegulations;
    @observable selectedStatus;
    @observable selectedTariff;
    @observable selectedFilingCarrier={ value: 'Plains Pipeline, L.P.', label: 'Plains Pipeline, L.P.' };
    @observable selectedBaseTariff;
    @observable jurisdictionDropdownOptions: any[] = [];
    @observable tariffTypesDropdownOptions: any[] = [];
    @observable productTypesDropdownOptions: any[] = [];
    @observable fillingCarrierDropdownOptions: any[] = [];
    @observable fillingCarrierDropdownOptionsUI: any[] = [];
    @observable pipelineAreaDropdownOptions: any[] = [];
    @observable tariffAgencyDropdownOptions: any[] = [];
    @observable selectedPipelineArea = '';
    @observable selectedPipelineAreaGrid: any[] = [];
    @observable selectedComments = '';
    @observable pipelineIds: any[] = [];
    @observable state;
    
    jurisdictionData: any[] = [];
    tariffTypesData: any[]  = [];
    productTypesData: any[] = [];
    fillingCarrierData: any[] = [];
    pipelineAreaData: any[] = [];
    tariffAgencyData: any[]=[];
    
    @observable createdUser;
    @observable createdDate;

    constructor(
        public baseTariffDataService: BaseTariffDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.selectedJurisdictionName = 'USA';
        this.selectedProductType= 'Crude Oil';
        this.state=history?.location?.state as {workflowId?: string, from?: string} || '';
        this.selectedTariffAgency={ value: 'FERC', label: 'FERC' };
        this.selectedRulesAndRegulations='';
        this.selectedComments=''
        this.selectedTariffType={ value: 'Rules and Regulations', label: 'Rules and Regulations' };
        this.selectedStatus=true;
        this.selectedTariff='0';
        this.selectedFilingCarrier={ value: 'Plains Pipeline, L.P.', label: 'Plains Pipeline, L.P.' };
        this.selectedBaseTariff = 'PPL' +  ' ' + this.selectedTariffAgency.label + ' ' + this.selectedTariff;
        this.createdDate = new Date().toDateString(); 
        this.createdUser = this.accountStore.displayName;
        this.getJurisdictionDropdownData();
        this.getTariffTypesDropdownData();
        this.getProductTypesDropdownData();
        this.getTariffAgencyDropdownData();
        this.getFillingCarrierDropdownData();
        // this.getPipelineAreaDropdownData(1);  
        
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getJurisdictionDropdownData(): Promise<void> {
        const jurisdictionDropdownOptions = await this.baseTariffDataService.getJurisdictionDropdownData();
        this.jurisdictionData = jurisdictionDropdownOptions;
        this.showLoader = false;
        const a = (jurisdictionDropdownOptions.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const finalArray: any [] = [];
        b.forEach((item) => {
            finalArray.push({valueText : item})
        })
        this.setJurisdictionDropDownOptions(finalArray)    
    }

    @action
    setJurisdictionDropDownOptions(finalArray){
        this.jurisdictionDropdownOptions=finalArray
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffTypesDropdownData(): Promise<void> {
        const tariffTypeDropdownOptions = await this.baseTariffDataService.getTariffTypes();
        this.tariffTypesData = tariffTypeDropdownOptions;
        this.showLoader = false;
        const a = (tariffTypeDropdownOptions.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const finalArray: any [] = [];
        b.forEach((item) => {
            finalArray.push({ 'value': item, 'label': item})
        })
        this.setTariffTypesDropDownOptions(finalArray)
    }

    
    @action
    setTariffTypesDropDownOptions(finalArray){
        this.tariffTypesDropdownOptions=finalArray
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getProductTypesDropdownData(): Promise<void> {
        const productTypesDropdownOptions = await this.baseTariffDataService.getProductTypesDropdownData();
        this.productTypesData = productTypesDropdownOptions;
        this.showLoader = false;
        const a = (productTypesDropdownOptions.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const finalArray: any [] = [];
        b.forEach((item) => {
            finalArray.push({valueText : item})
        })
        this.setProductTypesDropDownOptions(finalArray)
    }

    
    @action
    setProductTypesDropDownOptions(finalArray){
        this.productTypesDropdownOptions=finalArray
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffAgencyDropdownData(): Promise<void> {
        const tariffAgencyDropdownOptions = await this.baseTariffDataService.getTariffAgency(this.selectedJurisdictionName);
        this.tariffAgencyData = tariffAgencyDropdownOptions;
        this.showLoader = false;
        const a = (tariffAgencyDropdownOptions.map(x => {return { 'value': x.agencycode, 'label': x.agencycode}}));
        this.setTariffAgencyDropdownOptions(a);
    }

    @action
    setTariffAgencyDropdownOptions(options){
        this.tariffAgencyDropdownOptions = options
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getFillingCarrierDropdownData(): Promise<void> {
        const fillingCarrierDropdownOptions = await this.baseTariffDataService.getFillingCarrierDropdownData();
        this.fillingCarrierData = fillingCarrierDropdownOptions;
        this.showLoader = false;
        const a = (fillingCarrierDropdownOptions.map(x => {return { 'filingCode': x.filingCode, 'ctsfilingid': x.entityID, 'name': x.name, 'action': x.action}}));
        const b = fillingCarrierDropdownOptions.filter((i) => i.action !== 'D').map(x => { return { 'value': x.name, 'label': x.name } });
        this.fillingCarrierDropdownOptions = a;
        this.setFillingCarrierDropDownOptions(b);
        this.addValuesInCellDropdowns();
    }

    @action
    setFillingCarrierDropDownOptions(dropdownOptions){
        this.fillingCarrierDropdownOptionsUI = dropdownOptions;
    }

    // @Loader
    // @action
    // @Catch(() => errorHandler('Fetch Error'))
    // async getPipelineAreaDropdownData(id: number): Promise<void> {
    //     const pipelineAreaDropdownOptions = await this.baseTariffDataService.getPipelineAreaDropdownData(id);
    //     this.pipelineAreaData = pipelineAreaDropdownOptions;
    //     this.showLoader = false;
    //     const a = (pipelineAreaDropdownOptions.map(x => {return { 'plname': x.plname, 'id': x.ctspipelineid}}));
    //     const b = a.reduce((acc,current, index)=> acc + (index!==0?', ':'') + current.plname, '');
    //     this.setPipelineIds(a.map(x=>x.id));
    //     this.setPipelineAreaDropdownOptions(a);
    //     this.setPipelineArea(b)
    // }

    // @action
    // updateShowUnsavedWarningAlert = (): void => {
    //     dataStore.setShowUnsavedWarningAlert(true);
    // };

    @action
    setPipelineAreaDropdownOptions(options){
        this.pipelineAreaDropdownOptions=options
    }

    @action
    setPipelineIds(ids){
        this.pipelineIds=ids
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineAreaGrid(rowId:number, rowData: BaseTariffResponse, id: number): Promise<void> {
        // const pipelineAreaDropdownOptions = await this.baseTariffDataService.getPipelineAreaDropdownData(id);
        // this.pipelineAreaData = pipelineAreaDropdownOptions;
        // this.showLoader = false;
        // const a = (pipelineAreaDropdownOptions.map(x => {return { 'plname': x.plname, 'id': x.ctspipelineid}}));
        // const pipelineString = a.reduce((acc,current, index)=> acc + (index!==0?', ':'') + current.plname, '');
        // const pipelineIds= a.map(x=>x.id);
        // this.selectedPipelineAreaGrid.push({pipelineString, id, pipelineIds});
        const filingCarrier = this.fillingCarrierDropdownOptions.filter(x=>x.name===rowData?.jointCarrier)[0]?.filingCode;
        const currentRow = this.baseTariffData.find(x=> x.rowId===rowId); 
        if (currentRow){
            currentRow['baseTariff']= filingCarrier +  ' ' + 'JT' + ' ' + this.selectedTariffAgency.label + ' ' + this.selectedTariff;
            // currentRow['pipelineArea']= this.selectedPipelineAreaGrid.find(x=>x.id===id)?.pipelineString;
            // currentRow['pipelineIds']= this.selectedPipelineAreaGrid.find(x=>x.id===id)?.pipelineIds;
        }
        this.agGridService.getNodes()?.refreshCells();
    }

    @action
    setBaseTariffGridData(baseTariffData: BaseTariffResponse[]): void {
        this.baseTariffData = baseTariffData
    }

    @action
    setJurisdictionName(jurisdiction: string): void {
        this.selectedJurisdictionName = jurisdiction;
    }

    @action
    setTariffType(tariff: any): void {
        this.selectedTariffType = tariff;
    }

    @action
    setProductType(productType: string): void {
        this.selectedProductType = productType;
    }

    @action
    setRulesAndRegulations(rulesAndRegulations: string): void {
        this.selectedRulesAndRegulations = rulesAndRegulations;
    }

    
    @action
    setStatus(status: boolean): void {
        this.selectedStatus = status;
    }

    @action
    setBaseTariff(baseTariff: string): void {
        this.selectedBaseTariff = baseTariff;
    }

    @action
    setPipelineArea(pipelineArea: string): void {
        this.selectedPipelineArea = pipelineArea;
    }

    @action
    setComments(comments: string): void {
        this.selectedComments = comments;
        // dataStore.setShowUnsavedWarningAlert(true);
    }

    @computed
    get BaseTariffData() {
        return toJS(this.baseTariffData);
    }

    @action
    isEditableSettingName(params) {
         console.log(params);
         this.addValuesInCellDropdowns();
         return true;
    }
 
    addValuesInCellDropdowns(): void {
         this.agGridService.updateOptionCellEditorValues(
            this.fillingCarrierDropdownOptions.filter(x=>x.name!==this.selectedFilingCarrier.label && x.action !== 'D'),
            'jointCarrier', 
            'name',
         );
     }

     @Loader
     @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveTariffBase(): Promise<void> {
        const gridRowMapping = this.baseTariffData.map(x=> {return {
            'baseTariff': x.baseTariff,
            // 'pipelineId': x.pipelineIds,
            'jointCarrierId': this.fillingCarrierDropdownOptions.find(filingCarrier=>filingCarrier.name===x.jointCarrier)?.ctsfilingid,
            'createUser': x.createdBy,
            'createDate': moment.utc(x.createdDate).format('YYYY-MM-DDTHH:mm:ss')
        }})
        const requestBody: any =  {
            'tariffbaseid':0, 
            'jurisdictionId':this.jurisdictionData.find(x=>x.valueText===this.selectedJurisdictionName)?.settingID, 
            'tariffTypeId': this.tariffTypesData.find(x=>x.valueText===this.selectedTariffType.label)?.settingID, 
            'productId': this.productTypesData.find(x=>x.valueText===this.selectedProductType)?.settingID,
            'agencyId': this.tariffAgencyData.find(x=>x.agencycode===this.selectedTariffAgency.label)?.agencyid,
            'rulesReg': this.selectedRulesAndRegulations,
            'tariffStatus': this.selectedStatus,
            'comments': this.selectedComments,
            'filingCarrierID': this.fillingCarrierDropdownOptions.find(x=>x.name===this.selectedFilingCarrier.label)?.ctsfilingid,
            'baseTariff': this.selectedBaseTariff,
            'tariffNumber': this.selectedTariff,
            // 'pipelineId': this.pipelineIds,
            'createUser': this.createdUser,
            'createDate': moment.utc(this.createdDate).format('YYYY-MM-DDTHH:mm:ss'),
            'tariffBaseDetails':gridRowMapping 
        };
        if(requestBody.length !== 0) {
            let errorSummary: ToastMessage[] = [];
            const response: any[] = await this.baseTariffDataService.saveBaseTariff(requestBody);
            if(response){
                // dataStore.setShowUnsavedWarningAlert(false);
                for (const key in response) {                       
                    if (key==='tariffBaseID') {
                        this.uiService.toastService.success('Base Tariff Created Successfully with tariffBaseID: ' + response[key]);
                        history.push(this.state.from==='step2'?'/project-request-step-2':'/Dashboard', {workflowId:this.state?.workflowId});
                    }else {
                        if (key==='errors') {
                            for (const errorKey in response[key]) { 
                                errorSummary = [...errorSummary, { id: errorKey, description: response[key][errorKey].toString() }];
                            }                        
                            this.uiService.toastService.error('', {}, errorSummary);
                        }
                    }
                }
            } 
        }        
     }
  
    @action
    reset(): void {
        this.setBaseTariffGridData([]);
    }

    isEditDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };
}
