import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { profitCenterMaintenanceDataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ProfitCenterNameCellRenderer } from './cell-renderers/profit-center-name-cell-renderer';

const filterParams = {
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
    textFormatter: (r) => {
        if (r == null) return null;
        return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    suppressAndOrCondition: true,
  };

  const dateFilterParams = {
    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],

      // provide comparator function
      comparator: function (filterLocalDateAtMidnight, cellValue) {

          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts  = cellValue.split('/');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(day, month, year);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
              return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
          } else {
              return 0;
          }
      },
    debounceMs: 200,
    suppressAndOrCondition: true,
  }

export const profitCenterColDef: ColDef[] = [
    {   
        headerName: 'Select',
        field: 'select', 
        colId:'Select',
        checkboxSelection:  true, 
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 40, 
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true
    },
    { 
        headerName: 'Profit Center', 
        field: 'profitCenter',
        colId: 'profitCenter',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true,
        editable: false, 
        // cellRenderer: ProfitCenterNameCellRenderer,
        cellClass: (params) => {return  params && params.data && params.data.action &&  params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        minWidth: 200, 
        valueGetter: params => {
            if (!params.data || params.data['profitCenter'] === null) {
                return '';
            } else {
                return params.data['profitCenter'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Description', 
        field: 'profitCenterDesc', 
        colId: 'profitCenterDesc',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true,
        minWidth: 100,
        cellRenderer: ProfitCenterNameCellRenderer,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' :  ''},
        valueGetter: params => {
           if (!params.data || params.data['profitCenterDesc'] === null) {
                return '';
            } else {
                return params.data['profitCenterDesc'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Pipeline', 
        field: 'plName',
        colId: 'plName',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true, 
        minWidth: 100,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
           if (!params.data || params.data['plName'] === null) {
                return '';
            } else {
                return params.data['plName'];
            }
        },
        suppressSizeToFit: true
    },
    // { 
    //     headerName: 'Profit Center Group', 
    //     field: 'profitCntrGrp', 
    //     colId: 'profitCntrGrp',
    //     filter: 'agTextColumnFilter',
    //     filterParams: filterParams, 
    //     sortable: true,
    //     minWidth: 100,
    //     cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' :  ''},
    //     valueGetter: params => {
    //        if (!params.data || params.data['profitCntrGrp'] === null) {
    //             return '';
    //         } else {
    //             return params.data['profitCntrGrp'];
    //         }
    //     },
    //     suppressSizeToFit: true
    // },
    { 
        headerName: 'Valid To Date',
        field: 'validToDate',
        colId: 'validToDate',  
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
           if (!params.data || params.data['validToDate'] === null) {
                return '';
            } else {
                return moment(params.data['validToDate']).format(AppConstant.DATE_TIME_FORMAT);
            }

         },
         suppressSizeToFit: true
    },
    { 
        headerName: 'Valid From Date',
        field: 'validFromDate',
        colId: 'validFromDate',  
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        sortable: true,
        editable: false,
        width: 140,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
           if (!params.data || params.data['validFromDate'] === null) {
                return '';
            } else {
                return moment(params.data['validFromDate']).format(AppConstant.DATE_TIME_FORMAT);
            }

        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser', 
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
           if (!params.data || params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Created Date',
        field: 'createDate',
        colId: 'createDate',  
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
           if (!params.data || params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }

        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Updated User', 
        field: 'updateUser',
        colId: 'updateUser',   
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true,
        editable: false,
        width: 120,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
           if (!params.data || params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Updated Date', 
        field: 'updateDate', 
        colId: 'updateDate',   
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams, 
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
           if (!params.data || params.data['updateDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT)
            }
        },
        suppressSizeToFit: true 
    },
   
    { 
        headerName: 'Sync Status', 
        field: 'syncStatus', 
        colId: 'syncStatus',
        width: 130,
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
           if (!params.data || params.data['syncStatus'] === null) {
                return '';
            } else {
                return params.data['syncStatus'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'VMACS Test Sync Date', 
        field: 'testSyncDate', 
        colId: 'testSyncDate',
        editable: false,
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        width: 145,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || !params.data['testSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['testSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT)
            }
        },
        suppressSizeToFit: true  
    },
    { 
        headerName: 'VMACS Prod Sync Date', 
        field: 'prodSyncDate', 
        colId: 'prodSyncDate',
        editable: false,
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        width: 145,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || !params.data['prodSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['prodSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT)
            }
        },
        suppressSizeToFit: true  
    }
];

