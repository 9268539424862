import React, { Component } from 'react';
import { AccountStore } from '../stores/account-store';
import './_login.scss';
import logo from '../../../assets/cts-logo.png';
import { RouteComponentProps } from 'react-router';
import { inject, observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { LabelButton } from '../../../shared/components/label-button/label-button';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';

interface LoginContainerProps {
    accountStore: AccountStore;
}

@inject('accountStore')
@observer
export class LoginContainer extends Component<LoginContainerProps & RouteComponentProps> {
    constructor(props: LoginContainerProps & RouteComponentProps) {
        super(props);
        if (this.props.accountStore.isLoggedIn) {
            const to =  this.props.accountStore.defaultUserLocation === 'CAN' ?'/PMCDashboard' :'/Dashboard';
            this.props.history.push(to);
        }
    }

    render(): React.ReactNode {
        return (
            <div className="login-container">
                <div className="login-container__content">
                    <div className="login-container__logo">
                        <img className="login-container-logo" src={logo} alt="Logo" />
                    </div>
                    <div className='appTitle'>
                        <h2> Centralized Tariff System</h2>
                    </div>
                    <Button className="mt-50 mb-3 custom-btn" onClick={this.handleLogin}>
                        Login
                    </Button>
                    <div className='supportText'>
                        For support please contact <a href={'mailto:no-reply@example.com'}>CTSsupport@plains.com</a>
                    </div>
                </div>
                {/* <div className="login-container__row">
                <button type='button' className="login-container__info" onClick={this.handleInfo}>
                    i
                </button>
                </div> */}
            </div>
        );
    }

    handleLogin = () => {
        this.props.accountStore.login().then(() => {
            const to =  this.props.accountStore.defaultUserLocation === 'CAN' ?'/PMCDashboard' :'/Dashboard';
            const redirectRoute = {
                pathname: to,
                state: this.props.location.state
            };
            this.props.history.push(redirectRoute);
        });
    };

    handleRegister = () => {
        this.props.history.push('/emailentry');
    };

    handleInfo =() => {
        this.props.history.push('/info');
    }
}
