import { LineSegmentMaintenanceDataStore } from './line-segment-maintenance-data-store';
import { lineSegmentMaintenanceDataService } from '../services/line-segment-maintenance-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { accountStore } from '../../../account/stores';
import { AddEditLineSegmentMaintenanceStore } from './add-edit-line-segment-maintenance-store';

export const agGridServiceForAddOrEditLocation = new AgGridService();
export const agGridServiceForAddOrEditRemark = new AgGridService();
export const agGridServiceForAddOrEditLinker = new AgGridService();

export const lineSegmentMaintenanceDataStore = new LineSegmentMaintenanceDataStore(lineSegmentMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLocation);
export const addEditLineSegmentMaintenanceStore = new AddEditLineSegmentMaintenanceStore(lineSegmentMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditRemark, agGridServiceForAddOrEditLinker);