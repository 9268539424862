import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ShipperLossGainMaintenanceDataStore } from '../stores/shipper-loss-gain-maintenance-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/shipper-loss-gain.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { shipperLossGainMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface ShipperLossGainMaintenanceContainerProps {
    shipperLossGainMaintenanceDataStore: ShipperLossGainMaintenanceDataStore;
}

interface ShipperLossGainMaintenanceContainerState {
    search: string;
}

@inject('shipperLossGainMaintenanceDataStore')
@observer
export class ShipperLossGainMaintenanceContainer extends Component<ShipperLossGainMaintenanceContainerProps, ShipperLossGainMaintenanceContainerState> {
    constructor(props: ShipperLossGainMaintenanceContainerProps | Readonly<ShipperLossGainMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Shipper Loss/Gain Configuration')
    }
    
    componentDidMount() {
      const {shipperLossGainMaintenanceDataStore} = this.props;
      const previousLoc = history?.location?.state as { from?: string }
      history.push('/ShipperLossGainMaintenance');
       if(previousLoc?.from && previousLoc.from==='/AddEditShipperLossGainMaintenance'){
           shipperLossGainMaintenanceDataStore.onSearchClick();   
     } else {
            shipperLossGainMaintenanceDataStore.init();   
        }
       shipperLossGainMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {shipperLossGainMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Shipper Loss/Gain Configuration_filter');
      this.props.shipperLossGainMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Shipper Loss/Gain Configuration_' + accountStore.userName);
      this.props.shipperLossGainMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    onBtClearSelection = () => {
      const { shipperLossGainMaintenanceDataStore } = this.props;
      shipperLossGainMaintenanceDataStore.onClearSearch();
    }

    onSearchClick = () => {
      const { shipperLossGainMaintenanceDataStore } = this.props;
      shipperLossGainMaintenanceDataStore.onSearchClick();
    }
    
    onClickAddNewShipperLossGain = async() => {
      const { shipperLossGainMaintenanceDataStore } = this.props;
        
      shipperLossGainMaintenanceDataStore.onClickAddNewShipperLGButton = true;
      shipperLossGainMaintenanceDataStore.onClickShipperLGNameOnGrid = false;
      shipperLossGainMaintenanceDataStore.agGridService.destroyGrid();
      history.push('/AddEditShipperLossGainMaintenance');
    }
    
    onClickActivate = () => {
      const { shipperLossGainMaintenanceDataStore } = this.props;
      shipperLossGainMaintenanceDataStore.activateShipperLG();
    }

    onChangeSearchKeyword = (e) => {
      this.props.shipperLossGainMaintenanceDataStore.onChangeSearchKeyword(e.target.value)
    }
    
    onChangeBaseSystem(e) {
      const { shipperLossGainMaintenanceDataStore } = this.props;
      shipperLossGainMaintenanceDataStore.selectedBaseSystem = e;
      shipperLossGainMaintenanceDataStore.getSystemGradeDropdown();
      shipperLossGainMaintenanceDataStore.getRulesRegTariffDropdown();
    }

    render(): React.ReactNode {
      const { shipperLossGainMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderShipperLGMaintenanceScreen()}
        </Container>
      );
    }

    renderShipperLGMaintenanceScreen() : React.ReactNode {
      const { shipperLossGainMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='shipperLGMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Shipper Loss/Gain Maintenance</h2>
                </div>
                
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                  <label className="standard_label_style">Base System</label>
                      <ReactSelect
                        className='select'
                        values={shipperLossGainMaintenanceDataStore.selectedBaseSystem}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...shipperLossGainMaintenanceDataStore.baseSystemDropdownOptionsUI]}
                        onChange={(e: any) => { this.onChangeBaseSystem(e)}}
                        isMulti={false}
                        isSearchable={true}
                    />
                  <label className="standard_label_style">System Grade</label>
                  <ReactSelect
                        className='select'
                        values={shipperLossGainMaintenanceDataStore.selectedSystemGrade}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...shipperLossGainMaintenanceDataStore.systemGradeDropdownOptions]}
                        onChange={(e: any) => {shipperLossGainMaintenanceDataStore.selectedSystemGrade = e}}
                        isMulti={false}
                        isSearchable={true}
                  />
                  <label className="standard_label_style">Rules REGs Tariff</label>
                  <ReactSelect
                        className='select'
                        values={shipperLossGainMaintenanceDataStore.selectedRulesRegsTariff}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...shipperLossGainMaintenanceDataStore.rulesRegTariffDropdownOptions]}
                        onChange={(e: any) => {shipperLossGainMaintenanceDataStore.selectedRulesRegsTariff = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                </div>
                <div className='settingSelectionDiv'>
                  <label className="standard_label_style">Search</label>
                  <input 
                      type="text"
                      value={shipperLossGainMaintenanceDataStore.enteredSeachKeyword}
                      className="search_text_field"
                      onChange = {(e) => this.onChangeSearchKeyword(e)}
                    />                    
                    <label className="standard_label_style">Show</label>
                    <ReactSelect
                        className='select'
                        values={shipperLossGainMaintenanceDataStore.selectedLGCalcGroup}
                        placeholder="Select"
                        options={shipperLossGainMaintenanceDataStore.lgCalcGroupDropdownOptions}
                        onChange={(e: any) => {shipperLossGainMaintenanceDataStore.selectedLGCalcGroup = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                </div>
                <div className='searchButtonDiv'>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                      Search
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                      Clear
                  </CustomButton>
                  <div className='otherHeaderButtonsDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onClickAddNewShipperLossGain} disabled={true}>
                      {/* disabled={shipperLossGainMaintenanceDataStore.isSaveDisabled()}> */}
                        Add Shipper Loss/Gain
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onClickActivate} disabled={true} >
                         {/* disabled= {shipperLossGainMaintenanceDataStore.isActivateButtonDisabled || shipperLossGainMaintenanceDataStore.isActivateDeactivateDisabled()}> */}
                        Activate
                    </CustomButton>
                    </div>
                </div>
              </div>
            </div>
            {this.props.shipperLossGainMaintenanceDataStore.showLoader == true &&
              <div className = 'alert-overlay'> 
                <div style={{margin: 'auto'}} className = 'dots-1'></div> 
              </div> 
            }
            <AgGridComponent gridConfig={this.getGridConfig('Shipper Loss/Gain Configuration')} />
          </Col>
        </Row>
      );
    }


    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        shipperLossGainMaintenanceDataStore.selectedShipperLGIds = selectedRows.map(x => {return x.tariffShipperLGID})
        shipperLossGainMaintenanceDataStore.setIsActivateButtonDisabled(shipperLossGainMaintenanceDataStore.isSaveDisabled())
      } else {
        shipperLossGainMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
    }

    private getGridConfig(gridId): GridOptions {
      const {
          shipperLossGainMaintenanceDataStore: { ShipperLossGainMaintenanceData, agGridService }
      } = this.props;
      const { shipperLossGainMaintenanceDataStore } = this.props;
      const columnDefs = shipperLossGainMaintenanceDataStore.getColDef();
      // const rowData = ShipperLossGainMaintenanceData;
      const onSelectionChanged = this.onSelectionChanged;
      const rowModelType = 'infinite';
      const cacheBlockSize =  dataStore.CacheBlockSize;
      const shipperLGMaintenanceGridParams: any = {
        // rowData,
        rowModelType,
        columnDefs,
        onSelectionChanged,
        cacheBlockSize
      };
      return agGridService.getGridConfig(shipperLGMaintenanceGridParams, gridId);
    }
}
