import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './pipeline-maintenance.scss';
import {
    ColDef, RowSelectedEvent
} from 'ag-grid-community';
import { PipelineMaintenanceDataStore } from '../stores/pipeline-maintenance-data-store';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';
import { escape } from 'lodash';
import {PipelineDetailCellRenderer} from './cell-renderers/pipeline-detail-cell-renderer';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import momenttimezone from 'moment-timezone';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import { ReactSelect } from 'common/components/select/select';

/* eslint-disable */
interface PipelineMaintenanceContainerProps {
     pipelineMaintenanceDataStore: PipelineMaintenanceDataStore;
}

interface PipelineMaintenanceContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowSelected: boolean
}

@inject('pipelineMaintenanceDataStore')
@observer
export class PipelineMaintenanceContainer extends Component<PipelineMaintenanceContainerProps, PipelineMaintenanceContainerState> {

    constructor(props: PipelineMaintenanceContainerProps | Readonly<PipelineMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowSelected: false
        };
        dataStore.setModuleName('PipelineMaintenance')
    }

    componentDidMount() {
      const {pipelineMaintenanceDataStore} = this.props;
      pipelineMaintenanceDataStore.init();
    }
    
    pipelineMaintenanceGridColDef: ColDef[] = [
        { 
            headerName: 'Physical', 
            field: 'physical',
            tooltipField: 'physical', 
            filter: true, 
            sortable: true,
            editable: false,
            flex: 1,
            minWidth: 160,
        },
        { 
            headerName: 'Type', 
            field: 'type',
            tooltipField: 'type', 
            minWidth: 250,
            filter: true, 
            sortable: true,
            flex: 1,
            editable: false,
        },
        { 
            headerName: 'Pipeline Name', 
            field: 'name',
            tooltipField: 'Pipeline Name',
            cellRenderer: PipelineDetailCellRenderer, 
            minWidth: 250, 
            filter: true, 
            sortable: true,
            flex: 1,
            editable: false,
        },
        {   
            headerName: 'Operated', 
            field: 'operated',
            tooltipField: 'Operated', 
            headerClass: 'text-center',
            cellRenderer: params => {
            return (<input type='checkbox' checked={params.value==='Y'}/>);
            }, 
            minWidth: 150, 
            flex: 1,
            cellStyle: () => {return {padding: '10px', justifyContent: 'center'}}
        },
        { 
            headerName: 'Created User', 
            field: 'createUser', 
            colId: 'createUser',
            filter: true, 
            sortable: true,
            editable: false,
            width: 120,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['createUser'] === null) {
                    return '';
                } else {
                    return params.data['createUser'];
                }
            }
        },
        { 
            headerName: 'Created Date', 
            field: 'createDate', 
            colId: 'createDate',
            filter: true, 
            sortable: true, 
            editable: false,
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['createDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
             comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            }
        },
        { 
            headerName: 'Updated User', 
            field: 'updateUser', 
            colId: 'updateUser',
            filter: true, 
            sortable: true,
            editable: false,
            width: 120,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['updateUser'] === null) {
                    return '';
                } else {
                    return params.data['updateUser'];
                }
            }
        },
        { 
            headerName: 'Updated Date', 
            field: 'updatedate', 
            colId: 'updatedate',
            filter: true, 
            sortable: true, 
            editable: false,
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['updatedate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['updatedate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
            comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            },
        },
        { 
            headerName: 'Sync Status', 
            field: 'syncStatus', 
            colId: 'syncStatus',
            tooltipField: 'Sync Status',
            editable: false, 
            width: 130,
            // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['syncStatus'] === null) {
                    return '';
                } else {
                    return params.data['syncStatus'];
                }
            }
        },
        { 
            headerName: 'VMACS Test Sync Date', 
            field: 'testSyncDate', 
            colId: 'testSyncDate',
            tooltipField: 'VMACS Test Sync Date', 
            editable: false,
            width: 130,
            // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (!params.data['testSyncDate']) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['testSyncDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
            comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            },
        },
        { 
            headerName: 'VMACS Prod Sync Date', 
            field: 'prodSyncDate', 
            colId: 'prodSyncDate',
            tooltipField: 'VMACS Prod Sync Date', 
            editable: false,
            width: 130,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (!params.data['prodSyncDate']) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['prodSyncDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
            comparator: (a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                if(dateA===dateB) return 0;
                else if(dateA>dateB) return 1;
                return -1           
            },
        }
  ];
  

    getColDef() {
      return this.pipelineMaintenanceGridColDef
    }


    getGridConfig(gridId) {
        const {
            pipelineMaintenanceDataStore: { pipelineMaintenanceData, agGridService }
        } = this.props;
        const { pipelineMaintenanceDataStore } = this.props;
        const pMGridParams: any = {
          rowData: pipelineMaintenanceData,
          columnDefs:this.getColDef(),
          rowSelection: 'single',
          onRowSelected: this.onRowSelected
                //   onCellEditingStarted,
          };
        return agGridService.getGridConfig(pMGridParams, gridId);
      }

      onRowSelected = (event: RowSelectedEvent): void => {
        this.setState({...this.state, rowSelected: (event.api.getSelectedNodes().length===1)});
      }

    //   editPipeline = async () => {
    //     const { pipelineMaintenanceDataStore } = this.props;
    //     const requestBody: any = await pipelineMaintenanceDataStore.agGridService.getSelectedRows();
    //     if(requestBody.length > 0) {
    //         pipelineMaintenanceDataStore.setPipelineIdForEditing(requestBody[0].pipelineID);
    //         history.push('EditPipeline')
    //     }
    //   }

      
    render(): React.ReactNode {
        const { pipelineMaintenanceDataStore } = this.props;
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <div className='PipelineMaintenanceBox'>
                            <div className='screenHeader'>
                            <h2 className='screen_title'> Pipeline Maintenance</h2>
                            </div>
                            <div className='selectContainer'>
                                <div className='settingSelectionDiv'>
                                    <label className="standard_label_style">System/Area Type</label>
                                    <ReactSelect
                                        className='select'
                                        values={pipelineMaintenanceDataStore.selectedSystemAreaType}
                                        placeholder="Select"
                                        options={[{ value: 'All', label: 'All' }, ...pipelineMaintenanceDataStore.pipelineTypes]}
                                        onChange={(e: any) => {pipelineMaintenanceDataStore.setSystemAreaType(e)}}
                                        isMulti={false}
                                        isSearchable={true}
                                    />
                                    <label className="standard_label_style">System/Area Name</label>
                                    <input 
                                    type="text" 
                                    className="search_text_field input"
                                    onChange = {(e) => pipelineMaintenanceDataStore.setSystemAreaName(e.target.value)}
                                    value={pipelineMaintenanceDataStore.selectedSystemAreaName}
                                    />
                                    
                                    <label className="standard_label_style">Physical Pipeline</label>
                                    <ReactSelect
                                        className='select'
                                        values={pipelineMaintenanceDataStore.selectedPhysicalPipeline}
                                        placeholder="Select"
                                        options={[{ value: 'All', label: 'All' }, ...pipelineMaintenanceDataStore.dropdownPhysicalPipeline]}
                                        onChange={(e: any) => {pipelineMaintenanceDataStore.setPhysicalPipeline(e)}}
                                        isMulti={false}
                                        isSearchable={true}
                                    />
                                </div>
                                <div className='searchButtonDiv'>
                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{pipelineMaintenanceDataStore.getPipelineGridData()}}>
                                        Search
                                    </CustomButton>
                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{pipelineMaintenanceDataStore.clearScreen()}}>
                                        Clear
                                    </CustomButton>
                                    <div className="otherHeaderButtonsDiv"> 
                                        <CustomButton type={CustomButtonType.Submit} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)} onClick={()=>{history.push('AddPipeline')}}>
                                            Add New Pipeline
                                        </CustomButton>
                                        {/* <CustomButton type={CustomButtonType.Submit} disabled={!this.state.rowSelected} onClick={this.editPipeline}>
                                        Edit Pipeline
                                        </CustomButton> */}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <AgGridComponent gridConfig={this.getGridConfig('Pipeline Maintenance')}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
    );
}}
