import { pipelineMaintenanceDataService } from '../services/pipeline-maintenance-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { agGridService, AgGridService } from '../../../../common/services/ag-grid-service';
import { PipelineMaintenanceDataStore } from './pipeline-maintenance-data-store';
import { AddPipelineMaintenanceDataStore } from './add-pipeline-maintenance-data-store';
import { EditPipelineMaintenanceDataStore } from './edit-pipeline-maintenance-data-store';
import { accountStore } from '../../../account/stores';


export const agGridServiceRelated = new AgGridService();
export const agGridServiceOwners = new AgGridService();
export const agGridServiceEntities = new AgGridService();

export const editPipelineMaintenanceDataStore = new EditPipelineMaintenanceDataStore(pipelineMaintenanceDataService, uiService, accountStore, agGridServiceRelated, agGridServiceOwners, agGridServiceEntities);

export const addPipelineMaintenanceDataStore = new AddPipelineMaintenanceDataStore(pipelineMaintenanceDataService, uiService, accountStore, agGridServiceRelated, agGridServiceOwners, agGridServiceEntities);

export const pipelineMaintenanceDataStore = new PipelineMaintenanceDataStore(pipelineMaintenanceDataService, uiService, agGridService, accountStore);

