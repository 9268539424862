import React, { Component } from 'react';
import {
    ColDef
  } from 'ag-grid-community';
import { TariffOptionsDataStore } from '../stores/tariff-options-data-store';
import { Portal } from 'react-overlays';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { inject, observer } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import momenttimezone from 'moment-timezone';

interface lossAllowanceInterface {
  tariffOptionsDataStore: TariffOptionsDataStore
  index: number,
  tariffOptionID: number,
  addLossAllowance: (index: any) => void
}

interface calendarContainerInterface {
  children: any;
}

@inject('tariffOptionsDataStore')
@observer
export class LossAllowance extends Component<lossAllowanceInterface> {
  constructor(props) {
    super(props);
  }

  CalendarContainer = (props: calendarContainerInterface) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{props.children}</Portal>;
  };

   onChangeDate = (params, value, key, index) => {
    const {tariffOptionsDataStore} = this.props;
    if(key==='effectiveDate'||key==='endDate'){
      const rowIndex = tariffOptionsDataStore.LossAllowanceData[index].findIndex(x=>x.rowId===params.data.rowId)
      tariffOptionsDataStore.setLossAllowaneDate(value, rowIndex, key, index);
      // tariffOptionsDataStore.modifedRows[index]['lossAllowance'] = [...tariffOptionsDataStore.modifedRows[index]['lossAllowance'], params.data?.rowId] 
      this.props.tariffOptionsDataStore.modifedRows[this.props.index] = {...this.props.tariffOptionsDataStore.modifedRows[this.props.index], lossAllowance: [...(this.props.tariffOptionsDataStore.modifedRows[this.props.index]?.lossAllowance||[])]}
      this.props.tariffOptionsDataStore.modifedRows[this.props.index]['lossAllowance'] = [...this.props.tariffOptionsDataStore.modifedRows[this.props.index]['lossAllowance'], params.data?.rowId] 
    }
  } 

  colDefLossAllowance: ColDef[] = [
      {
        headerName: 'Select',
        field: 'select',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        checkboxSelection: (params) => { return !params.data.isCloned},
        colId:'Select',
        width: 40,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        suppressSizeToFit: true,
        cellStyle: () => {return {padding: '10px'}}
      },
      {
        headerName: 'Effective Date',
        field: 'effectiveDate',
        editable: false,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        // comparator: (a, b) => {
        //     const dateA = new Date(a);
        //     const dateB = new Date(b);
        //     if(dateA===dateB) return 0;
        //     else if(dateA>dateB) return 1;
        //     return -1           
        // },
        // sort: 'asc',
        // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
        valueFormatter: ({ value, data }) => '',
        cellRenderer: (params) => {
            return (params.data ? (<DatePicker
                  popperClassName='ag-custom-component-popup'
                  selected={params.data.effectiveDate && new Date(params.data.effectiveDate) || null}
                  onChange={(value)=>{this.onChangeDate(params, value, 'effectiveDate', this.props.index)}}
                  popperContainer={this.CalendarContainer}
                  popperPlacement='top-start'
                  className='datePickerGridInput'
                  disabled={this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                  // readOnly={this.props.tariffOptionsDataStore.ownersCreated}
                  />) : '')
        },
        minWidth: 130,
        flex: 1
    },
    {
        headerName: 'End Date',
        field: 'endDate',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        editable: false,
        // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
        cellRenderer: (params) => {
            return (params.data &&  (<DatePicker
                  popperClassName='ag-custom-component-popup'
                  selected={params.value && new Date(params.value) || null}
                  onChange={(value)=>{this.onChangeDate(params, value, 'endDate', this.props.index)}}
                  popperContainer={this.CalendarContainer}
                  popperPlacement='top-start'
                  // readOnly={this.props.tariffOptionsDataStore.ownersCreated}
                  className='datePickerGridInput'
                  disabled={this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                  />)) || params.value
        },
        flex: 1,
        minWidth: 130
    },
    {
        headerName: 'Loss Allowance',
        field: 'lossAllowance',
        editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
        minWidth: 130,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        flex: 1,
        valueSetter: (params) => {
          if(params.newValue.match(/^[0-9.]*$/)){
            params.data.lossAllowance=params.newValue.toUpperCase();
            return true
          } else {
            return false
          }
        },
      },
      {
        headerName: 'Update Date',
        field: 'updateDate',
        editable: false,
        minWidth: 130,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        flex: 1,
        valueGetter: params => {
          if (params.data['updateDate'] === null) {
              return '';
          } else {
              return moment(params.data['updateDate']).format(AppConstant.DATE_FORMAT);
          }
        }, 
      },
      {
        headerName: 'Update User',
        field: 'updateUser',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        editable: false,
        minWidth: 130,
        flex: 1
      }
    ];
    getGridConfigLossAllowance = (gridId) => {
      const {
        tariffOptionsDataStore: { agGridServiceLossAllowance }
      } = this.props;
      const {tariffOptionsDataStore} = this.props;
      const gridParams: any = {
        rowData: tariffOptionsDataStore.LossAllowanceData.length && tariffOptionsDataStore.LossAllowanceData[tariffOptionsDataStore.selectedTab]||[],
        columnDefs:this.colDefLossAllowance,
        domLayout: 'autoHeight',
        onCellEditingStopped: (params) => {
          this.props.tariffOptionsDataStore.modifedRows[this.props.index] = {...this.props.tariffOptionsDataStore.modifedRows[this.props.index], lossAllowance: [...(this.props.tariffOptionsDataStore.modifedRows[this.props.index]?.lossAllowance||[])]}
          this.props.tariffOptionsDataStore.modifedRows[this.props.index]['lossAllowance'] = [...this.props.tariffOptionsDataStore.modifedRows[this.props.index]['lossAllowance'], params.data?.rowId] 
      // tariffOptionsDataStore.modifedRows[this.props.index]['lossAllowance'] = [...tariffOptionsDataStore.modifedRows[this.props.index]['lossAllowance'], params.data?.rowId] 
          this.props.tariffOptionsDataStore.agGridServiceLossAllowance.redrawRows();
        },//
         // onCellEditingStopped: this.onCellEditingStopped
         context : agGridServiceLossAllowance
        };
      return agGridServiceLossAllowance.getGridConfig(gridParams, gridId);
    }

    deleteLossAllowance = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceLossAllowance.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteLossAllowance(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {console.log(1)},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

render(): React.ReactNode {
    return (
    <>
      <Row>
        <div className='rightFlex'>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.props.addLossAllowance(this.props.index)}}>
            Add Loss Allowance
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.props.tariffOptionsDataStore.saveLossAllowance(this.props.index, this.props.tariffOptionID)}}>
            Save Loss Allowance
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.deleteLossAllowance(this.props.index)}}>
            Delete Loss Allowance
            </CustomButton>
        </div>
        <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}}>
                      <label className='standard_label_style'>Rule 80(d)</label>
                      <input
                      type='text'
                      className='search_text_field inputLast'
                      disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                      // onChange = {(e) => {}}
                      value={''}
                      />
              </Col>
              <Col style={{'marginTop': '10px'}}>
                      <label className='standard_label_style'>Volume</label>
                      <input
                      type='text'
                      disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                      className='search_text_field inputLast'
                      // onChange = {(e) => {}}
                      value={''}
                      />
              </Col>
              <Col style={{'marginTop': '10px'}}>
                      <label className='standard_label_style'>No PLA</label>
                      <input
                      type='text'
                      disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                      className='search_text_field inputLast'
                      // onChange = {(e) => {}}
                      value={''}
                      />
              </Col>
              <Col style={{'marginTop': '10px'}}>
                      <label className='standard_label_style'>Additional Fields</label>
                      <input
                      type='text'
                      disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                      className='search_text_field inputLast'
                      // onChange = {(e) => {}}
                      value={''}
                      />
              </Col>
              <Col xs={'auto'}></Col>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigLossAllowance('Tariff Option loss')}/>
            </Row>
      </Row>
      </>
    )}
  }