import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { LogIdCellRenderer, TariffNumberCellRenderer, ViewOptionsCellRenderer } from '../../../tariff-management/traiff-dashboard/component/cell-renderers/logId-cell-renderer';
import { TariffNoCellRenderer } from './cell-renderers/tariffNo-cell-renderer';

export const homeTariffLogColDef: ColDef[] = [
    { 
        headerName: 'Reference Tariff No.', 
        field: 'refTariffNo',
        colId: 'refTariffNo',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        width: 200, 
        valueGetter: params => {
            if (params.data['refTariffNo'] === null) {
                return '';
            } else {
                return params.data['refTariffNo'];
            }
        },
        cellRenderer: TariffNoCellRenderer
    },{ 
        headerName: 'Tariff No', 
        field: 'tariffNo',
        colId: 'tariffNo',
        filter: true, 
        sortable: true, 
        minWidth: 120,
        singleClickEdit: true,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['tariffNo'] === null) {
                return '';
            } else {
                return params.data['tariffNo'];
            }
        },
        flex: 1,
    },
    { 
        headerName: 'Tariff Description', 
        field: 'name', 
        colId: 'name',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 130,
        valueGetter: params => {
            if (params.data['name'] === null) {
                return '';
            } else {
                return params.data['name'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Tariff Type', 
        field: 'type',
        colId: 'type',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['type'] === null) {
                return '';
            } else {
                return params.data['type'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Current Date', 
        field: 'createDate', 
        colId: 'createDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return moment(params.data['createDate']).format(AppConstant.DATE_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'Issue Date', 
        field: 'tariffIssueDate', 
        colId: 'tariffIssueDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['tariffIssueDate'] === null) {
                return '';
            } else {
                return moment(params.data['tariffIssueDate']).format(AppConstant.DATE_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'CTS Effective Date', 
        field: 'effectiveDate', 
        colId: 'effectiveDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['effectiveDate'] === null) {
                return '';
            } else {
                return moment(params.data['effectiveDate']).format(AppConstant.DATE_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'Cancel Date', 
        field: 'cancelDate', 
        colId: 'cancelDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['cancelDate']) {
                return '';
            } else {
                return moment(params.data['cancelDate']).format(AppConstant.DATE_FORMAT);
            }
        },
        flex: 1
    },
];
