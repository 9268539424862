import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './create-tariff-log.scss';
import {
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  ColDef
} from 'ag-grid-community';
import { tariffLogDataStore } from '../stores';
import { TariffLogDataStore } from '../stores/tariff-log-data-store';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import { BaseTariffResponse } from '../model/model';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import DatePicker from 'react-datepicker';
import { timingSafeEqual } from 'crypto';
import { AnyARecord } from 'dns';
import axios from 'axios';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { RoleBasedActionName } from 'shared/types/enum';
import { Prompt } from 'react-router-dom';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Attachments } from 'common/components/attachments/attachments';
import { agGridService } from 'common/services/ag-grid-service';
import { logDOM } from '@testing-library/react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import { errorHandler } from 'shared/handlers/error-handler';
import { Portal } from '@mui/material';
import { action } from 'mobx';
import _, { isEqual } from 'lodash';


/* eslint-disable */
interface CreateTariffLogContainerProps {
  tariffLogDataStore: TariffLogDataStore;
}

interface CreateTariffLogContainerState {
  rowId: number;
  predictiveSelectText: string;
  selectedShownArray: any[];
  totalArray: any[];
  hiddenSelect: boolean;
  hiddenCancelSelect: boolean;
  editableFormData: any;
  baseTariffState: any;
  selectedShownCancelArray: any[];
  totalCancelArray: any[];
  predictiveSelectCancelText: string;
  selectedLogEntryState: string;
  initialDefaultFormData: any;
}

interface CalendarContainerProps {
  children : any
}

const CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };

@inject('tariffLogDataStore')
@observer
export class TariffLogContainer extends Component<CreateTariffLogContainerProps, CreateTariffLogContainerState> {

  constructor(props) {
    super(props);
    this.state = {
      rowId: 0,
      predictiveSelectText: "",
      selectedShownArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      totalArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      hiddenSelect: true,
      hiddenCancelSelect: true,
      editableFormData: {},
      baseTariffState: {},
      selectedShownCancelArray:[],
      totalCancelArray: [],
      predictiveSelectCancelText: '',
      selectedLogEntryState:'',
      initialDefaultFormData: {}
    };
    dataStore.setModuleName('Tariff Log');
  }

  componentDidMount() {
    const { tariffLogDataStore } = this.props;
    tariffLogDataStore.init();
    window.scroll(0, 0);
  }

  componentDidUpdate(newProps, newState): void {
    const { tariffLogDataStore } = this.props;
    console.log('Hi');
    const filters = localStorageService.get('Tariff Log_filter');
    this.props.tariffLogDataStore.agGridService.setAllFilters(filters);
    const columnVisible = localStorageService.get('Tariff Log_' + accountStore.userName);
    this.props.tariffLogDataStore.agGridService.setColumnVisibility(columnVisible);
    const logEntrySelectionfilters = localStorageService.get('Log Entry Selection_filter');
    this.props.tariffLogDataStore.agGridServiceForVersionSelection.setAllFilters(logEntrySelectionfilters);
    const logEntrySelectioncolumnVisible = localStorageService.get('Log Entry Selection_' + accountStore.userName);
    this.props.tariffLogDataStore.agGridServiceForVersionSelection.setColumnVisibility(logEntrySelectioncolumnVisible);
    
    console.log(newProps.tariffLogDataStore.selectedBaseTariffDetails, tariffLogDataStore.selectedBaseTariffDetails);
    if (tariffLogDataStore.selectedBaseTariffDetails.length > 0 && (this.state.baseTariffState !== tariffLogDataStore.selectedBaseTariffDetails || this.state.selectedLogEntryState !== tariffLogDataStore.selectedLogEntryType)) {
      const jointTariffBaseDetailId = tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID;
      tariffLogDataStore.fileUploadedSuccessfully = jointTariffBaseDetailId && jointTariffBaseDetailId > 0;
      const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs : tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs;
      const filteredLogArray = tariffLogDataStore.tariffLogId ? logsArray && logsArray.length > 0 ? logsArray.filter((item) => item.tariffLogID === tariffLogDataStore.tariffLogId) : [] : logsArray;
      const tariffLog = filteredLogArray  && filteredLogArray.length > 0 ? filteredLogArray.reduce((a, b) => a.tariffEffectiveDate > b.tariffEffectiveDate ? a : b) : null;
      const tariffNumber = tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null;
      const defaultTariffNumber = tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff.split(' ');
      const objectForBaseCarrier = {
        [tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff]: {
          tariff: tariffNumber ? jointTariffBaseDetailId ?  tariffNumber[3] : tariffNumber[2] : defaultTariffNumber ? jointTariffBaseDetailId ? defaultTariffNumber[3] : defaultTariffNumber[2] : '',
          baseTariffName: tariffNumber ? jointTariffBaseDetailId ? `${tariffNumber[0]} ${tariffNumber[1]} ${tariffNumber[2]}` : `${tariffNumber[0]} ${tariffNumber[1]}` : defaultTariffNumber ? jointTariffBaseDetailId ? `${defaultTariffNumber[0]} ${defaultTariffNumber[1]} ${defaultTariffNumber[2]}` : `${defaultTariffNumber[0]} ${defaultTariffNumber[1]}` : '',
          legalVersion: '',
          cancels: tariffNumber ? '' : 'New Tariff 0 0 0',
          cancelBaseTariffName: tariffNumber ? '' : 'New Tariff',
          cancelTariff: tariffNumber ? '' : '0',
          cancelTariffLogVersion: tariffNumber ? '': '0',
          cancelTariffLogSubVersion: tariffNumber ? '' : '0',
          tariffLogVersion: tariffNumber ? jointTariffBaseDetailId ? tariffNumber[4] : tariffNumber[3] : '0',
          tariffLogSubVersion: tariffNumber ? jointTariffBaseDetailId ? tariffNumber[5] : tariffNumber[4] : '0',
          description: tariffLog && tariffLog.description ? tariffLog.description : '',
          index: tariffLog && tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Tariff Filing'.toLowerCase()? tariffLog.index ? true: false : tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Tariff Filing'.toLowerCase() ? false : true,
          vmacsTariffUpdate: true,
          vmacsImpact: true,
          documentation: false,
          tariffIssueDate: tariffLog && tariffLog.tariffIssueDate ? tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase() ? new Date(tariffLog.tariffIssueDate): tariffLog.newTariffFilling ? null : new Date(tariffLog.tariffIssueDate): tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase() ? null : new Date(),
          tariffEffectiveDate: tariffLog && tariffLog.tariffEffectiveDate ? tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase() ? new Date(tariffLog.tariffEffectiveDate): tariffLog.newTariffFilling ? null : new Date(tariffLog.tariffEffectiveDate): tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase() ? null : new Date(),
          ctsEffectiveDate: tariffLog && tariffLog.ctsEffectiveDate ? new Date(tariffLog.ctsEffectiveDate) : new Date(),
          projectName: tariffLog && tariffLog.projectName ? tariffLog.projectName : null,
          tested: tariffLog ? tariffLog.testInd ? true : false : false,
          projectType: 'Index',
          isProject: false,
          tariffBaseID: tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseID,
          tariffBaseDetailID: tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetailID,
          tariffLogID: tariffLog && tariffLog.tariffLogID ? tariffLog.tariffLogID : false,
          logStatus:  tariffLog && tariffLog.logStatus ? tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase() ? tariffLog.logStatus : tariffLog.newTariffFilling ? 'Pending Tariff Filing' : tariffLog.logStatus : tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase() ? 'Pending Test' : '',
          maintenanceSelectionVersionData: logsArray  && logsArray.length > 0 ? tariffLog && tariffLog.ctsEffectiveDate ?  [...logsArray.filter((item) => item.ctsEffectiveDate > tariffLog.ctsEffectiveDate), ...[logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).length > 0 ? logsArray.filter((item) => item.ctsEffectiveDate <= tariffLog.ctsEffectiveDate ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] : [] : [],
          newTariffFilling: tariffLog ? tariffLog.newTariffFilling ? true: false : true,
        }
      };
      let objectForJointCarriers = {};

      // ***for now comment out the tab for joint carriers
      // const tariffBaseDetails = tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails.map((item) => {return {
      //   tariffBaseDetailID: item.tariffBaseDetailID,
      //   baseTariff: item.baseTariff,
      //   jointCarrierID: item.jointCarrierID,
      //   pipelineID: item.pipelineID,
      //   jointCarrier: item.jointCarrier,
      //   pipeline: item.pipeline
      // }});
      // if(tariffBaseDetails && tariffBaseDetails.length > 0 && tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Maintenance'.toLowerCase()) {
      //   // const tariffLog = tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.reduce((a, b) => a.tariffEffectiveDate > b.tariffEffectiveDate ? a : b);
      //   // const tariffNumber = tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null;
      //   tariffBaseDetails.forEach(x => {
      //     const baseTariffName = x.baseTariff.split(' ');
      //     const logsArray  =  tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.jointCarrierLogs;
      //     const maintenanceSelectionVersionData = logsArray  && logsArray.length > 0 ? logsArray.filter((item)=> item.tariffNumber.includes(x.baseTariff)) as any[] : [];
      //     const tariffLog = logsArray  && logsArray.length > 0 ? logsArray.filter((item)=> item.tariffNumber.includes(x.baseTariff)).reduce((a, b) => a.tariffEffectiveDate > b.tariffEffectiveDate ? a : b) : null;
      //     const tariffNumber = tariffLog && tariffLog.tariffNumber ? tariffLog.tariffNumber.split(' ') : null;
      //   objectForJointCarriers = {
      //     ...objectForJointCarriers, [x.baseTariff]: {
      //       tariff: tariffNumber ? tariffNumber[3] : baseTariffName ? baseTariffName[3] : '',
      //       baseTariffName: tariffNumber ? `${tariffNumber[0]} ${tariffNumber[2]}` :baseTariffName ? `${baseTariffName[0]} ${baseTariffName[1]} ${baseTariffName[2]}` : '',
      //       legalVersion: '',
      //       cancels: tariffNumber ? '' : 'New Tariff 0 0 0',
      //       cancelBaseTariffName: tariffNumber ? '' : 'New Tariff',
      //       cancelTariff: tariffNumber ? '': '0',
      //       cancelTariffLogVersion: tariffNumber ? '' : '0',
      //       cancelTariffLogSubVersion: tariffNumber ? '' : '0',
      //       tariffLogVersion: tariffNumber ? tariffNumber[4] : '0',
      //       tariffLogSubVersion: tariffNumber ? tariffNumber[5] : '0',
      //       description: tariffLog && tariffLog.description ? tariffLog.description : '',
      //       index: tariffLog ? tariffLog.index : true,
      //       vmacsTariffUpdate: true,
      //       vmacsImpact: true,
      //       documentation: false,
      //       tariffIssueDate: tariffLog && tariffLog.tariffIssueDate ? new Date(tariffLog.tariffIssueDate) : new Date(),
      //       tariffEffectiveDate: tariffLog && tariffLog.tariffEffectiveDate ? new Date(tariffLog.tariffEffectiveDate) : new Date(),
      //       ctsEffectiveDate: tariffLog && tariffLog.ctsEffectiveDate ? new Date(tariffLog.ctsEffectiveDate) : new Date(),
      //       projectName: tariffLog && tariffLog.projectName ? tariffLog.projectName : null,
      //       tested: tariffLog ? tariffLog.testInd : false,
      //       projectType: 'Index',
      //       isProject: false,
      //       tariffBaseID: tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseID,
      //       tariffBaseDetailID: 0,
      //       tariffLogID: tariffLog && tariffLog.tariffLogID ? tariffLog.tariffLogID : false,
      //       logStatus: tariffLog && tariffLog.logStatus ? tariffLog.logStatus : '',
      //       maintenanceSelectionVersionData: maintenanceSelectionVersionData,
      //       newTariffFilling: tariffLog ? tariffLog.newTariffFilling : false,
      //     }
      //   };
      // });
      // }
      console.log(objectForJointCarriers);
      const editableFormData = { ...objectForBaseCarrier, ...objectForJointCarriers };
      this.setState({ ...this.state, editableFormData: editableFormData, baseTariffState: tariffLogDataStore.selectedBaseTariffDetails, initialDefaultFormData:editableFormData });
      this.setState({
        selectedLogEntryState : tariffLogDataStore.selectedLogEntryType
      })
      dataStore.showUnsavedWarningOnTabCloseOrReload();
      
    }
  }

  clickOutsideEvent = (e) => {
    const element = document.getElementById('predictiveSelectContainer');
    const elementCancel = document.getElementById('predictiveSelectContainerCancel');
    if (!element?.contains(e.target)) {
      this.setState({ ...this.state, hiddenSelect: true });
    }
    if(!elementCancel?.contains(e.target)) {
      this.setState({ ...this.state, hiddenCancelSelect: true });
    }
    this.removeClickListener();
  };

  removeClickListener = () => {
    document.removeEventListener('click', this.clickOutsideEvent);
  };


  tariffLogGridColDef: ColDef[] = [
    {
      headerName: 'File Name',
      field: 'fileName',
      filter: true,
      sortable: true,
      editable: () => { return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLog); },
      cellEditor: 'agSelectCellEditor',
      width: 150,
      //cellClass: (params) => {return this.props.tariffLogDataStore.isEditableSettingName(params) ?  'cell-editable' : ''}, 
      // cellStyle: (params) => {
      //       return {border: '1px solid #0091ea'};
      // }
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: true,
      sortable: true,
      editable: false,
      width: 150
    },
    {
      headerName: 'Created By',
      field: 'createUser',
      width: 150,
      filter: true,
      sortable: true,
      editable: false
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      filter: true,
      sortable: true,
      editable: false,
      comparator: (a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if(dateA===dateB) return 0;
        else if(dateA>dateB) return 1;
        return -1           
      },
    }
  ];


  tariffLogJointCarrierGridColDef: ColDef[] = [
    {
      headerName: 'Joint Carrier',
      field: 'jointCarrier',
      filter: true,
      sortable: true,
      editable: () => { return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLog); },
      cellEditor: 'agSelectCellEditor',
    },
    {
      headerName: 'Base Tariff',
      field: 'baseTariff',
      filter: true,
      sortable: true,
      editable: false,
    },
    {
      headerName: 'Pipeline Area',
      field: 'pipeline',
      minWidth: 665,
      filter: true,
      sortable: true,
      editable: false
    }
  ];


 logEntrySelectionGridColDef: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      width: 40,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly:true,
      filter: true,
      sortable: true,
      cellStyle: () => {return {padding: '10px'}},
      editable: () => { return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLog); },
      // cellEditor: 'agSelectCellEditor',
    },
    {
      headerName: 'Tariff#',
      field: 'tariffNumber',
      filter: true,
      width: 100,
      sortable: true,
      editable: false,
      flex: 1 
    },
    {
      headerName: 'Version',
      field: 'tariffLogVersion',
      filter: true,
      sortable: true,
      editable: false,
      width: 80,
      flex: 1
    },
    {
      headerName: 'CTS Effective Date',
      field: 'ctsEffectiveDate',
      filter: true,
      sortable: true,
      editable: false,
      width: 100,
      flex: 1 
    },
    {
      headerName: 'New Version',
      field: 'tariffLogNewVersion',
      filter: true,
      sortable: true,
      editable: () => {return !this.props.tariffLogDataStore.isEditDisabled()},
      cellClass: (params) => {return !this.props.tariffLogDataStore.isEditDisabled() ?  'cell-editable' : ''},
      width: 80, 
      flex: 1 
    },
    {
      headerName: 'New CTS Effective Date',
      field: 'newCtsEffectiveDate',
      filter: true,
      sortable: true,
      editable: () => {return !this.props.tariffLogDataStore.isEditDisabled()},
      cellClass: (params) => {return !this.props.tariffLogDataStore.isEditDisabled() ?  'cell-editable' : ''},
      cellRenderer: (params) => { 
        const newCtsEffectiveDate = params.data['newCtsEffectiveDate'] ?  moment(moment(params.data['newCtsEffectiveDate']).format('MM/DD/yyyy')).toDate() : null
        return (params.data && 
            (<DatePicker 
                popperClassName='ag-custom-component-popup' 
                selected={newCtsEffectiveDate}
                dateFormat="MM/dd/yyyy" 
                onChange={(value)=>{this.onChangeDate(params, value, 'newCtsEffectiveDate')}} 
                popperContainer={CalendarContainer} 
                popperPlacement='top-start' 
                className={this.props.tariffLogDataStore.isEditDisabled() ? 'serviceDateInputStyle' : 'serviceDateInputStyle-editable'}
                disabled={this.props.tariffLogDataStore.isEditDisabled()}/>
            )
        ) || params.value 
    },
    valueGetter: params => {
        if (params.data['newCtsEffectiveDate'] === null) {
            return '';
        } else {
            return moment(params.data['newCtsEffectiveDate']).format(AppConstant.DATE_FORMAT);
        }
    }, 
     
      width: 100,
      flex: 1 
    }
  ];


  onChangeDate = (params, value, key) => {  
    const {tariffLogDataStore} = this.props;
    const newCtsEffectiveDate = moment(value).format('MM/DD/yyyy');
    console.log(moment(value).format('MM/DD/yyyy'));
    tariffLogDataStore.setValueInChangeMap(params.data.tariffLogID, key, params.data.newCtsEffectiveDate, newCtsEffectiveDate);
    params.data.newCtsEffectiveDate =  moment(value).format('MM/DD/yyyy');
    this.updateRow(params.data);
  } 

  getColDef() {
    return this.tariffLogGridColDef;
  }

  getColDefJointCarrier() {
    return this.tariffLogJointCarrierGridColDef;
  }

  downloadAttachment(fileID) {
    this.props.tariffLogDataStore.downloadAttachment(fileID);
  };

  deleteAttachment = async (files) => {
      // const requestBody: any = await pmcTariffDataStore.agGridService.getSelectedRows();
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
          () => {
            this.props.tariffLogDataStore.deleteAttachment(files);
          },
          'Delete',
          'Are you sure you want to delete the selected attachment?'
      );
  };

  updateAttachment = async () => {
    this.props.tariffLogDataStore.updateAttachment();
  }


  getGridConfig() {
    const {
      tariffLogDataStore: { fileUploadGridData, agGridService }
    } = this.props;
    const tariffLogGridParams: any = {
      rowData: fileUploadGridData,
      columnDefs: this.getColDef(),
    };
    return agGridService.getGridConfig(tariffLogGridParams);
  }

  getGridConfigJointCarrier(gridId) {
    const {
      tariffLogDataStore: { selectedBaseTariffDetails, agGridService }
    } = this.props;
    const tariffLogJointCarrierGridParams: any = {
      rowData: selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails?.map(x => { return { 'jointCarrier': x.jointCarrier, 'baseTariff': x.baseTariff, 'pipelineArea': x.pipeline }; }) || [],
      columnDefs: this.getColDefJointCarrier(),
    };
    return agGridService.getGridConfig(tariffLogJointCarrierGridParams, gridId);
  }

  predictiveSelectOnChange = (e, tabIndex) => {
    document.addEventListener('click', this.clickOutsideEvent);
    this.onChangeEditableFormData('projectName', e.target.value, tabIndex);
  };

  predictiveSelectOnChangeCancel = (e, tabIndex) => {
    document.addEventListener('click', this.clickOutsideEvent);
    this.onChangeEditableFormData('cancelBaseTariffName', e.target.value, tabIndex);
  };

  predictiveSelectOnClick = (key?) => {
    document.addEventListener('click', this.clickOutsideEvent);
    if(key && key === 'cancels') {
      this.setState({ ...this.state, hiddenCancelSelect: tariffLogDataStore.shownCancelVersion.length > 0 ? false : true });
    }
    this.setState({ ...this.state, hiddenSelect: tariffLogDataStore.shownProjectNames.length > 0 ? false : true });
  };

  onChangeEditableFormData = (key, value, tabKey, fromPredictiveContainer = false) => {
    const {tariffLogDataStore} = this.props;
    let tempState = {};
    let tempCancelState = {};
    const logsArray =  tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs;
    if (key === 'projectName') {
      tempState = ({ hiddenSelect: tariffLogDataStore.shownProjectNames.filter((x, index, arr) => x.includes(value)).length > 0 ? false : true, predictiveSelectText: value, selectedShownArray: this.state.totalArray.filter((x, index, arr) => x.includes(value)) });
      tariffLogDataStore.setShownProjectNames(tariffLogDataStore.projectNames.filter((x, index, arr) => x.includes(value)));
      if (fromPredictiveContainer) {
        tempState = { ...tempState, predictiveSelectText: value, hiddenSelect: true };
      }
    }
    if (key === 'cancelBaseTariffName') {
      tempCancelState = ({ hiddenCancelSelect: tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs.filter((x, index, arr) => x.tariffNumber.includes(value)).length > 0 ? false : true, predictiveSelectCancelText: value, selectedShownCancelArray: this.state.totalCancelArray.filter((x, index, arr) => x.includes(value)) });
      const filteredArray = tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId].tariffLogs.filter((x, index, arr) => x.tariffNumber.includes(value));
      const cancelArray = filteredArray.map(x=> { return x.tariffNumber}).filter((item, index, arr) => arr.indexOf(item) === index);
      tariffLogDataStore.setShownCancelVersion(cancelArray);
      if (fromPredictiveContainer) {
        tempCancelState = { ...tempCancelState, predictiveSelectCancelText: value, hiddenCancelSelect: true };
      }
    }

    if (tabKey === tariffLogDataStore.baseTariffData[this.state.rowId].baseTariff) {
      let modifiedEditableFormDataState = {};
      
      Object.keys(this.state.editableFormData).forEach(stateKey => {
        modifiedEditableFormDataState = {
          ...modifiedEditableFormDataState,
          [stateKey]: {
            ...this.state.editableFormData[stateKey],
            [key]: value,
            'documentation': key === 'documentation' ? value : (key === "index" && value === true) ? false : this.state.editableFormData[stateKey]['documentation'],
            'isProject':
              ((key === "index" || key === "documentation") && value === true) ? false :
                (key === "index" && value === false && this.state.editableFormData[stateKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[stateKey]['index'] === false) ? true :
                  (key === "index" && value === false) ? false :
                    (this.state.editableFormData[stateKey]['index'] === true || this.state.editableFormData[stateKey]['documentation'] === true) ? false :
                      key === 'isProject' ? value : this.state.editableFormData[stateKey]['isProject'],
            'tested':
              // ((key === "index") && value === true) ? false :
              //   (key === "index" && value === false) || (this.state.editableFormData[stateKey]['index'] === false) ? true :
              //     (key === "index" && value === false) ? false :
              //       (this.state.editableFormData[stateKey]['index'] === true) ? false :
                      key === 'tested' ? value : this.state.editableFormData[stateKey]['tested'],
            'index': key === 'index' ? value : (key === "documentation" && value === true) ? false : this.state.editableFormData[stateKey]['index'],
            'projectType': key === 'projectType' ? value : (key === "index") && value === true ? 'Index' : (key === "documentation") && value === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === true) || (key === "vmacsImpact" && value === true && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) ? 'Project' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[stateKey]['documentation'] === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[stateKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[stateKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[stateKey]['index'] === true ? 'Index' : this.state.editableFormData[stateKey]['projectType'],
            'logStatus' : key === 'newTariffFilling' ? value ?  'Pending Tariff Filing' : 'Pending Test' : this.state.editableFormData[stateKey]['logStatus'],
            'maintenanceSelectionVersionData': key === 'ctsEffectiveDate' && logsArray  && logsArray.length > 0  ?  [...logsArray.filter((item) => item.ctsEffectiveDate > moment(value).format(AppConstant.API_DATE_FORMAT)), ...[logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).length > 0 ?  logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] : this.state.editableFormData[stateKey]['maintenanceSelectionVersionData'],
            'tariffIssueDate': key === 'newTariffFilling' && value ? null : key === 'tariffIssueDate' ? value : this.state.editableFormData[stateKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'newTariffFilling' && value ? null :  key === 'tariffEffectiveDate' ? value : this.state.editableFormData[stateKey]['tariffEffectiveDate']
          }
        };
      });
      this.setState({ ...this.state, editableFormData: modifiedEditableFormDataState, ...tempState, ...tempCancelState });
    } else {
      this.setState({
        ...this.state, editableFormData: {
          ...this.state.editableFormData,
          [tabKey]: {
            ...this.state.editableFormData[tabKey],
            [key]: value,
            'documentation': key === 'documentation' ? value : (key === "index" && value === true) ? false : this.state.editableFormData[tabKey]['documentation'],
            'isProject':
              ((key === "index" || key === "documentation") && value === true) ? false :
                (key === "index" && value === false && this.state.editableFormData[tabKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[tabKey]['index'] === false) ? true :
                  (key === "index" && value === false) ? false :
                    (this.state.editableFormData[tabKey]['index'] === true || this.state.editableFormData[tabKey]['documentation'] === true) ? false :
                      key === 'isProject' ? value : this.state.editableFormData[tabKey]['isProject'],
            'tested':
              // ((key === "index" || key === "documentation") && value === true) ? false :
              //   (key === "index" && value === false && this.state.editableFormData[tabKey]['documentation'] === false) || (key === "documentation" && value === false && this.state.editableFormData[tabKey]['index'] === false) ? true :
              //     (key === "index" && value === false) ? false :
              //       (this.state.editableFormData[tabKey]['index'] === true || this.state.editableFormData[tabKey]['documentation'] === true) ? false :
                      key === 'tested' ? value : this.state.editableFormData[tabKey]['tested'],
            'index': key === 'index' ? value : (key === "documentation" && value === true) ? false : this.state.editableFormData[tabKey]['index'],
            'projectType': key === 'projectType' ? value : (key === "index") && value === true ? 'Index' : (key === "documentation") && value === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === true) || (key === "vmacsImpact" && value === true && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) ? 'Project' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[tabKey]['documentation'] === true ? 'Documentation' : (key === "vmacsTariffUpdate" && value === false && this.state.editableFormData[tabKey]['vmacsImpact'] === false) || (key === "vmacsImpact" && value === false && this.state.editableFormData[tabKey]['vmacsTariffUpdate'] === false) && this.state.editableFormData[tabKey]['index'] === true ? 'Index' : this.state.editableFormData[tabKey]['projectType'],
            'logStatus' :  key === 'newTariffFilling' ? value ?  'Pending Tariff Filing' : 'Pending Test' :  this.state.editableFormData[tabKey]['logStatus'] ,
            'maintenanceSelectionVersionData': key === 'ctsEffectiveDate' && logsArray  && logsArray.length > 0  ?  [...logsArray.filter((item) => item.ctsEffectiveDate > moment(value).format(AppConstant.API_DATE_FORMAT)), ...[logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).length > 0 ? logsArray.filter((item) => item.ctsEffectiveDate <= moment(value).format(AppConstant.API_DATE_FORMAT) ).reduce((a, b) => a.ctsEffectiveDate > b.ctsEffectiveDate ? a : b) : []]] :  this.state.editableFormData[tabKey]['maintenanceSelectionVersionData'],
            'tariffIssueDate': key === 'newTariffFilling' && value ? null : key === 'tariffIssueDate' ? value : this.state.editableFormData[tabKey]['tariffIssueDate'],
            'tariffEffectiveDate': key === 'newTariffFilling' && value ? null :  key === 'tariffEffectiveDate' ? value : this.state.editableFormData[tabKey]['tariffEffectiveDate']
          
          }
        }, ...tempState, ...tempCancelState
      });
    }
    dataStore.setShowUnsavedWarningAlert(true);
  };

  uploadFile = (e) => {
    tariffLogDataStore.uploadFile(e);
  };


  validateRequest = (data) => {
      const localValidatingErrorBody: any = [];
      let isValidRequest = true;
      if((data.tariffBaseID === 0 || !data.tariffBaseID) && (!data.tariffBaseDetailID)){
          const message = 'Please select valid Tariff Base.';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
      }

      if(data.baseTariffName === '' || !data.baseTariffName || !data.tariff || data.tariff === '' || !data.tariffLogVersion || data.tariffLogVersion === '' || !data.tariffLogSubVersion || data.tariffLogSubVersion === ''){
          const message = 'Please enter valid Tariff version.';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
      }
      if(data.cancels !== '' && (!data.cancelBaseTariffName || data.cancelBaseTariffName === '' || !data.cancelTariff || data.cancelTariff === '' || !data.cancelTariffLogVersion || data.cancelTariffLogVersion === '' || !data.cancelTariffLogSubVersion || data.cancelTariffLogSubVersion === '')) {
          const message = 'Please select valid Tariff Version for Cancellation.';
          localValidatingErrorBody.push(message);
          isValidRequest = false;
      }
      return {
          isValidRequest: isValidRequest, 
          localValidatingErrorBody: localValidatingErrorBody
      };

  }

  cancelEditing = () => {
    const initialFormData = this.state.initialDefaultFormData;
    const updatedRows = this.props.tariffLogDataStore.getMaintenanceGridUpdatedRowIDs();
    if(initialFormData !== this.state.editableFormData || updatedRows.length > 0) {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () =>{this.setState({
            editableFormData: initialFormData
          })},
          '',
          'All the unsaved changes will be lost. Are you sure you want to cancel ?'
        );
    } 
  }

  saveLogEntry = () => {
    let data = { ...this.state.editableFormData };
    const logEntryID = tariffLogDataStore.logEntryTypes.filter((item) => item.valueText === tariffLogDataStore.selectedLogEntryType)[0].settingId;
      if(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) {
        const { isValidRequest, localValidatingErrorBody} = this.validateRequest(data[tariffLogDataStore.selectedBaseTariff]);
        if(isValidRequest) {
          const request =  {
            tariffLogID: 0,
            tariffBaseID: data[tariffLogDataStore.selectedBaseTariff].tariffBaseID,
            tariffNumber: `${data[tariffLogDataStore.selectedBaseTariff].baseTariffName} ${data[tariffLogDataStore.selectedBaseTariff].tariff} ${data[tariffLogDataStore.selectedBaseTariff].tariffLogVersion} ${data[tariffLogDataStore.selectedBaseTariff].tariffLogSubVersion}`,
            tariffIssueDate: data[tariffLogDataStore.selectedBaseTariff].tariffIssueDate ? moment(data[tariffLogDataStore.selectedBaseTariff].tariffIssueDate).format(AppConstant.API_DATE_FORMAT) :  moment(new Date('9999-12-31')).format(AppConstant.API_DATE_FORMAT),
            tariffEffectiveDate: data[tariffLogDataStore.selectedBaseTariff].tariffEffectiveDate ? moment(data[tariffLogDataStore.selectedBaseTariff].tariffEffectiveDate).format(AppConstant.API_DATE_FORMAT): moment(new Date('9999-12-31')).format(AppConstant.API_DATE_FORMAT),
            ctsEffectiveDate: moment(data[tariffLogDataStore.selectedBaseTariff].ctsEffectiveDate).format(AppConstant.API_DATE_FORMAT),
            cancels: `${data[tariffLogDataStore.selectedBaseTariff].cancelBaseTariffName} ${data[tariffLogDataStore.selectedBaseTariff].cancelTariff} ${data[tariffLogDataStore.selectedBaseTariff].cancelTariffLogVersion} ${data[tariffLogDataStore.selectedBaseTariff].cancelTariffLogSubVersion}`,
            index: tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase() ?  data[tariffLogDataStore.selectedBaseTariff].index : undefined,
            newTariffFilling: tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase() ? data[tariffLogDataStore.selectedBaseTariff].newTariffFilling : undefined,
            createUser: tariffLogDataStore.createdUser,
            createDate: moment().format(AppConstant.API_DATE_FORMAT),
            vmacsTariffNumber:`${data[tariffLogDataStore.selectedBaseTariff].tariff}.${data[tariffLogDataStore.selectedBaseTariff].tariffLogVersion}`,
            logEntryID: logEntryID,
            logStatus: data[tariffLogDataStore.selectedBaseTariff].logStatus,
            testInd:tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase() ? data[tariffLogDataStore.selectedBaseTariff].tested : undefined,
            projectName:tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase() ?data[tariffLogDataStore.selectedBaseTariff].projectName : undefined,
            description: data[tariffLogDataStore.selectedBaseTariff].description,
            tariffBaseDetailID: data[tariffLogDataStore.selectedBaseTariff].tariffBaseDetailID
            }
            tariffLogDataStore.saveLogEntry(request);
        } else {
          const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
          errorHandler(messages);
        }
      } else {
        const selectedRows = tariffLogDataStore.agGridServiceForVersionSelection.getSelectedRows();
        let request: any = [];
        const logEntryID = tariffLogDataStore.logEntryTypes.filter((item) => item.valueText === tariffLogDataStore.selectedLogEntryType)[0].settingId;
        if(selectedRows && selectedRows.length > 0 ) {
          Object.keys(data).map(key => {
          selectedRows.map((item) => {
            // const filteredData = Object.keys(data).filter(key => item.tariffNumber.includes(key));
            // console.log(filteredData);
        
              if(item.tariffNumber.includes(key)) {
                const { isValidRequest, localValidatingErrorBody} = this.validateRequest(data[key]);
                if(isValidRequest) {
                  const requestElement =  {
                        tariffLogID: item.tariffLogID,
                        tariffNumber: `${data[key].baseTariffName} ${data[key].tariff} ${data[key].tariffLogVersion} ${data[key].tariffLogSubVersion}`,
                        tariffLogVersion: item.tariffLogNewVersion &&  item.tariffLogNewVersion !== ''?  item.tariffLogNewVersion : item.tariffLogVersion,
                        tariffIssueDate: moment(data[key].tariffIssueDate).format(AppConstant.API_DATE_FORMAT),
                        tariffEffectiveDate: moment(data[key].tariffEffectiveDate).format(AppConstant.API_DATE_FORMAT),
                        ctsEffectiveDate: moment(item.newCtsEffectiveDate).format(AppConstant.API_DATE_FORMAT),
                        cancels: item.cancels,
                        index: item.index,
                        description: data[key].description,
                        createDate: moment(new Date()).format(AppConstant.API_DATE_FORMAT),
                        createUser: item.createUser,
                        updateUser: tariffLogDataStore.createdUser,
                        vmacsTariffNumber: `${data[key].tariff} ${data[key].tariffLogVersion}`,
                        logStatus: data[key].logStatus,
                        testInd: data[key].tested,
                        projectName: item.projectName,
                        logEntryID: logEntryID,
                        tariffBaseID: data[key].tariffBaseID,
                        tariffBaseDetailID: data[key].tariffBaseDetailID
                      }
                      request.push(requestElement);
                } else {
                  const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
                  errorHandler(messages);
                }  
              }           
            });            
          })
        } else {
          errorHandler('Please select atleast one log version for updation.');
        }
        
        
        // let tempData = {};
        // data.forEach((id) => {
        //   tempData = { ...tempData, ...id };
        // });
        tariffLogDataStore.saveLogEntry(request, tariffLogDataStore.selectedLogEntryType);
      }
    
  };


  saveVMACSSetup = () => {
    let data = { ...this.state.editableFormData };
    data = Object.keys(data).map(key => {
      return {
        [key]: {
          "projectName": data[key].projectName,
          "tested": data[key].tested,
          "projectType": data[key].projectType,
          "isProject": data[key].isProject,
          "tariffBaseID": data[key].tariffBaseID,
          "createUser": tariffLogDataStore.createdUser,
          "tariffBaseDetailID": data[key].tariffBaseDetailID
        }
      };
    });
    let tempData = {};
    data.forEach((id) => {
      tempData = { ...tempData, ...id };
    });
    tariffLogDataStore.saveVMACSSetup(tempData);
  };


  changeBaseTariff(e) {
    this.props.tariffLogDataStore.selectedBaseTariff = e.target.value;
    this.props.tariffLogDataStore.getSelectedBaseTariffDetails();
  }


  render(): React.ReactNode {
    const { tariffLogDataStore } = this.props;
    return (
      
        <Container fluid>
          <div className='createTariffLogBox'>
            <div className='screenHeader'>
                <h2 className='screen_title'>Create Tariff Log</h2>
            </div>
            <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'} />
            <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>Tariff Log</Accordion.Header>
                <Accordion.Body>
                  <div className='flex'>
                    <label className="standard_label_style">Log Entry Type</label>
                      <select className="select" 
                          value={tariffLogDataStore.selectedLogEntryType} 
                          onChange={(e)=>{tariffLogDataStore.selectedLogEntryType = e.target.value}}>
                                {tariffLogDataStore.logEntryTypes.map(option => (
                                  <option key={option.valueText} value={option.valueText}>
                                      {option.valueText}
                                  </option>
                              ))}
                          </select>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Base Tariff</Accordion.Header>
                <Accordion.Body>
                  <div className='flex'>
                    {/* <div className="rotatedText">
                      Base Tariff
                    </div> */}
                    <div className='baseTariff'>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Base Tariff</label>
                          <select className="select" 
                            value={tariffLogDataStore.selectedBaseTariff} 
                            onChange={(e)=>{this.changeBaseTariff(e)}}>
                              {tariffLogDataStore.baseTariffData.map(option => (
                                  <option key={option.baseTariff} value={option.baseTariff}>
                                      {option.baseTariff}
                                  </option>
                              ))}
                        </select>
                          {/* <input
                            type="text"
                            className="search_text_field input"
                            value={tariffLogDataStore.baseTariffData[this.state.rowId]?.baseTariff}
                            readOnly={true}
                          /> */}
                        </Col>
                        <Col>
                          <label className="standard_label_style">Tariff Type</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffType}
                            readOnly={true}
                          />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Jurisdiction</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            readOnly={true}
                            value={tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.jurisdiction}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Rules & Regulations</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.rulesReg}
                            readOnly={true}
                          />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Tariff Agency</label>
                          <input
                            type="text"
                            className='agencyInput'
                            readOnly={true}
                            value={tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.agencyName}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Filing Carrier</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.filingCarrier}
                            readOnly={true}
                          />
                        </Col>
                        <Col className='colPipelineArea'>
                          <label className="standard_label_style">Pipeline Area</label>
                          <textarea
                            value={tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.pipeline}
                            className="search_text_field textAreaPipelineArea"
                            readOnly={true}
                          />
                        </Col>
                        <Col></Col>
                      </Row>
                      <Row style={{ 'marginTop': '20px' }}>
                        <AgGridComponent gridConfig={this.getGridConfigJointCarrier('Tariff Log')} />
                      </Row>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {this.renderLogEntryAndVMACSSetupView(tariffLogDataStore)}
              <Accordion.Item eventKey="2">
                <Accordion.Header>Attachments</Accordion.Header>
                <Accordion.Body>
                  <div className='flex tariffTeam'>
                    {/* <div className="rotatedText">
                      Setup Team
                    </div> */}
                    <div style={{ 'width': '100%' }}>
                      <Row>
                        <Attachments saveRoleAccess={!tariffLogDataStore.isEditDisabled()} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.tariffLogDocID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.tariffLogDocID)} agGridService={tariffLogDataStore.agGridServiceForAddAttachment} fileUploadGridData={tariffLogDataStore.fileUploadGridData}  cellValueChangeMap={tariffLogDataStore.cellValueChangeMapAttachment} updateRow={(rowData) => tariffLogDataStore.updateRowAttachment(rowData)}></Attachments>
                        {/* <div className="rightFlexBottom">
                          <CustomButton type={CustomButtonType.Submit} onClick={() => { console.log('1'); }}>
                            Cancel
                          </CustomButton>
                          <CustomButton type={CustomButtonType.Submit} onClick={() => { console.log('1'); }} disabled={tariffLogDataStore.isEditDisabled()}>
                            Create CTS Tariff Option
                          </CustomButton>
                          <CustomButton type={CustomButtonType.Submit} onClick={() => { console.log('1'); }}>
                            Clone Tariff Log
                          </CustomButton>
                        </div> */}
                         <Row>
                              <div className="rightFlexBottom col">
                              {tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Tariff Filing'.toLowerCase() ? <CustomButton type={CustomButtonType.Submit}
                                  disabled={tariffLogDataStore.isEditDisabled()}
                                  onClick={() => {this.saveLogEntry();}}>
                                  Save and Submit for Testing Determination
                                </CustomButton> :
                                <CustomButton type={CustomButtonType.Submit} onClick={() => { this.saveLogEntry(); }} disabled={tariffLogDataStore.isEditDisabled()}>
                                  Save
                                </CustomButton>}
                                <CustomButton type={CustomButtonType.Submit} onClick={() => { this.cancelEditing() }}>
                                  Cancel
                                </CustomButton>
                              </div>
                            </Row>
                      </Row>
                      <Row>
                      </Row>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            
          </div>
        </Container>
    );
  }

  private renderLogEntryAndVMACSSetupView(tariffLogDataStore: TariffLogDataStore) {
    //For now hiding tabs for joint carrier 
    //const tabsArrayForBaseTariff = tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Maintenance'.toLowerCase() ? [tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff].concat(tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails?.map(x => x.baseTariff)) : [tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff];
    const tabsArrayForBaseTariff = [tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff];
    return <Accordion.Item eventKey="1">
      <Accordion.Header>Log Entry</Accordion.Header>
      <Accordion.Body>
        <Tabs
          defaultActiveKey="0"
          id="uncontrolled-tab-example"
          className="mb-3"
        >
          {tabsArrayForBaseTariff.map((x, index) => (
            <Tab eventKey={index} title={x}>
              {this.renderLogEntryBasedOnSelectedEntryType(x, tariffLogDataStore)}
              {dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.TariffLogVMACSSetup) && <><hr></hr>
              <div className='flex tariffTeam'>
                {/* <div className="rotatedText">
                  VMACS Setup
                </div> */}
                <div style={{ 'width': '100%' }}>
                  <Row>
                   { (tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase()) ? <Col>
                      <label className="standard_label_style">Project Name</label>
                      <div style={{ 'marginLeft': '10px' }} aria-disabled={tariffLogDataStore.isEditVMACSSetupDisabled()}>
                        <input type="text"
                          className= 'logStatus'
                          value={this.state.editableFormData[x]?.projectName}
                          //disabled={this.state.editableFormData[x]?.index === true || this.state.editableFormData[x]?.documentation === true}
                          style={{ 'width': '180px' }}
                          onClick={() => this.predictiveSelectOnClick()}
                          onChange={(e) => this.predictiveSelectOnChange(e, x)}
                          readOnly={tariffLogDataStore.isEditVMACSSetupDisabled()}
                        />
                        <div hidden={this.state.hiddenSelect} className="predictiveSelectContainer" id="predictiveSelectContainer">
                          {tariffLogDataStore.shownProjectNames.map((item) => (
                            <div className="predictiveSelect" onClick={(e) => {
                              this.onChangeEditableFormData('projectName', item, x, true);
                            }} aria-disabled={tariffLogDataStore.isEditDisabled()}>
                              {item}
                        </div>
                      ))}
                    </div>
                  </div>
                </Col> :
                    <Col>
                      <div className="status-block">
                        <label className="standard_l]abel_style">Will it be tested</label>
                        <div className="status-block" aria-disabled={tariffLogDataStore.isEditVMACSSetupDisabled()}>
                          <input
                            type="radio"
                            checked={this.state.editableFormData[x]?.tested === true}
                            onChange={() => this.onChangeEditableFormData('tested', true, x)}
                            readOnly={tariffLogDataStore.isEditVMACSSetupDisabled()}
                            disabled={tariffLogDataStore.isEditVMACSSetupDisabled()} />Yes
                          <input
                            type="radio"
                            value={"No"}
                            checked={this.state.editableFormData[x]?.tested === false}
                            onChange={() => this.onChangeEditableFormData('tested', false, x)}
                            readOnly={tariffLogDataStore.isEditVMACSSetupDisabled()}
                            disabled={tariffLogDataStore.isEditVMACSSetupDisabled()} />No
                        </div>
                      </div>
                    </Col>}
                    <Col>
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col>
                    <div className="status-block">
                      <label className="standard_label_style">Is this a Project</label>
                      <div className="status-block">
                        <input
                          type="radio"
                          checked={this.state.editableFormData[x]?.isProject === true}
                          onChange={() => this.onChangeEditableFormData('isProject', true, x)}
                          readOnly={tariffLogDataStore.isEditDisabled()}
                        />Yes
                        <input
                          type="radio"
                          checked={this.state.editableFormData[x]?.isProject === false}
                          onChange={() => this.onChangeEditableFormData('isProject', false, x)}
                          readOnly={tariffLogDataStore.isEditDisabled()}
                        />No
                      </div>
                    </div>
                  </Col>
                  <Col>
                    <label className="standard_label_style">Project Type</label>
                    <select className="select" value={this.state.editableFormData[x]?.projectType} onChange={(e) => this.onChangeEditableFormData('projectType', e.target.value, x)} disabled={tariffLogDataStore.isEditDisabled()}>
                      {tariffLogDataStore.projectTypes.map(option => (
                        <option key={option.settingId} value={option.valueText}>
                          {option.valueText}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col>
                  </Col>
                </Row> */}
                  {/* <Row>
                  <div className="col rightFlexBottom">
                    <CustomButton type={CustomButtonType.Submit} onClick={() => { this.saveVMACSSetup(); }} disabled={tariffLogDataStore.isEditDisabled()}>
                      Save
                    </CustomButton>
                  </div>
                </Row> */}
                </div>
              </div></>}
            </Tab>)
          )}
        </Tabs>
      </Accordion.Body>
    </Accordion.Item>;
  }

  private renderLogEntryBasedOnSelectedEntryType(x: any, tariffLogDataStore: TariffLogDataStore) {
    return <div className='flex tariffTeam'>
      {/* <div className="rotatedText">
        Log Entry
      </div> */}
      <div style={{ 'width': '100%' }}>
        <Row>
          <Col>
            <label className="standard_label_style">Tariff #</label>
            <input
              type="text"
              className="mainVersionInput"
              onChange={(e) => this.onChangeEditableFormData('baseTariffName', e.target.value, x)}
              value={this.state.editableFormData[x]?.baseTariffName}
              readOnly={true} />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('tariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.tariff}
              readOnly={true} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
              if(e.target.value.match(numbers) || e.target.value === '')
              this.onChangeEditableFormData('tariffLogVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogVersion}
              readOnly={tariffLogDataStore.isEditDisabled()} 
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => {const numbers = /^[0-9\b]+$/;
                if(e.target.value.match(numbers) || e.target.value === '')
                this.onChangeEditableFormData('tariffLogSubVersion', e.target.value, x)}}
              value={this.state.editableFormData[x]?.tariffLogSubVersion}
              readOnly={tariffLogDataStore.isEditDisabled()} 
            />
          </Col>
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase()) && (<Col>
            <label className="standard_label_style">Log Status</label>
            <input
              type="text"
              className="logStatus"
              onChange={(e) => this.onChangeEditableFormData('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.logStatus}
              readOnly={true} />
            {/* <label className="standard_label_style">Legal Version</label>
      <input
        type="text"
        className="search_text_field input"
        onChange={(e) => this.onChangeEditableFormData('legalVersion', e.target.value, x)}
        value={this.state.editableFormData[x]?.legalVersion}
        readOnly={tariffLogDataStore.isEditDisabled()}
      /> */}
          </Col>)}
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase()) && (<Col><div className="status-block">
            <label className="standard_label_style">New Tariff Filing</label>
            <div className="status-block">
              <input
                type="radio"
                value="true"
                onChange={() => this.onChangeEditableFormData('newTariffFilling', true, x)}
                checked={this.state.editableFormData[x]?.newTariffFilling === true}
                readOnly={tariffLogDataStore.isEditDisabled()} />Yes
              <input
                type="radio"
                value="false"
                onChange={() => this.onChangeEditableFormData('newTariffFilling', false, x)}
                checked={this.state.editableFormData[x]?.newTariffFilling === false}
                readOnly={tariffLogDataStore.isEditDisabled()} />No
            </div>
          </div></Col>)}
         {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">Tariff Issue Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('tariffIssueDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffIssueDate} disabled={tariffLogDataStore.isEditDisabled()} />
          </Col>}
        </Row>
        <Row>
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">Vmacs Tariff #</label>
            <input
              type="text"
              className="VmacsTariffInput"
              onChange={(e) => this.onChangeEditableFormData('vmacsTariff', e.target.value, x)}
              value={`${this.state.editableFormData[x]?.tariff}.${this.state.editableFormData[x]?.tariffLogVersion}`}
              readOnly={true} 
            />
          </Col>}
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">Description</label>
            <textarea
              className="search_text_field input descriptionInputBox description"
              onChange={(e) => this.onChangeEditableFormData('description', e.target.value, x)}
              value={this.state.editableFormData[x]?.description}
              readOnly={tariffLogDataStore.isEditDisabled()} />
          </Col>}
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase() && tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Request'.toLowerCase()) ? <Col>
             <div className="status-block">
              <label className="standard_label_style">Index</label>
              <div className="status-block">
                <input
                  type="radio"
                  value="true"
                  onChange={() => this.onChangeEditableFormData('index', true, x)}
                  checked={this.state.editableFormData[x]?.index === true}
                  readOnly={tariffLogDataStore.isEditDisabled()}
                  disabled={tariffLogDataStore.isEditDisabled() || tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Tariff Filing'.toLowerCase()} />Yes
                <input
                  type="radio"
                  value="false"
                  onChange={() => this.onChangeEditableFormData('index', false, x)}
                  checked={this.state.editableFormData[x]?.index === false}
                  readOnly={tariffLogDataStore.isEditDisabled()} 
                  disabled={tariffLogDataStore.isEditDisabled() || tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Tariff Filing'.toLowerCase()}/>No
              </div>
            </div>
          </Col> : <Col></Col>}
          
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) &&  <Col>
            <label className="standard_label_style">Tariff Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('tariffEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.tariffEffectiveDate} disabled={tariffLogDataStore.isEditDisabled()} />
          </Col>}
        </Row>
        <Row>
        {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) ? <Col>
            <label className="standard_label_style">Cancels</label>
            <div>
            <input
              type="text"
              className="mainVersionInput"
              onClick={() => this.predictiveSelectOnClick('cancels')}
              onChange={(e) => this.predictiveSelectOnChangeCancel(e, x)}
              // onChange={(e) => this.onChangeEditableFormData('cancelBaseTariffName', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelBaseTariffName}
              readOnly={this.props.tariffLogDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
             // disabled={this.props.tariffLogDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
             <div hidden={this.state.hiddenCancelSelect} className="predictiveSelectContainer" id="predictiveSelectContainerCancel">
                          {tariffLogDataStore.shownCancelVersion.map((item) => (
                            <div className="predictiveSelect" onClick={(e) => {
                              this.onChangeEditableFormData('cancels', item, x, true);
                              const cancelTariff = item.split(' ');
                              if(cancelTariff && cancelTariff.length > 0) {
                                this.state.editableFormData[x].cancelBaseTariffName = `${cancelTariff[0]} ${cancelTariff[1]}`;
                                this.state.editableFormData[x].cancelTariff = cancelTariff[2];
                                this.state.editableFormData[x].cancelTariffLogVersion = cancelTariff[3];
                                this.state.editableFormData[x].cancelTariffLogSubVersion = cancelTariff[4];
                                this.setState({
                                  editableFormData: this.state.editableFormData
                                });
                              }
                            }} aria-disabled={tariffLogDataStore.isEditDisabled()}>
                              {item}
                        </div>
                      ))}
                    </div>
             </div>
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariff', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariff}
              readOnly={this.props.tariffLogDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariffLogVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogVersion}
              readOnly={this.props.tariffLogDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)}
            />
            <input
              type="text"
              className="versionInput"
              onChange={(e) => this.onChangeEditableFormData('cancelTariffLogSubVersion', e.target.value, x)}
              value={this.state.editableFormData[x]?.cancelTariffLogSubVersion}
              readOnly={this.props.tariffLogDataStore.isCancelsFieldDisabled(this.state.editableFormData[x]?.newTariffFilling)} 
            />
            {/* <label className="standard_label_style">Tariff Log Version</label>
  <input
    type="text"
    className="search_text_field input"
    onChange={(e) => this.onChangeEditableFormData('tariffLogVersion', e.target.value, x)}
    value={this.state.editableFormData[x]?.tariffLogVersion}
    readOnly={tariffLogDataStore.isEditDisabled()}
  /> */}
          </Col>: <Col><div className="status-block"></div></Col>}
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase()) ? (<Col>
            <label className="standard_label_style">Log Status</label>
            <input
              type="text"
              className="logStatus"
              onChange={(e) => this.onChangeEditableFormData('logStatus', e.target.value, x)}
              value={this.state.editableFormData[x]?.logStatus}
              readOnly={true} />
            {/* <label className="standard_label_style">Legal Version</label>
      <input
        type="text"
        className="search_text_field input"
        onChange={(e) => this.onChangeEditableFormData('legalVersion', e.target.value, x)}
        value={this.state.editableFormData[x]?.legalVersion}
        readOnly={tariffLogDataStore.isEditDisabled()}
      /> */}
          </Col>) : <Col><div className="status-block"></div>
          </Col>}
          {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) && <Col>
            <label className="standard_label_style">CTS Effective Date</label>
            <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('ctsEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.ctsEffectiveDate} disabled={tariffLogDataStore.isEditDisabled()} />
          </Col>}
        </Row>
       {(tariffLogDataStore.selectedLogEntryType.toLowerCase() !== 'Maintenance'.toLowerCase()) ? <Row>
          <Col>
            <label className="standard_label_style">Description</label>
            <textarea
              className="search_text_field input descriptionInputBox"
              onChange={(e) => this.onChangeEditableFormData('description', e.target.value, x)}
              value={this.state.editableFormData[x]?.description}
              readOnly={tariffLogDataStore.isEditDisabled()} />
          </Col>
          <Col>
            {/* <div className="status-block">
    <label className="standard_label_style">Vmacs Impact</label>
    <div className="status-block">
      <input
        type="radio"
        onChange={() => this.onChangeEditableFormData('vmacsImpact', true, x)}
        checked={this.state.editableFormData[x]?.vmacsImpact === true}//onChange = {(e) => this.onSelectStatus(e)}
        readOnly={tariffLogDataStore.isEditDisabled()}
      />Yes
      <input
        type="radio"
        onChange={() => this.onChangeEditableFormData('vmacsImpact', false, x)}
        checked={this.state.editableFormData[x]?.vmacsImpact === false}
        readOnly={tariffLogDataStore.isEditDisabled()}
      />No
    </div>
  </div> */}
          </Col>
          <Col></Col>
        </Row> :
        <div style ={{marginTop : '20px'}}>
          <Row>
            <Col>
              <div>
                <Col><label className="standard_label_style">CTS Effective Date</label>
                <ReactDatePicker onDateChange={(e) => { this.onChangeEditableFormData('ctsEffectiveDate', e, x); } } selectedDate={this.state.editableFormData[x]?.ctsEffectiveDate} disabled={tariffLogDataStore.isEditDisabled()} />
                </Col>
              </div>
            </Col>
          </Row>
        <Row>
          <Col>
          <label className="standard_label_style">Selection</label>
          <div style={{width: '70%'}}>
            <AgGridComponent gridConfig={this.getGridConfigForLogEntrySelection(this.state.editableFormData[x]?.maintenanceSelectionVersionData, 'Log Entry Selection')}/>
          </div>
          </Col>
          </Row>
          </div>
          }
        <Row>
          <Col>
          </Col>
          <Col>
            {/* <div className="status-block">
    <label className="standard_label_style">Documentation</label>
    <div className="status-block">
      <input
        type="radio"
        onChange={() => this.onChangeEditableFormData('documentation', true, x)}
        checked={this.state.editableFormData[x]?.documentation === true}
        readOnly={tariffLogDataStore.isEditDisabled()}
      />Yes
      <input
        type="radio"
        checked={this.state.editableFormData[x]?.documentation === false}
        onChange={() => this.onChangeEditableFormData('documentation', false, x)}
        readOnly={tariffLogDataStore.isEditDisabled()}
      />No
    </div>
  </div> */}
          </Col>
          <Col></Col>
        </Row>
        <Row>
          {/* <div className="rightFlexBottom col">
            <CustomButton type={CustomButtonType.Submit} onClick={() => { this.saveLogEntry(); }} disabled={tariffLogDataStore.isEditDisabled()}>
    Save
  </CustomButton>
  <CustomButton type={CustomButtonType.Submit}
    disabled={(this.state.editableFormData[x]?.index === true || this.state.editableFormData[x]?.documentation === true) || tariffLogDataStore.isEditDisabled()}
    onClick={() => { console.log('1'); }}>
    Submit for Testing Determination
  </CustomButton>
          </div> */}

        </Row>
      </div>
    </div>;
  }


  getGridConfigForLogEntrySelection(rowData, gridId) {
    const {
      tariffLogDataStore: { fileUploadGridData, agGridServiceForVersionSelection }
    } = this.props;
    const onCellEditingStarted = this.onCellEditingStarted;
    const onCellEditingStopped = this.onCellEditingStopped;
    const tariffLogGridParams: any = {
      rowData: rowData,
      columnDefs: this.logEntrySelectionGridColDef,
      onCellEditingStarted,
      onCellEditingStopped,
      context : agGridServiceForVersionSelection     
    };
    return agGridServiceForVersionSelection.getGridConfig(tariffLogGridParams, gridId);
  }

  onCellEditingStarted = (event: CellEditingStartedEvent): void => {
    const row = event.data.tariffLogID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
        tariffLogDataStore: { cellValueChangeMap }
    } = this.props;
    const isNotEmpty =
        row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
    if (!isNotEmpty) {
        this.props.tariffLogDataStore.setValueInChangeMap(row, col, value, value);
    }
  };

  onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
    const row = event.data.tariffLogID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
      tariffLogDataStore: { cellValueChangeMap}
    } = this.props;
    cellValueChangeMap[row][col]['currentValue'] = value;
    this.updateRow(event.data);
    this.props.tariffLogDataStore.agGridService.getNodes()?.refreshCells();
  };

  @action
  updateRow = (selectedRowData: any): void => {
    const tabBaseTariff = [tariffLogDataStore.selectedBaseTariffDetails[this.state.rowId]?.baseTariff]
      const updatedShopIndex = this.state.editableFormData[tabBaseTariff[0]]?.maintenanceSelectionVersionData.findIndex(a => a.tariffLogID == selectedRowData.tariffLogID);
      if (updatedShopIndex && !_.isEqual(this.state.editableFormData[tabBaseTariff[0]]?.maintenanceSelectionVersionData[updatedShopIndex], selectedRowData)) {        
          this.mapEditableColumns(this.state.editableFormData[tabBaseTariff[0]]?.maintenanceSelectionVersionData[updatedShopIndex], selectedRowData);
          dataStore.setShowUnsavedWarningAlert(true);
      }
      this.setState({
        editableFormData: this.state.editableFormData
      })
         
  };

  mapEditableColumns(currentItem: any, updatedItem: any) {
      currentItem.newVersion = updatedItem.newVersion;
      currentItem.newCtsEffectiveDate = updatedItem.newCtsEffectiveDate;      
  }
}
