import React from 'react';
import { miscellaneousSettingsDataStore } from '../miscellaneous-settings-screen/stores';
import { MiscellaneousSettingsContainer } from '../miscellaneous-settings-screen/components/miscellaneous-settings-container';
import { AgencyMaintenanceContainer } from '../agency-maintenance/components/agency-maintenance-container';
import { agencyMaintenanceDataStore } from '../agency-maintenance/stores';
import { DefaultPageComponent } from '../../../common/components/default-page-component';
import { PipelineMaintenanceContainer } from '../pipeline-maintenance/components/index';
import { addPipelineMaintenanceDataStore, editPipelineMaintenanceDataStore, pipelineMaintenanceDataStore } from '../pipeline-maintenance/stores';
import { AddPipelineMaintenanceContainer } from '../pipeline-maintenance/components/add-pipeline';
import { locationMaintenanceDataStore } from '../location-maintenance/stores';
import { LocationMaintenanceContainer } from '../location-maintenance/components/location-maintenance-container';
import { EditPipelineMaintenanceContainer } from '../pipeline-maintenance/components/edit-pipeline';
import { TariffRateTypesContainer } from '../tariff-rate-types/components';
import { tariffRateTypesDataStore } from '../tariff-rate-types/stores';
import { ConnectionPointMaintenanceContainer } from '../connection-point-maintenance/components/connection-point-maintenance-container';
import { connectionPointDetailsDataStore, connectionPointMaintenanceDataStore } from '../connection-point-maintenance/stores';
import { AddConnectionPointDetailsContainer } from '../connection-point-maintenance/components/add-connection-point-details';
import { LocationDetailsContainer } from '../location-maintenance/components/add-location-details-container';
import { gradeMaintenanceDataStore, addEditGradeMaintenanceDataStore } from '../grade-maintenance/stores';
import { GradeMaintenanceContainer } from '../grade-maintenance/components/grade-maintenance-container';
import { LineSegmentMaintenanceContainer } from '../line-segment-maintenance/components/line-segment-maintenance-container';
import { addEditLineSegmentMaintenanceStore, lineSegmentMaintenanceDataStore } from '../line-segment-maintenance/stores';
import { ProfitCenterMaintenanceContainer } from '../profit-center-maintenance/components/profit-center-maintenance-container';
import { profitCenterDetailsDataStore, profitCenterMaintenanceDataStore } from '../profit-center-maintenance/stores';
import { HomeScreenDashboardContainer } from '../home-screen-dashboard/component/home-screen-dashboard';
import { homeDashboardDataStore } from '../home-screen-dashboard/stores';
import { CustomerMaintenanceContainer } from '../customer-maintenance/components';
import { customerMaintenanceDataStore } from '../customer-maintenance/stores';
import { AddEditLineSegmentMaintenance } from '../line-segment-maintenance/components/add-edit-line-segment-maintenance';
import { AddProfitCenterDetailsContainer } from '../profit-center-maintenance/components/profit-center-details';
import { AddEditGradeMaintenance } from '../grade-maintenance/components/add-edit-grade-maintenance';
import { AddCustomerMaintenanceContainer } from '../customer-maintenance/components/add-customer-maintenance';
import { ShipperLossGainMaintenanceContainer } from '../shipper-loss-gain-maintenance/components/shipper-loss-gain-maintenance-container';
import { shipperLossGainMaintenanceDataStore } from '../shipper-loss-gain-maintenance/stores';
import { FilingEntityMaintenanceContainer } from '../filing-entity-maintenance/components/filing-entity-maintenance-container';
import { addEditFilingEntityMaintenanceDataStore, filingEntityMaintenanceDataStore } from '../filing-entity-maintenance/stores';
import { AddEditFilingEntityMaintenance } from '../filing-entity-maintenance/components/add-edit-filing-entity-maintenance';
import { FilingCarrierMaintenanceContainer } from '../filing-carrier-maintenance/components/filing-carrier-maintenance-container';
import { addEditFilingCarrierMaintenanceDataStore, filingCarrierMaintenanceDataStore } from '../filing-carrier-maintenance/stores';
import { filingCarrierMaintenanceDataService } from '../filing-carrier-maintenance/services/filing-carrier-maintenance-data-service';
import { AddEditFilingCarrierMaintenance } from '../filing-carrier-maintenance/components/add-edit-filing-carrier-maintenance';


export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        'DefaultPageComponent',
        () => (
            <React.Fragment>
                <HomeScreenDashboardContainer homeDashboardDataStore={homeDashboardDataStore} />
            </React.Fragment>
        )
    ],
    [
        'MiscellaneousSettingsContainer',
        () => (
            <React.Fragment>
                <MiscellaneousSettingsContainer miscellaneousSettingsDataStore={miscellaneousSettingsDataStore} />
            </React.Fragment>
        )
    ],
    [
        'AgencyMaintenanceContainer',
        () => (
            <React.Fragment>
                <AgencyMaintenanceContainer agencyMaintenanceDataStore={agencyMaintenanceDataStore} />
            </React.Fragment>
        )
    ],[
        'PipelineMaintenanceContainer',
        () => (
            <React.Fragment>
                <PipelineMaintenanceContainer pipelineMaintenanceDataStore={pipelineMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],
    [
        'AddPipelineMaintenanceContainer',
        () => (
            <React.Fragment>
                <AddPipelineMaintenanceContainer addPipelineMaintenanceDataStore={addPipelineMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],
    [
        'EditPipelineMaintenanceContainer',
        () => (
            <React.Fragment>
                <EditPipelineMaintenanceContainer pipelineMaintenanceDataStore={pipelineMaintenanceDataStore} editPipelineMaintenanceDataStore={editPipelineMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'LocationMaintenanceContainer',
        () => (
            <React.Fragment>
                <LocationMaintenanceContainer locationMaintenanceDataStore={locationMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'TariffRateTypesContainer',
        () => (
            <React.Fragment>
                <TariffRateTypesContainer tariffRateTypesDataStore={tariffRateTypesDataStore}/>
            </React.Fragment>
        )
    ], [
        'ConnectionPointMaintenanceContainer',
        () => (
            <React.Fragment>
                <ConnectionPointMaintenanceContainer connectionPointMaintenanceDataStore={connectionPointMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'AddConnectionPointDetailsContainer',
        () => (
            <React.Fragment>
                <AddConnectionPointDetailsContainer connectionPointDetailsDataStore={connectionPointDetailsDataStore}/>
            </React.Fragment>
        )
    ],
    [
        'LocationDetailsContainer',
        () => (
            <React.Fragment>
                <LocationDetailsContainer locationMaintenanceDataStore={locationMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],  [
        'GradeMaintenanceContainer',
        () => (
            <React.Fragment>
                <GradeMaintenanceContainer gradeMaintenanceDataStore={gradeMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'AddEditGradeMaintenance',
        () => (
            <React.Fragment>
                <AddEditGradeMaintenance addEditGradeMaintenanceStore={addEditGradeMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'LineSegmentMaintenanceContainer',
        () => (
            <React.Fragment>
                <LineSegmentMaintenanceContainer lineSegmentMaintenanceDataStore={lineSegmentMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],  [
        'AddEditLineSegmentMaintenance',
        () => (
            <React.Fragment>
                <AddEditLineSegmentMaintenance addEditLineSegmentMaintenanceStore={addEditLineSegmentMaintenanceStore}/>
            </React.Fragment>
        )
    ],  [
        'ProfitCenterMaintenanceContainer',
        () => (
            <React.Fragment>
                <ProfitCenterMaintenanceContainer profitCenterMaintenanceDataStore={profitCenterMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],  [
        'CustomerMaintenanceContainer',
        () => (
            <React.Fragment>
                <CustomerMaintenanceContainer customerMaintenanceDataStore={customerMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],  [
        'AddProfitCenterDetailsContainer',
        () => (
            <React.Fragment>
                <AddProfitCenterDetailsContainer profitCenterDetailsDataStore={profitCenterDetailsDataStore}/>
            </React.Fragment>
        )
    ],
    [
        'ShipperLossGainMaintenanceContainer',
        () => (
            <React.Fragment>
                <ShipperLossGainMaintenanceContainer shipperLossGainMaintenanceDataStore={shipperLossGainMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'FilingEntityMaintenanceContainer',
        () => (
            <React.Fragment>
                <FilingEntityMaintenanceContainer filingEntityMaintenanceDataStore={filingEntityMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'AddEditFilingEntityMaintenance',
        () => (
            <React.Fragment>
                <AddEditFilingEntityMaintenance addEditFilingEntityMaintenanceDataStore={addEditFilingEntityMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],  [
        'AddCustomerMaintenanceContainer',
        () => (
            <React.Fragment>
                <AddCustomerMaintenanceContainer customerMaintenanceDataStore={customerMaintenanceDataStore}/>
            </React.Fragment>
        )
    ], [
        'FilingCarrierMaintenanceContainer',
        () => (
            <React.Fragment>
                <FilingCarrierMaintenanceContainer filingCarrierMaintenanceDataStore={filingCarrierMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],  [
        'AddEditFilingCarrierMaintenance',
        () => (
            <React.Fragment>
                <AddEditFilingCarrierMaintenance addEditFilingCarrierMaintenanceDataStore={addEditFilingCarrierMaintenanceDataStore}/>
            </React.Fragment>
        )
    ],
]);
