export enum AgencyMaintenanceMessages {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    SYNC = 'Synced Successfully',
    SYNC_FAILED_SUBMIT = 'Failed to sync changes',
}

export enum AgencyMaintenanceButtons {
        SAVE = 'Save Data',
        RESET = 'Reset',
        CANCELLOAD = 'Cancel Load',
        CLEARALL = 'Clear All',
        BULKASSIGN = 'Bulk Assign'
}

export enum AgencyMaintenanceHeaderName {

}

export enum AgencyMaintenanceFieldName {

}

// export class AgencyMaintenanceAllConstants {
// }

// export const SettingNames = [
// ]

// export const commaFormat = (number) => {
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// }