import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { filingEntityColDef } from '../components/filing-entity-col-def';
import moment from 'moment';
import Path from '../../../../Path';
import axios from 'axios';
import { FilingEntityMaintenanceDataService } from '../services/filing-entity-maintenance-data-service';
import { FilingEntityMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { addEditFilingEntityMaintenanceDataStore, filingEntityMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class AddEditFilingEntityMaintenanceStore { 
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userName = '';
    @observable selectedFilingEntity = { value: 'Select', label: 'Select' };
    @observable addOrEditLinkerGridData: any = [];
    @observable backupAddOrEditLinkerGridData: any = [];
    @observable editableFilingEntityData: any = {};
    @observable backupEditableFilingEntityData: any = {};
    @observable isEditingFilingEntity = false;
    @observable selectedFilingEntityId;
    @observable selectedWorkflowId = 0;
    @observable selectedProjectName = '';
    baseSystemData: any[] = [];
    pipelineSystemData: any[] = [];
    
    @observable pipelineSystemDropdownOption: any[] = [];
    
    @observable baseSystemDropdownOption: any[] = [];
    @observable pipelineOwnerDropdownOptions: any[] = [];
    pipelineOwnerData: any[] = [];
    navigateToScreen: any = null;
    
    constructor(
        public filingEntityMaintenanceDataService: FilingEntityMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditLinker: AgGridService,
    ) {
        makeAutoObservable(this);
    }

    async init(): Promise<void> {
        this.userName = this.accountStore.displayName;
        await this.getTariffPipelineOwner();
        if (!this.isEditingFilingEntity) {
            this.getPipelineSystemDropdown();
        }
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffPipelineOwner(): Promise<void> {
        const pipelineOwnerDropdownOptions = await this.filingEntityMaintenanceDataService.getTariffPipelineOwner();
        this.pipelineOwnerData = pipelineOwnerDropdownOptions;
        const a = pipelineOwnerDropdownOptions.map(x => {return { 'value': x.ownerEntityName, 'label': x.ownerEntityName}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        runInAction(() => {
            this.showLoader = false;
            this.pipelineOwnerDropdownOptions = sortedValues;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineSystemDropdown(): Promise<void> {
        const entityId = 0;
        const pipelineSystemDropdownOptions = await this.filingEntityMaintenanceDataService.getPipelineSystem(entityId);
        const result = Object.values(
            pipelineSystemDropdownOptions.reduce((acc, obj) => ({ ...acc, [obj.pipelineID]: obj }), {})
        );
        this.pipelineSystemData = result;
        const a = result.map((x:any) => {return { 'pipelineName': x.plName, 'pipelineID': x.pipelineID, 'entityID': x.entityID, 'plName' : x.plName}});
        const sortedValues =  _.orderBy(a, [x=>x.pipelineName], ['asc']);
        runInAction(() => {
            this.showLoader = false;
            // this.pipelineSystemDropdownOption = sortedValues;
            this.setAddOrEditLinkerGridData(sortedValues);
            this.setBackUpAddOrEditLinkerGridData(sortedValues);
        });
    }

    @action
    async resetAddFilingEntityMaintenanceGridData(): Promise<void> {
        this.addOrEditLinkerGridData = [];
        this.backupAddOrEditLinkerGridData = [];
        const addOrEditLinkerGridData: any = [];

        this.baseSystemData.map((item) => {
            item.isSelected = false;
            addOrEditLinkerGridData.push(item);
        });
        const sortedArr = _.orderBy(addOrEditLinkerGridData, [item=>item.pipelineName], ['asc']);
        this.setAddOrEditLinkerGridData(sortedArr);
        this.setBackUpAddOrEditLinkerGridData(sortedArr);
    }

    @action
    headerCheckBoxSelection = (event) => {
        const checked = event.target.checked;
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.pipelineName && filters.pipelineName.values.includes(item.pipelineName)))) : this.addOrEditLinkerGridData;
        // const data = filteredData.map((item) => { item.isSelected = checked; return (item)})
       
            if(checked) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmDialog(
                    () =>  {  filteredData.forEach((item) => {this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                    })},
                    'Multiple Pipeline',
                    'Do you want to link Tariff Entity to multiple pipelines?'
                );
                
            } else {
                filteredData.forEach((item) => {
                    this.setValueInChangeMap(item.pipelineID, 'isSelected', item.isSelected, checked);
                    item['isSelected'] = checked;
                    this.updateRowforAddOrUpdateGrid(item);
                });
            }
    };

    @action
    checkIfHeaderCheckboxShouldbeChecked = (params) => {
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.pipelineName && filters.pipelineName.values.includes(item.pipelineName)))) : this.addOrEditLinkerGridData;
        return filteredData.some((item) => item.isSelected === false || !item.isSelected)
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected;
        if((this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length > 0 && this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length < 2 ) && checked) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () =>  { this.setValueInChangeMap(row, 'isSelected', initValue, checked);
                params.data.isSelected = checked;
                this.updateRowforAddOrUpdateGrid(params.data);
                },
                'Multiple Pipeline',
                'Do you want to link Tariff Entity to multiple pipelines?'
            );
        } else {
            this.setValueInChangeMap(row, 'isSelected', initValue, checked);
            params.data.isSelected = checked;
            this.updateRowforAddOrUpdateGrid(params.data);
        }
    };

    @action
    globalEffectiveDateSelection = (effectiveDate) => {
            const data = this.addOrEditLinkerGridData.filter((item) => item.isSelected === true).map((item) => { item.effectiveDate = effectiveDate; return (item)})
            data.map((item) => {
                this.updateRowforAddOrUpdateGrid(item);
            }) 
    };

    @action
   setAddOrEditLinkerGridData(addOrEditLinkerGridData: any): void {
       this.addOrEditLinkerGridData = addOrEditLinkerGridData
   }

   setBackUpAddOrEditLinkerGridData(addOrEditLinkerGridData: any): void {
       this.backupAddOrEditLinkerGridData = addOrEditLinkerGridData
   }

    @computed
    get AddOrEditLinkerGridData() {
        return toJS(this.addOrEditLinkerGridData);
    }

    getColDef() {
        return filingEntityColDef;
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditLinkerGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditLinkerGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsTariffEntityDetailsGrid(this.addOrEditLinkerGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsTariffEntityDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.isSelected = updatedItem.isSelected;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async saveFilingEntity(): Promise<void> {
        const updatedRowIDs = this.addOrEditLinkerGridData.filter(a => a.isSelected === true);
        const filingEntityRelatedPipelines: any = [];
        let requestBody: any = {};
        const localValidatingErrorBody: any = [];
        if (this.selectedFilingEntity.label && this.selectedFilingEntity.label !== 'Select') {
            updatedRowIDs.length > 0 && updatedRowIDs.forEach((item) => {
                    item.entitySystemID = item.entitySystemID > 0 ? item.entitySystemID : 0;
                    item.entityID = this.isEditingFilingEntity ? this.editableFilingEntityData.entityID: 0;
                    item.createDate = item.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.createUser = item.createUser || this.userName,
                    item.updateDate = moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.updateUser = this.userName,
                    item.pipelineID = item.pipelineID && item.pipelineID > 0 ? item.pipelineID : 0;
                    item.pipelineName = item.pipelineName ? item.pipelineName : '';
                    item.action = this.isEditingFilingEntity ? this.editableFilingEntityData.action : 'A';
                    filingEntityRelatedPipelines.push(item);
                });
            } else {
            const message = 'Please select Filing Entity as mandatory field.';
            if (!localValidatingErrorBody.includes(message)) {
                localValidatingErrorBody.push(message);
            }
        }
        if (localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if (updatedRowIDs.length === filingEntityRelatedPipelines.length) {
            const filingEntityRelatedPipelinesUpdated = filingEntityRelatedPipelines.length > 0 ? filingEntityRelatedPipelines.map(({ isSelected, pipelineId, ...keepAttrs }) => keepAttrs) : [];
            const custId = this.pipelineOwnerData.filter(x => x.ownerEntityName == this.selectedFilingEntity.label)[0]?.ownerEntityID;
            requestBody = {
                'entityID': this.isEditingFilingEntity ? this.editableFilingEntityData.entityID : 0,
                'extFilingEntityID': this.isEditingFilingEntity ? this.editableFilingEntityData.extFilingEntityID : 0,
                'name': this.selectedFilingEntity.label,
                'createDate': this.isEditingFilingEntity ? this.editableFilingEntityData.createDate : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                'createUser': this.isEditingFilingEntity ? this.editableFilingEntityData.createUser : this.userName,
                'updateDate': this.isEditingFilingEntity ? moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'): null,
                'updateUser': this.isEditingFilingEntity ? this.userName : null,
                'syncStatus': this.isEditingFilingEntity ? this.editableFilingEntityData.syncStatus : SyncStatus[0],
                'action': this.isEditingFilingEntity ? this.editableFilingEntityData.action : 'A',
                'fillingCode': null,
                'customerID': this.isEditingFilingEntity ? this.editableFilingEntityData.customerID : custId,
                'workflowId': 0,
                'projectName': null,
                'tariffEntityRelPipelines': filingEntityRelatedPipelinesUpdated
            }
            const response: any = await this.filingEntityMaintenanceDataService.saveTariffEntity(requestBody);
            if (response && response.entityID) {
                this.uiService.toastService.success(FilingEntityMaintenanceMessages.SAVE);
                if (this.editableFilingEntityData && !_.isEqual(this.editableFilingEntityData, this.backupEditableFilingEntityData)) {
                    response.syncStatus = SyncStatus[0];
                }

                const a = this.addOrEditLinkerGridData;
                if (response.tariffEntityRelPipelines.length > 0) {
                    a.map(x => {
                        response.tariffEntityRelPipelines.map((item) => {
                            if (x.pipelineID == item.pipelineID) {
                                x.isSelected = true;
                                x.entitySystemID =item.entitySystemID;
                                x.action = item.action;
                                x.entityID = item.entityID;
                                x.syncStatus = item.syncStatus ? item.syncStatus : SyncStatus[0];
                                x.createDate = item.createDate
                                x.createUser = item.createUser
                                x.updateDate = item.updateDate,
                                x.updateUser = item.updateUser      
                            }
                            x.pipelineName = x.plName;
                        })
                    })
                } else {
                    a.map(x => {
                        x.pipelineName = x.plName;
                    })
                }

                this.editableFilingEntityData = response;
                this.backupEditableFilingEntityData = response;
                this.selectedFilingEntityId = response.entityID;
                const sortedArr = _.orderBy(a, [item=>item.pipelineName], ['asc']);
                this.setAddOrEditLinkerGridData(sortedArr);
            } else {
                this.uiService.toastService.error(FilingEntityMaintenanceMessages.FAILED_SUBMIT)
            }
        }
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.error) {
            errorHandler(FilingEntityMaintenanceMessages.SYNC_FAILED_SUBMIT);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const entityId = this.editableFilingEntityData ?  this.editableFilingEntityData.entityID.toString() : null;
        const requestBody: any =  {
            workflowid: this.selectedWorkflowId && this.selectedWorkflowId != 0 ? this.selectedWorkflowId : 0,
            env: env,
            id: entityId
            }
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response : any = await this.filingEntityMaintenanceDataService.pushToVmacs(requestBody);
            if(response == true) {
                this.uiService.toastService.success(FilingEntityMaintenanceMessages.SYNC);
                this.editableFilingEntityData.syncStatus = env === 'Push TariffEntity to Vmacs Test' ? SyncStatus[1] : SyncStatus[2]
                this.addOrEditLinkerGridData.forEach((x) => {
                    if(x.isSelected === true) {
                        x.syncStatus = env === 'Push TariffEntity to Vmacs Test' ? SyncStatus[1] : SyncStatus[2]
                    }
                })
            } else {
                this.uiService.toastService.error(FilingEntityMaintenanceMessages.SYNC_FAILED_SUBMIT);
            }
        }
    }    

    @action
    onCloseFilingEntityDetails() {
        // if(this.navigateToScreen) {
        //     history.push(this.navigateToScreen, {workflowId : this.selectedWorkflowId, tariffName: this.navigateState?.tariffLog, tariffNumber: this.navigateState?.tariffLog, baseTariff: this.navigateState?.baseTariff, tariffLogId : this.navigateState?.tariffLogId});
        //     dataStore.setShowUnsavedWarningAlert(false);
        // } else {
            history.push('/FilingEntityMaintenance');  
        // }
    }

    @action
    resetFilingEntityDetails() {
        this.isEditingFilingEntity = false;
        this.selectedFilingEntityId = '';
        this.selectedFilingEntity = { value: 'Select', label: 'Select' };
        this.editableFilingEntityData = {};
        this.backupEditableFilingEntityData = {};
        this.addOrEditLinkerGridData = [];
        this.backupAddOrEditLinkerGridData = [];
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.FilingEntityMaintenance);
    };

    isDeleteDisabled = ()  => {
          return !this.editableFilingEntityData  ||  (this.editableFilingEntityData && !this.editableFilingEntityData.entityID) || this.isActivateDeactivateDisabled()
    }
    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };
}