import { homeDashboardDataService } from '../services/home-dashboard-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { HomeDashboardDataStore } from './home-screen-dashboard-data-store';
import { accountStore } from '../../../account/stores';
import { projectDashboardDataStore } from 'features/project-management/project-dashboard/stores';

export const agGridServiceForHomeOpenRequest = new AgGridService();
export const agGridServiceForTariffLogs = new AgGridService();

export const homeDashboardDataStore = new HomeDashboardDataStore(homeDashboardDataService, agGridService, uiService, accountStore, agGridServiceForHomeOpenRequest, agGridServiceForTariffLogs, projectDashboardDataStore);
