import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { CustomButtonType, CustomButton } from '../../../../shared/components/custom-button/custom-button';
import { agencyMaintenanceDataStore } from '../stores';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { ReactSelect } from 'common/components/select/select';

export interface ConfirmNewAgencyProps {
    settingsTypeData: any;
    onClose: () => void;
    onSuccessClick: (data) => void;
    defaultUserLocation?: string;
    jurisdictionOptions?: any;
    agencyData?: any;
    editableData?: any;
    isEditingAgencyData: boolean;
}
export class AddNewAgencyPopup extends React.Component<ConfirmNewAgencyProps> {
    state = {
        agencyCode: this.props.isEditingAgencyData ? this.props.editableData[0].agencycode : '',
        agencyName: this.props.isEditingAgencyData ? this.props.editableData[0].agencyname : '',
        inValidAgencyCode: '',
        inValidAgencyName: '',
        jurisdiction: this.props.isEditingAgencyData ? { 'value': this.props.editableData[0].jurisdiction, 'label': this.props.jurisdictionOptions.filter(x=>x.value == this.props.editableData[0].jurisdiction)[0]?.label }
            : { 'value': this.props.jurisdictionOptions[0].value, 'label': this.props.jurisdictionOptions[0].label},
    };

    isDisable = (): boolean => {
        const action = this.props.isEditingAgencyData && this.props.editableData[0].action ? this.props.editableData[0].action : null
        return (action && action === 'D') || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, dataStore.currentModuleName);
    }
    
    public render(): React.ReactNode {
        const { onClose, jurisdictionOptions, defaultUserLocation, settingsTypeData, agencyData } = this.props;
        const maxLength = 4;
        return (
            <div className="confirm">
                <Row className="mb-2">
                    <Col>
                        <div className="input">
                                <div className="confirm__action mt-10">Select Jurisdiction:</div>
                            
                                <ReactSelect
                                    className='select w-200'
                                    values={this.state.jurisdiction}
                                    placeholder="Select"
                                    options={jurisdictionOptions}
                                    onChange={(e: any) => {this.setState({ jurisdiction : e})}}
                                    isMulti={false}
                                    isSearchable={true}
                                    disabled={this.props.isEditingAgencyData}
                                />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <div className="confirm__action mt-10">Agency Code:</div>
                            <input
                                className={`search_text_field ${this.state.inValidAgencyCode}`}
                                placeholder="Enter Agency Code"
                                onChange={this.handleChangeAgencyCode}
                                value={this.state.agencyCode}
                                disabled={this.props.isEditingAgencyData || this.isDisable()}
                                maxLength={maxLength}
                            />
                            {this.state.inValidAgencyCode && <div className="email-error">{'Enter a valid agency code'}</div>}
                    </Col>
                    <Col>
                        <div className="confirm__action mt-10">Agency Name:</div>
                            <input
                                className={`search_text_field ${this.state.inValidAgencyName}`}
                                placeholder="Enter Agency Name"
                                onChange={this.handleChangeAgencyName}
                                disabled={this.isDisable()}
                                value={this.state.agencyName}
                            />
                            {this.state.inValidAgencyName && <div className="email-error">{'Enter a valid agency name'}</div>}
                    </Col>
                </Row>
                <div className="confirm__action mt-10">
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick} disabled={!this.isAgencyNameValid() || !this.isAgencyCodeValid()}>
                        Save
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={onClose}>
                        Cancel
                    </CustomButton>
                </div>
            </div>
        );
    }

    isAgencyNameValid = () => {
        const checkValidValue = this.state.agencyName.replaceAll(' ', '').length > 0;
        if(this.state.agencyName.length > 0 && checkValidValue) {
            return true;
        }
        return false;
    }

    isAgencyCodeValid = () => {
        const checkValidValue = this.state.agencyCode.replaceAll(' ', '').length > 0;
        if(this.state.agencyCode.length > 0 && checkValidValue) {
            return true;
        }
        return false;
    }

    handleChangeAgencyCode = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ agencyCode: value });
        const checkValidValue = value.replaceAll(' ', '').length > 0;
        if(value.length > 0 && value.length <= 4 && checkValidValue) {
            this.setState({ inValidAgencyCode: '' });
        } else {
            this.setState({ inValidAgencyCode: 'invalid-value' });
        }
    };

    handleChangeAgencyName = event => {
        const { target } = event;
        const { value } = target;
        this.setState({ agencyName: value });
        const checkValidValue = value.replaceAll(' ', '').length > 0;
        if(value.length > 0 && checkValidValue) {
            this.setState({ inValidAgencyName: '' });
        } else {
            this.setState({ inValidAgencyName: 'invalid-value' });
        }
    };

    handleClick = () => {
        const data = {
            agencyCode: this.state.agencyCode,
            agencyName: this.state.agencyName,
            jurisdiction: this.state.jurisdiction.label,
        }
        agencyMaintenanceDataStore.addEditAgencyData = data;
        this.props.onSuccessClick(data);
        this.props.onClose();
    };
}
