import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class SystemFeeMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    async getSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getPMCShrinkage(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCShrinkage);
        return data;
    }

    async getPMCLossAllwance(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCLossAllwance);
        return data;
    }

    async getSystemFeeDataWithFilter(systemGroupId, facility, effectiveStartDate, effectiveEndDate): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getSystemFeeDataWithFilter+`?systemGroupId=${systemGroupId}&FacilityID=${facility}&effectiveStartDate=${effectiveStartDate}&effectiveEndDate=${effectiveEndDate}`);
        return data;
    }

    async getSystemFeeDataWithoutFilter(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getSystemFeeDataWithFilter+`?systemGroupId=${0}&FacilityID=${0}`);
        return data;
    }

    async saveSystemFeeDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.saveSystemFeeDetails, reqbody, {});
        return data;
    }

}

export const systemFeeMaintenanceDataService = new SystemFeeMaintenanceDataService(restApiService);
