import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { filingCarrierMaintenanceDataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { FilingCarrierCellRenderer } from './cell-renderers/filing-carrier-cell-renderer';

const filterParams = {
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
    textFormatter: (r) => {
        if (r == null) return null;
        return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    suppressAndOrCondition: true,
};
  
export const filingCarrierColDef: ColDef[] = [
    // {   
    //     headerName: 'Select',
    //     field: 'select', 
    //     colId:'Select',
    //     checkboxSelection: (params) => {return true}, 
    //     // cellRenderer: 'checkboxRenderer', 
    //     cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     width: 40, 
    //     headerCheckboxSelection: true, 
    //     headerCheckboxSelectionFilteredOnly: true,
    //     cellStyle: () => {return {padding: '10px'}},
    //     suppressSizeToFit: true, 
    // },
    { 
        headerName: 'Filing Carrier Code', 
        field: 'filingCode',
        colId: 'filingCode',
        filter: true, 
        sortable: true,
        editable: false, 
        filterParams: filterParams, 
        // cellRenderer: FilingCarrierCellRenderer,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 150, 
        flex: 1,
        valueGetter: params => {
            if (!params.data || !params.data['filingCode']) {
                return '';
            } else {
                return params.data['filingCode'];
            }
        }
    },
    { 
        headerName: 'Filing Carrier Name', 
        field: 'name',
        colId: 'name',
        filter: true, 
        sortable: true,
        editable: false, 
        filterParams: filterParams,
        minWidth: 250, 
        flex: 1,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        cellRenderer: FilingCarrierCellRenderer,
        valueGetter: params => {
            if (!params.data || !params.data['name']) {
                return '';
            } else {
                return params.data['name'];
            }
        }
    },
    // { 
    //     headerName: 'Pipeline System', 
    //     field: 'pipelineName',
    //     colId: 'pipelineName',
    //     filter: true, 
    //     sortable: true, 
    //     minWidth: 250,
    //     flex: 1,
    //     valueGetter: params => {
    //         if (!params.data || !params.data['pipelineName']) {
    //             return '';
    //         } else {
    //             return params.data['pipelineName'];
    //         }
    //     },
    //     suppressSizeToFit: true
    // },
    { 
        headerName: 'Updated Date', 
        field: 'updateDate',
        colId: 'updateDate',
        filter: true, 
        sortable: true,
        editable: false, 
        filterParams: filterParams,
        minWidth: 250, 
        flex: 1,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || !params.data['updateDate']) {
                return '';
            } else {
                return moment(params.data['updateDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        }
    },
    // { 
    //     headerName: 'Created User', 
    //     field: 'createUser', 
    //     colId: 'createUser', 
    //     filter: true, 
    //     sortable: true, 
    //     editable: false,
    //     width: 130,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['createUser'] === null) {
    //             return '';
    //         } else {
    //             return params.data['createUser'];
    //         }
    //     }
    // },
    // { 
    //     headerName: 'Created Date',
    //     field: 'createDate',
    //     colId: 'createDate',  
    //     filter: true, 
    //     sortable: true,
    //     editable: false,
    //     width: 120,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['createDate'] === null) {Entity
    //             return '';
    //         } else {
    //             return moment(params.data['createDate']).format(AppConstant.DATE_TIME_FORMAT);
    //         }

    //                } 
    // },
    { 
        headerName: 'Updated User', 
        field: 'updateUser',
        colId: 'updateUser',   
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 150,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        },
         flex: 1
    },
    
   
    // { 
    //     headerName: 'Sync Status', 
    //     field: 'syncStatus', 
    //     colId: 'syncStatus',
    //     width: 130,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['syncStatus'] === null) {
    //             return '';
    //         } else {
    //             return params.data['syncStatus'];
    //         }
    //     }
    // },
    // { 
    //     headerName: 'VMACS Test Sync Date', 
    //     field: 'testSyncDate', 
    //     colId: 'testSyncDate',
    //     editable: false,
    //     width: 145,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (!params.data['testSyncDate']) {
    //             return '';
    //         } else {
    //               return moment(params.data['testSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
    //         }
    //     },
    //     suppressSizeToFit: true  
    // },
    // { 
    //     headerName: 'VMACS Prod Sync Date', 
    //     field: 'prodSyncDate', 
    //     colId: 'prodSyncDate',
    //     editable: false,
    //     width: 145,
    //     // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
    //     valueGetter: params => {
    //         if (!params.data['prodSyncDate']) {
    //             return '';
    //         } else {
    //             return moment(params.data['prodSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
    //         }
    //     },
    //     suppressSizeToFit: true  
    // }
];

