import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class FilingEntityMaintenanceDataService {
    constructor(private restApi: RestApiService) { }
    
    async getTariffEntity(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getTariffEntity);
        return data;
    } 

    async getPipelineSystem(entityId): Promise<any[]> {
        if(entityId) {
            const { data } =  await this.restApi.get<any>(`${Path.getPipelineSystem}?entityId=${entityId}`);
            return data;
        } else {
            const { data } =  await this.restApi.get<any>(`${Path.getBaseSystems}?All=true`);
            return data;
            // getBaseSystems
        }
        // const { data } =  await this.restApi.get<any>(`${Path.getPipelineSystem}?entityId=${entityId}`);
        // return data;
    } 

    async getTariffEntityGrid(request, requestBody): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getTariffEntityGrid}?entityId=${request.entityId}&pipelineSystemId=${request.pipelineSystemId}&ColId=${request.sortColId}&Sort=${request.sort}&isPagination=true&pageno=${request.page}&pagesize=${request.pageSize}`, requestBody, {});
        return data;
    }

    async getTariffPipelineOwner(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getTariffPipelineOwner);
        return data;
    } 

    async getTariffEntityDetails(entityId): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getTariffEntityDetails}?entityID=${entityId}`);
        return data;
    } 

    async saveTariffEntity(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.saveTariffEntity, reqbody, {});
        return data;
    }
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsFilingEntityEndpoint, reqbody, {});
        return data;
    } 
}

export const filingEntityMaintenanceDataService = new FilingEntityMaintenanceDataService(restApiService);
