import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import moment from 'moment';
import { TariffOptionsDataService } from '../services/tariff-options-data-service';
import history from '../../../../shared/components/header-component/component/history';
import { map } from 'lodash';
import { AccountStore } from '../../../account/stores/account-store';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';


export class TariffOptionsDataStore {    
    @observable baseTariffDropdown: any[] = [];
    @observable selectedBaseTariffDetails: any[] = [];
    @observable cancelledTariffDropdown: any[] = [];
    @observable tariffOptions: any[] = [];
    @observable agencyDropdown: any[] = [];
    @observable activeAgencyDropdown: any[] = [];
    @observable statusDropdown: any[] = [];
    @observable tariffTypesDropdown: any[] = [];
    @observable companyDropdown: any[] = [];
    @observable invoiceBasisDropdown: any[] = [];
    @observable pipelineAreasDropdown: any[] = [];
    @observable administratorDropdown: any[] = [];
    @observable ownerDropdownForOwner: any = {};
    
    @observable tariffRelationTypes: any = {};
    
    // @observable shownProjectNames: any[] = [];
    // @observable fileUploadGridData: any[] = [];
    @observable Tabs: any[] = [];
    @observable createdUser;
    @observable selectedPipelineArea = '';

    @observable selectedTab = -1;
    @observable screenHeaderTabSelected:  any[] = [];
    @observable cloneNewTariff = {};
    @observable isCTSCommetsEnabled = false;
    
    @observable formData: any[] = [];

    @observable formDataSelection = {
        baseTariff: '',
        tariffLog: ''
    }

    @observable showUnsavedWarningAlert=false
    
    @observable mvtLineSegment: any [] = [];
    @observable mvtRateTypeDirection: any [] = [];
    @observable mvtRateBasis: any [] = [];
    @observable mvtRateTypes: any [] = [];
    @observable cpRateTypes: any[] = [];
    @observable cpLineLocation: any [] = [];
    @observable cpArea: any = {};
    @observable rulesRegTariffNumberOption: any[] = [];
    @observable cpTypeDropdownOption: any [] = [];
    
    @observable commentsData: any[] = [];
    @observable ctsCommentsData: any[] = [];
    @observable relatedTariffData: any [] = [];
    @observable deduction: any [] = [];
    @observable lossAllowance: any [] = [];
    @observable ownerData: any [] = [];
    @observable pipelineAreaData: any[] = [];
    @observable connectionPointData: any[] = [];
    @observable movementData: any[] = [];
    
    @observable tariffIndexRate; 
    @observable tariffLogId=0;
    @observable loader = false;

    @observable baseTariffShow=false;
    @observable tariffLogShow=false;
    @observable from='';
    @observable url='';
    @observable readOnlyView = false;
    @observable purpose: any = {};

    @observable modifedRows: any = {};

    @observable ctsEffectiveDate = null;
    @observable isCreateNewEntityDisabled = false;

    constructor(
        public tariffOptionsDataService: TariffOptionsDataService,
        public agGridServiceMovement: AgGridService,
        public agGridServiceConnectionPoint: AgGridService,
        public agGridServiceDeduction: AgGridService,
        public agGridServiceLossAllowance: AgGridService,
        public agGridServiceRelatedTariff: AgGridService,
        public agGridServicePipeline: AgGridService,
        public agGridServiceOwner: AgGridService,
        public agGridServiceComments: AgGridService,
        public agGridServiceCTSComments: AgGridService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    @action
    toggleBaseTariffShow() {
        this.baseTariffShow = !this.baseTariffShow
    }

    @action
    toggleTariffLogShow(){
        this.tariffLogShow = !this.tariffLogShow
    }

    @action
    async init(): Promise<void> {
        this.deduction= [];
        this.formDataSelection = {
            baseTariff: '',
            tariffLog: ''
        }
        this.isCTSCommetsEnabled = this.accountStore.userRoles.includes('TariffContributor') || this.accountStore.userRoles.includes('TariffAdmin');
        this.baseTariffShow=true;
        this.tariffLogShow=true;
        const state=history?.location?.state as {from?: string, url?: string, baseTariff?: string, tariffLogId?: number, isCTSCommetsEnabled:boolean, tariffName?: string, disabled?: boolean, isCreateNewEntityDisabled?: any, process?: any} || '';
        await this.getBaseTariffDropDownData();
        this.readOnlyView = state?.disabled ? state.disabled : false
        this.url = state?.url || this.url;
        if(state?.baseTariff){
            await this.changeFormDataSelection(state?.baseTariff || '', 'baseTariff', 'noLoop', null, state?.tariffLogId, state?.process);
            if(state?.from && (state?.from === 'project-request-infrastructure' || state?.from === '/process-tariff-infrastructure' || state?.from === '/Dashboard')) {
                await this.getSelectedBaseTariffDetails();
                this.ctsEffectiveDate = this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x=>x.tariffNumber===state?.tariffName && x.tariffLogID === state?.tariffLogId)?.ctsEffectiveDate || null;
            }
        }
        if(state?.from){
            this.from=state?.from;
        }
        if(state?.tariffName){
            this.formDataSelection = {
                baseTariff: state?.baseTariff || '',
                tariffLog: state?.tariffName || ''
            }
        }
        this.showUnsavedWarningAlert=false;
        this.tariffLogId=state?.tariffLogId || 0;
        this.tariffIndexRate=0;
        this.showUnsavedWarningAlert=false
        // this.selectedBaseTariffDetails=[];
        if(state.tariffLogId){
            await this.getTariffOptions(true);
        }
        this.isCTSCommetsEnabled = state?.from && state?.from !== '/Dashboard' ? state?.isCTSCommetsEnabled : this.isCTSCommetsEnabled;
        this.isCreateNewEntityDisabled = state?.isCreateNewEntityDisabled;
        // this.modifedRows={ 
        //     deduction: [] as any [],
        //     comments: [] as any [],
        //     cp: [] as any [],
        //     relatedTariff: [] as any [],
        //     lossAllowance: [] as any [],
        //     movement: [] as any [],
        //     owner: [] as any [],
        //     pipelineArea: [] as any [],
        //  };
         this.modifedRows = {};
        await this.getAgencyDropdown();
        this.getStatusDropdown();
        this.getTariffTypesDropdown();
        // this.getCompanyDropdown();
        this.getInvoiceBasisDropdown();
        await this.getPipelineAreasDropdown();
        this.getTariffRelationTypes();
        this.getMVTRateTypeDirection();
        this.getMVTRateBasis();
        this.getMVTRateType();        
        this.getTariffIndexRate();
        this.getCPTypeDropdown();
        // this.getAdministratorDropdown();
        this.createdUser = this.accountStore.displayName;
        this.screenHeaderTabSelected = [];
        dataStore.setShowUnsavedWarningAlert(false);
        
    }

    @action
    async addNewTariffOption(){
        this.selectedTab=0;
        await this.getOwnerDropDown(this.pipelineAreasDropdown[0]?.pipelineID, 0);
        await this.getMVTLineSegment(this.pipelineAreasDropdown[0]?.pipelineID, 0);
        await this.getCPLocation(this.pipelineAreasDropdown[0]?.pipelineID, 0);
        this.formData=[...this.formData, {
                agency: this.agencyDropdown[0].agencycode,
                statusValue: 'Active',
                name: '',
                tariffNo: '',
                effectiveDate: '' as unknown as Date,
                administrator: this.ownerDropdownForOwner[0][0]?.customerName,
                type: 'Rates, Rules and Regulations',
                companyValue: this.ownerDropdownForOwner[0][0]?.customerName,
                invoiceBasis: 'Delivery',
                tariffIssueDate: '' as unknown as Date,
                cancelDate: '' as unknown as Date,
                cancelTariff: '',
                pipelineArea: this.pipelineAreasDropdown[0]?.plName,
                syncStatus: '',
                tariffOptionID: 0,
                new: true,
                value1: '',
                value2: '',
                value3: '',
                value4: '',
        }]
        this.showUnsavedWarningAlert=true;
    }

    @action
    unmount(){
        this.formData=[];
        this.formDataSelection = {
            baseTariff: '',
            tariffLog: ''
        }
        this.selectedBaseTariffDetails=[];
        this.loader=false;
        this.tariffLogId=0;
        this.baseTariffShow=false;
        this.tariffLogShow=false;
        this.ctsEffectiveDate = null;
        this.readOnlyView = false;
        this.purpose = {};
    }

    @action

    async getTariffOptions(lastTabNavigation=false, fromClone=false, gridSaved?): Promise<void> {
        try{
            this.loader = true;
            if(!gridSaved)
            this.modifedRows = {};
            await this.getAgencyDropdown();
            await this.getPipelineAreasDropdown();
            const response = await this.tariffOptionsDataService.getTariffOptions(this.baseTariffDropdown.find(x=>this.formDataSelection.baseTariff===x.baseTariff)?.tariffBaseID,this.tariffLogId!==0 ? this.tariffLogId : this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x=>x.tariffNumber===this.formDataSelection?.tariffLog && x.tariffLogID === this.tariffLogId)?.tariffLogID);
            if(Array.isArray(response)){
                this.tariffOptions = response;
                if(response.length>0 && lastTabNavigation===true){
                    this.selectedTab=0;
                    this.baseTariffShow=false;
                    this.tariffLogShow=false;
                }
                if(fromClone){
                    this.selectedTab=response.length-1;
                }
                this.formData = this.tariffOptions.map((x, index)=>{return{
                    value1: (x.value1 || x.value1 !== null) ? x.value1 : (x.tariffNo?.split(' ')[0] && x.tariffNo?.split(' ')[0] || ''),
                    value2: (x.value1 && x.value2 !== null) ? x.value2 : (x.tariffNo?.split(' ')[1] && x.tariffNo?.split(' ')[1] || ''),
                    value3: (x.value1 && x.value3 !== null) ? x.value3 : (x.tariffNo?.split(' ')[2] && x.tariffNo?.split(' ')[2] || ''),
                    value4: (x.value1 && x.value4 !== null) ? x.value4 : (x.tariffNo?.slice((x.tariffNo?.split(' ')[0]+x.tariffNo?.split(' ')[1]+x.tariffNo?.split(' ')[2]).length+3) || ''),
                    agency: this.agencyDropdown.find(y=>y.agencyid===x.agencyID)?.agencycode,
                    statusValue: x.statusValue,
                    parentOptionID: x.parentOptionID,
                    name: x.name,
                    tariffNo: x.value1 && x.value1 + ' '+ (x.value2 || '') + ' ' + (x.value3 || '') + ' ' + (x.value4 || '') || x.tariffNo, 
                    effectiveDate: x.effectiveDate && new Date(x.effectiveDate) || '',
                    administrator: x.administratorValue,
                    type: x.type,
                    companyValue: x.companyValue,
                    invoiceBasis: x.invoiceBasis,
                    tariffIssueDate: x.tariffIssueDate && new Date(x.tariffIssueDate)||'',
                    cancelDate: x.cancelDate && new Date(x.cancelDate)||'',
                    cancelTariff: x.cancelTariff || 'None',
                    pipelineArea: this.pipelineAreasDropdown?.find(y=>y.pipelineID===x.pipelineArea)?.plName,
                    syncStatus: x.syncStatus,
                    tariffOptionID: x.tariffOptionID,
                    createDate: x.createDate,
                    createUser: x.createUser,
                    isCloned: x.isCloned,
                    action: x.action,
                    isChanged: x.isChanged
                }});
                this.formData.forEach((value,index)=>{
                    this.getOwnerDropDown(this.pipelineAreasDropdown.find(y=>y.plName=== value.pipelineArea)?.pipelineID, index, true)
                    this.getMVTLineSegment(this.pipelineAreasDropdown.find(y=>y.plName=== value.pipelineArea)?.pipelineID, index, true)
                    this.getCPLocation(this.pipelineAreasDropdown.find(y=>y.plName=== value.pipelineArea)?.pipelineID, index, true);
                })
                if(!gridSaved || gridSaved === 'deduction') {
                    this.deduction = response.map(x=>x.deduction);
                    this.deduction.forEach((x,index, xarray)=>{
                        x.forEach((y, yindex)=>{
                            xarray[index][yindex]={...y, existing: true, rowId: yindex, index: index }
                        })
                    });
                }
                if(!gridSaved || gridSaved === 'relatedTariff') {
                    this.relatedTariffData = response.map(x=>x.relatedTariff);
                    this.relatedTariffData.forEach((x,index, xarray)=>{
                        x.forEach((y, yindex)=>{
                            xarray[index][yindex]={...y, existing: true,rowId: yindex, index: index }
                        })
                    });
                }
                if(!gridSaved || gridSaved === 'lossAllowance') {
                    this.lossAllowance=response.map((x)=>x.lossAllowance);
                    this.lossAllowance.forEach((x,index, xarray)=>{
                        x.forEach((y, yindex)=>{
                            xarray[index][yindex]={...y, existing: true, rowId: yindex, index: index }
                        })
                    });
                }
                if(!gridSaved || gridSaved === 'owner') {
                    this.ownerData=response.map((x)=>x.ownerOptions);
                    this.ownerData.forEach((x,index, xarray)=>{
                        x.forEach((y, yindex)=>{
                            xarray[index][yindex]={...y, existing: true, rowId: yindex, system: y.plArea, index: index}
                        })
                    });
                }
                if(!gridSaved || gridSaved === 'pipelineArea') {
                    this.pipelineAreaData=response.map(x=>x.pipelineOptions);
                    this.pipelineAreaData.forEach((x,index, xarray)=>{
                        x.forEach((y, yindex)=>{
                            xarray[index][yindex]={...y, existing: true, rowId: yindex, index: index }
                        })
                    });
                }
               
                if(!gridSaved || gridSaved === 'cp') {
                    this.connectionPointData=response.map(x=>x.connectionPoints);
                    // this.connectionPointData=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
                    // this.connectionPointDataBackup=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
                    this.connectionPointData.forEach((x,index, xarray)=>{
                        x.forEach(async (y, yindex)=>{
                            xarray[index][yindex]={...y, rowId: yindex, existing: true, accept: y.accept || false, index: index }
                            await this.getCPArea(index, yindex, y.locationID)
                        })
                    });
                }
                // this.movementData=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
                // this.movementDataBackup=[[{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }], [{'newRate': 1.023, 'oldRate': 0.254 }, {'newRate': 1.023, 'oldRate': 0.254 }]]
                if(!gridSaved || gridSaved === 'movement') {
                    this.movementData=response.map(x=>x.movemements);
                    this.movementData.forEach((x,index, xarray)=>{
                        x.forEach((y, yindex)=>{
                            xarray[index][yindex]={...y, rowId: yindex, existing: true, accept: y.accept || false, index: index }
                        })
                    });
                }
                if(!gridSaved || gridSaved === 'comments') {
                    this.commentsData=response.map(x=>x.comments);
                    this.commentsData.forEach((x,index, xarray)=>{
                        x.forEach((y, yindex)=>{
                            xarray[index][yindex]={...y, rowId: yindex, existing: true, index: index }
                        })
                    });
                }

                if (!gridSaved || gridSaved === 'ctsComments') {
                    const data = response.map(x => x.ctsComments);
                    this.ctsCommentsData = data;                    
                    this.ctsCommentsData = this.ctsCommentsData?.map((x, index, xarray) => {
                        x.forEach(p=>{this.purpose = {...this.purpose, [index]: p.purpose}})
                        const val = x.filter(item=>item.comments && item.comments != '').map((y, yindex) => {
                            return xarray[index][yindex] = { ...y, rowId: yindex, existing: true, index: index }
                        })
                        return val;
                    });
                }
                this.showUnsavedWarningAlert=false;
                // this.agGridServiceConnectionPoint.autoSizeAll();
                this.loader = false;
            }else{
                this.uiService.toastService.error('Fetch error. Failed to fetch Tariff Options.');
                this.loader = false;
            }
        } catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
    }

    @action
    changePurpose = (purpose, index) => {
        this.modifedRows[index] = {...this.modifedRows[index], purpose: [...(this.modifedRows[index]?.purpose||[])]}
        this.modifedRows[index]['purpose'] = [...this.modifedRows[index]['purpose'], index] 
        this.purpose[index] = purpose;
    }

    // @action
    // async getTariffOptions(lastTabNavigation=false,tariffLogId=0): Promise<void> {
    //         try{
    //             this.loader = true;
    //             const response = await this.tariffOptionsDataService.getTariffOptions(this.baseTariffDropdown.find(x=>this.formDataSelection.baseTariff===x.baseTariff)?.tariffBaseID,tariffLogId!==0? tariffLogId : tariffLogId || this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x=>x.tariffNumber===this.formDataSelection?.tariffLog)?.tariffLogID);
    //             if(Array.isArray(response)){
    //             this.tariffOptions = response;
    //             if(response.length>0 && lastTabNavigation===true){
    //                 this.selectedTab=this.tariffOptions.length-1;
    //             }
    //             this.formData = this.tariffOptions.map((x, index)=>{return{
    //                 agency: this.agencyDropdown.find(y=>y.agencyname===x.agency).agencycode,
    //                 statusValue: x.statusValue,
    //                 name: x.name,
    //                 tariffNo: x.tariffNo,
    //                 effectiveDate: x.effectiveDate && new Date(x.effectiveDate) || '',
    //                 administrator: x.administratorValue,
    //                 type: x.type,
    //                 companyValue: x.companyValue,
    //                 invoiceBasis: x.invoiceBasis,
    //                 tariffIssueDate: x.tariffIssueDate && new Date(x.tariffIssueDate)||'',
    //                 cancelDate: x.cancelDate && new Date(x.cancelDate)||'',
    //                 cancelTariff: x.cancelTariff || '',
    //                 pipelineArea: x.plArea,
    //                 syncStatus: x.syncStatus,
    //                 tariffOptionID: x.tariffOptionID,
    //                 createDate: x.createDate,
    //                 createUser: x.createUser,
    //                 value1: x.tariffNo?.split(' ')[0],
    //                 value2: x.tariffNo?.split(' ')[1],
    //                 value3: x.tariffNo?.split(' ')[2],
    //                 value4: x.tariffNo?.slice((x.tariffNo?.split(' ')[0]+x.tariffNo?.split(' ')[1]+x.tariffNo?.split(' ')[2]).length+3)
    //             }});
    //             await this.tariffOptions.forEach((value,index)=>{
    //                 this.getOwnerDropDown(this.pipelineAreasDropdown.find(y=>y.plSysCode=== value.plArea)?.pipelineID|| this.pipelineAreasDropdown[0]?.pipelineID, index, true)
    //                 this.getMVTLineSegment(this.pipelineAreasDropdown.find(y=>y.plSysCode=== value.plArea)?.pipelineID|| this.pipelineAreasDropdown[0]?.pipelineID, index, true)
    //                 this.getCPLocation(this.pipelineAreasDropdown.find(y=>y.plSysCode=== value.plArea)?.pipelineID|| this.pipelineAreasDropdown[0]?.pipelineID, index, true);
    //             })
    //             this.deduction = response.map(x=>x.deduction);
    //             this.deduction.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, existing: true, rowId: yindex }
    //                 })
    //             });
    //             this.relatedTariffData = response.map(x=>x.relatedTariff);
    //             this.relatedTariffData.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, existing: true, rowId: yindex }
    //                 })
    //             });
    //             this.lossAllowance=response.map((x)=>x.lossAllowance);
    //             this.lossAllowance.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, existing: true, rowId: yindex }
    //                 })
    //             });
    //             this.ownerData=response.map((x)=>x.ownerOptions);
    //             this.ownerData.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, existing: true, system: this.pipelineAreasDropdown?.find(x=>x.plSysCode===this.formData[index].pipelineArea)?.plName, index: index}
    //                 })
    //             });
    //             this.pipelineAreaData=response.map(x=>x.pipelineOptions);
    //             this.pipelineAreaData.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, existing: true, rowId: yindex }
    //                 })
    //             });
    //             this.connectionPointData=response.map(x=>x.connectionPoints);
    //             this.connectionPointData.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, rowId: yindex, existing: true }
    //                 })
    //             });
    //             this.movementData=response.map(x=>x.movemements);
    //             this.movementData.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, rowId: yindex, existing: true }
    //                 })
    //             });
    //             this.commentsData=response.map(x=>x.comments);
    //             this.commentsData.forEach((x,index, xarray)=>{
    //                 x.forEach((y, yindex)=>{
    //                     xarray[index][yindex]={...y, rowId: yindex, existing: true }
    //                 })
    //             });
    //             this.loader = false;
    //             }else{
    //                 this.uiService.toastService.error('Fetch error. Failed to fetch Tariff Options.');
    //                 this.loader = false;
    //             }
    //     }catch(error: any){
    //         this.loader=false;
    //         errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))
    //     }
    // }

    
    @Loader
    @action
    async getCancelledTariffs(): Promise<void> {
        const response = await this.tariffOptionsDataService.getCancelledTariffs(this.baseTariffDropdown.find(x=>this.formDataSelection.baseTariff===x.baseTariff)?.tariffBaseID,this.selectedBaseTariffDetails[0]?.tariffLogs.find(x=>x.tariffNumber===this.formDataSelection?.tariffLog && x.tariffLogID === this.tariffLogId)?.tariffLogID);
        this.cancelledTariffDropdown = response;
    }

    @action
    async changeFormDataSelection(value, key, from='noLoop', version?, logId?, process = true) {
        this.formDataSelection= {...this.formDataSelection, [key]: value}
        if(key==='baseTariff'){
            this.changeFormDataSelection('','tariffLog','loop', version, logId);
            this.selectedBaseTariffDetails=[];
            this.getSelectedBaseTariffDetails(process);
            this.tariffOptions=[];
            this.formData=[];
            this.ctsEffectiveDate = null;
        }
        if(key==='tariffLog'&&from==='noLoop'){
            this.ctsEffectiveDate = this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x=>x.tariffNumber===value && (version ? version.includes(x.tariffLogVersion) :  logId === x.tariffLogID))?.ctsEffectiveDate || null
            this.tariffLogId = this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x => x.tariffNumber === value && (version ? version.includes(x.tariffLogVersion) :  logId === x.tariffLogID))?.tariffLogID;
            this.getTariffOptions(true);
            this.getCancelledTariffs();
        }
    }

    @action
    changeFormData(value, key, index) {
        this.formData[index]= {...this.formData[index], [key]: value}
        if(key==='pipelineArea'){
            this.getOwnerDropDown(this.pipelineAreasDropdown.find(y=>y.plName=== value)?.pipelineID, index);
            this.getMVTLineSegment(this.pipelineAreasDropdown.find(x=>x.plName===value)?.pipelineID, index);
            this.getCPLocation(this.pipelineAreasDropdown.find(x=>x.plName===value)?.pipelineID, index);
            this.formData[index]= {...this.formData[index], 'administrator': '', 'companyValue':''}
        }
        if(key==='cancelTariff'&&value==='None'){
            this.formData[index]= {...this.formData[index], [key]: ''}
        }
        this.showUnsavedWarningAlert=true;
    }

    @Catch(() => console.log('Fetch Error')) 
    @action
    async getBaseTariffDropDownData(): Promise<void> {
        try{this.loader=true;
        const response = await this.tariffOptionsDataService.getBaseTariffs();
        this.baseTariffDropdown = response && Array.isArray(response) && [...new Map(response.map(item =>
            [item['baseTariff'], item])).values()] || [];
        this.loader=false ;}catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
    }

    
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getSelectedBaseTariffDetails(process = true): Promise<void> {
        try{this.loader=true;
        const selectedBaseTariff = this.baseTariffDropdown.find(y=>{return this.formDataSelection.baseTariff===y.baseTariff});
        const baseTariffDetails = await this.tariffOptionsDataService.getSelectedBaseTariffDetails(selectedBaseTariff.tariffBaseID, process);
        this.selectedBaseTariffDetails = baseTariffDetails;
        this.loader=false;}catch (e) {
            this.loader = false;
            this.uiService.toastService.error('Some error occurred. Check console for more details.');
            console.log(e);
        }
    }
 
    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getAgencyDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getAgencyDropdown();
        this.agencyDropdown = response;
        this.activeAgencyDropdown= response.filter((item) => item.action !== 'D');
        // agencyname
    }

    @Loader
    @action
    async getStatusDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getStatusDropdown();
        this.statusDropdown = response;
        // valueText
    }

    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getTariffTypesDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getTariffTypesDropdown();
        this.tariffTypesDropdown = response;
        // valueText
    }

    // @Loader
    // @action
    // async getCompanyDropdown(): Promise<void> {
    //     const response = await this.tariffOptionsDataService.getCompanyDropdown();
    //     this.companyDropdown = response;
    //     // plName
    // }
    
    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getInvoiceBasisDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getInvoiceBasisDropdown();
        this.invoiceBasisDropdown = response;
        // valueText
    }
    
    @Loader
    @Catch(() => console.log('Fetch Error')) 
    @action
    async getPipelineAreasDropdown(): Promise<void> {
        const response = await this.tariffOptionsDataService.getPipelineAreasDropdown();
        this.pipelineAreasDropdown = response;
        // plName
    }

    
    @Loader
    @Catch(() => console.log('Fetch Error')) 
    @action
    async getTariffIndexRate(): Promise<void> {
        const response: any = await this.tariffOptionsDataService.getTariffIndexRate();
        this.tariffIndexRate = response.indexRate || '';
        // plName
    }
    
    

    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getTariffRelationTypes(): Promise<void> {
        const response = await this.tariffOptionsDataService.getTariffRelationTypes();
        this.tariffRelationTypes = response;
        // plName
    }
    
 

    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getMVTRateTypeDirection(): Promise<void> {
        const response = await this.tariffOptionsDataService.getMVTRateTypeDirection();
        this.mvtRateTypeDirection = response;
        // plName
    }

    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getMVTRateBasis(): Promise<void> {
        const response = await this.tariffOptionsDataService.getMVTRateBasis();
        this.mvtRateBasis = response;
        // plName
    }
    
    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getMVTRateType(): Promise<void> {
        const response = await this.tariffOptionsDataService.getMVTRateType();
        this.mvtRateTypes = response.filter((x) => x.mvmt === true);
        this.cpRateTypes = response;
        // plName
    }
    
    
    @Loader
    @Catch((error) => {console.log(error)}) 
    @action
    async getCPArea(index, rowId, location, redraw = false): Promise<void> {
        const selectedBaseTariffDetails = this.selectedBaseTariffDetails[0]?.tariffLogs.find(x => x.tariffNumber === this.formDataSelection?.tariffLog && x.tariffLogID === this.tariffLogId);
        let response = await this.tariffOptionsDataService.getCPTariff(location);
        response = selectedBaseTariffDetails.testInd ? response : response.filter(x => x.syncStatus == 'Synced to PROD')
        if(Array.isArray(response)){
            this.cpArea=  {...this.cpArea, [index]:{...this.cpArea[index], [rowId]:[...response]}};
        }
        if(redraw){
             this.agGridServiceConnectionPoint.redrawRows();
        }
    }

    @Loader
    @Catch((error) => {console.log(error)}) 
    @action
    async getTariffNumberOption(agencyID): Promise<void> {
        const agency = this.agencyDropdown.find((item) => item.agencyid === agencyID);
        if(agency.agencyid) {
            const response = await this.tariffOptionsDataService.getRulesRegOptions(agency.agencyid);
            if(Array.isArray(response)){
                const a = this.rulesRegTariffNumberOption.map((x) => x.tariffOptionID)
                response.map((item) => {
                    if(!a.includes(item.tariffOptionID))
                    this.rulesRegTariffNumberOption.push(item)
                })
            }
            this.agGridServiceRelatedTariff.redrawRows();
        }
        
    }
    
    // @Loader
    // @action
    // async getAdministratorDropdown(): Promise<void> {
    //     const response = await this.tariffOptionsDataService.getAdministratorDropdown();
    //     this.administratorDropdown = response;
    //     // customerName
    // }

    @Loader
    @Catch((e) => console.log(e)) 
    @action
    async getOwnerDropDown(pipeline, index, check=false): Promise<void> {
        const response = await this.tariffOptionsDataService.getOwnerDropDown(pipeline);
        this.ownerDropdownForOwner = {...this.ownerDropdownForOwner, [index]: response};
        if(check===true){
            this.formData[index] = {...this.formData[index],
            administrator: this.ownerDropdownForOwner[index]?.map(x=>x.customerName)?.includes(this.formData[index]?.administrator)?this.formData[index]?.administrator:this.ownerDropdownForOwner[index]?.map(x=>x.customerName)[0],
            companyValue: this.ownerDropdownForOwner[index]?.map(x=>x.customerName)?.includes(this.formData[index]?.companyValue)?this.formData[index]?.companyValue:this.ownerDropdownForOwner[index]?.map(x=>x.customerName)[0],
            };
        }
        console.log(toJS(this.formData))
    }

    @Loader
    @Catch(() => console.log('Fetch Error')) 
    @action
    async getMVTLineSegment(pipeline, index, check = false): Promise<void> {
        const selectedBaseTariffDetails = this.selectedBaseTariffDetails[0]?.tariffLogs.find(x => x.tariffNumber === this.formDataSelection?.tariffLog && x.tariffLogID === this.tariffLogId);
        let response = await this.tariffOptionsDataService.getMVTLineSegment(pipeline);
        response = selectedBaseTariffDetails.testInd ? response : response.filter(x => x.syncStatus == 'Synced to PROD')
        this.mvtLineSegment = {...this.mvtLineSegment, [index]: response};
        // plName
    }
    
    @Loader
    @action
    @Catch(() => console.log('Fetch Error')) 
    async getCPLocation(pipeline, index, check = false): Promise<void> {
        const selectedBaseTariffDetails = this.selectedBaseTariffDetails[0]?.tariffLogs.find(x => x.tariffNumber === this.formDataSelection?.tariffLog && x.tariffLogID === this.tariffLogId);
        let response = await this.tariffOptionsDataService.getCPLocation(pipeline);
        response = selectedBaseTariffDetails.testInd ? response : response.filter(x => x.syncStatus == 'Synced to PROD')
        this.cpLineLocation = {...this.cpLineLocation, [index]: response};
        // plName
    }

    
    @action
    @Catch((e) => {console.log(e)})
    async getCPTypeDropdown(): Promise<void> {
        const cpTypeDropdownOption = await this.tariffOptionsDataService.getCPTypesData();
        this.cpTypeDropdownOption = cpTypeDropdownOption ;
    }


    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveTariffOption(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.formData[index]?.agency){
            requiredFlag=true;
            requiredOptions.push('Agency');
        }
        if(!this.formData[index]?.value1||!this.formData[index]?.value2){
            requiredFlag=true;
            requiredOptions.push('Tariff No.');
        }
        if(!this.formData[index]?.type){
            requiredFlag=true;
            requiredOptions.push('Type');
        }
        if(!this.formData[index]?.companyValue){
            requiredFlag=true;
            requiredOptions.push('Company');
        }
        if(!this.formData[index]?.invoiceBasis){
            requiredFlag=true;
            requiredOptions.push('Invoice Basis');
        }
        if(!this.formData[index]?.statusValue){
            requiredFlag=true;
            requiredOptions.push('Status');
        }
        if(!this.formData[index]?.effectiveDate){
            requiredFlag=true;
            requiredOptions.push('Effective Date');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        if(this.formData[index]?.type === 'Rates'){
            const data  =  this.relatedTariffData.length > 0 && this.relatedTariffData[index].length > 0 ? this.relatedTariffData[index].filter((item) => item.relationship === 'General Rules/Regulations') : [];
            if(data.length <= 0) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                    () => {return},
                    ConfirmWithOK,
                    {secondaryText: `A Related Rules and Regulations Tariff needs to be associated with a ${this.formData[index]?.type} Tariff on the Related Tariff Section.`} as ConfirmWithOKProps,
                    'confirmPopup'
                );
            }
           
        }
        const tariffLog = this.selectedBaseTariffDetails[0]?.tariffLogs?.find(x=>x.tariffNumber===this.formDataSelection?.tariffLog && x.tariffLogID === this.tariffLogId);
        let requestBody : any =  { 
                    'tariffOptionID': tariffOptionID || 0, 
                    'name': this.formData[index]?.name, 
                    'tariffBaseID': this.baseTariffDropdown.find(x=>this.formDataSelection.baseTariff===x.baseTariff)?.tariffBaseID, 
                    'tariffLogID': tariffLog ? tariffLog.tariffLogID : this.tariffLogId ? this.tariffLogId : null, 
                    'agencyID': this.agencyDropdown.find(x=>x.agencycode===this.formData[index].agency)?.agencyid,
                    'tariffNo': this.formData[index].value1 && this.formData[index].value1 + ' '+ this.formData[index].value2 + ' ' + this.formData[index].value3 + ' ' + this.formData[index].value4 || this.formData[index]?.tariffNo, 
                    'typeID': this.tariffTypesDropdown.find(x=>x.valueText===this.formData[index].type)?.settingID, 
                    'companyID': this.ownerDropdownForOwner[index]?.find(x=>x.customerName===this.formData[index]?.companyValue)?.customerID, 
                    'invoiceBasisID': this.invoiceBasisDropdown.find(x=>x.valueText===this.formData[index]?.invoiceBasis)?.settingID, 
                    'pipelineArea': this.pipelineAreasDropdown.find(x=>x.plName===this.formData[index]?.pipelineArea)?.pipelineID, 
                    'status': this.statusDropdown.find(x=>x.valueText===this.formData[index]?.statusValue)?.settingID, 
                    'effectiveDate': this.formData[index]?.effectiveDate && moment(new Date(this.formData[index]?.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss') || null,
                    'tariffIssueDate': this.formData[index]?.tariffIssueDate && moment(new Date(this.formData[index]?.tariffIssueDate)).format('YYYY-MM-DDTHH:mm:ss')|| null,
                    'cancelDate': this.formData[index]?.cancelDate && moment(new Date(this.formData[index]?.cancelDate)).format('YYYY-MM-DDTHH:mm:ss')|| null, 
                    'cancelTariff': this.formData[index]?.cancelTariff===''||this.formData[index]?.cancelTariff==='None'?null:this.formData[index]?.cancelTariff, 
                    'administrator': this.ownerDropdownForOwner[index]?.find(x=>x.customerName===this.formData[index]?.administrator)?.customerID, 
                    'createDate': this.formData[index].createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    'createUser': this.formData[index].createUser || this.createdUser,
                    'value1': this.formData[index].value1,
                    'value2': this.formData[index].value2,
                    'value3': this.formData[index].value3,
                    'value4': this.formData[index].value4,
                    'parentOptionID': this.formData[index].parentOptionID || null
        }
        let response: any[] = []; 
        if(this.formData[index]?.new===true){
            response = await this.tariffOptionsDataService.saveTariffOptions([requestBody]);
        }else{
            requestBody = {
                ...requestBody,  
                'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'updateUser': this.createdUser 
            }
            response = await this.tariffOptionsDataService.editTariffOptions([requestBody]);
        }
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions();
                this.uiService.toastService.success('Tariff Options Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    addComments(index, linkTariff=false){
        if(this.CommentsData.length){
            this.commentsData[index]=[{
                'rowId': this.CommentsData[index].length,
                'index': index
            }, ...this.CommentsData[index]]
        } else {
            this.commentsData[0]=[{
                'rowId': 0,
                'index': index
            }]
        }
    }

    @computed
    get CommentsData() {
        return toJS(this.commentsData);
    }

    addCTSComments(index, linkTariff=false){
        if(this.CTSCommentsData.length){
            this.ctsCommentsData[index]=[{
                'rowId': this.CTSCommentsData[index].length,
                'index': index
            }, ...this.CTSCommentsData[index]]
        } else {
            this.ctsCommentsData[0]=[{
                'rowId': 0,
                'index': index
            }]
        }
    }
    
    @computed
    get CTSCommentsData() {
        return toJS(this.ctsCommentsData);
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteComments (requestBody, index): Promise<void>  {
        this.commentsData[index]=this.CommentsData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.optionCmntID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteComments(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'comments');
                    this.uiService.toastService.success('Comment/s Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }


    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveComments(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.CommentsData[index].every(x=>x.comments)){
            requiredFlag=true;
            requiredOptions.push('Comments');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.CommentsData[index].filter(x=>!x.existing || this.modifedRows[index]['comments']?.includes(x.rowId))?.map((x)=>{
            return { 
            'optionCmntID': x.optionCmntID || 0, 
            'optionID': tariffOptionID, 
            'comments': x.comments, 
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser ,
            'parentID': x.parentID || null
            }}
        )
        const response: any[] = await this.tariffOptionsDataService.saveComments(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                const { comments, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'comments'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'comments');
                this.uiService.toastService.success('Comments Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteCTSComments (requestBody, index): Promise<void>  {
        this.ctsCommentsData[index]=this.CTSCommentsData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.optionCmntID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteComments(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'ctsComments');
                    this.uiService.toastService.success('Comment/s Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveCTSComments(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.CTSCommentsData[index].every(x=>x.comments)){
            requiredFlag=true;
            requiredOptions.push('Comments');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.CTSCommentsData[index].filter(x=>!x.existing || (this.modifedRows[index] && this.modifedRows[index]['ctsComments']?.includes(x.rowId)))?.map((x)=>{
            return { 
            'optionCmntID': x.optionCmntID || 0, 
            'optionID': tariffOptionID, 
            'purpose': this.purpose[index],
            'comments': x.comments, 
            'commentType': 'CTS',
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser ,
            'parentID': x.parentID || null
            }}
        )
        if (requestBody.length <= 0 && this.purpose[index] != '') {
            const request =  { 
                'optionCmntID':  0, 
                'optionID': tariffOptionID, 
                'purpose': this.purpose[index],
                'comments': '', 
                'commentType': 'CTS',
                'createDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': this.createdUser, 
                'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'updateUser': this.createdUser ,
                'parentID': null
            }
            requestBody.push(request)
        }
        const response: any[] = await this.tariffOptionsDataService.saveComments(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            } else {
                if (this.modifedRows && this.modifedRows[index]) {
                    const { ctsComments, purpose, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'ctsComments' && key !== 'purpose'));
                    this.modifedRows[index] = rest
                }
                this.getTariffOptions(false, false, 'ctsComments');
                this.uiService.toastService.success('Tariff Comments Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }
    
    addRelatedTariff(index, linkTariff=false){
        const agency  = this.agencyDropdown.find((item) => item.agencycode === this.formData[this.selectedTab]?.agency);
        const isRulesAndReg = this.formData[this.selectedTab]?.type === 'Rates';
        if(this.RelatedTariffData.length){
            this.relatedTariffData[index]=[{
                'relationship': linkTariff===true || isRulesAndReg?'General Rules/Regulations':'', 
                'agencyID': agency && agency.agencyid? agency.agencyid : '',
                'agencyName': agency && agency.agencyname? agency.agencyname : '',
                'number': '', 
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'rowId': this.RelatedTariffData[index].length,
                'index': index
            }, ...this.RelatedTariffData[index]]
        } else {
            this.relatedTariffData[0]=[{
                'relationship': '', 
                'agencyID': agency && agency.agencyid? agency.agencyid : '',
                'agencyName': agency && agency.agencyname? agency.agencyname : '',
                'number': '', 
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'rowId': 0,
                'index': index
            }]
        }
        this.getTariffNumberOption(agency.agencycode);
    }

    @computed
    get RelatedTariffData() {
        return toJS(this.relatedTariffData);
    }

    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteRelatedTariff (requestBody, index): Promise<void>  {
        this.relatedTariffData[index]=this.RelatedTariffData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffRelID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteRelatedTariff(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'relatedTariff');
                    this.uiService.toastService.success('Related Tariff Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveRelatedTariff(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.RelatedTariffData[index].every(x=>x.relationship)){
            requiredFlag=true;
            requiredOptions.push('Relationship');
        }
        if(!this.RelatedTariffData[index].every(x=>x.agencyName)){
            requiredFlag=true;
            requiredOptions.push('Agency');
        }
        if(!this.RelatedTariffData[index].every(x=>x.number)){
            requiredFlag=true;
            requiredOptions.push('Number');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.RelatedTariffData[index].filter(x=>!x.existing || this.modifedRows[index]['relatedTariff']?.includes(x.rowId))?.map((x)=>{
            return { 
            'tariffRelID': x.tariffRelID || 0, 
            'tariffOptionID': tariffOptionID, 
            'relationship': x.relationship, 
            'agencyID': this.agencyDropdown.find(y=>y.agencyname===x.agencyName).agencyid, 
            'number': x.number, 
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser,
            'parentID': x.parentID || null
            }}
        )
        const response: any[] = await this.tariffOptionsDataService.saveRelatedTariff(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                const { relatedTariff, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'relatedTariff'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'relatedTariff');
                this.uiService.toastService.success('Related Tariff Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }
   
    addDeduction(index){
        if(this.DeductionData.length){
            this.deduction[index]=[{
                'tariffDedID': '', 
                'tariffOptionID': '', 
                'begin': '', 
                'end': '', 
                'deduction': '',
                'rowId': this.DeductionData[index].length,
                'index': index
            }, ...this.DeductionData[index]]
        } else {
            this.deduction[0]=[{
                'tariffDedID': '', 
                'tariffOptionID': '', 
                'begin': '', 
                'end': '', 
                'deduction': '', 
                'rowId': 0,
                'index': index
            }]
        }
    }

    @computed
    get DeductionData() {
        return toJS(this.deduction);
    }

    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteDeduction (requestBody, index): Promise<void>  {
        this.deduction[index]=this.DeductionData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffDedID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteDeduction(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'deduction');
                    this.uiService.toastService.success('Deduction Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveDeduction(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.DeductionData[index].every(x=>x.begin.toString())){
            requiredFlag=true;
            requiredOptions.push('Begin');
        }
        if(!this.DeductionData[index].every(x=>x.end.toString())){
            requiredFlag=true;
            requiredOptions.push('End');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.DeductionData[index].filter(x=>!x.existing || this.modifedRows[index]['deduction']?.includes(x.rowId)).map((x)=>{
            return { 
            'tariffDedID': x.tariffDedID || 0, 
            'tariffOptionID': tariffOptionID, 
            'begin': x.begin, 
            'end': x.end ? x.end : null, 
            'deduction': x.deduction ? x.deduction : null, 
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser,
            'parentID': x.parentID || null 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveDeduction(requestBody);
        if(response){
            let errorFlag=false;let errorValue=''
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            } 
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                const { deduction, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'deduction'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'deduction');
                this.uiService.toastService.success('Deduction Saved Successfully!')
            }dataStore.setShowUnsavedWarningAlert(false);
        } 
    }

    
    
    addLossAllowance(index): boolean {
        if(index==='blank'){
            return false;
        }
        if(this.LossAllowanceData.length){
            this.lossAllowance[index]=[{
                'tariffLAID': 0, 
                'tariffOptionID': '',
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'endDate': '', 
                'lossAllowance': '',  
                'rowId': this.LossAllowanceData[index].length,
                'index': index
            }, ...this.LossAllowanceData[index]]
        } else {
            this.lossAllowance[0]=[{
                'tariffLAID': 0, 
                'tariffOptionID': '', 
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'endDate': '', 
                'lossAllowance': '',  
                'rowId': 0,
                'index': index
            }]
        }
        return true;
    }

    @computed
    get LossAllowanceData() {
        return toJS(this.lossAllowance);
    }
    
    @action
    setLossAllowaneDate(value, rowId, key, index){
        this.lossAllowance=this.LossAllowanceData;
        this.lossAllowance[index][rowId][key] = value;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteLossAllowance (requestBody, index): Promise<void>  {
        this.lossAllowance[index]=this.LossAllowanceData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffLAID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteLossAllowance(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'lossAllowance');
                    this.uiService.toastService.success('Loss Allowance Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveLossAllowance(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.LossAllowanceData[index].every(x=>x.effectiveDate)){
            requiredFlag=true;
            requiredOptions.push('Effective Date');
        }
        if(!this.LossAllowanceData[index].every(x=>x.lossAllowance)){
            requiredFlag=true;
            requiredOptions.push('Loss Allowance');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.LossAllowanceData[index].filter(x=>!x.existing || this.modifedRows[index]['lossAllowance']?.includes(x.rowId)).map((x)=>{
            return { 
            'tariffLAID': x.tariffLAID || 0, 
            'tariffOptionID': tariffOptionID, 
            'effectiveDate': x.effectiveDate && moment(new Date(x.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss')||null, 
            'endDate': x.endDate && moment(new Date(x.endDate)).format('YYYY-MM-DDTHH:mm:ss')||null, 
            'lossAllowance': x.lossAllowance?x.lossAllowance:null,  
            'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'createUser': x.createUser || this.createdUser, 
            'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
            'updateUser': this.createdUser,
            'parentID': x.parentID || null 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveLossAllowance(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                const { lossAllowance, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'lossAllowance'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'lossAllowance');
                this.uiService.toastService.success('Loss Allowance Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    
    
    addPipelineArea(index) {
        if(this.PipelineAreaData.length){
            this.pipelineAreaData[index]=[{
                'rowId': this.PipelineAreaData[index].length,
                'index': index
            }, ...this.PipelineAreaData[index]]
        } else {
            this.pipelineAreaData[0]=[{
                'rowId': 0,
                'index': index
            }]
        }
    }

    @computed
    get PipelineAreaData() {
        return toJS(this.pipelineAreaData);
    }

    
    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deletePipelineArea (requestBody, index): Promise<void>  {
        this.pipelineAreaData[index]=this.PipelineAreaData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffPLID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deletePipeline(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'pipelineArea');
                    this.uiService.toastService.success('Pipeline Area Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    
    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async savePipelineArea(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.PipelineAreaData[index].every(x=>x.plName)){
            requiredFlag=true;
            requiredOptions.push('Area');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.PipelineAreaData[index].filter(x=>!x.existing || this.modifedRows[index]['pipelineArea']?.includes(x.rowId)).map((x)=>{
            return { 
                'ctsID':x.tariffPLID || 0, 
                'optionID': tariffOptionID, 
                'id': this.pipelineAreasDropdown.find(y=>y.plSysCode=== x.plName)?.pipelineID || x.plAreaID, 
                'user': this.createdUser,
                'parentID': x.parentID || null 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.savePipelineArea(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                const { pipelineArea, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'pipelineArea'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'pipelineArea');
                this.uiService.toastService.success('Pipeline Area Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    addConnectionPoint(index) {
        if(this.ConnectionPointData.length){
            this.connectionPointData[index]=[{
                'rowId': this.ConnectionPointData[index].length,
                'alwaysCharge': false,
                'rateBasis':'Net Standard Volume',
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'index': index
            }, ...this.ConnectionPointData[index]]
        } else {
            this.connectionPointData[0]=[{
                'rowId': 0,
                'alwaysCharge': false,
                'rateBasis':'Net Standard Volume',
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'index': index
            }]
        }
    }

    @computed
    get ConnectionPointData() {
        return toJS(this.connectionPointData);
    }
    
        
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteConnectionPoint (requestBody, index): Promise<void>  {
        this.connectionPointData[index]=this.ConnectionPointData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffCPID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteCP(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'cp');
                    this.uiService.toastService.success('Connection Point Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @action
    setConnectionPointData(value, rowId, key, index){
        this.connectionPointData=this.ConnectionPointData;
        this.connectionPointData[index][rowId][key] = value;
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveConnectionPoint(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.ConnectionPointData[index].every(x=>x.location)){
            requiredFlag=true;
            requiredOptions.push('Location');
        }
        if(!this.ConnectionPointData[index].every(x=>x.effectiveDate)){
            requiredFlag=true;
            requiredOptions.push('Effective Date');
        }
        if(!this.ConnectionPointData[index].every(x=>x.rateBasis)){
            requiredFlag=true;
            requiredOptions.push('Rate Basis');
        }
        // if(!this.ConnectionPointData[index].every(x=>x.cpTypeValue)){
        //     requiredFlag=true;
        //     requiredOptions.push('CP Type');
        // }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.ConnectionPointData[index].filter(x=>!x.existing || this.modifedRows[index]['cp']?.includes(x.rowId)).map((x)=>{
            return { 
                'tariffCPID': x.tariffCPID || 0, 
                'tariffOptionID': tariffOptionID, 
                'locationID': this.cpLineLocation[index]?.find(y=>y.locnName===x.location)?.locationID || x.locationID, 
                'cpid': x.cpName ? this.cpArea[index][x.rowId]?.find(y=>x.cpName===y.cpName)?.connectionPointID || x.cpid : null,
                'cpType': this.cpTypeDropdownOption?.find(y=>x.cpTypeValue===y.valueText)?.settingID, 
                'oldRate': x.oldRate, 
                'newRate': x.newRate, 
                'effectiveDate': x.effectiveDate && moment(new Date(x.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'endDate': x.endDate && moment(new Date(x.endDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'alwaysCharge': x.alwaysCharge, 
                'rateTypeID': [...this.cpRateTypes]?.find(y=>x.rateType===y.tariffRateDesc)?.tariffRateTypeID || x.rateTypeID, 
                'rateBasisID': [...this.mvtRateBasis]?.find(y=>x.rateBasis===y.valueText)?.settingID || x.rateBasisID,
                'tariffComments': x.tariffComments, 
                'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': x.createUser || this.createdUser, 
                'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'updateUser': this.createdUser,
                'parentID': x.parentID || null 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveConnectionPoint(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue);
            }else{
                const { cp, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'cp'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'cp');
                this.uiService.toastService.success('Connection Point Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    
    addMovement(index) {
        if(this.MovementData.length){
            this.movementData[index]=[{
                'rowId': this.MovementData[index].length,
                'noChange': false,
                'rateBasis':'Net Standard Volume',
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'index': index
            }, ...this.MovementData[index]]
        } else {
            this.movementData[0]=[{
                'rowId': 0,
                'noChange': false,
                'rateBasis':'Net Standard Volume',
                'effectiveDate': this.ctsEffectiveDate && new Date(this.ctsEffectiveDate) || null,
                'index': index
            }]
        }
    }

    @computed
    get MovementData() {
        return toJS(this.movementData);
    }
    
      
    @action
    setMovementData(value, rowId, key, index){
        this.movementData=this.MovementData;
        this.movementData[index][rowId][key] = value;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteMovement (requestBody, index): Promise<void>  {
        this.movementData[index]=this.MovementData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffMvmtID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteMovement(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'movement');
                    this.uiService.toastService.success('Movement Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveMovement(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.MovementData[index].every(x=>x.lineSegDesc)){
            requiredFlag=true;
            requiredOptions.push('Segment');
        }
        if(!this.MovementData[index].every(x=>x.rateDirection)){
            requiredFlag=true;
            requiredOptions.push('Rate Direction');
        }
        if(!this.MovementData[index].every(x=>x.effectiveDate)){
            requiredFlag=true;
            requiredOptions.push('Effective Date');
        }
        if(!this.MovementData[index].every(x=>x.rateBasis)){
            requiredFlag=true;
            requiredOptions.push('Rate Basis');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        }
        const requestBody=  this.MovementData[index].filter(x=>!x.existing || this.modifedRows[index]['movement']?.includes(x.rowId)).map((x)=>{
            return { 
                'tariffMvmtID': x.tariffMvmtID || 0, 
                'tariffOptionID': tariffOptionID, 
                'segmentID': this.mvtLineSegment[index].find(y=>y.lineSegDesc===x.lineSegDesc)?.lineSegID || x.segmentID, 
                'rateDirectionID': this.mvtRateTypeDirection.find(y=>x.rateDirection===y.valueText)?.settingID || x.rateDirectionID, 
                'oldRate': x.oldRate, 
                'newRate': x.newRate, 
                'noChange':x.noChange,
                'effectiveDate': x.effectiveDate && moment(new Date(x.effectiveDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'endDate': x.endDate && moment(new Date(x.endDate)).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'cemaRate': x.cemaRate, 
                'rateTypeID': [...this.mvtRateTypes].find(y=>x.rateType===y.tariffRateDesc)?.tariffRateTypeID || x.rateTypeID, 
                'rateBasisID': [...this.mvtRateBasis].find(y=>x.rateBasis===y.valueText)?.settingID || x.rateBasisID, 
                'tariffComments': x.tariffComments, 
                'createDate': x.createDate || moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': x.createUser || this.createdUser, 
                'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'updateUser': this.createdUser,
                'parentID': x.parentID || null 
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveMovement(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                const { movement, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'movement'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'movement');
                this.uiService.toastService.success('Movement Data Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }


    addOwner(index) {
        if(this.OwnerData.length){
            this.ownerData[index]=[{
                system: this.formData[index].pipelineArea,
                index: index,
                'rowId': this.OwnerData[index].length,
            }, ...this.ownerData[index]]
        } else {
            this.ownerData[0]=[{
                system: this.formData[index].pipelineArea,
                index: index,
                'rowId': 0
            }]
        }
    }

    @computed
    get OwnerData() {
        return toJS(this.ownerData);
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteOwner (requestBody, index): Promise<void>  {
        this.ownerData[index]=this.OwnerData[index].filter(
        x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId));
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true).map(x=>x.tariffOwnID);
        if(slicedRequestBody.length > 0) {
            const response: any[] = await this.tariffOptionsDataService.deleteOwner(slicedRequestBody, this.createdUser);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getTariffOptions(false, false, 'owner');
                    this.uiService.toastService.success('Owner Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveOwner(index, tariffOptionID): Promise<void> {
        let requiredFlag=false; const requiredOptions: any [] = [];
        if(!this.OwnerData[index].every(x=>x.custName)){
            requiredFlag=true;
            requiredOptions.push('Owner');
        }
        if(requiredFlag===true){
            this.uiService.toastService.error('Following required fields are missing:' + requiredOptions.map(x=>'\n \u2022 ' + x) + '.');
            return;
        } 
        const requestBody=  this.OwnerData[index].filter(x=>!x.existing || this.modifedRows[index]['owner']?.includes(x.rowId)).map((x)=>{
            return { 
                'ctsID':x.tariffOwnID || 0, 
                'optionID': tariffOptionID, 
                'id': this.ownerDropdownForOwner[index].find(y=>y.customerName===x.custName)?.customerID, 
                'user': this.createdUser,
                'parentID': x.parentID || null
            } 
        })
        const response: any[] = await this.tariffOptionsDataService.saveOwner(requestBody);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                const { owner, ...rest } = Object.fromEntries(
                    Object.entries(this.modifedRows[index]).filter(([key]) =>
                        key !== 'owner'));
                this.modifedRows[index] = rest
                this.getTariffOptions(false, false, 'owner');
                this.uiService.toastService.success('Owner Saved Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async deleteTariffOptions(tariffOptionID): Promise<void> {
        const response: any[] = await this.tariffOptionsDataService.deleteTariffOptions(tariffOptionID,this.createdUser);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions(true);
                this.uiService.toastService.success('Tariff Option Deleted Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async cancelTariffOptions(tariffOptionID): Promise<void> {
        const response: any[] = await this.tariffOptionsDataService.cancelTariffOptions(tariffOptionID,this.createdUser);
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions(true);
                this.uiService.toastService.success('Tariff Option Deleted Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async cloneTariffOption(tariffOptionId,cloneAll): Promise<void> {
        const response: any[] = await this.tariffOptionsDataService.cloneTariffOption(tariffOptionId,this.createdUser,cloneAll==='all');
        if(response){
            let errorFlag=false;
            let errorValue='';
            for(const key in response){
                if(key==='statusCode'&&response[key]===400){
                    errorFlag=true;
                }
                if(key==='value'){
                    errorValue=response[key];
                }
            }
            if(errorFlag===true){
                this.uiService.toastService.error(errorValue)
            }else{
                this.getTariffOptions(true, true);
                this.uiService.toastService.success('Tariff Option Cloned Successfully!')
            }
            dataStore.setShowUnsavedWarningAlert(false);
        }
    }

    isSaveDisabledForLineSegment = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.LineSegmentMaintenance);
    };
    
    isEditDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    newRateIncorrectAlert = () => {
        this.uiService.toastService.error('Rate should never be negative and only upto 2 decimal places!')
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveAllData(index, tariffOptionID): Promise<void> {

            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['comments'] && this.modifedRows[index]['comments'].length > 0)
            await this.saveComments(index, tariffOptionID);

            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['movement'] && this.modifedRows[index]['movement'].length > 0)
            await this.saveMovement(index, tariffOptionID);
            
            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['cp'] && this.modifedRows[index]['cp'].length > 0)
            await this.saveConnectionPoint(index, tariffOptionID);
    
            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['pipelineArea'] && this.modifedRows[index]['pipelineArea'].length > 0)
            await this.savePipelineArea(index, tariffOptionID);

            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['owner'] && this.modifedRows[index]['owner'].length > 0)
            await this.saveOwner(index, tariffOptionID);
    
            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['relatedTariff'] && this.modifedRows[index]['relatedTariff'].length > 0)
            await this.saveRelatedTariff(index, tariffOptionID);
    
            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['lossAllowance'] && this.modifedRows[index]['lossAllowance'].length > 0)
            await this.saveLossAllowance(index, tariffOptionID);

            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && this.modifedRows[index]['deduction'] && this.modifedRows[index]['deduction'].length > 0)
            await this.saveDeduction(index, tariffOptionID);
      
            if(this.modifedRows && Object.keys(this.modifedRows).length > 0 && ((this.modifedRows[index]['ctsComments'] && this.modifedRows[index]['ctsComments'].length > 0) || (this.modifedRows[index]['purpose'] && this.modifedRows[index]['purpose'].length > 0)))
            await this.saveCTSComments(index, tariffOptionID);
            // this.getTariffOptions()
               
     }

     disabledFieldOnRulesandRegType = () => {
        if(this.formData[this.selectedTab]?.type === 'Rules and Regulations') {
            return true
        } else {
            return false
        }
      }
    
}
