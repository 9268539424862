import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class PipelineMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    async getPipelineMaintenanceGridData(areaType, areaName, physicalPL): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.pipelineMaintenance_getPipelineGrid+`?areaType=${areaType}&areaName=${areaName}&status=Active&physicalPL=${physicalPL}`);
        return data;
    }
    
    async getPipelineTypesDropDownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.pipelineMaintenance_getPipelineTypes);
        return data;
    }

    async getPhysicalPipelinesDropDownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.pipelineMaintenance_getPhysicalPipelines);
        return data;
    }

    async getQBInvoiceFrequencyDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addPipelines_reviewFrequency);
        return data;
    }

    async getQBRateBasisDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addPipelines_rateBasis);
        return data;
    }

    async getLossGainDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addPipelines_lossGain);
        return data;
    }

    async getPLABasisDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addPipelinePLABasis);
        return data;
    }

    async getAllocationBasisDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addPipelineAllocationBasis);
        return data;
    }

    async getOwnersDropDown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addPipelineOwnersDropdown);
        return data;
    }

    async getDistributeLGToDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addPipelineLGToDropdown);
        return data;
    }

    async getPipelineData(pipelineId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.editPipeline_getPipeline+`?pipelineID=${pipelineId}`);
        return data;
    }

    async savePipeline(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.addPipelineSavePipeline, reqbody, {});
        return data;
    }

    async savePipelineOwners(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.addPipelineSaveOwners, reqbody, {});
        return data;
    }

    async savePipelineEntities(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.addPipelineSaveEntities, reqbody, {});
        return data;
    }    

    
    async getPipelineEntities(reqbody: any): Promise<any> {
        const { data } = await this.restApi.post<any>(Path.addPipelineEBSDATAValidate, reqbody, {});
        return data;
    }    

    
    async validateStateGLCode(reqbody: any): Promise<any> {
        const { data } = await this.restApi.post<any>(Path.addPipelineEBSDATAValidate, reqbody, {});
        return data;
    }    

    async updatePipeline(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.editPipeline_UpdatePipeline, reqbody, {});
        return data;
    }

    async updatePipelineOwners(reqbody: any, pipelineId): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.editPipeline_UpdatePipelineOwners+`?pipelineId=${pipelineId}`, reqbody, {});
        return data;
    }

    async updatePipelineEntities(reqbody: any, pipelineId): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.editPipeline_UpdatePipelineEntities+`?pipelineId=${pipelineId}`, reqbody, {});
        return data;
    }    

    async deletePipeline(reqbody: any, pipelineId, deleteUserName): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.deletePipeline+`?pipelineId=${pipelineId}&deletUserName=${deleteUserName}`, reqbody, {});
        return data;
    }    
     
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsLocationEndpoint, reqbody, {});
        return data;
    }  

}

export const pipelineMaintenanceDataService = new PipelineMaintenanceDataService(restApiService);
