export enum HttpMethodName {
    GET = 'GET',
    POST = 'POST',
    DELETE = 'DELETE',
    PUT = 'PUT'
}

export enum HttpHeaderKey {
    APIKEY = 'api-key'
}

export enum RoleBasedActionName {
    Save = 'save',
    View = 'view',
    Delete = 'delete'
}

// export enum CalenderMonths {
//     'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
// }
