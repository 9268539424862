import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import './tariff-options.scss';
import {
  CellEditingStoppedEvent,
  ColDef
} from 'ag-grid-community';
import { TariffOptionsIndexDataStore } from '../stores/tariff-options-data-store';
import { tariffOptionsDataStoreProject } from '../stores';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import momenttimezone from 'moment-timezone';
import { dataStore } from 'common/stores';
import { AgGridComponent } from 'common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { RoleBasedActionName } from 'shared/types/enum';
import { Prompt } from 'react-router-dom';
import { colDefDeduction } from './deduction-col-def';
import { LossAllowance } from './loss-allowance';
import { ConnectionPoint } from './connection-point';
import { Movement } from './movement';
import { ThreeDots } from 'react-loader-spinner';

/* eslint-disable */
interface TariffOptionsContainerProps {
  tariffOptionsDataStoreProject: TariffOptionsIndexDataStore;
  key: any;
  baseTariffLogDetails: any;
  tariffOptionsLinked: boolean;
  compare?: boolean
  allDisabled?:boolean
}

interface TariffOptionsContainerState {
  rowId: number;
  cloneNewTariff: boolean;
  // predictiveSelectText: string;
  // selectedShownArray: any[];
  // totalArray: any[];
  // hiddenSelect: boolean;
  // editableFormData: any;
  // baseTariffState: any;
}

interface interfaceCustomerName{
    customerName: string;
}

@inject('tariffOptionsDataStoreProject')
@observer
export class TariffOptionsContainer extends Component<TariffOptionsContainerProps, TariffOptionsContainerState> {
  cloneBoxRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {
      rowId: 0,
      cloneNewTariff: false
      // predictiveSelectText: "",
      // selectedShownArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      // totalArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      // hiddenSelect: true,
      // editableFormData: {},
      // baseTariffState: {}
    };
    this.cloneBoxRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    dataStore.setModuleName('Tariff Options');
  }

  // componentDidMount() {
  //   const { tariffOptionsDataStoreProject } = this.props;
  //   if((this.props.key==='review'||this.props.key==='approve')){
  //     tariffOptionsDataStoreProject.init(this.props.baseTariffLogDetails);
  //   }
  //  // this.getPlusDimensions();
  // }

  handleClickOutside(event) {
    if (this.cloneBoxRef && !this?.cloneBoxRef?.current?.contains(event.target)) {
      tariffOptionsDataStoreProject.cloneNewTariff = {};
    }
  }
  
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    tariffOptionsDataStoreProject.unmount();
  }

  getGridConfigJointCarrier(gridId) {
    const {
      tariffOptionsDataStoreProject: { agGridService, selectedBaseTariffDetails }
    } = this.props;
    const gridParams: any = {
      rowData: selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails?.map(x => { return { 'jointCarrier': x.jointCarrier, 'baseTariff': x.baseTariff, 'pipelineArea': x.pipeline }; }) || [],
      columnDefs: this.colDefBaseTariff,
      domLayout: 'autoHeight',
    };
    return agGridService.getGridConfig(gridParams, gridId);
  }
  colDefBaseTariff: ColDef[] = [
    {
      headerName: 'Joint Carrier',
      field: 'jointCarrier',
      filter: true,
      sortable: true,
      editable: false,
      minWidth: 130,
      cellEditor: 'agSelectCellEditor',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
    },
    {
      headerName: 'Base Tariff',
      field: 'baseTariff',
      minWidth: 130,
      filter: true,
      sortable: true,
      editable: false,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
    },
    {
      headerName: 'Pipeline Area',
      field: 'pipeline',
      minWidth: 665,
      filter: true,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      sortable: true,
      editable: false,
      flex: 1
    }
  ];

  colDefPipelineArea: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellRenderer: 'checkboxRenderer',
      width: 40,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Area',
      field: 'plName',
      editable: false,
      cellEditor: 'agSelectCellEditor',
      // valueGetter: (params) => {
      //   return this.props.tariffOptionsDataStoreProject.pipelineAreasDropdown.find(x=>x.pipelineID===params.data?.tariffPLID)?.plSysCode
      // },
      // cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStoreProject.pipelineAreasDropdown.map(x=>x.plSysCode)
        };
      },
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Update Date',
      field: 'updateDate',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      editable: false,
      minWidth: 130,
      flex: 1,
      valueGetter: params => {
        if (params.data['updateDate'] === null) {
            return '';
        } else {
            return moment(params.data['updateDate']).format(AppConstant.DATE_FORMAT);
        }
      }},
    {
      headerName: 'Update User',
      field: 'updateUser',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      editable: false,
      minWidth: 130,
      flex: 1
    }
  ];

  getGridConfigPipelineArea(gridId) {
    const {
      tariffOptionsDataStoreProject: { agGridServicePipeline }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStoreProject.PipelineAreaData.length && tariffOptionsDataStoreProject.PipelineAreaData[tariffOptionsDataStoreProject.selectedTab]||[],
      columnDefs:this.colDefPipelineArea,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context: agGridServicePipeline
      };
    return agGridServicePipeline.getGridConfig(gridParams, gridId);
  }
  colDefOwner: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      cellRenderer: 'checkboxRenderer',
      width: 40,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'System',
      field: 'system',
      editable: false,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Owner',
      field: 'custName',
      minWidth: 130,
      // cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStoreProject.ownerDropdownForOwner[params.data.index]?.map(x=>x.customerName)
        };
      },
      flex: 1
    }
  ];
  getGridConfigOwner(gridId) {
    const {
      tariffOptionsDataStoreProject: { agGridServiceOwner }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStoreProject.OwnerData.length && tariffOptionsDataStoreProject.OwnerData[tariffOptionsDataStoreProject.selectedTab]||[],
      columnDefs:this.colDefOwner,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context: agGridServiceOwner
      };
    return agGridServiceOwner.getGridConfig(gridParams, gridId);
  }
  colDefRelatedTariff: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => {return this.props.tariffOptionsDataStoreProject?.stepName==='Process & Submit for Approval'?  true : false},
      cellRenderer: 'checkboxRenderer',
      width: 40,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      headerCheckboxSelection: (params) => {return this.props.tariffOptionsDataStoreProject?.stepName==='Process & Submit for Approval'?  true : false},
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Relationship',
      field: 'relationship',
      cellEditor: 'agSelectCellEditor',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStoreProject.tariffRelationTypes.map(x=>x.valueText)
        };
      },
      editable: (params) => { 
        return this.props.tariffOptionsDataStoreProject?.stepName==='Process & Submit for Approval'
      }, 
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Agency',
      field: 'agencyName',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      flex: 1,
      editable: (params) => { 
        return this.props.tariffOptionsDataStoreProject?.stepName==='Process & Submit for Approval'
      }, 
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStoreProject.agencyDropdown.map(x=>x.agencyname)
        };
      },
    },
    {
      headerName: 'Number',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      field: 'number',
      editable: (params) => { 
        return this.props.tariffOptionsDataStoreProject?.stepName==='Process & Submit for Approval'
      }, 
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.number=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    },
    {
      headerName: 'Effective Date',
      field: 'effectiveDate',
      editable: false,
      minWidth: 130,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      valueGetter: params => {
        if (params.data['effectiveDate'] === null) {
            return '';
        } else {
            return moment(params.data['effectiveDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
      flex: 1
    },
    {
      headerName: 'Update Date',
      field: 'updateDate',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['updateDate'] === null) {
            return '';
        } else {
            return moment(params.data['updateDate']).format(AppConstant.DATE_FORMAT);
        }
    }, 
      flex: 1
    },
    {
      headerName: 'Update User',
      field: 'updateUser',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      editable: false,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Status',
      field: 'status',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      editable: false,
      minWidth: 130,
      flex: 1
    }
  ];

  colDefComments: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      cellRenderer: 'checkboxRenderer',
      width: 40,
      // cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Comments',
      field: 'comments',
      // cellClass: (params) => {return params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      flex: 4
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      editable: false,
      minWidth: 130,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      valueGetter: params => {
        if (params.data['createDate'] === null) {
            return '';
        } else {
            return moment(params.data['createDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
    },
    {
      headerName: 'Created User',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      field: 'createUser',
      editable: false,
      minWidth: 130,
    }
  ];

  colDefCTSComments: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: false,
      cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      cellRenderer: 'checkboxRenderer',
      width: 40,
      // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Comments',
      field: 'comments',
      // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      flex: 4
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      editable: false,
      minWidth: 130,
      cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      valueGetter: params => {
        if (params.data['createDate'] === null) {
            return '';
        } else {
            return moment(params.data['createDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
    },
    {
      headerName: 'Created User',
      cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'cell-deleted' : params.data.action === 'U' ? 'cell-overridden' : params.data.action === 'A' ? 'cell-new' : ''},
      field: 'createUser',
      editable: false,
      minWidth: 130,
    }
  ];

  getGridConfigComments(gridId) {
    const {
      tariffOptionsDataStoreProject: { agGridServiceComments }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStoreProject.CommentsData.length && tariffOptionsDataStoreProject.CommentsData[tariffOptionsDataStoreProject.selectedTab]||[],
      columnDefs:this.colDefComments,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context: agGridServiceComments
      };
    return agGridServiceComments.getGridConfig(gridParams, gridId);
  }

  getGridConfigCTSComments(gridId) {
    const {
      tariffOptionsDataStoreProject: { agGridServiceCommentsCTS }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStoreProject.CTSCommentsData.length && tariffOptionsDataStoreProject.CTSCommentsData[tariffOptionsDataStoreProject.selectedTab]||[],
      columnDefs:this.colDefCTSComments,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context: agGridServiceCommentsCTS
      };
    return agGridServiceCommentsCTS.getGridConfig(gridParams, gridId);
  }

  getGridConfigRelatedTariff(gridId) {
    const {
      tariffOptionsDataStoreProject: { agGridServiceRelatedTariff }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStoreProject.RelatedTariffData.length && tariffOptionsDataStoreProject.RelatedTariffData[tariffOptionsDataStoreProject.selectedTab]||[],
      columnDefs:this.colDefRelatedTariff,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context: agGridServiceRelatedTariff
      };
    return agGridServiceRelatedTariff.getGridConfig(gridParams, gridId);
  }

  getGridConfigDeduction(gridId) {
    const {
      tariffOptionsDataStoreProject: { agGridServiceDeduction }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStoreProject.DeductionData.length && tariffOptionsDataStoreProject.DeductionData[tariffOptionsDataStoreProject.selectedTab]||[],
      columnDefs:colDefDeduction,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      context: agGridServiceDeduction
      };
    return agGridServiceDeduction.getGridConfig(gridParams, gridId);
  }

  selectTab(x,index){
    if(x==='new'){
      if(tariffOptionsDataStoreProject.formData.length===0){
        tariffOptionsDataStoreProject.addNewTariffOption();
      }else{
      tariffOptionsDataStoreProject.cloneNewTariff[index]= true;
      document.addEventListener("mousedown", this.handleClickOutside);
      } 
    }else if(x===-1){
      tariffOptionsDataStoreProject.selectedTab=x;
      tariffOptionsDataStoreProject.screenHeaderTabSelected.push(x);
    }
    else {
      tariffOptionsDataStoreProject.cloneNewTariff[index]= false;
      tariffOptionsDataStoreProject.selectedTab=x;
      tariffOptionsDataStoreProject.screenHeaderTabSelected.push(x);
    }
  }

  getPlusDimensions(){
    var x= document.getElementById('cloneNew')?.getBoundingClientRect();
    console.log(x);
    return x?.x || 0;
  }

  findTariffLogDetail(){
    return tariffOptionsDataStoreProject.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.find(x=>x.tariffNumber===tariffOptionsDataStoreProject.formDataSelection?.tariffLog);
  } 
 
  addLossAllowance(index){
    this.props.tariffOptionsDataStoreProject.addLossAllowance(index)
  }
 
  addConnectionPoint(index){
    this.props.tariffOptionsDataStoreProject.addConnectionPoint(index)
  }

  addMovement(index){
    this.props.tariffOptionsDataStoreProject.addMovement(index)
  }

  
  isValidDate(d) {
    return d instanceof Date;
  }
  
  cloneContent(index, type, tariffOptionID) {
    this.props.tariffOptionsDataStoreProject.cloneTariffOption(tariffOptionID,type);
    tariffOptionsDataStoreProject.cloneNewTariff = {};
  }

  removeOption(tariffOptionID){
    const confirmService = new ConfirmService();
    confirmService.showConfirmDialog(
      () => {this.props.tariffOptionsDataStoreProject.deleteTariffOptions(tariffOptionID);
        tariffOptionsDataStoreProject.cloneNewTariff = {}},
      'Delete Tariff Option',
      'Are you sure you want to delete the selected Tariff Option?'
    );
  }

  changePipelineWarning(value, pipelineArea, index){
    if(this.props.tariffOptionsDataStoreProject.formData[index]['new']!==true){
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
        () => this.props.tariffOptionsDataStoreProject.changeFormData(value, pipelineArea, index),
        'Delete All Related Data',
        'Modifying the Pipeline Area Would Erase All Related Data from All the Below Grids on Save?'
      );
    }else{
      this.props.tariffOptionsDataStoreProject.changeFormData(value, pipelineArea, index);
    }
  }

  deleteDeduction = async (index) => {
    const { tariffOptionsDataStoreProject } = this.props;
    const requestBody: any = await tariffOptionsDataStoreProject.agGridServiceDeduction.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStoreProject.deleteDeduction(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  
  deleteRelatedTariff = async (index) => {
    const { tariffOptionsDataStoreProject } = this.props;
    const requestBody: any = await tariffOptionsDataStoreProject.agGridServiceRelatedTariff.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStoreProject.deleteRelatedTariff(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  deleteOwner = async (index) => {
    const { tariffOptionsDataStoreProject } = this.props;
    const requestBody: any = await tariffOptionsDataStoreProject.agGridServiceOwner.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStoreProject.deleteOwner(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  deletePipelineArea = async (index) => {
    const { tariffOptionsDataStoreProject } = this.props;
    const requestBody: any = await tariffOptionsDataStoreProject.agGridServicePipeline.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStoreProject.deletePipelineArea(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  
  deleteComments = async (index) => {
    const { tariffOptionsDataStoreProject } = this.props;
    const requestBody: any = await tariffOptionsDataStoreProject.agGridServiceComments.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStoreProject.deleteComments(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }
  render(): React.ReactNode {
    const { tariffOptionsDataStoreProject } = this.props;
    return (
      <div className='TariffOptionsIndexBox'>
        <>
          {tariffOptionsDataStoreProject.loader ? <div className="loader__overlay" /> : null}
          <div className="loader__icon">
              <ThreeDots height={80} width={80} color="green" ariaLabel="loading" visible = {tariffOptionsDataStoreProject.loader}/>
          </div>
        </> 
        {/* <Container fluid> */}
          <div className='boxes'>
            {/* <Prompt when={tariffOptionsDataStoreProject.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'} /> */}
            <div className='selectContainer'>
            <div className="screenHeaderTabs">
                {!this.props.compare && (<div className={tariffOptionsDataStoreProject.selectedTab===-1?'screenheaderTabActive':tariffOptionsDataStoreProject.screenHeaderTabSelected.includes(-1)?'screenHeaderTabSelected':'screenHeaderTab'}>
                <div style={{position:'relative'}}>
                    <div onClick={()=>{this.selectTab(-1, 0)}} style={{marginLeft:'0px', display: 'inline-block', 'cursor': 'pointer', padding: '5px', minHeight: '35px', minWidth: '40px'}}>Summary</div>
                </div>
                </div>)}
              {tariffOptionsDataStoreProject.formData.length && tariffOptionsDataStoreProject.formData.map((x,index)=>(
                <div className={tariffOptionsDataStoreProject.formData[index]?.action==='Z'?'screenheaderTabRed':tariffOptionsDataStoreProject.formData[index]?.isChanged? 'screenheaderTabYellow': tariffOptionsDataStoreProject.selectedTab===index?'screenheaderTabActive':tariffOptionsDataStoreProject.screenHeaderTabSelected.includes(x)?'screenHeaderTabSelected':'screenHeaderTab'} style = {{fontWeight : tariffOptionsDataStoreProject.selectedTab===index ? 'bold' : 'normal'}}>
                  <div style={{position:'relative'}}>
                    <div style={{display: 'inline-block', 'cursor': 'pointer', padding: '5px', minHeight: '35px', minWidth: '40px'}} onClick={()=>{this.selectTab(index, index)}}>{tariffOptionsDataStoreProject.formData[index]?.agency + ' ' +  (x.value1 && x.value1 + ' '+ x.value2 + ' ' + x.value3 + ' ' + x.value4 || x?.tariffNo)}</div>
                     {/* {tariffOptionsDataStoreProject.formData[index]['new']!==true && <button style={{float: 'right', display: 'inline-block', marginTop: '2.5px', marginLeft:'10px', border: '1px solid gray', textAlign: 'center', minWidth: '33px'}} onClick={()=>{this.selectTab('new', index)}}>...</button>}
                     {tariffOptionsDataStoreProject.cloneNewTariff[index]===true && (<div ref={this.cloneBoxRef} style={{'textAlign': 'right', 'padding': '5px', 'width': '145px', 'border': '1px solid black', 'position': 'absolute', 'backgroundColor':'white', top: '37px', right: this.getPlusDimensions() && this.getPlusDimensions()>800?'0px':'0px'}}>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.cloneContent(index, 'some', x.tariffOptionID)}>Clone</div>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.cloneContent(index, 'all', x.tariffOptionID)}>Clone All Content</div>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.removeOption(x.tariffOptionID)}>Remove Option</div>
                    </div>)||<></>} */}
                  </div>
                </div>))|| (<></>)}
                {!tariffOptionsDataStoreProject.formData.length&&<div style={{'position':'relative'}}>
              <button className='screenHeaderTab' disabled={true} onClick={()=>{this.selectTab('new', 0)}} style={{marginLeft:'0px', padding: '5px', minHeight: '48px', minWidth: '40px'}} id='cloneNew'>No Tariff Options Found</button>
              </div>}         
            </div>
            {tariffOptionsDataStoreProject.selectedTab===-1 && !this.props.compare && (
              <div>
                <Row style={{marginLeft: '10px'}}>
                  <Col>
                  {tariffOptionsDataStoreProject.infraSummary?.pLDTOs?.length>0 && (<Row style={{fontWeight: 'bold'}}>Pipeline</Row>|| <Row>No pipeline linked</Row>) }
                  {tariffOptionsDataStoreProject.infraSummary?.pLDTOs?.map(x=>(<Row>{x?.pipelineName}</Row>))}
                  {tariffOptionsDataStoreProject.infraSummary?.custDTOs?.length>0 && (<Row style={{fontWeight: 'bold'}}>Customer</Row>|| <Row>No customer linked</Row>)}
                  {tariffOptionsDataStoreProject.infraSummary?.custDTOs?.map(x=>(<Row>{x?.customerName}</Row>))}
                  {tariffOptionsDataStoreProject.infraSummary?.locDTOs?.length>0 && (<Row style={{fontWeight: 'bold'}}>Location</Row>|| <Row>No location linked</Row>)}
                  {tariffOptionsDataStoreProject.infraSummary?.locDTOs?.map(x=>(<Row>{x?.locationName}</Row>))}
                  </Col>
                  <Col>
                  {tariffOptionsDataStoreProject.infraSummary?.cpDTOs?.length>0 && (<Row style={{fontWeight: 'bold'}}>Connection Point</Row>|| <Row>No connection point linked</Row>)}
                  {tariffOptionsDataStoreProject.infraSummary?.cpDTOs?.map(x=>(<Row>{x.connectionPointName}</Row>))}
                  {tariffOptionsDataStoreProject.infraSummary?.gradeDTOs.length>0 && (<Row style={{fontWeight: 'bold'}}>Grade Maintenance</Row>|| <Row>No grade maintenance linked</Row>)}
                  {tariffOptionsDataStoreProject.infraSummary?.gradeDTOs?.map(x=>(<Row>{x?.gradeName}</Row>))}
                  {tariffOptionsDataStoreProject.infraSummary?.pfDTOs?.length>0 && (<Row style={{fontWeight: 'bold'}}>Profit Center</Row>|| <Row>No profit centers linked</Row>)}
                  {tariffOptionsDataStoreProject.infraSummary?.pfDTOs?.map(x=>(<Row>{x?.profitCenterDescription}</Row>))}
                  </Col>
                  <Col>
                  {tariffOptionsDataStoreProject.infraSummary?.agencyDTOs?.length>0 && (<Row style={{fontWeight: 'bold'}}>Agency</Row>|| <></>)}
                  {tariffOptionsDataStoreProject.infraSummary?.agencyDTOs?.map(x=>(<Row>{x?.agency}</Row>))}
                  {tariffOptionsDataStoreProject.infraSummary?.lineSegDTOs?.length>0 && (<Row style={{fontWeight: 'bold'}}>Line Segment</Row>|| <></>)}
                  {tariffOptionsDataStoreProject.infraSummary?.lineSegDTOs?.map(x=>(<Row>{x?.lsBidirectional && x?.lsBidirectional === 'Y' ? 'Bidirectional ' + x?.lineSegmentName : x?.lineSegmentName}</Row>))}
                  </Col>
                </Row>
              </div>)}
            {tariffOptionsDataStoreProject.formData.length && tariffOptionsDataStoreProject.formData.map((x,index)=>(
            tariffOptionsDataStoreProject.selectedTab===index && 
            (
            <div>
            <div className='internalHeader'>
              <h6>Ext System (VMACS) </h6>
            </div>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Agency</label>
                      <select className="select" disabled={true} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].agency} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'agency', index)}} style={{maxWidth: '200px', height: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].agency?.length>22?'70px':'35px', whiteSpace: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].agency?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].agency?.length>22?'2':'unset'}}>
                      {Array.isArray(tariffOptionsDataStoreProject.agencyDropdown) && tariffOptionsDataStoreProject.agencyDropdown.length && tariffOptionsDataStoreProject.agencyDropdown.map(option=>
                        (<option key={option.agencycode} value={option.agencycode}>
                            {option.agencycode}
                        </option>)
                      )}
                      </select>
              </Col>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Status</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].statusValue} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'statusValue', index)}}>
                      {Array.isArray(tariffOptionsDataStoreProject.statusDropdown) && tariffOptionsDataStoreProject.statusDropdown.length && tariffOptionsDataStoreProject.statusDropdown.map(option=>
                        (<option key={option.valueText} value={option.valueText}>
                            {option.valueText}
                        </option>)
                      )}
                      </select>
              </Col>
              <Col style={{'marginTop': '10px', 'marginRight': '10px', display: 'inline-flex' }} xs={4}>
                      <label className='standard_label_style'>Name</label>
                      <textarea
                      className='select'
                      style={{width:'100%'}}
                      disabled={true}
                      value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].name}
                      onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'name', index)}}
                      />
                      {/* <ReactDatePicker classNames='search_text_field' onDateChange={(e)=>{}} selectedDate={new Date()}/> */}
              </Col><Col></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}} xs={2}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className="standard_label_style">Tariff #</label>
                        {/* <div style={{display:'inline-flex'}}> */}
                        <input
                          type="text"
                          className="mainVersionInputTariff"
                          value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].value1 ||''}
                          onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value?.toUpperCase(), 'value1', index)}}
                          readOnly={tariffOptionsDataStoreProject.stepName!=='Process & Submit for Approval'}
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].value2 ||''}
                          onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value?.toUpperCase(), 'value2', index)}}
                          readOnly={tariffOptionsDataStoreProject.stepName!=='Process & Submit for Approval'}
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].value3 ||''}
                          onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value?.toUpperCase(), 'value3', index)}}
                          readOnly={tariffOptionsDataStoreProject.stepName!=='Process & Submit for Approval'}
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].value4 ||''}
                          onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value?.toUpperCase(), 'value4', index)}}
                          readOnly={tariffOptionsDataStoreProject.stepName!=='Process & Submit for Approval'}
                        />
                        {/* </div> */}
                        {/* <input type='text' disabled={true} className='search_text_field' value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].tariffNo} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'tariffNo', index)}}/> */}
              </Col>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                <div style={{display: 'inline-flex'}}>
                      <label className='standard_label_style'>Effective Date</label>
                      <ReactDatePicker disabled={true} classNames='search_text_field' onDateChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e, 'effectiveDate', index)}} selectedDate={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].effectiveDate}/>
                </div>
              </Col>
             <Col style={{'marginTop': '10px'}} xs={'auto'}></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col xs={'auto'}>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Type</label>
                      <select className='select'  disabled={true} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].type} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'type', index)}} style={{maxWidth: '223px', height: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].type?.length>28?'70px':'35px', whiteSpace: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].type?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].type?.length>22?'2':'unset'}}>
                      {Array.isArray(tariffOptionsDataStoreProject.tariffTypesDropdown) && tariffOptionsDataStoreProject.tariffTypesDropdown.length && tariffOptionsDataStoreProject.tariffTypesDropdown.map(option=>
                        (<option key={option.valueText} value={option.valueText}>
                            {option.valueText}
                        </option>)
                      )}
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Pipeline Areas</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab]?.pipelineArea} onChange={(e)=>this.changePipelineWarning(e.target.value, 'pipelineArea', index)} style={{maxWidth: '220px', padding: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].pipelineArea?.length>22?'9px':'5px', height: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].pipelineArea?.length>22?'70px':'35px', whiteSpace: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].pipelineArea?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].pipelineArea?.length>22?'2':'unset'}}>
                      <option value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab]?.pipelineArea}>
                            {tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab]?.pipelineArea}
                      </option>
                      </select>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Invoice Basis</label>
                      <select className='select'disabled={true} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].invoiceBasis} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'invoiceBasis', index)}}>
                      {Array.isArray(tariffOptionsDataStoreProject.invoiceBasisDropdown) && tariffOptionsDataStoreProject.invoiceBasisDropdown.length && tariffOptionsDataStoreProject.invoiceBasisDropdown.map(option=>
                        (<option key={option.valueText} value={option.valueText}>
                            {option.valueText}
                        </option>)
                      )}
                      </select>
                    </Col>
                  </Row>
              </Col>
              <Col xs={'auto'}>
                  <Row>
                      <Col style={{'marginTop': '10px', display:'inline-flex'}} xs={'auto'}>
                        <label className='standard_label_style'>Tariff Issue Date</label>
                          <ReactDatePicker disabled={true} classNames='select' onDateChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e, 'tariffIssueDate', index)}} selectedDate={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab]?.tariffIssueDate}/>
                      </Col>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancels Tariff</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].cancelTariff} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'cancelTariff', index)}} >
                        <option key={'None'} value={'None'}>None</option>
                        <option key={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].cancelTariff} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].cancelTariff}>
                            {tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].cancelTariff}
                        </option>
                      </select>
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancel Date</label>
                      <ReactDatePicker classNames='search_text_field' onDateChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e, 'cancelDate', index)}} selectedDate={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].cancelDate}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancel Tariffs</label>
                      <select className='select' value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].cancelTariff} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'cancelTariff', index)}} >
                      <option key={'None'} value={'None'}>None</option>
                      {Array.isArray(tariffOptionsDataStoreProject.cancelledTariffDropdown) && tariffOptionsDataStoreProject.cancelledTariffDropdown.length && tariffOptionsDataStoreProject.cancelledTariffDropdown.map(option=>
                        (<option key={option} value={option}>
                            {option}
                        </option>)
                      )}
                      </select>
                    </Col>
                  </Row> */}
              </Col>
              {/* <Col style={{'marginTop': '10px'}} xs={4}>
                      <div>
                        <label className='standard_label_style'>Comments</label>
                        <textarea className='search_text_field commentExtSytem' value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].comment} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'comment')}}/>
                      </div>
              </Col> */}
              <Col></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Company</label>
                      <select className='select' disabled={true} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].companyValue} onChange={(e)=>{tariffOptionsDataStoreProject.changeFormData(e.target.value, 'companyValue', index)}} style={{maxWidth: '223px', padding: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].companyValue?.length>22?'9px':'5px', height: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].companyValue?.length>22?'70px':'35px', whiteSpace: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].companyValue?.length>22?'pre-wrap':'unset',lineHeight: tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].companyValue?.length>22?'2':'unset'}}>
                      {tariffOptionsDataStoreProject.ownerDropdownForOwner[index] && Array.isArray(tariffOptionsDataStoreProject.ownerDropdownForOwner[index]) && tariffOptionsDataStoreProject.ownerDropdownForOwner[index].length && tariffOptionsDataStoreProject.ownerDropdownForOwner[index]?.map( option =>
                         (<option key={option?.customerName} value={option?.customerName}>
                            {option.customerName}
                        </option>)
                      )}
                      </select>
                    </Col>
                    <Col style={{'marginTop': '10px', display: 'inline-flex'}} xs={'auto'}>
                      <label className='standard_label_style'>VMACS Tariff #</label>
                      <input
                          type="text"
                          className="select"
                          value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab]?.agency + ' ' +tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].tariffNo||''}
                          readOnly={true}
                        />
              </Col>
              {/* <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Synced</label>
                      <input className='select' disabled onChange={(e)=>tariffOptionsDataStoreProject.changeFormData(e.target.value, 'syncStatus', index)} value={tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].syncStatus}/>
              </Col> */}
            </Row>
            {/* <Row>
              <div className="rightFlex" style={{'marginTop':'0px'}}>
                  <CustomButton type={CustomButtonType.Submit} onClick={()=>{tariffOptionsDataStoreProject.saveTariffOption(index, x.tariffOptionID)}}>
                      Save
                  </CustomButton>
              </div>
            </Row> */}
            {/* <div className='internalHeader'>
              <h6>Tariff Additional Info</h6>
            </div>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}} xs={'5'}>
                      <label className='standard_label_style'>Purpose</label>
                      <textarea className='search_text_field commentExtSytem' />
              </Col>
              <Col style={{'marginTop': '10px'}}>
                      <label className='standard_label_style'>Comments</label>
                      <textarea className='search_text_field commentExtSytem' />
              </Col>
              <Col xs={'auto'}></Col>
            </Row> */}
            {/* <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Impact</label>
                      <select className='search_text_field' value={'New Tariff in Vmacs'}>
                          <option value={'New Tariff in Vmacs'}>New Tariff in Vmacs</option>
                      </select>
              </Col>
            </Row> */}
            <div className='internalHeader'>
              <h6>Comments</h6>
            </div>
             {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.addComments(index)}}>
                  Add Comment
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.saveComments(index, x.tariffOptionID)}}>
                  Save Comments 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{this.deleteComments(index)}}>
                  Delete Comment 
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigComments('Tariff Option Project Comments')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Movement</h6>
            </div>
            <Movement addMovement={(index)=>this.addMovement(index)} tariffOptionsDataStore={tariffOptionsDataStoreProject} index={index} tariffOptionID={x.tariffOptionID} compare={this.props.compare}  allDisabled = {this.props.allDisabled}/>
            <div className='internalHeader'>
              <h6>Connection Point</h6>
            </div>
            <ConnectionPoint addConnectionPoint={(index)=>this.addConnectionPoint(index)} tariffOptionsDataStore={tariffOptionsDataStoreProject} index={index} tariffOptionID={x.tariffOptionID} compare={this.props.compare} allDisabled = {this.props.allDisabled}/>
            {tariffOptionsDataStoreProject.agGridServiceConnectionPoint && <></>}
            {tariffOptionsDataStoreProject.agGridServiceMovement && <></>}
            {tariffOptionsDataStoreProject.agGridServiceOwner && <></>}
            {tariffOptionsDataStoreProject.agGridServicePipeline && <></>}
            {tariffOptionsDataStoreProject.agGridServiceComments && <></>}
            {tariffOptionsDataStoreProject.agGridServiceDeduction && <></>}
            {tariffOptionsDataStoreProject.agGridServiceLossAllowance && <></>}
            {tariffOptionsDataStoreProject.agGridServiceRelatedTariff && <></>}
            <div className='internalHeader'>
              <h6>Pipeline Area</h6>
            </div>
            {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.addPipelineArea(index)}}>
                  Add Pipeline Area 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.savePipelineArea(index, x.tariffOptionID)}}>
                  Save Pipeline Area 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{this.deletePipelineArea(index)}}>
                  Delete Pipeline Area 
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigPipelineArea('Tariff Option Project Pipeline Area')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Owner</h6>
            </div>
            {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.addOwner(index)}}>
                  Add Owner
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.saveOwner(index, x.tariffOptionID)}}>
                  Save Owner
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{this.deleteOwner(index)}}>
                  Delete Owner
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigOwner('Tariff Option Project Owner')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Related Tariff</h6>
            </div>
            {this.props.tariffOptionsDataStoreProject?.stepName==='Process & Submit for Approval'&&this.props.tariffOptionsDataStoreProject?.workflowAction==='Process' && tariffOptionsDataStoreProject.formData[tariffOptionsDataStoreProject.selectedTab].type==='Rules and Regulations' && (<Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.addRelatedTariff(index)}}>
                  Add Related Tariff
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{this.deleteRelatedTariff(index)}}>
                  Delete Related Tariff
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={()=>{tariffOptionsDataStoreProject.addRelatedTariff(index, true)}}>
                  Link Tariff
                  </CustomButton>
              </div>
            </Row>)}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigRelatedTariff('Tariff Option Project Related Tariff')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Loss Allowance</h6>
            </div>
            <LossAllowance addLossAllowance={(index)=>this.addLossAllowance(index)} tariffOptionsDataStore={tariffOptionsDataStoreProject} index={index} tariffOptionID={x.tariffOptionID}/>
            {tariffOptionsDataStoreProject.addLossAllowance('blank') && <></>}
            <div className='internalHeader'>
              <h6>Deduction</h6>
            </div>
            {/* <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.addDeduction(index)}}>
                  Add Deduction
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{tariffOptionsDataStoreProject.saveDeduction(index, x.tariffOptionID)}}>
                  Save Deduction
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStoreProject.formData[index]['new']===true} onClick={()=>{this.deleteDeduction(index)}}>
                  Delete Deduction
                  </CustomButton>
              </div>
            </Row> */}
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigDeduction('Tariff Option Project Deduction')}/>
                {tariffOptionsDataStoreProject.statusOrHistory && <></>}
                {tariffOptionsDataStoreProject.workflowComplete && <></>}
                {tariffOptionsDataStoreProject.workflowStatusGridData && <></>}
            </Row>
            <div className='internalHeader'>
              <h6>CTS Purpose & Comments</h6>
            </div>
            <Row style={{margin: '10px'}}>
              <Col style={{display: 'flex', maxWidth: 'unset', alignItems: 'center'}}>
                  <label className="standard_label_style">Purpose:</label>
                  <textarea
                    style={{marginLeft: '20px', borderRadius: '5px', width: '100%', height: '120px', border: '1px solid #a3d0e4'}} 
                    value={tariffOptionsDataStoreProject.purpose[index]}
                    onChange={(e) => tariffOptionsDataStoreProject.changePurpose(e.target.value)}
                    disabled= {true}// {tariffOptionsDataStoreLog.readOnlyView}
                  />
              </Col>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigCTSComments('Tariff Option CTS Comments')}/>
            </Row>
            </div>)))||<></>}
            </div>
          </div>
        {/* </Container> */}
      </div>
    )
  }
}
