import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class PMCDashboardDataService {
    constructor(private restApi: RestApiService) {}

    async getSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getPipelineGroup(): Promise<any[]> {
            const { data } = await this.restApi.get<any>(Path.url_id24);
            return data;
    }

    async getTariffStatus(): Promise<any[]> {
            const { data } = await this.restApi.get<any>(Path.getTariffStatus);
            return data;
    }

    async getDistributionTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id28);
        return data;
    }

    async getPMCTariffDetailIDs(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCTariffDetailIDs);
        return data;
    }

    async getUserNames(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getUserNames + `?location=${jurisdiction}`);
        return data;
    }

    async getTariffRequestTypes(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffRequestTypes + `?jurisdiction=${jurisdiction}`);
        return data;
    }

    async getProducts(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id27);
        return data;
    }
    
    async getExternalSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getExternalSystems);
        return data;
    }

    async getProductTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCProductTypes);
        return data;
    }

    async getTariffApplications(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffApplications);
        return data;
    }

    async getUnits(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id29);
        return data;
    }

    async getCurrency(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id30);
        return data;
    }

    async getRegulator(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getRegulator);
        return data;
    }

    async getOperators(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getOperators);
        return data;
    }

    async getPMCShrinkage(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCShrinkage);
        return data;
    }

    async getPMCLossAllwance(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCLossAllwance);
        return data;
    }

    async getFacilityCodes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getFacilityCodes);
        return data;
    }

    async getDestination(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getDestination);
        return data;
    }

    async getLSD(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLSD);
        return data;
    }

    async getPMCDashboardDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.getPMCDashboardDetails, reqbody, {});
        return data;
    }
}

export const pmcDashboardDataService = new PMCDashboardDataService(restApiService);
