import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { filter, isEmpty, update } from 'lodash';
import { lineSegmentColDef } from '../components/line-segment-col-def';
import moment from 'moment';
import Path from '../../../../Path';
// import Path, { LineSegmentSettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { LineSegmentMaintenanceDataService } from '../services/line-segment-maintenance-data-service';
import { LineSegmentMaintenanceResponse } from '../model/model';
import { LineSegmentMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { lineSegmentMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
// import { AddLineSegmentDetailsColDef } from '../components/add-lineSegment-details-container';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class AddEditLineSegmentMaintenanceStore {
    @observable lineSegmentMaintenanceData: LineSegmentMaintenanceResponse[] = [];
    @observable modifiedLineSegmentMaintenanceData: LineSegmentMaintenanceResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable cellValueChangeMapForRemark: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    defaultLoggedInUserLocation = '';
    backupLineSegmentMaintenanceData: LineSegmentMaintenanceResponse[] = [];
    @observable isRefreshed = false;
    @observable showModal = false;
    lineSegmentTypeData: any[] = [];
    pipelineSystemData: any[] = [];
    lineSegmentStatusData: any[] = [];
    @observable selectedSegmentTypeForAddEdit;
    @observable selectedDescriptionForAddEdit;
    @observable selectedPipelineSystemForAddEdit;
    @observable relSelectedPipelineSystemForAddEdit;
    @observable selectedTariffProjectForAddEdit;
    @observable selectedWorkflowId = 0;
    @observable isFromProjectRequest = false;
    @observable selectedBeginningBaseSystemForAddEdit;
    @observable selectedEndingBaseSystemForAddEdit;
    @observable selectedBeginningLocationForAddEdit;
    @observable selectedEndingLocationForAddEdit;
    @observable selectedBeginningCPForAddEdit;
    @observable selectedEndingCPForAddEdit;
    @observable selectedOperationType = { value: 'CMG', label: 'Commingled' };
    @observable selectedOperationTypeCode = 'CMG';
    @observable isShowModal = false;
    @observable isEditingLineSegment = false;
    @observable selectedMiles;
    @observable prevSelectedMiles;
    @observable enteredRemarkForMile = '';
    @observable editableLSData: any = {};
    @observable backupEditableLSData: any = {};
    @observable projectNamesData: any = [];
    @observable shownProjectNames: any = [];
    @observable operationTypeData: any = [];
    @observable lineSegmentDescData: any = [];
    @observable lineSegmentDescDataForLS: any = [];
    @observable lineSegmentRelationshipData: any = [];
    @observable lineSegmentRelData: any = [];    
    @observable operationTypeDropdownOption: any = [];
    @observable baseSystemData: any = [];
    @observable baseSystemDropdownOption: any = [];
    @observable relatedBaseSystemDropdownOption: any = [];
    @observable locationData: any = [];
    @observable beginningLocationDropdownOption: any = [];
    @observable endingLocationDropdownOption: any = [];
    @observable beginningCPTypeData: any = [];
    @observable endingCPTypeData : any = [];
    @observable beginningCPTypeDropdownOption: any = [];
    @observable endingCPTypeDropdownOption: any = [];
    @observable isBiDirectionalChecked = false;
    @observable enteredCapacity;
    @observable selectedServiceDate;
    @observable selectedStatusForAddEdit;
    @observable addOrEditLinkerGridData: any = [];
    @observable backupAddOrEditLinkerGridData: any = [];
    @observable remarksGridData: any = [];
    @observable backupRemarksGridData: any = [];    
    
    @observable pipelineSystemDropdownOption: any[] = [];
    @observable lineSegTypeDropdownOption: any[] = [];
    @observable lineSegStatusDropdownOption: any[] = [];
    @observable selectedLineSegmentDetails: any = {};
    @observable addOrEditedLineSegName = '';
    @observable selectedLineSegmentId;
    @observable addOrEditLineSegmentGridData: any[] = [];
    @observable backupAddOrEditLineSegmentGridData: any[] = [];
    @observable onClickLineSegmentNameOnGrid = false;
    @observable onClickAddNewLineSegmentButton = false;
    @observable isActivateButtonDisabled = true;
    @observable updatedFieldonLineSegmentDetailsScreen = false;
    navigateToScreen: any = null;
    navigateState: any;

    constructor(
        public lineSegmentMaintenanceDataService: LineSegmentMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditRemark: AgGridService,
        public agGridServiceForAddOrEditLinker: AgGridService,
    ) {
        makeAutoObservable(this);
    }

    async init(): Promise<void> {
        this.userName = this.accountStore.displayName;
        this.navigateState = null;
        this.navigateToScreen = null;
        this.defaultLoggedInUserLocation = this.accountStore.defaultUserLocation;
        this.selectedLineSegmentDetails = {};
        
        dataStore.setShowUnsavedWarningAlert(false);
        await this.getPipelineSystemDropdown();
        await this.getLineSegmentStatusData();
        await this.getLineSegmentTypeData();

        await this.getProjectNames();
        await this.getSettingsData();
        await this.getBaseSystemDropdown();
        if (!this.isEditingLineSegment) {
            await this.getLocationDropdown();
        }
        await this.getBeginningCPTariff();
        await this.getEndingCPTariff();

        const historyVar: any = history;
        const state = historyVar?.location?.state as { from?: string; url?: string; workflowId?: number; pipeline?: any; location?: any; connectionPoint?: any } || '';
        if (state.url) {
            this.navigateToScreen = state.url;
            this.selectedWorkflowId = state.workflowId ? state.workflowId : 0;
            const projName = state.workflowId ? this.projectNamesData.filter(x => x.workflowID == state.workflowId)[0].name : '';
            this.selectedTariffProjectForAddEdit = projName;
            this.isFromProjectRequest = true;

            this.navigateState = state;
            const a = state.pipeline ? state.pipeline.map(x => {return { 'value': x.label, 'label': x.label}}) : this.baseSystemDropdownOption;
            this.baseSystemDropdownOption = a;
            const f = a.map((x) => {
                return x.value
            })
            const baseSystemData = this.baseSystemData.filter((x) => f.includes(x.plName));
            this.selectedBeginningBaseSystemForAddEdit = this.baseSystemDropdownOption.length  === 1 ?  this.baseSystemDropdownOption[0].value : null;
            this.selectedEndingBaseSystemForAddEdit = this.baseSystemDropdownOption.length  === 1 ?  this.baseSystemDropdownOption[0].value : null;
            const selectedBaseIds = baseSystemData.map(item => item.pipelineID)
            const systemAreas = this.pipelineSystemData.filter(x => selectedBaseIds.includes(x.basePipelineID))
            const selectedSystems = systemAreas.map(x => { return { 'value': x.plName, 'label': x.plName } })
            if (selectedSystems && selectedSystems.length > 0) {
                this.pipelineSystemDropdownOption = selectedSystems;
            } else {
                this.getPipelineSystemDropdown()
            }

            const uniqueBaseSystems = [... new Set(systemAreas.map(x => x.basePipelineID))];
            this.selectedPipelineSystemForAddEdit = uniqueBaseSystems && uniqueBaseSystems.length == 1 ? systemAreas.filter(x => x.pipelineID == uniqueBaseSystems[0])[0].plName : '';

            const baseDetailsBeginning = baseSystemData.find((item) => item.plName === this.selectedBeginningBaseSystemForAddEdit)
            const pipelineIDBeginning = baseDetailsBeginning && baseDetailsBeginning.pipelineID ? baseDetailsBeginning.pipelineID : null;
            const location = this.selectedBeginningBaseSystemForAddEdit ? this.locationData.filter(x => x.plName == this.selectedBeginningBaseSystemForAddEdit) : '';
            const locationOptDataBeginning = location ? location.map(x => { return { 'value': x.locnName, 'label': x.locnName } }) : this.beginningLocationDropdownOption;
            this.beginningLocationDropdownOption = locationOptDataBeginning;
            this.endingLocationDropdownOption = locationOptDataBeginning;
            await this.getBeginningCPTariff();
        }
        history.push('/AddEditLineSegmentMaintenance');
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineSystemDropdown(): Promise<void> {
        let pipelineSystemDropdownOptions: any[] = [];
        // if (!this.isFromProjectRequest && this.selectedWorkflowId !== 0) {
        pipelineSystemDropdownOptions = await this.lineSegmentMaintenanceDataService.getActivePipelines(this.selectedWorkflowId);
        // } else {
        //     pipelineSystemDropdownOptions = await this.lineSegmentMaintenanceDataService.getPipelineSystems();
        this.pipelineSystemData = pipelineSystemDropdownOptions;
        // }
        
        // this.pipelineSystemData = pipelineSystemDropdownOptions ? pipelineSystemDropdownOptions : this.pipelineSystemData;
        const systemsData = pipelineSystemDropdownOptions.map(x => {return { 'value': x.pipelineID, 'label': x.plName}});
        runInAction(() => {
            this.showLoader = false;
            this.pipelineSystemDropdownOption = systemsData;
        });
    }


    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLineSegmentTypeData(): Promise<void> {
        const lineSegTypeDropdownOption = await this.lineSegmentMaintenanceDataService.getLineSegmentTypeData();
        this.lineSegmentTypeData = lineSegTypeDropdownOption;
        const a = lineSegTypeDropdownOption.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
        runInAction(() => {
            this.showLoader = false;
            this.lineSegTypeDropdownOption = a;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLineSegmentStatusData(): Promise<void> {
        const lineSegStatusDropdownOption = await this.lineSegmentMaintenanceDataService.getLineSegmentStatusData();
        this.lineSegmentStatusData = lineSegStatusDropdownOption;
        const a = lineSegStatusDropdownOption.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
        runInAction(() => {
            this.showLoader = false;
            this.lineSegStatusDropdownOption = a;
        });
    }

    @Loader
    @action
    async getProjectNames(): Promise<void> {
        const projectNamesData = await this.lineSegmentMaintenanceDataService.getProjectNames();
        this.projectNamesData = projectNamesData;
        const a = projectNamesData.map(x => {return { 'value': x.workflowID, 'label': x.name}});
        this.shownProjectNames = a;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getSettingsData(): Promise<void> {
        const response = await this.lineSegmentMaintenanceDataService.getMiscellaneousSettingsData(this.defaultLoggedInUserLocation);
        const filteredResponse = response.filter((item) => item.settingName === 'TariffLineSegmentOpTypes');
        const a = filteredResponse.map(x => { return { 'value': x.valueCode, 'label': x.valueText } });
        this.operationTypeDropdownOption = a;
        const filteredDesc = response.filter((item) => item.settingName === 'Line Seg Rel Types');
        this.lineSegmentRelData = filteredDesc;
        const b = filteredDesc.map(x => { return { 'value': x.valueText, 'label': x.valueText } });
        this.lineSegmentRelationshipData = b;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(basePipelineId?): Promise<void> {
        const baseSystemDropdownOptions = await this.lineSegmentMaintenanceDataService.getBaseSystems();
        if(baseSystemDropdownOptions) {
            this.baseSystemData = baseSystemDropdownOptions;
            const filteredBaseSystems = this.selectedPipelineSystemForAddEdit && basePipelineId ? baseSystemDropdownOptions.filter(x => x.pipelineID == basePipelineId) : baseSystemDropdownOptions;
            const a = filteredBaseSystems.map(x => {return { 'value': x.plName, 'label': x.plName}});
            this.baseSystemDropdownOption= a;
            this.relatedBaseSystemDropdownOption = a;
        }
    }

    @action
    async getLineSegmentDesc(pipeline): Promise<void> {
        const pipelineId = this.pipelineSystemData.length > 0 ? this.pipelineSystemData.filter(x => x.plName == pipeline)[0].pipelineID : 0;
        const response = await this.lineSegmentMaintenanceDataService.getLineSegmentDesc(pipelineId);
        if(response && response.length > 0) {
            const a = this.lineSegmentDescData.map((x) => x.lineSegDesc)
            response.map((item) => {
                if(!a.includes(item.lineSegDesc))
                this.lineSegmentDescData.push(item)
            })
        }
    }

    @action
    async getLineSegmentDescForLS(pipeline): Promise<void> {
        const pipelineId = this.pipelineSystemData.length > 0 ? this.pipelineSystemData.filter(x => x.plName == pipeline)[0].pipelineID : 0;
        const response = await this.lineSegmentMaintenanceDataService.getLineSegmentDesc(pipelineId);
        if(response && response.length > 0) {
            const a = this.lineSegmentDescDataForLS.map((x) => x.lineSegDesc)
            response.map((item) => {
                if(!a.includes(item.lineSegDesc))
                this.lineSegmentDescDataForLS.push(item)
            })
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLocationDropdown(): Promise<void> {
        const locationDropdownOptions = await this.lineSegmentMaintenanceDataService.getLocationsData();
        if (locationDropdownOptions) {
            this.locationData = locationDropdownOptions;
            const a = locationDropdownOptions.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
            this.beginningLocationDropdownOption = a;
            this.endingLocationDropdownOption = a;
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBeginningCPTariff(locationName?): Promise<void> {
        const location = locationName ? this.locationData.find((x) => x.locnName === locationName) : this.locationData.find((x) => x.locnName === this.selectedBeginningLocationForAddEdit);
        const connectionPointGridData = await this.lineSegmentMaintenanceDataService.getCPTariff(location && location.locationID ?  location.locationID : 0);
        if (connectionPointGridData && connectionPointGridData.length > 0) {
            this.beginningCPTypeData = connectionPointGridData;
            const a = connectionPointGridData.map(x => {return { 'value': x.connectionPointID, 'label': x.cpName}});
            this.beginningCPTypeDropdownOption = a;
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getEndingCPTariff(locationName?): Promise<void> {
        const location = locationName ? this.locationData.find((x) => x.locnName === locationName) : this.locationData.find((x) => x.locnName === this.selectedEndingLocationForAddEdit);
        const connectionPointGridData = await this.lineSegmentMaintenanceDataService.getCPTariff(location && location.locationID ?  location.locationID : 0);
        if (connectionPointGridData) {
            this.endingCPTypeData = connectionPointGridData;
            const a = connectionPointGridData.map(x => {return { 'value': x.connectionPointID, 'label': x.cpName}});
            this.endingCPTypeDropdownOption = a;
        }
    }

    @action
    createDataSourceGroupByLineSegment(response) {
        const array = response
        const result = array.reduce((r, { LineSegName: LineSegName, ...object }) => {
        let temp = r.find(o => o.LineSegName === LineSegName);
        if (!temp) r.push(temp = { LineSegName, children: [] });
        temp.children.push(object);
        return r;
    }, []);
    
    console.log(result);
    }

    validateRequest = () => {
        const localValidatingErrorBody: any = [];
        let isValidRequest = true;
        if(!this.selectedPipelineSystemForAddEdit || this.selectedPipelineSystemForAddEdit === '') {
            const message = 'Please select a valid System Name'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(!this.selectedSegmentTypeForAddEdit || this.selectedSegmentTypeForAddEdit === '') {
            const message = 'Please select a valid Segment Type'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        }

        if(!this.selectedDescriptionForAddEdit || this.selectedDescriptionForAddEdit === '') {
            const message = 'Please enter a valid Description'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 
        if(!this.selectedBeginningBaseSystemForAddEdit || this.selectedBeginningBaseSystemForAddEdit === '') {
            const message = 'Please select a valid Beginning Base System'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        if(!this.selectedEndingBaseSystemForAddEdit || this.selectedEndingBaseSystemForAddEdit === '') {
            const message = 'Please select a valid Ending Base System'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        if(!this.selectedBeginningLocationForAddEdit || this.selectedBeginningLocationForAddEdit === '') {
            const message = 'Please select a valid Beginning Location Name'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        if(!this.selectedEndingLocationForAddEdit || this.selectedEndingLocationForAddEdit === '') {
            const message = 'Please select a valid Ending Location Name'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        } 

        // if(!this.selectedOperationType || this.selectedOperationType === '') {
        //     const message = 'Please select a valid Operation Type'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // } 

        if(!this.selectedServiceDate || this.selectedServiceDate === '') {
            const message = 'Please select a valid Service Date'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        }

        if(!this.selectedStatusForAddEdit || this.selectedStatusForAddEdit === '') {
            const message = 'Please select a valid Status'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        }

        return {
            isValidRequest: isValidRequest, 
            localValidatingErrorBody: localValidatingErrorBody
        };
  
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async saveLineSegment(): Promise<void> {
        const errorBody: any = [];
        const { isValidRequest, localValidatingErrorBody} = this.validateRequest();
        if (isValidRequest) {
            const filteredCPData = this.lineSegmentDescDataForLS.filter((cp) => cp.lineSegDesc.toLowerCase().trim() === this.selectedDescriptionForAddEdit.toLowerCase().trim());
            const existingCP = this.editableLSData && this.editableLSData.lineSegID ? filteredCPData.some((cp) => cp.primaryLineSegID !== this.editableLSData.lineSegID) : filteredCPData.length > 1;
            const isUniqueCP = this.editableLSData && this.editableLSData.lineSegID ? !existingCP : filteredCPData.length <= 0;
            if (isUniqueCP) {
                const systemAreaId = this.pipelineSystemData.length > 0 ? this.pipelineSystemData.filter(x => x.plName == this.selectedPipelineSystemForAddEdit)[0].pipelineID : 0;
                const segmentTypeId = this.lineSegmentTypeData.length > 0 ? this.lineSegmentTypeData.filter(x => x.valueText == this.selectedSegmentTypeForAddEdit)[0].settingID : 0;
                const beginingBaseSystemId = this.baseSystemData.length > 0 ? this.baseSystemData.filter(x => x.plName == this.selectedBeginningBaseSystemForAddEdit)[0].pipelineID : 0;
                const beginingLocationId = this.locationData.length > 0 ? this.locationData.filter(x => x.locnName == this.selectedBeginningLocationForAddEdit)[0].locationID : 0;
                const beginingCPId = this.beginningCPTypeData.length > 0 && this.selectedBeginningCPForAddEdit ? this.beginningCPTypeData.filter(x => x.cpName == this.selectedBeginningCPForAddEdit)[0].connectionPointID : null;
                const endingBaseSystemId = this.baseSystemData.length > 0 ? this.baseSystemData.filter(x => x.plName == this.selectedEndingBaseSystemForAddEdit)[0].pipelineID : 0;
                const endingLocationId = this.locationData.length > 0 ? this.locationData.filter(x => x.locnName == this.selectedEndingLocationForAddEdit)[0].locationID : 0;
                const endingCPId = this.endingCPTypeData.length > 0 && this.selectedEndingCPForAddEdit ? this.endingCPTypeData.filter(x => x.cpName == this.selectedEndingCPForAddEdit)[0].connectionPointID : null;
                const segmentStatusId = this.lineSegmentStatusData.length > 0 ? this.lineSegmentStatusData.filter(x => x.valueText == this.selectedStatusForAddEdit)[0].settingID : 0;
                this.addOrEditLinkerGridData.map(item => {
                    const basesegData = this.lineSegmentDescData.find(x => x.lineSegDesc == item.linkerLineSegment);
                    item.baseLineSegID = basesegData ? basesegData.lineSegID : item.baseLineSegID;
                    item.relSegType = this.lineSegmentRelData.filter(x => x.valueText == item.linkerRelationshipType)[0]?.settingID;
                })
                
                const tariffLSComments = this.remarksGridData.length > 0 ? this.remarksGridData.map(x => {
                    return {
                        'lsCommentID': x.lsCommentID > 0 ? x.lsCommentID : 0,
                        'lsid': this.selectedLineSegmentId ? this.selectedLineSegmentId : 0,
                        'remark': x.remark,
                        'lastUpdatedBy': x.lastUpdatedBy,
                        'lastUpdatedDate': x.lastUpdatedDate,
                    }
                }) : [];

                if (this.addOrEditLinkerGridData.length > 1) {
                    const linkerDescArr = this.addOrEditLinkerGridData.map((item) => item.baseLineSegID );
                    const isDuplicate = linkerDescArr.some((item, idx) =>
                        linkerDescArr.indexOf(item) != idx 
                    );
                    if (isDuplicate) {
                        const message = 'Please enter a unique Linker Line Segment';
                        errorBody.push(message);
                    }
                }
                

                const tariffRelLineSegs = this.addOrEditLinkerGridData.length > 0 ? this.addOrEditLinkerGridData.map(x => {
                    if (!x.baseLineSegID) {
                        const message = 'Select Base segment description';
                        if (!errorBody.includes(message)) {
                            errorBody.push(message);
                        }
                    }
                    if (!x.relSegType) {
                        const message = 'Select Relationship type';
                        if (!errorBody.includes(message)) {
                            errorBody.push(message);
                        }
                    }
                    return {
                        'tariffRelLineSegID': x.tariffRelLineSegID > 0 ? x.tariffRelLineSegID : 0,
                        'primaryLineSegID': this.selectedLineSegmentId ? this.selectedLineSegmentId : 0,
                        'baseLineSegID': x.baseLineSegID,
                        'relSegType': x.relSegType
                    }
                }) : [];

                if (errorBody.length < 1) {
                    const requestBody: any = {
                        'lineSegID': this.editableLSData.lineSegID ? this.editableLSData.lineSegID : 0,
                        'systemArea': systemAreaId,
                        'segmentTypeId': segmentTypeId,
                        'segmentDescription': this.selectedDescriptionForAddEdit,
                        'beginingBaseSystemId': beginingBaseSystemId,
                        'beginingLocationId': beginingLocationId,
                        'beginingCPId': beginingCPId,
                        'endingBaseSystemId': endingBaseSystemId,
                        'endingLocationId': endingLocationId,
                        'endingCPId': endingCPId,
                        'segmentOperationType': this.selectedOperationTypeCode,
                        'capacity': this.enteredCapacity ? this.enteredCapacity : null,
                        'miles': this.selectedMiles ? this.selectedMiles : null,
                        'serviceDate': this.selectedServiceDate ? this.selectedServiceDate : null,
                        'biDirectional': this.isBiDirectionalChecked ? 'Y' : 'N',
                        'segmentStatusId': segmentStatusId,
                        'createDate': this.isEditingLineSegment ? this.editableLSData.createDate : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                        'createUser': this.isEditingLineSegment ? this.editableLSData.createUser : this.userName,
                        'updateDate': moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                        'updateUser': this.userName,
                        'commodityCode': 'CO',
                        'remarks': 'string',
                        'workflowId': this.isEditingLineSegment ? this.editableLSData.workflowId : this.selectedWorkflowId,
                        'tariffLSComments': tariffLSComments,
                        'tariffRelLineSegs': tariffRelLineSegs
                    };
                    const response: any = await this.lineSegmentMaintenanceDataService.saveLineSegment(requestBody);
                    if (response && response.lineSegID) {
                        this.uiService.toastService.success('Line Segment details saved successfully.');
                        if (this.editableLSData && !_.isEqual(this.editableLSData, this.backupEditableLSData)) {
                            response.syncStatus = SyncStatus[0];
                        }
                        this.editableLSData = response;
                        this.backupEditableLSData = response;
                        this.selectedLineSegmentId = response.lineSegID;
                        // this.lineSegmentDescData.push({lineSegDesc: this.selectedDescriptionForAddEdit});
                    } else {
                        this.uiService.toastService.error( response && response.value ? response.value :'There is an error while saving Line Segment details.')
                    }
                } else {
                const messages = '\u2022  ' + errorBody.join('\r\n\n\u2022  ');
                errorHandler(messages);
            }
            }else {
                errorHandler('Please enter a unique Line Segment Name');
            }
        }else {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        }
    }

    @action
    resetLineSegmentDetails() {
        this.isEditingLineSegment = false;
        this.selectedLineSegmentId = '';
        this.editableLSData = {};
        this.backupEditableLSData = {};
        this.selectedTariffProjectForAddEdit = '';
        this.isFromProjectRequest = false;
        this.selectedPipelineSystemForAddEdit = '';
        this.selectedSegmentTypeForAddEdit = '';
        this.selectedDescriptionForAddEdit = '';
        this.selectedBeginningBaseSystemForAddEdit = '';
        this.selectedEndingBaseSystemForAddEdit = '';
        this.selectedBeginningLocationForAddEdit = '';
        this.beginningLocationDropdownOption = [];
        this.selectedEndingLocationForAddEdit = '';
        this.endingLocationDropdownOption = [];
        this.selectedBeginningCPForAddEdit = '';
        this.beginningCPTypeDropdownOption = [];
        this.selectedEndingCPForAddEdit = '';
        this.endingCPTypeDropdownOption = [];
        this.selectedOperationType = { value: 'CMG', label: 'Commingled' };
        this.selectedOperationTypeCode = 'CMG';
        this.selectedMiles = null;
        this.enteredCapacity = null;
        this.prevSelectedMiles = null;
        this.isBiDirectionalChecked = false;
        this.selectedServiceDate = '';
        this.selectedStatusForAddEdit = '';
        this.remarksGridData = [];
        this.backupRemarksGridData = [];
        this.addOrEditLinkerGridData = [];
        this.backupAddOrEditLinkerGridData = []
        this.lineSegmentRelData = [];
        this.selectedWorkflowId = 0;
        this.enteredRemarkForMile = '';
        this.navigateToScreen = null;
    }

    @Loader
    @action
    @Catch((error) => { console.log(error); errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString()) })
    async deactivateLineSegment(): Promise<void> {
        const lineSegID = this.editableLSData.lineSegID;
        const userName = this.userName;
        const response: any = await this.lineSegmentMaintenanceDataService.deactivateLineSegment(lineSegID, userName);
        if(response && response.value) {
            this.uiService.toastService.error('Record cannot be deleted because it is linked to other LineSegment(s). Unlink the LineSegment.')
        }else{
            this.uiService.toastService.success('Line Segment deactivated successfully.')
        }
    }

    getColDef() {
        return lineSegmentColDef;
    }

    @action 
    scrollToFocusTheNewCellRelationship() {
        this.agGridServiceForAddOrEditLinker.scrollToSelectedRow();
    }

    incorrectMiles = () => {
        this.uiService.toastService.error('Miles should be only upto 2 decimal places!')
    }

    incorrectCapacity = () => {
        this.uiService.toastService.error('Capacity should be integer number!')
    }

    @action
    reset(): void {
        this.setLineSegmentMaintenanceData(this.backupLineSegmentMaintenanceData);
        this.modifiedLineSegmentMaintenanceData = this.backupLineSegmentMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setLineSegmentMaintenanceData(lineSegmentMaintenanceData: LineSegmentMaintenanceResponse[]): void {
        this.lineSegmentMaintenanceData = lineSegmentMaintenanceData
    }

    setbackupLineSegmentMaintenanceData(backUpList: LineSegmentMaintenanceResponse[]) {
        this.backupLineSegmentMaintenanceData = backUpList;
    }

    @computed
    get LineSegmentMaintenanceData() {
        return toJS(this.lineSegmentMaintenanceData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    getUpdatedRowIDsForRemark(): string[] {
        let updatedRowIDs: string[] = [];
      //  get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMapForRemark) {
            for (const col in this.cellValueChangeMapForRemark[row]) {
                const obj = this.cellValueChangeMapForRemark[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    getUpdatedRowIDsForLinker(): string[] {
        let updatedRowIDs: string[] = [];
      //  get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    addValuesInCellDropdowns(): void {
        this.agGridServiceForAddOrEditLinker.updateOptionCellEditorValues(
            this.addOrEditLinkerGridData,
            'linkerSystem',
            'linkerSystem'
        );
        this.agGridServiceForAddOrEditLinker.updateOptionCellEditorValues(
            this.addOrEditLinkerGridData,
            'linkerLineSegment',
            'linkerLineSegment'
        );
        this.agGridServiceForAddOrEditLinker.updateOptionCellEditorValues(
            this.addOrEditLinkerGridData,
            'linkerRelationshipType',
            'linkerRelationshipType'
        );
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.LineSegmentMaintenance);
    };

    isDeleteDisabled = ()  => {
          return !this.editableLSData  ||  (this.editableLSData && !this.editableLSData.lineSegID) || this.isActivateDeactivateDisabled()
    }
    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };


    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    setValueInChangeMapForRemark(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMapForRemark)) {
            this.cellValueChangeMapForRemark[row] = {};
        }
        if (!(col in this.cellValueChangeMapForRemark[row] && this.cellValueChangeMapForRemark[row][col].initValue))
            this.cellValueChangeMapForRemark[row][col] = { initValue: initValue };
        else this.cellValueChangeMapForRemark[row][col]['currentValue'] = newValue;
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.error) {
            errorHandler(LineSegmentMaintenanceMessages.SYNC_FAILED_SUBMIT);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const lsId = this.editableLSData ?  this.editableLSData.lineSegID.toString() : null;
        const requestBody: any =  {
            workflowid: this.selectedWorkflowId && this.selectedWorkflowId != 0 ? this.selectedWorkflowId : 0,
            env: env,
            id: lsId
            }
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response : any = await this.lineSegmentMaintenanceDataService.pushToVmacs(requestBody);
            if(response == true) {
                this.uiService.toastService.success(LineSegmentMaintenanceMessages.SYNC);
                this.editableLSData.syncStatus = env === 'Push LineSegment to Vmacs Test' ? SyncStatus[1] : SyncStatus[2]
            } else {
                this.uiService.toastService.error(LineSegmentMaintenanceMessages.SYNC_FAILED_SUBMIT);
            }
        }
    }

    @action
   isEditableJurisdiction(params) {
        if (params.data.lineSegmentID <= 0) {
        return true
        }
        return false;
   }

   @action
   async onChangeLineSegmentDescription(value) {
        this.selectedDescriptionForAddEdit = value;
   }

    @action
    async onChangeCapacity(value) {
        this.enteredCapacity = value;
    }
   
    @action
    onSelectServiceDate(serviceDate: Date): void {
        this.selectedServiceDate = serviceDate;
    }

    @action
    setLinkerGridData(addOrEditLinkerGridData: any[]): void {
        this.addOrEditLinkerGridData = addOrEditLinkerGridData;
        this.backupAddOrEditLinkerGridData = addOrEditLinkerGridData;
    }

    @computed
    get AddOrEditLinkerGridData() {
        return toJS(this.addOrEditLinkerGridData);
    }

    @action
    setRemarksGridData(remarksGridData: any[]): void {
        this.remarksGridData = remarksGridData
        this.backupRemarksGridData = remarksGridData;
    }

    @computed
    get RemarksGridData() {
        return toJS(this.remarksGridData);
    }

    @action
    headerCheckBoxSelection = (event) => {
        const checked = event.target.checked;
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.linkerLineSegment && filters.linkerLineSegment.values.includes(item.linkerLineSegment)) || (filters.linkerSystem && filters.linkerSystem.values.includes(item.linkerSystem)) || (filters.linkerRelationshipType && filters.linkerRelationshipType.values.include(item.linkerRelationshipType)))) : this.addOrEditLinkerGridData;
        // const data = filteredData.map((item) => { item.isSelected = checked; return (item)})
        filteredData.forEach((item) => {
            if(checked)
            this.setValueInChangeMap(item.tariffRelLineSegID, 'isSelected', item.isSelected, checked);
            item['isSelected'] = checked;
            this.updateRowforAddOrUpdateGrid(item);
        });   
    };

    @action
    checkIfHeaderCheckboxShouldbeChecked = (params?) => {
        const filters =  this.agGridServiceForAddOrEditLinker.getFilterValue();
        const filteredData = filters && JSON.stringify(filters) !== '{}' ? this.addOrEditLinkerGridData.filter((item) => ((filters.linkerLineSegment && filters.linkerLineSegment.values.includes(item.linkerLineSegment)) || (filters.linkerSystem && filters.linkerSystem.values.includes(item.linkerSystem)) || (filters.linkerRelationshipType && filters.linkerRelationshipType.values.include(item.linkerRelationshipType)))) : this.addOrEditLinkerGridData;
        return filteredData.some((item) => item.isSelected === false || !item.isSelected)
    }
   
    @action
    onCloseLineSegmentDetails() {
        if(this.navigateToScreen) {
            history.push(this.navigateToScreen, {workflowId : this.selectedWorkflowId, tariffName: this.navigateState?.tariffLog, tariffNumber: this.navigateState?.tariffLog, baseTariff: this.navigateState?.baseTariff, tariffLogId : this.navigateState?.tariffLogId});
            dataStore.setShowUnsavedWarningAlert(false);
        } else {
            history.push('/LineSegmentMaintenance');  
        }
    }

//     onClickClose = () => {
//     const { addEditLineSegmentMaintenanceStore } = this.props;
//     const previousLineSegment = history?.location?.state as { from?: string }

//     if (previousLineSegment?.from && previousLineSegment.from === '/LineSegmentMaintenance') {
//       history.push('/LineSegmentMaintenance')
//     }
//     // else if (pmcTariffDataStore.previousLoc === '/project-request-step-1') {
//     //     history.push('/project-request-step-1', {workflowId:projectRequestStep1DataStore.workflowId})
//     // }
//     else if (previousLineSegment?.from && previousLineSegment.from === '/ProjectDashboard') {
//         history.push('/ProjectDashboard')
//     }
//     else if (previousLineSegment?.from && previousLineSegment.from === '/project-request-infrastructure') {
//         history.push('/project-request-infrastructure')
//     }
//     else {
//       history.push('/LineSegmentMaintenance')
//     }
//     addEditLineSegmentMaintenanceStore.resetLineSegmentDetails();
//   }

    @action
   async onEnterLineSegmentforAddOREdit(value) {
        if(this.addOrEditedLineSegName !== value) {
            this.updatedFieldonLineSegmentDetailsScreen = value
        }
        this.addOrEditedLineSegName = value   
   }

   @computed
    get AddOrEditLineSegmentGridData() {
        return toJS(this.addOrEditLineSegmentGridData);
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditLinkerGridData.findIndex(a => a.tariffRelLineSegID == selectedRowData.tariffRelLineSegID);
        if (!_.isEqual(this.backupAddOrEditLinkerGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsLineSegmentDetailsGrid(this.addOrEditLinkerGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    @action
    updateRowforAddOrUpdateGridForRemark = (selectedRowData: any): void => {
        const updatedShopIndex = this.remarksGridData.findIndex(a => a.lsCommentID == selectedRowData.lsCommentID);
        if (!_.isEqual(this.backupRemarksGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsLineSegmentDetailsGridForRemark(this.remarksGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsLineSegmentDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.linkerLineSegment = updatedItem.linkerLineSegment;
        currentItem.linkerSystem = updatedItem.linkerSystem;
        currentItem.isSelected = updatedItem.isSelected;
        currentItem.linkerRelationshipType = updatedItem.linkerRelationshipType
    }

    mapEditableColumnsLineSegmentDetailsGridForRemark(currentItem: any, updatedItem: any) {
        currentItem.remark = updatedItem.remark;
        currentItem.lastUpdatedBy = updatedItem.lastUpdatedBy;
        currentItem.lastUpdatedDate = updatedItem.lastUpdatedDate
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.tariffRelLineSegID;
        const initValue = params.data.isSelected
        if(checked)
        this.setValueInChangeMap(row, 'isSelected', initValue, checked);
        params.data.isSelected = checked;
        this.updateRowforAddOrUpdateGrid(params.data);
    };

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }
}
