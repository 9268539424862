import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { AgencyMaintenanceDataStore } from '../stores/agency-maintenance-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { AgencyMaintenanceResponse } from '../model/model';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/agency.scss';
import { AddNewAgencyPopup, ConfirmNewAgencyProps } from './addNewAgencyPopup';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { agencyMaintenanceDataStore } from '../stores';
import { errorHandler } from 'shared/handlers/error-handler';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface AgencyMaintenanceContainerProps {
    agencyMaintenanceDataStore: AgencyMaintenanceDataStore;
}

interface AgencyMaintenanceContainerState {
    search: string;
    addRowClick: boolean;
    lastAddedAgencyID: any;
}

@inject('agencyMaintenanceDataStore')
@observer
export class AgencyMaintenanceContainer extends Component<AgencyMaintenanceContainerProps, AgencyMaintenanceContainerState> {
    constructor(props: AgencyMaintenanceContainerProps | Readonly<AgencyMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            lastAddedAgencyID: 0
        };
        dataStore.setModuleName('Agency Maintenance')
    }
    
    componentDidMount() {
      history.push('/Agency');
      const {agencyMaintenanceDataStore} = this.props;
      agencyMaintenanceDataStore.init();
      agencyMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const filters = localStorageService.get('Agency Maintenance_filter');
      this.props.agencyMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Agency Maintenance_' + accountStore.userName);
      this.props.agencyMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    componentWillUnmount() {
        this.props.agencyMaintenanceDataStore.reset();
    }
    
    onBtClearSelection = () => {
      const { agencyMaintenanceDataStore } = this.props;
      const updatedRowIDs = agencyMaintenanceDataStore.agencyMaintenanceData.filter(a => agencyMaintenanceDataStore.getUpdatedRowIDs().includes(a.agencyid.toString()));
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => agencyMaintenanceDataStore.onClearSearch(),
            '',
            'All the unsaved changes will be lost. Are you sure you want to Continue?'
          );
        } else {
            agencyMaintenanceDataStore.onClearSearch();
        }
    }
    
    handleKeyDown = e => {
      if (e.keyCode === 13) {
        this.onSearchClick();
      }
    }

    onSearchClick = () => {
      const { agencyMaintenanceDataStore } = this.props;
      const updatedRowIDs = agencyMaintenanceDataStore.agencyMaintenanceData.filter(a => agencyMaintenanceDataStore.getUpdatedRowIDs().includes(a.agencyid.toString()));
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => agencyMaintenanceDataStore.onSearchClick(),
            '',
            'All the unsaved changes will be lost. Are you sure you want to Continue?'
          );
        } else {
            agencyMaintenanceDataStore.onSearchClick();
        }
    }
    
    onAddNewClick = async () => {
      const defaultUserLocation = this.props.agencyMaintenanceDataStore.defaultLoggedInUserLocation;
      const jurisdictionData = defaultUserLocation !== 'All' ? this.props.agencyMaintenanceDataStore.jurisdictionData.filter((item) => item.valueCode === defaultUserLocation) : this.props.agencyMaintenanceDataStore.jurisdictionData;
      const jurisdictionDropdownData = jurisdictionData.map(x => {return { 'value': x.valueText, 'label': x.valueCode}});
      const agencyData = this.props.agencyMaintenanceDataStore.agencyMaintenanceData;
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        (data) => { this.props.agencyMaintenanceDataStore.saveAgency() },
        AddNewAgencyPopup,
        { jurisdictionOptions: jurisdictionDropdownData,
          agencyData: agencyData,
          editableData: [],
          isEditingAgencyData: false,
          defaultUserLocation: defaultUserLocation
        } as ConfirmNewAgencyProps,
        'confirmPopup'
      );
    }  
  
    // onEditAgencyClick = async () => {
    //   const selectedRows = await this.props.agencyMaintenanceDataStore.agGridService.getSelectedRows();
    //   if (selectedRows?.length != 1) {
    //     errorHandler('Please select one row to edit.');
    //   } else {
    //     const defaultUserLocation = this.props.agencyMaintenanceDataStore.defaultLoggedInUserLocation;
    //     const jurisdictionData = this.props.agencyMaintenanceDataStore.jurisdictionData; // defaultUserLocation !== 'All' ? this.props.agencyMaintenanceDataStore.jurisdictionData.filter((item) => item.valueCode === defaultUserLocation) : this.props.agencyMaintenanceDataStore.jurisdictionData;
    //     const agencyData = this.props.agencyMaintenanceDataStore.agencyMaintenanceData;
    //     agencyMaintenanceDataStore.editableAgencyData = selectedRows;
    //     agencyMaintenanceDataStore.isEditingAgencyData = true;
    //     const confirmService = new ConfirmService();
    //     confirmService.showConfirmWithCustomComponent(
    //       (data) => { this.props.agencyMaintenanceDataStore.saveAgency() },
    //       AddNewAgencyPopup,
    //       { jurisdictionOptions: jurisdictionData,
    //         agencyData: agencyData,
    //         editableData: selectedRows,
    //         isEditingAgencyData: true,
    //         defaultUserLocation: defaultUserLocation
    //       } as ConfirmNewAgencyProps,
    //       'confirmPopup'
    //     );
    //   }
    // }

    onDeleteClick = async () => {
      const { agencyMaintenanceDataStore } = this.props;
      const requestBody: any = await agencyMaintenanceDataStore.agGridService.getSelectedRows();
      const updatedRowIDs = agencyMaintenanceDataStore.agencyMaintenanceData.filter(a => agencyMaintenanceDataStore.getUpdatedRowIDs().includes(a.agencyid.toString()));
      if(requestBody.length > 0 && !requestBody.some((item) => item.action === 'D')) {
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => agencyMaintenanceDataStore.deleteAgency(),
            'Inactive',
            'All the unsaved changes will be lost. Are you sure you want to deactivate the selected agencies?'
        );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => agencyMaintenanceDataStore.deleteAgency(),
            'Inactive',
            'Are you sure you want to deactivate the selected agency?'
        );
        }
      } else {
        const secondaryText = requestBody.length > 0 ? 'Please select only the active agency to perform the operation' : 'No records are selected. Please select atleast one active agency to perform the operation.';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }


    onSelectJurisdiction = (e) => {
      this.props.agencyMaintenanceDataStore.onSelectJurisdiction(e.target.value)
    }

    handleStateChangeFromDelToActive = async() => {
      const { agencyMaintenanceDataStore } = this.props;
      const requestBody: any = await agencyMaintenanceDataStore.agGridService.getSelectedRows();
      const updatedRowIDs = agencyMaintenanceDataStore.agencyMaintenanceData.filter(a => agencyMaintenanceDataStore.getUpdatedRowIDs().includes(a.agencyid.toString()));
      if(requestBody.length > 0 && !requestBody.some((item) => item.action !== 'D')) {
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.agencyMaintenanceDataStore.updateStatusFromDelToActive(),
            'Active',
            'All the unsaved changes will be lost. Are you sure you want to activate the selected agencies?'
        );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.agencyMaintenanceDataStore.updateStatusFromDelToActive(),
            'Active',
            'Are you sure you want to activate the selected agencies?'
        );
        }
      } else {
        const secondaryText = requestBody.length > 0 ? 'Please select only the inactive agencies to perform the operation' : 'No records are selected. Please select atleast one inactive agency to perform the operation.';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
      console.log(' succesfully');
    }

    render(): React.ReactNode {
      const { agencyMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          <Row>
            <Col>
              <div className='agencyMaintenanceView'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Agency Maintenance</h2>
                </div>
                {/* <h4 className='search_criteria_title'> 
                  <label>Search Criteria</label> 
                </h4> */}
                <Form onKeyDown={this.handleKeyDown}>
                  <div className='selectContainer'>
                    <div className='settingSelectionDiv'>
                      <label className="standard_label_style" style={{ color: 'red' }}>PAA  Jurisidiction</label>
                    <input 
                      type="text"
                      value={agencyMaintenanceDataStore.selectedJurisdictionName}
                      className="search_text_field"
                      // disabled={true}
                      onChange = {(e) => this.onSelectJurisdiction(e)}
                    />
                    <label className="standard_label_style">Agency Code</label>
                      <ReactSelect
                        className='select'
                        values={agencyMaintenanceDataStore.selectedAgencyCode}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...agencyMaintenanceDataStore.agencyCodeDropdownOption]}
                        onChange={(e: any) => {agencyMaintenanceDataStore.selectedAgencyCode = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                    <label className="standard_label_style">Agency Name</label>
                    <ReactSelect
                        className='select'
                        values={agencyMaintenanceDataStore.selectedAgencyName}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...agencyMaintenanceDataStore.agencyNameDropdownOption]}
                        onChange={(e: any) => {agencyMaintenanceDataStore.selectedAgencyName = e}}
                        isMulti={false}
                        isSearchable={true}
                    />
                    </div>
                    <div className='searchButtonDiv'>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                        Search
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                        Clear
                    </CustomButton>
                    <div className='otherHeaderButtonsDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled= {agencyMaintenanceDataStore.isSaveDisabled()}>
                        Add New Agency
                      </CustomButton>
                      {/* <CustomButton type={CustomButtonType.Submit} onClick={this.onEditAgencyClick} disabled= {agencyMaintenanceDataStore.isSaveDisabled()}>
                        Edit Agency
                      </CustomButton> */}
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onDeleteClick} disabled= {agencyMaintenanceDataStore.isActivateDeactivateDisabled()}>
                        Deactivate
                      </CustomButton>
                      <CustomButton type={CustomButtonType.Submit} onClick={this.handleStateChangeFromDelToActive} disabled= {agencyMaintenanceDataStore.isActivateButtonDisabled ||  agencyMaintenanceDataStore.isActivateDeactivateDisabled()}>
                          Activate
                      </CustomButton>
                    </div>
                    </div>
                  </div>
                </Form>
                {/* <div className='selectSecondLineContainer'>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                      Search
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                      Clear
                  </CustomButton>
                </div> */}
              </div>
              {this.props.agencyMaintenanceDataStore.showLoader == true &&
                <div className = 'alert-overlay'> 
                  <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                </div> 
              }
              <AgGridComponent gridConfig={this.getGridConfig('Agency Maintenance')} />
              <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/>

                {/* <div><br></br>
                  <input type="button" value="Create Base Tariff " className="cbasetariff" ></input>
                  <input type="button" value="Create CTS  Log" className="cctslog" ></input>
                  <input type="button" value="Create Tariff Request" className="ctariffrequest" ></input>
              </div> */}
            </Col>
          </Row>
        </Container>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          agencyMaintenanceDataStore: { AgencyMaintenanceData, agGridService }
      } = this.props;
      const { agencyMaintenanceDataStore } = this.props;
      const columnDefs = agencyMaintenanceDataStore.getColDef();
      const rowData = AgencyMaintenanceData;
      const onSelectionChanged = this.onSelectionChanged;
      const agencyMaintenanceGridParams: any = {
        rowData,
        columnDefs,
        onSelectionChanged
      };
      return agGridService.getGridConfig(agencyMaintenanceGridParams, gridId);
    }
    
    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        agencyMaintenanceDataStore.setIsActivateButtonDisabled(agencyMaintenanceDataStore.isSaveDisabled())
      } else {
        agencyMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
  }
}
