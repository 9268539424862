import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ProfitCenterMaintenanceDataStore } from '../stores/profit-center-maintenance-data-store';
import {
    GridOptions,
    RowClickedEvent,
    CellClickedEvent,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ProfitCenterMaintenanceResponse } from '../model/model';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/profit-center.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { profitCenterMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactSelect } from 'common/components/select/select';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface ProfitCenterMaintenanceContainerProps {
    profitCenterMaintenanceDataStore: ProfitCenterMaintenanceDataStore;
}

interface ProfitCenterMaintenanceContainerState {
    search: string;
}

@inject('profitCenterMaintenanceDataStore')
@observer
export class ProfitCenterMaintenanceContainer extends Component<ProfitCenterMaintenanceContainerProps, ProfitCenterMaintenanceContainerState> {
    constructor(props: ProfitCenterMaintenanceContainerProps | Readonly<ProfitCenterMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Profit Center Maintenance')
    }
    
    componentDidMount() {
      const {profitCenterMaintenanceDataStore} = this.props;
      const previousProfitCenter = history?.location?.state as { from?: string }
      history.push('/ProfitCenterMaintenance');
       if(previousProfitCenter?.from && previousProfitCenter.from==='/ProfitCenterDetails'){
           profitCenterMaintenanceDataStore.onSearchClick();   
     } else {
            profitCenterMaintenanceDataStore.init();   
        }
       profitCenterMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      // const {profitCenterMaintenanceDataStore} = this.props;
      // const filters = localStorageService.get('ProfitCenter Maintenance_filter');
      // this.props.profitCenterMaintenanceDataStore.agGridService.setAllFilters(filters);
      // const columnVisible = localStorageService.get('ProfitCenter Maintenance_' + accountStore.userName);
      // this.props.profitCenterMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    onBtClearSelection = () => {
      const { profitCenterMaintenanceDataStore } = this.props;
      profitCenterMaintenanceDataStore.onClearSearch();
    }

    onSearchClick = () => {
      const { profitCenterMaintenanceDataStore } = this.props;
      profitCenterMaintenanceDataStore.onSearchClick();
    }

    onAddNewClick = async() => {
      const { profitCenterMaintenanceDataStore } = this.props;
        
      // profitCenterMaintenanceDataStore.onClickAddNewProfitCenterButton = true;
      // profitCenterMaintenanceDataStore.onClickProfitCenterNameOnGrid = false;
      // profitCenterMaintenanceDataStore.agGridService.destroyGrid();
      // profitCenterMaintenanceDataStore.getSelectedProfitCenterData();
      history.push('/ProfitCenterDetails');
    }

    onChangeDescription = (e) => {
      this.props.profitCenterMaintenanceDataStore.onChangeDescription(e.target.value)
    }


    onChangeProfitCenter = (e) => {
      this.props.profitCenterMaintenanceDataStore.onChangeProfitCenter(e.target.value)
    }
    // handleStateChangeFromDelToActive = async() => {
    //   const { profitCenterMaintenanceDataStore } = this.props;
    //   const requestBody: any = await profitCenterMaintenanceDataStore.agGridService.getSelectedRows();
    //   const updatedRowIDs = profitCenterMaintenanceDataStore.profitCenterMaintenanceData.filter(a => profitCenterMaintenanceDataStore.getUpdatedRowIDs().includes(a.profitCenterID.toString()));
    //   if(requestBody.length > 0 && !requestBody.some((item) => item.action !== 'D')) {
    //     if(updatedRowIDs.length > 0) {
    //       const confirmService = new ConfirmService();
    //       confirmService.showConfirmDialog(
    //         () => {},
    //         'Active',
    //         'All the unsaved changes will be lost. Are you sure you want to activate the selected profitCenters?'
    //     );
    //     } else {
    //       const confirmService = new ConfirmService();
    //       confirmService.showConfirmDialog(
    //         () => {},
    //         'Active',
    //         'Are you sure you want to activate the selected profitCenters?'
    //     );
    //     }
    //   } else {
    //     const secondaryText = requestBody.length > 0 ? 'Please select only the inactive profitCenters to perform the operation' : 'No records are selected. Please select atleast one inactive profitCenter to perform the operation.';
    //     const confirmService = new ConfirmService();
    //     confirmService.showConfirmWithCustomComponent(
    //       () => {},
    //       ConfirmWithOK,
    //       {secondaryText: secondaryText} as ConfirmWithOKProps,
    //       'confirmPopup'
    //     );
    //   }
    //   console.log(' succesfully');
    // }

    render(): React.ReactNode {
      const { profitCenterMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderProfitCenterMaintenanceScreen()}
        </Container>
      );
    }

    renderProfitCenterMaintenanceScreen() : React.ReactNode {
      const { profitCenterMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='profitCenterMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Profit Center Maintenance</h2>
                </div>
                
                {/* <h4 className='search_criteria_title'> 
                  <label>Search Criteria</label> 
                </h4> */}
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                  <label className="standard_label_style">Profit Center</label>
                  <input 
                      type="text"
                      value={profitCenterMaintenanceDataStore.selectedprofitCenter}
                      className="search_text_field"
                      // disabled={true}
                      // style={{width: '20%'}}
                      onChange = {(e) => this.onChangeProfitCenter(e)}
                    />     
                  <label className="standard_label_style">Description</label>
                  <input 
                      type="text"
                      value={profitCenterMaintenanceDataStore.selectedDescription}
                      className="search_text_field"
                      // disabled={true}
                      // style={{width: '20%'}}
                      onChange = {(e) => this.onChangeDescription(e)}
                    /> 
                     <label className="standard_label_style">Valid To Date</label>
                  <input 
                      type="date"
                    value={profitCenterMaintenanceDataStore.selectedToDate}
                    className="search_text_field"
                    // disabled={true}
                    // style={{width: '20%'}}
                    onChange = {(e) => profitCenterMaintenanceDataStore.onChangeToDate(e.target.value)}
                  />  
                    {/* <label className="standard_label_style">Successor Profit Center</label>
                  <input 
                      type="text"
                      value={profitCenterMaintenanceDataStore.selectedSuccprofitCenter}
                      className="search_text_field"
                      // disabled={true}
                      // style={{width: '20%'}}
                      onChange = {(e) => profitCenterMaintenanceDataStore.onChangeSuccProfitCenter(e.target.value)}
                    />         */}
                </div>
                <div className='settingSelectionDiv'>
                  {/* <label className="standard_label_style">Profit Center Group</label>
                  <input 
                      type="text"
                    value={profitCenterMaintenanceDataStore.selectedprofitCenterGrp}
                    className="search_text_field"
                    // disabled={true}
                    // style={{width: '20%'}}
                    onChange = {(e) =>  profitCenterMaintenanceDataStore.onChangeProfitCenterGrp(e.target.value)}
                  />   */}
                 
                  <label className="standard_label_style">Valid From Date</label>
                  <input 
                      type="date"
                    value={profitCenterMaintenanceDataStore.selectedFromDate}
                    className="search_text_field"
                    // disabled={true}
                    // style={{width: '20%'}}
                    onChange = {(e) => profitCenterMaintenanceDataStore.onChangeFromDate(e.target.value)}
                  />  

                  <label className="standard_label_style">Base System</label>
                      <ReactSelect
                        className='select'
                        values={profitCenterMaintenanceDataStore.selectedBaseSystem}
                        placeholder="Select"
                        options={[{ value: 'All', label: 'All' }, ...profitCenterMaintenanceDataStore.baseSystemDropdownOptionsUI]}
                        onChange={(e: any) => {profitCenterMaintenanceDataStore.selectedBaseSystem = e}}
                        isMulti={false}
                        isSearchable={true}
                    />  
                  
                </div>
                <div className='searchButtonDiv'>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                      Search
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                      Clear
                  </CustomButton>
                  <div className='otherHeaderButtonsDiv'>
                    {/* <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled = {profitCenterMaintenanceDataStore.isSaveDisabled()}> */}
                    <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled = {profitCenterMaintenanceDataStore.isSaveDisabled()}>
                        Add Profit Center
                    </CustomButton>
                    {/* <CustomButton type={CustomButtonType.Submit} onClick={this.handleStateChangeFromDelToActive} disabled= {profitCenterMaintenanceDataStore.isActivateButtonDisabled}>
                      Activate
                    </CustomButton> */}
                      <CustomButton type={CustomButtonType.Submit} onClick={this.onAddNewClick} disabled= {profitCenterMaintenanceDataStore.isActivateButtonDisabled || profitCenterMaintenanceDataStore.isActivateDeactivateDisabled()}>
                      Activate
                    </CustomButton>
                    </div>
                </div>
              </div>
            </div>
            {this.props.profitCenterMaintenanceDataStore.showLoader == true &&
              <div className = 'alert-overlay'> 
                <div style={{margin: 'auto'}} className = 'dots-1'></div> 
              </div> 
            }
            <AgGridComponent gridConfig={this.getGridConfig('Profit Center Maintenance')} />
            {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
          </Col>
        </Row>
      );
    }


    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        profitCenterMaintenanceDataStore.setIsActivateButtonDisabled(profitCenterMaintenanceDataStore.isSaveDisabled())
      } else {
        profitCenterMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
    }

    private getGridConfig(gridId): GridOptions {
      const {
          profitCenterMaintenanceDataStore: { ProfitCenterMaintenanceData, agGridService }
      } = this.props;
      const { profitCenterMaintenanceDataStore } = this.props;
      const columnDefs = profitCenterMaintenanceDataStore.getColDef();
      // const rowData = ProfitCenterMaintenanceData;
      const onSelectionChanged = this.onSelectionChanged;
      const rowModelType = 'infinite';
      const cacheBlockSize = dataStore.CacheBlockSize
      const profitCenterMaintenanceGridParams: any = {
        // rowData,
        columnDefs,
        onSelectionChanged,
        rowModelType,
        cacheBlockSize
      };
      return agGridService.getGridConfig(profitCenterMaintenanceGridParams, gridId);
    }
}
