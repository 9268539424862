import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import './_add-notes.scss';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { AgGridComponent } from '../ag-grid-component';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AgGridService } from 'common/services/ag-grid-service';
import { DeleteRowCellRenderer } from '../cell-renderers/delete-ag-grid-row/delete-ag-grid-row';

interface AddNotesProps {
    notesGridData: any[];
    agGridService: AgGridService;
    disableAddBtn: boolean;
    disableSaveBtn: boolean;
    isNoteSaved: boolean;
    saveRoleAccess: () => boolean;
    onAddNewNoteCallback: () => void;
    onSaveNotesCallback: () => void;
    onDeleteNoteCallback: (data) => void;
    cellValueChangeMap: any;
    updateRow: (data: any) => void;
    disableDeleteBtn: boolean;
}


export const AddNotes = ({ notesGridData, agGridService, disableAddBtn, disableSaveBtn, disableDeleteBtn, isNoteSaved, saveRoleAccess, onAddNewNoteCallback, onSaveNotesCallback, onDeleteNoteCallback, cellValueChangeMap, updateRow }: AddNotesProps) => {
    const tariffNotesGridColDef: ColDef[] = [
        {
            headerName: 'Notes',
            field: 'notes',
            filter: true,
            sortable: true,
            editable: () => { return saveRoleAccess(); },
            minWidth: 365,
            flex: 1,
            valueGetter: params => {
                if (params.data['notes'] === null) {
                    return '';
                } else {
                    return params.data['notes'];
                }
            }
        },
        {
            headerName: 'Created By',
            field: 'createUser',
            width: 130,
            filter: true,
            sortable: true,
            editable: false,
            valueGetter: params => {
                if (params.data['createUser'] === null) {
                    return '';
                } else {
                    return params.data['createUser'];
                }
            }
        },
        {
            headerName: 'Created Date',
            field: 'createDate',
            filter: true,
            width: 130,
            sortable: true,
            editable: false,
            valueGetter: params => {
                if (params.data['createDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                        .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
                }
            }
        },
        {
            headerName: 'Updated By',
            field: 'updateUser',
            width: 130,
            filter: true,
            sortable: true,
            editable: false,
            valueGetter: params => {
                if (params.data['updateUser'] === null) {
                    return '';
                } else {
                    return params.data['updateUser'];
                }
            },
        },
        {
            headerName: 'Updated Date',
            field: 'updateDate',
            filter: true,
            width: 130,
            sortable: true,
            editable: false,
            valueGetter: params => {
                if (params.data['updateDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                        .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                        .format(AppConstant.DATE_TIME_FORMAT);
                }
            },
        },
        {
            headerName: '',
            field: 'delete',
            width: 130,
            cellRenderer: DeleteRowCellRenderer,
            cellRendererParams: {
                onDeleteRowCallback: onDeleteNoteCallback,
                disableDeleteBtn: disableDeleteBtn
            },
            cellStyle: params=> {
                if(params.data.pmcTariffNoteID < 0 || params.data.workflowDocumentID < 0){
                    return {'background-color': '#F5F5F5', display:'none'}
                }else{
                    return null;
                }
            },
        },
    ];

    const getNotesColDef = () => {
        return tariffNotesGridColDef;
    };

    const getNotesGridConfig = (gridId) => {
        const rowData = notesGridData;
        const tariffNotesGridParams: any = {
            rowData: rowData,
            columnDefs: getNotesColDef(),
            onCellEditingStopped: onCellEditingStopped,
            onCellEditingStarted: onCellEditingStarted,
            context : agGridService     
        };
        return agGridService.getGridConfig(tariffNotesGridParams, gridId);
    };

    const onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.pmcTariffNoteID;
        const col = event.colDef.field || '';
        const value = event.value;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            setValueInChangeMap(row, col, value, value);
        }
    };

    const onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
        const row = event.data.pmcTariffNoteID;
        const col = event.colDef.field || '';
        const value = event.value;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
        agGridService.getNodes()?.refreshCells();
    };

    const setValueInChangeMap = (row: number, col: string, initValue: string, newValue: string) => {
        if (!(row in cellValueChangeMap)) {
            cellValueChangeMap[row] = {};
        }
        if (!(col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue))
            cellValueChangeMap[row][col] = { initValue: initValue };
        else cellValueChangeMap[row][col]['currentValue'] = newValue;
    };

    return (
        <div className='notesGrid'>
            <div style={{ width: '80%' }}>
                <AgGridComponent gridConfig={getNotesGridConfig('Notes')} />
            </div>
            <CustomButton
                type={CustomButtonType.Submit}
                onClick={() => onAddNewNoteCallback()}
                disabled={disableAddBtn}
            >
                Add
            </CustomButton>
            <CustomButton
                type={CustomButtonType.Submit}
                onClick={() => onSaveNotesCallback()}
                disabled={disableSaveBtn}
            >
                Save
            </CustomButton>
        </div>
    );
};
