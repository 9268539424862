import { ColDef } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { pmcTariffDataService } from '../services/pmc-tariff-data-service';
import { pmcTariffDataStore } from '../stores';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { TariffManagementDataComponentModulesName } from 'features/tariff-management/components/constants';

  export const tariffNotesGridColDef: ColDef[] = [
    {
      headerName: 'Notes',
      field: 'notes',
      filter: true,
      sortable: true,
      singleClickEdit: true,
      editable: () => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, TariffManagementDataComponentModulesName.PMCTariff) ? true: false}, 
      minWidth: 268,
      valueGetter: params => {
            if (params.data['notes'] === null) {
                return '';
            } else {
                return params.data['notes'];
            }
        }
    },
    {
      headerName: 'Created By',
      field: 'createUser',
      minWidth: 150,
      filter: true,
      sortable: true,
      editable: false,
      valueGetter: params => {
            if (params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        }
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      filter: true,
      sortable: true,
      editable: false,
      valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        } 
    },
  ]

  export const tariffAttachmentsGridColDef: ColDef[] = [
    {
      headerName: 'File Name',
      field: 'fileName',
      filter: true,
      sortable: true,
      editable: false,
      cellEditor: 'agSelectCellEditor',
      minWidth: 268,
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: true,
      sortable: true,
      editable: false,
      minWidth: 268,
    },
    {
      headerName: 'Created By',
      field: 'createUser',
      minWidth: 150,
      filter: true,
      sortable: true,
      editable: false,
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      filter: true,
      sortable: true,
      editable: false,
    },
  ]