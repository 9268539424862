import { ProfitCenterMaintenanceDataStore } from './profit-center-maintenance-data-store';
import { profitCenterMaintenanceDataService } from '../services/profit-center-maintenance-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { accountStore } from '../../../account/stores';
import { ProfitCenterDetailsDataStore } from './profit-center-details-data-store';

export const agGridServiceForAddOrEditLocation = new AgGridService();

export const profitCenterMaintenanceDataStore = new ProfitCenterMaintenanceDataStore(profitCenterMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLocation);

export const profitCenterDetailsDataStore = new ProfitCenterDetailsDataStore(profitCenterMaintenanceDataService, agGridService, uiService, accountStore);
