import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { agencyColDef } from '../components/agency-col-def';
import moment from 'moment';
import Path, { AgencySettingsDBTableName } from '../../../../Path';
import axios from 'axios';
import { AgencyMaintenanceDataService } from '../services/agency-maintenance-data-service';
import { AgencyMaintenanceResponse } from '../model/model';
import { AgencyMaintenanceMessages } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { agencyMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { AddNewAgencyPopup, ConfirmNewAgencyProps } from '../components/addNewAgencyPopup';

export class AgencyMaintenanceDataStore {
    @observable agencyMaintenanceData: AgencyMaintenanceResponse[] = [];
    @observable modifiedAgencyMaintenanceData: AgencyMaintenanceResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupAgencyMaintenanceData: AgencyMaintenanceResponse[] = [];
    isRefreshed = false;
    @observable showModal = false;
    @observable selectedJurisdictionName = 'USA';
    @observable selectedAgencyName = { value: 'All', label: 'All' };
    @observable selectedAgencyCode = { value: 'All', label: 'All' };
    endDate: any = moment().toISOString().slice(0, 10);
    @observable jurisdictionDropdownOptions: any[] = [];
    @observable stateDropdownOptions: any[] = [];
    defaultLoggedInUserLocation = '';
    @observable addEditAgencyData: any = {};
    @observable isEditingAgencyData = false;
    @observable editableAgencyData: any = [];
    tariffStatesData: any[] = [];
    jurisdictionData: any[] = [];
    @observable agencyCodeDropdownOption: any[] = [];
    @observable agencyNameDropdownOption: any[] = [];
    @observable isActivateButtonDisabled = true

    constructor(
        public agencyMaintenanceDataService: AgencyMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        this.jurisdictionDropdownOptions = [];
        this.defaultLoggedInUserLocation = this.accountStore.defaultUserLocation;
        dataStore.setShowUnsavedWarningAlert(false);
        this.getJurisdictionDropdownData();
        this.getAgencyDropdownData();
        this.selectedJurisdictionName = 'USA';
        this.selectedAgencyName = { value: 'All', label: 'All' };
        this.selectedAgencyCode = { value: 'All', label: 'All' };
        this.loadViewModel();
        // this.userID = this.accountStore.getUserID();
        // this.userName = this.accountStore.displayName;
        this.onSelectJurisdiction(this.selectedJurisdictionName);
        this.userName = this.accountStore.displayName;
    }

    @Loader
    @action
    @Catch(() => errorHandler(AgencyMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const response: AgencyMaintenanceResponse[] = await this.agencyMaintenanceDataService.getAgenciesDataWithFilter(this.selectedJurisdictionName, this.selectedAgencyCode.label, this.selectedAgencyName.label );
        runInAction(() => {
            this.showLoader = false;
            this.modifiedAgencyMaintenanceData = response;
            this.agencyMaintenanceData = response;
            this.setAgencyMaintenanceData(response);
            this.setbackupAgencyMaintenanceData(response);
            this.isRefreshed = false;
        });
        this.addValuesInCellDropdowns();
        this.agGridService.getNodes()?.refreshCells();   
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getJurisdictionDropdownData(): Promise<void> {
        const jurisdictionDropdownOptions = await this.agencyMaintenanceDataService.getJurisdictionDropdownData();
        this.jurisdictionData = jurisdictionDropdownOptions;
        const a = (jurisdictionDropdownOptions.map(x => x.valueText));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({valueText : item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.jurisdictionDropdownOptions = option;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAgencyDropdownData(): Promise<void> {
        const agencyCodeOptions: any = [];
        const agencyNameOptions: any = [];
        const response: AgencyMaintenanceResponse[] = await this.agencyMaintenanceDataService.getAgenciesDataWithFilter(this.selectedJurisdictionName, this.selectedAgencyCode.label, this.selectedAgencyName.label );
        const filteredAgencyCodeArray = response.map(x => x.agencycode).filter((x , index, arr) => arr.indexOf(x) === index);
        filteredAgencyCodeArray.forEach((item) => {
            agencyCodeOptions.push({ 'value': item, 'label': item})
        })
        const filteredAgencyNameArray = response.map(x => x.agencyname).filter((x , index, arr) => arr.indexOf(x) === index);
        filteredAgencyNameArray.forEach((item) => {
            agencyNameOptions.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.agencyCodeDropdownOption = agencyCodeOptions;
            this.agencyNameDropdownOption = agencyNameOptions;
        });
    }

    @Loader
    @Catch(() => errorHandler(AgencyMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        const response: AgencyMaintenanceResponse[] = await this.agencyMaintenanceDataService.getTariffAgencies();
        runInAction(() => {
            this.showLoader = false;
            this.modifiedAgencyMaintenanceData = response;
            this.agencyMaintenanceData = response;
            this.setAgencyMaintenanceData(response);
            this.setbackupAgencyMaintenanceData(response);
            this.isRefreshed = false;
            this.selectedJurisdictionName = 'All';
            this.selectedAgencyCode = { value: 'All', label: 'All' };
            this.selectedAgencyName = { value: 'All', label: 'All' };
        });
        this.addValuesInCellDropdowns();
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @Catch(() => errorHandler(AgencyMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const response: AgencyMaintenanceResponse[] = await this.agencyMaintenanceDataService.getAgenciesDataWithFilter(this.selectedJurisdictionName, this.selectedAgencyCode.label, this.selectedAgencyName.label );
        runInAction(() => {
            this.showLoader = false;
            this.modifiedAgencyMaintenanceData = response;
            this.agencyMaintenanceData = response;
            this.setAgencyMaintenanceData(response);
            // this.setbackupAgencyMaintenanceData(response);
            this.isRefreshed = false;
        })
        this.addValuesInCellDropdowns();
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    getColDef() {
        this.updatePushtoVmacsButtonColDef();
        return agencyColDef;
    }

    @action
    reset(): void {
        this.setAgencyMaintenanceData(this.backupAgencyMaintenanceData);
        this.modifiedAgencyMaintenanceData = this.backupAgencyMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setAgencyMaintenanceData(agencyMaintenanceData: AgencyMaintenanceResponse[]): void {
        this.agencyMaintenanceData = agencyMaintenanceData
    }

    setbackupAgencyMaintenanceData(backUpList: AgencyMaintenanceResponse[]) {
        this.backupAgencyMaintenanceData = backUpList;
    }

    @computed
    get AgencyMaintenanceData() {
        return toJS(this.agencyMaintenanceData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    openAgencyDetails(data) {
        const editableAgencyData: any = [];
        editableAgencyData.push(data);
        const defaultUserLocation = this.defaultLoggedInUserLocation;
        const jurisdictionData = this.jurisdictionData; // defaultUserLocation !== 'All' ? this.props.agencyMaintenanceDataStore.jurisdictionData.filter((item) => item.valueCode === defaultUserLocation) : this.props.agencyMaintenanceDataStore.jurisdictionData;
        const jurisdictionDropdownData = this.jurisdictionData.map(x => {return { 'value': x.valueText, 'label': x.valueCode}});
        const agencyData = this.agencyMaintenanceData;
        agencyMaintenanceDataStore.editableAgencyData = editableAgencyData;
        agencyMaintenanceDataStore.isEditingAgencyData = true;
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          (editableAgencyData) => { this.saveAgency() },
          AddNewAgencyPopup,
          { jurisdictionOptions: jurisdictionDropdownData,
            agencyData: agencyData,
            editableData: editableAgencyData,
            isEditingAgencyData: true,
            defaultUserLocation: defaultUserLocation
          } as ConfirmNewAgencyProps,
          'confirmPopup'
        );
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(AgencyMaintenanceMessages.FAILED_SUBMIT))
    async saveAgency(): Promise<void> {
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        const item = this.addEditAgencyData;
        if((item.agencyCode !== '' && item.agencyCode) && (item.agencyName !== '' && item.agencyName) && (item.jurisdiction !== '' && item.jurisdiction)) {
            const isUniqueAgency = this.agencyMaintenanceData.filter((agency) => item.jurisdiction === agency.jurisdiction && (item.agencyCode === agency.agencycode || item.agencyName === agency.agencyname)).length <= 1;
            const jurisdiction = this.jurisdictionData.filter((jurisdiction) => jurisdiction.valueCode === item.jurisdiction)[0];
            if(isUniqueAgency) {
                item.updateuser = this.isEditingAgencyData ? this.userName : null,
                item.updatedate = this.isEditingAgencyData ? moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss') : null,
                item.createUser = this.isEditingAgencyData ? this.editableAgencyData[0].createUser : this.userName,
                item.createDate = this.isEditingAgencyData ? this.editableAgencyData[0].createDate : moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                item.agencyid = this.isEditingAgencyData ? this.editableAgencyData[0].agencyid : 0,
                item.jurisdiction = this.isEditingAgencyData ? item.jurisdiction : jurisdiction ? jurisdiction.valueCode : '';
                item.jurisdictionid = this.isEditingAgencyData ? this.editableAgencyData[0].jurisdictionid : jurisdiction ? jurisdiction.settingID : 0
                item.action = this.isEditingAgencyData ? this.editableAgencyData[0].action : 'I';
                if (!this.isEditingAgencyData) {
                    item.extagencyid = 0;
                }
                item.syncStatus = this.isEditingAgencyData ? this.editableAgencyData[0].syncStatus : 'Not yet Synced',
                requestBody.push(item);
            } else {
                const message = `Tried to create duplicate agency for ${item.jurisdiction} with Agency Code as ${item.agencyCode} and Agency Name as ${item.agencyName}. Please create the unique record.`;
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        } else {
            const message = 'Please insert valid Jurisdiction, Agency Code and Agency Name as mandatory fields.';
            if(!localValidatingErrorBody.includes(message)) {
                localValidatingErrorBody.push(message);
            }
        }
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0) {
            const response: AgencyMaintenanceResponse[] = await this.agencyMaintenanceDataService.updateAgencyMaintenanceData(requestBody, true);
            let errorInSave = false;
            let errorSummary: ToastMessage[] = [];
            if (response) {
                for (const key in response) {
                    if (response[key].agencyid) {
                        const objIndex = this.agencyMaintenanceData.findIndex(a => ((a.agencyid === response[key].agencyid)|| (a.jurisdiction === response[key].jurisdiction && a.agencycode === response[key].agencycode && a.agencyname === response[key].agencyname)));
                        if(objIndex >= 0) {
                            const backupItem = this.agencyMaintenanceData[objIndex];
                            this.mapEditableColumns(backupItem, response[key]);
                        }
                    }
                    else {
                        errorInSave = true;
                        errorSummary = [...errorSummary, { id: key, description: response[key].agencyid.toString() }];
                    }
                }
            }
            this.cellValueChangeMap = {};
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                    for (let i = 0; i < this.agencyMaintenanceData.length; i++) {
                        const updatedData = this.agencyMaintenanceData[i];
                        if (updatedData.agencyid.toString() === errorMessage.id) {
                            const backupRowIndex = this.agencyMaintenanceData.findIndex(a => a.agencyid.toString() == errorMessage.id);
                            this.mapEditableColumns(updatedData, this.agencyMaintenanceData[backupRowIndex]);
                        }
                    }
                });
            }
            else {
                this.getAgencyDropdownData();
                this.uiService.toastService.success(AgencyMaintenanceMessages.SAVE);
            }
            runInAction(() => {
                this.showLoader = false;
                this.isRefreshed = true;
                this.isEditingAgencyData = false;
                dataStore.setShowUnsavedWarningAlert(false);
            })
        }
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(AgencyMaintenanceMessages.FAILED_SUBMIT))
    async deleteAgency(): Promise<void> {
        const requestBody: any = await this.agGridService.getSelectedRows();
        if(requestBody.length > 0) {
            requestBody.forEach((item) => {
                item.updateuser = this.userName,
                item.updatedate = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')
            })
            await this.agencyMaintenanceDataService.deleteAgencyMaintenanceData(requestBody).then(() => {
                this.onSearchClick();
                this.isActivateButtonDisabled= true;
            });
        }
    }



    private addValuesInCellDropdowns(): void {
        const jurisdictionDropdownOptions = this.selectedJurisdictionName !== 'All' ? this.jurisdictionDropdownOptions.filter((item) => item.valueText  === this.selectedJurisdictionName) : this.jurisdictionDropdownOptions;
        this.agGridService.updateOptionCellEditorValues(
            jurisdictionDropdownOptions,
            'jurisdiction',
            'valueText'
        );
    }
     

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
        this.updateRow(item);
    }

    isSaveDisabled = ()  => {
        // for (const row in this.cellValueChangeMap) {
        //     for (const col in this.cellValueChangeMap[row]) {
        //         const obj = this.cellValueChangeMap[row][col];
        //         if (obj.initValue !== obj.currentValue) {
        //             return false;
        //         }
        //     }
        // }
        // return true;
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isDeleteDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Delete);
    };

    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    @action
    updateRow = (selectedRowData: any): void => {
        const updatedShopIndex = this.agencyMaintenanceData.findIndex(a => a.agencyid == selectedRowData.agencyid);
        if (!_.isEqual(this.backupAgencyMaintenanceData[updatedShopIndex], selectedRowData)) {
            selectedRowData.syncStatus = SyncStatus[0];
            this.mapEditableColumns(this.agencyMaintenanceData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.agencyid = updatedItem.agencyid;
        currentItem.agencyname = updatedItem.agencyname;
        currentItem.jurisdiction = updatedItem.jurisdiction;
        currentItem.jurisdictionid = updatedItem.jurisdictionid;
        currentItem.agencycode = updatedItem.agencycode;
        currentItem.syncStatus = updatedItem.syncStatus;
        currentItem.testSyncDate = updatedItem.testSyncDate;
        currentItem.prodSyncDate = updatedItem.prodSyncDate;
        currentItem.action = updatedItem.action;
        currentItem.updateuser = updatedItem.updateuser;
        currentItem.updatedate = updatedItem.updatedate;
    }

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    updatePushtoVmacsButtonColDef = () => {
        const pushToVmacsButtonColDef = agencyColDef.find(x => x.colId === 'PushToVMACS');
        if (pushToVmacsButtonColDef) {
            pushToVmacsButtonColDef.hide= !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)) ? true : false,
            pushToVmacsButtonColDef.suppressColumnsToolPanel= !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd))? true : false,
            pushToVmacsButtonColDef.cellRendererParams = {
                dataStore : agencyMaintenanceDataStore,
                handleClickPushVmacs : (env, data) => this.pushToVmacs(env, data),
                updatedData : this.getUpdatedRows() 
            };
        }
    }

    getUpdatedRows = () => {
        const updatedRowIDs = this.agencyMaintenanceData.filter(a => this.getUpdatedRowIDs().includes(a.agencyid.toString()));
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushToVmacs(env, data): Promise<void> {
        const action = data.action === 'U' ? 'Update' : 'Insert';

        const requestBody: any =  { 
            'http_action' : action,
            'http_record_id': data.agencyid,
            'http_cts_tablename': AgencySettingsDBTableName.TableName,
            'http_cts_mappingtablename' : AgencySettingsDBTableName.MappingTableName,
            'http_userid': this.userName,
            'http_environment': env.toUpperCase()  
        };
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response = await this.agencyMaintenanceDataService.pushToVmacs(requestBody);
            // if(response) {
                await this.onSearchClick();
                this.uiService.toastService.success(AgencyMaintenanceMessages.SYNC);
            // }
        }

    }

    @action
   isEditableJurisdiction(params) {
        if (params.data.agencyid <= 0) {
        this.addValuesInCellDropdowns();
        return true
        }
        return false;
   }

   @action
   async onSelectJurisdiction(value) {
        this.selectedJurisdictionName = value;
        const agencyCodeOptions: any = [];
        const agencyNameOptions: any = [];
        const response: AgencyMaintenanceResponse[] = await this.agencyMaintenanceDataService.getAgenciesDataWithFilter(value, this.selectedAgencyCode.label, this.selectedAgencyName.label );
        const filteredAgencyCodeArray = response.map(x => x.agencycode).filter((x , index, arr) => arr.indexOf(x) === index);
        filteredAgencyCodeArray.forEach((item) => {
            agencyCodeOptions.push({ 'value': item, 'label': item})
        })
        const filteredAgencyNameArray = response.map(x => x.agencyname).filter((x , index, arr) => arr.indexOf(x) === index);
        filteredAgencyNameArray.forEach((item) => {
            agencyNameOptions.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.agencyCodeDropdownOption = agencyCodeOptions;
            this.agencyNameDropdownOption = agencyNameOptions;
        });
   }
   
   @action
   updateAgencyCodeColumn(data: any, value: string, colName: string): void { 
    const row = data.agencyid;
        const initValue = data[colName];
        this.setValueInChangeMap(row, colName, initValue, value);
        data.agencycode = value;
        this.updateRow(data);
   }

   @Loader
    @action
    @Catch(() => errorHandler(AgencyMaintenanceMessages.FAILED_SUBMIT))
    async updateStatusFromDelToActive(): Promise<void> {
        const selectedRowIDs: any = await this.agGridService.getSelectedRows();
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        selectedRowIDs.forEach((item) => {
            if((item.agencycode !== '' && item.agencycode) && (item.agencyname !== '' && item.agencyname) && (item.jurisdiction !== '' && item.jurisdiction)) {
                const isUniqueAgency = this.agencyMaintenanceData.filter((agency) => item.jurisdiction === agency.jurisdiction && (item.agencycode === agency.agencycode || item.agencyname === agency.agencyname)).length <= 1;
                const jurisdiction = this.jurisdictionData.filter((jurisdiction) => jurisdiction.valueText === item.jurisdiction)[0];
                if(isUniqueAgency) {
                    item.updateuser =  this.userName,
                    item.updatedate = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.createUser = item.createUser ? item.createUser : this.userName,
                    item.createDate = item.createDate? item.createDate : moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
                    item.agencyid = item.agencyid ? item.agencyid : 0,
                    item.jurisdictionid = item.jurisdictionid? item.jurisdictionid : jurisdiction ? jurisdiction.settingID : 0,
                    item.action = item.action && item.action === 'D' ? 'U' : item.action
                    requestBody.push(item);
                } else {
                    const message = `Tried to create duplicate agency for ${item.jurisdiction} with Agency Code as ${item.agencycode} and Agency Name as ${item.agencyname}. Please create the unique record.`;
                    if(!localValidatingErrorBody.includes(message)) {
                        localValidatingErrorBody.push(message);
                    }
                }
            } else {
                const message = 'Please insert valid Jurisdiction, Agency Code and Agency Name as mandatory fields.';
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        });
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
            const response: AgencyMaintenanceResponse[] = await this.agencyMaintenanceDataService.updateStatusFromDelToActive(requestBody);
            let errorInSave = false;
            let errorSummary: ToastMessage[] = [];
            if (response) {
                for (const key in response) {
                    if (response[key].agencyid) {
                        const objIndex = this.agencyMaintenanceData.findIndex(a => ((a.agencyid === response[key].agencyid)|| (a.jurisdiction === response[key].jurisdiction && a.agencycode === response[key].agencycode && a.agencyname === response[key].agencyname)));
                        if(objIndex >= 0) {
                            const backupItem = this.agencyMaintenanceData[objIndex];
                            this.mapEditableColumns(backupItem, response[key]);
                        }
                    }
                    else {
                        errorInSave = true;
                        errorSummary = [...errorSummary, { id: key, description: response[key].agencyid.toString() }];
                    }
                }
            }
            this.cellValueChangeMap = {};
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                    for (let i = 0; i < this.agencyMaintenanceData.length; i++) {
                        const updatedData = this.agencyMaintenanceData[i];
                        if (updatedData.agencyid.toString() === errorMessage.id) {
                            const backupRowIndex = this.agencyMaintenanceData.findIndex(a => a.agencyid.toString() == errorMessage.id);
                            this.mapEditableColumns(updatedData, this.agencyMaintenanceData[backupRowIndex]);
                        }
                    }
                });
            }
            else {
                this.uiService.toastService.success(AgencyMaintenanceMessages.SAVE);
            }
            runInAction(() => {
                this.showLoader = false;
                this.isRefreshed = false;
                this.isActivateButtonDisabled= true;
                this.addValuesInCellDropdowns();
                dataStore.setShowUnsavedWarningAlert(false);
            })  
        }
    }

    @action 
    scrollToFocusTheNewCell() {
        this.agGridService.scrollToSelectedRow();
    }
}
