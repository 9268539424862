import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import moment from 'moment';
import { TariffLogDataService } from '../services/tariff-log-data-service';
import { BaseTariffResponse } from '../model/model';
import history from '../../../../shared/components/header-component/component/history';
// import { map, isEqual }, _ from 'lodash';
import { AccountStore } from '../../../account/stores/account-store';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import _, { isEqual } from 'lodash';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';

export class TariffLogDataStore {
    @observable baseTariffData: any[] = [];
    @observable projectTypes: any[] = [];
    @observable projectNames: any[] = [];
    @observable logEntryTypes: any[] = [];
    @observable shownProjectNames: any[] = [];
    @observable fileUploadGridData: any[] = [];
    @observable backupFileUploadGridData: any[] = [];
    @observable cellValueChangeMap: any = {};
    @observable Tabs: any[] = [];
    @observable createdUser;
    @observable selectedPipelineArea = '';
    @observable selectedBaseTariff = ''
    pipelineAreaData: any[] = [];
    @observable selectedLogEntryType = 'Tariff Filing';
    @observable selectedBaseTariffDetails : any = [];
    @observable fileUploadedSuccessfully = false;
    @observable currentAttachmentGridData: any = [];
    @observable backupAttachmentGridData: any = [];
    @observable cellValueChangeMapAttachment: any = {};
    @observable shownCancelVersion:any[] = [];
    @observable maintenanceSelectionVersionData: any[] = [];
    @observable tariffLogId: any;
    readonlyView: boolean | undefined;
    @observable isLogProcessed: any;
    

    constructor(
        public tariffLogDataService: TariffLogDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForVersionSelection: AgGridService,
        public agGridServiceForAddAttachment: AgGridService
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.selectedBaseTariff = '';
        this.tariffLogId = null;
        this.readonlyView = false;
        this.isLogProcessed = false;
        this.getLogEntryTypes();
        this.getBaseTariffData().then(() => {
            const state=history?.location?.state as {from?: string; tariffLogId?: string; baseTariff?: string; tariffNumber?: string; tariffClass?: string } || '';
            if(state.from === '/TariffDashboard') {
                this.selectedLogEntryType = state.tariffClass ? state.tariffClass : 'Tariff Filing';
                this.selectedBaseTariff = state.baseTariff ? state.baseTariff : '';

                this.tariffLogId = state.tariffLogId;
                this.getSelectedBaseTariffDetails(this.tariffLogId);
                this.createdUser = this.accountStore.displayName;
                this.readonlyView = true;
            }else if(this.baseTariffData.length > 0) {
                this.selectedBaseTariff = this.baseTariffData[0].baseTariff;
                this.getSelectedBaseTariffDetails();
            }
        });
        dataStore.setShowUnsavedWarningAlert(false);
        this.getProjectTypes();
        this.getProjectNames();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseTariffData(): Promise<void> {
        const baseTariffData = await this.tariffLogDataService.getBaseTariff();   
        this.baseTariffData = baseTariffData;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error')) 
    async getSelectedBaseTariffDetails(logId?): Promise<void> {
        const selectedBaseTariff = this.baseTariffData.find(y=>{return this.selectedBaseTariff===y.baseTariff});
        const jtId = selectedBaseTariff.jtid ? selectedBaseTariff.jtid : 0;
        const baseId = selectedBaseTariff.tariffBaseID? selectedBaseTariff.tariffBaseID : 0;
        const logEntryId = this.logEntryTypes.filter((item) => item.valueText === this.selectedLogEntryType)[0].settingId;
        const baseTariffDetails = await this.tariffLogDataService.getSelectedBaseTariffDetails(baseId, jtId, logEntryId, logId, this.isLogProcessed);
        this.selectedBaseTariffDetails = baseTariffDetails;
        this.fileUploadGridData = baseTariffDetails.length > 0 && baseTariffDetails[0].tariffLogDocuments ? baseTariffDetails[0].tariffLogDocuments as any[] : [];
        this.backupAttachmentGridData = this.fileUploadGridData;
    }
 
    @Loader
    @action
    async getProjectTypes(): Promise<void> {
        const projectTypeData = await this.tariffLogDataService.getProjectTypes();
        this.projectTypes = projectTypeData.map(x=>{return {'valueText':x.valueText, 'settingId': x.settingID}})
    }


    @Loader
    @action
    async getLogEntryTypes(): Promise<void> {
        const logEntryTypesData = await this.tariffLogDataService.getLogEntryTypes();
        this.logEntryTypes = logEntryTypesData.map(x=>{return {'valueText':x.valueText, 'settingId': x.settingID}})
    }
    
    
    @Loader
    @action
    async getProjectNames(): Promise<void> {
        const projectNamesData = await this.tariffLogDataService.getProjectNames();
        this.projectNames = projectNamesData;
    }

    
    @action
    async setShownProjectNames(values): Promise<void> {
        console.log(values);
        this.shownProjectNames= values;
    }

    @action
    async setShownCancelVersion(values): Promise<void> {
        console.log(values);
        this.shownCancelVersion= values;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async uploadFile(e): Promise<void> {
        const formData = new FormData();
        formData.append('Attachment', e.target.files[0]);
        formData.append('FileName', e.target.files[0].name);
        formData.append('Description', 'Test file for upload');
        formData.append('User', this.createdUser );
        formData.append('BaseID', this.selectedBaseTariffDetails[0]?.tariffBaseID ) ;
        const logEntryID = this.logEntryTypes.filter((item) => item.valueText === this.selectedLogEntryType)[0].settingId;
        formData.append('LogEntryID', logEntryID ) ;
        const uploadedFileData = await this.tariffLogDataService.uploadFile(formData);
        if(uploadedFileData.length > 0){
            this.uiService.toastService.success('Attachment uploaded successfully');
            // const arrUploadedFileData: any = [];
            // uploadedFileData.map(item => {
            //     arrUploadedFileData.push(item);
            // })
            const fileGridData = this.fetchFileGridData(uploadedFileData);
            const attachmentList = this.backupAttachmentGridData.concat(fileGridData);
            this.fileUploadedSuccessfully = true;
            this.fileUploadGridData = attachmentList;
            this.backupAttachmentGridData = attachmentList;
            e.target.value = '';
        }else {
            this.uiService.toastService.error('There is an error while uploading attachment');
        }      
    }

    fetchFileGridData(uploadedFileData){
        const filDataForGrid: any = [];
        uploadedFileData.map((fileData)=>{
            const data = {
                fileName: fileData.fileName,
                description: fileData.description,
                createUser: fileData.createUser,
                createDate: fileData.createDate,
                tariffLogDocID: fileData.tariffLogDocID,
                updateUser: fileData.updateUser,
                updateDate: fileData.updateDate
            }
            filDataForGrid.push(data);
        })
        return filDataForGrid;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in downloading attachment '))
    async downloadAttachment(fileID): Promise<void> {
        const response: any = await this.tariffLogDataService.downloadAttachment(fileID);
        window.open(response, '_blank', 'noreferrer');
        this.uiService.toastService.success('Attachment downloaded successfully');
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in deleting attachment '))
    async deleteAttachment(docID): Promise<void> {
        const response: any = await this.tariffLogDataService.deleteAttachment(docID);
        if(response){
            const filteredData = this.fileUploadGridData.filter((item) =>  item.tariffLogDocID !== docID);
            this.uiService.toastService.success('Attachment deleted successfully');
            this.fileUploadGridData = filteredData;
            this.backupAttachmentGridData = this.fileUploadGridData;
        }else {
            this.uiService.toastService.error('There is an error while deleting attachment');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in editing attachment '))
    async updateAttachment(): Promise<void> {
        const logEntryID = this.logEntryTypes.filter((item) => item.valueText === this.selectedLogEntryType)[0].settingId;
        const filesToUpdate: any = [];
        const updatedRowsIDS = this.getUpdatedRowIDs();
        const documents = JSON.parse(JSON.stringify(this.fileUploadGridData));
        if(updatedRowsIDS.length>0){
            updatedRowsIDS.map(docId=>{
                const updatedRow: any = documents.find(item=>item.tariffLogDocID == docId)
                filesToUpdate.push({
                    'id': updatedRow.tariffLogDocID,
                    'LogEntryID': logEntryID,
                    // 'feeDetailID': this.pmcTariffFeeDetailID,
                    'user': updatedRow.createUser,
                    'description': updatedRow.description
                })
            })
            const response: any = await this.tariffLogDataService.updateAttachment(filesToUpdate);
            if(response.length > 0){
                documents.map(item=>{
                    response.map(resItem=>{
                        if(resItem.tariffLogDocID == item.tariffLogDocID){
                            item.description = resItem.description,
                            item.updateUser = resItem.updateUser,
                            item.updateDate = resItem.updateDate
                        }
                    })
                })
                this.fileUploadGridData = documents;
                this.cellValueChangeMapAttachment = {};
                this.agGridService.getNodes()?.refreshCells();
                this.backupAttachmentGridData = documents;
                this.uiService.toastService.success('Attachment updated successfully')
            }else {
                this.uiService.toastService.error('There is an error while updating attachment');
            }
        }    
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveLogEntry(requestBody, logEntryType?): Promise<void> {
        if(this.fileUploadedSuccessfully) {
            if(requestBody.length !== 0) {
                const response: any[] = await this.tariffLogDataService.saveLogEntry(requestBody, logEntryType);
                if(response){
                    this.fileUploadedSuccessfully = false;
                    this.agGridServiceForVersionSelection.deselectAllRows();
                    this.cellValueChangeMap = {};
                    this.getSelectedBaseTariffDetails();
                    this.uiService.toastService.success('Log Entry Created Successfully')
                    dataStore.setShowUnsavedWarningAlert(false);
                } 
            } 
        } else {
            errorHandler('Referenced document is not yet uploaded. Please upload the document before saving.')
        }       
    }

     
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async saveVMACSSetup(requestBody): Promise<void> {
       if(requestBody.length !== 0) {
           const response: any[] = await this.tariffLogDataService.saveVMACSSetup(requestBody);
           if(response){
                this.uiService.toastService.success('VMACS Entry Created Successfully')
                dataStore.setShowUnsavedWarningAlert(false);
            }
        } 
    }    
    
    isEditDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isEditVMACSSetupDisabled = () => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLogVMACSSetup)
    }

   

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    getMaintenanceGridUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMapAttachment) {
            for (const col in this.cellValueChangeMapAttachment[row]) {
                const obj = this.cellValueChangeMapAttachment[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateRowAttachment = (selectedRowData: any): void => {
        const updatedShopIndex = this.fileUploadGridData.findIndex(a => a.tariffLogDocID == selectedRowData.tariffLogDocID);
        if (!_.isEqual(this.backupAttachmentGridData[updatedShopIndex], selectedRowData)) {
            this.mapAttachmentEditableColumns(this.fileUploadGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapAttachmentEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.tariffLogDocID = updatedItem.tariffLogDocID;
        currentItem.description = updatedItem.description;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
    }

    isCancelsFieldDisabled = (newTariffFiling) => {
        if(this.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase()) {
            return this.isEditDisabled() || !newTariffFiling
        }
        return this.isEditDisabled() 
    }
}
