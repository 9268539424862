import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { shipperLossGainMaintenanceDataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';

const filterParams = {
    filterOptions: ['contains', 'notContains', 'equals', 'notEqual'],
    textFormatter: (r) => {
        if (r == null) return null;
        return r
        .toLowerCase()
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y');
    },
    debounceMs: 200,
    suppressAndOrCondition: true,
  };

  const dateFilterParams = {
    filterOptions: ['equals', 'notEqual', 'greaterThan', 'lessThan'],

      // provide comparator function
      comparator: function (filterLocalDateAtMidnight, cellValue) {

          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateParts  = cellValue.split('/');
          const day = Number(dateParts[2]);
          const month = Number(dateParts[1]) - 1;
          const year = Number(dateParts[0]);
          const cellDate = new Date(day, month, year);

          // Now that both parameters are Date objects, we can compare
          if (cellDate < filterLocalDateAtMidnight) {
              return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
              return 1;
          } else {
              return 0;
          }
      },
    debounceMs: 200,
    suppressAndOrCondition: true,
  }

export const shipperLossGainColDef: ColDef[] = [
    {   
        headerName: 'Select',
        field: 'select', 
        colId:'Select',
        checkboxSelection:  true, 
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        width: 40, 
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true
    },
    { 
        headerName: 'L/G Calculation Group', 
        field: 'shipperLGName',
        colId: 'shipperLGName',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        minWidth: 100, 
        valueGetter: params => {
            if (!params.data || !params.data['shipperLGName']) {
                return '';
            } else {
                return params.data['shipperLGName'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Base System', 
        field: 'plName',
        colId: 'plName',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        minWidth: 100, 
        valueGetter: params => {
            if (!params.data || !params.data['plName']) {
                return '';
            } else {
                return params.data['plName'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'System Grade', 
        field: 'grade',
        colId: 'grade',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true, 
        minWidth: 100,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            const gradeData = params.data && params.data.grade ? shipperLossGainMaintenanceDataStore.allGradeData.find(x => x.gradeCode == params.data['grade'])?.gradeName : '';
            return gradeData;
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Rules REGs Tariff', 
        field: 'rulesregTariff', 
        colId: 'rulesregTariff',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true,
        minWidth: 100, 
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data || !params.data['rulesregTariff']) {
                return '';
            } else {
                return params.data['rulesregTariff'];
            }
        },
        suppressSizeToFit: true
    },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser', 
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true, 
        editable: false,
        width: 130,
         cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['createUser']) {
                return '';
            } else {
                return params.data['createUser'];
            }
        }
    },
    { 
        headerName: 'Created Date',
        field: 'createDate',
        colId: 'createDate',  
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        sortable: true,
        editable: false,
        width: 120,
         cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['createDate']) {
                return '';
            } else {
                return moment(params.data['createDate']).format(AppConstant.DATE_TIME_FORMAT);
            }

                   } 
    },
    { 
        headerName: 'Updated User', 
        field: 'updateUser',
        colId: 'updateUser',   
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        sortable: true,
        editable: false,
        width: 120,
         cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['updateUser']) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        }
    },
    { 
        headerName: 'Updated Date', 
        field: 'updateDate', 
        colId: 'updateDate',   
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        sortable: true, 
        editable: false,
        width: 130,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['updateDate']) {
                return '';
            } else {
                // return momenttimezone
                // .tz(moment.utc(params.data['updatedate']), AppConstant.CST_TIME_FORMATTER)
                // .format(AppConstant.DATE_TIME_FORMAT);

                return moment(params.data['updateDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        }  
    },
   
    { 
        headerName: 'Sync Status', 
        field: 'syncStatus', 
        colId: 'syncStatus',
        filter: 'agTextColumnFilter',
        filterParams: filterParams, 
        width: 130,
         cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['syncStatus']) {
                return '';
            } else {
                return params.data['syncStatus'];
            }
        }
    },
    { 
        headerName: 'VMACS Test Sync Date', 
        field: 'testSyncDate', 
        colId: 'testSyncDate',
        editable: false,
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        width: 145,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['testSyncDate']) {
                return '';
            } else {
                  return moment(params.data['testSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        suppressSizeToFit: true  
    },
    { 
        headerName: 'VMACS Prod Sync Date', 
        field: 'prodSyncDate', 
        colId: 'prodSyncDate',
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        editable: false,
        width: 145,
        cellClass: (params) => {return params.data && params.data.action && params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        valueGetter: params => {
            if (!params.data || !params.data['prodSyncDate']) {
                return '';
            } else {
                return moment(params.data['prodSyncDate']).format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        suppressSizeToFit: true  
    }
];

