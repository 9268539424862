import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './add-pipeline.scss';
import {
    ColDef,
    RowSelectedEvent
} from 'ag-grid-community';
import { EditPipelineMaintenanceDataStore } from '../stores/edit-pipeline-maintenance-data-store';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker'
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Portal } from 'react-overlays';
import { editPipelineMaintenanceDataStore } from '../stores';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { PipelineMaintenanceDataStore } from '../stores/pipeline-maintenance-data-store';
import history from '../../../../shared/components/header-component/component/history';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';

/* eslint-disable */
interface PipelineMaintenanceContainerProps {
     editPipelineMaintenanceDataStore: EditPipelineMaintenanceDataStore;
     pipelineMaintenanceDataStore: PipelineMaintenanceDataStore
}

interface PipelineMaintenanceContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowIdEntities: number,
    rowSelectedOwners: boolean
    rowSelectedEntities: boolean, 
}

@inject('editPipelineMaintenanceDataStore', 'pipelineMaintenanceDataStore')
@observer
export class EditPipelineMaintenanceContainer extends Component<PipelineMaintenanceContainerProps, PipelineMaintenanceContainerState> {

    constructor(props: PipelineMaintenanceContainerProps | Readonly<PipelineMaintenanceContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowIdEntities: 0,
            rowSelectedOwners: false, 
            rowSelectedEntities: false
        };
        dataStore.setModuleName('EditPipelineMaintenance')
    }

    componentDidMount() {
      const {editPipelineMaintenanceDataStore, pipelineMaintenanceDataStore} = this.props;
      if(!pipelineMaintenanceDataStore.pipelineIdForEditing){
        history.push('/PipelineMaintenance');
      }else{
        editPipelineMaintenanceDataStore.init(pipelineMaintenanceDataStore.pipelineIdForEditing);
      }
    }

    componentWillUnmount() {
        const {editPipelineMaintenanceDataStore} = this.props;
        editPipelineMaintenanceDataStore.reset();
    }

    CalendarContainer = ({ children }) => {
        const el = document.getElementById("calendar-portal");
      
        return <Portal container={el}>{children}</Portal>;
    };

    SimpleCellRenderer =  (props) => (
        <span
          style={{
            backgroundColor: props.node.group ? 'coral' : 'lightgreen',
            padding: 2,
          }}
        >
          Hi + {props.value}
        </span>
    ); 


    onChangeDate = (params, value, key) => {
        const {editPipelineMaintenanceDataStore} = this.props;
        if(key==='effectiveDate'||key==='endDate'){
            var index = editPipelineMaintenanceDataStore.ownershipData.findIndex(x=>x.rowId===params.data.rowId)
            editPipelineMaintenanceDataStore.setOwnershipData(this.dateToEpoch2(value), index, key);
        }else {
            var index = editPipelineMaintenanceDataStore.entitiesData.findIndex(x=>x.rowIdEntities===params.data.rowIdEntities)
            editPipelineMaintenanceDataStore.setEntitiesData(this.dateToEpoch2(value), index, key);
        }
    }
    
    pipelineMaintenanceGridColDef: ColDef[] = [
        {   
            headerName: 'Select',
            field: 'select', 
            checkboxSelection: (params) => {return params.data && !params.data.existing ? true: false}, 
            cellRenderer: 'checkboxRenderer', 
            width: 40, 
            headerCheckboxSelection: false, 
            headerCheckboxSelectionFilteredOnly: true,
            suppressSizeToFit: true, 
            cellStyle: () => {return {padding: '10px'}}
          },
          { 
            headerName: 'Owners*', 
            field: 'owner',
            tooltipField: 'owner', 
            filter: true, 
            sortable: true,
            editable: (params) => {return !params.data?.existing && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            // editable: true, 
            cellEditor: 'agSelectCellEditor', 
            minWidth: 150,
            singleClickEdit: true,
            // suppressNavigable: true, 
            cellClass: (params) => {return params.data?.existing ? '' : dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) && params.data ?  'cell-editable' : ''},
            cellEditorParams: (params) => {
                const data = this.props.editPipelineMaintenanceDataStore.addValuesInCellDropdownsOwners(params)?.map(x=>x.customerName);
                return {
                    values: params.data?.owner? [params.data?.owner,...data as []] : data
                };
            },
            flex: 1
        },
        { 
            headerName: 'Ownership*', 
            field: 'ownership',
            tooltipField: 'ownership', 
            singleClickEdit: true, 
            filter: true, 
            sortable: true,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            cellClass: (params) => {return params.data && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) ?  'cell-editable' : ''},
            aggFunc: params => {
                let total = 0;
                params.values.forEach(value => total += Number(value)||0);
                return 'Total Ownership: ' +  (total * 100).toFixed(2) + '%';
            },
            minWidth: 200,
            flex: 1,
            valueSetter: (params) => {
                if(Number(params.newValue)>100){
                    params.data.ownership=1
                    return true 
                 }else if(Number(params.newValue)>1){
                    params.data.ownership=params.newValue/100
                    return true
                } else {
                    if(isNaN(params.newValue)){
                        return false
                    }
                    params.data.ownership=params.newValue
                    return true
                }
            },
        },
        { 
            headerName: 'Effective Date*', 
            field: 'effectiveDate',
            editable: false,
            rowGroup: true,
            showRowGroup: true,
            cellClass: (params) => {return params.data?.existing ? '' : dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) && params.data ?  'cell-editable' : ''}, 
            // cellClass: (params) => {return params.data ?  'cell-editable' : ''},
            valueFormatter: ({ value, data }) => '',
            cellRenderer: (params) => {
                return (params.data ? (<DatePicker
                      popperClassName='ag-custom-component-popup'
                      selected={params.data.effectiveDate? params.data.effectiveDate: ''}
                      onChange={(value)=>{this.onChangeDate(params, value, 'effectiveDate')}}
                      popperContainer={this.CalendarContainer}
                      popperPlacement='top-start'
                      className='datePickerGridInput'
                      readOnly= {params.data?.existing && !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)}
                      />) : '') 
            },
            flex: 1, 
            minWidth: 130
        },
        { 
            headerName: 'End Date', 
            field: 'endDate',
            editable: false,
            cellClass: (params) => {return params.data && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) ?  'cell-editable' : ''},
            cellRenderer: (params) => {
                return (params.data &&  (<DatePicker
                      popperClassName='ag-custom-component-popup'
                      selected={params.value}
                      onChange={(value)=>{this.onChangeDate(params, value, 'endDate')}}
                      popperContainer={this.CalendarContainer}
                      popperPlacement='top-start'
                      className='datePickerGridInput'
                      readOnly= {!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)}
                      />)) || params.value 
            },
            flex: 1, 
            minWidth: 130
        },
        { 
            headerName: 'Transfer to Data Warehouse*', 
            field: 'transfer',
            tooltipField: 'transfer',
            cellEditor: 'agSelectCellEditor', 
            cellClass: (params) => {return params.data && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) ?  'cell-editable' : ''},
            cellEditorParams: {
                values: ['Y', 'N']
            },
            filter: true, 
            sortable: true,
            minWidth: 150,
            singleClickEdit: true,
            flex: 1,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
        },
        { 
            headerName: 'Distribute L/G To*', 
            field: 'distributeLG',
            tooltipField: 'distributeLG', 
            singleClickEdit: true,
            filter: true, 
            sortable: true,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            cellEditor: 'agSelectCellEditor', 
            minWidth: 150,
            flex: 1,
            cellClass: (params) => {return this.props.editPipelineMaintenanceDataStore.isEditableSettingNameDistribute(params) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) && params.data ?  'cell-editable' : ''}, 
        },
  ];
  
  legalEntityGrid: ColDef[] = [
     {   
        headerName: 'Select',
        field: 'select', 
        checkboxSelection: (params) => {return params.data && !params.data.existing ? true: false}, 
        cellRenderer: 'checkboxRenderer', 
        width: 40, 
        headerCheckboxSelection: false, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true, 
      },
      { 
        headerName: 'Start Cycle*', 
        field: 'startCycle',
        editable: false,
        cellClass: (params) => {return params.data && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) ?  'cell-editable' : ''},
        cellRenderer: (params) => {
            return (params.data &&  (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.data.startCycle}
                onChange={(value)=>{this.onChangeDate(params, value, 'startCycle')}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                className='datePickerGridInput'
                readOnly= {!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)}
                />)) || params.value 
        },
        sort: 'desc',
        minWidth: 130,
        flex:1
    },
    { 
        headerName: 'End Cycle', 
        field: 'endCycle',
        editable: false,
        cellClass: (params) => {return params.data && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) ?  'cell-editable' : ''},
        cellRenderer: (params) => {
            return (params.data &&  (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.data.endCycle}
                onChange={(value)=>{this.onChangeDate(params, value, 'endCycle')}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                className='datePickerGridInput'
                readOnly= {!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)}
                />)) || params.value 
        },
        minWidth: 130,
        flex: 1
    },
    { 
            headerName: 'State Code*', 
            field: 'stateCode',
            tooltipField: 'stateCode', 
            cellClass: (params) => {return params.data && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) ?  'cell-editable' : ''},
            filter: true, 
            sortable: true,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            width: 130,
            singleClickEdit: true,
            suppressSizeToFit: true
        },
        { 
            headerName: 'GL Location*', 
            field: 'glLocation',
            cellClass: (params) => {return params.data && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) ?  'cell-editable' : ''},
            tooltipField: 'glLocation', 
            filter: true, 
            sortable: true,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            width: 110,
            singleClickEdit: true,
            suppressSizeToFit: true
        },
        { 
            headerName: 'Legal Entity*', 
            field: 'legalEntity',
            tooltipField: 'legalEntity', 
            cellClass: (params) => {return this.props.editPipelineMaintenanceDataStore.isEditableSettingNameLE(params) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) && params.data ?  'cell-editable' : ''}, 
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            filter: true, 
            sortable: true,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            minWidth: 135,
            suppressSizeToFit: false,
            flex: 2
        },
        { 
            headerName: 'Line of Business*', 
            field: 'lineOfBusiness',
            cellClass: (params) => {return this.props.editPipelineMaintenanceDataStore.isEditableSettingNameLOB(params) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) && params.data ?  'cell-editable' : ''}, 
            tooltipField: 'endDate',
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            minWidth: 162,
            suppressSizeToFit: false,
            flex: 2
        },
        { 
            headerName: 'Joint Interest Billing*', 
            field: 'jointInterestBilling',
            tooltipField: 'jointInterestBilling', 
            cellEditor: 'agSelectCellEditor',
            singleClickEdit: true,
            cellClass: (params) => {return this.props.editPipelineMaintenanceDataStore.isEditableSettingNameJIB(params) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance) && params.data ?  'cell-editable' : ''}, 
            filter: true, 
            sortable: true,
            editable: (params) => {return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)},
            minWidth: 175,
            suppressSizeToFit: false,
            flex: 2
        },
        { 
            headerName: 'Created User', 
            field: 'createUser',
            tooltipField: 'createUser', 
            filter: true, 
            sortable: true,
            editable: false,
            width: 130
        },
        { 
            headerName: 'Created Date', 
            field: 'createDate',
            tooltipField: 'createDate', 
            filter: true, 
            sortable: true,
            editable: false,
            width: 130,
            valueGetter: params => {
                if (params.data['createDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            } 
        },
        { 
            headerName: 'Updated User', 
            field: 'updateUser',
            tooltipField: 'updateUser', 
            filter: true, 
            sortable: true,
            editable: false,
            width: 130
        },
        { 
            headerName: 'Updated Date', 
            field: 'updateDate',
            tooltipField: 'updateDate', 
            filter: true, 
            sortable: true,
            editable: false,
            width: 130,
            valueGetter: params => {
                if (params.data['updateDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            } 
        }
    ];

    relatedAreasGrid: ColDef[] = [
        { 
                headerName: 'Pipeline Type', 
                field: 'pipelineType',
                tooltipField: 'pipelineType', 
                filter: true, 
                sortable: true,
                editable: true,
                minWidth: 550,
                flex: 1
            },
            { 
                headerName: 'Pipeline Name', 
                field: 'pipelineName',
                tooltipField: 'pipelineName', 
                filter: true, 
                sortable: true,
                editable: true,
                minWidth: 550,
                flex: 1
            }
        ];
  

    getColDef() {
        return this.pipelineMaintenanceGridColDef
    }

    getColDefLegalEntity() {
        return this.legalEntityGrid 
    }

    getColDefRelatedAreas() {
        return this.relatedAreasGrid
    }

    getGridConfigOwnerShip(gridId) {
        const {
            editPipelineMaintenanceDataStore: { agGridServiceOwners }
        } = this.props;
        const { editPipelineMaintenanceDataStore } = this.props;
        const pMGridParams: any = {
          rowData: editPipelineMaintenanceDataStore.OwnershipData,
          columnDefs:this.getColDef(), // onCellEditingStopped: this.onCellEditingStopped,
          groupDisplayType: 'groupRows',
          groupIncludeFooter: true,
          onRowSelected: this.onRowSelectedOwners,
          context: agGridServiceOwners
          };
        return agGridServiceOwners.getGridConfig(pMGridParams, gridId);
    }

    onRowSelectedOwners = (event: RowSelectedEvent): void => {
        this.setState({...this.state, rowSelectedOwners: (event.api.getSelectedNodes().length>0)});
    }

    getGridConfigLegalEntity(gridId) {
        const {
            editPipelineMaintenanceDataStore: { agGridServiceEntities }
        } = this.props;
        const { editPipelineMaintenanceDataStore } = this.props;
        const pMGridParams: any = {
          rowData: editPipelineMaintenanceDataStore.EntitiesData,
          columnDefs:this.getColDefLegalEntity(), // onCellEditingStopped: this.onCellEditingStopped,
          onRowSelected: this.onRowSelectedEntities,
          context: agGridServiceEntities
        };
        return agGridServiceEntities.getGridConfig(pMGridParams, gridId);
    }

    
    onRowSelectedEntities = (event: RowSelectedEvent): void => {
        this.setState({...this.state, rowSelectedEntities: (event.api.getSelectedNodes().length>0)});
    }


    getGridConfigRelatedAreas (gridId) {
        const {
            editPipelineMaintenanceDataStore: { agGridService }
        } = this.props;
        const { editPipelineMaintenanceDataStore } = this.props;
        const pMGridParams: any = {
          rowData: editPipelineMaintenanceDataStore.relatedAreasData,
          columnDefs:this.getColDefRelatedAreas(), // onCellEditingStopped: this.onCellEditingStopped
          };
        return agGridService.getGridConfig(pMGridParams, gridId);
    }

    changeFormData(value, key) {
        editPipelineMaintenanceDataStore.changeFormData(value, key)
    }

    savePipeline() {
        editPipelineMaintenanceDataStore.savePipeline();
    }

    onAddRowOwnership() {
        const rowData = [{
            createdUser: editPipelineMaintenanceDataStore.createdUser,
            effectiveDate: this.dateToEpoch2(editPipelineMaintenanceDataStore.OwnershipData.length===0?
                new Date():new Date(editPipelineMaintenanceDataStore.OwnershipData.sort((a,b)=>b.effectiveDate-a.effectiveDate)[0]?.effectiveDate)),
            rowId: editPipelineMaintenanceDataStore.OwnershipData.sort((a,b)=>b.rowId-a.rowId)[0]?.rowId + 1 || 0
            },...editPipelineMaintenanceDataStore.OwnershipData
           ];      
          editPipelineMaintenanceDataStore.setOwnershipGridData(rowData);
          this.setState({...this.state, rowId: this.state.rowId+1})
        //   this.props.baseTariffDataStore.addValuesInCellDropdowns();
    }
    
    dateToEpoch2(thedate) {
        return thedate && thedate.setHours(0,0,0,0) || "";
    }

    onSaveOwnership() {
        editPipelineMaintenanceDataStore.saveOwnership();
    }

    onAddRowEntities() {
        const rowData = [{
            createUser: editPipelineMaintenanceDataStore.createdUser,
            createDate: null,
            updateDate: null,
            rowIdEntities: editPipelineMaintenanceDataStore.EntitiesData.sort((a,b)=>b.rowIdEntities-a.rowIdEntities)[0]?.rowIdEntities + 1 || 0
            },...editPipelineMaintenanceDataStore.EntitiesData
           ];    
          editPipelineMaintenanceDataStore.setEntitiesGridData(rowData);
          this.setState({...this.state, rowIdEntities: this.state.rowIdEntities+1})
        //   this.props.baseTariffDataStore.addValuesInCellDropdowns();
    }

    onSaveEntities() {
        editPipelineMaintenanceDataStore.savePipelineEntities();
    }

    deleteItemOwners = async () => {
        const { editPipelineMaintenanceDataStore } = this.props;
        const requestBody: any = await editPipelineMaintenanceDataStore.agGridServiceOwners.getSelectedRows();
        if(requestBody.length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => this.filterRowsOwners(requestBody),
              'Delete',
              'Are you sure you want to delete the selected record/s?'
          );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        }
      }
    
      filterRowsOwners = async (requestBody) => {
        await editPipelineMaintenanceDataStore.setOwnershipGridData(editPipelineMaintenanceDataStore.OwnershipData.filter(
          x=>requestBody.every(y=>x.rowId!==y.rowId)))
        const rows: any = await (editPipelineMaintenanceDataStore.agGridServiceOwners.getSelectedRows());
        if(rows.length === 0) {
            this.setState({...this.state, rowSelectedOwners: false})
        }
      }  

      
    deleteItemEntities = async () => {
        const { editPipelineMaintenanceDataStore } = this.props;
        const requestBody: any = await editPipelineMaintenanceDataStore.agGridServiceEntities.getSelectedRows();
        if(requestBody.length > 0) {
            console.log(requestBody);
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => this.filterRowsEntities(requestBody),
              'Delete',
              'Are you sure you want to delete the selected record/s?'
          );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        }
      }
    
      filterRowsEntities = async (requestBody) => {
        await editPipelineMaintenanceDataStore.setEntitiesGridData(editPipelineMaintenanceDataStore.EntitiesData.filter(
          x=>requestBody.every(y=>x.rowIdEntities!==y.rowIdEntities)))
        const rows: any = await (editPipelineMaintenanceDataStore.agGridServiceEntities.getSelectedRows());
        if(rows.length === 0) {
            this.setState({...this.state, rowSelectedEntities: false})
        }
      }  

      deletePipeline = () => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => {
                editPipelineMaintenanceDataStore.deletePipeline()
            },
            'Delete',
            'Are you sure you want to delete the pipeline?'
        );
      }

      editEnabled = () => {
        return dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.PipelineSystemMaintenance)
      }

      pushToVmacs = (env) => {
        const { pipelineMaintenanceDataStore } = this.props;
        const displayEnv = env === 'Prod' ? 'Production' : env;
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () =>  editPipelineMaintenanceDataStore.pushToVmacs(env, pipelineMaintenanceDataStore),
            'Sync',
            `Are you sure you want to push the selected data to the ${displayEnv} Environment?`
        );
      }

    render(): React.ReactNode {
        const { pipelineMaintenanceDataStore, editPipelineMaintenanceDataStore } = this.props;
        return (
        <div className='AddPipelineBox'>
            <Container fluid>
            <div className='boxes'>
                    <div className='screenHeader'>
                      <h2 className='screen_title'> {this.editEnabled() && 'Edit '}Pipeline Detail</h2>
                    </div>
                  <div className='selectContainer'>
                  <Row className='physicalPipeline'>
                    <Col style={{'marginTop':'10px'}}>   
                        <label className="standard_label_style">Physical Pipeline</label>
                        <select className="select" disabled={!this.editEnabled()} value={'ADVANTAGE PL'} onChange={(e) => {}}>
                        {editPipelineMaintenanceDataStore.dropdownPhysicalPipeline.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                        </select>
                        <div style={{marginLeft:'20px',"padding":'5px'}}>
                        <label className="standard_label_style">
                            <input 
                                type="checkbox"
                                checked={false}
                                disabled={!this.editEnabled()}
                                onChange = {()=>{}}
                            /> Map
                        </label>
                        </div>
                    </Col>
                    <Col style={{'marginTop':'10px'}}>   
                        <label className="standard_label_style">Daily Close Time</label>
                        <input 
                            type="text" 
                            className="search_text_field input"
                            readOnly
                            value={editPipelineMaintenanceDataStore.formData?.dailyCloseTime || '07:00'}
                            />
                    </Col>
                    </Row>
                    <Row className="baseSystem">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Base System*</label>
                            <Row style ={{marginTop : '20px'}}>
                            <input 
                            type="text" 
                            className="search_text_field input"
                            readOnly={!this.editEnabled()} 
                            onChange = {(e) => this.changeFormData(e.target.value, 'plname')}
                            value={editPipelineMaintenanceDataStore.formData?.plname}
                            />
                            </Row>
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Nomination Area</label>
                            <Row style ={{marginTop : '20px'}}><input 
                            type="text" 
                            className="search_text_field input"
                            onChange = {(e) => {}}
                            value={editPipelineMaintenanceDataStore.formData?.plname?editPipelineMaintenanceDataStore.formData?.plname + ' - N':''}
                            readOnly
                            />
                            </Row>
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Tariff Area</label>
                            <Row style ={{marginTop : '20px'}}><input 
                            type="text" 
                            className="search_text_field input"
                            onChange = {(e) => {}}
                            value={editPipelineMaintenanceDataStore.formData?.plname?editPipelineMaintenanceDataStore.formData?.plname + ' - T':''}
                            readOnly
                            />
                            </Row>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <hr className='dateView'></hr>
                    <div className="row justify-content-center dates">
                            <Col className="colWidth" style={{'marginTop':'10px'}}>
                                <label className="standard_label_style">Effective Date*</label>
                                <ReactDatePicker readOnly={!this.editEnabled()} onDateChange={(e)=>this.changeFormData(e, 'effectiveDate')} selectedDate={editPipelineMaintenanceDataStore.formData?.effectiveDate}/>
                            </Col>
                            <Col className="colWidth" style={{'marginTop':'10px'}}>
                                <label className="standard_label_style">End Date</label>
                                <ReactDatePicker readOnly={!this.editEnabled()} onDateChange={(e)=>this.changeFormData(e, 'endDate')} selectedDate={editPipelineMaintenanceDataStore.formData?.endDate}/>
                                {/* <div style={{marginLeft:'20px'}}>
                                    <input 
                                        type="checkbox"
                                        checked={true}
                                        onChange = {()=>{}}
                                    /> Operated
                                </div> */}
                            
                            </Col>
                            <Col style={{'marginTop':'10px', 'minWidth':'130px'}}>
                            <label style={{"padding":'5px'}} className="standard_label_style">
                                <input type="checkbox" disabled={!this.editEnabled()} onChange={(e)=>this.changeFormData(!editPipelineMaintenanceDataStore.formData?.operatedInd, 'operatedInd')} checked={editPipelineMaintenanceDataStore.formData?.operatedInd}/>
                                 &nbsp; Operated
                            </label>
                            </Col>
                        </div>
                        <div>
                            <div className="rightFlexSave col">
                                <CustomButton type={CustomButtonType.Submit} disabled={!this.editEnabled()} onClick={()=>{this.savePipeline()}} >
                                    Save
                                </CustomButton>
                            </div>
                        </div>
                    <div className='flexDetails'>
                        <div className='inner'>
                            <div className="rotatedText">
                            Quality Bank 
                            </div>
                            <div style={{'width':'100%'}}>
                                <Row>   
                                    <Col>
                                        <label className="standard_label_style">QB Invoice Freq</label>
                                        <select className="select" disabled={!this.editEnabled()} value={editPipelineMaintenanceDataStore.formData?.qbInvoiceFreq} onChange={(e) => this.changeFormData(e.target.value, 'qbInvoiceFreq')}>
                                        {editPipelineMaintenanceDataStore.qbInvoiceFrequencyDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">QB Rate Basis</label>
                                        <select className="select" disabled={!this.editEnabled()} value={editPipelineMaintenanceDataStore.formData?.qbRateBasis} onChange={(e) => this.changeFormData(e.target.value, 'qbRateBasis')}>
                                        {editPipelineMaintenanceDataStore.qbRateBasisDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{justifyContent: 'center'}}>
                                        <label className="standard_label_style" style={{"padding":'5px', 'width': '150px'}}>
                                            <input type="checkbox" disabled={!this.editEnabled()} onChange={(e)=>this.changeFormData(!editPipelineMaintenanceDataStore.formData?.gravityBankInd, 'gravityBankInd')} checked={editPipelineMaintenanceDataStore.formData?.gravityBankInd}/>
                                            &nbsp; Gravity Bank
                                        </label>
                                        <label className="standard_label_style" style={{"padding":'5px', 'width': '150px'}}>
                                            <input type="checkbox" disabled={!this.editEnabled()} onChange={(e)=>this.changeFormData(!editPipelineMaintenanceDataStore.formData?.sulphurBankInd, 'sulphurBankInd')} checked={editPipelineMaintenanceDataStore.formData?.sulphurBankInd}/>
                                            &nbsp; Sulphur Bank
                                        </label>
                                    </Col>
                                </Row>
                            </div>    
                        </div>
                        <div className='inner'>
                            <div className="rotatedText">
                            Loss Gain & PLA
                            </div>
                            <div style={{'width':'100%'}}>
                                <Row>   
                                    <Col>
                                        <label className="standard_label_style">Loss/Gain</label>
                                        <select className="select" disabled={!this.editEnabled()} value={editPipelineMaintenanceDataStore.formData?.lossGain} onChange={(e) => this.changeFormData(e.target.value, 'lossGain')}>
                                        {editPipelineMaintenanceDataStore.lossGainDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">PLA Basis</label>
                                        <select className="select" disabled={!this.editEnabled()} value={editPipelineMaintenanceDataStore.formData?.plaBasis} onChange={(e) => this.changeFormData(e.target.value, 'plaBasis')}>
                                        {editPipelineMaintenanceDataStore.plaBasisDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <label className="standard_label_style">Allocation Basis</label>
                                        <select className="select" disabled={!this.editEnabled()} value={editPipelineMaintenanceDataStore.formData?.allocationBasis} onChange={(e) => this.changeFormData(e.target.value, 'allocationBasis')}>
                                        {editPipelineMaintenanceDataStore.allocationBasisDropdownOptions.map(option => (
                                            <option key={option.valueCode} value={option.valueText}>
                                                {option.valueText}
                                            </option>
                                        ))}
                                        </select>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className='screenHeader'>
                        <h2 className='screen_title'> Owners/L/G System Owners</h2>
                    </div>
                    <div className='selectContainer'>
                    <Row>
                        <div className="rightFlex">
                            <CustomButton type={CustomButtonType.Submit} disabled={!this.state.rowSelectedOwners} onClick={()=>this.deleteItemOwners()}>
                            Delete Ownership
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={!this.editEnabled()} onClick={()=>this.onAddRowOwnership()}>
                            Add Ownership
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={!editPipelineMaintenanceDataStore.OwnershipData.length||!this.editEnabled()} onClick={()=>this.onSaveOwnership()}>
                            Save
                            </CustomButton>
                        </div>
                    </Row>
                    <Row>
                        <AgGridComponent gridConfig={this.getGridConfigOwnerShip('Edit Pipeline Ownership')}/>
                    </Row> 
                    <Row>
                        <div className="rightFlex">
                            <CustomButton type={CustomButtonType.Submit} disabled={!this.state.rowSelectedEntities} onClick={()=>this.deleteItemEntities()}>
                            Delete Legal Entity
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={!this.editEnabled()} onClick={()=>this.onAddRowEntities()}>
                            Add Legal Entity
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={!editPipelineMaintenanceDataStore.EntitiesData.length||!this.editEnabled()} onClick={()=>this.onSaveEntities()}>
                            Save
                            </CustomButton>
                        </div>
                    </Row>
                    <Row>
                        <AgGridComponent gridConfig={this.getGridConfigLegalEntity('Edit Pipeline Legal Entity')}/>
                    </Row>
                    </div>
                    <div className='screenHeader'>
                        <h2 className='screen_title'> Related Area(s)</h2>
                    </div>
                    <div className='selectContainer'>
                    <Row style={{'marginTop':'10px'}}>
                        <AgGridComponent gridConfig={this.getGridConfigRelatedAreas('Edit Pipeline Related Areas')}/>
                    </Row>
                    </div>
                    <Row>
                        <div className="rightFlexBottom">
                            {(dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)) && (
                            <>
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.pushToVmacs('Test')}} disabled={(editPipelineMaintenanceDataStore.syncedToTest||pipelineMaintenanceDataStore.vmacsStatus==='Synced to TEST'||pipelineMaintenanceDataStore.vmacsStatus==='Synced to PROD' || !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest))&&!editPipelineMaintenanceDataStore.saveChangesMade}>
                            Push to Test
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.pushToVmacs('Prod')}} disabled={editPipelineMaintenanceDataStore.syncedToProd||pipelineMaintenanceDataStore.vmacsStatus==='Synced to PROD'||pipelineMaintenanceDataStore.vmacsStatus==='Not yet Synced' ||  !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) ||[null,'',undefined].includes(pipelineMaintenanceDataStore.vmacsStatus)||editPipelineMaintenanceDataStore.saveChangesMade}>
                            Push to Prod
                            </CustomButton>
                            </>)}
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{history.push('/PipelineMaintenance', { from: 'EditPipeline'})}} disabled= {false}>
                            Cancel
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.deletePipeline()}} disabled={!this.editEnabled()}>
                            Delete Pipeline
                            </CustomButton>
                        </div>
                    </Row>
                </div>
          </div>
        </Container>
       </div>
    );
}}
