import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { AddEditFilingEntityMaintenanceStore } from '../stores/add-edit-filing-entity-maintenance-data-store';
import {
    GridOptions,
    ColDef,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/filingEntity.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
// import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { addEditFilingEntityMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import moment from 'moment';
import { ReactSelect } from 'common/components/select/select';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';
import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays';

interface CalendarContainerProps {
    children : any
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface AddEditFilingEntityMaintenanceProps {
    addEditFilingEntityMaintenanceDataStore: AddEditFilingEntityMaintenanceStore;
}

interface AddEditFilingEntityMaintenanceState {
    search: string;
    lastAddedRemarksID: number;
    lastAddedRelationshipID: number;
}

@inject('addEditFilingEntityMaintenanceDataStore')
@observer
export class AddEditFilingEntityMaintenance extends Component<AddEditFilingEntityMaintenanceProps, AddEditFilingEntityMaintenanceState> {
    constructor(props: AddEditFilingEntityMaintenanceProps | Readonly<AddEditFilingEntityMaintenanceProps>) {
        super(props);
        this.state = {
            search: '',
            lastAddedRemarksID: 0,
            lastAddedRelationshipID: 0
        };
        dataStore.setModuleName('Filing Entity Maintenance')
    }
    
    async componentDidMount() {
        const { addEditFilingEntityMaintenanceDataStore } = this.props;
        const previousLineSegment = history?.location?.state as { from?: string }
        await addEditFilingEntityMaintenanceDataStore.init();
        addEditFilingEntityMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {addEditFilingEntityMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Filing Entity Maintenance_filter');
      this.props.addEditFilingEntityMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Filing Entity Maintenance_' + accountStore.userName);
      this.props.addEditFilingEntityMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }
    
    componentWillUnmount() {
        const { addEditFilingEntityMaintenanceDataStore } = this.props;
        addEditFilingEntityMaintenanceDataStore.resetFilingEntityDetails();
    }

    CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };

    disableEditing = () => {
        const { addEditFilingEntityMaintenanceDataStore } = this.props;
        if (addEditFilingEntityMaintenanceDataStore && addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData) {
            const initialData = addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData.filter(a => (!addEditFilingEntityMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString())));
            return initialData.filter((item) => item.isSelected === true).length > 1
        }
    }

    AddLinkerDetailsColDef() {
        const { addEditFilingEntityMaintenanceDataStore } = this.props;
        const AddLinkerDetailsColDef: ColDef[] = [
            {
                headerName: 'Select',
                field: 'select',
                colId: 'Select',
                headerComponent: (params) => {
                    return (
                        <input
                            type="checkbox"
                            onChange={(e) => addEditFilingEntityMaintenanceDataStore.headerCheckBoxSelection(e)}
                            checked={!addEditFilingEntityMaintenanceDataStore.checkIfHeaderCheckboxShouldbeChecked(params)}
                            // disabled={this.disableEditing()}
                        />
                    );
                },
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            onChange={(e) => addEditFilingEntityMaintenanceDataStore.linkedPipelineCheckboxHandler(params, e)}
                            checked={params.data.isSelected || false}
                        />
                    );
                },
                cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
                maxWidth: 40,
                flex: 1,
                suppressSizeToFit: true,
                cellStyle: () => { return { padding: '10px' } }
            },
            {
                headerName: 'Pipeline System',
                field: 'pipelineName',
                colId: 'pipelineName',
                filter: true,
                sortable: true,
                editable: false,
                // cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
                valueGetter: params => {
                    if (params.data['pipelineName'] === null) {
                        return '';
                    } else {
                        return params.data['pipelineName'];
                    }
                },
                suppressSizeToFit: true,
                flex: 1
            },
        ];
        return AddLinkerDetailsColDef;
    }

    private getAddLinkerDetailsGridConfig(gridId): GridOptions {
      const {
          addEditFilingEntityMaintenanceDataStore: { AddOrEditLinkerGridData, agGridServiceForAddOrEditLinker }
      } = this.props;
      const { addEditFilingEntityMaintenanceDataStore } = this.props;
      const columnDefs = this.AddLinkerDetailsColDef();
      const rowData = AddOrEditLinkerGridData;
      const onCellEditingStarted = this.onCellEditingStarted;
      const onCellEditingStopped = this.onCellEditingStopped;
      const filingEntityMaintenanceGridParams: any = {
        rowData,
        columnDefs,
        onCellEditingStarted,
        onCellEditingStopped
      };
      return agGridServiceForAddOrEditLinker.getGridConfig(filingEntityMaintenanceGridParams, gridId);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          addEditFilingEntityMaintenanceDataStore: { cellValueChangeMap }
      } = this.props;
      const isNotEmpty =
          row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
      if (!isNotEmpty) {
          this.props.addEditFilingEntityMaintenanceDataStore.setValueInChangeMap(row, col, value, value);
      }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
        addEditFilingEntityMaintenanceDataStore: { cellValueChangeMap, updateRowforAddOrUpdateGrid, getColDef}
      } = this.props;
      cellValueChangeMap[row][col]['currentValue'] = value;
      updateRowforAddOrUpdateGrid(event.data);
      this.props.addEditFilingEntityMaintenanceDataStore.agGridServiceForAddOrEditLinker.getNodes()?.refreshCells();
    };

    // onChangeDate = (params, value, key) => {
    //     const { addEditFilingEntityMaintenanceDataStore } = this.props;
    //     const effectiveDate = moment(value).format('MM/DD/yyyy');
    //     console.log(moment(value).format('MM/DD/yyyy'));
    //     addEditFilingEntityMaintenanceDataStore.setValueInChangeMap(params.data.pipelineID, key, params.data.effectiveDate, effectiveDate);
    //     params.data.effectiveDate = moment(value).format('MM/DD/yyyy');
    //     addEditFilingEntityMaintenanceDataStore.updateRowforAddOrUpdateGrid(params.data);
    //     if (addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length > 1) {
    //         const confirmService = new ConfirmService();
    //         confirmService.showConfirmDialog(
    //             () => addEditFilingEntityMaintenanceDataStore.globalEffectiveDateSelection(params.data.effectiveDate),
    //             'EffectiveDate',
    //             `Do you want to apply selected service date i.e. ${params.data.effectiveDate} to all the linked pipelines?`
    //         );
    //     }
    // }

    onClickSaveFilingEntity = () => {
      const { addEditFilingEntityMaintenanceDataStore } = this.props;
      addEditFilingEntityMaintenanceDataStore.saveFilingEntity();
    }
    
    async pushToVmacs(env) {
      const { addEditFilingEntityMaintenanceDataStore } = this.props;
      const needToSync = addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0]) || addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData.some((item) => item.syncStatus && item.syncStatus === SyncStatus[1] && item.syncStatus !== SyncStatus[2] );
      if(addEditFilingEntityMaintenanceDataStore.selectedFilingEntity.label !== 'Select' || needToSync ||  (addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus && addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus !== SyncStatus[2])) {
        if(((addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus && addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus=== SyncStatus[0]) || addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0])) && env === 'Push TariffEntity to Vmacs Prod' && addEditFilingEntityMaintenanceDataStore.selectedFilingEntity.label !== 'Select') {
            const confirmService = new ConfirmService();
            confirmService.showConfirmWithCustomComponent(
              () => {},
              ConfirmWithOK,
              {secondaryText: 'Filing Entity has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
              'confirmPopup'
            );
          } else {
              const confirmService = new ConfirmService();
              confirmService.showConfirmDialog(
                () => addEditFilingEntityMaintenanceDataStore.pushSelectedRowsToVmacs(env),
                'Active',
                `Are you sure you want to push the selected data to the ${env} Environment?`
            );
          }
      } else {
        const secondaryText = 'Please select only the active and unsynced Filing Entity to perform the operation';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
  }
    
    onClickClose = () => {
        const { addEditFilingEntityMaintenanceDataStore } = this.props;
        addEditFilingEntityMaintenanceDataStore.onCloseFilingEntityDetails();
    }

    pushButtonDisabled(env) {
      const { addEditFilingEntityMaintenanceDataStore } = this.props;
      if(env === 'Push TariffEntity to Vmacs Test') {
        const needToSync = addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0] && item.syncStatus !== SyncStatus[2]);
        return  !addEditFilingEntityMaintenanceDataStore.editableFilingEntityData || (!addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.entityID || addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.entityID === 0) || (addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus && (addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus === SyncStatus[1] || addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus === SyncStatus[2] && !needToSync)); 
      }
      const needToSync = addEditFilingEntityMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[1] && item.syncStatus !== SyncStatus[2] );
        return  !addEditFilingEntityMaintenanceDataStore.editableFilingEntityData || (!addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.entityID || addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.entityID === 0) || ( addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus && addEditFilingEntityMaintenanceDataStore.editableFilingEntityData.syncStatus === SyncStatus[2] && !needToSync);
    }

    render(): React.ReactNode {
      const { addEditFilingEntityMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderFilingEntityMaintenanceScreen()}
        </Container>
      );
    }

    renderFilingEntityMaintenanceScreen() : React.ReactNode {
      const { addEditFilingEntityMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='filingEntityMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>FERC Reporting Filing Entity Details</h2>
                </div>
                
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                    <label className="standard_label_style">Filing Entity</label>
                      <ReactSelect
                        className='expanded_select'
                        values={addEditFilingEntityMaintenanceDataStore.selectedFilingEntity}
                        placeholder="Select"
                        options={[{ value: 'Select', label: 'Select' }, ...addEditFilingEntityMaintenanceDataStore.pipelineOwnerDropdownOptions]}
                        onChange={(e: any) => {addEditFilingEntityMaintenanceDataStore.selectedFilingEntity = e}}
                        isMulti={false}
                        isSearchable={true}
                        disabled={addEditFilingEntityMaintenanceDataStore.isEditingFilingEntity}
                    />
                </div>
              </div>
            </div>
            
            
              
              <div style={{'marginTop':'20px'}}>
                    <div className='screenHeader'>
                        <h2 className='screen_title'>Related Filing Entity</h2>
                    </div>
              
            <div className='filingEntityMaintenanceBox'>
                <div className='pipelineLinkerBox'>
                    <div className="rotatedText">
                      Linker
                    </div>
                    <Row style={{margin:'20px', width: '60%', height: '40%'}}>
                      <AgGridComponent gridConfig={this.getAddLinkerDetailsGridConfig('Filing Entity Details')}/>
                    </Row>
                </div>
              </div>


                <div className='selectContainer'>
                  <div className='searchButtonDiv'>
                        <div className='otherHeaderButtonsDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickSaveFilingEntity} disabled={addEditFilingEntityMaintenanceDataStore.isSaveDisabled()}>
                                Save
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push TariffEntity to Vmacs Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Push TariffEntity to Vmacs Test')}>
                                Push to Test
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push TariffEntity to Vmacs Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Push TariffEntity to Vmacs Prod')}>
                                Push to Prod
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickClose} disabled= {false}>
                                Close
                            </CustomButton>
                          </div>
                      </div>
              </div>
            </div>
            </Col>
        </Row>
      );
    }

}