import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class LocationMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    async getLocationsDataWithFilter(locnName, baseSystemId, status): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getLocations}?baseSystemId=${baseSystemId}&locationName=${locnName}&status=${status}`);
        return data;
    }

    async getLocationsData(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getLocationsWithDefaultFilter);
        return data;
    }
    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }
    async getSettingNameOptions(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id3);
        return data;
    }  
    async getJurisdictionDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id8);
        return data;
    }
    async updateLocationMaintenanceData(requestBody, onEditLocation): Promise<any[]> {
        if(onEditLocation) {
            const { data } = await this.restApi.put<any>(Path.addLocationDetailsAPI, requestBody, {});
            return data;
        } else {
            const { data } = await this.restApi.post<any>(Path.addLocationDetailsAPI, requestBody, {});
            return data;
        }
    }  
    async deleteLocationMaintenanceData(requestBody): Promise<any> {
        const { data } = await this.restApi.delete<any>(Path.addLocationDetailsAPI, requestBody, {});
        return data;
    }  
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsLocationEndpoint, reqbody, {});
        return data;
    }  

    async updateStatusFromDelToActive(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateLocations, reqbody, {});
        return data;
    }

    async getWorkflowLinkerData(workflowId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_2_getLinkedWorkflow+`?workflowID=${workflowId}`);
        return data;
    }
}

export const locationMaintenanceDataService = new LocationMaintenanceDataService(restApiService);
