import React, { Component } from 'react';
import { AppConstant } from 'app_constants';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './project-request.scss';
import {
    ColDef, RowSelectedEvent, CellEditingStoppedEvent
} from 'ag-grid-community';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import { ProjectRequestDataStore } from '../stores/project-request-data-store';
import moment from 'moment';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import momenttimezone from 'moment-timezone';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { projectRequestDataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import { accountStore } from 'features/account/stores';

/* eslint-disable */
interface ProjectRequestContainerProps {
    projectRequestDataStore: ProjectRequestDataStore;
}

interface ProjectRequestContainerState {
    rowSelected: boolean
}

@inject('projectRequestDataStore')
@observer
export class ProjectRequestContainer extends Component<ProjectRequestContainerProps, ProjectRequestContainerState> {

    constructor(props: ProjectRequestContainerProps | Readonly<ProjectRequestContainerProps>) {
        super(props);
        this.state = {
            rowSelected: false
        };
        dataStore.setModuleName('Project Request')
    }

    componentDidMount() {
      const {projectRequestDataStore} = this.props;
      projectRequestDataStore.init();
    }

    render(): React.ReactNode {
        const { projectRequestDataStore } = this.props;
        return (
            <div className='ProjectRequestBox'>
                <Container fluid>
                    <div className='boxes'>
                        <div className='screenHeader'>
                            <h2 className='screen_title'> Project Request</h2>
                            </div>
                        <div className='selectContainer'>
                            <div className='settingSelectionDiv'>
                                <Row>
                                    <Col>
                                        <div className="inline">
                                            <label className="standard_label_style">Project Request Type*</label>
                                            <ReactSelect
                                                className='search_text_field'
                                                values={{label: projectRequestDataStore.selectedProjectRequestType || '', value: projectRequestDataStore.selectedProjectRequestType || ''}}
                                                placeholder={'Select Project Request'}
                                                options={Array.isArray(projectRequestDataStore.projectRequestTypeDropdownOptions) && projectRequestDataStore.projectRequestTypeDropdownOptions.length && projectRequestDataStore.projectRequestTypeDropdownOptions.map(option=> {return{value: option.name, label: option.name }}) ||  []}
                                                onChange={(e)=>{projectRequestDataStore.setProjectRequestType((e as Option).value)}}
                                                isMulti={false}
                                                isSearchable={true}
                                            />
                                        </div>
                                    </Col>
                                    <Col><label>{projectRequestDataStore.getDescription()}</label></Col>
                                    <Col></Col>
                                </Row>
                                {projectRequestDataStore.location==='CAN' && (
                                <Row>
                                    <Col style={{marginTop:'10px'}}>
                                    <div className="inline">
                                        <label className="standard_label_style">Tariff Rate Type*</label>
                                        <ReactSelect
                                            className='search_text_field'
                                            values={{label: projectRequestDataStore.selectedTariffRateType || '', value: projectRequestDataStore.selectedProjectRequestType || ''}}
                                            placeholder={'Customer Maintenance'}
                                            options={Array.isArray(projectRequestDataStore.tariffRateTypesDropdownOptions) && projectRequestDataStore.tariffRateTypesDropdownOptions.length && projectRequestDataStore.tariffRateTypesDropdownOptions.map(option=> {return{value: option.valueText, label: option.valueText }}) ||  []}
                                           onChange={(e)=>{projectRequestDataStore.setTariffRateType((e as Option).value)}}
                                            isMulti={false}
                                            isSearchable={true}
                                        />
                                    </div>
                                    </Col>
                                    <Col></Col>
                                </Row>)}
                            </div>
                            <div className='searchButtonDiv'>
                                    <div className="otherHeaderButtonsDiv"> 
                                        <CustomButton type={CustomButtonType.Submit} disabled={projectRequestDataStore.location==='All'} onClick={()=>{projectRequestDataStore.step0PostWorkflowDetails()}}>
                                            Start the Workflow
                                        </CustomButton>
                                    </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
    );
}}
