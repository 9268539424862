import React, { Component } from 'react';
import { AppConstant } from 'app_constants';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './process-tariff-options.scss';
import { ColDef } from 'ag-grid-community';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { dataStore } from '../../../../common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';
import { ProcessOptionStep2DataStore } from '../stores/process-tariff-options-data-store';
import { ProcessOptionDetails } from './common/tariff-log-details';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import img from 'assets/check_complete_done_green_success_icon.png';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { AddNotes } from 'common/components/add-notes/add-notes';
import { Attachments } from 'common/components/attachments/attachments';
import { tariffAttachmentsGridColDef } from 'features/tariff-management/pmc-tariff/components/pmc-tariff-col-defs';
import { processOptionStep2DataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { TariffOptionsContainer } from '../tariff-options-project/components';
import { tariffOptionsDataStoreLog } from '../tariff-options-project/stores';
import { TariffOptionsIndexDataStore } from '../tariff-options-project/stores/tariff-options-data-store';
import _ from 'lodash';
import { ThreeDots } from 'react-loader-spinner';

/* eslint-disable */
interface ProcessOptionStep2ContainerProps {
    processOptionStep2DataStore: ProcessOptionStep2DataStore;
    tariffOptionsDataStoreLog: TariffOptionsIndexDataStore;
}

interface ProcessOptionStep2ContainerState {
    rowSelected: boolean,
    lastTariffNoteID: number
}

@inject('processOptionStep2DataStore','tariffOptionsDataStoreLog') 
@observer
export class ProcessOptionStep2Container extends Component<ProcessOptionStep2ContainerProps, ProcessOptionStep2ContainerState> {

    constructor(props: ProcessOptionStep2ContainerProps | Readonly<ProcessOptionStep2ContainerProps>) {
        super(props);
        this.state = {
            rowSelected: false,
            lastTariffNoteID: 0
        };
        dataStore.setModuleName('Process Option Step 2')
    }

    componentDidMount() {
      const {processOptionStep2DataStore} = this.props;
    //   if(!(processOptionDataStore.workflowId||processOptionStep2DataStore.workflowId)){
    //        history.push('/ProjectDashboard')
    //   }else{
          processOptionStep2DataStore.init(); 
          window.scroll(0,0);
    //   }
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', this.backButtonDisabled)
    }

    componentWillUnmount() {
        const {processOptionStep2DataStore} = this.props;
        processOptionStep2DataStore.reset();
        window.removeEventListener('popstate', this.backButtonDisabled)
    }

    backButtonDisabled(event){
            window.history.pushState(null, document.title,  window.location.href);
            alert('Back button is not allowed in Process Option! Use \'Cancel\' to go back.')
    }

    componentDidUpdate() {
        const filters = localStorageService.get('Process Tariff Option_filter');
        this.props.processOptionStep2DataStore.agGridService.setAllFilters(filters);
        const columnVisible = localStorageService.get('Process Tariff Option_' + accountStore.userName);
        this.props.processOptionStep2DataStore.agGridService.setColumnVisibility(columnVisible);
    }

    onChangePipeline = (selectedList, selectedItem) => {
        this.props.processOptionStep2DataStore.setPipeline(selectedList);
    };

    gridConfigTariffSummary: ColDef[] = [
        {
              headerName: 'Tariff Base',
              field: 'tariffBase',
              tooltipField: 'tariffBase',
              filter: true,
              editable: false,
              sortable: true,
              minWidth: 110,
              flex: 1
          },
          {
              headerName: 'Tariff Log',
              field: 'tariffLog',
              tooltipField: 'tariffLog',
              filter: true,
              sortable: true,
              minWidth: 110,
              editable: false,
              flex: 2
          }
    ]

    
    gridConfigInfrastructureSummary: ColDef[] = [
    {
          headerName: 'Pipeline',
          field: 'pipeline',
          tooltipField: 'pipeline',
          filter: true,
          editable: false,
          sortable: true,
          minWidth: 110,
          flex: 1
      },
      {
          headerName: 'Customer Maintenance',
          field: 'customer',
          tooltipField: 'customer',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
      {
          headerName: 'Location',
          field: 'location',
          tooltipField: 'location',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 1
      },
      {
          headerName: 'Connnection Point',
          field: 'cp',
          tooltipField: 'cp',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
      {
          headerName: 'Agency Mtn',
          field: 'agency',
          tooltipField: 'agency',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 1
      },
      {
          headerName: 'Line Segment',
          field: 'lineSegment',
          tooltipField: 'lineSegment',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
      {
          headerName: 'Grade Mtn',
          field: 'grade',
          tooltipField: 'grade',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
      {
          headerName: 'Profit Center',
          field: 'profitCenter',
          tooltipField: 'profitCenter',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
      {
          headerName: 'Tariff Option',
          field: 'option',
          tooltipField: 'option',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      }
]
    
    
    gridConfig: ColDef[] = [
        {
              headerName: 'Steps',
              field: 'workflowstepName',
              tooltipField: 'workflowstepName',
              filter: true,
              editable: false,
              sortable: true,
              minWidth: 150,
              singleClickEdit: true,
              flex: 2
          },
          {
              headerName: 'Status',
              field: 'workflowstepStatus',
              tooltipField: 'workflowstepStatus',
              filter: true,
              sortable: true,
              minWidth: 110,
              editable: false,
              singleClickEdit: true,
              suppressSizeToFit: true,
              flex: 1
          },
          {
              headerName: 'Comment',
              field: 'workflowStepComments',
              tooltipField: 'workflowStepComments',
              cellEditor: 'agSelectCellEditor',
              singleClickEdit: true,
              filter: true,
              sortable: true,
              editable: false,
              minWidth: 135,
              flex: 2
          },
          {
              headerName: 'Workflow Step Role',
              field: 'workflowstepRole',
              tooltipField: 'workflowstepRole',
              singleClickEdit: true,
              editable: false,
              minWidth: 120,
              suppressSizeToFit: false,
          },
          {
              headerName: 'Last Modified User *',
              field: 'lastModifiedUser',
              tooltipField: 'lastModifiedUser',
              singleClickEdit: true,
              editable: false,
              minWidth: 120,
              suppressSizeToFit: false,
          },
          {
              headerName: 'Last Modified Date',
              field: 'lastModifiedDate',
              tooltipField: 'lastModifiedDate',
              filter: true,
              sortable: true,
              editable: false,
              minWidth: 120,
              valueGetter: params => {
                  if (params.data['lastModifiedDate'] === null) {
                      return '';
                  } else {
                      return momenttimezone
                      .tz(moment.utc(params.data['lastModifiedDate']), AppConstant.CST_TIME_FORMATTER)
                      .format(AppConstant.DATE_TIME_FORMAT);
                  }
              },
              suppressSizeToFit: false,
          }
      ];
    
        

  getGridConfig(gridId) {
    const {
        processOptionStep2DataStore: { agGridService }
    } = this.props;
    const { processOptionStep2DataStore } = this.props;
    const rowData = processOptionStep2DataStore.workflowStatusGridData;
    const gridParams: any = {
      rowData,
      columnDefs:this.gridConfig,
    };
    return agGridService.getGridConfig(gridParams, gridId);
  }

  getGridConfigInfrastructureSummary(gridId) {
    const {
        processOptionStep2DataStore: { agGridService , linkedWorkflowData}
    } = this.props;
    const { processOptionStep2DataStore } = this.props;
    const rowData = [];
    const gridParams: any = {
        rowData: linkedWorkflowData,
        columnDefs:this.gridConfigInfrastructureSummary,
    };
    return agGridService.getGridConfig(gridParams, gridId);
  }

  getGridConfigTariffSummary(gridId) {
    const {
        processOptionStep2DataStore: { agGridService }
    } = this.props;
    const { processOptionStep2DataStore } = this.props;
    const rowData = processOptionStep2DataStore.formData2a.baseTariff ? [
        {tariffBase: processOptionStep2DataStore.formData2a.baseTariff, tariffLog: processOptionStep2DataStore.formData2a.tariffLog[0]}, 
        ...processOptionStep2DataStore.formData2a.tariffLog.flatMap((x,index)=>{
            if(index!==0){
                return {tariffLog:x}
            }else{
                return []
            }
        })]: [];
        const gridParams: any = {
            rowData,
            columnDefs:this.gridConfigTariffSummary,
        };
        return agGridService.getGridConfig(gridParams, gridId);
    }

    showOnlyUS = () => {
        return this.props.processOptionStep2DataStore.accountStore.rolesJson[0]?.defaultLocation==='US'
    }

    goToPreviousPage = () => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
        () => processOptionStep2DataStore.goToPreviousPage(),
        'Warning',
        'This will redirect you back to Initiate Project - Step 1, and some changes may be lost!'
        );
    }

    setActiveTab = (e) => {
        processOptionStep2DataStore.setActiveTab(e)
        if((e==='review'||e==='compare')){
            if(processOptionStep2DataStore.tariffOptionLinked){
            this.props.tariffOptionsDataStoreLog.init(processOptionStep2DataStore.formData2a, processOptionStep2DataStore.workflowId, true, processOptionStep2DataStore.activeTab);
            } else{
                this.props.tariffOptionsDataStoreLog.init(processOptionStep2DataStore.formData2a, processOptionStep2DataStore.workflowId,false, processOptionStep2DataStore.activeTab);
            }
        }
    }

    onSaveNotes = () => {
        processOptionStep2DataStore.saveTariffNotes();
    };

    onDeleteNote = (noteID) => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(() => {
            processOptionStep2DataStore.deleteTariffNote(noteID);
        },
            'Delete',
            'Are you sure you want to delete the selected note?'
        );
        processOptionStep2DataStore.NotesGridData.length > 0 ? processOptionStep2DataStore.isTariffNoteAdded = true : false;
    };

    onAddNewNote() {
        const workflowDocumentID = this.state.lastTariffNoteID - 1;
        const rowData = [{
            workflowDocumentID: workflowDocumentID,
            workflowID: processOptionStep2DataStore.workflowId,
            notes: '',
            createUser: processOptionStep2DataStore.username,
            createDate: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            updateUser: null,
            updateDate: null,
            workflow: null,
        } as unknown as any, ...processOptionStep2DataStore.notesGridData];
        processOptionStep2DataStore.setPMCTariffNoteGridData(rowData);
        this.setState({ ...this.state, lastTariffNoteID: workflowDocumentID });
        processOptionStep2DataStore.NotesGridData.length > 0 ? processOptionStep2DataStore.isTariffNoteAdded = true : false;
    };

    getAttachmentsColDef() {
        return tariffAttachmentsGridColDef;
    }

    // getAttachmentsGridConfig() {
    //     const {
    //         processOptionStep1DataStore: { fileUploadGridData, agGridServiceForAddAttachment },
    //     } = this.props;
    //     const tariffAttachmentsGridParams: any = {
    //         rowData: fileUploadGridData,
    //         columnDefs: this.getAttachmentsColDef(),
    //     };
    //     return agGridServiceForAddAttachment.getGridConfig(tariffAttachmentsGridParams);
    // }

    uploadFile(e) {
        processOptionStep2DataStore.uploadFile(e);
    };

    downloadAttachment(fileID) {
        processOptionStep2DataStore.downloadAttachment(fileID);
    };

    deleteAttachment = async (files) => {
        const requestBody: any = await processOptionStep2DataStore.agGridService.getSelectedRows();
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => {
                processOptionStep2DataStore.deleteAttachment(files);
            },
            'Delete',
            'Are you sure you want to delete the selected attachment?'
        );
    };

    updateAttachment = () => {
        processOptionStep2DataStore.updateAttachment();
    }

    checkRoleAccessSave = () => {
        const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffDashboardProcessButton);
        console.log(a, 'roleaccess');
        return a;
    }
    
    isSaveDisabledForLineSegment = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.LineSegmentMaintenance);
    };
     
    isforwardWorflowStepDsabled = () => {
        const { processOptionStep2DataStore } = this.props;
        const a = processOptionStep2DataStore.workflowStatusGridData.find((item) => item.workflowstepName === processOptionStep2DataStore.workflowstepName);
        return a ? a.autocomplete && a.autocomplete === "Y": false
    }

     checkRoleAccessSaveCompleteTariffReview = () => {
        const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteTariffReview);
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompleteTariffApproval = () => {
        const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteTariffApproval);
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompletePLAccountingApproval = () => {
        const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.CompletePLAccountingApproval);
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompleteCommercialApproval = () => {
        const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteCommercialApproval);
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompleteStep= () => {
        const a =  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, processOptionStep2DataStore.workflowstepName, this.props.processOptionStep2DataStore.pipeline? this.props.processOptionStep2DataStore.pipeline.split(',') : []);
        console.log(a, 'roleaccess');
        return a;
     }

    render(): React.ReactNode {
        const { processOptionStep2DataStore } = this.props;
        
        return (
            <div className='ProcessOptionBoxStep2'>
                <Container fluid>
                    <>
                        {processOptionStep2DataStore.loader ? <div className="loader__overlay" /> : null}
                        <div className="loader__icon">
                            <ThreeDots height={80} width={80} color="green" ariaLabel="loading" visible = {processOptionStep2DataStore.loader}/>
                        </div>
                    </>
                    <div className='boxes'>
                        <ProcessOptionDetails datastore={this.props.processOptionStep2DataStore} showOnlyUS={this.showOnlyUS} onChangePipeline={this.onChangePipeline} allDisabled={true} checkRoleAccessSave={this.checkRoleAccessSave()}/>
                        {this.showOnlyUS() && (
                            <Tabs
                                defaultActiveKey={processOptionStep2DataStore.activeTab}
                                activeKey={processOptionStep2DataStore.activeTab}
                                className="screenHeaderTabs"
                                onSelect={(e)=>this.setActiveTab(e)}
                            >
                                <Tab eventKey='tariffSetup' title="Tariff Setup">
                                    <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff Setup</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                            <Row style={{ marginTop: '0px' }}>
                                                <Col style={{ marginTop: '5px', 'display': 'inline-flex' }}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Select Base Tariff'}
                                                        // disabled={!(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options') || ! (Array.isArray(processOptionStep2DataStore.baseTariffDropdown) && processOptionStep2DataStore.baseTariffDropdown.length)}
                                                        disabled={true}
                                                        options={Array.isArray(processOptionStep2DataStore.baseTariffDropdown) && processOptionStep2DataStore.baseTariffDropdown.length && processOptionStep2DataStore.baseTariffDropdown.map(option=> {return{value: option.baseTariff, label: option.baseTariff }}) ||  []}
                                                        onChange={(e)=>{processOptionStep2DataStore.changeFormData2a((e as Option)?.value, 'baseTariff')}}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton disabled={true} type={CustomButtonType.Submit} onClick={() => { history.push('/BaseTariff', { from: 'step2', workflowId: processOptionStep2DataStore.workflowId }) }}>
                                                        +
                                                </CustomButton>
                                                    {processOptionStep2DataStore.baseLinked && (<img style={{ height: '25px', 'marginLeft': '10px', 'marginTop': '5px' }} src={img} alt="Synced" />)}
                                                </Col>
                                                <Col></Col><Col></Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                {processOptionStep2DataStore.formData2a.baseTariff && (
                                                    <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                        {/* {this.checkRoleAccessSave()&&(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options')  && !(!processOptionStep2DataStore.workflowstepName || processOptionStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { processOptionStep2DataStore.changeFormData2a('', 'baseTariff') }}>X</div>} */}
                                                        {processOptionStep2DataStore.formData2a.baseTariff}
                                                    </div>
                                                )}
                                            </Row>
                                            <Row style={{ marginTop: '0px' }}>
                                                <Col style={{ marginTop: '10px', 'display': 'inline-flex' }}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Select Tariff Log/s'}
                                                        disabled={true}
                                                        // disabled={!(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options') ||processOptionStep2DataStore.formData2a.baseTariff === ''}
                                                        options={Array.isArray(processOptionStep2DataStore.tariffLogDropdown) && processOptionStep2DataStore.tariffLogDropdown.length && processOptionStep2DataStore.tariffLogDropdown.map(option=> {return{value: option.tariffNumber, label: option.tariffNumber }}) ||  []}
                                                        onChange={(e)=>{processOptionStep2DataStore.changeFormData2a((e as Option)?.value, 'tariffLog')}}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton disabled={true} type={CustomButtonType.Submit} onClick={() => { history.push('/TariffLog') }}>
                                                        +
                                                </CustomButton>
                                                    {processOptionStep2DataStore.tariffLogLinked && (<img style={{ height: '25px', 'marginLeft': '10px','marginTop': '5px' }} src={img} alt="Synced" />)}
                                                </Col>
                                                <Col></Col><Col></Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                {processOptionStep2DataStore.formData2a.tariffLog.length > 0 && processOptionStep2DataStore.formData2a.tariffLog.map(x =>
                                                    <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                        {/* {this.checkRoleAccessSave()&&(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options') && !(!processOptionStep2DataStore.workflowstepName || processOptionStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { processOptionStep2DataStore.changeFormData2a(x, 'tariffLogDelete') }}>
                                                            X
                                                        </div>} */}
                                                        {x}
                                                    </div>
                                                )}
                                            </Row>
                                        </div>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff Summary</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                            <div className="innerflexwidths">
                                                <Row style={{ 'border': '1px solid #a3d0e4', 'padding': '5px', 'width': '98%', 'marginLeft': '10px', 'marginRight': '10px', 'marginTop': '5px' }}>Linked Items</Row>
                                                <Row>
                                                    <AgGridComponent gridConfig={this.getGridConfigTariffSummary('Step2 Tariff Summary')} />
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="infrastructureSetup" title="Tariff Options Setup">
                                <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff Options Setup</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                            <Row>
                                            <Col style={{ marginTop: '5px' }}>
                                                    <CustomButton type={CustomButtonType.Submit}  disabled={!this.checkRoleAccessSaveCompleteStep()||!(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-infrastructure') ||!processOptionStep2DataStore.tariffLogLinked||processOptionStep2DataStore.dontProcessOptions} onClick={() => {processOptionStep2DataStore.processTariffOptions()}}>
                                                    Process Tariff Options
                                                    </CustomButton>
                                                    {processOptionStep2DataStore.tariffOptionLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <CustomButton type={CustomButtonType.Submit} disabled={!this.checkRoleAccessSaveCompleteStep()||!(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-infrastructure') ||!processOptionStep2DataStore.tariffLogLinked} onClick={() => {history.push('\TariffOptions', {from: '/process-tariff-infrastructure', url: processOptionStep2DataStore.url, baseTariff: processOptionStep2DataStore.formData2a.baseTariff})}}>
                                                    Create Tariff Option +
                                                    </CustomButton>
                                                    </Col>
                                                    <Col>
                                                    </Col><Col></Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                {Array.isArray(processOptionStep2DataStore.tariffOptions) && processOptionStep2DataStore.tariffOptions.length > 0 && processOptionStep2DataStore.tariffOptions.map((x:any) =>
                                                    <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex', 'cursor': 'pointer' }} onClick={()=>{history.push('/TariffOptions', {from: '/process-tariff-infrastructure', url: processOptionStep2DataStore.url, tariffName: x.tariffName, baseId: x.baseId, baseTariff: x.baseTariff, tariffLogId: x.logId, isCTSCommetsEnabled: processOptionStep2DataStore.checkIsCTSCommentsEnabled(), disabled: !(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-infrastructure') || (!processOptionStep2DataStore.workflowstepName || processOptionStep2DataStore.workflowstepName === '') || !this.checkRoleAccessSaveCompleteStep()})}}>
                                                        {/* <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { processOptionStep2DataStore.changeFormDataInfrastructure(x, 'lineSegmentDelete') }}>
                                                            X
                                                        </div> */}
                                                        View/Edit Tariff Option: {x.tariffNo}
                                                    </div>
                                                )}
                                            </Row>
                                        </div>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Infrastructure Summary</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                                <Row style={{ 'border': '1px solid #a3d0e4', 'padding': '5px', 'width': '98%', 'marginLeft': '10px', 'marginRight': '10px', 'marginTop': '5px' }}>Linked Items</Row>
                                                <Row>
                                                    <AgGridComponent gridConfig={this.getGridConfigInfrastructureSummary('Process Tariff Option Infrastructure')} />
                                                </Row>
                                        </div>
                                    </div>
                                </Tab>
                                {/* disabled={processOptionStep2DataStore.href==='/process-tariff-options'} */}
                                <Tab eventKey="review" title="Review" >
                                    <div className='selectContainer'>
                                        {/* <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff</h6>
                                        </div> */}
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Infrastructure</h6>
                                        </div>
                                        <React.Fragment>
                                            <TariffOptionsContainer key = {'review'} tariffOptionsDataStoreLog={tariffOptionsDataStoreLog} baseTariffLogDetails={processOptionStep2DataStore.formData2a} tariffOptionsLinked={processOptionStep2DataStore.tariffOptionLinked} compare={false}/>
                                        </React.Fragment>

                                    </div>
                                </Tab>
                                <Tab eventKey="compare" title="Compare" >
                                    <div className='selectContainer'>
                                        <React.Fragment>
                                            <TariffOptionsContainer key= {'compare'} tariffOptionsDataStoreLog={tariffOptionsDataStoreLog} baseTariffLogDetails={processOptionStep2DataStore.formData2a} tariffOptionsLinked={processOptionStep2DataStore.tariffOptionLinked} compare={true} allDisabled={!(processOptionStep2DataStore.href==='/process-clone-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-infrastructure') || (!processOptionStep2DataStore.workflowstepName || processOptionStep2DataStore.workflowstepName === '')}/>
                                        </React.Fragment>
                                    </div>
                                </Tab>
                                <Tab eventKey="notesAndAttachments" title="Notes & Attachments">
                                    <div className='selectContainer'>
                                        <div style={{ width: '100%', marginTop: '12px' }}>
                                            <Row>
                                                <AddNotes saveRoleAccess={() => true} disableAddBtn={false} disableSaveBtn={!processOptionStep2DataStore.isTariffNoteAdded} disableDeleteBtn={false} isNoteSaved={processOptionStep2DataStore.isTariffNoteSaved} onAddNewNoteCallback={() => this.onAddNewNote()} onSaveNotesCallback={() => this.onSaveNotes()} onDeleteNoteCallback={(data) => this.onDeleteNote(data.workflowNoteID)} agGridService={processOptionStep2DataStore.agGridService} notesGridData={processOptionStep2DataStore.notesGridData} cellValueChangeMap={processOptionStep2DataStore.cellValueChangeMap} updateRow={(rowData) => processOptionStep2DataStore.updateRow(rowData)} ></AddNotes>
                                            </Row>
                                        </div>
                                        <div style={{ width: '100%', marginTop: '12px'  }}>
                                            <Row>
                                                <Attachments saveRoleAccess={true} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.workflowDocumentID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.workflowDocumentID)} agGridService={processOptionStep2DataStore.agGridService} fileUploadGridData={processOptionStep2DataStore.fileUploadGridData}  cellValueChangeMap={processOptionStep2DataStore.cellValueChangeMapAttachment} updateRow={(rowData) => processOptionStep2DataStore.updateRowAttachment(rowData)}></Attachments>
                                            </Row>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                        {!this.showOnlyUS() && (
                            <Tabs
                                defaultActiveKey="review"
                                className="screenHeaderTabs"
                            >
                                <Tab eventKey="review" title="Review">
                                    <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Review</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 10px' }}>
                                            <Row style={{ 'marginTop': '0px' }}>
                                                {processOptionStep2DataStore.selectedTariffs.length > 0 &&
                                                    <Tabs
                                                        defaultActiveKey="tariffs"
                                                        className="screenHeaderTabs"
                                                        style={{ 'marginBottom': '20px' }}
                                                    >
                                                        {processOptionStep2DataStore.reviewTariffDetails.length > 0 && processOptionStep2DataStore.reviewTariffDetails.map((item, index) => (
                                                            <Tab eventKey={index == 0 ? 'tariffs' : index} title={item.tariffNumber}>
                                                                <div className='reviewTabSelectContainer'>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style_review_header">Tariff Details:</label>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_review_header">Prior Fees:</label>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style_review_header">{item.hasParent ? 'Updated Fees:' : 'Fees'}</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Tariff Number:</label>
                                                                            <span className="tariffValue">{item.tariffNumber}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Primary Pipeline Tariff:</label>
                                                                            <span className="tariffValue">{item.primaryPipelineTariffParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Primary Pipeline Tariff:</label>
                                                                            <span className={ item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.primaryPipelineTariff.toFixed(2)} { item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? item.primaryPipelineTariffChangePercent === 'New' ? "(" + item.primaryPipelineTariffChangePercent + ")" : "(" + item.primaryPipelineTariffChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Asset Group:</label>
                                                                            <span className="tariffValue">{item.assetGroup}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Secondary Pipeline Tariff:</label>
                                                                            <span className="tariffValue">{item.secondaryRateParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Secondary Pipeline Tariff:</label>
                                                                            <span className={ item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.secondaryRate.toFixed(2)} { item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0  ? item.secondaryRateChangePercent === 'New' ? "(" + item.secondaryRateChangePercent + ")" : "(" + item.secondaryRateChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Start Date:</label>
                                                                            <span className="tariffValue">{moment(item.startDate).format(AppConstant.DATE_FORMAT)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Surcharge:</label>
                                                                            <span className="tariffValue">{item.surchargeParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Surcharge:</label>
                                                                            <span className={ item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.surcharge.toFixed(2)} { item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? item.surchargeChangePercent === 'New' ? "(" + item.surchargeChangePercent + ")" : "(" + item.surchargeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">End Date:</label>
                                                                            <span className="tariffValue">{moment(item.endDate).format(AppConstant.DATE_FORMAT)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">LMCI Abandonment S/C:</label>
                                                                            <span className="tariffValue">{item.lmciAbandonmentSCParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>LMCI Abandonment S/C:</label>
                                                                            <span className={ item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.lmciAbandonmentSC.toFixed(2)} { item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? item.lmciAbandonmentSCChangePercent === 'New' ? "(" + item.lmciAbandonmentSCChangePercent + ")" : "(" + item.lmciAbandonmentSCChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Pipeline System:</label>
                                                                            <span className="tariffValue">{item.pipelineSystem}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Offload/Injection Fee:</label>
                                                                            <span className="tariffValue">{item.offloadInjectionFeeParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Offload/Injection Fee:</label>
                                                                            <span className={ item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.offloadInjectionFee.toFixed(2)} { item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? item.offloadInjectionFeeChangePercent === 'New' ? "(" + item.offloadInjectionFeeChangePercent + ")" : "(" + item.offloadInjectionFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Pipeline Region:</label>
                                                                            <span className="tariffValue">{item.pipelineRegion}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Receipt Terminaling:</label>
                                                                            <span className="tariffValue">{item.receiptTerminallingParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Receipt Terminaling:</label>
                                                                            <span className={ item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.receiptTerminalling.toFixed(2)} { item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? item.receiptTerminallingChangePercent === 'New' ? "(" + item.receiptTerminallingChangePercent + ")" : "(" + item.receiptTerminallingChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Operator:</label>
                                                                            <span className="tariffValue">{item.operator}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Quality Management Fee:</label>
                                                                            <span className="tariffValue">{item.qualityManagementFeeParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Quality Management Fee:</label>
                                                                            <span className={ item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.qualityManagementFee.toFixed(2)} { item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? item.qualityManagementFeeChangePercent === 'New' ? "(" + item.qualityManagementFeeChangePercent + ")" : "(" + item.qualityManagementFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Facility ID:</label>
                                                                            <span className="tariffValue">{item.facilityID}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Electronic Reporting Fee:</label>
                                                                            <span className="tariffValue">{item.electronicReportingFeeParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Electronic Reporting Fee:</label>
                                                                            <span className={ item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.electronicReportingFee.toFixed(2)} { item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? item.electronicReportingFeeChangePercent === 'New' ? "(" + item.electronicReportingFeeChangePercent + ")" : "(" + item.electronicReportingFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">LSD:</label>
                                                                            <span className="tariffValue">{item.lsd}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Total:</label>
                                                                            <span className="tariffValue">{item.totalParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Total:</label>
                                                                            <span className={ item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.total.toFixed(2)} { item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? item.totalChangePercent === 'New' ? "(" + item.totalChangePercent + ")" : "(" + item.totalChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Destination:</label>
                                                                            <span className="tariffValue">{item.destination}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Tariff Application:</label>
                                                                            <span className="tariffValue">{item.tariffApplication}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Delivery Terminaling:</label>
                                                                            <span className="tariffValue">{item.deliveryTerminallingParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Delivery Terminaling:</label>
                                                                            <span className={ item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.deliveryTerminalling.toFixed(2)} { item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? item.deliveryTerminallingChangePercent === 'New' ? "(" + item.deliveryTerminallingChangePercent + ")" : "(" + item.deliveryTerminallingChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Distribution:</label>
                                                                            <span className="tariffValue">{item.distribution}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Loss Allowance (% Stream):</label>
                                                                            <span className="tariffValue">{item.lossAllowanceTextParent}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Loss Allowance (% Stream):</label>
                                                                            <span className={ item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? "tariffValueChanged" : "tariffValue"}>{item.lossAllowanceText} { item.lossAllowanceTextChangePercent == 'New' ? '(New)' : item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? '(Changed)' : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">External System:</label>
                                                                            <span className="tariffValue">{item.externalSystem}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Shrinkage (% Stream):</label>
                                                                            <span className="tariffValue">{item.shrinkageTextParent}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Shrinkage (% Stream):</label>
                                                                            <span className={ item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? "tariffValueChanged" : "tariffValue"}>{item.shrinkageText} { item.shrinkageTextChangePercent == 'New' ? '(New)' : item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? '(Changed)' : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Product Type:</label>
                                                                            <span className="tariffValue">{item.productType}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">BS&W Penalty Fee:</label>
                                                                            <span className="tariffValue">{item.bswPenaltyParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>BS&W Penalty Fee:</label>
                                                                            <span className={ item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.bswPenalty.toFixed(2)} { item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? item.bswPenaltyChangePercent === 'New' ? "(" + item.bswPenaltyChangePercent + ")" : "(" + item.bswPenaltyChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Product:</label>
                                                                            <span className="tariffValue">{item.product}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Intra System Transfer Fee:</label>
                                                                            <span className="tariffValue">{item.intraSystemTransferFeeParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Intra System Transfer Fee:</label>
                                                                            <span className={ item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.intraSystemTransferFee.toFixed(2)} { item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? item.intraSystemTransferFeeChangePercent === 'New' ? "(" + item.intraSystemTransferFeeChangePercent + ")" : "(" + item.intraSystemTransferFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Unit:</label>
                                                                            <span className="tariffValue">{item.unit}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Lump Sum Fee:</label>
                                                                            <span className="tariffValue">{item.lumpSumFeeParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Lump Sum Fee:</label>
                                                                            <span className={ item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.lumpSumFee.toFixed(2)} { item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? item.lumpSumFeeChangePercent === 'New' ? "(" + item.lumpSumFeeChangePercent + ")" : "(" + item.lumpSumFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Currency</label>
                                                                            <span className="tariffValue">{item.currency}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                </div>
                                                            </Tab>
                                                        ))}
                                                    </Tabs>
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="notesAndAttachments" title="Notes & Attachments">
                                    <div className='selectContainer'>
                                        <div style={{ width: '100%', marginTop: '12px' }}>
                                            <Row>
                                                <AddNotes saveRoleAccess={() => true} disableAddBtn={false} disableSaveBtn={!processOptionStep2DataStore.isTariffNoteAdded} disableDeleteBtn={false} isNoteSaved={processOptionStep2DataStore.isTariffNoteSaved} onAddNewNoteCallback={() => this.onAddNewNote()} onSaveNotesCallback={() => this.onSaveNotes()} onDeleteNoteCallback={(data) => this.onDeleteNote(data.workflowNoteID)} agGridService={processOptionStep2DataStore.agGridService} notesGridData={processOptionStep2DataStore.notesGridData} cellValueChangeMap={processOptionStep2DataStore.cellValueChangeMap} updateRow={(rowData) => processOptionStep2DataStore.updateRow(rowData)} ></AddNotes>
                                            </Row>
                                        </div>
                                        <div style={{ width: '100%', marginTop: '12px'  }}>
                                            <Row>
                                                <Attachments saveRoleAccess={true} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.workflowDocumentID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.workflowDocumentID)} agGridService={processOptionStep2DataStore.agGridService} fileUploadGridData={processOptionStep2DataStore.fileUploadGridData}  cellValueChangeMap={processOptionStep2DataStore.cellValueChangeMapAttachment} updateRow={(rowData) => processOptionStep2DataStore.updateRowAttachment(rowData)}></Attachments>
                                            </Row>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                        <div>
                            <div className='screenHeader'>
                            <h2 className='screen_title'> Workflow Details</h2>
                        </div>
                        <div className='selectContainer'>
                                <Row>
                                    <div className="rightFlex">
                                        <div className="leftFlex">
                                            <label className="standard_label_style">{processOptionStep2DataStore.statusOrHistory?'Workflow Status':'Workflow History'}</label>
                                        </div>
                                        <div className="rightFlexInner">
                                            <CustomButton type={CustomButtonType.Submit} disabled={processOptionStep2DataStore.statusOrHistory} onClick={()=>{processOptionStep2DataStore.getWorkflowStatusGridData()}}>
                                            Workflow Status
                                            </CustomButton>
                                            <CustomButton type={CustomButtonType.Submit} disabled={!processOptionStep2DataStore.statusOrHistory} onClick={()=>{processOptionStep2DataStore.getWorkflowHistoryStatusGridData()}}>
                                            Workflow History
                                            </CustomButton>
                                        </div>
                                    </div>
                                </Row>
                                <div className="flex">
                                <div className="innerflexwidths">
                                    <Row>
                                        <AgGridComponent gridConfig={this.getGridConfig('Process Tariff Option')}/>
                                    </Row>
                                </div>
                                <div className="innerflexwidths">
                                    <div className="flexColumn">
                                        <div style={{'width':'100%'}}>
                                            <Row style={{'marginTop': '0px'}}>
                                                <Col style={{'marginTop': '10px'}}>
                                                    <label className="standard_label_style">Step Description:</label>
                                                </Col>
                                            </Row>
                                            <Row style={{'marginTop': '0px'}}>
                                                <Col style={{'marginTop': '5px'}}>
                                                <label style={{'fontSize': '14px'}}>{processOptionStep2DataStore.stepDescription&&processOptionStep2DataStore.stepDescription||"Tariff setup step consists of creating Base Tariff & creating Tariff Log. It also consists of linking the base tariff and tariff log to the workflow. This step is performed by the Tariff department."}</label>
                                                </Col>
                                            </Row>
                                            <Row style={{'marginTop': '0px'}}>
                                                <Col style={{'marginTop': '10px'}} >
                                                    <label className="standard_label_style">Comment*:</label>
                                                    <textarea
                                                    style={{marginLeft: '20px', borderRadius: '5px', border: '1px solid #a3d0e4'}} 
                                                    value={processOptionStep2DataStore.formData.comment}
                                                    onChange={(e) => processOptionStep2DataStore.changeFormData(e.target.value, "comment")}
                                                    disabled={processOptionStep2DataStore.workflowStepStatus==null}
                                                    />
                                                </Col>
                                            </Row>
                                            </div>
                                            {this.showOnlyUS() && (
                                                <div className="rightFlexButtons">

                                                   {!(!processOptionStep2DataStore.workflowstepName || processOptionStep2DataStore.workflowstepName === '') && (<>{(processOptionStep2DataStore.href==='/process-clone-tariff-options'||processOptionStep2DataStore.href==='/process-tariff-infrastructure'||processOptionStep2DataStore.href==='/process-tariff-options' || processOptionStep2DataStore.href==='/process-tariff-infrastructure') && (
                                                        // <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.goToPreviousPage()}}>
                                                        //     &#9664; Go Back
                                                        // </CustomButton>
                                                        <></>
                                                        ) 
                                                        ||
                                                        (<CustomButton type={CustomButtonType.Submit} disabled={(!this.checkRoleAccessSaveCompleteStep())||(processOptionStep2DataStore.href==='/process-clone-tariff-options'&&Array.isArray(processOptionStep2DataStore.tariffOptions) && processOptionStep2DataStore.tariffOptions.length===0)} onClick={()=>{processOptionStep2DataStore.saveComments('reject')}}>
                                                        &#9664; Reject
                                                        </CustomButton>) 
                                                    }</>)}
                                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{history.push('/TariffDashboard')}}>
                                                        Cancel
                                                    </CustomButton>
                                                    {!(!processOptionStep2DataStore.workflowstepName || processOptionStep2DataStore.workflowstepName === '') &&
                                                    <CustomButton type={CustomButtonType.Submit} disabled={(!this.checkRoleAccessSaveCompleteStep())||(processOptionStep2DataStore.href==='/process-clone-tariff-options'&&Array.isArray(processOptionStep2DataStore.tariffOptions) && processOptionStep2DataStore.tariffOptions.length===0) || this.isforwardWorflowStepDsabled()} onClick={()=>{processOptionStep2DataStore.saveComments()}}>
                                                        <>{processOptionStep2DataStore.forwardButtonText} &#9654; </>  
                                                    </CustomButton>}
                                                </div>
                                            )}
                                            {!this.showOnlyUS() && processOptionStep2DataStore.workflowstepName==='Approve & Export' && (
                                                <div className="rightFlexButtons">
                                                    <CustomButton type={CustomButtonType.Submit} onClick={() => { processOptionStep2DataStore.rejectTariffRequest() }} disabled= {_.intersection(['PMCEasternNGLApproval', 'PMCWesternNGLApproval', 'PMCCrudeAssetsApproval'], accountStore.userRoles).length == 0}>
                                                        Reject
                                                    </CustomButton>
                                                    <CustomButton type={CustomButtonType.Submit} onClick={() => { processOptionStep2DataStore.approveTariffRequest() }} disabled= {_.intersection(['PMCEasternNGLApproval', 'PMCWesternNGLApproval', 'PMCCrudeAssetsApproval'], accountStore.userRoles).length == 0}>
                                                        Approve/Export &#9654;
                                                    </CustomButton>
                                                </div>
                                            )}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {this.props.processOptionStep2DataStore.showLoader == true &&
                        <div className = 'alert-overlay'> 
                        <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                        </div> 
                    }
                    </div>
                </Container>
            </div>
    );
}}
