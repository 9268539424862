import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { systemFeeMaintenanceDataStore } from '../stores';


export const SystemFeeDetailCellRenderer = (props: ICellRendererParams) => {    
    return (
            <span style={{color: 'blue'}} onClick={()=>systemFeeMaintenanceDataStore.openSystemFeeDetails(props.data)}>
                {props.data.systemGroup }
            </span>
    );
};