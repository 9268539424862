import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { agencyMaintenanceDataStore } from '../../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';


export const AgencyCodeCellRenderer = (props: ICellRendererParams) => {    
    return (

        !dataStore.checkOperationAccess(RoleBasedActionName.View) || (props.data && props.data.action && props.data.action == 'D') ? <span>{props.data && props.data.agencycode ? props.data.agencycode : ''}</span> : 
            <span style={{color: 'blue'}}
            onClick={()=>agencyMaintenanceDataStore.openAgencyDetails(props.data)}
            >
                {props.data ? props.data.agencycode : '' }</span>
    );
};