import React, { Component } from 'react';
import {
    ColDef
  } from 'ag-grid-community';
import { TariffOptionsIndexDataStore } from '../stores/tariff-options-data-store';
import { Portal } from 'react-overlays';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'common/components/ag-grid-component';
import { inject, observer } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import history from 'shared/components/header-component/component/history';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';

interface movementInterface {
  tariffOptionsDataStore: TariffOptionsIndexDataStore
  index: number,
  tariffOptionID: number,
  addMovement: (index: any) => void,
  compare?: boolean,
  allDisabled?:boolean
}

interface calendarContainerInterface {
  children: any;
}

@inject('tariffOptionsDataStore')
@observer
export class Movement extends Component<movementInterface> {

  CalendarContainer = (props: calendarContainerInterface) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{props.children}</Portal>;
  };

   onChangeData = (params, value, key, index) => {
    const {tariffOptionsDataStore} = this.props;
    if(key==='effectiveDate'||key==='endDate'||key==='noChange'||key==='accept'){
      const rowIndex = tariffOptionsDataStore.MovementData[index].findIndex(x=>x.rowId===params.data?.rowId)
      tariffOptionsDataStore.setMovementData(value, rowIndex, key, index, params);
    }
  } 

  colDefMovement: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      // (params) => {
      //   return this.props.compare && params.data?.parent===true?true:false;
      // },
      colId:'Select',
      // cellRenderer: 'checkboxRenderer',
      checkboxSelection: (params) => { return this.props.compare && params.data?.parent === true ? true: false},
      hide: !this.props.compare,
      width: 40,
      headerCheckboxSelection: false,
      suppressSizeToFit: true,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      cellStyle: () => {return {padding: '10px'}},
    },
    {
      headerName: 'Rel',
      field: 'rel',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      minWidth: 70,
      width: 70,
      hide: !this.props.compare,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
        headerName: 'Tariff Mov ID',
        field: 'tariffMvmtID',
        tooltipField: 'tariffMvmtID',
        editable: false,
        filter: true,
        sortable: true,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        width: 90,
        singleClickEdit: true,
        suppressSizeToFit: true
    },
    {
      headerName: 'Segment',
      field: 'lineSegDesc',
      editable: false,
      // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      minWidth: 200,
      flex: 2,
      cellEditor: 'agSelectCellEditor',
      singleClickEdit: true,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStore.mvtLineSegment[this.props.index].map(x=>x.lineSegDesc)
        };
      },
      rowGroup:this.props.compare? true : false,
      showRowGroup: this.props.compare? true : false,
  },
  {
      headerName: 'Rate Direction',
      field: 'rateDirection',
      editable: false,
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStore.mvtRateTypeDirection.map(x=>x.valueText)
        };
      },
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
      // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      minWidth: 130,
      flex: 1
  },
  {
    headerName: 'Rate Type',
    field: 'rateType',
    tooltipField: 'rateType',
    cellEditor: 'agSelectCellEditor',
    filter: true,
    editable: false,
    sortable: true,
    cellEditorParams: (params) => {
      return {
          values: this.props.tariffOptionsDataStore.mvtRateTypes.map(x=>x.tariffRateDesc)
      };
    },
    cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
    minWidth: 135,
    suppressSizeToFit: false,
    flex: 2
},

      // {
      //   headerName: 'No Change',
      //   field: 'noChange',
      //   headerClass: 'text-center',
      //   editable: false,
      //   filter: true,
      //   sortable: true,
      //   cellRenderer: params => {
      //     return (<input type='checkbox' checked={params.data?.noChange===true}  
      //     onClick={(e)=>{this.onChangeData(params, !params.data?.noChange, 'noChange', this.props.index)}}
      //     disabled={this.props.tariffOptionsDataStore.stepName!=='Process & Submit for Approval'||this.props.tariffOptionsDataStore?.workflowAction==='View'}/>);
      //   }, 
      //   cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
      //   cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      //   width: 110,
      //   singleClickEdit: true,
      //   suppressSizeToFit: true
      // },
  {
          headerName: 'Old Rate (cents)',
          field: 'oldRate',
          tooltipField: 'oldRate',
          editable: false,
          valueSetter: (params) => {
            if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
              params.data.oldRate=params.newValue.toUpperCase();
              return true
            } else {
              this.props.tariffOptionsDataStore.newRateIncorrectAlert();
              return false
            }
          },
          filter: true,
          sortable: true,
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          width: 140,
          minWidth: 140,
          singleClickEdit: true,
          // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
          suppressSizeToFit: true
      },
      {
        headerName: 'New Rate (cents)',
        field: 'newRate',
        tooltipField: 'newRate',
        filter: true,
        sortable: true,
        width: 140,
        minWidth: 140,
        editable: (params) => {
          return !params.data?.accept && !params.data?.noChange && this.props.tariffOptionsDataStore.stepName==='Process & Submit for Approval' && this.props.tariffOptionsDataStore?.workflowAction==='Process' ?  true : false 
        },
        valueSetter: (params) => {
          if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
            params.data.newRate=params.newValue.toUpperCase();
            this.props.tariffOptionsDataStore.changeNewRateMovementOverride(this.props.tariffOptionsDataStore.MovementData[this.props.index].findIndex(x=>x.rowId===params.data?.rowId), this.props.index, params.newValue)
            return true
          } else {
            this.props.tariffOptionsDataStore.newRateIncorrectAlert();
            return false
          }
        },
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        singleClickEdit: true,
        suppressSizeToFit: true
    },
    {
      headerName: 'Effective Date',
      field: 'effectiveDate',
      editable: false,
      // comparator: (a, b) => {
      //     const dateA = new Date(a);
      //     const dateB = new Date(b);
      //     if(dateA===dateB) return 0;
      //     else if(dateA>dateB) return 1;
      //     return -1           
      // },
      // sort: 'asc',
      // // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
      valueFormatter: ({ value, data }) => '',
      cellRenderer: (params) => {
          return (params.data ? (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.data?.effectiveDate && new Date(params.data?.effectiveDate) || null}
                onChange={(value)=>{this.onChangeData(params, value, 'effectiveDate', this.props.index)}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                className='datePickerGridInput'
                readOnly={true}
                />) : '')
      },
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      flex: 1
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    editable: false,
    // // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
    cellRenderer: (params) => {
        return (params.data &&  (<DatePicker
              popperClassName='ag-custom-component-popup'
              selected={params.value && new Date(params.value) || null}
              onChange={(value)=>{this.onChangeData(params, value, 'endDate', this.props.index)}}
              popperContainer={this.CalendarContainer}
              popperPlacement='top-start'
              readOnly={true}
              className='datePickerGridInput'
              />)) || params.value
    },
    cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
    flex: 1,
    minWidth: 130
},
      {
          headerName: 'Rate Basis',
          field: 'rateBasis',
          tooltipField: 'rateBasis',
          cellEditor: 'agSelectCellEditor',
          editable: false,
          minWidth: 162,
          cellEditorParams: (params) => {
            return {
                values: this.props.tariffOptionsDataStore.mvtRateBasis.map(x=>x.valueText)
            };
          },
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          suppressSizeToFit: false,
          flex: 2
      },
    {
          headerName: 'CEMA Rate',
          field: 'cemaRate',
          tooltipField: 'cemaRate',
          filter: true,
          valueSetter: (params) => {
            if(params.newValue.match(/^[0-9.]*$/)){
              params.data.cemaRate=params.newValue.toUpperCase();
              return true
            } else {
              return false
            }
          },
          editable: false,
          sortable: true,
          width: 110,
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          singleClickEdit: true,
          suppressSizeToFit: true
      },
      {
          headerName: 'Tariff Comment(Info Only)*',
          field: 'tariffComments',
          tooltipField: 'tariffComments',
          singleClickEdit: true,
          filter: true,
          sortable: true,
          minWidth: 175,
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          editable: false,
          suppressSizeToFit: false,
          flex: 2
      }
    ];
    getGridConfigMovement = (gridId) => {
      const {
        tariffOptionsDataStore: { agGridServiceMovement }
      } = this.props;
      const {tariffOptionsDataStore} = this.props;
      const gridParams: any = {
        rowData: tariffOptionsDataStore.MovementData?.length && tariffOptionsDataStore.MovementData[tariffOptionsDataStore.selectedTab]||[],
        columnDefs:this.colDefMovement,
        groupDisplayType: 'groupRows',
        domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
        context : agGridServiceMovement
        };
      return agGridServiceMovement.getGridConfig(gridParams, gridId);
    }

    deleteMovement = async (index) => {
      const { tariffOptionsDataStore } = this.props;
      const requestBody: any = await tariffOptionsDataStore.agGridServiceMovement.getSelectedRows();
      if(requestBody.length > 0) {
          console.log(requestBody);
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => tariffOptionsDataStore.deleteMovement(requestBody, index),
            'Delete',
            'Are you sure you want to delete the selected record/s?'
        );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {console.log(1)},
          ConfirmWithOK,
          {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }

    mergeMovement = async (index) => {
      const { tariffOptionsDataStore } = this.props;
      const requestBody: any = await tariffOptionsDataStore.agGridServiceMovement.getSelectedRows();
      if(requestBody.length > 0) {
          console.log(requestBody);
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => tariffOptionsDataStore.merge('Movement', requestBody,this.props.tariffOptionID),
            'Merge',
            'Are you sure you want to merge the selected record/s?'
        );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {console.log(1)},
          ConfirmWithOK,
          {secondaryText: 'No records are selected. Please select atleast one record to perform merge.'} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }

    checkRoleAccessSaveCompare = () => {
      const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ProjectRequestCompare);
      console.log(a, 'roleaccess');
      return a;
   }

  
render(): React.ReactNode {
    return (
    <>
      <Row>
          {this.props.compare && (<div className='LeftFlex'>
            <CustomButton disabled={!this.checkRoleAccessSaveCompare() || this.props.allDisabled} type={CustomButtonType.Submit} onClick={()=>{this.mergeMovement(this.props.index)}}>
            Merge
            </CustomButton>
            </div>)}
      </Row>
      <Row>
        <AgGridComponent gridConfig={this.getGridConfigMovement('Tariff Option Project Movement')}/>
      </Row>
      </>
    )}
  }