export enum LineSegmentMaintenanceMessages {
    SAVE = 'Saved Successfully',
    FETCH_ERROR_MESSAGE = 'Data could not be loaded',
    FAILED_SUBMIT = 'Failed to save changes',
    SYNC = 'Synced Successfully',
    SYNC_FAILED_SUBMIT = 'Failed to sync changes',
}

export enum LineSegmentMaintenanceButtons {
        SAVE = 'Save Data',
        RESET = 'Reset',
        CANCELLOAD = 'Cancel Load',
        CLEARALL = 'Clear All',
        BULKASSIGN = 'Bulk Assign'
}

export enum LineSegmentMaintenanceHeaderName {

}

export enum LineSegmentMaintenanceFieldName {

}

export const ServiceStatus = [
    {status: 'Active'},
    {status: 'Inactive'}
]

