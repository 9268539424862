import { ColDef } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { pmcDashboardDataStore } from '../stores';
import { TariffDetailIDCellRenderer } from './cell-renderer/tariff-detail-id';

export const pmcDashboardColDef: ColDef[] = [
    {   
        headerName: 'Tariff Number',
        field: 'tariffNumber', 
        colId:'tariffNumber',
        cellRenderer: TariffDetailIDCellRenderer, 
        minWidth: 130,
        filter: true,
        sortable: true,
        flex: 1,
    },
    { 
        headerName: 'Start Date', 
        field: 'startDate',
        colId: 'startDate',
        valueGetter: params => {
            if (params.data['startDate'] === null) {
                return '';
            } else {
                return moment(params.data['startDate'])
                .format(AppConstant.DATE_FORMAT);
            }
        },
        filter: true, 
        sortable: true,
        editable: false, 
        minWidth: 130,
        flex: 1
    },
    { 
        headerName: 'End Date', 
        field: 'endDate',
        colId: 'endDate',
        valueGetter: params => {
            if (params.data['endDate'] === null) {
                return '';
            } else {
                return moment(params.data['endDate'])
                .format(AppConstant.DATE_FORMAT);
            }
        },
        filter: true, 
        sortable: true, 
        minWidth: 130,
        flex: 1
    },
    {   
        headerName: 'Status',
        field: 'pmcTariffDetailStatus', 
        colId:'pmcTariffDetailStatus',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 90,
        flex: 1,
    },
    {   
        headerName: 'Asset Group',
        field: 'assetGroup', 
        colId:'assetGroup',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 140,
        flex: 1,
    },
    {   
        headerName: 'Pipeline System',
        field: 'pmcSystemGroup', 
        colId:'pmcSystemGroup',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 260,
        flex: 1,
    },
    {   
        headerName: 'Pipeline Region',
        field: 'pmcPipelineGroup', 
        colId:'pmcPipelineGroup',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 260,
        flex: 1,
    },
    { 
        headerName: 'Effective Date', 
        field: 'effectiveDate', 
        colId: 'effectiveDate',   
        valueGetter: params => {
            if (params.data['effectiveDate'] === null) {
                return '';
            } else {
                return moment(params.data['effectiveDate'])
                .format(AppConstant.DATE_FORMAT);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 125,
        flex: 1,
        hide: true
    },
    {   
        headerName: 'Operator',
        field: 'operator', 
        colId:'operator',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {   
        headerName: 'Description',
        field: 'additionalDesc', 
        colId:'additionalDesc',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    {   
        headerName: 'Facility ID',
        field: 'pmcFacility', 
        colId:'pmcFacility',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        flex: 1,
    },
    {   
        headerName: 'LSD',
        field: 'lsd', 
        colId:'lsd',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        flex: 1,
    },
    {   
        headerName: 'Destination',
        field: 'destination', 
        colId:'destination',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 210,
        flex: 1,
    },
    {   
        headerName: 'Tariff Application',
        field: 'tariffApplication', 
        colId:'tariffApplication',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 150,
        flex: 1,
    },
    {   
        headerName: 'Distribution',
        field: 'distribution', 
        colId:'distribution',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        flex: 1,
    },
    {   
        headerName: 'External System',
        field: 'externalSystem', 
        colId:'externalSystem',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 150,
        flex: 1,
    },
    {   
        headerName: 'Product Type',
        field: 'productType', 
        colId:'productType',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        flex: 1,
    },
    {   
        headerName: 'Product',
        field: 'product', 
        colId:'product',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 150,
        flex: 1,
    },
    {   
        headerName: 'Unit',
        field: 'unit', 
        colId:'unit',
        valueGetter: params => {
            return pmcDashboardDataStore.unitData.find(x=>x.settingID==params.data['unit'])?.valueText;
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 80,
        flex: 1,
    },
    {   
        headerName: 'Currency',
        field: 'currency', 
        colId:'currency',
        valueGetter: params => {
            return pmcDashboardDataStore.currencyData.find(x=>x.settingID==params.data['currency'])?.valueText;
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 100,
        flex: 1,
    },
    { 
        headerName: 'Primary Pipeline Tariff', 
        field: 'primaryPipelineTariff', 
        colId: 'primaryPipelineTariff',
        valueGetter: params => {
            if (params.data['primaryPipelineTariff'] === null) {
                return '';
            } else {
                return (Math.round(params.data['primaryPipelineTariff'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 170,
        flex: 1,
    },
    { 
        headerName: 'Secondary Pipeline Tariff', 
        field: 'secondaryPipelineTariff', 
        colId: 'secondaryPipelineTariff',  
        valueGetter: params => {
            if (params.data['secondaryPipelineTariff'] === null) {
                return '';
            } else {
                return (Math.round(params.data['secondaryPipelineTariff'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 200,
        flex: 1,
    },
    { 
        headerName: 'Surcharge', 
        field: 'surcharge', 
        colId: 'surcharge', 
        valueGetter: params => {
            if (params.data['surcharge'] === null) {
                return '';
            } else {
                return (Math.round(params.data['surcharge'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 110,
        flex: 1,
    },
    { 
        headerName: 'LMCI Abandonment S/C', 
        field: 'lmciAbandonmentS_C', 
        colId: 'lmciAbandonmentS_C',   
        valueGetter: params => {
            if (params.data['lmciAbandonmentS_C'] === null) {
                return '';
            } else {
                return (Math.round(params.data['lmciAbandonmentS_C'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 180,
        flex: 1,
    },
    { 
        headerName: 'Offload/Injection Fee', 
        field: 'offload_InjectionFee',
        colId: 'offload_InjectionFee', 
        valueGetter: params => {
            if (params.data['offload_InjectionFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['offload_InjectionFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 160,
        flex: 1,
    },
    { 
        headerName: 'Receipt Terminaling', 
        field: 'receiptTerminalling', 
        colId: 'receiptTerminalling',
        valueGetter: params => {
            if (params.data['receiptTerminalling'] === null) {
                return '';
            } else {
                return (Math.round(params.data['receiptTerminalling'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 160,
        flex: 1,
    },
    { 
        headerName: 'Quality Mgmt Fee', 
        field: 'qualityManagementFee', 
        colId: 'qualityManagementFee', 
        valueGetter: params => {
            if (params.data['qualityManagementFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['qualityManagementFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 150,
        flex: 1
    },
    { 
        headerName: 'Electronic Reporting', 
        field: 'electronicReportingFee', 
        colId: 'electronicReportingFee',
        valueGetter: params => {
            if (params.data['electronicReportingFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['electronicReportingFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        minWidth: 165,
        flex: 1
    },
    { 
        headerName: 'Total', 
        field: 'total', 
        colId: 'total',   
        valueGetter: params => {
            if (params.data['total'] === null) {
                return '';
            } else {
                return (Math.round(params.data['total'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 80,
        flex: 1,
    },
    { 
        headerName: 'Delivery Terminalling', 
        field: 'deliveryTerminalling', 
        colId: 'deliveryTerminalling',  
        valueGetter: params => {
            if (params.data['deliveryTerminalling'] === null) {
                return '';
            } else {
                return (Math.round(params.data['deliveryTerminalling'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 170,
        flex: 1,
    },
    { 
        headerName: 'Loss Allowance (% Stream)', 
        field: 'lossAllowance', 
        colId: 'lossAllowance',
        filter: true, 
        sortable: true, 
        minWidth: 200,
        flex: 1
    },
    { 
        headerName: 'Shrinkage (% Stream)', 
        field: 'shrinkage', 
        colId: 'shrinkage',
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 170,
        flex: 1
    },
    { 
        headerName: 'BS&W Penalty',
        field: 'bS_WPenalty', 
        colId: 'bS_WPenalty',
        valueGetter: params => {
            if (params.data['bS_WPenalty'] === null) {
                return '';
            } else {
                return (Math.round(params.data['bS_WPenalty'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 130,
        flex: 1
    },
    { 
        headerName: 'Intra Sys Transfer Fee',
        field: 'intraSystemTransferFee',
        colId: 'intraSystemTransferFee',  
        valueGetter: params => {
            if (params.data['intraSystemTransferFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['intraSystemTransferFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 170,
        flex: 1
    },
    { 
        headerName: 'Lump Sum Fee', 
        field: 'lumpSumFees', 
        colId: 'lumpSumFees',   
        valueGetter: params => {
            if (params.data['lumpSumFees'] === null) {
                return '';
            } else {
                return (Math.round(params.data['lumpSumFees'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 130,
        flex: 1,
    },
    { 
        headerName: 'Agreement', 
        field: 'agreement', 
        colId: 'agreement',   
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 110,
        flex: 1,
    },
    { 
        headerName: 'Agreement End Date', 
        field: 'agreementEndDate', 
        colId: 'agreementEndDate',   
        valueGetter: params => {
            if (params.data['agreementEndDate'] === null) {
                return '';
            } else {
                return moment(params.data['agreementEndDate'])
                .format(AppConstant.DATE_FORMAT);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 160,
        flex: 1,
    },
    { 
        headerName: 'Escalation Month', 
        field: 'escalationMonth', 
        colId: 'escalationMonth',   
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 160,
        flex: 1,
        valueGetter: params => {
            if (params.data['escalationMonth'] === null ||  params.data['escalationMonth'] === 'Select' ) {
                return '';
            } else {
                return params.data['escalationMonth'];
            }
        }
    },
    { 
        headerName: 'Escalation Amount', 
        field: 'escalationAmount', 
        colId: 'escalationAmount',   
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 160,
        flex: 1,
    },
    { 
        headerName: 'Regulator', 
        field: 'regulator', 
        colId: 'regulator',   
        valueGetter: params => {
            return pmcDashboardDataStore.regulatorData.find(x=>x.settingID==params.data['regulator'])?.valueText;
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 100,
        flex: 1,
    },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser', 
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 140,
        valueGetter: params => {
            if (params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        }
    },
    { 
        headerName: 'Created Date',
        field: 'createDate',
        colId: 'createDate',  
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 130,
        valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        } 
    },
    { 
        headerName: 'Updated User', 
        field: 'updateUser',
        colId: 'updateUser',   
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 140,
        valueGetter: params => {
            if (params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        }
    },
    { 
        headerName: 'Updated Date', 
        field: 'updateDate', 
        colId: 'updateDate',   
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 130,
        valueGetter: params => {
            if (params.data['updateDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }  
    },
];

