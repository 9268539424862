import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import { PushVmacsButtonComponent } from 'common/components/push-vmacs-button/push-vmacs-button-container';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { tariffDashboardDataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { LogIdCellRenderer, TariffNumberCellRenderer, ViewOptionsCellRenderer } from './cell-renderers/logId-cell-renderer';

export const inProgressLogsColDef: ColDef[] = [
    { 
        headerName: 'Log ID', 
        field: 'tariffLogId',
        colId: 'tariffLogId',
        filter: true, 
        sortable: true,
        editable: false, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},  
        width: 110, 
        valueGetter: params => {
            if (params.data['tariffLogId'] === null) {
                return '';
            } else {
                return params.data['tariffLogId'];
            }
        },
        cellRenderer: LogIdCellRenderer
    },{ 
        headerName: 'Tariff #', 
        field: 'tariffNumber',
        colId: 'tariffNumber',
        filter: true, 
        sortable: true, 
        minWidth: 120,
        singleClickEdit: true,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['tariffNumber'] === null) {
                return '';
            } else {
                return params.data['tariffNumber'];
            }
        },
        flex: 1,
        cellRenderer: TariffNumberCellRenderer,
    },
    { 
        headerName: 'Version', 
        field: 'tariffLogVersion', 
        colId: 'tariffLogVersion',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['tariffLogVersion'] === null) {
                return '';
            } else {
                return params.data['tariffLogVersion'];
            }
        },
        minWidth: 60,
        flex: 1
    },
    { 
        headerName: 'Tariff Log Type', 
        field: 'tariffClass', 
        colId: 'tariffClass',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['tariffClass'] === null) {
                return '';
            } else {
                return params.data['tariffClass'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Log Status', 
        field: 'tariffStatus', 
        colId: 'tariffStatus',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['tariffStatus'] === null) {
                return '';
            } else {
                return params.data['tariffStatus'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'CTS Effective Date', 
        field: 'ctsEffectiveDt', 
        colId: 'ctsEffectiveDt',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['ctsEffectiveDt'] === null) {
                return '';
            } else {
                return moment(params.data['ctsEffectiveDt']).format(AppConstant.DATE_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'Issue Date', 
        field: 'tariffIssueDate', 
        colId: 'tariffIssueDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['tariffIssueDate'] === null) {
                return '';
            } else {
                return moment(params.data['tariffIssueDate']).format(AppConstant.DATE_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'Effective Date', 
        field: 'tariffEffectiveDate', 
        colId: 'tariffEffectiveDate',
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['tariffEffectiveDate'] === null) {
                return '';
            } else {
                return moment(params.data['tariffEffectiveDate']).format(AppConstant.DATE_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'Project Name', 
        field: 'projectName', 
        colId: 'projectName',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 130,
        valueGetter: params => {
            if (params.data['projectName'] === null) {
                return '';
            } else {
                return params.data['projectName'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Description', 
        field: 'description', 
        colId: 'description',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 130,
        valueGetter: params => {
            if (params.data['description'] === null) {
                return '';
            } else {
                return params.data['description'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'View Options', 
        field: 'viewOptions', 
        colId: 'viewOptions',
        filter: true, 
        sortable: true, 
        editable: false,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        minWidth: 260,
        valueGetter: params => {
            if (params.data['wfStep'] === null) {
                return '';
            } else {
                return params.data['wfStep'];
            }
        },
        cellRenderer: ViewOptionsCellRenderer,
        flex: 2
    },
];

