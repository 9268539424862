import * as React from 'react';
import './_confirm.scss';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import { Button } from 'react-bootstrap';
export interface ConfirmProps {
    onClose: () => void;
    primaryText: string;
    secondaryText: string;
    onSuccessClick: () => void;
    resetData: () => void;
}

export class ConfirmWithDataReset extends React.Component<ConfirmProps> {
    public render(): React.ReactNode {
        const { primaryText, secondaryText, onClose } = this.props;
        return (
            <div className="confirm">
                <div className="confirm__warning mt-10">
                    <i className="fa fa-exclamation-triangle confirm-warning"></i>
                </div>
                <div className="confirm__primary-text mt-10">{primaryText}</div>
                <div className="confirm__secondary-text mt-10">{secondaryText}</div>
                <div className="confirm__action mt-30">
                    <CustomButton type={CustomButtonType.Ok} onClick={this.handleClick}>
                        Yes
                    </CustomButton>
                    <CustomButton type={CustomButtonType.Cancel} onClick={this.handleClose}>
                        No
                    </CustomButton>
                </div>
            </div>
        );
    }

    handleClose=() => {
        this.props.resetData();
        this.props.onClose();
    }

    handleClick = () => {
        this.props.onSuccessClick();
        this.props.onClose();
    };
}
