import * as React from 'react';
import DatePicker from 'react-datepicker';
import './react-datepicker.scss';
import className from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

interface ReactDatePickerProps {
    maxDate?: Date;
    selectedDate: Date | null;
    onDateChange: (date: Date) => void;
    dateFormat?: string;
    disabled?: boolean;
    configurePopper?: boolean;
    timeIntervals?: number;
    placeholder?: string;
    showTimeSelect?: boolean;
    classNames?: string;
    readOnly?: boolean;
}

const DatePickerDateTimeFormat = 'MM/dd/yyyy';

export class ReactDatePicker extends React.Component<ReactDatePickerProps> {
    public render(): React.ReactNode {
        const { onDateChange, selectedDate, placeholder, dateFormat, configurePopper, ...rest } = this.props;
        const dateTimeFormat = dateFormat ? dateFormat : DatePickerDateTimeFormat;
        const placeholderText = placeholder ? placeholder : 'Select a date';
        const dateProps = configurePopper
            ? {
                  ...rest,
                  ...this.getPopperConfig(),
                  dateFormat: dateTimeFormat
              }
            : { ...rest, dateFormat: dateTimeFormat };
        const classNames = className('datepicker__holder', this.props.classNames);
        return (
            <div className="datepicker">
                <DatePicker
                    className={classNames}
                    onChange={onDateChange}
                    selected={selectedDate}
                    placeholderText={placeholderText}
                    {...dateProps}
                />
            </div>
        );
    }

    getPopperConfig() {
        return {
            popperPlacement: 'botom',
            popperModifiers: {
                flip: {
                    enabled: false
                }
            }
        };
    }
}
