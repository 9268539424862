import React, { Component } from 'react';
import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import './step_1.scss';
import { ProjectRequestDataStore } from '../stores/project-request-data-store';
import { dataStore } from '../../../../common/stores';
import { observer, inject } from 'mobx-react';
import { ProjectRequestStep1DataStore } from '../stores/project-request-step-1-data-store';
import { ProjectRequestDetails } from './common/project-request-details';
import { WorkflowDetails } from './common/workflow-details';
import history from 'shared/components/header-component/component/history';
import { Attachments } from 'common/components/attachments/attachments';
import { AddNotes } from 'common/components/add-notes/add-notes';
import { ReactSelect } from 'common/components/select/select';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { tariffAttachmentsGridColDef } from 'features/tariff-management/pmc-tariff/components/pmc-tariff-col-defs';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'common/components/ag-grid-component';
import { projectRequestStep1DataStore, projectRequestStep2DataStore } from '../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { AppConstant } from 'app_constants';

/* eslint-disable */
interface ProjectRequestStep1ContainerProps {
    projectRequestDataStore: ProjectRequestDataStore;
    projectRequestStep1DataStore: ProjectRequestStep1DataStore;
}

interface ProjectRequestStep1ContainerState {
    rowSelected: boolean,
    lastTariffNoteID: number
}

@inject('projectRequestDataStore','projectRequestStep1DataStore') 
@observer
export class ProjectRequestStep1Container extends Component<ProjectRequestStep1ContainerProps, ProjectRequestStep1ContainerState> {

    constructor(props: ProjectRequestStep1ContainerProps | Readonly<ProjectRequestStep1ContainerProps>) {
        super(props);
        this.state = {
            rowSelected: false,
            lastTariffNoteID: 0
        };
        dataStore.setModuleName('Project Request Step 1')
    }

    componentDidMount() {
      const {projectRequestStep1DataStore, projectRequestDataStore} = this.props;
      if(!projectRequestDataStore.workflowId){
        history.push('/ProjectRequest')
      }else{
          projectRequestStep1DataStore.init();
      }
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', this.backButtonDisabled)
    }

    componentWillUnmount() {
        window.removeEventListener('popstate', this.backButtonDisabled)
    }

    backButtonDisabled(event){
            window.history.pushState(null, document.title,  window.location.href);
            alert('Back button is not allowed in Project Request! Use \'Cancel\' to go back.')
    }

    onChangePipeline = (selectedList, selectedItem) => {
        this.props.projectRequestStep1DataStore.setPipeline(selectedList);
    };

    getGridConfigTariffDetail(gridId) {
        const {
            projectRequestStep1DataStore: { agGridService }
        } = this.props;
        const { projectRequestStep1DataStore } = this.props;
        const columnDefs = projectRequestStep1DataStore.getTariffDetailColDef();
        const rowData = projectRequestStep1DataStore.PmcGridViewData;
        
        const pmcGridViewGridParams: any = {
            rowData,
            columnDefs
        };
        return agGridService.getGridConfig(pmcGridViewGridParams, gridId);
    }

    onSelectSystemGroup = (e) => {
        projectRequestStep1DataStore.setSystemGroup(e);
    };

    onSelectPipelineGroup = (e) => {
        projectRequestStep1DataStore.setPipelineGroup(e);
    };

    onSelectEffectiveStartDate = (e) => {
        projectRequestStep1DataStore.setEffectiveStartDate(e);
    };

    onSelectEffectiveEndDate = (e) => {
        projectRequestStep1DataStore.setEffectiveEndDate(e);
    };

    handleSelect(key) {
        if (key == 'review') {
            projectRequestStep1DataStore.tariffReviewTabClicked();
        } else {
            projectRequestStep1DataStore.showTariffReviw = false;
        }
    }

    onSaveNotes = () => {
        projectRequestStep1DataStore.saveTariffNotes();
    };

    onDeleteNote = (noteID) => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(() => {
            projectRequestStep1DataStore.deleteTariffNote(noteID);
        },
            'Delete',
            'Are you sure you want to delete the selected note?'
        );
        projectRequestStep1DataStore.NotesGridData.length > 0 ? projectRequestStep1DataStore.isTariffNoteAdded = true : false;
    };

    onAddNewNote() {
        const workflowDocumentID = this.state.lastTariffNoteID - 1;
        const rowData = [{
            workflowDocumentID: workflowDocumentID,
            workflowID: projectRequestStep1DataStore.workflowId,
            notes: '',
            createUser: projectRequestStep1DataStore.username,
            createDate: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            updateUser: null,
            updateDate: null,
            workflow: null,
        } as unknown as any, ...projectRequestStep1DataStore.notesGridData];
        projectRequestStep1DataStore.setPMCTariffNoteGridData(rowData);
        this.setState({ ...this.state, lastTariffNoteID: workflowDocumentID });
        projectRequestStep1DataStore.NotesGridData.length > 0 ? projectRequestStep1DataStore.isTariffNoteAdded = true : false;
    };

    getAttachmentsColDef() {
        return tariffAttachmentsGridColDef;
    }

    getAttachmentsGridConfig() {
        const {
            projectRequestStep1DataStore: { fileUploadGridData, agGridServiceForAddAttachment },
        } = this.props;
        const tariffAttachmentsGridParams: any = {
            rowData: fileUploadGridData,
            columnDefs: this.getAttachmentsColDef(),
        };
        return agGridServiceForAddAttachment.getGridConfig(tariffAttachmentsGridParams);
    }

    uploadFile(e) {
        projectRequestStep1DataStore.uploadFile(e);
    };

    downloadAttachment(fileID) {
        projectRequestStep1DataStore.downloadAttachment(fileID);
    };

    deleteAttachment = async (files) => {
        const requestBody: any = await projectRequestStep1DataStore.agGridService.getSelectedRows();
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => {
                projectRequestStep1DataStore.deleteAttachment(files);
            },
            'Delete',
            'Are you sure you want to delete the selected attachment?'
        );
    };

    updateAttachment = () => {
        projectRequestStep1DataStore.updateAttachment();
    }

    checkRoleAccessSave = () => {
        const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ProjectRequest);
        return a;
     }
 

    render(): React.ReactNode {
        const { projectRequestDataStore, projectRequestStep1DataStore} = this.props;
        return (
            <div className='ProjectRequestBoxStep1'>
                <Container fluid>
                    <div className='boxes'>
                        <ProjectRequestDetails datastore={projectRequestStep1DataStore} projectRequestDatastore={projectRequestDataStore} showOnlyUS={projectRequestStep1DataStore.showOnlyUS} onChangePipeline={this.onChangePipeline} allDisabled={false} checkRoleAccessSave = {this.checkRoleAccessSave()}/>
                        
                        {!projectRequestStep1DataStore.showOnlyUS() && (
                            <Tabs
                                defaultActiveKey="tariffSetup"
                                className="screenHeaderTabs"
                                onSelect={this.handleSelect}
                            >
                                <Tab eventKey="tariffSetup" title="Select Tariff">
                                    <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Select Tariff</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 10px' }}>
                                            <Row style={{ 'marginTop': '0px' }}>
                                                <Col className='tariffCol'>
                                                    <label className="standard_label_style">Pipeline System</label>
                                                    <ReactSelect
                                                        className='expanded_select'
                                                        values={projectRequestStep1DataStore.selectedSystemGroup}
                                                        placeholder="Select"
                                                        options={[{ value: 'All', label: 'All' }, ...projectRequestStep1DataStore.systemGroupDropdownOptions]}
                                                        onChange={this.onSelectSystemGroup}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                    />
                                                </Col>
                                                <Col className='tariffCol'>
                                                    <label className='standard_label_style'>Pipeline Region</label>
                                                    <ReactSelect
                                                        className='expanded_select'
                                                        values={projectRequestStep1DataStore.selectedPipelineGroup}
                                                        placeholder="Select"
                                                        options={[{ value: 'All', label: 'All' }, ...projectRequestStep1DataStore.pipelineGroupDropdownOptions]}
                                                        onChange={this.onSelectPipelineGroup}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                    />
                                                </Col>
                                                <Col className='tariffCol'>
                                                    <label className="standard_label_style">Start Date</label>
                                                    <DatePicker
                                                        className='datepicker__holder'
                                                        selected={projectRequestStep1DataStore.selectedEffectiveStartDate}
                                                        onChange={(e) => {
                                                            this.onSelectEffectiveStartDate(e);
                                                        }}
                                                        // dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                    />
                                                </Col>
                                                <Col className='tariffCol'>
                                                    <label className="standard_label_style">End Date</label>
                                                    <DatePicker
                                                        className='datepicker__holder'
                                                        selected={projectRequestStep1DataStore.selectedEffectiveEndDate}
                                                        onChange={(e) => {
                                                            this.onSelectEffectiveEndDate(e);
                                                        }}
                                                        // dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                    />
                                                </Col>
                                            </Row>
                                            <div className='searchButtonDiv'>
                                                <CustomButton type={CustomButtonType.Submit} onClick={() => projectRequestStep1DataStore.onSearchClick()} disabled={false}>
                                                    Search
                                            </CustomButton>
                                                <CustomButton type={CustomButtonType.Submit} onClick={() => projectRequestStep1DataStore.onClearSearch()} disabled={false}>
                                                    Clear
                                            </CustomButton>
                                            </div>
                                            {this.props.projectRequestStep1DataStore.showLoader == true &&
                                                <div className='alert-overlay'>
                                                    <div style={{ margin: 'auto' }} className='dots-1'></div>
                                                </div>
                                            }
                                            <div>
                                                <Row>
                                                    <AgGridComponent gridConfig={this.getGridConfigTariffDetail('PMC Tariff Management')} />
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="review" title="Review">
                                    <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Review</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 10px' }}>
                                            <Row style={{ 'marginTop': '0px' }}>
                                                {projectRequestStep1DataStore.showTariffReviw && projectRequestStep1DataStore.selectedTariffs.length > 0 &&
                                                    <Tabs
                                                        defaultActiveKey="tariffs"
                                                        className="screenHeaderTabs"
                                                        style={{ 'marginBottom': '20px' }}
                                                    >
                                                        {projectRequestStep1DataStore.reviewTariffDetails.length > 0 && projectRequestStep1DataStore.reviewTariffDetails.map((item, index) => (
                                                            <Tab eventKey={index == 0 ? 'tariffs' : index} title={item.tariffNumber}>
                                                                <div className='reviewTabSelectContainer'>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style_review_header">Tariff Details:</label>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_review_header">Prior Fees:</label>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style_review_header">{item.hasParent ? 'Updated Fees:' : 'Fees'}</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Tariff Number:</label>
                                                                            <span className="tariffValue">{item.tariffNumber}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Primary Pipeline Tariff:</label>
                                                                            <span className="tariffValue">{item.primaryPipelineTariffParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Primary Pipeline Tariff:</label>
                                                                            <span className={item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.primaryPipelineTariff.toFixed(2)} {item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? item.primaryPipelineTariffChangePercent === 'New' ? "(" + item.primaryPipelineTariffChangePercent + ")" : "(" + item.primaryPipelineTariffChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Asset Group:</label>
                                                                            <span className="tariffValue">{item.assetGroup}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Secondary Pipeline Tariff:</label>
                                                                            <span className="tariffValue">{item.secondaryRateParent? item.secondaryRateParent.toFixed(2) : 0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Secondary Pipeline Tariff:</label>
                                                                            <span className={item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.secondaryRate.toFixed(2)} {item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0 ? item.secondaryRateChangePercent === 'New' ? "(" + item.secondaryRateChangePercent + ")" : "(" + item.secondaryRateChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Start Date:</label>
                                                                            <span className="tariffValue">{item.startDate ? moment(item.startDate).format(AppConstant.DATE_FORMAT) : item.startDate}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Surcharge:</label>
                                                                            <span className="tariffValue">{item.surchargeParent ? item.surchargeParent.toFixed(2) :0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Surcharge:</label>
                                                                            <span className={item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.surcharge.toFixed(2)} {item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? item.surchargeChangePercent === 'New' ? "(" + item.surchargeChangePercent + ")" : "(" + item.surchargeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">End Date:</label>
                                                                            <span className="tariffValue">{item.endtDate ? moment(item.endtDate).format(AppConstant.DATE_FORMAT) : item.endtDate}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">LMCI Abandonment S/C:</label>
                                                                            <span className="tariffValue">{item.lmciAbandonmentSCParent ?  item.lmciAbandonmentSCParent.toFixed(2) : 0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>LMCI Abandonment S/C:</label>
                                                                            <span className={item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.lmciAbandonmentSC.toFixed(2)} {item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? item.lmciAbandonmentSCChangePercent === 'New' ? "(" + item.lmciAbandonmentSCChangePercent + ")" : "(" + item.lmciAbandonmentSCChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Pipeline System:</label>
                                                                            <span className="tariffValue">{item.pipelineSystem}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Offload/Injection Fee:</label>
                                                                            <span className="tariffValue">{item.offloadInjectionFeeParent ? item.offloadInjectionFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Offload/Injection Fee:</label>
                                                                            <span className={item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.offloadInjectionFee.toFixed(2)} {item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? item.offloadInjectionFeeChangePercent === 'New' ? "(" + item.offloadInjectionFeeChangePercent + ")" : "(" + item.offloadInjectionFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Pipeline Region:</label>
                                                                            <span className="tariffValue">{item.pipelineRegion}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Receipt Terminaling:</label>
                                                                            <span className="tariffValue">{item.receiptTerminallingParent? item.receiptTerminallingParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Receipt Terminaling:</label>
                                                                            <span className={item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.receiptTerminalling.toFixed(2)} {item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? item.receiptTerminallingChangePercent === 'New' ? "(" + item.receiptTerminallingChangePercent + ")" : "(" + item.receiptTerminallingChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Operator:</label>
                                                                            <span className="tariffValue">{item.operator}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Quality Management Fee:</label>
                                                                            <span className="tariffValue">{item.qualityManagementFeeParent?item.qualityManagementFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Quality Management Fee:</label>
                                                                            <span className={item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.qualityManagementFee.toFixed(2)} {item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? item.qualityManagementFeeChangePercent === 'New' ? "(" + item.qualityManagementFeeChangePercent + ")" : "(" + item.qualityManagementFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Description:</label>
                                                                            <span className="tariffValue">{item.additionalDesc}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Electronic Reporting Fee:</label>
                                                                            <span className="tariffValue">{item.electronicReportingFeeParent? item.electronicReportingFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Electronic Reporting Fee:</label>
                                                                            <span className={item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.electronicReportingFee.toFixed(2)} {item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? item.electronicReportingFeeChangePercent === 'New' ? "(" + item.electronicReportingFeeChangePercent + ")" : "(" + item.electronicReportingFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Facility ID:</label>
                                                                            <span className="tariffValue">{item.facilityID}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Total:</label>
                                                                            <span className="tariffValue">{item.totalParent? item.totalParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Total:</label>
                                                                            <span className={item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.total.toFixed(2)} {item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? item.totalChangePercent === 'New' ? "(" + item.totalChangePercent + ")" : "(" + item.totalChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">LSD:</label>
                                                                            <span className="tariffValue">{item.lsd}</span>
                                                                        </Col>
                                                                        
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Destination:</label>
                                                                            <span className="tariffValue">{item.destination}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Tariff Application:</label>
                                                                            <span className="tariffValue">{item.tariffApplication}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Delivery Terminaling:</label>
                                                                            <span className="tariffValue">{item.deliveryTerminallingParent? item.deliveryTerminallingParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Delivery Terminaling:</label>
                                                                            <span className={item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.deliveryTerminalling.toFixed(2)} {item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? item.deliveryTerminallingChangePercent === 'New' ? "(" + item.deliveryTerminallingChangePercent + ")" : "(" + item.deliveryTerminallingChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Distribution:</label>
                                                                            <span className="tariffValue">{item.distribution}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Loss Allowance (% Stream):</label>
                                                                            <span className="tariffValue">{item.lossAllowanceTextParent}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Loss Allowance (% Stream):</label>
                                                                            <span className={ item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? "tariffValueChanged" : "tariffValue"}>{item.lossAllowanceText} { item.lossAllowanceTextChangePercent == 'New' ? '(New)' : item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? '(Changed)' : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">External System:</label>
                                                                            <span className="tariffValue">{item.externalSystem}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Shrinkage (% Stream):</label>
                                                                            <span className="tariffValue">{item.shrinkageTextParent}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Shrinkage (% Stream):</label>
                                                                            <span className={ item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? "tariffValueChanged" : "tariffValue"}>{item.shrinkageText} { item.shrinkageTextChangePercent == 'New' ? '(New)' : item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? '(Changed)' : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Product Type:</label>
                                                                            <span className="tariffValue">{item.productType}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">BS&W Penalty Fee:</label>
                                                                            <span className="tariffValue">{item.bswPenaltyParent?item.bswPenaltyParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>BS&W Penalty Fee:</label>
                                                                            <span className={item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.bswPenalty.toFixed(2)} {item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? item.bswPenaltyChangePercent === 'New' ? "(" + item.bswPenaltyChangePercent + ")" : "(" + item.bswPenaltyChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Product:</label>
                                                                            <span className="tariffValue">{item.product}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Intra System Transfer Fee:</label>
                                                                            <span className="tariffValue">{item.intraSystemTransferFeeParent?item.intraSystemTransferFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Intra System Transfer Fee:</label>
                                                                            <span className={item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.intraSystemTransferFee.toFixed(2)} {item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? item.intraSystemTransferFeeChangePercent === 'New' ? "(" + item.intraSystemTransferFeeChangePercent + ")" : "(" + item.intraSystemTransferFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Unit:</label>
                                                                            <span className="tariffValue">{item.unit}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}>
                                                                            <label className="standard_label_style_tariff">Lump Sum Fee:</label>
                                                                            <span className="tariffValue">{item.lumpSumFeeParent?item.lumpSumFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Lump Sum Fee:</label>
                                                                            <span className={item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.lumpSumFee.toFixed(2)} {item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? item.lumpSumFeeChangePercent === 'New' ? "(" + item.lumpSumFeeChangePercent + ")" : "(" + item.lumpSumFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Currency</label>
                                                                            <span className="tariffValue">{item.currency}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Regulator:</label>
                                                                            <span className="tariffValue">{item.regulator }</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? { display: 'block' } : { display: 'none' }}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                </div>
                                                            </Tab>
                                                        ))}
                                                    </Tabs>
                                                }
                                                {projectRequestStep1DataStore.showTariffReviw && projectRequestStep1DataStore.selectedTariffs.length == 0 &&
                                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                        No tariff has been selected.
                                                </div>
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="notesAndAttachments" title="Notes & Attachments">
                                    <div className='selectContainer'>
                                        <div style={{ width: '100%', marginTop: '12px' }}>
                                            <Row>
                                                <AddNotes saveRoleAccess={() => true} disableAddBtn={false} disableSaveBtn={!projectRequestStep1DataStore.isTariffNoteAdded} disableDeleteBtn={false} isNoteSaved={projectRequestStep1DataStore.isTariffNoteSaved} onAddNewNoteCallback={() => this.onAddNewNote()} onSaveNotesCallback={() => this.onSaveNotes()} onDeleteNoteCallback={(data) => this.onDeleteNote(data.workflowNoteID)} agGridService={projectRequestStep1DataStore.agGridService} notesGridData={projectRequestStep1DataStore.notesGridData} cellValueChangeMap={projectRequestStep1DataStore.cellValueChangeMap} updateRow={(rowData) => projectRequestStep1DataStore.updateRow(rowData)} ></AddNotes>
                                            </Row>
                                        </div>
                                        <div style={{ width: '100%', marginTop: '12px'  }}>
                                            <Row>
                                                <Attachments saveRoleAccess={true} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.workflowDocumentID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.workflowDocumentID)} agGridService={projectRequestStep1DataStore.agGridService} fileUploadGridData={projectRequestStep1DataStore.fileUploadGridData}  cellValueChangeMap={projectRequestStep1DataStore.cellValueChangeMapAttachment} updateRow={(rowData) => projectRequestStep1DataStore.updateRowAttachment(rowData)}></Attachments>
                                            </Row>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                        
                        <WorkflowDetails datastore={projectRequestStep1DataStore} stepName={'step1'} />
                        {projectRequestStep1DataStore.formData && <></>}
                        {projectRequestStep1DataStore.statusOrHistory && <></>}
                        {projectRequestStep1DataStore.projectRequestSaved && <></>}
                        {projectRequestStep1DataStore.workflowStatusGridData && <></>}
                        {projectRequestDataStore && <></>}
                    </div>
                </Container>
            </div>
    );
}}
