import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { gradeColDef } from '../components/grade-col-def';
import moment from 'moment';
import Path from '../../../../Path';
import axios from 'axios';
import { GradeMaintenanceDataService } from '../services/grade-maintenance-data-service';
import { GradeMaintenanceResponse } from '../model/model';
import { GradeMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { addEditGradeMaintenanceDataStore, gradeMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class GradeMaintenanceDataStore {
    @observable gradeMaintenanceData: GradeMaintenanceResponse[] = [];
    @observable modifiedGradeMaintenanceData: GradeMaintenanceResponse[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupGradeMaintenanceData: GradeMaintenanceResponse[] = [];
    isRefreshed = false;
    @observable showModal = false;
    @observable selectedgrade = '';
    @observable selectedgradeName = '';
    @observable selectedStatus = 'All';
    @observable selectedPipelineSystem = { value: 'All', label: 'All' };
    endDate: any = moment().toISOString().slice(0, 10);
    @observable pipelineSystemDropdownOptions: any[] = [];
    @observable statusDropdownOptions: any[] = ServiceStatus;
    @observable selectedGradeIds: any[] = [];
    tariffStatesData: any[] = [];
    pipelineSystemData: any[] = [];
    baseSystemData: any[] = [];
    
    @observable pipelineSystemDropdownOption: any[] = [];
    @observable locnNameDropdownOption: any[] = [];
    @observable selectedGradeDetails: any = {};
    @observable addOrEditedLocnName = '';
    @observable selectedGradeId = 0;
    @observable addOrEditGradeGridData: any[] = [];
    @observable backupAddOrEditGradeGridData: any[] = [];
    @observable onClickGradeNameOnGrid = false;
    @observable onClickAddNewGradeButton = false;
    @observable isActivateButtonDisabled = true;
    @observable updatedFieldonGradeDetailsScreen = false;

    constructor(
        public gradeMaintenanceDataService: GradeMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditGrade: AgGridService
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        this.pipelineSystemDropdownOptions = [];
        dataStore.setShowUnsavedWarningAlert(false);
        this.getPipelineSystemDropdown();
        this.getBaseSystemDropdown();
        // this.getLocationDropdownData();
        this.selectedgrade = '';
        this.selectedgradeName = '';
        this.selectedStatus = 'All';
        this.selectedPipelineSystem = { value: 'All', label: 'All' };
        // this.loadViewModel();
        // this.userID = this.accountStore.getUserID();
        // this.userName = this.accountStore.displayName;
        // this.onSelectJurisdiction(this.selectedlocnName);
        this.userName = this.accountStore.displayName;
        this.selectedGradeId = 0;
        this.selectedGradeDetails = {};
    }

    @Loader
    @action
    @Catch(() => errorHandler(GradeMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel(): Promise<void> {
        const grade = this.selectedgrade === '' ? 'All' : this.selectedgrade;
        const gradeName = this.selectedgradeName === '' ? 'All' : this.selectedgradeName;
        const response: GradeMaintenanceResponse[] = await this.gradeMaintenanceDataService.getGradesData();
        runInAction(() => {
            this.showLoader = false;
            this.modifiedGradeMaintenanceData = response;
            this.gradeMaintenanceData = response;
            this.setGradeMaintenanceData(response);
            this.setbackupGradeMaintenanceData(response);
            this.createDataSourceGroupByGrade(response);
            this.isRefreshed = false;
            this.selectedGradeId = 0;
            this.selectedGradeDetails = {};
        });
        this.agGridService.getNodes()?.refreshCells();   
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineSystemDropdown(): Promise<void> {
        const pipelineSystemDropdownOptions = await this.gradeMaintenanceDataService.getPipelineSystems();
        this.pipelineSystemData = pipelineSystemDropdownOptions;
        const a = (pipelineSystemDropdownOptions.map(x => x.plName));
        const b =  a.filter((x , index, arr) => arr.indexOf(x) === index)
        const option: any = [];
        b.forEach((item) => {
            option.push({ 'value': item, 'label': item})
        })
        runInAction(() => {
            this.showLoader = false;
            this.pipelineSystemDropdownOption = option;
            this.resetAddGradeMaintenanceGridData();
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemDropdown(): Promise<void> {
        const baseSystemDropdownOptions = await this.gradeMaintenanceDataService.getBaseSystems();
        this.baseSystemData = baseSystemDropdownOptions;
    }

    @action
    createDataSourceGroupByGrade(response) {
        const array = response
        const result = array.reduce((r, { locnName: locnName, ...object }) => {
        let temp = r.find(o => o.locnName === locnName);
        if (!temp) r.push(temp = { locnName, children: [] });
        temp.children.push(object);
        return r;
    }, []);
    
    console.log(result);
    }

    @Loader
    @Catch(() => errorHandler(GradeMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        const response: GradeMaintenanceResponse[] = await this.gradeMaintenanceDataService.getGradesData();
        runInAction(() => {
            this.showLoader = false;
            this.modifiedGradeMaintenanceData = response;
            this.gradeMaintenanceData = response;
            this.setGradeMaintenanceData(response);
            this.setbackupGradeMaintenanceData(response);
            this.createDataSourceGroupByGrade(response);
            this.isRefreshed = false;
           this.selectedgrade = 'All';
            this.selectedgradeName = 'All';
            this.selectedPipelineSystem = { value: 'All', label: 'All' };
            this.selectedStatus = 'All';
            this.selectedGradeId = 0;
            this.selectedGradeDetails = {};
        });
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @Catch(() => errorHandler(GradeMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
       const pipelineSystemId = this.selectedPipelineSystem.label === 'All' ? 0 : this.pipelineSystemData.filter((system) => system.plName === this.selectedPipelineSystem.label)[0].pipelineID;
        const grade = this.selectedgrade === '' ? 'All' : this.selectedgrade;
        const gradeName = this.selectedgradeName === '' ? 'All' : this.selectedgradeName;
    
        const serviceStatus = this.selectedStatus === 'All' ? this.selectedStatus : this.selectedStatus === 'Active' ? 'A' : 'I';
        const response: GradeMaintenanceResponse[] = await this.gradeMaintenanceDataService.getGradesDataWithFilter(grade,gradeName,pipelineSystemId  );
        runInAction(() => {
            this.showLoader = false;
            this.modifiedGradeMaintenanceData = response;
            this.gradeMaintenanceData = response;
            this.setGradeMaintenanceData(response);
            this.selectedGradeId = 0;
            this.selectedGradeDetails = {};
            // this.setbackupGradeMaintenanceData(response);
            this.isRefreshed = false;
        })
        this.agGridService.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    getColDef() {
        // this.updatePushtoVmacsButtonColDef();
        return gradeColDef;
    }

    @action
    reset(): void {
        this.setGradeMaintenanceData(this.backupGradeMaintenanceData);
        this.modifiedGradeMaintenanceData = this.backupGradeMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setGradeMaintenanceData(gradeMaintenanceData: GradeMaintenanceResponse[]): void {
        this.gradeMaintenanceData = gradeMaintenanceData
    }

    setbackupGradeMaintenanceData(backUpList: GradeMaintenanceResponse[]) {
        this.backupGradeMaintenanceData = backUpList;
    }

    @computed
    get GradeMaintenanceData() {
        return toJS(this.gradeMaintenanceData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(GradeMaintenanceMessages.FAILED_SUBMIT))
    async saveGrade(saveAndAddNew): Promise<void> {
        const updatedRowIDs = this.addOrEditGradeGridData.filter(a => a.isSelected === true);
        const gradeDetailDTOs: any = [];
        let requestBody: any = {};
        const localValidatingErrorBody: any = [];
        if(this.addOrEditedLocnName && this.addOrEditedLocnName !== '') {
            const filteredGradeData = this.gradeMaintenanceData.filter((grade) => grade.gradeCode === this.addOrEditedLocnName);
            const existingGrade  = filteredGradeData.some((grade) => grade.gradeID !==  this.selectedGradeId)
            const isUniqueGrade = this.onClickGradeNameOnGrid ? !existingGrade : filteredGradeData.length <=0;
            if(isUniqueGrade) {
                updatedRowIDs.forEach((item) => {
                    const serviceDate = item.serviceDate ? moment.utc(new Date(item.serviceDate)).format('YYYY-MM-DDTHH:mm:ss')  : null;
                    item.gradeRelPLID = item.gradeRelPLID && item.gradeRelPLID > 0 ? item.gradeRelPLID: 0,
                    item.pipelineID = item.pipelineID && item.pipelineID > 0 ? item.pipelineID : 0,
                    item.serviceDate = serviceDate,
                    item.serviceStatus = item.serviceStatus === 'Active' || item.serviceStatus === 'A' ?  'A' : 'I'
                    if(item.serviceDate) {
                        gradeDetailDTOs.push(item);
                    } else {
                        const message = 'Please select valid service date for the linked pipelines';
                        if(!localValidatingErrorBody.includes(message)) {
                            localValidatingErrorBody.push(message);
                        }
                    }
                });
            } else {
                const message = 'Tried to create duplicate grade. Please create the unique record.';
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            } 
        } else {
            const message = 'Please insert valid Grade Name as mandatory fields.';
            if(!localValidatingErrorBody.includes(message)) {
                localValidatingErrorBody.push(message);
            }
        }
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(updatedRowIDs.length === gradeDetailDTOs.length) {
            requestBody = { gradeID: this.selectedGradeId, 
                locnName: this.addOrEditedLocnName, 
                createUser: this.userName,
                gradeDetailDTOs: gradeDetailDTOs,
            } 
            const response: GradeMaintenanceResponse[] = await this.gradeMaintenanceDataService.updateGradeMaintenanceData(requestBody, this.onClickGradeNameOnGrid);
            if (response) {
                dataStore.setShowUnsavedWarningAlert(false);
                this.onClickGradeNameOnGrid = false; 
                this.cellValueChangeMap = {};
                this.selectedGradeId = 0;
                this.selectedGradeDetails = {};
                this.updatedFieldonGradeDetailsScreen = false;
                this.uiService.toastService.success(GradeMaintenanceMessages.SAVE);
                if(!saveAndAddNew) {
                    this.showLoader = false;
                    this.isRefreshed = false;
                    this.agGridServiceForAddOrEditGrade.destroyGrid();
                    this.onClickAddNewGradeButton = false;
                    this.onCloseGradeDetails();
                } else {
                    this.resetAddGradeMaintenanceGridData();
                }

            }
        }
    }


    @action
    async resetAddGradeMaintenanceGridData(): Promise<void> {
            this.addOrEditedLocnName = '';
            this.addOrEditGradeGridData = [];
            this.backupAddOrEditGradeGridData = []
            this.pipelineSystemData.map((item) =>
             {
                item.serviceStatus =  'Active',
                item.serviceDate =  null,
                item.allowTrades = 'N'
                item.isSelected = false
                this.addOrEditGradeGridData.push(item)
            });
            this.backupAddOrEditGradeGridData = this.addOrEditGradeGridData;
            this.onClickGradeNameOnGrid = false;
            this.isRefreshed = false
        this.agGridServiceForAddOrEditGrade.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(GradeMaintenanceMessages.FAILED_SUBMIT))
    async deleteGrade(): Promise<void> {
        const requestBody = { 
            gradeID: this.selectedGradeId, 
            locnName:this.addOrEditedLocnName,
            createUser: this.userName, 
            gradeDetailDTOs: [] 
        }
        await this.gradeMaintenanceDataService.deleteGradeMaintenanceData(requestBody).then((response) => {
            if(response && response.value) {
                const confirmService = new ConfirmService();
                confirmService.showConfirmWithCustomComponent(
                  () => {console.log('')},
                  ConfirmWithOK,
                  {secondaryText: response.value !== '' ? response.value : 'Record cannot be deleted because it is linked to a base system. Unlink grade from base system.'} as ConfirmWithOKProps,
                  'confirmPopup'
                );
                const gradeData = {locnName: gradeMaintenanceDataStore.addOrEditedLocnName}
             //   gradeMaintenanceDataStore.openGradeDetails(gradeData);
                return ;
            }
            dataStore.setShowUnsavedWarningAlert(false);
            this.onClickGradeNameOnGrid = false;
            this.onClickAddNewGradeButton = false;
            this.agGridServiceForAddOrEditGrade.destroyGrid();
            this.isActivateButtonDisabled= true;
            this.updatedFieldonGradeDetailsScreen = false;
            this.onCloseGradeDetails();
        });
    }



 addValuesInCellDropdowns(): void {
        // const baseSystemDropdownOptions = this.selectedlocnName !== 'All' ? this.baseSystemDropdownOptions.filter((item) => item.valueText  === this.selectedlocnName) : this.baseSystemDropdownOptions;
        // this.agGridService.updateOptionCellEditorValues(
        //     baseSystemDropdownOptions,
        //     'pipeline',
        //     'plName'
        // );

        this.agGridServiceForAddOrEditGrade.updateOptionCellEditorValues(
            ServiceStatus,
            'serviceStatus',
            'status'
        );
    }
     

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
      //  get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
       // this.updateRow(item);
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isDeleteDisabled = ()  => {
      const requestBody: any = this.addOrEditGradeGridData.filter(a => a.isSelected === true);
      console.log(requestBody);
        return !this.selectedGradeId || requestBody.length > 0;
    };

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    updatePushtoVmacsButtonColDef = () => {
        const pushToVmacsButtonColDef = gradeColDef.find(x => x.colId === 'PushToVMACS');
        if (pushToVmacsButtonColDef) {
            pushToVmacsButtonColDef.hide = !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)) ? true : false,
            pushToVmacsButtonColDef.suppressColumnsToolPanel= !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd))? true : false,
            pushToVmacsButtonColDef.cellRendererParams = {
                dataStore : gradeMaintenanceDataStore,
                handleClickPushVmacs : (env, data) => this.pushToVmacs(env, data),
                updatedData : this.getUpdatedRows() 
            };
        }
    }

    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    getUpdatedRows = () => {
        const updatedRowIDs = this.gradeMaintenanceData.filter(a => this.getUpdatedRowIDs().includes(a.gradeID.toString()));
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushToVmacs(env, data): Promise<void> {
        // const action = data.action === 'U' ? 'Update' : 'Insert';
        const gradeRelPLID =  data.gradeRelPLID === 0 ? '' :  data.gradeRelPLID.toString();
        const requestBody: any =  { 
            'http_action' : null,
            'http_record_id': data.gradeID.toString(),
            'http_childrecord_id': gradeRelPLID,
            'http_environment': env.toUpperCase() 
        };
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response = await this.gradeMaintenanceDataService.pushToVmacs(requestBody);
            // if(response) {
                await this.onSearchClick();
                this.uiService.toastService.success(GradeMaintenanceMessages.SYNC);
            // }
        }

    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const selectedRowData = this.addOrEditGradeGridData;
        if(selectedRowData && selectedRowData.length > 0) {
            const grades = this.selectedGradeId.toString();
            const linkedpipelines= (selectedRowData.filter((item) => item.gradeRelPLID && item.gradeRelPLID !== 0).map(x => x.gradeRelPLID )).join(',');
            const requestBody: any =  { 
                'http_action' : null,
                'http_record_id': grades,
                'http_childrecord_id': linkedpipelines,
                'http_userid': this.userName,
                'http_environment': env.toUpperCase()   
            };
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.gradeMaintenanceDataService.pushToVmacs(requestBody);
                this.updatedFieldonGradeDetailsScreen = false;
                this.uiService.toastService.success(GradeMaintenanceMessages.SYNC);
            }
    
        }
       
    }

    @action
   isEditableJurisdiction(params) {
        if (params.data.gradeID <= 0) {
        return true
        }
        return false;
   }

   @action
   async onChangeGrade(value) {
        this.selectedgrade = value;
   }

   @action
   async onChangeGradeName(value) {
        this.selectedgradeName = value;
   }
   
   mapEditableColumnsGradesMainGrid(currentItem: any, updatedItem: any) {
    currentItem.gradeRelPLID = updatedItem.gradeRelPLID;
    currentItem.gradeID = updatedItem.gradeID;
    currentItem.locnName = updatedItem.locnName;
    currentItem.locnCode = updatedItem.locnCode;
    currentItem.pipelineID = updatedItem.pipelineID;
    currentItem.plName = updatedItem.plName;
    currentItem.syncStatus = updatedItem.syncStatus;
    currentItem.testSyncDate = updatedItem.testSyncDate;
    currentItem.prodSyncDate = updatedItem.prodSyncDate;
    currentItem.serviceStatus = updatedItem.serviceStatus;
    currentItem.allowTrades = updatedItem.allowTrades;
    currentItem.serviceDate = updatedItem.serviceDate;
    currentItem.action = updatedItem.action;
}


   @Loader
    @action
    @Catch(() => errorHandler(GradeMaintenanceMessages.FAILED_SUBMIT))
    async updateStatusFromDelToActive(): Promise<void> {
        const selectedRowIDs: any = await this.agGridService.getSelectedRows();
        const requestBody: any = [];
        const localValidatingErrorBody: any = [];
        selectedRowIDs.forEach((item) => {
            if(item.locnName && item.locnName !== '') {
                const isUniqueGrade = this.gradeMaintenanceData.filter((grade) => item.gradeCode === grade.gradeCode).length <= 1;
                if(isUniqueGrade) {
                    item.createUser =  this.userName,
                    item.gradeID = item.gradeID ? item.gradeID : 0,
                    item.action = item.action && item.action === 'D' ? 'U' : item.action,
                    item.gradeDetailDTOs = []
                    requestBody.push(item);
                } else {
                    const message = `Tried to create duplicate grade with Grade Code as ${item.locnCode} and Grade Name as ${item.locnName}. Please create the unique record.`;
                    if(!localValidatingErrorBody.includes(message)) {
                        localValidatingErrorBody.push(message);
                    }
                }
            } else {
                const message = 'Please insert valid Grade Name as mandatory fields.';
                if(!localValidatingErrorBody.includes(message)) {
                    localValidatingErrorBody.push(message);
                }
            }
        });
        if(localValidatingErrorBody.length > 0) {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } else if(requestBody.length !== 0 && selectedRowIDs.length === requestBody.length) {
            const response: GradeMaintenanceResponse[] = await this.gradeMaintenanceDataService.updateStatusFromDelToActive(requestBody);
            let errorInSave = false;
            let errorSummary: ToastMessage[] = [];
            if (response) {
                for (const key in response) {
                    if (response[key].gradeID) {
                        const objIndex = this.gradeMaintenanceData.findIndex(a => ((a.gradeID === response[key].gradeID)|| (a.gradeCode === response[key].gradeCode && a.gradeName === response[key].gradeName)));
                        if(objIndex >= 0) {
                            const backupItem = this.gradeMaintenanceData[objIndex];
                            this.mapEditableColumnsGradesMainGrid(backupItem, response[key]);
                        }
                    }
                    else {
                        errorInSave = true;
                        errorSummary = [...errorSummary, { id: key, description: response[key].gradeID.toString() }];
                    }
                }
            }
            this.cellValueChangeMap = {};
            if (errorInSave) {
                this.uiService.toastService.error('', {}, errorSummary);
                errorSummary.forEach(errorMessage => {
                    for (let i = 0; i < this.gradeMaintenanceData.length; i++) {
                        const updatedData = this.gradeMaintenanceData[i];
                        if (updatedData.gradeID.toString() === errorMessage.id) {
                            const backupRowIndex = this.gradeMaintenanceData.findIndex(a => a.gradeID.toString() == errorMessage.id);
                            this.mapEditableColumnsGradesMainGrid(updatedData, this.gradeMaintenanceData[backupRowIndex]);
                        }
                    }
                });
            }
            else {
                this.uiService.toastService.success(GradeMaintenanceMessages.SAVE);
            }
            runInAction(() => {
                this.showLoader = false;
                this.isRefreshed = false;
                this.isActivateButtonDisabled= true;
                dataStore.setShowUnsavedWarningAlert(false);
            })  
        }
    }

    @Loader
    @action
    @Catch(() =>  errorHandler(GradeMaintenanceMessages.FAILED_SUBMIT))
    async activateGrade(): Promise<void> {
        const requestBody = this.selectedGradeIds;
        const userName = this.userName;
        const response: any = await this.gradeMaintenanceDataService.activateGrade(requestBody, userName);
       if (response && response.value) {
            this.uiService.toastService.error('There is an error while activating Grade details.')
       } else {
           this.uiService.toastService.success('Grade activated successfully.');
           this.onSearchClick();
        }
    }
    
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async openGradeDetails(data) {
        const gradeID = data.gradeID;
        const addOrEditGradeGridData: any = [];
        const response: any = await this.gradeMaintenanceDataService.getTariffGrade(gradeID);
        const systemGradeData = await this.gradeMaintenanceDataService.getSystemGrades();
        
        this.baseSystemData.map((item) =>
        {
            const linkedGrade = response.tariffGradeRelatedPipelines.filter((x) => x.pipelineID === item.pipelineID);
            const gradeStatus = linkedGrade.length > 0  && linkedGrade[0].gradeStatus ? linkedGrade[0].gradeStatus !== 'A' ? 'Inactive' : 'Active' :'Active'
            item.gradeStatus =  gradeStatus
            item.effectiveDate = linkedGrade.length > 0 ? linkedGrade[0].effectiveDate : null,
            item.systemGrade = linkedGrade.length > 0 ? linkedGrade[0].systemGrade : null,
            item.isSelected = linkedGrade.length > 0 ? true : false,
            item.syncStatus = linkedGrade.length > 0 ? linkedGrade[0].syncStatus || SyncStatus[0] : SyncStatus[0],
            item.gradeRelPipelineID = linkedGrade.length > 0 ? linkedGrade[0].gradeRelPipelineID : 0,
            item.updateUser = linkedGrade.length > 0 ? linkedGrade[0].updateUser : this.userName
            item.createUser = linkedGrade.length > 0 ? linkedGrade[0].createUser : this.userName
            // item.gradeRelPLID = linkedGrade.length > 0 ? linkedGrade[0].gradeRelPipelineID : 0,
            // item.syncStatus = linkedGrade.length > 0 ? linkedGrade[0].syncStatus : SyncStatus[0]
            addOrEditGradeGridData.push(item)
        });

        runInAction(() => {
            addEditGradeMaintenanceDataStore.isEditingGrade = true;
            addEditGradeMaintenanceDataStore.editableGradeData = response;
            addEditGradeMaintenanceDataStore.selectedGrade = response.grade;
            addEditGradeMaintenanceDataStore.selectedGradeName = response.gradeName;
            addEditGradeMaintenanceDataStore.selectedGradeId = response.tariffGradeID;
            addEditGradeMaintenanceDataStore.selectedWorkflowId = response.workflowId;
            addEditGradeMaintenanceDataStore.setAddOrEditLinkerGridData(addOrEditGradeGridData);
            addEditGradeMaintenanceDataStore.setBackUpAddOrEditLinkerGridData(addOrEditGradeGridData);
            
            history.push('/GradeDetails', { from: '/GradeMaintenance', data: data })
        });
    }

    @action
    onCloseGradeDetails() {
        console.log(dataStore.showUnsavedWarningAlert);
        history.push('/GradeMaintenance', { from: '/GradeDetails' });   
    }


    @Loader
    @Catch(() => errorHandler(GradeMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async getSelectedGradeData(): Promise<void> {
            runInAction(() => {
                this.addOrEditedLocnName = '';
                this.addOrEditGradeGridData = [];
                this.backupAddOrEditGradeGridData = [];
                this.cellValueChangeMap = {};
                this.pipelineSystemData.map((item) =>
                 {
                    item.gradeRelPLID = 0,
                    item.serviceStatus =  'Active',
                    item.serviceDate =  null,
                    item.allowTrades = 'N',
                    item.isSelected = false,
                    item.syncStatus = SyncStatus[0]
                    this.addOrEditGradeGridData.push(item)
                });
                this.backupAddOrEditGradeGridData = this.addOrEditGradeGridData;
                this.isRefreshed = false;
            })
            this.agGridService.destroyGrid();
        this.agGridServiceForAddOrEditGrade.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
   async onEnterGradeforAddOREdit(value) {
        if(this.addOrEditedLocnName !== value) {
            this.updatedFieldonGradeDetailsScreen = value
        }
        this.addOrEditedLocnName = value   
   }

   @computed
    get AddOrEditGradeGridData() {
        return toJS(this.addOrEditGradeGridData);
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditGradeGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditGradeGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsGradeDetailsGrid(this.addOrEditGradeGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsGradeDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.serviceStatus = updatedItem.serviceStatus;
        currentItem.allowTrades = updatedItem.allowTrades;
        currentItem.isSelected = updatedItem.isSelected
        currentItem.serviceDate = updatedItem.serviceDate;
    }

    handleAllowTradeCheckBoxChange(params, value) {
        const row = params.data.pipelineID;
        const initValue = params.data['allowTrades'];
        this.setValueInChangeMap(row, 'allowTrades', initValue, value);
        if(initValue === 'Y') {
            params.data['allowTrades'] = 'N'
        } else {
            params.data['allowTrades'] = 'Y'
        }
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data)
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected
        this.setValueInChangeMap(row, 'isSelected', initValue, checked);
        params.data.isSelected = checked;
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data);
    };

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }
}
