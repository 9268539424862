import * as React from 'react';
import './_confirm.scss';
import { CustomButtonType, CustomButton } from '../custom-button/custom-button';
import { Button } from 'react-bootstrap';
export interface ConfirmWithOKProps {
    onClose: () => void;
    primaryText: string;
    secondaryText?: string;
}

export class ConfirmWithOK extends React.Component<ConfirmWithOKProps> {
    public render(): React.ReactNode {
        const { primaryText, secondaryText, onClose } = this.props;
        return (
            <div className="confirm">
                <div className="confirm__warning mt-10">
                    <i className="fa fa-exclamation-triangle confirm-warning"></i>
                </div>
                <div className="confirm__primary-text mt-10">{primaryText}</div>
                <div className="confirm__secondary-text mt-10">{secondaryText}</div>
                <div className="justify-content-center confirm__action mt-10">
                    <Button className="mt-3 mb-3" onClick={onClose}>
                        OK
                    </Button>
                </div>
            </div>
        );
    }

    handleClick = () => {
        this.props.onClose();
    };
}
