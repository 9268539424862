import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { MiscellaneousSettingsDataStore } from '../stores/miscellaneous-setting-store';
import {
    GridOptions,
    SelectionChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { MiscellaneousSettingsResponse } from '../model/model';
import { miscellaneousSettingsDataStore } from '../stores';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/miscellaneous.scss';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { AddNewSettingsTypePopup, ConfirmProps } from './addNewSettingsTypePopup';
import { dataStore } from '../../../../common/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { AddNewSettingsPopup, ConfirmNewSettingsProps } from './addNewSettingsPopup';
import { errorHandler } from 'shared/handlers/error-handler';
import { ReactSelect } from 'common/components/select/select';

/* eslint-disable */
interface MiscellaneousSettingsContainerProps {
    miscellaneousSettingsDataStore: MiscellaneousSettingsDataStore;
}

interface MiscellaneousSettingsContainerState {
    search: string;
    addRowClick: boolean;
    lastAddedSettingID: number;
}

@inject('miscellaneousSettingsDataStore')
@observer
export class MiscellaneousSettingsContainer extends Component<MiscellaneousSettingsContainerProps, MiscellaneousSettingsContainerState> {
    constructor(props: MiscellaneousSettingsContainerProps | Readonly<MiscellaneousSettingsContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            lastAddedSettingID: 0,
        };
        dataStore.setModuleName('Setting Maintenance')
    }
    
    componentDidMount() {
      history.push('/Miscellaneous');
      const {miscellaneousSettingsDataStore} = this.props;
      miscellaneousSettingsDataStore.init();
      miscellaneousSettingsDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const filters = localStorageService.get('Setting Maintenance_filter');
      this.props.miscellaneousSettingsDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Setting Maintenance_' + accountStore.userName);
      this.props.miscellaneousSettingsDataStore.agGridService.setColumnVisibility(columnVisible);
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    onBtClearSelection = () => {
      const updatedRowIDs = miscellaneousSettingsDataStore.miscellaneousSettingsData.filter(a => miscellaneousSettingsDataStore.getUpdatedRowIDs().includes(a.settingID.toString()));
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.miscellaneousSettingsDataStore.onClearSelectSettingName(),
            '',
            'All the unsaved changes will be lost. Are you sure you want to Continue?'
          );
        } else {
          this.props.miscellaneousSettingsDataStore.onClearSelectSettingName();
        }
    }
    
    handleAddNewSettingClick = () => {
      this.props.miscellaneousSettingsDataStore.isEditingSettingData = false;
      this.props.miscellaneousSettingsDataStore.editableSettingData = [];
      const defaultUserLocation = this.props.miscellaneousSettingsDataStore.defaultLoggedInUserLocation;
      const jurisdictionDropdown = defaultUserLocation !== 'All' ? this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions.filter((item) => item.valueCode === defaultUserLocation) : this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions;
      const jurisdictionDropdownData = jurisdictionDropdown.map(x => {return { 'value': x.valueText, 'label': x.valueCode}});
      const settingsTypeData = this.props.miscellaneousSettingsDataStore.settingNameOptions.map(x => {return { 'value': x.settingTypeDesc, 'label': x.settingTypeDesc}});
      const assetGroupDropdown = this.props.miscellaneousSettingsDataStore.assetGroupDropdownOptions.map(x => {return { 'value': x.assetGroupID, 'label': x.name}});
      const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          (data) => {this.props.miscellaneousSettingsDataStore.saveSettingsData()},
          AddNewSettingsPopup,
          {settingsTypeData: settingsTypeData,
          jurisdictionOptions: jurisdictionDropdownData,
          assetGroupDropdownOptions: assetGroupDropdown,
          editableData: [],
          isEditingSettingData: false,
          defaultUserLocation: defaultUserLocation} as ConfirmNewSettingsProps,
          'confirmPopup'
        );
    }
    
    // handleEditSettingClick = async () => {
    //   const selectedRows = await miscellaneousSettingsDataStore.agGridService.getSelectedRows();
    //   if (selectedRows?.length != 1) {
    //     errorHandler('Please select one row to edit.');
    //   } else {
    //     this.props.miscellaneousSettingsDataStore.isEditingSettingData = true;
    //     this.props.miscellaneousSettingsDataStore.editableSettingData = selectedRows;
    //     const defaultUserLocation = this.props.miscellaneousSettingsDataStore.defaultLoggedInUserLocation;
    //     const jurisdictionDropdown = this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions; // defaultUserLocation !== 'All' ? this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions.filter((item) => item.valueCode === defaultUserLocation) : this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions;
    //     const assetGroupDropdown = this.props.miscellaneousSettingsDataStore.assetGroupDropdownOptions;
    //     const confirmService = new ConfirmService();
    //     confirmService.showConfirmWithCustomComponent(
    //       (data) => {this.props.miscellaneousSettingsDataStore.saveSettingsData()},
    //       AddNewSettingsPopup,
    //       {settingsTypeData: this.props.miscellaneousSettingsDataStore.settingNameOptions,
    //       jurisdictionOptions: jurisdictionDropdown,
    //       assetGroupDropdownOptions: assetGroupDropdown,
    //       editableData: selectedRows,
    //       isEditingSettingData: true,
    //       defaultUserLocation: defaultUserLocation} as ConfirmNewSettingsProps,
    //       'confirmPopup'
    //     );
    //   }
    // }

    onSearchSetting = () => {
      const updatedRowIDs = miscellaneousSettingsDataStore.miscellaneousSettingsData.filter(a => miscellaneousSettingsDataStore.getUpdatedRowIDs().includes(a.settingID.toString()));
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.miscellaneousSettingsDataStore.onSearchSetting(),
            '',
            'All the unsaved changes will be lost. Are you sure you want to Continue?'
          );
        } else {
          this.props.miscellaneousSettingsDataStore.onSearchSetting();
        }
    }

    onRemoveSelected = () => {
      this.props.miscellaneousSettingsDataStore.removeSelectedSettings();
    }
      
    deleteItem = async () => {
      const { miscellaneousSettingsDataStore } = this.props;
      const requestBody: any = await miscellaneousSettingsDataStore.agGridService.getSelectedRows();
      const updatedRowIDs = miscellaneousSettingsDataStore.miscellaneousSettingsData.filter(a => miscellaneousSettingsDataStore.getUpdatedRowIDs().includes(a.settingID.toString()));
      if(requestBody.length > 0 && !requestBody.some((item) => item.action === 'D')) {
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.onRemoveSelected(),
            'Inactive',
            'All the unsaved changes will be lost. Are you sure you want to deactivate the selected settings?'
        );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.onRemoveSelected(),
            'Inactive',
            'Are you sure you want to deactivate the selected settings?'
        );
        }
      } else {
        const secondaryText = requestBody.length > 0 ? 'Please select only the active settings to perform the operation' : 'No records are selected. Please select atleast one active Setting to perform the operation.';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
      console.log(' succesfully');
    }

    handleAddNewSettingTypeClick = () => {
      const defaultUserLocation = this.props.miscellaneousSettingsDataStore.defaultLoggedInUserLocation;
      const jurisdictionDropdown = defaultUserLocation !== 'All' ? this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions.filter((item) => item.valueCode === defaultUserLocation) : this.props.miscellaneousSettingsDataStore.jurisdictionDropdownOptions;
      const jurisdictionDropdownData = jurisdictionDropdown.map(x => {return { 'value': x.valueText, 'label': x.valueCode}});
      const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          (data) => {this.props.miscellaneousSettingsDataStore.saveNewSettingType(data.value, data.jurisdiction)},
          AddNewSettingsTypePopup,
          {settingsTypeData: this.props.miscellaneousSettingsDataStore.settingNameOptions,
          jurisdictionOptions: jurisdictionDropdownData,
          defaultUserLocation: defaultUserLocation} as ConfirmProps,
          'confirmPopup'
        );
    }

    handleStateChangeFromDelToActive = async() => {
      const { miscellaneousSettingsDataStore } = this.props;
      const requestBody: any = await miscellaneousSettingsDataStore.agGridService.getSelectedRows();
      const updatedRowIDs = miscellaneousSettingsDataStore.miscellaneousSettingsData.filter(a => miscellaneousSettingsDataStore.getUpdatedRowIDs().includes(a.settingID.toString()));
      if(requestBody.length > 0 && !requestBody.some((item) => item.action !== 'D')) {
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.miscellaneousSettingsDataStore.updateStatusFromDelToActive(),
            'Active',
            'All the unsaved changes will be lost. Are you sure you want to activate the selected settings?'
        );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.miscellaneousSettingsDataStore.updateStatusFromDelToActive(),
            'Active',
            'Are you sure you want to activate the selected settings?'
        );
        }
      } else {
        const secondaryText = requestBody.length > 0 ? 'Please select only the inactive settings to perform the operation' : 'No records are selected. Please select atleast one inactive setting to perform the operation.';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
      console.log(' succesfully');
    }

    render(): React.ReactNode {
      const { miscellaneousSettingsDataStore } = this.props;
      return (
            <Container fluid>
              <Row>
                <Col>
                    <div className='settingMaintenanceView'>
                        <div className='screenHeader'>
                          <h2 className='screen_title'>Setting Maintenance</h2>
                        </div>
                          <div className='selectContainer'>
                            <div className='settingSelectionDiv'>
                              <label className="standard_label_style">Setting</label>
                              <ReactSelect
                                  className='select'
                                  values={miscellaneousSettingsDataStore.selectedSettingName}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...miscellaneousSettingsDataStore.settingNameOptionsUI]}
                                  onChange={(e: any) => {miscellaneousSettingsDataStore.selectedSettingName = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                            </div>
                            <div className='searchButtonDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchSetting} disabled= {false}>
                                Search
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                                Clear
                            </CustomButton>

                          <div className='otherHeaderButtonsDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.handleAddNewSettingClick} disabled= {miscellaneousSettingsDataStore.isSaveDisabled()}>
                              Add New Setting
                            </CustomButton>
                            {/* <CustomButton type={CustomButtonType.Submit} onClick={this.handleEditSettingClick} disabled= {miscellaneousSettingsDataStore.isSaveDisabled()}>
                              Edit Setting
                          </CustomButton> */}
                          <CustomButton type={CustomButtonType.Submit} onClick={this.handleAddNewSettingTypeClick} disabled= {miscellaneousSettingsDataStore.isAddNewSettingTypeDisabled()}>
                              Add New Setting Type
                          </CustomButton>
                          <CustomButton type={CustomButtonType.Submit} onClick={this.deleteItem} disabled= {miscellaneousSettingsDataStore.isActivateDeactivateDisabled()}>
                              Deactivate
                          </CustomButton>
                          <CustomButton type={CustomButtonType.Submit} onClick={this.handleStateChangeFromDelToActive} disabled= {miscellaneousSettingsDataStore.isActivateButtonDisabled || miscellaneousSettingsDataStore.isActivateDeactivateDisabled()}>
                              Activate
                          </CustomButton>
                          </div>
                            </div>
                          </div>
                    </div>
                    {this.props.miscellaneousSettingsDataStore.showLoader == true &&
                      <div className = 'alert-overlay'> 
                        <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                      </div>
                    }
                    <AgGridComponent gridConfig={this.getGridConfig('Setting Maintenance')} />
                    <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/>
                </Col>
              </Row>
            </Container>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          miscellaneousSettingsDataStore: { MiscellaneousSettingsData, agGridService }
      } = this.props;
      const { miscellaneousSettingsDataStore } = this.props;
      const columnDefs = miscellaneousSettingsDataStore.getColDef();
      const onSelectionChanged = this.onSelectionChanged;
      const rowData = MiscellaneousSettingsData;
      const miscellaneousSettingsGridParams: any = {
        rowData,
        columnDefs,
        onSelectionChanged
        };
      
      return agGridService.getGridConfig(miscellaneousSettingsGridParams, gridId);
  }
  
    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        miscellaneousSettingsDataStore.setIsActivateButtonDisabled(miscellaneousSettingsDataStore.isSaveDisabled())
      } else {
       miscellaneousSettingsDataStore.setIsActivateButtonDisabled(true)
      }
  }
}
