import React, { Component, useEffect, useState } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { SystemFeeMaintenanceDataStore } from '../stores/system-fee-maintenance-data-store';
import './system-fee-maintenance.scss';
import { dataStore } from '../../../../common/stores';
import { systemFeeMaintenanceDataStore } from '../stores';
// import './AddEditSystemFeeMaintenance.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import DatePicker from 'react-datepicker';
import { ReactSelect } from 'common/components/select/select';
import moment from 'moment';


  /* eslint-disable */
interface AddEditSystemFeeMaintenanceProps {
    systemFeeMaintenanceDataStore: SystemFeeMaintenanceDataStore;
}


@inject('systemFeeMaintenanceDataStore')
@observer
export class AddEditSystemFeeMaintenance extends Component<AddEditSystemFeeMaintenanceProps, any> {
    constructor(props: AddEditSystemFeeMaintenanceProps | Readonly<AddEditSystemFeeMaintenanceProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('Add System Fee')
    }

    componentDidUpdate() {
      const {systemFeeMaintenanceDataStore} = this.props;
      dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    componentWillUnmount() {
      console.log("unmount" + dataStore.showUnsavedWarningAlert);
    }

    handleClose = () => {
      systemFeeMaintenanceDataStore.onCloseSystemFeeDetailsModal();
    }

    onSelectedSystemGroupForAddEdit = (e) => {
        systemFeeMaintenanceDataStore.setSystemGroupForAddEdit(e);
    };

    handleShow = () => systemFeeMaintenanceDataStore.isShowModal = true;

    handleSaveSystemFees = async() => 
    {
      systemFeeMaintenanceDataStore.handleSaveSystemFees();
    }

    onSelectEffectiveStartDateForAddEdit = (e) => {
        systemFeeMaintenanceDataStore.setEffectiveStartDateForAddEdit(e);
    };

    onSelectEffectiveEndDateForAddEdit = (e) => {
        systemFeeMaintenanceDataStore.setEffectiveEndDateForAddEdit(e);
    };

    onSelectShrinkage = (e) => {
      systemFeeMaintenanceDataStore.setShrinkage(e);
    }

    onSelectLossAllowance = (e) => {
      systemFeeMaintenanceDataStore.setLossAllowance(e);
    }

    render(): React.ReactNode {
      const { systemFeeMaintenanceDataStore } = this.props;
      
      return (
        <Container fluid>
            {this.renderAddSystemFeeScreen()}
        </Container>
      );
    }

    renderAddSystemFeeScreen() : React.ReactNode {
      const { systemFeeMaintenanceDataStore } = this.props;
      const headerTitle = systemFeeMaintenanceDataStore.isEditingSystemFee ? 'Edit System Fee' : 'Add System Fee';
      return (
    <>
      {/* <Button variant="primary" onClick={this.handleShow}>
        click modal
      </Button> */}
      <Modal className='modal-xl systemFeeMaintenanceBox' show={systemFeeMaintenanceDataStore.isShowModal} onHide={this.handleClose}>
        <Modal.Header className='screenHeader' closeButton>
          <Modal.Title className='screen_title'>{headerTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Col className='pipelineSys'>
            <label className="standard_label_style systemGroup">Pipeline System*</label>
              <ReactSelect
                className='select_small'
                values={systemFeeMaintenanceDataStore.selectedSystemGroupForAddEdit}
                placeholder="Select"
                options={systemFeeMaintenanceDataStore.systemGroupDropdownOptions}
                onChange={this.onSelectedSystemGroupForAddEdit}
                isMulti={false}
                isSearchable={true}
              />
          </Col>
          <Col>
            <label className='standard_label_style lmci'>
                LMCI Abandonment S/C
            </label>
            <input
                type='number'
                step="0.01"
                min="0"
                value={systemFeeMaintenanceDataStore.enteredLMCIRate}
                className='search_text_field input'
                onChange={(e) => {
                    systemFeeMaintenanceDataStore.enteredLMCIRate= e.target.value;
                }}
            />
          </Col>
          <Col>
            <label className='standard_label_style'>
                Delivery Terminaling
            </label>
            <input
                type='number'
                step="0.01"
                min="0"
                className='search_text_field input'
                value={systemFeeMaintenanceDataStore.deliveryTerminallingFee}
                onChange={(e) => {
                    systemFeeMaintenanceDataStore.deliveryTerminallingFee= e.target.value;
                }}
            />
          </Col>
          </Row>
          <Row className='rowStyle'>
            <Col className='dateCol'>
              <label className="standard_label_style dateLabel">Effective Start Date*</label>
              <DatePicker
                  className='datepicker__holder datepicker'
                  selected={systemFeeMaintenanceDataStore.selectedEffectiveStartDateForAddEdit}
                  onChange={(e) => {
                      this.onSelectEffectiveStartDateForAddEdit(e);
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  minDate={new Date(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'))}
              />
            </Col>
            <Col>
              <label className='standard_label_style'>Offload/Injection Fee</label>
              <input
                  type='number'
                  step="0.01"
                  min="0"
                  value={systemFeeMaintenanceDataStore.enteredInjectionFee}
                  className='search_text_field input'
                  onChange={(e) => {
                        systemFeeMaintenanceDataStore.enteredInjectionFee= e.target.value;
                    }}
              />
            </Col>
          <Col className='shrinkageLossAllowanceCol'>
            <label className='standard_label_style shrinkageLossAllowanceLabel'>Loss Allowance (% Stream)</label>
            <ReactSelect
              className='select_small'
              values={systemFeeMaintenanceDataStore.lossAllowance}
              placeholder="Select"
              options={systemFeeMaintenanceDataStore.lossAllowanceDropdownOptions}
              onChange={this.onSelectLossAllowance}
              isMulti={false}
              isSearchable={true}
          />
        </Col>
          </Row>
          <Row className='rowStyle'>
            <Col className='dateCol'>
              <label className="standard_label_style dateLabel">Effective End Date</label>
              <DatePicker
                  className='datepicker__holder datepicker'
                  selected={systemFeeMaintenanceDataStore.selectedEffectiveEndDateForAddEdit}
                  onChange={(e) => {
                      this.onSelectEffectiveEndDateForAddEdit(e);
                  }}
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  minDate={new Date(moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'))} 
              />
            </Col>
            <Col>
              <label className='standard_label_style'>
                  Receipt Terminaling
              </label>
              <input
                  type='number'
                  step="0.01"
                  min="0"
                  value={systemFeeMaintenanceDataStore.enteredRecieptTerminalingRate}
                  className='search_text_field input'
                  onChange={(e) => {
                      systemFeeMaintenanceDataStore.enteredRecieptTerminalingRate= e.target.value;
                  }}
              />
            </Col>
            <Col className='shrinkageLossAllowanceCol'>
              <label className='standard_label_style shrinkageLossAllowanceLabel'>Shrinkage (% Stream)</label>
              <ReactSelect
                className='select_small'
                values={systemFeeMaintenanceDataStore.shrinkage}
                placeholder="Select"
                options={systemFeeMaintenanceDataStore.shrinkageDropdownOptions}
                onChange={this.onSelectShrinkage}
                isMulti={false}
                isSearchable={true}
            />
          </Col>
          </Row>
          <Row className='rowStyle'>
            <Col>
              <label className='standard_label_style'>Primary Pipeline Tariff</label>
              <input
                  type='number'
                  step="0.01"
                  min="0"
                  className='search_text_field input'
                  value={systemFeeMaintenanceDataStore.enteredPrimaryRate}
                  onChange={(e) => {
                      systemFeeMaintenanceDataStore.enteredPrimaryRate= e.target.value;
                  }}
              />
            </Col>
            <Col>
                <label className='standard_label_style'>Quality Management Fee</label>
                <input
                    type='number'
                    step="0.01"
                    min="0"
                    value={systemFeeMaintenanceDataStore.enteredQualityMgmtFee}
                    className='search_text_field input'
                    onChange={(e) => {
                      systemFeeMaintenanceDataStore.enteredQualityMgmtFee= e.target.value;
                  }}
                />
            </Col>
            <Col>
                <label className='standard_label_style bsw'>BS&W Penalty Fee</label>
                <input
                    type='number'
                    step="0.01"
                    min="0"
                    className='search_text_field input'
                    value={systemFeeMaintenanceDataStore.bsw}
                    onChange={(e) => {
                      systemFeeMaintenanceDataStore.bsw= e.target.value;
                  }}
                />
            </Col>
          </Row>
          <Row className='rowStyle'>
            <Col>
                <label className='standard_label_style'>Secondary Pipeline Tariff</label>
                <input
                type='number'
                step="0.01"
                min="0"
                className='search_text_field input'
                value={systemFeeMaintenanceDataStore.enteredSecondaryRate}
                onChange={(e) => {
                        systemFeeMaintenanceDataStore.enteredSecondaryRate= e.target.value;
                    }}
                />
            </Col>
            <Col>
              <label className='standard_label_style electronicReporting'>
                  Electronic Reporting Fee
              </label>
              <input
                  type='number'
                  step="0.01"
                  min="0"
                  className='search_text_field input'
                  value={systemFeeMaintenanceDataStore.enteredElectronicReportingFee}
                  onChange={(e) => {
                    systemFeeMaintenanceDataStore.enteredElectronicReportingFee= e.target.value;
                }}
              />
          </Col>
          <Col>
              <label className='standard_label_style intraSys'>
                  Intra System Transfer Fee
              </label>
              <input
                  type='number'
                  step="0.01"
                  min="0"
                  className='search_text_field input'
                  value={systemFeeMaintenanceDataStore.intraSystemTransferFee}
                  onChange={(e) => {
                      systemFeeMaintenanceDataStore.intraSystemTransferFee= e.target.value;
                  }}
              />
          </Col>
          </Row>
          <Row className='rowStyle'>
            <Col>
              <label className='standard_label_style'>Surcharge</label>
              <input
                  type='number'
                  step="0.01"
                  min="0"
                  value={systemFeeMaintenanceDataStore.enteredSurcharge}
                  className='search_text_field input'
                  onChange={(e) => {
                      systemFeeMaintenanceDataStore.enteredSurcharge= Number(e.target.value);
                  }}
              />
            </Col>
            <Col></Col>
            <Col className='lumpSum'>
              <label className='standard_label_style'>Lump Sum Fee</label>
              <input
                  type='number'
                  step="0.01"
                  min="0"
                  className='search_text_field input'
                  value={systemFeeMaintenanceDataStore.lumpSumFees}
                  onChange={(e) => {
                      systemFeeMaintenanceDataStore.lumpSumFees= e.target.value;
                  }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleSaveSystemFees}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
}