import { ValueFormatterParams, ColDef, ICellEditorParams } from 'ag-grid-community';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { SystemFeeDetailCellRenderer } from '../cell-renderers/system-group-cell-renderer';
import { systemFeeMaintenanceDataStore } from '../stores';

export const systemFeeColDef: ColDef[] = [
    {   
        headerName: 'Pipeline System',
        field: 'systemGroup', 
        colId:'systemGroup',
        filter: true, 
        sortable: true,
        editable: false, 
        cellRenderer: SystemFeeDetailCellRenderer,
        minWidth: 270,
        flex: 1,
    },
    { 
        headerName: 'Eff Start Date', 
        field: 'effectiveStartDate',
        colId: 'effectiveStartDate',
        valueGetter: params => {
            if (params.data['effectiveStartDate'] === null) {
                return '';
            } else {
                return moment(params.data['effectiveStartDate'])
                .format(AppConstant.DATE_FORMAT);
            }
        },
        filter: true, 
        sortable: true,
        editable: false, 
        minWidth: 130,
        flex: 1
    },
    { 
        headerName: 'Eff End Date', 
        field: 'effectiveEndDate',
        colId: 'effectiveEndDate',
        valueGetter: params => {
            if (params.data['effectiveEndDate'] === null) {
                return '';
            } else {
                return moment(params.data['effectiveEndDate'])
                .format(AppConstant.DATE_FORMAT);
            }
        },
        filter: true, 
        sortable: true, 
        minWidth: 130,
        flex: 1
    },
    { 
        headerName: 'QMF', 
        field: 'qualityMgmtFee', 
        colId: 'qualityMgmtFee', 
        valueGetter: params => {
            if (params.data['qualityMgmtFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['qualityMgmtFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 80,
        flex: 1
    },
    { 
        headerName: 'ERF', 
        field: 'electronicReportingFee', 
        colId: 'electronicReportingFee',
        valueGetter: params => {
            if (params.data['electronicReportingFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['electronicReportingFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        minWidth: 80,
        flex: 1
    },
    { 
        headerName: 'PLA', 
        field: 'lossAllowance', 
        colId: 'lossAllowance',
        valueGetter: params => {
            return systemFeeMaintenanceDataStore.lossAllowanceData.find(x=>x.settingID==params.data['lossAllowance'])?.valueText;
        },
        filter: true, 
        sortable: true, 
        minWidth: 160,
        flex: 1
    },
    { 
        headerName: 'Shrinkage', 
        field: 'shrinkage', 
        colId: 'shrinkage',
        valueGetter: params => {
            return systemFeeMaintenanceDataStore.shrinkageData.find(x=>x.settingID==params.data['shrinkage'])?.valueText;
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 140,
        flex: 1
    },
    { 
        headerName: 'BS&W',
        field: 'bS_W', 
        colId: 'bS_W',
        valueGetter: params => {
            if (params.data['bS_W'] === null) {
                return '';
            } else {
                return (Math.round(params.data['bS_W'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 80,
        flex: 1
    },
    { 
        headerName: 'ISTF',
        field: 'intraSystemTransferFee',
        colId: 'intraSystemTransferFee',
        valueGetter: params => {
            if (params.data['intraSystemTransferFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['intraSystemTransferFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 80,
        flex: 1
    },
    { 
        headerName: 'Offload/Injection Fee', 
        field: 'injectionFee',
        colId: 'injectionFee',  
        valueGetter: params => {
            if (params.data['injectionFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['injectionFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 160,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Receipt Terminaling', 
        field: 'receiptTermFee', 
        colId: 'receiptTermFee',  
        valueGetter: params => {
            if (params.data['receiptTermFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['receiptTermFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 160,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'LMCI Abandonment S/C', 
        field: 'lmciAbandonmentS_C', 
        colId: 'lmciAbandonmentS_C',  
        valueGetter: params => {
            if (params.data['lmciAbandonmentS_C'] === null) {
                return '';
            } else {
                return (Math.round(params.data['lmciAbandonmentS_C'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 180,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Primary Pipeline Tariff', 
        field: 'primaryPLTariff', 
        colId: 'primaryPLTariff',  
        valueGetter: params => {
            if (params.data['primaryPLTariff'] === null) {
                return '';
            } else {
                return (Math.round(params.data['primaryPLTariff'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 170,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Secondary Pipeline Tariff', 
        field: 'secondaryPLTariff', 
        colId: 'secondaryPLTariff',   
        valueGetter: params => {
            if (params.data['secondaryPLTariff'] === null) {
                return '';
            } else {
                return (Math.round(params.data['secondaryPLTariff'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 200,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Delivery Terminalling', 
        field: 'deliveryTermFee', 
        colId: 'deliveryTermFee',   
        valueGetter: params => {
            if (params.data['deliveryTermFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['deliveryTermFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 170,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Surcharge', 
        field: 'surcharge', 
        colId: 'surcharge',   
        valueGetter: params => {
            if (params.data['surcharge'] === null) {
                return '';
            } else {
                return (Math.round(params.data['surcharge'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 110,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Lump Sum Fee', 
        field: 'lumpSumpFee', 
        colId: 'lumpSumpFee',  
        valueGetter: params => {
            if (params.data['lumpSumpFee'] === null) {
                return '';
            } else {
                return (Math.round(params.data['lumpSumpFee'] * 100) / 100).toFixed(2);
            }
        },
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 130,
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser', 
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 140,
        valueGetter: params => {
            if (params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        }
    },
    { 
        headerName: 'Created Date',
        field: 'createDate',
        colId: 'createDate',  
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 130,
        valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        } 
    },
    { 
        headerName: 'Updated User', 
        field: 'updateUser',
        colId: 'updateUser',   
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 140,
        valueGetter: params => {
            if (params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        }
    },
    { 
        headerName: 'Updated Date', 
        field: 'updateDate', 
        colId: 'updateDate',   
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 130,
        valueGetter: params => {
            if (params.data['updateDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        }  
    },
];

