import React from 'react';
import { DefaultPageComponent } from '../../../common/components/default-page-component';
import { ProjectDashboardContainer } from '../project-dashboard/component/project-dashboard-container';
import { projectDashboardDataStore } from '../project-dashboard/stores';


export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        'DefaultPageComponent',
        () => (
            <React.Fragment>
                <DefaultPageComponent />
            </React.Fragment>
        )
    ],
    [
        'ProjectDashboardContainer',
        () => (
            <React.Fragment>
                <ProjectDashboardContainer projectDashboardDataStore={projectDashboardDataStore} />
            </React.Fragment>
        )
    ]
]);
