import React from 'react';
import { DataGrid } from './data-grid/data-grid'
import { GridOptions } from 'ag-grid-community';

interface AgGridComponentProps {
    gridConfig: GridOptions;
}

export const AgGridComponent = (props: AgGridComponentProps) => {
    return (
        <div>
            <DataGrid gridOption={props.gridConfig} />
        </div>
    );
};
