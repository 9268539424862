import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { lineSegmentMaintenanceDataStore } from '../../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';


export const LineSegmentDescriptionCellRenderer = (props: ICellRendererParams) => {    
    return (

        !dataStore.checkOperationAccess(RoleBasedActionName.View) || (props.data && ((props.data.segmentStatusText && props.data.segmentStatusText !== 'Active') || (props.data.action && props.data.action == 'D'))) ? <span>{props.data && props.data.lineSegDesc ? props.data.lineSegDesc : ''}</span> : 
            <span style={{color: 'blue'}}
            onClick={()=>lineSegmentMaintenanceDataStore.openLineSegmentDetails(props.data)}
            >
                {props.data ? props.data.lineSegDesc ==undefined?'0000000':props.data.lineSegDesc : '' }</span>
    );
};