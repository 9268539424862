import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { AddEditFilingCarrierMaintenanceStore } from '../stores/add-edit-filing-carrier-maintenance-data-store';
import {
    GridOptions,
    ColDef,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/filingCarrier.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
// import { ServiceStatus } from '../constants/enums';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { addEditFilingCarrierMaintenanceDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import moment from 'moment';
import { ReactSelect } from 'common/components/select/select';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';
import DatePicker from 'react-datepicker';
import { Portal } from 'react-overlays';

interface CalendarContainerProps {
    children : any
}

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface AddEditFilingCarrierMaintenanceProps {
    addEditFilingCarrierMaintenanceDataStore: AddEditFilingCarrierMaintenanceStore;
}

interface AddEditFilingCarrierMaintenanceState {
    search: string;
    lastAddedRemarksID: number;
    lastAddedRelationshipID: number;
}

@inject('addEditFilingCarrierMaintenanceDataStore')
@observer
export class AddEditFilingCarrierMaintenance extends Component<AddEditFilingCarrierMaintenanceProps, AddEditFilingCarrierMaintenanceState> {
    constructor(props: AddEditFilingCarrierMaintenanceProps | Readonly<AddEditFilingCarrierMaintenanceProps>) {
        super(props);
        this.state = {
            search: '',
            lastAddedRemarksID: 0,
            lastAddedRelationshipID: 0
        };
        dataStore.setModuleName('Filing Carrier Maintenance')
    }
    
    async componentDidMount() {
        const { addEditFilingCarrierMaintenanceDataStore } = this.props;
        const previousLineSegment = history?.location?.state as { from?: string }
        await addEditFilingCarrierMaintenanceDataStore.init();
        addEditFilingCarrierMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const {addEditFilingCarrierMaintenanceDataStore} = this.props;
      const filters = localStorageService.get('Filing Carrier Maintenance_filter');
      this.props.addEditFilingCarrierMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Filing Carrier Maintenance_' + accountStore.userName);
      this.props.addEditFilingCarrierMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }
    
    componentWillUnmount() {
        const { addEditFilingCarrierMaintenanceDataStore } = this.props;
        addEditFilingCarrierMaintenanceDataStore.resetFilingCarrierDetails();
    }

    CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };

    disableEditing = () => {
        const { addEditFilingCarrierMaintenanceDataStore } = this.props;
        if (addEditFilingCarrierMaintenanceDataStore && addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData) {
            const initialData = addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter(a => (!addEditFilingCarrierMaintenanceDataStore.getUpdatedRowIDs().includes(a.pipelineID.toString())));
            return initialData.filter((item) => item.isSelected === true).length > 1
        }
    }

    AddLinkerDetailsColDef() {
        const { addEditFilingCarrierMaintenanceDataStore } = this.props;
        const AddLinkerDetailsColDef: ColDef[] = [
            // {
            //     headerName: 'Select',
            //     field: 'select',
            //     colId: 'Select',
            //     headerComponent: (params) => {
            //         return (
            //             <input
            //                 type="checkbox"
            //                 onChange={(e) => addEditFilingCarrierMaintenanceDataStore.headerCheckBoxSelection(e)}
            //                 checked={!addEditFilingCarrierMaintenanceDataStore.checkIfHeaderCheckboxShouldbeChecked(params)}
            //                 // disabled={this.disableEditing()}
            //             />
            //         );
            //     },
            //     cellRenderer: (params) => {
            //         return (
            //             <input
            //                 type="checkbox"
            //                 onChange={(e) => addEditFilingCarrierMaintenanceDataStore.linkedPipelineCheckboxHandler(params, e)}
            //                 checked={params.data.isSelected || false}
            //             />
            //         );
            //     },
            //     cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
            //     maxWidth: 40,
            //     flex: 1,
            //     suppressSizeToFit: true,
            //     cellStyle: () => { return { padding: '10px' } }
            // },
            {
                headerName: 'Pipeline System',
                field: 'pipelineName',
                colId: 'pipelineName',
                filter: true,
                sortable: true,
                editable: false,
                // cellClass: (params) => { return params.data.action === 'D' ? 'grid-cell-bgColor-red' : '' },
                valueGetter: params => {
                    if (params.data['pipelineName'] === null) {
                        return '';
                    } else {
                        return params.data['pipelineName'];
                    }
                },
                suppressSizeToFit: true,
                flex: 1
            },
        ];
        return AddLinkerDetailsColDef;
    }

    private getAddLinkerDetailsGridConfig(gridId): GridOptions {
      const {
          addEditFilingCarrierMaintenanceDataStore: { AddOrEditLinkerGridData, agGridServiceForAddOrEditLinker }
      } = this.props;
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      const columnDefs = this.AddLinkerDetailsColDef();
      const rowData = AddOrEditLinkerGridData;
      const onCellEditingStarted = this.onCellEditingStarted;
      const onCellEditingStopped = this.onCellEditingStopped;
      const filingEntityMaintenanceGridParams: any = {
        rowData,
        columnDefs,
        onCellEditingStarted,
        onCellEditingStopped,
        context: agGridServiceForAddOrEditLinker
      };
      return agGridServiceForAddOrEditLinker.getGridConfig(filingEntityMaintenanceGridParams, gridId);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          addEditFilingCarrierMaintenanceDataStore: { cellValueChangeMap }
      } = this.props;
      const isNotEmpty =
          row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
      if (!isNotEmpty) {
          this.props.addEditFilingCarrierMaintenanceDataStore.setValueInChangeMap(row, col, value, value);
      }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
      const row = event.data.pipelineID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
        addEditFilingCarrierMaintenanceDataStore: { cellValueChangeMap, updateRowforAddOrUpdateGrid, getColDef}
      } = this.props;
      cellValueChangeMap[row][col]['currentValue'] = value;
      updateRowforAddOrUpdateGrid(event.data);
      this.props.addEditFilingCarrierMaintenanceDataStore.agGridServiceForAddOrEditLinker.getNodes()?.refreshCells();
    };

    // onChangeDate = (params, value, key) => {
    //     const { addEditFilingCarrierMaintenanceDataStore } = this.props;
    //     const effectiveDate = moment(value).format('MM/DD/yyyy');
    //     console.log(moment(value).format('MM/DD/yyyy'));
    //     addEditFilingCarrierMaintenanceDataStore.setValueInChangeMap(params.data.pipelineID, key, params.data.effectiveDate, effectiveDate);
    //     params.data.effectiveDate = moment(value).format('MM/DD/yyyy');
    //     addEditFilingCarrierMaintenanceDataStore.updateRowforAddOrUpdateGrid(params.data);
    //     if (addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter((item) => item.isSelected === true).length > 1) {
    //         const confirmService = new ConfirmService();
    //         confirmService.showConfirmDialog(
    //             () => addEditFilingCarrierMaintenanceDataStore.globalEffectiveDateSelection(params.data.effectiveDate),
    //             'EffectiveDate',
    //             `Do you want to apply selected service date i.e. ${params.data.effectiveDate} to all the linked pipelines?`
    //         );
    //     }
    // }

    onClickSaveFilingCarrier = () => {
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      addEditFilingCarrierMaintenanceDataStore.saveFilingCarrier();
    }
    
    async pushToVmacs(env) {
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      const needToSync = addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0]) || addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.some((item) => item.syncStatus && item.syncStatus === SyncStatus[1] && item.syncStatus !== SyncStatus[2] );
      if(addEditFilingCarrierMaintenanceDataStore.selectedFilingCarrierName.label !== 'Select' || needToSync ||  (addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus && addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus !== SyncStatus[2])) {
        if(((addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus && addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus=== SyncStatus[0]) || addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0])) && env === 'Push TariffEntity to Vmacs Prod' && addEditFilingCarrierMaintenanceDataStore.selectedFilingCarrierName.label !== 'Select') {
            const confirmService = new ConfirmService();
            confirmService.showConfirmWithCustomComponent(
              () => {},
              ConfirmWithOK,
              {secondaryText: 'Filing Carrier has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
              'confirmPopup'
            );
          } else {
              const confirmService = new ConfirmService();
              confirmService.showConfirmDialog(
                () => addEditFilingCarrierMaintenanceDataStore.pushSelectedRowsToVmacs(env),
                'Active',
                `Are you sure you want to push the selected data to the ${env} Environment?`
            );
          }
      } else {
        const secondaryText = 'Please select only the active and unsynced Filing Carrier to perform the operation';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
  }
    
    onClickClose = () => {
        const { addEditFilingCarrierMaintenanceDataStore } = this.props;
        addEditFilingCarrierMaintenanceDataStore.onCloseFilingCarrierDetails();
    }

    pushButtonDisabled(env) {
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      if(env === 'Push TariffEntity to Vmacs Test') {
        const needToSync = addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[0] && item.syncStatus !== SyncStatus[2]);
        return  !addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData || (!addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.entityID || addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.entityID === 0) || (addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus && (addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus === SyncStatus[1] || addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus === SyncStatus[2] && !needToSync)); 
      }
      const needToSync = addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter((i) => i.isSelected == true).some((item) => item.syncStatus && item.syncStatus === SyncStatus[1] && item.syncStatus !== SyncStatus[2] );
        return  !addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData || (!addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.entityID || addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.entityID === 0) || ( addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus && addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData.syncStatus === SyncStatus[2] && !needToSync);
    }

    deactivateFilingCarriereData = async () => {
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      // const updatedRowIDs: any = addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter(a => addEditFilingCarrierMaintenanceDataStore.getUpdatedRowIDs().includes(a.entityID.toString()));
      const updatedRowIDs: any = [];
      if(updatedRowIDs.length > 0 || addEditFilingCarrierMaintenanceDataStore.unsavedChanges) {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => addEditFilingCarrierMaintenanceDataStore.deactivateFilingCarriereData(),
          'Inactive',
          'All the unsaved changes will be lost. Are you sure you want to deactivate the selected filing carrier?'
      );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
        () => addEditFilingCarrierMaintenanceDataStore.deactivateFilingCarriereData(),
        'Inactive',
        'Are you sure you want to deactivate the selected filing carrier?'
      );
      }
    }

    activateFilingCarriereData = async () => {
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      // const updatedRowIDs: any = addEditFilingCarrierMaintenanceDataStore.addOrEditLinkerGridData.filter(a => addEditFilingCarrierMaintenanceDataStore.getUpdatedRowIDs().includes(a.entityID.toString()));
      const updatedRowIDs: any = [];
      if(updatedRowIDs.length > 0 || addEditFilingCarrierMaintenanceDataStore.unsavedChanges) {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => addEditFilingCarrierMaintenanceDataStore.activateFilingCarriereData(),
          'Inactive',
          'All the unsaved changes will be lost. Are you sure you want to activate the selected filing carrier?'
      );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
        () => addEditFilingCarrierMaintenanceDataStore.activateFilingCarriereData(),
        'Inactive',
        'Are you sure you want to activate the selected filing carrier?'
      );
      }
    }

    

    render(): React.ReactNode {
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderFilingCarrierMaintenanceScreen()}
        </Container>
      );
    }

    renderFilingCarrierMaintenanceScreen() : React.ReactNode {
      const { addEditFilingCarrierMaintenanceDataStore } = this.props;
      return (
        <Row>
            <Col>
              <div className='filingEntityMaintenanceBox'>
                <div className='screenHeader'>
                  <h2 className='screen_title'>Filing Carrier Details</h2>
                </div>
                <Prompt when={addEditFilingCarrierMaintenanceDataStore.unsavedChanges} message={'You have unsaved changes, Are you sure you want to leave?'}/>
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                    <label className="standard_label_style">Filing Carrier Name</label>
                      <ReactSelect
                        className='expanded_select'
                        values={addEditFilingCarrierMaintenanceDataStore.selectedFilingCarrierName}
                        placeholder="Select"
                        options={[{ value: 'Select', label: 'Select' }, ...addEditFilingCarrierMaintenanceDataStore.pipelineOwnerDropdownOptions]}
                        onChange={(e: any) => {addEditFilingCarrierMaintenanceDataStore.selectedFilingCarrierName = e; addEditFilingCarrierMaintenanceDataStore.getPipelineSystemDropdown();  addEditFilingCarrierMaintenanceDataStore.unsavedChanges =  true;}}
                        isMulti={false}
                        isSearchable={true}
                        disabled={addEditFilingCarrierMaintenanceDataStore.isEditingFilingCarrier}
                    />
                     <label className="standard_label_style">Filing Carrier Code</label>
                     <input
                        type="text"
                        className="search_text_field input"
                        onChange={(e)=>{addEditFilingCarrierMaintenanceDataStore.selectedFilingCarrierCode = e.target.value; addEditFilingCarrierMaintenanceDataStore.unsavedChanges =  true;}}
                        value={addEditFilingCarrierMaintenanceDataStore.selectedFilingCarrierCode}
                        disabled={addEditFilingCarrierMaintenanceDataStore.isSaveDisabled()}
                        readOnly={addEditFilingCarrierMaintenanceDataStore.isSaveDisabled()}
                        maxLength={10}
                    />
                     <label style ={{color : 'red', fontSize: '12px', marginLeft: '5px'}}>* Max char length is 10</label>
                </div>
              </div>
            </div>
            
            
              
              <div style={{'marginTop':'20px'}}>
                    <div className='screenHeader'>
                        <h2 className='screen_title'>Related Base Pipeline</h2>
                    </div>
              
            <div className='filingEntityMaintenanceBox'>
                <div className='pipelineLinkerBox'>
                    <div className="rotatedText">
                      Linker
                    </div>
                    <Row style={{margin:'20px', width: '60%', height: '40%'}}>
                      <AgGridComponent gridConfig={this.getAddLinkerDetailsGridConfig('Filing Carrier Details')}/>
                    </Row>
                </div>
              </div>


                <div className='selectContainer'>
                  <div className='searchButtonDiv'>
                        <div className='otherHeaderButtonsDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickSaveFilingCarrier} disabled={addEditFilingCarrierMaintenanceDataStore.isSaveDisabled()}>
                                Save
                            </CustomButton>
                           {this.props.addEditFilingCarrierMaintenanceDataStore.editableFilingCarrierData?.action  === 'D'  ?  <CustomButton type={CustomButtonType.Submit} onClick={this.activateFilingCarriereData} disabled={addEditFilingCarrierMaintenanceDataStore.isDeleteDisabled()}>
                                {'Activate'}
                            </CustomButton> :  <CustomButton type={CustomButtonType.Submit} onClick={this.deactivateFilingCarriereData} disabled={addEditFilingCarrierMaintenanceDataStore.isDeleteDisabled()}>
                                {'Deactivate Dropdown'}
                            </CustomButton>}
                            {/* <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push TariffEntity to Vmacs Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Push TariffEntity to Vmacs Test')}>
                                Push to Test
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Push TariffEntity to Vmacs Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Push TariffEntity to Vmacs Prod')}>
                                Push to Prod
                            </CustomButton> */}
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onClickClose} disabled= {false}>
                                Close
                            </CustomButton>
                          </div>
                      </div>
              </div>
            </div>
            </Col>
        </Row>
      );
    }

}