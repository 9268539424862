import React from 'react';
import { DefaultPageComponent } from '../../../common/components/default-page-component';
import { BaseTariffContainer } from '../base-tariff/components/create-base-tariff-page';
import { baseTariffDataStore } from '../base-tariff/stores';
import { TariffLogContainer } from '../tariff-log/components/create-tariff-log-page';
import { logEntryDataStore, tariffLogDataStore } from '../tariff-log/stores';
import { PMCTariffContainer } from '../pmc-tariff/components/pmc-tariff-page';
import { pmcTariffDataStore } from '../pmc-tariff/stores';
import { SystemFeeMaintenanceContainer } from '../system-fee-maintenance/components';
import { systemFeeMaintenanceDataStore } from '../system-fee-maintenance/stores';
import { ProjectRequestContainer } from '../project-request/components';
import { projectRequestDataStore, projectRequestStep1DataStore, projectRequestStep2DataStore } from '../project-request/stores';
import { ProjectRequestStep1Container } from '../project-request/components/step_1';
import { ProjectRequestStep2Container } from '../project-request/components/step_2';
import {AddEditSystemFeeMaintenance} from '../system-fee-maintenance/components/AddEditSystemFeeMaintenance';
import { TariffOptionsContainer } from '../tariff-options/components';
import { tariffOptionsDataStore } from '../tariff-options/stores';
import { TariffOptionsContainer as  TariffOptionsIndexContainer } from '../tariff-options-index/components';
import { tariffOptionsDataStore as  tariffOptionsIndexDataStore } from '../tariff-options-index/stores';
import { IndexRateMaintenanceContainer } from '../index-rate-maintenance/component/index-rate-maintenance-container';
import { indexRateMaintenanceDataStore } from '../index-rate-maintenance/stores';
import { PMCGridViewContainer } from '../pmc-grid-view/components/pmc-grid-view';
import { pmcGridViewDataStore } from '../pmc-grid-view/stores';
import { PMCDashboardContainer } from '../pmc-dashboard/components/pmc-dashboard';
import { pmcDashboardDataStore } from '../pmc-dashboard/stores';
import { TariffDashboardContainer } from '../traiff-dashboard/component/tariff-dashboard-container';
import { tariffDashboardDataStore } from '../traiff-dashboard/stores';
import { CreateTariffLogEntryContainer } from '../tariff-log/components/create-log-entry';
import { tariffOptionsDataStoreProject } from '../project-request/tariff-options-project/stores';
import { RightAngleXRefContainer } from '../right-angle-xref/components/right-angle-xref';
import { rightAngleXRefDataStore } from '../right-angle-xref/stores';
import { ProcessOptionStep2Container } from '../process-tariff-options/components/process-tariff-options';
import { processOptionStep2DataStore } from '../process-tariff-options/stores';
import { tariffOptionsDataStoreLog } from '../process-tariff-options/tariff-options-project/stores';

export const componentMapper = new Map<string, () => React.ReactElement>([
    [
        'DefaultPageComponent',
        () => (
            <React.Fragment>
                <DefaultPageComponent />
            </React.Fragment>
        )
    ],
    [
        'PMCDashboardContainer',
        () => (
            <React.Fragment>
                <PMCDashboardContainer pmcDashboardDataStore={pmcDashboardDataStore} />
            </React.Fragment>
        )
    ],
    [
        'BaseTariffContainer',
        () => (
            <React.Fragment>
                <BaseTariffContainer baseTariffDataStore={baseTariffDataStore} />
            </React.Fragment>
        )
    ], [
        'TariffLogContainer',
        () => (
            <React.Fragment>
                <TariffLogContainer tariffLogDataStore={tariffLogDataStore} />
            </React.Fragment>
        )
    ],[
        'TariffOptionsContainer',
        () => (
            <React.Fragment>
                <TariffOptionsContainer tariffOptionsDataStore={tariffOptionsDataStore} />
            </React.Fragment>
        )
    ],[
        'TariffOptionsIndexContainer',
        () => (
            <React.Fragment>
                <TariffOptionsIndexContainer tariffOptionsDataStore={tariffOptionsIndexDataStore} />
            </React.Fragment>
        )
    ],[
        'PMCTariffContainer',
        () => (
            <React.Fragment>
                <PMCTariffContainer pmcTariffDataStore={pmcTariffDataStore} />
            </React.Fragment>
        ),
    ],
    [
        'SystemFeeMaintenanceContainer',
        () => (
            <React.Fragment>
                <SystemFeeMaintenanceContainer systemFeeMaintenanceDataStore={systemFeeMaintenanceDataStore} />
            </React.Fragment>
        ),
    ],
    [
        'PMCGridViewContainer',
        () => (
            <React.Fragment>
                <PMCGridViewContainer pmcGridViewDataStore={pmcGridViewDataStore} />
            </React.Fragment>
        ),
    ],
    [
        'AddEditSystemFeeMaintenance',
        () => (
            <React.Fragment>
                <AddEditSystemFeeMaintenance systemFeeMaintenanceDataStore={systemFeeMaintenanceDataStore} />
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestContainer',
        () => (
            <React.Fragment>
                <ProjectRequestContainer projectRequestDataStore={projectRequestDataStore} />
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestStep1Container',
        () => (
            <React.Fragment>
                <ProjectRequestStep1Container projectRequestDataStore={projectRequestDataStore} projectRequestStep1DataStore={projectRequestStep1DataStore} />
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestStep2Container',
        () => (
            <React.Fragment>
                <ProjectRequestStep2Container projectRequestDataStore={projectRequestDataStore} projectRequestStep1DataStore={projectRequestStep1DataStore} projectRequestStep2DataStore={projectRequestStep2DataStore} key={1} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject}/>
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestStep2InfraContainer',
        () => (
            <React.Fragment>
                <ProjectRequestStep2Container projectRequestDataStore={projectRequestDataStore} projectRequestStep1DataStore={projectRequestStep1DataStore} projectRequestStep2DataStore={projectRequestStep2DataStore} key={2} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject}/>
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestStep2CloneTariffContainer',
        () => (
            <React.Fragment>
                <ProjectRequestStep2Container projectRequestDataStore={projectRequestDataStore} projectRequestStep1DataStore={projectRequestStep1DataStore} projectRequestStep2DataStore={projectRequestStep2DataStore} key={3} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject}/>
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestStep2VMACSTestContainer',
        () => (
            <React.Fragment>
                <ProjectRequestStep2Container projectRequestDataStore={projectRequestDataStore} projectRequestStep1DataStore={projectRequestStep1DataStore} projectRequestStep2DataStore={projectRequestStep2DataStore} key={4} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject}/>
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestStep2VMACSConfirmContainer',
        () => (
            <React.Fragment>
                <ProjectRequestStep2Container projectRequestDataStore={projectRequestDataStore} projectRequestStep1DataStore={projectRequestStep1DataStore} projectRequestStep2DataStore={projectRequestStep2DataStore} key={5} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject}/>
            </React.Fragment>
        ),
    ],
    [
        'ProjectRequestStep2TariffReviewContainer',
        () => (
            <React.Fragment>
                <ProjectRequestStep2Container projectRequestDataStore={projectRequestDataStore} projectRequestStep1DataStore={projectRequestStep1DataStore} projectRequestStep2DataStore={projectRequestStep2DataStore} key={6} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject}/>
            </React.Fragment>
        ),
    ],
    [
        'IndexRateMaintenanceContainer',
        () => (
            <React.Fragment>
                <IndexRateMaintenanceContainer indexRateMaintenanceDataStore={indexRateMaintenanceDataStore} />
            </React.Fragment>
        ),
    ],
    [
        'TariffDashboardContainer',
        () => (
            <React.Fragment>
                <TariffDashboardContainer tariffDashboardDataStore={tariffDashboardDataStore} />
            </React.Fragment>
        ),
    ],
    [
        'CreateTariffLogEntryContainer',
        () => (
            <React.Fragment>
                <CreateTariffLogEntryContainer logEntryDataStore = {logEntryDataStore}/>
            </React.Fragment>
        )
    ],  [
        'RightAngleXRefContainer',
        () => (
            <React.Fragment>
                <RightAngleXRefContainer rightAngleXRefDataStore={rightAngleXRefDataStore}/>
            </React.Fragment>
        )
    ],
    [
        'ProcessOptionStep2Container',
        () => (
            <React.Fragment>
                <ProcessOptionStep2Container processOptionStep2DataStore={processOptionStep2DataStore} key={1} tariffOptionsDataStoreLog={tariffOptionsDataStoreLog}/>
            </React.Fragment>
        ),
    ],
    [
        'ProcessOptionStep2InfrastructureTestContainer',
        () => (
            <React.Fragment>
                <ProcessOptionStep2Container processOptionStep2DataStore={processOptionStep2DataStore} key={2} tariffOptionsDataStoreLog={tariffOptionsDataStoreLog}/>
            </React.Fragment>
        ),
    ],
    [
        'ProcessOptionStep2CloneTariffContainer',
        () => (
            <React.Fragment>
                <ProcessOptionStep2Container processOptionStep2DataStore={processOptionStep2DataStore} key={3} tariffOptionsDataStoreLog={tariffOptionsDataStoreLog}/>
            </React.Fragment>
        ),
    ],
    [
        'ProcessOptionStep2TariffReviewContainer',
        () => (
            <React.Fragment>
                <ProcessOptionStep2Container processOptionStep2DataStore={processOptionStep2DataStore} key={4} tariffOptionsDataStoreLog={tariffOptionsDataStoreLog}/>
            </React.Fragment>
        ),
    ],
    [
        'ProcessOptionStep2TariffApprovalContainer',
        () => (
            <React.Fragment>
                <ProcessOptionStep2Container processOptionStep2DataStore={processOptionStep2DataStore} key={5} tariffOptionsDataStoreLog={tariffOptionsDataStoreLog}/>
            </React.Fragment>
        ),
    ],
]);
