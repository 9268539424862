import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class HomeDashboardDataService {
    constructor(private restApi: RestApiService) {}
    async getTariffOption(requestData?): Promise<any[]> {
        if(requestData) {
            const { data } = await this.restApi.get<any>(`${Path.getHomeScreenTariffOption}?agencyID=${requestData.agencyID}&plID=${requestData.plID}&typeID=${requestData.typeID}&tariffNo=${requestData.tariffNo}&companyID=${requestData.companyID}&refTariffNo=${requestData.refTariffNo}&name=${requestData.name}`);
            return data; 
        }
        const { data } = await this.restApi.get<any>(Path.getHomeScreenTariffOption);
        return data;
    }

    async getAgencyDropdown(juridictionName): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.url_id9}?jurisdiction=${juridictionName}&agency=All&agencyCode=All`);
        return data;
    }

    async getPipelineSystem(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_1_getPipelines);
        return data;
    }

    async getOwnerCarrierData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id13);
        return data;
    }

    async getTariffTypesData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id10);
        return data;
    }

    async getProjectActionCenter(userEmail): Promise<any> {
        const { data } = await this.restApi.get<any>(`${Path.getProjectActionCenter}${userEmail}`);
        return data;
    }
    


}

export const homeDashboardDataService = new HomeDashboardDataService(restApiService);