import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class ProfitCenterMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    async getProfitCentersData(request, requestBody): Promise<any> {
        const { data } =  await this.restApi.post<any>(`${Path.getProfitCenterData}?PrftCntr=${request.profitCenter}&Desc=${request.description}&ToDate=${request.toDate}&FromDate=${request.fromDate}&ColId=${request.sortColId}&Sort=${request.sort}&isPagination=true&pageno=${request.page}&pagesize=${request.pageSize}&SuccrPrftCtr=${request.succprofitCenter}&PrftCntrGrp=${request.profitCenterGrp}&baseID=${request.baseID}`, requestBody, {});
        return data;
    }

    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
                 return data;
    }
        
    async getPipelineSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystemsProfitCenter);
        return data;
    }
    async getSettingNameOptions(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id3);
        return data;
    }  
    async getJurisdictionDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id8);
        return data;
    }
    async getTariffArea(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getLineSegmentSystemAreaData);
        return data;
    }
    async getTariffAgencies(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.url_id4);
        return data;
    }
    async updateProfitCenterMaintenanceData(requestBody, onEditProfitCenter): Promise<any[]> {
        if(onEditProfitCenter) {
            const { data } = await this.restApi.put<any>(Path.addProfitCenterDetailsAPI, requestBody, {});
            return data;
        } else {
            const { data } = await this.restApi.post<any>(Path.addProfitCenterDetailsAPI, requestBody, {});
            return data;
        }
    }  
    async deleteProfitCenterMaintenanceData(requestBody): Promise<any> {
        const { data } = await this.restApi.delete<any>(Path.addProfitCenterDetailsAPI, requestBody, {});
        return data;
    }  
    async pushToVmacs(reqbody: any): Promise<any> {
        const { data } = await this.restApi.post<any>(Path.vmacsProfitCenterEndpoint, reqbody, {});
        return data;
    }  

    async updateStatusFromDelToActive(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateProfitCenters, reqbody, {});
        return data;
    }

    async saveProfitCenter(reqbody: any) : Promise<any> {
        const { data } = await this.restApi.put<any>(Path.saveTariffProfitCenter, reqbody, {});
        return data;
    }

    async getSelectedProfitCenterData(profitCenterID: any) : Promise<any> {
        const { data } = await this.restApi.get<any>(`${Path.getTariffProfitCenter}?profitCenterID=${profitCenterID}`);
        return data;
    }
    async getRelatedGridData(profitCenterID, pipelineId: any, agencyId) : Promise<any> {
        const { data } = await this.restApi.get<any>(`${Path.getTariffRelProfitCenter}?profitCenterID=${profitCenterID}&tariffAreaID=${pipelineId}&agencyID=${agencyId}`);
        return data;
    }
}

export const profitCenterMaintenanceDataService = new ProfitCenterMaintenanceDataService(restApiService);
