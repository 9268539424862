import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { observer, inject } from 'mobx-react';
import { DataStore } from '../../../common/stores/data-store';
import { componentMapper } from './component-mapper';
import history from '../../../shared/components/header-component/component/history';
import { PathDataTypeMap } from '../../../static/data';
import { dataStore } from 'common/stores';
import { MasterDataTypeOptions } from './constants';


interface MasterDataComponentProps {
    dataStore: DataStore;
}

@inject('dataStore')
@observer
export class MasterDataComponent extends Component<MasterDataComponentProps> {
    dataTypeOptionsFiltered: any;
    constructor(props) {
        super(props);
        this.dataTypeOptionsFiltered = dataStore.configureDataTypeOptionsByRole(MasterDataTypeOptions);
        this.props.dataStore.init(this.dataTypeOptionsFiltered);
    }

    componentWillUnmount() {
        this.props.dataStore.reset();
        const intervalId = setInterval(()=>{
            console.log('timer interval')
        }, 30000);
    }

    render(): React.ReactNode {
        const {
            dataStore: { dataTypes }
        } = this.props;
        return (
            <Container fluid={true}>
                <Row className="mt-3">
                    <Col>{this.renderGrid()}</Col>
                </Row>
            </Container>
        );
    }

    renderGrid(): React.ReactNode {
        const {
            dataStore: { selectedDataType }
        } = this.props;
        let selectedValue = '';
        const selectedPathMapping = PathDataTypeMap.filter(
            x => x.path.toLowerCase() === history.location.pathname.toLowerCase()
        );
        if (selectedPathMapping[0]) {
            selectedValue = selectedPathMapping[0].dataTypeValue;
        } else {
            selectedValue = selectedDataType;
        }
        const selectedComponent = componentMapper.get(selectedValue);
        if (selectedComponent) return selectedComponent();
    }
}
