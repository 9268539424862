import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import Multiselect from 'multiselect-react-dropdown';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker'
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import '../project-request.scss';

interface projectRequestDetailsInterface {
    datastore: any;
    projectRequestDatastore: any;
    showOnlyUS: () => boolean;
    onChangePipeline: (selectedList: any, selectedItem: any) => void;
    allDisabled: boolean;
    checkRoleAccessSave?: boolean;
}

export const ProjectRequestDetails = (props: projectRequestDetailsInterface) => {
    const checkRoleAccessSave = props.checkRoleAccessSave
    return (
        <div className='ProjectRequestDetailsBox'>
        <div className='screenHeader'>
                            <h2 className='screen_title'> Project Request</h2>
                        </div>
                        <div className='selectContainer'>
                                <Row style={{'marginTop': '0px'}}>
                                    <Col style={{'marginTop': '10px'}}>
                                            <label className='standard_label_style'>Workflow ID</label>
                                            <input
                                            type='text'
                                            className='search_text_field'
                                            // onChange = {(e) => {}}
                                            value={props.datastore?.workflowId}
                                            readOnly
                                            />
                                    </Col>
                                    <Col style={{'marginTop': '10px'}}>
                                            <label className='standard_label_style'>Workflow Type</label>
                                            <select className='search_text_field' disabled={true} value={props.projectRequestDatastore.selectedProjectRequestType}>
                                                <option>{props.projectRequestDatastore.selectedProjectRequestType}</option>
                                            </select>
                                    </Col>
                                    <Col style={{'marginTop': '10px'}}>
                                            <label className='standard_label_style' style={{'minWidth':'unset'}}>Go-live{(!props.allDisabled||!props.checkRoleAccessSave)&&('*')}</label>
                                            <ReactDatePicker 
                                            readOnly={!props.checkRoleAccessSave} 
                                            classNames='search_text_field' onDateChange={(e)=>props.datastore.changeFormData(e, 'goLive')} selectedDate={props.datastore.formData?.goLive} disabled={props.datastore.workflowStepStatus==null}/>
                                    </Col>
                                </Row>
                                <Row style={{'marginTop': '0px'}}>
                                    <Col style={{'marginTop': '10px'}}>
                                        <label className='standard_label_style'>Project Name*</label>
                                        <input
                                            type='text'
                                            className='search_text_field'
                                            onChange = {(e) => {props.datastore.changeFormData(e.target.value, 'projectName')}}
                                            value={props.datastore?.formData.projectName}
                                            readOnly={props.allDisabled}
                                        />
                                    </Col>
                                    {props.showOnlyUS() && (
                                    <Col style={{'marginTop': '10px', 'display':'inline-flex'}}>
                                            <label className='standard_label_style'>Tariff</label>
                                            <ReactSelect
                                                        className='select'
                                                        values={{value: props.datastore?.formData.selectedBaseTariff, label: props.datastore?.formData.selectedBaseTariff}}
                                                        placeholder={'Select Tariff'}
                                                        disabled={!props.checkRoleAccessSave} 
                                                        // disabled={projectRequestStep2DataStore.formData2a.baseTariff === ''}
                                                        options={Array.isArray(props.datastore.baseTariffDropdown) && props.datastore.baseTariffDropdown.length && props.datastore.baseTariffDropdown.map(option=> {return{value: option.baseTariff, label: option.baseTariff }}) ||  []}
                                                        onChange={(e)=>{props.datastore.changeFormData((e as Option)?.value, 'selectedBaseTariff')}}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                    />
                                    </Col>)||<Col></Col>}
                                    <Col>
                                    </Col>
                                </Row>
                                {props.showOnlyUS() && (
                                <Row style={{'marginTop': '0px'}}>
                                    <Col style={{'marginTop': '10px'}}>
                                        <label className='standard_label_style'>Tracking</label>
                                        <div className='status-block'>
                                            <input
                                            type='radio'
                                            value='true'
                                            onChange={() => props.datastore.changeFormData(true, 'tracking')}
                                            checked={props.datastore.formData.tracking === true}
                                            disabled={props.allDisabled}
                                            />Yes
                                            <input
                                            type='radio'
                                            value='false'
                                            onChange={() => props.datastore.changeFormData(false, 'tracking')}
                                            checked={props.datastore.formData.tracking === false}
                                            disabled={props.allDisabled}
                                            />No
                                        </div>
                                  </Col>
                                  <Col style={{'marginTop': '10px'}}>
                                        <label className='standard_label_style'>Internal DOR</label>
                                        <div className='status-block'>
                                            <input
                                            type='radio'
                                            value='true'
                                            onChange={() => props.datastore.changeFormData(true, 'dor')}
                                            checked={props.datastore.formData.dor === true}
                                            disabled={props.allDisabled}
                                            />Yes
                                            <input
                                            type='radio'
                                            value='false'
                                            onChange={() => props.datastore.changeFormData(false, 'dor')}
                                            checked={props.datastore.formData.dor === false}
                                            disabled={props.allDisabled}
                                            />No
                                        </div>
                                  </Col>
                                  <Col></Col>
                                </Row>)}
                                <Row style={{'marginTop': '0px'}}>
                                    {props.showOnlyUS() && (
                                    <Col style={{'marginTop': '10px', 'marginRight':'10px'}} xs={5}>
                                        <label className='standard_label_style'>Pipeline Systems</label>
                                        <Multiselect
                                            className='multiselect'
                                            isObject={false}
                                            selectedValues={props.datastore.formData.pipelineSystems}
                                            // onKeyPressFn={function noRefCheck() { }}
                                            onRemove={props.onChangePipeline}
                                            // onSearch={function noRefCheck() { }}
                                            onSelect={props.onChangePipeline}
                                            options={props.datastore.pipelinesDropdown.map(
                                                (option) => option.plName
                                            )}
                                            disable={props.allDisabled}
                                            style={{ searchBox :{backgroundColor: props.allDisabled ? 'lightgray' : 'white'}}}
                                        />
                                    </Col>) || (<Col></Col>)}
                                    <Col style={{'marginTop': '10px','marginLeft': '10px'}} >
                                            <label className='standard_label_style'>Description</label>
                                            <textarea 
                                            className='textarea'
                                            value={props.datastore.formData.description}
                                            onChange={(e)=>props.datastore.changeFormData(e.target.value, 'description')}
                                            disabled={!props.checkRoleAccessSave||props.datastore.workflowStepStatus==null}
                                            />
                                    </Col>
                                </Row>
                                <div className='searchButtonDiv'>
                                    <div className='otherHeaderButtonsDiv'> 
                                        <CustomButton type={CustomButtonType.Submit} disabled={props.datastore.workflowStepStatus==null || !props.checkRoleAccessSave} onClick={()=>{props.datastore.step1SavePostWorkflowDetails()}}>
                                            Save
                                        </CustomButton>
                                    </div>
                                </div>
                        </div>
        </div>
    )
}