import { action, observable, makeAutoObservable, toJS, computed } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { TariffRateTypesDataService } from '../services/tariff-rate-types-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import { TariffRateTypesDBTableName } from '../../../../Path';
import moment from 'moment';

export class TariffRateTypesDataStore {
  
    @observable tariffRateTypesData : any [] = [];
    @observable fercTypesDropwdown: any [] = [];
    @observable fercTypesDropwdownUI: any [] = [];
    @observable rateTypesDropdown: any [] = [];
    @observable prSummaryInlineDropdown: any [] = [];
    @observable serviceBasisInlineDropdown: any [] = [];
    @observable updatedRows: any [] = [];
    @observable selectedFercType = { value: 'All', label: 'All' };
    @observable selectedRateType = { value: 'All', label: 'All' };
    @observable username;
    @observable showLoader = false;
    
    constructor(
        public tariffRateTypesDataService: TariffRateTypesDataService,
        public uiService: UiService,
        public agGridService: AgGridService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        // this.selectedSystemAreaType='All';
        // this.selectedSystemAreaName='';
        // this.selectedPhysicalPipeline='All';
        this.getRateTypesGridData();
        this.getFercTypesDropDownData();
        this.getRateTypesDropDownData();
        this.getServiceBasisInlineDropdown();
        this.getPRSummaryInlineDropdown();
        this.username = this.accountStore.displayName;
        this.updatedRows = [];
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getRateTypesGridData(): Promise<void> {
        const response = await this.tariffRateTypesDataService.getRateTypesGridData(this.fercTypesDropwdown.find(x=>x.valueText===this.selectedFercType.label)?.settingID||0, this.rateTypesDropdown.find(x=>x.label===this.selectedRateType.label)?.value||0);
        this.tariffRateTypesData = response.map((x, index)=>{return {...x, rowId: index, existing: true}});    
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getFercTypesDropDownData(): Promise<void> {
        const response = await this.tariffRateTypesDataService.getFercTypesDropDownData();
        this.fercTypesDropwdown = response.map(x=>{return {'settingID':x.settingID, 'valueText':x.valueText}});    
        this.fercTypesDropwdownUI = response.map(x=>{return {'value':x.valueText, 'label':x.valueText}});    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getRateTypesDropDownData(): Promise<void> {
        const response = await this.tariffRateTypesDataService.getRateTypesDropDownData();
        const a = response.map(x => { return { 'value': x.tariffRateTypeID, 'label': x.tariffRateDesc } }); 
        this.rateTypesDropdown = a;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPRSummaryInlineDropdown(): Promise<void> {
        const response = await this.tariffRateTypesDataService.getPRSummaryDropdownData();
        this.prSummaryInlineDropdown = response;    
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getServiceBasisInlineDropdown(): Promise<void> {
        const response = await this.tariffRateTypesDataService.getActivityType();
        this.serviceBasisInlineDropdown = response;    
    }

    isEditableSettingNameFercType(params) {
        this.addValuesInCellDropdownsFercType(params);
        return true;
   }

   addValuesInCellDropdownsFercType(params): void {
       if(params.data){
           this.agGridService.updateOptionCellEditorValues(
           this.fercTypesDropwdown,
           'fercType', 
           'valueText',
           );
       }
   }

   
    isEditableSettingNamePRSummary(params) {
        this.addValuesInCellDropdownsPRSummary(params);
        return true;
    }

    addValuesInCellDropdownsPRSummary(params): void {
        if(params.data){
            this.agGridService.updateOptionCellEditorValues(
            this.prSummaryInlineDropdown,
            'prSummaryAction', 
            'valueText',
            );
        }
    }

    
    isEditableSettingNameServiceBasis(params) {
        this.addValuesInCellDropdownsServiceBasis(params);
        return true;
    }

    addValuesInCellDropdownsServiceBasis(params): void {
        if(params.data){
            this.agGridService.updateOptionCellEditorValues(
            this.serviceBasisInlineDropdown,
            'serviceBasis', 
            'valueText',
            );
        }
    }
    hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async SaveRateTypes(): Promise<void> {
        const requestBody = this.TariffRateTypesData.filter(x=>this.updatedRows.includes(x.rowId));
        if(requestBody.length > 0) {
        requestBody.forEach((item) => {
            if(item.newRecord){
                item.tariffRateTypeID=0;
                item.createUser = this.username;
                item.createDate = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')  
            }
            item.fercTypeID=this.fercTypesDropwdown.find(x=>x.valueText===item.fercType)?.settingID;
            item.serviceBasis=(item.serviceBasis==='Receipt' || item.serviceBasis==='R') ? 'R' :(item.serviceBasis==='Delivery' || item.serviceBasis==='D')? 'D' : item.serviceBasis;
            item.prSummaryActionID=this.prSummaryInlineDropdown.find(x=>x.valueText===item.prSummaryAction)?.settingID;
            item.updateuser = this.username;
            item.updatedate = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')
        })} else {
            this.uiService.toastService.error('No values have changed! Please update records before saving!');
            return;
        }
        const duplicates = this.TariffRateTypesData.filter(item=>item.action!=='D').map(x=>x.tariffRateType);
        if(this.hasDuplicates(duplicates)){
            this.uiService.toastService.error('Following duplicate values were found: '+ this.toFindDuplicates(duplicates).toString()+'!');
            return;
        }
        const response: any = await this.tariffRateTypesDataService.updateRateTypes(requestBody);
        if(response){
            let error ='';
            this.agGridService.deselectAllRows();
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
           }
           if(error===''){
                this.tariffRateTypesData = response.map((x, index)=>{return {...x, rowId: index, existing: true}});
                this.uiService.toastService.success('Tariff Rate Type/(s) Updated Successfully!')
                this.setUpdatedRowIds([])
           }
       }       
    }
    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async deleteRateTypes(): Promise<void> {
        const errorFlag = false;
        if(errorFlag){
            return;
        }
        const requestBody: any = await this.agGridService.getSelectedRows(); 
        this.tariffRateTypesData=this.TariffRateTypesData.filter(x=>x.existing===true || requestBody.every(y=>x.rowId!==y.rowId))
        const slicedRequestBody = [...requestBody].filter(x=>x.existing===true);
        if(slicedRequestBody.length > 0) {
        slicedRequestBody.forEach((item) => {
            delete item.rowId
            item.updateUser = this.username;
            item.updateDate = moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss')
        })
        const response: any = await this.tariffRateTypesDataService.deleteRateTypes(slicedRequestBody);
        if(response){
            let error ='';
            this.agGridService.deselectAllRows();
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
           }
           if(error===''){
                this.tariffRateTypesData = response.map((x, index)=>{return {...x, rowId: index, existing: true}});
                this.uiService.toastService.success('Tariff Rate Type/(s) Deactivated Successfully!')
           }
       }  }   
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async activateRateTypes(): Promise<void> {
        const duplicates = this.TariffRateTypesData.filter(item=>item.action!=='D').map(x=>x.tariffRateType);
        if(this.hasDuplicates(duplicates)){
            this.uiService.toastService.error('Following duplicate values were found: '+ this.toFindDuplicates(duplicates).toString()+'!');
            return;
        }
        const requestBody: any = await this.agGridService.getSelectedRows(); 
        const slicedRequestBody = [...requestBody];
        const response: any = await this.tariffRateTypesDataService.activateRateTypes(slicedRequestBody);
        if(response){
            let error ='';
            this.agGridService.deselectAllRows();
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
           }
           if(error===''){
                this.tariffRateTypesData = response.map((x, index)=>{return {...x, rowId: index, existing: true}});
                this.uiService.toastService.success('Tariff Rate Type/(s) Activated Successfully!')
           }
       }     
    }



    @action
    clearScreen(){
        this.selectedFercType={ value: 'All', label: 'All' };
        this.selectedRateType={ value: 'All', label: 'All' };
        this.getRateTypesGridData();
        this.agGridService.deselectAllRows();
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async pushToVmacs(env, data): Promise<void> {
        // const action = data.action === 'U' ? 'Update' : 'Insert';
        const updatedRowIds = this.TariffRateTypesData.filter(x=>this.updatedRows.includes(x.rowId));
        if(updatedRowIds.length > 0) {
            this.uiService.toastService.error('All unsaved changes will be lost. Please save before proceeding!');
            return;
        }
        const requestBody: any =  { 
            'http_action' : '',
            'http_record_id': data.tariffRateTypeID,
            'http_cts_tablename': TariffRateTypesDBTableName.TableName,
            'http_cts_mappingtablename' : TariffRateTypesDBTableName.MappingTableName,
            'http_userid': this.username,
            'http_environment': env.toUpperCase()  
        };
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response = await this.tariffRateTypesDataService.pushToVmacs(requestBody);
            if(response) {
                this.getRateTypesGridData();
                this.uiService.toastService.success('Synced Successfully!');
            }
        }
    }

    @action
    setRateType(value){
        this.selectedRateType=value
    }

    @action
    setFercType(value){
        this.selectedFercType=value
    }

    @action
    mvmtClicked(params){
        this.tariffRateTypesData=this.TariffRateTypesData;
        const tariffRateTypesDataIndex=this.tariffRateTypesData.findIndex(x=>x.rowId===params.data.rowId);
        this.tariffRateTypesData[tariffRateTypesDataIndex]['mvmt'] = !this.tariffRateTypesData[tariffRateTypesDataIndex]['mvmt'];
        this.setUpdatedRowIds([...this.updatedRows, params.data.rowId]);
    }

    @action
    throwTariffRateTypeLengthError(){
        this.uiService.toastService.error('Length of Tariff Rate Type Cannot be Greater than 3!');
    }

    @computed
    get TariffRateTypesData() {
        return toJS(this.tariffRateTypesData);
    }
   
    @action
    setGridData(value){
        this.tariffRateTypesData=value
    }

    @action
    setUpdatedRowIds(value){
        this.updatedRows=value
    }

    @action
    getUpdatedRows = () => {
        return this.updatedRows;
    }
    
}
