import { action, observable, makeAutoObservable, toJS, computed } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { PipelineMaintenanceDataService } from '../services/pipeline-maintenance-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import moment from 'moment';
import history from '../../../../shared/components/header-component/component/history';
import _ from 'lodash';
import { PipelineMaintenanceDBTableName } from 'Path';

export class EditPipelineMaintenanceDataStore {
    @observable showLoader = false;
    @observable ownershipData: any [] = [];
    @observable entitiesData: any [] = [];
    @observable relatedAreasData: any [] = []; 
    @observable dropdownPhysicalPipeline : any [] = [];
    @observable qbInvoiceFrequencyDropdownOptions: any [] = [] ;   
    @observable qbRateBasisDropdownOptions: any [] = [];
    @observable lossGainDropdownOptions: any [] = [];
    @observable plaBasisDropdownOptions: any [] = [];
    @observable allocationBasisDropdownOptions: any [] = [];
    @observable ownersDropdownOptions: any [] = [];
    @observable distributeLGToDropdownOptions: any [] = [];
    @observable JIBDropdownOptions: any [] = [];
    @observable legalEntityDropdownOptions: any [] = [];
    @observable lineOfBusinessDropdownOptions: any [] = [];

    @observable formData = {
        plname: '',
        effectiveDate: '' as unknown as Date,
        endDate: '' as unknown as Date,
        operatedInd: true,
        qbInvoiceFreq: '',
        qbRateBasis: '',
        gravityBankInd: false,
        sulphurBankInd: false,
        lossGain: '',
        plaBasis: '',
        allocationBasis: '',
        dailyCloseTime: ''
    }
    @observable pipelineCreated=false;
    @observable ownersCreated=false;
    @observable createdUser;
    @observable pipelineId;
    @observable syncedToTest = false;
    @observable syncedToProd = false;
    @observable saveChangesMade = false;
    
    constructor(
        public pipelineMaintenanceDataService: PipelineMaintenanceDataService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridService: AgGridService,
        public agGridServiceOwners: AgGridService,
        public agGridServiceEntities: AgGridService,
    ) {
        makeAutoObservable(this);
    }

    @Loader
    @action
    async init(pipelineId) {
       this.createdUser = this.accountStore.displayName;
       this.syncedToTest = false;
       this.syncedToProd = false;
       await this.getInitialData();
       this.getFormData(pipelineId);
    }

    @action
    reset(){
       this.formData= {
            plname: '',
            effectiveDate: '' as unknown as Date,
            endDate: '' as unknown as Date,
            operatedInd: true,
            qbInvoiceFreq: '',
            qbRateBasis: '',
            gravityBankInd: false,
            sulphurBankInd: false,
            lossGain: '',
            plaBasis: '',
            allocationBasis: '',
            dailyCloseTime: ''
       };
       this.ownershipData=[];
       this.entitiesData=[];
       this.relatedAreasData=[];
    }

    @Loader
    @action
    async getInitialData(){ 
        await this.getPhysicalPipelinesDropDownData(); 
        await this.getQBInvoiceFrequencyDropdown();
        await this.getQBRateBasisDropdown();
        await this.getLossGainDropdown();
        await this.getPLABasisDropdown();
        await this.getAllocationBasisDropdown();
        await this.getPipelineEntities();
        await this.getOwnersDropdown();
        await this.getDistributeLGToDropdown();
    }
    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler('Fetch Error In Pipeline Data')})
    async getFormData(pipelineId): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPipelineData(pipelineId);
        // this.formData = response;
        this.pipelineId=pipelineId;
        if (response.plName) {
            this.formData = {
                plname: response.plName,
                dailyCloseTime: response.dailyCloseTime,
                effectiveDate: new Date(response.effectiveDate) as unknown as Date,
                endDate: response.endDate==='9999-12-31T00:00:00'||response.endDate==='0001-01-01T00:00:00'||response.endDate===null? '' as unknown as Date:new Date(response.endDate) as unknown as Date,
                operatedInd: response.operatedInd === 'Y'? true : false,
                qbInvoiceFreq: this.qbInvoiceFrequencyDropdownOptions.find(x=>x.valueCode===String(response.qbInvoiceFreq))?.valueText,
                qbRateBasis: this.qbRateBasisDropdownOptions.find(x=>x.valueCode===response.qbRateBasis)?.valueText,
                gravityBankInd: response.gravityBankInd === 'Y'? true : false,
                sulphurBankInd: response.sulphurBankInd === 'Y'? true : false,
                lossGain: this.lossGainDropdownOptions.find(x=>x.valueCode===response.lossGain)?.valueText,
                plaBasis: this.plaBasisDropdownOptions.find(x=>x.valueCode===response.plaBasis)?.valueText,
                allocationBasis: this.allocationBasisDropdownOptions.find(x=>x.valueCode===response.allocationBasis)?.valueText
            }
            this.relatedAreasData=response.related;
            this.ownershipData=response.owners ? response.owners.map((owner, index)=>{return {
                'pipelineID': this.pipelineId,
                'pipelineOwnerID': owner.pipelineOwnerID,
                'createDate': owner.createDate, 
                'createUser': owner.createUser, 
                'owner': this.ownersDropdownOptions.find(x=>x.customerID===owner.ownerID)?.customerName, 
                'ownership': owner.ownership, 
                'effectiveDate': new Date(owner.ownerEffectiveDate).setHours(0,0,0,0), 
                'endDate': owner.ownerEndDate==='9999-12-31T00:00:00'||owner.ownerEndDate==='0001-01-01T00:00:00'||owner.ownerEndDate===null? '' : new Date(owner.ownerEndDate), 
                'transfer': owner.transfertoWarehouseIndicator,
                'distributeLG': this.distributeLGToDropdownOptions.find(x=>x.valueCode===owner.distributeLGTo)?.valueText,
                'rowId': index,
                'existing': true
            }}) : this.OwnershipData;
            this.entitiesData=response.legalEntities && response.legalEntities.map((entity, index)=>{return {
                'pipelineID': this.pipelineId, 
                'legalEntityID': entity.legalEntityID, 
                'createDate': entity.createDate, 
                'createUser': entity.createUser,
                'updateDate': entity.updateDate, 
                'updateUser': entity.updateUser,
                'startCycle': entity.startCycle==='9999-12-31T00:00:00'||entity.startCycle==='0001-01-01T00:00:00'||entity.startCycle===null? '' :new Date(entity.startCycle), 
                'endCycle': entity.endCycle==='9999-12-31T00:00:00'||entity.endCycle==='0001-01-01T00:00:00'||entity.endCycle===null? '' :new Date(entity.endCycle), 
                'stateCode': entity.stateCode,  
                'glLocation': entity.glLocation, 
                'legalEntity': this.legalEntityDropdownOptions.find(x=>String(x.LE_CD) === String(entity.legalEntity))?.LE_NM, 
                'lineOfBusiness': this.lineOfBusinessDropdownOptions.find(x=>String(x.LOB_CD) === String(entity.lineOfBusiness))?.LOB_NM, 
                'jointInterestBilling': this.JIBDropdownOptions.find(x=>String(x.JIB_CD) === String(entity.jointIntrestBilling))?.JIB_NM,
                'rowIdEntities': index,
                'existing': true
            }}) || this.entitiesData
            this.agGridServiceOwners.sortGrid('effectiveDate');        }
    }
    
    @action
    changeFormData(value, key) {
        this.formData= {...this.formData, [key]: value}
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPhysicalPipelinesDropDownData(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPhysicalPipelinesDropDownData();
        this.dropdownPhysicalPipeline = response;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getQBInvoiceFrequencyDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getQBInvoiceFrequencyDropdown();
        this.qbInvoiceFrequencyDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getQBRateBasisDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getQBRateBasisDropdown();
        this.qbRateBasisDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getLossGainDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getLossGainDropdown();
        this.lossGainDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPLABasisDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getPLABasisDropdown();
        this.plaBasisDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAllocationBasisDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getAllocationBasisDropdown();
        this.allocationBasisDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getOwnersDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getOwnersDropDown();
        this.ownersDropdownOptions = response.map(x=>{return {'customerID':x.customerID, 'customerName':x.customerName}});    
        // this.addValuesInCellDropdownsOwners();
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getDistributeLGToDropdown(): Promise<void> {
        const response = await this.pipelineMaintenanceDataService.getDistributeLGToDropdown();
        this.distributeLGToDropdownOptions = response.map(x=>{return {'valueCode':x.valueCode, 'valueText':x.valueText}});
        this.addValuesInCellDropdownsDistribute    
    }


    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error In Getting EBS Data. Legal Entities cannot be populated!'))
    async getPipelineEntities(): Promise<void> {
        const requestBody={
            'http_action':'Fetch',
            'http_flex_value':''
        }
        const response = await this.pipelineMaintenanceDataService.getPipelineEntities(requestBody);
        this.JIBDropdownOptions = JSON.parse(response.cts_response[0].ebsdata)[0].ResultSets.Table1;
        this.legalEntityDropdownOptions = JSON.parse(response.cts_response[0].ebsdata)[1].ResultSets.Table1;
        this.lineOfBusinessDropdownOptions = JSON.parse(response.cts_response[0].ebsdata)[2].ResultSets.Table1;
        this.addValuesInCellDropdownsLOB()
        this.addValuesInCellDropdownsLE()
        this.addValuesInCellDropdownsJIB()
    }
    
    
    // @action
    // isEditableSettingName(params) {
    //      this.addValuesInCellDropdownsOwners(params);
    //      return true;
    // }
    
    // addValuesInCellDropdownsOwners(params): void {
    //     const ownersNotEndDated=this.OwnershipData.filter(x=>x.endDate===''||x.endDate===null).map(y=>y.owner)
    //     this.agGridServiceOwners.updateOptionCellEditorValues(
    //        this.ownersDropdownOptions.filter(x=>!ownersNotEndDated.includes(x.customerName)),
    //        'owner', 
    //        'customerName',
    //     );
    // }

    addValuesInCellDropdownsOwners(params) {
        if(params.data){
            const ownersNotEndDated= this.OwnershipData.filter(x=>((((x.endDate==''||x.endDate==null||x.endDate==undefined)&&new Date(x.effectiveDate)<new Date(params.data?.effectiveDate))||x.effectiveDate===params.data?.effectiveDate))).map(y=>y.owner);
            return this.ownersDropdownOptions.filter(x=>!ownersNotEndDated.includes(x.customerName))
        }
    }

    @action
    isEditableSettingNameDistribute(params) {
         console.log(params);
         this.addValuesInCellDropdownsDistribute();
         return true;
    }


    addValuesInCellDropdownsDistribute(): void {
        this.agGridServiceOwners.updateOptionCellEditorValues(
           this.distributeLGToDropdownOptions,
           'distributeLG', 
           'valueText',
        );
    }

    @action
    isEditableSettingNameJIB(params) {
         this.addValuesInCellDropdownsJIB();
         return true;
    }


    addValuesInCellDropdownsJIB(): void {
        this.agGridServiceEntities.updateOptionCellEditorValues(
           this.JIBDropdownOptions,
           'jointInterestBilling', 
           'JIB_NM',
        );
    }

    @action
    isEditableSettingNameLE(params) {
         this.addValuesInCellDropdownsLE();
         return true;
    }


    addValuesInCellDropdownsLE(): void {
        this.agGridServiceEntities.updateOptionCellEditorValues(
           this.legalEntityDropdownOptions,
           'legalEntity', 
           'LE_NM',
        );
    }

    @action
    isEditableSettingNameLOB(params) {
         this.addValuesInCellDropdownsLOB();
         return true;
    }


    addValuesInCellDropdownsLOB(): void {
        this.agGridServiceEntities.updateOptionCellEditorValues(
           this.lineOfBusinessDropdownOptions,
           'lineOfBusiness', 
           'LOB_NM',
        );
    }


    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})   
    async savePipeline(): Promise<void> {
        let errorFlag = false;
        if(!this.formData.plname){
            this.uiService.toastService.error('Base System is Required');
            errorFlag=true;
        }
        if(!this.formData.effectiveDate){
            this.uiService.toastService.error('Effective Date is Required');
            errorFlag=true;
        }
        // if(!this.formData.endDate){
        //     this.uiService.toastService.error('End Date is Required');
        //     errorFlag=true;
        // }
        if(errorFlag){
            return;
        }
        const requestBody: any =  
            {
                pipelineId: this.pipelineId, 
                plName: this.formData.plname,
                operatedInd: this.formData.operatedInd === true? 'Y': 'N',
                qbInvoiceFreq: this.qbInvoiceFrequencyDropdownOptions.find(x=>x.valueText===this.formData.qbInvoiceFreq)?.valueCode,
                qbRateBasis: this.qbRateBasisDropdownOptions.find(x=>x.valueText===this.formData.qbRateBasis)?.valueCode,
                gravityBankInd: this.formData.gravityBankInd === true? 'Y': 'N',
                sulphurBankInd: this.formData.sulphurBankInd === true? 'Y': 'N',
                lossGain: this.lossGainDropdownOptions.find(x=>x.valueText===this.formData.lossGain)?.valueCode,
                plaBasis: this.plaBasisDropdownOptions.find(x=>x.valueText===this.formData.plaBasis)?.valueCode,
                allocationBasis: this.allocationBasisDropdownOptions.find(x=>x.valueText===this.formData.allocationBasis)?.valueCode,
                effectiveDate: moment.utc(this.formData.effectiveDate).format('YYYY-MM-DDTHH:mm:ss'), 
                endDate: this.formData.endDate && moment.utc(this.formData.endDate).format('YYYY-MM-DDTHH:mm:ss')||null, 
                createUser: this.createdUser
            };
       if(requestBody.length !== 0) {
           const response: any = await this.pipelineMaintenanceDataService.updatePipeline(requestBody);
           let error = '';
           if(response){
               for (const key in response) {                       
                   if (key==='pipelineID') {
                       this.uiService.toastService.success('Pipeline Details updated successfully!')
                       this.pipelineCreated = true;
                       this.saveChangesMade = true;
                       this.pipelineId = response[key];
                       this.getFormData(response[key]);
                   } else if (key==='related') {
                        this.relatedAreasData = response[key];
                    } else {
                        if (key==='value') {
                            error = response[key]; 
                        }
                       if (key==='statusCode' && response[key]===400) {
                           this.uiService.toastService.error(error);
                       }
                   }
               }
           } 
       }        
    }
    
    hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async saveOwnership(): Promise<void> {
       let errorFlag = false; 
       const notEndDatedPriorRecord = this.OwnershipData.filter(x=>(x.endDate==''||x.endDate==null||x.endDate==undefined)&&this.OwnershipData.filter(y=>x.owner===y.owner&&new Date(x.effectiveDate)<new Date(y.effectiveDate)).length>0).length>0;
       if(notEndDatedPriorRecord){            
            this.uiService.toastService.error('Owners must be end dated if occuring again!');
            errorFlag = true;
       }
       const group = _.groupBy(this.OwnershipData, 'effectiveDate') as any;
       Object.keys(group).forEach(key => {
        let sum = 0; const ownersList : any [] = [];
        group[key].forEach((owners, index)=>{
            sum+=Number(owners.ownership)
            ownersList[index] = owners?.owner
        })
        if(this.OwnershipData.some(x=>x.owner===''||x.owner===undefined)){
            this.uiService.toastService.error('Owner cannot be blank!');
            errorFlag = true;
         }
        if(this.OwnershipData.some(x=>x.effectiveDate===''||x.effectiveDate===undefined)){
            this.uiService.toastService.error('Effective Date cannot be blank!');
            errorFlag = true;
        }
        if(sum!==1){
            this.uiService.toastService.error('Ownership should be 100% !');
            errorFlag = true;
        }
        if(this.hasDuplicates(ownersList)){
            this.uiService.toastService.error('Owners should be unique for a grouping!');
            errorFlag = true;
        }
        });
        if(errorFlag){
            return;
        }
        const requestBody = this.OwnershipData.map(owners=>
            {return {
                'pipelineID': this.pipelineId, 
                'pipelineOwnerID': owners.pipelineOwnerID ? owners.pipelineOwnerID : 0,
                'createDate': owners.createDate ?  owners.createDate : moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': owners.createUser ? owners.createUser : this.createdUser, 
                'ownerID': this.ownersDropdownOptions.find(x=>x.customerName===owners.owner)?.customerID, 
                'ownership': Number(owners.ownership), 
                'ownerEffectiveDate': moment.utc(owners.effectiveDate).format('YYYY-MM-DDTHH:mm:ss'), 
                'ownerEndDate': owners.endDate && moment.utc(owners.endDate).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'transfertoWarehouseIndicator': owners.transfer, 
                'distributeLGTo': this.distributeLGToDropdownOptions.find(x=>x.valueText===owners.distributeLG)?.valueCode 
            }});
       if(requestBody.length !== 0) {
           const response: any = await this.pipelineMaintenanceDataService.updatePipelineOwners(requestBody, this.pipelineId);
           if(response){
                    let error ='';
                    for (const key in response) {                       
                        if (key==='pipelineID') {
                            this.uiService.toastService.success('Pipeline Owners Updated Successfully!')
                            this.ownersCreated = true;
                            this.saveChangesMade = true;
                            this.ownershipData = response.owners ? response.owners.map((owner, index)=>{return {
                                'pipelineID': this.pipelineId,
                                'pipelineOwnerID': owner.pipelineOwnerID,
                                'createDate': owner.createDate, 
                                'createUser': owner.createUser, 
                                'owner': this.ownersDropdownOptions.find(x=>x.customerID===owner.ownerID)?.customerName, 
                                'ownership': owner.ownership, 
                                'effectiveDate': new Date(owner.ownerEffectiveDate).setHours(0,0,0,0), 
                                'endDate': owner.ownerEndDate==='9999-12-31T00:00:00'||owner.ownerEndDate==='0001-01-01T00:00:00'||owner.ownerEndDate===null? '' : new Date(owner.ownerEndDate), 
                                'transfer': owner.transfertoWarehouseIndicator,
                                'distributeLG': this.distributeLGToDropdownOptions.find(x=>x.valueCode===owner.distributeLGTo)?.valueText,
                                'rowId': index,
                                'existing': true
                            }}) : this.OwnershipData;
                        }else {
                            if (key==='value') {
                                error = response[key]; 
                            }
                            if (key==='statusCode' && response[key]===400) {
                                this.uiService.toastService.error(error);
                            }
                        }
                }
            } 
       }        
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async savePipelineEntities(): Promise<void> {
        let errorFlag = false;
        if(this.EntitiesData.some(x=>x.startCycle===''||x.startCycle===undefined)){
            this.uiService.toastService.error('Start cycle cannot be blank!');
            errorFlag = true;
         }if(errorFlag){
            return;
        }
        const requestBody = this.EntitiesData.map(entity=>
            {return {
                'pipelineID': this.pipelineId, 
                'legalEntityID': entity.legalEntityID ? entity.legalEntityID : 0,
                'createDate': entity.createDate? entity.createDate : moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'), 
                'createUser': entity.createUser? entity.createUser :this.createdUser,  
                'startCycle': entity.startCycle && moment.utc(entity.startCycle).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'endCycle': entity.endCycle && moment.utc(entity.endCycle).format('YYYY-MM-DDTHH:mm:ss') || null, 
                'stateCode': entity.stateCode, 
                'glLocation': entity.glLocation, 
                'legalEntity': this.legalEntityDropdownOptions.find(x=>x.LE_NM === entity.legalEntity)?.LE_CD, 
                'lineOfBusiness': this.lineOfBusinessDropdownOptions.find(x=>x.LOB_NM === entity.lineOfBusiness)?.LOB_CD, 
                'jointIntrestBilling': this.JIBDropdownOptions.find(x=>x.JIB_NM === entity.jointInterestBilling)?.JIB_CD,
                'legalEntityName': entity.legalEntity,
                'lineOfBusinessName':entity.lineOfBusiness,
                'jointIntrestBillingName':entity.jointInterestBilling
            }});
       if(requestBody.length !== 0) {
            for(const item of requestBody){
                const requestBodyStateGLCode={
                    'http_action':'Validate',
                    'http_flex_value':(item.stateCode?String(item.stateCode):'0')+(item.glLocation?String(item.glLocation):'0')
                }
                const responseValidateStateGLCode: any = await this.pipelineMaintenanceDataService.validateStateGLCode(requestBodyStateGLCode);
                if(String(responseValidateStateGLCode.cts_response[0]?.ebsdata)==='0'){
                    this.uiService.toastService.error('Invalid State/GL Location Combination!');
                    errorFlag = true;
                }
            }
            if(errorFlag){
                return;
            }
           const response: any = await this.pipelineMaintenanceDataService.updatePipelineEntities(requestBody, this.pipelineId);
           if(response){
                let error ='';
                for (const key in response) {                       
                    if (key==='pipelineID') {
                        this.saveChangesMade = true;
                        this.uiService.toastService.success('Pipeline Entity Updated Successfully!')
                        this.entitiesData=response.legalEntities && response.legalEntities.map((entity, index)=>{return {
                            'pipelineID': this.pipelineId, 
                            'legalEntityID': entity.legalEntityID, 
                            'createDate': entity.createDate, 
                            'createUser': entity.createUser,
                            'updateDate': entity.updateDate, 
                            'updateUser': entity.updateUser,
                            'startCycle': entity.startCycle==='9999-12-31T00:00:00'||entity.startCycle==='0001-01-01T00:00:00'||entity.startCycle===null? '' :new Date(entity.startCycle), 
                            'endCycle': entity.endCycle==='9999-12-31T00:00:00'||entity.endCycle==='0001-01-01T00:00:00'||entity.endCycle===null? '' :new Date(entity.endCycle), 
                            'stateCode': entity.stateCode,  
                            'glLocation': entity.glLocation, 
                            'legalEntity': this.legalEntityDropdownOptions.find(x=>String(x.LE_CD) === String(entity.legalEntity))?.LE_NM, 
                            'lineOfBusiness': this.lineOfBusinessDropdownOptions.find(x=>String(x.LOB_CD) === String(entity.lineOfBusiness))?.LOB_NM, 
                            'jointInterestBilling': this.JIBDropdownOptions.find(x=>String(x.JIB_CD) === String(entity.jointIntrestBilling))?.JIB_NM,
                            'rowIdEntities': index,
                            'existing': true
                        }}) || this.entitiesData
                    }else {
                        if (key==='value') {
                            error = response[key]; 
                        }
                        if (key==='statusCode' && response[key]===400) {
                            this.uiService.toastService.error(error);
                        }
                    }
               }
           } 
       }        
    }

    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async deletePipeline(): Promise<void> {
        const errorFlag = false;
        if(errorFlag){
            return;
        }
        const response: any = await this.pipelineMaintenanceDataService.deletePipeline({}, this.pipelineId, this.createdUser);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='plName') {
                    this.uiService.toastService.success('Pipeline deleted successfully with ID: ' + response[key])
                    history.push('/PipelineMaintenance');
                }else {
                    if (key==='value') {
                        error = response[key]; 
                    }
                    if (key==='statusCode' && response[key]===400) {
                        this.uiService.toastService.error(error);
                    }
                }
           }
       }       
    }

    @action
    setOwnershipData(value, rowId, key){
        this.ownershipData=this.OwnershipData;
        this.ownershipData[rowId][key] = value;
        this.agGridServiceOwners.sortGrid('effectiveDate');
    }
    
    @action
    setEntitiesData(value, rowId, key){
        this.entitiesData=this.EntitiesData;
        this.entitiesData[rowId][key] = value;
    }
    
    @action
    setOwnershipGridData(value){
        this.ownershipData=value
    }

    @action
    setEntitiesGridData(value){
        this.entitiesData=value
    }

    @computed
    get OwnershipData() {
        return toJS(this.ownershipData);
    }

    @computed
    get EntitiesData() {
        return toJS(this.entitiesData);
    }

    
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async pushToVmacs(env, store): Promise<void> {
        // const action = data.action === 'U' ? 'Update' : 'Insert';
        const requestBody: any =  { 
            'http_action' : '',
            'http_record_id': this.pipelineId,
            'http_cts_tablename': PipelineMaintenanceDBTableName.TableName,
            'http_cts_mappingtablename' : PipelineMaintenanceDBTableName.MappingTableName,
            'http_userid': this.createdUser,
            'http_environment': env.toUpperCase()  
        };
        if(requestBody.length !== 0) {
            const response = await this.pipelineMaintenanceDataService.pushToVmacs(requestBody);
            if(response) {
                this.uiService.toastService.success('Synced Successfully!');
                if(env.toUpperCase()==='TEST'){
                    this.syncedToTest = true;
                    this.saveChangesMade = false;
                    store.setSyncStatusForEditing('Synced to TEST');
                } else {
                    this.syncedToProd = true;
                    this.saveChangesMade = false;
                }
            }
        }
    }
}
