import * as React from 'react';
import { RouteProps, RouteComponentProps, withRouter } from 'react-router';
import { AccountStore } from '../../features/account/stores/account-store';
import ErrorBoundary from './error-boundary/error-boundary';
import { dataStore } from 'common/stores';
export interface ProtectedRouteProps extends RouteProps {
    accountStore: AccountStore;
    component: React.ComponentClass<any>;
    roles: string[];
}

export class ProtectedRouteComponent extends React.Component<ProtectedRouteProps & RouteComponentProps> {

    componentDidMount(): void {
        const { accountStore } = this.props;
        const roleData = accountStore.getRoleData();
        const userRoleNames = (roleData.map(x => x.roleName)).filter((x , index, arr) => arr.indexOf(x) === index);
        accountStore.setUserRole(userRoleNames);
        accountStore.setRoleAccessData(roleData);
    }

    public render(): React.ReactNode {
        const { isLoggedIn } = this.props.accountStore;
        const { component: Component, roles, location, accountStore, history } = this.props;
        const isAccessAllowed = accountStore.isLoggedInUserHasRoleToAccessAView(roles);
        if (!isLoggedIn) {
            const { pathname, search } = location;
            const path = {
                pathname: '/login',
                state: { from: { pathname, search } }
            };
            history.push(path);
            return null;
        } else {
            const { pathname, search } = location;
            if(pathname === '/') {
                const path = {
                    pathname: accountStore.defaultUserLocation === 'CAN' ? '/PMCDashboard' : '/Dashboard',
                    state: { from: { pathname, search } }
                };
                history.push(path);
                return null;
            }
        }
        // if(role && isLoggedIn && userInfo &&  userInfo.isPSA && !userInfo.isPTS){
        //     window.location.href = Config.psaWebUrl;
        // }
        if (!isAccessAllowed) {
            return null;
        }
        return (
            <ErrorBoundary>
                <Component  {...this.props} />
            </ErrorBoundary>
        );
    }
}

const ProtectedRoute = withRouter(ProtectedRouteComponent);
export default ProtectedRoute;
