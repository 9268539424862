import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
// import './add-pipeline.scss';
import {
    CellEditingStartedEvent,
    CellEditingStoppedEvent,
    ColDef,
    GridOptions
} from 'ag-grid-community';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker'
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Portal } from 'react-overlays';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConnectionPointDetailsDataStore } from '../stores/connection-point-details-store';
import { ReactSelect } from 'common/components/select/select';
import { ThreeSixty } from '@mui/icons-material';
import { connectionPointDetailsDataStore } from '../stores';
import { SyncStatus } from 'common/constants/enum';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';

/* eslint-disable */
interface ConnectionPointDetailsContainerProps {
    connectionPointDetailsDataStore: ConnectionPointDetailsDataStore;
}

interface ConnectionPointDetailsContainerState {
    search: string;
    addRowClick: boolean;
    rowId: number;
    rowIdEntities: number;
    lastAddedRemarksID: number;
    lastAddedRelationshipID: number;
}

interface CalendarContainerProps {
    children : any
}

const onChangeDate = (params, value, key) => {  
    const effTransCycle = moment(value).format('MM/DD/yyyy');
    console.log(moment(value).format('MM/DD/yyyy'));
    connectionPointDetailsDataStore.setValueInChangeMapRelationshipGrid(params.data.pipelineID, key, params.data.effTransCycle, effTransCycle);
    params.data.effTransCycle =  moment(value).format('MM/DD/yyyy');
    connectionPointDetailsDataStore.updateRowRelationship(params.data);
 } ;
const CalendarContainer: React.FC<CalendarContainerProps> = ({ children }) => { const el = document.getElementById('calendar-portal'); return <Portal container={el}>{children}</Portal>; };


@inject('connectionPointDetailsDataStore')
@observer
export class AddConnectionPointDetailsContainer extends Component<ConnectionPointDetailsContainerProps, ConnectionPointDetailsContainerState> {

    constructor(props: ConnectionPointDetailsContainerProps | Readonly<ConnectionPointDetailsContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            rowId: 0,
            rowIdEntities: 0,
            lastAddedRemarksID: 0,
            lastAddedRelationshipID: 0
        };
        dataStore.setModuleName('AddConnectionPointDetails')
    }

    componentDidMount() {
          const {connectionPointDetailsDataStore} = this.props;
          if(!dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.ConnectionPointMaintenance)){
            history.push('/ConnectionPointMaintenance');
            return;
        }
          connectionPointDetailsDataStore.init();
    }

    componentWillUnmount() {
        const {connectionPointDetailsDataStore} = this.props;
        connectionPointDetailsDataStore.reset();
    }

    
    remarkColDef() { 
        const remarkColDef: ColDef[] = [
        { 
            headerName: 'User Name', 
            field: 'createUser', 
            colId: 'createUser', 
            filter: true, 
            sortable: true, 
            editable: false,
            width: 200,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['createUser'] === null) {
                    return '';
                } else {
                    return params.data['createUser'];
                }
            }
        },
        { 
            headerName: 'Date',
            field: 'createDate',
            colId: 'createDate',  
            filter: true, 
            sortable: true,
            editable: false,
            width: 200,
            cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
            valueGetter: params => {
                if (params.data['createDate'] === null) {
                    return '';
                } else {
                    return momenttimezone
                    .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                    .format(AppConstant.DATE_TIME_FORMAT);
                }
            } 
        },
        { 
            headerName: 'Remarks', 
            field: 'remarks',
            colId: 'remarks',   
            filter: true, 
            sortable: true,
            editable: !connectionPointDetailsDataStore.isSaveDisabled(),
            width: 300,
            cellClass: (params) => {return !connectionPointDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
            valueGetter: params => {
                if (params.data['remarks'] === null) {
                    return '';
                } else {
                    return params.data['remarks'];
                }
            },
            flex: 1
        },
       
       
    ];
    return remarkColDef
    }

    relationshipColDef() { 
        const relationshipColDef: ColDef[] = [
            {   
                headerName: 'Select',
                field: 'Select', 
                colId:'Select',
                headerComponent: (params) => {
                return (
                    <input
                        type="checkbox"
                        onChange={(e) => this.props.connectionPointDetailsDataStore.headerCheckBoxSelection(e)}
                        checked={!this.props.connectionPointDetailsDataStore.checkIfHeaderCheckboxShouldbeChecked(params)}
                        disabled={false}
                    />
                );
            },
                cellRenderer: (params) => {
                    return (
                        <input
                            type="checkbox"
                            onChange={(e) => this.props.connectionPointDetailsDataStore.linkedCPCheckboxHandler(params, e)}
                            checked={params.data.isSelected}
                            disabled={false}
                        />
                    );
                }, 
                cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                maxWidth: 45, 
                flex: 1,
                suppressSizeToFit: true,
                cellStyle: () => {return {padding: '10px'}}
            },
            { 
                headerName: 'Pipeline', 
                field: 'plName',
                colId: 'plName',
                filter: true, 
                sortable: true,
                editable: !connectionPointDetailsDataStore.isSaveDisabled(),
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                cellEditor: PredictiveSelectCellEditor, 
                minWidth: 110, 
                cellEditorParams: {
                    dropDownValues: this.props.connectionPointDetailsDataStore.baseSystemData.map((x) => x.plName)
                },
                cellClass: (params) =>{return !connectionPointDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},        
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['plName'] === null) {
                        return '';
                    } else {
                        return params.data['plName'];
                    }
                },
                singleClickEdit: true,
            },
            { 
                headerName: 'Location', 
                field: 'locnName',
                colId: 'locnName',
                filter: true, 
                sortable: true,
                editable: !connectionPointDetailsDataStore.isSaveDisabled(),
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                cellEditor: PredictiveSelectCellEditor, 
                minWidth: 110, 
                cellEditorParams: (params) => {return {
                    dropDownValues:  params.data['plName']  &&  params.data['plName'] !== '' ? this.props.connectionPointDetailsDataStore.locationData.filter((i) => i.plName === params.data['plName']).map((x) => x.locnName) :  this.props.connectionPointDetailsDataStore.locationData.map((x) => x.locnName),
                }},
                cellClass: (params) => {return !connectionPointDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['locnName'] === null) {
                        return '';
                    } else {
                        return params.data['locnName'];
                    }
                },
                singleClickEdit: true,
            },
            { 
                headerName: 'CP Name', 
                field: 'cpName',
                colId: 'cpName',
                filter: true, 
                sortable: true,
                editable: !connectionPointDetailsDataStore.isSaveDisabled(),
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                cellEditor: PredictiveSelectCellEditor, 
                minWidth: 110, 
                cellEditorParams: (params) => {return {
                    dropDownValues:  params.data['locnName']  &&  params.data['locnName'] !== '' ? this.props.connectionPointDetailsDataStore.connectionPointGridData.filter((item) => item.locnName === params.data.locnName).map((x) => x.cpName) : [],
                }},
                cellClass: (params) => {return !connectionPointDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['cpName'] === null) {
                        return '';
                    } else {
                        return params.data['cpName'];
                    }
                },
                singleClickEdit: true,
            },
            { 
                headerName: 'Relationship Type', 
                field: 'relatedCPType',
                colId: 'relatedCPType',
                filter: true, 
                sortable: true,
                editable: !connectionPointDetailsDataStore.isSaveDisabled(),
                singleClickEdit: true,
                // cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
                cellEditor: PredictiveSelectCellEditor, 
                cellEditorParams: {
                    dropDownValues: this.props.connectionPointDetailsDataStore.cpRelTypesDropdownOption.map((x) => x.valueText)
                },
                minWidth: 110, 
                cellClass: (params) => {return !connectionPointDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},        
                // cellStyle: (params) => {
                //     if (params.data.settingID <= 0 && !params.data.settingName) {
                //         return {border: '1px solid #0091ea'};
                //     }
                //     return null;
                // },
                valueGetter: params => {
                    if (params.data['relatedCPType'] === null) {
                        return '';
                    } else {
                        return params.data['relatedCPType'];
                    }
                }
            },
            { 
                headerName: 'Effective Trans Cycle',
                field: 'effTransCycle', 
                colId: 'effTransCycle',
                filter: true, 
                sortable: true,
                editable: !connectionPointDetailsDataStore.isSaveDisabled(),
                suppressSizeToFit: true,
                cellRenderer: (params) => { 
                    const effTransCycle = params.data['effTransCycle'] ?  moment(moment(params.data['effTransCycle'])
                    .format('MM/DD/yyyy')).toDate() : null
                    return (params.data && 
                        (<DatePicker 
                            popperClassName='ag-custom-component-popup' 
                            selected={effTransCycle}
                            dateFormat="MM/dd/yyyy" 
                            onChange={(value)=>{onChangeDate(params, value, 'effTransCycle')}} 
                            popperContainer={CalendarContainer} 
                            popperPlacement='top-start' 
                            className={'serviceDateInputStyle-editable'}
                            disabled={connectionPointDetailsDataStore.isSaveDisabled()}/>
                        )
                    ) || params.value 
                },
                valueGetter: params => {
                    if (params.data['effTransCycle'] === null) {
                        return '';
                    } else {
                        return moment(params.data['effTransCycle']).format(AppConstant.DATE_FORMAT);
                    }
                }, 
                cellClass: (params) => {return !connectionPointDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},     
                minWidth: 120,    
            },
            { 
                headerName: 'Carry Split', 
                field: 'carrySplit', 
                colId: 'carrySplit',
                headerClass: 'text-center',
                filter: true, 
                sortable: true,
                tooltipField: 'carrySplit', 
                cellRenderer: (params) => {
                    return (
                        <input
                            className='' 
                            type='checkbox' 
                            checked={params.data['carrySplit']==='Y'}
                            onChange={(value) => this.handleCarrySplitCheckBoxChange(params, value)}
                            disabled={connectionPointDetailsDataStore.isSaveDisabled() }
                        />);
                }, 
                minWidth: 120, 
                cellClass: (params) => {return !connectionPointDetailsDataStore.isSaveDisabled() ? 'cell-editable' : ''},        
                cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
            },
       
       
    ];
    return relationshipColDef
    }


    onChangeBaseSystem(e) {
        const { connectionPointDetailsDataStore } = this.props;
       if(e.value && e.value !== 'Select') {
        connectionPointDetailsDataStore.selectedBaseSystem = e.value;
        connectionPointDetailsDataStore.selectedRelatedBaseSystem = e.value;
        const baseDetails = connectionPointDetailsDataStore.baseSystemData.find((item) => item.plName === e.value)
        const pipelineID = baseDetails.pipelineID ? baseDetails.pipelineID : null;
        const a = pipelineID ? connectionPointDetailsDataStore.locationData.filter((x) => x.pipelineID === pipelineID) : connectionPointDetailsDataStore.locationData;
        const b = a.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        connectionPointDetailsDataStore.locationDropdownOption = b;
        connectionPointDetailsDataStore.relatedLocationDropdownOption = b;
       } else {
        const b = connectionPointDetailsDataStore.locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        connectionPointDetailsDataStore.locationDropdownOption = b;
        connectionPointDetailsDataStore.selectedBaseSystem = null;
       }
       connectionPointDetailsDataStore.selectedLocation = undefined;
       connectionPointDetailsDataStore.isFormUpdated = true;
    }

    onChangeRelatedBaseSystem(e) {
        const { connectionPointDetailsDataStore } = this.props;
       if(e.value && e.value !== 'Select') {
        connectionPointDetailsDataStore.selectedRelatedBaseSystem = e.value;
        const baseDetails = connectionPointDetailsDataStore.baseSystemData.find((item) => item.plName === e.value)
        const pipelineID = baseDetails.pipelineID ? baseDetails.pipelineID : null;
        const a = pipelineID ? connectionPointDetailsDataStore.locationData.filter((x) => x.pipelineID === pipelineID) : connectionPointDetailsDataStore.locationData;
        const b = a.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        connectionPointDetailsDataStore.relatedLocationDropdownOption = b;
       } else {
        const b = connectionPointDetailsDataStore.locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
        connectionPointDetailsDataStore.relatedLocationDropdownOption = b;
        connectionPointDetailsDataStore.selectedRelatedBaseSystem = null;
       }
       connectionPointDetailsDataStore.selectedRelatedLocation = undefined;
       connectionPointDetailsDataStore.connectionPointGridData = [];
       connectionPointDetailsDataStore.isFormUpdated = true;
    }

    handleCarrySplitCheckBoxChange(params, value) {
        const row = params.data.pipelineID;
        const initValue = params.data['carrySplit'];
        this.props.connectionPointDetailsDataStore.setValueInChangeMapRelationshipGrid(row, 'carrySplit', initValue, value);
        if(initValue === 'Y') {
            params.data['carrySplit'] = 'N'
        } else {
            params.data['carrySplit'] = 'Y'
        }
        this.props.connectionPointDetailsDataStore.updateRowRelationship(params.data)
    }

    onChangeLocationName(e, isRelationSegment) {
        const { connectionPointDetailsDataStore } = this.props;
       if (e.value && e.value !== 'Select' ) {
        if(!isRelationSegment) {
            connectionPointDetailsDataStore.selectedLocation = e.value ;
            if(connectionPointDetailsDataStore.selectedBaseSystem === connectionPointDetailsDataStore.selectedRelatedBaseSystem)
            connectionPointDetailsDataStore.selectedRelatedLocation = e.value ;
            connectionPointDetailsDataStore.getCPTariff(e.value)
        } else {
            connectionPointDetailsDataStore.selectedRelatedLocation = e.value ;
            connectionPointDetailsDataStore.getCPTariff(e.value)
        }
       } else {
            if(!isRelationSegment) {
                connectionPointDetailsDataStore.selectedLocation = null;
                if(connectionPointDetailsDataStore.selectedBaseSystem === connectionPointDetailsDataStore.selectedRelatedBaseSystem)
                connectionPointDetailsDataStore.selectedRelatedLocation = null;
                connectionPointDetailsDataStore.connectionPointGridData = [];
            } else {
                connectionPointDetailsDataStore.selectedRelatedLocation = null;
                connectionPointDetailsDataStore.connectionPointGridData = [];
            }
       }
       connectionPointDetailsDataStore.isFormUpdated = true;
    }

    onChangeCPType(e) {
        const { connectionPointDetailsDataStore } = this.props;
        if (e.value && e.value !== 'Select' ) {
            connectionPointDetailsDataStore.selectedCPType = e.value
            if(e.value !== 'Connecting Carrier (rec/del)') {
                connectionPointDetailsDataStore.selectedCCType = undefined;
                connectionPointDetailsDataStore.selectedConnCarrierOperator = undefined;
            }
            if(e.value === 'Non-Custody Transfer (rec/del)') {
                connectionPointDetailsDataStore.selectedfercType = undefined;
            }

        } else {
            connectionPointDetailsDataStore.selectedCPType = null;
        }
        connectionPointDetailsDataStore.isFormUpdated = true;
    }

    onSaveCP() {
        this.props.connectionPointDetailsDataStore.saveConnectionPointData();
    }

    onDeleteClick = async () => {
        const { connectionPointDetailsDataStore } = this.props;
        const updatedRowIDs: any = connectionPointDetailsDataStore.remarksGridData.filter(a => connectionPointDetailsDataStore.getUpdatedRowIDs().includes(a.cpCommentID.toString()));
        if(updatedRowIDs.length > 0 || connectionPointDetailsDataStore.isFormUpdated) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => { connectionPointDetailsDataStore.deleteCP()},
            'Inactive',
            'All the unsaved changes will be lost. Are you sure you want to deactivate the selected connection point?'
        );
        } else {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
          () => connectionPointDetailsDataStore.deleteCP(),
          'Inactive',
          'Are you sure you want to deactivate the selected connection point?'
        );
        }
      }


    render(): React.ReactNode {
        const { connectionPointDetailsDataStore } = this.props;
        return (
        <div className='connectionPointMaintenanceView'>
            <Container fluid>
            <div className='boxes'>
                  <div className='screenHeader'>
                      <h2 className='screen_title'>Connection Point Details</h2>
                    </div>
                  <div className='selectContainer'>
                    <Row className="baseSystem">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Base System*</label>
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedBaseSystem ? {label: connectionPointDetailsDataStore.selectedBaseSystem, value: connectionPointDetailsDataStore.selectedBaseSystem} : {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.baseSystemDropdownOption]}
                                onChange={(e: any) => { this.onChangeBaseSystem(e)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled = {connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Location</label>
                           
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedLocation ? {label: connectionPointDetailsDataStore.selectedLocation, value: connectionPointDetailsDataStore.selectedLocation} : {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.locationDropdownOption]}
                                onChange={(e: any) => { this.onChangeLocationName(e, false) }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                        
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Connection Point Name</label>
                            <input
                                type="text"
                                className="search_text_field"
                                onChange = {(e) => {connectionPointDetailsDataStore.selectedCPName = e.target.value; connectionPointDetailsDataStore.isFormUpdated = true;}}
                                value={connectionPointDetailsDataStore.selectedCPName}
                                readOnly={connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                        </Col>
                      
                    </Row>

                    <Row className="baseSystem">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Connection Point Code</label>
                            
                            <input
                            type="text"
                            className="search_text_field"
                            onChange = {(e) => {connectionPointDetailsDataStore.selectedCPCode = e.target.value; connectionPointDetailsDataStore.isFormUpdated = true;}}
                            value={connectionPointDetailsDataStore.selectedCPCode}
                            readOnly={connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                           
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">CP Type</label>
                            
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedCPType?  {label: connectionPointDetailsDataStore.selectedCPType, value: connectionPointDetailsDataStore.selectedCPType} : {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.cpTypeDropdownOption]}
                                onChange={(e: any) => { this.onChangeCPType(e) }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                            
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">CC Type</label>
                           
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedCCType ? {label: connectionPointDetailsDataStore.selectedCCType, value: connectionPointDetailsDataStore.selectedCCType} : {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.ccTypeDropdownOption]}
                                onChange={(e: any) => { e.value && e.value !== 'Select' ?  connectionPointDetailsDataStore.selectedCCType = e.value : connectionPointDetailsDataStore.selectedCCType = null; connectionPointDetailsDataStore.isFormUpdated = true; }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.selectedCPType !== 'Connecting Carrier (rec/del)' ||  connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                            
                        </Col>
                       
                    </Row>

                    <Row className="baseSystem">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Activity Type</label>
                            
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedActivityType ? {label: connectionPointDetailsDataStore.selectedActivityType, value: connectionPointDetailsDataStore.selectedActivityType} : {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.activityTypeDropdownOption]}
                                onChange={(e: any) => { e.value && e.value !== 'Select' ?  connectionPointDetailsDataStore.selectedActivityType = e.value : connectionPointDetailsDataStore.selectedActivityType = null; connectionPointDetailsDataStore.isFormUpdated = true; }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                           
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">FERC Report Type</label>
                            
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedfercType ? {label: connectionPointDetailsDataStore.selectedfercType, value: connectionPointDetailsDataStore.selectedfercType} :  {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.fercTypeDropdownOption]}
                                onChange={(e: any) => { e.value && e.value !== 'Select' ?  connectionPointDetailsDataStore.selectedfercType = e.value : connectionPointDetailsDataStore.selectedfercType = null; connectionPointDetailsDataStore.isFormUpdated = true; }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.selectedCPType === 'Non-Custody Transfer (rec/del)' || connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                            
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">CP Status</label>
                            
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedCPStatus ? {label: connectionPointDetailsDataStore.selectedCPStatus, value: connectionPointDetailsDataStore.selectedCPStatus} : {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.ctsStatusDropdownOption]}
                                onChange={(e: any) => { e.value && e.value !== 'Select' ?  connectionPointDetailsDataStore.selectedCPStatus = e.value : connectionPointDetailsDataStore.selectedCPStatus = null; connectionPointDetailsDataStore.isFormUpdated = true; }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                            
                        </Col>
                      
                    </Row>

                    <Row className="baseSystem">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">State</label>
                           
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedState ? {label: connectionPointDetailsDataStore.selectedState, value: connectionPointDetailsDataStore.selectedState} :  {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.tariffStatesDropdownOption]}
                                onChange={(e: any) => { e.value && e.value !== 'Select' ?  connectionPointDetailsDataStore.selectedState = e.value : connectionPointDetailsDataStore.selectedState= null; connectionPointDetailsDataStore.isFormUpdated = true; }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                           
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Conn. Carrier Operator</label>
                           
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedConnCarrierOperator ? {label: connectionPointDetailsDataStore.selectedConnCarrierOperator, value: connectionPointDetailsDataStore.selectedConnCarrierOperator} :  {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.connCarrierOperatorDropdownOption]}
                                onChange={(e: any) => { e.value && e.value !== 'Select' ?  connectionPointDetailsDataStore.selectedConnCarrierOperator = e.value : connectionPointDetailsDataStore.selectedConnCarrierOperator = null; connectionPointDetailsDataStore.isFormUpdated = true; }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={connectionPointDetailsDataStore.selectedCPType !== 'Connecting Carrier (rec/del)' ||  connectionPointDetailsDataStore.isSaveDisabled()}
                            />
                           
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Service Date</label>
                                <ReactDatePicker classNames='select' onDateChange={(e) => { connectionPointDetailsDataStore.selectedServiceDate = e; connectionPointDetailsDataStore.isFormUpdated = true; } } selectedDate={connectionPointDetailsDataStore.selectedServiceDate} disabled={connectionPointDetailsDataStore.isSaveDisabled()}/>
                        </Col>
                      
                    </Row>
                    <div className='searchButtonDiv'>

                          <div className='otherHeaderButtonsDiv'>
                          <CustomButton type={CustomButtonType.Submit} onClick={() => this.onAddRowRemarksRow()} disabled={connectionPointDetailsDataStore.isSaveDisabled()}> 
                            Add a Remark
                          </CustomButton>
                          </div>
                            
                    </div>
                    <div className='locationMaintenanceBox'>
                        <div className='pipelineLinkerBox'>
                            <Row style={{margin:'20px', width: '60%', height: '40%'}}>
                                <AgGridComponent gridConfig={this.getGridConfig('Connection Point Remark')} />
                            </Row>
                            
                        </div>
                    </div>
                   
                    <div style={{'marginTop':'20px'}}>
                    <div className='screenHeader'>
                        <h2 className='screen_title'>Related Connection Point</h2>
                    </div>
              <div className='locationMaintenanceBox'>
                <div className='selectContainer'>
                  <div className='settingSelectionDiv'>
                  <Row className="baseSystem">
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Base System*</label>
                           
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedRelatedBaseSystem ? {label: connectionPointDetailsDataStore.selectedRelatedBaseSystem, value: connectionPointDetailsDataStore.selectedRelatedBaseSystem} :  {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.relatedBaseSystemDropdownOption]}
                                onChange={(e: any) => { this.onChangeRelatedBaseSystem(e)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled={true}
                            />
                           
                        </Col>
                        <Col style={{'marginTop':'10px'}}>
                            <label className="standard_label_style">Location</label>
                           
                            <ReactSelect
                                className='select'
                                values={connectionPointDetailsDataStore.selectedRelatedLocation ? {label: connectionPointDetailsDataStore.selectedRelatedLocation, value: connectionPointDetailsDataStore.selectedRelatedLocation} :  {label: 'Select', value:  'Select'}}
                                placeholder={'Select'}
                                options={[{value:'Select', label:'Select'},...connectionPointDetailsDataStore.relatedLocationDropdownOption]}
                                onChange={(e: any) => { this.onChangeLocationName(e, true) }}
                                isMulti={false}
                                isSearchable={true}
                                disabled={true}
                            />
                            
                        </Col>
                    </Row>
                    <Row>
                    <div className='searchButtonDiv'>
                    <div className='otherHeaderButtonsDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => this.onAddRelationshipRow()} disabled={connectionPointDetailsDataStore.isSaveDisabled()}>
                          Add New Relationship
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => this.onClearRelationshipRow()} disabled={connectionPointDetailsDataStore.isSaveDisabled()}>
                          Clear
                        </CustomButton>
                    </div>
                  </div>
                  </Row>
                  </div>
                  
                </div>
              </div>
              <div className='locationMaintenanceBox'>
                <div className='pipelineLinkerBox'>
                    <div className="rotatedText">
                      Linker
                    </div>
                    <Row style={{margin:'20px', width: '80%', height: '40%'}}>
                      <AgGridComponent gridConfig={this.getAddRelationShipGridConfig('RelationShip Details')}/>
                    </Row>
                    
                </div>
              </div>
              </div>
              {/* <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/> */}
                    <hr></hr>
                    <div className='searchButtonDiv'>

                        <div className='otherHeaderButtonsDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => this.onSaveCP()} disabled={connectionPointDetailsDataStore.isSaveDisabled()}> 
                            Save
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.onDeleteClick} disabled= {connectionPointDetailsDataStore.isDeleteDisabled()}>
                            Deactivate
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => this.pushToVmacs('Test')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) || this.pushButtonDisabled('Test')}>
                            Push to Test
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={() =>  this.pushToVmacs('Prod')} disabled={!dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd) || this.pushButtonDisabled('Prod')}>
                            Push to Prod
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={() => connectionPointDetailsDataStore.onCancelCPDetails()}> 
                            Close
                        </CustomButton>
                        </div>
                        
                        </div>
                    </div>
          </div>
          {this.props.connectionPointDetailsDataStore.showLoader == true &&
                <div className = 'alert-overlay'> 
                  <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                </div> 
              }
        </Container>
       </div>
    );
}

private getGridConfig(gridId): GridOptions {
    const {
        connectionPointDetailsDataStore: { RemarksGridData, agGridService }
    } = this.props;
    const { connectionPointDetailsDataStore } = this.props;
    const columnDefs = this.remarkColDef();
    const rowData = RemarksGridData;
    const onCellEditingStarted = this.onCellEditingStartedRemarks;
    const onCellEditingStopped = this.onCellEditingStoppedRemarks;
    const miscellaneousSettingsGridParams: any = {
      columnDefs,
      rowData,
      onCellEditingStarted,
      onCellEditingStopped
      };
    return agGridService.getGridConfig(miscellaneousSettingsGridParams, gridId);
  }

  onCellEditingStartedRemarks = (event: CellEditingStartedEvent): void => {
    const row = event.data.cpCommentID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
        connectionPointDetailsDataStore: { cellValueChangeMap }
    } = this.props;
    const isNotEmpty =
        row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
    if (!isNotEmpty) {
        this.props.connectionPointDetailsDataStore.setValueInChangeMap(row, col, value, value);
    }
  };

  onCellEditingStoppedRemarks = (event: CellEditingStoppedEvent): void => {
    const row = event.data.cpCommentID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
        connectionPointDetailsDataStore: { cellValueChangeMap, updateRowRemarks, getColDef}
    } = this.props;
    cellValueChangeMap[row][col]['currentValue'] = value;
    updateRowRemarks(event.data);
    this.props.connectionPointDetailsDataStore.agGridService.getNodes()?.refreshCells();
  };

  private getAddRelationShipGridConfig(gridId): GridOptions {
    const {
        connectionPointDetailsDataStore: { RelationshipGridData, agGridServiceForRelationShip }
    } = this.props;
    const { connectionPointDetailsDataStore } = this.props;
    const columnDefs = this.relationshipColDef();
    const rowData = RelationshipGridData;
    const onCellEditingStarted = this.onCellEditingStartedRelationShip;
    const onCellEditingStopped = this.onCellEditingStoppedRelationShip;
    const locationMaintenanceGridParams: any = {
      rowData,
      columnDefs,
      onCellEditingStarted,
      onCellEditingStopped,
      context: agGridServiceForRelationShip
    };
    return agGridServiceForRelationShip.getGridConfig(locationMaintenanceGridParams, gridId);
  }

  onCellEditingStartedRelationShip = async(event: CellEditingStartedEvent): Promise<void> => {
    const row = event.data.relConnectionPointID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
        connectionPointDetailsDataStore: { cellValueChangeMapForRelationshipGrid }
    } = this.props;
    if(col==='cpName'){
        await this.props.connectionPointDetailsDataStore.getCPTariff(event.data.locnName);
    }
    const isNotEmpty =
        row in cellValueChangeMapForRelationshipGrid && col in cellValueChangeMapForRelationshipGrid[row] && cellValueChangeMapForRelationshipGrid[row][col].initValue;
    if (!isNotEmpty) {
        this.props.connectionPointDetailsDataStore.setValueInChangeMapRelationshipGrid(row, col, value, value);
    }
  };

  onCellEditingStoppedRelationShip = async (event: CellEditingStoppedEvent): Promise<void> => {
    const row = event.data.relConnectionPointID;
    const col = event.colDef.field || '';
    const value = event.value;
    const {
        connectionPointDetailsDataStore: { cellValueChangeMapForRelationshipGrid, updateRowRelationship, getColDef}
    } = this.props;
    cellValueChangeMapForRelationshipGrid[row][col]['currentValue'] = value;
    if(col==='plName'){
        event.data.locnName = null;
    }
    if(col==='locnName'){
        event.data.cpName = null;
        await this.props.connectionPointDetailsDataStore.getCPTariff(value);
    }
    updateRowRelationship(event.data);
    this.props.connectionPointDetailsDataStore.agGridServiceForRelationShip.getNodes()?.refreshCells();
  };

  onAddRowRemarksRow = () => {
    const cpCommentID = this.state.lastAddedRemarksID - 1
    const rowData = [{
      createDate: moment().format(AppConstant.DATE_TIME_FORMAT),
      createUser: this.props.connectionPointDetailsDataStore.userName,
      remarks: '',
      cpCommentID: cpCommentID
    }, ...this.props.connectionPointDetailsDataStore.remarksGridData];
    this.props.connectionPointDetailsDataStore.setRemarksGridData(rowData);
    this.setState({
        lastAddedRemarksID: cpCommentID
    });
    this.props.connectionPointDetailsDataStore.scrollToFocusTheNewCell();

}

onAddRelationshipRow = () => {
    const cpCommentID = this.state.lastAddedRelationshipID - 1
    const rowData = [{
        relConnectionPointID: cpCommentID,
        conPointIDFrom: null,
        conPointIDTO: null,
        relatedCPType: null,
        carrySplit: null,
        effTransCycle: null,
        createDate: moment().format(AppConstant.API_DATE_FORMAT),
        createUser: this.props.connectionPointDetailsDataStore.userName,
        updateDate: null,
        updateUser: null,
        action: "I",
        isSelected: false
      }, ...this.props.connectionPointDetailsDataStore.relationshipGridData];
    this.props.connectionPointDetailsDataStore.setRelationshipGridData(rowData);
    this.setState({
        lastAddedRelationshipID: cpCommentID
    });
    this.props.connectionPointDetailsDataStore.addValuesInCellDropdowns();
    this.props.connectionPointDetailsDataStore.agGridServiceForRelationShip.getNodes()?.refreshCells();   
    this.props.connectionPointDetailsDataStore.scrollToFocusTheNewCellRelationship();
    this.props.connectionPointDetailsDataStore.checkIfHeaderCheckboxShouldbeChecked()
}

onClearRelationshipRow = () => {
    const filterValidRows = this.props.connectionPointDetailsDataStore.relationshipGridData.filter((item) => !item.isSelected);
    this.props.connectionPointDetailsDataStore.setRelationshipGridData(filterValidRows);
    this.props.connectionPointDetailsDataStore.addValuesInCellDropdowns();
    this.props.connectionPointDetailsDataStore.agGridServiceForRelationShip.getNodes()?.refreshCells();   
    this.props.connectionPointDetailsDataStore.checkIfHeaderCheckboxShouldbeChecked()
}


private async pushToVmacs(env) {
    const { connectionPointDetailsDataStore } = this.props;
    const requestBody: any = connectionPointDetailsDataStore.relationshipGridData.filter(a => connectionPointDetailsDataStore.getUpdatedRowIDsRelationshipGrid().includes(a.relConnectionPointID.toString()));
    const updatedRowIDs = connectionPointDetailsDataStore.remarksGridData.filter(a => connectionPointDetailsDataStore.getUpdatedRowIDs().includes(a.cpCommentID.toString()));
    if((connectionPointDetailsDataStore.selectedCPName !== ''  &&( (requestBody.length > 0 && !requestBody.some((item) => item.syncStatus === SyncStatus[2])) || (requestBody.length <= 0 && connectionPointDetailsDataStore.cpDetailsData.syncStatus && connectionPointDetailsDataStore.cpDetailsData.syncStatus !== SyncStatus[2])))) {
      if(updatedRowIDs.length > 0 || connectionPointDetailsDataStore.isFormUpdated || requestBody.length > 0) {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => connectionPointDetailsDataStore.pushSelectedRowsToVmacs(env),
          'Active',
          `All the unsaved changes will be lost. Are you sure you want to push the selected data to the ${env} Environment?`
      );
      } else {
        if(((connectionPointDetailsDataStore.cpDetailsData.syncStatus && connectionPointDetailsDataStore.cpDetailsData.syncStatus=== SyncStatus[0])) && env === 'Prod' && connectionPointDetailsDataStore.selectedCPName !== '' ) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
            () => {},
            ConfirmWithOK,
            {secondaryText: 'Connection point has not been verified in the Test Environment. Please sync with Test Environment before pushing to Production Environment.'} as ConfirmWithOKProps,
            'confirmPopup'
          );
        } else {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
              () => connectionPointDetailsDataStore.pushSelectedRowsToVmacs(env),
              'Active',
              `Are you sure you want to push the selected data to the ${env} Environment?`
          );
        }      
      }
    } else {
      const secondaryText = 'Please select only the active and unsynced connection points to perform the operation';
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: secondaryText} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  private pushButtonDisabled(env) {
    const { connectionPointDetailsDataStore } = this.props;
    if(env === 'Test') {
      return  !connectionPointDetailsDataStore.cpDetailsData || ((!connectionPointDetailsDataStore.cpDetailsData.connectionPointID || connectionPointDetailsDataStore.cpDetailsData.connectionPointID === 0) || (connectionPointDetailsDataStore.cpDetailsData.syncStatus && (connectionPointDetailsDataStore.cpDetailsData.syncStatus === SyncStatus[1] || connectionPointDetailsDataStore.cpDetailsData.syncStatus === SyncStatus[2]))); 
    }
      return  !connectionPointDetailsDataStore.cpDetailsData || ((!connectionPointDetailsDataStore.cpDetailsData.connectionPointID || connectionPointDetailsDataStore.cpDetailsData.connectionPointID === 0) || ( connectionPointDetailsDataStore.cpDetailsData.syncStatus && connectionPointDetailsDataStore.cpDetailsData.syncStatus === SyncStatus[2]));
  }

}