import { action, observable, makeAutoObservable, toJS, computed } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import { CustomerMaintenanceDataService } from '../services/customer-maintenance-data-service';
import { AccountStore } from '../../../account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import { TariffRateTypesDBTableName } from '../../../../Path';
import moment from 'moment';
import { dataStore } from 'common/stores';
import { AppConstant } from 'app_constants';
import momenttimezone from 'moment-timezone';
import { SyncStatus } from 'common/constants/enum';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { RoleBasedActionName } from 'shared/types/enum';


export class CustomerMaintenanceDataStore {
  
    @observable formData: any = {
        customerType: {label:'', value:0},
        customerName: '',
        assignedSystem: {label:'All', value:0},
        customerStatus: {label:'', value:0},
        customerCode: '',
        enomCode:''
    }

    @observable formDataAdd: any = {
        customerNameSearch: '',
        customerId:'',
        customerName: '',
        customerCode: '',
        enomCode: '',
        fein: '',
        sapAccount: '',
        no637: '',
        sapICAccount: '',
        comments: '',
        prepaymentRequiredInd: false, 
        shipperAffiliatedInd: false, 
        performanceRiskInd: false, 
        foreignEntityInd: false, 
        relatedPartyInd: false,
        updatedBy: '',
        updateDate: '',
        createDate: '',
        createUser: '',
        syncStatus: SyncStatus[0],
        salesForceID: '',
        extCustID: 0
    }

    @observable customerTypesDropdown: any [] = [];
    @observable assignedSystemsDropdown: any [] = [];
    @observable customerStatusDropdown: any [] = [];


    @observable phoneNumberTypesDropdown: any [] = [];    
    @observable creditTypesDropdown: any [] = [];
    @observable contactTypesDropdown: any [] = [];
    @observable addressTypesDropdown: any [] = [];
    @observable customerAddressTypeDropdown: any [] = [];
    @observable customerCommentTypeDropdown: any [] = [];
    @observable jurisdictionDropdown: any [] = [];
    @observable tariffStatesDropdownOption:any [] = [];

    @observable customerMaintenanceData: any [] = [];

    @observable isSearchClicked = false;
    @observable isRefreshed = false;
    
    @observable updatedRows: any [] = [];
    @observable username;
    @observable createDate;
    
    @observable showLoader = false;
    @observable addCM = false;

    @observable customerNames: any [] = [];
    @observable linkerData: any [] = [];
    @observable backupLinkerData: any [] = [];
    @observable creditsData: any [] = [];
    @observable otherData: any [] = [];
    @observable commentsData: any [] = [];
    @observable addressData: any [] = [];
    @observable addContactData: any [] = [];
    @observable disableCustomerName = true;
    @observable disablecustomerDetails = false;
    @observable unsavedChanges = false;

    agGridServiceAdd = new AgGridService();
    agGridServiceForAddAddress = new AgGridService();
    agGridServiceForAddCredit = new AgGridService();
    agGridServiceForAddOtherContacts = new AgGridService();
    agGridServiceForAddOtherInfo = new AgGridService()
    agGridServiceForAddComments = new AgGridService()
    
    constructor(
        public customerMaintenanceDataService: CustomerMaintenanceDataService,
        public uiService: UiService,
        public agGridService: AgGridService,
        public accountStore: AccountStore
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.formData = {
            customerType: {label:'', value:0},
            customerName: '',
            assignedSystem: {label:'All', value:0},
            customerStatus: {label:'', value:0},
            customerCode: '',
            enomCode:''
        }
        this.getCustomerTypesDropdown();
        this.getCustomerStatusDropdown();
        this.getBaseSystemsDropdown();
        this.username = this.accountStore.displayName;
        this.updatedRows = [];
        this.addCM = false;
        this.isSearchClicked = true;
        history.push('/CustomerMaintenance')
    }

    
    @action
    initAddEdit(): void {
        this.getCustomerTypesDropdown();
        this.getJurisdictionDropdown();
        this.getBaseSystemsDropdown();
        this.getPhoneNumberTypesDropdown();
        this.getAddressTypesDropdown();
        this.getContactTypesDropdown();
        this.getTariffStatesDropdown();
        this.getCreditTypesDropdown();
        this.getCustomerAddressTypesDropdown();
        this.getCustomerCommentTypesDropdown();
        this.username = this.accountStore.displayName;
        this.createDate = momenttimezone
        .tz(moment.utc(new Date()), AppConstant.CST_TIME_FORMATTER)
        .format(AppConstant.DATE_TIME_FORMAT);
    }

    @action
    resetAddEdit(): void {
        this.formDataAdd = {
            customerNameSearch: '',
            customerId:'',
            customerName: '',
            customerCode: '',
            enomCode: '',
            fein: '',
            sapAccount: '',
            no637: '',
            sapICAccount: '',
            comments: '',
            prepaymentRequiredInd: false, 
            shipperAffiliatedInd: false, 
            performanceRiskInd: false, 
            foreignEntityInd: false, 
            relatedPartyInd: false,
            updatedBy: '',
            updateDate: '',
            createDate: '',
            createUser: '',
            syncStatus: SyncStatus[0],
            salesForceID: '',
            extCustID: 0
        }
        this.linkerData = [];
        this.backupLinkerData = [];
        this.creditsData = [];
        this.otherData = [];
        this.commentsData = [];
        this.addressData = [];
        this.addContactData = [];
        this.customerNames = [];
        this.unsavedChanges = false;
    }

    @action
    setAddCM(){
        if(this.addCM===true){
            dataStore.setModuleName('CustomerMaintenance');
            this.formDataAdd = {
                customerNameSearch: '',
                customerId:'',
                customerName: '',
                customerCode: '',
                enomCode: '',
                fein: '',
                sapAccount: '',
                no637: '',
                sapICAccount: '',
                comments: '',
                prepaymentRequiredInd: false, 
                shipperAffiliatedInd: false, 
                performanceRiskInd: false, 
                foreignEntityInd: false, 
                relatedPartyInd: false,
                updatedBy: '',
                updateDate: '',
                createDate: '',
                createUser: '',
                syncStatus: SyncStatus[0],
                salesForceID: '',
                extCustID: 0
            }
        }
        this.addCM=!this.addCM;
        this.disableCustomerName = true;
        this.disablecustomerDetails = false;
    }
     
    @action
    changeFormData(value, key) {
        this.formData= {...this.formData, [key]: value}
    }

    
    @action
    changeFormDataAdd(value, key) {
        this.formDataAdd= {...this.formDataAdd, [key]: value};
        this.unsavedChanges = key === 'customerNameSearch' ? this.unsavedChanges : true;
        // if(key==='customerNameSearch'){
        //     this.checkCustomerName();
        // }
    }
    @Loader
    @action
    @Catch((e) => {console.log(e);errorHandler('Fetch Error')})
    async getCustomerDetails(id, section?): Promise<void> {
        const responseArray = await this.customerMaintenanceDataService.getCustomerDetails(id);
        const response: any = responseArray[0];
        this.formDataAdd = {...this.formDataAdd, 
            customerNameSearch: '',
            customerName: response.custName,
            customerId:response.customerID,
            customerCode: response.custCode,
            enomCode: response.custEnomCode,
            fein: response.fein,
            sapAccount: response.sapCompID,
            no637: response.ph637No,
            sapICAccount: response.sapCustAcctID,
            comments: response.comments,
            prepaymentRequiredInd:response.prepaymentRequiredInd==='Y'?true:false, 
            shipperAffiliatedInd: response.shipperAffiliatedInd==='Y'?true:false, 
            performanceRiskInd: response.performanceRiskInd==='Y'?true:false, 
            foreignEntityInd: response.foreignEntityInd==='Y'?true:false, 
            relatedPartyInd: response.relatedPartyInd==='Y'?true:false, 
            updatedBy: response.updateUser || response.createUser,
            updateDate: response.updateDate ? response.updateDate : response.createDate,
            createUser: response.createUser,
            createDate: response.createDate,
            syncStatus: response.syncStatus ?   response.syncStatus : SyncStatus[0],
            salesForceID: response.salesForceID || '',
            extCustID: response.extCustID || 0
        };
        if(!section || section === 'Linker') {
            this.linkerData=response.pipelines;
            this.linkerData.forEach((x,index, xarray)=>{
                    xarray[index]={...x, rowId: index, existing: true}
            });
            this.backupLinkerData = this.linkerData;
            this.disableCustomerName = response.pipelines.filter((i) => i.shipper === true).length > 0 ;
            this.disablecustomerDetails = response.pipelines.filter((i) => i.shipper === true).length > 0 ;
        }
       
        this.creditsData=response.credits;
        this.creditsData.forEach((x,index, xarray)=>{
            xarray[index]={...x, rowId: index, existing: true}
        });
        // if(!section || section === 'OtherData') {
        //     this.otherData=response.affliates;
        //     this.otherData.forEach((x,index, xarray)=>{
        //         xarray[index]={...x, rowId: index, existing: true}
        //     });
        // }
        if(!section || section === 'AddComments') {
            this.commentsData=response.addComments;
            this.commentsData.forEach((x,index, xarray)=>{
                xarray[index]={...x, rowId: index, existing: true}
            });
        }
        if(!section || section === 'Address') {
            this.addressData = response.contacts;
            this.addressData.forEach((x,index, xarray)=>{
                xarray[index]={...x, rowId: index, existing: true}
            });
        }
        if(!section || section === 'AddContact') {
            this.addContactData = response.additionalContact;
            this.addContactData.forEach((x,index, xarray)=>{
                xarray[index]={...x, rowId: index, existing: true}
            });
        }
        this.unsavedChanges =  false;
    }

    
    validateRequest = () => {
        const localValidatingErrorBody: any = [];
        let isValidRequest = true;

        if(!this.formDataAdd.customerName || this.formDataAdd.customerName === '') { 
            const message = 'Please Enter Valid Customer Name'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        }
      
        // if(!this.formDataAdd.customerCode || this.formDataAdd.customerCode === '') { 
        //     const message = 'Please Enter Valid Customer Code'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // }

        // if(!this.formDataAdd.enomCode || this.formDataAdd.enomCode === '') { 
        //     const message = 'Please Enter Valid Customer Enom Code'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // }

        if(this.LinkerData.length > 0 && this.LinkerData.some((x) => !x.plName || !(x.shipper || x.consignee || x.supplier || x.operator || x.owner || x.trader ))) {
            const message = 'Please Select Valid Pipeline and Checked atleast one of the Customer Type in Linker Section.'
            localValidatingErrorBody.push(message);
            isValidRequest = false;
        }

        if(this.AddressData.length > 0) {
            if(this.AddressData.some((x) => !x.plName || x.plName === '')) {
                const message = 'Please Select Valid Pipeline for Address'
                localValidatingErrorBody.push(message);
                isValidRequest = false;
            }

            if(this.AddressData.some((x) => !x.customerTypeValue || x.customerTypeValue === '')) {
                const message = 'Please Select Valid Customer Type for Address'
                localValidatingErrorBody.push(message);
                isValidRequest = false;
            }

            if(this.AddressData.some((x) => !x.custAddressType || x.custAddressType === '')) {
                const message = 'Please Select Valid Address or Contact Type for Address'
                localValidatingErrorBody.push(message);
                isValidRequest = false;
            }
            
            if(this.AddressData.some((x) => !x.addressTypeValue || x.addressTypeValue === '')) {
                const message = 'Please Select Valid Address Type for Address'
                localValidatingErrorBody.push(message);
                isValidRequest = false;
            }

            // if(this.AddressData.some((x) => (x.customerTypeValue === 'Shipper' && x.addressTypeValue === 'General') && (!x.addrLine1 || x.addrLine1 === '' || !x.city || x.city === '' || !x.stateName || x.stateName === '' || !x.zip || x.zip === '' ||  !x.countryValue || x.countryValue === '' || (x.firstName && x.firstName !== '') || (x.lastName && x.lastName !== '') ))) {
            //     const message = 'If Customer Type is shipper and Address Type is general then  Address Line 1, City, State, Zip, Country are required , in this case First name and last name should be empty. Please Enter Valid Field for Address'
            //     localValidatingErrorBody.push(message);
            //     isValidRequest = false;
            // } 
            
            // if(this.AddressData.some((x) => !(x.customerTypeValue === 'Shipper' && x.addressTypeValue === 'General')) ){
            //     if(this.AddressData.filter((x) => !(x.customerTypeValue === 'Shipper' && x.addressTypeValue === 'General')).some((x) => !x.firstName || x.firstName === '' || !x.lastName || x.lastName === '' ||  !x.addrLine1 || x.addrLine1 === '' || !x.city || x.city === '' || !x.stateName || x.stateName === '' || !x.zip || x.zip ==='' || !x.countryValue || x.countryValue === '' || !x.phone || x.phone === '')) {
            //         const message = 'In general scenario First Name, Last Name, Address Line 1, City, State, Zip, Country, Phone No are required. Please Enter Valid Field for Address'
            //         localValidatingErrorBody.push(message);
            //         isValidRequest = false;
            //     }
                
            // }
            if(this.AddressData.some((x) => !(x.customerTypeValue === 'Shipper' && x.addressTypeValue === 'General')) ){
                if(this.AddressData.filter((x) => !(x.customerTypeValue === 'Shipper' && x.addressTypeValue === 'General') && x.custAddressType === 'CONTACT').some((x) => !x.firstName || x.firstName === '' || !x.lastName || x.lastName === '')) {
                    const message = 'In case of CONTACT Type First Name, Last Name are required. Please Enter Valid Field for Address'
                    localValidatingErrorBody.push(message);
                    isValidRequest = false;
                }
                
            }
        }

    //    if(this.CreditsData.length > 0) {
        
    //     if(this.CreditsData.some((x) => !x.valueText || x.valueText === '')) {
    //         const message = 'Please Select Valid Credit Type for Credit Info'
    //         localValidatingErrorBody.push(message);
    //         isValidRequest = false;
    //     }

    //     if(this.CreditsData.some((x) => !x.creditAmount || x.creditAmount === '')) {
    //         const message = 'Please Enter Valid Credit Amount for Credit Info'
    //         localValidatingErrorBody.push(message);
    //         isValidRequest = false;
    //     }

    //     if(this.CreditsData.some((x) => !x.effectiveRange || x.effectiveRange === '')) {
    //         const message = 'Please Select Valid Effective Range for Credit Info'
    //         localValidatingErrorBody.push(message);
    //         isValidRequest = false;
    //     }

    //     // if(this.CreditsData.some((x) => !x.endDate || x.endDate === '')) {
    //     //     const message = 'Please Select Valid End Date for Credit Info'
    //     //     localValidatingErrorBody.push(message);
    //     //     isValidRequest = false;
    //     // }

    //    }
        // if(this.CommentsData.length > 0  && this.CommentsData.some((x) => !x.comment|| x.comment === '')) {
        //     const message = 'Please Enter Valid Comments in Add Comment Section'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // }

        // if(this.CommentsData.length > 0  && this.CommentsData.some((x) => !x.custCommentType|| x.custCommentType === '')) {
        //     const message = 'Please Enter Valid Comment Type in Add Comment Section'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // }

        // if(this.CommentsData.length > 0  && this.CommentsData.some((x) => x.custCommentType &&  x.custCommentType === 'CONTACT' && (!x.contactInfo|| x.contactInfo === ''))) {
        //     const message = 'Please Select Valid Contact when Comment Type is CONTACT in Add Comment Section'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // }

        if(this.AddContanctData.length > 0) {
           
    
            if(this.AddContanctData.some((x) => !x.contactTypeValue || x.contactTypeValue === '')) {
                const message = 'Please Select Valid Contact Type in Additional Contact'
                localValidatingErrorBody.push(message);
                isValidRequest = false;
            }
            if(this.AddContanctData.some((x) => x.contactTypeValue === 'Phone'&& ((!x.phoneTypeValue || x.phoneTypeValue === '') || (!x.phone || x.phone === '')))) {
                const message = 'Please Select Valid Phone Type or Phone in Additional Contact'
                localValidatingErrorBody.push(message);
                isValidRequest = false;
            }
            // if(this.AddContanctData.some((x) => x.contactTypeValue === 'Email' && (!x.emailAddress || x.emailAddress === ''))) {
            //     const message = 'Please Enter Valid Email Address in Additional Contact'
            //     localValidatingErrorBody.push(message);
            //     isValidRequest = false;
            // }
            if(this.AddContanctData.some((x) => x.contactTypeValue === 'Phone' && (!x.contactInfo || x.contactInfo === ''))) {
                const message = 'Please Select Valid Contact in Additional Contact'
                localValidatingErrorBody.push(message);
                isValidRequest = false;
            }
            // if(this.AddContanctData.filter((x) => x.contactTypeValue === 'Email' && (x.emailAddress || x.emailAddress !== '')).length > 10) {
            //     const message = 'Email Contacts that can added in in Additional Contact should be max 10.'
            //     localValidatingErrorBody.push(message);
            //     isValidRequest = false;
            // }
        }

      

        // if(this.OtherData.length > 0 && this.OtherData.some((x) => !x.comment || x.comment === '')) {
        //     const message = 'Please Select Valid Additional Info'
        //     localValidatingErrorBody.push(message);
        //     isValidRequest = false;
        // }

        

        return {
            isValidRequest: isValidRequest, 
            localValidatingErrorBody: localValidatingErrorBody
        };
  
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveCustomer(): Promise<void> {
        const { isValidRequest, localValidatingErrorBody} = this.validateRequest();
        if(isValidRequest) {
            const requestBody : any = { 
                'customer': { 
                    'customerID': this.formDataAdd.customerId || 0, 
                    'custCode':this.formDataAdd.customerCode || '', 
                    'custName':this.formDataAdd.customerName || '', 
                    'fein':this.formDataAdd.fein || '', 
                    'prepaymentRequiredInd':this.formDataAdd.prepaymentRequiredInd===true?'Y':'N', 
                    'foreignEntityInd':this.formDataAdd.foreignEntityInd===true?'Y':'N', 
                    'performanceRiskInd':this.formDataAdd.performanceRiskInd===true?'Y':'N', 
                    'relatedPartyInd':this.formDataAdd.relatedPartyInd===true?'Y':'N', 
                    'shipperAffiliatedInd':this.formDataAdd.shipperAffiliatedInd===true?'Y':'N', 
                    'custEnomCode':this.formDataAdd.enomCode || '', 
                    'createDate':this.formDataAdd.createDate || moment().format('YYYY-MM-DDTHH:mm:ss'), 
                    'createUser':this.formDataAdd.createUser || this.username, 
                    'updateDate': this.formDataAdd.customerId ? moment().format('YYYY-MM-DDTHH:mm:ss') : null, 
                    'updateUser': this.formDataAdd.customerId ? this.username : null, 
                    'comments': this.formDataAdd.comments  || '',
                    'salesForceID': this.formDataAdd.salesForceID || null,
                    'extCustID': this.formDataAdd.extCustID || 0
                }, 
                'lstCustPL': this.LinkerData.map(x=>{return{         
                    'plName': x.plName, 
                    'pipelineID': x.pipelineID || this.assignedSystemsDropdown.find(y=>y.plName===x.plName)?.pipelineID, 
                    'shipper': x.shipper, 
                    'consignee': x.consignee, 
                    'supplier': x.supplier, 
                    'operator': x.operator, 
                    'owner': x.owner, 
                    'trader': x.trader,
                    'ic' : x.ic,
                    'nominationStartDate' : x.nominationStartDate
                    }
                }), 
                'credit': this.CreditsData.map(x=>{return{ 
                    'creditID':x.tariffCustCreditID || 0,  
                    'creditType': this.creditTypesDropdown.find(y=>y.valueText===x.valueText)?.settingID || 0, 
                    'creditAmount': x.creditAmount || 0, 
                    'effectiveRange':x.effectiveRange && x.effectiveRange !== '' ?  moment(x.effectiveRange).format(AppConstant.API_DATE_FORMAT) : null,
                    'endDate':x.endCycle && x.endCycle !== '' ?  moment(x.endCycle).format(AppConstant.API_DATE_FORMAT) : null
                    } 
                }), 
                'comment': this.CommentsData.map(x=>{return{         
                    'commentID': x.tariffCustCommentID || 0, 
                    'commentType': this.customerCommentTypeDropdown.find((y) => y.valueText === x.custCommentType)?.settingID || 0,
                    'contactInfo':x.contactInfo|| '',
                    'comments': x.comment || ''
                    } 
                }), 
                'addContact': this.AddContanctData.filter((i) =>  i.newOrModified && i.newOrModified === true).map(x=>{return{ 
                    'addContactID': x.tariffCustAddContactID || 0, 
                    'contactInfo':x.contactInfo|| '',
                    'contactType': this.contactTypesDropdown.find(y=>y.valueText===x.contactTypeValue)?.settingID || 0, 
                    'phoneType': this.phoneNumberTypesDropdown.find(y=>y.valueText===x.phoneTypeValue)?.settingID || null, 
                    'phone': x.phone || null,
                    'email': x.emailAddress || null,
                    'remarks': x.remark || ''
                    }
                }),
                'affliate': this.OtherData.map(x =>{return{ 
                    'affliateID':  x.tariffCustCommentID || 0, 
                    'data': x.comment || ''
                    }
                }), 
                'address':this.AddressData.filter((i) =>  i.newOrModified && i.newOrModified === true).map(x=> {return{ 
                    'custType':this.customerTypesDropdown.find((y) => y.valueText === x.customerTypeValue)?.settingID || 0,
                    'custAddType': this.customerAddressTypeDropdown.find((y) => y.valueText === x.custAddressType)?.settingID || 0,
                    'addrType':this.addressTypesDropdown.find((y)=> y.valueText === x.addressTypeValue)?.settingID || 0, 
                    'addrLine1':x.addrLine1 || '', 
                    'addrLine2':x.addrLine2|| '', 
                    'city':x.city || ' ', 
                    'state':this.tariffStatesDropdownOption.find((y) => y.stateName === x.stateName)?.stateID || 0, 
                    'country':this.jurisdictionDropdown.find((y) => y.valueText === x.countryValue)?.settingID || 0, 
                    'pipelineID':x.pipelineID || this.assignedSystemsDropdown.find((y) => y.plName === x.plName)?.pipelineID || 0, 
                    'zip':x.zip ?  x.zip : '', 
                    'firstName':x.firstName ?  x.firstName : '', 
                    'lastName':x.lastName ? x.lastName : '', 
                    'initial':x.initial ?  x.initial: '', 
                    'title':x.title ?  x.title : '', 
                    'phone':x.phone ?  x.phone : '', 
                    'email':x.email ?  x.email : '',
                    'addressID': x.tariffCustAddID || 0,
                    'primaryInd' : x.primaryInd || 'N'
                    }
                })
            } 
            let response: any[] = []; 
            response = await this.customerMaintenanceDataService.saveCustomer(requestBody);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.unsavedChanges = false;
                    const responseArray: any = response[0];
                    this.formDataAdd = {...this.formDataAdd, 
                        customerNameSearch: '',
                        customerName: responseArray.custName,
                        customerId:responseArray.customerID,
                        customerCode: responseArray.custCode,
                        enomCode: responseArray.custEnomCode,
                        fein: responseArray.fein,
                        sapAccount: responseArray.sapCompID,
                        no637: responseArray.ph637No,
                        sapICAccount: responseArray.sapCustAcctID,
                        comments: responseArray.comments,
                        prepaymentRequiredInd:responseArray.prepaymentRequiredInd==='Y'?true:false, 
                        shipperAffiliatedInd: responseArray.shipperAffiliatedInd==='Y'?true:false, 
                        performanceRiskInd: responseArray.performanceRiskInd==='Y'?true:false, 
                        foreignEntityInd: responseArray.foreignEntityInd==='Y'?true:false, 
                        relatedPartyInd: responseArray.relatedPartyInd==='Y'?true:false, 
                        updatedBy: responseArray.updateUser || responseArray.createUser,
                        updateDate: responseArray.updateDate ? responseArray.updateDate : responseArray.createDate,
                        createUser: responseArray.createUser,
                        createDate: responseArray.createDate,
                        syncStatus: responseArray.syncStatus ?  responseArray.syncStatus : SyncStatus[0],
                        salesForceID: responseArray.salesForceID || '',
                        extCustID: responseArray.extCustID || 0
                    };
                    this.linkerData=responseArray.pipelines;
                    this.linkerData.forEach((x,index, xarray)=>{
                            xarray[index]={...x, rowId: index, existing: true}
                    });
                    this.backupLinkerData = this.linkerData;
                    this.creditsData=responseArray.credits;
                    this.creditsData.forEach((x,index, xarray)=>{
                        xarray[index]={...x, rowId: index, existing: true}
                    });
                    // this.otherData=responseArray.affliates;
                    // this.otherData.forEach((x,index, xarray)=>{
                    //     xarray[index]={...x, rowId: index, existing: true}
                    // });
                    this.commentsData=responseArray.addComments;
                    this.commentsData.forEach((x,index, xarray)=>{
                        xarray[index]={...x, rowId: index, existing: true}
                    });
                    this.addressData = responseArray.contacts;
                    this.addressData.forEach((x,index, xarray)=>{
                        xarray[index]={...x, rowId: index, existing: true}
                    });
                    this.addContactData = responseArray.additionalContact;
                    this.addContactData.forEach((x,index, xarray)=>{
                        xarray[index]={...x, rowId: index, existing: true}
                    });
                    this.uiService.toastService.success('Customer Saved Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
        } else {
            const messages = '\u2022  ' + localValidatingErrorBody.join('\r\n\n\u2022  ');
            errorHandler(messages);
        } 
       
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPhoneNumberTypesDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getPhoneNumberTypesDropdown();
        this.phoneNumberTypesDropdown = response    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getAddressTypesDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getAddressTypesDropdown();
        this.addressTypesDropdown = response    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCustomerAddressTypesDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getCustomerAddressTypesDropdown();
        this.customerAddressTypeDropdown = response    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCustomerCommentTypesDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getCustomerCommentTypesDropdown();
        this.customerCommentTypeDropdown = response    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getContactTypesDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getContactTypesDropdown();
        this.contactTypesDropdown = response    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCreditTypesDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getCreditTypesDropdown();
        this.creditTypesDropdown = response    ;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCustomerTypesDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getCustomerTypesDropdown();
        this.customerTypesDropdown = response    
    }

    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getCustomerStatusDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getCustomerStatusDropdown();
        this.customerStatusDropdown = response    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getTariffStatesDropdown(): Promise<void> {
        const tariffStatesDropdownOption = await this.customerMaintenanceDataService.getTariffStates();
        this.tariffStatesDropdownOption= tariffStatesDropdownOption;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getJurisdictionDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getJurisdictionDropdown();
        this.jurisdictionDropdown = response    
    }
    
    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getBaseSystemsDropdown(): Promise<void> {
        const response = await this.customerMaintenanceDataService.getBaseSystems();
        this.assignedSystemsDropdown = response 
        // const assignedSystem = response && response.length > 0 && { value: response[0].pipelineID, label: response[0].plName }
        // this.formData.assignedSystem = assignedSystem;  
    }
    
    @Loader
    @Catch(() => errorHandler('Fetch Error. Please retry!'))
    async onSearchClick(currentPage, pageSize): Promise<void> {
        this.isSearchClicked = true;
        this.isRefreshed = true;
        dataStore.onGridReady();
        dataStore.setShowUnsavedWarningAlert(false); 
        this.loadViewModel();
    }

    hasDuplicates(array) {
        return (new Set(array)).size !== array.length;
    }

    toFindDuplicates = arry => arry.filter((item, index) => arry.indexOf(item) !== index)
    
    @action
    clearScreen(){
        this.formData = {
            customerType: {label:'', value:0},
            customerName: '',
            assignedSystem: {label:'All', value:0},
            customerStatus: {label:'', value:0},
            customerCode: '',
            enomCode: ''
        }
        this.agGridService.deselectAllRows();
        this.isSearchClicked = false;
        this.loadViewModel();
    }

    @Loader
    @action
    async checkCustomerName() {
        const response = await this.customerMaintenanceDataService.checkCustomerName(this.formDataAdd.customerNameSearch);
        this.customerNames = response  
        this.disableCustomerName = this.formDataAdd.customerId ?  this.disableCustomerName : false
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async deactivate(id): Promise<void> {
        const response: any = await this.customerMaintenanceDataService.deactivate(id, this.username);
        if(response){
            let error ='';
            this.agGridService.deselectAllRows();
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
           }
           if(error===''){
                this.uiService.toastService.success('Customer/(s) Deactivated Successfully!')
                this.setAddCM();
           }
       }  
    }   

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async activate(): Promise<void> {
        const requestBody: any = await this.agGridService.getSelectedRows()?.map(x=>x.customerID);
        if(requestBody.length){
        const response: any = await this.customerMaintenanceDataService.activate(requestBody, this.username);
        if(response){
            let error ='';
            this.agGridService.deselectAllRows();
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
           }
           if(error===''){
                this.uiService.toastService.success('Customer/(s) Activated Successfully!')
           }
       }}else {
            this.uiService.toastService.error('Please Select Rows to Activate');
       }
    }       
       
    @Loader
    @action
    async loadViewModel() {
        const pageSize = this.agGridService.paginationGetPageSize();
        if(dataStore.isPageSizeChanged) {
            this.agGridService.resetCache(Number(pageSize));
        }
        const dataSource = {
            getRows: (params) => {
            // Use startRow and endRow for sending pagination to Backend
            // params.startRow : Start Page
            // params.endRow : End Page
                const pageSize = this.agGridService.paginationGetPageSize();
                const page =  params.endRow / (pageSize ? pageSize : 100);
                const pageNo = this.isRefreshed || dataStore.isPageSizeChanged || page <= 1 ? 1 : parseInt(page.toFixed(0));
                const filterRequestBody: any = [];
                for (const [key, value] of Object.entries(params.filterModel)) {
                    console.log(key, value);
                    const item = {
                        propertyName: key,
                        value: value && value['filterType'] === 'date'? value['dateFrom'] : value ? value['filter']:'',
                        operator: value ? value['type'] : '',
                        joinPrev: 'none'
                      }
                      filterRequestBody.push(item);
                  }
                console.log('params',params,JSON.stringify(filterRequestBody), this.CustomerMaintenanceData);
                this.agGridService.showLoadingOverlay();
                // if(params?.startRow===0){
                //     params.successCallback([]);
                // }
                const sortModel=params.sortModel 
                const data = {
                    paginationStart: pageNo || 1, 
                    paginationEnd: pageSize || 100, 
                    pipelineID: this.isSearchClicked ? this.formData.assignedSystem?.value || 0 : 0,
                    custStatus: this.isSearchClicked ? this.formData.customerStatus?.value || 0 : 0, 
                    custType: this.isSearchClicked ? this.formData.customerType?.value || 0 : 0,
                    custCode: this.isSearchClicked ? this.formData.customerCode || '' : '', 
                    custName: this.isSearchClicked ? this.formData.customerName || '' : '',
                    custEnomCode: this.isSearchClicked ? this.formData.enomCode || '' : '',
                    ColId: sortModel && sortModel.length > 0 ? sortModel[0]?.colId : '',
                    Sort:  sortModel && sortModel.length > 0 ? sortModel[0]?.sort : '',
                } 
                this.customerMaintenanceDataService.getCustomerMaintenanceGridData(data, filterRequestBody).then((response) => {
                    const totalCount = response.data && response.data.length > 0 ? response.data[0].totalCount : response.data.length
                    params.successCallback(response.data ? response.data : [], totalCount);
                    this.agGridService.hideOverlay();
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false);
                    dataStore.setCacheBlockSize(response.data? response.data.length : 0)
                }).catch((e) => {
                    params.successCallback([], 0);
                    this.agGridService.hideOverlay();
                    dataStore.setCacheBlockSize(0);
                    this.isRefreshed = false;
                    dataStore.setPageSizeChanged(false);
                    errorHandler('Fetch Error')
                    console.log(e)
                });
            }
        }
        this.agGridService.setDatasource(dataSource);
    }

    @computed
    get CustomerMaintenanceData() {
        return toJS(this.customerMaintenanceData);
    }
   
    @action
    setGridData(value){
        this.customerMaintenanceData=value
    }

    @action
    setUpdatedRowIds(value){
        this.updatedRows=value
    }

    @action
    getUpdatedRows = () => {
        return this.updatedRows;
    }

    @computed
    get LinkerData() {
        return toJS(this.linkerData);
    }

    @action
    setLinkerData(value, rowId, key){
        this.linkerData=this.LinkerData;
        if(key==='trader'&&this.linkerData[rowId]['shipper']!==true){
            this.uiService.toastService.error('Only an existing shipper on a system can be marked as a trader!');
            return;
        } else if((key === 'trader' && this.linkerData[rowId]['ic'] ===true) || (key === 'ic' && this.linkerData[rowId]['trader'] ===true)){
            this.uiService.toastService.error('An existing shipper on a system can be marked as either Trader or Inter-Company, but not both!');
            return;
        } else {
            this.linkerData[rowId][key] = value;
        }
        if(this.LinkerData.length >  1 && this.LinkerData.filter((item) => item[key] === true).length > 0) {
            const confirmService = new ConfirmService();
            confirmService.showConfirmDialog(
                () =>  this.globalLinkedCustomerTypeSelection(value, key),
                'Add Pipeline Validation',
                'Do you want to apply selected customer type to all the linked pipelines?'
            );
          }
    }

    @action
    setError(message) {
        this.uiService.toastService.error(message)
    }

    addLinkerData() {
            this.linkerData=[{
                'rowId': this.LinkerData.length,
            }, ...this.LinkerData]
            // this.backupLinkerData = this.linkerData;
            this.agGridServiceAdd.removeSortModel();
            this.scrollToFocusTheNewCell(this.agGridServiceAdd)
    }
    
    @computed
    get AddressData() {
        return toJS(this.addressData);
    }

    addAddressData() {
        this.addressData=[{
            'rowId': this.AddressData.length,
        }, ...this.AddressData]
        this.agGridServiceForAddAddress.removeSortModel();
        this.scrollToFocusTheNewCell(this.agGridServiceForAddAddress)
    }

    @computed
    get CreditsData() {
        return toJS(this.creditsData);
    }

    addCreditsData() {
        this.creditsData=[{
            'rowId': this.CreditsData.length,
        }, ...this.CreditsData]
        this.scrollToFocusTheNewCell(this.agGridServiceForAddCredit)
    }
    @action
    setCreditsData(value, rowId, key){
        this.creditsData=this.CreditsData;
            this.creditsData[rowId][key] = value;
    }

    @computed
    get OtherData() {
        return toJS(this.otherData);
    }

    addOtherData() {
        this.otherData=[{
            'rowId': this.OtherData.length,
        }, ...this.OtherData]
        this.scrollToFocusTheNewCell(this.agGridServiceForAddOtherInfo)
    }

    @action
    setAddressData(value, rowId, key){
        this.addressData=this.AddressData;
            this.addressData[rowId][key] = value;
    }

    @computed
    get CommentsData() {
        return toJS(this.commentsData);
    }

    addCommentsData() {
        this.commentsData=[{
            'rowId': this.CommentsData.length,
        }, ...this.CommentsData]
        this.scrollToFocusTheNewCell(this.agGridServiceForAddComments)
    }
 
    @computed
    get AddContanctData() {
        return toJS(this.addContactData);
    }

    addAddContactData() {
        this.addContactData=[{
            'rowId': this.AddContanctData.length,
            'contactTypeValue': 'Phone'
        }, ...this.AddContanctData]
        this.agGridServiceForAddOtherContacts.removeSortModel();
        this.scrollToFocusTheNewCell(this.agGridServiceForAddOtherContacts)
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async deleteSelectedRows(requestBody, section): Promise<void>  {
            const response: any[] = await this.customerMaintenanceDataService.deleteSectionRows(requestBody, this.username, section);
            if(response){
                let errorFlag=false;let errorValue=''
                for(const key in response){
                    if(key==='statusCode'&&response[key]===400){
                        errorFlag=true;
                    }
                    if(key==='value'){
                        errorValue=response[key];
                    }
                } 
                if(errorFlag===true){
                    this.uiService.toastService.error(errorValue)
                }else{
                    this.getCustomerDetails(this.formDataAdd.customerId, section);
                    this.uiService.toastService.success('Record Deleted Successfully!')
                }dataStore.setShowUnsavedWarningAlert(false);
            } 
    }

    @Loader
    @action
    async pushSelectedRowsToVmacs(env): Promise<void> {
            const customerId = this.formDataAdd ?  this.formDataAdd.customerId.toString() : null;
            const requestBody: any =  {
                workflowid: '0',
                env: env,
                id: customerId
              }
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.customerMaintenanceDataService.pushToVmacs(requestBody);
                if(response) {
                    this.uiService.toastService.success('Synced Successfully');
                    if(env === 'Prod' && (!this.formDataAdd.enomCode || !this.formDataAdd.customerCode)) {
                        const data = await this.customerMaintenanceDataService.getCustomerEnomCode(customerId);
                        if(data) {
                            this.formDataAdd.enomCode = this.formDataAdd.enomCode && this.formDataAdd.enomCode !== '' ?  this.formDataAdd.enomCode :  data;
                            this.formDataAdd.customerCode = this.formDataAdd.customerCode && this.formDataAdd.customerCode !== '' ?  this.formDataAdd.customerCode :  data;
                            // this.formDataAdd = {...this.formDataAdd, ...data[0]}
                        }
                    }
                    this.getCustomerDetails(this.formDataAdd.customerId);
                } else {
                    this.uiService.toastService.error('Failed to sync changes');
                }
            }
    
        // }
       
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.CustomerMaintenance);
    };

    @action 
    scrollToFocusTheNewCell(gridService: AgGridService) {
        gridService.scrollToSelectedRow();
    }

    @action
    globalLinkedCustomerTypeSelection = (value, key) => {
        this.linkerData=this.LinkerData;
        if(key==='trader'){
            this.linkerData.forEach((x) => {
                if(x.shipper === true && x.ic !== true && (x.plName && !(x.plName.startsWith('Z-') || x.plName.startsWith('z-')) && !x.syncToProdList?.includes(key))) {
                    x[key] = value;
                }
            })
           
        } else if(key==='ic'){
            this.linkerData.forEach((x) => {
                if(x.trader !== true && (x.plName && !(x.plName.startsWith('Z-') || x.plName.startsWith('z-'))) && !x.syncToProdList?.includes(key)) {
                    x[key] = value;
                }
            })
           
        } else {
            this.linkerData.forEach((x) => {
                if(x.plName && !(x.plName.startsWith('Z-') || x.plName.startsWith('z-')) && !x.syncToProdList?.includes(key))
                x[key] = value;
            })
        }
    };
}
