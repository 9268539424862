import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { PMCDashboardDataStore } from '../stores/pmc-dashboard-data-store';
import {
    GridOptions,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import './pmc-dashboard.scss'
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { pmcDashboardDataStore } from '../stores';
import DatePicker from 'react-datepicker';
import { ReactSelect } from 'common/components/select/select';
import { localStorageService } from 'shared/services/local-storage-service';
import moment from 'moment';

/* eslint-disable */
interface PMCDashboardContainerProps {
    pmcDashboardDataStore: PMCDashboardDataStore;
}

interface PMCDashboardContainerState {
    search: string;
}

@inject('pmcDashboardDataStore')
@observer
export class PMCDashboardContainer extends Component<PMCDashboardContainerProps, PMCDashboardContainerState> {
    constructor(props: PMCDashboardContainerProps | Readonly<PMCDashboardContainerProps>) {
        super(props);
        this.state = {
            search: ''
        };
        dataStore.setModuleName('PMC Tariff Management')
    }
    
    componentDidMount() {
        history.push('/PMCDashboard');
      const {pmcDashboardDataStore} = this.props;
      pmcDashboardDataStore.init();
      pmcDashboardDataStore.agGridService.refreshGrid();
    }

    async componentDidUpdate(){
        const filters = await localStorageService.get('PMC Dashboard_filter');
        await this.props.pmcDashboardDataStore.agGridService.setAllFilters(filters);
        const columnVisible = localStorageService.get('PMC Dashboard_' + this.props.pmcDashboardDataStore.accountStore.userName);
        this.props.pmcDashboardDataStore.agGridService.setColumnVisibility(columnVisible);
    }

    onSelectTariffNumber = (e) => {
        pmcDashboardDataStore.setTariffNumber(e);
    };

    onSelectSystemGroup = (e) => {
        pmcDashboardDataStore.setSystemGroup(e);
    };

    onSelectPipelineGroup = (e) => {
        pmcDashboardDataStore.setPipelineGroup(e);
    };

    onSelectDistributionType = (e) => {
        pmcDashboardDataStore.setDistributionType(e);
    };

    onSelectTariffRequestType = (e) => {
        pmcDashboardDataStore.setTariffRequestType(e);
    };

    onSelectTariffApplication = (e) => {
        pmcDashboardDataStore.setTariffApplication(e);
    };

    onChangeOperator = (e) => {
        pmcDashboardDataStore.setOperator(e);
    };

    onSelectProduct = (e) => {
        pmcDashboardDataStore.setProduct(e);
    };

    onSelectExternalSystem = (e) => {
        pmcDashboardDataStore.setExternalSystem(e);
    };

    onSelectProductType = (e) => {
        pmcDashboardDataStore.setProductType(e);
    };

    onSelectUnit = (e) => {
        pmcDashboardDataStore.setUnit(e);
    };

    onSelectStatus = (e) => {
        pmcDashboardDataStore.setTariffStatus(e);
    };

    onSelectCurrency = (e) => {
        pmcDashboardDataStore.setCurrency(e);
    };

    onSelectRegulator = (e) => {
        pmcDashboardDataStore.setRegulator(e);
    };

    onSelectFacilityCode = (e) => {
        pmcDashboardDataStore.setFacilityCode(e);
    };

    onSelectLSD = (e) => {
        pmcDashboardDataStore.setLSD(e);
    };

    onSelectDestination = (e) => {
        pmcDashboardDataStore.setDestination(e);
    };

    onSelectEffectiveStartDate = (e) => {
        pmcDashboardDataStore.setEffectiveStartDate(e);
    };

    onSelectEffectiveEndDate = (e) => {
        pmcDashboardDataStore.setEffectiveEndDate(e);
    };

    onSelectCreatedUserName = (e) => {
        pmcDashboardDataStore.setCreatedUserName(e);
    };

    onSelectUpdatedUserName = (e) => {
        pmcDashboardDataStore.setUpdatedUserName(e);
    };

    render(): React.ReactNode {
      const { pmcDashboardDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderPMCDashboardDataStoreScreen()}
        </Container>
      );
    }

    renderPMCDashboardDataStoreScreen() : React.ReactNode {
      const { pmcDashboardDataStore } = this.props;
      return (
        <div className='pmcDashboardBox'>
            <Container fluid>
                <div>
                    <div className='screenHeader'>
                    <h2 className='screen_title'>PMC Dashboard</h2>
                    </div>
                    <div className='selectContainer'>
                    <div className='dashboardSettingSelectionDiv'>
                    <Row>
                        <Col>
                            <label className='standard_label_style'>Tariff Number</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedTariffNumber}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.tariffNumberDropdownOptions]}
                                onChange={this.onSelectTariffNumber}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Pipeline Region</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedPipelineGroup}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.pipelineGroupDropdownOptions]}
                                onChange={this.onSelectPipelineGroup}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Distribution</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedDistributionType}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.distributionTypeDropdownOptions]}
                                onChange={this.onSelectDistributionType}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Product Type</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedProductType}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.productTypeDropdownOptions]}
                                onChange={this.onSelectProductType}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        </Row>
                        <Row>
                        <Col>
                            <label className="standard_label_style">Start Date</label>
                            <DatePicker
                                className='datepicker__holder'
                                selected={pmcDashboardDataStore.selectedEffectiveStartDate}
                                onChange={(e) => {
                                    this.onSelectEffectiveStartDate(e);
                                }}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Operator</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.enteredOperator}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.operatorDropdownOptions]}
                                onChange={this.onChangeOperator}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Tariff Application</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedTariffApplication}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.tariffApplicationDropdownOptions]}
                                onChange={this.onSelectTariffApplication}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Product</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedProduct}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.productDropdownOptions]}
                                onChange={this.onSelectProduct}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        </Row>
                        <Row>
                            <Col>
                                <label className="standard_label_style">End Date</label>
                                <DatePicker
                                    className='datepicker__holder'
                                    selected={pmcDashboardDataStore.selectedEffectiveEndDate}
                                    onChange={(e) => {
                                        this.onSelectEffectiveEndDate(e);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                            </Col>
                            <Col>
                                <label className='standard_label_style'>Facility ID</label>
                                <ReactSelect
                                    className='select'
                                    values={pmcDashboardDataStore.selectedFacilityCode}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...pmcDashboardDataStore.facilityCodeDropdownOptions]}
                                    onChange={this.onSelectFacilityCode}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                                <label className='standard_label_style'>External System</label>
                                <ReactSelect
                                    className='select'
                                    values={pmcDashboardDataStore.selectedExternalSystem}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...pmcDashboardDataStore.externalSystemDropdownOptions]}
                                    onChange={this.onSelectExternalSystem}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>
                            <Col>
                            <label className='standard_label_style'>Regulator</label>
                            <ReactSelect
                                className='select lastColInput'
                                values={pmcDashboardDataStore.selectedRegulator}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.regulatorDropdownOptions]}
                                onChange={this.onSelectRegulator}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>                            
                        </Row>
                        <Row>
                        <Col>
                            <label className='standard_label_style'>Asset Group</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedTariffRequestType}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.tariffRequestTypeDropdownOptions]}
                                onChange={this.onSelectTariffRequestType}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>LSD</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedLSD}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.lsdDropdownOptions]}
                                onChange={this.onSelectLSD}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Unit</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedUnit}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.unitDropdownOptions]}
                                onChange={this.onSelectUnit}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                                <label className='standard_label_style'>Created User</label>
                                <ReactSelect
                                    className='select'
                                    values={pmcDashboardDataStore.selectedCreatedUserName}
                                    placeholder="Select"
                                    options={[{value:'All', label:'All'},...pmcDashboardDataStore.userNamesDropdownOptions]}
                                    onChange={this.onSelectCreatedUserName}
                                    isMulti={false}
                                    isSearchable={true}
                                />
                            </Col>                       
                        </Row>
                        <Row>
                            <Col>
                            <label className="standard_label_style">Pipeline System</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedSystemGroup}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.systemGroupDropdownOptions]}
                                onChange={this.onSelectSystemGroup}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Destination</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedDestination}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.destinationDropdownOptions]}
                                onChange={this.onSelectDestination}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Currency</label>
                            <ReactSelect
                                className='select lastColInput'
                                values={pmcDashboardDataStore.selectedCurrency}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.currencyDropdownOptions]}
                                onChange={this.onSelectCurrency}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        <Col>
                            <label className='standard_label_style'>Updated User</label>
                            <ReactSelect
                                className='select'
                                values={pmcDashboardDataStore.selectedUpdatedUserName}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.userNamesDropdownOptions]}
                                onChange={this.onSelectUpdatedUserName}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>                       
                        </Row>
                        <Row>
                            <Col></Col>
                            <Col></Col>
                            <Col></Col>
                        <Col>
                            <label className='standard_label_style'>Status</label>
                            <ReactSelect
                                className='select lastColInput'
                                values={pmcDashboardDataStore.selectedTariffStatus}
                                placeholder="Select"
                                options={[{value:'All', label:'All'},...pmcDashboardDataStore.tariffStatusDropdownOptions]}
                                onChange={this.onSelectStatus}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                        </Row>
                    </div>
                    <div className='searchButtonDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=> pmcDashboardDataStore.onSearchClick()} disabled= {false}>
                            Search
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={()=> pmcDashboardDataStore.onClearSearch()} disabled= {false}>
                            Clear
                        </CustomButton>
                    </div>
                    </div>
                {this.props.pmcDashboardDataStore.showLoader == true &&
                    <div className = 'alert-overlay'> 
                    <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                    </div> 
                }
                <AgGridComponent gridConfig={this.getGridConfig('PMC Dashboard')} />
                </div>
            </Container >
        </div>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          pmcDashboardDataStore: { pmcDashboardData, agGridService }
      } = this.props;
      const { pmcDashboardDataStore } = this.props;
      const columnDefs = pmcDashboardDataStore.getColDef();
      const rowData = pmcDashboardData;
      const pmcDashboardGridParams: any = {
        rowData,
        columnDefs
      };
      return agGridService.getGridConfig(pmcDashboardGridParams, gridId);
    }
}
