import React from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { filingEntityMaintenanceDataStore } from '../../stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { dataStore } from 'common/stores';


export const FilingEntityCellRenderer = (props: ICellRendererParams) => {    
    return (

        !dataStore.checkOperationAccess(RoleBasedActionName.View) ? <span>{props.data && props.data.filingEntity ? props.data.filingEntity : ''}</span> : 
            <span style={{color: 'blue'}}
            onClick={()=>filingEntityMaintenanceDataStore.openFilingEntityDetails(props.data)}
            >
                {props.data ? props.data.filingEntity ==undefined?'0000000':props.data.filingEntity : '' }</span>
    );
};