import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class GradeMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    async getGradesDataWithFilter(grade, gradeName, pipelineSystemId): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getGrades}?grade=${grade}&gradeName=${gradeName}&pipelineSystemId=${pipelineSystemId}`);
        return data;
    } 

    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }

    async getGradesData(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getGradesWithDefaultFilter);
        return data;
    }
    async getSystemGrades(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getSystemGrades);
        return data;
    }
    async saveTariffGrade(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.saveTariffGrade, reqbody, {});
        return data;
    }
    async getTariffGrade(tariffGradeID): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getTariffGrade+`?tariffGradeID=${tariffGradeID}`);
        return data;
    }
    async deactivateGrade(tariffGradeID, user): Promise<any> {
        const { data } = await this.restApi.delete<any>(Path.deleteTariffGrade+`?tariffGradeID=${tariffGradeID}&user=${user}`);
        return data;
    }  
    async activateGrade(requestBody, user): Promise<any> {
        const { data } = await this.restApi.put<any>(Path.activateTariffGrade+`?user=${user}`, requestBody, {});
        return data;
    }  
    async getPipelineSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }
    async getSettingNameOptions(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id3);
        return data;
    }  
    async getJurisdictionDropdownData(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id8);
        return data;
    }
    async updateGradeMaintenanceData(requestBody, onEditGrade): Promise<any[]> {
        if(onEditGrade) {
            const { data } = await this.restApi.put<any>(Path.addGradeDetailsAPI, requestBody, {});
            return data;
        } else {
            const { data } = await this.restApi.post<any>(Path.addGradeDetailsAPI, requestBody, {});
            return data;
        }
    }  
    async deleteGradeMaintenanceData(requestBody): Promise<any> {
        const { data } = await this.restApi.delete<any>(Path.addGradeDetailsAPI, requestBody, {});
        return data;
    }  
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsGradeEndpoint, reqbody, {});
        return data;
    }  

    async updateStatusFromDelToActive(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateGrades, reqbody, {});
        return data;
    }
}

export const gradeMaintenanceDataService = new GradeMaintenanceDataService(restApiService);
