import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import './tariff-options.scss';
import {
  CellEditingStoppedEvent,
  ColDef
} from 'ag-grid-community';
import { TariffOptionsDataStore } from '../stores/tariff-options-data-store';
import { tariffOptionsDataStore } from '../stores';
import moment from 'moment';
import { AppConstant } from 'app_constants';
import momenttimezone from 'moment-timezone';
import { dataStore } from '../../../../common/stores';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { observer, inject } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { RoleBasedActionName } from 'shared/types/enum';
import { Prompt } from 'react-router-dom';
import { LossAllowance } from './loss-allowance';
import { ConnectionPoint } from './connection-point';
import { Movement } from './movement';
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import { keyBy } from 'lodash';
import history from 'shared/components/header-component/component/history';
import {ThreeDots} from 'react-loader-spinner';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';

/* eslint-disable */
interface TariffOptionsContainerProps {
  tariffOptionsDataStore: TariffOptionsDataStore;
}

interface TariffOptionsContainerState {
  rowId: number;
  cloneNewTariff: boolean;
  // predictiveSelectText: string;
  // selectedShownArray: any[];
  // totalArray: any[];
  // hiddenSelect: boolean;
  // editableFormData: any;
  // baseTariffState: any;
}

interface interfaceCustomerName{
    customerName: string;
}

@inject('tariffOptionsDataStore')
@observer
export class TariffOptionsContainer extends Component<TariffOptionsContainerProps, TariffOptionsContainerState> {
  cloneBoxRef: React.RefObject<HTMLInputElement>;

  constructor(props) {
    super(props);
    this.state = {
      rowId: 0,
      cloneNewTariff: false
      // predictiveSelectText: "",
      // selectedShownArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      // totalArray: ['apple', 'ball', 'cat', 'dog', 'Paris', 'New York'],
      // hiddenSelect: true,
      // editableFormData: {},
      // baseTariffState: {}
    };
    this.cloneBoxRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    dataStore.setModuleName('Tariff Option Tariff Reference');
  }

  componentDidMount() {
    const { tariffOptionsDataStore } = this.props;
    tariffOptionsDataStore.init();
    window.scroll(0, 0);
   // this.getPlusDimensions();
  }

  handleClickOutside(event) {
    if (this.cloneBoxRef && !this?.cloneBoxRef?.current?.contains(event.target)) {
      tariffOptionsDataStore.cloneNewTariff = {};
    }
  }
  
  componentWillUnmount() {
    tariffOptionsDataStore.unmount();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getGridConfigJointCarrier(gridId) {
    const {
      tariffOptionsDataStore: { agGridService, selectedBaseTariffDetails }
    } = this.props;
    const gridParams: any = {
      rowData: selectedBaseTariffDetails[this.state.rowId]?.tariffBaseDetails?.map(x => { return { 'jointCarrier': x.jointCarrier, 'baseTariff': x.baseTariff, 'pipelineArea': x.pipeline }; }) || [],
      columnDefs: this.colDefBaseTariff,
      domLayout: 'autoHeight',
    };
    return agGridService.getGridConfig(gridParams, gridId);
  }
  colDefBaseTariff: ColDef[] = [
    {
      headerName: 'Joint Carrier',
      field: 'jointCarrier',
      filter: true,
      sortable: true,
      editable: false,
      minWidth: 130,
      cellEditor: 'agSelectCellEditor',
    },
    {
      headerName: 'Base Tariff',
      field: 'baseTariff',
      minWidth: 130,
      filter: true,
      sortable: true,
      editable: false,
    },
    {
      headerName: 'Pipeline Area',
      field: 'pipeline',
      minWidth: 665,
      filter: true,
      sortable: true,
      editable: false,
      flex: 1
    }
  ];

  colDefDeduction: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => { return !params.data.isCloned},
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      colId:'Select',
      width: 40,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Tariff DedID',
      field: 'tariffDedID',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      editable: false,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Begin',
      field: 'begin',
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.begin=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    },
    {
      headerName: 'End',
      field: 'end',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.end=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    },
    {
      headerName: 'Deduction',
      field: 'deduction',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      minWidth: 130,
      flex: 1,
      valueSetter: (params) => {
        if(params.newValue.match(/^[0-9.]*$/)){
          params.data.deduction=params.newValue.toUpperCase();
          return true
        } else {
          return false
        }
      },
    }
  ];

  colDefPipelineArea: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => { return !params.data.isCloned},
      colId:'Select',
      width: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}},
    },
    {
      headerName: 'Area',
      field: 'plName',
      editable: !this.props.tariffOptionsDataStore.readOnlyView,
      cellEditor: PredictiveSelectCellEditor,
      // valueGetter: (params) => {
      //   return this.props.tariffOptionsDataStore.pipelineAreasDropdown.find(x=>x.pipelineID===params.data?.tariffPLID)?.plSysCode
      // },
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      cellEditorParams: (params) => {
        return {
            dropDownValues: this.props.tariffOptionsDataStore.pipelineAreasDropdown.map(x=>x.plSysCode),
            elementName: 'Pipeline Area'
        };
      },
      singleClickEdit: true, 
      valueFormatter:  (params) => {
        return params.data.plName? params.data.plName : this.props.tariffOptionsDataStore.pipelineAreasDropdown?.length>0 ? 'Select Pipeline Area': 'No Pipelines Found'  
      },
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Effective Date',
      field: 'effectiveDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (this.props.tariffOptionsDataStore.ctsEffectiveDate === null) {
            return '';
        } else {
            return moment(this.props.tariffOptionsDataStore.ctsEffectiveDate).format(AppConstant.DATE_FORMAT);
        }
      }, 
      flex: 1
    },
    {
      headerName: 'Update Date',
      field: 'updateDate',
      editable: false,
      minWidth: 130,
      flex: 1,
      valueGetter: params => {
        if (params.data['updateDate'] === null) {
            return '';
        } else {
            return moment(params.data['updateDate']).format(AppConstant.DATE_FORMAT);
        }
      }},
    {
      headerName: 'Update User',
      field: 'updateUser',
      editable: false,
      minWidth: 130,
      flex: 1
    }
  ];

  getGridConfigPipelineArea(gridId) {
    const {
      tariffOptionsDataStore: { agGridServicePipeline }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.PipelineAreaData.length && tariffOptionsDataStore.PipelineAreaData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefPipelineArea,
      domLayout: 'autoHeight', 
      onCellEditingStopped: (params) => {
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index] = {...this.props.tariffOptionsDataStore.modifedRows[params.data?.index], pipelineArea: [...(this.props.tariffOptionsDataStore.modifedRows[params.data.index]?.pipelineArea||[])]}
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index]['pipelineArea'] = [...this.props.tariffOptionsDataStore.modifedRows[params.data.index]['pipelineArea'], params.data?.rowId] 
        // tariffOptionsDataStore.modifedRows['pipelineArea'] = [...tariffOptionsDataStore.modifedRows['pipelineArea'], params.data?.rowId] 
        this.props.tariffOptionsDataStore.agGridServicePipeline.redrawRows();
      },// onCellEditingStopped: this.onCellEditingStopped
      context : agGridServicePipeline     
      };
    return agGridServicePipeline.getGridConfig(gridParams, gridId);
  }
  colDefOwner: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => { return !params.data.isCloned},
      colId:'Select',
      width: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'System',
      field: 'system',
      editable: false,
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Owner',
      field: 'custName',
      minWidth: 130,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      editable: !this.props.tariffOptionsDataStore.readOnlyView,
      cellEditor: PredictiveSelectCellEditor,
      singleClickEdit: true, 
      cellEditorParams: (params) => {
        return {
            dropDownValues: this.props.tariffOptionsDataStore.ownerDropdownForOwner[params.data.index]?.map(x=>x.customerName),
            elementName: 'Owner'
        };
      },
      valueFormatter:  (params) => {
        return params.data.custName? params.data.custName : this.props.tariffOptionsDataStore.ownerDropdownForOwner[params.data.index]?.length>0 ? 'Select Owner': 'No Owner Found'  
      },
      valueSetter: (params) => {
        if(params.newValue!=='Unselect Owner'){
          params.data.custName=params.newValue;
          return true
        } else {
          params.data.custName='';
          return true
        }
      },  
      flex: 1
    }
  ];
  getGridConfigOwner(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceOwner }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.OwnerData.length && tariffOptionsDataStore.OwnerData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefOwner,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      onCellEditingStopped: (params) => {
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index] = {...this.props.tariffOptionsDataStore.modifedRows[params.data?.index], owner: [...(this.props.tariffOptionsDataStore.modifedRows[params.data.index]?.owner||[])]}
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index]['owner'] = [...this.props.tariffOptionsDataStore.modifedRows[params.data.index]['owner'], params.data?.rowId] 
        // tariffOptionsDataStore.modifedRows[params.data.index]['owner'] = [...tariffOptionsDataStore.modifedRows[params.data.index]['owner'], params.data?.rowId] 
        this.props.tariffOptionsDataStore.agGridServiceOwner.redrawRows();
      },
      context : agGridServiceOwner     
      };
    return agGridServiceOwner.getGridConfig(gridParams, gridId);
  }
  colDefRelatedTariff: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => { return !params.data.isCloned},
      colId:'Select',
      width: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Relationship',
      field: 'relationship',
      cellEditor: PredictiveSelectCellEditor,
      singleClickEdit: true,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      cellEditorParams: (params) => {
        return {
          dropDownValues: ['Unselect Relationship', ...this.props.tariffOptionsDataStore.tariffRelationTypes.map(x=>x.valueText)]
        };
      },
      valueFormatter:  (params) => {
        return params.data.relationship? params.data.relationship : this.props.tariffOptionsDataStore.tariffRelationTypes?.length>0 ? 'Select Relationship': 'No Relationship Found'  
      },
      valueSetter: (params) => {
        if(params.newValue!=='Unselect Relationship'){
          params.data.relationship=params.newValue;
          return true
        } else {
          params.data.relationship='';
          return true
        }
      },  
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      minWidth: 130,
      flex: 1
    },
    {
      headerName: 'Agency',
      field: 'agencyName',
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      flex: 1,
      cellEditor: PredictiveSelectCellEditor,
      singleClickEdit: true,
      cellEditorParams: (params) => {
        return {
            dropDownValues: ['Unselect Agency', ...this.props.tariffOptionsDataStore.activeAgencyDropdown.map(x=>x.agencyname)]
        };
      },
      valueFormatter:  (params) => {
        return params.data.agencyName? params.data.agencyName : this.props.tariffOptionsDataStore.tariffRelationTypes?.length>0 ? 'Select Agency': 'No Agencies Found'  
      },
      valueSetter: (params) => {
        if(params.newValue!=='Unselect Agency'){
          params.data.agencyName=params.newValue;
          return true
        } else {
          params.data.agencyName='';
          return true
        }
      },  
    },
    {
      headerName: 'Number',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      field: 'number',
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      minWidth: 130,
      flex: 1,
      singleClickEdit: true,
      cellEditorSelector: params => {

        if (params.data?.relationship && params.data?.relationship === 'General Rules/Regulations') {
          const agency = this.props.tariffOptionsDataStore.agencyDropdown.find((item) => item.agencyname === params.data.agencyName);
          return {
            component: PredictiveSelectCellEditor,
            params: {
              dropDownValues: ['Unselect Number', ...this.props.tariffOptionsDataStore.rulesRegTariffNumberOption.filter((y) => y.agencyID === agency.agencyid).map(x=>x.tariffNo)]
            }
          }
        }
      },
      // cellEditor: (params) => params.data?.relationship && params.data?.relationship === 'General Rules/Regulations' ?  PredictiveSelectCellEditor : 'agTextCellEditor',
      // cellEditorParams: (params) => { 
      //   return params.data?.relationship && params.data?.relationship === 'General Rules/Regulations' ? {
           
      //   } : {value: params.data.number};
      // },
      valueFormatter:  (params) => {
        return params.data.number? params.data.number : params.data.relationship && params.data.relationship === 'General Rules/Regulations' ? this.props.tariffOptionsDataStore.tariffRelationTypes?.length>0 ? 'Select Number': 'No Number Found' : ''  
      },
      valueSetter: (params) => {
        if(params.newValue!=='Unselect Number'){
          params.data.number=params.newValue;
          return true
        } else {
          params.data.number='';
          return true
        }
      },  
      // valueSetter: (params) => {
      //   if(params.newValue.match(/^[0-9.]*$/)){
      //     params.data.number=params.newValue.toUpperCase();
      //     return true
      //   } else {
      //     return false
      //   }
      // },
    },
    {
      headerName: 'Effective Date',
      field: 'effectiveDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['effectiveDate'] === null) {
            return '';
        } else {
            return moment(params.data['effectiveDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
      flex: 1
    },
    {
      headerName: 'Update Date',
      field: 'updateDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['updateDate'] === null) {
            return '';
        } else {
            return moment(params.data['updateDate']).format(AppConstant.DATE_FORMAT);
        }
      },
      flex: 1
    },
    {
      headerName: 'Update User',
      field: 'updateUser',
      editable: false,
      minWidth: 130,
      flex: 1,
    }
  ];

  colDefComments: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => { return !params.data.isCloned},
      colId:'Select',
      width: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Comments',
      field: 'comments',
      cellClass: (params) => {return this.props.tariffOptionsDataStore.modifedRows[params.data.index] && this.props.tariffOptionsDataStore.modifedRows[params.data.index]['comments']?.includes(params.data.rowId)? 'cell-overridden-yellow' :params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data && !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())?  'cell-editable' : ''},
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      minWidth: 130,
      flex: 4
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['createDate'] === null) {
            return '';
        } else {
            return moment(params.data['createDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
    },
    {
      headerName: 'Created User',
      field: 'createUser',
      editable: false,
      minWidth: 130,
    }
  ];

  colDefCTSComments: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => { return !params.data.isCloned},
      colId:'Select',
      width: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: false,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
      headerName: 'Comments',
      field: 'comments',
      cellClass: (params) => {return this.props.tariffOptionsDataStore.modifedRows[params.data.index] && this.props.tariffOptionsDataStore.modifedRows[params.data.index]['ctsComments']?.includes(params.data.rowId)? 'cell-overridden-yellow' :params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data && !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())?  'cell-editable' : ''},
      editable: () => {return !(!tariffOptionsDataStore.isCTSCommetsEnabled || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      minWidth: 130,
      flex: 4
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      editable: false,
      minWidth: 130,
      valueGetter: params => {
        if (params.data['createDate'] === null) {
            return '';
        } else {
            return moment(params.data['createDate']).format(AppConstant.DATE_FORMAT);
        }
      }, 
    },
    {
      headerName: 'Created User',
      field: 'createUser',
      editable: false,
      minWidth: 130,
    }
  ];

  getGridConfigComments(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceComments }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.CommentsData.length && tariffOptionsDataStore.CommentsData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefComments,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      onCellEditingStopped: (params) => {
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index] = {...this.props.tariffOptionsDataStore.modifedRows[params.data?.index], comments: [...(this.props.tariffOptionsDataStore.modifedRows[params.data.index]?.comments||[])]}
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index]['comments'] = [...this.props.tariffOptionsDataStore.modifedRows[params.data.index]['comments'], params.data?.rowId] 
        // tariffOptionsDataStore.modifedRows[params.data.index]['comments'] = [...tariffOptionsDataStore.modifedRows[params.data.index]['comments'], params.data?.rowId] 
        this.props.tariffOptionsDataStore.agGridServiceComments.redrawRows();
      },
      context : agGridServiceComments     
    };
    return agGridServiceComments.getGridConfig(gridParams, gridId);
  }

  getGridConfigCTSComments(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceCTSComments }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.CTSCommentsData.length && tariffOptionsDataStore.CTSCommentsData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefCTSComments,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      onCellEditingStopped: (params) => {
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index] = {...this.props.tariffOptionsDataStore.modifedRows[params.data?.index], ctsComments: [...(this.props.tariffOptionsDataStore.modifedRows[params.data.index]?.ctsComments||[])]}
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index]['ctsComments'] = [...this.props.tariffOptionsDataStore.modifedRows[params.data.index]['ctsComments'], params.data?.rowId] 
        // tariffOptionsDataStore.modifedRows[params.data.index]['ctsComments'] = [...tariffOptionsDataStore.modifedRows[params.data.index]['ctsComments'], params.data?.rowId] 
        this.props.tariffOptionsDataStore.agGridServiceCTSComments.redrawRows();
      },
      context : agGridServiceCTSComments     
    };
    return agGridServiceCTSComments.getGridConfig(gridParams, gridId);
  }

  getGridConfigRelatedTariff(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceRelatedTariff }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.RelatedTariffData.length && tariffOptionsDataStore.RelatedTariffData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefRelatedTariff,
      domLayout: 'autoHeight', 
      onCellEditingStopped: async (params) => {
        const col = params.colDef.field || '';
        if(col==='agencyName'){
          params.data.number = null;
          await this.props.tariffOptionsDataStore.getTariffNumberOption(params.value);
        }
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index] = {...this.props.tariffOptionsDataStore.modifedRows[params.data?.index], relatedTariff: [...(this.props.tariffOptionsDataStore.modifedRows[params.data.index]?.relatedTariff||[])]}
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index]['relatedTariff'] = [...this.props.tariffOptionsDataStore.modifedRows[params.data.index]['relatedTariff'], params.data?.rowId] 
    //  tariffOptionsDataStore.modifedRows[params.data.index]['relatedTariff'] = [...tariffOptionsDataStore.modifedRows[params.data.index]['relatedTariff'], params.data?.rowId] 
        this.props.tariffOptionsDataStore.agGridServiceRelatedTariff.redrawRows();
      },
      context : agGridServiceRelatedTariff     
    };
    return agGridServiceRelatedTariff.getGridConfig(gridParams, gridId);
  }

  getGridConfigDeduction(gridId) {
    const {
      tariffOptionsDataStore: { agGridServiceDeduction }
    } = this.props;
    const gridParams: any = {
      rowData: tariffOptionsDataStore.DeductionData.length && tariffOptionsDataStore.DeductionData[tariffOptionsDataStore.selectedTab]||[],
      columnDefs:this.colDefDeduction,
      domLayout: 'autoHeight', // onCellEditingStopped: this.onCellEditingStopped
      onCellEditingStopped: (params) => {
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index] = {...this.props.tariffOptionsDataStore.modifedRows[params.data?.index], deduction: [...(this.props.tariffOptionsDataStore.modifedRows[params.data.index]?.deduction||[])]}
        this.props.tariffOptionsDataStore.modifedRows[params.data?.index]['deduction'] = [...this.props.tariffOptionsDataStore.modifedRows[params.data.index]['deduction'], params.data?.rowId] 
    //  tariffOptionsDataStore.modifedRows[params.data.index]['deduction'] = [...tariffOptionsDataStore.modifedRows[params.data.index]['deduction'], params.data?.rowId] 
        this.props.tariffOptionsDataStore.agGridServiceDeduction.redrawRows();
      },
      context : agGridServiceDeduction     
      };
    return agGridServiceDeduction.getGridConfig(gridParams, gridId);
  }

  selectTab(x,index){
    if(x==='new'){
      if(tariffOptionsDataStore.formData.length===0){
        tariffOptionsDataStore.addNewTariffOption();
      }else{
      tariffOptionsDataStore.cloneNewTariff[index]= true;
      document.addEventListener("mousedown", this.handleClickOutside);
      } 
    }else{
      tariffOptionsDataStore.cloneNewTariff[index]= false;
      tariffOptionsDataStore.selectedTab=x;
     };
      tariffOptionsDataStore.screenHeaderTabSelected.push(x);
  }

  getPlusDimensions(){
    var x= document.getElementById('cloneNew')?.getBoundingClientRect();
    console.log(x);
    return x?.x || 0;
  }

  findTariffLogDetail(){
    return tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.find(x=>x.tariffNumber===tariffOptionsDataStore.formDataSelection?.tariffLog && x.tariffLogID === tariffOptionsDataStore.tariffLogId);
  } 
 
  addLossAllowance(index){
    this.props.tariffOptionsDataStore.addLossAllowance(index)
  }
 
  addConnectionPoint(index){
    this.props.tariffOptionsDataStore.addConnectionPoint(index)
  }

  addMovement(index){
    this.props.tariffOptionsDataStore.addMovement(index)
  }

  
  isValidDate(d) {
    return d instanceof Date;
  }
  
  cloneContent(index, type, tariffOptionID) {
    this.props.tariffOptionsDataStore.cloneTariffOption(tariffOptionID,type);
    tariffOptionsDataStore.cloneNewTariff = {};
  }

  removeOption(tariffOptionID){
    const confirmService = new ConfirmService();
    confirmService.showConfirmDialog(
      () => {this.props.tariffOptionsDataStore.deleteTariffOptions(tariffOptionID);
        tariffOptionsDataStore.cloneNewTariff = {}},
      'Delete Tariff Option',
      'Are you sure you want to delete the selected Tariff Option?'
    );
  }

  
  cancelOption(tariffOptionID){
    const confirmService = new ConfirmService();
    confirmService.showConfirmDialog(
      () => {this.props.tariffOptionsDataStore.cancelTariffOptions(tariffOptionID);
        tariffOptionsDataStore.cloneNewTariff = {}},
      'Cancel Tariff Option',
      'Are you sure you want to cancel the selected Tariff Option?'
    );
  }

  
  changePipelineWarning(value, key, index){
    if(this.props.tariffOptionsDataStore.formData[index]['new']!==true){
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
        () => this.props.tariffOptionsDataStore.changeFormData(value, key, index),
        'Delete All Related Data',
        'Modifying the Pipeline Area Would Erase All Related Data from All the Below Grids on Save?'
      );
    }else{
      this.props.tariffOptionsDataStore.changeFormData(value, key, index);
    }
  }

  changeTabWarning(callbackfn, ...args){
      if(tariffOptionsDataStore.showUnsavedWarningAlert){
      const confirmService = new ConfirmService();
      confirmService.showConfirmDialog(
        () => {tariffOptionsDataStore.showUnsavedWarningAlert && callbackfn.call(...args)},
        'Warning',
        'You have unsaved data. Please save your data before switching tab/cloning options. Would you like to still continue?'
      );
      } else {
        callbackfn.call(...args)
      }
  }

  deleteDeduction = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceDeduction.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteDeduction(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  
  deleteRelatedTariff = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceRelatedTariff.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteRelatedTariff(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  deleteOwner = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceOwner.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteOwner(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  deletePipelineArea = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServicePipeline.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deletePipelineArea(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  
  deleteComments = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceComments.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteComments(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  onCloseTariffOption() {
    if(this.props.tariffOptionsDataStore.formData.some((x) => x?.type === 'Rates')){
      let data: any = [];
      this.props.tariffOptionsDataStore.formData.map((x, index) => {
        if( x?.type === 'Rates') {
          const filteredRelationshipData = this.props.tariffOptionsDataStore.relatedTariffData.length > 0 ? this.props.tariffOptionsDataStore.relatedTariffData[index].filter((item, i) => item.relationship === 'General Rules/Regulations') : []
          data = [...data, ...filteredRelationshipData];
        } else  {
          const relatedTariffData = this.props.tariffOptionsDataStore.relatedTariffData.length > 0 ? this.props.tariffOptionsDataStore.relatedTariffData[index] : [];
          data = [...data, ...relatedTariffData]
        }
      })
      if(data.length < this.props.tariffOptionsDataStore.relatedTariffData.length) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmWithCustomComponent(
              () => {},
              ConfirmWithOK,
              {secondaryText: 'A Related Rules and Regulations Tariff needs to be associated with a Rates Tariff on the Related Tariff Section.'} as ConfirmWithOKProps,
              'confirmPopup'
          );
      } 
     
    }
      if(this.props.tariffOptionsDataStore.from==='project-request-infrastructure' || this.props.tariffOptionsDataStore.from==='process-tariff-infrastructure'|| this.props.tariffOptionsDataStore.url) {
        history.push(this.props.tariffOptionsDataStore.url)
      } else {
        history.push('/TariffDashboard')
      }
   
  }

  deleteCTSComments = async (index) => {
    const { tariffOptionsDataStore } = this.props;
    const requestBody: any = await tariffOptionsDataStore.agGridServiceCTSComments.getSelectedRows();
    if(requestBody.length > 0) {
        console.log(requestBody);
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
          () => tariffOptionsDataStore.deleteCTSComments(requestBody, index),
          'Delete',
          'Are you sure you want to delete the selected record/s?'
      );
    } else {
      const confirmService = new ConfirmService();
      confirmService.showConfirmWithCustomComponent(
        () => {},
        ConfirmWithOK,
        {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
        'confirmPopup'
      );
    }
  }

  render(): React.ReactNode {
    const { tariffOptionsDataStore } = this.props;
    return (
      <div className='TariffOptionsBox'>
          <>
            {tariffOptionsDataStore.loader ? <div className="loader__overlay" /> : null}
            <div className="loader__icon">
                <ThreeDots height={80} width={80} color="green" ariaLabel="loading" visible = {tariffOptionsDataStore.loader}/>
            </div>
          </>
          <Prompt when={this.props.tariffOptionsDataStore.showUnsavedWarningAlert || (this.props.tariffOptionsDataStore.modifedRows && Object.keys(this.props.tariffOptionsDataStore.modifedRows).length > 0 && this.props.tariffOptionsDataStore.modifedRows[this.props.tariffOptionsDataStore.selectedTab] &&  Object.keys(this.props.tariffOptionsDataStore.modifedRows[this.props.tariffOptionsDataStore.selectedTab]).length > 0)} message={'You have unsaved changes, Are you sure you want to leave?'}/>
        {/* <Container fluid> */}
          <div className='boxes'>
            {/* <Prompt when={tariffOptionsDataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'} /> */}
            <div className='screenHeader'>
                <h2 className='screen_title'>Tariff Reference</h2>
            </div>
            <div className='selectContainer'>
              <div className='internalHeader' onClick={()=>tariffOptionsDataStore.toggleBaseTariffShow()}>
                <h6>Base Tariff </h6>
              </div>
              {tariffOptionsDataStore.baseTariffShow && (
                    <div style={{marginLeft: '10px'}}>
                      <Row>
                        <Col xs={4} style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Base Tariff</label>
                          <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formDataSelection?.baseTariff!=="" && {label:tariffOptionsDataStore.formDataSelection?.baseTariff, value: tariffOptionsDataStore.formDataSelection?.baseTariff} || ''}
                                placeholder={'Select Base Tariff'}
                                options={Array.isArray(tariffOptionsDataStore.baseTariffDropdown) && tariffOptionsDataStore.baseTariffDropdown.length && tariffOptionsDataStore.baseTariffDropdown.map(option=> {return{value: option.baseTariff, label: option.baseTariff }})||[]}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormDataSelection((e as Option).value, 'baseTariff')}}
                                isMulti={false}
                                isSearchable={true}
                                disabled = {tariffOptionsDataStore.readOnlyView}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Row> 
                            <Col style={{marginTop:'12.5px'}}>
                              <label className="standard_label_style">Jurisdiction</label>
                              <input
                                type="text"
                                className="search_text_field input"
                                readOnly={true}
                                value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.jurisdiction}
                              />
                            </Col>
                          </Row>
                          <Row>  
                            <Col style={{marginTop:'10px'}}>
                              <label className="standard_label_style">Tariff Agency</label>
                              <input
                                type="text"
                                className='search_text_field input'
                                readOnly={true}
                                value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.agencyName}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <Col style={{marginTop:'10px'}}>
                            <label className="standard_label_style">Tariff Type</label>
                            <input
                              type="text"
                              className="search_text_field input"
                              value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffType}
                              readOnly={true}
                            />
                            </Col>
                          </Row>
                          <Row>
                            <Col style={{marginTop:'10px'}}>
                              <label className="standard_label_style">Rules & Regulations</label>
                              <input
                                type="text"
                                className="search_text_field input"
                                value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.rulesReg}
                                readOnly={true}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={4} style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Pipeline Area</label>
                          <textarea
                            value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.pipeline}
                            className="search_text_field pipelineAreas"
                            readOnly={true}
                          />
                        </Col>
                      </Row>
                      <Row style={{marginTop:'0px'}}>
                        <Col style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Filing Carrier</label>
                          <input
                            type="text"
                            className="search_text_field input"
                            value={tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.filingCarrier}
                            readOnly={true}
                          />
                        </Col>
                        <Col></Col><Col></Col>
                      </Row>
                      <Row style={{ 'marginTop': '20px' }}>
                        <AgGridComponent gridConfig={this.getGridConfigJointCarrier('Tariff Option Joint Carrier')} />
                      </Row>
                    </div>)}
                  <div className='internalHeader' onClick={()=>tariffOptionsDataStore.toggleTariffLogShow()}>
                    <h6>Tariff Log </h6>
                  </div>
              {tariffOptionsDataStore.tariffLogShow && (
                  <div style={{ marginLeft: '10px' }}>
                    <Row>
                      <Col style={{marginTop:'10px'}} xs={4}>
                        <label className="standard_label_style">Tariff #</label>
                        <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formDataSelection?.tariffLog!=='' && {label:tariffOptionsDataStore.formDataSelection?.tariffLog, value: tariffOptionsDataStore.formDataSelection?.tariffLog} || ''}
                                placeholder={'Select Tariff Log'}
                                options={Array.isArray(tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs) && tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.length && tariffOptionsDataStore.selectedBaseTariffDetails[this.state.rowId]?.tariffLogs.map(option=> {return{value: option.tariffNumber, label: option.tariffNumber + ' '+ option.tariffLogVersion }}) ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormDataSelection((e as Option).value, 'tariffLog', 'noLoop', (e as Option).label)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled={tariffOptionsDataStore.formDataSelection.baseTariff==='' || tariffOptionsDataStore.readOnlyView}
                            />
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Log Status</label>
                        <input
                          type="text"
                          className="search_text_field input"
                          value={this.findTariffLogDetail()?.logStatus||''}
                          readOnly={true} />
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Tariff Issue Date</label>
                        <ReactDatePicker onDateChange={()=>{}} selectedDate={this.findTariffLogDetail()?.tariffIssueDate && new Date(this.findTariffLogDetail()?.tariffIssueDate)||null} disabled={true} />
                      </Col>
                    </Row>
                    <Row>
                     <Col style={{marginTop:'12.5px'}}>
                        <label className="standard_label_style">Vmacs Tariff #</label>
                        <input
                          type="text"
                          className="search_text_field input"
                          value={this.findTariffLogDetail()?.vmacsTariffNumber||''}
                          readOnly={true} 
                        />
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Index</label>
                          <div className="status-block">
                            <input
                              type="radio"
                              value="true"
                              checked={this.findTariffLogDetail()?.index === true}
                              readOnly={true}
                              disabled={true}/>Yes
                            <input
                              type="radio"
                              value="false"
                              checked={this.findTariffLogDetail()?.index === false}
                              readOnly={true} 
                              disabled={true}/>No
                          </div>
                      </Col> 
                      <Col style={{marginTop:'10px'}}>
                      <div>
                        <Col><label className="standard_label_style">Tariff Effective Date</label>
                        <ReactDatePicker onDateChange={()=>{}} selectedDate={this.findTariffLogDetail()?.tariffEffectiveDate && new Date(this.findTariffLogDetail()?.tariffEffectiveDate)||null} disabled={true} />
                        </Col>
                      </div>
                      </Col>
                    </Row>
                    <Row>
                    <Col style={{marginTop:'10px', display: 'inline-flex'}}>
                        <label className="standard_label_style">Cancels</label>
                        <div style={{display:'inline-flex'}}>
                        <input
                          type="text"
                          className="mainVersionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(0, this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/)))||''}
                          readOnly={true}
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/))).split(' ')[0]||''}
                          readOnly={true} 
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/))).split(' ')[1]||''}
                          readOnly={true} 
                        />
                        <input
                          type="text"
                          className="versionInput"
                          value={this.findTariffLogDetail()?.cancels?.slice(this.findTariffLogDetail()?.cancels?.indexOf(this.findTariffLogDetail()?.cancels?.match(/\d/))).split(' ')[2]||''}
                          readOnly={true} 
                        />
                      </div>
                      </Col>
                      <Col style={{marginTop:'10px'}}>
                          <label className="standard_label_style">Will it be tested</label>
                          <div className="status-block">
                            <input
                              type="radio"
                              value="true"
                              checked={this.findTariffLogDetail()?.testInd === true}
                              readOnly={true}
                              disabled={true}/>Yes
                            <input
                              type="radio"
                              value="false"
                              checked={this.findTariffLogDetail()?.testInd === false}
                              readOnly={true} 
                              disabled={true}/>No
                          </div>
                      </Col> 
                      <Col style={{marginTop:'10px'}}>
                        <label className="standard_label_style">CTS Effective Date</label>
                        <ReactDatePicker onDateChange={()=>{}} selectedDate={this.findTariffLogDetail()?.ctsEffectiveDate && new Date(this.findTariffLogDetail()?.ctsEffectiveDate)||null} disabled={true} />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={7} style={{marginTop:'10px'}}>
                        <label className="standard_label_style">Description</label>
                        <textarea
                          className="search_text_field input descriptionTariffLogArea"
                          value={this.findTariffLogDetail()?.description||''}
                          readOnly={true} />
                      </Col>
                    </Row>
                  </div>)}
            </div>
            <div className='screenHeader'>
                <h2 className='screen_title'>Tariff Option</h2>
            </div>
            <div className='selectContainer'>
            <div className="screenHeaderTabs">
              {tariffOptionsDataStore.formData.length && tariffOptionsDataStore.formData.map((x,index)=>(
                <div className={tariffOptionsDataStore.formData[index]?.action==='Z'?'screenheaderTabRed':tariffOptionsDataStore.formData[index]?.isChanged? 'screenheaderTabYellow':tariffOptionsDataStore.selectedTab===index?'screenheaderTabActive':tariffOptionsDataStore.screenHeaderTabSelected.includes(x)?'screenHeaderTabSelected':'screenHeaderTab'} style = {{fontWeight : tariffOptionsDataStore.selectedTab===index ? 'bold' : 'normal'}}>
                  <div style={{position:'relative'}}>
                    <div style={{display: 'inline-block', 'cursor': 'pointer', padding: '5px', minHeight: '35px', minWidth: '40px'}} onClick={()=>{this.changeTabWarning(this.selectTab, index, index)}}>{tariffOptionsDataStore.formData[index]?.agency + ' ' + (x?.value1 && x.value1 + ' '+ x.value2 + ' ' + x.value3 + ' ' + x.value4 || x.tariffNo)}</div>
                     {tariffOptionsDataStore.formData[index]['new']!==true && <button style={{float: 'right', display: 'inline-block', marginTop: '2.5px', marginLeft:'10px', border: '1px solid gray', textAlign: 'center', minWidth: '33px'}} disabled = {this.props.tariffOptionsDataStore.readOnlyView} onClick={()=>{this.selectTab('new', index)}}>...</button>}
                    {tariffOptionsDataStore.cloneNewTariff[index]===true && (<div ref={this.cloneBoxRef} style={{'textAlign': 'right', 'padding': '5px', 'width': '145px', 'border': '1px solid black', 'position': 'absolute', 'backgroundColor':'white', top: '37px', zIndex:999, right: this.getPlusDimensions() && this.getPlusDimensions()>800?'0px':'0px'}}>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.cloneContent(index, 'some', x.tariffOptionID)}>Clone</div>
                      <div style={{'cursor': 'pointer'}} onClick={()=>this.cloneContent(index, 'all', x.tariffOptionID)}>Clone All Content</div>
                      {tariffOptionsDataStore.formData[index]['isCloned']!==true && (<div style={{'cursor': 'pointer'}} onClick={()=>this.removeOption(x.tariffOptionID)}>Remove Option</div>)}
                      {tariffOptionsDataStore.formData[index]['isCloned'] ===true && (<div style={{'cursor': 'pointer'}} onClick={()=>this.cancelOption(x.tariffOptionID)}>Cancel Option</div>)}
                    </div>)||<></>}
                  </div>
                </div>))|| (<></>)}
              {!tariffOptionsDataStore.formData.length&&<div style={{'position':'relative'}}>
              <button className='screenHeaderTab' disabled={tariffOptionsDataStore.formDataSelection?.tariffLog==="" ||  this.props.tariffOptionsDataStore.readOnlyView} onClick={()=>{this.selectTab('new', 0)}} style={{marginLeft:'0px'}} id='cloneNew'>+</button>
              </div>}         
            </div>
            {tariffOptionsDataStore.formData.length && tariffOptionsDataStore.formData.map((x,index)=>(
            tariffOptionsDataStore.selectedTab===index && 
            (
            <div>
            <div className='internalHeader'>
              <h6>Ext System (VMACS) </h6>
            </div>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Agency</label>
                      <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.agency!=='' && {label:tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.agency, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].agency} || ''}
                                placeholder={'Select Agency'}
                                options={Array.isArray(tariffOptionsDataStore.activeAgencyDropdown) && tariffOptionsDataStore.activeAgencyDropdown.length && tariffOptionsDataStore.activeAgencyDropdown.map(option=> {return{value: option.agencycode, label: option.agencycode }}) ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormData((e as Option).value, 'agency', index)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true ||  tariffOptionsDataStore.readOnlyView}
                            />
              </Col>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Status</label>
                      <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.statusValue!=='' && {label:tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.statusValue, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.statusValue} || ''}
                                placeholder={'Select Status'}
                                options={Array.isArray(tariffOptionsDataStore.statusDropdown) && tariffOptionsDataStore.statusDropdown.length && tariffOptionsDataStore.statusDropdown.map(option=> {return{value: option.valueText, label: option.valueText }}) ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormData((e as Option).value, 'statusValue', index)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                            />
              </Col>
              <Col style={{'marginTop': '10px'}} xs={4}>
                      <label className='standard_label_style' style={{'minWidth':'unset'}}>Name</label>
                      <input
                      type='text'
                      className='inputLast search_text_field'
                      // onChange = {(e) => {}}
                      value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].name}
                      onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'name', index)}}
                      disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                      />
                      {/* <ReactDatePicker classNames='search_text_field' onDateChange={(e)=>{}} selectedDate={new Date()}/> */}
              </Col><Col></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}} xs={2}>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Tariff #</label>
                      <div style={{display:'inline-flex'}}>
                        <input
                          type="text"
                          className="mainVersionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value1 ||''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value?.toUpperCase(), 'value1', index)}}
                          readOnly={tariffOptionsDataStore.readOnlyView}
                          disabled
                        />
                        <input
                          type="text"
                          className="versionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value2 ||''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value?.toUpperCase(), 'value2', index)}}
                          readOnly={tariffOptionsDataStore.readOnlyView}
                          disabled
                        />
                        <input
                          type="text"
                          className="versionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value3 ||''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'value3', index)}}
                          readOnly={tariffOptionsDataStore.readOnlyView}
                         
                        />
                        <input
                          type="text"
                          className="versionInputTariff"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value4 || ''}
                          onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'value4', index)}}
                          readOnly={tariffOptionsDataStore.readOnlyView}
                        />
                        </div>
                        {/* <input type='text' className='search_text_field' value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value1&&tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value1+' '+tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value2+' '+tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value3+' '+tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].value4 || tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].tariffNo} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'tariffNo', index)}}/> */}
              </Col>
              <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Effective Date</label>
                      <ReactDatePicker classNames='search_text_field' 
                      disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true ||  tariffOptionsDataStore.readOnlyView}
                      onDateChange={(e)=>{tariffOptionsDataStore.changeFormData(e, 'effectiveDate', index)}} selectedDate={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].effectiveDate}/>
              </Col>
              <Col style={{'marginTop': '10px'}} xs={4}>
                      <label className='standard_label_style'>Administrator</label>
                      <ReactSelect
                                className='select inputLast'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.administrator!=='' && {label:tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.administrator, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.administrator} || ''}
                                placeholder={'Select Administrator'}
                                options={tariffOptionsDataStore.ownerDropdownForOwner[index] && Array.isArray(Object.values(tariffOptionsDataStore.ownerDropdownForOwner[index])) && (Object.values(tariffOptionsDataStore.ownerDropdownForOwner[index]).length && (Object.values(tariffOptionsDataStore.ownerDropdownForOwner[index])as any[])||[])?.map(option=> {return{value: option.customerName, label: option.customerName }}) ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormData((e as Option).value, 'administrator', index)}}
                                isMulti={false}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                                isSearchable={true}
                            />
              </Col><Col></Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
              <Col xs={'auto'}>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Type</label>
                      <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.type!=='' && {label:tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.type, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.type} || ''}
                                placeholder={'Select Type'}
                                options={Array.isArray(tariffOptionsDataStore.tariffTypesDropdown) && tariffOptionsDataStore.tariffTypesDropdown.length && tariffOptionsDataStore.tariffTypesDropdown.map(option=> {return{value: option.valueText, label: option.valueText }}) ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormData((e as Option).value, 'type', index)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Pipeline Areas</label>
                      <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea!=='' && {label:Array.isArray(tariffOptionsDataStore.pipelineAreasDropdown) && tariffOptionsDataStore.pipelineAreasDropdown.length && tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea} || ''}
                                placeholder={'Select Pipeline Area'}
                                options={Array.isArray(tariffOptionsDataStore.pipelineAreasDropdown) && tariffOptionsDataStore.pipelineAreasDropdown.length && tariffOptionsDataStore.pipelineAreasDropdown.map(option=> {return{value: option.plName, label: option.plName }}) ||  []}
                                onChange={(e)=>{this.changePipelineWarning((e as Option).value, 'pipelineArea', index)}}
                                isMulti={false}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                                isSearchable={true}
                            />
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Invoice Basis</label>
                      <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.invoiceBasis!=='' && {label:tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.invoiceBasis, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.invoiceBasis} || ''}
                                placeholder={'Select Invoice Basis'}
                                options={Array.isArray(tariffOptionsDataStore.invoiceBasisDropdown) && tariffOptionsDataStore.invoiceBasisDropdown.length && tariffOptionsDataStore.invoiceBasisDropdown.map(option=> {return{value: option.valueText, label: option.valueText }}) ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormData((e as Option).value, 'invoiceBasis', index)}}
                                isMulti={false}
                                isSearchable={true}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                            />
                    </Col>
                  </Row>
              </Col>
              <Col xs={'auto'}>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Tariff Issue Date</label>
                      <ReactDatePicker 
                      disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                      classNames='search_text_field' onDateChange={(e)=>{tariffOptionsDataStore.changeFormData(e, 'tariffIssueDate', index)}} selectedDate={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].tariffIssueDate}/>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancel Date</label>
                      <ReactDatePicker classNames='search_text_field' onDateChange={(e)=>{tariffOptionsDataStore.changeFormData(e, 'cancelDate', index)}} selectedDate={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].cancelDate}/>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Cancels Tariff</label>
                      <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.cancelTariff!=='' && {label:tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.cancelTariff, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.cancelTariff} || ''}
                                placeholder={'Select Cancel Tariffs'}
                                options={Array.isArray(tariffOptionsDataStore.cancelledTariffDropdown) && tariffOptionsDataStore.cancelledTariffDropdown.length && [{value: 'None', label: 'None'}, ...tariffOptionsDataStore.cancelledTariffDropdown.map(option=> {return{value: option, label: option }})] ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormData((e as Option).value, 'cancelTariff', index)}}
                                isMulti={false}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                                isSearchable={true}
                            />
                    </Col>
                  </Row>
              </Col>
              {/* <Col style={{'marginTop': '10px'}} xs={4}>
                      <div>
                        <label className='standard_label_style'>Comments</label>
                        <textarea className='search_text_field commentExtSytem' value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].comment} onChange={(e)=>{tariffOptionsDataStore.changeFormData(e.target.value, 'comment')}}/>
                      </div>
              </Col> */}
               <Col style={{'marginTop': '10px'}} xs={4}>
                      <label className='standard_label_style'>VMACS Tariff #</label>
                      <input
                          type="text"
                          className="select"
                          value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.agency + ' ' + tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].tariffNo||''}
                          readOnly={true}
                        />
              </Col>
            </Row>
            <Row style={{'marginTop': '0px', 'marginLeft': '0px'}}>
                    <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Company</label>
                      <ReactSelect
                                className='search_text_field'
                                values={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.companyValue!=='' && {label:tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.companyValue, value: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.companyValue} || ''}
                                placeholder={'Select Company'}
                                options={tariffOptionsDataStore.ownerDropdownForOwner[index] && Array.isArray(tariffOptionsDataStore.ownerDropdownForOwner[index]) && tariffOptionsDataStore.ownerDropdownForOwner[index].length && tariffOptionsDataStore.ownerDropdownForOwner[index]?.map(option=> {return{value: option.customerName, label: option.customerName }}) ||  []}
                                onChange={(e)=>{tariffOptionsDataStore.changeFormData((e as Option).value, 'companyValue', index)}}
                                isMulti={false}
                                disabled={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.isCloned===true || tariffOptionsDataStore.readOnlyView}
                                isSearchable={true}
                            />
                    </Col>
              {/* <Col style={{'marginTop': '10px'}} xs={'auto'}>
                      <label className='standard_label_style'>Synced</label>
                      <input className='select' disabled onChange={(e)=>tariffOptionsDataStore.changeFormData(e.target.value, 'syncStatus', index)} value={tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab].syncStatus}/>
              </Col> */}
            </Row>
            <Row>
              <div className="rightFlex" style={{'marginTop':'0px'}}>
                  <CustomButton type={CustomButtonType.Submit} onClick={()=>{tariffOptionsDataStore.saveTariffOption(index, x.tariffOptionID)}} disabled ={tariffOptionsDataStore.readOnlyView}>
                      Save
                  </CustomButton>
              </div>
            </Row>
            <div className='internalHeader'>
              <h6>Comments</h6>
            </div>
             <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.addComments(index)}}>
                  Add Comment
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.saveComments(index, x.tariffOptionID)}}>
                  Save Comments 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.deleteComments(index)}}>
                  Delete Comment 
                  </CustomButton>
              </div>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigComments('Tariff Option Comments')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Movement</h6>
            </div>
            <Movement addMovement={(index)=>this.addMovement(index)} tariffOptionsDataStore={tariffOptionsDataStore} index={index} tariffOptionID={x.tariffOptionID}/>
            <div className='internalHeader'>
              <h6>Connection Point</h6>
            </div>
            <ConnectionPoint addConnectionPoint={(index)=>this.addConnectionPoint(index)} tariffOptionsDataStore={tariffOptionsDataStore} index={index} tariffOptionID={x.tariffOptionID}/>
            <div className='internalHeader'>
              <h6>Pipeline Area</h6>
            </div>
            <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView} onClick={()=>{tariffOptionsDataStore.addPipelineArea(index)}}>
                  Add Pipeline Area 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView} onClick={()=>{tariffOptionsDataStore.savePipelineArea(index, x.tariffOptionID)}}>
                  Save Pipeline Area 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView} onClick={()=>{this.deletePipelineArea(index)}}>
                  Delete Pipeline Area 
                  </CustomButton>
              </div>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigPipelineArea('Tariff Option Pipeline Area')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Owner</h6>
            </div>
            <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView} onClick={()=>{tariffOptionsDataStore.addOwner(index)}}>
                  Add Owner
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView} onClick={()=>{tariffOptionsDataStore.saveOwner(index, x.tariffOptionID)}}>
                  Save Owner
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView} onClick={()=>{this.deleteOwner(index)}}>
                  Delete Owner
                  </CustomButton>
              </div>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigOwner('Tariff Option Owners')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Related Tariff</h6>
            </div>
            <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.addRelatedTariff(index)}}>
                  Add Related Tariff
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.saveRelatedTariff(index, x.tariffOptionID)}}>
                  Save Related Tariff
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.deleteRelatedTariff(index)}}>
                  Delete Related Tariff
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={tariffOptionsDataStore.formData[index].type!=='Rates'||this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.addRelatedTariff(index, true)}}>
                  Link Tariff
                  </CustomButton>
              </div>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigRelatedTariff('Tariff Option Related Tariffs')}/>
            </Row>
            <div className='internalHeader'>
              <h6>Loss Allowance</h6>
            </div>
            <LossAllowance addLossAllowance={(index)=>this.addLossAllowance(index)} tariffOptionsDataStore={tariffOptionsDataStore} index={index} tariffOptionID={x.tariffOptionID}/>
            {tariffOptionsDataStore.addLossAllowance('blank') && <></>}
            <div className='internalHeader'>
              <h6>Deduction</h6>
            </div>
            <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.addDeduction(index)}}>
                  Add Deduction
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView  || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.saveDeduction(index, x.tariffOptionID)}}>
                  Save Deduction
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView  || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.deleteDeduction(index)}}>
                  Delete Deduction
                  </CustomButton>
              </div>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigDeduction('Tariff Option Deductions')}/>
            </Row>
            <div className='internalHeader'>
              <h6>CTS Comments</h6>
            </div>
            <Row style={{margin: '10px'}}>
              <Col style={{display: 'flex', maxWidth: 'unset', alignItems: 'center'}}>
                  <label className="standard_label_style">Purpose:</label>
                  <textarea
                    style={{marginLeft: '20px', borderRadius: '5px', width: '100%', height: '120px', border: '1px solid #a3d0e4'}} 
                    value={this.props.tariffOptionsDataStore.purpose[index]}
                    onChange={(e) => this.props.tariffOptionsDataStore.changePurpose(e.target.value, index)}
                    disabled={!tariffOptionsDataStore.isCTSCommetsEnabled || tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                  />
              </Col>
            </Row>
             <Row>
              <div className="rightFlex">
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || !tariffOptionsDataStore.isCTSCommetsEnabled || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.addCTSComments(index)}}>
                  Add Comment
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || !tariffOptionsDataStore.isCTSCommetsEnabled || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{tariffOptionsDataStore.saveCTSComments(index, x.tariffOptionID)}}>
                  Save Comments 
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[index]['new']===true || !tariffOptionsDataStore.isCTSCommetsEnabled || tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.deleteCTSComments(index)}}>
                  Delete Comment 
                  </CustomButton>
              </div>
            </Row>
            <Row>
              <AgGridComponent gridConfig={this.getGridConfigCTSComments('Tariff Option CTS Comments')}/>
            </Row>
            <Row>
              <div className="rightFlex">
                <CustomButton type={CustomButtonType.Submit} onClick={() => { this.props.tariffOptionsDataStore.saveAllData(index, x.tariffOptionID)}} disabled = {this.props.tariffOptionsDataStore.formData[index]['new']===true || tariffOptionsDataStore.readOnlyView}>
                    Save All
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.onCloseTariffOption()}}>
                  Close
                  </CustomButton>
              </div>
              </Row>
            </div>)))||<></>}
            </div>
            {tariffOptionsDataStore.formData.length===0&&
              <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.props.tariffOptionsDataStore.from==='project-request-infrastructure' || this.props.tariffOptionsDataStore.from==='process-tariff-infrastructure' || this.props.tariffOptionsDataStore.url ?history.push(this.props.tariffOptionsDataStore.url):history.push('/TariffDashboard')}}>
              Close
              </CustomButton>}  
            {tariffOptionsDataStore.agGridServiceConnectionPoint && <></>}
            {tariffOptionsDataStore.agGridServiceMovement && <></>}
            {tariffOptionsDataStore.agGridServiceOwner && <></>}
            {tariffOptionsDataStore.agGridServicePipeline && <></>}
            {tariffOptionsDataStore.agGridServiceComments && <></>}
            {tariffOptionsDataStore.agGridServiceDeduction && <></>}
            {tariffOptionsDataStore.agGridServiceLossAllowance && <></>}
            {tariffOptionsDataStore.agGridServiceRelatedTariff && <></>}  
            {tariffOptionsDataStore.readOnlyView && <></>}
            {tariffOptionsDataStore.isCreateNewEntityDisabled && <></>}
          </div>
        {/* </Container> */}
      </div>
    )
  }
}
