import { FilingCarrierMaintenanceDataStore } from './filing-carrier-maintenance-data-store';
import { filingCarrierMaintenanceDataService } from '../services/filing-carrier-maintenance-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { accountStore } from '../../../account/stores';
import { AddEditFilingCarrierMaintenanceStore } from './add-edit-filing-carrier-maintenance-data-store';

export const agGridServiceForAddOrEditLocation = new AgGridService();
export const agGridServiceForAddOrEditLinker = new AgGridService();

export const filingCarrierMaintenanceDataStore = new FilingCarrierMaintenanceDataStore(filingCarrierMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLocation);
export const addEditFilingCarrierMaintenanceDataStore = new AddEditFilingCarrierMaintenanceStore(filingCarrierMaintenanceDataService, agGridService, uiService, accountStore, agGridServiceForAddOrEditLinker);