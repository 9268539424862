import React from 'react';
// import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { homeDashboardDataStore } from '../../stores';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';



export const TariffNoCellRenderer = (props: ICellRendererParams) => {    
    return (

       homeDashboardDataStore.isTariffOptionViewDisabled() || props.data.action === 'D' ? 
        <span>{props.data.refTariffNo}</span> : 
            <span style={{color: 'blue', width: '60px', cursor:'pointer'}}
            onClick={()=>homeDashboardDataStore.onClickTariffNo(props)}>
                {props.data.refTariffNo ==undefined?'0000000':props.data.refTariffNo }</span>
    );
};
