import * as React from 'react';
import { toast, ToastOptions } from 'react-toastify';
import { CustomToast, CustomToastType, ToastMessage } from './custom-toast';

export class ToastService {
    success(text: string, options: ToastOptions = {}, errors: ToastMessage[] = []): React.ReactNode {
        return toast((props) => <CustomToast type={CustomToastType.SUCCESS} text={text} errors={errors} closeToast={props.closeToast}/>, {
            ...options
        });
    }

    error(text = '', options: ToastOptions = {}, errors: ToastMessage[] = []): React.ReactNode {
        return toast((props) => <CustomToast type={CustomToastType.ERROR} text={text} errors={errors} closeToast={props.closeToast}/>, {
            ...options
        });
    }

    info(text: string, options: ToastOptions = {}, errors: ToastMessage[] = []): React.ReactNode {
        return toast((props) => <CustomToast type={CustomToastType.INFO} text={text} errors={errors} closeToast={props.closeToast}/>, {
            ...options
        });
    }
}
