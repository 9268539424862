import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import {
    ColDef
} from 'ag-grid-community';
import momenttimezone from 'moment-timezone';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import { AgGridComponent } from 'common/components/ag-grid-component';
import history from 'shared/components/header-component/component/history';
import { logEntryDataStore } from '../stores';
import { TariffLogEntry } from '../constant/enums';


interface workflowDetailsInterface {
    datastore: any;
    stepName: string;
    saveLogEntry: (buttonTitle) => void;
    cancelEditing: () => void;
    createNewLogEntry: () => void;
    saveProjectName : (button) => void;
    isSaveDisabled : () => boolean;
    isCommentDisabled : () => boolean;
}


export const WorkflowDetails = (props: workflowDetailsInterface) => {

    const gridConfig: ColDef[] = [
    {
          headerName: 'Steps',
          field: 'workflowstepName',
          tooltipField: 'workflowstepName',
          filter: true,
          editable: false,
          sortable: true,
          minWidth: 150,
          singleClickEdit: true,
          flex: 2
      },
      {
          headerName: 'Status',
          field: 'workflowstepStatus',
          tooltipField: 'workflowstepStatus',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          singleClickEdit: true,
          suppressSizeToFit: true,
          flex: 1
      },
      {
          headerName: 'Comment',
          field: 'workflowStepComments',
          tooltipField: 'workflowStepComments',
          cellEditor: 'agSelectCellEditor',
          singleClickEdit: true,
          filter: true,
          sortable: true,
          editable: false,
          minWidth: 135,
          flex: 2
      },
      {
          headerName: 'Workflow Step Role',
          field: 'workflowstepRole',
          tooltipField: 'workflowstepRole',
          singleClickEdit: true,
          editable: false,
          minWidth: 120,
          suppressSizeToFit: false,
      },
      {
          headerName: 'Last Modified User *',
          field: 'lastModifiedUser',
          tooltipField: 'lastModifiedUser',
          singleClickEdit: true,
          editable: false,
          minWidth: 120,
          suppressSizeToFit: false,
      },
      {
          headerName: 'Last Modified Date',
          field: 'lastModifiedDate',
          tooltipField: 'lastModifiedDate',
          filter: true,
          sortable: true,
          editable: false,
          minWidth: 120,
          valueGetter: params => {
              if (params.data['lastModifiedDate'] === null) {
                  return '';
              } else {
                  return momenttimezone
                  .tz(moment.utc(params.data['lastModifiedDate']), AppConstant.CST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
              }
          },
          suppressSizeToFit: false,
      }
  ];

  function getGridConfig(gridId) {
    const {
        datastore: { agGridServiceForWorkflowDetails }
    } = props;
    const { datastore } = props;
    const rowData = datastore.WorkFlowDetailsDataGrid;
    const gridParams: any = {
      rowData,
      columnDefs: gridConfig,
      context : agGridServiceForWorkflowDetails
      };
    return agGridServiceForWorkflowDetails.getGridConfig(gridParams, gridId);
  }

  const saveButtonTitle = props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFilingIndex.toLowerCase() ? 'Complete & Close Index Log':
  props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFiling.toLowerCase() ? props.stepName && props.stepName === 'Complete Test Determination' ? props.stepName : props.stepName && props.stepName === 'Link Project' ? props.stepName :  logEntryDataStore.lastCompletedtWorkflowstepName === 'Link Project' ? 'Unlink/Link New Project' : 'Complete & Close Filing Log' : 
  props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.Maintenance.toLowerCase() ? props.stepName && props.stepName === 'Complete Test Determination' ? props.stepName : props.stepName && props.stepName === 'Link Project' ? props.stepName :  logEntryDataStore.lastCompletedtWorkflowstepName === 'Link Project' ? 'Unlink/Link New Project' : 'Complete & Close Maintenance Log':
  props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.Request.toLowerCase() ? 'Save/Submit' : '';

  const saveAndCreateNewButtonTitle = props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFilingIndex.toLowerCase() ? props.datastore.tariffLogId ? 'Create New Index Log Entry' : 'Complete & Create New Index Log' : 
  props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.TariffFiling.toLowerCase() ? props.datastore.tariffLogId ? 'Create New Filing Log Entry' : 'Complete & Create New Filing Log' : 
  props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.Maintenance.toLowerCase() ? props.datastore.tariffLogId ? 'Create New Maintenance Log Entry' : 'Complete & Create New Maintenance Log' : 
  props.datastore.selectedLogEntryType.toLowerCase() === TariffLogEntry.Request.toLowerCase() ? props.datastore.tariffLogId ? 'Create New Request Log Entry' : 'Complete & Create New Request Log' : '';

 
  
  return (
        <div className= 'workflowDetails'>
            <div className='screenHeader'>
                <h2 className='screen_title'> Workflow Details</h2>
            </div>
            <div className='selectContainer'>
                <Row>
                    <div className="rightFlex">
                        <div className="leftFlex">
                            <label className="standard_label_style">{props.datastore.statusOrHistory?'Workflow Status':'Workflow History'}</label>
                        </div>
                        <div className="rightFlexInner">
                            <CustomButton type={CustomButtonType.Submit} disabled={props.datastore.statusOrHistory} onClick={()=>{props.datastore.getWorkflowStatusGridData()}}>
                            Workflow Status
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} disabled={!props.datastore.statusOrHistory} onClick={()=>{props.datastore.getWorkflowHistoryStatusGridData()}}>
                            Workflow History
                            </CustomButton>
                        </div>
                    </div>
                </Row>
                <div className="flex">
                <div className="innerflexwidths">
                    <Row>
                        <AgGridComponent gridConfig={getGridConfig('Workflow Details')}/>
                    </Row>
                </div>
                <div className="innerflexwidths">
                    <div className="flexColumn">
                        <div style={{'width':'100%'}}>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '10px'}}>
                                    <label className="standard_label_style">Step Description:</label>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '5px'}}>
                                <label style={{'fontSize': '14px'}}>{props.datastore.stepDescription&&props.datastore.stepDescription||''}</label>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '10px'}} >
                                    <label className="standard_label_style">Comment:</label>
                                </Col>
                                </Row>
                                <Row style={{'marginTop': '0px'}}>
                                <Col style={{'marginTop': '5px'}}>
                                    <textarea
                                        style={{marginLeft: '20px', borderRadius: '5px', border: '1px solid #a3d0e4'}} 
                                        value={props.datastore.comment}
                                        onChange={(e)=>props.datastore.editComment(e.target.value)}
                                        readOnly={props.isCommentDisabled()}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="rightFlexButtons">
                             
                                <CustomButton type={CustomButtonType.Submit} onClick={() => { props.cancelEditing() }}>
                                  Cancel
                                </CustomButton>
                                {props.stepName === 'Link Project' && <CustomButton type={CustomButtonType.Submit} onClick={()=>{ props.datastore.rejectButtonClick()}}>
                                    Reject
                                </CustomButton>}
                                <CustomButton type={CustomButtonType.Submit} onClick={() => { (saveButtonTitle !== 'Link Project' && saveButtonTitle !== 'Unlink/Link New Project') ? props.saveLogEntry('Save') : props.saveProjectName(saveButtonTitle) }} disabled={props.isSaveDisabled()}>
                                  {saveButtonTitle}
                                </CustomButton>
                                <CustomButton type={CustomButtonType.Submit} onClick={() => { props.datastore.tariffLogId ? props.createNewLogEntry() : props.saveLogEntry('SaveAndCreateNew') }} disabled={logEntryDataStore.isEditDisabled()}>
                                    {saveAndCreateNewButtonTitle}
                                </CustomButton>
                            <></>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}