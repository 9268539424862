import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import {
    GridOptions,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/home-dashboard.scss';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { TariffDashboardDataStore } from 'features/tariff-management/traiff-dashboard/stores/tariff-dashboard-data-store';
import { projectDashboardDataStore } from 'features/project-management/project-dashboard/stores';
import { openRequestColDef } from 'features/project-management/project-dashboard/component/open-request-col-def';
import { HomeDashboardDataStore } from '../stores/home-screen-dashboard-data-store';
import { requestColDef } from './request-col-def';
import { tariffDashboardDataStore } from 'features/tariff-management/traiff-dashboard/stores';
import DatePicker from 'react-datepicker';
import { ReactSelect } from 'common/components/select/select';
import { tariffDashboardColDef } from 'features/tariff-management/traiff-dashboard/component/tariff-dashboard-col-def';
import { homeTariffLogColDef } from './tariff-option-col-def';
import defaultpageicon from '../../../../assets/home.png';
import { homeDashboardDataStore } from '../stores';
import { ProjectDashboardDataStore } from 'features/project-management/project-dashboard/stores/project-dashboard-data-store';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

/* eslint-disable */
interface HomeScreenDashboardProps {
    homeDashboardDataStore: HomeDashboardDataStore;
}

interface HomeScreenDashboardState {
    // search: string;
    // addRowClick: boolean;
    // lastAddedAgencyID: any;
}

@inject('homeDashboardDataStore')
@observer
export class HomeScreenDashboardContainer extends Component<HomeScreenDashboardProps, HomeScreenDashboardState> {
    constructor(props: HomeScreenDashboardProps | Readonly<HomeScreenDashboardProps>) {
        super(props);
        this.state = {
            // search: '',
            // addRowClick: false,
            // lastAddedAgencyID: 0
        };
        dataStore.setModuleName('Home Screen Dashboard')
    }
    
    componentDidMount() {
      
      const {homeDashboardDataStore} = this.props;
      homeDashboardDataStore.init();
      homeDashboardDataStore.agGridService.refreshGrid();
      homeDashboardDataStore.agGridServiceForOpenRequest.refreshGrid();
      history.push('/Dashboard');
    }

    componentWillUnmount() {
        // this.props.HomeDashboardDataStore.reset();
    }
    
    onBtClearSelection = () => {
    //   const { projectDashboardDataStore } = this.props;
      projectDashboardDataStore.clearSearch();
    }

    onSearchClick = () => {
    //   const { projectDashboardDataStore } = this.props;
      if(projectDashboardDataStore.endDateError === '') {
        projectDashboardDataStore.onSearchClick();
      }
    }

    onBtClearSelectionOption = () => {
      this.props.homeDashboardDataStore.onClearSearch();
      }
  
    onSearchClickOption = () => {
      this.props.homeDashboardDataStore.onSearchClick();
    }

      renderLogo(): React.ReactNode {
      
        return (
          <div className="header__logo">
             <img className="icon"  src={defaultpageicon} alt="DefaultPage" />

          </div>
        );
      }

      handleHideInactiveTariffs(e) {
        homeDashboardDataStore.selectedStatus = e.target.checked;
        if(e.target.checked === true) {
          homeDashboardDataStore.tariffOptionSearchData = homeDashboardDataStore.tariffOptionSearchData.filter((item) => item.action === 'A');
        } else {
          homeDashboardDataStore.tariffOptionSearchData = homeDashboardDataStore.backupTariffOptionSearchData;
        }
      }
    
    render(): React.ReactNode {
      const { homeDashboardDataStore } = this.props;
      return (
        <Container fluid>
          <div className='homeDashboardBox'>
            <div className='selectContainer'>
              <div className='screenHeader'>
                <h2 className='screen_title'>Home</h2>
              </div>
          
              <Tabs
                  defaultActiveKey="0"
                  id="uncontrolled-tab-example"
                  className="mb-3">
              
                  <Tab eventKey={0} title={this.renderLogo()} onClick={() =>  {homeDashboardDataStore.selectedTab = 'Home'}}>
                    <div className='selectContainer'>
                      <div className='subHeader'>
                          <h2 className='screen_title'>Action Center</h2>
                          
                      </div>
                      <div className='selectSecondContainer'>
                        <div style = {{ height : '200px' , overflowY: 'auto'}}>

                        
                        {homeDashboardDataStore.actionCenterData.map((action) => {
                            return <div className='actionCenterView'>
                               <span style={{color: 'blue', width: '150px', cursor:'pointer', borderBottom: '1px solid blue', borderColor: 'blue'}}
                                onClick={()=>homeDashboardDataStore.onClickActionItem(action)}>
                                    {action.link ==undefined?'0000000': action.logInfo && action.logInfo.length > 0 ? action.link.replaceAll(' ', '.') :  action.link }</span> 
                                    {action.description}
                                </div>
                          })}
                        </div>
                      </div>                 
                      <div className='subHeader'>
                          <h2 className='screen_title'>Open Projects Requests</h2>
                      </div>
                      <div className='selectSecondContainer'>
                          <AgGridComponent gridConfig={this.getGridConfigForOpenRequest('Project Dashboard Open Request')} />
                      </div>
                    </div>
                  </Tab>

                  <Tab eventKey={1} title={'Tariff Options Search'} onClick={() => { homeDashboardDataStore.selectedTab = 'Tariff option search'}}>
                    <div className='subHeader'>
                      <h2 className='screen_title'>Tariff Options Search</h2>
                    </div>
                    <div className='selectSecondContainer'>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Tariff Name / Description</label>
                          <input 
                            type="text"
                            value={homeDashboardDataStore.selectedTariffName}
                            className="search_text_field"
                            // disabled={true}
                            // style={{width: '20%'}}
                            onChange = {(e) => homeDashboardDataStore.selectedTariffName = e.target.value}
                          />
                        </Col>
                        <Col>
                          <input 
                            type="checkbox"
                            value={homeDashboardDataStore.selectedStatus}
                            checked={homeDashboardDataStore.selectedStatus}
                            className='checkBox'
                            // disabled={true}
                            // style={{width: '20%'}}
                            onChange = {(e) => this.handleHideInactiveTariffs(e) }
                          />
                          <label className="standard_label_style hideInactiveTariff">Hide Inactive Tariffs</label>
                        </Col>
                        <Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Agency</label>
                          <ReactSelect 
                            className='select'
                            values={{label: homeDashboardDataStore.selectedAgency, value: homeDashboardDataStore.selectedAgency}}
                            placeholder="Select"
                            options={[{value:'All', label:'All'},...homeDashboardDataStore.agencySelectOptionData]}
                            onChange={(e: any) => { e.value ?  homeDashboardDataStore.selectedAgency = e.value : null }}
                            isMulti={false}
                            isSearchable={true}
                          />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Pipelines/Area</label>
                          <ReactSelect 
                              className='select'
                              values={{label: homeDashboardDataStore.selectedPipelineSystem, value: homeDashboardDataStore.selectedPipelineSystem}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...homeDashboardDataStore.pipelineSelectOptionData]}
                              onChange={(e: any) => { e.value ?  homeDashboardDataStore.selectedPipelineSystem = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Type</label>
                          <ReactSelect 
                              className='select'
                              values={{label: homeDashboardDataStore.selectedTariffType, value: homeDashboardDataStore.selectedTariffType}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...homeDashboardDataStore.tariffTypeSelectOptionData]}
                              onChange={(e: any) => { e.value ?  homeDashboardDataStore.selectedTariffType = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label className="standard_label_style">Tariff No.</label>
                          <input 
                            type="text"
                            value={homeDashboardDataStore.selectedTariffNumber}
                            className="search_text_field"
                            // disabled={true}
                            // style={{width: '20%'}}
                            onChange = {(e) => homeDashboardDataStore.selectedTariffNumber = e.target.value}
                          />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Company</label>
                          <ReactSelect 
                              className='select'
                              values={{label: homeDashboardDataStore.selectedOwnerOrCarrier, value: homeDashboardDataStore.selectedOwnerOrCarrier}}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...homeDashboardDataStore.ownerCarrierSelectOptionData]}
                              onChange={(e: any) => { e.value ?  homeDashboardDataStore.selectedOwnerOrCarrier = e.value : null }}
                              isMulti={false}
                              isSearchable={true}
                            />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Reference Tariff No.</label>
                          <input 
                            type="text"
                            value={homeDashboardDataStore.selectedReferenceTariffNumber}
                            className="search_text_field"
                            // disabled={true}
                            // style={{width: '20%'}}
                            onChange = {(e) => homeDashboardDataStore.selectedReferenceTariffNumber = e.target.value}
                          />
                        </Col>
                      </Row>         

                      <div className='searchButtonDiv'>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClickOption} disabled= {false}>
                            Search
                        </CustomButton>
                        <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelectionOption} disabled= {false}>
                            Clear
                        </CustomButton>
                      </div>
                      {tariffDashboardDataStore.showLoader == true &&
                        <div className = 'alert-overlay'> 
                          <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                        </div> 
                      }
                      <AgGridComponent gridConfig={this.getGridConfigTariffLog('Tariff Dashboard')} />

                    </div>
                  </Tab>

                  <Tab eventKey={2} title={'Project Search'} onClick={() => { homeDashboardDataStore.selectedTab = 'Project Search'}}>
                      <div className='subHeader'>
                        <h2 className='screen_title'>Project Search</h2>
                      </div>
                      <div className='selectSecondContainer'>
                        <div className='settingSelectionDiv'>
                          <Row>
                              <Col>
                                <label className="standard_label_style">Project Type</label>
                                 <ReactSelect
                                  className='select'
                                  values={projectDashboardDataStore.selectedProjectRequestType}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...projectDashboardDataStore.projectRequestTypeDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.onSelectRequestType(e)}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                              </Col>
                              <Col>
                                <label className="standard_label_style">Pipeline Systems</label>
                                <ReactSelect
                                  className='select'
                                  values={projectDashboardDataStore.selectedPipelineSystem}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...projectDashboardDataStore.pipelineSystemsDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.selectedPipelineSystem = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                              </Col>
                              <Col>
                                  <label className="standard_label_style">Go Live Date</label>
                                  <input 
                                      type="date"
                                      value={projectDashboardDataStore.selectedGoLiveFromDate}
                                      className="goLiveInputs"
                                      onChange = {(e) => projectDashboardDataStore.onChangeFromDate(e.target.value)}
                                  />
                                  To
                                  <input 
                                      type="date"
                                      value={projectDashboardDataStore.selectedGoLiveToDate}
                                      className="goLiveInputs toDate"
                                      onChange = {(e) => projectDashboardDataStore.onChangeToDate(e.target.value)}
                                  />
                                  <div className='error'>{projectDashboardDataStore.endDateError} </div>
                              </Col>
                          </Row>
                        </div>
                        <div className='settingSelectionDiv'>
                          <Row>
                            <Col>
                              <label className="standard_label_style">Project Name</label>
                              <input 
                                  type="text"
                                  value={projectDashboardDataStore.selectedProjectName}
                                  className="search_text_field"
                                  onChange = {(e) => projectDashboardDataStore.onChangeProjectname(e.target.value)}
                              />
                            </Col>
                            <Col>
                              <label className="standard_label_style">Tariff</label>
                              <ReactSelect
                                  className='select'
                                  values={projectDashboardDataStore.selectedTraiff}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, { value: 'New', label: 'New' }, ...projectDashboardDataStore.tariffDataDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.selectedTraiff = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                            </Col>
                            <Col>
                              <label className="standard_label_style">Workflow Step</label>
                              <ReactSelect
                                  className={projectDashboardDataStore.selectedProjectRequestType.label !== 'All' ? "select workFlowStepSelect" : 'select workFlowStepSelect disabled'}
                                  values={projectDashboardDataStore.selectedWorkflowStatus}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...projectDashboardDataStore.workflowStepDropdownOptionsUI]}
                                  onChange={(e: any) => {projectDashboardDataStore.selectedWorkflowStatus = e}}
                                  isMulti={false}
                                  isSearchable={true}
                                  disabled={projectDashboardDataStore.selectedProjectRequestType.label === 'All'}
                              />
                            </Col>
                          </Row>
                        </div>
                        <div className='searchButtonDiv'>
                          <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                              Search
                          </CustomButton>
                          <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                              Clear
                          </CustomButton>
                        </div>
                        {projectDashboardDataStore.showLoader == true &&
                        <div className = 'alert-overlay'> 
                            <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                        </div> 
                        }
                        <AgGridComponent gridConfig={this.getGridConfig('Project Dashboard')} />
                        <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/>

                      </div>
                  </Tab>

              </Tabs>
            </div>
          </div>
        </Container>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const columnDefs =requestColDef;
      const rowData = projectDashboardDataStore.ProjectDashboardData;
      const projectDashboardGridParams: any = {
        rowData,
        columnDefs,
      };
      return this.props.homeDashboardDataStore.agGridService.getGridConfig(projectDashboardGridParams, gridId);
    }

    private getGridConfigTariffLog(gridId): GridOptions {
        const columnDefs =homeTariffLogColDef;
        const rowData = this.props.homeDashboardDataStore.tariffOptionSearchData;
        const tariffDashboardGridParams: any = {
          rowData,
          columnDefs,
          context: this.props.homeDashboardDataStore.agGridServiceForTariffLogs
        };
        return this.props.homeDashboardDataStore.agGridServiceForTariffLogs.getGridConfig(tariffDashboardGridParams, gridId);
      }

    private getGridConfigForOpenRequest(gridId): GridOptions {
   
      const columnDefs = requestColDef;
      const rowData = projectDashboardDataStore.openRequestGridData;
      const projectDashboardGridParams: any = {
        rowData,
        columnDefs,
        context: this.props.homeDashboardDataStore.agGridServiceForOpenRequest
      };
      return this.props.homeDashboardDataStore.agGridServiceForOpenRequest.getGridConfig(projectDashboardGridParams, gridId);
    }
}
