import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { AgGridService } from 'common/services/ag-grid-service';
import { UiService } from 'shared/services/ui-service';
import { AccountStore } from 'features/account/stores/account-store';
import history from '../../../../shared/components/header-component/component/history';
import { ToastMessage } from 'shared/components/custom-toast/custom-toast';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import moment from 'moment';
import _ from 'lodash';
import { HomeDashboardDataService } from '../services/home-dashboard-data-service';
import { homeDashboardDataStore } from '.';
import { tariffDashboardDataStore } from 'features/tariff-management/traiff-dashboard/stores';
import { projectDashboardDataStore } from 'features/project-management/project-dashboard/stores';
import { HomeScreenDashboardMessages } from '../constants/enum';
import { TariffLogEntry } from 'features/tariff-management/tariff-log/constant/enums';
import { ProjectDashboardDataStore } from 'features/project-management/project-dashboard/stores/project-dashboard-data-store';

export class HomeDashboardDataStore {

    @observable selectedTab = 'Home';
    @observable selectedTariffName;
    @observable selectedStatus;
    @observable selectedAgency = 'All';
    @observable selectedPipelineSystem = 'All';
    @observable selectedTariffType = 'All';
    @observable selectedTariffNumber;
    @observable selectedOwnerOrCarrier = 'All';
    @observable selectedReferenceTariffNumber;
    @observable tariffOptionSearchData: any[] = [];
    agencyDropdownOptions: any[] = []
    @observable agencySelectOptionData: any[] = []
    pipelineSystemsDropdownOptions: any[] = []
    @observable pipelineSelectOptionData: any[] = [];
    @observable defaultUserLocation;
    ownerCarrierDropdownOptions: any[] = [];
    @observable ownerCarrierSelectOptionData: any[] = [];
    tariffTypeDropdownOptions: any[] = [];
    @observable tariffTypeSelectOptionData: any[] = [];
    @observable backupTariffOptionSearchData: any[] = [];
    userName;
    @observable actionCenterData: any[]  = [];



    constructor(
        public homeDashboardDataService: HomeDashboardDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForOpenRequest: AgGridService,
        public agGridServiceForTariffLogs: AgGridService,
        public projectDashboardDataStore: ProjectDashboardDataStore
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.defaultUserLocation = this.accountStore.defaultUserLocation === 'US' ? 'USA': this.accountStore.defaultUserLocation;
        this.userName = this.accountStore.userName;
        this.getProjectActionCenter();
        this.projectDashboardDataStore.init();
        this.getPipelineSystems();
        this.getAgencyDropdown();
        this.getOwnerCarrierData();
        this.getTariffTypesData();
        this.onClearSearch();
        

        // tariffDashboardDataStore.init();
    }


    @Loader
    @action
    @Catch(() => errorHandler(HomeScreenDashboardMessages.FETCH_ERROR_MESSAGE))
    async getProjectActionCenter(): Promise<void> {
        const response = await this.homeDashboardDataService.getProjectActionCenter(this.userName);
        if(response) {
            this.actionCenterData = response;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(HomeScreenDashboardMessages.FETCH_ERROR_MESSAGE))
    async getAgencyDropdown(): Promise<void> {
        const response = await this.homeDashboardDataService.getAgencyDropdown(this.defaultUserLocation);
        if(response) {
            this.agencyDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.agencycode, 'label': x.agencycode}});
            this.agencySelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(HomeScreenDashboardMessages.FETCH_ERROR_MESSAGE))
    async getPipelineSystems(): Promise<void> {
        const response = await this.homeDashboardDataService.getPipelineSystem();
        if(response) {
            this.pipelineSystemsDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.plName, 'label': x.plName}});
            this.pipelineSelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(HomeScreenDashboardMessages.FETCH_ERROR_MESSAGE))
    async getOwnerCarrierData(): Promise<void> {
        const response = await this.homeDashboardDataService.getOwnerCarrierData();
        if(response) {
            this.ownerCarrierDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.name, 'label': x.name}});
            this.ownerCarrierSelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(HomeScreenDashboardMessages.FETCH_ERROR_MESSAGE))
    async getTariffTypesData(): Promise<void> {
        const response = await this.homeDashboardDataService.getTariffTypesData();
        if(response) {
            this.tariffTypeDropdownOptions = response;
            const a = response.map(x => {return { 'value': x.valueText, 'label': x.valueText}});
            this.tariffTypeSelectOptionData = a;
        } 
    }

    @Loader
    @action
    @Catch(() => errorHandler(HomeScreenDashboardMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        const response = await this.homeDashboardDataService.getTariffOption();
            this.tariffOptionSearchData = response;
            this.backupTariffOptionSearchData = response;
            this.selectedAgency = 'All';
            this.selectedOwnerOrCarrier = 'All';
            this.selectedPipelineSystem = 'All';
            this.selectedTariffType = 'All';
            this.selectedReferenceTariffNumber = '';
            this.selectedTariffName = '';
            this.selectedStatus = false;
            this.selectedTariffNumber = '';
    }

    @Loader
    @Catch(() => errorHandler(HomeScreenDashboardMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
        const agencyID = this.agencyDropdownOptions.length > 0 && this.selectedAgency !=='All' ? this.agencyDropdownOptions.filter((item) => item.agencycode === this.selectedAgency)[0].agencyid : 0;
        const plID = this.pipelineSystemsDropdownOptions.length > 0 && this.selectedPipelineSystem !=='All'? this.pipelineSystemsDropdownOptions.filter((item) => item.plName === this.selectedPipelineSystem)[0].pipelineID : 0;
        const companyID =this.ownerCarrierDropdownOptions.length > 0 && this.selectedOwnerOrCarrier !=='All'? this.ownerCarrierDropdownOptions.filter((item) => item.name === this.selectedOwnerOrCarrier)[0].entityID : 0;
        const typeID = this.tariffTypeDropdownOptions.length > 0 && this.selectedTariffType !=='All' ? this.tariffTypeDropdownOptions.filter((item) => item.valueText === this.selectedTariffType)[0].settingID : 0;
        const data = {
            agencyID,
            plID,
            companyID,
            typeID,
            refTariffNo : this.selectedReferenceTariffNumber ? this.selectedReferenceTariffNumber : '',
            tariffNo: this.selectedTariffNumber ? this.selectedTariffNumber : '',
            name: this.selectedTariffName ? this.selectedTariffName : ''
        }
        const response = await this.homeDashboardDataService.getTariffOption(data);
        this.tariffOptionSearchData = response;
        this.backupTariffOptionSearchData = response;
    }

    isTariffOptionViewDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, 'Tariff Option Tariff Reference');
    };


    async onClickTariffNo(params) {
        if(params.data['logEntry'] === TariffLogEntry.TariffFilingIndex) {
            history.push('/TariffOptions-Index', { from: '/Dashboard', url: '/Dashboard', tariffLogId: params.data['tariffLogID'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['logEntry'], workflowId : params.data['workflowID'] , process : params.data['process'], action: 'View',  workflowStep: params.data['wfStep'] });
        } else {
            history.push('/TariffOptions', { from: '/Dashboard', url: '/Dashboard', tariffLogId: params.data['tariffLogID'], tariffName: params.data['tariffNumber'], tariffNumber: params.data['tariffNumber'], baseTariff: params.data['baseTariff'], tariffClass: params.data['logEntry'], baseId: params.data['tariffBaseID']});

        }    
    }

    async onClickActionItem(action) {
        if(action.url && action.workflowId) {
            const data = this.projectDashboardDataStore.openRequestGridData.find((item) => item.workflowID === action.workflowId);
            if(data) {
                this.projectDashboardDataStore.onClickWorkflowId({data : data});
            } else if(action.logInfo && action.logInfo.length > 0){ 
                history.push(`/${action.url}`, { from: '/TariffDashboard', tariffLogId: action.logInfo[0].tariffLogID, tariffNumber: action.logInfo[0].tariffNumber, baseTariff: action.logInfo[0].baseTariff, tariffClass: action.logInfo[0].logEntry, workflowId : action.workflowId, process :action.logInfo[0].process, pipeline: action.pipelineIds.join(','), action: 'Process'});
            } else {
                history.push(`/${action.url}`, { workflowId: action.workflowId, saved: true, selectedProjectRequestType: action.workflowName });
            } 
        }
    }


  }