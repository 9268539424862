import { User, ResponseDataType, BaseConfigValue } from '../types/shared';
import { RestApiService, restApiService } from './rest-api-service';
import Path from '../../Path';
import { AppConstant } from '../../app_constants';

export class UserService {
    constructor(private restApi: RestApiService) { }

    getRolesAccessData(requestBody): Promise<ResponseDataType> {
        return this.restApi.get<any>(`${Path.getRoleAccessData}${requestBody}`);
    }
    
}

export const userService = new UserService(restApiService);
