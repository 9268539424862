import * as React from 'react';

import Select, { components, ActionMeta } from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Option } from '../../../shared/types/shared';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { grey } from '@mui/material/colors';

library.add(faCaretDown);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const CaretDownIcon = () => {
    return <FontAwesomeIcon icon="caret-down" />;
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const DropdownIndicator = props => {
    return (
        <components.DropdownIndicator {...props}>
            <CaretDownIcon />
        </components.DropdownIndicator>
    );
};

export interface SelectProps {
    className: string;
    onChange: (option: SelectOptionType | Option) => void;
    options: Option[];
    placeholder: string;
    values: Option[] | Option | string;
    isMulti?: boolean;
    isSearchable?: boolean;
    disabled?: boolean;
    menuPlacement?: 'auto' | 'bottom' | 'top' | undefined;
}

export type SelectOptionType = Option[] | Option;

export class ReactSelect extends React.Component<SelectProps, any> {
    constructor(props: SelectProps | Readonly<SelectProps>) {
        super(props);
        this.state = {
            selectedValues: [] as any
        }
    }
    public render(): React.ReactNode {
        const { className, options, placeholder, values, isMulti = true, isSearchable = false, disabled = false, menuPlacement='auto' } = this.props;
        return (
            <div className={className}>
                <Select
                    value={values}
                    placeholder={placeholder}
                    isMulti={isMulti}
                    onChange={this.handleChange}
                    options={options}
                    components={{ DropdownIndicator }}
                    closeMenuOnSelect={!isMulti}
                    closeMenuOnScroll={true}
                    isSearchable={isSearchable}
                    isDisabled={disabled}
                    menuPlacement={menuPlacement}
                    styles={{
                        control: (base, state) => ({
                          ...base,
                          color: '#000000',
                          background:
                            state.isDisabled ? '#e0e0e0': base.background,
                        }),
                        option: (provided, state) => {
                            const data = state.data as Option
                            return ({
                              ...provided,
                              color: this.state.selectedValues.filter((x) => x.label === data.label).length > 0 ? '#FFFFFF' :  provided.color,
                              background: this.state.selectedValues.filter((x) => x.label === data.label).length > 0 ? '#1e90ff' : provided.background,
                            });
                          }
                    }}
                    onMenuClose={this.onMenuClose}
                />
            </div>
        );
    }

    private handleChange = (values: any): void => {
        const { onChange } = this.props;
        if(!this.props.isMulti) {
            onChange(values);
        } else {
            const selectedValues = [...this.state.selectedValues, ...values];
            this.setState({
                selectedValues
            });
        }
    }

    private onMenuClose = (): void => {
        const { onChange } = this.props;
        this.state.selectedValues.map((x) => {
            onChange([x]);
        })
        this.setState({
            selectedValues: []
        })
            
        
    }
}
