import React from 'react';
// import './_current-status-renderer.scss';
import { ICellRendererParams } from 'ag-grid-community';
import { projectDashboardDataStore } from '../../stores';



export const WorkflowIdCellRenderer = (props: ICellRendererParams) => {    
    return (

        projectDashboardDataStore.isSaveDisabled() || props.data.action === 'D' ? 
        <span>{props.data.workflowID}</span> : 
            <span style={{color: 'blue', width: '60px'}}
            onClick={()=>projectDashboardDataStore.onClickWorkflowId(props)}>
                {props.data.workflowID ==undefined?'0000000':props.data.workflowID }</span>
    );
};