export enum PMCTariffMessages {
    CANCEL = 'Cancel Changes?',
    SUBMIT_FOR_APPROVAL = 'Submit for Approval?',
    NOTE_SAVE = 'Note saved successfully'
}

export enum PMCTariffButtons {
        ADD_LOSS_ALLOWANCE = 'Add Loss Allowance',
        ADD_SHRINKAGE = 'Add Shrinkage',
        ADD_ELECTRONIC_REPORTING = 'Add Electronic Reporting',
        ADD_OTHER_CALCULATION = 'Add Other Calculation',
        ADD = 'Add',
        UPLOAD = 'Upload',
        UPLOAD_FILES = 'Upload Files',
        CANCEL = 'Cancel',
        SAVE = 'Save',
        SUBMIT_FOR_APPROVAL = 'Submit for Approval',
        COMPLETE_TARIFF = 'Complete Tariff',
}

export enum PMCTariffHeaderName {

}

export enum PMCTariffFieldName {

}

// export class PMCTariffAllConstants {
// }

// export const SettingNames = [
// ]

// export const commaFormat = (number) => {
//     return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
// }