import { ValueFormatterParams, ColDef } from 'ag-grid-community';
import { miscellaneousSettingsDataStore } from '../stores';
import { dateUtils } from '../../../../shared/services/date-utils';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { PushVmacsButtonComponent } from '../../../../common/components/push-vmacs-button/push-vmacs-button-container';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { dataStore } from 'common/stores';
import { TextCellRenderer } from './cell-renderer/text-cell-renderer';
import { ValueCodeCellRenderer } from './cell-renderer/value-code-cell-renderer';

export const miscellaneousColDef: ColDef[] = [
    {   
        headerName: 'Select',
        field: 'select', 
        colId:'Select',
        checkboxSelection: (params) => {return miscellaneousSettingsDataStore.isEditableSettingName(params) ? false: true}, 
        // cellRenderer: 'checkboxRenderer', 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        width: 40, 
        headerCheckboxSelection: true, 
        headerCheckboxSelectionFilteredOnly: true,
        cellStyle: () => {return {padding: '10px'}},
        suppressSizeToFit: true
    },
    { 
        headerName: 'Jurisdiction', 
        field: 'defaultLocation', 
        colId: 'defaultLocation', 
        filter: true, 
        sortable: true, 
        singleClickEdit: true,
        minWidth: 110, 
        cellClass: (params) => {return miscellaneousSettingsDataStore.isEditableSettingName(params) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.MiscellaneousSettings) ?  'cell-editable' : params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        cellStyle: (params) => {
            if (params.data.settingID <= 0 && !params.data.defaultLocation) {
                return {border: '1px solid #0091ea'};
            }
            return null;
        },
        valueGetter: params => {
            if (params.data['defaultLocation'] === null) {
                return '';
            } else {
                return params.data['defaultLocation'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Setting Name', 
        field: 'settingName', 
        colId: 'settingName', 
        filter: true, 
        sortable: true, 
        singleClickEdit: true,
        minWidth: 150, 
        cellClass: (params) => {return miscellaneousSettingsDataStore.isEditableSettingName(params) && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.MiscellaneousSettings) ?  'cell-editable' : params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        // cellStyle: (params) => {
        //     if (params.data.settingID <= 0 && !params.data.settingName) {
        //         return {border: '1px solid #0091ea'};
        //     }
        //     return null;
        // },
        valueGetter: params => {
            if (params.data['settingName'] === null) {
                return '';
            } else {
                return params.data['settingName'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Value Code', 
        field: 'valueCode',
        colId: 'valueCode', 
        singleClickEdit: true, 
        filter: true, 
        sortable: true, 
        minWidth: 110, 
        // cellRenderer: TextCellRenderer,
        cellRenderer: ValueCodeCellRenderer,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        valueGetter: params => {
            if (params.data['valueCode'] === null) {
                return '';
            } else {
                return params.data['valueCode'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Value Text', 
        field: 'valueText', 
        colId: 'valueText',
        singleClickEdit: true, 
        filter: true, 
        sortable: true,
        minWidth: 130, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.MiscellaneousSettings) ? 'cell-editable': ''}, 
        valueGetter: params => {
            if (params.data['valueText'] === null) {
                return '';
            } else {
                return params.data['valueText'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Asset Group', 
        field: 'assetGrp', 
        colId: 'assetGrp', 
        filter: true, 
        sortable: true, 
        singleClickEdit: true,
        minWidth: 150, 
        cellClass: (params) => {return params.data.action !== 'D' && params.data.defaultLocation === 'CAN' && params.data.settingName === 'PMC Pipeline System' && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.MiscellaneousSettings) ?  'cell-editable' : params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        // cellStyle: (params) => {
        //     if (params.data.settingID <= 0 && !params.data.defaultLocation) {
        //         return {border: '1px solid #0091ea'};
        //     }
        //     return null;
        // },
        valueGetter: params => {
            if (params.data['assetGrp'] === null) {
                return '';
            } else {
                return params.data['assetGrp'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'Remarks', 
        field: 'remarks',
        colId: 'remarks', 
        singleClickEdit: true,
        filter: true, 
        sortable: true, 
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.MiscellaneousSettings) ? 'cell-editable' : ''}, 
        valueGetter: params => {
            if (params.data['remarks'] === null) {
                return '';
            } else {
                return params.data['remarks'];
            }
        },
        minWidth: 200,
        flex: 2
    },
    // { 
    //     headerName: 'Additional Value', 
    //     field: 'additionalValue', 
    //     colId: 'additionalValue', 
    //     singleClickEdit: true, 
    //     filter: true, 
    //     sortable: true, 
    //     editable: (params) => {return params.data.action !== 'D' && dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.MiscellaneousSettings)},
    //     cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.MiscellaneousSettings) ? 'cell-editable' : ''}, 
    //     valueGetter: params => {
    //         if (params.data['additionalValue'] === null) {
    //             return '';
    //         } else {
    //             return params.data['additionalValue'];
    //         }
    //     }
    // },
    { 
        headerName: 'Created User', 
        field: 'createUser', 
        colId: 'createUser', 
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['createUser'] === null) {
                return '';
            } else {
                return params.data['createUser'];
            }
        },
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Created Date',
        field: 'createDate',
        colId: 'createDate',  
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['createDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Updated User', 
        field: 'updateUser',
        colId: 'updateUser',   
        filter: true, 
        sortable: true,
        editable: false,
        minWidth: 120,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        },
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Updated Date', 
        field: 'updateDate', 
        colId: 'updateDate',   
        filter: true, 
        sortable: true, 
        editable: false,
        minWidth: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['updateDate'] === null) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        flex: 1,
        hide: true
    },
    { 
        headerName: 'Push to VMACS', 
        field: 'PushToVMACS', 
        colId: 'PushToVMACS',
        editable: dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd),
        cellRenderer: PushVmacsButtonComponent,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''},
        suppressSizeToFit: true,
        flex: 1
    },
    { 
        headerName: 'Sync Status', 
        field: 'syncStatus', 
        colId: 'syncStatus',
        minWidth: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (params.data['syncStatus'] === null) {
                return '';
            } else {
                return params.data['syncStatus'];
            }
        },
        flex: 1
    },
    { 
        headerName: 'VMACS Test Sync Date', 
        field: 'testSyncDate', 
        colId: 'testSyncDate',
        editable: false,
        minWidth: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['testSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['testSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        flex: 1
    },
    { 
        headerName: 'VMACS Prod Sync Date', 
        field: 'prodSyncDate', 
        colId: 'prodSyncDate',
        editable: false,
        minWidth: 130,
        cellClass: (params) => {return params.data.action === 'D' ? 'grid-cell-bgColor-red' : ''}, 
        valueGetter: params => {
            if (!params.data['prodSyncDate']) {
                return '';
            } else {
                return momenttimezone
                .tz(moment.utc(params.data['prodSyncDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
            }
        },
        flex: 1
    }
];
