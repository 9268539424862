import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class ShipperLossGainMaintenanceDataService {
    constructor(private restApi: RestApiService) {}


    async getTariffShipperLGGrid(request, requestBody): Promise<any[]> {
        const { data } =  await this.restApi.post<any>(`${Path.getTariffShipperLGGrid}?pipelineID=${request.pipelineID}&TariffOptionID=${request.tariffOptionid}&Grade=${request.grade}&Seach=${request.seachKeyword}&CalcGroup=${request.calcGroup}&ColId=${request.sortColId}&Sort=${request.sort}&isPagination=true&pageno=${request.page}&pagesize=${request.pageSize}`, requestBody, {});
        return data;
    }
    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }
    async getGradesData(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getGradesWithDefaultFilter);
        return data;
    }
    async getGradesDataWithFilter(grade, gradeName, pipelineSystemId): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getGrades}?grade=${grade}&gradeName=${gradeName}&pipelineSystemId=${pipelineSystemId}`);
        return data;
    } 
    async getRulesRegTariff(pipelineId): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getRulesRegTariff}?pipelineId=${pipelineId}`);
        return data;
    }
    async getMiscellaneousSettingsData(defaultUserLocation): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.url_id1 + defaultUserLocation);
        return data;
    }
    async deactivateLineSegment(lineSegID: number, userName: string): Promise<any[]> {
        const { data } = await this.restApi.delete<any>(Path.deactivateTariffLineSegment+`?LineSegID=${lineSegID}&user=${userName}`);
        return data;
    }
    async activateLineSegment(requestBody: any, userName: string): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateTariffLineSegment+`?user=${userName}`, requestBody, {});
        return data;
    } 
    async updateLineSegmentMaintenanceData(requestBody, onEditLineSegment): Promise<any[]> {
        if(onEditLineSegment) {
            const { data } = await this.restApi.put<any>(Path.addLineSegmentDetailsAPI, requestBody, {});
            return data;
        } else {
            const { data } = await this.restApi.post<any>(Path.addLineSegmentDetailsAPI, requestBody, {});
            return data;
        }
    }  
    async deleteLineSegmentMaintenanceData(requestBody): Promise<any> {
        const { data } = await this.restApi.delete<any>(Path.addLineSegmentDetailsAPI, requestBody, {});
        return data;
    }  
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsLineSegmentEndpoint, reqbody, {});
        return data;
    }  

    async updateStatusFromDelToActive(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateLineSegments, reqbody, {});
        return data;
    }
}

export const shipperLossGainMaintenanceDataService = new ShipperLossGainMaintenanceDataService(restApiService);
