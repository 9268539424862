import React, { Component } from 'react';
import { agGridService } from 'common/services/ag-grid-service';
import { agGridServiceForAddOrEditLocation } from 'features/master-data-management/location-maintenance/stores'
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { agGridServiceRelated, agGridServiceOwners, agGridServiceEntities } from 'features/master-data-management/pipeline-maintenance/stores'
import { agGridServiceForOpenRequest } from 'features/project-management/project-dashboard/stores';
import { agGridServiceForAddAttachmentLog, agGridServiceForLogWorkflowDetails, agGridServiceForVersionSelection } from 'features/tariff-management/tariff-log/stores';
import { agGridServiceCommentsProject, agGridServiceConnectionPointProject, agGridServiceDeductionProject, agGridServiceLossAllowanceProject, agGridServiceMovementProject, agGridServiceOwnerProject, agGridServicePipelineProject, agGridServiceRelatedTariffProject } from 'features/tariff-management/project-request/tariff-options-project/stores';
import { agGridServiceMovementIndex, agGridServiceConnectionPointIndex, agGridServiceDeductionIndex, agGridServiceLossAllowanceIndex, agGridServiceRelatedTariffIndex, agGridServicePipelineIndex, agGridServiceOwnerIndex, agGridServiceCommentsIndex } from 'features/tariff-management/tariff-options-index/stores';
import { agGridServicePipeline, agGridServiceConnectionPoint, agGridServiceMovement, agGridServiceLossAllowance, agGridServiceDeduction, agGridServiceOwner, agGridServiceComments, agGridServiceRelatedTariff } from 'features/tariff-management/tariff-options/stores';
import { agGridServiceForRelationShip } from 'features/master-data-management/connection-point-maintenance/stores';
import { agGridServiceForHomeOpenRequest, agGridServiceForTariffLogs } from 'features/master-data-management/home-screen-dashboard/stores';
import { agGridServiceForAddAttachment } from 'features/tariff-management/pmc-tariff/stores';
import { agGridServiceForAddAttachmentProject } from 'features/tariff-management/project-request/stores';
import { agGridServiceForOpenTariffLog } from 'features/tariff-management/traiff-dashboard/stores';

interface PageSizeComponentProps { 
    agGridService? : any
}

export class PageSizeComponent extends React.Component<PageSizeComponentProps> {
    render(): React.ReactNode {
        return (
                <div className = 'page-size-div'>
                    Rows Per Page  
                    <select style= {{height: '25px', marginLeft: 2, textAlign: 'center', fontSize: '10px'}} onChange={(e) => this.onPageSizeChanged(e.target.value)} id="page-size" defaultValue={100}>
                        <option value="10">
                            10
                        </option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                    </select>
                </div>
        );
    }

    onPageSizeChanged(newPageSize) {
        this.props.agGridService?.paginationSetPageSize(Number(newPageSize))
        if(dataStore.getCurrentModuleName() === MasterDataComponentModulesName.ConnectionPointMaintenance || 
            dataStore.getCurrentModuleName() === MasterDataComponentModulesName.LineSegmentMaintenance || 
            dataStore.getCurrentModuleName() === MasterDataComponentModulesName.ShipperLGMaintenance || 
            dataStore.getCurrentModuleName() === MasterDataComponentModulesName.GradeMaintenance || 
            dataStore.getCurrentModuleName() === MasterDataComponentModulesName.ProfitCenterMaintenance || 
            dataStore.getCurrentModuleName() === MasterDataComponentModulesName.FilingEntityMaintenance || 
            dataStore.getCurrentModuleName() === MasterDataComponentModulesName.CustomerMaintenance) {
            dataStore.setPageSizeChanged(true);
            dataStore.onGridReady();
        }
      }
}