import React, { Component } from 'react';
import {
    ColDef, CellEditingStoppedEvent
  } from 'ag-grid-community';
import { TariffOptionsIndexDataStore } from '../stores/tariff-options-data-store';
import { Portal } from 'react-overlays';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'common/components/ag-grid-component';
import { inject, observer } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import history from 'shared/components/header-component/component/history';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';


interface connectionPointInterface {
  tariffOptionsDataStore: TariffOptionsIndexDataStore
  index: number,
  tariffOptionID: number,
  addConnectionPoint: (index: any) => void,
  compare?: boolean,
  allDisabled?:boolean
}

interface calendarContainerInterface {
  children: any;
}

@inject('tariffOptionsDataStore')
@observer
export class ConnectionPoint extends Component<connectionPointInterface> {

  CalendarContainer = (props: calendarContainerInterface) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{props.children}</Portal>;
  };

  onChangeData = (params, value, key, index) => {
    const {tariffOptionsDataStore} = this.props;
    if(key==='effectiveDate'||key==='endDate'||key==='noChange'||key==='accept'){
      const rowIndex = tariffOptionsDataStore.ConnectionPointData[index].findIndex(x=>x.rowId===params.data?.rowId)
      tariffOptionsDataStore.setConnectionPointData(value, rowIndex, key, index, params);
    }
  } 
  colDefConnectionPoint: ColDef[] = [
      {
        headerName: 'Select',
        field: 'select',
        checkboxSelection: (params) => { return this.props.compare && params.data?.parent === true ? true: false},
        hide: !this.props.compare,
        colId:'Select',
        // cellRenderer: 'checkboxRenderer',
        width: 40,
        headerCheckboxSelection: false,
        suppressSizeToFit: true,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        cellStyle: () => {return {padding: '10px'}}
      },
      {
        headerName: 'Rel',
        field: 'rel',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        minWidth: 70,
        width: 70,
        hide: !this.props.compare,
        suppressSizeToFit: true,
        cellStyle: () => {return {padding: '10px'}}
      },
      {
        headerName: 'Tariff CPID',
        field: 'tariffCPID',
        tooltipField: 'cpid',
        filter: true,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        sortable: true,
        editable: false,
        width: 130,
        singleClickEdit: true,
        suppressSizeToFit: true
      },
      {
      headerName: 'Location',
      field: 'location',
      editable: false,
      cellEditorParams: (params) => {
        return {
            values: this.props.tariffOptionsDataStore.cpLineLocation[this.props.index].map(x=>x.locnName)
        };
      },
      cellEditor: 'agSelectCellEditor',
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      singleClickEdit: true,
      // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      minWidth: 130,
      flex: 2,
      },
      { 
        headerName: 'Connection Point',
        field: 'cpName',
        cellEditor: 'agSelectCellEditor',
        singleClickEdit: true,
        cellEditorParams:  (params) => {
          // const values = await this.props.tariffOptionsDataStore.getCPTariff(params.data?.location, this.props.index)
          return {
              values: this.props.tariffOptionsDataStore.cpArea[this.props.index][params.data?.rowId].map(x=>x.cpName) || []
          };
        },
        rowGroup:this.props.compare? true : false,
        showRowGroup: this.props.compare? true : false,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        editable: false,
        // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
        minWidth: 200,
        flex: 1
      },
      {
        headerName: 'CP Type',
        field: 'cpTypeValue',
        tooltipField: 'cpTypeValue',
        filter: true,
        editable: false,
        valueGetter:  (params) => {
          // const values = await this.props.tariffOptionsDataStore.getCPTariff(params.data?.location, this.props.index)
          return params.data?.cpName && this.props.tariffOptionsDataStore.cpArea[this.props.index] && this.props.tariffOptionsDataStore.cpArea[this.props.index][params.data?.rowId]?.find(x=>x.cpName===params.data?.cpName)?.cpTypeValue || params.data?.cpTypeValue
        },
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        sortable: true,
        minWidth: 130,
        singleClickEdit: true,
        suppressSizeToFit: true
      },
      {
        headerName: 'Rate Type',
        // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
        field: 'rateType',
        tooltipField: 'rateType',
        cellEditor: 'agSelectCellEditor',
        filter: true,
        editable: false,
        sortable: true,
        cellEditorParams: (params) => {
          return {
              values: this.props.tariffOptionsDataStore.mvtRateTypes.map(x=>x.tariffRateDesc)
          };
        },
        minWidth: 135,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        suppressSizeToFit: false,
        flex: 2
      },
      // {
      //   headerName: 'No Change',
      //   field: 'noChange',
      //   headerClass: 'text-center',
      //   editable: false,
      //   filter: true,
      //   sortable: true,
      //   cellRenderer: params => {
      //     return (<input type='checkbox' checked={params.data?.noChange===true}  
      //     onClick={(e)=>{this.onChangeData(params, !params.data?.noChange, 'noChange', this.props.index)}}
      //     disabled={this.props.tariffOptionsDataStore.stepName!=='Process & Submit for Approval'||this.props.tariffOptionsDataStore?.workflowAction==='View'}/>);
      //   }, 
      //   cellClass: (params) => {return this.props.compare? '': this.props.compare? '': params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      //   cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
      //   width: 110,
      //   singleClickEdit: true,
      //   suppressSizeToFit: true
      // },
      {
        headerName: 'Old Rate (cents)',
        field: 'oldRate',
        // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
        tooltipField: 'oldRate',
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        valueSetter: (params) => {
          if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
            params.data.oldRate=params.newValue.toUpperCase();
            return true
          } else {
            this.props.tariffOptionsDataStore.newRateIncorrectAlert();
            return false
          }
        },
        filter: true,
        editable: false,
        sortable: true,
        width: 140,
        minWidth: 140,
        singleClickEdit: true,
        suppressSizeToFit: true
      },
      {
          headerName: 'New Rate (cents)',
          field: 'newRate',
          // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
          tooltipField: 'newRate',
          valueSetter: (params) => {
            if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
              params.data.newRate=params.newValue.toUpperCase();
              this.props.tariffOptionsDataStore.changeNewRateCPOverride(this.props.tariffOptionsDataStore.ConnectionPointData[this.props.index].findIndex(x=>x.rowId===params.data?.rowId), this.props.index, params.newValue)
              return true
            } else {
              this.props.tariffOptionsDataStore.newRateIncorrectAlert();
              return false
            }
          },
          filter: true,
          editable: (params) => {
            return !params.data?.accept && !params.data?.noChange && this.props.tariffOptionsDataStore.stepName==='Process & Submit for Approval' && this.props.tariffOptionsDataStore?.workflowAction==='Process'?  true : false 
          },
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          sortable: true,
          width: 140,
          minWidth: 140,
          singleClickEdit: true,
          suppressSizeToFit: true
      },
      {
        headerName: 'Effective Date',
        field: 'effectiveDate',
        // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
        editable: false,
        // comparator: (a, b) => {
        //     const dateA = new Date(a);
        //     const dateB = new Date(b);
        //     if(dateA===dateB) return 0;
        //     else if(dateA>dateB) return 1;
        //     return -1           
        // },
        // sort: 'asc',
        // // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
        valueFormatter: ({ value, data }) => '',
        cellRenderer: (params) => {
            return (params.data ? (<DatePicker
                  popperClassName='ag-custom-component-popup'
                  selected={params.data?.effectiveDate && new Date(params.data?.effectiveDate) || null}
                  onChange={(value)=>{this.onChangeData(params, value, 'effectiveDate', this.props.index)}}
                  popperContainer={this.CalendarContainer}
                  popperPlacement='top-start'
                  className='datePickerGridInput'
                  readOnly={true}
                  />) : '')
        },
        minWidth: 130,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        flex: 1
    },
    {
      headerName: 'End Date',
      field: 'endDate',
      // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
      editable: false,
      // // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
      cellRenderer: (params) => {
          return (params.data &&  (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.value && new Date(params.value) || null}
                onChange={(value)=>{this.onChangeData(params, value, 'endDate', this.props.index)}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                readOnly={true}
                className='datePickerGridInput'
                />)) || params.value
      },
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      flex: 1,
      minWidth: 130
  },
      {
          headerName: 'Rate Basis',
          field: 'rateBasis',
          // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
          editable: false,
          tooltipField: 'rateBasis',
          cellEditor: 'agSelectCellEditor',
          cellEditorParams: (params) => {
            return {
                values: this.props.tariffOptionsDataStore.mvtRateBasis?.map(x=>x.valueText)
            };
          },
          minWidth: 162,
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          suppressSizeToFit: false,
          flex: 2
      },
      {
        headerName: 'Always Charge',
        field: 'alwaysCharge',
        tooltipField: 'alwaysCharge',
        headerClass: 'text-center',
        editable: false,
        filter: true,
        sortable: true,
        cellRenderer: params => {
          return (<input type='checkbox' checked={params.data.alwaysCharge===true}  
          disabled={true}/>);
        }, 
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
        width: 110,
        singleClickEdit: true,
      },
      {
          headerName: 'Ref',
          field: 'ref',
          editable: true,
          // cellClass: (params) => {return this.props.compare? '': params.data?.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
          tooltipField: 'ref',
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          filter: true,
          sortable: true,
          width: 120,
      },    
    ];
    getGridConfigConnectionPoint = (gridId) => {
      const {
        tariffOptionsDataStore: { agGridServiceConnectionPoint }
      } = this.props;
      const {tariffOptionsDataStore} = this.props;
      const gridParams: any = {
        rowData: tariffOptionsDataStore.ConnectionPointData?.length && tariffOptionsDataStore.ConnectionPointData[tariffOptionsDataStore.selectedTab]||[],
        columnDefs:this.colDefConnectionPoint, 
        domLayout: 'autoHeight',
        onCellEditingStopped: this.onCellEditingStopped,
        groupDisplayType: 'groupRows',
        cellDataType: false,
        context : agGridServiceConnectionPoint
        };
      return agGridServiceConnectionPoint.getGridConfig(gridParams, gridId);
    }

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
      const rowId = event.data?.rowId;
      const rowData = event.data;
      const col = event.colDef.field || '';
      const value = event.value;
      // baseTariffDataStore.updateShowUnsavedWarningAlert();
      if(col==='location'){
        const locationId = this.props.tariffOptionsDataStore.cpLineLocation[this.props.index].find(x=>value===x.locnName).locationID;
        this.props.tariffOptionsDataStore.getCPArea(this.props.index, rowId,locationId);
        // console.log(baseTariffDataStore.BaseTariffData)
      }
    }

    deleteConnectionPoint = async (index) => {
      const { tariffOptionsDataStore } = this.props;
      const requestBody: any = await tariffOptionsDataStore.agGridServiceConnectionPoint.getSelectedRows();
      if(requestBody.length > 0) {
          console.log(requestBody);
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => tariffOptionsDataStore.deleteConnectionPoint(requestBody, index),
            'Delete',
            'Are you sure you want to delete the selected record/s?'
        );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {console.log(1)},
          ConfirmWithOK,
          {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }

    mergeCP= async (index) => {
      const { tariffOptionsDataStore } = this.props;
      const requestBody: any = await tariffOptionsDataStore.agGridServiceConnectionPoint.getSelectedRows();
      if(requestBody.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => tariffOptionsDataStore.merge('Connnection Point', requestBody, this.props.tariffOptionID),
            'Merge',
            'Are you sure you want to merge the selected record/s?'
        );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {console.log(1)},
          ConfirmWithOK,
          {secondaryText: 'No records are selected. Please select atleast one record to perform merge.'} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }
    
     
    checkRoleAccessSaveCompare = () => {
      const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ProjectRequestCompare);
      console.log(a, 'roleaccess');
      return a;
   }

  
  
render(): React.ReactNode {
    return (
    <>
        <Row>
        {this.props.compare && (<div className='LeftFlex'>
            <CustomButton disabled={!this.checkRoleAccessSaveCompare()|| this.props.allDisabled} type={CustomButtonType.Submit} onClick={()=>{this.mergeCP(this.props.index)}}>
            Merge
            </CustomButton>
            </div>)}
        </Row>
      <Row>
        <AgGridComponent gridConfig={this.getGridConfigConnectionPoint('Tariff Option Project CP')}/>
      </Row>
      </>
    )}
  }