import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/overrides/ag-grid.scss'
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
// import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './App.scss';
import { createRoot } from 'react-dom/client';
import {LicenseManager} from 'ag-grid-enterprise';
import { Provider } from 'mobx-react';
import { Router } from 'react-router-dom';
import history from './shared/components/header-component/component/history';
import { dataStore } from './common/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import { AuthToken } from 'shared/types/shared';
import { AppConstant } from 'app_constants';
import { accountStore } from 'features/account/stores';
LicenseManager.setLicenseKey('Using_this_AG_Grid_Enterprise_key_( AG-041125 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Plains Midstream Canada ULC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 7 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 26 April 2024 )____[v2]_MTcxNDA4NjAwMDAwMA==d10b30f9f8ee7218715024ac7684c2ef');


const rootStore = {
  dataStore,
  accountStore
};

const authTokenOnPageLoad = localStorageService.get<AuthToken>(AppConstant.AUTHTOKEN);
if (authTokenOnPageLoad) {
    accountStore.setAuthInfo(authTokenOnPageLoad, true);
}
const loggedInUserDefaultLocation = localStorageService.get<AuthToken>(AppConstant.DEFAULT_USER_LOCATION);
if (loggedInUserDefaultLocation) {
  accountStore.setDefaultUserLocation(loggedInUserDefaultLocation);
}


const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(<Provider {...rootStore}>
  <Router history={history}>
      <App />
  </Router>
</Provider>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
