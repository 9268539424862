import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { AgGridComponent } from 'common/components/ag-grid-component';
import { AgGridService } from 'common/services/ag-grid-service';
import './attachments.scss';
import { FileNameCellRenderer } from '../cell-renderers/attachment-file-name.tsx/attachment-file-name';
import { DeleteRowCellRenderer } from '../cell-renderers/delete-ag-grid-row/delete-ag-grid-row';
import { AppConstant } from 'app_constants';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { CellEditingStartedEvent, CellEditingStoppedEvent, ColDef } from 'ag-grid-community';

interface AttachmentsProps {
  fileUploadGridData: any[];
  agGridService: AgGridService;
  disabled: boolean;
  uploadFileCallback: (e: any) => void;
  onDownloadFileCallback: (data) => void;
  onDeleteFileCallback: (fileID, fileName) => void;
  onUpdateAttachmentCallback: ()=> void;
  saveRoleAccess: boolean;
  cellValueChangeMap: any;
  updateRow: (data: any) => void;
  disableDeleteBtn: boolean;
}

export const Attachments = ({ fileUploadGridData, agGridService, disabled, disableDeleteBtn, uploadFileCallback, onUpdateAttachmentCallback, onDownloadFileCallback, onDeleteFileCallback, saveRoleAccess, cellValueChangeMap, updateRow }: AttachmentsProps) => {
  const attachmentsGridColDef = [
    {
      headerName: 'File Name',
      field: 'fileName',
      filter: true,
      sortable: true,
      cellEditor: 'agSelectCellEditor',
      cellRenderer: FileNameCellRenderer,
      cellRendererParams: {
        onDownloadFileCallback: onDownloadFileCallback
      },
      minWidth: 150,
      flex: 1
    },
    {
      headerName: 'Description',
      field: 'description',
      filter: true,
      sortable: true,
      editable: () => { return saveRoleAccess; },
      minWidth: 150,
      flex:1,
      valueGetter: params => {
          if (params.data['description'] === null) {
              return '';
          } else {
              return params.data['description'];
          }
      }
    },
    {
      headerName: 'Created By',
      field: 'createUser',
      width: 150,
      filter: true,
      sortable: true,
      editable: false,
      valueGetter: params => {
        if (params.data['createUser'] === null) {
            return '';
        } else {
            return params.data['createUser'];
        }
      }
    },
    {
      headerName: 'Created Date',
      field: 'createDate',
      filter: true,
      sortable: true,
      editable: false,
      valueGetter: params => {
        if (params.data['createDate'] === null) {
            return '';
        } else {
            return momenttimezone
                .tz(moment.utc(params.data['createDate']), AppConstant.CST_TIME_FORMATTER)
                .format(AppConstant.DATE_TIME_FORMAT);
        }
      }
    },
    {
        headerName: 'Updated By',
        field: 'updateUser',
        width: 130,
        filter: true,
        sortable: true,
        editable: false,
        valueGetter: params => {
            if (params.data['updateUser'] === null) {
                return '';
            } else {
                return params.data['updateUser'];
            }
        },
    },
    {
      headerName: 'Updated Date',
      field: 'updateDate',
      filter: true,
      width: 130,
      sortable: true,
      editable: false,
      valueGetter: params => {
          if (params.data['updateDate'] === null) {
              return '';
          } else {
              return momenttimezone
                  .tz(moment.utc(params.data['updateDate']), AppConstant.CST_TIME_FORMATTER)
                  .format(AppConstant.DATE_TIME_FORMAT);
          }
      },
    },
    {
      headerName: '',
      field: 'delete',
      width: 130,
      cellRenderer: DeleteRowCellRenderer,
      cellRendererParams: {
        onDeleteRowCallback: onDeleteFileCallback,
        disableDeleteBtn: disableDeleteBtn
      },
    },
  ];

  function getGridConfig(gridId) {
    const tariffLogGridParams: any = {
      rowData: fileUploadGridData,
      columnDefs: attachmentsGridColDef,
      onCellEditingStopped: onCellEditingStopped,
      onCellEditingStarted: onCellEditingStarted,
      context : agGridService
    };
    return agGridService.getGridConfig(tariffLogGridParams, gridId);
  }

  const onCellEditingStarted = (event: CellEditingStartedEvent): void => {
        const row = event.data.pmcTariffDocumentID || event.data.tariffLogDocID || event.data.workflowDocumentID;
        const col = event.colDef.field || '';
        const value = event.value;
        const isNotEmpty =
            row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
        if (!isNotEmpty) {
            setValueInChangeMap(row, col, value, value);
        }
    };

    const onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
      const row = event.data.pmcTariffDocumentID || event.data.tariffLogDocID || event.data.workflowDocumentID;
        const col = event.colDef.field || '';
        const value = event.value;
        cellValueChangeMap[row][col]['currentValue'] = value;
        updateRow(event.data);
        agGridService.getNodes()?.refreshCells();
    };

    const setValueInChangeMap = (row: number, col: string, initValue: string, newValue: string) => {
        if (!(row in cellValueChangeMap)) {
            cellValueChangeMap[row] = {};
        }
        if (!(col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue))
            cellValueChangeMap[row][col] = { initValue: initValue };
        else cellValueChangeMap[row][col]['currentValue'] = newValue;
    };

  return (
    <div className='attachmentsGrid'>
      <div style={{ 'minWidth': '80%' }}>
        <AgGridComponent gridConfig={getGridConfig('Attachments')} />
      </div>
      <CustomButton type={CustomButtonType.Submit} onClick={() => document.getElementById('file')?.click()} disabled={disabled}>
        Upload
      </CustomButton>
      <input type="file" style={{ 'display': 'none' }} id="file" name="file" onChange={(e) => uploadFileCallback(e)} readOnly={disabled} />
      <CustomButton type={CustomButtonType.Submit} onClick={() => onUpdateAttachmentCallback()} disabled={disabled}>
        Save
      </CustomButton>
    </div>
  );
};