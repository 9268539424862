import { processOptionDataService } from '../services/process-tariff-options-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';

// import { ProcessOptionStep1DataStore } from './project-request-step-1-data-store';
import { accountStore } from '../../../account/stores';
import { ProcessOptionStep2DataStore } from './process-tariff-options-data-store';

export const agGridServiceForAddAttachmentProcessOption = new AgGridService();

// export const processOptionStep1DataStore = new ProcessOptionStep1DataStore(processOptionDataService, uiService, accountStore, agGridService, agGridServiceForAddAttachmentProject);

export const processOptionStep2DataStore = new ProcessOptionStep2DataStore(processOptionDataService, uiService, accountStore, agGridService);
