import * as React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Confirm } from './confirm';
import { ConfirmWithDataReset } from './confirm-with-data-reset';


export class ConfirmService {
    showConfirmDialog(proceedCallback: () => void, primaryText: string, secondaryText: string) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <Confirm
                        onClose={onClose}
                        primaryText={primaryText}
                        secondaryText={secondaryText}
                        onSuccessClick={proceedCallback}
                    />
                );
            }
        });
    }

    showConfirmWithCustomComponent<P>(
        callBack: (data: any) => void,
        Component: React.ComponentClass<P> | any,
        props: P,
        classNameStyle: string
    ) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={classNameStyle}>
                        <Component {...props} onClose={onClose} onSuccessClick={callBack} />
                    </div>
                );
            }
        });
    }

    showMessageAlert(message: string) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm">
                        <div className="confirm__warning mt-10">
                            <i className="fa fa-exclamation-triangle confirm-warning"></i>
                        </div>
                        <div className="justify-content-center confirm__secondary-text mt-10">{message}</div>
                    </div>
                );
            }
        });
    }

    showConfirmWithDataReset(proceedCallback: () => void, primaryText: string, secondaryText: string, resetData: () => void) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <ConfirmWithDataReset
                        onClose={onClose}
                        primaryText={primaryText}
                        secondaryText={secondaryText}
                        onSuccessClick={proceedCallback}
                        resetData={resetData}
                    />
                );
            }
        });
    }
}
