import { action, computed, observable, toJS, makeAutoObservable } from 'mobx';
import { UiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import moment from 'moment';
import { TariffLogDataService } from '../services/tariff-log-data-service';
import { BaseTariffResponse } from '../model/model';
import history from '../../../../shared/components/header-component/component/history';
// import { map, isEqual }, _ from 'lodash';
import { AccountStore } from '../../../account/stores/account-store';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import _, { isEqual } from 'lodash';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { TariffLogEntry } from '../constant/enums';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';


export class LogEntryDataStore {
    @observable baseTariffData: any[] = [];
    @observable projectTypes: any[] = [];
    @observable projectNames: any[] = [];
    @observable logEntryTypes: any[] = [];
    @observable shownProjectNames: any[] = [];
    @observable fileUploadGridData: any[] = [];
    @observable backupFileUploadGridData: any[] = [];
    @observable cellValueChangeMap: any = {};
    @observable Tabs: any[] = [];
    @observable createdUser;
    @observable selectedPipelineArea = '';
    @observable selectedBaseTariff = 'Select';
    pipelineAreaData: any[] = [];
    @observable selectedLogEntryType = '';
    @observable selectedBaseTariffDetails : any = [];
    @observable fileUploadedSuccessfully = false;
    @observable currentAttachmentGridData: any = [];
    @observable backupAttachmentGridData: any = [];
    @observable cellValueChangeMapAttachment: any = {};
    @observable shownCancelVersion:any[] = [];
    @observable maintenanceSelectionVersionData: any[] = [];
    @observable tariffLogId: any;
    @observable statusOrHistory = true;
    @observable stepDescription = '';
    @observable comment = '';
    @observable workFlowDetailsDataGrid: any[] = [];
    userName = '';
    @observable workflowId: any;
    @observable workflowStepFlowId: any;
    @observable workflowStepId: any;
    @observable readonlyView = false;
    @observable isLogProcessed: any;
    @observable resetLogEntryForm = false;
    @observable isLogEntrySaved = false;
    @observable baseTariffDropdownOption: any[] = [];
    @observable workflowstepName;
    @observable isProjectNameSaved = false;
    @observable projectNameDropdownOptions: any[] = [];
    @observable lastCompletedtWorkflowstepName;
    @observable lastCompletedstepWorkflowId;
    @observable showLoader = false;
    @observable projectUnlinkEnable = true;
    userEmail = '';
    criteria;
    baseSystemData:any = []
    

    constructor(
        public tariffLogDataService: TariffLogDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForVersionSelection: AgGridService,
        public agGridServiceForAddAttachment: AgGridService,
        public agGridServiceForWorkflowDetails: AgGridService
    ) {
        makeAutoObservable(this);
    }

    @action
    init(): void {
        this.selectedBaseTariff = 'Select';
        this.tariffLogId = null;
        this.readonlyView = false;
        this.isLogProcessed = false;
        this.workflowstepName = null;
        this.selectedBaseTariffDetails= [];
        this.isProjectNameSaved = false;
        this.lastCompletedtWorkflowstepName = null;
        this.lastCompletedstepWorkflowId = null;
        this.projectUnlinkEnable = true;
        this.comment = '';
        this.criteria= null;
        this.getBaseSystems()
        this.getLogEntryTypes().then(() => {
            this.getBaseTariffData().then(() => {
                const state=history?.location?.state as {from?: string; tariffLogId?: string; baseTariff?: string; tariffNumber?: string; tariffClass?: string; workflowId?: string; process?:boolean; } || '';
                if(state.from === '/TariffDashboard') {
                    this.selectedLogEntryType = state.tariffClass ? state.tariffClass : 'Tariff Filing';
                    this.selectedBaseTariff = state.baseTariff ? state.baseTariff : '';
                    this.workflowId = state.workflowId;
                    this.isLogProcessed = state.process;
                    this.tariffLogId = state.tariffLogId;
                    this.getSelectedBaseTariffDetails(this.tariffLogId).then((baseTariffDetails) => {
                        if(this.workflowId && (this.selectedLogEntryType === TariffLogEntry.TariffFiling)) {
                            const jointTariffBaseDetailId = baseTariffDetails[0]?.tariffBaseDetailID;
                            const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? baseTariffDetails[0].jointCarrierLogs : baseTariffDetails[0]?.tariffLogs;
                            const testInd =  logsArray.filter((item) => item.tariffLogID === this.tariffLogId)[0].testInd;
                            const criteria =  testInd === true ? 'test' : testInd === false  ? 'no test' : 'test';
                            this.criteria = criteria
                            this.getWorkflowStatusGridData(criteria);
                        } else if(this.workflowId && (this.selectedLogEntryType === TariffLogEntry.Maintenance)) {
                            const jointTariffBaseDetailId = baseTariffDetails[0]?.tariffBaseDetailID;
                            const logsArray  =  jointTariffBaseDetailId && jointTariffBaseDetailId > 0 ? baseTariffDetails[0].jointCarrierLogs : baseTariffDetails[0]?.tariffLogs;
                            const testInd =  logsArray.filter((item) => item.tariffLogID === this.tariffLogId)[0].testInd;
                            const criteria =  testInd === true ? 'Test' : testInd === false  ? 'No Test' : 'Test';
                            this.criteria = criteria
                            this.getWorkflowStatusGridData(criteria);
                        } else if(this.workflowId) {
                            this.getWorkflowStatusGridData();
                        } else {
                            this.workFlowDetailsDataGrid = [];
                            this.statusOrHistory=true;
                            this.stepDescription='';
                        }
                    });
                                  
                    this.createdUser = this.accountStore.displayName;
                    this.readonlyView = true;
                    this.isLogEntrySaved = true;
                }else if(this.baseTariffData.length > 0) {
                    // this.selectedBaseTariff = this.baseTariffData[0].baseTariff;
                    // this.getSelectedBaseTariffDetails();
                    this.getWorkflowInstanceData();
                    this.readonlyView = false;
                    this.isLogEntrySaved = false;
                }
                history.push(history.location.pathname);
                this.getProjectNames();
            }
            );
        });
        this.getProjectTypes();
        
        dataStore.setShowUnsavedWarningAlert(false);
        this.userName = this.accountStore.displayName;
        this.userEmail = this.accountStore.userName;
        this.createdUser = this.accountStore.displayName;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Base Tariff Fetch Error'))
    async getBaseSystems(): Promise<void> {
        const baseSystemData = await this.tariffLogDataService.getBaseSystems();   
        this.baseSystemData = baseSystemData;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Base Tariff Fetch Error'))
    async getBaseTariffData(): Promise<void> {
        const baseTariffData = await this.tariffLogDataService.getBaseTariff();   
        this.baseTariffData = baseTariffData;
        const a = baseTariffData.map(x => {return { 'value': x.baseTariff, 'label': x.baseTariff}});
        this.baseTariffDropdownOption = a;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Base Details Fetch Error')) 
    async getSelectedBaseTariffDetails(logId?): Promise<any> {
        const selectedBaseTariff = this.baseTariffData.find(y=>{return this.selectedBaseTariff===y.baseTariff});
        const jtId = selectedBaseTariff.jtid ? selectedBaseTariff.jtid : 0;
        const baseId = selectedBaseTariff.tariffBaseID? selectedBaseTariff.tariffBaseID : 0;
        const logEntryId = this.logEntryTypes.filter((item) => item.valueText === this.selectedLogEntryType)[0].settingId;
        if(baseId) {
            const baseTariffDetails = await this.tariffLogDataService.getSelectedBaseTariffDetails(baseId, jtId, logEntryId, logId, this.isLogProcessed);
            if(baseTariffDetails && baseTariffDetails.length > 0 && (this.selectedLogEntryType === TariffLogEntry.TariffFilingIndex || this.selectedLogEntryType === TariffLogEntry.TariffFiling) && (!baseTariffDetails[0].currentTariffIsProcess || (baseTariffDetails[0].currentIndexLog && baseTariffDetails[0].currentIndexLog !== '')) && !this.tariffLogId) {
                const confirmService = new ConfirmService();
                if(baseTariffDetails[0].currentIndexLog && baseTariffDetails[0].currentIndexLog !== '' && this.selectedLogEntryType === TariffLogEntry.TariffFilingIndex) {
                    this.showDialogue(baseTariffDetails[0].currentIndexLog);
                } else{
                    // const stringMsg = baseTariffDetails[0].currentIndexLog ? ` and ${baseTariffDetails[0].currentIndexLog} is inprogress` : ''
                    // const msg = `${baseTariffDetails[0].currentTariffNo} is not processed${stringMsg} yet. Do you want to cancel that and create a new log entry for the base tariff?`
                    confirmService.showConfirmWithDataReset(
                        () => {
                            this.selectedBaseTariffDetails = baseTariffDetails;
                            this.fileUploadGridData = baseTariffDetails.length > 0 && baseTariffDetails[0].tariffLogDocuments ? baseTariffDetails[0].tariffLogDocuments as any[] : [];
                            this.backupAttachmentGridData = this.fileUploadGridData;
                        },
                        'Warning',
                        `${baseTariffDetails[0].currentTariffNo} is not processed yet. Do you want to cancel that and create a new log entry for the base tariff?`,
                        () => {this.resetData()}
                    );
                   
                }
                
            } else {
                this.selectedBaseTariffDetails = baseTariffDetails;
                this.fileUploadGridData = baseTariffDetails.length > 0 && baseTariffDetails[0].tariffLogDocuments ? baseTariffDetails[0].tariffLogDocuments as any[] : [];
                this.backupAttachmentGridData = this.fileUploadGridData;
            }
            return baseTariffDetails;
        }
    }

    @action
    showDialogue(currentIndexLog) {
        const confirmServiceOK = new ConfirmService();
        confirmServiceOK.showConfirmWithCustomComponent(
        () => {return null},
        ConfirmWithOK,
        {secondaryText: `You cannot create another index log when the ${currentIndexLog} is still in progress.`} as ConfirmWithOKProps,
        'confirmPopup'
        );
        this.resetData();
    }
 
    @Loader
    @action
    async getProjectTypes(): Promise<void> {
        const projectTypeData = await this.tariffLogDataService.getProjectTypes();
        this.projectTypes = projectTypeData.map(x=>{return {'valueText':x.valueText, 'settingId': x.settingID}})
    }


    @Loader
    @action
    async getLogEntryTypes(): Promise<void> {
        const logEntryTypesData = await this.tariffLogDataService.getLogEntryTypes();
        this.logEntryTypes = logEntryTypesData.map(x=>{return {'valueText':x.valueText, 'settingId': x.settingID}})
    }
    
    
    @Loader
    @action
    async getProjectNames(): Promise<void> {
        const projectNamesData = await this.tariffLogDataService.getProjectNames(this.selectedLogEntryType);
        this.projectNames = projectNamesData;
        const a = projectNamesData.map(x => {return { 'value': x.name, 'label': x.name}});
        this.shownProjectNames = a;
    }

    
    @action
    async setShownProjectNames(values): Promise<void> {
        console.log(values);
        this.shownProjectNames= values;
    }

    @action
    async setShownCancelVersion(values): Promise<void> {
        console.log(values);
        this.shownCancelVersion= values;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Get Workflow Instance Fetch Error'))
    async getWorkflowInstanceData(): Promise<void> {
        const response = await this.tariffLogDataService.getWorkflowInstanceData(this.userName, this.selectedLogEntryType);
        if(response) {
            this.workflowId = response.workflowId;
            const criteria = this.selectedLogEntryType === TariffLogEntry.TariffFiling ?  'test' : this.selectedLogEntryType === TariffLogEntry.Maintenance ? 'Test' : null
            this.getWorkflowStatusGridData(criteria);
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Workflow Status Fetch Error'))
    async getWorkflowStatusGridData(criteria?): Promise<void> {
        const criteriaforWorkflowStatus = this.criteria ? this.criteria : criteria ? criteria : null
        const response = await this.tariffLogDataService.getWorkflowStatusGridData(this.workflowId, criteriaforWorkflowStatus);
        this.workFlowDetailsDataGrid=response;
        const inProgressStep = response.find(x=>x.workflowstepStatus==='In Progress');
        this.workflowStepFlowId= inProgressStep ?  inProgressStep.workflowStepFlowId : null;
        this.workflowStepId = inProgressStep ? inProgressStep.workflowstepId : null;
        this.workflowstepName = inProgressStep ? inProgressStep.workflowstepName : null;
        const completedSteps = response.filter(x=>x.workflowstepStatus==='Complete');
        this.lastCompletedtWorkflowstepName = completedSteps && completedSteps.length > 0 ? completedSteps[completedSteps.length -1].workflowstepName : null;
        this.lastCompletedstepWorkflowId = completedSteps && completedSteps.length > 0 ? completedSteps[completedSteps.length - 1].workflowstepId : null;
        this.statusOrHistory=true;
        this.stepDescription=inProgressStep ? inProgressStep.workflowstepDescription : '';
        if(this.lastCompletedtWorkflowstepName && this.lastCompletedtWorkflowstepName === 'Link Project' && this.tariffLogId) {
            const log1DStatus  = await this.tariffLogDataService.getLog1DStatus(this.tariffLogId);
            this.projectUnlinkEnable = log1DStatus;
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Workflow History Fetch Error'))
    async getWorkflowHistoryStatusGridData(): Promise<void> {
        const response = await this.tariffLogDataService.getWorkflowHistoryStatusGridData(this.workflowId);
        this.workFlowDetailsDataGrid=response;
        this.statusOrHistory=false;
    }

    @computed
    get WorkFlowDetailsDataGrid() {
        return toJS(this.workFlowDetailsDataGrid);
    }

    @Loader
    @action
    @Catch(() => errorHandler('There is an error while uploading attachment'))
    async uploadFile(e): Promise<void> {
        const formData = new FormData();
        formData.append('Attachment', e.target.files[0]);
        formData.append('FileName', e.target.files[0].name);
        formData.append('Description', 'Test file for upload');
        formData.append('User', this.createdUser );
        formData.append('BaseID', this.selectedBaseTariffDetails[0]?.tariffBaseID ) ;
        const logEntryID = this.logEntryTypes.filter((item) => item.valueText === this.selectedLogEntryType)[0].settingId;
        formData.append('LogEntryID', logEntryID ) ;
        formData.append('LogID', this.tariffLogId ? this.tariffLogId : 0);
        const uploadedFileData = await this.tariffLogDataService.uploadFile(formData);
        if(uploadedFileData.length > 0){
            this.uiService.toastService.success('Attachment uploaded successfully');
            // const arrUploadedFileData: any = [];
            // uploadedFileData.map(item => {
            //     arrUploadedFileData.push(item);
            // })
            const fileGridData = this.fetchFileGridData(uploadedFileData);
            const attachmentList = this.backupAttachmentGridData.concat(fileGridData);
            this.fileUploadedSuccessfully = true;
            this.fileUploadGridData = attachmentList;
            this.backupAttachmentGridData = attachmentList;
            e.target.value = '';
        }else {
            this.uiService.toastService.error('There is an error while uploading attachment');
        }      
    }

    fetchFileGridData(uploadedFileData){
        const filDataForGrid: any = [];
        uploadedFileData.map((fileData)=>{
            const data = {
                fileName: fileData.fileName,
                description: fileData.description,
                createUser: fileData.createUser,
                createDate: fileData.createDate,
                tariffLogDocID: fileData.tariffLogDocID,
                updateUser: fileData.updateUser,
                updateDate: fileData.updateDate
            }
            filDataForGrid.push(data);
        })
        return filDataForGrid;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in downloading attachment '))
    async downloadAttachment(fileID): Promise<void> {
        const response: any = await this.tariffLogDataService.downloadAttachment(fileID);
        window.open(response, '_blank', 'noreferrer');
        this.uiService.toastService.success('Attachment downloaded successfully');
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in deleting attachment '))
    async deleteAttachment(docID): Promise<void> {
        const response: any = await this.tariffLogDataService.deleteAttachment(docID);
        if(response){
            const filteredData = this.fileUploadGridData.filter((item) =>  item.tariffLogDocID !== docID);
            this.uiService.toastService.success('Attachment deleted successfully');
            this.fileUploadGridData = filteredData;
            this.backupAttachmentGridData = this.fileUploadGridData;
            if(filteredData.length < 1) {
                this.fileUploadedSuccessfully = false
            }
        }else {
            this.uiService.toastService.error('There is an error while deleting attachment');
        }
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in editing attachment '))
    async updateAttachment(): Promise<void> {
        const logEntryID = this.logEntryTypes.filter((item) => item.valueText === this.selectedLogEntryType)[0].settingId;
        const filesToUpdate: any = [];
        const updatedRowsIDS = this.getUpdatedRowIDs();
        const documents = JSON.parse(JSON.stringify(this.fileUploadGridData));
        if(updatedRowsIDS.length>0){
            updatedRowsIDS.map(docId=>{
                const updatedRow: any = documents.find(item=>item.tariffLogDocID == docId)
                filesToUpdate.push({
                    'id': updatedRow.tariffLogDocID,
                    'LogEntryID': logEntryID,
                    // 'feeDetailID': this.pmcTariffFeeDetailID,
                    'user': this.createdUser,
                    'description': updatedRow.description,
                    'LogID': this.tariffLogId ? this.tariffLogId : 0
                })
            })
            const response: any = await this.tariffLogDataService.updateAttachment(filesToUpdate);
            if(response.length > 0){
                documents.map(item=>{
                    response.map(resItem=>{
                        if(resItem.tariffLogDocID == item.tariffLogDocID){
                            item.description = resItem.description,
                            item.updateUser = resItem.updateUser,
                            item.updateDate = resItem.updateDate
                        }
                    })
                })
                this.fileUploadGridData = documents;
                this.cellValueChangeMapAttachment = {};
                this.agGridService.getNodes()?.refreshCells();
                this.backupAttachmentGridData = documents;
                this.uiService.toastService.success('Attachment updated successfully')
            }else {
                this.uiService.toastService.error('There is an error while updating attachment');
            }
        }    
    }

    @Loader
    @action
    @Catch(() => errorHandler('Error in editing attachment '))
    async updateAllAttachmentLogId(tariffLogId): Promise<void> {
        const logEntryID = this.logEntryTypes.filter((item) => item.valueText === this.selectedLogEntryType)[0].settingId;
        const filesToUpdate: any = [];
        const documents = JSON.parse(JSON.stringify(this.fileUploadGridData));
        if(documents.length>0){
            documents.map(updatedRow=>{
                if(!updatedRow.tariffLogID || updatedRow.tariffLogID !== tariffLogId) {
                    filesToUpdate.push({
                        'id': updatedRow.tariffLogDocID,
                        'LogEntryID': logEntryID,
                        // 'feeDetailID': this.pmcTariffFeeDetailID,
                        'user': updatedRow.createUser,
                        'description': updatedRow.description,
                        'LogID': tariffLogId
                    })
                } 
            })
            if(filesToUpdate.length < 1 )
            return;
            const response: any = await this.tariffLogDataService.updateAttachment(filesToUpdate);
            if(response.length > 0){
                documents.map(item=>{
                    response.map(resItem=>{
                        if(resItem.tariffLogDocID == item.tariffLogDocID){
                            item.description = resItem.description,
                            item.updateUser = resItem.updateUser,
                            item.updateDate = resItem.updateDate
                        }
                    })
                })
                this.fileUploadGridData = documents;
                this.cellValueChangeMapAttachment = {};
                this.agGridService.getNodes()?.refreshCells();
                this.backupAttachmentGridData = documents;
                this.uiService.toastService.success('Attachment updated successfully')
            }else {
                this.uiService.toastService.error('There is an error while updating attachment');
            }
        }    
    }

    @Loader
    @action
     @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
     async saveLogEntry(requestBody, logEntryType?, buttonTitle?): Promise<void> {
        if(this.fileUploadedSuccessfully || this.selectedLogEntryType === TariffLogEntry.TariffFilingIndex || this.isLogEntrySaved) {
            if(requestBody.length !== 0) {
                const response: any = await this.tariffLogDataService.saveLogEntryFromLogEntryScreen(requestBody, logEntryType);
                if(response && response.tariffLogID){
                    this.showLoader = true;
                    dataStore.setShowUnsavedWarningAlert(false);
                    this.fileUploadedSuccessfully = false;
                    this.agGridServiceForVersionSelection.deselectAllRows();
                    this.cellValueChangeMap = {};
                    if(response.workflowID && response.tariffLogID) {
                        const data = {
                            workflowId: response.workflowID,
                            logId : response.tariffLogID, 
                            tariffNumber: response.tariffNumber ? response.tariffNumber : requestBody.tariffNumber
                        }
                        await this.updateWFTariffLogInfo(data);
                        this.updateAllAttachmentLogId(response.tariffLogID);
                    }
                    // this.readonlyView = true;
                    this.isLogEntrySaved = true;
                    const comment = this.comment;
                    const criteria = this.selectedLogEntryType === TariffLogEntry.Maintenance ?  requestBody.testInd === true? 'Test' : 'No Test' :   this.selectedLogEntryType === TariffLogEntry.TariffFiling || this.workflowstepName === 'Complete Test Determination' ? requestBody.testInd === true? 'Test' : '' : null;
                    await this.saveWorkflowComments(criteria).then(async() => {
                        if((this.selectedLogEntryType === TariffLogEntry.TariffFiling ) && requestBody.testInd === false ) {
                            const linkProjectStep = this.workFlowDetailsDataGrid.find((item) => item.workflowstepName === 'Link Project');
                            const workflowStepId = linkProjectStep ? linkProjectStep.workflowstepId : null;
                            const workflowStepFlowId = linkProjectStep ? linkProjectStep.workflowStepFlowId : null;
                            if(workflowStepId) {
                                this.workflowStepId = workflowStepId;
                                this.workflowStepFlowId = workflowStepFlowId
                                    this.skipLinkProject(comment);
                            }
                        } else {
                            if(this.selectedLogEntryType === TariffLogEntry.Maintenance && requestBody.testInd === true) {
                                this.autoUpdateWorkflowStepstatusComplete(this.workflowStepId);
                                this.workflowStepId = response.nextStepID
                                await this.sendEmailWorkflow(comment)
                            }
                        }
                        if(buttonTitle === 'Save') {
                            history.push('/TariffDashboard');
                        } else {
                            this.createNewIndexLogEntry()
                        }
                        
                    });
                    if(requestBody.tariffLogID && requestBody.tariffLogID !== 0) {
                        this.uiService.toastService.success('Log Entry Updated Successfully')
                    } else  {
                        this.uiService.toastService.success('Log Entry Created Successfully')
                    }   
                    this.showLoader = false;
                } else {
                    errorHandler(response);
                }
            } 
        } else {
            errorHandler('Referenced document is not yet uploaded. Please upload the document before saving.')
        }       
    }

     
    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + '\n \u2022 ' + error.response?.data?.toString())})
    async saveVMACSSetup(requestBody): Promise<void> {
       if(requestBody.length !== 0) {
           const response: any[] = await this.tariffLogDataService.saveVMACSSetup(requestBody);
           if(response){
                this.uiService.toastService.success('VMACS Entry Created Successfully')
                dataStore.setShowUnsavedWarningAlert(false);
            }
        } 
    }    
    
    isEditDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isEditVMACSSetupDisabled = () => {
        const pipelineID = this.selectedBaseTariffDetails[0]?.pipeline ? this.baseSystemData.filter((i) => this.selectedBaseTariffDetails[0]?.pipeline.split(',').includes(i.plName)).map((x) => x.pipelineID) : [];
        return !dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLogVMACSSetup, pipelineID ? pipelineID: [])
    }

    isEditMaintenanceLogDisabled = () => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLogMaintenance)
    }

   

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    getMaintenanceGridUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
        // get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMapAttachment) {
            for (const col in this.cellValueChangeMapAttachment[row]) {
                const obj = this.cellValueChangeMapAttachment[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateRowAttachment = (selectedRowData: any): void => {
        const updatedShopIndex = this.fileUploadGridData.findIndex(a => a.tariffLogDocID == selectedRowData.tariffLogDocID);
        if (!_.isEqual(this.backupAttachmentGridData[updatedShopIndex], selectedRowData)) {
            this.mapAttachmentEditableColumns(this.fileUploadGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        } 
    };

    mapAttachmentEditableColumns(currentItem: any, updatedItem: any) {
        currentItem.tariffLogDocID = updatedItem.tariffLogDocID;
        currentItem.description = updatedItem.description;
        currentItem.updateUser = updatedItem.updateUser;
        currentItem.updateDate = updatedItem.updateDate;
    }

    isCancelsFieldDisabled = (newTariffFiling) => {
        if(this.selectedLogEntryType.toLowerCase() === 'Request'.toLowerCase()) {
            return this.isEditDisabled() || !newTariffFiling
        }
        return this.isEditDisabled() 
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async sendEmailWorkflow(comment?, criteria?): Promise<void> {
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': 'approve',
            'criteria': criteria ?  criteria : '',
            'useremail': this.userName,
            'additionalInfo' : comment ? comment : this.comment
        }
        const response: any = await this.tariffLogDataService.logEntrySendEmailWorkflow(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            }
        }       
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async skipLinkProject(comment?): Promise<void> {
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': 'approve',
            'criteria': 'no test',
            'useremail': 'system skipped',
            'additionalInfo' : comment ? comment : this.comment
        }
        const response: any = await this.tariffLogDataService.logEntrySendEmailWorkflow(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            }
        }       
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async rejectWorkflowAction(criteria, comment?): Promise<void> {
        const requestBody= {
            'workflowStepId': this.workflowStepId,
            'direction': 'reject',
            'criteria': criteria,
            'useremail': this.userName,
            'additionalInfo': comment ? comment : this.comment
        }
        const response: any = await this.tariffLogDataService.logEntrySendEmailWorkflow(requestBody);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    // this.uiService.toastService.success('Email Sent Successfully!')
            }
        }       
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async saveWorkflowComments(criteria?): Promise<void> {
        if(!this.comment || this.comment === ''){
            await this.sendEmailWorkflow(null, criteria);
            await this.getWorkflowStatusGridData();
            this.comment='';
            return;
        }
        const response: any = await this.tariffLogDataService.saveWorkflowComments(this.workflowId, this.comment, this.userName, this.workflowStepFlowId);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    this.getWorkflowStatusGridData();
                    this.sendEmailWorkflow(this.comment, criteria);
                    this.comment=''
                    this.uiService.toastService.success('Comments Saved Successfully!');
            }
        }       
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async updateWFTariffLogInfo(data): Promise<void> {
        const response: any = await this.tariffLogDataService.updateWFTariffLogInfo(data.workflowId, data.logId, data.tariffNumber);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
        }       
    }

    @action
    editComment = (value) => {
        this.comment = value;
    }

    getComment= () => {
        return this.comment;
    }

    @Loader
    @action
    @Catch(() => errorHandler('Fetch Error while create new index log entry'))
    async createNewIndexLogEntry(): Promise<void> {
        this.isLogProcessed = false;
        this.readonlyView = false;
        this.tariffLogId = null;
        this.selectedBaseTariff = 'Select'
        this.selectedBaseTariffDetails = [];
        this.fileUploadGridData = [];
        this.backupAttachmentGridData = this.fileUploadGridData;
        this.isProjectNameSaved = false;
        if(this.isLogEntrySaved) {
            this.isLogEntrySaved = false;
            this.getWorkflowInstanceData();
        }
        this.lastCompletedtWorkflowstepName = null ;
        this.lastCompletedstepWorkflowId = null;
        this.resetLogEntryForm  = true;
        this.projectUnlinkEnable = true;
        this.comment = '';
        this.criteria = null ;
        window.scroll(0, 0);
    }

    @Loader
    @action
    @Catch(() => errorHandler('cancel Workflow Error'))
    async cancelEditing(): Promise<void> {
        if(!this.isLogEntrySaved && this.workflowStepId && !this.tariffLogId) {
            this.cancelWorkflow(); 
        }
        history.push('/TariffDashboard');
    }

    @action 
    resetData() {
        this.selectedBaseTariff = 'Select'
        this.selectedBaseTariffDetails = [];
        this.resetLogEntryForm  = true;
        this.isLogProcessed = false;
        this.readonlyView = false;
        this.tariffLogId = null;
        this.isLogEntrySaved= false;
        this.fileUploadGridData = [];
        this.backupAttachmentGridData = this.fileUploadGridData;
        this.comment = '';
        this.workflowstepName = null;
        this.lastCompletedtWorkflowstepName = null ;
        this.lastCompletedstepWorkflowId = null;
        this.isProjectNameSaved = false;
        this.projectUnlinkEnable = true;
        this.criteria = null;
        window.scroll(0, 0);
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async cancelWorkflow(): Promise<void> {
        const response: any = await this.tariffLogDataService.cancelWorkflow(this.userName, this.workflowStepId);
        if(response){
            console.log(response);
        }
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async autoUpdateWorkflowStepstatusComplete(workflowStepId): Promise<void> {
        const response: any = await this.tariffLogDataService.autoUpdateWorkflowStepstatusComplete(workflowStepId);
        if(response){
            console.log(response);
        }
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async autoUpdateWorkflowStepstatusReject(workflowStepId): Promise<void> {
        const response: any = await this.tariffLogDataService.autoUpdateWorkflowStepstatusReject(workflowStepId, this.userName);
        if(response){
            console.log(response);
        }
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async saveTestDetermination(projectName, tariffNumber, saveButtonTitle): Promise<void> {
        const prjName = projectName ?  projectName : ''
        const projectNameData = this.projectNames.find((item) => item.name === projectName);
        const response: any = await this.tariffLogDataService.saveTestDetermination(prjName, this.tariffLogId, this.userName, projectNameData ?  projectNameData.workflowID : 0 );
        if(response && !isNaN(parseInt(response)) && response !== 0){
            this.showLoader = true;
            if(saveButtonTitle === 'Unlink/Link New Project') {
                this.workflowStepId = this.lastCompletedstepWorkflowId;
                // const linkProjectStep = this.workFlowDetailsDataGrid.find((item) => item.workflowstepName === 'Link Project');
                // const workflowStepId = linkProjectStep ? linkProjectStep.workflowstepId : null;
                // if(workflowStepId) {
                //     this.workflowStepId = workflowStepId;
                //     this.rejectWorkflowAction()
                // }
            }
            this.isProjectNameSaved = true;
            if(this.workflowId && this.tariffLogId && tariffNumber) {
                const data = {
                    workflowId: this.workflowId,
                    logId : this.tariffLogId, 
                    tariffNumber: tariffNumber
                }
                await this.updateWFTariffLogInfo(data);
                this.updateAllAttachmentLogId(this.tariffLogId)
            }
            const comment = this.comment;
            await this.saveWorkflowComments();
            this.workflowStepId = response;
            this.sendEmailWorkflow(comment).then(() => {
                this.uiService.toastService.success('Linked Project Successfully');
                this.autoUpdateWorkflowStepstatusComplete(this.lastCompletedstepWorkflowId);
                history.push('/TariffDashboard');
                this.showLoader = false;
            });
            
        } else if(response === 0){
            this.showLoader = true; 
            if(saveButtonTitle === 'Unlink/Link New Project') {
                this.workflowStepId = this.lastCompletedstepWorkflowId;
                const linkProjectStep = this.workFlowDetailsDataGrid.find((item) => item.workflowstepName === 'Link Project');
                const workflowStepId = linkProjectStep ? linkProjectStep.workflowstepId : null;
                const workflowStepFlowId = linkProjectStep ? linkProjectStep.workflowStepFlowId : null;
                if(workflowStepId) {
                    if(!this.comment || this.comment === ''){
                        this.workflowStepId = workflowStepId;
                        this.workflowStepFlowId = workflowStepFlowId;
                        await this.rejectWorkflowAction('Unlink Project').then(async () => {
                            await this.autoUpdateWorkflowStepstatusReject(workflowStepId).then(() => {
                                this.uiService.toastService.success('Unlinked Project Successfully');
                                this.comment='';
                                history.push('/TariffDashboard');
                                this.showLoader = false;
                            });
                        });
                        return;
                    }
                    const response: any = await this.tariffLogDataService.saveWorkflowComments(this.workflowId, this.comment, this.userName, workflowStepFlowId);
                    if(response){
                        let error ='';
                        for (const key in response) {                       
                            if (key==='value') {
                                error = response[key]; 
                            } else if (key==='statusCode' && response[key]===400) {
                                this.uiService.toastService.error(error);
                            } 
                        }
                        if(error===''){
                                // this.getWorkflowDetails();
                                this.workflowStepId = workflowStepId;
                                this.workflowStepFlowId = workflowStepFlowId;
                                await this.rejectWorkflowAction('Unlink Project', this.comment).then(async () => {
                                    await this.autoUpdateWorkflowStepstatusReject(workflowStepId).then(() => {
                                        this.uiService.toastService.success('Unlinked Project Successfully')
                                        history.push('/TariffDashboard');
                                        this.showLoader = false;
                                    });
                                });
                                this.comment=''
                                this.uiService.toastService.success('Comments Saved Successfully!');
                        }
                    }
                }
                
            }
        } else {
            errorHandler(response);
        }
    }

    @action 
    isProjectNameDisabled = () => {
        console.log(this.projectUnlinkEnable);
        if(this.lastCompletedtWorkflowstepName && this.lastCompletedtWorkflowstepName === 'Link Project' && this.tariffLogId) {
            const pipelineID = this.selectedBaseTariffDetails[0]?.pipeline ? this.baseSystemData.filter((i) => this.selectedBaseTariffDetails[0]?.pipeline.split(',').includes(i.plName)).map((x) => x.pipelineID) : [];
            return !dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.TestDetermination, pipelineID ? pipelineID : []) || !this.projectUnlinkEnable

        }
        if(this.selectedLogEntryType === TariffLogEntry.Maintenance) {
            return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.TariffLogMaintenance) || this.tariffLogId;
        }
        const pipelineID = this.selectedBaseTariffDetails[0]?.pipeline ? this.baseSystemData.filter((i) => this.selectedBaseTariffDetails[0]?.pipeline.split(',').includes(i.plName)).map((x) => x.pipelineID) : [];
        return !dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.TestDetermination, pipelineID ? pipelineID : [])
    }

    @Loader
    @action
    @Catch((error) => {console.log(error);errorHandler(error.message + Object.values(error.response?.data?.errors).map(x=> '\n \u2022 ' + x))})
    async rejectButtonClick(): Promise<void> {
        this.showLoader = true;
        if(!this.comment || this.comment === ''){
            this.uiService.toastService.error('Comment is required!');
            this.showLoader = false;
            return;
        }
        const response: any = await this.tariffLogDataService.saveWorkflowComments(this.workflowId, this.comment, this.userName, this.workflowStepFlowId);
        if(response){
            let error ='';
            for (const key in response) {                       
                if (key==='value') {
                    error = response[key]; 
                } else if (key==='statusCode' && response[key]===400) {
                    this.uiService.toastService.error(error);
                } 
            }
            if(error===''){
                    // this.getWorkflowDetails();
                    this.rejectWorkflowAction('', this.comment).then(() => {
                        this.getWorkflowStatusGridData();
                        this.uiService.toastService.success('Comments Saved Successfully!');
                        this.uiService.toastService.success('Workflow Step Rejected Successfully!');
                        this.comment='';
                        this.showLoader = false;
                    });
                    
            }
        }    
    }

}
