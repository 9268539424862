export class AppConstant {
    static currentYear = new Date().getFullYear();
    static AUTHTOKEN = 'AUTH_TOKEN';
    static LOGINTIME = 'LOGIN_TIME';
    static USERID = 'USER_ID';
    static DEFAULT_USER_DETAILS = {
        userName: '',
        roles: [],
        value: '',
        displayName: '',
        expiry: '',
        isLoggedIn: false
    };
    static USER_INFO = 'USER_INFO';
    static ROLE_ACCESS_DATA = 'ROLE_ACCESS_DATA';
    static DEFAULT_USER_LOCATION = 'DEFAULT_USER_LOCATION';
    static DEFAULT_USER_PIPELINEID = 'DEFAULT_USER_PIPELINEID';
    static PRIMARY_APPROVER = 'PRIMARY_APPROVER';
    static GRID_DEFAULT_PAGINATION = 100;
    static DATE_TIME_FORMAT = 'MM/DD/YYYY HH:mm';
    static MST_TIME_FORMATTER='America/Denver';
    static CST_TIME_FORMATTER='America/Chicago';
    static DATE_FORMAT = 'MM/DD/YYYY';
    static API_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
    static UNAUTHORIZED_USER = 'You are not authorized to access this application';
    static REFRESH_CONFIRMATION = 'Unsaved changes / selection will be lost. Do you want to refresh ?';
}
