import { action, computed, observable, toJS, makeAutoObservable, runInAction } from 'mobx';
import { UiService, uiService } from '../../../../shared/services/ui-service';
import Catch from '../../../../shared/decorators/catch-decorator';
import Loader from '../../../../shared/decorators/loader-decorator';
import { errorHandler } from '../../../../shared/handlers/error-handler';
import { ToastMessage } from '../../../../shared/components/custom-toast/custom-toast';
import { AgGridService } from '../../../../common/services/ag-grid-service';
import _, { isEmpty, update } from 'lodash';
import { filingEntityColDef } from '../components/filing-entity-col-def';
import moment from 'moment';
import Path from '../../../../Path';
import axios from 'axios';
import { FilingEntityMaintenanceDataService } from '../services/filing-entity-maintenance-data-service';
import { FilingEntityMaintenanceMessages, ServiceStatus } from '../constants/enums';
import { AccountStore } from '../../../account/stores/account-store';
import { addEditFilingEntityMaintenanceDataStore, filingEntityMaintenanceDataStore } from '.';
import { SyncStatus } from '../../../../common/constants/enum';
import { dataStore } from 'common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { ConfirmWithOK, ConfirmWithOKProps } from 'shared/components/confirm/confirmWithOk';
import history from '../../../../shared/components/header-component/component/history';

export class FilingEntityMaintenanceDataStore {
    @observable filingEntityMaintenanceData: any[] = [];
    @observable modifiedFilingEntityMaintenanceData: any[] = [];
    @observable cellValueChangeMap: any = {};
    @observable showLoader = false;
    userID = 0;
    userName = '';
    backupFilingEntityMaintenanceData: any[] = [];
    isRefreshed = false;
    @observable showModal = false;
    @observable isSearchClicked = false;
    @observable selectedgrade = '';
    @observable selectedgradeName = '';
    @observable selectedStatus = 'All';
    @observable selectedFilingEntity = { value: 'All', label: 'All' };
    @observable selectedPipelineSystem = { value: 'All', label: 'All' };
    endDate: any = moment().toISOString().slice(0, 10);
    @observable pipelineSystemDropdownOptions: any[] = [];
    @observable filingEntityDropdownOption: any[] = [];
    @observable statusDropdownOptions: any[] = ServiceStatus;
    @observable selectedFilingEntityIds: any[] = [];
    tariffStatesData: any[] = [];
    pipelineSystemData: any[] = [];
    pipelineSystemDataLinker: any[] = [];
    filingEntityDropdownData: any[] = [];
    baseSystemData: any[] = [];
    
    @observable pipelineSystemDropdownOption: any[] = [];
    @observable locnNameDropdownOption: any[] = [];
    @observable selectedFilingEntityDetails: any = {};
    @observable addOrEditedLocnName = '';
    @observable selectedFilingEntityId = 0;
    @observable addOrEditFilingEntityGridData: any[] = [];
    @observable backupAddOrEditFilingEntityGridData: any[] = [];
    @observable onClickFilingEntityOnGrid = false;
    @observable onClickAddNewFilingEntityButton = false;
    @observable isActivateButtonDisabled = true;
    @observable updatedFieldonGradeDetailsScreen = false;

    constructor(
        public filingEntityMaintenanceDataService: FilingEntityMaintenanceDataService,
        public agGridService: AgGridService,
        public uiService: UiService,
        public accountStore: AccountStore,
        public agGridServiceForAddOrEditFilingEntity: AgGridService
    ) {
        makeAutoObservable(this);
    }

    init(): void {
        this.pipelineSystemDropdownOptions = [];
        dataStore.setShowUnsavedWarningAlert(false);
        this.getFilingEntityDropdown();
        this.getPipelineSystemDropdown();
        // this.getBaseSystemDropdown();
        // this.getLocationDropdownData();
        this.selectedgrade = '';
        this.selectedgradeName = '';
        this.selectedStatus = 'All';
        this.selectedPipelineSystem = { value: 'All', label: 'All' };
        // this.loadViewModel();
        // this.userID = this.accountStore.getUserID();
        // this.userName = this.accountStore.displayName;
        // this.onSelectJurisdiction(this.selectedlocnName);
        this.userName = this.accountStore.displayName;
        this.selectedFilingEntityId = 0;
        this.selectedFilingEntityDetails = {};
    }

    @Loader
    @action
    @Catch(() => errorHandler(FilingEntityMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async loadViewModel() {
        // const pageSize = this.agGridService.paginationGetPageSize();
        // if(dataStore.isPageSizeChanged) {
        //     this.agGridService.resetCache(Number(pageSize));
        // }
        // const dataSource = {
        //     getRows: (params) => {
        //     // Use startRow and endRow for sending pagination to Backend
        //     // params.startRow : Start Page
        //     // params.endRow : End Page
        //         const pageSize = this.agGridService.paginationGetPageSize();
        //         // console.log('loadViewtriggered');
        //         const page =  params.endRow / (pageSize ? pageSize : 100);
        //         const pageNo = this.isRefreshed || dataStore.isPageSizeChanged || page <= 1 ? 1 : parseInt(page.toFixed(0));
        //         const data = this.getapiRequest(pageNo, pageSize, params.sortModel);
        //         const filterRequestBody: any = [];
        //         for (const [key, value] of Object.entries(params.filterModel)) {
        //             console.log(key, value);
        //             const item = {
        //                 propertyName: key,
        //                 value: value && value['filterType'] === 'date'? value['dateFrom'] : value ? value['filter']:'',
        //                 operator: value ? value['type'] : '',
        //                 joinPrev: 'none'
                    
        //               }
        //               filterRequestBody.push(item);
        //           }
        //           console.log('params' +JSON.stringify(filterRequestBody));
        //         this.agGridService.showLoadingOverlay();
        //         this.filingEntityMaintenanceDataService.getTariffEntityGrid(data, filterRequestBody).then((response) => {
        //         const totalCount = response && response.length > 0 ? response[0].totalCount : response.length
        //             params.successCallback(response ? response : [], totalCount);
        //             dataStore.setCacheBlockSize(response? response.length : 0)
        //             this.agGridService.hideOverlay();
        //             this.isRefreshed = false;
        //             dataStore.setPageSizeChanged(false); 
        //         }).catch(() => {
        //             params.successCallback([], 0);
        //             dataStore.setCacheBlockSize(0);
        //             this.isRefreshed = false;
        //             dataStore.setPageSizeChanged(false);
        //             this.agGridService.hideOverlay();
        //             errorHandler(FilingEntityMaintenanceMessages.FETCH_ERROR_MESSAGE)
        //         });
        //         dataStore.setCacheBlockSize(Number(pageSize));
        //     }
        // }
        // this.agGridService.setDatasource(dataSource);
        const data = this.getapiRequest();
        const filterRequestBody  = null
       const response: any =  await this.filingEntityMaintenanceDataService.getTariffEntityGrid(data, filterRequestBody);
       this.setFilingEntityMaintenanceData(response);
       
    }

    getapiRequest = (currentPage?, pageSize?, sortModel?) => {
        if(this.isSearchClicked) {
            const pipelineSystemId = this.selectedPipelineSystem.label === 'All' ? 0 : this.pipelineSystemData.filter((item) => item.plName === this.selectedPipelineSystem.label)[0].pipelineID;
            const entityId = this.selectedFilingEntity.label === 'All' ? 0 : this.filingEntityDropdownData.filter((item) => item.name === this.selectedFilingEntity.label)[0].entityID;
            const data = {
                pipelineSystemId: pipelineSystemId, 
                entityId: entityId,
                // page: currentPage ? currentPage : 1,
                // pageSize : pageSize ? pageSize : 100,
                // sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                // sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        } else {
            const data = {
                pipelineSystemId: 0, 
                entityId: 0,
                // page: currentPage ? currentPage : 1,
                // pageSize : pageSize ? pageSize : 100,
                // sortColId: sortModel && sortModel.length > 0 ? sortModel[0].colId : '',
                // sort:  sortModel && sortModel.length > 0 ? sortModel[0].sort : '',
            }
            return data;
        }
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getFilingEntityDropdown(): Promise<void> {
        const filingEntityDropdownOptions = await this.filingEntityMaintenanceDataService.getTariffEntity();
        this.filingEntityDropdownData = filingEntityDropdownOptions;
        const a = filingEntityDropdownOptions.map(x => {return { 'value': x.name, 'label': x.name}});
        const sortedValues =  _.orderBy(a, [x=>x.label], ['asc']);
        runInAction(() => {
            this.showLoader = false;
            this.filingEntityDropdownOption = sortedValues;
        });
    }

    @action
    setFilingEntity(filingEntity: any): void {
        this.selectedFilingEntity = filingEntity;
        this.selectedPipelineSystem = { value: 'All', label: 'All' };
        this.getPipelineSystemDropdown();
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineSystemDropdown(): Promise<void> {
        const entityId = this.filingEntityDropdownData.filter(x => x.name == this.selectedFilingEntity.label)[0]?.entityID || 0;
        const pipelineSystemDropdownOptions = await this.filingEntityMaintenanceDataService.getPipelineSystem(entityId);
        this.pipelineSystemData = pipelineSystemDropdownOptions;
        const a = pipelineSystemDropdownOptions.map(x => {return { 'value': x.plName, 'label': x.plName}});
        const result = Object.values(
            a.reduce((acc, obj) => ({ ...acc, [obj.label]: obj }), {})
        );
        
        const sortedValues =  _.orderBy(result, [(x : any)=>x.label], ['asc']);
        runInAction(() => {
            this.showLoader = false;
            this.pipelineSystemDropdownOption = sortedValues;
        });
    }

    @action
    @Catch(() => errorHandler('Fetch Error'))
    async getPipelineSystemDataLinker(): Promise<void> {
        const entityId = 0;
        const pipelineSystemDropdownOptions = await this.filingEntityMaintenanceDataService.getPipelineSystem(entityId);
        const result = Object.values(
            pipelineSystemDropdownOptions.reduce((acc, obj) => ({ ...acc, [obj.pipelineID]: obj }), {})
        );
        this.pipelineSystemDataLinker = result;
    }

    @action
    createDataSourceGroupByFilingEntity(response) {
        const array = response
        const result = array.reduce((r, { locnName: locnName, ...object }) => {
        let temp = r.find(o => o.locnName === locnName);
        if (!temp) r.push(temp = { locnName, children: [] });
        temp.children.push(object);
        return r;
    }, []);
    
    console.log(result);
    }

    @Loader
    @Catch(() => errorHandler(FilingEntityMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onClearSearch(): Promise<void> {
        this.isSearchClicked = false;
        this.isRefreshed = true;
        dataStore.onGridReady(); 
        this.selectedFilingEntity = { value: 'All', label: 'All' };
        this.selectedPipelineSystem = { value: 'All', label: 'All' };
        this.getPipelineSystemDropdown();
    }

    @Loader
    @Catch(() => errorHandler(FilingEntityMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async onSearchClick(): Promise<void> {
           this.isRefreshed = true;
           this.isSearchClicked = true;
           dataStore.onGridReady();
    }

    getColDef() {
        // this.updatePushtoVmacsButtonColDef();
        return filingEntityColDef;
    }

    @action
    reset(): void {
        this.setFilingEntityMaintenanceData(this.backupFilingEntityMaintenanceData);
        this.modifiedFilingEntityMaintenanceData = this.backupFilingEntityMaintenanceData;
    }

    @action
    setShowModal(): void {
        this.showModal = !this.showModal;
    }

    @action
    setFilingEntityMaintenanceData(filingEntityMaintenanceData: any[]): void {
        this.filingEntityMaintenanceData = filingEntityMaintenanceData
    }

    setbackupFilingEntityMaintenanceData(backUpList: any[]) {
        this.backupFilingEntityMaintenanceData = backUpList;
    }

    @computed
    get FilingEntityMaintenanceData() {
        return toJS(this.filingEntityMaintenanceData);
    }

    @action
    showTicketApproverPopUp() {
        this.showModal = true;
    }

    @action
    hideTicketApproverPopUp() {
        this.showModal = false;
    }
 addValuesInCellDropdowns(): void {
        // const baseSystemDropdownOptions = this.selectedlocnName !== 'All' ? this.baseSystemDropdownOptions.filter((item) => item.valueText  === this.selectedlocnName) : this.baseSystemDropdownOptions;
        // this.agGridService.updateOptionCellEditorValues(
        //     baseSystemDropdownOptions,
        //     'pipeline',
        //     'plName'
        // );

        this.agGridServiceForAddOrEditFilingEntity.updateOptionCellEditorValues(
            ServiceStatus,
            'serviceStatus',
            'status'
        );
    }
     

    getUpdatedRowIDs(): string[] {
        let updatedRowIDs: string[] = [];
      //  get updated rows id  here from changedMap
        for (const row in this.cellValueChangeMap) {
            for (const col in this.cellValueChangeMap[row]) {
                const obj = this.cellValueChangeMap[row][col];
                if (obj.initValue !== obj.currentValue) {
                    updatedRowIDs = [...updatedRowIDs, row];
                    break;
                }
            }
        }
        return updatedRowIDs;
    }

    @action
    updateColumn(item: any, value: string, key: string, popup?: boolean ) {
        this.setValueInChangeMap(item.ID, key, item[key], value);
        item[key] = value;
       // this.updateRow(item);
    }

    isSaveDisabled = ()  => {
        return !dataStore.checkOperationAccess(RoleBasedActionName.Save);
    };

    isDeleteDisabled = ()  => {
      const requestBody: any = this.addOrEditFilingEntityGridData.filter(a => a.isSelected === true);
      console.log(requestBody);
        return !this.selectedFilingEntityId || requestBody.length > 0;
    };

    setValueInChangeMap(row: number, col: string, initValue: string, newValue: string) {
        if (!(row in this.cellValueChangeMap)) {
            this.cellValueChangeMap[row] = {};
        }
        if (!(col in this.cellValueChangeMap[row] && this.cellValueChangeMap[row][col].initValue))
            this.cellValueChangeMap[row][col] = { initValue: initValue };
        else this.cellValueChangeMap[row][col]['currentValue'] = newValue;
    }

    updatePushtoVmacsButtonColDef = () => {
        const pushToVmacsButtonColDef = filingEntityColDef.find(x => x.colId === 'PushToVMACS');
        if (pushToVmacsButtonColDef) {
            pushToVmacsButtonColDef.hide = !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd)) ? true : false,
            pushToVmacsButtonColDef.suppressColumnsToolPanel= !(dataStore.checkOperationAccessWithModule(RoleBasedActionName.View, MasterDataComponentModulesName.VMACSSyncTest) ||  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.VMACSSyncProd))? true : false,
            pushToVmacsButtonColDef.cellRendererParams = {
                dataStore : filingEntityMaintenanceDataStore,
                handleClickPushVmacs : (env, data) => this.pushToVmacs(env, data),
                updatedData : this.getUpdatedRows() 
            };
        }
    }

    isActivateDeactivateDisabled = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ActivateAndDeactivate);
    };

    getUpdatedRows = () => {
        const updatedRowIDs = this.filingEntityMaintenanceData.filter(a => this.getUpdatedRowIDs().includes(a.gradeID.toString()));
        return updatedRowIDs;
    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushToVmacs(env, data): Promise<void> {
        // const action = data.action === 'U' ? 'Update' : 'Insert';
        const gradeRelPLID =  data.gradeRelPLID === 0 ? '' :  data.gradeRelPLID.toString();
        const requestBody: any =  { 
            'http_action' : null,
            'http_record_id': data.gradeID.toString(),
            'http_childrecord_id': gradeRelPLID,
            'http_userid': this.userName,
            'http_environment': env.toUpperCase() 
        };
        console.log('pushtoVMacs')
        if(requestBody.length !== 0) {
            const response = await this.filingEntityMaintenanceDataService.pushToVmacs(requestBody);
            // if(response) {
                await this.onSearchClick();
                this.uiService.toastService.success(FilingEntityMaintenanceMessages.SYNC);
            // }
        }

    }

    @Loader
    @action
    @Catch((err) => {
        if(err && err.response.data.cts_response) {
            let msg = '';
            err.response.data.cts_response.forEach((item) => {
                msg = msg + ' ' + (item.detail ? item.detail : '');
            })
            errorHandler(msg);
        }
    })
    async pushSelectedRowsToVmacs(env): Promise<void> {
        const selectedRowData = this.addOrEditFilingEntityGridData;
        if(selectedRowData && selectedRowData.length > 0) {
            const grades = this.selectedFilingEntityId.toString();
            const linkedpipelines= (selectedRowData.filter((item) => item.gradeRelPLID && item.gradeRelPLID !== 0).map(x => x.gradeRelPLID )).join(',');
            const requestBody: any =  { 
                'http_action' : null,
                'http_record_id': grades,
                'http_childrecord_id': linkedpipelines,
                'http_userid': this.userName,
                'http_environment': env.toUpperCase()   
            };
            console.log('pushtoVMacs')
            if(requestBody.length !== 0) {
                const response = await this.filingEntityMaintenanceDataService.pushToVmacs(requestBody);
                // if(response) {
                    // await this.onSearchClick();
                    // this.openGradeDetails({locnName: this.addOrEditedLocnName});
                    this.updatedFieldonGradeDetailsScreen = false;
                    this.uiService.toastService.success(FilingEntityMaintenanceMessages.SYNC);
                // }
            }
    
        }
       
    }

    @action
   isEditableJurisdiction(params) {
        if (params.data.gradeID <= 0) {
        return true
        }
        return false;
   }
   
   mapEditableColumnsGradesMainGrid(currentItem: any, updatedItem: any) {
    currentItem.gradeRelPLID = updatedItem.gradeRelPLID;
    currentItem.gradeID = updatedItem.gradeID;
    currentItem.locnName = updatedItem.locnName;
    currentItem.locnCode = updatedItem.locnCode;
    currentItem.pipelineID = updatedItem.pipelineID;
    currentItem.plName = updatedItem.plName;
    currentItem.syncStatus = updatedItem.syncStatus;
    currentItem.testSyncDate = updatedItem.testSyncDate;
    currentItem.prodSyncDate = updatedItem.prodSyncDate;
    currentItem.serviceStatus = updatedItem.serviceStatus;
    currentItem.allowTrades = updatedItem.allowTrades;
    currentItem.serviceDate = updatedItem.serviceDate;
    currentItem.action = updatedItem.action;
}
    
    @action
    @Catch(() => errorHandler('Fetch Error'))
    async openFilingEntityDetails(data) {
        this.getPipelineSystemDataLinker();
        const entityId = data.entityID;
        const response: any = await this.filingEntityMaintenanceDataService.getTariffEntityDetails(entityId);
        
        runInAction(() => {
            const a = this.pipelineSystemDataLinker;
            if (response.tariffEntityRelPipelines.length > 0) {
                a.map(x => {
                    response.tariffEntityRelPipelines.map((item) => {
                        if (x.pipelineID == item.pipelineID) {
                            x.isSelected = true;
                            x.entitySystemID =item.entitySystemID;
                            x.action = item.action;
                            x.entityID = item.entityID;
                            x.syncStatus = item.syncStatus ? item.syncStatus : SyncStatus[0];
                            x.createUser = item.createdUser;
                            x.createDate = item.createDate;
                        }
                        x.pipelineName = x.plName;
                    })
                })
            } else {
                a.map(x => {
                    x.pipelineName = x.plName;
                })
            }
            addEditFilingEntityMaintenanceDataStore.isEditingFilingEntity = true;
            addEditFilingEntityMaintenanceDataStore.editableFilingEntityData = response;
            addEditFilingEntityMaintenanceDataStore.selectedFilingEntity = { value: response.name, label: response.name };
            addEditFilingEntityMaintenanceDataStore.selectedFilingEntityId = response.entityID;
            addEditFilingEntityMaintenanceDataStore.selectedWorkflowId = response.workflowId;
            addEditFilingEntityMaintenanceDataStore.selectedProjectName = response.projectName;
            const sortedArr = _.orderBy(a, [item=>item.pipelineName], ['asc']);
            addEditFilingEntityMaintenanceDataStore.setAddOrEditLinkerGridData(sortedArr);
            addEditFilingEntityMaintenanceDataStore.setBackUpAddOrEditLinkerGridData(sortedArr);
            
            history.push('/FilingEntityDetails', { from: '/FilingEntityMaintenance', data: data })
        });
    }

    @action
    onCloseFilingEntityDetails() {
        console.log(dataStore.showUnsavedWarningAlert);
        history.push('/FilingEntityMaintenance', { from: '/FilingEntityDetails' });   
    }


    @Loader
    @Catch(() => errorHandler(FilingEntityMaintenanceMessages.FETCH_ERROR_MESSAGE))
    async getSelectedFilingEntityData(): Promise<void> {
            runInAction(() => {
                this.addOrEditedLocnName = '';
                this.addOrEditFilingEntityGridData = [];
                this.backupAddOrEditFilingEntityGridData = [];
                this.cellValueChangeMap = {};
                this.pipelineSystemData.map((item) =>
                 {
                    item.gradeRelPLID = 0,
                    item.serviceStatus =  'Active',
                    item.serviceDate =  null,
                    item.allowTrades = 'N',
                    item.isSelected = false,
                    item.syncStatus = SyncStatus[0]
                    this.addOrEditFilingEntityGridData.push(item)
                });
                this.backupAddOrEditFilingEntityGridData = this.addOrEditFilingEntityGridData;
                this.isRefreshed = false;
            })
            this.agGridService.destroyGrid();
        this.agGridServiceForAddOrEditFilingEntity.getNodes()?.refreshCells();  
        dataStore.setShowUnsavedWarningAlert(false);
    }

    @action
   async onEnterGradeforAddOREdit(value) {
        if(this.addOrEditedLocnName !== value) {
            this.updatedFieldonGradeDetailsScreen = value
        }
        this.addOrEditedLocnName = value   
   }

   @computed
    get AddOrEditGradeGridData() {
        return toJS(this.addOrEditFilingEntityGridData);
    }

    @action
    updateRowforAddOrUpdateGrid = (selectedRowData: any): void => {
        const updatedShopIndex = this.addOrEditFilingEntityGridData.findIndex(a => a.pipelineID == selectedRowData.pipelineID);
        if (!_.isEqual(this.backupAddOrEditFilingEntityGridData[updatedShopIndex], selectedRowData)) {
            this.mapEditableColumnsGradeDetailsGrid(this.addOrEditFilingEntityGridData[updatedShopIndex], selectedRowData);
            dataStore.setShowUnsavedWarningAlert(true);
        }
    };

    mapEditableColumnsGradeDetailsGrid(currentItem: any, updatedItem: any) {
        currentItem.serviceStatus = updatedItem.serviceStatus;
        currentItem.allowTrades = updatedItem.allowTrades;
        currentItem.isSelected = updatedItem.isSelected
        currentItem.serviceDate = updatedItem.serviceDate;
    }

    handleAllowTradeCheckBoxChange(params, value) {
        const row = params.data.pipelineID;
        const initValue = params.data['allowTrades'];
        this.setValueInChangeMap(row, 'allowTrades', initValue, value);
        if(initValue === 'Y') {
            params.data['allowTrades'] = 'N'
        } else {
            params.data['allowTrades'] = 'Y'
        }
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data)
    }

    @action
    linkedPipelineCheckboxHandler = (params, event) => {
        const checked = event.target.checked;
        const row = params.data.pipelineID;
        const initValue = params.data.isSelected
        this.setValueInChangeMap(row, 'isSelected', initValue, checked);
        params.data.isSelected = checked;
        params.data['serviceStatus'] = (params.data.serviceStatus === 'Active' ||  params.data.serviceStatus === 'A') ? 'Active' : 'Inactive'
        this.updateRowforAddOrUpdateGrid(params.data);
    };

    @action 
    setIsActivateButtonDisabled(value) {
        this.isActivateButtonDisabled = value
    }
}
