import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class RightAngleXRefDataService {
    constructor(private restApi: RestApiService) { }
    
    async getRAXrefDetails(tariffNumber, assetGroupID, systemGroupID, pmcOperator, distributionID, tariffRatetype, raName): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCRightAngleXref+`?tariffNumber=${tariffNumber}&assetGroupID=${assetGroupID}&pipelineSystemGroupID=${systemGroupID}&pmcOperator=${pmcOperator}&distributionID=${distributionID}&tariffRatetype=${tariffRatetype}&raName=${raName}`);
        return data;
    } 

    async getRAXrefDetailsWithoutFilter(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCRightAngleXref, {});
        return data;
    }
    
    async savePMCRightAngleXref(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.savePMCRightAngleXref, reqbody, {});
        return data;
    }
    
    async removePMCRightAngleXref(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.removePMCRightAngleXref, reqbody, {});
        return data;
    }

    async getPMCRightAngleXrefSupplementaryData(tariffNumber): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCRightAngleXrefSupplementaryData + `?tariffNumber=${tariffNumber}`);
        return data;
    } 

    async getPMCTariffDetailIDs(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCTariffDetailIDs);
        return data;
    }

    async getSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getDistributionTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id28);
        return data;
    }

    async getOperators(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getOperators);
        return data;
    }

    async getRANames(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getRAName);
        return data;
    }

    async getTariffRequestTypes(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffRequestTypes + `?jurisdiction=${jurisdiction}`);
        return data;
    } 
    
    async getTariffRateTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCFeeTypes);
        return data;
    }
}

export const rightAngleXRefDataService = new RightAngleXRefDataService(restApiService);
