import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class PMCGridViewDataService {
    constructor(private restApi: RestApiService) {}

    async getAssetGroups(jurisdiction): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getTariffRequestTypes + `?jurisdiction=${jurisdiction}`);
        return data;
    }

    async getSystemGroup(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id25);
        return data;
    }

    async getPMCPipelineSystems(assetGroupID): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCPipelineSystems + `?assetGroupID=${assetGroupID}`);
        return data;
    }

    async getPipelineGroup(): Promise<any[]> {
            const { data } = await this.restApi.get<any>(Path.url_id24);
            return data;
    }

    async getDistributionTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id28);
        return data;
    }
    async getPMCFeeTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCFeeTypes);
        return data;
    }

    async getPMCTariffDetail(assetGroupID, systemGroupId, pipelineId, distributionId,startDate,endDate): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCTariffDetail+`?AssetID=${assetGroupID}&PLSystemID=${systemGroupId}&PLRegionID=${pipelineId}&DistID=${distributionId}&startDate=${startDate}&endDate=${endDate}`);
        return data;
    }

    async updateBulkTariff(user, feeType, amount, unit, reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.updateBulkTariff+`?user=${user}&feeType=${feeType}&amount=${amount}&updateBy=${unit}`, reqbody, {});
        return data;
    }
    
    async bulkDuplicateTariffCheck(startDate, reqBody): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.bulkDuplicateTariffCheck+`?startDate=${startDate}`, reqBody, {});
        return data;
    }

    async bulkDuplicateTariff(user, feeType, amount, unit, startDate, reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.bulkDuplicateTariff+`?user=${user}&feeType=${feeType}&amount=${amount}&updateBy=${unit}&startDate=${startDate}`, reqbody, {});
        return data;
    }

    async getUnits(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id29);
        return data;
    }

    async getCurrency(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id30);
        return data;
    }

    async getRegulator(): Promise<any[]> {
          const { data } = await this.restApi.get<any>(Path.getRegulator);
          return data;
       }

    async getPMCShrinkage(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCShrinkage);
        return data;
    }

    async getPMCLossAllwance(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getPMCLossAllwance);
        return data;
    }

    async getPMCDashboardDetails(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.getPMCDashboardDetails, reqbody, {});
        return data;
    }
}

export const pmcGridViewDataService = new PMCGridViewDataService(restApiService);
