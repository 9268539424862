import Path from 'Path';
import { RestApiService, restApiService } from 'shared/services/rest-api-service';

export class TariffOptionsDataService {
    constructor(private restApi: RestApiService) {}
    
    
    async getInfraSummary(workflowId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_2_getInfraSummary+`?workflowID=${workflowId}`);
        return data;
    }
     
    async getWorkflowStatusGridData(workflowId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowStatusGridDetails+`?workflowId=${workflowId}`);
        return data;
    }

    async getWorkflowHistoryStatusGridData(workflowId): Promise<any> {
        const { data } = await this.restApi.get<any>(Path.step_1_getWorkflowHistoryStatusGridDetails+`?workflowId=${workflowId}`);
        return data;
    }

    async sendEmailWorkflow(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_1_sendEmailWorkflow, reqbody, {});
        return data;
    }

    async saveWorkflowComments(workflowId, comments, username,workflowStepFlowId ): Promise<any[]> {
        const reqBody = { 'workflowID': workflowId, 'comment': comments, 'user': username, 'workflowStepFlowID': workflowStepFlowId }
        const { data } = await this.restApi.post<any>(Path.step_1_saveComments, reqBody, {});
        return data;
    }

    
    async getBaseTariffs(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.step_1_getBaseTariffs);
        return data;
    }

    async getSelectedBaseTariffDetails(id, process, logId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.url_id16}?baseID=${id}&process=${process}&logId=${logId}`);
        return data;
    }

    async getTariffOptions(baseId, logId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_GetTariffOptions}?baseID=${baseId}&logID=${logId}&includeParent=true`);
        return data;
    }

    async getCancelledTariffs(baseId, logId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_GetCancelledTariff}?baseID=${baseId}&logID=${logId}`);
        return data;
    }

    async getAgencyDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_GetAgencyDropdown);
        return data;
    }

    async getStatusDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_GetStatusDropdown);
        return data;
    }

    async getTariffTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_GetTariffTypesDropdown);
        return data;
    }

    // async getCompanyDropdown(): Promise<any[]> {
    //     const { data } = await this.restApi.get<any>(Path.tariffOptions_GetCompanyDropdown);
    //     return data;
    // }

    async getInvoiceBasisDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_GetInvoiceBasisDropdown);
        return data;
    }
    
    async getPipelineAreasDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_GetPipelineAreasDropdown);
        return data;
    }

    
    async getTariffRelationTypes(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_tariff_relation_types);
        return data;
    }

    

    // async getAdministratorDropdown(): Promise<any[]> {
    //     const { data } = await this.restApi.get<any>(Path.tariffOptions_GetAdministratorDropdown);
    //     return data;
    // }
    
    async saveTariffOptions(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveTariffOptionsIndex, formData);
        return data;
    }

    
    async editTariffOptions(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveTariffOptionsIndex, formData);
        return data;
    }
  
    async saveDeduction(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveDeduction, formData);
        return data;
    }

    
    async saveLossAllowance(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveLossAllowance, formData);
        return data;
    }
    
    async saveRelatedTariff(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveRelatedTariff, formData);
        return data;
    }

    async savePipelineArea(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_savePipelineArea, formData);
        return data;
    }

    async saveOwner(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveOwner, formData);
        return data;
    }

    async saveMovement(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveMovement, formData);
        return data;
    }

    
    async merge(request): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.step_2_merge, request);
        return data;
    }

    async deleteTariffOptions(tariffOptionId,user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deactivateOption}?optionID=${tariffOptionId}&user=${user}`, {});
        return data;
    }
      
    async saveConnectionPoint(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_saveConnectionPoint, formData);
        return data;
    }

    
    async saveComments(formData): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.tariffOptions_SaveComment, formData);
        return data;
    }

    async cloneTariffOption(tariffOptionId,user,cloneAll ): Promise<any[]> {
        const { data } = await this.restApi.post<any>(`${Path.tariffOptions_cloneOption}?optionID=${tariffOptionId}&user=${user}&cloneAll=${cloneAll}`, {});
        return data;
    }

    async getOwnerDropDown(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_ownerDropdown}?pipelineId=${pipelineId}`);
        return data;
    }
    
    async getTariffIndexRate(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_TariffIndexRate);
        return data;
    }

    async getMVTLineSegment(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_MovementGridLineSegment}?plAreaId=${pipelineId}`);
        return data;
    }

    async getMVTRateTypeDirection(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_MovementGridRateTypeDirection);
        return data;
    }

    async getMVTRateBasis(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_MovementGridRateBasis);
        return data;
    }

    
    async getMVTRateType(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.tariffOptions_MovementGridRateType);
        return data;
    }

    async getCPLocation(pipelineId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_ConnectionPointGridLocation}?baseSystemId=${pipelineId}&locationName=All&status=All`);
        return data;
    }

    async getCPTariff(locationId): Promise<any[]> {
        const { data } = await this.restApi.get<any>(`${Path.tariffOptions_ConnectionPointGridCP}?locationID=${locationId}`);
        return data;
    }

    async deleteDeduction(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deleteDeduction}?user=${user}`, reqbody, {});
        return data;
    }    
    
    
    async deleteLossAllowance(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deleteLossAllowance}?user=${user}`, reqbody, {});
        return data;
    }    
    
    
    async deleteRelatedTariff(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deleteRelatedTariff}?user=${user}`, reqbody, {});
        return data;
    }    
    
    
    async deletePipeline(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deletePipeline}?user=${user}`, reqbody, {});
        return data;
    }    
    
    
    async deleteOwner(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deleteOwner}?user=${user}`, reqbody, {});
        return data;
    }    
    
    
    async deleteCP(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deleteCP}?user=${user}`, reqbody, {});
        return data;
    }    
    
    
    async deleteMovement(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deleteMovement}?user=${user}`, reqbody, {});
        return data;
    }    

    
    async deleteComments(reqbody: any, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(`${Path.tariffOptions_deleteComments}?user=${user}`, reqbody, {});
        return data;
    }    
    
    
    
}

export const tariffOptionsDataService = new TariffOptionsDataService(restApiService);
