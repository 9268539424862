import { add } from 'lodash';
import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class FilingCarrierMaintenanceDataService {
    constructor(private restApi: RestApiService) { }
    
    async getTariffCarrier(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getFilingCarrier);
        return data;
    } 

    async getPipelineSystem(entityId): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getPipelineSystem}?entityId=${entityId}`);
        return data;
    } 

    async getFilingCarrierRelPipelinesByCustomerID(customerID): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getFilingCarrierRelPipelinesByCustomerID}?customerID=${customerID}`);
        return data;
    } 

    async getFilingCarrierRelPipelinesByEntityID(entityID): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getFilingCarrierRelPipelinesByEntityID}?entityID=${entityID}`);
        return data;
    } 

    async getTariffCarrierGrid(request, requestBody): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getFilingCarriersGrid}?carrierCode=${request.carrierCode}&carrierName=${request.carrierName}&pipelineSystemId=${request.pipelineSystemId}`, {});
        return data;
    }

    async getTariffPipelineOwner(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getTariffPipelineOwner);
        return data;
    } 

    async getTariffCarrierDetails(entityId): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(`${Path.getFilingCarriersDetails}?entityID=${entityId}`);
        return data;
    } 

    async saveTariffCarrier(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.saveFilingCarrier, reqbody, {});
        return data;
    }
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsFilingCarrierEndpoint, reqbody, {});
        return data;
    } 

    async deactivateFilingCarriereData(requestBody): Promise<any> {
        const { data } = await this.restApi.delete<any>(Path.deleteFilingCarrier+`?entityID=${requestBody.entityID}&user=${requestBody.createUser}`);
        return data;
    }  

    async activateFilingCarriereData(requestBody): Promise<any> {
        const { data } = await this.restApi.put<any>(Path.activateFilingCarrier+`?user=${requestBody.createUser}`, [requestBody.entityID], {});
        return data;
    }  

    async getFilingCarrierEntity(): Promise<any[]> {
        const { data } =  await this.restApi.get<any>(Path.getFilingCarrierEntity);
        return data;
    } 
}

export const filingCarrierMaintenanceDataService = new FilingCarrierMaintenanceDataService(restApiService);
