import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { ConnectionPointMaintenanceDataStore } from '../stores/connection-point-store';
import {
    GridOptions,
    CellEditingStoppedEvent,
    CellEditingStartedEvent,
    SelectionChangedEvent,
    PaginationChangedEvent,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { ConnectionPointsResponse } from '../model/model';
import { connectionPointMaintenanceDataStore } from '../stores';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from '../../../../shared/components/header-component/component/history';
import '../styles/connection-point.scss';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { Prompt } from 'react-router-dom';
import { ReactSelect } from 'common/components/select/select';

/* eslint-disable */
interface ConnectionPointsContainerProps {
    connectionPointMaintenanceDataStore: ConnectionPointMaintenanceDataStore;
}

interface ConnectionPointsContainerState {
    search: string;
    addRowClick: boolean;
    lastAddedSettingID: number;
}

@inject('connectionPointMaintenanceDataStore')
@observer
export class ConnectionPointMaintenanceContainer extends Component<ConnectionPointsContainerProps, ConnectionPointsContainerState> {
    constructor(props: ConnectionPointsContainerProps | Readonly<ConnectionPointsContainerProps>) {
        super(props);
        this.state = {
            search: '',
            addRowClick: false,
            lastAddedSettingID: 0,
        };
        dataStore.setModuleName('Connection Point Maintenance')
    }
    
    componentDidMount() {
      history.push('/ConnectionPointMaintenance');
      const {connectionPointMaintenanceDataStore} = this.props;
      connectionPointMaintenanceDataStore.init();
      connectionPointMaintenanceDataStore.agGridService.refreshGrid();
    }

    componentDidUpdate() {
      const filters = localStorageService.get('Connection Point Maintenance_filter');
      this.props.connectionPointMaintenanceDataStore.agGridService.setAllFilters(filters);
      const columnVisible = localStorageService.get('Connection Point Maintenance_' + accountStore.userName);
      this.props.connectionPointMaintenanceDataStore.agGridService.setColumnVisibility(columnVisible);
      // dataStore.showUnsavedWarningOnTabCloseOrReload();
    }

    onBtClearSelection = () => {
      const updatedRowIDs = connectionPointMaintenanceDataStore.connectionPointGridData.filter(a => connectionPointMaintenanceDataStore.getUpdatedRowIDs().includes(a.connectionPointID.toString()));
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.connectionPointMaintenanceDataStore.onClearSelectSettingName(),
            '',
            'All the unsaved changes will be lost. Are you sure you want to Continue?'
          );
        } else {
          this.props.connectionPointMaintenanceDataStore.onClearSelectSettingName();
        }
    }

    onAddConnectionPoint = () => {
        history.push('/ConnectionPointDetails')
    }

    onSearchClick = () => {
      const updatedRowIDs = connectionPointMaintenanceDataStore.connectionPointGridData.filter(a => connectionPointMaintenanceDataStore.getUpdatedRowIDs().includes(a.connectionPointID.toString()));
      const currentPage = connectionPointMaintenanceDataStore.agGridService.paginationGetCurrentPage();
      const pageSize = connectionPointMaintenanceDataStore.agGridService.paginationGetPageSize();
        if(updatedRowIDs.length > 0) {
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.connectionPointMaintenanceDataStore.onSearchClick(currentPage, pageSize),
            '',
            'All the unsaved changes will be lost. Are you sure you want to Continue?'
          );
        } else {
          this.props.connectionPointMaintenanceDataStore.onSearchClick(currentPage, pageSize);
        }
    }

    handleStateChangeFromDelToActive = async() => {
      const { connectionPointMaintenanceDataStore } = this.props;
      const requestBody: any = await connectionPointMaintenanceDataStore.agGridService.getSelectedRows();
      if(requestBody.length > 0 && !requestBody.some((item) => item.action !== 'D')) {
        
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => this.props.connectionPointMaintenanceDataStore.updateStatusFromDelToActive(),
            'Active',
            'Are you sure you want to activate the selected connection points?'
        );
      } else {
        const secondaryText = requestBody.length > 0 ? 'Please select only the inactive connection points to perform the operation' : 'No records are selected. Please select atleast one inactive location to perform the operation.';
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {},
          ConfirmWithOK,
          {secondaryText: secondaryText} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
      console.log(' succesfully');
    }

    onChangeBaseSystem(e) {
      this.props.connectionPointMaintenanceDataStore.selectedBaseSystem ={ value: e.value, label: e.value };
      this.props.connectionPointMaintenanceDataStore.selectedLocation ={ value: 'All', label: 'All' };
      if(e.value !== 'All') {
        const locationData = this.props.connectionPointMaintenanceDataStore.locationData.filter((item) => item.plName === e.value);
        this.props.connectionPointMaintenanceDataStore.locationDropdownOption =  locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
      } else {
        this.props.connectionPointMaintenanceDataStore.locationDropdownOption =  this.props.connectionPointMaintenanceDataStore.locationData.map(x => {return { 'value': x.locnName, 'label': x.locnName}});
      }
    }
   
    render(): React.ReactNode {
      const { connectionPointMaintenanceDataStore } = this.props;
      return (
            <Container fluid>
              <Row>
                <Col>
                    <div className='connectionPointMaintenanceView'>
                        <div className='screenHeader'>
                          <h2 className='screen_title'> Connection Point Maintenance</h2>
                        </div>
                          <div className='selectContainer'>
                            <div className='settingSelectionDiv'>
                              <label className="standard_label_style">Base System</label>
                              <ReactSelect
                                  className='select'
                                  values={connectionPointMaintenanceDataStore.selectedBaseSystem}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...connectionPointMaintenanceDataStore.baseSystemDropdownOption]}
                                  onChange={(e: any) => {this.onChangeBaseSystem(e)}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                              <label className="standard_label_style">Location</label>
                              <ReactSelect
                                  className='select'
                                  values={connectionPointMaintenanceDataStore.selectedLocation}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...connectionPointMaintenanceDataStore.locationDropdownOption]}
                                  onChange={(e: any) => {connectionPointMaintenanceDataStore.selectedLocation = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                              <label className="standard_label_style">Connection Point Name</label>
                              <input 
                                type="text"
                                value={this.props.connectionPointMaintenanceDataStore.selectedCPName}
                                className="search_text_field"
                                // disabled={true}
                                onChange = {(e) => this.props.connectionPointMaintenanceDataStore.selectedCPName = e.target.value}
                              />
                            </div>
                            <div className='settingSelectionDiv'>
                              <label className="standard_label_style">CP Code</label>
                              <input 
                                type="text"
                                value={this.props.connectionPointMaintenanceDataStore.selectedCPCode}
                                className="search_text_field"
                                onChange = {(e) => this.props.connectionPointMaintenanceDataStore.selectedCPCode = e.target.value}
                              />
                        
                              <label className="standard_label_style">CP Type</label>
                              <ReactSelect
                                  className='select'
                                  values={connectionPointMaintenanceDataStore.selectedCPType}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...connectionPointMaintenanceDataStore.cpTypeDropdownOption]}
                                  onChange={(e: any) => {connectionPointMaintenanceDataStore.selectedCPType = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                              <label className="standard_label_style">CP Status</label>
                             <ReactSelect
                                  className='select'
                                  values={connectionPointMaintenanceDataStore.selectedCPStatus}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...connectionPointMaintenanceDataStore.ctsStatusDropdownOption]}
                                  onChange={(e: any) => {connectionPointMaintenanceDataStore.selectedCPStatus = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                            </div>
                            <div className='settingSelectionDiv'>
                              <label className="standard_label_style">Activity Type</label>
                              <ReactSelect
                                  className='select'
                                  values={connectionPointMaintenanceDataStore.selectedActivityType}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...connectionPointMaintenanceDataStore.activityTypeDropdownOption]}
                                  onChange={(e: any) => {connectionPointMaintenanceDataStore.selectedActivityType = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                              <label className="standard_label_style">State</label>
                              <ReactSelect
                                  className='select'
                                  values={connectionPointMaintenanceDataStore.selectedState}
                                  placeholder="Select"
                                  options={[{ value: 'All', label: 'All' }, ...connectionPointMaintenanceDataStore.tariffStatesDropdownOption]}
                                  onChange={(e: any) => {connectionPointMaintenanceDataStore.selectedState = e}}
                                  isMulti={false}
                                  isSearchable={true}
                              />
                            </div>
                            <div className='searchButtonDiv'>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onSearchClick} disabled= {false}>
                                Search
                            </CustomButton>
                            <CustomButton type={CustomButtonType.Submit} onClick={this.onBtClearSelection} disabled= {false}>
                                Clear
                            </CustomButton>

                          <div className='otherHeaderButtonsDiv'>
                          <CustomButton type={CustomButtonType.Submit} onClick={this.onAddConnectionPoint} disabled= {connectionPointMaintenanceDataStore.isSaveDisabled()}> 
                            Add Connection Point
                          </CustomButton>
                          {/* <CustomButton type={CustomButtonType.Submit} onClick={this.onAddConnectionPoint} disabled= {true}> 
                            Connection Linker
                          </CustomButton> */}
                          <CustomButton type={CustomButtonType.Submit} onClick={this.handleStateChangeFromDelToActive} disabled= {connectionPointMaintenanceDataStore.isActivateButtonDisabled || connectionPointMaintenanceDataStore.isActivateDeactivateDisabled() }>
                            Activate
                          </CustomButton>
                          </div>
                            </div>
                          </div>
                    </div>
                    {this.props.connectionPointMaintenanceDataStore.showLoader == true &&
                      <div className = 'alert-overlay'> 
                        <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                      </div>
                    }
                    <AgGridComponent gridConfig={this.getGridConfig('Connection Point Maintenance')} />
                    <Prompt when={dataStore.showUnsavedWarningAlert} message={'You have unsaved changes, Are you sure you want to leave?'}/>
                </Col>
              </Row>
            </Container>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          connectionPointMaintenanceDataStore: { ConnectionPointsData, agGridService }
      } = this.props;
      const { connectionPointMaintenanceDataStore } = this.props;
      const columnDefs = connectionPointMaintenanceDataStore.getColDef();
      const onCellEditingStarted = this.onCellEditingStarted;
      const onCellEditingStopped = this.onCellEditingStopped;
      const onSelectionChanged = this.onSelectionChanged;
      const rowModelType = 'infinite';
      const currentPage = connectionPointMaintenanceDataStore.agGridService.paginationGetCurrentPage() ;
      const pageSize = connectionPointMaintenanceDataStore.agGridService.paginationGetPageSize();
      const cacheBlockSize =  dataStore.CacheBlockSize;
      const rowData = ConnectionPointsData;
      const miscellaneousSettingsGridParams: any = {
        columnDefs,
        onCellEditingStarted,
        onCellEditingStopped,
        onSelectionChanged,
        rowModelType,
        cacheBlockSize
        };
      return agGridService.getGridConfig(miscellaneousSettingsGridParams, gridId);
    }

    onCellEditingStarted = (event: CellEditingStartedEvent): void => {
      const row = event.data.connectionPointID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          connectionPointMaintenanceDataStore: { cellValueChangeMap }
      } = this.props;
      const isNotEmpty =
          row in cellValueChangeMap && col in cellValueChangeMap[row] && cellValueChangeMap[row][col].initValue;
      if (!isNotEmpty) {
          this.props.connectionPointMaintenanceDataStore.setValueInChangeMap(row, col, value, value);
      }
    };

    onCellEditingStopped = (event: CellEditingStoppedEvent): void => {
      const row = event.data.connectionPointID;
      const col = event.colDef.field || '';
      const value = event.value;
      const {
          connectionPointMaintenanceDataStore: { cellValueChangeMap, updateRow, getColDef}
      } = this.props;
      cellValueChangeMap[row][col]['currentValue'] = value;
      updateRow(event.data);
      connectionPointMaintenanceDataStore.agGridService.getNodes()?.refreshCells();
    };

    onSelectionChanged(event: SelectionChangedEvent) {
      const selectedRows = event.api.getSelectedRows();
      console.log(event.api.getSelectedRows());
      if(selectedRows && selectedRows.length > 0 && selectedRows.some((item) => item.action === 'D')) {
        connectionPointMaintenanceDataStore.setIsActivateButtonDisabled(connectionPointMaintenanceDataStore.isSaveDisabled())
      } else {
        connectionPointMaintenanceDataStore.setIsActivateButtonDisabled(true)
      }
  }
}
