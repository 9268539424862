import Path from '../../../../Path';
import { RestApiService, restApiService } from '../../../../shared/services/rest-api-service';

export class CustomerMaintenanceDataService {
    constructor(private restApi: RestApiService) {}

    async getCustomerMaintenanceGridData( reqParameters: any, reqBody: any): Promise<any> {
        const {paginationStart, paginationEnd, pipelineID, custStatus, custType, custCode, custName, ColId, Sort, custEnomCode} = reqParameters
        const response = await this.restApi.post<any>(Path.getCustomerMaintenanceGrid+`?isPagination=true&start=${paginationStart}&end=${paginationEnd}&pipelineID=${pipelineID}&custStatus=${custStatus}&custType=${custType}&custCode=${custCode}&custName=${custName}&ColId=${ColId}&Sort=${Sort}&custEnomCode=${custEnomCode}`, reqBody, {});
        return response;
    }

    async checkCustomerName(customerName): Promise<any> {
        const { data }  = await this.restApi.get<any>(Path.checkCustomerName+`?custName=${customerName}`);
        return data;
    }

    async getTariffStates(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id7);
        return data;
    }  
    
    async getCustomerTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getCustomerTypes);
        return data;
    }
    
    async getJurisdictionDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.url_id8);
        return data;
    }
    
    async getCustomerDetails(custID): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.checkCustomerName+`?custID=${custID}`);
        return data;
    }
    
    
    async getPhoneNumberTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.phoneNumberTypes);
        return data;
    }

    async deactivate(custId, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.deactivateCustomer+`?custID=${custId}&user=${user}`);
        return data;
    }
    
    async activate(body, user): Promise<any[]> {
        const { data } = await this.restApi.put<any>(Path.activateCustomer+`?user=${user}`, body, {});
        return data;
    }

    
    async saveCustomer(body): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.saveCustomer, body, {});
        return data;
    }
    
    
    async getAddressTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.addressTypes);
        return data;
    }

    async getCustomerAddressTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.custAddressTypes);
        return data;
    }

    async getCustomerCommentTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.custCommentTypes);
        return data;
    }

    async getContactTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.contactTypes);
        return data;
    }

    async getCreditTypesDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.creditTypes);
        return data;
    }

    async getCustomerStatusDropdown(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getCustomerStatus);
        return data;
    }

    async getBaseSystems(): Promise<any[]> {
        const { data } = await this.restApi.get<any>(Path.getBaseSystems);
        return data;
    }
    
    async pushToVmacs(reqbody: any): Promise<any[]> {
        const { data } = await this.restApi.post<any>(Path.vmacsCustomerEndpoint, reqbody, {});
        return data;
    }  

    async getCustomerEnomCode(customerId): Promise<any> {
        const { data }  = await this.restApi.get<any>(Path.getTariffCustomerEnomCode+customerId);
        return data;
    }

    async deleteSectionRows(reqbody: any, user, section): Promise<any[]> {
        const url = section === 'Address' ? Path.tariffCust_deleteAddress : section === 'AddContact' ? Path.tariffCust_deleteCustAddInf : section === 'OtherData' ? Path.tariffCust_deleteCustAffliates : Path.tariffCust_deleteCustComments;
        const { data } = await this.restApi.put<any>(`${url}${user}`, reqbody, {});
        return data;
    }    

}

export const customerMaintenanceDataService = new CustomerMaintenanceDataService(restApiService);
