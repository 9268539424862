import { tariffLogDataService } from '../services/tariff-log-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { TariffLogDataStore } from './tariff-log-data-store';
import { accountStore } from '../../../account/stores';
import { LogEntryDataStore } from './create-log-entry-data-store';

export const agGridServiceForVersionSelection = new AgGridService();
export const agGridServiceForAddAttachmentLog = new AgGridService();

export const tariffLogDataStore = new TariffLogDataStore(tariffLogDataService, agGridService, uiService, accountStore, agGridServiceForVersionSelection, agGridServiceForAddAttachmentLog);

export const agGridServiceForLogWorkflowDetails = new AgGridService();
export const logEntryDataStore = new LogEntryDataStore(tariffLogDataService, agGridService, uiService, accountStore, agGridServiceForVersionSelection, agGridServiceForAddAttachmentLog, agGridServiceForLogWorkflowDetails);
