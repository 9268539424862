import React, { Component } from 'react';
import {
    ColDef, RowDataUpdatedEvent, FirstDataRenderedEvent
  } from 'ag-grid-community';
import { TariffOptionsDataStore } from '../stores/tariff-options-data-store';
import { Portal } from 'react-overlays';
import DatePicker from 'react-datepicker';
import { Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import { inject, observer } from 'mobx-react';
import { ConfirmWithOK, ConfirmWithOKProps } from '../../../../shared/components/confirm/confirmWithOk';
import { ConfirmService } from '../../../../shared/components/confirm/confirm-service';
import history from 'shared/components/header-component/component/history';
import PredictiveSelectCellEditor from 'common/components/predictive-cell-editor/PredictiveSelectCellEditor';

interface movementInterface {
  tariffOptionsDataStore: TariffOptionsDataStore
  index: number,
  tariffOptionID: number,
  addMovement: (index: any) => void
}

interface calendarContainerInterface {
  children: any;
}

@inject('tariffOptionsDataStore')
@observer
export class Movement extends Component<movementInterface> {
  constructor(props) {
    super(props);
  }

  CalendarContainer = (props: calendarContainerInterface) => {
    const el = document.getElementById('calendar-portal');

    return <Portal container={el}>{props.children}</Portal>;
  };

   onChangeData = (params, value, key, index) => {
    const {tariffOptionsDataStore} = this.props;
    if(key==='effectiveDate'||key==='endDate'||key==='noChange'){
      const rowIndex = tariffOptionsDataStore.MovementData[index].findIndex(x=>x.rowId===params.data.rowId)
      tariffOptionsDataStore.setMovementData(value, rowIndex, key, index);
      tariffOptionsDataStore.modifedRows[this.props.index] = {...this.props.tariffOptionsDataStore.modifedRows[this.props.index], movement: [...(tariffOptionsDataStore.modifedRows[this.props.index]?.movement||[])]}
      tariffOptionsDataStore.modifedRows[this.props.index]['movement'] = [...tariffOptionsDataStore.modifedRows[this.props.index]['movement'], params.data?.rowId] 
      // tariffOptionsDataStore.modifedRows[index] = {movement: []}
      // tariffOptionsDataStore.modifedRows[index] = {movement: [...tariffOptionsDataStore.modifedRows[index].movement, params.data.rowId]} 
    }
  } 

  colDefMovement: ColDef[] = [
    {
      headerName: 'Select',
      field: 'select',
      checkboxSelection: (params) => { return !params.data.isCloned},
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      colId:'Select',
      width: 40,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      suppressSizeToFit: true,
      cellStyle: () => {return {padding: '10px'}}
    },
    {
        headerName: 'Tariff Mov ID',
        field: 'tariffMvmtID',
        tooltipField: 'tariffMvmtID',
        editable: false,
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        filter: true,
        sortable: true,
        width: 110,
        minWidth: 110,
        singleClickEdit: true,
        suppressSizeToFit: true,
        flex: 1
    },
    {
      headerName: 'Segment',
      field: 'lineSegDesc',
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
      cellEditor: PredictiveSelectCellEditor,
      singleClickEdit: true,
      cellEditorParams: (params) => {
        return {
            dropDownValues: ['Unselect Line Segment', ...this.props.tariffOptionsDataStore.mvtLineSegment[this.props.index].map(x=>x.lineSegDesc)]
        };
      },
      valueFormatter:  (params) => {
        return params.data.lineSegDesc? params.data.lineSegDesc : this.props.tariffOptionsDataStore.mvtLineSegment[this.props.index]?.length>0 ? 'Select Line Segment': 'No Line Segments Found'  
      },
      valueSetter: (params) => {
        if(params.newValue!=='Unselect Line Segment'){
          params.data.lineSegDesc=params.newValue;
          return true
        } else {
          params.data.lineSegDesc='';
          return true
        }
      },
  },
  {
      headerName: 'Rate Direction',
      field: 'rateDirection',
      editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
      cellEditorParams: (params) => {
        return {
          dropDownValues: ['Unselect Rate Direction', ...this.props.tariffOptionsDataStore.mvtRateTypeDirection.map(x=>x.valueText)]
        };
      },
      valueFormatter:  (params) => {
        return params.data.rateDirection? params.data.rateDirection : this.props.tariffOptionsDataStore.mvtRateTypeDirection?.length>0 ? 'Select Rate Direction': 'No Rate Direction Found'  
      },
      valueSetter: (params) => {
        if(params.newValue!=='Unselect Rate Direction'){
          params.data.rateDirection=params.newValue;
          return true
        } else {
          params.data.rateDirection='';
          return true
        }
      },
      cellEditor: PredictiveSelectCellEditor,
      singleClickEdit: true,
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      minWidth: 130,
  },
  {
    headerName: 'Rate Type',
    field: 'rateType',
    tooltipField: 'rateType',
    cellEditor: PredictiveSelectCellEditor,
    filter: true,
    editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
    sortable: true,
    singleClickEdit: true,
    cellEditorParams: (params) => {
      return {
        dropDownValues: ['Unselect Rate Type', ...this.props.tariffOptionsDataStore.mvtRateTypes.map(x=>x.tariffRateDesc)]
      };
    },
    valueFormatter:  (params) => {
      return params.data.rateType? params.data.rateType : this.props.tariffOptionsDataStore.mvtRateTypes?.length>0 ? 'Select Rate Type': 'No Rate Types Found'  
    },
    valueSetter: (params) => {
      if(params.newValue!=='Unselect Rate Type'){
        params.data.rateType=params.newValue;
        return true
      } else {
        params.data.rateType='';
        return true
      }
    },
    cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
    minWidth: 135,
},
  {
          headerName: 'Old Rate (cents)',
          field: 'oldRate',
          tooltipField: 'oldRate',
          editable: false,
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          valueSetter: (params) => {
            if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
              params.data.oldRate=params.newValue.toUpperCase();
              return true;
            } else {
              this.props.tariffOptionsDataStore.newRateIncorrectAlert();
              return false
            }
          },
          filter: true,
          sortable: true,
          width: 140,
          minWidth: 140,
          flex: 1,
          suppressSizeToFit: true
      },
      {
        headerName: 'New Rate (cents)',
        field: 'newRate',
        tooltipField: 'newRate',
        filter: true,
        editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
        sortable: true,
        width: 140,
        minWidth: 140,
        valueSetter: (params) => {
          if(params.newValue.match(/^[0-9]*[.]{0,1}[0-9]{0,2}$/)&&params.newValue.slice(-1)!=='.'){
            params.data.newRate=params.newValue.toUpperCase();
            return true
          } else {
            this.props.tariffOptionsDataStore.newRateIncorrectAlert();
            return false
          }
        },
        cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
        singleClickEdit: true,
        suppressSizeToFit: true,
        flex: 1
    },
    // {
    //   headerName: 'No Change',
    //   field: 'noChange',
    //   tooltipField: 'noChange',
    //   headerClass: 'text-center',
    //   editable: false,
    //   filter: true,
    //   sortable: true,
    //   cellRenderer: params => {
    //     return (<input type='checkbox' checked={params.data.noChange===true}  
    //     onClick={(e)=>{this.onChangeData(params, !params.data.noChange, 'noChange', this.props.index)}}/>);
    //   }, 
    //   cellClass: (params) => {return this.props.tariffOptionsDataStore.modifedRows[this.props.index]&&this.props.tariffOptionsDataStore.modifedRows[this.props.index]['movement']?.includes(params.data.rowId)? 'cell-overridden-yellow' :params.data.action === 'D' ? 'grid-cell-bgColor-red' : params.data?  'cell-editable' : ''},
    //   cellStyle: () => {return {padding: '10px', justifyContent: 'center'}},
    //   width: 110,
    //   singleClickEdit: true,
    //   suppressSizeToFit: true
    // },
    {
      headerName: 'Effective Date',
      field: 'effectiveDate',
      editable: false,
      // comparator: (a, b) => {
      //     const dateA = new Date(a);
      //     const dateB = new Date(b);
      //     if(dateA===dateB) return 0;
      //     else if(dateA>dateB) return 1;
      //     return -1           
      // },
      // sort: 'asc',
      // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
      valueFormatter: ({ value, data }) => '',
      cellRenderer: (params) => {
          return (params.data ? (<DatePicker
                popperClassName='ag-custom-component-popup'
                selected={params.data.effectiveDate && new Date(params.data.effectiveDate) || null}
                onChange={(value)=>{this.onChangeData(params, value, 'effectiveDate', this.props.index)}}
                popperContainer={this.CalendarContainer}
                popperPlacement='top-start'
                className='datePickerGridInput'
                disabled = {this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
                // readOnly={this.props.tariffOptionsDataStore.ownersCreated}
                />) : '')
      },
      cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
      width: 120,
      minWidth: 120,
      flex: 1
  },
  {
    headerName: 'End Date',
    field: 'endDate',
    editable: false,
    // cellClass: (params) => {return params.data && !this.props.tariffOptionsDataStore.ownersCreated ?  'cell-editable' : ''},
    cellRenderer: (params) => {
        return (params.data &&  (<DatePicker
              popperClassName='ag-custom-component-popup'
              selected={params.value && new Date(params.value) || null}
              onChange={(value)=>{this.onChangeData(params, value, 'endDate', this.props.index)}}
              popperContainer={this.CalendarContainer}
              popperPlacement='top-start'
              // readOnly={this.props.tariffOptionsDataStore.ownersCreated}
              className='datePickerGridInput'
              disabled={this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()}
              />)) || params.value
    },
    cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
    flex: 1,
    minWidth: 120,
    width: 120
},
{
  headerName: 'Rate Basis',
  field: 'rateBasis',
  tooltipField: 'rateBasis',
  cellEditor: PredictiveSelectCellEditor,
  editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
  cellEditorParams: (params) => {
    return {
      dropDownValues: ['Unselect Rate Basis',...(this.props.tariffOptionsDataStore.mvtRateBasis && this.props.tariffOptionsDataStore.mvtRateBasis?.map(x=>x.valueText) || [])]
    };
  },
  singleClickEdit: true, 
  valueFormatter:  (params) => {
    return params.data.rateBasis? params.data.rateBasis : this.props.tariffOptionsDataStore.mvtRateBasis?.length>0 ? 'Select Rate Basis': 'No Rate Basis Found'  
  },
  valueSetter: (params) => {
    if(params.newValue!=='Unselect Rate Basis'){
      params.data.rateBasis=params.newValue;
      return true
    } else {
      params.data.rateBasis='';
      return true
    }
  },      
  cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
  minWidth: 130,
},
      {
          headerName: 'CEMA Rate',
          field: 'cemaRate',
          tooltipField: 'cemaRate',
          filter: true,
          valueSetter: (params) => {
            if(params.newValue.match(/^[0-9.]*$/)){
              params.data.cemaRate=params.newValue.toUpperCase();
              return true
            } else {
              return false
            }
          },
          editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
          sortable: true,
          width: 110,
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          singleClickEdit: true,
      },
      {
          headerName: 'Tariff Comment(Info Only)*',
          field: 'tariffComments',
          tooltipField: 'tariffComments',
          singleClickEdit: true,
          filter: true,
          sortable: true,
          minWidth: 175,
          cellClass: (params) => {return params.data?.action === 'D' ? 'cell-deleted' : params.data?.action === 'U' ? 'cell-overridden' : params.data?.action === 'A' ? 'cell-new' : ''},
          editable: () => {return !(this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType())},
          suppressSizeToFit: false,
      }
    ];
    getGridConfigMovement = (gridId) => {
      const {
        tariffOptionsDataStore: { agGridServiceMovement }
      } = this.props;
      const {tariffOptionsDataStore} = this.props;
      const gridParams: any = {
        rowData: tariffOptionsDataStore.MovementData.length && tariffOptionsDataStore.MovementData[tariffOptionsDataStore.selectedTab]||[],
        columnDefs:this.colDefMovement,
        domLayout: 'autoHeight', 
        onRowDataUpdated: this.onRowDataUpdated,
        onFirstDataRendered: this.onFirstDataRendered,
        onCellEditingStopped: (params) => {
          tariffOptionsDataStore.modifedRows[this.props.index] = {...this.props.tariffOptionsDataStore.modifedRows[this.props.index], movement: [...(tariffOptionsDataStore.modifedRows[this.props.index]?.movement||[])]}
          tariffOptionsDataStore.modifedRows[this.props.index]['movement'] = [...tariffOptionsDataStore.modifedRows[this.props.index]['movement'], params.data?.rowId] 
          this.props.tariffOptionsDataStore.agGridServiceMovement.redrawRows();
          // const allColumnIds : any [] = [];
          // params.columnApi?.getAllGridColumns().forEach((column, index) => {
          //   if(index!==0){
          //     allColumnIds.push(column.getId());
          //   }
          // });
          setTimeout(()=>params.columnApi.autoSizeColumns(['lineSegDesc','rateDirection','rateType','rateBasis','tariffComments'], false), 1000);
        },
        context : agGridServiceMovement
      };
      return agGridServiceMovement.getGridConfig(gridParams, gridId);
    }

    onRowDataUpdated = (event: RowDataUpdatedEvent) => {
      setTimeout(()=>event.columnApi.autoSizeColumns(['lineSegDesc','rateDirection','rateType','rateBasis','tariffComments'], false), 1000);
    }

    onFirstDataRendered = async (event: FirstDataRenderedEvent): Promise<void> => {
      setTimeout(()=>event.columnApi.autoSizeColumns(['lineSegDesc','rateDirection','rateType','rateBasis','tariffComments'], false), 1000);
    }

    deleteMovement = async (index) => {
      const { tariffOptionsDataStore } = this.props;
      const requestBody: any = await tariffOptionsDataStore.agGridServiceMovement.getSelectedRows();
      if(requestBody.length > 0) {
          console.log(requestBody);
          const confirmService = new ConfirmService();
          confirmService.showConfirmDialog(
            () => tariffOptionsDataStore.deleteMovement(requestBody, index),
            'Delete',
            'Are you sure you want to delete the selected record/s?'
        );
      } else {
        const confirmService = new ConfirmService();
        confirmService.showConfirmWithCustomComponent(
          () => {console.log(1)},
          ConfirmWithOK,
          {secondaryText: 'No records are selected. Please select atleast one record to perform deletion.'} as ConfirmWithOKProps,
          'confirmPopup'
        );
      }
    }
  
render(): React.ReactNode {
  const { tariffOptionsDataStore } = this.props;
    return (
    <>
      <Row>
      <Col><div className='LeftFlex'>
        {/* <label className="standard_label_style">Current Index Rate</label>
          <input
            type="text"
            className="search_text_field input"
            readOnly={true}
            value={this.props.tariffOptionsDataStore.tariffIndexRate}
          /> */}
        </div>
        </Col>
        <div className='rightFlex2'>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.props.addMovement(this.props.index)}}>
            Add Movement
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.props.tariffOptionsDataStore.saveMovement(this.props.index, this.props.tariffOptionID)}}>
            Save Movement
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType()} onClick={()=>{this.deleteMovement(this.props.index)}}>
            Delete Movement
            </CustomButton>
            <CustomButton type={CustomButtonType.Submit} disabled={this.props.tariffOptionsDataStore.formData[this.props.index]['new']===true || this.props.tariffOptionsDataStore.readOnlyView || this.props.tariffOptionsDataStore.isSaveDisabledForLineSegment() || this.props.tariffOptionsDataStore.disabledFieldOnRulesandRegType() || this.props.tariffOptionsDataStore.isCreateNewEntityDisabled} onClick={()=>{history.push('/AddEditLineSegmentMaintenance', {from: '/TariffOptions', url: '/TariffOptions',  tariffName: tariffOptionsDataStore.formDataSelection.tariffLog, tariffNumber: tariffOptionsDataStore.formDataSelection.tariffLog, baseTariff: tariffOptionsDataStore.formDataSelection.baseTariff, tariffLogId : tariffOptionsDataStore.tariffLogId, pipeline: tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea ? [{ 'value': tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea, 'label': tariffOptionsDataStore.formData[tariffOptionsDataStore.selectedTab]?.pipelineArea}] : null})}}>
            Add Segment
            </CustomButton>
        </div>
      </Row>
      <Row>
        <AgGridComponent gridConfig={this.getGridConfigMovement('Tariff Option Movement')}/>
      </Row>
      </>
    )}
  }