import { ConnectionPointMaintenanceDataStore } from './connection-point-store';
import { cpDataService } from '../services/cp-data-service';
import { uiService } from '../../../../shared/services/ui-service';
import { AgGridService, agGridService } from '../../../../common/services/ag-grid-service';
import { accountStore } from '../../../account/stores';
import { ConnectionPointDetailsDataStore } from './connection-point-details-store';

export const connectionPointMaintenanceDataStore = new ConnectionPointMaintenanceDataStore(cpDataService, agGridService, uiService, accountStore);

export const agGridServiceForRelationShip = new AgGridService();
export const connectionPointDetailsDataStore = new ConnectionPointDetailsDataStore(cpDataService, agGridService, uiService, accountStore, agGridServiceForRelationShip);
